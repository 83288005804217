import * as React from 'react';
import DocumentTitle from 'react-document-title';

import { Header } from '../components/header';
import { ProfileUpdatesNeeded } from '../components/profile-updates-needed';

export const ProfileUpdatesNeededPage = () => (
    <DocumentTitle title="Profile Updates Needed">
        <div id="container">
            <Header title="Profile Updates Needed" />
            <div id="content" className="flex-fill">
                <ProfileUpdatesNeeded />
            </div>
        </div>
    </DocumentTitle>
);
