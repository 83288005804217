import { MenuItem, Select } from '@material-ui/core';
import { isEqual } from 'lodash';
import React from 'react';

import { JobType, placementFeeJobTypes, sourcerJobTypes } from 'shared/common/job-constants';

export type JobFilterType = 'placement-fees' | 'sourcer' | 'staffing' | 'rpo' | 'all';

export const jobTypesForFilter: Map<JobFilterType, JobType[]> = new Map([
    ['all', placementFeeJobTypes.concat(sourcerJobTypes, ['RPO', 'Staffing'])],
    ['placement-fees', placementFeeJobTypes],
    ['rpo', ['RPO']],
    ['sourcer', sourcerJobTypes],
    ['staffing', ['Staffing']]
]);

export const jobTypeFilterLabels: Map<JobFilterType, string> = new Map([
    ['all', 'All'],
    ['placement-fees', 'Contingency/Retained'],
    ['rpo', 'RPO'],
    ['sourcer', 'Sourcer Jobs'],
    ['staffing', 'Staffing']
]);

export const JobsTypeSelector: React.FC<{
    selected: JobType[];
    onChange: (value: JobType[]) => void;
}> = ({ selected, onChange }) => {
    const handleChange = (event: React.ChangeEvent<{ value: JobFilterType }>) => {
        onChange(jobTypesForFilter.get(event.target.value));
    };

    const menuOptions: JobFilterType[] = ['placement-fees', 'sourcer', 'staffing', 'rpo', 'all'];
    const menuOptionItems = menuOptions.map((opt) => (
        <MenuItem key={opt} value={opt}>
            {jobTypeFilterLabels.get(opt)}
        </MenuItem>
    ));

    const selectedValue = menuOptions.find((opt) => isEqual(jobTypesForFilter.get(opt), selected));

    return (
        <Select value={selectedValue} onChange={handleChange} variant="outlined">
            {menuOptionItems}
        </Select>
    );
};
