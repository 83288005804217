import { css } from '@emotion/core';
import { Chip, Tooltip } from '@material-ui/core';
import React from 'react';
import { JobFilter, JobFilterField, jobFilterFieldsOrder } from 'shared/models/job';

import { addFilter, removeFilter } from '../common/job';

const styles = css`
    margin: 5px 15px 0 4px;

    .presets-cloud {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;

        .field-presets {
            display: flex;
            gap: 15px;

            .preset {
                font-size: 14px;
                opacity: 0.5;
                cursor: pointer;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }

    .field-presets-spacer {
        border-right: thin solid rgba(0, 0, 0, 0.3);

        &:last-child {
            border-right: none;
        }
    }

    .filter-chips {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        margin-top: 15px;
    }
`;

export const JobsFilters: React.FC<{
    presets: JobFilter[];
    selected: JobFilter[];
    onChange: (filters: JobFilter[]) => void;
}> = ({ presets, selected, onChange }) => {
    const handleAddPresetFilter = (field: JobFilterField, value: { label?: string; name: string }) => () => {
        onChange(addFilter(selected, { field, value }));
    };

    const handleDeletePresetFilter = (field: JobFilterField, value: { label?: string; name: string }) => () => {
        onChange(removeFilter(selected, { field, value }));
    };

    const cloud = presets.map(({ field, values }, i) => {
        const fieldPresets = values.map((v, j) => (
            <Tooltip key={j} title={v.tooltip}>
                <div className="preset" onClick={handleAddPresetFilter(field, v)}>
                    {v.label ?? v.name}
                </div>
            </Tooltip>
        ));
        return (
            <React.Fragment key={i}>
                <div className="field-presets">{fieldPresets}</div>
                <div className="field-presets-spacer" />
            </React.Fragment>
        );
    });

    const chips = selected
        .sort((f1, f2) => jobFilterFieldsOrder.indexOf(f1.field) - jobFilterFieldsOrder.indexOf(f2.field))
        .map((filter, i) => {
            const fieldChips = filter.values.map((value, j) => (
                <Chip
                    key={j}
                    label={value.label ?? value.name}
                    onDelete={handleDeletePresetFilter(filter.field, value)}
                />
            ));
            return (
                <React.Fragment key={i}>
                    {fieldChips}
                    <div className="field-presets-spacer" />
                </React.Fragment>
            );
        });

    return (
        <div className="jobs-filters-chips-section" css={styles}>
            <div className="presets-cloud">{cloud}</div>
            <div className="filter-chips">{chips}</div>
        </div>
    );
};
