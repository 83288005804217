import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import * as classNames from 'classnames';
import * as React from 'react';
import { BASELINE_SEARCH_SORT_RANK, JobSearchesSourcingStats, SearchResultsViewType } from 'shared/models/search';

import { Permissions } from 'shared/models/permission';
import { UserData } from 'shared/models/user';

import { getStatusLabel } from '../common/searches';
import { timeRelativeDay } from '../common/timestamp';
import { User } from '../sfc/user';
import { Search, SearchStatus } from '../state';
import { JobSearchesTitle } from './job-searches-title';

const styles = {
    headerCol: { height: 48 }
};

interface SourcerSearchesStatsProps {
    searches: Search[];
    searchStats: JobSearchesSourcingStats[];
    user: UserData;
    userPermissions: Permissions;
    onDisplaySearchInfo: (view: { searchId: string; resultsType: SearchResultsViewType }) => void;
    onViewResults: (view: { searchId: string; resultsType: SearchResultsViewType }) => void;
    onCloneSearch: (data: Search) => void;
    onUpdateSearch: (id: string, updates: Partial<Search>, refetchSearches: boolean) => void;
    onDeleteSearch: (data: Search) => void;
}
export class SourcerSearchesStats extends React.Component<SourcerSearchesStatsProps> {
    handleViewSearchInfo = (searchId: string) => {
        this.props.onViewResults({ searchId, resultsType: 'total' });
    };

    handleViewSearchResults = (searchId: string, resultsType: SearchResultsViewType) => () => {
        this.props.onViewResults({ searchId, resultsType });
    };

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ sourcingDetail: event.target.checked });
    };

    render() {
        const { searches, searchStats, userPermissions } = this.props;
        const statsRows = searches
            .filter((s) => s.status !== SearchStatus.Initial)
            .map((s) => {
                const stats = searchStats.find((st) => st.id === s.id);
                const availableCount = (
                    <span className="search-results-count-link" onClick={this.handleViewSearchResults(s.id, 'ready')}>
                        {stats ? stats.ready : '-'}
                    </span>
                );
                const lowScoreCount = (
                    <span
                        className="search-results-count-link"
                        onClick={this.handleViewSearchResults(s.id, 'low_score')}
                    >
                        {stats ? stats.lowScore : '-'}
                    </span>
                );
                const addedCount = (
                    <span className="search-results-count-link" onClick={this.handleViewSearchResults(s.id, 'added')}>
                        {stats ? stats.added : '-'}
                    </span>
                );
                const rejectedCount = (
                    <span
                        className="search-results-count-link"
                        onClick={this.handleViewSearchResults(s.id, 'rejected')}
                    >
                        {stats ? stats.rejected : '-'}
                    </span>
                );
                const newCount = (
                    <span
                        className="search-results-count-link"
                        onClick={this.handleViewSearchResults(s.id, 'total_new')}
                    >
                        {stats ? stats.totalNew : '-'}
                    </span>
                );

                const title = (
                    <JobSearchesTitle
                        user={this.props.user}
                        onCloneSearch={this.props.onCloneSearch}
                        onUpdateSearch={this.props.onUpdateSearch}
                        onDeleteSearch={this.props.onDeleteSearch}
                        onSearchClick={this.handleViewSearchInfo}
                        search={s}
                        job={null}
                        userPermissions={userPermissions}
                        isCampaign={true}
                        name={s.name}
                    />
                );

                const classes = classNames({
                    baseline: s.sortRank === BASELINE_SEARCH_SORT_RANK,
                    inactive: s.status !== SearchStatus.Active
                });

                return (
                    <TableRow key={s.id} className={classes} hover={true}>
                        <TableCell>{title}</TableCell>
                        <TableCell>{getStatusLabel(s, s.status)}</TableCell>
                        <TableCell>{availableCount}</TableCell>
                        <TableCell>{lowScoreCount}</TableCell>
                        <TableCell>{addedCount}</TableCell>
                        <TableCell>{rejectedCount}</TableCell>
                        <TableCell>{newCount}</TableCell>
                        <TableCell>{timeRelativeDay(s.createdAt)}</TableCell>
                        <TableCell>
                            <User id={s.userId} />
                        </TableCell>
                        <TableCell>
                            <User id={s.createdBy} />
                        </TableCell>
                    </TableRow>
                );
            });

        return (
            <Paper style={{ overflow: 'hidden' }}>
                <TableContainer>
                    <Table stickyHeader={true} size="small">
                        <TableHead className="jobs-table-header">
                            <TableRow style={styles.headerCol}>
                                <TableCell>Title</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Ready</TableCell>
                                <TableCell>Low Score</TableCell>
                                <TableCell>Added</TableCell>
                                <TableCell>Rejected</TableCell>
                                <TableCell>Total New</TableCell>
                                <TableCell>Created</TableCell>
                                <TableCell>Owner</TableCell>
                                <TableCell>Created By</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{statsRows}</TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        );
    }
}
