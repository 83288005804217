import { useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import { Button, Theme, useTheme } from '@material-ui/core';
import * as React from 'react';
import { JobData } from 'shared/models/job';

import { generateJobOnePager } from '../api';
import { DurationProgress } from '../common/duration-progress';
import { config } from '../config';
import { Pdf } from '../core-ui/pdf';
import { client as apolloClient } from '../graphql/apollo-client';
import { JOB_ONE_PAGER, JobOnePagerData, UPDATE_JOB_ONE_PAGER_DATA } from '../graphql/queries/job-form';
import { useModal } from '../hooks/use-modal';
import { useSnackbar } from '../hooks/use-snackbar';
import { JobOnePagerDataForm } from './job-one-pager-data-form';

const onePagerWidth = 889;
const onePagerHeight = 571;

const styles = (theme: Theme) => css`
    width: 100%;
    position: relative;
    padding-top: 24px;

    .one-pager-label {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 1rem;
        transform: translate(0, 0) scale(0.75);
        transform-origin: top left;
        transition: all 200ms ease-out;
        color: ${theme.palette.text.secondary};
    }

    .job-one-pager-pdf {
        width: 100%;
        height: 100%;
    }

    .onepager-placeholder {
        padding: 12px;
        background: ${theme.palette.grey[100]};
        border-radius: ${theme.shape.borderRadius}px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
    }

    .one-pager-footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 18px;

        .MuiButtonBase-root {
            margin-right: 8px;
            &:last-child {
                margin-right: 0;
            }
        }

        .upload-button {
            min-width: 100px;
        }
    }
`;

const expectedGenerateDurationSeconds = 10;

export const JobOnePager: React.FC<{ jobId: string }> = ({ jobId }) => {
    const { data, refetch } = useQuery<{ job: JobOnePagerData }, { jobId: string }>(JOB_ONE_PAGER, {
        variables: { jobId }
    });
    const [updateOnePagerData] = useMutation<{}, { jobId: string; onePagerData: JobData['onePagerData'] }>(
        UPDATE_JOB_ONE_PAGER_DATA,
        { client: apolloClient('job_creator') }
    );
    const [saving, setSaving] = React.useState(false);
    const [editing, setEditing] = React.useState<JobData['onePagerData'] | null>(null);
    const theme = useTheme();
    const { setSnackbar } = useSnackbar();
    const { setAlert } = useModal();

    const onePager = data?.job.onePager;
    const onePagerData = data?.job.onePagerData;

    const handleGenerate = async () => {
        setSaving(true);
        try {
            setSnackbar('Generating one pager...');
            const result = await generateJobOnePager(jobId);
            if (!result.success) {
                throw new Error('Failed to generate one pager');
            }
            await refetch();
            setSnackbar('One pager generated');
        } catch {
            refetch();
            setAlert('Error', `Failed to generate one pager. ${onePager ? 'Reverting to saved onepager' : ''}`);
        } finally {
            setSaving(false);
        }
    };

    const handleSaveOnePagerData = async (updated: JobData['onePagerData']) => {
        setSaving(true);
        setEditing(null);
        setSnackbar('Saving one pager data...');
        try {
            await updateOnePagerData({ variables: { jobId, onePagerData: updated } });
            const result = await generateJobOnePager(jobId);
            if (!result.success) {
                throw new Error('Failed to generate one pager');
            }
            await refetch();
            setSnackbar('One pager generated');
        } catch {
            setAlert('Error', `Failed to save one pager. ${onePager ? 'Reverting to saved onepager' : ''}`);
        } finally {
            setSaving(false);
        }
    };

    const handleEditOnePagerData = () => {
        setEditing(onePagerData);
    };

    const handleCancelEdit = () => {
        setEditing(null);
    };

    const onePagerLink = onePager
        ? `${config.AssetHost}/files/` + onePager.key.split('/').map(encodeURIComponent).join('/')
        : null;
    const fileDisplay =
        onePager && !saving ? (
            <div style={{ width: onePagerWidth, height: onePagerHeight }}>
                <Pdf url={onePagerLink} className="job-one-pager-pdf" />
            </div>
        ) : saving ? (
            <DurationProgress duration={expectedGenerateDurationSeconds} />
        ) : (
            <div className="onepager-placeholder">Please add a one pager for this job.</div>
        );

    const downloadButton = onePagerLink ? (
        <a href={onePagerLink} target="_blank" download={true}>
            <Button disabled={saving}>Download</Button>
        </a>
    ) : null;

    const generateButton = !onePagerData ? (
        <Button disabled={saving} onClick={handleGenerate}>
            Generate
        </Button>
    ) : (
        <Button onClick={handleEditOnePagerData} disabled={saving}>
            Edit
        </Button>
    );

    const onePagerDataForm = editing ? (
        <JobOnePagerDataForm
            data={data?.job}
            refetch={refetch}
            onSave={handleSaveOnePagerData}
            onClose={handleCancelEdit}
        />
    ) : null;

    return (
        <div css={styles(theme)}>
            <div className="one-pager-label">One Pager</div>
            {fileDisplay}
            <div className="one-pager-footer">
                {downloadButton}
                {generateButton}
            </div>
            {onePagerDataForm}
        </div>
    );
};
