import { IconButton, MenuItem, TextField } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { produce } from 'immer';
import * as React from 'react';

import { Editor } from 'react-ce';

import { SequenceStage } from 'shared/models/sequence';

interface EmailSequenceStageFormProps {
    stage: SequenceStage;
    index: number;
    disabled: boolean;
    onChange: (stage: SequenceStage) => void;
    onRemove: () => void;
}

const maxDelayDays = 20;

export const EmailSequenceStageForm: React.FC<EmailSequenceStageFormProps> = ({
    stage,
    index,
    disabled,
    onChange,
    onRemove
}) => {
    const handleFieldChange =
        <T extends keyof SequenceStage>(k: T) =>
        (value: SequenceStage[T]) => {
            const updated = produce(stage, (draft) => {
                draft[k] = value;
            });
            onChange(updated);
        };

    const handleWaitDaysChange = (val: number) => () => {
        handleFieldChange('waitDays')(val);
    };

    const handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleFieldChange('subject')(event.target.value);
    };

    const subject =
        index === 0 ? (
            <TextField
                value={stage.subject}
                label="Subject"
                fullWidth={true}
                onChange={handleSubjectChange}
                disabled={disabled}
            />
        ) : null;
    const body = (
        <Editor
            value={stage.body}
            label="Content"
            className="sequence-stage-body"
            onChange={handleFieldChange('body')}
            disabled={disabled}
        />
    );
    let header;
    if (index === 0) {
        header = <div className="sequence-stage-header initial">Initial Message</div>;
    } else {
        const daysMenuOpts = [];
        for (let i = 1; i <= maxDelayDays; i++) {
            daysMenuOpts.push(
                <MenuItem value={i} key={i} onClick={handleWaitDaysChange(i)}>
                    {i}
                </MenuItem>
            );
        }
        const removeButton =
            onRemove && !disabled ? (
                <div className="sequence-remove-stage">
                    <IconButton onClick={onRemove}>
                        <Clear />
                    </IconButton>
                </div>
            ) : null;
        header = (
            <div className="sequence-stage-header">
                <div className="sequence-stage-header-text">
                    Follow up message #{index}: This will be sent if no reply after{' '}
                    <TextField
                        value={stage.waitDays}
                        select={true}
                        style={{ width: '80px', margin: '3px 10px 0', fontSize: 20 }}
                        disabled={disabled}
                    >
                        {daysMenuOpts}
                    </TextField>{' '}
                    weekdays
                </div>
                {removeButton}
            </div>
        );
    }
    return (
        <div className="sequence-stage">
            {header}
            {subject}
            {body}
        </div>
    );
};
