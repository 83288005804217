import { css } from '@emotion/core';
import { Button, Dialog, Theme, useTheme } from '@material-ui/core';
import React, { createContext, FC, useContext, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { zIndexes } from '../common/css-variables';
import { getDisabledHours, getNearestValidEmailTs, isDayDisabled } from '../common/email-sending-times';
import { DateTimePicker } from '../core-ui/date-time-picker';

const styles = (theme: Theme) => css`
    .date-time-picker {
        width: 360px;
    }

    .timepicker-actions {
        padding: 10px 20px;
        text-align: center;
        border-top: thin solid ${theme.palette.divider};

        .MuiButton-root {
            margin: 0 10px;
        }
    }
`;

const TimepickerContext = createContext<{
    getCustomTime: (data: { buttonLabel: string; onSelect: (ts: number) => void }) => void;
}>(undefined);

const TimepickerProvider: FC = ({ children }) => {
    const theme = useTheme();
    const layer = useRef<HTMLElement>();
    const [open, setOpen] = useState(false);
    const [pickerData, setPickerData] = useState<{
        buttonLabel: string;
        onSelect: (ts: number) => void;
    }>(null);
    const [timestamp, setTimestamp] = React.useState(undefined);

    useEffect(() => {
        const element = document.createElement('div');
        element.setAttribute('class', 'timepicker-layer');
        element.setAttribute('style', `z-index: ${zIndexes.overEmailDialog}`);
        document.body.appendChild(element);
        layer.current = element;
        return () => {
            layer.current.remove();
        };
    }, []);

    const getCustomTime = (data: { buttonLabel: string; onSelect: (ts: number) => void }) => {
        setPickerData(data);
        setOpen(true);
        setTimestamp(Date.now());
    };

    const handleClose = () => {
        setOpen(false);
        setPickerData(null);
        setTimestamp(undefined);
    };

    const handleConfirm = () => {
        pickerData.onSelect(timestamp);
        handleClose();
    };

    const dialog = open ? (
        <Dialog open={open} onClose={handleClose} css={styles(theme)} style={{ zIndex: zIndexes.timePickerDialog }}>
            <DateTimePicker
                onChange={setTimestamp}
                value={timestamp}
                minDate={Date.now()}
                getNearestValidTime={getNearestValidEmailTs(Date.now(), undefined, false)}
                getDisabledHours={getDisabledHours}
                isDayDisabled={isDayDisabled(Date.now(), undefined, false)}
            />
            <div className="timepicker-actions">
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleConfirm}>{pickerData.buttonLabel}</Button>
            </div>
        </Dialog>
    ) : null;

    const portal = layer.current ? ReactDOM.createPortal(dialog, layer.current) : null;

    return (
        <TimepickerContext.Provider value={{ getCustomTime }}>
            {children}
            {portal}
        </TimepickerContext.Provider>
    );
};

const useTimepicker = () => useContext(TimepickerContext);

export { useTimepicker, TimepickerProvider };
