import { css } from '@emotion/core';
import { Button } from '@material-ui/core';
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';

import { hasRole } from 'shared/models/user';

import { Header } from '../components/header';
import { JobViewSelector } from '../components/job-view-selector';
import { Jobs } from '../components/jobs-details-table';
import { useSession } from '../hooks/use-session';

const buttonStyle = css`
    color: white;
    margin-right: 15px;
`;

export const JobsDetailsPage = () => {
    const { userPermissions } = useSession();
    const addJobButton = hasRole(userPermissions, 'job_creator') ? (
        <Link to="/job/new" key="add-job">
            <Button css={buttonStyle}>Create New Job</Button>
        </Link>
    ) : null;

    return (
        <DocumentTitle title="Jobs">
            <div id="container">
                <Header
                    title="Jobs"
                    actions={[<JobViewSelector key="jobs-details" current="Details View" />, addJobButton]}
                />
                <div id="content" className="flex-fill">
                    <Jobs />
                </div>
            </div>
        </DocumentTitle>
    );
};
