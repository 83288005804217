import { css } from '@emotion/core';
import { Button, MenuItem, Select, TableCell, TableRow } from '@material-ui/core';
import { orderBy } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import {
    awaitingClientStage,
    clientFinalRoundStage,
    clientFirstRoundStage,
    clientMiddleRoundStage,
    offerStage
} from 'shared/models/job-stages';
import { getLocalStorageKey, setLocalStorageKey } from '../../common/local-storage';
import { PageDialogLink } from '../../common/page-dialog-link';

import { RecruiterActiveCandidates } from '../../containers/recruiter-active-candidates';
import { Candidate } from '../../graphql/queries/home';
import { useSlides } from '../../hooks/use-candidate-slides';
import { useSession } from '../../hooks/use-session';
import { useUserContext } from '../../hooks/use-user-context';
import { CandidateActivity } from './candidate-activity';
import { HomePageTable } from './home-page-table';

interface AssigneeCandidatesProps {
    candidates: Candidate[];
}

const rowsPerPage = 8;

const titleStyle = css`
    .MuiSelect-root {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.334;
        letter-spacing: -0.05px;
    }
`;

type FilterOption = 'all-client-rounds' | 'offer' | 'final-rounds' | 'client-initial-rounds' | 'awaiting-feedback';

const CandidateTableRow: React.FC<{ data: Candidate; list: Candidate[] }> = ({ data, list }) => {
    const { setList } = useSlides();

    const handleSelect = (personId: string, jobId: string) => () => {
        const ids = list.map((c) => ({
            jobId: c.jobId,
            personId: c.personId
        }));
        setList(ids, { personId, jobId });
    };

    return (
        <TableRow>
            <TableCell className="link" onClick={handleSelect(data.personId, data.jobId)}>
                {data.person.name}
            </TableCell>
            <TableCell>{data.job.client.name}</TableCell>
            <TableCell>{data.job.title}</TableCell>
            <TableCell>{data.stage.label}</TableCell>
            <TableCell>
                <CandidateActivity candidate={data} />
            </TableCell>
        </TableRow>
    );
};

export const AssigneeCandidates: React.FC<AssigneeCandidatesProps> = ({ candidates }) => {
    const { user } = useUserContext();

    const localStorageKey = 'assigned-candidates-filter';
    const session = useSession();
    const stageFilterOptions: Array<[FilterOption, [string, string[]]]> = [
        [
            'all-client-rounds',
            [
                'Client Round Candidates',
                [offerStage, clientFinalRoundStage, clientMiddleRoundStage, clientFirstRoundStage]
            ]
        ],
        ['offer', ['Offer Candidates', [offerStage]]],
        ['final-rounds', ['Final Round Candidates', [clientFinalRoundStage]]],
        ['client-initial-rounds', ['Client Early Round Candidates', [clientMiddleRoundStage, clientFirstRoundStage]]],
        ['awaiting-feedback', ['Awaiting Client Feedback Candidates', [awaitingClientStage]]]
    ];
    const savedFilter = getLocalStorageKey(localStorageKey, 'all-client-rounds');
    const [filter, setFilter] = React.useState<FilterOption>(savedFilter);

    const filteredStages = stageFilterOptions.find((v) => v[0] === filter)[1][1];
    const filtered = candidates?.filter((c) => filteredStages.indexOf(c.stage.name) !== -1);

    const handleFilterChange = (event: React.ChangeEvent<{ value: FilterOption }>) => {
        event.stopPropagation();
        setFilter(event.target.value);
        setLocalStorageKey(localStorageKey, event.target.value, -1);
    };

    const handleFilterClose = (event: React.ChangeEvent<{}>) => {
        event.stopPropagation();
    };

    const columns = [
        { title: 'Candidate', sortFunc: (c: Candidate) => c.person.name },
        { title: 'Client', sortFunc: (c: Candidate) => c.job.client.name },
        { title: 'Job', sortFunc: (c: Candidate) => c.job.title },
        { title: 'Stage', sortFunc: (c: Candidate) => c.stage.id, defaultDesc: true },
        { title: 'Last Activity', sortFunc: (c: Candidate) => c.lastActivityAt, defaultDesc: true }
    ];

    const menuItems = stageFilterOptions.map(([value, [label]]) => (
        <MenuItem key={value} value={value}>
            {label}
        </MenuItem>
    ));
    const title = (
        <Select
            value={filter}
            disableUnderline={true}
            onChange={handleFilterChange}
            onClose={handleFilterClose}
            css={titleStyle}
        >
            {menuItems}
        </Select>
    );
    const initialSortedRecords = orderBy(filtered, [(c) => c.stage.id, (c) => c.lastActivityAt], ['desc', 'desc']);

    const username = session.user.id === user.id ? 'me' : user.username;
    const url = `/user/${username}/candidates`;
    const viewAllCandidatesButton = (
        <PageDialogLink Component={RecruiterActiveCandidates} componentProps={{ username }} url={url}>
            <Link to={url}>
                <Button>View All</Button>
            </Link>
        </PageDialogLink>
    );

    const showIfEmpty = candidates?.length > 0 && filtered?.length === 0;

    return (
        <HomePageTable
            columns={columns}
            records={initialSortedRecords}
            RowComponent={CandidateTableRow}
            title={title}
            sortKeyId="candidates"
            rowsPerPage={rowsPerPage}
            initialSort={{ column: 'Stage', ascending: false }}
            actions={viewAllCandidatesButton}
            showIfEmpty={showIfEmpty}
        />
    );
};
