import { Buffer } from 'buffer';
const isValidDomain = require('is-valid-domain'); // tslint:disable-line:no-var-requires

import { Communication } from '../../state';
import { clientCommsEmailAddress } from './emails';

export const decodeTitanEmailHeaders = (communication: Communication) => {
    const personId = communication.headers['x-titan-prospect-id']
        ? Buffer.from(communication.headers['x-titan-prospect-id'], 'base64').toString()
        : null;
    const jobId = communication.headers['x-titan-requisition-id']
        ? Buffer.from(communication.headers['x-titan-requisition-id'], 'base64').toString()
        : null;
    const isClientComm = communication.headers['x-titan-mandator-addressed']
        ? Buffer.from(communication.headers['x-titan-mandator-addressed'], 'base64').toString()
        : null;

    return {
        isClientComm: isClientComm === 'true',
        jobId,
        personId
    };
};

export const isValidEmailDomain = (domain: string) => {
    return isValidDomain(domain, { subdomain: false, wildcard: false });
};

export const isClientCommAccount = (message: Communication) => {
    const { account } = message;
    return (
        message.headers.bcc.findIndex((e) => e.address === account) !== -1 &&
        message.headers.to.findIndex((e) => e.address === account) === -1 &&
        message.headers.cc.findIndex((e) => e.address === account) === -1 &&
        message.headers.from.address !== account &&
        (message.headers.to.findIndex((e) => e.address === clientCommsEmailAddress.address) !== -1 ||
            message.headers.cc.findIndex((e) => e.address === clientCommsEmailAddress.address) !== -1)
    );
};
