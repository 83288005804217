import { useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import { IconButton, Theme, useTheme } from '@material-ui/core';
import { ArrowDownward, ArrowUpward, Menu, Settings } from '@material-ui/icons';
import * as classNames from 'classnames';
import moment from 'moment';
import React from 'react';

import { currencyFormat } from 'shared/common/string-format-utils';
import { ActivityMetricType } from 'shared/models/activity-metric';
import {
    awaitingClientStage,
    clientFinalRoundStage,
    clientFirstRoundStage,
    hiredStage,
    offerStage
} from 'shared/models/job-stages';

import { connect } from 'react-redux';
import { toggleDrawer } from '../../actions';
import { isoDate } from '../../common/timestamp';
import {
    ACTIVITY_METRICS,
    AGGREGATE_ACTIVITY_METRICS,
    AggregateMetricsData,
    FEES,
    Fees,
    MetricsData,
    STAGE_CANDIDATES_COUNT,
    STAGE_CONVERSION,
    StageCandidatesCount
} from '../../graphql/queries/metrics';
import { internalClientIds as excludeClientIds, JobType } from '../../state';
import { Duration } from './metrics-durations';
import { MetricsSettingsDialog } from './metrics-settings-dialog';
import { MetricsTimeline } from './metrics-timeline';
import { MetricsDashboardDataProvider, pollInterval, useMetricsDashboardData } from './use-metrics-dashboard-data';

const mouseMoveLimitMs = 10000;

const styles = (theme: Theme) => css`
    background: #c8cacc;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;

    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    .settings-button {
        position: fixed;
        top: 25px;
        right: 25px;
        transition: opacity 0.5s;

        &.not-visible {
            opacity: 0;
        }
    }

    .menu-button {
        position: fixed;
        top: 25px;
        left: 25px;
        transition: opacity 0.5s;

        &.not-visible {
            opacity: 0;
        }
    }

    .panel {
        border: thin solid ${theme.palette.divider};
        border-radius: ${theme.shape.borderRadius}px;
        background: white;
        display: flex;
        flex-direction: column;

        .increased {
            background: #a5d6a7;
        }

        .decreased {
            background: #ef9a9a;
        }

        .panel-header {
            padding: 15px 20px;
            border-bottom: thin solid ${theme.palette.divider};
            font-size: 24px;
            font-weight: 500;
            text-align: center;
            flex: 0 0 auto;
        }

        .panel-data {
            padding: 15px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1 1 auto;

            &.big-number {
                font-size: 100px;
                font-weight: 500;
                text-align: center;

                .baseline {
                    display: flex;
                    font-size: 20px;
                    margin-left: 20px;
                    padding: 12px;
                    border-radius: ${theme.shape.borderRadius}px;
                    align-items: center;
                    justify-content: center;

                    .MuiSvgIcon-root {
                        margin-right: 5px;
                    }
                }
            }

            &.chart {
                .chart-container {
                    margin-bottom: -72px;
                    margin-top: -20px;
                    width: 100%;
                }
            }
        }
    }
`;

const factor = 100;
const chartHeight = 300;
const chartStrokeWidth = 5;

const Trend: React.FC<{ kind: ActivityMetricType; duration: Duration }> = ({ kind, duration }) => {
    const dashboardData = useMetricsDashboardData();
    const restrictToAccountManagerIds = (dashboardData?.restrictToAccountManagerIds ?? []).concat(
        dashboardData?.restrictToTeam?.role === 'account_manager' ? dashboardData?.restrictToTeam?.users : []
    );
    const restrictToRecruiterIds = (dashboardData?.restrictToRecruiterIds ?? []).concat(
        dashboardData?.restrictToTeam?.role === 'recruiter' ? dashboardData?.restrictToTeam?.users : []
    );
    const { restrictToClientIds } = dashboardData;
    const kinds = [kind];
    const whereClause: any = {
        date: { _lte: isoDate(duration.end), _gte: isoDate(duration.start) },
        job: { jobType: { _in: dashboardData.jobTypes }, clientId: { _nin: excludeClientIds } },
        kind: { _in: kinds }
    };

    if (restrictToAccountManagerIds?.length) {
        whereClause.job = { ...whereClause.job, accountManagerId: { _in: restrictToAccountManagerIds } };
    }
    if (restrictToClientIds?.length) {
        whereClause.job.clientId = { ...whereClause.job.clientId, _in: restrictToClientIds };
    }
    if (restrictToRecruiterIds?.length) {
        whereClause.userId = { _in: restrictToRecruiterIds };
    }

    const { data } = useQuery<{ metrics: MetricsData[] }>(ACTIVITY_METRICS, {
        pollInterval,
        variables: {
            where: whereClause
        }
    });

    const chartOptions = {
        yaxis: {
            min: 0, // Ensures that the Y-axis always starts at zero,
            tickAmount: 4
        }
    };

    return (
        <MetricsTimeline
            metrics={data?.metrics}
            duration={duration}
            fields={kinds}
            height={chartHeight}
            periodSize="month"
            strokeWidth={chartStrokeWidth}
            chartOptions={chartOptions}
        />
    );
};

const NumberPanel: React.FC<{
    title: string;
    value: number;
    baseline?: number;
    formatter?: (val: number) => string;
    percent?: boolean;
}> = ({ title, value, baseline, formatter, percent }) => {
    const [previousValue, setPreviousValue] = React.useState(value);

    React.useEffect(() => {
        if (value !== previousValue) {
            setTimeout(() => setPreviousValue(value), pollInterval);
        }
    }, [value]);

    const formattedValue = formatter ? formatter(value) : value;

    const classes = classNames({
        decreased: value !== undefined && previousValue !== undefined && previousValue > value,
        increased: value !== undefined && previousValue !== undefined && previousValue < value,
        panel: true
    });

    let baselineContent;
    if (!!baseline && !isNaN(value) && !isNaN(baseline)) {
        const change = Math.round(((value - baseline) / baseline) * factor * factor) / factor;
        const arrow = change < 0 ? <ArrowDownward /> : <ArrowUpward />;
        baselineContent = (
            <span className={`baseline ${change < 0 ? 'decreased' : 'increased'}`}>
                {arrow} {change}%
            </span>
        );
    }

    const content = isNaN(value) ? null : (
        <span>
            {formattedValue ?? ''}
            {percent ? '%' : ''}
        </span>
    );

    return (
        <div className={classes}>
            <div className="panel-header">{title}</div>
            <div className="panel-data big-number">
                {content}
                {baselineContent}
            </div>
        </div>
    );
};

// hook for outreach -> submit conversion
const useOutreachConversion = (dateRange: [number, number]) => {
    const dashboardData = useMetricsDashboardData();
    const restrictToAccountManagerIds = (dashboardData?.restrictToAccountManagerIds ?? []).concat(
        dashboardData?.restrictToTeam?.role === 'account_manager' ? dashboardData?.restrictToTeam?.users : []
    );
    const restrictToRecruiterIds = (dashboardData?.restrictToRecruiterIds ?? []).concat(
        dashboardData?.restrictToTeam?.role === 'recruiter' ? dashboardData?.restrictToTeam?.users : []
    );
    const { restrictToClientIds } = dashboardData;
    const whereClause: any = {
        date: { _lte: isoDate(dateRange[1]), _gte: isoDate(dateRange[0]) },
        job: { jobType: { _in: dashboardData.jobTypes }, clientId: { _nin: excludeClientIds } }
    };

    if (restrictToAccountManagerIds?.length) {
        whereClause.job = { ...whereClause.job, accountManagerId: { _in: restrictToAccountManagerIds } };
    }
    if (restrictToClientIds?.length) {
        whereClause.job.clientId = { ...whereClause.job.clientId, _in: restrictToClientIds };
    }
    if (restrictToRecruiterIds?.length) {
        whereClause.userId = { _in: restrictToRecruiterIds };
    }

    const { data: outreachData } = useQuery<AggregateMetricsData>(AGGREGATE_ACTIVITY_METRICS, {
        pollInterval,
        variables: {
            where: { ...whereClause, kind: { _in: ['outreach'] } }
        }
    });

    const { data: phoneCallsCompleteData } = useQuery<AggregateMetricsData>(AGGREGATE_ACTIVITY_METRICS, {
        pollInterval,
        variables: {
            where: { ...whereClause, kind: { _in: ['rocket_screen_complete'] } }
        }
    });

    const outreachCallConversion =
        !phoneCallsCompleteData && !outreachData
            ? undefined
            : Math.round(
                  (phoneCallsCompleteData?.metrics.aggregate.sum.count * factor * factor) /
                      outreachData?.metrics.aggregate.sum.count
              ) /
              (factor * 1.0);
    return outreachCallConversion;
};

// hook for conversion between stages
const useStageConversion = (fromStage: string, toStage: string, dateRange: [number, number]) => {
    const dashboardData = useMetricsDashboardData();
    const restrictToAccountManagerIds = (dashboardData?.restrictToAccountManagerIds ?? []).concat(
        dashboardData?.restrictToTeam?.role === 'account_manager' ? dashboardData?.restrictToTeam?.users : []
    );
    const restrictToRecruiterIds = (dashboardData?.restrictToRecruiterIds ?? []).concat(
        dashboardData?.restrictToTeam?.role === 'recruiter' ? dashboardData?.restrictToTeam?.users : []
    );
    const { restrictToClientIds } = dashboardData;
    const { data } = useQuery<
        { conversion: Array<{ conversionPercentage: number }> },
        {
            jobTypes: JobType[];
            excludeClientIds: string[];
            startTime: number;
            endTime: number;
            fromStage: string;
            toStage: string;
            restrictToClientIds: string[];
            restrictToAccountManagerIds: string[];
            restrictToRecruiterIds: string[];
        }
    >(STAGE_CONVERSION, {
        pollInterval,
        variables: {
            endTime: dateRange[1],
            excludeClientIds,
            fromStage,
            jobTypes: dashboardData.jobTypes,
            restrictToAccountManagerIds: restrictToAccountManagerIds?.length ? restrictToAccountManagerIds : null,
            restrictToClientIds: restrictToClientIds ?? null,
            restrictToRecruiterIds: restrictToRecruiterIds?.length ? restrictToRecruiterIds : null,
            startTime: dateRange[0],
            toStage
        }
    });
    return data?.conversion[0].conversionPercentage;
};

// hook for billing information
const useFeesData = (dateRange: [number, number]) => {
    const dashboardData = useMetricsDashboardData();
    const restrictToAccountManagerIds = (dashboardData?.restrictToAccountManagerIds ?? []).concat(
        dashboardData?.restrictToTeam?.role === 'account_manager' ? dashboardData?.restrictToTeam?.users : []
    );
    const restrictToRecruiterIds = (dashboardData?.restrictToRecruiterIds ?? []).concat(
        dashboardData?.restrictToTeam?.role === 'recruiter' ? dashboardData?.restrictToTeam?.users : []
    );
    const { restrictToClientIds } = dashboardData;
    // TODO: use codegen to fill in the type of the variables
    const whereClause: any = {
        incurredAt: { _gte: dateRange[0], _lte: dateRange[1] },
        job: { jobType: { _in: dashboardData.jobTypes }, clientId: { _nin: excludeClientIds } },
        type: { _neq: 'prepayment' }
    };

    if (restrictToAccountManagerIds?.length) {
        whereClause.accountManagerId = { _in: restrictToAccountManagerIds };
    }
    if (restrictToClientIds?.length) {
        whereClause.job.clientId = { ...whereClause.job.clientId, _in: restrictToClientIds };
    }
    if (restrictToRecruiterIds?.length) {
        whereClause.recruiterId = { _in: restrictToRecruiterIds };
    }

    const { data } = useQuery<Fees>(FEES, {
        pollInterval,
        variables: {
            where: whereClause
        }
    });
    return data?.fees?.aggregate.sum.fees;
};

// hook for count in a stage
const useStageCount = (stage: string) => {
    const dashboardData = useMetricsDashboardData();
    const restrictToAccountManagerIds = (dashboardData?.restrictToAccountManagerIds ?? []).concat(
        dashboardData?.restrictToTeam?.role === 'account_manager' ? dashboardData?.restrictToTeam?.users : []
    );
    const restrictToRecruiterIds = (dashboardData?.restrictToRecruiterIds ?? []).concat(
        dashboardData?.restrictToTeam?.role === 'recruiter' ? dashboardData?.restrictToTeam?.users : []
    );
    const { restrictToClientIds } = dashboardData;
    const whereClause: any = {
        disqualified: { _eq: false },
        job: { jobType: { _in: dashboardData.jobTypes }, clientId: { _nin: excludeClientIds }, status: { _neq: 3 } },
        stage: { _eq: stage }
    };

    if (restrictToAccountManagerIds?.length) {
        whereClause.accountManagerId = { _in: restrictToAccountManagerIds };
    }
    if (restrictToClientIds?.length) {
        whereClause.job.clientId = { ...whereClause.job.clientId, _in: restrictToClientIds };
    }
    if (restrictToRecruiterIds?.length) {
        whereClause.assignee = { _in: restrictToRecruiterIds };
    }

    const { data } = useQuery<StageCandidatesCount>(STAGE_CANDIDATES_COUNT, {
        pollInterval,
        variables: { where: whereClause }
    });
    return data?.stageCount?.aggregate.count;
};

const MetricsDashboardComponent: React.FC<{ toggleDrawer: () => void }> = (props) => {
    const theme = useTheme();
    const [mouseMovedTimeout, setMouseMovedTimeout] = React.useState<NodeJS.Timeout | undefined>(undefined);
    const [settingsDialogOpen, setSettingsDialogOpen] = React.useState(false);
    const {
        conversionDuration: { value: conversionDuration },
        monthDateRange,
        trendDuration: { value: trendDuration },
        yearDateRange
    } = useMetricsDashboardData();

    const handleMouseMove = () => {
        if (mouseMovedTimeout) {
            clearTimeout(mouseMovedTimeout);
        }
        const timeout = setTimeout(() => setMouseMovedTimeout(undefined), mouseMoveLimitMs);
        setMouseMovedTimeout(timeout);
    };

    const handleToggleSettingsDialog = () => setSettingsDialogOpen(!settingsDialogOpen);

    const finalRoundCount = useStageCount(clientFinalRoundStage);
    const offerCount = useStageCount(offerStage);
    const monthlyFees = useFeesData(monthDateRange);
    const yearlyFees = useFeesData(yearDateRange);

    const conversionDateRange = [conversionDuration.start, conversionDuration.end] as [number, number];
    const outreachConversion = useOutreachConversion(conversionDateRange);
    const submitAcceptConversion = useStageConversion(awaitingClientStage, clientFirstRoundStage, conversionDateRange);
    const finalRoundOfferConversion = useStageConversion(clientFinalRoundStage, offerStage, conversionDateRange);
    const offerHiredConversion = useStageConversion(offerStage, hiredStage, conversionDateRange);

    const baselineConversionDateRange = [conversionDuration.baselineStart, conversionDuration.baselineEnd] as [
        number,
        number
    ];
    const baselineOutreachConversion = useOutreachConversion(baselineConversionDateRange);
    const baselineSubmitAcceptConversion = useStageConversion(
        awaitingClientStage,
        clientFirstRoundStage,
        baselineConversionDateRange
    );
    const baselineFinalRoundOfferConversion = useStageConversion(
        clientFinalRoundStage,
        offerStage,
        baselineConversionDateRange
    );
    const baselineOfferHiredConversion = useStageConversion(offerStage, hiredStage, baselineConversionDateRange);

    const settingsDialog = settingsDialogOpen ? (
        <MetricsSettingsDialog open={settingsDialogOpen} onClose={handleToggleSettingsDialog} />
    ) : null;

    return (
        <div css={styles(theme)} onMouseMove={handleMouseMove}>
            <div className={`menu-button ${!!mouseMovedTimeout ? 'visible' : 'not-visible'}`}>
                <IconButton onClick={props.toggleDrawer}>
                    <Menu />
                </IconButton>
            </div>
            <div className={`settings-button ${!!mouseMovedTimeout ? 'visible' : 'not-visible'}`}>
                <IconButton onClick={handleToggleSettingsDialog}>
                    <Settings />
                </IconButton>
            </div>
            <NumberPanel title="Final Rounds" value={finalRoundCount} />
            <NumberPanel title="Offers" value={offerCount} />
            <NumberPanel title={`${moment().format('MMMM')} Billings`} value={monthlyFees} formatter={currencyFormat} />
            <NumberPanel title={`${moment().format('YYYY')} Billings`} value={yearlyFees} formatter={currencyFormat} />
            <div className="panel">
                <div className="panel-header">Submissions</div>
                <div className="panel-data chart">
                    <Trend kind="awaiting_client_feedback" duration={trendDuration} />
                </div>
            </div>
            <div className="panel">
                <div className="panel-header">Client Accepts</div>
                <div className="panel-data chart">
                    <Trend kind="client_first_round" duration={trendDuration} />
                </div>
            </div>
            <div className="panel">
                <div className="panel-header">Final Rounds</div>
                <div className="panel-data chart">
                    <Trend kind="client_second_round" duration={trendDuration} />
                </div>
            </div>
            <div className="panel">
                <div className="panel-header">Offers</div>
                <div className="panel-data chart">
                    <Trend kind="offer" duration={trendDuration} />
                </div>
            </div>
            <NumberPanel
                title="Outreach → Phone Call"
                value={outreachConversion}
                percent={true}
                baseline={baselineOutreachConversion}
            />
            <NumberPanel
                title="Submit → Client Accept"
                value={submitAcceptConversion}
                percent={true}
                baseline={baselineSubmitAcceptConversion}
            />
            <NumberPanel
                title="Final Round → Offer"
                value={finalRoundOfferConversion}
                percent={true}
                baseline={baselineFinalRoundOfferConversion}
            />
            <NumberPanel
                title="Offer → Hired"
                value={offerHiredConversion}
                percent={true}
                baseline={baselineOfferHiredConversion}
            />
            {settingsDialog}
        </div>
    );
};

interface ConnectedDispatch {
    toggleDrawer: () => void;
}

export const MetricsDashboard: React.FC = () => {
    const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
        toggleDrawer
    };
    const MetricsDashboardWithDrawerToggle = connect<{}, ConnectedDispatch, {}>(
        null,
        mapDispatchToProps
    )(MetricsDashboardComponent);
    return (
        <MetricsDashboardDataProvider>
            <MetricsDashboardWithDrawerToggle />
        </MetricsDashboardDataProvider>
    );
};
