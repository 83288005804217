import { TableCell, TableRow } from '@material-ui/core';
import { orderBy } from 'lodash';
import React from 'react';
import { awaitingAMApproval } from 'shared/models/job-stages';

import { Candidate } from '../../graphql/queries/home';
import { useSlides } from '../../hooks/use-candidate-slides';
import { useSession } from '../../hooks/use-session';
import { CandidateActivity } from './candidate-activity';
import { HomePageTable } from './home-page-table';

interface SubmissionsTableProps {
    candidates: Candidate[];
}

const rowsPerPage = 8;

const taskOwner = (data: Candidate) =>
    data.stage.name === awaitingAMApproval ? data.job.accountManager : data.assignee;

const CandidateTableRow: React.FC<{ data: Candidate; list: Candidate[] }> = ({ data, list }) => {
    const { setList } = useSlides();
    const { user } = useSession();
    const showAssigneeNameInSubmissions = user?.settings.homePage.showAssigneeNameInSubmissions;

    const handleSelect = (personId: string, jobId: string) => () => {
        const ids = list.map((c) => ({
            jobId: c.jobId,
            jobNotesOnly: true,
            notesKind: 'submission' as 'submission',
            personId: c.personId,
            selectedTab: 'notes'
        }));
        const selected = {
            jobId,
            jobNotesOnly: true,
            notesKind: 'submission' as 'submission',
            personId,
            selectedTab: 'notes'
        };
        setList(ids, selected);
    };

    return (
        <TableRow>
            <TableCell className="link" onClick={handleSelect(data.personId, data.jobId)}>
                {data.person.name}
            </TableCell>
            <TableCell>{data.job.client.name}</TableCell>
            <TableCell>{data.job.title}</TableCell>
            <TableCell>{data.stage.label}</TableCell>
            {showAssigneeNameInSubmissions ? <TableCell>{data.assignee.name}</TableCell> : null}
            <TableCell>{taskOwner(data).name}</TableCell>
            <TableCell>
                <CandidateActivity candidate={data} />
            </TableCell>
        </TableRow>
    );
};

export const SubmissionsTable: React.FC<SubmissionsTableProps> = ({ candidates }) => {
    const { user } = useSession();
    const showAssigneeNameInSubmissions = user?.settings.homePage.showAssigneeNameInSubmissions;

    const columns = [
        { title: 'Candidate', sortFunc: (c: Candidate) => c.person.name },
        { title: 'Client', sortFunc: (c: Candidate) => c.job.client.name },
        { title: 'Job', sortFunc: (c: Candidate) => c.job.title },
        { title: 'Stage', sortFunc: (c: Candidate) => c.stage.id, defaultDesc: true },
        { title: 'Assignee', sortFunc: (c: Candidate) => c.job.assignee },
        { title: 'Task Owner', sortFunc: (c: Candidate) => taskOwner(c).name },
        { title: 'Last Activity', sortFunc: (c: Candidate) => c.lastActivityAt, defaultDesc: true }
    ];

    const initialSortedRecords = orderBy(
        candidates,
        [(c) => taskOwner(c).id === user.id, (c) => c.stage.id, (c) => c.lastActivityAt, (c) => taskOwner(c).name],
        ['desc', 'desc', 'desc', 'asc']
    );

    return (
        <HomePageTable
            columns={showAssigneeNameInSubmissions ? columns : columns.filter((c) => c.title !== 'Assignee')}
            records={initialSortedRecords}
            RowComponent={CandidateTableRow}
            title="Submissions"
            sortKeyId="submissions"
            initialSort={{ column: 'Stage', ascending: false }}
            rowsPerPage={rowsPerPage}
        />
    );
};
