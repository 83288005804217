import { Button, Checkbox, Dialog, DialogActions, DialogContent, Tooltip } from '@material-ui/core';
import { Map } from 'immutable';
import * as _ from 'lodash';
import { CircularProgress, IconButton, TextField } from 'material-ui';
import { lightBlack, white } from 'material-ui/styles/colors';
import Search from 'material-ui/svg-icons/action/search';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { JobStageData } from 'shared/models/job-stages';

import { receivePersonSearchResult, searchForPerson } from '../actions';
import { Avatar } from '../sfc/avatar';
import { PersonSearchResults, RequestErrors, State } from '../state';

const enterKey = 'Enter';
const loadingSize = 32;
const loadingThickness = 2;

interface OwnProps {
    onSelect?: (selected: Array<{ personId: string; jobId: string }>) => void;
    prioritizeJobId?: string;
}

interface ConnectedProps {
    pendingRequests: Map<string, RequestErrors>;
    searchResults: PersonSearchResults;
    sessionUserId: string;
    jobStages: JobStageData[];
}

interface ConnectedDispatch {
    searchForPerson: (searchString: string) => void;
    receivePersonSearchResult: (searchResults: PersonSearchResults) => void;
}

type SearchBoxProps = OwnProps & ConnectedProps & ConnectedDispatch;

interface SearchBoxState {
    searchText: string;
    dialogOpen: boolean;
    selected: Array<{ personId: string; jobId: string }>;
}

class SearchBoxComponent extends React.Component<SearchBoxProps, SearchBoxState> {
    constructor(props: SearchBoxProps) {
        super(props);
        this.state = { dialogOpen: false, searchText: '', selected: this.props.onSelect ? [] : null };
    }

    handleSearchIconTap = () => {
        this.props.receivePersonSearchResult(null);
        this.setState({ dialogOpen: true });
    };

    handleDialogClose = () => {
        this.props.receivePersonSearchResult(null);
        this.setState({ dialogOpen: false, searchText: '' });
    };

    handleTextChange = (event: React.FormEvent<{}>, newValue: string) => {
        event.preventDefault();
        this.setState({ searchText: newValue });
        this.props.receivePersonSearchResult(null);
    };

    handleKeyPress = (e: React.KeyboardEvent<{}>) => {
        if (e.key === enterKey) {
            this.props.searchForPerson(this.state.searchText);
        }
    };

    handleSelect = (val: { personId: string; jobId: string }) => (event: { target: { checked: boolean } }) => {
        if (event.target.checked) {
            this.setState({
                selected: this.state.selected.concat(val)
            });
        } else {
            this.setState({
                selected: this.state.selected.filter((s) => s.jobId !== val.jobId && s.personId !== val.personId)
            });
        }
    };

    handleConfirm = () => {
        this.handleDialogClose();
        this.props.onSelect(this.state.selected);
    };

    getSortedSearchResults = () => {
        const { searchResults, prioritizeJobId } = this.props;

        if (!searchResults) return null;

        if (!prioritizeJobId) {
            return searchResults;
        } else {
            const hasCandidateInJob = searchResults.filter((r) => r.jobs.find((j) => j.id === prioritizeJobId));
            const other = _.differenceBy(searchResults, hasCandidateInJob, 'id');
            return hasCandidateInJob.concat(other);
        }
    };

    render() {
        const { dialogOpen, searchText } = this.state;
        const { pendingRequests, sessionUserId, jobStages } = this.props;
        const sortedSearchResults = this.getSortedSearchResults();
        const searching = pendingRequests.has('person-search');
        const icon = (
            <Tooltip title="Search for Candidate">
                <IconButton onClick={this.handleSearchIconTap}>
                    <Search color={white} />
                </IconButton>
            </Tooltip>
        );
        let results;
        let spinner;
        let count;
        if (searching) {
            spinner = (
                <div className="person-search-results person-search-results-loading">
                    <CircularProgress size={loadingSize} thickness={loadingThickness} />
                </div>
            );
        } else if (sortedSearchResults) {
            count = <div className="person-search-text-count">{sortedSearchResults.length} results</div>;
            const rows = _.orderBy(
                sortedSearchResults,
                [(record) => record.jobs.findIndex((j) => j.assignee === sessionUserId)],
                ['desc']
            ).map((record, i) => {
                const evenOdd = i % 2 === 0 ? 'even' : 'odd'; // tslint:disable-line:no-magic-numbers
                const jobs = _.orderBy(record.jobs, [(j) => j.assignee !== sessionUserId, 'disqualified']).map((j) => {
                    const body = (
                        <div className={`search-results-job ${j.disqualified ? 'disqualified' : ''}`}>
                            <div className="job-title">
                                <span className="list-centered-icon flex-wrap">
                                    <span>{j.client}</span>
                                    <i className="material-icons separator">chevron_right</i>
                                    {j.title}
                                </span>
                            </div>
                            <div className="stage">{jobStages.find((s) => s.name === j.stage).label}</div>
                        </div>
                    );

                    if (!this.props.onSelect) {
                        return (
                            <Link key={j.id} to={`/job/${j.id}/board/candidate/${record.id}`} target="_blank">
                                {body}
                            </Link>
                        );
                    } else {
                        return (
                            <div className="person-search-result-detail" key={j.id}>
                                {body}
                                <Checkbox
                                    color="primary"
                                    onChange={this.handleSelect({ personId: record.id, jobId: j.id })}
                                />
                            </div>
                        );
                    }
                });
                return (
                    <div key={record.id} className={`search-results-person ${evenOdd}`}>
                        <div className="avatar">
                            <Avatar entity={record} />
                        </div>
                        <div className="search-results-details">
                            <span className="search-results-details-name">{record.name.full}</span>
                            <div className="search-results-details-jobs">{jobs}</div>
                        </div>
                    </div>
                );
            });
            results = <div className="person-search-results person-search-results-scroll-container">{rows}</div>;
        }

        const actions =
            this.state.selected && sortedSearchResults?.length > 0 ? (
                <DialogActions classes={{ root: 'person-search-actions' }}>
                    <Button disabled={this.state.selected.length === 0} color="primary" onClick={this.handleConfirm}>
                        Confirm
                    </Button>
                </DialogActions>
            ) : null;

        const dialog = (
            <Dialog
                open={dialogOpen}
                onClose={this.handleDialogClose}
                classes={{ root: 'person-search-dialog' }}
                fullWidth={true}
            >
                <DialogContent classes={{ root: 'person-search-dialog-body' }}>
                    <div className="person-search-text">
                        <Search color={lightBlack} className="person-search-icon" />
                        <TextField
                            hintText="Search"
                            autoFocus={true}
                            underlineShow={false}
                            value={searchText}
                            onChange={this.handleTextChange}
                            className="person-search-text-field"
                            fullWidth={true}
                            onKeyPress={this.handleKeyPress}
                            disabled={searching}
                        />
                        {count}
                    </div>
                    {results}
                    {spinner}
                </DialogContent>
                {actions}
            </Dialog>
        );
        return (
            <div>
                {icon}
                {dialog}
            </div>
        );
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    jobStages: state.appConstants.constants.jobStages,
    pendingRequests: state.pendingRequests,
    searchResults: state.ui.searchResults,
    sessionUserId: state.session.user.id
});
const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    receivePersonSearchResult,
    searchForPerson
};
export const SearchBox = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(SearchBoxComponent);
