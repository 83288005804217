// cspell:ignore Accountmanagerid
import { gql } from '@apollo/client';

export const candidateFields = gql`
    fragment candidateFields on candidates {
        _id
        personId
        jobId
        disqualified
        assignee: userByAssignee {
            name(path: "full")
            id
        }
        person {
            id
            name(path: "full")
            profilePicUrl
        }
        pendingEmailsCount
        pendingEmails
        job {
            id
            title
            status
            client {
                id
                name
            }
            accountManager: userByAccountmanagerid {
                id
                name(path: "full")
            }
            assignee
            maxIdleDays
        }
        lastStageChangedAt
        lastActivityAt
        stage: job_stage {
            label
            name
            id
        }
    }
`;

export const permissionsFields = gql`
    fragment permissionsFields on permissions {
        recruiter
        email_account_admin
        scrum_master
        email_templates_editor
        settings_editor
        site_owner
        email_match_setter
        notes_editor
        submit_approver
        blacklist_editor
        can_impersonate
        email_reply_skip_admin
        can_reassign_candidate
        can_modify_restricted_scheduled_messages
        search_create_access
        purge_candidate_access
        parachute_access
        sourcer
        job_details_editor
        save_search_presets
        email_ignore_match_setter
        billing_admin
        job_creator
    }
`;
