import { css } from '@emotion/core';
import { MenuItem, Select } from '@material-ui/core';
import { Map } from 'immutable';
import React from 'react';
import { Redirect } from 'react-router-dom';

const styles = css`
    color: white;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    padding-bottom: 7px;
    margin-right: 15px;

    .MuiOutlinedInput-root:hover,
    .MuiOutlinedInput-notchedOutline {
        border: none;
    }

    .MuiSelect-select:focus {
        background: none;
    }

    .MuiSelect-icon {
        color: white;
    }
`;

const options = ['Details View', 'Funnel View'];
const routes: Map<string, string> = Map([
    ['Details View', '/jobs/details'],
    ['Funnel View', '/jobs/funnel']
] as Array<[string, string]>);

interface JobViewSelectorProps {
    current: string;
}

export const JobViewSelector: React.FC<JobViewSelectorProps> = ({ current }) => {
    const [redirectTo, setRedirectTo] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(options.indexOf(current));

    if (redirectTo) {
        if (current !== options[selectedIndex]) {
            return <Redirect to={routes.get(options[selectedIndex])} push={true} />;
        }
    }

    const menuItems = options.map((option, i) => (
        <MenuItem key={i} value={option}>
            {option}
        </MenuItem>
    ));

    const handleChange = (event: React.ChangeEvent<{ value: string }>) => {
        setSelectedIndex(options.indexOf(event.target.value));
        setRedirectTo(true);
    };

    return (
        <Select css={styles} value={options[selectedIndex]} onChange={handleChange} variant="outlined">
            {menuItems}
        </Select>
    );
};
