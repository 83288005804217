import { IconButton, Snackbar } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React, { createContext, FC, useContext, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { zIndexes } from '../common/css-variables';

const SnackbarContext = createContext<{
    setSnackbar: (message: string, action?: JSX.Element, hideDuration?: number, className?: string) => void;
}>(undefined);

const defaultAutoHideDurationMs = 6000;

const SnackbarProvider: FC = ({ children }) => {
    const layer = useRef<HTMLElement>();
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState<string>(undefined);
    const [action, setAction] = useState<JSX.Element>(undefined);
    const [className, setClassName] = useState('');
    const [autoHideDuration, setAutoHideDuration] = useState(defaultAutoHideDurationMs);

    useEffect(() => {
        const element = document.createElement('div');
        element.setAttribute('class', 'snackbar-layer');
        element.setAttribute('style', `z-index: ${zIndexes.snackBar}`);
        document.body.appendChild(element);
        layer.current = element;
        return () => {
            layer.current.remove();
        };
    }, []);

    const handleOpen = (msg: string, button?: JSX.Element, hideDuration?: number, cn?: string) => {
        setMessage(msg);
        setAction(button);
        setAutoHideDuration(hideDuration === undefined ? defaultAutoHideDurationMs : hideDuration);
        setOpen(true);
        setClassName(cn);
    };

    const handleClose = () => {
        setOpen(false);
        setAutoHideDuration(defaultAutoHideDurationMs);
        setMessage(undefined);
        setAction(undefined);
        setClassName('');
    };

    const setSnackbar = (msg: string, button?: JSX.Element, hideDuration?: number, cn?: string) => {
        handleClose();
        setTimeout(() => {
            handleOpen(msg, button, hideDuration, cn);
        }, 1);
    };

    const actionElement = open ? (
        <>
            {action}
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                <Close fontSize="small" />
            </IconButton>
        </>
    ) : null;

    const toaster = open ? (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            autoHideDuration={autoHideDuration}
            open={true}
            message={message}
            onClose={handleClose}
            action={actionElement}
            className={className}
        />
    ) : null;

    const portal = layer.current ? ReactDOM.createPortal(toaster, layer.current) : null;

    return (
        <SnackbarContext.Provider value={{ setSnackbar }}>
            {children}
            {portal}
        </SnackbarContext.Provider>
    );
};

const useSnackbar = () => useContext(SnackbarContext);

export { useSnackbar, SnackbarProvider };
