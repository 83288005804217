import { Dialog, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import * as React from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';

import { EnvHeader } from '../common/env-header';
import { PersonProfile } from '../containers/person';

interface RouterProps {
    id: string;
}

const PersonPageComponent: React.FC<RouteComponentProps<RouterProps>> = ({ match }) => {
    const [dialogOpen, setDialogOpen] = React.useState(true);

    const handleDialogClose = () => setDialogOpen(false);

    if (!dialogOpen) {
        return <Redirect to="/" push={true} />;
    }

    const menuButton = (
        <IconButton onClick={handleDialogClose}>
            <Close />
        </IconButton>
    );

    const content = (
        <div id="container" className="person-profile-page">
            <EnvHeader />
            <div id="content" className="flex-fill">
                <PersonProfile id={match.params.id} menuButton={menuButton} />
            </div>
        </div>
    );
    return (
        <Dialog fullScreen={true} open={dialogOpen} disableEnforceFocus={true}>
            {content}
        </Dialog>
    );
};

export const PersonPage = withRouter(PersonPageComponent);
