import { Tooltip } from '@material-ui/core';
import { RaisedButton } from 'material-ui';
import { AvSkipPrevious } from 'material-ui/svg-icons';
import * as React from 'react';
import { connect } from 'react-redux';

import { JobStageData } from 'shared/models/job-stages';

import { moveCandidateToStage } from '../actions';
import { Candidate, State } from '../state';

interface OwnProps {
    candidate: Candidate;
}

interface ConnectedProps {
    jobStages: JobStageData[];
}

interface ConnectedDispatch {
    moveCandidateToStage: (candidate: Candidate, stage: string) => void;
}

type MoveCandidateBackButtonProps = OwnProps & ConnectedProps & ConnectedDispatch;

const MoveCandidateBackButtonSFC = (props: MoveCandidateBackButtonProps) => {
    const currentStage = props.jobStages.find((stage) => stage.name === props.candidate.stage);
    const prevStage = props.jobStages.find((stage) => stage.name === currentStage.prevStage);

    const handleMoveBackward = () => {
        const { candidate } = props;
        props.moveCandidateToStage(candidate, prevStage.name);
    };

    return (
        <div className="candidate-move-button-container candidate-move-button-container-small">
            <Tooltip title={prevStage ? `Move back to ${prevStage.label}` : 'Cannot move back'}>
                <span>
                    <RaisedButton
                        icon={<AvSkipPrevious />}
                        primary={true}
                        disabled={!prevStage}
                        className="candidate-move-button"
                        onClick={prevStage ? handleMoveBackward : null}
                    />
                </span>
            </Tooltip>
        </div>
    );
};

const mapStateToProps = (state: State): ConnectedProps => ({
    jobStages: state.appConstants.constants.jobStages
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = { moveCandidateToStage };

export const MoveCandidateBackButton = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(MoveCandidateBackButtonSFC);
