import { css } from '@emotion/core';
import { Button, List, Theme, Typography, useTheme } from '@material-ui/core';
import React from 'react';

import { jobStatusMentions } from 'shared/models/user-mention';

import { useNotificationsData } from '../../hooks/use-notifications-data';
import { useUserContext } from '../../hooks/use-user-context';
import { HomePagePanel } from './home-page-panel';
import { Mention } from './mention';

const contentStyle = (theme: Theme) => css`
    .tabs {
        display: flex;
        border-bottom: thin solid ${theme.palette.divider};

        .tab {
            flex: 1 1 auto;
            border-radius: 0;
            font-size: 0.75rem;
            font-weight: 500;
            letter-spacing: 0.08333em;
            opacity: 0.6;
            padding: 8px;

            &.active {
                opacity: 1;
            }

            border-left: thin solid ${theme.palette.divider};

            &:first-of-type {
                border-left: none;
            }
        }
    }

    .no-mentions {
        text-align: center;
        padding: 20px;
    }
`;

const Content: React.FC<{
    rows: JSX.Element[];
    view: 'sent' | 'received';
    setView: (view: 'sent' | 'received') => void;
}> = ({ rows, view, setView }) => {
    const theme = useTheme();

    const handleTabClick = (tab: 'sent' | 'received') => () => {
        setView(tab);
    };

    const content =
        rows.length === 0 ? (
            <div className="no-mentions">
                <Typography variant="overline" color="textSecondary">
                    No Pending Mentions
                </Typography>
            </div>
        ) : (
            <List disablePadding={true}>{rows}</List>
        );

    return (
        <div css={contentStyle(theme)}>
            <div className="tabs">
                <Button className={`tab ${view === 'received' ? 'active' : ''}`} onClick={handleTabClick('received')}>
                    Received
                </Button>
                <Button className={`tab ${view === 'sent' ? 'active' : ''}`} onClick={handleTabClick('sent')}>
                    Sent
                </Button>
            </div>
            {content}
        </div>
    );
};

export const UserMentions: React.FC = () => {
    const { user } = useUserContext();
    const { mentions: allMentions } = useNotificationsData();
    const [view, setView] = React.useState<'sent' | 'received'>('received');

    const nonJobStatusMentions = allMentions?.filter((m) => !jobStatusMentions.includes(m.kind));

    const mentions = nonJobStatusMentions?.filter((m) => {
        return (view === 'received' && m.recipient.id === user.id) || (view === 'sent' && m.sender.id === user.id);
    });

    return (
        <HomePagePanel
            RowComponent={Mention}
            ContentComponent={Content}
            contentProps={{ view, setView }}
            rowProps={{ view }}
            records={mentions}
            title="Mentions"
            containerClass="list"
            collapseKeyId="mentions"
            showIfEmpty={nonJobStatusMentions?.length > mentions?.length}
        />
    );
};
