import { IconButton, Snackbar } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import * as React from 'react';
import { connect } from 'react-redux';

import { requestToasterClose } from '../actions';
import { RenderAppendedToBody } from '../sfc/render-appended-to-body';
import { State } from '../state';

interface ConnectedProps {
    autoHideDuration: number;
    message: string;
    open: boolean;
}

interface ConnectedDispatch {
    requestToasterClose: () => void;
}

type ToasterProps = ConnectedProps & ConnectedDispatch;

class ToasterComponent extends React.Component<ToasterProps, { timer: number }> {
    constructor(props: ToasterProps) {
        super(props);
        this.state = { timer: null };
    }

    componentDidUpdate(prevProps: ToasterProps) {
        if (!prevProps.open && this.props.open) {
            let updatedTimer = this.state.timer;
            if (this.state.timer) {
                window.clearTimeout(this.state.timer);
                updatedTimer = null;
            }
            if (this.props.autoHideDuration) {
                updatedTimer = window.setTimeout(() => {
                    this.props.requestToasterClose();
                }, this.props.autoHideDuration);
            }
            this.setState({ timer: updatedTimer });
        }
    }

    render() {
        const { message, open } = this.props;
        const actionElement = open ? (
            <IconButton size="small" aria-label="close" color="inherit" onClick={this.props.requestToasterClose}>
                <Close fontSize="small" />
            </IconButton>
        ) : null;
        return open ? (
            <RenderAppendedToBody>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    autoHideDuration={this.props.autoHideDuration}
                    open={true}
                    message={message}
                    action={actionElement}
                    onClose={this.props.requestToasterClose}
                />
            </RenderAppendedToBody>
        ) : null;
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    autoHideDuration: state.toast.autoHideDuration,
    message: state.toast.message,
    open: state.toast.open
});
const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    requestToasterClose
};
export const Toaster = connect<ConnectedProps, ConnectedDispatch, {}>(
    mapStateToProps,
    mapDispatchToProps
)(ToasterComponent);
