import * as React from 'react';

import { JobStageData } from 'shared/models/job-stages';

import { CrossAddCandidate } from '../components/cross-add-candidate';
import { DisqualifyCandidateButton } from '../components/disqualify-candidate-button';
import { MoveCandidateForwardButton } from '../components/move-candidate-forward-button';
import { RequalifyButton } from '../components/requalify-candidate';
import { MoveCandidateBackButton } from '../containers/move-candidate-back-button';
import { IconLabel } from '../core-ui/icon-label';
import { Candidate } from '../state';

export const CandidateActions = (props: { candidate: Candidate; jobStages: JobStageData[] }) => {
    const { candidate, jobStages } = props;

    const buttons = candidate.disqualified
        ? [
              <RequalifyButton candidate={candidate} key="requalify" />,
              <CrossAddCandidate candidate={candidate} key="cross-add" />
          ]
        : [
              <MoveCandidateBackButton candidate={candidate} key="back" />,
              <MoveCandidateForwardButton candidate={candidate} key="forward" />,
              <CrossAddCandidate candidate={candidate} key="cross-add" />,
              <DisqualifyCandidateButton candidate={candidate} key="disqualify" />
          ];

    return (
        <div className="action-buttons">
            <div className="candidate-stage-info">
                <IconLabel icon="dashboard">
                    <span className="candidate-stage-name-label">
                        {jobStages.find((s) => s.name === candidate.stage).label}
                    </span>
                </IconLabel>
            </div>
            <div className="candidate-stage-move-actions">{buttons}</div>
        </div>
    );
};
