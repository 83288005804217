import { Map, OrderedSet } from 'immutable';

import { Action, ReceiveUserActions } from '../actions';
import { UserAction } from '../state';

const initialState: Map<string, OrderedSet<UserAction>> = Map();

export function userActions(state = initialState, action: Action) {
    switch (action.type) {
        case ReceiveUserActions: {
            return state.set(action.payload.target, OrderedSet(action.payload.userActions));
        }
        default:
            return state;
    }
}
