import * as React from 'react';

import { SearchConfigEducation } from 'shared/models/search';

import { RadioButtons } from './search-radio-buttons';
import { SearchRequirementsSection } from './search-requirements-section';
import { useSearch } from './use-search';

export const SearchEducationBootcamp: React.FC = () => {
    const searchContext = useSearch();
    const config = searchContext.data.config.education;
    const onChange = searchContext.onConfigFieldChange('education');
    const readonly = searchContext.readonly;
    const configRef = React.useRef(config);
    configRef.current = config;

    if (!config) return null;

    const { bootcampOk } = config;

    const handleUpdate =
        <T extends keyof SearchConfigEducation>(field: T) =>
        (val: SearchConfigEducation[T]) => {
            const updated = Object.assign({}, configRef.current, { [field]: val });
            onChange(updated);
        };

    const bootcampOptions = [
        { value: false, label: 'Exclude' },
        { value: true, label: 'Include' }
    ];

    return (
        <SearchRequirementsSection section="education-bootcamp">
            <div className="search-requirements-field">
                <label className="search-label">Candidates with Bootcamp</label>
                <div className="search-requirements-value radio-buttons">
                    <RadioButtons
                        readonly={readonly}
                        name="bootcampSwitch"
                        selected={bootcampOk}
                        options={bootcampOptions}
                        onChange={handleUpdate('bootcampOk')}
                    />
                </div>
            </div>
        </SearchRequirementsSection>
    );
};
