import { Map } from 'immutable';
import { Dialog, FlatButton, IconButton, TextField } from 'material-ui';
import * as React from 'react';
import { connect } from 'react-redux';

import { updatePerson } from '../actions';
import { List, Person, PersonDetails, RequestErrors, State } from '../state';

interface OwnProps {
    personId: string;
}

interface ConnectedProps {
    pendingRequests: Map<string, RequestErrors>;
    persons: List<PersonDetails>;
}

interface ConnectedDispatch {
    updatePerson: (id: string, updates: Partial<Person>) => void;
}

type CandidateRemindersProps = ConnectedProps & ConnectedDispatch & OwnProps;

interface PersonNameState {
    first: string;
    full: string;
    last: string;
    middle: string;
}

const PersonNameEditComponent: React.FC<CandidateRemindersProps> = (props) => {
    const { personId, pendingRequests, persons } = props;
    const personDetail = persons.list.get(personId);
    const { first, full, last } = personDetail.person.name;
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [name, setName] = React.useState<PersonNameState>({ first: '', middle: '', last: '', full: '' });

    const handleDialogOpen = () => {
        const middle = (full.replace(new RegExp(`^${first}`), '').replace(new RegExp(`${last}$`), '') ?? '').trim();
        setName({ first, full, last, middle });
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const setNameText = (field: keyof PersonNameState) => (_1: any, value: string) => {
        const newName = { ...name, [field]: value };
        const newFull = `${newName.first} ${newName.middle} ${newName.last}`.trim().replace(/\s+/g, ' ');
        newName.full = newFull;
        setName(newName);
    };

    const handleUpdateName = () => {
        const updatedName = {
            edited: true,
            first: name.first.trim().replace(/\s+/g, ' '),
            full: name.full,
            last: name.last.trim().replace(/\s+/g, ' ')
        };
        props.updatePerson(personId, { name: updatedName });
        handleDialogClose();
    };

    const inProgress = pendingRequests.has(`person-update-request-${personId}`);
    let dialog;
    if (dialogOpen) {
        const disabled = personDetail.person.name.full === name.full || !name.first || !name.last;
        const actions = [
            <FlatButton label="Cancel" secondary={true} onClick={handleDialogClose} key="cancel" />,
            <FlatButton label="Save" primary={true} onClick={handleUpdateName} key="save" disabled={disabled} />
        ];
        dialog = (
            <Dialog open={true} title="Update Person Name" actions={actions}>
                <div className="person-name-edit">
                    <TextField
                        value={name.first}
                        floatingLabelText="First"
                        onChange={setNameText('first')}
                        name="person-first-name-text-field"
                        fullWidth={true}
                        disabled={inProgress}
                        autoComplete="off"
                        className="name-field"
                    />
                    <TextField
                        value={name.middle}
                        floatingLabelText="Middle"
                        onChange={setNameText('middle')}
                        name="person-middle-name-text-field"
                        fullWidth={true}
                        disabled={inProgress}
                        autoComplete="off"
                        className="name-field"
                    />
                    <TextField
                        value={name.last}
                        floatingLabelText="Last"
                        onChange={setNameText('last')}
                        name="person-last-name-text-field"
                        fullWidth={true}
                        disabled={inProgress}
                        autoComplete="off"
                        className="name-field"
                    />
                </div>
            </Dialog>
        );
    }

    return (
        <div className="header-candidate-edit-name">
            <IconButton iconStyle={{ color: 'white' }} onClick={handleDialogOpen}>
                <i className="material-icons">edit</i>
            </IconButton>
            {dialog}
        </div>
    );
};

const mapStateToProps = (state: State): ConnectedProps => ({
    pendingRequests: state.pendingRequests,
    persons: state.personsDetails
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    updatePerson
};

export const PersonNameEdit = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(PersonNameEditComponent);
