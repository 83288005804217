import { useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Avatar,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    TextField,
    Typography
} from '@material-ui/core';
import { orderBy } from 'lodash';
import React from 'react';

import { hasRole } from 'shared/models/user';

import { ALLOCATIONS_BY_JOB, CREATE_ALLOCATION, JobAllocations } from '../graphql/queries/allocations';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';

interface AddRecruiterAllocationsButtonProps {
    user: {
        id: string;
        name?: string;
        target: number;
        allocated: number;
    };
    onSave?: () => void;
}

const dialogStyles = css`
    .form-row {
        display: flex;
        justify-content: space-between;

        .form-col-user {
            flex: 1 1 auto;
            margin-right: 40px;
        }

        .form-col-target {
            flex: 0 0 100px;
        }
    }
`;

export const AddRecruiterAllocationsButton: React.FC<AddRecruiterAllocationsButtonProps> = ({ user, onSave }) => {
    const [adding, setAdding] = React.useState(false);
    const { data } = useQuery<JobAllocations>(ALLOCATIONS_BY_JOB);
    const [createAllocation] = useMutation<{}, { jobId: string; userId: string; acceptsGoal: number }>(
        CREATE_ALLOCATION
    );
    const { user: sessionUser, userPermissions } = useSession();
    const { setSnackbar } = useSnackbar();
    const [selectedJobId, setSelectedJobId] = React.useState<string | null>(null);
    const [selectedJobAcceptsGoal, setSelectedJobAcceptsGoal] = React.useState<number | null>(null);
    const [saving, setSaving] = React.useState(false);

    const handleToggleAdding = () => {
        setAdding(!adding);
    };

    const handleAddAllocation = async () => {
        setSaving(true);
        setAdding(false);
        setSnackbar('Adding allocation');
        await createAllocation({
            variables: {
                acceptsGoal: selectedJobAcceptsGoal!,
                jobId: selectedJobId!,
                userId: user.id
            }
        });
        setSnackbar('Allocation added');
        setSaving(false);
        setSelectedJobAcceptsGoal(null);
        setSelectedJobId(null);
        onSave?.();
    };

    const handleSelectJob = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedJobId(e.target.value);
        setSelectedJobAcceptsGoal(null);
    };

    const handleAcceptsGoalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedJobAcceptsGoal(parseInt(e.target.value, 10));
    };

    const jobs = data?.jobs.filter(
        (job) => job.accountManagerId === sessionUser.id || hasRole(userPermissions, 'recruiter_funnel_target_editor')
    );

    if (!user?.id || !data || jobs.length === 0) return null;

    const dialogTitle = user.name ? `Add Allocation for ${user.name}` : 'Add Allocation';
    const jobOpts = orderBy(
        jobs.filter((j) => !j.allocations.some((a) => a.user.id === user.id)),
        [
            (j) => (j?.target?.goal ?? 0) - (j?.allocations_aggregate?.aggregate?.sum?.acceptsGoal ?? 0) <= 0,
            (j) => `${j.client.name} - ${j.title}`
        ],
        ['asc', 'asc']
    ).map((j) => {
        const need = (j?.target?.goal ?? 0) - (j?.allocations_aggregate?.aggregate?.sum?.acceptsGoal ?? 0);
        return (
            <MenuItem key={j.id} value={j.id} disabled={need <= 0}>
                {j.client.name} - {j.title}
            </MenuItem>
        );
    });
    const userCapacity = user.target - user.allocated;
    const selectedJob = jobs.find((j) => j.id === selectedJobId);
    const acceptsOpts = [];
    let helperText = '';
    if (selectedJob) {
        const selectedJobTarget = selectedJob?.target?.goal ?? 0;
        const selectedJobAllocated = selectedJob?.allocations_aggregate?.aggregate?.sum?.acceptsGoal ?? 0;
        const selectedJobNeed = selectedJobTarget - selectedJobAllocated;
        const selectedJobLast14Days = selectedJob?.metrics?.accepts ?? 0;
        for (let i = 1; i <= Math.min(userCapacity, selectedJobNeed); i++) {
            acceptsOpts.push(
                <MenuItem key={i} value={i}>
                    {i}
                </MenuItem>
            );
        }
        helperText = `Needed allocation: ${selectedJobNeed} (Last 14 days: ${selectedJobLast14Days} accepts, target ${selectedJobTarget})`;
    }
    const saveDisabled = saving || !selectedJobId || !selectedJobAcceptsGoal || selectedJobAcceptsGoal <= 0;

    const addingDialog = (
        <Dialog open={adding} onClose={handleToggleAdding} maxWidth="md" fullWidth={true} css={dialogStyles}>
            <DialogTitle>
                <Typography variant="h4" component="div">
                    {dialogTitle}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <div className="form-row">
                    <div className="form-col-user">
                        <TextField
                            label="Job"
                            select={true}
                            fullWidth={true}
                            onChange={handleSelectJob}
                            helperText={helperText}
                            value={selectedJobId ?? ''}
                        >
                            {jobOpts}
                        </TextField>
                    </div>
                    <div className="form-col-target">
                        <TextField
                            label="Target"
                            select={true}
                            fullWidth={true}
                            onChange={handleAcceptsGoalChange}
                            value={selectedJobAcceptsGoal ?? ''}
                        >
                            {acceptsOpts}
                        </TextField>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleToggleAdding}>Cancel</Button>
                <Button onClick={handleAddAllocation} disabled={saveDisabled}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <>
            <Chip
                className="add-allocation-chip"
                key="add"
                label="Add"
                variant="outlined"
                avatar={<Avatar>+</Avatar>}
                onClick={handleToggleAdding}
            />
            {addingDialog}
        </>
    );
};
