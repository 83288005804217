import { gql } from '@apollo/client';
import { JobFilter } from 'shared/models/job';
import { Filters } from 'shared/types/candidates-search';

import { permissionsFields } from './common';
export interface User {
    id: string;
    email: string;
    name: { first: string; last: string; full: string };
    profilePicUrl: string;
    username: string;
    maxCallsPerDay: number;
    calendarLink: string;
    calendlyToken: string;
    signature: string;
    features: number;
    settings: {
        homePage: {
            rowsPerPage: number;
            showAssigneeNameInSubmissions: boolean;
        };
        emailSendDelayMs: number;
    };
    jobsFilterPresets: Array<{ name: string; values: JobFilter[] }>;
    candidatesSearchFilters: Array<{
        name: string;
        filters: Filters;
    }>;
}

export type ScrumUserData = User & {
    targets: Array<{
        goal: number;
    }>;
    scrumLeads: Array<{
        leadUserId: string;
    }>;
};

export interface UserDataModificationTimestamps {
    id: string;
    lastCandidateModifiedAt: number;
    lastReminderModifiedAt: number;
    lastMentionModifiedAt: number;
    lastJobModifiedAt: number;
    lastNotificationModifiedAt: number;
}

export interface RecruiterFunnelTargetData {
    targets: Array<{
        stage: string;
        goal: number;
    }>;
    leads: Array<{ leadUserId: string }>;
    metrics: Array<{ count: number }>;
    allocations: Array<{
        acceptsGoal: number;
        userId: string;
        job: {
            id: string;
            title: string;
            accountManagerId: string;
            client: {
                id: string;
                name: string;
            };
        };
    }>;
}

const userFields = gql`
    fragment userFields on users {
        id
        email
        name
        profilePicUrl
        username
        maxCallsPerDay
        calendarLink
        calendlyToken
        signature
        settings
        features
        jobsFilterPresets
        candidatesSearchFilters
    }
`;

export const USER_BY_USERNAME = gql`
    ${userFields}
    ${permissionsFields}
    query User($username: String!) {
        users(where: { username: { _eq: $username } }) {
            ...userFields
        }
        userPermissions: permissions(where: { user: { username: { _eq: $username } } }) {
            ...permissionsFields
        }
    }
`;

export const ACTIVE_USERS = gql`
    ${userFields}
    query UsersQuery {
        users(where: { status: { _eq: "active" } }, order_by: { name: asc }) {
            ...userFields
            targets(where: { stage: { _eq: "client_first_round" } }) {
                goal
            }
            scrumLeads {
                leadUserId
            }
        }
    }
`;

export const SCRUM_USERS = gql`
    ${userFields}
    query ScrumUsersQuery($lead: String!) {
        users: scrum_users(
            where: { leadUserId: { _eq: $lead }, user: { status: { _eq: "active" } } }
            order_by: { user: { name: asc } }
        ) {
            user {
                ...userFields
                targets(where: { stage: { _eq: "client_first_round" } }) {
                    goal
                }
                scrumLeads {
                    leadUserId
                }
            }
        }
    }
`;

export const EDIT_USER = gql`
    mutation EditUser($userId: String!, $updates: users_set_input!) {
        update_users(where: { id: { _eq: $userId } }, _set: $updates) {
            affected_rows
        }
    }
`;

export const DAILY_ACTIVITY = gql`
    query DailyActivity($userId: String!, $startTime: bigint, $endTime: bigint) {
        activity: daily_user_activity(args: { userId: $userId, startTime: $startTime, endTime: $endTime }) {
            emailsPending
            emailsArchivedPending
            rrPending
            screenScheduledElapsedPending
            screenCompletePending
            submitApprovedPending
            remindersPending
            mentionsPending
            upcomingCalls
            searches
            sourcing
            outreachesSent
            messagesSent
            rocketScreensCompleted
            amSubmits
            clientSubmits
            clientAccepts
            movedToFinalRound
            movedToOffer
            finalRounds
            currentOffers
            hires
        }
    }
`;

export const USER_DATA_MODIFICATION_TS = gql`
    subscription DataModificationTs($userId: String!) {
        user: users_by_pk(id: $userId) {
            id
            lastJobModifiedAt
            lastCandidateModifiedAt
            lastMentionModifiedAt
            lastReminderModifiedAt
            lastNotificationModifiedAt
        }
    }
`;

export const UPDATE_USER = gql`
    mutation UpdateUser($id: String!, $updates: users_set_input!) {
        update_users_by_pk(pk_columns: { id: $id }, _set: $updates) {
            id
        }
    }
`;

export const RECRUITER_FUNNEL_TARGETS = gql`
    query RecruiterFunnelTargets($username: String!) {
        targets: recruiter_14d_funnel_target(
            where: { username: { _eq: $username }, stage: { _eq: "client_first_round" } }
        ) {
            stage
            goal
        }
        metrics: recruiter_last_14d_funnel(
            where: { username: { _eq: $username }, stage: { _eq: "client_first_round" } }
        ) {
            count
        }
        leads: scrum_users(where: { user: { username: { _eq: $username } } }) {
            leadUserId
        }
        allocations: job_allocations(
            where: { user: { username: { _eq: $username } } }
            order_by: [{ job: { client: { name: asc } } }, { job: { title: asc } }]
        ) {
            acceptsGoal
            userId
            job {
                id
                title
                accountManagerId
                client {
                    id
                    name
                }
            }
        }
    }
`;

export const UPDATE_RECRUITER_FUNNEL_DATA = gql`
    mutation UpdateRecruiterFunnelTargets(
        $username: String!
        $stage: String!
        $goal: Int!
        $allocations: [job_allocations_insert_input!]!
    ) {
        update_recruiter_14d_funnel_target_by_pk(
            pk_columns: { username: $username, stage: $stage }
            _set: { goal: $goal }
        ) {
            goal
        }
        delete_job_allocations(where: { user: { username: { _eq: $username } } }) {
            affected_rows
        }
        insert_job_allocations(
            objects: $allocations
            on_conflict: { constraint: job_allocations_pkey, update_columns: [] }
        ) {
            affected_rows
        }
    }
`;
