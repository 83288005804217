import { useLazyQuery, useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import { Theme, useTheme } from '@material-ui/core';
import countries from 'i18n-iso-countries';
import _ from 'lodash';
import React from 'react';

import {
    CBCities,
    CBCountries,
    CBRegions,
    CRUNCHBASE_CITIES,
    CRUNCHBASE_COUNTRIES,
    CRUNCHBASE_REGIONS
} from '../../graphql/queries/crunchbase';
import { MultiSelectForm } from './multi-select-form';

// tslint:disable-next-line: no-var-requires
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

interface LocationFormProps {
    readonly: boolean;
    filters: { countries?: string[]; regions?: string[]; cities?: string[] };
    onChange: (val: { countries?: string[]; regions?: string[]; cities?: string[] }) => void;
}

const styles = (theme: Theme) => css`
    display: flex;
    align-items: center;
    border-top: thin solid ${theme.palette.divider};

    .filter-field {
        flex: 1 1 auto;
        width: 30%;
        border-top: none;

        &.country-field {
            width: 40%;
        }
    }

    .filter-field:first-child {
        padding-top: 35px;
    }
`;

export const LocationForm: React.FC<LocationFormProps> = ({ readonly, filters, onChange }) => {
    const theme = useTheme();
    const { data: countryOptionsData } = useQuery<{ country: CBCountries[] }>(CRUNCHBASE_COUNTRIES);
    const [fetchRegions, { called: loadRegionsCalled }] = useLazyQuery<{ regions: CBRegions[] }>(CRUNCHBASE_REGIONS);
    const [fetchCities, { called: loadCitiesCalled }] = useLazyQuery<{ cities: CBCities[] }>(CRUNCHBASE_CITIES);
    const [regionsData, setRegionsData] = React.useState<{ regions: CBRegions[] }>(undefined);
    const [citiesData, setCitiesData] = React.useState<{ cities: CBCities[] }>(undefined);

    const selectedCountries = filters?.countries;
    const selectedRegions = _.uniq(filters?.regions);
    const selectedCities = _.uniq(filters?.cities);

    React.useEffect(() => {
        if (selectedCountries?.length > 0) {
            if (regionsData === undefined) {
                fetchRegions({
                    variables: { where: { country_code: { _in: selectedCountries } } }
                }).then((result) => setRegionsData(result.data));
            }
        } else {
            setRegionsData(undefined);
            setCitiesData(undefined);
        }
        if (selectedRegions?.length > 0) {
            if (citiesData === undefined) {
                fetchCities({
                    variables: { where: { country_code: { _in: selectedCountries }, region: { _in: selectedRegions } } }
                }).then((result) => setCitiesData(result.data));
            }
        } else {
            setCitiesData(undefined);
        }
    }, [filters]);

    React.useEffect(() => {
        if (loadRegionsCalled) {
            const regions = _.intersection(filters?.regions ?? [], regionsData?.regions.map((i) => i.region) ?? []);
            if (!_.isEqual(regions, filters?.regions ?? [])) {
                onChange({ ...filters, regions });
            }
        }
    }, [regionsData]);

    React.useEffect(() => {
        if (loadCitiesCalled) {
            const cities = _.intersection(filters?.cities ?? [], citiesData?.cities.map((i) => i.city) ?? []);
            if (!_.isEqual(cities, filters?.cities ?? [])) {
                onChange({ ...filters, cities });
            }
        }
    }, [citiesData]);

    const getCountryNameFromCode = (code: string) => countries.getName(code, 'en') ?? 'Unknown';

    const countryOptions = _.uniq(
        _.sortBy(countryOptionsData?.country.map((i) => i.countryCode ?? 'Unknown') ?? [], getCountryNameFromCode)
    );

    const handleCountryChange = (list: string[]) => {
        onChange({ ...filters, countries: list });
    };

    const handleRegionChange = (list: string[]) => {
        onChange({ ...filters, regions: list });
    };

    const handleCityChange = (list: string[]) => {
        onChange({ ...filters, cities: list });
    };

    return (
        <div css={styles(theme)}>
            <MultiSelectForm
                readonly={readonly}
                filters={selectedCountries}
                label="Country"
                options={countryOptions}
                renderOption={getCountryNameFromCode}
                onChange={handleCountryChange}
                className="country-field"
            />
            <MultiSelectForm
                readonly={readonly || regionsData === undefined}
                filters={selectedRegions}
                label="Region"
                options={_.sortBy(regionsData?.regions.map((i) => i.region) ?? [])}
                onChange={handleRegionChange}
            />
            <MultiSelectForm
                readonly={readonly || citiesData === undefined}
                filters={selectedCities}
                label="Cities"
                options={_.sortBy(citiesData?.cities.map((i) => i.city) ?? [])}
                onChange={handleCityChange}
            />
        </div>
    );
};
