import { useMutation, useQuery, useSubscription } from '@apollo/client';
import React from 'react';
import DocumentTitle from 'react-document-title';

import { client as apolloClient } from '../graphql/apollo-client';
import { CLIENT_FORM_DATA, CLIENT_MODIFIED_AT, CLIENT_UPDATE, ClientFormData } from '../graphql/queries/clients';
import { ClientForm } from './client-form';

import { ClientPageHeader } from './client-page-header';

export const ClientSettings: React.FC<{ clientId: string }> = ({ clientId }) => {
    const { data, refetch } = useQuery<{ client: ClientFormData }, { clientId: string }>(CLIENT_FORM_DATA, {
        variables: { clientId }
    });
    const { data: modifiedSubData } = useSubscription<{ client: { modifiedAt: number } }, { clientId: string }>(
        CLIENT_MODIFIED_AT,
        { variables: { clientId } }
    );
    const [updateClient] = useMutation<{}, { updates: Omit<ClientFormData, 'id' | '__typename'>; clientId: string }>(
        CLIENT_UPDATE,
        {
            client: apolloClient('client_creator')
        }
    );

    React.useEffect(() => {
        if (modifiedSubData?.client?.modifiedAt) {
            refetch();
        }
    }, [modifiedSubData?.client?.modifiedAt]);

    const handleSave = async (updates: ClientFormData) => {
        const { id, __typename, ...updatesWithoutId } = updates;
        await updateClient({ variables: { updates: updatesWithoutId, clientId } });
        refetch();
    };

    const content = data ? <ClientForm data={data?.client} onSave={handleSave} /> : null;

    return (
        <DocumentTitle title="Settings">
            <div id="container">
                <ClientPageHeader clientId={clientId} actions={[]} activeTab="Settings" />
                <div id="content" className="flex-fill">
                    {content}
                </div>
            </div>
        </DocumentTitle>
    );
};
