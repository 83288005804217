import { useQuery } from '@apollo/client';
import { MenuItem, Select, useTheme } from '@material-ui/core';
import React from 'react';
import DocumentTitle from 'react-document-title';

import { Permissions } from 'shared/models/permission';

import { Duration, getDurationOptions } from '../../common/duration';
import { funnelViewMetrics } from '../../common/metrics';
import { isoDate } from '../../common/timestamp';
import { Loading } from '../../core-ui/loading';
import { MetricsData, USER_METRICS } from '../../graphql/queries/metrics';
import { User, USER_BY_USERNAME } from '../../graphql/queries/user';
import { useLocalStorage } from '../../hooks/use-local-storage';
import { UserContextProvider } from '../../hooks/use-user-context';
import { internalClientIds as excludeClientIds, JobFilterTypes, JobType, jobTypesForFilter } from '../../state';
import { DurationSelector } from '../duration-selector';
import { Header } from '../header';
import { JobsTypeFilter } from '../jobs-type-filter';
import { FunnelMetricsChart } from './funnel-metrics-chart';
import { FunnelMetricsTimeline } from './funnel-metrics-timeline';
import { styles } from './styles';
import { UserClientMetrics } from './user-client-metrics';

type ViewType = 'client-activity' | 'funnel' | 'timeline';

export const UserMetrics: React.FC<{ username: string }> = ({ username }) => {
    const theme = useTheme();

    const { data: userData } = useQuery<{ users: User[]; userPermissions: Permissions[] }, { username: string }>(
        USER_BY_USERNAME,
        {
            variables: { username }
        }
    );

    const durationPeriods = { days: 2, weeks: 2, months: 2, quarters: 4, years: 2, allTime: true };
    const durationOpts = getDurationOptions(durationPeriods);
    const defaultOption = durationOpts.find((opt) => opt.label === 'This Week');
    const [duration, setDuration] = useLocalStorage<Duration>('metrics-duration', defaultOption);
    const [jobTypes, setJobTypes] = useLocalStorage<JobFilterTypes>('metrics-job-types', 'placement-fees');
    const [viewType, setViewType] = useLocalStorage<ViewType>('metrics-view-type', 'client-activity');

    const handleViewChange = (event: React.ChangeEvent<{ value: ViewType }>) => {
        setViewType(event.target.value);
    };

    const { data } = useQuery<
        { metrics: MetricsData[] },
        {
            userId: string;
            kinds: string[];
            jobTypes: JobType[];
            excludeClientIds: string[];
            startDate: string;
            endDate: string;
        }
    >(USER_METRICS, {
        skip: !userData,
        variables: {
            endDate: isoDate(duration.end),
            excludeClientIds,
            jobTypes: jobTypesForFilter.get(jobTypes),
            kinds: funnelViewMetrics,
            startDate: isoDate(duration.start),
            userId: userData?.users[0]?.id
        }
    });

    if (!userData) {
        return <Loading />;
    }

    const user = userData.users[0];
    const userPermissions = userData.userPermissions[0];

    const content =
        viewType === 'client-activity' ? (
            <UserClientMetrics metrics={data?.metrics} />
        ) : viewType === 'funnel' ? (
            <FunnelMetricsChart metrics={data?.metrics} />
        ) : (
            <FunnelMetricsTimeline
                metrics={data?.metrics}
                duration={duration}
                title="Metrics Trends"
                fields={funnelViewMetrics}
            />
        );

    return (
        <UserContextProvider user={user} userPermissions={userPermissions}>
            <DocumentTitle title={`Metrics - ${user.name.full}`}>
                <div id="container">
                    <Header title={`Metrics - ${user.name.full}`} />
                    <div css={styles(theme)}>
                        <div className="metrics-container">
                            <div className="selectors">
                                <Select value={viewType} onChange={handleViewChange} variant="outlined">
                                    <MenuItem value="client-activity">Activity by Client</MenuItem>
                                    <MenuItem value="funnel">Funnel Metrics</MenuItem>
                                    <MenuItem value="timeline">Metrics Trends</MenuItem>
                                </Select>
                                <DurationSelector
                                    selected={duration}
                                    onSelect={setDuration}
                                    periods={durationPeriods}
                                />
                                <JobsTypeFilter selectedFilter={jobTypes} onSelectFilter={setJobTypes} />
                            </div>
                            {content}
                        </div>
                    </div>
                </div>
            </DocumentTitle>
        </UserContextProvider>
    );
};
