import { useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import { IconButton, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { History } from '@material-ui/icons';
import React from 'react';
import { jdUpdateContextDescription } from 'shared/models/jd-llm-updates';
import { fullDateTime } from '../common/timestamp';

import { JDLLMUpdate, JOB_LLM_UPDATES_HISTORY } from '../graphql/queries/job-form';
import { JDLLMUpdateDetails } from './jd-llm-update-details';

const styles = css`
    position: fixed;
    bottom: 15px;
    right: 15px;
    opacity: 0.5;
    transition: opacity 0.3s;

    &:hover {
        opacity: 1;
    }
`;

export const JobLLMEditsHistory: React.FC<{ jobId: string }> = ({ jobId }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedUpdate, setSelectedUpdate] = React.useState<JDLLMUpdate | null>(null);
    const { data } = useQuery<{
        updates: JDLLMUpdate[];
        job: { id: string; title: string; client: { name: string } };
    }>(JOB_LLM_UPDATES_HISTORY, { variables: { jobId } });

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleShowDetails = (update: JDLLMUpdate) => () => {
        setAnchorEl(null);
        setSelectedUpdate(update);
    };

    const handleCloseDetails = () => {
        setSelectedUpdate(null);
    };

    const menuItems = data?.updates.map((update) => (
        <MenuItem key={update.updatedAt} onClick={handleShowDetails(update)}>
            <div>
                <div>{fullDateTime(update.updatedAt)}</div>
                <Typography variant="caption" color="textSecondary" component="div">
                    {jdUpdateContextDescription(update.context)}
                </Typography>
            </div>
        </MenuItem>
    ));

    const menu = (
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
            {menuItems}
        </Menu>
    );

    const detailsDialog = selectedUpdate ? (
        <JDLLMUpdateDetails updateData={selectedUpdate} job={data?.job} open={true} onClose={handleCloseDetails} />
    ) : null;

    if (!data || data?.updates.length === 0) {
        return null;
    }

    return (
        <div css={styles}>
            <Tooltip title="View Job Updates By AI">
                <IconButton onClick={handleOpen}>
                    <History />
                </IconButton>
            </Tooltip>
            {menu}
            {detailsDialog}
        </div>
    );
};
