import { css } from '@emotion/core';
import {
    Collapse,
    Drawer as MUIDrawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Theme,
    useTheme
} from '@material-ui/core';
import {
    AccountCircle,
    AssignmentInd,
    Build,
    Business,
    Email,
    ExpandLess,
    ExpandMore,
    Extension,
    Flare,
    GroupWork,
    Home,
    MonetizationOn,
    People,
    ShowChart,
    Update,
    Work
} from '@material-ui/icons';
import React from 'react';
import { connect } from 'react-redux';
import { LinkProps as RouterLinkProps, NavLink as RouterLink } from 'react-router-dom';

import { hasRole } from 'shared/models/user';

import { toggleDrawer } from '../actions';
import { useLocalStorage } from '../hooks/use-local-storage';
import { useSession } from '../hooks/use-session';
import { State } from '../state';

interface ConnectedProps {
    open: boolean;
}

interface ConnectedDispatch {
    onClose: () => void;
}

type DrawerProps = ConnectedProps & ConnectedDispatch;

interface SectionsExpandedState {
    allocations: boolean;
    billing: boolean;
    candidates: boolean;
    jobs: boolean;
    emails: boolean;
    extensions: boolean;
    users: boolean;
    stats: boolean;
    hireflow: boolean;
}

const recruiterExtension = 'https://chrome.google.com/webstore/detail/nbnodchgpmfohefjjcgaogplimdghieo';

const renderLink = (to: string) =>
    React.useMemo(
        () =>
            React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
                <RouterLink to={to} exact={true} innerRef={ref} {...itemProps} />
            )),
        [to]
    );

const styles = (theme: Theme) => css`
    .MuiPaper-root {
        padding: 0 10px 20px;
    }

    .MuiList-root {
        padding-top: 0;
    }

    .MuiButtonBase-root {
        border-radius: ${theme.shape.borderRadius}px;
    }

    .MuiListItemText-primary {
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        margin-right: 40px;
        margin-left: 5px;
        color: ${theme.palette.text.secondary};
    }

    .nested {
        .MuiListItemText-primary {
            padding-left: 33px;
            font-weight: 400;
        }
    }

    .active {
        .MuiListItemText-primary {
            font-weight: 500;
            color: ${theme.palette.primary.main};
        }
    }

    .top-level-menu.MuiListItem-root {
        margin-top: 10px;

        img {
            width: 22px;
            height: 22px;
        }
    }
`;

const DrawerComponent: React.FC<DrawerProps> = ({ open, onClose }) => {
    const theme = useTheme();
    const { user, userPermissions } = useSession();

    const [sectionsExpandedState, setSectionsExpandedState] = useLocalStorage<SectionsExpandedState>(
        'drawer-sections-expanded',
        {
            allocations: true,
            billing: true,
            candidates: true,
            emails: true,
            extensions: true,
            hireflow: true,
            jobs: true,
            stats: true,
            users: true
        }
    );

    const handleSectionClick = (section: keyof SectionsExpandedState) => () => {
        setSectionsExpandedState({ ...sectionsExpandedState, [section]: !sectionsExpandedState[section] });
    };

    const createJobLink = hasRole(userPermissions, 'job_creator') ? (
        <ListItem button={true} component={renderLink('/job/new')} onClick={onClose}>
            <ListItemText primary="Create New Job" />
        </ListItem>
    ) : null;

    let usersSection;
    if (hasRole(userPermissions, 'scrum_master') || hasRole(userPermissions, 'user_settings_editor')) {
        const allUserLinks = hasRole(userPermissions, 'user_settings_editor') ? (
            <>
                <ListItem button={true} component={renderLink('/users')} onClick={onClose}>
                    <ListItemText primary="All Users" />
                </ListItem>
                <ListItem button={true} component={renderLink('/phone-screens')} onClick={onClose}>
                    <ListItemText primary="All Phone Calls" />
                </ListItem>
            </>
        ) : null;
        const scrumLink = hasRole(userPermissions, 'scrum_master') ? (
            <ListItem button={true} component={renderLink('/users/scrum')} onClick={onClose}>
                <ListItemText primary="Scrum" />
            </ListItem>
        ) : null;
        usersSection = (
            <>
                <ListItem className="top-level-menu" button={true} onClick={handleSectionClick('users')}>
                    <ListItemIcon>
                        <AccountCircle />
                    </ListItemIcon>
                    <ListItemText primary="Users" />
                    {sectionsExpandedState.users ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={sectionsExpandedState.users} timeout="auto" unmountOnExit={true}>
                    <List component="div" disablePadding={true} className="nested">
                        {scrumLink}
                        {allUserLinks}
                    </List>
                </Collapse>
            </>
        );
    }

    const billingSection = (
        <>
            <ListItem className="top-level-menu" button={true} onClick={handleSectionClick('billing')}>
                <ListItemIcon>
                    <MonetizationOn />
                </ListItemIcon>
                <ListItemText primary="Billing" />
                {sectionsExpandedState.billing ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={sectionsExpandedState.billing} timeout="auto" unmountOnExit={true}>
                <List component="div" disablePadding={true} className="nested">
                    <ListItem button={true} component={renderLink('/placements')} onClick={onClose}>
                        <ListItemText primary="Placements" />
                    </ListItem>
                    <ListItem button={true} component={renderLink('/monthly-fees')} onClick={onClose}>
                        <ListItemText primary="Monthly Fees" />
                    </ListItem>
                    <ListItem button={true} component={renderLink('/invoices')} onClick={onClose}>
                        <ListItemText primary="Invoices" />
                    </ListItem>
                    <ListItem button={true} component={renderLink('/fees')} onClick={onClose}>
                        <ListItemText primary="Fees" />
                    </ListItem>
                </List>
            </Collapse>
        </>
    );

    const emailAccountsLink = hasRole(userPermissions, 'email_account_admin') ? (
        <ListItem button={true} component={renderLink('/email-accounts')} onClick={onClose}>
            <ListItemText primary="Accounts" />
        </ListItem>
    ) : null;

    const amCandidatesLink = hasRole(userPermissions, 'account_manager') ? (
        <ListItem button={true} component={renderLink('/account-manager/me/candidates')} onClick={onClose}>
            <ListItemText primary="Active AM Candidates" />
        </ListItem>
    ) : null;

    const fixProfileConflicts = hasRole(userPermissions, 'fix_profiles_conflict') ? (
        <ListItem
            className="top-level-menu"
            button={true}
            component={renderLink('/profile-conflicts')}
            onClick={onClose}
        >
            <ListItemIcon>
                <Build />
            </ListItemIcon>
            <ListItemText primary="Profile Conflicts" />
        </ListItem>
    ) : null;

    const pendingProfilesLink = hasRole(userPermissions, 'fix_profiles_conflict') ? (
        <ListItem
            className="top-level-menu"
            button={true}
            component={renderLink('/profiles/pending')}
            onClick={onClose}
        >
            <ListItemIcon>
                <Update />
            </ListItemIcon>
            <ListItemText primary="Profile Updates Needed" />
        </ListItem>
    ) : null;

    const teamMetrics = (
        <ListItem
            className="top-level-menu"
            button={true}
            component={renderLink(`/team/${user.defaultTeamId}/metrics`)}
            onClick={onClose}
        >
            <ListItemIcon>
                <GroupWork />
            </ListItemIcon>
            <ListItemText primary="Team Metrics" />
        </ListItem>
    );

    const clientEmailsLink = hasRole(userPermissions, 'account_manager') ? (
        <ListItem button={true} component={renderLink('/client-emails')} onClick={onClose}>
            <ListItemText primary="Client Emails" />
        </ListItem>
    ) : null;

    return (
        <MUIDrawer open={open} onClose={onClose} css={styles(theme)}>
            <List>
                <ListItem className="top-level-menu" button={true} component={renderLink('/')} onClick={onClose}>
                    <ListItemIcon>
                        <Home />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                </ListItem>

                <ListItem className="top-level-menu" button={true} component={renderLink('/clients')} onClick={onClose}>
                    <ListItemIcon>
                        <Business />
                    </ListItemIcon>
                    <ListItemText primary="Clients" />
                </ListItem>

                <ListItem className="top-level-menu" button={true} onClick={handleSectionClick('jobs')}>
                    <ListItemIcon>
                        <Work />
                    </ListItemIcon>
                    <ListItemText primary="Jobs" />
                    {sectionsExpandedState.jobs ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={sectionsExpandedState.jobs} timeout="auto" unmountOnExit={true}>
                    <List component="div" disablePadding={true} className="nested">
                        <ListItem button={true} component={renderLink('/jobs/details')} onClick={onClose}>
                            <ListItemText primary="Details View" />
                        </ListItem>
                        <ListItem button={true} component={renderLink('/jobs/funnel')} onClick={onClose}>
                            <ListItemText primary="Funnel View" />
                        </ListItem>
                        {createJobLink}
                    </List>
                </Collapse>

                <ListItem className="top-level-menu" button={true} onClick={handleSectionClick('candidates')}>
                    <ListItemIcon>
                        <People />
                    </ListItemIcon>
                    <ListItemText primary="Candidates" />
                    {sectionsExpandedState.candidates ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={sectionsExpandedState.candidates} timeout="auto" unmountOnExit={true}>
                    <List component="div" disablePadding={true} className="nested">
                        <ListItem button={true} component={renderLink('/recruiter/me/candidates')} onClick={onClose}>
                            <ListItemText primary="Active Recruiter Candidates" />
                        </ListItem>
                        {amCandidatesLink}
                        <ListItem button={true} component={renderLink('/candidates/offers')} onClick={onClose}>
                            <ListItemText primary="Offers" />
                        </ListItem>
                        <ListItem button={true} component={renderLink('/candidates/search')} onClick={onClose}>
                            <ListItemText primary="Candidates Search" />
                        </ListItem>
                        <ListItem button={true} component={renderLink('/candidates/favorites')} onClick={onClose}>
                            <ListItemText primary="Favorites" />
                        </ListItem>
                        <ListItem button={true} component={renderLink('/candidates/blacklist')} onClick={onClose}>
                            <ListItemText primary="Blacklisted" />
                        </ListItem>
                        <ListItem button={true} component={renderLink('/searches/linkedin')} onClick={onClose}>
                            <ListItemText primary="Linkedin Searches" />
                        </ListItem>
                    </List>
                </Collapse>

                {billingSection}

                <ListItem
                    className="top-level-menu"
                    button={true}
                    component={renderLink('/company/talent')}
                    onClick={onClose}
                >
                    <ListItemIcon>
                        <Flare />
                    </ListItemIcon>
                    <ListItemText primary="Talent Intelligence" />
                </ListItem>

                <ListItem className="top-level-menu" button={true} onClick={handleSectionClick('allocations')}>
                    <ListItemIcon>
                        <AssignmentInd />
                    </ListItemIcon>
                    <ListItemText primary="Allocations" />
                    {sectionsExpandedState.allocations ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={sectionsExpandedState.allocations} timeout="auto" unmountOnExit={true}>
                    <List component="div" disablePadding={true} className="nested">
                        <ListItem button={true} component={renderLink('/allocations/job')} onClick={onClose}>
                            <ListItemText primary="Jobs" />
                        </ListItem>
                        <ListItem button={true} component={renderLink('/allocations/recruiter')} onClick={onClose}>
                            <ListItemText primary="Recruiters" />
                        </ListItem>
                    </List>
                </Collapse>

                <ListItem className="top-level-menu" button={true} onClick={handleSectionClick('stats')}>
                    <ListItemIcon>
                        <ShowChart />
                    </ListItemIcon>
                    <ListItemText primary="Stats" />
                    {sectionsExpandedState.stats ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={sectionsExpandedState.stats} timeout="auto" unmountOnExit={true}>
                    <List component="div" disablePadding={true} className="nested">
                        <ListItem button={true} component={renderLink('/stats/recruiting')} onClick={onClose}>
                            <ListItemText primary="Recruiting Stats" />
                        </ListItem>
                        <ListItem button={true} component={renderLink('/stats/leaderboard')} onClick={onClose}>
                            <ListItemText primary="Leaderboard" />
                        </ListItem>
                        <ListItem button={true} component={renderLink('/candidates/trends')} onClick={onClose}>
                            <ListItemText primary="Trends" />
                        </ListItem>
                        <ListItem button={true} component={renderLink('/stats/emails')} onClick={onClose}>
                            <ListItemText primary="Email Stats" />
                        </ListItem>
                        <ListItem button={true} component={renderLink('/stats/business')} onClick={onClose}>
                            <ListItemText primary="Metrics Dashboard" />
                        </ListItem>
                    </List>
                </Collapse>

                <ListItem className="top-level-menu" button={true} onClick={handleSectionClick('emails')}>
                    <ListItemIcon>
                        <Email />
                    </ListItemIcon>
                    <ListItemText primary="Emails" />
                    {sectionsExpandedState.emails ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={sectionsExpandedState.emails} timeout="auto" unmountOnExit={true}>
                    <List component="div" disablePadding={true} className="nested">
                        <ListItem button={true} component={renderLink('/user/me/outreaches')} onClick={onClose}>
                            <ListItemText primary="Outreaches" />
                        </ListItem>
                        <ListItem button={true} component={renderLink('/outreaches')} onClick={onClose}>
                            <ListItemText primary="All Outreaches" />
                        </ListItem>
                        <ListItem button={true} component={renderLink('/emails')} onClick={onClose}>
                            <ListItemText primary="Inboxes" />
                        </ListItem>
                        {clientEmailsLink}
                        <ListItem button={true} component={renderLink('/templates')} onClick={onClose}>
                            <ListItemText primary="Templates" />
                        </ListItem>
                        {emailAccountsLink}
                        <ListItem button={true} component={renderLink('/stats/emails')} onClick={onClose}>
                            <ListItemText primary="Stats" />
                        </ListItem>
                    </List>
                </Collapse>

                {teamMetrics}

                {usersSection}

                <ListItem className="top-level-menu" button={true} onClick={handleSectionClick('extensions')}>
                    <ListItemIcon>
                        <Extension />
                    </ListItemIcon>
                    <ListItemText primary="Extensions" />
                    {sectionsExpandedState.extensions ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={sectionsExpandedState.extensions} timeout="auto" unmountOnExit={true}>
                    <List component="div" disablePadding={true} className="nested">
                        <ListItem
                            button={true}
                            component="a"
                            href={recruiterExtension}
                            target="_blank"
                            onClick={onClose}
                        >
                            <ListItemText primary="Titan Extension" />
                        </ListItem>
                    </List>
                </Collapse>
                {fixProfileConflicts}
                {pendingProfilesLink}
            </List>
        </MUIDrawer>
    );
};

const mapStateToProps = (state: State): ConnectedProps => ({
    open: state.ui.drawerOpen
});
const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    onClose: toggleDrawer
};

export const Drawer = connect<ConnectedProps, ConnectedDispatch, {}>(
    mapStateToProps,
    mapDispatchToProps
)(DrawerComponent);
