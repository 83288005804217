import produce from 'immer';
import { flatten, startCase } from 'lodash';
import React from 'react';

import { anyValue, Seniority, seniorityName } from 'shared/models/search';

import { idealGroups, jobDisciplines } from '../common/job-disciplines';
import { RangeSelectField } from '../core-ui/range-select-field';
import { SelectField } from '../core-ui/simple-select-field';
import { SearchStatus } from '../state';
import { SearchExperienceTenureRequirements } from './search-experience-tenure-requirements';
import { SearchExperienceTitlesRequirements } from './search-experience-titles-requirements';
import { SearchFieldContext } from './search-field-context';
import { SearchGroup } from './search-group';
import { RadioButtons } from './search-radio-buttons';
import { SearchRequirementsSection } from './search-requirements-section';
import { useSearch } from './use-search';

export const SearchConfigWork: React.FC = () => {
    const searchContext = useSearch();
    const config = searchContext.data.config;
    const onChange = searchContext.onFieldChange('config');
    const readonly = searchContext.readonly;
    const { groups } = config.experience;
    const savedSearch = searchContext.data.status && searchContext.data.status !== SearchStatus.Initial;

    const handleDisciplineSelect = (selectedItems: string[]) => {
        onChange(
            produce(config, (draft) => {
                draft.general.disciplines = selectedItems;
            })
        );
    };

    const handleUpdateGroup = (menuItemValue: any) => {
        onChange(
            produce(config, (draft) => {
                draft.experience.groups = menuItemValue;
            })
        );
    };

    const handleUpdateSeniorityRange = (seniorityMinIndex: number, seniorityMaxIndex: number) => {
        onChange(
            produce(config, (draft) => {
                draft.experience.seniorityMin = seniorityOptions[seniorityMinIndex];
                draft.experience.seniorityMax = seniorityOptions[seniorityMaxIndex];
            })
        );
    };

    const handleCCFSEChange = (updatedVal: boolean) => {
        onChange(
            produce(config, (draft) => {
                if (draft.experience.ccfseExclude?.enabled !== updatedVal) {
                    draft.experience.ccfseExclude = { enabled: updatedVal, when: 'latest' };
                }
            })
        );
    };

    const handleCCFSEWhenChange = (selectedItem: 'latest' | 'latestOrPast') => {
        onChange(
            produce(config, (draft) => {
                draft.experience.ccfseExclude.when = selectedItem;
            })
        );
    };

    const disciplines = config.general.disciplines || [];

    const getGroupOptions = () => {
        const options = disciplines.find((d) => d === anyValue)
            ? Object.keys(idealGroups).map((k) => idealGroups[k])
            : disciplines.map((jd) => idealGroups[jd]);
        const groupOptions = flatten(options);
        return groupOptions;
    };

    const disciplineSelect =
        savedSearch && config.general.disciplines?.length === 0 ? null : (
            <div className="search-requirements-field">
                <label className="search-label">Current Discipline</label>
                <SelectField
                    disabled={readonly}
                    multiple={true}
                    selected={config.general.disciplines}
                    onSelect={handleDisciplineSelect}
                    options={[anyValue, ...jobDisciplines, 'Unknown']}
                    getOptionLabel={startCase}
                    className="search-config-custom-multi-select-field"
                />
            </div>
        );

    const groupSelect =
        savedSearch && groups?.length === 0 ? null : (
            <div className="search-requirements-field top-padding">
                <label className="search-label">Current Job Group</label>
                <SelectField
                    multiple={true}
                    selected={groups}
                    onSelect={handleUpdateGroup}
                    hintText={disciplines.length ? 'Select' : 'Select Job Discipline First'}
                    disabled={readonly || !disciplines.length}
                    options={getGroupOptions()}
                    className="search-config-custom-multi-select-field"
                />
            </div>
        );

    const seniorityOptions: Seniority[] = ['entry', 'junior', 'normal', 'senior', 'director', 'executive'];
    const seniorityLabel = (s: Seniority) => seniorityName[s];
    const { seniorityMax, seniorityMin, ccfseExclude } = config.experience;

    const includeExcludeOptions = [
        { value: true, label: 'Exclude' },
        { value: false, label: 'Include' }
    ];

    const seniorityField =
        (seniorityMin === 'entry' && seniorityMax === 'executive') || !seniorityMin || !seniorityMax ? null : (
            <div className="search-requirements-field top-padding">
                <label className="search-label">Current Job Seniority</label>
                <div className="search-requirements-value range-value">
                    <RangeSelectField
                        readonly={readonly}
                        max={seniorityOptions.indexOf(seniorityMax)}
                        min={seniorityOptions.indexOf(seniorityMin)}
                        maxValueOpts={seniorityOptions.map((opt, i) => ({ value: i, name: `${seniorityLabel(opt)}` }))}
                        minValueOpts={seniorityOptions.map((opt, i) => ({ value: i, name: `${seniorityLabel(opt)}` }))}
                        onChange={handleUpdateSeniorityRange}
                    />
                </div>
            </div>
        );

    const ccfseExcludeFieldContext = ccfseExclude?.enabled ? (
        <SearchFieldContext
            disabled={readonly}
            label="Kind"
            onSelect={handleCCFSEWhenChange}
            selected={ccfseExclude?.when}
            options={['latest', 'latestOrPast']}
            getOptionLabel={startCase}
        />
    ) : null;

    const ccfseExcludeField = (
        <SearchGroup readonly={readonly} actions={[]} className="top-padding-1-5x">
            <div className={`search-requirements-field ${ccfseExclude?.enabled ? 'with-context' : ''}`}>
                <div style={{ flex: '1 1 auto' }}>
                    <label className="search-label">Contractor/Consultant/Freelance/Self Employed</label>
                    <div className="search-requirements-value radio-buttons">
                        <RadioButtons
                            readonly={readonly}
                            name="ccfse"
                            selected={ccfseExclude?.enabled}
                            onChange={handleCCFSEChange}
                            options={includeExcludeOptions}
                        />
                    </div>
                </div>
                {ccfseExcludeFieldContext}
            </div>
        </SearchGroup>
    );

    return (
        <SearchRequirementsSection section="work">
            {disciplineSelect}
            {groupSelect}
            {seniorityField}
            <SearchExperienceTenureRequirements />
            <SearchExperienceTitlesRequirements />
            {ccfseExcludeField}
        </SearchRequirementsSection>
    );
};
