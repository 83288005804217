import * as React from 'react';
import DocumentTitle from 'react-document-title';

import { Allocations } from '../components/allocations';
import { Header } from '../components/header';

export const AllocationsPage = () => (
    <DocumentTitle title="Allocations">
        <div id="container">
            <Header title="Allocations" />
            <div id="content" className="flex-fill">
                <Allocations />
            </div>
        </div>
    </DocumentTitle>
);
