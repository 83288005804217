import { Map } from 'immutable';
import * as _ from 'lodash';
import { MenuItem, SelectField } from 'material-ui';
import { white } from 'material-ui/styles/colors';
import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import { EmailAddress } from 'shared/types/email-compose';

import { State } from '../../state';
import { fetchAccounts } from '../actions';

interface OwnProps {
    pagePath: string;
    selected: string;
}

interface ConnectedProps {
    accounts: Map<string, EmailAddress>;
}

interface ConnectedDispatch {
    fetchAccounts: () => void;
}

interface EmailAccountSelectorState {
    selectAccount: string;
    redirect: boolean;
}

type EmailAccountSelectorProps = OwnProps & ConnectedProps & ConnectedDispatch;

class EmailAccountSelectorComponent extends React.Component<EmailAccountSelectorProps, EmailAccountSelectorState> {
    constructor(props: EmailAccountSelectorProps) {
        super(props);
        if (!props.accounts) {
            props.fetchAccounts();
        }
        this.state = { selectAccount: null, redirect: false };
    }

    componentDidUpdate() {
        if (this.state.redirect) {
            this.setState({ selectAccount: null, redirect: false });
        }
    }

    handleAccountSelect = (_1: any, _2: any, account: string) => {
        if (this.props.selected !== account) {
            this.setState({ selectAccount: account, redirect: true });
        }
    };

    render() {
        const { pagePath } = this.props;
        const { selectAccount, redirect } = this.state;
        if (selectAccount && redirect) {
            return <Redirect to={`/${pagePath}/${selectAccount}/`} push={true} />;
        }
        const { accounts, selected } = this.props;
        if (!accounts) return null;
        const menuItems = accounts
            .sortBy(
                (a) => a,
                (a1, a2) => a1.name.localeCompare(a2.name) || a1.address.localeCompare(a2.address)
            )
            .valueSeq()
            .map((account) => (
                <MenuItem
                    value={account.address}
                    primaryText={`${account.name} <${account.address}>`}
                    key={account.address}
                />
            ));
        return (
            <div className="email-inbox-account-info">
                <SelectField
                    value={selected}
                    autoWidth={true}
                    onChange={this.handleAccountSelect}
                    underlineStyle={{ display: 'none' }}
                    style={{ width: 'auto' }}
                    labelStyle={{ color: white }}
                >
                    {menuItems}
                </SelectField>
            </div>
        );
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    accounts: state.emailInboxAccounts
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    fetchAccounts
};

export const EmailAccountSelector = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(EmailAccountSelectorComponent);
