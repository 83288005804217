import { css } from '@emotion/core';
import { Dialog } from '@material-ui/core';
import { Map } from 'immutable';
import { flatMap, groupBy, orderBy, partition, startCase } from 'lodash';
import { FlatButton, List, ListItem } from 'material-ui';
import * as React from 'react';
import { connect } from 'react-redux';

import { EmailTemplateView } from 'shared/models/email-templates';
import { Permissions } from 'shared/models/permission';
import { hasRole, UserData } from 'shared/models/user';

import { deleteTemplate, getConfirmation, getEmailTemplates, saveTemplate, showModalAlert } from '../actions';
import { Spinner } from '../core-ui/spinner';
import { RequestErrors, State } from '../state';
import { EmailTemplate } from './email-template';

const styles = css`
    .MuiDialog-paper {
        width: 60%;
    }
`;

interface ConnectedProps {
    emailTemplates: EmailTemplateView[];
    pendingRequests: Map<string, RequestErrors>;
    user: UserData;
    userPermissions: Permissions;
}

interface ConnectedDispatch {
    deleteTemplate: (template: Partial<EmailTemplateView>) => void;
    getConfirmation: (onConfirm: () => void, description?: string | JSX.Element, title?: string) => void;
    getEmailTemplates: (group: string) => void;
    saveTemplate: (data: Partial<EmailTemplateView>) => void;
    showModalAlert: (description: string | JSX.Element, title: string) => void;
}

type EmailTemplatesProps = ConnectedProps & ConnectedDispatch;

interface EmailTemplatesState {
    selected: Partial<EmailTemplateView>;
}

class EmailTemplatesComponent extends React.Component<EmailTemplatesProps, EmailTemplatesState> {
    constructor(props: EmailTemplatesProps) {
        super(props);
        props.getEmailTemplates(undefined);
        this.state = { selected: null };
    }

    handleSelectTemplate = (selected: EmailTemplateView) => () => {
        this.setState({ selected });
    };

    handleCloseTemplate = () => {
        this.setState({ selected: null });
    };

    handleSaveTemplate = (data: Partial<EmailTemplateView>) => {
        this.props.saveTemplate(data);
        this.setState({ selected: null });
    };

    handleAddTemplate = () => {
        const newTemplate: Partial<EmailTemplateView> = {
            body: '',
            group: 'generic',
            id: null,
            kind: '',
            subject: '',
            userId: this.props.user.id
        };
        this.setState({ selected: newTemplate });
    };

    handleDelete = (template: Partial<EmailTemplateView>) => () => {
        this.props.getConfirmation(
            () => {
                this.props.deleteTemplate(template);
                this.setState({ selected: null });
            },
            'This email template will be deleted immediately.',
            'Confirm'
        );
    };

    render() {
        const { emailTemplates, pendingRequests, user, userPermissions } = this.props;
        const { selected } = this.state;
        if (emailTemplates.length === 0) return <Spinner />;

        const [generic, others] = partition(emailTemplates, (t) => t.group === 'generic');
        const [personal, shared] = partition(generic, (t) => t.userId === user.id);
        const personalTemplates = orderBy(personal, ['kind', 'id']).map((t) => (
            <ListItem primaryText={startCase(t.kind)} key={t.id} onClick={this.handleSelectTemplate(t)} />
        ));
        const sharedTemplates = orderBy(
            shared.filter((t) => !t.userId),
            ['kind', 'id']
        ).map((t) => <ListItem primaryText={startCase(t.kind)} key={t.id} onClick={this.handleSelectTemplate(t)} />);

        const groups = groupBy(others, 'group');
        const templateGroups = orderBy(Object.keys(groups)).map((name, i) => {
            if (name === 'phonescreen' && !hasRole(userPermissions, 'email_templates_editor')) {
                return null;
            }
            const list = groups[name];
            const groupedByKind = groupBy(list, 'kind');
            const templatesList = Object.keys(groupedByKind).map((kind) => {
                const templates = orderBy(groupedByKind[kind], 'id');
                if (templates.length === 1) {
                    return (
                        <ListItem
                            primaryText={startCase(templates[0].kind)}
                            key={templates[0].id}
                            onClick={this.handleSelectTemplate(templates[0])}
                        />
                    );
                } else {
                    const kindList = templates.map((t, j) => (
                        <ListItem
                            primaryText={`${startCase(t.kind)} ${j}`}
                            key={t.id}
                            onClick={this.handleSelectTemplate(t)}
                        />
                    ));
                    return (
                        <ListItem
                            primaryText={startCase(templates[0].kind)}
                            key={templates[0].id}
                            nestedItems={kindList}
                            primaryTogglesNestedList={true}
                        />
                    );
                }
            });

            return (
                <ListItem
                    primaryText={startCase(name)}
                    key={i}
                    nestedItems={templatesList}
                    primaryTogglesNestedList={true}
                />
            );
        });

        let dialog;
        if (selected) {
            const deleteCallback =
                selected.userId === user.id || hasRole(userPermissions, 'email_templates_editor')
                    ? this.handleDelete(selected)
                    : null;
            const inProgress =
                pendingRequests.has('save-email-template') || pendingRequests.has('delete-email-template');
            dialog = (
                <Dialog open={true} maxWidth="md" css={styles}>
                    <EmailTemplate
                        template={selected}
                        key={selected.id}
                        readonly={!hasRole(userPermissions, 'email_templates_editor') && selected.userId !== user.id}
                        onSave={this.handleSaveTemplate}
                        saving={inProgress}
                        onCancel={this.handleCloseTemplate}
                        onDelete={deleteCallback}
                        showModalAlert={this.props.showModalAlert}
                    />
                </Dialog>
            );
        }

        return (
            <div className="email-templates-scroll-container">
                <div className="email-templates-list">
                    <List>
                        <ListItem
                            primaryText="Personal"
                            nestedItems={personalTemplates}
                            primaryTogglesNestedList={true}
                        />
                        <ListItem primaryText="Shared" nestedItems={sharedTemplates} primaryTogglesNestedList={true} />
                        {templateGroups}
                    </List>
                    <div className="add-template">
                        <FlatButton label="Add Template" onClick={this.handleAddTemplate} />
                    </div>
                </div>
                {dialog}
            </div>
        );
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    emailTemplates: flatMap(state.emailTemplates.valueSeq().toArray()),
    pendingRequests: state.pendingRequests,
    user: state.session.user,
    userPermissions: state.session.userPermissions
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    deleteTemplate,
    getConfirmation,
    getEmailTemplates,
    saveTemplate,
    showModalAlert
};

export const EmailTemplates = connect<ConnectedProps, ConnectedDispatch, {}>(
    mapStateToProps,
    mapDispatchToProps
)(EmailTemplatesComponent);
