import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';

import { clearProfileConflicts } from '../actions';
import { zIndexes } from '../common/css-variables';
import { config } from '../config';
import { State } from '../state';

interface ConnectedProps {
    conflictIds: [string, string];
}

interface ConnectedDispatch {
    clearProfileConflicts: () => void;
}

type ProfileConflictProps = ConnectedProps & ConnectedDispatch;

const ProfileConflictAlertComponent: React.FC<ProfileConflictProps> = (props) => {
    const handleCloseModal = () => props.clearProfileConflicts();

    const handleResolve = () => {
        const url = props.conflictIds
            ? `${config.AppUrl}/profile-conflicts?personId1=${props.conflictIds[0]}&personId2=${props.conflictIds[1]}`
            : null;
        window.open(url, '_blank');
    };

    return props.conflictIds ? (
        <Dialog
            open={true}
            onClose={handleCloseModal}
            style={{ zIndex: zIndexes.modalDialog }}
            maxWidth="sm"
            fullWidth={true}
        >
            <DialogTitle>
                <Typography variant="h4" component="div">
                    Potential Profile Conflict
                </Typography>
            </DialogTitle>
            <DialogContent>Possibly multiple profiles for same person detected.</DialogContent>
            <DialogActions>
                <Button onClick={handleCloseModal}>Close</Button>
                <Button onClick={handleResolve}>Resolve Conflicts</Button>
            </DialogActions>
        </Dialog>
    ) : null;
};

const mapStateToProps = (state: State): ConnectedProps => ({
    conflictIds: state.profileConflicts
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    clearProfileConflicts
};

export const ProfileConflictAlert = connect<ConnectedProps, ConnectedDispatch, {}>(
    mapStateToProps,
    mapDispatchToProps
)(ProfileConflictAlertComponent);
