import { Tooltip } from '@material-ui/core';
import { Map } from 'immutable';
import { CircularProgress } from 'material-ui';
import * as React from 'react';
import { connect } from 'react-redux';

import { SearchResultsViewType } from 'shared/models/search';

import { addSearchResultToJob } from '../actions';
import { RequestErrors, SearchResult, State } from '../state';

interface OwnProps {
    jobId: string;
    result: SearchResult;
    viewedAt?: number;
    onNavigateNext?: (personId: string) => void;
    resultsType: SearchResultsViewType;
}

interface ConnectedProps {
    pendingRequests: Map<string, RequestErrors>;
}

interface ConnectedDispatch {
    addSearchResultToJob: (jobId: string, result: SearchResult, disqualified: boolean) => void;
}

type SearchResultActionsProps = OwnProps & ConnectedProps & ConnectedDispatch;

const SearchResultActionsComponent: React.FC<SearchResultActionsProps> = (props) => {
    const { jobId, result, pendingRequests, resultsType } = props;
    const loadingSize = 20;
    const loadingThickness = 2;
    const loadingContent = <CircularProgress color="white" size={loadingSize} thickness={loadingThickness} />;

    let content;

    if (result && (resultsType === 'ready' || resultsType === 'low_score')) {
        const loading = pendingRequests.has(`adding-search-result-${jobId}-${result.searchId}-${result.personId}`);
        if (loading) {
            content = loadingContent;
        } else {
            const handleClick = (disqualified: boolean) => () => {
                props.addSearchResultToJob(jobId, result, disqualified);
                props.onNavigateNext?.(result.personId);
            };
            content = (
                <React.Fragment>
                    <Tooltip title="Add">
                        <button className="add" onClick={handleClick(false)}>
                            <i className="fas fa-thumbs-up" />
                        </button>
                    </Tooltip>
                    <Tooltip title="Reject">
                        <button className="reject" onClick={handleClick(true)}>
                            <i className="fas fa-thumbs-down" />
                        </button>
                    </Tooltip>
                </React.Fragment>
            );
        }
    }

    return <div className="search-result-add-buttons">{content}</div>;
};

const mapStateToProps = (state: State): ConnectedProps => ({
    pendingRequests: state.pendingRequests
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    addSearchResultToJob
};

export const SearchResultActions = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(SearchResultActionsComponent);
