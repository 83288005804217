import { css } from '@emotion/core';
import { MenuItem, Select, Theme, useTheme } from '@material-ui/core';
import { sortBy, uniqBy } from 'lodash';
import React from 'react';

import { pageBgColor } from '../../common/css-variables';
import { Duration } from '../../common/duration';
import { funnelMetricFields } from '../../common/metrics';
import { MetricsData, OutreachMetrics } from '../../graphql/queries/metrics';
import { DurationSelector } from '../duration-selector';
import { FunnelMetricsChart } from './funnel-metrics-chart';
import { FunnelMetricsOutreach } from './funnel-metrics-outreach';
import { FunnelMetricsTable } from './funnel-metrics-table';
import { FunnelMetricsTimeline } from './funnel-metrics-timeline';

type View = 'timeline' | 'funnel' | 'table' | 'outreach';

interface FunnelMetricsProps {
    metrics: MetricsData[];
    outreachMetrics: OutreachMetrics[];
    selectedUser: string;
    onSelectUser: (user: string) => void;
    viewType: View;
    onSelectView: (view: View) => void;
    duration: Duration;
    onChangeDuration: (duration: Duration) => void;
    showJobTitle: boolean;
}

const styles = (theme: Theme) => css`
    overflow: hidden;
    padding: 15px 50px 20px;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    background: ${pageBgColor};

    .panels-container {
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .selectors {
        text-align: right;
        margin-bottom: 15px;
        flex: 0 0 auto;

        .MuiInputBase-root {
            margin-left: 15px;

            .MuiOutlinedInput-input {
                padding-top: 14px;
                padding-bottom: 14px;
                background: white;
            }
        }
    }

    .chart-container {
        background: white;
        border-radius: ${theme.shape.borderRadius}px;
        border: thin solid ${theme.palette.divider};
        flex: 1 1 auto;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        .panel-header {
            padding: 15px 25px;
            border-bottom: thin solid ${theme.palette.divider};
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex: 0 0 auto;
        }

        .panel-content {
            overflow: hidden;
            display: flex;

            .chart {
                padding: 10px 20px;
                flex: 1 1 auto;
                overflow: auto;
                min-height: 50px;

                &::-webkit-scrollbar {
                    width: 4px;
                }
            }
        }

        tr:hover {
            background: ${theme.palette.action.hover};
        }
    }
`;

export const FunnelMetrics: React.FC<FunnelMetricsProps> = ({
    metrics,
    outreachMetrics,
    selectedUser,
    onSelectUser,
    viewType,
    onSelectView,
    duration,
    onChangeDuration,
    showJobTitle
}) => {
    const theme = useTheme();
    const durationPeriods = { days: 0, weeks: 2, months: 2, quarters: 4, years: 2, allTime: true };

    const handleViewChange = (event: React.ChangeEvent<{ value: View }>) => {
        onSelectView(event.target.value);
    };

    const handleSelectUser = (event: React.ChangeEvent<{ value: string }>) => {
        onSelectUser(event.target.value);
    };

    const viewOptsData = [
        ['timeline', 'Trends'],
        ['funnel', 'Funnel'],
        ['table', 'Table'],
        ['outreach', 'Outreach Metrics']
    ] as Array<[View, string]>;

    const viewOptions = viewOptsData.map(([value, title]) => (
        <MenuItem value={value} key={value}>
            {title}
        </MenuItem>
    ));

    const userOptions = sortBy(
        uniqBy(
            metrics?.map((m) => m.user),
            'id'
        ),
        'name'
    ).map((u) => (
        <MenuItem value={u.id} key={u.id}>
            {u.name}
        </MenuItem>
    ));

    const filteredMetrics = metrics?.filter((m) => selectedUser === 'all' || m.user.id === selectedUser);
    const filteredOutreachMetrics = outreachMetrics?.filter((m) => selectedUser === 'all' || m.userId === selectedUser);

    const chart =
        viewType === 'timeline' ? (
            <FunnelMetricsTimeline
                metrics={filteredMetrics}
                duration={duration}
                fields={funnelMetricFields}
                title="Metrics over time"
            />
        ) : viewType === 'funnel' ? (
            <FunnelMetricsChart metrics={filteredMetrics} />
        ) : viewType === 'outreach' ? (
            <FunnelMetricsOutreach metrics={filteredOutreachMetrics} showJobTitle={showJobTitle} />
        ) : (
            <FunnelMetricsTable metrics={filteredMetrics} duration={duration} />
        );

    return (
        <div css={styles(theme)}>
            <div className="panels-container">
                <div className="selectors">
                    <Select value={selectedUser ?? 'all'} onChange={handleSelectUser} variant="outlined">
                        <MenuItem value="all">All Recruiters</MenuItem>
                        {userOptions}
                    </Select>
                    <Select value={viewType} onChange={handleViewChange} variant="outlined">
                        {viewOptions}
                    </Select>
                    <DurationSelector selected={duration} onSelect={onChangeDuration} periods={durationPeriods} />
                </div>
                {chart}
            </div>
        </div>
    );
};
