import { CircularProgress } from 'material-ui';
import DatePicker from 'material-ui/DatePicker';
import * as moment from 'moment';
import * as React from 'react';

import { ClientUpdateData } from '../state';
import { WeeklyUpdateJobSectionComponent } from './weekly-update-job-section';

interface WeeklyUpdateEmailProps {
    data: ClientUpdateData;
    startDate: number;
    endDate: number;
    inProgress: boolean;
    onDateChange: (startDate: number) => void;
}

const containerStyle = {
    background: '#fff',
    flex: '1 1 auto',
    overflowY: 'scroll' as any,
    padding: '10px'
};

const mainStyle = {
    color: '#212121',
    fontFamily: 'Arial',
    fontSize: '13px',
    lineHeight: '1.7'
};

const configStyle = {
    background: '#ddd',
    padding: '0px 10px'
};

export class WeeklyUpdateEmailComponent extends React.Component<WeeklyUpdateEmailProps, undefined> {
    constructor(props: WeeklyUpdateEmailProps) {
        super(props);
    }

    handleDateChange = (_: any, newDate: Date) => {
        this.props.onDateChange(newDate.valueOf());
    };

    render() {
        const { data, startDate, endDate } = this.props;

        const jobsSections = data
            ? data.jobs.map((jobAndCandidate) => {
                  const stageCounts = this.props.data.jobStageCounts[jobAndCandidate.job.id] || {};

                  return (
                      <WeeklyUpdateJobSectionComponent
                          jobAndCandidates={jobAndCandidate}
                          stageCounts={stageCounts}
                          startDate={this.props.startDate}
                          endDate={this.props.endDate}
                          clientName={data.client.name}
                          key={jobAndCandidate.job.id}
                      />
                  );
              })
            : null;

        const content = data ? (
            <div>
                <div style={configStyle}>
                    <div>
                        <DatePicker
                            hintText="Start Date"
                            floatingLabelText="Start Date"
                            mode="landscape"
                            disableYearSelection={true}
                            autoOk={true}
                            defaultDate={moment(startDate).toDate()}
                            maxDate={moment(endDate).toDate()}
                            onChange={this.handleDateChange}
                        />
                        <DatePicker
                            hintText="End Date"
                            floatingLabelText="End Date"
                            mode="landscape"
                            disableYearSelection={true}
                            autoOk={true}
                            defaultDate={moment(endDate).toDate()}
                            maxDate={moment(endDate).toDate()}
                            disabled={true}
                            underlineDisabledStyle={{ display: 'none' }}
                        />
                    </div>
                </div>
                <div>
                    <h2>
                        Rocket Hiring Update for {data.client.name} ({moment(endDate).format('MM/DD/YY')})
                    </h2>
                </div>
                <hr />
                <div style={mainStyle}>
                    <div>
                        This is an automated update, however please reply with any questions or feedback and we will
                        respond back.
                    </div>
                    <hr />
                    {jobsSections}
                </div>
            </div>
        ) : null;

        return (
            <div style={containerStyle}>
                <div
                    className="spinner-container"
                    style={{ display: !data || this.props.inProgress ? 'block' : 'none' }}
                >
                    <CircularProgress />
                </div>
                {content}
            </div>
        );
    }
}
