import React from 'react';
import { SliderForm } from './slider-form';

const MAX_FOUNDED_YEAR = new Date().getFullYear();
const SINGLE_YEARS = 10;
const SINGLE_YEAR_ENDPOINTS = Array.from({ length: SINGLE_YEARS }, (_, i) => MAX_FOUNDED_YEAR - SINGLE_YEARS + i);
// tslint:disable-next-line: no-magic-numbers
const ENDPOINTS = [0, 2000, 2010, ...SINGLE_YEAR_ENDPOINTS, MAX_FOUNDED_YEAR];

export const FoundingYearForm: React.FC<{
    readonly: boolean;
    filters: [number, number];
    onChange: (val: [number, number]) => void;
}> = ({ readonly, filters, onChange }) => {
    return (
        <SliderForm
            readonly={readonly}
            endpoints={ENDPOINTS}
            fieldName="Year Founded"
            value={filters}
            onChange={onChange}
        />
    );
};
