import * as React from 'react';
import DocumentTitle from 'react-document-title';

import { EmailTemplates as EmailTemplatesComponent } from '../components/email-templates';
import { Header } from '../components/header';

export const EmailTemplates = () => (
    <DocumentTitle title="Email Templates">
        <div id="container">
            <Header title="Email Templates" />
            <div id="content" className="flex-fill">
                <EmailTemplatesComponent />
            </div>
        </div>
    </DocumentTitle>
);
