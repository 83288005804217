import { Button, Dialog, DialogActions, DialogContent, TextField } from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';

import { getDisabledHours, getNearestValidEmailTs, isDayDisabled } from '../common/email-sending-times';
import { DateTimePicker } from '../core-ui/date-time-picker';

interface OutreachSendTimeSelectorProps {
    sendTime: number;
    onChange: (value: number) => void;
}

export const OutreachSendTimeSelector: React.FC<OutreachSendTimeSelectorProps> = ({ sendTime, onChange }) => {
    const minSendTime = getNearestValidEmailTs(Date.now(), undefined)(Date.now());
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleDialogOpen = () => setDialogOpen(true);
    const handleDialogClose = () => setDialogOpen(false);

    const workdays = 5;
    let maxDate = moment(minSendTime);
    let remaining = 2; // Number of days to look into future
    while (remaining !== 0) {
        maxDate = maxDate.add(1, 'days');
        if (maxDate.isoWeekday() <= workdays) {
            remaining -= 1;
        }
    }
    maxDate = maxDate.endOf('day');
    const picker = (
        <Dialog open={dialogOpen} onClose={handleDialogClose}>
            <DialogContent style={{ padding: 0 }}>
                <DateTimePicker
                    onChange={onChange}
                    value={sendTime}
                    minDate={Date.now()}
                    maxDate={maxDate.valueOf()}
                    getDisabledHours={getDisabledHours}
                    isDayDisabled={isDayDisabled(Date.now(), maxDate.valueOf())}
                    getNearestValidTime={getNearestValidEmailTs(Date.now(), undefined)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose}>OK</Button>
            </DialogActions>
        </Dialog>
    );

    const sendingAt = moment(sendTime).format('ddd MM/DD/YYYY h:mm a');

    return (
        <>
            <TextField
                fullWidth={true}
                value={sendingAt}
                label="Select Sending Time"
                onClick={handleDialogOpen}
                style={{ cursor: 'pointer' }}
            />
            {picker}
        </>
    );
};
