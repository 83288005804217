import { useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Theme,
    Typography,
    useTheme
} from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';

import { getSequenceVariables, isRevivalSequence, SequenceType, SequenceVariables } from 'shared/models/sequence';

import { getNearestValidEmailTs } from '../common/email-sending-times';
import { LightTooltip } from '../core-ui/light-tooltip';
import { JOB_SEQUENCES, OUTREACH_COUNTS, OutreachCounts, Sequence } from '../graphql/queries/outreach';
import { useSession } from '../hooks/use-session';
import { Name } from '../types/common';
import { OutreachCountsBar } from './outreach-counts-bar';
import { OutreachRevivalMenu } from './outreach-revival-menu';
import { OutreachSendCountsInfo } from './outreach-send-counts-info';
import { OutreachSendTimeSelector } from './outreach-send-time-selector';
import { SequencePreview } from './sequence-preview';

interface OutreachSendDialogProps {
    jobId: string;
    client: { name: string };
    sampleRecipient: {
        id: string;
        name: Name;
    };
    phoneCallSchedulingLink: string;
    availableCount: number;
    dailyEmailLimit: number;
    dailyPerUserEmailLimit: number;
    onClose: () => void;
    onSend: (data: { limit: number; revivalReason?: string; scheduledAt: number; sequenceId: string }) => Promise<void>;
    onRevivalSelect?: (revival: boolean, reason: string) => void;
}

const warningStyle = css`
    padding: 20px;
    text-align: center;
`;

const styles = (theme: Theme) => css`
    .MuiDialog-paper {
        .outreach-dialog-title {
            border-bottom: thin solid ${theme.palette.divider};
        }

        .outreach-dialog-actions {
            border-top: thin solid ${theme.palette.divider};
        }

        .outreach-dialog-content {
            padding: 20px 24px;
        }

        .outreach-title {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .toggle {
                cursor: pointer;
            }
        }

        min-width: 768px;

        .select-field {
            display: flex;

            > * {
                flex: 1 0;
                margin: 0 10px;

                &:first-of-type {
                    margin-left: 0;
                }

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }

        .outreach-field {
            margin-bottom: 25px;
        }
    }
`;

export const OutreachSendDialog: React.FC<OutreachSendDialogProps> = ({
    client,
    jobId,
    availableCount,
    dailyEmailLimit,
    dailyPerUserEmailLimit,
    sampleRecipient,
    phoneCallSchedulingLink,
    onClose,
    onRevivalSelect,
    onSend
}) => {
    const theme = useTheme();
    const { user } = useSession();
    const [revival, setRevival] = useState(false);
    const [revivalReason, setRevivalReason] = useState('never_responded');
    const [selectedSeq, setSelectedSeq] = useState('');
    const [sendTime, setSendTime] = useState(getNearestValidEmailTs(Date.now(), undefined)(Date.now()));
    const sendingDayStart = useMemo(() => moment(sendTime).startOf('day').valueOf(), [sendTime]);
    const sendingDayEnd = useMemo(() => moment(sendTime).endOf('day').valueOf(), [sendTime]);
    const [sendCount, setSendCount] = useState<number>(undefined);
    const { data: sequencesData } = useQuery<{ sequences: Sequence[] }, { jobId: string }>(JOB_SEQUENCES, {
        fetchPolicy: 'network-only',
        variables: { jobId }
    });
    const { data: outreachData, loading } = useQuery<
        OutreachCounts,
        { jobId: string; userId: string; sendingDayStart: number }
    >(OUTREACH_COUNTS, {
        fetchPolicy: 'network-only',
        variables: {
            jobId,
            sendingDayStart,
            userId: user.id
        }
    });

    const sequences = sequencesData?.sequences?.filter(
        (s) => s.type === SequenceType.Outreach && revival === isRevivalSequence(s)
    );
    const accounts = outreachData?.emailAccounts;

    let availableToSendCount: number;
    let jobSentCount: number;
    let jobScheduledCount: number;
    let userSentCount: number;
    let userScheduledCount: number;
    let userJobSentCount: number;
    let userJobScheduledCount: number;
    let emailAccountSlots: number;
    let max: number;
    if (outreachData && !loading && availableCount !== undefined) {
        jobSentCount = outreachData.outreaches
            .filter(
                (o) =>
                    o.jobId === jobId &&
                    o.status === 'complete' &&
                    o.scheduledAt >= sendingDayStart &&
                    o.scheduledAt <= sendingDayEnd
            )
            .reduce((sum, o) => o.addedCount + sum, 0);
        jobScheduledCount = outreachData.outreaches
            .filter(
                (o) =>
                    o.jobId === jobId &&
                    (o.status === 'scheduled' || o.status === 'inProgress') &&
                    o.scheduledAt >= sendingDayStart &&
                    o.scheduledAt <= sendingDayEnd
            )
            .reduce((sum, o) => o.limit + sum, 0);

        userSentCount = outreachData.outreaches
            .filter(
                (o) =>
                    o.userId === user.id &&
                    o.status === 'complete' &&
                    o.scheduledAt >= sendingDayStart &&
                    o.scheduledAt <= sendingDayEnd
            )
            .reduce((sum, o) => o.addedCount + sum, 0);

        userScheduledCount = outreachData.outreaches
            .filter(
                (o) =>
                    o.userId === user.id &&
                    (o.status === 'scheduled' || o.status === 'inProgress') &&
                    o.scheduledAt >= sendingDayStart &&
                    o.scheduledAt <= sendingDayEnd
            )
            .reduce((sum, o) => o.limit + sum, 0);

        userJobSentCount = outreachData.outreaches
            .filter(
                (o) =>
                    o.jobId === jobId &&
                    o.userId === user.id &&
                    o.status === 'complete' &&
                    o.scheduledAt >= sendingDayStart &&
                    o.scheduledAt <= sendingDayEnd
            )
            .reduce((sum, o) => o.addedCount + sum, 0);

        userJobScheduledCount = outreachData.outreaches
            .filter(
                (o) =>
                    o.jobId === jobId &&
                    o.userId === user.id &&
                    (o.status === 'scheduled' || o.status === 'inProgress') &&
                    o.scheduledAt >= sendingDayStart &&
                    o.scheduledAt <= sendingDayEnd
            )
            .reduce((sum, o) => o.limit + sum, 0);

        const userJobAllScheduledCount = outreachData.outreaches
            .filter(
                (o) =>
                    o.jobId === jobId && o.userId === user.id && (o.status === 'scheduled' || o.status === 'inProgress')
            )
            .reduce((sum, o) => o.limit + sum, 0);

        emailAccountSlots = accounts.reduce((sum, account) => sum + account.maxDailyOutreach, 0);

        const jobSlotsAvailable = dailyEmailLimit - jobSentCount - jobScheduledCount;
        const userJobSlotsAvailable = dailyPerUserEmailLimit - userJobSentCount - userJobScheduledCount;
        const emailAccountSlotsAvailable = emailAccountSlots - userSentCount - userScheduledCount;
        const userDailyLimitSlotsAvailable = user.outreachLimitDaily - userSentCount - userScheduledCount;

        // available to send is available count minus what is already scheduled
        // sent is already accounted for in availableCount
        availableToSendCount = availableCount - userJobAllScheduledCount;

        max = Math.max(
            0,
            Math.min(
                availableToSendCount,
                jobSlotsAvailable,
                userJobSlotsAvailable,
                emailAccountSlotsAvailable,
                userDailyLimitSlotsAvailable
            )
        );
    }

    useEffect(() => {
        if (!selectedSeq && sequences) {
            setSelectedSeq(sequences[0]?.id ?? '');
        }
    }, [sequences]);

    useEffect(() => {
        setSelectedSeq(sequences?.[0]?.id ?? '');
    }, [revival]);

    useEffect(() => {
        if (onRevivalSelect) {
            onRevivalSelect(revival, revivalReason);
        }
    }, [revival, revivalReason]);

    useEffect(() => {
        if (max !== undefined) {
            setSendCount(max);
        } else {
            setSendCount(undefined);
        }
    }, [max]);

    const handleSetRevivalReason = (event: React.ChangeEvent<{ value: string }>) => {
        setRevivalReason(event.target.value);
    };

    const handleSelectSequence = (event: React.ChangeEvent<{ value: string }>) => {
        setSelectedSeq(event.target.value);
    };

    const handleSwitchType = () => {
        setRevival(!revival);
        setSelectedSeq('');
    };

    const handleSendCountChange = (value: number) => {
        setSendCount(Math.max(0, Math.min(value, max)));
    };

    const handleSend = () => {
        onSend({
            limit: sendCount,
            revivalReason: revival ? revivalReason : undefined,
            scheduledAt: sendTime,
            sequenceId: selectedSeq
        });
    };

    let revivalInput;
    if (revival) {
        revivalInput = (
            <div className="outreach-field">
                <OutreachRevivalMenu value={revivalReason} onChange={handleSetRevivalReason} />
            </div>
        );
    }

    const seqOpts = (sequences ?? []).map((seq) => (
        <MenuItem key={seq.id} value={seq.id}>
            {seq.title}
        </MenuItem>
    ));

    const sequenceSelector = sequences ? (
        <FormControl>
            <InputLabel id="outreach-sequence-select-label">Select Sequence</InputLabel>
            <Select value={selectedSeq} onChange={handleSelectSequence} labelId="outreach-sequence-select-label">
                {seqOpts}
            </Select>
        </FormControl>
    ) : null;

    const countsInfo = outreachData ? (
        <OutreachSendCountsInfo
            dailyEmailLimit={dailyEmailLimit}
            dailyPerUserEmailLimit={dailyPerUserEmailLimit}
            availableCount={availableCount}
            alreadyScheduled={availableCount - availableToSendCount}
            jobSentCount={jobSentCount}
            jobScheduledCount={jobScheduledCount}
            userSentCount={userSentCount}
            userScheduledCount={userScheduledCount}
            userJobScheduledCount={userJobScheduledCount}
            userJobSentCount={userJobSentCount}
            emailAccountSlots={emailAccountSlots}
            userDailyLimit={user.outreachLimitDaily}
        />
    ) : (
        ''
    );

    const senderAccount = outreachData?.emailAccounts?.[0];
    const seqVars: SequenceVariables =
        senderAccount && sampleRecipient
            ? getSequenceVariables({
                  client,
                  disclaimerText:
                      'I hope I can connect you to the job of your dreams, but if you already have, then let me know and I will stop reaching out to you.',
                  job: {
                      id: jobId
                  },
                  person: sampleRecipient,
                  phoneCallSchedulingLink,
                  senderAccount,
                  user
              })
            : null;

    let preview;
    const sequence = sequences?.find((s) => s.id === selectedSeq);
    const sender = accounts?.[0];
    if (!outreachData || availableCount === undefined) {
        preview = (
            <div css={warningStyle}>
                <Typography variant="body2">Loading, please wait</Typography>
            </div>
        );
    } else if (!sequence) {
        preview = (
            <div css={warningStyle}>
                <Typography variant="body2">Select a Sequence</Typography>
            </div>
        );
    } else if (!sender) {
        preview = (
            <div css={warningStyle}>
                <Typography variant="body2">No email accounts found to send using</Typography>
            </div>
        );
    } else if (max === 0) {
        preview = (
            <div css={warningStyle}>
                <LightTooltip title={countsInfo} interactive={true}>
                    <Typography variant="body2">Not able to send outreach, click for details</Typography>
                </LightTooltip>
            </div>
        );
    } else {
        preview = <SequencePreview sequence={sequence} variables={seqVars} />;
    }

    const typeToggle = onRevivalSelect ? (
        <Typography variant="caption" component="span" className="toggle" onClick={handleSwitchType}>
            {revival ? 'Switch to Regular' : 'Switch to Revival'}
        </Typography>
    ) : null;

    const canSend = max !== undefined && selectedSeq && outreachData && sendCount > 0 && sendCount <= max;

    const outreachCountBarLabelText =
        sendCount === undefined
            ? 'Fetching counts'
            : `${userJobSentCount} Sent, ${userJobScheduledCount} Scheduled, ${sendCount} will be Sent`;

    const outreachCountBarLabel = (
        <LightTooltip title={countsInfo} interactive={true}>
            <InputLabel shrink={true}>{outreachCountBarLabelText}</InputLabel>
        </LightTooltip>
    );

    return (
        <Dialog onClose={onClose} open={true} css={styles(theme)}>
            <DialogTitle className="outreach-dialog-title">
                <div className="outreach-title">
                    <Typography variant="h4" component="span">
                        Send Outreach
                    </Typography>
                    {typeToggle}
                </div>
            </DialogTitle>
            <DialogContent className="outreach-dialog-content">
                {revivalInput}
                <div className="select-field outreach-field">{sequenceSelector}</div>
                <div className="outreach-field">
                    <OutreachSendTimeSelector sendTime={sendTime} onChange={setSendTime} />
                </div>
                <div className="outreach-field">
                    <OutreachCountsBar
                        label={outreachCountBarLabel}
                        sendingCount={sendCount}
                        sentCount={userJobSentCount}
                        scheduledCount={userJobScheduledCount}
                        maxSends={max}
                        onChange={handleSendCountChange}
                    />
                </div>
                {preview}
            </DialogContent>
            <DialogActions className="outreach-dialog-actions">
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSend} disabled={!canSend}>
                    Schedule
                </Button>
            </DialogActions>
        </Dialog>
    );
};
