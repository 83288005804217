import { gql } from '@apollo/client';

export const PERSON_LATEST_COMMUNICATION_AT = gql`
    subscription PersonLatestCommunication($personId: String!) {
        communications(where: { personIds: { _has_key: $personId } }, limit: 1, order_by: { internalDate: desc }) {
            internalDate
        }
    }
`;

export const PERSON_LATEST_SCHEDULED_MESSAGE_AT = gql`
    subscription PersonLatestCommunication($personId: String!) {
        messages: scheduled_messages(
            where: { personIds: { _has_key: $personId } }
            limit: 1
            order_by: { modifiedAt: desc }
        ) {
            modifiedAt
        }
    }
`;
