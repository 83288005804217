import { gql } from '@apollo/client';

export interface Reminder {
    id: string;
    jobId: string;
    text: string;
    remindAt: number;
    recipients: Array<{
        id: string;
        name: string;
    }>;
}

export interface UserReminder {
    id: string;
    text: string;
    remindAt: number;
    person: {
        id: string;
        name: string;
        profilePicUrl: string;
    };
    job: {
        id: string;
        title: string;
        client: {
            name: string;
        };
    };
    client: {
        id: string;
        name: string;
    };
}

export const REMINDERS = gql`
    query Reminders($recipient: String!, $personId: String) {
        reminders(where: { recipient: { _eq: $recipient }, personId: { _eq: $personId } }) {
            id
            jobId
            text
            remindAt
            completed
            recipients: userByRecipient {
                id
                name(path: "full")
            }
        }
    }
`;

export const CREATE_REMINDER = gql`
    mutation CreateReminder($reminder: reminders_insert_input!) {
        insert_reminders(objects: [$reminder]) {
            returning {
                id
                jobId
                text
                remindAt
                completed
                recipients: userByRecipient {
                    id
                    name(path: "full")
                }
            }
        }
    }
`;

export const DELETE_REMINDER = gql`
    mutation DeleteReminder($id: String!) {
        delete_reminders(where: { id: { _eq: $id } }) {
            affected_rows
        }
    }
`;

export const UPDATE_REMINDER = gql`
    mutation UpdateReminder($id: String!, $updates: reminders_set_input!) {
        update_reminders(where: { id: { _eq: $id } }, _set: $updates) {
            affected_rows
        }
    }
`;

export const USER_REMINDERS = gql`
    query Reminders($userId: String!, $maxRemindAt: bigint!) {
        reminders(
            where: { recipient: { _eq: $userId }, completed: { _eq: false }, remindAt: { _lte: $maxRemindAt } }
            order_by: { remindAt: desc }
        ) {
            id
            text
            remindAt
            person {
                id
                name(path: "full")
                profilePicUrl
            }
            job {
                id
                title
                client {
                    id
                    name
                }
            }
            client {
                id
                name
            }
        }
    }
`;
