import { Map } from 'immutable';
import { Paper, Tab, Tabs } from 'material-ui';
import * as React from 'react';
import { connect } from 'react-redux';

import { fetchPersonJobScore } from '../actions';
import { blacklistChecks } from '../extension/blacklist-checks';
import { profileEssentials } from '../extension/profile-essentials';
import { BlacklistCheck, PersonDetails, State } from '../state';
import { ProfileDebugInfoComponent } from './profile-debug-info';

interface ConnectedProps {
    blacklists: Map<string, BlacklistCheck>;
    details: Map<string, PersonDetails>;
}

interface OwnProps {
    personId: string;
    jobId: string;
}

interface ConnectedDispatch {
    fetchPersonJobScore: (personId: string, jobId: string) => void;
}

type PersonJobScoreProps = ConnectedProps & ConnectedDispatch & OwnProps;

class PersonJobScoreComponent extends React.Component<PersonJobScoreProps, undefined> {
    constructor(props: PersonJobScoreProps) {
        super(props);
        this.ensureDataExists(props);
    }

    ensureDataExists(props: PersonJobScoreProps) {
        const { personId, jobId, blacklists } = props;
        const key = `${personId}-${jobId}`;
        if (!blacklists.has(key) && jobId) {
            props.fetchPersonJobScore(personId, jobId);
        }
    }

    componentDidUpdate() {
        this.ensureDataExists(this.props);
    }

    render() {
        const { personId, jobId, blacklists, details } = this.props;
        const key = `${personId}-${jobId}`;
        if (!blacklists.has(key) && jobId) return null;

        const { person, profile } = details.get(personId);

        if (!profile) return null;

        const profileTabIcon = (
            <i className="material-icons" style={{ color: '#596D85' }}>
                person
            </i>
        );
        const debugTabIcon = (
            <i className="material-icons" style={{ color: '#596D85' }}>
                bug_report
            </i>
        );
        const blacklistChecksData = jobId ? (
            <div className="profile-section-blacklist-checks">{blacklistChecks(blacklists.get(key))}</div>
        ) : null;

        const content = (
            <Tabs
                tabItemContainerStyle={{ backgroundColor: 'white', borderBottom: '1px solid #efefef' }}
                contentContainerClassName="profile-score-tab"
            >
                <Tab icon={profileTabIcon}>
                    <div className="extension-profile-details">
                        {blacklistChecksData}
                        <div className="profile-section-essentials">{profileEssentials(profile.content)}</div>
                    </div>
                </Tab>
                <Tab icon={debugTabIcon}>
                    <ProfileDebugInfoComponent person={person} profile={profile.content} />
                </Tab>
            </Tabs>
        );

        return (
            <div className="person-job-score">
                <Paper className="flat-card">{content}</Paper>
            </div>
        );
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    blacklists: state.blacklists,
    details: state.personsDetails.list
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    fetchPersonJobScore
};

export const PersonJobScore = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(PersonJobScoreComponent);
