import { useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    TablePagination,
    Theme,
    useTheme
} from '@material-ui/core';
import { Business } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import _ from 'lodash';
import React from 'react';

import { CrunchbaseCompanyFilters } from 'shared/common/crunchbase-filters';

import { currencyFormat } from 'shared/common/string-format-utils';
import {
    CRUNCHBASE_COMPANIES,
    CrunchbaseCompany,
    CrunchbaseCompanyFilterQueryParams
} from '../../graphql/queries/crunchbase';
import { getGraphRequestParams } from './common';

const pageSize = 10;
const skeletonRows = 10;

const styles = (theme: Theme) => css`
    .MuiListItem-root {
        border-bottom: thin solid ${theme.palette.divider};

        .MuiAvatar-root {
            width: 28px;
            height: 28px;

            .MuiSvgIcon-root {
                font-size: 16px;
            }
        }

        .MuiSkeleton-root.MuiSkeleton-rect {
            width: calc(100% + 25px);
            height: 25px;
            margin: 5px -15px;
        }

        .cb-company-secondary {
            .desc {
                font-size: 14px;
            }
        }

        .cb-info-chips {
            display: flex;
            flex-wrap: wrap;

            .cb-info-chip {
                margin-right: 10px;
                padding-left: 10px;
                border-left: thin solid ${theme.palette.divider};
                display: flex;
                font-size: 12px;
                line-height: 18px;
                margin-top: 4px;

                &:first-child {
                    padding-left: 0;
                    border-left: none;
                }

                .category {
                    opacity: 0.5;
                }

                .value {
                    padding-left: 2px;
                }
            }
        }
    }
`;

export const CBPreview: React.FC<{ filters: CrunchbaseCompanyFilters; totalCount: number }> = ({
    filters,
    totalCount
}) => {
    const theme = useTheme();
    const [page, setPage] = React.useState(0);
    const whereClause = getGraphRequestParams(filters);
    const { data } = useQuery<
        { companies: CrunchbaseCompany[] },
        { where: CrunchbaseCompanyFilterQueryParams; limit: number; offset: number }
    >(CRUNCHBASE_COMPANIES, { variables: { where: whereClause, limit: pageSize, offset: page * pageSize } });

    const handleChangePage = (_1: any, newPage: number) => setPage(newPage);
    const handleChangeRowsPerPage = () => {
        // no-op
    };

    const skeletonRowsArray = !data
        ? _.times(skeletonRows, (i) => (
              <ListItem alignItems="flex-start" key={i}>
                  <Skeleton variant="rect" />
              </ListItem>
          ))
        : null;

    const rows = data?.companies?.map((company) => {
        const avatar = company.logoUrl ? (
            <Avatar alt={company.name} src={company.logoUrl} />
        ) : (
            <Avatar>
                <Business />
            </Avatar>
        );
        const secondary = (
            <div className="cb-company-secondary">
                <div className="desc">{company.description}</div>
                <div className="cb-info-chips">
                    <div className="cb-info-chip">
                        <div className="category">Last Round:</div>
                        <div className="value">{_.startCase(company.latestRound?.investMentType)}</div>
                    </div>
                    <div className="cb-info-chip">
                        <div className="category">Total Funding:</div>
                        <div className="value">{currencyFormat(company.funding, 0)}</div>
                    </div>
                    <div className="cb-info-chip">
                        <div className="category">Size:</div>
                        <div className="value">{company.employees}</div>
                    </div>
                </div>
            </div>
        );
        const primary = (
            <a href={company.cbUrl} target="_blank">
                {company.name}
            </a>
        );
        return (
            <ListItem alignItems="flex-start" key={company.uuid}>
                <ListItemAvatar>
                    <a href={company.cbUrl} target="_blank">
                        {avatar}
                    </a>
                </ListItemAvatar>
                <ListItemText primary={primary} secondary={secondary} disableTypography={true} />
            </ListItem>
        );
    });

    const pagination = data ? (
        <TablePagination
            rowsPerPageOptions={[pageSize]}
            component="div"
            count={totalCount}
            rowsPerPage={pageSize}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            className="pagination"
        />
    ) : null;
    return (
        <>
            <List css={styles(theme)} dense={true}>
                {skeletonRowsArray}
                {rows}
            </List>
            {pagination}
        </>
    );
};
