import { gql } from '@apollo/client';

import { SequenceData } from 'shared/models/sequence';
import { SequenceGenerationPromptData } from 'shared/models/sequence-generation-prompt';

export type Sequence = SequenceData;

export type Prompt = SequenceGenerationPromptData;

export const SEQUENCES = gql`
    query Sequences($jobId: String!) {
        sequences(where: { jobId: { _eq: $jobId } }, order_by: { createdAt: desc }) {
            id
            jobId
            title
            type
            stages
            cc
            bcc
            weekDaysOnly
            modifiedAt
            createdAt
            archived
        }
    }
`;

export const CREATE_SEQUENCE = gql`
    mutation createSequence($sequence: sequences_insert_input!) {
        insert_sequences(objects: [$sequence]) {
            affected_rows
        }
    }
`;

export const UPDATE_SEQUENCE = gql`
    mutation UpdateSequence($id: String!, $sequence: sequences_insert_input!) {
        update_sequences(where: { id: { _eq: $id } }, _set: { archived: true }) {
            affected_rows
        }
        insert_sequences(
            objects: [$sequence]
            on_conflict: { constraint: sequences_jobId_contentHash_key, update_columns: [title, archived] }
        ) {
            affected_rows
        }
    }
`;

export const UPDATE_SEQUENCE_STATUS = gql`
    mutation UpdateSequenceStatus($id: String!, $archive: Boolean!) {
        update_sequences(where: { id: { _eq: $id } }, _set: { archived: $archive }) {
            affected_rows
        }
    }
`;

export const CREATE_SEQUENCE_GENERATION_PROMPT = gql`
    mutation CreateSequenceGenerationPrompt($prompt: sequence_generation_prompts_insert_input!) {
        insert_sequence_generation_prompts(objects: [$prompt]) {
            affected_rows
        }
    }
`;

export const UPDATE_PROMPT_STATUS = gql`
    mutation UpdatePromptStatus($id: String!, $active: Boolean!) {
        update_sequence_generation_prompts(where: { id: { _eq: $id } }, _set: { active: $active }) {
            affected_rows
        }
    }
`;

export const GET_SEQUENCE_GENERATION_PROMPTS = gql`
    query GetSequenceGenerationPrompts($jobId: String!) {
        prompts: sequence_generation_prompts(where: { jobId: { _eq: $jobId } }) {
            id
            title
            jobId
            active
            userId
            modelParameters
            companyInformation
            jobDescription
            additionalContext
            systemPrompt
            userPromptTemplate
            modifiedAt
            createdAt
        }
    }
`;

export const GET_SAMPLE_LLM_GENERATED_EMAILS = gql`
    query GetSampleLLMGeneratedEmails($prompt: CustomOutreachGenerateInput!) {
        sampleOutreach: getSampleCustomOutreach(prompt: $prompt) {
            profile
            stages {
                subject
                body
            }
            systemPrompt
            userPrompt
            requestParameters
        }
    }
`;

export const GENERATE_OUTREACH_TEMPLATE = gql`
    mutation GenerateOutreachTemplate($jobId: String!) {
        generateOutreachTemplate(jobId: $jobId)
    }
`;
