import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ClientMetrics } from '../components/metrics/client-metrics';

interface RouterProps {
    id: string;
}

const ClientMetricsPageComponent: React.SFC<RouteComponentProps<RouterProps>> = ({ match }) => (
    <ClientMetrics clientId={match.params.id} />
);

export const ClientMetricsPage = withRouter(ClientMetricsPageComponent);
