import { Map } from 'immutable';
import { groupBy } from 'lodash';

import { CandidateSummary } from 'shared/models/candidate';

import { Action, ReceiveJobCandidatesSummary, RequestJobCandidatesSummary } from '../actions';

const initialState: Map<string, Map<string, CandidateSummary[]>> = Map();

export function jobCandidatesSummary(
    state = initialState,
    action: Action
): Map<string, Map<string, CandidateSummary[]>> {
    switch (action.type) {
        case ReceiveJobCandidatesSummary:
            return state.set(
                action.payload.jobId,
                action.payload.candidates ? Map(groupBy(action.payload.candidates, 'stage')) : null
            );
        case RequestJobCandidatesSummary:
            return state.set(action.payload.jobId, null);
        default:
            return state;
    }
}
