import { gql } from '@apollo/client';

export const GET_TEMPLATES = gql`
    query Templates($group: String, $kind: String) {
        templates: email_templates(where: { group: { _eq: $group }, kind: { _eq: $kind } }) {
            id
            group
            kind
            body
            createdAt
            modifiedAt
            subject
            userId
            clientId
            cc
            bcc
        }
    }
`;
