import React, { createContext, FC, useContext } from 'react';

import { Permissions } from 'shared/models/permission';

import { User } from '../graphql/queries/user';

const UserContext = createContext<{ user: User; userPermissions: Permissions }>({
    user: undefined,
    userPermissions: undefined
});

const UserContextProvider: FC<{ user: User; userPermissions: Permissions }> = ({ user, userPermissions, children }) => {
    return <UserContext.Provider value={{ user, userPermissions }}>{children}</UserContext.Provider>;
};

const useUserContext = () => useContext(UserContext);

export { UserContextProvider, useUserContext };
