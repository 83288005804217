import { css } from '@emotion/core';
import { Map } from 'immutable';
import { CircularProgress, Dialog, FlatButton, Paper, TextField } from 'material-ui';
import * as React from 'react';
import Dropzone, { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import { connect } from 'react-redux';

import { HrefFilePayload } from 'shared/types/file-payload';

import { addPersonFiles, updatePersonFilename } from '../actions';
import { fullDate } from '../common/timestamp';
import { FileDownloadLink } from '../core-ui/file-download-link';
import { readFile } from '../lib/read-file-payload';
import { Person, RequestErrors, State } from '../state';

const knownFileExtensions = ['.pdf', '.docx'];

const styles = css`
    .see-more {
        cursor: pointer;
        font-size: 14px;
        margin-top: 10px;
    }
`;

interface PersonFilesState {
    active: boolean;
    files: File[];
    editingFilePath: string;
    editingFileExtension: string;
    editedFilename: string;
    seeMoreFiles: boolean;
}

interface OwnProps {
    person: Person;
    viewOnly?: boolean;
}

interface ConnectedProps {
    pendingRequests: Map<string, RequestErrors>;
}

interface ConnectedDispatch {
    addPersonFiles: (id: string, files: HrefFilePayload[]) => void;
    updatePersonFilename: (personId: string, path: string, newFilename: string) => void;
}

type PersonFilesProps = OwnProps & ConnectedProps & ConnectedDispatch;

class PersonFilesComponent extends React.Component<PersonFilesProps, PersonFilesState> {
    constructor(props: PersonFilesProps) {
        super(props);
        this.state = {
            active: false,
            editedFilename: null,
            editingFileExtension: null,
            editingFilePath: null,
            files: [],
            seeMoreFiles: false
        };
    }

    inProgress = (props: PersonFilesProps) => {
        const { pendingRequests, person } = props;
        return (
            pendingRequests.has(`person-files-upload-${person.id}`) &&
            pendingRequests.get(`person-files-upload-${person.id}`).isEmpty()
        );
    };

    showDropzone = () => {
        this.setState({ active: true });
    };

    hideDropzone = () => {
        this.setState({ active: false, files: [] });
    };

    handleSave = () => {
        Promise.all(this.state.files.map(readFile)).then((result) => {
            this.props.addPersonFiles(this.props.person.id, result);
        });
    };

    handleDrop = (files: File[]) => {
        if (!this.inProgress(this.props)) {
            this.setState({ files: this.state.files.concat(files) });
        }
    };

    componentDidUpdate(prevProps: PersonFilesProps) {
        if (this.inProgress(prevProps) && !this.inProgress(this.props)) {
            this.setState({ files: [], active: false });
        }
        if (prevProps.person.id !== this.props.person.id) {
            this.setState({ editedFilename: null, editingFileExtension: null, editingFilePath: null });
        }
    }

    handleEditFile = (path: string) => () => {
        const file = this.props.person.files.find((f) => f.path === path);
        const fileNameSplitByDot = file.name.split('.');

        let filename = fileNameSplitByDot.slice(0, -1).join('.');
        let filenameExtension = `.${fileNameSplitByDot.slice(-1)}`;

        if (!knownFileExtensions.includes(filenameExtension)) {
            filenameExtension = '';
            filename = file.name;
        }

        this.setState({ editingFilePath: path, editedFilename: filename, editingFileExtension: filenameExtension });
    };

    handleCancelEdit = () => {
        this.setState({ editingFileExtension: null, editedFilename: null, editingFilePath: null });
    };

    handleSaveEdit = () => {
        const { person } = this.props;
        const { editingFilePath, editedFilename, editingFileExtension } = this.state;
        this.handleCancelEdit();
        this.props.updatePersonFilename(person.id, editingFilePath, `${editedFilename}${editingFileExtension}`);
    };

    handleFilenameChange = (event: React.FormEvent<{}>, newValue: string) => {
        event.preventDefault();
        this.setState({ editedFilename: newValue });
    };

    handleSeeMoreFiles = () => {
        this.setState({ seeMoreFiles: !this.state.seeMoreFiles });
    };

    render() {
        const { active, files } = this.state;
        const { person, viewOnly } = this.props;
        const inProgress = this.inProgress(this.props);
        const previews = files.map((f, i) => (
            <div className="preview-file" key={i}>
                <i className="material-icons list-icon">attach_file</i>
                <span>{f.name}</span>
            </div>
        ));
        const dropZoneContent = inProgress ? <CircularProgress /> : <p>Click to select a file</p>;
        const saveButton =
            files.length > 0 && !inProgress ? (
                <FlatButton label="Save" primary={true} onClick={this.handleSave} />
            ) : null;
        const cancelButton = inProgress ? null : (
            <FlatButton label="Cancel" secondary={true} onClick={this.hideDropzone} />
        );
        const spinnerSize = 20;
        const thickness = 2;
        const visibleItems = 3;
        const fileLinks = person.files
            .slice(0, this.state.seeMoreFiles ? person.files.length : visibleItems)
            .sort((f1, f2) => f2.createdAt - f1.createdAt)
            .map((f) => {
                const url = `media/files/${f.path}`;
                const editButton = this.props.pendingRequests.has(`update-filename-${person.id}-${f.path}`) ? (
                    <CircularProgress size={spinnerSize} thickness={thickness} />
                ) : (
                    <div className="edit-filename-button" onClick={this.handleEditFile(f.path)}>
                        <i className="fas fa-pen" />
                    </div>
                );
                return (
                    <div className="person-prop-row" key={f.path}>
                        <div className="person-prop-file-item">
                            <FileDownloadLink path={url} filename={f.name}>
                                <a href={url} target="_blank">
                                    {f.name}
                                </a>
                            </FileDownloadLink>
                            {editButton}
                        </div>
                        <div className="file-timestamp">{fullDate(f.createdAt)}</div>
                    </div>
                );
            });

        const getDropzoneSection = (
            getRootProps: <T extends DropzoneRootProps>(props?: T) => T,
            getInputProps: <T extends DropzoneInputProps>(props?: T) => T
        ) => {
            return (
                <section className="drop-zone">
                    <div {...getRootProps()}>
                        <input {...getInputProps()} disabled={inProgress} />
                        {dropZoneContent}
                    </div>
                </section>
            );
        };

        const form = viewOnly ? null : active ? (
            <div className="person-prop-form person-files-form">
                <div className="file-drop-container">
                    <Paper className="file-drop">
                        <Dropzone onDrop={this.handleDrop}>
                            {({ getRootProps, getInputProps }) => getDropzoneSection(getRootProps, getInputProps)}
                        </Dropzone>
                        {previews}
                        <div className="buttons">
                            {cancelButton}
                            {saveButton}
                        </div>
                    </Paper>
                </div>
            </div>
        ) : (
            <div className="placeholder" onClick={this.showDropzone}>
                Add Files
            </div>
        );
        const notEmptyProps = person.files.length > 0 ? 'person-props-not-empty' : '';

        const { editedFilename, editingFileExtension, editingFilePath } = this.state;
        const fileBeingEdited = person.files.find((f) => f.path === editingFilePath);
        const saveDisabled =
            !editedFilename ||
            fileBeingEdited.name === `${editedFilename}${editingFileExtension}` ||
            editedFilename.trim() === '';
        const fileEditActions = [
            <FlatButton key="cancel" label="Cancel" onClick={this.handleCancelEdit} />,
            <FlatButton key="save" label="Save" onClick={this.handleSaveEdit} disabled={saveDisabled} />
        ];
        const editingFileDialog = (
            <Dialog
                open={!!this.state.editingFilePath}
                onRequestClose={this.handleCancelEdit}
                actions={fileEditActions}
                title="Edit filename"
            >
                <TextField
                    value={this.state.editedFilename}
                    floatingLabelText="Filename"
                    onChange={this.handleFilenameChange}
                />
            </Dialog>
        );

        const seeMoreFileLinks =
            person.files.length > visibleItems ? (
                <div className="see-more" onClick={this.handleSeeMoreFiles}>
                    {this.state.seeMoreFiles ? 'See less' : `See ${person.files.length - visibleItems} more`}
                </div>
            ) : null;

        return (
            <div css={styles} className={`person-props person-props-files ${notEmptyProps}`}>
                <i className="prop-icon material-icons section-icon">attach_file</i>
                <div className="prop">
                    {fileLinks}
                    {seeMoreFileLinks}
                    {form}
                </div>
                {editingFileDialog}
            </div>
        );
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    pendingRequests: state.pendingRequests
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    addPersonFiles,
    updatePersonFilename
};

export const PersonFiles = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(PersonFilesComponent);
