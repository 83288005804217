import { useMutation, useQuery } from '@apollo/client';
import { Button, IconButton, Tooltip } from '@material-ui/core';
import { CheckCircle, CheckCircleOutline } from '@material-ui/icons';
import React from 'react';

import { UserMentionData } from 'shared/models/user-mention';

import { NOTE_MENTIONS, NoteMention, UPDATE_MENTION } from '../graphql/queries/user-mentions';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';

interface NoteMentionCheckProps {
    noteId: string;
}

export const NoteMentionCheck: React.FC<NoteMentionCheckProps> = ({ noteId }) => {
    const { user } = useSession();
    const { setSnackbar } = useSnackbar();
    const [noteMentionCleared, setNoteMentionCleared] = React.useState(false);

    const [updateMention] = useMutation<{}, { id: string; updates: Partial<UserMentionData> }>(UPDATE_MENTION);
    const { data, loading } = useQuery<{ mentions: NoteMention[] }, { noteId: string; userId: string }>(NOTE_MENTIONS, {
        variables: { noteId, userId: user.id }
    });

    const mention = data?.mentions[0];

    if (!data || !mention) {
        return null;
    }

    const handleCheckNoteMention = async () => {
        if (!noteMentionCleared) {
            setSnackbar('Clearing mention');
            setNoteMentionCleared(true);
            await updateMention({ variables: { id: mention.id, updates: { completed: true } } });
            const handleUndo = async () => {
                await updateMention({ variables: { id: mention.id, updates: { completed: false } } });
                setSnackbar('Mention marked pending');
                setNoteMentionCleared(false);
            };
            const undoButton = (
                <Button onClick={handleUndo} style={{ color: 'white' }}>
                    Undo
                </Button>
            );
            setSnackbar('Mention marked completed', undoButton);
        } else {
            setSnackbar('Mention already marked complete');
        }
    };

    return (
        <div className="note-action">
            <Tooltip title={!noteMentionCleared ? 'Clear mention for this note' : 'Note mention cleared'}>
                <IconButton disabled={loading} onClick={handleCheckNoteMention} size="small">
                    {!noteMentionCleared ? <CheckCircleOutline fontSize="small" /> : <CheckCircle fontSize="small" />}
                </IconButton>
            </Tooltip>
        </div>
    );
};
