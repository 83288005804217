import { css } from '@emotion/core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Theme,
    Typography,
    useTheme
} from '@material-ui/core';
import React from 'react';

import { promptsProfileScoringSystem, promptsProfileScoringUser } from 'shared/common/strings';

import { LLMPromptBaseData, LLMPromptData } from 'shared/common/llm';
import { CandidateScoreEval } from '../graphql/queries/scoring-evals';
import useConstants from '../hooks/use-constants';
import { useSnackbar } from '../hooks/use-snackbar';
import { LLMPromptForm } from './llm-prompt-form';

const styles = (theme: Theme) => css`
    .MuiPaper-root {
        min-width: 720px;
    }

    .MuiDialogContent-root {
        border-top: 1px solid ${theme.palette.divider};
        border-bottom: 1px solid ${theme.palette.divider};
        padding-top: 16px;
        padding-bottom: 16px;
    }

    .action-items {
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .MuiIconButton-root {
            margin-right: 10px;
        }
    }

    .MuiInputBase-root.Mui-disabled {
        color: ${theme.palette.text.primary};
    }
`;

export const CandidateScoreEvalForm: React.FC<{
    data?: CandidateScoreEval;
    open: boolean;
    onClose: () => void;
    onSave?: (data: Partial<CandidateScoreEval>) => Promise<void>;
}> = ({ data: initialData, open, onClose, onSave }) => {
    const theme = useTheme();
    const [data, setData] = React.useState<CandidateScoreEval | null>(initialData);
    const [saving, setSaving] = React.useState(false);
    const { setSnackbar } = useSnackbar();
    const { getConstants } = useConstants();
    const constants = getConstants(['profileScoringDefaultModelParameters', 'profileScoringModelOptions']);
    const disabled = !!data?.id || saving;

    const modelOptions = constants?.profileScoringModelOptions;
    const defaults = {
        modelParameters: constants?.profileScoringDefaultModelParameters,
        systemPrompt: promptsProfileScoringSystem,
        title: '',
        userPromptTemplate: promptsProfileScoringUser
    };

    const validateForm = () => {
        return data?.title?.trim()?.length > 0;
    };

    const handleGenerateScores = async () => {
        if (validateForm()) {
            setSaving(true);
            setSnackbar('Queuing profile scores generation');
            try {
                await onSave(data);
                setSnackbar('Profile scores generation queued');
            } catch (e) {
                setSnackbar('Error generating profile scores');
            } finally {
                setSaving(false);
            }
        }
    };

    const handleChange = (newData: LLMPromptData) => {
        setData({ ...data, ...newData });
    };

    const generateButton =
        disabled || !onSave ? null : (
            <Button onClick={handleGenerateScores} disabled={!validateForm()}>
                Generate Scores
            </Button>
        );

    const formData: LLMPromptBaseData = data?.id ? data : { ...defaults, ...data };

    return (
        <Dialog open={open} maxWidth="lg" css={styles(theme)}>
            <DialogTitle>
                <Typography variant="h4" component="div">
                    {data?.id ? 'View Profile Scoring' : 'New Profile Scoring'}
                    {data?.id ? ` - ${data.title}` : ''}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <LLMPromptForm
                    data={formData}
                    defaults={defaults}
                    modelOptions={modelOptions}
                    isEditable={!data?.id}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={saving}>
                    {data?.id ? 'Close' : 'Cancel'}
                </Button>
                {generateButton}
            </DialogActions>
        </Dialog>
    );
};
