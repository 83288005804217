import { css } from '@emotion/core';
import {
    IconButton,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Theme,
    Tooltip as MUITooltip,
    useTheme
} from '@material-ui/core';
import { Settings, ViewColumn } from '@material-ui/icons';
import { escapeRegExp, uniq } from 'lodash';
import { orderBy } from 'lodash';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { PageDialogLink } from '../common/page-dialog-link';
import { fullDate, timeMonthYear } from '../common/timestamp';
import { ClientCandidates } from '../containers/client-candidates';
import { JobCandidatesBoard } from '../containers/job-candidates-board';
import { LightTooltip as Tooltip } from '../core-ui/light-tooltip';
import { ClientWithJobCounts, JobSummary } from '../graphql/queries/clients';
import { useSlides } from '../hooks/use-candidate-slides';
import { useLocalStorage } from '../hooks/use-local-storage';
import { ClientSettings } from './client-settings';
import { CompanyInvestors } from './company-investors';
import { CompanyTags } from './company-tags';
import { TableColumnsSelector } from './table-columns-selector';

interface ClientsTableProps {
    clients: ClientWithJobCounts[];
    refetch: () => void;
}

// tslint:disable:no-magic-numbers
const styles = (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 25px 50px;
    background: #f4f6f8;

    .selectors {
        flex: 0 0 auto;
        text-align: right;
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-end;

        .MuiOutlinedInput-input {
            padding-top: 9px;
            padding-bottom: 9px;
            background: white;
        }

        .search-field {
            margin-right: 15px;
        }
    }

    .MuiPaper-root {
        display: flex;
        overflow: auto;
        flex-direction: column;

        .section-title {
            display: flex;
            flex: 0 0 auto;
            align-items: center;
            justify-content: space-between;
            padding: 0 15px;
            border-bottom: thin solid ${theme.palette.divider};
            line-height: 2.75;
        }

        .client-actions {
            .MuiIconButton-root {
                opacity: 0;
                margin-left: 5px;
            }
        }

        .MuiTableContainer-root {
            flex: 1 1 auto;
            background-color: white;

            .MuiTableCell-root {
                max-width: 300px;
                vertical-align: top;
            }

            &::-webkit-scrollbar {
                border-left: thin solid ${theme.palette.divider};
                border-top: thin solid ${theme.palette.divider};
            }

            &::-webkit-scrollbar:vertical {
                border-left: thin solid ${theme.palette.divider};
            }

            .MuiTableRow-root {
                .chips-list {
                    margin: -8px 5px -8px -5px;
                    display: flex;
                    overflow: hidden;
                    flex-wrap: wrap;

                    .chip {
                        margin: 3px;
                    }
                }

                &:hover {
                    background: ${theme.palette.grey[100]};

                    .client-actions {
                        .MuiIconButton-root {
                            opacity: 1;
                        }
                    }
                }
            }

            .MuiTableRow-root:last-child {
                td.MuiTableCell-root.MuiTableCell-body {
                    border-bottom: none;
                }
            }
        }
    }

    .pagination {
        flex: 0 0 auto;
        border-top: thin solid ${theme.palette.divider};
    }
`;
// tslint:enable

const tooltipStyles = (theme: Theme) => css`
    margin: -4px -8px;
    padding: 0;
    max-height: 310px;
    min-width: 240px;
    overflow: hidden;
    border-radius: 4px;

    .tooltip-inner {
        max-height: 300px;
        overflow: auto;
        padding: 10px 18px 0;
    }

    .tooltip-job {
        margin: 5px 0 20px;
        line-height: 1.5;

        .tooltip-job-title {
            font-size: 15px;
            font-weight: 500;
            color: ${theme.palette.text.primary};
        }

        .tooltip-secondary {
            opacity: 0.7;
            font-weight: 400;
            font-size: 13px;
        }
    }
`;

const rowsPerPage = 20;

type Column =
    | 'Name'
    | 'Actions'
    | 'Active Jobs'
    | 'Paused Jobs'
    | 'Archived Jobs'
    | 'Placements'
    | 'Date Created'
    | 'Tags'
    | 'Investors'
    | 'Global Blacklist';

const persistentColumns: Column[] = ['Name', 'Actions'];

const selectableColumns: Column[] = [
    'Active Jobs',
    'Paused Jobs',
    'Archived Jobs',
    'Placements',
    'Investors',
    'Tags',
    'Date Created',
    'Global Blacklist'
];

const sortableColumns: Column[] = [
    'Name',
    'Active Jobs',
    'Paused Jobs',
    'Archived Jobs',
    'Date Created',
    'Placements',
    'Global Blacklist'
];

export const ClientsTable: React.FC<ClientsTableProps> = (props) => {
    const { clients } = props;

    const [sortCol, setSortCol] = useLocalStorage<Column>('clients-table-sort-col', 'Name');
    const [selectedColumns, setSelectedColumns] = useLocalStorage<Column[]>('clients-table-columns', selectableColumns);
    const [sortAsc, setSortAsc] = useLocalStorage<'asc' | 'desc'>('clients-table-sort-asc', 'asc');
    const [page, setPage] = React.useState(0);
    const [search, setSearch] = React.useState('');
    const { setList } = useSlides();

    type FilterType =
        | 'all'
        | 'with-active-jobs'
        | 'with-paused-jobs'
        | 'with-active-paused-jobs'
        | 'with-archived-no-jobs';

    const [filter, setFilter] = useLocalStorage<FilterType>('clients-table-filter', 'with-active-jobs');

    const theme = useTheme();

    const handleSortChange = (col: Column) => () => {
        if (col === sortCol) {
            if (sortAsc === 'asc') {
                setSortAsc('desc');
            } else {
                setSortAsc('asc');
            }
        } else {
            setSortCol(col);
            setSortAsc('desc');
        }
    };

    const handleSearchChange = (event: React.ChangeEvent<{ value: string }>) => {
        setSearch(event.target.value);
        setPage(0);
    };

    const handleFilterChange = (event: React.ChangeEvent<{ value: any }>) => {
        setPage(0);
        setSearch('');
        setFilter(event.target.value);
    };

    const handleChangePage = (_1: any, newPage: number) => setPage(newPage);
    const handleChangeRowsPerPage = () => {
        /* no-op */
    };

    const handleUpdateSelectedColumns = (col: Column, selected: boolean) => {
        const newSelected = selected ? [...selectedColumns, col] : selectedColumns.filter((c) => c !== col);
        setSelectedColumns(selectableColumns.filter((c) => newSelected.includes(c)));
    };

    const handlePlacementsClick = (c: ClientWithJobCounts) => () => {
        const list = c.hiredCandidates.map((hc) => ({ jobId: hc.jobId, personId: hc.personId }));
        setList(list, list[0]);
    };

    const getSortedClients = () => {
        const sortFunc = (c: ClientWithJobCounts) => {
            switch (sortCol) {
                case 'Name':
                    return c.name.toLowerCase();
                case 'Active Jobs':
                    return c.activeJobs.length;
                case 'Paused Jobs':
                    return c.pausedJobs.length;
                case 'Archived Jobs':
                    return c.archivedJobs.length;
                case 'Date Created':
                    return c.createdAt;
                case 'Global Blacklist':
                    return !c.globalBlacklistForcedTill ? 2 : c.globalBlacklistForcedTill > Date.now() ? 1 : 0;
                case 'Placements':
                    return c.hiredCandidates.length;
                default:
                    return null;
            }
        };

        return orderBy(clients, [sortFunc, (c: ClientWithJobCounts) => c.name.toLowerCase()], [sortAsc, 'asc']);
    };

    const getJobsCountWithTooltip = (jobs: JobSummary[]) => {
        if (jobs.length === 0) {
            return 0;
        } else {
            const jobsList = jobs.map((j) => {
                const url = `/job/${j.id}/board`;
                return (
                    <div key={j.id} className="tooltip-job">
                        <PageDialogLink Component={JobCandidatesBoard} componentProps={{ jobId: j.id }} url={url}>
                            <Link to={url}>
                                <div className="tooltip-job-title">{j.title}</div>
                            </Link>
                        </PageDialogLink>
                        <div className="tooltip-secondary">{timeMonthYear(j.createdAt)}</div>
                    </div>
                );
            });
            const tooltipContent = (
                <div css={tooltipStyles(theme)}>
                    <div className="tooltip-inner">{jobsList}</div>
                </div>
            );
            return (
                <Tooltip title={tooltipContent} interactive={true}>
                    <span style={{ cursor: 'pointer' }}>{jobs.length}</span>
                </Tooltip>
            );
        }
    };

    const getColumnValue = (c: ClientWithJobCounts, col: Column) => {
        switch (col) {
            case 'Name':
                return c.name;
            case 'Active Jobs':
                return getJobsCountWithTooltip(c.activeJobs);
            case 'Paused Jobs':
                return getJobsCountWithTooltip(c.pausedJobs);
            case 'Archived Jobs':
                return getJobsCountWithTooltip(c.archivedJobs);
            case 'Date Created':
                return timeMonthYear(c.createdAt);
            case 'Global Blacklist':
                return !c.globalBlacklistForcedTill
                    ? 'ON'
                    : c.globalBlacklistForcedTill > Date.now()
                      ? `${fullDate(c.globalBlacklistForcedTill)}`
                      : 'OFF';
            case 'Placements':
                return (
                    <span style={{ cursor: 'pointer' }} onClick={handlePlacementsClick(c)}>
                        {c.hiredCandidates.length}
                    </span>
                );
            case 'Tags':
                return <CompanyTags company={c.company} searchText={search} />;
            case 'Investors':
                return <CompanyInvestors company={c.company} searchText={search} />;
            default:
                return null;
        }
    };

    const displayedColumns = uniq([...persistentColumns, ...selectedColumns]);

    const headers = displayedColumns.map((h) => {
        if (h === 'Actions') {
            // empty cell for actions
            return <TableCell key={h} />;
        }
        const content = sortableColumns.includes(h) ? (
            <TableSortLabel active={sortCol === h} direction={sortAsc} onClick={handleSortChange(h)}>
                {h}
            </TableSortLabel>
        ) : (
            h
        );
        return <TableCell key={h}>{content}</TableCell>;
    });
    const headerRow = <TableRow>{headers}</TableRow>;

    const sortedClients = getSortedClients();
    const searchRegex = new RegExp(escapeRegExp(search), 'i');
    const filteredClients = sortedClients.filter((c) => {
        const match =
            !search ||
            c.name.match(searchRegex) ||
            c.company?.investments?.some((i) => i.investorName.match(searchRegex)) ||
            c.company?.tags?.some((t) => t.tag.match(searchRegex));

        switch (filter) {
            case 'all':
                return match;
            case 'with-active-jobs':
                return match && c.activeJobs.length > 0;
            case 'with-paused-jobs':
                return match && c.activeJobs.length === 0 && c.pausedJobs.length > 0;
            case 'with-active-paused-jobs':
                return match && (c.activeJobs.length > 0 || c.pausedJobs.length > 0);
            case 'with-archived-no-jobs':
                return match && c.activeJobs.length === 0 && c.pausedJobs.length === 0;
            default:
                return match;
        }
    });

    const rows = filteredClients.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((c) => {
        const cols = displayedColumns.map((col) => {
            if (col === 'Actions') {
                return (
                    <TableCell key={col} className="client-actions">
                        <PageDialogLink
                            Component={ClientCandidates}
                            componentProps={{ id: c.id }}
                            url={`/client/${c.id}/candidates`}
                        >
                            <MUITooltip title="View Candidates">
                                <IconButton size="small" href={`/client/${c.id}/candidates`}>
                                    <ViewColumn fontSize="small" />
                                </IconButton>
                            </MUITooltip>
                        </PageDialogLink>
                        <PageDialogLink
                            Component={ClientSettings}
                            componentProps={{ clientId: c.id }}
                            url={`/client/${c.id}/edit`}
                        >
                            <MUITooltip title="View/Edit Settings">
                                <IconButton size="small" href={`/client/${c.id}/edit`}>
                                    <Settings fontSize="small" />
                                </IconButton>
                            </MUITooltip>
                        </PageDialogLink>
                    </TableCell>
                );
            } else {
                return <TableCell key={col}>{getColumnValue(c, col)}</TableCell>;
            }
        });
        return <TableRow key={c.id}>{cols}</TableRow>;
    });

    const pagination =
        rowsPerPage && filteredClients.length > rowsPerPage ? (
            <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                component="div"
                count={filteredClients.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                className="pagination"
            />
        ) : null;

    const selectedColumnsMap = selectedColumns.reduce((acc, col) => ({ ...acc, [col]: true }), {});

    return (
        <div css={styles(theme)}>
            <div className="selectors">
                <TextField
                    className="search-field"
                    variant="outlined"
                    placeholder="Search"
                    value={search}
                    onChange={handleSearchChange}
                />
                <Select value={filter} onChange={handleFilterChange} variant="outlined">
                    <MenuItem value={'all'}>All Clients</MenuItem>
                    <MenuItem value={'with-active-jobs'}>With Active Jobs</MenuItem>
                    <MenuItem value={'with-paused-jobs'}>With Paused Jobs Only</MenuItem>
                    <MenuItem value={'with-active-paused-jobs'}>With Active or Paused Jobs</MenuItem>
                    <MenuItem value={'with-archived-no-jobs'}>With Archived or No Jobs</MenuItem>
                </Select>
                <TableColumnsSelector
                    columns={selectableColumns}
                    selected={selectedColumnsMap}
                    onSelect={handleUpdateSelectedColumns}
                />
            </div>
            <Paper>
                <TableContainer>
                    <Table stickyHeader={true}>
                        <TableHead>{headerRow}</TableHead>
                        <TableBody>{rows}</TableBody>
                    </Table>
                </TableContainer>
                {pagination}
            </Paper>
        </div>
    );
};
