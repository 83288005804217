import { gql } from '@apollo/client';
import { CommunicationCategory, EmailSentiment } from 'shared/models/communication';
import { CandidateDisqualReason } from 'shared/models/job-stages';

import { Interview } from '../../state';
export interface StageCountsRecord {
    id: number;
    name: string;
    label: string;
    candidatesCount: number;
    pendingEmailsCount: number;
    interviewsCount: number;
}

export interface StageFunnelData {
    id: number;
    name: string;
    count: number;
    prevCount: number;
    last14d: number;
    target: number;
}

export type StageCountsRecordWithFunnel = StageCountsRecord & Partial<StageFunnelData>;

export interface UserWithJobs {
    id: string;
    firstName: string;
    jobs: Array<{
        job: {
            id: string;
            title: string;
            client: {
                id: string;
                name: string;
            };
        };
    }>;
}

export interface Stage {
    id: number;
    name: string;
    label: string;
    count: number;
}
export interface Candidate {
    personId: string;
    jobId: string;
    assignee: {
        id: string;
        name: {
            first: string;
            last: string;
            full: string;
        };
    };
    person: {
        name: {
            first: string;
            last: string;
            full: string;
        };
        profilePicUrl: string;
        phoneNumbers: Array<{ value: string }>;
    };
    job: {
        title: string;
        client: {
            id: string;
            name: string;
        };
    };
    stage: {
        id: number;
        name: string;
        label: string;
    };
    lastEmailExchangedAt: number;
    lastStageChangedAt: number;
    interviews: Interview[];
    pendingEmails: Array<{
        account: string;
        messageId: string;
        isClientComm?: boolean;
        category: CommunicationCategory;
    }>;
    emailSentiment: EmailSentiment;
    disqualified: boolean;
    disqualReason: CandidateDisqualReason;
    sortOrder: number;
}

export interface CandidateModifiedAt {
    candidates_aggregate: {
        aggregate: {
            max: {
                modifiedAt: number;
            };
        };
    };
}

export interface LatestUserCandidateData {
    users: Array<{
        lastCandidateModifiedAt: number;
    }>;
}

export interface StageCandidatesVars {
    jobIds?: string[];
    assignee?: string;
    stageId: number;
    minSortOrder: number;
    maxSortOrder: number;
    disqualified?: boolean;
    excludeArchivedJobs?: boolean;
}

export interface StageCountsVars {
    jobIds?: string[];
    assignee?: string;
    disqualified?: boolean;
}

const candidateFields = gql`
    fragment candidateFields on stage_candidates_sorted_table {
        _id
        personId
        jobId
        person {
            name
            profilePicUrl
            phoneNumbers: contacts(where: { invalid: { _eq: false }, channel: { _eq: "phone" } }, limit: 1) {
                value
            }
        }
        job {
            title
            client {
                id
                name
            }
        }
        stage: job_stage {
            id
            name
            label
        }
        assignee: userByAssignee {
            id
            name
        }
        lastEmailExchangedAt
        lastStageChangedAt
        interviews
        pendingEmails
        emailSentiment
        disqualified
        disqualReason
        sortOrder
    }
`;

export const STAGE_CANDIDATES = gql`
    ${candidateFields}
    query JobStageCandidates(
        $jobIds: jsonb
        $assignee: String
        $stageId: Int!
        $minSortOrder: bigint!
        $maxSortOrder: bigint!
        $disqualified: Boolean
        $excludeArchivedJobs: Boolean
    ) {
        candidates: stage_candidates_sorted(
            args: {
                jobIds: $jobIds
                assignee: $assignee
                disqualified: $disqualified
                minStageId: $stageId
                maxStageId: $stageId
                excludeArchivedJobs: $excludeArchivedJobs
            }
            where: { sortOrder: { _gte: $minSortOrder, _lte: $maxSortOrder } }
        ) {
            ...candidateFields
        }
    }
`;

export const LATEST_JOB_CANDIDATE = gql`
    subscription LatestJobCandidateModified($jobId: String!) {
        candidates_aggregate(where: { jobId: { _eq: $jobId } }) {
            aggregate {
                max {
                    modifiedAt
                }
            }
        }
    }
`;

export const LATEST_CLIENT_CANDIDATE = gql`
    subscription LatestClientCandidateModified($clientId: String!) {
        candidates_aggregate(where: { job: { clientId: { _eq: $clientId } } }) {
            aggregate {
                max {
                    modifiedAt
                }
            }
        }
    }
`;

export const LATEST_USER_CANDIDATE = gql`
    subscription LatestUserCandidateModifiedAt($username: String!) {
        users(where: { username: { _eq: $username } }) {
            lastCandidateModifiedAt
        }
    }
`;

export const CANDIDATES_STAGE_COUNTS = gql`
    query CandidatesStageCounts($jobIds: jsonb, $assignee: String, $disqualified: Boolean) {
        stageCounts: stage_candidates_counts(
            args: { jobIds: $jobIds, assignee: $assignee, disqualified: $disqualified }
        ) {
            id
            name
            label
            candidatesCount
            pendingEmailsCount
            interviewsCount
        }
    }
`;

export const USER_WITH_JOBS = gql`
    query UserJobs($username: String!) {
        users(where: { username: { _eq: $username } }) {
            id
            firstName: name(path: "first")
            jobs: jobsWithPostOutreachActiveCandidates(
                order_by: [{ job: { client: { name: asc } } }, { job: { title: asc } }]
            ) {
                job {
                    id
                    title
                    client {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const USER_WITH_AM_JOBS = gql`
    query UserJobs($username: String!) {
        users(where: { username: { _eq: $username } }) {
            id
            firstName: name(path: "first")
            jobs: jobsWithActiveAMCandidates(order_by: [{ job: { client: { name: asc } } }, { job: { title: asc } }]) {
                job {
                    id
                    title
                    client {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const STAGE_FUNNEL_METRICS = gql`
    query StageFunnel($jobIds: jsonb, $assignee: String) {
        funnel: stage_funnel_counts(args: { jobIds: $jobIds, assignee: $assignee }) {
            id
            name
            count
            prevCount
            last14d
            target
        }
    }
`;
