import { Dialog } from '@material-ui/core';
import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { replaceHistory } from '../common/history-replace';

import { Transition } from '../core-ui/dialog-transition';
import { usePrevious } from './use-previous';

interface PageDialogProviderProps {
    setDialog: (content: JSX.Element, url: string) => void;
    onClose: () => void;
}

const PageDialogContext = createContext<PageDialogProviderProps>({ setDialog: undefined, onClose: undefined });

const PageDialogProviderComponent: FC<RouteComponentProps> = ({ children, history }) => {
    const [dialogContent, setDialogContent] = useState<JSX.Element>(undefined);
    const [initialUrl, setInitialUrl] = useState<string>(undefined);
    const previousLocation = usePrevious(history.location.pathname);

    useEffect(() => {
        if (previousLocation !== undefined && previousLocation !== history.location.pathname) {
            setDialogContent(undefined);
            setInitialUrl(undefined);
        }
    }, [history.location.pathname]);

    const handleCloseDialog = () => {
        replaceHistory({}, '', initialUrl);
        setDialogContent(undefined);
        setInitialUrl(undefined);
    };

    const setDialog = (content: JSX.Element, url: string) => {
        setDialogContent(content);
        setInitialUrl(window.location.pathname);
        replaceHistory({}, '', url);
    };

    const onClose = !!dialogContent ? handleCloseDialog : undefined;

    const dialog = (
        <Dialog fullScreen={true} open={!!dialogContent} TransitionComponent={Transition} disableEnforceFocus={true}>
            {dialogContent}
        </Dialog>
    );

    return (
        <PageDialogContext.Provider value={{ setDialog, onClose }}>
            {children}
            {dialog}
        </PageDialogContext.Provider>
    );
};

const PageDialogProvider = withRouter(PageDialogProviderComponent);

const usePageDialog = () => useContext(PageDialogContext);

export { PageDialogProvider, usePageDialog };
