// cspell:ignore Ondemand
import { useQuery } from '@apollo/client';
import React from 'react';
import DocumentTitle from 'react-document-title';

import { CLIENT_MEETINGS, Meeting } from '../graphql/queries/clients';
import { ClientPageHeader } from './client-page-header';
import { Meetings } from './meetings';

export const ClientMeetings: React.FC<{ id: string }> = ({ id }) => {
    const { data, refetch } = useQuery<{ meetings: Meeting[] }, { clientId: string }>(CLIENT_MEETINGS, {
        variables: { clientId: id }
    });

    return (
        <DocumentTitle title="Client Meetings">
            <div id="container">
                <ClientPageHeader clientId={id} actions={[]} activeTab="Meetings" />
                <Meetings meetings={data?.meetings} refetch={refetch} jobIds={[]} />
            </div>
        </DocumentTitle>
    );
};
