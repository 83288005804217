import { useQuery, useSubscription } from '@apollo/client';
import { pickBy } from 'lodash';
import React from 'react';
import DocumentTitle from 'react-document-title';

import { NoteKind } from 'shared/models/note';

import { CandidatesOutreach } from '../components/candidates-outreach-button';
import { ClientReminderEmails } from '../components/client-reminder-email';
import { JobAddCandidates } from '../components/job-add-candidates';
import { JobPageHeader } from '../components/job-page-header';
import { JobScheduledOutreach } from '../components/job-scheduled-outreach-button';
import { Kanban } from '../components/kanban';
import { PurgeCandidates } from '../components/purge-job-stage-candidates';
import { PrimaryToggle } from '../core-ui/primary-toggle';
import {
    CandidateModifiedAt,
    CANDIDATES_STAGE_COUNTS,
    LATEST_JOB_CANDIDATE,
    STAGE_FUNNEL_METRICS,
    StageCountsRecord,
    StageCountsRecordWithFunnel,
    StageCountsVars,
    StageFunnelData
} from '../graphql/queries/candidates-board';
import { JOB_FOR_OUTREACH } from '../graphql/queries/outreach';
import { useSlides } from '../hooks/use-candidate-slides';
import { CandidatesBoardProvider } from '../hooks/use-candidates-board';
import { JobStatus } from '../state';

const JobCandidatesBoardDataLoader: React.FC<{ jobId: string; disqualified: boolean }> = ({ jobId, disqualified }) => {
    const [lastModifiedCandidate, setLastModifiedCandidate] = React.useState<number>(undefined);
    const { data: candidateModifiedAtData } = useSubscription<CandidateModifiedAt, { jobId: string }>(
        LATEST_JOB_CANDIDATE,
        { variables: { jobId } }
    );
    const { data: stageCountsData, refetch: refetchStageCountsData } = useQuery<
        { stageCounts: StageCountsRecord[] },
        StageCountsVars
    >(CANDIDATES_STAGE_COUNTS, {
        variables: { jobIds: [jobId], disqualified }
    });
    const { data: funnelData, refetch: refetchFunnelData } = useQuery<
        { funnel: StageFunnelData[] },
        { jobIds: string[] }
    >(STAGE_FUNNEL_METRICS, {
        variables: { jobIds: [jobId] }
    });

    React.useEffect(() => {
        if (candidateModifiedAtData?.candidates_aggregate.aggregate.max.modifiedAt !== lastModifiedCandidate) {
            setLastModifiedCandidate(candidateModifiedAtData?.candidates_aggregate.aggregate.max.modifiedAt);
        }
    }, [candidateModifiedAtData]);

    React.useEffect(() => {
        if (candidateModifiedAtData) {
            refetchStageCountsData();
            refetchFunnelData();
        }
    }, [lastModifiedCandidate, disqualified]);

    let stageCounts: StageCountsRecordWithFunnel[];
    if (stageCountsData) {
        stageCounts = [];
        for (const stage of stageCountsData.stageCounts) {
            const funnelCounts = funnelData?.funnel.find((s) => s.id === stage.id);
            stageCounts.push({
                ...stage,
                ...funnelCounts
            });
        }
    }

    return (
        <CandidatesBoardProvider
            assignee={undefined}
            jobIds={[jobId]}
            disqualified={disqualified}
            showAssigneeName={true}
            showJobName={false}
            showClientName={false}
        >
            <Kanban stageCounts={stageCounts} />
        </CandidatesBoardProvider>
    );
};

export const JobCandidatesBoard: React.FC<{ jobId: string; candidateId?: string }> = ({ jobId, candidateId }) => {
    const { setList } = useSlides();
    const [disqualified, setDisqualified] = React.useState(false);
    const { data, loading } = useQuery<
        {
            job: {
                id: string;
                title: string;
                status: JobStatus;
                dailyEmailLimit: number;
                client: { name: string };
            };
        },
        {
            jobId: string;
        }
    >(JOB_FOR_OUTREACH, { variables: { jobId } });

    React.useEffect(() => {
        if (data?.job?.status === JobStatus.Archived) {
            setDisqualified(undefined);
        }
    }, [data]);

    React.useEffect(() => {
        if (candidateId) {
            let selectedTab;
            let jobNotesOnly;
            let notesKind: NoteKind;
            if (window.location.search) {
                const params = new URLSearchParams(window.location.search);
                const paramsNoteKind = params.get('noteKind');
                if (paramsNoteKind === 'submission' || paramsNoteKind === 'all' || paramsNoteKind === 'phonescreen') {
                    selectedTab = 'notes';
                    jobNotesOnly = true;
                    notesKind = paramsNoteKind;
                } else if (params.get('tab')) {
                    selectedTab = params.get('tab');
                }
            }
            const candidateKey = { personId: candidateId, ...pickBy({ jobId, selectedTab, jobNotesOnly, notesKind }) };
            setList([candidateKey], candidateKey);
        }
    }, []);

    const handleToggleDisqualify = () => {
        setDisqualified(!disqualified);
    };

    const initialOutreach = loading ? null : (
        <CandidatesOutreach
            client={data?.job?.client}
            jobId={jobId}
            key="send-outreach"
            dailyEmailLimit={data?.job?.dailyEmailLimit}
            status={data?.job?.status}
            iconColor="white"
        />
    );

    const jobScheduledOutreach = loading ? null : <JobScheduledOutreach jobId={jobId} key="scheduled-outreach" />;

    const qualifiedToggle =
        data && data?.job?.status !== JobStatus.Archived ? (
            <PrimaryToggle
                key="disqualified-toggle"
                checked={!disqualified}
                label={disqualified ? 'Disqualified' : 'Qualified'}
                onChange={handleToggleDisqualify}
            />
        ) : null;
    const purgeCandidates = <PurgeCandidates jobId={jobId} key="purge-candidates" />;
    const clientReminder = <ClientReminderEmails jobId={jobId} key="client-reminder" />;

    const title = data ? `${data?.job?.client.name} - ${data?.job?.title}` : '';
    const addCandidates = data?.job?.status === JobStatus.Active ? <JobAddCandidates jobId={jobId} /> : null;
    const actions = [
        qualifiedToggle,
        addCandidates,
        initialOutreach,
        jobScheduledOutreach,
        purgeCandidates,
        clientReminder
    ].filter((v) => v);

    return (
        <DocumentTitle title={title}>
            <div id="container">
                <JobPageHeader jobId={jobId} actions={actions} activeTab="Candidates" />
                <div id="content" className="flex-fill">
                    <JobCandidatesBoardDataLoader jobId={jobId} disqualified={disqualified} />
                </div>
            </div>
        </DocumentTitle>
    );
};
