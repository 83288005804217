import { IconButton, ListItem, ListItemSecondaryAction, ListItemText, Tooltip } from '@material-ui/core';
import { AttachMoney, Help, HourglassEmpty, Warning } from '@material-ui/icons';
import React from 'react';

import { getInvoiceStatusLabel, invoiceStatus } from '../common/billing';
import { fullDate } from '../common/timestamp';
import { Invoice as InvoiceData } from '../graphql/queries/billing';
import { InvoiceDetailsDialog } from './invoice-details-dialog';

export const Invoice: React.FC<{ invoice: InvoiceData }> = ({ invoice }) => {
    const [viewDetails, setViewDetails] = React.useState(false);

    const handleInvoiceClick = () => {
        setViewDetails(true);
    };

    const handleCloseInvoiceDetails = () => {
        setViewDetails(false);
    };

    const status = invoiceStatus(invoice);

    const notAppliedFees =
        status === 'FEE_NOT_FOUND' ? (
            <Tooltip title="Not all line items have fees associated">
                <IconButton onClick={handleInvoiceClick}>
                    <Help />
                </IconButton>
            </Tooltip>
        ) : null;

    const paymentStatusIcon = {
        OPEN: <HourglassEmpty />,
        OVERDUE: <Warning />,
        PAID_IN_FULL: <AttachMoney />,
        PARTIAL_PAYMENT: <HourglassEmpty />,
        SCHEDULED: <HourglassEmpty />,
        UNDEFINED: <HourglassEmpty />
    }[status === 'FEE_NOT_FOUND' ? invoice.status : status];

    const paymentStatusIndicator = (
        <Tooltip title={getInvoiceStatusLabel(status === 'FEE_NOT_FOUND' ? invoice.status : status)}>
            <IconButton
                onClick={handleInvoiceClick}
                className={invoice.status === 'PAID_IN_FULL' ? 'show-on-hover' : undefined}
            >
                {paymentStatusIcon}
            </IconButton>
        </Tooltip>
    );

    const secondaryText = (
        <span>
            ${invoice.totalAmount.toLocaleString()}
            <br />
            {fullDate(invoice.invoiceDate)}
        </span>
    );

    const invoiceDetails = viewDetails ? (
        <InvoiceDetailsDialog open={viewDetails} onClose={handleCloseInvoiceDetails} invoice={invoice} />
    ) : null;

    return (
        <React.Fragment>
            <ListItem key={invoice.id} button={true} onClick={handleInvoiceClick}>
                <ListItemText primary={invoice.invoiceNumber} secondary={secondaryText} />
                <ListItemSecondaryAction>
                    {paymentStatusIndicator}
                    {notAppliedFees}
                </ListItemSecondaryAction>
            </ListItem>
            {invoiceDetails}
        </React.Fragment>
    );
};
