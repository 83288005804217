import * as React from 'react';
import { connect } from 'react-redux';

import { Loading } from '../../core-ui/loading';
import { State } from '../../state';
import { EmailInboxData } from '../types';
import { EmailInboxMessage } from './email-inbox-message';

interface ConnectedProps {
    data: EmailInboxData;
}

type EmailInboxThreadDetailsProps = ConnectedProps;

export class EmailInboxThreadDetailsComponent extends React.Component<EmailInboxThreadDetailsProps, undefined> {
    threadVisibleMessages = () => {
        const { data } = this.props;
        const thread = data.threads.find((t) => t.id === data.viewingThread);
        return thread.messages.filter((m) => {
            switch (data.selectedLabel) {
                case 'DRAFT':
                    return m.labels.indexOf('DRAFT') !== -1 && m.labels.indexOf('TRASH') === -1;
                case 'TRASH':
                    return m.labels.indexOf('TRASH') !== -1;
                default:
                    return m.labels.indexOf('DRAFT') === -1 && m.labels.indexOf('TRASH') === -1;
            }
        });
    };

    render() {
        const { data } = this.props;
        const thread = data.threads.find((t) => t.id === data.viewingThread);
        // when navigating across page boundaries, this may go null while we load the message
        if (!thread) return <Loading />;
        const messageList = this.threadVisibleMessages();
        let foundMessageToScrollTo = false;
        const messages = messageList.map((m, i) => {
            const collapsed = m.labels.indexOf('UNREAD') === -1 && i < messageList.length - 1;
            const lastMessageInThread = i === messageList.length - 1;
            const startScrolledTo = !foundMessageToScrollTo && !collapsed;
            if (startScrolledTo) {
                foundMessageToScrollTo = true;
            }
            return (
                <EmailInboxMessage
                    message={m}
                    account={data.account}
                    key={m.messageId}
                    startCollapsed={collapsed}
                    lastMessageInThread={lastMessageInThread}
                    startScrolledTo={startScrolledTo}
                />
            );
        });
        return (
            <div className="email-inbox-thread-details">
                <div className="email-inbox-thread-subject">{messageList[0].subject}</div>
                <div className="email-inbox-thread-scroll-container">
                    <div className="email-inbox-thread-messages">{messages}</div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    data: state.emailInbox
});

export const EmailInboxThreadDetails = connect<ConnectedProps, undefined, {}>(mapStateToProps)(
    EmailInboxThreadDetailsComponent
);
