import { css } from '@emotion/core';
import { Button, IconButton, MenuItem, TextField, Theme, Tooltip, Typography, useTheme } from '@material-ui/core';
import { BarChart, PieChart, Reorder, TableChart } from '@material-ui/icons';
import { saveAs } from 'file-saver';
import { kebabCase, orderBy, pick, startCase } from 'lodash';
import { unparse } from 'papaparse';
import React from 'react';

import { clusterData, yoeClusterBoundaries } from 'shared/talent-intelligence/cluster-data';
import { CompanyData, EntityCount } from 'shared/talent-intelligence/get-company-stats';

import { fetchCompanyTalentData } from '../../api';
import { jobDisciplines } from '../../common/job-disciplines';
import { isValidUrl } from '../../common/utils';
import { CompanyNameSelectField } from './company-name-select';
import { DataPanel } from './data-panel';

const styles = (theme: Theme) => css`
    background: #f4f6f8;
    flex: 1 1 auto;
    overflow: auto;
    padding: 25px 50px;

    .talent-form {
        display: flex;

        .company-name-select,
        .discipline-select {
            margin-right: 20px;
        }

        .company-name-select {
            flex: 3;
        }

        .discipline-select {
            flex: 2;
        }

        .MuiFormControl-root {
            .MuiInputBase-root {
                background: white;
            }
        }
    }

    .talent-data-header {
        margin-top: 20px;
        background: white;
        border-radius: ${theme.shape.borderRadius}px;
        border: thin solid ${theme.palette.divider};
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
    }

    .buttons {
        display: flex;
        align-items: center;
    }

    .talent-numbers {
        display: flex;
        margin: 0 -16px;

        .number-section {
            flex: 1 1 auto;
            border-right: thin solid ${theme.palette.divider};
            &:last-child {
                border-right: none;
            }
            margin: 0 16px;
            padding: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .value-download {
                display: flex;
                align-items: center;

                .MuiIconButton-root {
                    margin-left: 10px;
                }
            }
        }
    }

    .MuiTypography-h4 {
        font-size: 16px;
        padding: 0;
    }

    .MuiIconButton-root {
        margin-left: 15px;
    }

    .talent-tables {
        margin-top: 20px;
        margin-bottom: 20px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;

        .MuiTableCell-root.count-col {
            width: 120px;
            text-align: right;
        }

        .MuiTableRow-root.totals-row {
            .MuiTableCell-root {
                font-weight: bold;
            }
        }

        .content-wrapper {
            max-height: 368px;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 6px;
            }

            &.bar {
                padding-right: 16px;
                padding-top: 8px;
                height: 480px;
                max-height: 480px;
                overflow-y: hidden;

                .bar-chart {
                    height: 100%;
                }
            }

            &.pie {
                padding: 16px;
                height: 480px;
                max-height: 480px;
                overflow-y: hidden;

                .pie-chart {
                    height: 100%;
                }
            }
        }

        .table-section {
            background: white;
            border: thin solid ${theme.palette.divider};
            border-radius: ${theme.shape.borderRadius}px;
            overflow: hidden;

            .table-section-header {
                border-bottom: thin solid ${theme.palette.divider};
                display: flex;
                justify-content: space-between;
                padding: 14px;
                align-items: center;
            }
        }
    }
`;

function roundToTwo(num: number) {
    return Math.round((num + Number.EPSILON) * 100) / 100;
}

function mapArrayToAvg<T extends { yoe: number }>(records: T[]) {
    const value = records.reduce((acc, curr) => ({ sum: acc.sum + curr.yoe, count: acc.count + 1 }), {
        count: 0,
        sum: 0
    });
    return roundToTwo(value.sum / value.count || 1);
}

export const CompanyTalent = () => {
    const theme = useTheme();
    const [view, setView] = React.useState<'table' | 'pie' | 'bar'>('bar');
    const [company, setCompany] = React.useState<{ name: string; url: string }>(undefined);
    const [discipline, setDiscipline] = React.useState('');
    const [isEditable, setIsEditable] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState<CompanyData>(null);
    const [fetchError, setFetchError] = React.useState<string>('');

    const handleSetView = (v: 'table' | 'pie' | 'bar') => () => {
        setView(v);
    };

    const handleCompanyChange = (selected: { url: string; name: string }) => {
        setCompany({ name: selected.name, url: selected.url });
        setData(null);
        setFetchError('');
    };

    const handleDisciplineChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDiscipline(e.target.value);
        setData(null);
        setFetchError('');
    };

    const handleSearch = async () => {
        setIsEditable(false);
        setData(null);
        setFetchError('');
        setLoading(true);
        const result = await fetchCompanyTalentData(company?.url, discipline);
        setLoading(false);
        if (result.success) {
            setData(result.data);
        } else {
            setFetchError('Something went wrong');
        }
        setIsEditable(true);
    };

    const handleDownloadProfiles = () => {
        const fileName = kebabCase(`${data?.companyName}-raw-profiles`).toLowerCase() + '.csv';
        const csvContent = unparse(data.profileUrls);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, fileName);
    };

    const handleDownloadWithProfiles = (title: string, ordered: EntityCount[]) => () => {
        const fileName = kebabCase(`${companyName}-raw-${title}`).toLowerCase() + '.csv';
        const keys = Object.keys(ordered[0]?.profiles?.[0]).filter(
            (k) => k !== 'url' && k !== 'startDate' && k !== 'endDate'
        );
        const lines = [];
        for (const entity of ordered) {
            for (const profile of entity.profiles) {
                const line = {
                    url: profile.url,
                    // tslint:disable-next-line: object-literal-sort-keys
                    startDate: profile.startDate,
                    endDate: profile.endDate,
                    ...pick(profile, keys),
                    entityName: entity.name,
                    entityUrl: entity.url
                };
                lines.push(line);
            }
        }
        const csvData = unparse(lines);
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, fileName);
    };

    const handleDownloadYoe = (yoeData: Array<{ url: string; yoe?: number; title?: string }>, title: string) => () => {
        const fileName = kebabCase(`${companyName}-raw-${title}`).toLowerCase() + '.csv';
        const csvContent = unparse(yoeData);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, fileName);
    };

    const disciplines = jobDisciplines.map((disciplineName) => {
        return (
            <MenuItem key={disciplineName} value={disciplineName}>
                {startCase(disciplineName)}
            </MenuItem>
        );
    });

    const canSubmit = isValidUrl(company?.url);
    const companyName = data?.companyName;

    const dataTablesContent = [];
    if (data) {
        const orderedSchoolRanks = orderBy(
            data.schoolRanks,
            [(val) => parseInt(val.name.replace(/^top\s*/i, ''), 10), (val) => val.name.match(/\+/)],
            ['asc', 'desc']
        );
        dataTablesContent.push(
            <DataPanel
                key="last-company"
                companyName={companyName}
                title="Last Company"
                data={data.mostRecentPastCompanies}
                view={view}
                sort={true}
                onRawDownload={handleDownloadWithProfiles('last-company', data.mostRecentPastCompanies)}
            />,
            <DataPanel
                key="last-company-size"
                companyName={companyName}
                title="Last Company Size"
                data={data.mostRecentCompanySize}
                view={view}
                sort={true}
                onRawDownload={handleDownloadWithProfiles('last-company-size', data.mostRecentCompanySize)}
            />,
            <DataPanel
                key="last-company-funding"
                companyName={companyName}
                title="Last Company Latest Funding Series"
                data={data.mostRecentCompanyFundingSeries}
                view={view}
                sort={true}
                onRawDownload={handleDownloadWithProfiles('last-company-funding', data.mostRecentCompanyFundingSeries)}
            />,
            <DataPanel
                key="last-company-amount"
                companyName={companyName}
                title="Last Company Total Funding Amount"
                view={view}
                sort={true}
                data={data.mostRecentCompanyFundingAmount}
                onRawDownload={handleDownloadWithProfiles('last-company-amount', data.mostRecentCompanyFundingAmount)}
            />,
            <DataPanel
                key="school"
                companyName={companyName}
                title="School"
                sort={true}
                data={data.schools}
                view={view}
                onRawDownload={handleDownloadWithProfiles('school', data.schools)}
            />,
            <DataPanel
                key="school-ranks"
                companyName={companyName}
                title="School Ranks"
                sort={false}
                data={orderedSchoolRanks}
                view={view}
                onRawDownload={handleDownloadWithProfiles('school-ranks', data.schoolRanks)}
            />
        );
    }
    let yoeLatest;
    let yoeOverall;

    if (data) {
        const yoeData = data
            ? clusterData(
                  data.yoeLastCompany.map((v) => v.yoe),
                  yoeClusterBoundaries
              )
            : undefined;
        yoeLatest = (
            <DataPanel
                companyName={companyName}
                title="YOE Last Company"
                data={yoeData}
                view={view}
                sort={false}
                secondaryTitle={`${mapArrayToAvg(data.yoeLastCompany)} Average`}
                onRawDownload={handleDownloadYoe(data.yoeLastCompany, 'yoe-last-company')}
            />
        );

        const yoeOverallData = data
            ? clusterData(
                  data.yoeOverall.map((v) => v.yoe),
                  yoeClusterBoundaries
              )
            : undefined;
        yoeOverall = (
            <DataPanel
                companyName={companyName}
                title="YOE Overall"
                data={yoeOverallData}
                secondaryTitle={`${mapArrayToAvg(data.yoeOverall)} Average`}
                view={view}
                sort={false}
                onRawDownload={handleDownloadYoe(data.yoeOverall, 'yoe-overall')}
            />
        );
    }

    const dataErrorsContent = fetchError ? (
        <div className="error">
            <span>{fetchError}</span>
        </div>
    ) : null;

    const viewButtons = (
        <>
            <Tooltip title="Pie Chart">
                <IconButton size="small" onClick={handleSetView('pie')}>
                    <PieChart fontSize="small" />
                </IconButton>
            </Tooltip>
            <Tooltip title="Bar Chart">
                <IconButton size="small" onClick={handleSetView('bar')}>
                    <BarChart fontSize="small" />
                </IconButton>
            </Tooltip>
            <Tooltip title="Table">
                <IconButton size="small" onClick={handleSetView('table')}>
                    <TableChart fontSize="small" />
                </IconButton>
            </Tooltip>
        </>
    );

    const headerSection = data ? (
        <div className="talent-data-header">
            <div className="company-name">
                <Typography variant="h4" component="h4">
                    {companyName} Talent Data
                </Typography>
            </div>
            <div className="buttons">
                <Typography variant="h4" component="h4">
                    {data.profileUrls.length} People
                </Typography>
                <Tooltip title="Download Raw Data">
                    <IconButton size="small" onClick={handleDownloadProfiles}>
                        <Reorder fontSize="small" />
                    </IconButton>
                </Tooltip>
                {viewButtons}
            </div>
        </div>
    ) : null;

    return (
        <div css={styles(theme)}>
            <div className="talent-form">
                <CompanyNameSelectField selected={company} onSelect={handleCompanyChange} disabled={!isEditable} />
                <TextField
                    label="Discipline"
                    select={true}
                    value={discipline}
                    onChange={handleDisciplineChange}
                    disabled={!isEditable}
                    variant="outlined"
                    className="discipline-select"
                >
                    <MenuItem value="">Any</MenuItem>
                    {disciplines}
                </TextField>
                <Button variant="contained" color="primary" disabled={!canSubmit || loading} onClick={handleSearch}>
                    Search
                </Button>
            </div>
            {headerSection}
            <div className="talent-tables">
                {yoeLatest}
                {yoeOverall}
                {dataTablesContent}
                {dataErrorsContent}
            </div>
        </div>
    );
};
