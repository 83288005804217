import { useQuery } from '@apollo/client';
import * as React from 'react';

import { internalClientIds } from 'shared/models/client';

import { ClientsTable as ClientsTableComponent } from '../components/clients-table';
import { Spinner } from '../core-ui/spinner';
import { CLIENTS, ClientWithJobCounts } from '../graphql/queries/clients';

const pollInterval = 60000;

export const ClientsTable: React.FC<{}> = () => {
    const { data, refetch } = useQuery<{ clients: ClientWithJobCounts[] }, { excludeClientIds: string[] }>(CLIENTS, {
        pollInterval,
        variables: { excludeClientIds: internalClientIds }
    });

    if (data) {
        const { clients } = data;
        return <ClientsTableComponent clients={clients} refetch={refetch} />;
    } else {
        return <Spinner />;
    }
};
