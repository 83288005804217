import { css } from '@emotion/core';
import { Dialog } from '@material-ui/core';
import { Map, OrderedMap } from 'immutable';
import { IconButton } from 'material-ui';
import { SocialPerson } from 'material-ui/svg-icons';
import * as React from 'react';
import { connect } from 'react-redux';

import { Permissions } from 'shared/models/permission';
import { hasRole, UserData } from 'shared/models/user';

import { reassignCandidateAssignee } from '../actions';
import { Candidate, RequestErrors, State } from '../state';
import { UserSelector } from './user-selector';

const styles = css`
    .MuiDialog-paper {
        width: 800px;
        padding: 25px;
    }
`;

interface OwnProps {
    personId: string;
    jobId: string;
}

interface ConnectedProps {
    candidates: Map<string, OrderedMap<string, Candidate>>;
    users: Map<string, UserData>;
    pendingRequests: Map<string, RequestErrors>;
    userPermissions: Permissions;
    currentUser: UserData;
}

interface ConnectedDispatch {
    reassignCandidateAssignee: (personId: string, jobId: string, newAssigneeId: string) => void;
}

type ReassignCandidateAssigneeProps = OwnProps & ConnectedProps & ConnectedDispatch;

const ReassignCandidateAssigneeComponent: React.FC<ReassignCandidateAssigneeProps> = (props) => {
    const { personId, jobId, candidates, pendingRequests, userPermissions, currentUser } = props;

    const candidate = candidates.get(jobId).get(personId);
    if (
        !hasRole(userPermissions, 'can_reassign_candidate') &&
        currentUser.id !== candidate.assignee &&
        currentUser.id !== candidate.accountManagerId
    ) {
        return null;
    }

    const [userDialogOpen, setUserDialogState] = React.useState(false);

    const handleRequestOpenUserDialog = () => {
        setUserDialogState(true);
    };

    const closeUserDialog = () => {
        setUserDialogState(false);
    };

    const reassignAssignee = (newAssigneeId: string) => {
        props.reassignCandidateAssignee(personId, jobId, newAssigneeId);
        closeUserDialog();
    };

    const userFilter = (user: UserData) => {
        return user.status === 'active';
    };

    const userDialog = (
        <Dialog
            className="reassign-candidate-assignee-dialog"
            css={styles}
            open={userDialogOpen}
            onClose={closeUserDialog}
        >
            <UserSelector
                onSelect={reassignAssignee}
                value={candidate.assignee}
                label="Candidate Assignee"
                userFilter={userFilter}
                fullWidth={true}
            />
        </Dialog>
    );

    const disabled = pendingRequests.has(`candidate-reassign-assignee-${candidate.personId}-${candidate.jobId}`);
    return (
        <div className="header-candidate-action">
            <IconButton tooltip="Reassign Candidate" onClick={handleRequestOpenUserDialog} disabled={disabled}>
                <SocialPerson color="white" />
            </IconButton>
            {userDialog}
        </div>
    );
};

const mapStateToProps = (state: State) => ({
    candidates: state.candidates,
    currentUser: state.session.user,
    pendingRequests: state.pendingRequests,
    userPermissions: state.session.userPermissions,
    users: state.users
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    reassignCandidateAssignee
};

export const ReassignCandidateAssignee = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(ReassignCandidateAssigneeComponent);
