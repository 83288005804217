import { CrunchbaseCompanyFilters, employeeSizeOptions, MAX_FUNDING } from 'shared/common/crunchbase-filters';

import { CrunchbaseCompanyFilterQueryParams } from '../../graphql/queries/crunchbase';

export const getGraphRequestParams = (
    filters: Partial<CrunchbaseCompanyFilters>
): CrunchbaseCompanyFilterQueryParams => {
    const params: CrunchbaseCompanyFilterQueryParams = {};
    if (filters.size) {
        const sizeOpts = employeeSizeOptions(filters.size);
        if (sizeOpts.length > 0) {
            params.employee_count = { _in: sizeOpts };
        }
    }
    if (filters.funding && (filters.funding[0] !== 0 || filters.funding[1] !== MAX_FUNDING)) {
        params.total_funding_usd = {};
        if (filters.funding[0] && filters.funding[0] !== 0) {
            params.total_funding_usd._gte = filters.funding[0];
        }
        if (filters.funding[1] && filters.funding[1] !== MAX_FUNDING) {
            params.total_funding_usd._lte = filters.funding[1];
        }
    }
    const currentYear = new Date().getFullYear();
    if (filters.foundingYear && (filters.foundingYear[0] !== 0 || filters.foundingYear[1] !== currentYear)) {
        params.founded_on = {};
        const [minYear, maxYear] = filters.foundingYear;
        if (minYear && minYear !== 0) {
            params.founded_on._gte = `${minYear}-01-01`;
        }
        if (maxYear && maxYear !== currentYear) {
            params.founded_on._lte = `${maxYear}-12-31`;
        }
    }
    if (filters.fundingRounds?.length > 0) {
        params.latest_funding_round = { investment_type: { _in: filters.fundingRounds } };
    }
    if (filters.industries?.length > 0) {
        params.categories = { category: { _in: filters.industries } };
    }
    if (filters.industryGroups?.length > 0) {
        params.category_groups = { category_group: { _in: filters.industryGroups } };
    }
    if (filters.status?.length > 0) {
        params.status = { _in: filters.status };
    }
    if (filters?.lastFundingRound?.length > 0) {
        params.latest_funding_round = params.latest_funding_round ?? { funding_date: {} };
        if (!params.latest_funding_round.funding_date) {
            params.latest_funding_round.funding_date = {};
        }
        const [minYear, maxYear] = filters.lastFundingRound;
        if (minYear && minYear !== 0) {
            params.latest_funding_round.funding_date._gte = `${minYear}-01-01`;
        }
        if (maxYear && maxYear !== currentYear) {
            params.latest_funding_round.funding_date._lte = `${maxYear}-12-31`;
        }
    }
    if (filters?.countries?.length > 0) {
        params.country_code = { _in: filters.countries };
    }
    if (filters?.regions?.length > 0) {
        params.region = { _in: filters.regions };
    }
    if (filters?.cities?.length > 0) {
        params.city = { _in: filters.cities };
    }
    if (filters?.investors?.length > 0) {
        params.funding_rounds = { investment: { investor_uuid: { _in: filters.investors?.map((i) => i.uuid) } } };
    }
    return params;
};
