import { Map } from 'immutable';

import {
    Action,
    ReceiveAddContactFromMessage,
    ReceiveCandidate,
    ReceiveContactsUpdate,
    ReceivePersonContacts,
    ReceivePersonDetails,
    RequestContactDelete
} from '../actions';
import { Contact } from '../state';

const contactsInitialState: Map<string, Contact[]> = Map();

export function contacts(state = contactsInitialState, action: Action) {
    switch (action.type) {
        case ReceiveCandidate:
            return action.payload.contacts ? state.set(action.payload.person.id, action.payload.contacts) : state;
        case ReceiveContactsUpdate:
        case ReceiveAddContactFromMessage:
            return state.set(action.payload.personId, action.payload.contacts);
        case ReceivePersonContacts:
            return state.set(action.payload.personId, action.payload.contacts);
        case ReceivePersonDetails:
            return state.set(action.payload.details.person.id, action.payload.details.contacts);
        case RequestContactDelete: {
            let newState = state;
            let updatedContact = newState.get(action.payload.personId);
            updatedContact = updatedContact.filter((c) => c.value !== action.payload.value);
            newState = newState.set(action.payload.personId, updatedContact);
            return newState;
        }
        default:
            return state;
    }
}
