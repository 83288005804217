import { useLazyQuery } from '@apollo/client';
import { debounce } from 'lodash';
import React, { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { logger } from '../common/logger';
import { CRUNCHBASE_ORGANIZATIONS, CrunchbaseOrganization } from '../graphql/queries/crunchbase';

interface CBDataState {
    isLoading: boolean;
    crunchbaseInfo?: CrunchbaseOrganization;
}

interface CBDataContextType {
    getCompanyData: (url: string) => CBDataState;
}

const CBDataContext = createContext<CBDataContextType | undefined>(undefined);

const debouncePeriodMs = 200;

export const CrunchbaseDataProvider: FC = ({ children }) => {
    const [data, setData] = useState<{ [url: string]: CBDataState }>({});
    const [urlsToFetch, setUrlsToFetch] = useState<Set<string>>(new Set());
    const [fetchingUrls, setFetchingUrls] = useState<Set<string>>(new Set());
    const [fetchData] = useLazyQuery<{ organizations: CrunchbaseOrganization[] }, { linkedinUrls: string[] }>(
        CRUNCHBASE_ORGANIZATIONS
    );

    const debouncedFetchData = useMemo(
        () =>
            debounce(async (urls: Set<string>) => {
                const urlArray = Array.from(urls);
                try {
                    setUrlsToFetch((prev) => new Set([...Array.from(prev)].filter((url) => !urls.has(url))));
                    setFetchingUrls((prev) => new Set([...Array.from(prev), ...urlArray]));
                    const res = await fetchData({ variables: { linkedinUrls: urlArray } });
                    setData((prev) => {
                        let updated = { ...prev };
                        for (const url of urlArray) {
                            const orgData = res.data.organizations.find((org) =>
                                org.linkedinRecruiterUrls.find((l) => l.url === url)
                            );
                            updated = {
                                ...updated,
                                [url]: { isLoading: false, crunchbaseInfo: orgData }
                            };
                        }
                        return updated;
                    });
                    setFetchingUrls((prev) => new Set([...Array.from(prev)].filter((url) => !urlArray.includes(url))));
                } catch (err) {
                    logger.debug('Error fetching crunchbase data', err);
                    setUrlsToFetch((prev) => new Set([...Array.from(prev), ...urlArray]));
                    setFetchingUrls((prev) => new Set([...Array.from(prev)].filter((url) => !urlArray.includes(url))));
                }
            }, debouncePeriodMs),
        [fetchData]
    );

    useEffect(() => {
        if (urlsToFetch.size > 0) {
            debouncedFetchData(urlsToFetch);
        }
    }, [urlsToFetch, debouncedFetchData]);

    const getCBData = useCallback(
        (url: string): CBDataState => {
            if (!url) return { isLoading: false };
            const loadingState: CBDataState = { isLoading: true };
            if (!data[url] && !fetchingUrls.has(url) && !urlsToFetch.has(url)) {
                setUrlsToFetch((prev) => new Set(prev).add(url));
            }
            return data[url] || loadingState;
        },
        [data, fetchingUrls, urlsToFetch]
    );

    const value = useMemo(() => ({ getCompanyData: getCBData }), [getCBData]);

    return <CBDataContext.Provider value={value}>{children}</CBDataContext.Provider>;
};

export const useCrunchbaseData = (url: string): CBDataState => {
    const context = useContext(CBDataContext);
    if (!context) {
        throw new Error('useCrunchbaseData must be used within a CrunchbaseDataProvider');
    }
    return context.getCompanyData(url);
};
