import { useQuery } from '@apollo/client';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import {
    awaitingClientStage,
    clientFinalRoundStage,
    clientFirstRoundStage,
    clientMiddleRoundStage,
    offerStage
} from 'shared/models/job-stages';

import { Home as HomeComponent } from '../components/home';
import {
    AM_JOBS,
    ASSIGNEE_CANDIDATES_BY_STAGE,
    Candidate,
    Interview,
    INTERVIEWS,
    Job,
    SUBMISSIONS
} from '../graphql/queries/home';
import { useNotificationsData } from '../hooks/use-notifications-data';
import { useUserContext } from '../hooks/use-user-context';
import { useUserDataChanges } from '../hooks/use-user-data-changes';

export const Home: React.FC = () => {
    const maxUpdateDelay = 900000; // 15 minutes
    const pollInterval = 900000; // 15 minutes
    const { user } = useUserContext();
    const userId = user.id;
    const [interviewsStartTime, setInterviewsStartTime] = useState(moment().startOf('day').valueOf());
    const [lastUpdateAt, setLastUpdateAt] = React.useState<number>(Date.now());
    const { dataModificationTs } = useUserDataChanges();
    const { pendingEmails, pendingEmailsArchived } = useNotificationsData();

    const submissionsQuery = useQuery<{ candidates: Candidate[] }, { userId: string }>(SUBMISSIONS, {
        pollInterval,
        variables: { userId }
    });
    const interviewsQuery = useQuery<{ interviews: Interview[] }, { userId: string; interviewsStartTime: number }>(
        INTERVIEWS,
        {
            pollInterval,
            variables: { userId, interviewsStartTime }
        }
    );
    const amJobsQuery = useQuery<{ jobs: Job[] }, { userId: string }>(AM_JOBS, {
        pollInterval,
        variables: { userId }
    });
    const candidateStages = [
        awaitingClientStage,
        clientFirstRoundStage,
        clientMiddleRoundStage,
        clientFinalRoundStage,
        offerStage
    ];
    const candidatesQuery = useQuery<{ candidates: Candidate[] }, { userId: string; stages: string[] }>(
        ASSIGNEE_CANDIDATES_BY_STAGE,
        {
            pollInterval,
            variables: { userId, stages: candidateStages }
        }
    );

    useEffect(() => {
        const updateDelay = Math.min(moment().endOf('day').valueOf() - Date.now() + 1, maxUpdateDelay);
        const timer = setTimeout(() => {
            setInterviewsStartTime(moment().startOf('day').valueOf());
            setLastUpdateAt(Date.now());
        }, updateDelay);
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [interviewsStartTime, lastUpdateAt]);

    useEffect(() => {
        if (!submissionsQuery.loading) {
            submissionsQuery.refetch();
        }
        if (!interviewsQuery.loading) {
            interviewsQuery.refetch();
        }
        if (!candidatesQuery.loading) {
            candidatesQuery.refetch();
        }
    }, [dataModificationTs?.lastCandidateModifiedAt]);

    useEffect(() => {
        if (!amJobsQuery.loading) {
            amJobsQuery.refetch();
        }
    }, [dataModificationTs?.lastJobModifiedAt]);

    return (
        <HomeComponent
            submissions={submissionsQuery?.data?.candidates}
            candidates={candidatesQuery?.data?.candidates}
            interviews={interviewsQuery?.data?.interviews}
            amJobs={amJobsQuery?.data?.jobs}
            pendingEmails={pendingEmails}
            pendingEmailsArchived={pendingEmailsArchived}
        />
    );
};
