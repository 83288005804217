import { useMutation } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import React from 'react';

import { ModelParameters } from 'shared/common/llm';
import { convertToHTML } from 'shared/common/text-to-html';
import { UserData } from 'shared/models/user';

import { getGeneratedSubmission } from '../api';
import { DurationProgress } from '../common/duration-progress';
import { UPDATE_USER } from '../graphql/queries/user';
import { JobTemplates } from '../state';
import { GPTChat } from './gpt-chat';

interface SubmissionGPTDialogProps {
    personId: string;
    job: {
        id: string;
        templates: JobTemplates;
    };
    userId: string;
    open: boolean;
    onConfirm: (text: string) => void;
    onSkip: () => void;
}

const loadingDurationSeconds = 60;

const styles = css`
    .MuiSkeleton-root {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const refineDialogStyles = css`
    .MuiDialogContent-root {
        overflow-y: hidden;
        display: flex;

        .gpt-completions-container {
            flex: 1 1 auto;
            max-height: 100%;
            overflow: hidden;
        }
    }
`;

export const SubmissionGPTDialog: React.FC<SubmissionGPTDialogProps> = ({
    open,
    job,
    personId,
    userId,
    onConfirm,
    onSkip
}) => {
    const [initialGenerating, setInitialGenerating] = React.useState<boolean | undefined>(undefined);
    const [generated, setGenerated] = React.useState<
        {
            error?: string;
            messages?: Array<{ role: 'user' | 'system' | 'assistant'; content: string }>;
        } & Partial<ModelParameters>
    >(undefined);
    const [refining, setRefining] = React.useState(false);
    const [] = useMutation<{}, { id: string; updates: Partial<UserData> }>(UPDATE_USER);

    const handleConfirm = () => {
        const html = convertToHTML(generated.messages[generated.messages.length - 1].content);
        navigator.clipboard.writeText(html);
        onConfirm(html);
    };

    const handleToggleRefining = () => setRefining(!refining);

    const handleGenerate = async () => {
        setInitialGenerating(true);
        try {
            const response = await getGeneratedSubmission(job.id, personId, userId);
            if (!response.success || response.data.error !== undefined) {
                setGenerated({
                    error: response.data.error ?? 'Error occurred when calling OpenAI',
                    ...response.data
                });
                setRefining(true);
            } else {
                setGenerated(response.data);
            }
        } catch (err) {
            setGenerated({ error: 'Error occurred when calling OpenAI: ' + err.message ?? 'Unknown error' });
        }
        setInitialGenerating(false);
    };

    const handleGPTRefineMessagesChange = (
        updatedMessages: Array<{ role: 'user' | 'system' | 'assistant'; content: string }>
    ) => {
        setGenerated({ ...generated, messages: updatedMessages });
    };

    const jobTemplatePlaceholderText = '{generated submission content | submission details}';
    const canUseGenerated =
        !job.templates?.submission || job.templates?.submission?.body?.includes(jobTemplatePlaceholderText);

    const hasResult =
        generated?.messages?.[generated.messages.length - 1].content !== undefined &&
        generated?.messages?.[generated.messages.length - 1].role === 'assistant';
    const result = hasResult ? generated.messages[generated.messages.length - 1].content : undefined;
    const initialPrompt =
        initialGenerating === undefined ? (
            <Typography variant="body1">Would you like Titan to automatically generate the submission?</Typography>
        ) : null;
    const generateButton =
        !refining && initialGenerating === undefined ? (
            <Tooltip title={canUseGenerated ? '' : 'Job submission template does not support generated submission'}>
                <span>
                    <Button onClick={handleGenerate} disabled={!canUseGenerated}>
                        Generate
                    </Button>
                </span>
            </Tooltip>
        ) : undefined;
    const continueButton =
        initialGenerating === false ? (
            <Button onClick={handleConfirm} disabled={!hasResult}>
                Use
            </Button>
        ) : undefined;
    const inProgressButton = initialGenerating === true ? <Button disabled={true}>Generating</Button> : undefined;
    const loading =
        initialGenerating === true ? (
            <div className="duration-loading">
                <DurationProgress duration={loadingDurationSeconds} />
            </div>
        ) : null;
    const errorInfo =
        !refining && generated?.error ? (
            <Typography variant="body1" color="error">
                {generated.error}
            </Typography>
        ) : null;
    const generatedText = refining ? null : result ? (
        <TextField value={result} variant="outlined" multiline={true} fullWidth={true} />
    ) : null;
    const refineButton = !refining && result ? <Button onClick={handleToggleRefining}>Refine</Button> : undefined;
    const { messages, ...modelParameters } = generated ?? {};
    const refineContent =
        refining && generated?.messages ? (
            <GPTChat
                modelParameters={modelParameters}
                messages={generated.messages}
                error={generated.error}
                onChange={handleGPTRefineMessagesChange}
            />
        ) : null;

    const dialogMaxWidth = refining ? 'lg' : initialGenerating !== false ? 'sm' : 'md';

    const dialogStyles = refining ? refineDialogStyles : styles;

    return (
        <Dialog open={open} css={dialogStyles} fullWidth={true} maxWidth={dialogMaxWidth}>
            <DialogTitle>
                <Typography variant="h4" component="div">
                    Generate Submission
                </Typography>
            </DialogTitle>
            <DialogContent>
                {initialPrompt}
                {loading}
                {errorInfo}
                {generatedText}
                {refineContent}
            </DialogContent>
            <DialogActions>
                <Button onClick={onSkip}>Skip</Button>
                {generateButton}
                {inProgressButton}
                {refineButton}
                {continueButton}
            </DialogActions>
        </Dialog>
    );
};
