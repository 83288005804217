import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ClientCandidates } from '../containers/client-candidates';

interface RouterProps {
    id: string;
}

const ClientCandidatesComponent: React.FC<RouteComponentProps<RouterProps>> = ({ match }) => {
    return <ClientCandidates id={match.params.id} />;
};

export const ClientCandidatesPage = withRouter(ClientCandidatesComponent);
