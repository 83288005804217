import { Map } from 'immutable';
import { merge } from 'lodash';
import { Paper } from 'material-ui';
import * as React from 'react';
import { connect } from 'react-redux';
import * as striptags from 'striptags';

import { EmailTemplateView } from 'shared/models/email-templates';
import { UserData } from 'shared/models/user';

import { fetchEntityNotes } from '../actions';
import { deleteDraft, getDraft } from '../common/draft-storage';
import { Avatar } from '../sfc/avatar';
import { NoteDraftState, State } from '../state';
import { NoteEditor } from './note-editor';

const avatarSize = 32;

interface OwnProps {
    context: { [field: string]: string };
    noteDraftKey: string;
    notable: string;
    parentId: string;
    startingTemplates: EmailTemplateView[];
    templateLabels: Map<string, string>;
    templateInterpolators: Map<string, (body: string) => string>;
    actions?: Array<{ text: string; handler: () => void }>;
    onClose?: () => void;
}

interface ConnectedProps {
    user: UserData;
}

interface ConnectedDispatch {
    fetchEntityNotes: (notable: string) => void;
}

interface NoteFormState {
    initialDraft: NoteDraftState;
}

type NoteFormComponentProps = ConnectedProps & ConnectedDispatch & OwnProps;

class NoteFormComponent extends React.Component<NoteFormComponentProps, NoteFormState> {
    constructor(props: NoteFormComponentProps) {
        super(props);
        this.state = { initialDraft: null };
    }

    componentDidMount() {
        getDraft<NoteDraftState>(this.props.noteDraftKey).then((initialDraft) => {
            this.setState({ initialDraft });
        });

        if (this.props.parentId !== null) {
            this.handleStart(null)();
        }
    }

    hasChanged = (content: string) => {
        return striptags(content).trim().length !== 0;
    };

    handleStart = (template: EmailTemplateView) => () => {
        getDraft<NoteDraftState>(this.props.noteDraftKey).then((draft) => {
            let content = draft?.content ?? '';
            let context = draft?.context ?? this.props.context;
            if (!draft && template) {
                content = template.body;
                if (this.props.templateInterpolators.has(template.kind)) {
                    content = this.props.templateInterpolators.get(template.kind)(template.body);
                }
                if (template.group === 'phonescreen') {
                    context = merge({}, context, { isPhonescreenTemplate: true });
                } else {
                    context = merge({}, context, { noteKind: template.kind });
                }
            }
            this.setState({
                initialDraft: {
                    addingAttachment: false,
                    content,
                    context,
                    initialAttachments: [],
                    modifiedAt: Date.now(),
                    saving: false
                }
            });
        });
    };

    postSave = () => {
        this.props.fetchEntityNotes(this.props.notable);
        deleteDraft(this.props.noteDraftKey);
        this.setState({ initialDraft: null });
        if (this.props.onClose) this.props.onClose();
    };

    postDiscard = () => {
        deleteDraft(this.props.noteDraftKey);
        this.setState({ initialDraft: null });
        if (this.props.onClose) this.props.onClose();
    };

    render() {
        const { user, notable, noteDraftKey, startingTemplates, templateLabels, context, actions, parentId } =
            this.props;

        const extraOptions = (actions ?? []).map((action, i) => (
            <span className="note-start-option" key={i} onClick={action.handler}>
                {action.text}
            </span>
        ));

        const options = [
            <span className="note-start-option" key="Empty Note" onClick={this.handleStart(null)}>
                Add a note
            </span>
        ].concat(extraOptions);

        for (const template of startingTemplates) {
            options.push(
                <span className="note-start-option" key={template.id} onClick={this.handleStart(template)}>
                    {templateLabels.get(template.kind)}
                </span>
            );
        }

        const form = this.state.initialDraft ? (
            <NoteEditor
                initialDraft={this.state.initialDraft}
                context={context}
                parentId={parentId}
                noteDraftKey={noteDraftKey}
                notable={notable}
                hasChanged={this.hasChanged}
                postSave={this.postSave}
                highlightErrors={false}
                postDiscard={this.postDiscard}
            />
        ) : (
            <div className="note-header note-form flex-columns-container">
                <Avatar entity={user} size={avatarSize} />
                <div className="start-note">{options}</div>
            </div>
        );

        return <Paper className="note">{form}</Paper>;
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    user: state.session.user
});
const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    fetchEntityNotes
};
export const NoteForm = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(NoteFormComponent);
