import { RaisedButton } from 'material-ui';
import * as React from 'react';
import { connect } from 'react-redux';
import * as striptags from 'striptags';

import { amRejected, amRequestedInfo, amSubmitApproved, awaitingAMApproval } from 'shared/models/job-stages';
import { NoteView } from 'shared/models/note';

import { moveCandidateToStage } from '../actions';
import { deleteDraft, getDraft } from '../common/draft-storage';
import { Candidate, NoteDraftState } from '../state';
import { NoteEditor } from './note-editor';

interface OwnProps {
    note: NoteView;
    candidate: Candidate;
    onNoteToggle: (isActive: boolean) => void;
}

interface ConnectedDispatch {
    moveCandidateToStage: (candidate: Candidate, stage: string) => void;
}

type SubmissionNoteAMActionsProps = OwnProps & ConnectedDispatch;

const SubmissionNoteAMActionsComponent: React.FC<SubmissionNoteAMActionsProps> = (props) => {
    const { candidate, note } = props;

    const noteDraftKey = (draftTarget: 'change' | 'reject') => {
        return `submission-note-${draftTarget}-${note.id}`;
    };

    const [target, setTarget] = React.useState<'change' | 'reject'>(null);
    const [initialDraft, setInitialDraft] = React.useState<NoteDraftState>(null);

    React.useEffect(() => {
        getDraft<NoteDraftState>(noteDraftKey('reject')).then((draft) => {
            if (draft) {
                setInitialDraft(draft);
                setTarget('reject');
            }
        });
        getDraft<NoteDraftState>(noteDraftKey('change')).then((draft) => {
            if (draft) {
                setInitialDraft(draft);
                setTarget('change');
            }
        });
    }, []);

    const closeNotes = () => {
        setInitialDraft(null);
        setTarget(null);
        props.onNoteToggle(false);
    };

    const handleChangeNoteDiscard = () => {
        deleteDraft(noteDraftKey(target));
        closeNotes();
    };

    const handleChangeNoteSaved = () => {
        deleteDraft(noteDraftKey(target));

        const stageToMove = target === 'change' ? amRequestedInfo : amRejected;
        closeNotes();
        props.moveCandidateToStage(candidate, stageToMove);
    };

    const handleButtonClick = (buttonTarget: 'change' | 'reject' | 'approve') => () => {
        if (buttonTarget === 'change' || buttonTarget === 'reject') {
            setTarget(buttonTarget);
            setInitialDraft({
                addingAttachment: false,
                content: '',
                context: { isSubmissionNoteChangeRequest: true },
                initialAttachments: [],
                modifiedAt: Date.now(),
                saving: false
            });
            props.onNoteToggle(true);
        } else {
            props.moveCandidateToStage(candidate, amSubmitApproved);
        }
    };

    const hasNoteChanged = (content: string) => {
        return striptags(content).trim().length !== 0;
    };
    const requestButtons = [];
    let noteForm;
    if (initialDraft && target) {
        noteForm = (
            <NoteEditor
                initialDraft={initialDraft}
                noteDraftKey={noteDraftKey(target)}
                notable={note.notable}
                hasChanged={hasNoteChanged}
                context={note.context}
                postDiscard={handleChangeNoteDiscard}
                postSave={handleChangeNoteSaved}
                highlightErrors={true}
            />
        );
    } else {
        const approveButton = (
            <RaisedButton
                key="approve"
                className="action-button"
                primary={true}
                label="Approve to Submit"
                onClick={handleButtonClick('approve')}
            />
        );
        const rejectButton = (
            <RaisedButton
                key="reject"
                className="action-button"
                secondary={true}
                label="Reject Candidate"
                onClick={handleButtonClick('reject')}
            />
        );
        const requestChangesButton = (
            <RaisedButton
                key="change"
                className="action-button"
                primary={true}
                label="Request Changes"
                onClick={handleButtonClick('change')}
            />
        );
        requestButtons.push(rejectButton);
        if (candidate.stage === awaitingAMApproval) {
            requestButtons.push(requestChangesButton);
        }
        requestButtons.push(approveButton);
    }
    return (
        <div className="submission-note-changes-request">
            {noteForm}
            <div>{requestButtons}</div>
        </div>
    );
};

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    moveCandidateToStage
};

export const SubmissionNoteAMActions = connect<{}, ConnectedDispatch, OwnProps>(
    undefined,
    mapDispatchToProps
)(SubmissionNoteAMActionsComponent);
