import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { RecruiterActiveCandidates } from '../containers/recruiter-active-candidates';

interface RouterProps {
    username: string;
}

const RecruiterActiveCandidatesComponent: React.FC<RouteComponentProps<RouterProps>> = ({ match }) => {
    return <RecruiterActiveCandidates username={match.params.username} />;
};

export const RecruiterActiveCandidatesPage = withRouter(RecruiterActiveCandidatesComponent);
