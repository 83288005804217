import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { UserDashboard } from '../components/user-dashboard';

interface RouterProps {
    username: string;
}

const UserDashboardComponent: React.FC<RouteComponentProps<RouterProps>> = ({ match }) => {
    const { username } = match.params;
    return <UserDashboard username={username} />;
};

export const UserDashboardPage = withRouter(UserDashboardComponent);
