import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

import { config } from './config';
import { root } from './reducers/root';

const enhancers =
    config.Env === 'production' ? applyMiddleware(thunk) : applyMiddleware(thunk, createLogger({ collapsed: true }));

export const store = createStore(root, enhancers);
