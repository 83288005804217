import React, { useMemo, useState } from 'react';

import { SearchTerm } from 'shared/models/search';

import { TextfieldWithChips } from '../core-ui/textfield-with-chips';
import { useCompanyNames } from '../hooks/use-company-names';
import { renderOption } from './search-autocomplete-option';
import { useSearchPresets } from './use-search-presets';

interface SearchCompanyTextFieldProps {
    onInputChange?: (value: string) => void;
    chips: SearchTerm[];
    readonly: boolean;
    onChange: (chips: SearchTerm[]) => void;
    getChipFromString: (text: string, dataSource?: boolean) => SearchTerm;
    getStringFromChip: (chip: SearchTerm) => string;
    getChipStyle?: (value: SearchTerm) => React.CSSProperties;
    prefixChips?: JSX.Element;
}

export const SearchCompanyTextField: React.FC<SearchCompanyTextFieldProps> = ({
    readonly,
    prefixChips,
    chips,
    onChange,
    getChipStyle,
    getChipFromString,
    getStringFromChip
}) => {
    const [inputValue, setInputValue] = useState<string>('');
    const { companyNames, loading } = useCompanyNames(inputValue);
    const { searchPresets, getGroupSearchTerms } = useSearchPresets();

    const options = useMemo(() => {
        const companyNameOptsWithCounts: Array<SearchTerm & { count?: number }> = companyNames?.map((company) => ({
            count: company.count,
            isPreset: false,
            label: company.name,
            value: company.url
        }));
        return getGroupSearchTerms('companies', companyNameOptsWithCounts);
    }, [companyNames, searchPresets]);

    return (
        <TextfieldWithChips
            readonly={readonly}
            prefixChips={prefixChips}
            chips={chips}
            options={options}
            hintText="Add Company"
            onChange={onChange}
            getChipStyle={getChipStyle}
            getChipFromString={getChipFromString}
            getStringFromChip={getStringFromChip}
            onInputChange={setInputValue}
            renderOption={renderOption}
            loading={loading}
        />
    );
};
