import { Map } from 'immutable';

import { Action, ReceiveJobMetrics } from '../actions';
import { JobMetrics } from '../state';

const initialState: Map<string, JobMetrics> = null;

export function jobMetrics(state = initialState, action: Action): Map<string, JobMetrics> {
    switch (action.type) {
        case ReceiveJobMetrics: {
            let updatedState = state || Map();
            for (const c of action.payload.metrics) {
                updatedState = updatedState.set(c.id, c.metrics);
            }
            return updatedState;
        }
        default:
            return state;
    }
}
