import { Map } from 'immutable';
import { uniqBy } from 'lodash';

import { Action, ReceiveEmailAccountCreated, ReceiveJobEmails } from '../actions';
import { EmailAccount } from '../state';

const initialState: Map<string, EmailAccount[]> = Map();

export function jobEmails(state = initialState, action: Action): Map<string, EmailAccount[]> {
    switch (action.type) {
        case ReceiveEmailAccountCreated:
        case ReceiveJobEmails: {
            return state.set(
                action.payload.jobId,
                uniqBy((state.get(action.payload.jobId) || []).concat(action.payload.emails), 'email')
            );
        }
        default:
            return state;
    }
}
