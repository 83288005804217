import { gql } from '@apollo/client';
import { EmailSyncStatus } from '../../state';

export interface EmailAccount {
    id: string;
    name: string;
    email: string;
    syncStatus: EmailSyncStatus;
    lastSyncAt: number;
    maxDailyOutreach: number;
}

export const EMAIL_ACCOUNTS = gql`
    subscription EmailAccounts {
        email_accounts(where: { syncStatus: { _neq: "archived" } }) {
            id: email
            name(path: "full")
            email
            syncStatus
            lastSyncAt
            maxDailyOutreach
        }
    }
`;

export const UPDATE_SYNC_STATUS = gql`
    mutation UpdateSyncStatus($email: String!, $syncStatus: String!) {
        update_email_accounts(_set: { syncStatus: $syncStatus }, where: { email: { _eq: $email } }) {
            affected_rows
            returning {
                id: email
                email
                syncStatus
            }
        }
    }
`;
