import { useMutation } from '@apollo/client';
import { Button, Dialog, IconButton, Menu, MenuItem, Paper } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import moment from 'moment';
import React from 'react';

import { InterviewData, InterviewKind } from 'shared/models/interview';
import { hasRole } from 'shared/models/user';

import { css } from '@emotion/core';
import { CREATE_INTERVIEW, DELETE_INTERVIEW, Interview, UPDATE_INTERVIEW } from '../graphql/queries/interviews';
import { useModal } from '../hooks/use-modal';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';
import { InterviewTimeSelector } from './interview-time-selector';

const activeElevation = 10;

const styles = css`
    &.interview {
        position: relative;

        &.past {
            opacity: 0.5;
        }

        .interview-actions {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            justify-content: space-between;
            opacity: 0;
        }

        &:hover {
            .interview-actions {
                opacity: 1;
            }
        }

        .interview-kind {
            flex: 0 0 auto;
            background-color: #da7464;
            color: white;
            font-weight: 500;
            text-transform: uppercase;
            text-align: center;
            height: 2.5em;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2px;
        }

        .interview-time {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 20px;

            .interview-time-month {
                text-transform: uppercase;
            }

            .interview-time-date {
                font-size: 48px;
                padding: 5px 0;
            }
        }

        &.editing {
            cursor: pointer;

            .interview-time {
                padding-top: 10px;
            }
            .interview-actions {
                opacity: 1;

                .MuiButton-root {
                    flex: 1 1 50%;
                }
            }
        }
    }
`;

export const PersonInterview: React.FC<{
    data: Partial<Interview>;
    onSave: () => void;
    onDelete: () => void;
    validKinds: InterviewKind[];
}> = ({ data, validKinds, onSave, onDelete }) => {
    const [interview, setInterview] = React.useState({ ...data });
    const [editing, setEditing] = React.useState(!data.id);
    const [deleted, setDeleted] = React.useState(false);
    const [timeSelectorAnchor, setTimeSelectorAnchor] = React.useState<HTMLElement | null>(null);
    const [menuAnchor, setMenuAnchor] = React.useState<HTMLElement | null>(null);
    const [deleteInterview] = useMutation<{}, { id: string }>(DELETE_INTERVIEW);
    const [updateInterview] = useMutation<{}, { id: string; interview: Partial<InterviewData> }>(UPDATE_INTERVIEW);
    const [createInterview] = useMutation<{}, { interview: Partial<InterviewData> }>(CREATE_INTERVIEW);
    const { getConfirmation, setAlert } = useModal();
    const { user, userPermissions } = useSession();
    const { setSnackbar } = useSnackbar();

    React.useEffect(() => {
        setInterview({ ...data });
    }, [data]);

    const handleEditClick = () => {
        if (interview.reschedulingLink) {
            if (interview.endTime < Date.now()) {
                setAlert(
                    'Cannot Edit Interview',
                    `Interview created via a calendar link and is in the past. Delete this interview and create a new one instead`
                );
            } else {
                window.open(interview.reschedulingLink, '_blank');
            }
        } else {
            setEditing(true);
        }
    };

    const handleCancel = () => {
        if (interview.id) {
            setInterview({ ...data });
            setEditing(false);
        } else {
            onDelete();
        }
    };

    const handleSave = async () => {
        setEditing(false);
        if (interview.id) {
            setSnackbar('Updating interview');
            await updateInterview({
                variables: {
                    id: interview.id,
                    interview: { endTime: interview.endTime, kind: interview.kind, startTime: interview.startTime }
                }
            });
            setSnackbar('Interview updated');
        } else {
            setSnackbar('Creating interview');
            await createInterview({ variables: { interview } });
            setSnackbar('Interview created');
        }
        onSave();
    };

    const handleDelete = () => {
        getConfirmation(
            async () => {
                if (interview.cancellationLink && interview.endTime > Date.now()) {
                    window.open(interview.cancellationLink, '_blank');
                } else {
                    setDeleted(true);
                    setSnackbar('Deleting interview');
                    await deleteInterview({ variables: { id: interview.id } });
                    setSnackbar('Interview deleted');
                    onDelete();
                }
            },
            'Are you sure you want to delete this interview?',
            'Delete Interview'
        );
    };

    const handleKindClick = (event: React.MouseEvent<HTMLElement>) => {
        if (editing) {
            setMenuAnchor(event.currentTarget);
        }
    };

    const handleCloseMenu = () => setMenuAnchor(null);

    const handleKindChange = (kind: InterviewKind) => () => {
        setInterview({ ...interview, kind });
        handleCloseMenu();
    };

    const handleOpenTimePicker = (e: React.MouseEvent<HTMLElement>) => {
        if (editing) {
            setTimeSelectorAnchor(e.currentTarget);
        }
    };
    const handleCloseTimePicker = () => setTimeSelectorAnchor(null);
    const handleChangeInterviewTime = (startTime: number, endTime: number) => {
        setInterview({ ...interview, startTime, endTime });
    };

    if (deleted) return null;

    const canEdit =
        interview.id &&
        (interview.candidate.assignee === user.id ||
            interview.candidate.accountManagerId === user.id ||
            hasRole(userPermissions, 'settings_editor'));
    const actionButtons = editing ? (
        <div className="interview-actions">
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleSave}>Save</Button>
        </div>
    ) : canEdit ? (
        <div className="interview-actions">
            <IconButton size="small" onClick={handleDelete}>
                <Delete fontSize="small" />
            </IconButton>
            <IconButton size="small" onClick={handleEditClick}>
                <Edit fontSize="small" />
            </IconButton>
        </div>
    ) : null;
    const paperClass = `interview ${!editing && interview.endTime < Date.now() ? 'past' : ''} ${
        editing ? 'editing' : ''
    }`;

    const kindMenuOpts = validKinds.map((kind) => (
        <MenuItem key={kind} onClick={handleKindChange(kind)}>
            {kind}
        </MenuItem>
    ));

    const kindMenu = (
        <Menu anchorEl={menuAnchor} open={!!menuAnchor} onClose={handleCloseMenu}>
            {kindMenuOpts}
        </Menu>
    );

    const timePicker = (
        <Dialog open={!!timeSelectorAnchor} onClose={handleCloseTimePicker}>
            <InterviewTimeSelector
                onClose={handleCloseTimePicker}
                startTime={interview.startTime}
                endTime={interview.endTime}
                onChange={handleChangeInterviewTime}
            />
        </Dialog>
    );

    return (
        <Paper key={interview.id} className={paperClass} css={styles} elevation={editing ? activeElevation : 1}>
            <div className="interview-kind" onClick={handleKindClick}>
                {interview.kind}
            </div>
            <div className="interview-time" onClick={handleOpenTimePicker}>
                <div className="interview-time-month">{moment(interview.startTime).format('MMM')}</div>
                <div className="interview-time-date">{moment(interview.startTime).format('DD')}</div>
                <div className="interview-time-time">
                    {moment(interview.startTime).format('h:mm a')} - {moment(interview.endTime).format('h:mm a')}
                </div>
            </div>
            {actionButtons}
            {kindMenu}
            {timePicker}
        </Paper>
    );
};
