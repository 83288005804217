import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { ApexOptions } from 'apexcharts';
import React from 'react';
import Chart from 'react-apexcharts';
import { ActivityMetricType } from 'shared/models/activity-metric';

import { fontFamily } from '../../common/css-variables';
import { Field, fieldLabels, FieldsSelected, getFieldColors, metricsByPeriod } from '../../common/metrics';
import { Legend } from './legend';

const defaultHeight = 480;
const defaultStrokeWidth = 3;

export const MetricsTimeline: React.FC<{
    metrics: Array<{ kind: ActivityMetricType; count: number; date: string }>;
    fields: ActivityMetricType[];
    duration: { start: number; end: number };
    selectedFields?: FieldsSelected;
    onChangeSelectedFields?: (updated: FieldsSelected) => void;
    title?: string;
    hideXaxisLabels?: boolean;
    height?: number;
    periodSize?: 'day' | 'week' | 'month';
    strokeWidth?: number;
    chartOptions?: ApexOptions;
}> = ({
    metrics,
    duration,
    fields: fieldOptions,
    title,
    selectedFields,
    onChangeSelectedFields,
    hideXaxisLabels,
    height,
    periodSize,
    strokeWidth,
    chartOptions: customChartOptions
}) => {
    const [hoveredField, setHoveredField] = React.useState<Field>(undefined);

    const dataByPeriod = metricsByPeriod(metrics, duration.start, duration.end, periodSize);

    const periods = dataByPeriod?.map((d) => d.period);
    const categories = periods?.map((p) => p.tick);
    const fields = !selectedFields ? fieldOptions : fieldOptions.filter((f) => selectedFields[f]);
    const series = fields.map((f) => ({ name: fieldLabels.get(f), data: dataByPeriod.map((d) => d.metrics[f] ?? 0) }));
    const colors = getFieldColors(fields, hoveredField);

    const chartOptions = {
        ...customChartOptions,
        chart: { fontFamily, toolbar: { show: false } },
        colors,
        legend: { show: false },
        stroke: { curve: 'smooth' as 'smooth', width: strokeWidth ?? defaultStrokeWidth },
        tooltip: {
            x: {
                formatter: (index: number) => {
                    return periods[index - 1].label;
                }
            }
        },
        xaxis: {
            categories,
            labels: {
                show: !hideXaxisLabels
            }
        }
    };

    const chart = !metrics ? (
        <Skeleton variant="rect" />
    ) : categories.length > 0 ? (
        <Chart options={chartOptions} series={series} height={height ?? defaultHeight} />
    ) : null;

    const header = title ? (
        <div className="panel-header">
            <Typography variant="h5">{title}</Typography>
        </div>
    ) : null;

    const legend =
        fieldOptions.length > 1 ? (
            <Legend
                fields={fieldOptions}
                selected={selectedFields}
                onFieldHover={setHoveredField}
                onUpdateSelected={onChangeSelectedFields}
            />
        ) : null;

    return (
        <div className="chart-container">
            {header}
            <div className="panel-content">
                <div className="chart">{chart}</div>
                {legend}
            </div>
        </div>
    );
};
