import { Communication } from '../state';

export const threadsPageSize = 50;
export const searchResultLabel = 'SEARCH_RESULT_LABEL';

export interface EmailAccountLabel {
    id: string;
    name: string;
    messageListVisibility: string;
    labelListVisibility: string;
    type: string;
    messagesTotal: number;
    messagesUnread: number;
    threadsTotal: number;
    threadsUnread: number;
}

export interface EmailThread {
    id: string;
    snippet: string;
    historyId: string;
    messages: Communication[];
}

export interface EmailInboxData {
    account: string;
    initialized: boolean;
    fetching: boolean;
    labels?: EmailAccountLabel[];
    threads?: EmailThread[];
    selectedLabel?: string;
    selectedThreads: string[];
    threadsStartIndex: number;
    searchString: string;
    viewingThread: string;
    downloadingAttachment?: boolean;
}
