import { css } from '@emotion/core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Tab,
    Tabs,
    TextField,
    Theme,
    Typography,
    useTheme
} from '@material-ui/core';
import React, { createContext, ReactNode, useContext, useState } from 'react';

import { SearchTerm } from 'shared/models/search';
import { PresetGroup, SearchPresetData } from 'shared/models/search-preset';

import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';
import { SearchPresetForm } from './search-preset-form';
import { useSearchPresets } from './use-search-presets';

interface SearchPresetsFormContextType {
    requestPresetsSave: (data: {
        selectedGroup: PresetGroup;
        groups: PresetGroup[];
        terms?: SearchTerm[];
        presetData?: Partial<SearchPresetData>;
        postSave?: (term: SearchTerm) => void;
        disableTabs?: boolean;
    }) => void;
}

const SearchPresetsFormContext = createContext<SearchPresetsFormContextType | undefined>(undefined);

const tabsStyles = (theme: Theme) => css`
    background-color: ${theme.palette.primary.main};

    .MuiTabs-indicator {
        background-color: rgb(169, 190, 217);
    }

    .MuiTab-wrapper {
        color: ${theme.palette.primary.contrastText};
    }
`;

const titleStyle = css`
    padding: 18px;
    flex: 0 0 auto;
    font-size: 18px;
    font-weight: 600;
`;

export const SearchPresetsFormProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const theme = useTheme();
    const [formActive, setFormActive] = useState(false);
    const [mode, setMode] = useState<'edit' | 'new' | undefined>();
    const [selectedGroup, setSelectedGroup] = useState<PresetGroup | undefined>();
    const [postSave, setPostSave] = useState<(term: SearchTerm) => void>(undefined);
    const [disableTabs, setDisableTabs] = useState(false);
    const [groups, setGroups] = useState<PresetGroup[]>([]);
    const [presetSelectOpen, setPresetSelectOpen] = useState(false);
    const [presetData, setPresetData] = useState<Partial<SearchPresetData>>(undefined);
    const { searchPresets, createPreset, updatePreset, loading } = useSearchPresets();
    const { user } = useSession();
    const { setSnackbar } = useSnackbar();

    const requestPresetsSave = (data: {
        terms?: SearchTerm[];
        selectedGroup: PresetGroup;
        groups: PresetGroup[];
        presetData?: Partial<SearchPresetData>;
        postSave?: (term: SearchTerm) => void;
        disableTabs?: boolean;
    }) => {
        setFormActive(true);
        setSelectedGroup(data.selectedGroup);
        setGroups(data.groups);
        setPostSave(() => data.postSave);
        let newTerms: SearchTerm[] = [];
        for (const value of data.terms ?? presetData?.list ?? []) {
            if ((value as any).presetId) {
                newTerms = [...newTerms, ...(searchPresets.find((p) => p.id === (value as any).presetId)?.list ?? [])];
            } else {
                newTerms.push({ value: value.value, label: value.label });
            }
        }
        const defaultPresetData = { name: '', list: newTerms };
        setPresetData(data.presetData ?? defaultPresetData);
        setMode('new');
        setDisableTabs(data.disableTabs ?? false);
    };

    const handleModeChange = (_1: React.ChangeEvent<{}>, newMode: 'edit' | 'new') => {
        if (newMode === 'edit' && mode !== 'edit') {
            setPresetSelectOpen(true);
        }
        setMode(newMode);
    };

    const handleClose = () => {
        setFormActive(false);
        setMode(undefined);
        setSelectedGroup(undefined);
        setGroups([]);
        setPresetData(undefined);
        setPostSave(undefined);
    };

    const handleCancelPresetSelect = () => {
        setPresetSelectOpen(false);
        setMode('new');
    };

    const handleSelectExistingPreset = (event: React.ChangeEvent<{ value: string }>) => {
        setPresetSelectOpen(false);
        const existingPreset = searchPresets.find((p) => p.id === event.target.value);
        setPresetData(existingPreset);
    };

    const handleSavePreset = async (id: string, preset: Partial<SearchPresetData>) => {
        let record: { id: string; name: string };
        if (id) {
            setSnackbar('Updating preset...');
            record = await updatePreset(id, preset);
            setSnackbar('Preset updated');
        } else {
            setSnackbar('Creating preset...');
            record = await createPreset(preset);
            setSnackbar('Preset created');
        }
        const term = { label: record.name, presetId: record.id, isPreset: true as const };
        postSave?.(term);
        handleClose();
    };

    const handlePresetDelete = async (id: string) => {
        setSnackbar('Deleting preset...');
        await updatePreset(id, { deprecated: true });
        setSnackbar('Preset deleted');
        handleClose();
    };

    let content;
    if (formActive) {
        let presetSelectDialog;
        let title;
        if (disableTabs) {
            title = <div css={titleStyle}>{presetData?.id ? 'Update Preset' : 'Create New Preset'}</div>;
        } else {
            title = (
                <Tabs value={mode} onChange={handleModeChange} variant="fullWidth" css={tabsStyles(theme)}>
                    <Tab label="Create New Preset" value="new" />
                    <Tab label="Edit existing Preset" value="edit" />
                </Tabs>
            );
            const existingPresetOptions = searchPresets
                .filter((p) => p.group === selectedGroup && p.createdBy === user.id)
                .map((p) => (
                    <MenuItem key={p.id} value={p.id}>
                        {p.name}
                    </MenuItem>
                ));
            presetSelectDialog = (
                <Dialog open={presetSelectOpen} fullWidth={true} maxWidth="sm">
                    <DialogTitle>
                        <Typography variant="h4" component="div">
                            Select existing preset
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            select={true}
                            label="Preset"
                            fullWidth={true}
                            value=""
                            onChange={handleSelectExistingPreset}
                        >
                            {existingPresetOptions}
                        </TextField>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancelPresetSelect}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            );
        }
        content = (
            <Dialog open={true} onClose={handleClose} maxWidth="md">
                <SearchPresetForm
                    title={title}
                    groups={groups}
                    selected={selectedGroup}
                    data={presetData}
                    onClose={handleClose}
                    onSave={handleSavePreset}
                    onDelete={handlePresetDelete}
                    disabled={loading}
                />
                {presetSelectDialog}
            </Dialog>
        );
    }

    return (
        <SearchPresetsFormContext.Provider value={{ requestPresetsSave }}>
            {children}
            {content}
        </SearchPresetsFormContext.Provider>
    );
};

export const useSearchPresetsForm = () => {
    const context = useContext(SearchPresetsFormContext);
    if (!context) {
        throw new Error('useSearchPresetsForm must be used within a SearchPresetsFormProvider');
    }
    return context;
};
