import { Map } from 'immutable';

import { Action, ReceiveAccountManagerData } from '../actions';
import { AccountManagerData } from '../state';

const initialState: Map<string, AccountManagerData> = Map();

export function accountManagerData(state = initialState, action: Action): Map<string, AccountManagerData> {
    switch (action.type) {
        case ReceiveAccountManagerData:
            return state.set(action.payload.id, action.payload.data);
        default:
            return state;
    }
}
