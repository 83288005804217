import { css } from '@emotion/core';
import { Theme, useTheme } from '@material-ui/core';
import { StarBorder } from '@material-ui/icons';
import { Rating } from '@material-ui/lab';
import React from 'react';
import { ProfileRating, ProfileRatingReq } from '../graphql/queries/profile-ratings';
import { useSession } from '../hooks/use-session';
import { Avatar } from '../sfc/avatar';

import { PersonProfile } from '../sfc/person-profile';
import { Profile } from '../types/profile';

const styles = (theme: Theme) => css`
    .rating-header {
        background: ${theme.palette.primary.main};
        color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 40px;

        .title {
            font-size: 28px;
        }
    }

    .rating-content {
        padding: 20px 40px;
        display: flex;
        background: #fafafa;

        .right-bar {
            width: 380px;
            flex: 0 0 auto;
            margin-left: 35px;
        }

        .left-bar {
            flex: 1 1 auto;
        }
    }

    .ratings-box {
        border: thin solid ${theme.palette.divider};
        border-radius: 2px;
        padding: 20px;
        background: white;
        position: sticky;
        top: 20px;
    }

    .req-rating {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }

        .req-title {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 10px;
        }
    }

    .company-info,
    .company-name,
    .school-name {
        background: ${theme.palette.text.primary};
        color: ${theme.palette.text.primary};
        border-radius: ${theme.shape.borderRadius}px;
    }
`;

const avatarSize = 64;
const ratingPrecision = 0.5;

interface ProfileRatingCandidateProps {
    person: {
        id: string;
        name: {
            first: string;
            last: string;
            full: string;
        };
        profilePicUrl: string;
    };
    profile: Profile;
    viewedAt: number;
    reqs: ProfileRatingReq[];
    ratings: ProfileRating[];
    onRatingChange: (personId: string, reqId: string, rating: number) => void;
}

export const ProfileRatingCandidate: React.FC<ProfileRatingCandidateProps> = ({
    person,
    profile,
    viewedAt,
    reqs,
    ratings,
    onRatingChange
}) => {
    const theme = useTheme();
    const { user } = useSession();

    const handleRatingChange = (reqId: string) => (_1: any, rating: number) => {
        onRatingChange(person.id, reqId, rating / ratingPrecision);
    };

    const reqRatings = reqs.map((req) => {
        const rating = ratings.find((r) => r.reqId === req.id && r.user.id === user.id)?.rating ?? 0;
        return (
            <div className="req-rating" key={req.id}>
                <div className="req-title">{req.name}</div>
                <div className="req-stars">
                    <Rating
                        name={`profile-rating-${req.id}`}
                        precision={ratingPrecision}
                        value={rating * ratingPrecision}
                        emptyIcon={<StarBorder fontSize="inherit" />}
                        onChange={handleRatingChange(req.id)}
                    />
                </div>
            </div>
        );
    });

    return (
        <div css={styles(theme)}>
            <div className="rating-header">
                <div className="title">{person.name.full}</div>
                <div>
                    <Avatar entity={person} size={avatarSize} />
                </div>
            </div>
            <div className="rating-content">
                <div className="left-bar">
                    <PersonProfile personId={person.id} profile={profile} viewedAt={viewedAt} />
                </div>
                <div className="right-bar">
                    <div className="ratings-box">{reqRatings}</div>
                </div>
            </div>
        </div>
    );
};
