import { FlatButton } from 'material-ui';
import * as React from 'react';

interface ToggleButtonProps {
    onLabel: React.ReactNode;
    offLabel: React.ReactNode;
    initialValue: boolean;
    onClick: () => void;
}

interface ToggleButtonState {
    value: boolean;
}

export class ToggleButton extends React.Component<ToggleButtonProps, ToggleButtonState> {
    constructor(props: ToggleButtonProps) {
        super(props);
        this.state = {
            value: props.initialValue
        };
    }

    handleClick = () => {
        this.setState({ value: !this.state.value });
        this.props.onClick();
    };

    render() {
        const { onLabel, offLabel } = this.props;
        const { value } = this.state;
        const label = value ? onLabel : offLabel;
        return <FlatButton label={label} onClick={this.handleClick} />;
    }
}
