import * as React from 'react';
import DocumentTitle from 'react-document-title';

import { ClientNew } from '../components/client-new';
import { Header } from '../components/header';

export const ClientNewPage: React.FC = () => {
    return (
        <DocumentTitle title="New Client">
            <div id="container">
                <Header title="New Client" />
                <div id="content" className="flex-fill">
                    <ClientNew />
                </div>
            </div>
        </DocumentTitle>
    );
};
