import { css } from '@emotion/core';
import { Badge, Drawer, IconButton } from '@material-ui/core';
import { List } from '@material-ui/icons';
import React from 'react';

import { jobStatusMentions } from 'shared/models/user-mention';

import { useNotificationsData } from '../hooks/use-notifications-data';
import { useSession } from '../hooks/use-session';
import { UserContextProvider } from '../hooks/use-user-context';
import { PendingEmails } from './home/pending-emails';
import { Reminders } from './home/reminders';
import { UserMentions } from './home/user-mentions';
import { UserNotifications } from './home/user-notifications';

const styles = css`
    .MuiDrawer-paper {
        background: #f4f6f8;
        width: 450px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        box-shadow:
            rgba(0, 0, 0, 0.16) 0px 3px 10px,
            rgba(0, 0, 0, 0.23) 0px 3px 10px;
        border-left: none;
    }

    .app-bar {
        flex: 0 0 auto;
    }

    .toolbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 8px;
    }

    .notifications-main {
        padding: 20px;
        flex: 1 1 auto;
        overflow: auto;
    }

    .panel {
        margin-bottom: 25px;
    }
`;

export const Notifications = () => {
    const { user, userPermissions } = useSession();
    const { pendingEmails, pendingEmailsArchived, reminders, mentions, notifications } = useNotificationsData();
    const [drawerOpen, setDrawerOpen] = React.useState(false);

    const handleToggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const drawer = (
        <Drawer onClose={handleToggleDrawer} open={drawerOpen} anchor="right" css={styles}>
            <UserContextProvider user={user} userPermissions={userPermissions}>
                <div className="notifications-main">
                    <div className="panel">
                        <UserMentions />
                    </div>
                    <div className="panel">
                        <Reminders />
                    </div>
                    <div className="panel">
                        <UserNotifications />
                    </div>
                    <div className="panel">
                        <PendingEmails candidates={pendingEmails} title="Pending Emails" />
                    </div>
                    <div className="panel">
                        <PendingEmails candidates={pendingEmailsArchived} title="Archived Candidates Emails" />
                    </div>
                </div>
            </UserContextProvider>
        </Drawer>
    );

    const count =
        (pendingEmails?.filter((c) => c.assignee.id === user.id).length ?? 0) +
        (mentions?.filter((m) => !jobStatusMentions.includes(m.kind) && m.recipient.id === user.id).length ?? 0) +
        (reminders?.length ?? 0) +
        (notifications?.length ?? 0);

    return (
        <div>
            <IconButton onClick={handleToggleDrawer}>
                <Badge badgeContent={count} color="error">
                    <List htmlColor="white" />
                </Badge>
            </IconButton>
            {drawer}
        </div>
    );
};
