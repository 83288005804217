import { css } from '@emotion/core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    MenuItem,
    Select,
    Theme,
    useTheme
} from '@material-ui/core';
import moment from 'moment';
import React from 'react';

import { defaultDateFormat, Duration, getDurationOptions, Periods } from '../common/duration';
import { DayPicker } from '../core-ui/react-day-picker';

const dateRangeDialogStyles = (theme: Theme) => css`
    .MuiDialogContent-root {
        border-bottom: thin solid ${theme.palette.divider};
        padding: 0 20px;
    }
`;

export const DurationSelector: React.FC<{
    periods?: Periods;
    selected: { start: number; end: number };
    onSelect: (duration: Duration) => void;
}> = ({ periods, selected: { start, end }, onSelect }) => {
    const theme = useTheme();
    const [customDateSelectorOpen, setCustomDateSelectorOpen] = React.useState(false);
    const [customDateStart, setCustomDateStart] = React.useState(moment().startOf('week').valueOf());
    const [customDateEnd, setCustomDateEnd] = React.useState(moment().endOf('week').valueOf());
    const [options] = React.useState(getDurationOptions(periods));

    const getDurationForCustomRange = (from: number, to: number): Duration => {
        let delta: number | moment.Duration = to - from;
        if (moment(from).add(1, 'day') >= moment(to)) {
            delta = moment.duration(1, 'day');
        } else if (moment(from).add(1, 'week') >= moment(to)) {
            delta = moment.duration(1, 'week');
        } else if (moment(from).add(1, 'month') >= moment(to)) {
            delta = moment.duration(1, 'month');
        } else if (moment(from).add(1, 'quarter') >= moment(to)) {
            delta = moment.duration(1, 'quarter');
        } else if (moment(from).add(1, 'year') >= moment(to)) {
            delta = moment.duration(1, 'year');
        }
        const baselineStart = moment(from).subtract(delta).valueOf();
        const baselineEnd = moment(to).subtract(delta).valueOf();
        const period = `${moment(from).format(defaultDateFormat)} - ${moment(to).format(defaultDateFormat)}`;
        const baselinePeriod = `${moment(baselineStart).format(defaultDateFormat)} - ${moment(baselineEnd).format(
            defaultDateFormat
        )}`;
        return { start: from, end: to, baselineStart, baselineEnd, period, baselinePeriod };
    };

    // run once on mount to set up baseline for parent component
    React.useEffect(() => {
        const presetIndex = options.findIndex((o) => o.start === start && o.end === end);
        if (presetIndex !== -1) {
            onSelect(options[presetIndex]);
        } else {
            onSelect(getDurationForCustomRange(start, end));
        }
    }, []);

    const handleSelectDuration = (duration: Duration) => () => {
        onSelect(duration);
    };

    const handleConfirmCustomDuration = () => {
        const duration = getDurationForCustomRange(customDateStart, customDateEnd);
        handleSelectDuration(duration)();
        setCustomDateSelectorOpen(false);
        setCustomDateStart(moment().startOf('week').valueOf());
        setCustomDateEnd(moment().endOf('week').valueOf());
    };

    const handleToggleSelectCustomDuration = () => {
        setCustomDateSelectorOpen(!customDateSelectorOpen);
    };

    const renderValue = (selected: string) => {
        const index = options.findIndex((o) => selected === `${o.start}-${o.end}`);
        if (index !== -1) {
            return options[index].label;
        } else {
            return `${moment(start).format(defaultDateFormat)} - ${moment(end).format(defaultDateFormat)}`;
        }
    };

    const handleDateRangeSelected = (range: { from: Date; to: Date }) => {
        if (range) {
            if (!range.to) {
                setCustomDateEnd(moment(range.from).endOf('day').valueOf());
                setCustomDateStart(moment(range.from).startOf('day').valueOf());
            } else {
                setCustomDateEnd(moment(range.to).endOf('day').valueOf());
                setCustomDateStart(moment(range.from).startOf('day').valueOf());
            }
        }
    };

    const menuItems = options.map((opt, i) => (
        <MenuItem key={i} onClick={handleSelectDuration(opt)} value={`${opt.start}-${opt.end}`}>
            {opt.label}
        </MenuItem>
    ));

    // custom date option
    const customDateMenu = (
        <MenuItem onClick={handleToggleSelectCustomDuration} value="custom">
            Custom
        </MenuItem>
    );

    const customDateSelector = customDateSelectorOpen ? (
        <Dialog open={true} onClose={handleToggleSelectCustomDuration} css={dateRangeDialogStyles(theme)}>
            <DialogContent>
                <div className="date-selectors">
                    <div className="date-selector">
                        <DayPicker
                            mode="range"
                            selected={{ from: new Date(customDateStart), to: new Date(customDateEnd) }}
                            onSelect={handleDateRangeSelected}
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirmCustomDuration}>Update</Button>
            </DialogActions>
        </Dialog>
    ) : null;

    const selectedOption = options.find((v) => v.start === start && v.end === end);
    const selectedValue = selectedOption ? `${selectedOption.start}-${selectedOption.end}` : 'custom';

    return (
        <>
            <Select value={selectedValue} variant="outlined" renderValue={renderValue}>
                {menuItems}
                <Divider />
                {customDateMenu}
            </Select>
            {customDateSelector}
        </>
    );
};
