import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { SearchResultsViewType } from 'shared/models/search';

import { SourcerSearchDetail } from '../components/sourcer-search-detail';

interface RouterProps {
    sourcerId: string;
    searchId: string;
    viewType: SearchResultsViewType;
}

const SourcerSearchPageComponent: React.SFC<RouteComponentProps<RouterProps>> = ({ match }) => (
    <SourcerSearchDetail
        sourcerId={match.params.sourcerId}
        searchId={match.params.searchId}
        resultsType={match.params.viewType}
    />
);

export const SourcerSearchPage = withRouter(SourcerSearchPageComponent);
