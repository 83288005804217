import { css } from '@emotion/core';
import React from 'react';

import { ProfileScore } from '../graphql/queries/profile-score';
import { useAIProfileScoreDetails } from '../hooks/use-ai-profile-score-details';
import { StarRating } from './star-rating';

const styles = css`
    position: absolute;
    white-space: nowrap;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
`;

export const AIProfileScore: React.FC<{ score: ProfileScore }> = ({ score }) => {
    const { showScoreDetails } = useAIProfileScoreDetails();

    const handleScoreClick = () => {
        showScoreDetails(score);
    };

    return score ? (
        <div css={styles}>
            <StarRating rating={score.score} onClick={handleScoreClick} tooltip="AI Score, click for details" />
        </div>
    ) : null;
};
