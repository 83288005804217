import { Divider, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';

import { CandidateDisqualReason, getDisqualReasonLabel } from 'shared/models/job-stages';

interface OutreachRevivalMenuProps {
    value: string;
    onChange: (event: React.ChangeEvent<{ value: string }>) => void;
}

const allDisqualReasons: CandidateDisqualReason[] = [
    'candidate_declined_compensation_issue',
    'candidate_declined_contract_only',
    'candidate_declined_permanent_only',
    'candidate_declined_location_not_a_fit',
    'candidate_declined_not_interested_in_role',
    'candidate_declined_remote_only',
    'candidate_declined_withdrawing',
    'recruiter_declined_added_by_mistake',
    'recruiter_declined_gone_cold',
    'recruiter_declined_not_qualified_on_review',
    'recruiter_declined_visa_issue'
];

export const OutreachRevivalMenu: React.FC<OutreachRevivalMenuProps> = ({ value, onChange }) => {
    const disqualMenuItems = allDisqualReasons.map((reason) => (
        <MenuItem key={reason} value={reason}>
            {getDisqualReasonLabel(reason, true)}
        </MenuItem>
    ));
    const revivalOpts = [
        <MenuItem key="never_responded" disabled={true}>
            Never Responded
        </MenuItem>,
        <Divider key="divider1" />,
        <MenuItem value="never_responded" key="email-not-responded">
            Email candidates who have not been contacted in a while
        </MenuItem>,
        <Divider key="divider2" />,
        <MenuItem key="disqualified" disabled={true}>
            Disqualified Candidates
        </MenuItem>,
        <Divider key="divider3" />,
        ...disqualMenuItems
    ];
    return (
        <FormControl fullWidth={true}>
            <InputLabel id="outreach-sequence-revival-reason-label">Select Revival Reason</InputLabel>
            <Select value={value} onChange={onChange} labelId="outreach-sequence-revival-reason-label">
                {revivalOpts}
            </Select>
        </FormControl>
    );
};
