import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as striptags from 'striptags';

import { zIndexes } from '../common/css-variables';
import { NoteDraftState } from '../state';
import { NoteEditor } from './note-editor';

interface NoteDialogProps {
    initialDraft: NoteDraftState;
    personId: string;
    jobId: string;
    noteDraftKey: string;
    title: string;
    placeholder?: string;
    noteId?: string;
    onCancel: () => void;
    onSave: () => void;
}

interface NoteDialogState {
    collapsed: boolean;
}

export class NoteDialog extends React.Component<NoteDialogProps, NoteDialogState> {
    private layer: HTMLElement;

    constructor(props: NoteDialogProps) {
        super(props);
        this.state = { collapsed: false };
        this.layer = document.createElement('div');
        this.layer.setAttribute('class', 'note-dialog-layer');
        this.layer.setAttribute('style', `z-index: ${zIndexes.emailComposeLayer}`);
        document.body.appendChild(this.layer);
    }

    componentWillUnmount() {
        this.layer.remove();
    }

    hasNoteChanged = (content: string) => {
        return striptags(content).trim().length !== 0;
    };

    handleToggleWindow = () => {
        this.setState({ collapsed: !this.state.collapsed });
    };

    render() {
        const { personId, jobId, noteId, noteDraftKey, title, placeholder, initialDraft } = this.props;
        const { collapsed } = this.state;
        const noteContext = {
            isSubmissionNote: true,
            jobId
        };

        const component = (
            <div className={`note-dialog ${collapsed ? 'collapsed' : ''}`}>
                <div className="note-dialog-header" onClick={this.handleToggleWindow}>
                    {title}
                </div>
                <div className="note-dialog-editor-container">
                    <NoteEditor
                        initialDraft={initialDraft}
                        noteDraftKey={noteDraftKey}
                        notable={`persons-${personId}`}
                        hasChanged={this.hasNoteChanged}
                        context={noteContext}
                        postDiscard={this.props.onCancel}
                        postSave={this.props.onSave}
                        placeholder={placeholder}
                        id={noteId}
                        highlightErrors={true}
                    />
                </div>
            </div>
        );
        return ReactDOM.createPortal(component, this.layer);
    }
}
