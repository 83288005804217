import { css } from '@emotion/core';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography
} from '@material-ui/core';
import { Map } from 'immutable';
import * as _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import { addSourcerMembersToSourcer, fetchCampaignSearchStats } from '../actions';
import { usePrevious } from '../hooks/use-previous';
import { useSnackbar } from '../hooks/use-snackbar';
import { JobSearchesState, RequestErrors, SourcerData, State } from '../state';

// tslint:disable:no-magic-numbers
const styles = css`
    .content {
        width: 500px;
        height: 200px;

        .spinner {
            display: flex;
            flex: 1 1 auto;
            justify-content: center;
            align-items: center;
            min-height: 200px;
        }
    }
`;
// tslint:enable:no-magic-numbers

interface OwnProps {
    sourcer: SourcerData;
    hasUser: boolean;
    onClose: () => void;
}

interface ConnectedProps {
    pendingRequests: Map<string, RequestErrors>;
    searches: Map<string, JobSearchesState>;
}

interface ConnectedDispatch {
    addSourcerMembersToSourcer: (sourcerId: string, limit: number) => void;
    fetchCampaignSearchStats: (jobId: string) => void;
}

type SourcerAddProspectsProps = OwnProps & ConnectedProps & ConnectedDispatch;

export const SourcerAddProspectsComponent: React.FC<SourcerAddProspectsProps> = (props) => {
    const { sourcer, onClose, pendingRequests, searches } = props;
    const addingProspects = pendingRequests.has(`sourcer-members-add-${sourcer.sourcerId}`);
    const previousAddingProspects = usePrevious(addingProspects);
    const [profilesTToAdd, setProfilesToAdd] = React.useState<string>(sourcer.maxToAdd.toString());

    const { setSnackbar } = useSnackbar();

    React.useEffect(() => {
        if (!searches || !searches.has(sourcer.sourcerId)) {
            props.fetchCampaignSearchStats(sourcer.sourcerId);
        }
    }, []);

    React.useEffect(() => {
        if (previousAddingProspects && !addingProspects) {
            props.onClose();
            setSnackbar('Sourcer members are getting added to sourcer');
        }
    }, [pendingRequests]);

    const handleAdd = () => {
        props.addSourcerMembersToSourcer(sourcer.sourcerId, Number(profilesTToAdd));
    };

    const handleProfileTextChange = (event: React.ChangeEvent<{ value: string }>) => {
        setProfilesToAdd(event.target.value);
    };

    const isFetching =
        !searches || !searches.has(sourcer.sourcerId) || pendingRequests.has(`job-searches-stats-${sourcer.sourcerId}`);

    let content;
    let disableAdd = true;
    if (isFetching) {
        const size = 40;
        content = (
            <div className="spinner">
                <CircularProgress size={size} />
            </div>
        );
    } else {
        const jobSearchData = searches.get(sourcer.sourcerId);
        const newProfiles = _.sum(jobSearchData.searchStats.map((s) => s.ready));
        content = (
            <div>
                <p>{`A total of ${newProfiles} new profiles are available to add, and sourcer has a daily limit of ${sourcer.maxToAdd} sourcer members`}</p>
                <p>{`Are you sure to add ${sourcer.maxToAdd} profiles?`}</p>
                <TextField
                    className="search-field"
                    placeholder="Profiles to add"
                    value={profilesTToAdd}
                    onChange={handleProfileTextChange}
                    type="number"
                />
            </div>
        );
        if (newProfiles > 0 && !addingProspects && !!profilesTToAdd) {
            disableAdd = false;
        }
    }
    return (
        <Dialog open={true} maxWidth="md" css={styles}>
            <DialogTitle>
                <Typography variant="h4" component="span">
                    {sourcer.title}
                </Typography>
            </DialogTitle>
            <DialogContent className="content">{content}</DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={addingProspects}>
                    Cancel
                </Button>
                <Button disabled={disableAdd} onClick={handleAdd}>
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state: State): ConnectedProps => ({
    pendingRequests: state.pendingRequests,
    searches: state.searches
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    addSourcerMembersToSourcer,
    fetchCampaignSearchStats
};

export const SourcerAddProspects = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(SourcerAddProspectsComponent);
