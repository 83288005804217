import { Tooltip } from '@material-ui/core';
import { Map, OrderedMap } from 'immutable';
import { IconButton } from 'material-ui';
import { white } from 'material-ui/styles/colors';
import ActionDelete from 'material-ui/svg-icons/action/delete';
import * as React from 'react';
import { connect } from 'react-redux';

import { Permissions } from 'shared/models/permission';
import { hasRole } from 'shared/models/user';

import { getConfirmation, purgeJobCandidates } from '../actions';
import { Job, RequestErrors, State } from '../state';

interface OwnProps {
    jobId: string;
}

interface ConnectedProps {
    jobs: OrderedMap<string, Job>;
    pendingRequests: Map<string, RequestErrors>;
    userPermissions: Permissions;
}

interface ConnectedDispatch {
    getConfirmation: (onConfirm: () => void, description?: string, title?: string) => void;
    purgeJobCandidates: (jobId: string) => void;
}

type PurgeJobCandidatesProps = ConnectedProps & ConnectedDispatch & OwnProps;

class PurgeJobCandidatesComponent extends React.Component<PurgeJobCandidatesProps> {
    handleClick = () => {
        const description = 'Candidates in the sourced, email found, email pending or incomplete stage will be removed';
        this.props.getConfirmation(
            () => {
                this.setState({ loading: true });
                this.props.purgeJobCandidates(this.props.jobId);
            },
            description,
            'Confirm Purging Added Candidates for this Job'
        );
    };

    render() {
        const { jobId, jobs, pendingRequests, userPermissions } = this.props;
        const loading = pendingRequests.has(`job-purge-${jobId}`);
        if (!jobs || !jobs.get(jobId) || !hasRole(userPermissions, 'purge_candidate_access')) return null;
        return (
            <div>
                <Tooltip title="Purge Job Candidates in sourced, email found, email pending and incomplete columns">
                    <span>
                        <IconButton onClick={this.handleClick} disabled={loading}>
                            <ActionDelete color={white} />
                        </IconButton>
                    </span>
                </Tooltip>
            </div>
        );
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    jobs: state.jobs,
    pendingRequests: state.pendingRequests,
    userPermissions: state.session.userPermissions
});
const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    getConfirmation,
    purgeJobCandidates
};

export const PurgeCandidates = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(PurgeJobCandidatesComponent);
