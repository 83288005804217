import { Tooltip } from '@material-ui/core';
import { TextTransformProperty } from 'csstype';
import { Map, OrderedMap } from 'immutable';
import { Divider, Menu, MenuItem, Popover, RaisedButton } from 'material-ui';
import { grey900 } from 'material-ui/styles/colors';
import { AvNotInterested } from 'material-ui/svg-icons';
import * as React from 'react';
import { connect } from 'react-redux';

import { checkSendAccess } from 'shared/common/email-access';
import { interpolateEmailTemplate } from 'shared/common/interpolate-template';
import { EmailAccountView } from 'shared/models/email-account';
import { EmailTemplateView } from 'shared/models/email-templates';
import { hasJobPermission } from 'shared/models/job';
import {
    beingScheduledStage,
    CandidateDisqualReason,
    clientStages,
    emailFoundStage,
    getDisqualReasonLabel,
    JobStageData,
    outreachSentStage,
    responseReceivedStage,
    sourcedStage
} from 'shared/models/job-stages';
import { Permissions } from 'shared/models/permission';
import { hasRole, UserData } from 'shared/models/user';
import { ComposeEmailPayloadHeaders, EmailAddress } from 'shared/types/email-compose';
import { FilePayload } from 'shared/types/file-payload';

import {
    doNotEmailPerson,
    getEmailTemplates,
    moveCandidateToStage,
    personNotInterestedAtThisTime,
    setCandidateQualification,
    updatePersonContact
} from '../actions';
import { getAccountOptions } from '../common/email/account-options';
import { EmailKind } from '../common/email/header-validator';
import {
    EmailValidationData,
    replyToEmailWithValidation,
    replyToEmailWithValidationWithDifferentAccount,
    sendDisqualificationMail
} from '../email-compose/actions';
import { ComposeEmailWindowData } from '../email-compose/types';
import { getLastCommunicationWithCandidate } from '../lib/last-communication-with-candidate';
import {
    Candidate,
    Client,
    Communication,
    Contact,
    ContactType,
    EmailAccount,
    Job,
    List,
    PersonDetails,
    State
} from '../state';
import { DoNotEmailAgain } from './do-not-email-again';
import { NotInterestedRightNow } from './not-interested-right-now';

const styles = {
    menuItemTitle: {
        textTransform: 'uppercase' as TextTransformProperty
    }
};
interface MenuState {
    open: boolean;
    anchor: React.ReactInstance;
}
interface OwnProps {
    candidate: Candidate;
}

interface ConnectedProps {
    emailContentBlacklistedDomains: string;
    communications: Map<string, Communication[]>;
    contacts: Map<string, Contact[]>;
    emailTemplates: Map<string, EmailTemplateView[]>;
    jobs: OrderedMap<string, Job>;
    emailAccounts: Map<string, EmailAccount>;
    persons: List<PersonDetails>;
    clients: List<Client>;
    jobStages: JobStageData[];
    sessionUser: UserData;
    userPermissions: Permissions;
    users: Map<string, UserData>;
}

interface ConnectedDispatch {
    getEmailTemplates: (group: string) => void;
    setCandidateQualification: (candidate: Candidate, qualified: boolean, reason: string) => void;
    replyToEmailWithValidation: (
        accountOptions: EmailAddress[],
        emailContentBlacklistedDomains: string,
        message: Communication,
        replyAll: boolean,
        emailKind: EmailKind,
        validationData: EmailValidationData,
        archiveOnReply?: boolean,
        composeWindowData?: Partial<ComposeEmailWindowData>,
        initialBody?: string
    ) => void;
    replyToEmailWithValidationWithDifferentAccount: (
        accountOptions: EmailAddress[],
        emailContentBlacklistedDomains: string,
        message: Communication,
        newAccount: EmailAddress,
        replyAll: boolean,
        emailKind: EmailKind,
        validationData: EmailValidationData,
        composeWindowData?: Partial<ComposeEmailWindowData>
    ) => void;
    sendDisqualificationMail: (
        payload: ComposeEmailWindowData,
        sendAt: number,
        postSendUpdates: {
            disqualReason: CandidateDisqualReason;
            note?: string;
            optOutUntil?: number;
        }
    ) => void;
    doNotEmailPerson: (
        personId: string,
        payload: {
            note: string;
            clientId: string;
        }
    ) => void;
    personNotInterestedAtThisTime: (
        personId: string,
        payload: {
            jobId: string;
            note: string;
            optOutUntil: number;
        }
    ) => void;
    moveCandidateToStage: (candidate: Candidate, stage: string) => void;
    updatePersonContact: (
        personId: string,
        contact: Contact,
        attribute: keyof Contact,
        value: boolean | ContactType
    ) => void;
}

type DisqualifyCandidateButtonComponentProps = OwnProps & ConnectedProps & ConnectedDispatch;
interface DisqualifyCandidateButtonComponentState {
    doNotEmailDialogOpen: boolean;
    optOutDialogOpen: boolean;
    rejectMenu: MenuState;
}

class DisqualifyCandidateButtonComponent extends React.Component<
    DisqualifyCandidateButtonComponentProps,
    DisqualifyCandidateButtonComponentState
> {
    constructor(props: DisqualifyCandidateButtonComponentProps) {
        super(props);
        this.state = {
            doNotEmailDialogOpen: false,
            optOutDialogOpen: false,
            rejectMenu: { open: false, anchor: null }
        };

        if (!props.emailTemplates.get(props.candidate.stage)) {
            props.getEmailTemplates(props.candidate.stage);
        }
    }

    hasAccess = () => {
        const { candidate, sessionUser, jobs, persons, userPermissions } = this.props;
        const isAdmin = hasRole(userPermissions, 'candidate_dq_admin');
        if (isAdmin) {
            return true;
        } else if (candidate.stage === responseReceivedStage) {
            const { account, lastEmail } = this.getLastEmail();
            if (lastEmail) {
                const jobIds = lastEmail.jobIds || [];
                const commJobs = jobIds.map((j) => jobs.get(j));
                const personCandidates = persons.list
                    .get(candidate.personId)
                    .candidates.filter((c) => jobIds.includes(c.jobId));

                return (
                    !account ||
                    checkSendAccess(sessionUser, userPermissions, account, {
                        jobs: commJobs,
                        personsCandidates: personCandidates
                    })
                );
            } else {
                return true;
            }
        } else {
            return true;
        }
    };

    handleDisqualifyClick = (event: React.SyntheticEvent<{}>) => {
        event.preventDefault();
        const { candidate } = this.props;
        if (candidate.stage === emailFoundStage) {
            const reason = 'recruiter_declined_added_by_mistake';
            this.props.setCandidateQualification(candidate, false, reason);
        } else {
            this.setState({ rejectMenu: { open: true, anchor: event.target as React.ReactInstance } });
        }
    };

    handleMenuClose = () => {
        this.setState({ rejectMenu: { open: false, anchor: null } });
    };

    hideOptOutDialog = () => {
        this.setState({ optOutDialogOpen: false });
    };

    hideDoNotEmailDialog = () => {
        this.setState({ doNotEmailDialogOpen: false });
    };

    getLastEmail = () => {
        const { candidate, jobs, emailAccounts, communications, users } = this.props;
        const { jobId, personId } = candidate;
        const job = jobs.get(jobId);
        const personCommunications = communications.get(personId);
        if (!personCommunications || !job || emailAccounts.isEmpty()) {
            return {};
        }
        const lastEmail = getLastCommunicationWithCandidate(personCommunications, emailAccounts, candidate);
        let account: EmailAccountView;
        if (lastEmail) {
            const lastEmailAccount = emailAccounts.get(lastEmail.account);
            if (lastEmailAccount.syncStatus === 'enabled') {
                account = lastEmailAccount;
            } else if (lastEmailAccount.syncStatus === 'archived') {
                const assignedUser = users.find((u) => u.id === candidate.assignee);
                const accountOptions = getAccountOptions(emailAccounts, assignedUser, undefined);
                account = emailAccounts.get(accountOptions?.[0]?.address);
            } else {
                account = undefined;
            }
        }
        return { lastEmail, account };
    };

    showDisqualificationEmail = (updates: {
        disqualReason: CandidateDisqualReason;
        note?: string;
        optOutUntil?: number;
    }) => {
        const { candidate, clients, jobs, persons, emailTemplates, emailAccounts, contacts, users } = this.props;
        const { personId, jobId } = candidate;
        const personDetails = persons.list.get(personId);
        const job = jobs.get(jobId);
        const client = clients.list.get(job.clientId);
        const personContacts = contacts.get(personId);

        const { account: sender, lastEmail } = this.getLastEmail();
        if (lastEmail) {
            let body: string;
            let headers: ComposeEmailPayloadHeaders;
            const templates = emailTemplates.get(candidate.stage);
            if (templates && templates.length > 0) {
                const kind = `disqualification-${updates.disqualReason}`;
                const template = templates.find((t) => t.kind === kind);
                if (template) {
                    body = interpolateEmailTemplate(template.body, {
                        client,
                        job,
                        person: personDetails.person,
                        profile: personDetails.profile,
                        profileUrls: personDetails.profileUrls,
                        senderName: sender.name
                    });
                    headers = {
                        bcc: template.bcc,
                        cc: template.cc,
                        subject: undefined,
                        to: undefined
                    };
                }
            }

            const scheduledMessageRestricted = candidate.stage === responseReceivedStage;
            const onRequestSendAction: (payload: ComposeEmailWindowData, sendAt: number) => void =
                candidate.stage === responseReceivedStage &&
                !hasRole(this.props.userPermissions, 'email_reply_skip_admin')
                    ? (payload: ComposeEmailWindowData, sendAt: number) =>
                          this.props.sendDisqualificationMail(payload, sendAt, updates)
                    : null;

            let emailPayload = {
                headers,
                isClientComm: false,
                jobId,
                onRequestSendAction,
                personId,
                scheduledMessageRestricted
            };
            if (
                updates.disqualReason === 'candidate_declined_get_resume_comp_first' ||
                updates.disqualReason === 'candidate_declined_not_a_fit_requirements_changed' ||
                updates.disqualReason === 'candidate_declined_not_a_fit_ml_search_was_off'
            ) {
                const attachments: FilePayload[] = job.onePager
                    ? [
                          {
                              filename: job.onePager.key.split('/').reverse()[0],
                              key: `media/files/${job.onePager.key}`,
                              size: job.onePager.size,
                              type: 's3Key'
                          }
                      ]
                    : [];

                emailPayload = Object.assign({}, emailPayload, {
                    attachments,
                    onSentAction: moveCandidateToStage(candidate, beingScheduledStage)
                });
            }

            const validationData: EmailValidationData = {
                client,
                emailAccounts: emailAccounts.valueSeq().toArray(),
                job,
                personContacts,
                users: users.valueSeq().toArray()
            };
            const emailKind: EmailKind = 'candidate';
            const sendingAddress = { address: sender.email, name: sender.name.full };

            if (lastEmail.account === sender.email) {
                this.props.replyToEmailWithValidation(
                    [sendingAddress],
                    this.props.emailContentBlacklistedDomains,
                    lastEmail,
                    true,
                    emailKind,
                    validationData,
                    true,
                    emailPayload,
                    body
                );
            } else {
                this.props.replyToEmailWithValidationWithDifferentAccount(
                    [sendingAddress],
                    this.props.emailContentBlacklistedDomains,
                    lastEmail,
                    sendingAddress,
                    true,
                    emailKind,
                    validationData,
                    emailPayload
                );
            }
        }
    };

    handleRejectMenuClick = (reason: CandidateDisqualReason) => (event: React.SyntheticEvent<{}>) => {
        event.preventDefault();
        const { lastEmail } = this.getLastEmail();
        const { candidate, persons } = this.props;
        switch (reason) {
            case 'candidate_declined_do_not_email':
                this.setState({ rejectMenu: { open: false, anchor: null }, doNotEmailDialogOpen: true });
                break;
            case 'candidate_declined_not_right_time':
                this.setState({ rejectMenu: { open: false, anchor: null }, optOutDialogOpen: true });
                break;
            case 'candidate_declined_get_resume_comp_first':
            case 'candidate_declined_not_a_fit_requirements_changed':
            case 'candidate_declined_not_a_fit_ml_search_was_off':
                this.setState({ rejectMenu: { open: false, anchor: null } });
                this.props.setCandidateQualification(candidate, false, reason);
                this.showDisqualificationEmail({ disqualReason: reason });
                break;
            case 'candidate_declined_incorrect_email':
                this.setState({ rejectMenu: { open: false, anchor: null } });
                if (lastEmail) {
                    const addresses = [lastEmail.headers.from.address]
                        .concat(lastEmail.headers.to.map((t) => t.address))
                        .filter((a) => !!a && a !== lastEmail.account);
                    const { contacts } = persons.list.get(candidate.personId);
                    const contact = contacts.find((c) => addresses.includes(c.value));
                    if (contact) {
                        this.props.updatePersonContact(candidate.personId, contact, 'invalid', true);
                    }
                }
                this.props.moveCandidateToStage(candidate, sourcedStage);
                this.showDisqualificationEmail({ disqualReason: reason });
            case 'candidate_declined_ooo_response':
                this.props.moveCandidateToStage(candidate, outreachSentStage);
                this.showDisqualificationEmail({ disqualReason: reason });
            default:
                this.setState({ rejectMenu: { open: false, anchor: null } });
                if (
                    candidate.stage !== responseReceivedStage ||
                    !lastEmail ||
                    hasRole(this.props.userPermissions, 'email_reply_skip_admin') ||
                    hasRole(this.props.userPermissions, 'skip_candidate_reply')
                ) {
                    this.props.setCandidateQualification(candidate, false, reason);
                }
                this.showDisqualificationEmail({ disqualReason: reason });
        }
    };

    handleDoNotEmailAgain = (note: string) => {
        const { candidate } = this.props;
        this.hideDoNotEmailDialog();
        if (
            candidate.stage !== responseReceivedStage ||
            hasRole(this.props.userPermissions, 'email_reply_skip_admin') ||
            hasRole(this.props.userPermissions, 'skip_candidate_reply')
        ) {
            this.props.doNotEmailPerson(candidate.personId, {
                clientId: candidate.clientId,
                note
            });
        } else {
            this.showDisqualificationEmail({
                disqualReason: 'candidate_declined_do_not_email',
                note
            });
        }
    };

    handleNotInterestedRightNow = (note: string, optOutUntil: number) => {
        const { candidate } = this.props;
        this.hideOptOutDialog();
        if (
            candidate.stage !== responseReceivedStage ||
            hasRole(this.props.userPermissions, 'email_reply_skip_admin') ||
            hasRole(this.props.userPermissions, 'skip_candidate_reply')
        ) {
            this.props.personNotInterestedAtThisTime(candidate.personId, {
                jobId: candidate.jobId,
                note,
                optOutUntil
            });
        } else {
            this.showDisqualificationEmail({
                disqualReason: 'candidate_declined_not_right_time',
                note,
                optOutUntil
            });
        }
    };

    render() {
        const { jobStages, jobs, candidate } = this.props;
        const stage = jobStages.find((s) => s.name === this.props.candidate.stage);
        const optOutRejectionMenuItems: JSX.Element[] = [];
        const candidateRejectionReasons: string[] = stage.rejectionReasons.candidate;
        const recruiterRejectionReasons: string[] = stage.rejectionReasons.recruiter;
        const clientRejectionReasons: string[] = stage.rejectionReasons.client;

        const makeMenuItemsWithIcon = (iconClass: string) => (reason: CandidateDisqualReason) => {
            return (
                <MenuItem
                    key={reason}
                    desktop={true}
                    primaryText={getDisqualReasonLabel(reason)}
                    onClick={this.handleRejectMenuClick(reason)}
                    leftIcon={<i className={`fas ${iconClass}`} style={{ top: '8px', color: grey900 }} />}
                    innerDivStyle={{ paddingLeft: '60px' }}
                />
            );
        };

        let rejectMenuItems = candidateRejectionReasons
            .map(makeMenuItemsWithIcon('fa-user'))
            .concat(optOutRejectionMenuItems);

        if (recruiterRejectionReasons.length > 0) {
            if (rejectMenuItems.length > 0) {
                rejectMenuItems.push(<Divider key="recruiterDivider" />);
            }

            rejectMenuItems = rejectMenuItems.concat(recruiterRejectionReasons.map(makeMenuItemsWithIcon('fa-rocket')));
        }

        if (clientRejectionReasons.length > 0) {
            if (rejectMenuItems.length > 0) {
                rejectMenuItems.push(<Divider key="clientDivider" />);
            }

            rejectMenuItems = rejectMenuItems.concat(clientRejectionReasons.map(makeMenuItemsWithIcon('fa-building')));
        }

        const noRejectionOptionsForStage = rejectMenuItems.length === 0;

        const job = jobs.get(candidate.jobId);

        const forbidToDisqualifyAtClientStages =
            !hasJobPermission(job.recruiterPermissions.dqAtClientStage, this.props.sessionUser.id) &&
            clientStages.indexOf(candidate.stage) !== -1;

        const disabled = noRejectionOptionsForStage || !this.hasAccess() || forbidToDisqualifyAtClientStages;

        let tooltip: string = null;
        if (disabled) {
            if (noRejectionOptionsForStage) {
                tooltip = 'There are no rejection options for this stage';
            } else if (this.props.candidate.stage === responseReceivedStage) {
                const { lastEmail, account } = this.getLastEmail();
                if (!lastEmail) {
                    tooltip = 'An email to reply to could not be found';
                } else {
                    if (!account || account.syncStatus !== 'enabled') {
                        tooltip = `The email account ${account.email} is not currently enabled`;
                    } else {
                        tooltip = `You do not have access to send emails from ${account.email}`;
                    }
                }
            }
            if (forbidToDisqualifyAtClientStages) {
                tooltip = 'Please ask the AM to Disqualify';
            }
        } else {
            tooltip = 'Disqualify';
        }

        const button = (
            <span>
                <RaisedButton
                    icon={<AvNotInterested />}
                    secondary={true}
                    className="candidate-move-button"
                    disabled={disabled}
                    onClick={this.handleDisqualifyClick}
                />
            </span>
        );
        const buttonWithTooltip = tooltip ? <Tooltip title={tooltip}>{button}</Tooltip> : button;

        return (
            <div className="candidate-move-button-container candidate-move-button-container-small">
                {buttonWithTooltip}
                <Popover
                    anchorEl={this.state.rejectMenu.anchor}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    open={this.state.rejectMenu.open}
                    onRequestClose={this.handleMenuClose}
                    targetOrigin={{ horizontal: 'right', vertical: 'top' }}
                >
                    <Menu desktop={true}>
                        <MenuItem primaryText="Reason" desktop={true} style={styles.menuItemTitle} />
                        <Divider />
                        {rejectMenuItems}
                    </Menu>
                </Popover>
                <NotInterestedRightNow
                    show={this.state.optOutDialogOpen}
                    requestClose={this.hideOptOutDialog}
                    onDisqualify={this.handleNotInterestedRightNow}
                />
                <DoNotEmailAgain
                    show={this.state.doNotEmailDialogOpen}
                    requestClose={this.hideDoNotEmailDialog}
                    onDisqualify={this.handleDoNotEmailAgain}
                />
            </div>
        );
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    clients: state.clients,
    communications: state.communications,
    contacts: state.contacts,
    emailAccounts: state.emailAccounts,
    emailContentBlacklistedDomains: state.appConstants.constants.emailContentBlacklistedDomains,
    emailTemplates: state.emailTemplates,
    jobStages: state.appConstants.constants.jobStages,
    jobs: state.jobs,
    persons: state.personsDetails,
    sessionUser: state.session.user,
    userPermissions: state.session.userPermissions,
    users: state.users
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    doNotEmailPerson,
    getEmailTemplates,
    moveCandidateToStage,
    personNotInterestedAtThisTime,
    replyToEmailWithValidation,
    replyToEmailWithValidationWithDifferentAccount,
    sendDisqualificationMail,
    setCandidateQualification,
    updatePersonContact
};

export const DisqualifyCandidateButton = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(DisqualifyCandidateButtonComponent);
