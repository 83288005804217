import * as React from 'react';

import { NoteKind } from 'shared/models/note';

import { fetchClientsList, fetchPersonDetails } from '../actions';
import { Spinner } from '../core-ui/spinner';
import { useCandidateScore } from '../hooks/use-candidate-score';
import { useReduxDispatch, useReduxState } from '../hooks/use-redux';
import { State } from '../state';
import { PersonProfile } from './person';

interface CandidateDetailProps {
    id: string;
    jobId: string;
    tab?: string;
    menuButton?: JSX.Element;
    jobNotesOnly?: boolean;
    notesKind?: NoteKind;
}

export const CandidateDetails: React.FC<CandidateDetailProps> = ({
    id,
    jobId,
    tab,
    menuButton,
    jobNotesOnly,
    notesKind
}) => {
    const clients = useReduxState((state: State) => state.clients);
    const jobs = useReduxState((state: State) => state.jobs);
    const candidates = useReduxState((state: State) => state.candidates);
    const dispatch = useReduxDispatch();
    useCandidateScore(id, jobId);

    React.useEffect(() => {
        if (!candidates.get(jobId) || !candidates.get(jobId).get(id)) {
            dispatch(fetchPersonDetails(id, jobId));
        }
        if (!clients.list || !clients.initialized) {
            dispatch(fetchClientsList());
        }
    }, [id, jobId, candidates, clients, dispatch]);

    if (
        !jobs.get(jobId) ||
        !candidates.get(jobId) ||
        !candidates.get(jobId).get(id) ||
        !clients ||
        !clients.initialized
    ) {
        return <Spinner />;
    } else {
        const candidate = candidates.get(jobId).get(id);
        const job = jobs.get(candidate.jobId);
        const client = clients.list.get(job.clientId);
        return (
            <PersonProfile
                id={candidate.personId}
                candidate={candidate}
                job={jobs.get(candidate.jobId)}
                client={client}
                tab={tab}
                jobNotesOnly={jobNotesOnly}
                notesKind={notesKind}
                menuButton={menuButton}
            />
        );
    }
};
