export const openGoogleVoiceWindow = (link: string) => {
    const width = 600;
    const height = 550;
    const pixelOffsetX = 200;
    const pixelOffsetY = 200;
    const top = window.screenY + pixelOffsetY;
    const left = window.screenX + pixelOffsetX;
    window.open(
        link,
        '_blank',
        `location=no, toolbar=no, menubar=no,\
         height=${height}, width=${width}, top=${top}, left=${left}`
    );
};
