import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ProfileConflicts } from '../containers/profile-conflicts';

const ProfilesConflictPageComponent: React.FC<RouteComponentProps<{}>> = (props) => {
    const qs = new URLSearchParams(props.location.search);
    const personId1 = qs.get('personId1');
    const personId2 = qs.get('personId2');
    return <ProfileConflicts personId1={personId1} personId2={personId2} />;
};

export const ProfilesConflictPage = withRouter(ProfilesConflictPageComponent);
