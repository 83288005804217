import { Map } from 'immutable';
import * as _ from 'lodash';
import * as React from 'react';

import { communicationNeedsAction, filteredAttachmentsMimeTypes } from '../common/communication-utils';
import { CommunicationPreview } from '../sfc/communication-preview';
import { Communication as CommunicationType, EmailAccount } from '../state';
import { CommunicationDetails } from './communication-details';

interface CommunicationProps {
    personId: string;
    data: CommunicationType;
    actions: JSX.Element[];
    initiallyExpanded?: boolean;
    emailAccounts?: Map<string, EmailAccount>;
    jobId?: string;
    emailContentBlacklistedDomains: string;
    onReply?: (data: CommunicationType, initialBody?: string) => void;
}

export const Communication: React.FC<CommunicationProps> = (props) => {
    const needsAction = communicationNeedsAction(props.data);
    const [expanded, setExpanded] = React.useState(props.initiallyExpanded !== false && needsAction);

    const handleToggleExpand = (open: boolean) => () => {
        setExpanded(open);
    };

    const { data, personId, actions, emailAccounts, jobId } = props;

    const fromCrossSubmit =
        !data.isClientComm && emailAccounts && jobId && emailAccounts.get(data.account)
            ? emailAccounts.get(data.account).jobId !== jobId && (data.jobIds || []).includes(jobId)
            : false;

    const accountInfo =
        data.accountInfo.name.first.charAt(0).toLocaleUpperCase() +
        data.accountInfo.name.last.charAt(0).toLocaleUpperCase();

    return expanded ? (
        <CommunicationDetails
            personId={personId}
            jobId={jobId}
            actions={actions}
            data={data}
            handleClose={handleToggleExpand(false)}
            fromCrossSubmit={fromCrossSubmit}
            emailContentBlacklistedDomains={props.emailContentBlacklistedDomains}
            onReply={props.onReply}
        />
    ) : (
        <CommunicationPreview
            needsAction={needsAction}
            sender={data.headers.from.name || data.headers.from.address}
            hasAttachments={data.attachments.filter((a) => !filteredAttachmentsMimeTypes.has(a.mimeType)).length > 0}
            snippet={data.snippet}
            subject={data.subject}
            date={data.internalDate}
            handleSelect={handleToggleExpand(true)}
            accountInitials={accountInfo}
            fromCrossSubmit={fromCrossSubmit}
        />
    );
};
