// cspell:ignore 827lpokz 8ygjyg64
import produce from 'immer';
import React from 'react';

import { SearchTerm, SearchVisaOptionType } from 'shared/models/search';
import { SearchPresetData } from 'shared/models/search-preset';

import { SelectField } from '../core-ui/simple-select-field';
import { SearchGeneralLogisticsLocations } from './search-general-logistics-locations';
import { SearchGroup } from './search-group';
import { SearchRequirementsSection } from './search-requirements-section';
import { useSearch } from './use-search';
import { useSearchPresets } from './use-search-presets';

export const SearchConfigLogistics: React.FC = () => {
    const searchContext = useSearch();
    const config = searchContext.data.config;
    const onChange = searchContext.onFieldChange('config');
    const readonly = searchContext.readonly;
    const { searchPresets } = useSearchPresets();

    const { visa } = config.general;

    const presetToSearchTerm = (p: SearchPresetData): SearchTerm =>
        p ? { isPreset: true, label: p.name, presetId: p.id } : null;

    const selected = presetToSearchTerm(searchPresets?.find((p) => p.id === visa?.[0]?.presetId));

    const handleUpdateVisa = (selectedItem: SearchTerm<SearchVisaOptionType>) => {
        onChange(
            produce(config, (draft) => {
                draft.general.visa = selectedItem ? [selectedItem] : [];
            })
        );
    };

    const visaPresetIds = ['827lpokz', '8ygjyg64'];
    const visaOptions: SearchTerm[] =
        searchPresets?.filter((p) => visaPresetIds.includes(p.id))?.map(presetToSearchTerm) ?? [];
    const visaOptionLabel = (o: SearchTerm) => o.label;
    const visaMaxHeight = 500;

    return (
        <SearchRequirementsSection section="logistics">
            <SearchGeneralLogisticsLocations />
            <SearchGroup readonly={readonly} actions={[]} className="top-padding">
                <div className="search-requirements-field">
                    <label key="label" className="search-label">
                        Visa Status
                    </label>
                    <SelectField
                        className="search-config-custom-multi-select-field"
                        selected={selected}
                        onSelect={handleUpdateVisa}
                        options={visaOptions}
                        getOptionLabel={visaOptionLabel}
                        disabled={readonly}
                        maxMenuHeight={visaMaxHeight}
                        includeNoneOpt={true}
                    />
                </div>
            </SearchGroup>
        </SearchRequirementsSection>
    );
};
