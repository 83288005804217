import { css } from '@emotion/core';
import { Theme, Tooltip as MUITooltip, useTheme, withStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { startCase } from 'lodash';
import * as numeral from 'numeral';
import * as React from 'react';

import { useCompanySimilarity } from '../hooks/use-company-similarity';
import { useCrunchbaseData } from '../hooks/use-crunchbase-data';

const styles = (theme: Theme) => css`
    cursor: default;
    color: ${theme.palette.primary.main};
`;

const tooltipStyles = (theme: Theme) => css`
    background: white;
    display: flex;
    padding: 5px 5px 10px;
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;

    .company-info-title {
        text-transform: uppercase;
        font-size: 11px;
        margin: 3px 0 5px;
        font-weight: 600;
        text-align: center;
    }

    table.company-similarity-scores {
        border: none;

        td {
            padding: 2px 5px;
            text-align: left;

            &.progress-bar {
                min-width: 122px;
            }
        }
    }

    .company-crunchbase {
        display: flex;
        flex-direction: column;
        width: 250px;
        text-align: left;
        margin-left: 5px;
        border-right: thin solid ${theme.palette.divider};
        margin-right: 10px;
        padding-right: 10px;

        .company-crunchbase-data-primary {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
        }

        .company-crunchbase-data {
            margin: 5px 0 10px;

            .company-crunchbase-data-section-header {
                text-transform: uppercase;
                font-size: 10px;
                margin-bottom: 3px;
                opacity: 0.6;
            }
        }

        .company-crunchbase-link {
            .company-crunchbase-data {
                margin: 0;
            }
        }

        ul.company-crunchbase-investors {
            list-style-type: none;
            font-size: 11px;
            padding-inline-start: 0px;
            margin-block-start: 0;
        }
    }

    .company-similarity-table {
        width: 250px;
    }
`;

const maxWidth = 480;

const Tooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        color: theme.palette.text.primary,
        maxWidth
    }
}))(MUITooltip);

export const CompanyInfo: React.FC<{
    data: { companyName: string; companyUrl?: string };
    highlight?: (content: string) => string | JSX.Element | Array<string | JSX.Element>;
}> = ({ data, highlight }) => {
    const { companyUrl } = data;
    const theme = useTheme();
    const { isLoading: cbDataLoading, crunchbaseInfo } = useCrunchbaseData(companyUrl);
    const { isLoading: similarityLoading, similarityData } = useCompanySimilarity(companyUrl);

    const skeletonRows = 15;
    const skeletonItems = Array.from({ length: skeletonRows }, (_, i) => (
        <div key={i}>
            <Skeleton variant="text" />
        </div>
    ));

    let similarityInfo: JSX.Element;
    if (similarityData) {
        const similarityScoreRows = similarityData.scores.map((s) => {
            const percent = Math.floor(s.similarityScore * 100);
            const negativeStyle: React.CSSProperties = {
                width: percent < 0 ? `${Math.abs(percent)}%` : 0
            };
            const positiveStyle: React.CSSProperties = {
                width: percent > 0 ? `${percent}%` : 0
            };

            return (
                <tr key={s.name}>
                    <td>{s.name}</td>
                    <td className="progress-bar">
                        <div className="progress-bar-container">
                            <div className="progress-bar-negative">
                                <div className="progress-bar-not-filled-in" />
                                <div className="progress-bar-filled-in" style={negativeStyle} />
                            </div>
                            <div className="progress-bar-positive">
                                <div className="progress-bar-filled-in" style={positiveStyle} />
                                <div className="progress-bar-not-filled-in" />
                            </div>
                        </div>
                    </td>
                    <td>{percent}%</td>
                </tr>
            );
        });
        similarityInfo = (
            <div className="company-similarity-table">
                <div className="company-info-title">Similarity</div>
                <table className="company-similarity-scores">
                    <tbody>{similarityScoreRows}</tbody>
                </table>
            </div>
        );
    } else if (similarityLoading) {
        similarityInfo = (
            <div className="company-similarity-table">
                <div className="company-info-title">
                    <Skeleton variant="text" />
                </div>
                {skeletonItems}
            </div>
        );
    }

    let cbInfo: JSX.Element;
    if (crunchbaseInfo) {
        const shortDescription = crunchbaseInfo.shortDescription ? (
            <div className="company-crunchbase-data">{crunchbaseInfo.shortDescription}</div>
        ) : undefined;

        const numEmployees = crunchbaseInfo.numEmployees ? (
            <div className="company-crunchbase-data">
                <div className="company-crunchbase-data-section-header">Employees</div>
                {crunchbaseInfo.numEmployees}
            </div>
        ) : undefined;
        const lastSeries = crunchbaseInfo.lastSeries ? (
            <div className="company-crunchbase-data">
                <div className="company-crunchbase-data-section-header">Last Series</div>
                {startCase(crunchbaseInfo.lastSeries.name)}
            </div>
        ) : null;
        const totalFundingUSD = crunchbaseInfo.totalFundingUSD ? (
            <div className="company-crunchbase-data">
                <div className="company-crunchbase-data-section-header">Total Funding</div>
                {numeral(crunchbaseInfo.totalFundingUSD).format('($0,0a)')}
            </div>
        ) : undefined;
        const crunchbaseLink = crunchbaseInfo.crunchbaseLink ? (
            <div className="company-crunchbase-data">
                <a href={crunchbaseInfo.crunchbaseLink} target="_blank">
                    Link
                </a>
            </div>
        ) : null;

        const foundingDate = crunchbaseInfo.foundingDate ? (
            <div className="company-crunchbase-data">
                <div className="company-crunchbase-data-section-header">Founding Date</div>
                {crunchbaseInfo.foundingDate}
            </div>
        ) : undefined;

        const hqLocation =
            crunchbaseInfo.city && crunchbaseInfo.region && crunchbaseInfo.countryCode ? (
                <div className="company-crunchbase-data">
                    <div className="company-crunchbase-data-section-header">Headquarters</div>
                    {crunchbaseInfo.city}, {crunchbaseInfo.region}, {crunchbaseInfo.countryCode}
                </div>
            ) : undefined;

        const investors = crunchbaseInfo.investors.map((investor) => (
            <li key={investor.crunchbaseLink}>
                <a href={investor.crunchbaseLink} target="_blank">
                    {investor.name}
                </a>
            </li>
        ));
        const investorsList =
            crunchbaseInfo.investors.length > 0 ? (
                <div className="company-crunchbase-data">
                    <div className="company-crunchbase-data-section-header">Investors</div>
                    <ul className="company-crunchbase-investors">{investors}</ul>
                </div>
            ) : null;

        cbInfo =
            shortDescription || numEmployees || totalFundingUSD || lastSeries || investorsList || foundingDate ? (
                <div className="company-crunchbase">
                    <div className="company-info-title">Crunchbase Info</div>
                    <div className="company-crunchbase-data-primary">
                        {shortDescription}
                        {hqLocation}
                        {foundingDate}
                        {numEmployees}
                        {totalFundingUSD}
                        {lastSeries}
                        {investorsList}
                    </div>
                    <div className="company-crunchbase-link">{crunchbaseLink}</div>
                </div>
            ) : null;
    } else if (cbDataLoading) {
        cbInfo = (
            <div className="company-crunchbase">
                <div className="company-info-title">
                    <Skeleton variant="text" />
                </div>
                <div className="company-crunchbase-data-primary">
                    <div className="preview-loading">{skeletonItems}</div>
                </div>
            </div>
        );
    }

    const tippyContent =
        cbInfo || similarityInfo ? (
            <div css={tooltipStyles(theme)}>
                {cbInfo}
                {similarityInfo}
            </div>
        ) : null;

    const content = highlight?.(data.companyName) ?? data.companyName;

    return companyUrl && tippyContent ? (
        <Tooltip title={tippyContent} interactive={true} placement="right">
            <span css={styles(theme)}>{content}</span>
        </Tooltip>
    ) : (
        <span>{content}</span>
    );
};
