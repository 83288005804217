import { gql } from '@apollo/client';

export interface UserFavoritesData {
    users_favorites: Array<{
        person: {
            id: string;
            name: {
                full: string;
                first: string;
                last: string;
            };
        };
        personId: string;
        userId: string;
        createdAt: number;
    }>;
}

export interface UserFavoritesVariables {
    userId: string;
}

export const GET_USER_FAVORITES = gql`
    query GetUserFavorites($userId: String!) {
        users_favorites(where: { userId: { _eq: $userId } }) {
            person {
                id
                name
            }
            personId
            userId
            createdAt
        }
    }
`;

export interface MutateUserFavoriteVariables {
    userId: string;
    personId: string;
}

export const CREATE_USER_FAVORITE = gql`
    mutation upsertUserFavorite($userId: String!, $personId: String!) {
        insert_users_favorites(
            objects: [{ userId: $userId, personId: $personId }]
            on_conflict: { constraint: users_favorites_pkey, update_columns: [] }
        ) {
            returning {
                userId
                personId
                createdAt
                modifiedAt
            }
        }
    }
`;

export const DELETE_USER_FAVORITE = gql`
    mutation deleteUserFavorite($userId: String!, $personId: String!) {
        delete_users_favorites(where: { personId: { _eq: $personId }, userId: { _eq: $userId } }) {
            returning {
                userId
                personId
                createdAt
                modifiedAt
            }
        }
    }
`;
