import { gql } from '@apollo/client';
import { internalClientIds } from 'shared/common/job-constants';

export interface RecruiterAllocation {
    id: string;
    name: string;
    targets: Array<{
        goal: number;
    }>;
    last14dFunnel: Array<{
        count: number;
    }>;
    allocations: Array<{
        acceptsGoal: number;
        job: {
            id: string;
            title: string;
            accountManagerId: string;
            client: {
                id: string;
                name: string;
            };
        };
    }>;
    allocations_aggregate: {
        aggregate: {
            sum: {
                acceptsGoal: number;
            };
        };
    };
}

export interface RecruiterAllocations {
    users: RecruiterAllocation[];
}

export interface JobAllocation {
    id: string;
    title: string;
    client: {
        id: string;
        name: string;
    };
    accountManagerId: string;
    target: {
        goal: number;
    };
    metrics: {
        accepts: number;
    };
    allocations: Array<{
        user: {
            id: string;
            name: string;
        };
        acceptsGoal: number;
    }>;
    allocations_aggregate: {
        aggregate: {
            sum: {
                acceptsGoal: number;
            };
        };
    };
}

export interface JobAllocations {
    jobs: JobAllocation[];
}

export const ALLOCATIONS_BY_RECRUITER = gql`
    query RecruiterAllocations {
        users(
            where: {
                targets: { stage: { _eq: "client_first_round" }, goal: { _gt: 0 } }
                _or: [
                    { status: { _eq: "active" }, roles: { role: { _eq: recruiter } } }
                    {
                        allocations: {
                            job: {
                                status: { _eq: 1 }
                                clientId: { _nin: ${JSON.stringify(internalClientIds)} }
                                jobType: { _neq: "Staffing" }
                            }
                        }
                    }
                ]
            }
            order_by: { name: asc }
        ) {
            id
            name(path: "full")
            targets(where: { stage: { _eq: "client_first_round" } }) {
                goal
            }
            last14dFunnel(where: { stage: { _eq: "client_first_round" } }) {
                count
            }
            allocations(
                where: {
                    job: {
                        status: { _eq: 1 }
                        clientId: { _nin: ${JSON.stringify(internalClientIds)} }
                        jobType: { _neq: "Staffing" }
                    }
                }
                order_by: [{ job: { client: { name: asc } } }, { job: { title: asc } }]
            ) {
                acceptsGoal
                job {
                    id
                    title
                    accountManagerId
                    client {
                        id
                        name
                    }
                }
            }
            allocations_aggregate(
                where: {
                    job: {
                        status: { _eq: 1 }
                        clientId: { _nin: ${JSON.stringify(internalClientIds)} }
                        jobType: { _neq: "Staffing" }
                    }
                }
            ) {
                aggregate {
                    sum {
                        acceptsGoal
                    }
                }
            }
        }
    }
`;

export const ALLOCATIONS_BY_JOB = gql`
    query JobAllocations {
        jobs(
            where: {
                status: { _eq: 1 }
                clientId: { _nin: ${JSON.stringify(internalClientIds)} }
                jobType: { _neq: "Staffing" }
                funnel_targets: { client_first_round: { _gt: "0" } }
            }
            order_by: [{ client: { name: asc } }, { title: asc }]
        ) {
            id
            title
            client {
                id
                name
            }
            accountManagerId
            target: funnel_targets {
                goal: client_first_round
            }
            metrics: job_metrics {
                accepts
            }
            allocations(where: { user: { status: { _eq: "active" } } }, order_by: [{ user: { name: asc } }]) {
                user {
                    id
                    name(path: "full")
                }
                acceptsGoal
            }
            allocations_aggregate(where: { user: { status: { _eq: "active" } } }) {
                aggregate {
                    sum {
                        acceptsGoal
                    }
                }
            }
        }
    }
`;

export const DELETE_ALLOCATION = gql`
    mutation DeleteAllocation($jobId: String!, $userId: String!) {
        delete_job_allocations(where: { jobId: { _eq: $jobId }, userId: { _eq: $userId } }) {
            affected_rows
        }
    }
`;

export const CREATE_ALLOCATION = gql`
    mutation CreateAllocation($jobId: String!, $userId: String!, $acceptsGoal: Int!) {
        insert_job_allocations(objects: { jobId: $jobId, userId: $userId, acceptsGoal: $acceptsGoal }) {
            affected_rows
        }
    }
`;
