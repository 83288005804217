import { Menu, MenuItem } from '@material-ui/core';
import * as React from 'react';

export interface SearchGroupAction {
    name: string;
    onSelect: () => void;
    checked?: boolean;
    style?: React.CSSProperties;
}

interface SearchGroupProps {
    actions: SearchGroupAction[];
    className?: string;
    readonly: boolean;
}

export const SearchGroup: React.FC<SearchGroupProps> = (props) => {
    const { children, actions, className, readonly } = props;

    const [open, setOpen] = React.useState(false);
    const [anchor, setAnchor] = React.useState<Element>(null);

    const handleOpen = (e: React.MouseEvent) => {
        setOpen(true);
        setAnchor(e.currentTarget);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSelect = (action: SearchGroupAction) => () => {
        handleClose();
        action?.onSelect();
    };

    const actionButton =
        actions.length > 0 ? (
            <button className="search-group-action-button" onClick={handleOpen}>
                <i className="fas fa-chevron-circle-down" />
            </button>
        ) : null;
    const menuItems = actions.map((a) => (
        <MenuItem
            key={a.name}
            dense={true}
            onClick={handleSelect(a)}
            selected={a.checked}
            style={{ minWidth: '100px', ...a.style }}
        >
            {a.name}
        </MenuItem>
    ));
    const actionsPopover = (
        <Menu open={open} anchorEl={anchor} onClose={handleClose}>
            {menuItems}
        </Menu>
    );
    const actionsGroup = readonly ? null : (
        <div className="search-group-actions">
            {actionButton}
            {actionsPopover}
        </div>
    );
    return (
        <div className={`search-group ${className || ''}`}>
            <div className="search-group-content">{children}</div>
            {actionsGroup}
        </div>
    );
};
