import { useMutation, useQuery } from '@apollo/client';
import { CircularProgress, Dialog, FlatButton, IconButton, Menu, MenuItem, Popover } from 'material-ui';
import * as React from 'react';

import { hasRole } from 'shared/models/user';

import { client } from '../graphql/apollo-client';
import {
    ADD_USER_BLACKLIST,
    DELETE_USER_BLACKLIST,
    GET_PERSON_BLACKLIST,
    PersonBlacklistStatus,
    UPDATE_GLOBAL_BLACKLIST
} from '../graphql/queries/person';
import { CREATE_MENTION, UserMentionData } from '../graphql/queries/user-mentions';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';

const loadingSize = 20;
const loadingThickness = 2;

export const PersonBlacklisted: React.FC<{ personId: string; jobId?: string }> = ({ personId, jobId }) => {
    const { user, userPermissions } = useSession();
    const adminClient = client('blacklist_editor');
    const { data, refetch, loading } = useQuery<PersonBlacklistStatus, { personId: string; userId: string }>(
        GET_PERSON_BLACKLIST,
        {
            variables: { personId, userId: user.id }
        }
    );
    const [addUserBlacklist, { loading: adding }] = useMutation<{}, { personId: string; userId: string }>(
        ADD_USER_BLACKLIST
    );
    const [removeUserBlacklist, { loading: removing }] = useMutation<{}, { personId: string; userId: string }>(
        DELETE_USER_BLACKLIST
    );
    const [updateGlobalBlacklist, { loading: updatingGlobal }] = useMutation<
        {},
        { personId: string; blacklisted: boolean }
    >(UPDATE_GLOBAL_BLACKLIST, { client: adminClient });
    const [requestBlacklist, { loading: requestingGlobal }] = useMutation<{}, { object: UserMentionData }>(
        CREATE_MENTION
    );
    const userBlacklisted = !!data?.user;
    const globalBlacklisted = data?.global.blacklisted;
    const [dialog, setDialog] = React.useState<'global' | 'user'>(null);
    const [popoverOpen, setPopoverOpen] = React.useState(false);
    const [popoverAnchor, setPopoverAnchor] = React.useState(null);
    const { setSnackbar } = useSnackbar();

    const isAdmin = hasRole(userPermissions, 'blacklist_editor');
    const disabled = !isAdmin && globalBlacklisted;

    const handleCloseDialog = () => {
        setDialog(null);
    };

    const handleClick = (event: React.MouseEvent) => {
        if (!disabled) {
            setPopoverOpen(true);
            setPopoverAnchor(event.currentTarget);
        }
    };

    const handleClosePopover = () => {
        setPopoverOpen(false);
    };

    const handleMenuClick = (kind: 'global' | 'user') => () => {
        setDialog(kind);
        setPopoverOpen(false);
    };

    const handleToggleBlacklist = (kind: 'global' | 'user') => async () => {
        setDialog(null);
        if (kind === 'global') {
            if (isAdmin) {
                const blacklisted = !data.global.blacklisted;
                setSnackbar('Updating global do not email list');
                await updateGlobalBlacklist({ variables: { personId, blacklisted } });
                setSnackbar('Updated global do not email list');
            } else {
                setSnackbar('Requested add to global do not email list');
                await requestBlacklist({ variables: { object: { kind: 'blacklist-request', personId, jobId } } });
                await addUserBlacklist({ variables: { personId, userId: user.id } });
            }
        } else {
            if (data?.user) {
                await removeUserBlacklist({ variables: { personId, userId: user.id } });
            } else {
                await addUserBlacklist({ variables: { personId, userId: user.id } });
            }
        }
        refetch();
    };

    const inProgress = !data || loading || adding || removing || updatingGlobal || requestingGlobal;

    let dialogContent;
    if (dialog) {
        const prefix = isAdmin ? '' : 'Request';
        const buttonText =
            dialog === 'global'
                ? globalBlacklisted
                    ? `${prefix} Remove from Global Do Not Email list`
                    : `${prefix} Add to Global Do Not Email list`
                : userBlacklisted
                  ? 'Remove from Personal Do Not Email list'
                  : 'Add to Personal Do Not Email list';
        const actions = [
            <FlatButton label="Cancel" onClick={handleCloseDialog} key="cancel" />,
            <FlatButton label={buttonText} onClick={handleToggleBlacklist(dialog)} key="add" />
        ];
        const text =
            dialog === 'global'
                ? globalBlacklisted
                    ? 'Are you sure to remove this profile from global Do Not Email list?'
                    : 'Are you sure to add this profile to global Do Not Email list? This will disqualify this person from all jobs in stages before Being Scheduled AND disable adding to any other job'
                : userBlacklisted
                  ? 'Are you sure to remove this profile from your Personal Do Not Email list?'
                  : 'Are you sure to add this profile to your Personal Do Not Email list? This will disqualify this person in all your assigned jobs in stages before Being Scheduled AND disable adding by you to any job';

        dialogContent = (
            <Dialog open={true} title={`Update Do Not Email Status`} actions={actions}>
                <div className="blacklist-content">{text}</div>
            </Dialog>
        );
    }

    const iconClass = globalBlacklisted || userBlacklisted ? 'person_add_disabled' : 'block';
    const toolTip = disabled
        ? 'In Global Do Not Email list'
        : globalBlacklisted || userBlacklisted
          ? 'Remove from Do Not Email list'
          : 'Add to Do Not Email list';
    const icon = !data ? null : inProgress ? (
        <CircularProgress size={loadingSize} color="white" thickness={loadingThickness} />
    ) : (
        <IconButton
            tooltip={toolTip}
            iconStyle={{ color: 'white' }}
            onClick={handleClick}
            disableTouchRipple={disabled}
            style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
        >
            <i className="material-icons">{iconClass}</i>
        </IconButton>
    );
    const adminPersonalBlacklistMenuItem = globalBlacklisted ? null : (
        <MenuItem primaryText="Update Personal Do Not Email status" onClick={handleMenuClick('user')} />
    );
    return (
        <div className={`header-candidate-action ${globalBlacklisted || userBlacklisted || inProgress ? 'show' : ''}`}>
            {icon}
            <Popover
                open={popoverOpen}
                anchorEl={popoverAnchor}
                anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                targetOrigin={{ horizontal: 'left', vertical: 'top' }}
                onRequestClose={handleClosePopover}
            >
                <Menu desktop={true}>
                    <MenuItem primaryText="Update Global Do Not Email status" onClick={handleMenuClick('global')} />
                    {adminPersonalBlacklistMenuItem}
                </Menu>
            </Popover>{' '}
            {dialogContent}
        </div>
    );
    return <div />;
};
