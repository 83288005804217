import { css } from '@emotion/core';
import { fade, Theme, Tooltip, useTheme } from '@material-ui/core';
import React from 'react';

import { percentageChange, Stat } from '../common/recruiter-stats';

// tslint:disable: no-magic-numbers
const styles = (theme: Theme) => css`
    display: flex;
    align-items: center;

    .stat-main.clickable {
        cursor: pointer;
    }

    .delta {
        cursor: default;
        height: 20px;
        display: inline-flex;
        padding: 2px 8px;
        flex-grow: 0;
        font-size: 0.75rem;
        min-width: 20px;
        align-items: center;
        flex-shrink: 0;
        font-weight: 500;
        white-space: nowrap;
        border-radius: 2px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        justify-content: center;
        margin-left: 8px;

        &.period {
            &.up {
                color: ${theme.palette.success.main};
                background-color: ${fade(theme.palette.success.main, 0.08)};
            }

            &.down {
                color: ${theme.palette.error.main};
                background-color: ${fade(theme.palette.error.main, 0.08)};
            }
        }

        &.average {
            &.up {
                color: ${theme.palette.info.main};
                background-color: ${fade(theme.palette.info.main, 0.08)};
            }

            &.down {
                color: ${theme.palette.warning.main};
                background-color: ${fade(theme.palette.warning.main, 0.08)};
            }
        }
    }
`;
// tslint:enable: no-magic-numbers

const Change: React.FC<{
    value: number;
    pastValue: number;
    className: string;
    description: string;
}> = ({ value, pastValue, className, description }) => {
    if (value === undefined || pastValue === undefined) return null;
    const change = pastValue === 0 ? 0 : percentageChange(value, pastValue);
    const tooltip =
        pastValue === 0 ? (
            value === 0 ? (
                `Flat at 0 ${description}`
            ) : (
                `Up from 0 to ${value.toLocaleString()} ${description}`
            )
        ) : change === 0 ? (
            `Flat at ${value.toLocaleString()} ${description}`
        ) : (
            <div style={{ textAlign: 'center' }}>
                {Math.abs(change)}% {change >= 0 ? 'higher' : 'lower'} {description}
                <br />
                {pastValue.toLocaleString()} vs {value.toLocaleString()}
            </div>
        );

    const content = pastValue === 0 ? '-' : `${change > 0 ? '+' : ''}${change}%`;
    return (
        <Tooltip title={tooltip}>
            <span className={`${className} delta ${pastValue === 0 || change >= 0 ? 'up' : 'down'}`}>{content}</span>
        </Tooltip>
    );
};

export const FunnelStat: React.FC<{
    data: Stat;
    description: string;
    period: string;
    baselinePeriod: string;
    target?: number;
    formatter?: (value: number) => string;
    onClick?: () => void;
}> = ({ data, description, period, baselinePeriod, formatter, target, onClick }) => {
    const theme = useTheme();

    const mainStat = data?.value === undefined ? '-' : formatter ? formatter(data.value) : data.value.toLocaleString();
    const tooltip = data?.value !== undefined ? `${data?.value} ${description} in ${period}` : '';

    const handleStatClick = (e: React.MouseEvent) => {
        if (onClick) {
            e.preventDefault();
            e.stopPropagation();
            onClick();
        }
    };

    const targetClass =
        target === undefined || data?.value === undefined || target === 0
            ? ''
            : data.value >= target
              ? 'success'
              : data.value >= target / 2
                ? 'warning'
                : 'error';

    return (
        <div className="funnel-stat" css={styles(theme)}>
            <span className={`stat-main ${targetClass} ${onClick ? 'clickable' : ''}`} onClick={handleStatClick}>
                <Tooltip title={tooltip}>
                    <span>{mainStat}</span>
                </Tooltip>
            </span>
            <Change
                value={data?.value}
                pastValue={data?.average}
                className="average"
                description={`compared to Rocket average for ${period}`}
            />
            <Change
                value={data?.value}
                pastValue={data?.periodBaseline}
                className="period"
                description={`compared to ${baselinePeriod}`}
            />
        </div>
    );
};
