import { useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import { Paper, Tab, Tabs, Theme, Tooltip, useTheme } from '@material-ui/core';
import { Attachment, ChevronLeft, Email, Notes, Person, Work } from '@material-ui/icons';
import * as React from 'react';
import { NoteDataWithUser, NOTES } from '../graphql/queries/person';

import { PersonProfile } from '../sfc/person-profile';
import { Client, Job, PersonDetails } from '../state';
import { Notes as NotesComponent } from './notes';
import { PersonCandidacies } from './person-candidacies';
import { PersonCommunications } from './person-communications';
import { PersonFiles } from './person-files';

// tslint:disable: no-magic-numbers
const collapsedStyles = css`
    padding: 14px 15px;
`;

type ProfileTabs = 'profile' | 'emails' | 'notes' | 'jobs' | 'attachments';

const styles = (theme: Theme) => css`
    flex-grow: 1;
    flex-basis: 0;
    margin: 11px 12px 12.5px 15px;
    overflow: hidden;
    padding: 3px;

    .phonescreen-profile-panel {
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .tabs-actions {
        border-bottom: thin solid #e0e0e0;
        display: flex;

        .collapse-button {
            flex: 0 0 auto;
            cursor: pointer;
            padding: 8px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                background: ${theme.palette.action.hover};
            }
        }
    }

    .tabs {
        flex: 1 1 auto;

        .MuiTab-wrapper {
            font-size: 0.78rem;
            line-height: 1.5;
        }

        .MuiTab-root,
        .MuiTabs-root {
            min-height: 41px;
            min-width: 80px;
        }
    }

    .content {
        flex: 1 1 auto;
        overflow: auto;
    }

    .detailed-profile {
        transform: scale(0.85);
        transform-origin: top left;
        width: 117%;
        height: 100%;
    }

    .detailed-profile-card {
        border: none;
    }

    .phonescreen-profile-emails {
        padding: 15px 10px;
        transform: scale(0.9);
        transform-origin: top left;
        width: 106%;
        height: 100%;
    }

    .candidacies {
        padding: 10px;

        .rightbar-card {
            border: none;
            display: block;
        }

        .person-candidacy {
            font-size: 14px;
            line-height: 22px;

            &.disqualified {
                opacity: 0.6;
            }

            i.material-icons {
                font-size: 20px;
            }

            padding-bottom: 18px;
            border-bottom: thin solid #e0e0e0;
            margin-bottom: 18px;

            .job-candidate-status,
            .job-title {
                display: flex;
                align-items: center;
            }

            .job-candidate-status .material-icons,
            .material-icons.list-icon {
                margin-right: 8px;
            }

            i.job-status-icon.material-icons {
                margin-left: 3px;
            }

            .job-last-stage-change,
            .job-candidate-status,
            .job-stage {
                margin-left: 28px;
            }
        }
    }

    .phonescreen-person-notes {
        transform: scale(0.85);
        transform-origin: top left;
        width: 117%;
    }

    .attachments {
        padding: 20px;

        .person-props:hover {
            background: inherit;
        }

        .person-prop-row {
            margin-top: 20px;
        }

        .prop-icon.material-icons.section-icon {
            display: none;
        }
    }

    .profile-essential {
        display: flex;
        overflow: hidden;
        justify-content: space-between;
        margin-bottom: 4px;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 14px;
        background: #f8f8f8;
        padding: 7px;
        border-radius: 2px;

        &:hover {
            background: #e8e8e8;
        }
    }
`;
// tslint:enable: no-magic-numbers

export const PhoneScreenProfile: React.FC<{ personDetails: PersonDetails; job: Job; client: Client }> = ({
    personDetails,
    job,
    client
}) => {
    const theme = useTheme();
    const { data: notesData } = useQuery<{ notes: NoteDataWithUser[] }>(NOTES, {
        variables: { notable: `persons-${personDetails.person.id}` }
    });
    const [selectedTab, setSelectedTab] = React.useState<ProfileTabs | false>('profile');
    const [collapsed, setCollapsed] = React.useState(false);

    const handleTabChange = (_1: any, tab: ProfileTabs) => {
        setSelectedTab(tab);
    };

    const handleCollapse = () => {
        setCollapsed(!collapsed);
        setSelectedTab(false);
    };

    const handleCollapsedTabChange = (_1: any, tab: ProfileTabs) => {
        setSelectedTab(tab);
        setCollapsed(false);
    };

    const emails = personDetails.contacts.filter((c) => c.channel === 'email');

    const tabbedPanelContent =
        selectedTab === 'emails' ? (
            <div className="phonescreen-profile-emails">
                <PersonCommunications person={personDetails.person} job={job} contacts={emails} client={client} />
            </div>
        ) : selectedTab === 'profile' ? (
            <PersonProfile
                personId={personDetails.person.id}
                profile={personDetails.profile?.content}
                viewedAt={personDetails.profile?.viewedAt}
                showYearsExpSummary={true}
            />
        ) : selectedTab === 'notes' ? (
            <div className="phonescreen-person-notes">
                <NotesComponent notes={notesData?.notes ?? []} jobId={job.id} />
            </div>
        ) : selectedTab === 'jobs' ? (
            <div className="candidacies">
                <PersonCandidacies candidates={personDetails.candidates} currentJobId={job.id} />
            </div>
        ) : selectedTab === 'attachments' ? (
            personDetails.person.files.length > 0 ? (
                <div className="attachments">
                    <PersonFiles person={personDetails.person} viewOnly={true} />
                </div>
            ) : (
                <div className="attachments">No Files</div>
            )
        ) : null;

    const content = collapsed ? (
        <div css={collapsedStyles}>
            <Paper>
                <Tabs value={selectedTab} onChange={handleCollapsedTabChange} orientation="vertical">
                    <Tab label="Profile" value="profile" />
                    <Tab label="Emails" value="emails" />
                    <Tab label="Notes" value="notes" />
                    <Tab label="Jobs Progress" value="jobs" />
                    <Tab label="Files" value="attachments" />
                </Tabs>
            </Paper>
        </div>
    ) : (
        <div css={styles(theme)}>
            <Paper className="phonescreen-profile-panel">
                <div className="tabs-actions">
                    <div className="collapse-button" onClick={handleCollapse}>
                        <Tooltip title="Collapse">
                            <ChevronLeft />
                        </Tooltip>
                    </div>
                    <div className="tabs">
                        <Tabs value={selectedTab} onChange={handleTabChange} variant="fullWidth">
                            <Tab icon={<Person />} value="profile" />
                            <Tab icon={<Email />} value="emails" />
                            <Tab icon={<Notes />} value="notes" />
                            <Tab icon={<Work />} value="jobs" />
                            <Tab icon={<Attachment />} value="attachments" />
                        </Tabs>
                    </div>
                </div>
                <div className="content">{tabbedPanelContent}</div>
            </Paper>
        </div>
    );

    return content;
};
