import { css } from '@emotion/core';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React, { createContext, FC, useContext, useState } from 'react';

import { zIndexes } from '../common/css-variables';
import { DurationProgress } from '../common/duration-progress';

const styles = css`
    .MuiDialogContent-root {
        line-height: 1.4rem;
        min-width: 480px;
        overflow-x: hidden;
    }
`;

const loadingModalStyles = css`
    .MuiDialogContent-root {
        padding: 30px 20px;
    }
`;

interface Modal {
    setAlert: (
        title: string | JSX.Element,
        description: string | JSX.Element,
        nonCancelable?: boolean,
        fullWidth?: boolean
    ) => void;
    getConfirmation: (
        onConfirm: () => void,
        description?: string | JSX.Element,
        title?: string,
        nonCancelable?: boolean
    ) => void;
    showLoading: (durationSeconds?: number) => void;
    hideLoading: () => void;
    showDialog: (dialog: JSX.Element) => void;
    hideDialog: () => void;
}

const ModalContext = createContext<Modal>(undefined);

const ModalProvider: FC = ({ children }) => {
    const [loadingData, setLoadingData] = useState<{ duration?: number } | null>(null);
    const [alertData, setAlertData] = useState<{
        title: string | JSX.Element;
        description: string | JSX.Element;
        nonCancelable?: boolean;
        fullWidth?: boolean;
    }>(null);
    const [confirmationData, setConfirmationData] = useState<{
        title: string | JSX.Element;
        description: string | JSX.Element;
        onConfirm: () => void;
        nonCancelable?: boolean;
    }>(null);
    const [dialog, setDialog] = useState<JSX.Element>(null);

    const showLoading = (duration?: number) => setLoadingData({ duration });
    const hideLoading = () => setLoadingData(null);
    const showDialog = setDialog;
    const hideDialog = () => setDialog(null);

    const setModal = (
        title: string | JSX.Element,
        description: string | JSX.Element,
        nonCancelable?: boolean,
        fullWidth?: boolean
    ) => {
        setAlertData({ title, description, nonCancelable, fullWidth });
    };

    const getConfirmation = (onConfirm: () => void, description?: string, title?: string, nonCancelable?: boolean) => {
        setConfirmationData({ title, description, onConfirm, nonCancelable });
    };

    const handleCloseModal = () => setAlertData(null);

    const alertDialog = alertData ? (
        <Dialog
            open={true}
            onClose={alertData.nonCancelable ? null : handleCloseModal}
            style={{ zIndex: zIndexes.modalDialog }}
            css={styles}
            maxWidth="md"
            fullWidth={alertData.fullWidth}
        >
            <DialogTitle>
                <Typography variant="h4" component="div">
                    {alertData.title}
                </Typography>
            </DialogTitle>
            <DialogContent style={{ lineHeight: '1.4rem' }}>{alertData.description}</DialogContent>
            <DialogActions>
                <Button onClick={handleCloseModal} disabled={alertData?.nonCancelable}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    ) : null;

    const handleCloseConfirmation = (confirmed: boolean) => () => {
        if (confirmed) {
            confirmationData.onConfirm();
        }
        setConfirmationData(null);
    };

    let confirmationDialog = null;
    if (confirmationData) {
        const buttons = confirmationData.nonCancelable ? (
            <Button onClick={handleCloseConfirmation(true)}>OK</Button>
        ) : (
            <>
                <Button onClick={handleCloseConfirmation(false)}>Cancel</Button>
                <Button onClick={handleCloseConfirmation(true)}>Continue</Button>
            </>
        );
        confirmationDialog = (
            <Dialog
                open={true}
                onClose={handleCloseModal}
                style={{ zIndex: zIndexes.modalDialog }}
                css={styles}
                maxWidth="md"
            >
                <DialogTitle>
                    <Typography variant="h4" component="div">
                        {confirmationData.title}
                    </Typography>
                </DialogTitle>

                <DialogContent>{confirmationData.description}</DialogContent>
                <DialogActions>{buttons}</DialogActions>
            </Dialog>
        );
    }

    const loadingDialog = loadingData ? (
        <Dialog
            open={true}
            style={{ zIndex: zIndexes.modalDialog }}
            css={loadingModalStyles}
            maxWidth="sm"
            fullWidth={true}
        >
            <DialogContent>
                <DurationProgress duration={loadingData.duration} />
            </DialogContent>
        </Dialog>
    ) : null;

    return (
        <ModalContext.Provider
            value={{ setAlert: setModal, getConfirmation, showLoading, hideLoading, showDialog, hideDialog }}
        >
            {children}
            {alertDialog}
            {confirmationDialog}
            {loadingDialog}
            {dialog}
        </ModalContext.Provider>
    );
};

const useModal = () => useContext(ModalContext);

export { ModalProvider, useModal };
