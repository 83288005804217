import { css } from '@emotion/core';
import { Avatar, fade, Tooltip } from '@material-ui/core';
import React from 'react';

export const InfoIcon: React.FC<{ icon: JSX.Element; color: string; tooltip: string; className?: string }> = ({
    icon,
    color,
    tooltip,
    className
}) => {
    const fadeRate = 0.08;
    const bgColor = fade(color, fadeRate);
    const styles = css`
        .MuiAvatar-root {
            width: 20px;
            height: 20px;

            color: ${color};
            background: ${bgColor};
            font-size: 12px;

            .MuiSvgIcon-root {
                font-size: 12px;
            }
        }
    `;
    return (
        <Tooltip title={tooltip}>
            <span css={styles} className={className ?? ''}>
                <Avatar>{icon}</Avatar>
            </span>
        </Tooltip>
    );
};
