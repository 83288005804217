import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { School, SCHOOL_QUERY } from '../graphql/queries/schools';

const minInputLengthForSearch = 3;

export const useSchoolNames = (partial: string) => {
    // Define the state and hooks for fetching school names
    const [schoolNames, setSchoolNames] = useState<School[]>([]);
    const [fetchSchools, { data, loading, error }] = useLazyQuery<{ schools: School[] }, { search: string }>(
        SCHOOL_QUERY
    );

    // Fetch school names when the prefix changes
    useEffect(() => {
        if (partial.length >= minInputLengthForSearch) {
            fetchSchools({
                variables: { search: partial }
            });
        } else {
            setSchoolNames([]);
        }
    }, [fetchSchools, partial]);

    // Update the state when new data is fetched
    useEffect(() => {
        if (data && data.schools) {
            setSchoolNames(data.schools);
        }
    }, [data]);

    return {
        error,
        loading,
        schoolNames
    };
};
