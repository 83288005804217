const getAppVersion = () => {
    const url = `${window.location.protocol}//${window.location.host}/VERSION`;
    return fetch(url).then((response) => {
        const ok = 200;
        if (response.status === ok) {
            return response.text();
        } else {
            throw new Error('unable to fetch version');
        }
    });
};

export function monitorAppVersion(): Promise<void> {
    const intervalMs = 300000;
    let appVersion: string;
    return new Promise((resolve) => {
        const checker = setInterval(() => {
            getAppVersion()
                .then((version) => {
                    if (!appVersion) {
                        appVersion = version;
                    }
                    if (appVersion !== version) {
                        if (document.hidden) {
                            // reload background tabs automatically
                            window.location.reload();
                        }
                        resolve();
                        clearInterval(checker);
                    }
                })
                .catch(); // ignore app version fetch errors
        }, intervalMs);
    });
}
