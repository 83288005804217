import React from 'react';
import DocumentTitle from 'react-document-title';
import { MetricsDashboard } from '../components/metrics/metrics-dashboard';

const reloadTimeoutMs = 7200000; // reload every 2 hours

export const MetricsDashboardPage: React.FC = () => {
    React.useEffect(() => {
        setTimeout(() => window.location.reload(), reloadTimeoutMs);
    }, []);
    return (
        <DocumentTitle title="Rocket Metrics">
            <MetricsDashboard />
        </DocumentTitle>
    );
};
