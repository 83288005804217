import { Delete } from '@material-ui/icons';
import { Avatar, CircularProgress, IconButton, IconMenu, MenuItem } from 'material-ui';
import { red600, white } from 'material-ui/styles/colors';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import * as React from 'react';
import { connect } from 'react-redux';

import { Permissions } from 'shared/models/permission';
import { hasRole } from 'shared/models/user';

import { deleteProfileLink } from '../actions';
import { RequestErrors, State } from '../state';

const avatarSize = 18;

interface OwnProps {
    url: string;
    personId: string;
}

interface ConnectedProps {
    pendingRequests: Map<string, RequestErrors>;
    userPermissions: Permissions;
}

interface ConnectedDispatch {
    deleteProfileLink: (personId: string, url: string) => void;
}

type ProfileLinkAttributesProps = OwnProps & ConnectedProps & ConnectedDispatch;

export const ProfileLinkAttributesComponent: React.FC<ProfileLinkAttributesProps> = (props) => {
    const { url, pendingRequests, personId, userPermissions } = props;

    const updateRequestKey = `website-update-request-${url}`;
    const disabled = pendingRequests.has(updateRequestKey) && pendingRequests.get(updateRequestKey).isEmpty();

    const handleLinkDelete = () => {
        if (!disabled) {
            props.deleteProfileLink(personId, url);
        }
    };

    if (hasRole(userPermissions, 'profile_links_editor')) {
        let content: JSX.Element;
        if (disabled) {
            const spinnerSize = 20;
            const spinnerThickness = 2;
            content = <CircularProgress size={spinnerSize} thickness={spinnerThickness} />;
        } else {
            const removeIcon = (
                <Avatar
                    size={avatarSize}
                    backgroundColor={red600}
                    color={white}
                    className="avatar-contact-attribute"
                    icon={<Delete />}
                />
            );

            const button = (
                <IconButton
                    disableTouchRipple={true}
                    style={{ width: 16, height: 16, padding: 0 }}
                    iconStyle={{ height: 16, width: 16 }}
                >
                    <MoreVertIcon className="icon-button-more" />
                </IconButton>
            );

            content = (
                <div className="contact-attributes">
                    <div className="list-item-menu-button">
                        <IconMenu
                            iconButtonElement={button}
                            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                            targetOrigin={{ horizontal: 'right', vertical: 'top' }}
                            desktop={true}
                        >
                            <MenuItem
                                primaryText="Delete"
                                insetChildren={true}
                                onClick={handleLinkDelete}
                                leftAvatar={removeIcon}
                                disabled={!hasRole(userPermissions, 'profile_links_editor')}
                            />
                        </IconMenu>
                    </div>
                </div>
            );
        }

        return content;
    } else {
        return null;
    }
};

const mapStateToProps = (state: State): any => ({
    pendingRequests: state.pendingRequests,
    userPermissions: state.session.userPermissions
});

const mapStateToDispatch = {
    deleteProfileLink
};

export const ProfileLinkAttributes = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapStateToDispatch
)(ProfileLinkAttributesComponent);
