import { useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import { Button, Chip, TextField, Theme, useTheme } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Autocomplete, AutocompleteInputChangeReason, RenderInputParams } from '@material-ui/lab';
import React from 'react';
import { getTagType, presetPersonTags } from '../common/preset-person-tags';

import { ADD_TAG, GET_TAGS, PersonTag, REMOVE_TAG } from '../graphql/queries/tags';
import { useSession } from '../hooks/use-session';

const contentStyle = (theme: Theme) => css`
    .form {
        margin-top: 5px;
    }

    .form-actions {
        text-align: right;
        margin-top: 5px;
    }

    .person-tags {
        padding: 15px;
        margin-bottom: 20px;
        background: white;
        border: thin solid ${theme.palette.divider};
        border-radius: 2px;
    }

    .chips {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }

    .tag-tabs {
        display: flex;
        font-size: 12px;
        text-transform: uppercase;
        justify-content: end;
        margin-bottom: 15px;

        .tag-tab {
            font-weight: 500;
            color: ${theme.palette.text.secondary};
            border-right: thin solid ${theme.palette.divider};
            padding: 0px 10px;
            cursor: pointer;

            &:last-child {
                border-right: none;
                padding-right: 0;
            }

            &.active {
                color: ${theme.palette.text.primary};
                font-weight: 600;
            }
        }
    }
`;

export const PersonTags: React.FC<{ personId: string }> = (props) => {
    const theme = useTheme();
    const { personId } = props;
    const { user } = useSession();
    const userId = user.id;

    const taggableType = 'persons';

    const [tags, setTags] = React.useState<PersonTag[]>([]);
    const [newTagText, setNewTagText] = React.useState<string>('First Val');
    const [errorText, setErrorText] = React.useState<string>('');
    const [active, setActive] = React.useState<boolean>(false);
    const [showAll, setShowAll] = React.useState<boolean>(false);
    const [value, setValue] = React.useState<string[]>([]);
    const [displayTags, setDisplayTags] = React.useState<PersonTag[]>([]);

    const { data: initialData } = useQuery<{ taggings: PersonTag[] }, { taggableId: string; taggableType: string }>(
        GET_TAGS,
        { variables: { taggableId: personId, taggableType } }
    );

    const [deleteTag, { loading: deletingTag }] = useMutation<{}, { deletedTagId: string; createdBy: string }>(
        REMOVE_TAG
    );

    const [addTag, { loading: addingTag }] = useMutation<
        {
            insert_taggings: {
                returning: Array<{
                    id: string;
                    tagId: string;
                    createdBy: string;
                    tag: {
                        value: string;
                    };
                }>;
            };
        },
        { taggableId: string; taggableType: string; value: string }
    >(ADD_TAG);

    React.useEffect(() => {
        if (initialData) {
            setTags(initialData.taggings);
        }
    }, [initialData]);

    React.useEffect(() => {
        if (showAll) {
            const updatedTags = tags.filter((f) => f.createdBy === userId);
            const tagValues = updatedTags.map((c) => c.tag.value);
            for (const tag of tags) {
                if (tagValues.indexOf(tag.tag.value) === -1) {
                    updatedTags.push(tag);
                }
            }
            setDisplayTags(updatedTags);
        } else {
            setDisplayTags(tags.filter((f) => f.createdBy === userId));
        }
    }, [showAll, tags]);

    React.useEffect(() => {
        if (displayTags) {
            setValue(displayTags.map((t) => t.tag.value));
        }
    }, [displayTags]);

    const tagExistsCheck = (newTag: string): boolean => {
        if (newTag.trim().length > 0) {
            if (tags.map((c) => c.tag.value.toLowerCase()).indexOf(newTag.trim().toLowerCase()) === -1) {
                return true;
            } else {
                const tag = tags.filter((f) => f.tag.value.toLowerCase() === newTag.trim().toLowerCase());
                if (tag.length === 1 && tag[0].createdBy !== userId) {
                    return true;
                }
            }
        }
        return false;
    };

    const handleInputChange = (_1: React.ChangeEvent<{}>, val: string, reason: AutocompleteInputChangeReason) => {
        if (reason !== 'reset') {
            setNewTagText(val);
            setErrorText('');
        }
    };

    const createTagging = async (tag: string) => {
        if (tag.trim().length > 0) {
            if (tagExistsCheck(tag)) {
                const result = await addTag({
                    variables: {
                        taggableId: props.personId,
                        taggableType,
                        value: tag.trim().toLowerCase()
                    }
                });
                const updatedTags = [...tags, result.data.insert_taggings.returning[0]];
                setTags(updatedTags);
                setNewTagText('');
                setErrorText('');
            } else {
                setErrorText('Tag exists');
            }
        }
    };

    const handleSubmit = () => {
        createTagging(newTagText);
    };

    const handleDelete = (tagToDelete: PersonTag) => async () => {
        if (tagToDelete.createdBy === userId) {
            let updatedTags = [...tags];
            updatedTags = updatedTags.filter((f) => f.id !== tagToDelete.id);
            await deleteTag({ variables: { deletedTagId: tagToDelete.id, createdBy: userId } });
            setTags(updatedTags);
        }
    };

    const handleAddClick = () => {
        setActive(!active);
        setErrorText('');
        setNewTagText('');
    };

    const handleClose = () => {
        setActive(false);
    };

    const handleToggle = () => {
        setShowAll(!showAll);
    };

    const handleChange = (_1: React.ChangeEvent<{}>, newValues: string[]) => {
        const newValue = newValues.filter((a) => value.indexOf(a) === -1);
        if (newValue.length === 1) {
            createTagging(newValue[0]);
        }
        if (newTagText.length > 0 && !tagExistsCheck(newTagText) && newValue.length === 0) {
            setErrorText('Tag exists');
        }
    };

    const inputRef = (input: any) => input && input.focus();

    const tagChips = displayTags.map((c) => {
        return c.createdBy === userId ? (
            <Chip
                key={c.id}
                className="chip"
                disabled={addingTag || deletingTag}
                label={renderPersonTagOptions(c.tag.value)}
                size="medium"
                onDelete={handleDelete(c)}
            />
        ) : (
            <Chip
                className="chip"
                disabled={addingTag || deletingTag}
                key={c.id}
                label={renderPersonTagOptions(c.tag.value)}
                size="medium"
            />
        );
    });

    const addTagChip = active ? null : (
        <Chip
            key="addTag"
            className="chip"
            disabled={addingTag || deletingTag}
            icon={<Add />}
            label="ADD TAGS"
            size="medium"
            onClick={handleAddClick}
        />
    );

    const renderInput = (params: RenderInputParams) => (
        <TextField variant="standard" fullWidth={true} helperText={errorText} inputRef={inputRef} {...params} />
    );

    const renderTags = (): any => null;

    const form = active ? (
        <div className="form">
            <Autocomplete
                multiple={true}
                filterSelectedOptions={true}
                options={presetPersonTags}
                freeSolo={true}
                renderInput={renderInput}
                renderTags={renderTags}
                disabled={addingTag || deletingTag}
                onChange={handleChange}
                value={value}
                onInputChange={handleInputChange}
                inputValue={newTagText}
                renderOption={renderPersonTagOptions}
            />
        </div>
    ) : null;

    const formActions =
        newTagText.length === 0 && active ? (
            <div className="form-actions">
                <Button key="doneButton" onClick={handleClose} disabled={addingTag || deletingTag}>
                    Done
                </Button>
            </div>
        ) : active ? (
            <div className="form-actions">
                <Button key="cancelButton" onClick={handleClose} disabled={addingTag || deletingTag}>
                    Cancel
                </Button>
                <Button key="saveButton" onClick={handleSubmit} disabled={addingTag || deletingTag}>
                    Save
                </Button>
            </div>
        ) : null;

    const tagsTab = (
        <>
            <div key="allTags" className={`tag-tab ${showAll ? 'active' : ''}`} onClick={handleToggle}>
                All Tags
            </div>
            <div key="myTags" className={`tag-tab ${showAll ? '' : 'active'}`} onClick={handleToggle}>
                My Tags
            </div>
        </>
    );

    return (
        <div css={contentStyle(theme)}>
            <div className="person-tags">
                <div className="tag-tabs">{tagsTab}</div>
                <div className="chips">
                    {tagChips}
                    {addTagChip}
                </div>
                <div>
                    {form}
                    {formActions}
                </div>
            </div>
        </div>
    );
};

export const renderPersonTagOptions = (tag: string) => {
    switch (getTagType(tag)) {
        case 'wants':
        case 'not':
            const [prefix, ...value] = tag.trim().toLowerCase().split(' ');
            return (
                <span>
                    <b>
                        <i>{prefix}</i>
                    </b>{' '}
                    {value.join(' ')}
                </span>
            );
        case 'plain':
            return <span>{tag.trim().toLowerCase()}</span>;
    }
};
