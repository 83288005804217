import { TextField } from 'material-ui';
import * as React from 'react';
import { connect } from 'react-redux';

import { State } from '../../state';
import { searchInbox, setSearchString } from '../actions';
import { EmailInboxData } from '../types';

interface ConnectedProps {
    data: EmailInboxData;
}

interface ConnectedDispatch {
    searchInbox: () => void;
    setSearchString: (searchString: string) => void;
}

type EmailInboxSearchProps = ConnectedProps & ConnectedDispatch;

interface EmailInboxSearchState {
    focused: boolean;
}

class EmailInboxSearchComponent extends React.Component<EmailInboxSearchProps, EmailInboxSearchState> {
    constructor(props: EmailInboxSearchProps) {
        super(props);
        this.state = { focused: false };
    }

    componentDidUpdate(prevProps: EmailInboxSearchProps) {
        if (!this.props.data.searchString && !!prevProps.data.searchString) {
            this.setState({ focused: false });
        }
    }

    handleValueChange = (_1: any, searchString: string) => {
        this.props.setSearchString(searchString);
    };

    handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            this.props.searchInbox();
        }
    };

    handleFocus = (focused: boolean) => () => {
        this.setState({ focused });
    };

    render() {
        const { data } = this.props;
        const searchString = data.searchString || '';
        const { focused } = this.state;
        const disabled = data.fetching;
        const underlineDisplay = !focused && !searchString ? 'none' : 'inherit';
        return (
            <div className="email-inbox-search">
                <TextField
                    onFocus={this.handleFocus(true)}
                    onBlur={this.handleFocus(false)}
                    underlineStyle={{ display: underlineDisplay }}
                    underlineFocusStyle={{ display: 'inherit' }}
                    value={searchString}
                    onChange={this.handleValueChange}
                    onKeyPress={this.handleKeyPress}
                    id="email-inbox-search-textfield"
                    disabled={disabled}
                    style={!searchString && !focused ? {} : { marginLeft: 32 }}
                />
                <div className={`email-inbox-search-icon ${disabled ? 'disabled' : ''}`}>
                    <i className="material-icons">search</i>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    data: state.emailInbox
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    searchInbox,
    setSearchString
};

export const EmailInboxSearch = connect<ConnectedProps, ConnectedDispatch, {}>(
    mapStateToProps,
    mapDispatchToProps
)(EmailInboxSearchComponent);
