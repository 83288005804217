import { css } from '@emotion/core';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { capitalize } from 'lodash';
import { standardizeUrl } from 'profile-parser';
import React from 'react';
import { v4 } from 'uuid';
import { isEmail } from 'validator';

import { HiringManagerData } from 'shared/models/client';

import { formatNumber, isValidPhoneNumber } from '../common/phone';
import { SelectField, TextField } from '../core-ui/form-fields';
import { useModal } from '../hooks/use-modal';
import { useFormContextData } from './form-context';

interface HiringManagerFormProps {
    formTitle: string;
    hiringManager: HiringManagerData;
    onSave: (hiringManager: HiringManagerData) => void;
    onCancel: () => void;
    disabled: boolean;
}

const styles = css`
    .form-field-row {
        display: flex;
        margin-bottom: 32px;
    }
`;

const defaultHMData: Omit<HiringManagerData, 'id'> = {
    email: '',
    gender: undefined,
    linkedIn: '',
    name: {
        first: '',
        full: '',
        last: ''
    },
    phoneNumber: '',
    role: ''
};

export const HiringManagerForm: React.FC<HiringManagerFormProps> = ({
    formTitle,
    hiringManager,
    onSave,
    onCancel,
    disabled
}) => {
    const { setAlert } = useModal();
    const { data, onFieldChange, getError, setError, validateFieldExists, hasErrors, isDirty } =
        useFormContextData<HiringManagerData>(hiringManager ?? { ...defaultHMData, id: v4() });

    const validateRequiredFields = () => {
        let valid = true;
        valid = validateNameExists('first')(data.name.first) && valid;
        valid = validateFieldExists('gender')(data.gender) && valid;
        valid = validateEmail() && valid;
        return valid;
    };

    const handleSave = () => {
        if (!validateRequiredFields() || hasErrors) {
            setAlert('Errors exist', 'Please fix the errors before saving');
        } else {
            onSave(data);
        }
    };

    const handleNameChange = (field: 'first' | 'last') => (value: string) => {
        const updated = { ...data.name, [field]: value };
        updated.full = `${updated.first} ${updated.last}`;
        onFieldChange('name')(updated);
    };

    const validateNameExists = (field: 'first' | 'last') => (value: string) => {
        if (!value?.trim()) {
            setError(`name.${field}`, 'Required');
            return false;
        } else {
            setError(`name.${field}`, undefined);
            return true;
        }
    };

    const validateLinkedinUrl = (url: string) => {
        if (url && !url.match(/^(https?:\/\/)?(www\.)?linkedin\.com\/in\/.+/)) {
            setError('linkedIn', 'Please enter a valid LinkedIn URL');
        } else {
            setError('linkedIn', undefined);
        }
    };

    const validateEmail = () => {
        if (!isEmail(data.email)) {
            setError('email', 'Please enter a valid email address');
            return false;
        } else {
            setError('email', undefined);
            return true;
        }
    };

    const formatLinkedinUrl = () => {
        if (data.linkedIn && !getError('linkedIn')) {
            const standardized = standardizeUrl(data.linkedIn);
            onFieldChange('linkedIn')(standardized);
        }
    };

    const formatPhoneNumber = () => {
        if (data.phoneNumber) {
            if (isValidPhoneNumber(data.phoneNumber)) {
                const formatted = formatNumber(data.phoneNumber);
                onFieldChange('phoneNumber')(formatted);
                setError('phoneNumber', undefined);
            } else {
                setError('phoneNumber', 'Invalid phone number');
            }
        } else {
            setError('phoneNumber', undefined);
        }
    };

    const buttons = disabled ? (
        <Button onClick={onCancel}>Close</Button>
    ) : (
        <>
            <Button onClick={onCancel}>Cancel</Button>
            <Button onClick={handleSave} disabled={!isDirty}>
                Save
            </Button>
        </>
    );

    const genderOptions = ['female', 'male', 'other'].map((v) => ({ label: capitalize(v), value: v }));

    return (
        <Dialog open={true} fullWidth={true} maxWidth="sm" css={styles}>
            <DialogTitle>
                <Typography variant="h4" component="div">
                    {formTitle}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <div className="form-field-row">
                    <TextField
                        value={data.name.first}
                        label="First Name"
                        onChange={handleNameChange('first')}
                        disabled={disabled}
                        validate={validateNameExists('first')}
                        errorText={getError('name.first')}
                    />
                </div>
                <div className="form-field-row">
                    <TextField
                        value={data.name.last}
                        label="Last Name"
                        onChange={handleNameChange('last')}
                        disabled={disabled}
                    />
                </div>
                <div className="form-field-row">
                    <SelectField
                        value={data.gender}
                        label="Gender"
                        options={genderOptions}
                        onChange={onFieldChange('gender')}
                        disabled={disabled}
                        validate={validateFieldExists('gender')}
                        errorText={getError('gender')}
                    />
                </div>
                <div className="form-field-row">
                    <TextField
                        value={data.linkedIn}
                        label="LinkedIn"
                        onChange={onFieldChange('linkedIn')}
                        disabled={disabled}
                        validate={validateLinkedinUrl}
                        errorText={getError('linkedIn')}
                        onBlur={formatLinkedinUrl}
                    />
                </div>
                <div className="form-field-row">
                    <TextField
                        value={data.email}
                        label="Email"
                        onChange={onFieldChange('email')}
                        disabled={disabled}
                        onBlur={validateEmail}
                        errorText={getError('email')}
                    />
                </div>
                <div className="form-field-row">
                    <TextField
                        value={data.phoneNumber}
                        label="Phone"
                        onChange={onFieldChange('phoneNumber')}
                        disabled={disabled}
                        onBlur={formatPhoneNumber}
                        errorText={getError('phoneNumber')}
                    />
                </div>
                <div className="form-field-row">
                    <TextField value={data.role} label="Role" onChange={onFieldChange('role')} disabled={disabled} />
                </div>
            </DialogContent>
            <DialogActions>{buttons}</DialogActions>
        </Dialog>
    );
};
