import { RaisedButton } from 'material-ui';
import * as React from 'react';
import { Link } from 'react-router-dom';

const reports = [
    ['candidates-inflow', 'Candidates Inflow'],
    ['emails-out', 'Emails Out'],
    ['job-funnel-by-month', 'Job Funnel (Month)']
];

export const Analytics = () => {
    const links = reports.map((r) => (
        <Link to={`/analytics/${r[0]}`} className="analytics-list-item" key={r[0]}>
            <RaisedButton label={r[1]} />
        </Link>
    ));
    return <div className="analytics-list">{links}</div>;
};
