import * as React from 'react';
import DocumentTitle from 'react-document-title';

import { BillingPageHeader } from '../components/billing-page-header';
import { Fees } from '../components/fees';

export const FeesPage: React.FC = () => (
    <DocumentTitle title="Fees">
        <div id="container">
            <BillingPageHeader />
            <div id="content" className="flex-fill">
                <Fees />
            </div>
        </div>
    </DocumentTitle>
);
