import { useQuery } from '@apollo/client';
import moment from 'moment-timezone';
import React from 'react';

import { CALLS_BY_PERIOD, Interview } from '../graphql/queries/home';
import { User } from '../graphql/queries/user';
import { Interviews } from './home/interviews';

const rowsPerPage = 15;

export const UserCalls: React.FC<{ user: User; ts: number }> = ({ user, ts }) => {
    const startTime = moment(ts).startOf('day').valueOf();
    const endTime = moment(ts).endOf('day').valueOf();
    const { data } = useQuery<{ interviews: Interview[] }, { userId: string; startTime: number; endTime: number }>(
        CALLS_BY_PERIOD,
        { variables: { userId: user.id, startTime, endTime } }
    );
    const title = `${user.name.first}'s calls for ${moment(ts).format('dddd')}`;
    return (
        <Interviews
            interviews={data?.interviews}
            title={title}
            rowsPerPage={rowsPerPage}
            showIfEmpty={true}
            disableCollapse={true}
        />
    );
};
