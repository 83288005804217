import { useQuery } from '@apollo/client';
import React from 'react';

import { CBIndustries, CRUNCHBASE_INDUSTRIES } from '../../graphql/queries/crunchbase';
import { MultiSelectForm } from './multi-select-form';

export const IndustriesForm: React.FC<{
    readonly: boolean;
    filters: string[];
    onChange: (val: string[]) => void;
}> = ({ readonly, filters, onChange }) => {
    const { data: optionsData } = useQuery<{ industries: CBIndustries[] }>(CRUNCHBASE_INDUSTRIES);

    const options = optionsData?.industries.map((i) => i.name) ?? [];

    return (
        <MultiSelectForm
            readonly={readonly}
            filters={filters}
            label="Industries"
            options={options}
            onChange={onChange}
        />
    );
};
