import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { JobMeetings } from '../components/job-meetings';

interface RouterProps {
    id: string;
}

const JobMeetingsPageComponent: React.FC<RouteComponentProps<RouterProps>> = ({ match }) => (
    <JobMeetings id={match.params.id} />
);

export const JobMeetingsPage = withRouter(JobMeetingsPageComponent);
