import { Map } from 'immutable';
import { FlatButton, IconButton, Menu, MenuItem, Popover } from 'material-ui';
import * as React from 'react';
import { connect } from 'react-redux';

import { EmailAddress } from 'shared/types/email-compose';

import { buttonBorderColor } from '../../common/css-variables';
import { fullDateTime } from '../../common/timestamp';
import { forwardEmail, replyToEmail } from '../../email-compose/actions';
import { ComposeEmailWindowData } from '../../email-compose/types';
import { insertEmailMarkup } from '../../lib/email-markup';
import { Communication, State } from '../../state';
import { deleteMessage, downloadAttachment, markMessagesUnread, viewThreadDetails } from '../actions';

interface OwnProps {
    account: string;
    message: Communication;
    startCollapsed: boolean;
    lastMessageInThread: boolean;
    startScrolledTo: boolean;
}

interface EmailInboxMessageState {
    collapsed: boolean;
    headerDetailsOpen: boolean;
    headerDetailsAnchor: HTMLElement;
    moreActionsOpen: boolean;
    moreActionsAnchor: HTMLElement;
}

interface ConnectedProps {
    accounts: Map<string, EmailAddress>;
    emailContentBlacklistedDomains: string;
    downloadingAttachment: boolean;
}

interface ConnectedDispatch {
    forwardEmail: (message: Communication, emailContentBlacklistedDomains: string) => void;
    replyToEmail: (
        accountOptions: EmailAddress[],
        emailContentBlacklistedDomains: string,
        message: Communication,
        replyAll: boolean,
        archiveOnReply: boolean,
        payload?: Partial<ComposeEmailWindowData>
    ) => void;
    deleteMessage: (messageId: string, threadId: string) => void;
    markMessagesUnread: (messageId: string, threadId: string) => void;
    downloadAttachment: (messageId: string, attachmentId: string, filename: string) => void;
    viewThreadDetails: (threadId: string) => void;
}

type EmailInboxMessageProps = OwnProps & ConnectedProps & ConnectedDispatch;

class EmailInboxMessageComponent extends React.Component<EmailInboxMessageProps, EmailInboxMessageState> {
    private emailBodyRef: HTMLElement;
    private messageRef: HTMLElement;

    constructor(props: EmailInboxMessageProps) {
        super(props);
        this.state = {
            collapsed: props.startCollapsed,
            headerDetailsAnchor: null,
            headerDetailsOpen: false,
            moreActionsAnchor: null,
            moreActionsOpen: false
        };
    }

    componentDidMount() {
        const forwardedMessage = !!this.props.message.subject && !!this.props.message.subject.match(/^fwd/i);
        insertEmailMarkup(
            this.props.message.body,
            this.emailBodyRef,
            forwardedMessage,
            this.props.emailContentBlacklistedDomains,
            this.props.message.attachments
                .filter((a) => !!a.contentId)
                .map((a) => ({ path: a.key, contentId: a.contentId }))
        );
        if (this.props.startScrolledTo) {
            this.messageRef.scrollIntoView({ block: 'start' });
        }
    }

    setMessageRef = (msgDiv: any) => {
        this.messageRef = msgDiv;
    };

    setEmailBodyRef = (body: any) => {
        this.emailBodyRef = body;
    };

    handleShowHeaders = (event: React.SyntheticEvent<{}>) => {
        event.stopPropagation();
        this.setState({ headerDetailsOpen: true, headerDetailsAnchor: event.currentTarget as any });
    };

    handleHideHeaders = () => {
        this.setState({ headerDetailsOpen: false });
    };

    handleShowMoreActions = (event: React.SyntheticEvent<{}>) => {
        event.stopPropagation();
        this.setState({ moreActionsOpen: true, moreActionsAnchor: event.currentTarget as any });
    };

    handleHideMoreActions = () => {
        this.setState({ moreActionsOpen: false });
    };

    handleToggleCollapse = () => {
        if (!this.props.lastMessageInThread) {
            this.setState({ collapsed: !this.state.collapsed });
        }
    };

    handleReply = (replyAll: boolean) => (event: React.SyntheticEvent<{}>) => {
        event.stopPropagation();
        this.setState({ moreActionsOpen: false });
        const message = Object.assign({}, this.props.message, { account: this.props.account });
        this.props.replyToEmail(
            [{ address: this.props.account, name: message.accountInfo?.name.full }],
            this.props.emailContentBlacklistedDomains,
            message,
            replyAll,
            true,
            {
                canEditToRecipients: true
            }
        );
    };

    handleForward = (event: React.SyntheticEvent<{}>) => {
        event.stopPropagation();
        this.setState({ moreActionsOpen: false });
        const message = Object.assign({}, this.props.message, { account: this.props.account });
        this.props.forwardEmail(message, this.props.emailContentBlacklistedDomains);
    };

    handleDelete = (event: React.SyntheticEvent<{}>) => {
        event.stopPropagation();
        this.setState({ moreActionsOpen: false });
        this.props.deleteMessage(this.props.message.messageId, this.props.message.threadId);
        this.props.viewThreadDetails(null);
    };

    handleMarkUnread = (event: React.SyntheticEvent<{}>) => {
        event.stopPropagation();
        this.setState({ moreActionsOpen: false });
        this.props.markMessagesUnread(this.props.message.messageId, this.props.message.threadId);
    };

    handleDownloadAttachment = (attachmentId: string, filename: string) => () => {
        this.props.downloadAttachment(this.props.message.messageId, attachmentId, filename);
    };

    render() {
        const { account, message, lastMessageInThread, downloadingAttachment } = this.props;
        const { collapsed } = this.state;
        const recipients = message.headers.to
            .concat(message.headers.cc, message.headers.bcc)
            .map((r) => (r.address === account ? 'me' : r.name ? r.name.split(/\s/)[0] : r.address.split('@')[0]))
            .join(', ');
        const sender = (
            <div className="email-inbox-message-sender">
                <span className="sender-name">{message.headers.from.name} </span>
                <span className="sender-address">
                    &lt;
                    {message.headers.from.address}
                    &gt;
                </span>
            </div>
        );
        const recipientHeaders = ['to', 'cc', 'bcc'].map((header) => {
            const values: Array<{ address: string; name: string }> = (message.headers as any)[header];
            if (values.length === 0) return null;
            const headerVals = values.map((address, i) =>
                address.name ? (
                    <div key={i}>
                        {address.name} &lt;
                        {address.address}
                        &gt;
                    </div>
                ) : (
                    <div key={i}>{address.address}</div>
                )
            );
            return (
                <div className="email-message-header" key={header}>
                    <div className="email-message-header-name">{header}:</div>
                    <div className="email-message-header-value">{headerVals}</div>
                </div>
            );
        });
        const moreActions: JSX.Element[] = [
            <MenuItem
                primaryText="Reply"
                key="reply"
                leftIcon={<i className="material-icons">reply</i>}
                onClick={this.handleReply(false)}
            />
        ];
        const participants = message.headers.to
            .concat(message.headers.cc, message.headers.bcc, [message.headers.from])
            .filter((p) => p.address !== account);
        if (participants.length > 1) {
            moreActions.push(
                <MenuItem
                    primaryText="Reply To All"
                    key="reply_all"
                    leftIcon={<i className="material-icons">reply_all</i>}
                    onClick={this.handleReply(true)}
                />
            );
        }
        moreActions.push(
            <MenuItem
                primaryText="Forward"
                key="forward"
                leftIcon={<i className="material-icons">forward</i>}
                onClick={this.handleForward}
            />,
            <MenuItem
                primaryText="Delete this message"
                key="delete"
                insetChildren={true}
                onClick={this.handleDelete}
            />,
            <MenuItem
                primaryText="Mark unread from here"
                key="mark_unread"
                insetChildren={true}
                onClick={this.handleMarkUnread}
            />
        );
        const headerSectionClass = `email-inbox-message-header ${lastMessageInThread ? '' : 'can-collapse'}`;
        const headerSection = collapsed ? (
            <div className={headerSectionClass} onClick={this.handleToggleCollapse}>
                <div className="email-inbox-message-participants">{sender}</div>
                <div className="email-inbox-message-date-actions">
                    <div className="email-date">{fullDateTime(message.internalDate)}</div>
                </div>
            </div>
        ) : (
            <div className={headerSectionClass} onClick={this.handleToggleCollapse}>
                <div className="email-inbox-message-participants">
                    {sender}
                    <div className="email-inbox-message-recipients">
                        <div className="email-inbox-message-recipients-list">
                            to <span className="recipient">{recipients}</span>
                        </div>
                        <div className="email-inbox-message-headers-popup-icon">
                            <IconButton
                                className="email-message-action-button"
                                onClick={this.handleShowHeaders}
                                style={{ width: 24, height: 24, padding: 0, marginLeft: 4, marginTop: 4 }}
                            >
                                <i className="material-icons">arrow_drop_down</i>
                            </IconButton>
                            <Popover
                                open={this.state.headerDetailsOpen}
                                anchorEl={this.state.headerDetailsAnchor}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                targetOrigin={{ horizontal: 'left', vertical: 'top' }}
                                onRequestClose={this.handleHideHeaders}
                                style={{ maxWidth: 600, overflowY: 'auto' }}
                            >
                                <div className="email-message-headers-popup">
                                    <div className="email-message-header">
                                        <div className="email-message-header-name">from:</div>
                                        <div className="email-message-header-value">{sender}</div>
                                    </div>
                                    {recipientHeaders}
                                    <div className="email-message-header">
                                        <div className="email-message-header-name">date:</div>
                                        <div className="email-message-header-value">
                                            {fullDateTime(message.internalDate)}
                                        </div>
                                    </div>
                                    <div className="email-message-header">
                                        <div className="email-message-header-name">subject:</div>
                                        <div className="email-message-header-value">{message.subject}</div>
                                    </div>
                                </div>
                            </Popover>
                        </div>
                    </div>
                </div>
                <div className="email-inbox-message-date-actions">
                    <div className="email-date">{fullDateTime(message.internalDate)}</div>
                    <div className="email-actions">
                        <IconButton
                            className="email-message-action-button"
                            onClick={this.handleReply(false)}
                            disableTouchRipple={true}
                        >
                            <i className="material-icons">reply</i>
                        </IconButton>
                        <IconButton
                            className="email-message-action-button"
                            onClick={this.handleShowMoreActions}
                            disableTouchRipple={true}
                        >
                            <i className="material-icons">more_vert</i>
                        </IconButton>
                        <Popover
                            open={this.state.moreActionsOpen}
                            anchorEl={this.state.moreActionsAnchor}
                            anchorOrigin={{ horizontal: 'middle', vertical: 'center' }}
                            targetOrigin={{ horizontal: 'right', vertical: 'top' }}
                            onRequestClose={this.handleHideMoreActions}
                        >
                            <Menu desktop={true}>{moreActions}</Menu>
                        </Popover>
                    </div>
                </div>
            </div>
        );
        let bottomButtons: JSX.Element;
        if (lastMessageInThread) {
            const buttons = [];
            buttons.push(
                <FlatButton
                    label="Reply"
                    key="reply"
                    icon={<i className="material-icons">reply</i>}
                    className="email-inbox-message-button"
                    style={{ border: `thin solid ${buttonBorderColor}` }}
                    labelStyle={{ textTransform: 'capitalize' }}
                    onClick={this.handleReply(false)}
                />
            );
            if (participants.length > 1) {
                buttons.push(
                    <FlatButton
                        label="Reply All"
                        key="reply all"
                        icon={<i className="material-icons">reply_all</i>}
                        className="email-inbox-message-button"
                        style={{ border: 'thin solid ${buttonBorderColor}' }}
                        labelStyle={{ textTransform: 'capitalize' }}
                        onClick={this.handleReply(true)}
                    />
                );
            }
            buttons.push(
                <FlatButton
                    label="Forward"
                    key="forward"
                    icon={<i className="material-icons">forward</i>}
                    className="email-inbox-message-button"
                    style={{ border: 'thin solid ${buttonBorderColor}' }}
                    labelStyle={{ textTransform: 'capitalize' }}
                    onClick={this.handleForward}
                />
            );
            bottomButtons = <div className="email-inbox-message-reply-buttons">{buttons}</div>;
        }

        const attachments = collapsed
            ? null
            : message.attachments.map((a) => (
                  <div
                      className="email-attachment"
                      key={a.id}
                      onClick={this.handleDownloadAttachment(a.id, a.filename)}
                  >
                      <div className="list-centered-icon">
                          <i className="fas fa-file-alt attachment-icon" />
                          <span>{a.filename}</span>
                      </div>
                  </div>
              ));

        return (
            <div className={`email-inbox-thread-message ${collapsed ? 'collapsed' : ''}`} ref={this.setMessageRef}>
                {headerSection}
                <div className="email-inbox-message-body" ref={this.setEmailBodyRef} />
                <div
                    className="email-inbox-message-snippet"
                    dangerouslySetInnerHTML={{ __html: message.snippet }}
                    onClick={this.handleToggleCollapse}
                />
                <div className={`email-attachments ${downloadingAttachment ? 'downloading' : ''}`}>{attachments}</div>
                {bottomButtons}
            </div>
        );
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    accounts: state.emailInboxAccounts,
    downloadingAttachment: state.emailInbox.downloadingAttachment,
    emailContentBlacklistedDomains: state.appConstants.constants.emailContentBlacklistedDomains
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    deleteMessage,
    downloadAttachment,
    forwardEmail,
    markMessagesUnread,
    replyToEmail,
    viewThreadDetails
};

export const EmailInboxMessage = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(EmailInboxMessageComponent);
