import { OrderedMap } from 'immutable';

import {
    Action,
    ReceiveAddContactFromMessage,
    ReceiveCommunicationMatchDataUpdate,
    ReceiveCommunicationMatcher,
    ReceiveFlaggedEmails,
    ReceiveForwardFlaggedEmail,
    ReceiveIgnoreContactFromMessage,
    RequestAddContactFromMessage,
    RequestFlaggedEmails,
    RequestIgnoreContactFromMessage
} from '../actions';
import { Communication, FlaggedEmails } from '../state';

const initialState: OrderedMap<string, FlaggedEmails> = OrderedMap();

export function flaggedEmails(state = initialState, action: Action): OrderedMap<string, FlaggedEmails> {
    switch (action.type) {
        case ReceiveFlaggedEmails: {
            let threads = OrderedMap<string, Communication[]>();
            for (const threadId of Object.keys(action.payload.data.threads)) {
                const communications = action.payload.data.threads[threadId];
                threads = threads.set(threadId, communications);
            }
            return state.set(action.payload.account, { total: action.payload.data.total, threads });
        }
        case RequestFlaggedEmails: {
            const account = action.payload.account;
            return state.set(account, null);
        }

        case RequestIgnoreContactFromMessage:
        case RequestAddContactFromMessage: {
            const { account, messageId, threadId } = action.payload;
            const accountFlaggedEmails = state.get(account);
            if (accountFlaggedEmails) {
                const accountThreads = accountFlaggedEmails.threads;
                const threadEmails = accountThreads.get(threadId);
                let message = threadEmails.find((e) => e.messageId === messageId);
                message = { ...message, unknownEmailFound: null };

                const newThreadEmails = threadEmails
                    .filter((e) => e.messageId !== messageId)
                    .concat([message])
                    .sort((c1, c2) => c2.internalDate - c1.internalDate);
                const newAccountThreads = accountThreads.set(threadId, newThreadEmails);
                return state.set(account, {
                    threads: newAccountThreads,
                    total: accountFlaggedEmails.total
                });
            } else {
                return state;
            }
        }
        case ReceiveAddContactFromMessage: {
            const { communications, success } = action.payload;
            if (!success) {
                return state;
            }

            const { account, threadId, messageId } = communications[0];
            const accountFlaggedEmails = state.get(account);
            if (accountFlaggedEmails) {
                const accountThreads = accountFlaggedEmails.threads;
                const threadEmails = accountThreads.get(threadId);

                const newThreadEmails = threadEmails
                    .filter((e) => e.messageId !== messageId)
                    .concat(communications)
                    .sort((c1, c2) => c2.internalDate - c1.internalDate);
                const newAccountThreads = accountThreads.set(threadId, newThreadEmails);
                return state.set(account, {
                    threads: newAccountThreads,
                    total: accountFlaggedEmails.total
                });
            } else {
                return state;
            }
        }
        case ReceiveIgnoreContactFromMessage:
        case ReceiveForwardFlaggedEmail:
        case ReceiveCommunicationMatchDataUpdate:
        case ReceiveCommunicationMatcher: {
            const { account, threadId } = action.payload.communications[0];
            const accountFlaggedEmails = state.get(account);
            if (accountFlaggedEmails) {
                const accountThreads = accountFlaggedEmails.threads;
                const threadEmails = accountThreads.get(threadId);

                const newThreadEmails = threadEmails
                    .filter((e) => !action.payload.communications.find((c) => c.messageId === e.messageId))
                    .concat(action.payload.communications)
                    .sort((c1, c2) => c2.internalDate - c1.internalDate);
                const newAccountThreads = accountThreads.set(threadId, newThreadEmails);
                return state.set(account, {
                    threads: newAccountThreads,
                    total: accountFlaggedEmails.total
                });
            } else {
                return state;
            }
        }
        default:
            return state;
    }
}
