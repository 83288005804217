import { useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Theme,
    Typography,
    useTheme
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { SequenceType, SequenceVariables } from 'shared/models/sequence';

import { Spinner } from '../core-ui/spinner';
import { JOB_SEQUENCES, Sequence } from '../graphql/queries/outreach';
import { SequencePreview } from './sequence-preview';

const styles = (theme: Theme) => css`
    .MuiDialog-paper {
        min-width: 768px;

        .dialog-title {
            border-bottom: thin solid ${theme.palette.divider};
        }

        .dialog-actions {
            border-top: thin solid ${theme.palette.divider};
        }

        .dialog-content {
            padding: 20px 24px;

            .select-field {
                display: flex;
                margin-bottom: 25px;
            }
        }

        .MuiExpansionPanel-root {
            &:not(:last-child) {
                margin: 16px 0;
            }

            &:first-of-type {
                margin: 0;
            }
        }
    }
`;

interface SchedulingSendDialogProps {
    jobId: string;
    variables: SequenceVariables;
    onClose: () => void;
    initialCustomStages: Array<{ subject: string; body: string }>;
    onSend: (data: { sequenceId: string; customStages: Array<{ subject: string; body: string }> }) => void;
}

export const SchedulingSendDialog: React.FC<SchedulingSendDialogProps> = ({
    jobId,
    variables,
    onClose,
    onSend,
    initialCustomStages
}) => {
    const theme = useTheme();
    const { data: sequencesData } = useQuery<{ sequences: Sequence[] }, { jobId: string }>(JOB_SEQUENCES, {
        fetchPolicy: 'network-only',
        variables: { jobId }
    });

    const [sequenceId, setSequenceId] = useState('');
    const [customStages, setCustomStages] = useState<Array<{ subject: string; body: string }>>([
        ...initialCustomStages
    ]);

    const sequences = sequencesData?.sequences?.filter((s) => s.type === SequenceType.Phonescreen);

    useEffect(() => {
        if (!sequenceId && sequences) {
            setSequenceId(sequences[0]?.id ?? '');
        }
    }, [sequences]);

    const handleSelectSequence = (event: React.ChangeEvent<{ value: string }>) => {
        setSequenceId(event.target.value);
    };

    const handleSend = () => {
        onSend({ sequenceId, customStages });
    };

    const seqOpts = (sequences ?? []).map((seq) => (
        <MenuItem key={seq.id} value={seq.id}>
            {seq.title}
        </MenuItem>
    ));

    const sequenceSelector = sequences ? (
        <FormControl fullWidth={true}>
            <InputLabel id="scheduling-sequence-select-label">Select Sequence</InputLabel>
            <Select value={sequenceId} onChange={handleSelectSequence} labelId="scheduling-sequence-select-label">
                {seqOpts}
            </Select>
        </FormControl>
    ) : null;

    const body = !sequencesData ? (
        <Spinner />
    ) : (
        <>
            <DialogContent className="dialog-content">
                <div className="select-field">{sequenceSelector}</div>
                <SequencePreview
                    sequence={sequences?.find((s) => s.id === sequenceId)}
                    variables={variables}
                    custom={{ stages: customStages, onChange: setCustomStages }}
                />
            </DialogContent>
            <DialogActions className="dialog-actions">
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSend} disabled={!sequenceId}>
                    Send
                </Button>
            </DialogActions>
        </>
    );

    return (
        <Dialog onClose={onClose} open={true} css={styles(theme)}>
            <DialogTitle className="dialog-title">
                <Typography variant="h4" component="span">
                    Send Scheduling Sequence
                </Typography>
            </DialogTitle>
            {body}
        </Dialog>
    );
};
