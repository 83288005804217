import { gql } from '@apollo/client';

import { UserMentionKind } from 'shared/models/user-mention';

export interface UserMention {
    id: string;
    kind: UserMentionKind;
    createdAt: number;
    person: {
        id: string;
        name: string;
        profilePicUrl: string;
    };
    job: {
        id: string;
        title: string;
        client: {
            id: string;
            name: string;
        };
    };
    client: {
        id: string;
        name: string;
    };
    sender: {
        id: string;
        name: string;
    };
    recipient: {
        id: string;
        name: string;
    };
    note: {
        id: string;
        content: string;
    };
}

export interface UserMentionData {
    kind: UserMentionKind;
    personId?: string;
    jobId?: string;
    recipientId?: string;
}

export const USER_MENTIONS = gql`
    query Mentions($userId: String!) {
        mentions: user_mentions(
            where: {
                _or: [
                    { recipientId: { _eq: $userId } }
                    { senderId: { _eq: $userId }, showSent: { _eq: true } }
                    { recipientId: { _is_null: true } }
                ]
                completed: { _eq: false }
            }
            order_by: { createdAt: desc }
        ) {
            id
            kind
            createdAt
            person {
                id
                name(path: "full")
                profilePicUrl
            }
            job {
                id
                title
                client {
                    id
                    name
                }
            }
            client {
                id
                name
            }
            sender {
                id
                name(path: "full")
            }
            recipient {
                id
                name(path: "full")
            }
            note {
                id
                content
            }
        }
    }
`;

export interface NoteMention {
    id: string;
    note: {
        id: string;
    };
}

export const NOTE_MENTIONS = gql`
    query Mentions($userId: String!, $noteId: String!) {
        mentions: user_mentions(
            where: { recipientId: { _eq: $userId }, completed: { _eq: false }, note: { id: { _eq: $noteId } } }
        ) {
            id
            note {
                id
            }
        }
    }
`;

export const UPDATE_MENTION = gql`
    mutation UpdateMention($id: String!, $updates: user_mentions_set_input!) {
        update_user_mentions(where: { id: { _eq: $id } }, _set: $updates) {
            affected_rows
        }
    }
`;

export const CREATE_MENTION = gql`
    mutation InsertMention($object: user_mentions_insert_input!) {
        insert_user_mentions_one(object: $object) {
            id
        }
    }
`;
