import { css } from '@emotion/core';
import { Collapse, fade, IconButton, Paper, TablePagination, Theme, Typography, useTheme } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import React, { useState } from 'react';
import { getLocalStorageKey, setLocalStorageKey } from '../../common/local-storage';
import { useUserContext } from '../../hooks/use-user-context';

interface HomePagePanelProps<T extends { id?: string; _id?: string }, U extends {}, V extends {}> {
    RowComponent: React.ComponentType<{ data: T; list: T[] } & V>;
    ContentComponent: React.ComponentType<{ rows: JSX.Element[] } & U>;
    records: T[];
    title: string | JSX.Element;
    containerClass: string;
    collapseKeyId: string;
    showIfEmpty?: boolean;
    actions?: JSX.Element;
    disableCollapse?: boolean;
    contentProps?: U;
    rowProps?: V;
}

// tslint:disable:no-magic-numbers
const styles = (theme: Theme) => css`
    .section-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px;
        min-height: 40px;
        border-bottom: thin solid ${theme.palette.divider};

        &.collapsed {
            border-bottom: none;
        }

        .left {
            flex: 1 1 auto;
            line-height: 2.75;
        }

        .right {
            flex: 0 0 auto;
            display: flex;
            align-items: center;

            .toggle {
                width: 0;
                overflow: hidden;
                transition: width 0.2s ease;
            }

            .actions-container {
                margin-left: 15px;
            }

            .count {
                border-radius: 50%;
                width: 24px;
                height: 24px;
                padding: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &:hover {
            .right {
                .toggle {
                    width: 52px;
                }
            }
        }
    }

    .pagination {
        border-top: thin solid ${theme.palette.divider};
    }

    .alert {
        padding: 5px;
        margin: -5px;
        border-radius: ${theme.shape.borderRadius}px;
        background: ${fade(theme.palette.error.main, 0.08)};
        color: ${theme.palette.error.main};
    }

    .left-pad {
        margin-left: 8px;
    }

    .assignee {
        border-left: thin solid ${theme.palette.text.secondary};
        padding-left: 8px;
        margin-left: 4px;
    }
`;
// tslint:enable:no-magic-numbers

export const HomePagePanel = <T extends { id?: string; _id?: string }, U extends {}, V extends {}>(
    props: HomePagePanelProps<T, U, V>
) => {
    const theme = useTheme();
    const {
        RowComponent,
        records,
        title,
        ContentComponent,
        containerClass,
        showIfEmpty,
        actions,
        disableCollapse,
        contentProps,
        rowProps,
        collapseKeyId
    } = props;

    const { user } = useUserContext();

    const panelCollapseKey = `homepage-panel-collapse-${collapseKeyId}`;
    const [page, setPage] = useState(0);
    const [collapsed, setCollapsed] = useState<boolean>(
        !disableCollapse && getLocalStorageKey(panelCollapseKey, false)
    );

    const handleCollapseToggle = () => {
        if (!disableCollapse) {
            setCollapsed(!collapsed);
            setLocalStorageKey(panelCollapseKey, !collapsed, -1);
        }
    };

    const handleChangeRowsPerPage = () => {
        /* no-op */
    };
    const handleChangePage = (_1: any, newPage: number) => setPage(newPage);

    if (!records) return <Skeleton variant="rect" className="skeleton" />;

    if (records.length === 0 && !showIfEmpty) return null;

    const rowsPerPage = user?.settings.homePage.rowsPerPage;

    const pagination =
        rowsPerPage && records.length > rowsPerPage ? (
            <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                component="div"
                count={records.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                className="pagination"
            />
        ) : null;

    const visibleRecords =
        rowsPerPage && records.length > rowsPerPage
            ? records.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
            : records;
    const tableRows = visibleRecords.map((r) => (
        <RowComponent data={r} key={r.id || r._id} list={records} {...rowProps} />
    ));

    const count = collapsed ? (
        <Typography variant="h5" color="textPrimary" className="count">
            {records.length}
        </Typography>
    ) : null;

    const toggle = !disableCollapse ? (
        <div className="toggle">
            <IconButton onClick={handleCollapseToggle}>{collapsed ? <ExpandMore /> : <ExpandLess />}</IconButton>
        </div>
    ) : null;

    const leftHeading =
        typeof title === 'string' ? (
            <Typography variant="h5" color="textPrimary">
                {title}
            </Typography>
        ) : (
            title
        );

    const actionsContainer = actions ? <div className="actions-container">{actions}</div> : null;

    return (
        <div css={styles(theme)} className={`${containerClass} panel`}>
            <Paper>
                <div className={`section-title ${collapsed ? 'collapsed' : ''}`}>
                    <div className="left">{leftHeading}</div>
                    <div className="right">
                        {count}
                        {toggle}
                        {actionsContainer}
                    </div>
                </div>
                <Collapse in={!collapsed}>
                    <ContentComponent rows={tableRows} {...contentProps} />
                    {pagination}
                </Collapse>
            </Paper>
        </div>
    );
};
