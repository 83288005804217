import React from 'react';
import DropzoneLib, { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';

interface DropZoneProps {
    onDrop: (files: File[]) => void;
    renderElement: JSX.Element;
}

export const DropZone: React.FC<DropZoneProps> = ({ onDrop, renderElement }) => {
    const getDropzoneSection = (
        getRootProps: <T extends DropzoneRootProps>(props?: T) => T,
        getInputProps: <T extends DropzoneInputProps>(props?: T) => T
    ) => {
        return (
            <section>
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {renderElement}
                </div>
            </section>
        );
    };

    return (
        <DropzoneLib onDrop={onDrop}>
            {({ getRootProps, getInputProps }) => getDropzoneSection(getRootProps, getInputProps)}
        </DropzoneLib>
    );
};
