import * as React from 'react';

import { UserData } from 'shared/models/user';

import { withUser } from '../hocs/with-user';

const UserComponent: React.SFC<{ user: UserData; name?: 'full' | 'first' | 'initials' }> = ({ user, name }) =>
    name === 'first' ? (
        <span>{user.name.first}</span>
    ) : name === 'initials' ? (
        <span>
            {user.name.first[0]}
            {user.name.last[0]}
        </span>
    ) : (
        <span>{user.name.full}</span>
    );

export const User = withUser<{ name?: 'full' | 'first' | 'initials' }>(UserComponent);
