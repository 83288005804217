import { css } from '@emotion/core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Theme,
    Typography,
    useTheme
} from '@material-ui/core';
import { startCase } from 'lodash';
import React from 'react';

import { JobData } from 'shared/models/job';

import { uploadClientLogo } from '../api';
import { DurationProgress } from '../common/duration-progress';
import { config } from '../config';
import { DropZone } from '../core-ui/drop-zone';
import { TextField } from '../core-ui/form-fields';
import { JobOnePagerData } from '../graphql/queries/job-form';
import { useModal } from '../hooks/use-modal';
import { useSnackbar } from '../hooks/use-snackbar';
import { readFile } from '../lib/read-file-payload';
import { useFormContextData } from './form-context';

const formStyles = (theme: Theme) => css`
    .add-field-button {
        text-align: right;
        margin: -24px 10px 32px;
    }

    .client-logo {
        position: relative;
        padding-top: 22px;
        display: flex;

        .client-logo-image {
            flex: 1 1 auto;
        }

        .client-logo-replace {
            flex: 0 0 auto;
            margin-left: 10px;
            opacity: 0;
            transition: opacity 200ms ease-out;

            &.only-element {
                margin-left: 0;
                opacity: 1;
            }
        }

        &:hover {
            .client-logo-replace {
                opacity: 1;
            }
        }

        .label {
            position: absolute;
            top: 0;
            left: 0;
            font-size: 1rem;
            transform: translate(0, 1.5rem) scale(1);
            transform-origin: top left;
            transition: all 200ms ease-out;
            transform: translate(0, 0) scale(0.75);
            color: ${theme.palette.text.secondary};
        }

        img {
            max-width: 200px;
            max-height: 100px;
        }
    }

    .form-field {
        margin-bottom: 32px;

        &.removable {
            display: flex;
            align-items: flex-end;

            .removable-field-field {
                flex: 1 1 auto;
            }

            .removable-field-remove {
                flex: 0 0 auto;
                width: 0;
                overflow: hidden;
                opacity: 0;
                transition:
                    width 200ms ease-out,
                    opacity 200ms ease-out;
                margin-left: 10px;
            }

            &:hover {
                .removable-field-remove {
                    width: 75px;
                    opacity: 1;
                }
            }
        }
    }
`;

export const JobOnePagerDataForm: React.FC<{
    data: JobOnePagerData;
    refetch: () => void;
    onSave: (data: JobData['onePagerData']) => void;
    onClose: () => void;
}> = ({ data: initialData, refetch, onClose, onSave }) => {
    const theme = useTheme();
    const { data, onFieldChange, onChange, getError, validateFieldExists } = useFormContextData<
        JobData['onePagerData']
    >(initialData.onePagerData);
    const [addingField, setAddingField] = React.useState(false);
    const [uploadingLogo, setUploadingLogo] = React.useState(false);
    const [newFieldName, setNewFieldName] = React.useState('');
    const [clientLogo, setClientLogo] = React.useState(initialData.client.logoUrl);
    const { setSnackbar } = useSnackbar();
    const { setAlert, getConfirmation } = useModal();

    const jobTitle = initialData.title;

    const handleAddFieldButtonClick = () => {
        setAddingField(true);
    };

    const handleCancelAddField = () => {
        setAddingField(false);
    };

    const handleUpdateNewFieldName = (value: string) => {
        setNewFieldName(value);
    };

    const handleAddField = () => {
        onChange({ ...data, companyDetails: { ...data.companyDetails, [newFieldName]: '' } });
        setAddingField(false);
        setNewFieldName('');
    };

    const handleStringArrayChange = (field: 'requirements' | 'responsibilities') => (value: string) => {
        onFieldChange(field)(value.split('\n'));
    };

    const handleUploadLogo = async (files: File[]) => {
        setUploadingLogo(true);
        try {
            setSnackbar('Uploading logo...');
            const content = await readFile(files[0]);
            const result = await uploadClientLogo(initialData.client.id, content);
            if (!result.success) {
                throw new Error('Failed to upload logo');
            }
            setClientLogo(result.data.client.logoUrl);
            refetch();
        } catch {
            setAlert('Error', 'Failed to upload logo');
            setSnackbar('Failed to upload logo');
        } finally {
            setUploadingLogo(false);
        }
    };

    const handleCompanyInfoKeyChange =
        <K extends keyof JobData['onePagerData']['companyDetails']>(key: K) =>
        (value: string) => {
            onChange({ ...data, companyDetails: { ...data.companyDetails, [key]: value } });
        };

    const handleRemoveField =
        <K extends keyof JobData['onePagerData']['companyDetails']>(key: K) =>
        () => {
            const { [key]: _, ...newCompanyDetails } = data.companyDetails;
            onChange({ ...data, companyDetails: newCompanyDetails });
        };

    const handleSave = () => {
        onSave(data);
    };

    const handleReset = () => {
        getConfirmation(
            () => {
                onSave(null);
            },
            'Are you sure you want to reset the one pager data? This will use AI to regenerate the data from scratch.',
            'Reset'
        );
    };

    const companyInfoFields = Object.keys(data.companyDetails).map((key) => {
        return (
            <div className="form-field removable" key={key}>
                <div className="removable-field-field">
                    <TextField
                        label={`${startCase(key)}`}
                        value={data.companyDetails[key]}
                        onChange={handleCompanyInfoKeyChange(key as keyof JobData['onePagerData']['companyDetails'])}
                    />
                </div>
                <div className="removable-field-remove">
                    <Button onClick={handleRemoveField(key as keyof JobData['onePagerData']['companyDetails'])}>
                        Remove
                    </Button>
                </div>
            </div>
        );
    });

    const addCompanyInfoFieldButton = (
        <div className="add-field-button">
            <Button onClick={handleAddFieldButtonClick}>Add Field</Button>
        </div>
    );

    const addFieldDialog = addingField ? (
        <Dialog open={true} onClose={handleCancelAddField} maxWidth="sm" fullWidth={true}>
            <DialogTitle>
                <Typography variant="h4" component="div">
                    Add Field
                </Typography>
            </DialogTitle>
            <DialogContent>
                <TextField label="Field Name" value={newFieldName} onChange={handleUpdateNewFieldName} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancelAddField}>Cancel</Button>
                <Button onClick={handleAddField}>Add</Button>
            </DialogActions>
        </Dialog>
    ) : null;

    const logoFullUrl = clientLogo
        ? `${config.AssetHost}/files/` + clientLogo.split('/').map(encodeURIComponent).join('/')
        : null;
    const logo = uploadingLogo ? (
        <div className="client-logo-image">
            <DurationProgress />
        </div>
    ) : clientLogo ? (
        <div className="client-logo-image">
            <img src={logoFullUrl} />
        </div>
    ) : null;

    const replaceLogoButtonElement = (
        <div className={`client-logo-replace ${clientLogo ? '' : 'only-element'}`}>
            <Button variant={clientLogo ? 'text' : 'contained'}>{clientLogo ? 'Replace' : 'Upload'}</Button>
        </div>
    );
    const replaceLogoButton = uploadingLogo ? null : (
        <DropZone onDrop={handleUploadLogo} renderElement={replaceLogoButtonElement} />
    );

    return (
        <Dialog open={true} onClose={onClose} fullWidth={true} maxWidth="md">
            <DialogTitle>
                <Typography variant="h4" component="div">
                    Edit One Pager Data
                </Typography>
            </DialogTitle>
            <DialogContent>
                <div css={formStyles(theme)}>
                    <div className="form-field client-logo">
                        <div className="label">Client Logo</div>
                        {logo}
                        {replaceLogoButton}
                    </div>
                    <div className="form-field">
                        <TextField
                            label="Company Mission"
                            value={data.companyMission}
                            onChange={onFieldChange('companyMission')}
                            errorText={getError('companyMission')}
                            validate={validateFieldExists('companyMission')}
                            multiline={true}
                        />
                    </div>
                    {companyInfoFields}
                    {addCompanyInfoFieldButton}
                    <div className="form-field">
                        <TextField
                            label="Role Title"
                            value={data.roleTitle}
                            onChange={onFieldChange('roleTitle')}
                            placeholder={jobTitle}
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                    <div className="form-field">
                        <TextField
                            label="Role Description"
                            value={data.roleDescription}
                            onChange={onFieldChange('roleDescription')}
                            errorText={getError('roleDescription')}
                            validate={validateFieldExists('roleDescription')}
                            multiline={true}
                        />
                    </div>
                    <div className="form-field">
                        <TextField
                            label="Responsibilities"
                            value={data.responsibilities.join('\n')}
                            onChange={handleStringArrayChange('responsibilities')}
                            multiline={true}
                            helperText="Separate each responsibility with a new line"
                        />
                    </div>
                    <div className="form-field">
                        <TextField
                            label="Requirements"
                            value={data.requirements.join('\n')}
                            onChange={handleStringArrayChange('requirements')}
                            multiline={true}
                            helperText="Separate each requirement with a new line"
                        />
                    </div>
                    <div className="form-field" />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleReset}>Reset</Button>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSave}>Save</Button>
            </DialogActions>
            {addFieldDialog}
        </Dialog>
    );
};
