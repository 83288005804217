import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { TeamMetrics } from '../components/team-metrics';

interface RouterProps {
    id: string;
}

const TeamMetricsComponent: React.FC<RouteComponentProps<RouterProps>> = ({ match }) => {
    const { id } = match.params;
    return <TeamMetrics id={id} />;
};

export const TeamMetricsPage = withRouter(TeamMetricsComponent);
