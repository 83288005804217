import { css } from '@emotion/core';
import { MenuItem, Select, Theme, useTheme } from '@material-ui/core';
import React from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';

import { SearchTextField } from '../common/search-text-field';
import { AllocationsJob } from './allocations-job';
import { AllocationsRecruiter } from './allocations-recruiter';

const styles = (theme: Theme) => css`
    background: #f4f6f8;
    flex: 1 1 auto;
    padding: 25px 50px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .selectors {
        display: flex;
        flex: 0 0 auto;
        justify-content: flex-end;
        padding: 0;
        margin-bottom: 20px;

        .search-field,
        > .MuiInputBase-root {
            margin-left: 15px;

            &:first-child {
                margin-left: 0;
            }

            .MuiOutlinedInput-input {
                padding-top: 14px;
                padding-bottom: 14px;
                background: white;
            }
        }
    }

    .MuiPaper-root {
        display: flex;
        overflow: auto;
        flex-direction: column;
        justify-content: space-between;

        .MuiTableContainer-root {
            flex: 1 1 auto;

            &::-webkit-scrollbar:horizontal {
                border-top: thin solid ${theme.palette.divider};
            }

            &::-webkit-scrollbar:vertical {
                border-left: thin solid ${theme.palette.divider};
            }

            .MuiTableRow-root:last-child {
                td.MuiTableCell-root.MuiTableCell-body {
                    border-bottom: none;
                }
            }

            .role-chips {
                display: flex;
                flex-wrap: wrap;
                margin: -4px;

                .MuiChip-root {
                    margin: 4px;
                }

                .add-allocation-chip {
                    opacity: 0;
                    transition: opacity 0.2s ease-in-out;
                }
            }

            .MuiTableRow-root:hover {
                background-color: ${theme.palette.action.hover};

                .add-allocation-chip {
                    opacity: 1;
                }
            }

            .total {
                font-weight: bold;
                padding: 5px 10px;
                border-radius: ${theme.shape.borderRadius}px;

                &.over {
                    color: rgb(33, 150, 243);
                    background-color: rgba(33, 150, 243, 0.08);
                }

                &.equal {
                    color: ${theme.palette.success.dark};
                }

                &.under-severe {
                    background-color: ${theme.palette.error.main};
                    color: ${theme.palette.error.contrastText};
                }

                &.under {
                    background-color: ${theme.palette.warning.main};
                    color: ${theme.palette.warning.contrastText};
                }
            }

            .MuiTableCell-root {
                &.width120 {
                    min-width: 120px;
                }

                &.width90 {
                    min-width: 120px;
                }
            }
        }
    }
`;

interface RouterProps {
    view: string;
}

const AllocationsComponent: React.FC<RouteComponentProps<RouterProps>> = ({
    match: {
        params: { view }
    }
}) => {
    const theme = useTheme();
    const [search, setSearch] = React.useState('');
    const [redirectTo, setRedirectTo] = React.useState('');

    React.useEffect(() => {
        if (view === redirectTo) {
            setRedirectTo('');
        }
    }, [redirectTo, view]);

    const handleViewChange = (event: React.ChangeEvent<{ value: 'recruiter' | 'job' }>) => {
        setSearch('');
        setRedirectTo(event.target.value);
    };

    const table =
        view === 'recruiter' ? (
            <AllocationsRecruiter filterText={search?.trim()} />
        ) : (
            <AllocationsJob filterText={search?.trim()} />
        );

    if (redirectTo && redirectTo !== view) {
        return <Redirect to={`/allocations/${redirectTo}`} />;
    }

    return (
        <div css={styles(theme)}>
            <div className="selectors">
                <SearchTextField value={search} variant="outlined" onValueChange={setSearch} />
                <Select value={view} onChange={handleViewChange} variant="outlined">
                    <MenuItem value={'recruiter'}>Recruiters</MenuItem>
                    <MenuItem value={'job'}>Jobs</MenuItem>
                </Select>
            </div>
            {table}
        </div>
    );
};

export const Allocations = withRouter(AllocationsComponent);
