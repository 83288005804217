import { Map } from 'immutable';

import { EmailAddress } from 'shared/types/email-compose';

import { Action } from '../../actions';
import { ReceiveAccountsList } from '../actions';

export function emailInboxAccounts(state: Map<string, EmailAddress> = null, action: Action): Map<string, EmailAddress> {
    switch (action.type) {
        case ReceiveAccountsList: {
            let result = Map<string, EmailAddress>();
            for (const record of action.payload) {
                result = result.set(record.email, { address: record.email, name: record.name });
            }
            return result;
        }
        default:
            return state;
    }
}
