import { ApiEndpoint, getHeaders, parseResponse } from '../api';
import { queryString } from '../common/utils';

export const fetchAccountsList = () => {
    const endpoint = `${ApiEndpoint}/email-accounts/accounts-with-inbox-access`;
    return fetch(endpoint, { headers: getHeaders() }).then(parseResponse);
};

export const fetchAccountData = (account: string) => {
    const endpoint = `${ApiEndpoint}/email/${account}`;
    return fetch(endpoint, { headers: getHeaders() }).then(parseResponse);
};

export const fetchThreads = (account: string, label: string, offset: number, count: number, query?: string) => {
    const qs = queryString({ label, offset, count, query });
    const endpoint = `${ApiEndpoint}/email/${account}/threads?${qs}`;
    return fetch(endpoint, { headers: getHeaders() }).then(parseResponse);
};

export const deleteThreads = (account: string, threadIds: string[]) => {
    const endpoint = `${ApiEndpoint}/email/${account}/threads`;
    return fetch(endpoint, { headers: getHeaders(), method: 'delete', body: JSON.stringify({ threadIds }) }).then(
        parseResponse
    );
};

export const untrashThreads = (account: string, threadIds: string[]) => {
    const endpoint = `${ApiEndpoint}/email/${account}/threads/recover`;
    return fetch(endpoint, { headers: getHeaders(), method: 'put', body: JSON.stringify({ threadIds }) }).then(
        parseResponse
    );
};

export const discardDraftsByMessageIds = (account: string, messageIds: string[]) => {
    const endpoint = `${ApiEndpoint}/email/${account}/drafts/discard-by-message-ids`;
    return fetch(endpoint, {
        body: JSON.stringify({ messageIds }),
        headers: getHeaders(),
        method: 'put'
    }).then(parseResponse);
};

export const updateThreadLabels = (
    account: string,
    threadIds: string[],
    labelsAdded: string[],
    labelsRemoved: string[]
) => {
    const endpoint = `${ApiEndpoint}/email/${account}/threads`;
    const body = JSON.stringify({ labelsAdded, labelsRemoved, threadIds });
    return fetch(endpoint, { headers: getHeaders(), method: 'put', body }).then(parseResponse);
};

export const searchInbox = (account: string, searchString: string) => {
    const endpoint = `${ApiEndpoint}/email/${account}/search/${encodeURIComponent(searchString)}`;
    return fetch(endpoint, { headers: getHeaders() }).then(parseResponse);
};

export const markMessagesUnread = (account: string, messageIds: string[]) => {
    const endpoint = `${ApiEndpoint}/email/${account}/mark-message-unread`;
    return fetch(endpoint, { headers: getHeaders(), method: 'put', body: JSON.stringify({ messageIds }) }).then(
        parseResponse
    );
};

export const deleteMessage = (account: string, messageId: string) => {
    const endpoint = `${ApiEndpoint}/email/${account}/message/${messageId}`;
    return fetch(endpoint, {
        headers: getHeaders(),
        method: 'delete'
    }).then(parseResponse);
};

export const downloadAttachment = (account: string, messageId: string, attachmentId: string) => {
    const endpoint = `${ApiEndpoint}/email/${account}/get-attachment`;
    return fetch(endpoint, {
        body: JSON.stringify({ messageId, attachmentId }),
        headers: getHeaders(),
        method: 'post'
    }); // no parsing return raw response
};
