import React, { createContext, FC, useContext, useEffect, useState } from 'react';

import moment from 'moment';
import { JobType } from 'shared/common/job-constants';
import { useLocalStorage } from '../../hooks/use-local-storage';
import {
    Duration,
    DurationOptionLabel,
    getConversionPeriodDuration,
    getTrendPeriodDuration
} from './metrics-durations';

const defaultConversionPeriodOpt: DurationOptionLabel = '30 days (ending 2 weeks ago)';
const defaultTrendPeriodOpt: DurationOptionLabel = 'Last 6 months';
const pollInterval = 300000;

const getMonthDateRange = () =>
    [moment().startOf('month').valueOf(), moment().endOf('day').valueOf()] as [number, number];

const getYearDateRange = () =>
    [moment().startOf('year').valueOf(), moment().endOf('day').valueOf()] as [number, number];

const MetricsDashboardDataContext = createContext<{
    conversionDuration: { label: DurationOptionLabel; value: Duration };
    trendDuration: { label: DurationOptionLabel; value: Duration };
    onConversionDurationChange: (label: DurationOptionLabel, value: Duration) => void;
    onTrendDurationChange: (label: DurationOptionLabel, value: Duration) => void;
    monthDateRange: [number, number];
    yearDateRange: [number, number];
    restrictToAccountManagerIds: string[];
    restrictToClientIds: string[];
    restrictToRecruiterIds: string[];
    restrictToTeam: { id: string; users: string[]; role: 'recruiter' | 'account_manager' };
    jobTypes: JobType[];
    setJobTypes: (jobTypes: JobType[]) => void;
    setRestrictToAccountManagerIds: (ids: string[]) => void;
    setRestrictToClientIds: (ids: string[]) => void;
    setRestrictToRecruiterIds: (ids: string[]) => void;
    setRestrictToTeam: (team: { id: string; users: string[]; role: 'recruiter' | 'account_manager' }) => void;
}>({
    conversionDuration: undefined,
    jobTypes: undefined,
    monthDateRange: undefined,
    onConversionDurationChange: () => {
        /* */
    },
    onTrendDurationChange: () => {
        /* */
    },
    restrictToAccountManagerIds: undefined,
    restrictToClientIds: undefined,
    restrictToRecruiterIds: undefined,
    restrictToTeam: undefined,
    setJobTypes: () => {
        /* */
    },
    setRestrictToAccountManagerIds: () => {
        /* */
    },
    setRestrictToClientIds: () => {
        /* */
    },
    setRestrictToRecruiterIds: () => {
        /* */
    },
    setRestrictToTeam: () => {
        /* */
    },
    trendDuration: undefined,
    yearDateRange: undefined
});

const MetricsDashboardDataProvider: FC = (props) => {
    const [monthDateRange, setMonthDateRange] = useState(getMonthDateRange());
    const [yearDateRange, setYearDateRange] = useState(getYearDateRange());
    const [restrictToAccountManagerIds, setRestrictToAccountManagerIds] = useLocalStorage<string[]>(
        'metrics-restrict-to-am',
        undefined
    );
    const [restrictToClientIds, setRestrictToClientIds] = useLocalStorage<string[]>(
        'metrics-restrict-to-client',
        undefined
    );
    const [restrictToRecruiterIds, setRestrictToRecruiterIds] = useLocalStorage<string[]>(
        'metrics-restrict-to-recruiter',
        undefined
    );
    const [conversionPeriodLabel, setConversionPeriodLabel] = useLocalStorage<DurationOptionLabel>(
        'metrics-conversion-period',
        defaultConversionPeriodOpt
    );
    const [conversionPeriodCustom, setConversionPeriodCustom] = useLocalStorage<Duration>(
        'metrics-conversion-period-custom',
        undefined
    );
    const [trendPeriodLabel, setTrendPeriodLabel] = useLocalStorage<DurationOptionLabel>(
        'metrics-trend-period',
        defaultTrendPeriodOpt
    );
    const [trendPeriodCustom, setTrendPeriodCustom] = useLocalStorage<Duration>(
        'metrics-trend-period-custom',
        undefined
    );
    const [restrictToTeam, setRestrictToTeam] = useLocalStorage<{
        id: string;
        users: string[];
        role: 'recruiter' | 'account_manager';
    }>('metrics-restrict-to-team', undefined);
    const [jobTypes, setJobTypes] = useLocalStorage<JobType[]>('metrics-job-types', [
        'C',
        'S',
        'CnC',
        'R',
        'C+$',
        'SwC',
        'CnC+$',
        'RPO'
    ]);

    useEffect(() => {
        const interval = setInterval(() => {
            // update date ranges
            setMonthDateRange(getMonthDateRange());
            setYearDateRange(getYearDateRange());
        }, pollInterval);
        return () => clearInterval(interval);
    }, []);

    const conversionDuration =
        conversionPeriodLabel === 'Custom'
            ? conversionPeriodCustom
            : getConversionPeriodDuration(conversionPeriodLabel);

    const trendDuration = trendPeriodLabel === 'Custom' ? trendPeriodCustom : getTrendPeriodDuration(trendPeriodLabel);

    const handleConversionDurationChange = (label: DurationOptionLabel, value: Duration) => {
        setConversionPeriodLabel(label);
        if (label === 'Custom') {
            setConversionPeriodCustom(value);
        }
    };

    const handleTrendDurationChange = (label: DurationOptionLabel, value: Duration) => {
        setTrendPeriodLabel(label);
        if (label === 'Custom') {
            setTrendPeriodCustom(value);
        }
    };

    const contextValue = {
        conversionDuration: { label: conversionPeriodLabel, value: conversionDuration },
        jobTypes,
        monthDateRange,
        onConversionDurationChange: handleConversionDurationChange,
        onTrendDurationChange: handleTrendDurationChange,
        restrictToAccountManagerIds,
        restrictToClientIds,
        restrictToRecruiterIds,
        restrictToTeam,
        setJobTypes,
        setRestrictToAccountManagerIds,
        setRestrictToClientIds,
        setRestrictToRecruiterIds,
        setRestrictToTeam,
        trendDuration: { label: trendPeriodLabel, value: trendDuration },
        yearDateRange
    };

    return (
        <MetricsDashboardDataContext.Provider value={contextValue}>
            {props.children}
        </MetricsDashboardDataContext.Provider>
    );
};

const useMetricsDashboardData = () => useContext(MetricsDashboardDataContext);

export { MetricsDashboardDataProvider, useMetricsDashboardData, pollInterval };
