import { OrderedMap } from 'immutable';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { getConfirmation } from '../../actions';
import { zIndexes } from '../../common/css-variables';
import { deleteDraft } from '../../common/draft-storage';
import { State } from '../../state';
import { closeEmailComposeWindow, sendMail } from '../actions';
import { ComposeEmailWindowData } from '../types';
import { EmailComposeFab } from './email-compose-fab';
import { EmailComposeWindow } from './email-compose-window';

interface ConnectedProps {
    emailComposeWindows: OrderedMap<string, ComposeEmailWindowData>;
}

interface ConnectedDispatch {
    getConfirmation: (onConfirm: () => void, description?: string, title?: string) => void;
    closeEmailComposeWindow: (payload: ComposeEmailWindowData) => void;
    sendMail: (payload: ComposeEmailWindowData, sendAt: number) => void;
}

type EmailComposeLayerProps = RouteComponentProps<any> & ConnectedProps & ConnectedDispatch;
class EmailComposeLayerComponent extends React.Component<EmailComposeLayerProps, undefined> {
    handleDiscardDraftRequest = (payload: ComposeEmailWindowData, needConfirmation: boolean) => {
        if (!needConfirmation) {
            deleteDraft(`email-compose-${payload.windowId}`);
            this.props.closeEmailComposeWindow(payload);
        } else {
            this.props.getConfirmation(
                () => {
                    deleteDraft(`email-compose-${payload.windowId}`);
                    this.props.closeEmailComposeWindow(payload);
                },
                'Message draft will be deleted immediately',
                'Confirm'
            );
        }
    };

    render() {
        const { emailComposeWindows, location } = this.props;
        const composeWindows = emailComposeWindows
            .valueSeq()
            .map((payload) => (
                <EmailComposeWindow
                    data={payload}
                    onRequestSend={payload.onRequestSendAction || this.props.sendMail}
                    onRequestDelete={this.handleDiscardDraftRequest}
                    onRequestCloseWindow={this.props.closeEmailComposeWindow}
                    key={payload.windowId}
                />
            ));
        const composeButton = location.pathname.match(/^\/emails/) ? <EmailComposeFab /> : null;
        return (
            <div className="email-compose-section">
                {composeButton}
                {composeWindows}
            </div>
        );
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    emailComposeWindows: state.emailComposeWindows
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    closeEmailComposeWindow,
    getConfirmation,
    sendMail
};

const ConnectedComponent = withRouter(
    connect<ConnectedProps, ConnectedDispatch, RouteComponentProps<any>>(
        mapStateToProps,
        mapDispatchToProps
    )(EmailComposeLayerComponent)
);

export class EmailComposeLayer extends React.Component<{}> {
    private layer: HTMLElement;

    constructor(props: EmailComposeLayerProps) {
        super(props);
        this.layer = document.createElement('div');
        this.layer.setAttribute('class', 'email-compose-layer');
        this.layer.setAttribute('style', `z-index: ${zIndexes.emailComposeLayer}`);
        document.body.appendChild(this.layer);
    }

    render() {
        return ReactDOM.createPortal(<ConnectedComponent />, this.layer);
    }
}
