import { useMutation, useQuery } from '@apollo/client';
import {
    Avatar,
    Chip,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip
} from '@material-ui/core';
import { escapeRegExp, orderBy } from 'lodash';
import React from 'react';
import { hasRole } from 'shared/models/user';
import {
    ALLOCATIONS_BY_RECRUITER,
    DELETE_ALLOCATION,
    RecruiterAllocation,
    RecruiterAllocations
} from '../graphql/queries/allocations';
import { useLocalStorage } from '../hooks/use-local-storage';
import { useModal } from '../hooks/use-modal';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';
import { AddRecruiterAllocationsButton } from './add-recruiter-allocations-button';

export const AllocationsRecruiter: React.FC<{ filterText: string }> = ({ filterText }) => {
    const { data, refetch } = useQuery<RecruiterAllocations>(ALLOCATIONS_BY_RECRUITER);
    const [deleteAllocation] = useMutation<{}, { jobId: string; userId: string }>(DELETE_ALLOCATION);
    const { user: sessionUser, userPermissions } = useSession();
    const { getConfirmation } = useModal();
    const { setSnackbar } = useSnackbar();
    const [sortCol, setSortCol] = useLocalStorage<string>('allocations-table-sort-column', 'Name');
    const [sortAsc, setSortAsc] = useLocalStorage<boolean>('allocations-table-sort-asc', true);
    const [saving, setSaving] = React.useState(false);

    const handleSortChange = (column: string) => () => {
        const newSortAsc = sortCol === column ? !sortAsc : true;
        setSortCol(column);
        setSortAsc(newSortAsc);
    };
    const getSortDirection = (ascending: boolean) => (ascending ? 'asc' : 'desc');

    const sortFuncs = (col: string, sortOrder: 'asc' | 'desc') => {
        switch (col) {
            case 'Name':
                return {
                    functions: [(user: RecruiterAllocation) => user.name.toLocaleLowerCase()],
                    order: [sortOrder]
                };
            case 'Allocated':
                return {
                    functions: [
                        (user: RecruiterAllocation) => user.allocations_aggregate.aggregate.sum.acceptsGoal ?? 0,
                        (user: RecruiterAllocation) => user.name.toLocaleLowerCase()
                    ],
                    order: [sortOrder, 'asc' as 'asc']
                };
            case 'Capacity':
                return {
                    functions: [
                        (user: RecruiterAllocation) => user.targets[0]?.goal ?? 0,
                        (user: RecruiterAllocation) => user.name.toLocaleLowerCase()
                    ],
                    order: [sortOrder, 'asc' as 'asc']
                };
            case 'Capacity Gap':
                return {
                    functions: [
                        (user: RecruiterAllocation) =>
                            (user.targets[0]?.goal ?? 0) - (user.allocations_aggregate.aggregate.sum.acceptsGoal ?? 0),
                        (user: RecruiterAllocation) => user.name.toLocaleLowerCase()
                    ],
                    order: [sortOrder, 'asc' as 'asc']
                };
            case 'Last 14d':
                return {
                    functions: [
                        (user: RecruiterAllocation) => user.last14dFunnel?.[0]?.count ?? 0,
                        (user: RecruiterAllocation) => user.name.toLocaleLowerCase()
                    ],
                    order: [sortOrder, 'asc' as 'asc']
                };
            case 'Last 14d Gap':
                return {
                    functions: [
                        (user: RecruiterAllocation) =>
                            (user.last14dFunnel?.[0]?.count ?? 0) - (user.targets[0]?.goal ?? 0),
                        (user: RecruiterAllocation) => user.name.toLocaleLowerCase()
                    ],
                    order: [sortOrder, 'asc' as 'asc']
                };
            default:
                return { functions: [], order: [] };
        }
    };

    const getValueTargetClass = (value: number, target: number) => {
        return value === target ? 'equal' : value < target / 2 ? 'under-severe' : value < target ? 'under' : 'over';
    };
    const getGapString = (value: number, target: number) => {
        const gap = value - target;
        return gap === 0 ? '0' : gap > 0 ? `+${gap}` : `${gap}`;
    };

    const handleDeleteAllocation = (jobId: string, userId: string) => () => {
        getConfirmation(
            async () => {
                setSaving(true);
                setSnackbar('Removing allocation');
                await deleteAllocation({ variables: { jobId, userId } });
                await refetch();
                setSnackbar('Allocation removed');
                setSaving(false);
            },
            'Are you sure you want to remove this allocation?',
            'Remove Allocation'
        );
    };

    const { functions, order } = sortFuncs(sortCol, getSortDirection(sortAsc));
    const searchRegex = new RegExp(escapeRegExp(filterText), 'i');
    const filteredData = data?.users.filter((user) => !filterText || searchRegex.test(user.name));
    const sortedData = orderBy(filteredData, functions, order);
    const rows = sortedData?.map((user) => {
        const total = user.allocations_aggregate.aggregate.sum.acceptsGoal ?? 0;
        const capacity = user.targets[0]?.goal ?? 0;
        const last14d = user.last14dFunnel?.[0]?.count ?? 0;
        const totalClass = getValueTargetClass(capacity, total);
        const last14dClass = getValueTargetClass(last14d, capacity);
        const totalGap = getGapString(capacity, total);
        const last14dGap = getGapString(last14d, capacity);
        const roles = user.allocations.map((allocation) => {
            const canDelete =
                !saving &&
                (hasRole(userPermissions, 'recruiter_funnel_target_editor') ||
                    allocation.job.accountManagerId === sessionUser.id);
            return (
                <Chip
                    key={allocation.job.id}
                    label={`${allocation.job.client.name} - ${allocation.job.title}`}
                    variant="outlined"
                    avatar={<Avatar>{allocation.acceptsGoal}</Avatar>}
                    onDelete={canDelete ? handleDeleteAllocation(allocation.job.id, user.id) : undefined}
                />
            );
        });
        const userForAdd = { id: user.id, name: user.name, target: capacity, allocated: total };
        return (
            <TableRow key={user.id}>
                <TableCell>{user.name}</TableCell>
                <TableCell>
                    <div className="role-chips">
                        {roles}
                        <AddRecruiterAllocationsButton user={userForAdd} onSave={refetch} />
                    </div>
                </TableCell>
                <TableCell>
                    <Tooltip title={`Accepts capacity in a 14 day period: ${capacity}`}>
                        <span>{capacity}</span>
                    </Tooltip>
                </TableCell>
                <TableCell>
                    <Tooltip title={`Target accepts allocated for a 14 day period: ${total}`}>
                        <span>{total}</span>
                    </Tooltip>
                </TableCell>
                <TableCell>
                    <Tooltip title={`Gap of ${totalGap} between capacity and allocation`}>
                        <span className={`total ${totalClass}`}>{capacity - total}</span>
                    </Tooltip>
                </TableCell>
                <TableCell>
                    <Tooltip title={`${last14d} accepts in last 14 day period`}>
                        <span>{last14d}</span>
                    </Tooltip>
                </TableCell>
                <TableCell>
                    <Tooltip title={`Gap of ${last14dGap} between actual in last 14 days and capacity`}>
                        <span className={`total ${last14dClass}`}>{last14d - capacity}</span>
                    </Tooltip>
                </TableCell>
            </TableRow>
        );
    });

    const columns = ['Name', 'Allocations', 'Capacity', 'Allocated', 'Capacity Gap', 'Last 14d', 'Last 14d Gap'];
    const colClasses: { [key: string]: string } = {
        Allocated: 'width90',
        Capacity: 'width90',
        'Capacity Gap': 'width120',
        'Last 14d': 'width90',
        'Last 14d Gap': 'width120',
        Name: 'width120'
    };

    const headerColumns = columns.map((col) => {
        const columnHeader =
            col === 'Allocations' ? (
                col
            ) : (
                <TableSortLabel
                    active={sortCol === col}
                    direction={getSortDirection(sortAsc)}
                    onClick={handleSortChange(col)}
                >
                    {col}
                </TableSortLabel>
            );
        return (
            <TableCell className={colClasses[col] ?? ''} key={col}>
                {columnHeader}
            </TableCell>
        );
    });

    return (
        <Paper>
            <TableContainer>
                <Table stickyHeader={true}>
                    <TableHead>
                        <TableRow>{headerColumns}</TableRow>
                    </TableHead>
                    <TableBody>{rows}</TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};
