import { css } from '@emotion/core';
import { Avatar as MuiAvatar, Theme, Tooltip, useTheme } from '@material-ui/core';
import { AccessTime, Block, Email, EmailOutlined, Error, Event, Help, Phone } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import * as classNames from 'classnames';
import { orderBy } from 'lodash';
import moment from 'moment';
import React from 'react';

import { EmailSentiment as EmailSentimentType } from 'shared/models/communication';
import {
    clientFinalRoundStage,
    clientFirstRoundStage,
    clientMiddleRoundStage,
    getDisqualReasonLabel,
    responseReceivedStage,
    rocketScreenScheduledStage
} from 'shared/models/job-stages';

import { getPendingCountsByView } from '../common/communication-utils';
import { fullDateTime, timeFrom, timeRelativeDay } from '../common/timestamp';
import { Candidate } from '../graphql/queries/candidates-board';
import { useCandidatesBoard } from '../hooks/use-candidates-board';
import { Avatar } from '../sfc/avatar';

interface KanbanCardProps {
    candidate: Candidate;
    onClick: () => void;
}

// tslint:disable:no-magic-numbers
const styles = (theme: Theme) => css`
    margin: 25px 0;
    border: thin solid ${theme.palette.divider};
    border-radius: ${theme.shape.borderRadius}px;
    padding: 12px 15px 16px;
    background: white;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;

    &.disqualified {
        opacity: 0.7;
    }

    &:first-of-type {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    .emails-badge {
        position: absolute;
        top: -8px;
        right: -8px;

        .MuiAvatar-root {
            width: 20px;
            height: 20px;

            color: white;
            font-size: 12px;

            .MuiSvgIcon-root {
                font-size: 12px;
            }
        }

        &.candidate {
            .MuiAvatar-root {
                background: #f44336;
            }
        }

        &.client {
            .MuiAvatar-root {
                background: #367cf7;
            }
        }

        &.candidate-client {
            .MuiAvatar-root {
                background: #691b99;
            }
        }
    }

    .sentiment-indicator {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 5px;
        border-top-left-radius: ${theme.shape.borderRadius}px;
        border-bottom-left-radius: ${theme.shape.borderRadius}px;

        &.sentiment-0 {
            background: ${theme.palette.error.main};
        }

        &.sentiment-1 {
            background: ${theme.palette.grey[400]};
        }

        &.sentiment-2 {
            background: ${theme.palette.success.main};
        }
    }

    .interview-info {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 5;
        padding: 2px 5px;
        background: white;
        border-radius: ${theme.shape.borderRadius}px;

        .MuiSvgIcon-root {
            font-size: 20px;

            &.error {
                color: ${theme.palette.error.main};
            }

            &.success {
                color: ${theme.palette.success.main};
            }

            &.info {
                color: ${theme.palette.grey[400]};
            }
        }
    }

    .assignee-info {
        position: absolute;
        bottom: 1px;
        left: 1px;
        z-index: 5;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: rgba(0, 0, 0, 0.4);
        font-size: 10px;
        border-radius: 50%;
        width: 20px;
        height: 20px;
    }

    &:hover {
        .assignee-info {
            background: ${theme.palette.divider};
            color: ${theme.palette.text.primary};
        }
    }

    &.stage-response_received {
        .assignee-info {
            left: 5px;
        }
    }

    .avatar {
        display: flex;
        align-items: center;
    }

    .info {
        margin-left: 12px;
        overflow: hidden;

        .activity-age {
            margin-top: 7px;
        }

        .age {
            display: inline-flex;
            align-items: center;
            color: rgba(0, 0, 0, 0.4);
            margin-right: 15px;
            font-size: 14px;

            &:last-child {
                margin-right: 0;
            }

            .MuiSvgIcon-root {
                margin-right: 4px;
                font-size: 18px;
            }
        }
    }

    .disqualified-info {
        display: flex;
        align-items: center;
        margin-top: 4px;
        margin-bottom: 6px;
        font-size: 14px;
        color: ${theme.palette.text.secondary};

        &:last-of-type {
            margin-bottom: 0;
        }

        .disqualified-label {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .MuiSvgIcon-root {
            font-size: 1rem;
            margin-right: 7px;
        }
    }

    .client-info {
        margin-top: 4px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.54);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .MuiSkeleton-circle {
        height: 40px;
        width: 40px;
    }

    .MuiSkeleton-rect {
        width: 150px;
        height: 14px;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;
// tslint:enable:no-magic-numbers

const EmailBadge: React.FC<{ candidate: Candidate }> = ({ candidate }) => {
    const countsByView = getPendingCountsByView(candidate.pendingEmails);
    const hasCandidateEmails = countsByView.get('candidate') > 0;
    const hasClientEmails = countsByView.get('client') > 0;
    const hasSharedEmails = countsByView.get('candidate-and-client') > 0;
    const iconClass = classNames({
        candidate: hasCandidateEmails,
        'candidate-client': hasSharedEmails,
        client: hasClientEmails,
        'notification-badge': true
    });
    const tooltip =
        (hasClientEmails && hasCandidateEmails) || hasSharedEmails
            ? 'Client and Candidate Emails'
            : hasClientEmails
              ? 'Client Emails'
              : 'Candidate Emails';

    return candidate.pendingEmails.length > 0 ? (
        <Tooltip title={tooltip}>
            <div className={`emails-badge ${iconClass}`}>
                <MuiAvatar>
                    <Email />
                </MuiAvatar>
            </div>
        </Tooltip>
    ) : null;
};

const EmailSentiment: React.FC<{ candidate: Candidate }> = ({ candidate }) => {
    const sentimentTitle =
        candidate.emailSentiment === EmailSentimentType.Negative
            ? 'Likely Not Interested Response'
            : candidate.emailSentiment === EmailSentimentType.Positive
              ? 'Likely Interested Response'
              : 'Response interest unclear';
    return candidate.stage.name === responseReceivedStage ? (
        <Tooltip title={sentimentTitle}>
            <div className={`sentiment-indicator sentiment-${candidate.emailSentiment}`} />
        </Tooltip>
    ) : null;
};

const Interview: React.FC<{ candidate: Candidate }> = ({ candidate }) => {
    if (
        !candidate.disqualified &&
        (candidate.stage.name === rocketScreenScheduledStage ||
            candidate.stage.name === clientFirstRoundStage ||
            candidate.stage.name === clientMiddleRoundStage ||
            candidate.stage.name === clientFinalRoundStage)
    ) {
        const kind =
            candidate.stage.name === rocketScreenScheduledStage
                ? 'Rocket Screen'
                : candidate.stage.name === clientFirstRoundStage
                  ? 'Client First Round'
                  : candidate.stage.name === clientMiddleRoundStage
                    ? 'Client Middle Round'
                    : candidate.stage.name === clientFinalRoundStage
                      ? 'Client Final Round'
                      : '';
        const interview = orderBy(
            candidate.interviews,
            [
                // today's interviews that have not happened yet first
                (i) => moment(i.startTime).isSame(moment(), 'day') && i.startTime < Date.now(),
                // future interviews next
                (i) => i.startTime < Date.now(),
                // ordered by start time descending
                (i) => i.startTime
            ],
            ['asc', 'asc', 'desc']
        )?.find((i) => i.kind === kind);
        const infoIcon = !interview ? (
            candidate.stage.name === rocketScreenScheduledStage ? (
                <Tooltip title="Interview not found">
                    <Error className="error" />
                </Tooltip>
            ) : (
                <Tooltip title="Interview information not found">
                    <Help className="info" />
                </Tooltip>
            )
        ) : (interview.endTime ?? interview.startTime) < Date.now() ? (
            <Tooltip title={`Interview elapsed ${timeRelativeDay(interview.endTime ?? interview.startTime)}`}>
                <Event className="error" />
            </Tooltip>
        ) : candidate.stage.name === rocketScreenScheduledStage && candidate.person.phoneNumbers.length === 0 ? (
            <Tooltip title="Phone number missing">
                <Phone className="error" />
            </Tooltip>
        ) : moment(interview.startTime).isSame(moment(), 'day') ? (
            <Tooltip title={`Interview scheduled at ${timeRelativeDay(interview.startTime)}`}>
                <Event className="success" />
            </Tooltip>
        ) : (
            <Tooltip title={`Interview scheduled at ${fullDateTime(interview.startTime)}`}>
                <Event className="info" />
            </Tooltip>
        );
        return infoIcon ? <div className="interview-info">{infoIcon}</div> : null;
    }
    return null;
};

const Assignee: React.FC<{ candidate: Candidate }> = ({ candidate }) => (
    <Tooltip title={`Assignee: ${candidate.assignee.name.full}`}>
        <div className="assignee-info">
            {candidate.assignee.name.first.charAt(0)}
            {candidate.assignee.name.last.charAt(0)}
        </div>
    </Tooltip>
);

export const KanbanCard: React.FC<KanbanCardProps> = ({ candidate, onClick }) => {
    const theme = useTheme();
    const { showJobName, showAssigneeName, showClientName } = useCandidatesBoard();

    let jobInfo;
    if (showClientName || showJobName) {
        const textParts = [];
        if (showClientName) {
            textParts.push(candidate.job.client.name);
        }
        if (showJobName) {
            textParts.push(candidate.job.title);
        }
        const text = textParts.join(' - ');
        jobInfo = (
            <div className="client-info">
                <Tooltip title={text}>
                    <span>{text}</span>
                </Tooltip>
            </div>
        );
    }

    const disqualifiedInfo = candidate.disqualified ? (
        <div className="disqualified-info">
            <Block />
            <div className="disqualified-label">{getDisqualReasonLabel(candidate.disqualReason)}</div>
        </div>
    ) : null;
    const secondaryInfo = (
        <div className="activity-age">
            <Tooltip title="Time in stage">
                <span className="age">
                    <AccessTime /> {candidate.lastStageChangedAt ? timeFrom(candidate.lastStageChangedAt, true) : ' - '}
                </span>
            </Tooltip>
            <Tooltip title="Time since last email">
                <span className="age">
                    <EmailOutlined />{' '}
                    {candidate.lastEmailExchangedAt ? timeFrom(candidate.lastEmailExchangedAt, true) : ' - '}
                </span>
            </Tooltip>
        </div>
    );

    const interviewInfo = <Interview candidate={candidate} />;

    const assigneeInfo = showAssigneeName ? <Assignee candidate={candidate} /> : null;

    const classes = classNames({
        disqualified: candidate.disqualified,
        [`stage-${candidate.stage.name}`]: true
    });

    return (
        <div css={styles(theme)} onClick={onClick} className={classes}>
            <EmailSentiment candidate={candidate} />
            <EmailBadge candidate={candidate} />
            {interviewInfo}
            {assigneeInfo}
            <div>
                <Avatar entity={candidate.person} />
            </div>
            <div className="info">
                {candidate.person.name.full}
                {secondaryInfo}
                {disqualifiedInfo}
                {jobInfo}
            </div>
        </div>
    );
};

export const KanbanCardSkeleton: React.FC = () => {
    const theme = useTheme();
    return (
        <div css={styles(theme)}>
            <div>
                <Skeleton variant="circle" />
            </div>
            <div className="info">
                <Skeleton variant="rect" />
                <Skeleton variant="rect" />
            </div>
        </div>
    );
};
