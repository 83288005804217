import { Map } from 'immutable';
import { ClientData } from 'shared/models/client';

import {
    Action,
    ExtensionProfileParsed,
    ReceiveClientData,
    ReceiveClientFilesResult,
    ReceiveClientList,
    ReceiveClientUpdate,
    ReceiveJobInfo,
    ReceiveJobsList,
    ReceiveNewClient,
    ReceivePersonDetails,
    ReceiveRemoveClientFileResult,
    ReceiveUploadClientPersonBlacklist,
    RequestClientData,
    RequestClientFiles,
    RequestClientList,
    RequestClientUpdate,
    RequestNewClient,
    RequestRemoveClientFile
} from '../actions';
import { mergeArrayToMap } from '../common/lang/immutable-utils';
import { Client, List } from '../state';

const initialState: List<Client> = {
    formErrors: null,
    initialized: false,
    isCreating: false,
    isFetching: false,
    list: Map()
};

export function clients(state = initialState, action: Action) {
    switch (action.type) {
        case RequestClientList:
            return Object.assign({}, state, { isFetching: true });
        case ExtensionProfileParsed:
        case ReceiveJobsList: {
            const hasList = action.payload.clients?.length > 0;
            const list = hasList ? mergeArrayToMap(state.list, action.payload.clients) : state.list;
            return Object.assign({}, state, { initialized: hasList, isFetching: false, list });
        }
        case ReceiveClientList: {
            const list = mergeArrayToMap(state.list, action.payload);
            return Object.assign({}, state, { initialized: true, isFetching: false, list });
        }
        case ReceivePersonDetails: {
            const list = mergeArrayToMap(state.list, action.payload.clients);
            return Object.assign({}, state, { list });
        }
        case RequestClientData:
            return Object.assign({}, state, { isFetching: true });
        case ReceiveClientData: {
            const list = mergeArrayToMap(state.list, [action.payload.client]);
            return Object.assign({}, state, { initialized: true, isFetching: false, list });
        }
        case RequestNewClient:
        case RequestClientUpdate:
            return Object.assign({}, state, { isCreating: true });
        case RequestClientFiles:
            return Object.assign({}, state);
        case RequestRemoveClientFile:
            const clientId = action.payload.clientId;
            const key = action.payload.key;
            const clientRecord = state.list.get(clientId);
            const clientsList = state.list.set(clientRecord.id, {
                ...clientRecord,
                contracts: clientRecord.contracts.filter((c) => c.key !== key)
            });
            return Object.assign({}, state, { list: clientsList });
        case ReceiveNewClient:
        case ReceiveClientUpdate:
        case ReceiveUploadClientPersonBlacklist:
            if (action.error) {
                return Object.assign({}, state, {
                    formErrors: Map(action.payload as Map<string, string>),
                    isCreating: false
                });
            } else {
                const client = (action.payload as { client: ClientData }).client;
                const list = state.list.set(client.id, client);
                return Object.assign({}, state, { list, isFetching: false, isCreating: false, formErrors: null });
            }
        case ReceiveJobInfo:
        case ReceiveRemoveClientFileResult:
        case ReceiveClientFilesResult: {
            const client = action.payload.client;
            const list = state.list.set(client.id, client);
            return Object.assign({}, state, { list });
        }

        default:
            return state;
    }
}
