import { Map } from 'immutable';

import * as actions from '../actions';
import { BlacklistCheck } from '../state';

const initialBlacklist: Map<string, BlacklistCheck> = Map();

export function blacklists(state = initialBlacklist, action: actions.Action): Map<string, BlacklistCheck> {
    switch (action.type) {
        case actions.ReceivePersonDetails: {
            if (action.payload.jobId) {
                const key = `${action.payload.personId}-${action.payload.jobId}`;
                return state.set(key, action.payload.blacklistCheck);
            } else {
                return state;
            }
        }
        case actions.ReceivePersonJobScore: {
            const key = `${action.payload.personId}-${action.payload.jobId}`;
            return state.set(key, action.payload.data.blacklistCheck);
        }
        case actions.ReceivePersonSetBlacklisted: {
            const keysForThisPerson = Array.from(state.keys()).filter((k) =>
                k.startsWith(`${action.payload.person.id}-`)
            );
            let newState = state;
            for (const key of keysForThisPerson) {
                const blacklistCheck = newState.get(key);
                const newBlackListCheck = Object.assign({}, blacklistCheck, {
                    profileBlacklisted: !!action.payload.person.blacklisted
                });
                newState = newState.set(key, newBlackListCheck);
            }
            return newState;
        }
        case actions.ReceiveUserBlacklistPerson: {
            const keysForThisPerson = Array.from(state.keys()).filter((k) =>
                k.startsWith(`${action.payload.personId}-`)
            );
            let newState = state;
            for (const key of keysForThisPerson) {
                const blacklistCheck = newState.get(key);
                const newBlackListCheck = Object.assign({}, blacklistCheck, {
                    userBlacklisted: action.payload.userBlacklisted
                });
                newState = newState.set(key, newBlackListCheck);
            }
            return newState;
        }
        default:
            return state;
    }
}
