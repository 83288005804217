import { css } from '@emotion/core';
import { Slider } from '@material-ui/core';
import chroma from 'chroma-js';
import React from 'react';

interface MetricSliderProps {
    value: number;
    min: number;
    max: number;
    goodThreshold: number; // Threshold where values transition to 'good' (green)
    badThreshold: number; // Threshold where values transition to 'bad' (red)
}

const styles = css`
    height: 5px;
    padding: 10px 0;

    .MuiSlider-rail,
    .MuiSlider-track {
        height: 5px;
        border-radius: 3px;
    }
    .MuiSlider-thumb {
        height: 12px;
        width: 12px;
        margin-top: -4px;
        margin-left: -7px;
    }
`;

export const MetricDisplay: React.FC<MetricSliderProps> = ({ value, min, max, goodThreshold, badThreshold }) => {
    // Determine if higher or lower values are better
    const isHigherBetter = goodThreshold > badThreshold;

    // Create a dynamic color scale using chroma.js
    const colorScale = chroma
        .scale(['red', 'orange', 'green'])
        .domain(
            isHigherBetter
                ? [badThreshold, (badThreshold + goodThreshold) / 2, goodThreshold]
                : [goodThreshold, (badThreshold + goodThreshold) / 2, badThreshold]
        );

    // Get the slider color dynamically from chroma.js
    const color = colorScale(value).hex();

    return (
        <Slider
            value={value}
            min={min}
            max={max}
            valueLabelDisplay="auto"
            disabled={true} // Disable interaction, since this is for display purposes
            style={{ color }}
            css={styles}
        />
    );
};
