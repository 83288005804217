// Function to load an external JS library
export const loadExternalJS = (id: string, url: string, onload: () => void) => {
    const doc = document;
    if (doc.getElementById(id)) {
        return;
    }
    const scriptElement = doc.getElementsByTagName('script')[0];
    const js = doc.createElement<'script'>('script');
    js.id = id;
    js.onload = onload;
    js.src = url;
    scriptElement.parentNode.insertBefore(js, scriptElement);
};
