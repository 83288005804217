import { Map, OrderedMap } from 'immutable';
import * as _ from 'lodash';
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';

import { NoteView } from 'shared/models/note';

import { fetchJobInfo } from '../actions';
import { JobPageHeader } from '../components/job-page-header';
import { Client, Job, List, State } from '../state';
import { Notes } from './notes';

interface OwnProps {
    jobId: string;
}

interface ConnectedProps {
    clients: List<Client>;
    jobs: OrderedMap<string, Job>;
}

interface ConnectedDispatch {
    fetchJobInfo: (id: string) => void;
}

type JobNotesContainerComponentProps = OwnProps & ConnectedDispatch & ConnectedProps;

interface JobNotesState {
    jobNotesOnly: boolean;
}

const jobFilterTabOpts: Array<[boolean, string]> = [
    [false, 'All Client Jobs'],
    [true, 'Current Job']
];

class JobNotesContainerComponent extends React.Component<JobNotesContainerComponentProps, JobNotesState> {
    constructor(props: JobNotesContainerComponentProps) {
        super(props);
        this.ensureDataExists(props);
        this.state = { jobNotesOnly: true };
    }

    ensureDataExists(props: JobNotesContainerComponentProps) {
        const { jobId, jobs, clients } = props;
        if (!jobs || !jobs.get(jobId) || !clients.list.get(jobs.get(jobId).clientId)) {
            this.props.fetchJobInfo(jobId);
        }
    }

    componentDidUpdate() {
        this.ensureDataExists(this.props);
    }

    handleUpdateJobSpecific = (jobNotesOnly: boolean) => () => {
        this.setState({ jobNotesOnly });
    };

    noteFilter = (note: NoteView) => {
        return !this.state.jobNotesOnly || note.context.jobId === this.props.jobId || !note.context.jobId;
    };

    render() {
        const { jobId, jobs, clients } = this.props;
        const { jobNotesOnly } = this.state;

        const jobFilterTabs = jobFilterTabOpts.map(([jobSpecific, label]) => (
            <div
                key={jobSpecific.toString()}
                className={`notes-tab ${jobNotesOnly === jobSpecific ? 'active' : ''}`}
                onClick={this.handleUpdateJobSpecific(jobSpecific)}
            >
                {label}
            </div>
        ));

        const tabs = (
            <div className="notes-tabs">
                <div />
                <div className="notes-tabs-right">{jobFilterTabs}</div>
            </div>
        );

        const job = jobs.get(jobId);
        if (job) {
            const client = clients.list.get(job.clientId);
            const templateLabels = Map({
                kickoff: 'Start kickoff note',
                weekly_sync: 'Start weekly sync note'
            });
            return (
                <DocumentTitle title={`Job Notes - ${client.name} - ${job.title}`}>
                    <div id="container">
                        <JobPageHeader jobId={jobId} actions={[]} activeTab="Notes" />
                        <div id="content" className="flex-fill">
                            <div className="job-notes">
                                <div className="job-notes-section">
                                    {tabs}
                                    <Notes
                                        notable={`clients-${client.id}`}
                                        context={{ jobId }}
                                        noteDraftKey={`jobs-${jobId}`}
                                        startingTemplates={[]}
                                        templateLabels={templateLabels}
                                        notesFilter={this.noteFilter}
                                        noteFormContext={jobNotesOnly ? { jobId } : {}}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </DocumentTitle>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    clients: state.clients,
    jobs: state.jobs
});
const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    fetchJobInfo
};
export const JobNotesContainer = connect<ConnectedProps, ConnectedProps, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(JobNotesContainerComponent);
