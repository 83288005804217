import { Button } from '@material-ui/core';
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';

import { hasRole } from 'shared/models/user';

import { Header } from '../components/header';
import { ClientsTable } from '../containers/clients-table';
import { useSession } from '../hooks/use-session';

export const ClientsPage = () => {
    const { userPermissions } = useSession();
    const createClientButton = hasRole(userPermissions, 'client_creator') ? (
        <Button style={{ color: 'white', marginRight: '15px' }} key="add-client" to="/client/new" component={Link}>
            Create New Client
        </Button>
    ) : null;
    return (
        <DocumentTitle title="Clients">
            <div id="container">
                <Header title="Clients" actions={[createClientButton]} />
                <div id="content" className="flex-fill">
                    <ClientsTable />
                </div>
            </div>
        </DocumentTitle>
    );
};
