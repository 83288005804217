import { Map } from 'immutable';
import {
    Action,
    ReceiveAllEmailAccountInfo,
    ReceiveEmailAccountCreated,
    ReceiveReassignEmailAccounts,
    RequestReassignEmailAccount
} from '../actions';
import { getMapFromArray } from '../common/lang/immutable-utils';
import { EmailAccount } from '../state';

const initialState: Map<string, EmailAccount> = Map();

export function emailAccounts(state = initialState, action: Action): Map<string, EmailAccount> {
    switch (action.type) {
        case ReceiveAllEmailAccountInfo:
            return getMapFromArray(action.payload.emailAccounts, 'email');
        case RequestReassignEmailAccount:
            return state.set(
                action.payload.email,
                Object.assign({}, state.get(action.payload.email), action.payload.update)
            );
        case ReceiveReassignEmailAccounts: {
            let updatedState = state;
            for (const emailAccount of action.payload.emailAccounts) {
                updatedState = updatedState.set(emailAccount.email, emailAccount);
            }
            return updatedState;
        }
        case ReceiveEmailAccountCreated: {
            let updated = state;
            for (const account of action.payload.emails) {
                updated = updated.set(account.email, account);
            }
            return updated;
        }
        default:
            return state;
    }
}
