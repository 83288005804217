// cspell:disable
export const searchLocations = [
    'Albany, New York Area',
    'Albuquerque, New Mexico Area',
    'Asheville, North Carolina Area',
    'Auburn, Alabama Area',
    'Austin, Texas Area',
    'Baton Rouge, Louisiana Area',
    'Bellingham, Washington Area',
    'Birmingham, Alabama Area',
    'Bloomington, Illinois Area',
    'Bloomington, Indiana Area',
    'Boise, Idaho Area',
    'Buffalo/Niagara, New York Area',
    'Burlington, Vermont Area',
    'Charleston, South Carolina Area',
    'Charlotte, North Carolina Area',
    'Charlottesville, Virginia Area',
    'Chattanooga, Tennessee Area',
    'Chico, California Area',
    'Cincinnati, Ohio Area',
    'Cleveland/Akron, Ohio Area',
    'Colorado Springs, Colorado Area',
    'Columbia, Missouri Area',
    'Columbia, South Carolina Area',
    'Columbus, Ohio Area',
    'Corvallis, Oregon Area',
    'Dallas/Fort Worth, Texas Area',
    'Davenport, Iowa Area',
    'Dayton, Ohio Area',
    'Daytona Beach, Florida Area',
    'Des Moines, Iowa Area',
    'El Paso, Texas Area',
    'Erie, Pennsylvania Area',
    'Eugene, Oregon Area',
    'Evansville, Indiana Area',
    'Fayetteville, Arkansas Area',
    'Flagstaff, Arizona Area',
    'Fort Collins, Colorado Area',
    'Fort Myers, Florida Area',
    'Fort Pierce, Florida Area',
    'Fort Wayne, Indiana Area',
    'Fresno, California Area',
    'Gainesville, Florida Area',
    'Greater Atlanta, Georgia Area',
    'Greater Boston, Massachusetts Area',
    'Greater Calgary, Canada Metropolitan Area',
    'Greater Chicago, Illinois Area',
    'Greater Denver, Colorado Area',
    'Greater Detroit, Michigan Area',
    'Greater Edmonton, Canada Metropolitan Area',
    'Greater Gatineau (Ottawa Extended), Canada Metropolitan Area',
    'Greater Grand Rapids, Michigan Area',
    'Greater Halifax, Canada Metropolitan Area',
    'Greater Hamilton (Burlington), Canada Metropolitan Area',
    'Greater Kingston, Canada Metropolitan Area',
    'Greater Kitchener-Cambridge-Waterloo, Canada Metropolitan Area',
    'Greater London, Canada Metropolitan Area',
    'Greater Los Angeles, California Area',
    'Greater Memphis, Tennessee Area',
    'Greater Milwaukee, Wisconsin Area',
    'Greater Minneapolis-St. Paul, Minnesota Area',
    'Greater Montreal, Canada Metropolitan Area',
    'Greater Montreal, Canada Metropolitan Area',
    'Greater Nashville, Tennessee Area',
    'Greater New Orleans, Louisiana Area',
    'Greater New York City, New York Area',
    'Greater Omaha, Nebraska Area',
    'Greater Ottawa, Canada Metropolitan Area',
    'Greater Philadelphia, Pennsylvania Area',
    'Greater Pittsburgh, Pennsylvania Area',
    'Greater Quebec City, Canada Metropolitan Area',
    'Greater Salt Lake City, Utah Area',
    'Greater San Diego, California Area',
    'Greater Seattle, Washington Area',
    'Greater St. Louis, Missouri Area',
    'Greater Sudbury, Canada Metropolitan Area',
    'Greater Toronto, Canada Metropolitan Area',
    'Greater Toronto (Extended), Canada Metropolitan Area',
    'Greater Vancouver, Canada Metropolitan Area',
    'Greater Victoria, Canada Metropolitan Area',
    'Greater Winnipeg, Canada Metropolitan Area',
    'Green Bay, Wisconsin Area',
    'Greensboro/Winston-Salem, North Carolina Area',
    'Greenville, South Carolina Area',
    'Harrisburg, Pennsylvania Area',
    'Hartford, Connecticut Area',
    'Hawaii',
    'Hawaii, Hawaiian Island',
    'Houston, Texas Area',
    'Huntsville, Alabama Area',
    'Indianapolis, Indiana Area',
    'Iowa City, Iowa Area',
    'Ithaca, New York Area',
    'Jackson, Mississippi Area',
    'Jacksonville, Florida Area',
    'Johnson City, Tennessee Area',
    'Kalamazoo, Michigan Area',
    'Kansas City, Missouri Area',
    'Kauai, Hawaiian Island',
    'Knoxville, Tennessee Area',
    'Lafayette, Indiana Area',
    'Lakeland, Florida Area',
    'Lancaster, Pennsylvania Area',
    'Lansing, Michigan Area',
    'Las Vegas, Nevada Area',
    'Lawrence, Kansas Area',
    'Lexington, Kentucky Area',
    'Lincoln, Nebraska Area',
    'Little Rock, Arkansas Area',
    'Louisville, Kentucky Area',
    'Lubbock, Texas Area',
    'Madison, Wisconsin Area',
    'Major Canadian Cities',
    'Maui, Hawaiian Island',
    'Melbourne, Florida Area',
    'Miami/Fort Lauderdale, Florida Area',
    'Mobile, Alabama Area',
    'New London/Norwich, Connecticut Area',
    'Norfolk, Virginia Area',
    'Oahu, Hawaiian Island',
    'Oklahoma City, Oklahoma Area',
    'Orlando, Florida Area',
    'Oshkosh, Wisconsin Area',
    'Pensacola, Florida Area',
    'Peoria, Illinois Area',
    'Phoenix, Arizona Area',
    'Portland, Maine Area',
    'Portland, Oregon Area',
    'Providence, Rhode Island Area',
    'Provo, Utah Area',
    'Raleigh-Durham, North Carolina Area',
    'Reno, Nevada Area',
    'Richmond, Virginia Area',
    'Roanoke, Virginia Area',
    'Rochester, New York Area',
    'Rockford, Illinois Area',
    'Sacramento, California Area',
    'Saginaw, Michigan Area',
    'Salinas, California Area',
    'San Antonio, Texas Area',
    'San Francisco Bay, California Area',
    'San Francisco Bay, California Area Generic',
    'Santa Barbara, California Area',
    'Sarasota, Florida Area',
    'Savannah, Georgia Area',
    'Scranton, Pennsylvania Area',
    'SFBA - BART STOPS',
    'SFBA - CALTRAIN',
    'SFBA - City',
    'SFBA - East Bay',
    'SFBA - North Bay',
    'SFBA - Peninsula',
    'SFBA - San Jose',
    'Sioux Falls, South Dakota Area',
    'Spokane, Washington Area',
    'Springfield, Massachusetts Area',
    'Springfield, Missouri Area',
    'State College, Pennsylvania Area',
    'Stockton, California Area',
    'Syracuse, New York Area',
    'Tallahassee, Florida Area',
    'Tampa/St. Petersburg, Florida Area',
    'Toledo, Ohio Area',
    'Tucson, Arizona Area',
    'Tulsa, Oklahoma Area',
    'Tuscaloosa, Alabama Area',
    'Urbana-Champaign, Illinois Area',
    'Washington D.C. Area',
    'West Palm Beach, Florida Area',
    'Wichita, Kansas Area',
    'Wilmington, North Carolina Area'
];
