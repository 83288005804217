import { IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { black } from 'material-ui/styles/colors';
import { ContentArchive } from 'material-ui/svg-icons';
import React from 'react';

import { hasJobPermission } from 'shared/models/job';

import { useSession } from '../hooks/use-session';
import { Communication, Job } from '../state';
import { CommunicationReplyButton } from './communication-reply-button';

interface PersonCommunicationActionsProps {
    data: Communication;
    disabled: boolean;
    handleConvertEmailToNote: (data: Communication) => void;
    handleReportIncorrectCandidate: (data: Communication) => void;
    handleReplyButton: (data: Communication) => void;
    handleArchiveButton: (data: Communication) => void;
    job: Job;
}

export const PersonCommunicationActions: React.FC<PersonCommunicationActionsProps> = ({
    data,
    disabled,
    job,
    handleConvertEmailToNote,
    handleReportIncorrectCandidate,
    handleReplyButton,
    handleArchiveButton
}) => {
    const { user } = useSession();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const addAsNoteToJob = () => {
        handleConvertEmailToNote(data);
        setAnchorEl(null);
    };

    const handleReportCandidate = () => {
        handleReportIncorrectCandidate(data);
        setAnchorEl(null);
    };

    const handleReply = () => {
        handleReplyButton(data);
    };

    const handleArchive = () => {
        handleArchiveButton(data);
    };

    const inInbox = data.labels.indexOf('INBOX') !== -1;

    const inInboxNotif = inInbox ? <div key="inbox-notification" className="email-in-inbox-icon email-action" /> : null;

    const hasReplyToClientEmailsPermission = hasJobPermission(job?.recruiterPermissions.replyToClientEmails, user.id);

    const replyIcon = job ? (
        <CommunicationReplyButton key="reply-button" communication={data} onReply={handleReply} />
    ) : null;

    const archiveIconDisabled = !inInbox || (!hasReplyToClientEmailsPermission && data.isClientComm);
    const archiveTooltip = !inInbox
        ? 'Already archived'
        : !hasReplyToClientEmailsPermission && data.isClientComm
          ? 'Contact AM to archive'
          : 'Archive message';
    const archiveButton = archiveIconDisabled ? (
        <div className={`email-action-icon email-action disabled`}>
            <ContentArchive color={black} />
        </div>
    ) : (
        <div className={`email-action-icon email-action`} onClick={handleArchive}>
            <ContentArchive color={black} />
        </div>
    );
    const archiveIcon = (
        <Tooltip key="archive-icon" title={archiveTooltip}>
            {archiveButton}
        </Tooltip>
    );

    const actions = [inInboxNotif, replyIcon, archiveIcon];

    return (
        <>
            {actions}

            <div className="email-action-icon email-action">
                <IconButton aria-controls="pca-menu" onClick={handleClick}>
                    <MoreVert style={{ color: 'black' }} />
                </IconButton>
                <Menu
                    id="pca-menu"
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorEl={anchorEl}
                    keepMounted={true}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem key="1" onClick={addAsNoteToJob} disabled={disabled}>
                        Add as note to job
                    </MenuItem>

                    <MenuItem key="2" onClick={handleReportCandidate}>
                        Report Incorrect Candidate
                    </MenuItem>
                </Menu>
            </div>
        </>
    );
};
