import { useQuery, useSubscription } from '@apollo/client';
import * as React from 'react';

import { hasRole } from 'shared/models/user';

import { FlaggedEmails } from '../containers/flagged-emails';
import { ALL_FLAGGED_EMAIL_ACCOUNTS, FlaggedEmailAccount } from '../graphql/queries/home';
import { MATERIALIZED_VIEW_REFRESH, RefreshesData } from '../graphql/queries/view_refreshes';
import { useSession } from '../hooks/use-session';

export const FlaggedEmailsPage: React.FC<{}> = () => {
    const { userPermissions } = useSession();
    if (!hasRole(userPermissions, 'email_match_setter')) {
        return null;
    }
    const { data: refreshData } = useSubscription<RefreshesData, { name: string }>(MATERIALIZED_VIEW_REFRESH, {
        variables: { name: 'flagged_email_accounts' }
    });
    const { data, refetch, loading } = useQuery<{ accounts: FlaggedEmailAccount[] }>(ALL_FLAGGED_EMAIL_ACCOUNTS);

    React.useEffect(() => {
        if (!loading) {
            refetch();
        }
    }, [refreshData?.refreshes.lastRefreshAt]);

    return <FlaggedEmails accounts={data?.accounts} />;
};
