import * as _ from 'lodash';
import { Menu, MenuItem, Popover } from 'material-ui';
import * as moment from 'moment';
import * as React from 'react';

import { hours } from '../common/email-sending-times';
import { DayPicker } from '../core-ui/react-day-picker';

const timeFormat = 'h:mm A';
const timePickerMaxHeight = 240;

interface DefaultProps {
    getNearestValidTime: (value: number) => number;
    getDisabledHours: (value: number) => number[];
    isDayDisabled: (day: Date) => boolean;
    minuteStep: number;
}

interface DateTimePickerProps extends Partial<DefaultProps> {
    value: number;
    onChange: (ts: number) => void;
    minDate?: number;
    maxDate?: number;
}

interface DateTimePickerState {
    timeSelectorOpen: boolean;
    timeSelectorAnchor: any;
}

export class DateTimePicker extends React.Component<DateTimePickerProps, DateTimePickerState> {
    static defaultProps: DefaultProps = {
        getDisabledHours: (_1: number) => [],
        getNearestValidTime: (value: number) => value,
        isDayDisabled: (_1: Date) => false,
        minuteStep: 30
    };

    constructor(props: DateTimePickerProps) {
        super(props);
        this.state = { timeSelectorOpen: false, timeSelectorAnchor: null };
    }

    componentDidMount() {
        const { value, onChange, getNearestValidTime } = this.props;
        const ts = getNearestValidTime(value);
        if (ts !== value) {
            onChange(ts);
        }
    }

    handleDayChange = (date: Date) => {
        const { isDayDisabled } = this.props;
        if (!isDayDisabled(date)) {
            const { value } = this.props;
            const timeOffset = moment(value).diff(moment(value).startOf('day'));
            const newValue = moment(date).startOf('day').add(timeOffset);
            this.updateValue(newValue.valueOf());
        }
    };

    handleTimeChange = (offset: number) => () => {
        const { value } = this.props;
        const newValue = moment(value).startOf('day').add(offset);
        this.updateValue(newValue.valueOf());
        this.setState({ timeSelectorOpen: false });
    };

    minuteOffsets = () => {
        const offsets = [];
        let offset = 0;
        // tslint:disable-next-line:no-magic-numbers
        while (offset < 60) {
            offsets.push(offset);
            offset += this.props.minuteStep;
        }
        return offsets;
    };

    getTimeOptions = () => {
        const { value, getDisabledHours } = this.props;
        const day = moment(value).startOf('day');
        const validHours = _.difference(hours, getDisabledHours(value));
        const options: number[] = [];
        const minuteOffsets = this.minuteOffsets();
        for (const hour of validHours) {
            for (const minutes of minuteOffsets) {
                const time = moment(day).add(hour, 'hours').add(minutes, 'minutes').diff(day);
                options.push(time);
            }
        }
        return options;
    };

    updateValue = (value: number) => {
        const { onChange, minDate, maxDate, getNearestValidTime } = this.props;
        if (minDate && value < minDate) {
            const ts = getNearestValidTime(minDate);
            onChange(ts);
        } else if (maxDate && value > maxDate) {
            onChange(maxDate);
        } else {
            onChange(value);
        }
    };

    handleTimeClick = (e: React.MouseEvent) => {
        this.setState({ timeSelectorOpen: true, timeSelectorAnchor: e.currentTarget });
    };

    handleTimeSelectorClose = () => {
        this.setState({ timeSelectorOpen: false });
    };

    render() {
        const { value, isDayDisabled } = this.props;
        const { timeSelectorOpen, timeSelectorAnchor } = this.state;
        const date = new Date(value);
        const timeValue = moment(value).diff(moment(value).startOf('day'));
        const timeDisplay = moment(value).format(timeFormat);
        const timeOptions = this.getTimeOptions().map((offset) => {
            const label = moment(value).startOf('day').add(offset).format(timeFormat);
            return (
                <MenuItem
                    value={offset}
                    primaryText={label}
                    key={offset}
                    checked={timeValue === offset}
                    insetChildren={true}
                    onClick={this.handleTimeChange(offset)}
                    style={timeValue === offset ? { fontWeight: 'bold' } : {}}
                />
            );
        });
        return (
            <div className="date-time-picker">
                <div className="date-time-picker-title">
                    <div className="date-time-picker-title-year">{moment(value).format('YYYY')}</div>
                    <div className="date-time-picker-title-date">{moment(value).format('ddd, MMM DD h:mm A')}</div>
                </div>
                <div className="date-picker">
                    <DayPicker onDayClick={this.handleDayChange} selected={date} disabled={[isDayDisabled]} />
                </div>
                <div className="time-picker">
                    <div className="time-picker-value" onClick={this.handleTimeClick}>
                        {timeDisplay}
                    </div>
                    <Popover
                        open={timeSelectorOpen}
                        anchorEl={timeSelectorAnchor}
                        anchorOrigin={{ horizontal: 'middle', vertical: 'center' }}
                        targetOrigin={{ horizontal: 'middle', vertical: 'center' }}
                        onRequestClose={this.handleTimeSelectorClose}
                    >
                        <Menu desktop={true} maxHeight={timePickerMaxHeight}>
                            {timeOptions}
                        </Menu>
                    </Popover>
                </div>
            </div>
        );
    }
}
