import * as React from 'react';
import * as ReactDOM from 'react-dom';

interface RenderProps {
    children?: JSX.Element;
}
export class RenderAppendedToBody extends React.Component<RenderProps> {
    private popup: any;

    constructor(props: RenderProps) {
        super(props);
        this.popup = document.createElement('div');
        document.body.appendChild(this.popup);
    }

    render() {
        if (this.popup) {
            return ReactDOM.createPortal(this.props.children, this.popup);
        }
        return null;
    }
}
