import { upperCase } from 'lodash';
import { Avatar, Popover } from 'material-ui';
import * as React from 'react';

import { EmailAddress } from 'shared/types/email-compose';
import { CommunicationHeaderDetails } from './communication-header-details';

interface CommunicationHeadersProps {
    to: EmailAddress[];
    bcc: EmailAddress[];
    cc: EmailAddress[];
    from: EmailAddress;
    jobIds: string[];
    account: EmailAddress;
    subject: string;
    date: number;
}

const avatarSize = 30;

export const CommunicationHeaders: React.FC<CommunicationHeadersProps> = (props) => {
    const { from, to, cc, bcc } = props;
    const [headersAnchorRef, setHeadersAnchorRef] = React.useState<React.ReactInstance>(null);
    const [headersOpen, setHeadersOpen] = React.useState(false);

    const getAddressee = (e: EmailAddress) => {
        return e.name ? e.name : e.address;
    };

    const initials = getAddressee(from)
        .split(' ')
        .map((s) => upperCase(s.charAt(0)));
    // tslint:disable-next-line:no-magic-numbers
    const avatarText = initials.length > 2 ? `${initials[0]}${initials[initials.length - 1]}` : initials;

    const handleDisplayHeaders = (event: React.MouseEvent) => {
        setHeadersAnchorRef(event.target as React.ReactInstance);
        setHeadersOpen(true);
    };

    const handleHideHeaders = () => {
        setHeadersOpen(false);
    };

    const recipients = to.concat(cc).concat(bcc).map(getAddressee).join(', ');

    return (
        <div className="header-left" onClick={handleDisplayHeaders}>
            <Avatar size={avatarSize}>{avatarText}</Avatar>
            <div className="sender">{from.name}</div>
            <div className="recipients">to {recipients}</div>
            <Popover
                open={headersOpen}
                onRequestClose={handleHideHeaders}
                anchorEl={headersAnchorRef}
                anchorOrigin={{ vertical: 'center', horizontal: 'middle' }}
                targetOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                <CommunicationHeaderDetails
                    jobIds={props.jobIds}
                    account={props.account}
                    from={props.from}
                    to={props.to}
                    cc={props.cc}
                    bcc={props.bcc}
                    subject={props.subject}
                    date={props.date}
                />
            </Popover>
        </div>
    );
};
