import { Map } from 'immutable';

import { Action, ReceiveClientWeeklyUpdateData } from '../actions';
import { ClientUpdateData } from '../state';

const initialState: Map<string, ClientUpdateData> = Map();

export function clientWeeklyUpdates(state = initialState, action: Action) {
    switch (action.type) {
        case ReceiveClientWeeklyUpdateData:
            return state.set(action.payload.data.client.id, action.payload.data);
        default:
            return state;
    }
}
