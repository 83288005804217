import { timeMonthYearUTC } from './timestamp';

export const standardizeDate = (d: number) => {
    if (d === 0) return 'Current';
    if (!d) return '';
    return timeMonthYearUTC(d);
};

export const sortByPeriod = <T extends { end: number }>(a: T, b: T): number => {
    const val1 = !a.end ? Number.MAX_VALUE : a.end;
    const val2 = !b.end ? Number.MAX_VALUE : b.end;
    return val2 - val1;
};

export function sleep(delayMs: number) {
    return new Promise((resolve) => {
        setTimeout(resolve, delayMs);
    });
}

export const queryString = (params: any) => {
    return Object.keys(params)
        .filter((k) => params[k] !== undefined)
        .map((k) => {
            if (Array.isArray(params[k])) {
                return params[k].map((v: any) => `${k}=${encodeURIComponent(v)}`).join('&');
            } else {
                return `${k}=${encodeURIComponent(params[k])}`;
            }
        })
        .join('&');
};

export const humanReadableSize = (sizeInBytes: number) => {
    let i = -1;
    const byteUnits = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const threshold = 1024;
    do {
        sizeInBytes = sizeInBytes / threshold;
        i += 1;
    } while (sizeInBytes > threshold);

    return `${Math.max(sizeInBytes, 0.1).toFixed(1)} ${byteUnits[i]}`;
};

export function isValidUrl(str: string) {
    try {
        // tslint:disable-next-line: no-unused-expression
        new URL(str);
        return true;
    } catch (_) {
        return false;
    }
}

export function isHtml(text: string): boolean {
    // Check for common HTML tags
    const htmlPattern = /<([a-z]+).*?>.*?<\/\1>/i;

    // Check for self-closing tags
    const selfClosingPattern = /<([a-z]+).*?\/>/i;

    // Check for HTML entities
    const entityPattern = /&[a-z]+;|&#\d+;/i;

    // Check if the text starts with <!DOCTYPE html>
    const doctypePattern = /^\s*<!DOCTYPE\s+html.*?>/i;

    if (
        htmlPattern.test(text) ||
        selfClosingPattern.test(text) ||
        entityPattern.test(text) ||
        doctypePattern.test(text)
    ) {
        return true;
    } else {
        return false;
    }
}
