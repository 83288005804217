import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { SourcerSearches } from '../components/sourcer-searches';

interface RouterProps {
    id: string;
}

const SourcerSearchesPageComponent: React.SFC<RouteComponentProps<RouterProps>> = ({ match }) => (
    <SourcerSearches id={match.params.id} />
);

export const SourcerSearchesPage = withRouter(SourcerSearchesPageComponent);
