import * as React from 'react';
import DocumentTitle from 'react-document-title';

import { Header } from '../components/header';
import { Analytics } from '../sfc/analytics';

export const AnalyticsPage = () => (
    <DocumentTitle title="Stats Reports">
        <div id="container">
            <Header title="Stats Reports" />
            <div id="content" className="flex-fill">
                <Analytics />
            </div>
        </div>
    </DocumentTitle>
);
