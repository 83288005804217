import { gql } from '@apollo/client';

import { InterviewKind } from 'shared/models/interview';

export interface Interview {
    id: string;
    startTime: number;
    endTime: number;
    kind: InterviewKind;
    cancellationLink: string;
    reschedulingLink: string;
    job: {
        id: string;
        title: string;
        client: {
            id: string;
            name: string;
        };
    };
    candidate: {
        assignee: string;
        accountManagerId: string;
    };
}

export interface Interviews {
    interviews: Interview[];
    candidate: {
        stage: string;
        assignee: string;
        accountManagerId: string;
    };
    job: {
        id: string;
        title: string;
        client: {
            id: string;
            name: string;
        };
    };
}

export const INTERVIEWS = gql`
    query Interviews($personId: String!, $jobId: String!) {
        interviews(
            where: { personId: { _eq: $personId } }
            order_by: [{ startTime: asc }, { job: { client: { name: asc } } }, { job: { title: asc } }]
        ) {
            id
            startTime
            endTime
            kind
            cancellationLink
            reschedulingLink
            job {
                id
                title
                client {
                    id
                    name
                }
            }
            candidate {
                assignee
                accountManagerId
            }
        }
        candidate: candidates_by_pk(personId: $personId, jobId: $jobId) {
            _id
            stage
            assignee
            accountManagerId
        }
        job: jobs_by_pk(id: $jobId) {
            id
            title
            client {
                id
                name
            }
        }
    }
`;

export const INTERVIEWS_LATEST_MODIFICATION = gql`
    subscription InterviewsLatestModification($personId: String!) {
        interviews(where: { personId: { _eq: $personId } }, order_by: { modifiedAt: desc }, limit: 1) {
            modifiedAt
        }
    }
`;

export const DELETE_INTERVIEW = gql`
    mutation DeleteInterview($id: String!) {
        delete_interviews_by_pk(id: $id) {
            id
        }
    }
`;

export const CREATE_INTERVIEW = gql`
    mutation CreateInterview($interview: interviews_insert_input!) {
        insert_interviews_one(object: $interview) {
            id
        }
    }
`;

export const UPDATE_INTERVIEW = gql`
    mutation UpdateInterview($id: String!, $interview: interviews_set_input!) {
        update_interviews_by_pk(pk_columns: { id: $id }, _set: $interview) {
            id
        }
    }
`;
