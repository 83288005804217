import * as React from 'react';

import { UserData } from 'shared/models/user';

import { withUser } from '../hocs/with-user';
import { Avatar } from './avatar';

const UserAvatarComponent: React.SFC<{ user: UserData; size: number }> = ({ user, size }) => (
    <Avatar entity={user} size={size} />
);

export const UserAvatar = withUser<{ size: number }>(UserAvatarComponent);
