import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ClientSettings } from '../components/client-settings';

interface RouterProps {
    id: string;
}

const ClientSettingsPageComponent: React.SFC<RouteComponentProps<RouterProps>> = ({ match }) => (
    <ClientSettings clientId={match.params.id} />
);

export const ClientSettingsPage = withRouter(ClientSettingsPageComponent);
