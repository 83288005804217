import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Company, COMPANY_QUERY } from '../graphql/queries/companies';

const minInputLengthForSearch = 3;

export const useCompanyNames = (substring: string) => {
    // Define the state and hooks for fetching company names
    const [companyNames, setCompanyNames] = useState<Company[]>([]);
    const [fetchCompanies, { data, loading, error }] = useLazyQuery<{ companies: Company[] }, { search: string }>(
        COMPANY_QUERY
    );

    // Fetch company names when the prefix changes
    useEffect(() => {
        if (substring?.length >= minInputLengthForSearch) {
            fetchCompanies({
                variables: {
                    search: substring
                }
            });
        } else {
            setCompanyNames([]);
        }
    }, [fetchCompanies, substring]);

    // Update the state when new data is fetched
    useEffect(() => {
        if (data && data.companies) {
            setCompanyNames(data.companies);
        }
    }, [data]);

    return {
        companyNames,
        error,
        loading
    };
};
