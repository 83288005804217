import { useLazyQuery } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Menu,
    MenuItem,
    Theme,
    Tooltip,
    Typography,
    useTheme
} from '@material-ui/core';
import { PersonAdd } from '@material-ui/icons';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { addPersonAndCreateCandidate } from '../api';

import { JOB_CP_CANDIDATES, JobCPCandidate } from '../graphql/queries/job';
import { useModal } from '../hooks/use-modal';
import { useSnackbar } from '../hooks/use-snackbar';
import { AddCandidateWithoutLinkedin } from './add-candidate-without-linkedin-form';
import { JobCPCandidatesTable } from './job-cp-candidates-table';

const styles = (theme: Theme) => css`
    .MuiDialogContent-root {
        padding: 0;
        border-top: thin solid ${theme.palette.divider};
        border-bottom: thin solid ${theme.palette.divider};
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }

    .MuiTableRow-root {
        .MuiTableCell-root {
            &:first-of-type {
                padding-left: 28px;
            }

            &:last-of-type {
                padding-right: 28px;
            }
        }

        &:last-of-type {
            .MuiTableCell-root.MuiTableCell-body {
                border-bottom: 0;
            }
        }
    }
`;

export const JobAddCandidates: React.FC<{ jobId: string }> = ({ jobId }) => {
    const theme = useTheme();
    const [fetchCPCandidates, { data, loading, refetch }] = useLazyQuery<
        { job: { cpCandidates: JobCPCandidate[] } },
        { jobId: string }
    >(JOB_CP_CANDIDATES, { variables: { jobId } });
    const [showAddByScoreDialog, setShowAddByScoreDialog] = React.useState(false);
    const [showAddWithoutLinkedinDialog, setShowAddWithoutLinkedinDialog] = React.useState(false);
    const { showLoading, hideLoading } = useModal();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { setSnackbar } = useSnackbar();
    const [redirectTo, setRedirectTo] = React.useState<string | null>(null);
    const [savingCandidate, setSavingCandidate] = React.useState(false);

    const handleButtonClick = (e: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(e.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleAddBasedOnScore = async () => {
        setAnchorEl(null);
        showLoading();
        await fetchCPCandidates();
        hideLoading();
        setShowAddByScoreDialog(true);
    };

    const hideDialog = () => {
        setShowAddByScoreDialog(false);
    };

    const handleAddWithoutLinkedin = () => {
        setAnchorEl(null);
        setShowAddWithoutLinkedinDialog(true);
    };

    const handleCloseAddWithoutLinkedinDialog = () => {
        setShowAddWithoutLinkedinDialog(false);
    };

    const handleAddCandidateWithoutLinkedin = async (candidateData: {
        firstName: string;
        lastName: string;
        email: string;
    }) => {
        setSavingCandidate(true);
        try {
            setSnackbar('Adding candidate...');
            const response = await addPersonAndCreateCandidate(jobId, candidateData);
            const personId = response.data.candidate.personId;
            setSnackbar('Candidate added');
            setRedirectTo(`/job/${jobId}/board/candidate/${personId}`);
            setShowAddWithoutLinkedinDialog(false);
        } catch {
            setSnackbar('Error saving candidate');
        } finally {
            setSavingCandidate(false);
        }
    };

    const button = (
        <Tooltip title="Add Candidates">
            <IconButton onClick={handleButtonClick} disabled={loading}>
                <PersonAdd htmlColor="white" />
            </IconButton>
        </Tooltip>
    );

    const addByScoreDialog = showAddByScoreDialog ? (
        <Dialog open={true} maxWidth="lg" css={styles(theme)} onClose={hideDialog} fullWidth={true}>
            <DialogTitle>
                <Typography variant="h4" component="div">
                    Cross Pitchable Candidates
                </Typography>
            </DialogTitle>
            <DialogContent>
                <JobCPCandidatesTable jobId={jobId} candidates={data.job.cpCandidates} refetch={refetch} />
            </DialogContent>
            <DialogActions>
                <Button onClick={hideDialog}>Close</Button>
            </DialogActions>
        </Dialog>
    ) : null;

    const addWithoutLinkedinDialog = showAddWithoutLinkedinDialog ? (
        <AddCandidateWithoutLinkedin
            onSave={handleAddCandidateWithoutLinkedin}
            onClose={handleCloseAddWithoutLinkedinDialog}
            disabled={savingCandidate}
        />
    ) : null;

    const menu = (
        <Menu anchorEl={anchorEl} keepMounted={true} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
            <MenuItem dense={true} onClick={handleAddBasedOnScore}>
                Candidates with high scores
            </MenuItem>
            <MenuItem dense={true} onClick={handleAddWithoutLinkedin}>
                Add candidate without LinkedIn profile
            </MenuItem>
        </Menu>
    );

    if (redirectTo) {
        return <Redirect to={redirectTo} />;
    }

    return (
        <>
            {button}
            {menu}
            {addByScoreDialog}
            {addWithoutLinkedinDialog}
        </>
    );
};
