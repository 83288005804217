import { RaisedButton } from 'material-ui';
import SettingsBackupRestore from 'material-ui/svg-icons/action/settings-backup-restore';
import * as React from 'react';
import { connect } from 'react-redux';

import { getDisqualReasonLabel } from 'shared/models/job-stages';

import { setCandidateQualification } from '../actions';
import { IconLabel } from '../core-ui/icon-label';
import { Candidate } from '../state';

interface OwnProps {
    candidate: Candidate;
}

interface ConnectedDispatch {
    setCandidateQualification: (candidate: Candidate, qualified: boolean, stage: string) => void;
}

type RequalifyButtonProps = OwnProps & ConnectedDispatch;

class RequalifyButtonComponent extends React.Component<RequalifyButtonProps> {
    handleRequalifyRequest = () => {
        this.props.setCandidateQualification(this.props.candidate, true, '');
    };
    render() {
        const { candidate } = this.props;
        return (
            <div className="candidate-disqual-info">
                <IconLabel icon="block">
                    <span className="candidate-disqual-reason">{getDisqualReasonLabel(candidate.disqualReason)}</span>
                </IconLabel>
                <RaisedButton
                    label="Re-qualify"
                    labelPosition="before"
                    icon={<SettingsBackupRestore />}
                    primary={true}
                    fullWidth={true}
                    className="candidate-move-button candidate-move-button-big"
                    onClick={this.handleRequalifyRequest}
                />
            </div>
        );
    }
}

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    setCandidateQualification
};

export const RequalifyButton = connect<undefined, ConnectedDispatch, OwnProps>(
    undefined,
    mapDispatchToProps
)(RequalifyButtonComponent);
