import { css } from '@emotion/core';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import * as React from 'react';

interface RadioButtonsProps<T> {
    name: string;
    selected: T;
    options: Array<{ value: T; label?: string }>;
    onChange: (val: T) => void;
    readonly: boolean;
}

const styles = css`
    display: flex;
    flex-direction: row;
    margin-top: -2px;

    .MuiFormControlLabel-label {
        font-size: 14px;
    }

    svg.MuiSvgIcon-root {
        font-size: 18px;
    }
`;

export function RadioButtons<T>({ selected, options, name, onChange, readonly }: RadioButtonsProps<T>) {
    const handleChange = (_1: any, val: string) => {
        onChange(options.find((o) => o.label === val)?.value ?? (val as any));
    };

    const buttons = options.map((o, i) => (
        <FormControlLabel
            key={i}
            value={o.label ?? o.value}
            control={<Radio disabled={readonly} />}
            label={o.label ?? o.value}
        />
    ));

    const selectedLabel = options.find((o) => o.value === selected)?.label ?? selected;

    return (
        <RadioGroup name={name} value={selectedLabel} onChange={handleChange} css={styles}>
            {buttons}
        </RadioGroup>
    );
}
