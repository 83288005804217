import { IconButton, Tooltip } from '@material-ui/core';
import { Delete, Edit, History, Message } from '@material-ui/icons';
import { Map, OrderedMap } from 'immutable';
import { Dialog, Paper } from 'material-ui';
import * as React from 'react';
import { connect } from 'react-redux';

import { NoteView } from 'shared/models/note';
import { Permissions } from 'shared/models/permission';
import { hasRole, UserData } from 'shared/models/user';

import { deleteNote, getConfirmation } from '../actions';
import { deleteDraft, getDraft } from '../common/draft-storage';
import { JobTitle } from '../containers/job-title';
import { Note as NoteFC } from '../sfc/note';
import { Job, List, NoteDraftState, PersonDetails, State } from '../state';
import { NoteEditor } from './note-editor';
import { NoteForm } from './note-form';
import { NoteMentionCheck } from './note-mention-check';
import { SubmissionNoteActions } from './submission-note-actions';

interface OwnProps {
    note: NoteView;
    context: { jobId?: string };
}

interface ConnectedProps {
    personDetails: List<PersonDetails>;
    jobs: OrderedMap<string, Job>;
    user: UserData;
    pendingRequests: Map<string, Map<string, string>>;
    userPermissions: Permissions;
}

interface ConnectedDispatch {
    deleteNote: (note: NoteView) => void;
    getConfirmation: (onConfirm: () => void, description?: string, title?: string) => void;
}

type NoteProps = OwnProps & ConnectedProps & ConnectedDispatch;

interface NoteState {
    showHistory: boolean;
    initialDraft: NoteDraftState;
    showReply: boolean;
}

class NoteComponent extends React.Component<NoteProps, NoteState> {
    constructor(props: NoteProps) {
        super(props);
        this.state = { showHistory: false, initialDraft: null, showReply: false };
    }

    componentDidMount() {
        getDraft<NoteDraftState>(this.noteDraftKey()).then((initialDraft) => {
            this.setState({ initialDraft });
        });
    }

    noteDraftKey = () => {
        return `${this.props.note.id}-note-draft`;
    };

    hasChanged = (content: string) => {
        return content !== this.props.note.content;
    };

    handleEditNote = () => {
        this.setState({
            initialDraft: {
                addingAttachment: false,
                content: this.props.note.content,
                context: this.props.note.context,
                initialAttachments: this.props.note.attachments,
                modifiedAt: Date.now(),
                saving: false
            }
        });
    };

    handleCloseEditor = () => {
        this.setState({ initialDraft: null });
        deleteDraft(this.noteDraftKey());
    };

    handleDeleteNote = () => {
        this.props.getConfirmation(
            () => {
                this.props.deleteNote(this.props.note);
            },
            'Are you sure you want to delete this note?',
            'Confirm'
        );
    };

    handleToggleHistory = () => {
        this.setState({ showHistory: !this.state.showHistory });
    };

    handleShowReplyForm = () => {
        this.setState({
            showReply: true
        });
    };

    handleHideReplyForm = () => {
        this.setState({
            showReply: false
        });
    };

    handleToggleReplyForm = () => {
        this.state.showReply ? this.handleHideReplyForm() : this.handleShowReplyForm();
    };

    render() {
        const { note, user, jobs, personDetails, context, pendingRequests, userPermissions } = this.props;

        const { initialDraft } = this.state;

        let content;
        let history;

        if (initialDraft) {
            content = (
                <NoteEditor
                    initialDraft={initialDraft}
                    context={note.context}
                    parentId={note.parentId}
                    noteDraftKey={this.noteDraftKey()}
                    notable={note.notable}
                    id={note.id}
                    hasChanged={this.hasChanged}
                    highlightErrors={note.context.isSubmissionNote}
                    postDiscard={this.handleCloseEditor}
                    postSave={this.handleCloseEditor}
                />
            );
        } else {
            const jobTitle = note.context.jobId ? (
                <div className="small2 secondary">
                    <JobTitle id={note.context.jobId} />
                </div>
            ) : null;

            const job = note.context.jobId ? jobs.get(note.context.jobId) : null;
            const notableParts = note.notable.split('-');
            const noteOptionItems = [];

            if (
                user.id === note.createdBy ||
                hasRole(userPermissions, 'notes_editor') ||
                (job &&
                    job.accountManagerId === user.id &&
                    (notableParts[0] === 'clients' || note.context.isSubmissionNote))
            ) {
                noteOptionItems.push(
                    <div key={'delete-note'} className="note-action">
                        <Tooltip title="Delete">
                            <IconButton onClick={this.handleDeleteNote} size="small">
                                <Delete fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </div>
                );
            }

            if (
                user.id === note.createdBy ||
                hasRole(userPermissions, 'notes_editor') ||
                (job &&
                    job.accountManagerId === user.id &&
                    (notableParts[0] === 'clients' || note.context.isSubmissionNote))
            ) {
                noteOptionItems.push(
                    <div key={'edit-note'} className="note-action">
                        <Tooltip title="Edit">
                            <IconButton onClick={this.handleEditNote} size="small">
                                <Edit fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </div>
                );
            }
            if (note.history.length > 0) {
                noteOptionItems.push(
                    <div key={'history-note'} className="note-action">
                        <Tooltip title="Show history">
                            <IconButton onClick={this.handleToggleHistory} size="small">
                                <History fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </div>
                );
            }

            noteOptionItems.push(
                <div key={'reply-note'} className="note-action">
                    <Tooltip title="Reply">
                        <IconButton onClick={this.handleToggleReplyForm} size="small">
                            <Message fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </div>
            );

            noteOptionItems.push(<NoteMentionCheck noteId={note.id} key="note-mention-check" />);

            const noteOptions =
                noteOptionItems.length > 0 ? <div className="note-actions">{noteOptionItems}</div> : null;

            const noteClass = `note-body ${note.context.isPhonescreenTemplate ? 'phonescreen-note' : ''}`;

            if (this.state.showHistory) {
                const noteHistory = note.history.map((h, i) => {
                    const attachments = h.attachments || [];
                    return (
                        <div className="note" key={i}>
                            <NoteFC
                                content={h.content}
                                format={note.format}
                                createdBy={h.createdBy}
                                createdAt={h.createdAt}
                                edited={false}
                                context={jobTitle}
                                className={noteClass}
                                options={null}
                                attachments={attachments}
                            />
                        </div>
                    );
                });
                history = (
                    <Dialog
                        open={true}
                        onRequestClose={this.handleToggleHistory}
                        autoScrollBodyContent={true}
                        bodyStyle={{ background: '#fafafa' }}
                    >
                        {noteHistory}
                    </Dialog>
                );
            }

            const peronId = note.notable.split('-')[0] === 'persons' ? note.notable.split('-')[1] : null;
            const personDetail = peronId ? personDetails.list.get(peronId) : null;
            const candidate = job && personDetail ? personDetail.candidates.find((c) => c.jobId === job.id) : null;
            const isMovingCandidate = candidate
                ? pendingRequests.has(`candidate-update-${candidate.personId}-${candidate.jobId}`)
                : false;
            const actions =
                job && job.id === context.jobId && candidate && !isMovingCandidate ? (
                    <SubmissionNoteActions
                        note={note}
                        candidate={candidate}
                        job={job}
                        user={user}
                        userPermissions={userPermissions}
                    />
                ) : null;

            content = note.deleted ? (
                <span className="note-deleted">
                    <i className="material-icons">delete</i> This note was deleted
                </span>
            ) : (
                <React.Fragment>
                    <NoteFC
                        content={note.content}
                        format={note.format}
                        createdAt={note.lastEditedAt ?? note.createdAt}
                        createdBy={note.createdBy}
                        edited={note.history.length > 0}
                        context={jobTitle}
                        className={noteClass}
                        options={noteOptions}
                        actions={actions}
                        attachments={note.attachments}
                    />
                    {history}
                </React.Fragment>
            );
        }

        const reply = this.state.showReply ? (
            <div className={'note-reply-form'}>
                <NoteForm
                    parentId={note.parentId ? note.parentId : note.id}
                    notable={note.notable}
                    context={note.context}
                    noteDraftKey={`note-draft-reply-${note.parentId}`}
                    startingTemplates={[]}
                    templateLabels={Map()}
                    templateInterpolators={Map()}
                    onClose={this.handleHideReplyForm}
                />
            </div>
        ) : null;

        return (
            <Paper className="note profile-section">
                {content}

                {reply}
                {this.props.children}
            </Paper>
        );
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    jobs: state.jobs,
    pendingRequests: state.pendingRequests,
    personDetails: state.personsDetails,
    user: state.session.user,
    userPermissions: state.session.userPermissions
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    deleteNote,
    getConfirmation
};

export const Note = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(NoteComponent);
