import { Map } from 'immutable';

import { Action, ReceivedSavePreset, ReceivePresets } from '../actions';
import { SearchPreset } from '../state';

const initialState: Map<string, SearchPreset> = Map<string, SearchPreset>();

export function searchPresets(state = initialState, action: Action): Map<string, SearchPreset> {
    switch (action.type) {
        case ReceivePresets: {
            let newState = initialState;
            for (const preset of action.payload.presets) {
                newState = newState.set(preset.id, preset);
            }
            return newState;
        }
        case ReceivedSavePreset: {
            let newState = state;
            const { preset } = action.payload;
            newState = newState.set(preset.id, preset);
            return newState;
        }
        default:
            return state;
    }
}
