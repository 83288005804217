import { css } from '@emotion/core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography
} from '@material-ui/core';
import { parse, unparse } from 'papaparse';
import React from 'react';
import Dropzone, { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import { ClientFormData } from '../graphql/queries/clients';
import { useModal } from '../hooks/use-modal';

import { useSnackbar } from '../hooks/use-snackbar';
import { getUniqueList } from '../lib/unique';
import { useFormContext } from './form-context';

const pageSize = 25;

const viewDialogStyles = css`
    .MuiDialogActions-root {
        justify-content: space-between;
        flex-direction: row-reverse;
    }

    .MuiDialogContent-root {
        padding: 0 20px;
    }

    .MuiTableCell-root {
        padding: 10px 12px;
    }
`;

const buttonsWidthSingle = 64;
const buttonsWidthMultiple = 212;

const styles = (disabled: boolean) => css`
    display: flex;
    align-items: baseline;
    flex: 1 1 auto;

    .blacklist-persons-input {
        flex: 1 1 auto;
    }

    .blacklist-persons-buttons {
        flex: 0 0 auto;
        display: flex;
        align-items: baseline;
        align-self: flex-end;
        gap: 10px;
        width: 0;
        overflow: hidden;
        transition: width 0.3s;
    }

    &:hover {
        .blacklist-persons-buttons {
            margin-left: 10px;
            width: ${disabled ? buttonsWidthSingle : buttonsWidthMultiple}px;
        }
    }
`;

export const ClientFormPersonBlacklist: React.FC = () => {
    const {
        data: { personBlacklist, name: clientName },
        onFieldChange,
        disabled
    } = useFormContext<ClientFormData>();
    const onChange = onFieldChange('personBlacklist');
    const [uploadConfirmationOpen, setUploadConfirmationOpen] = React.useState(false);
    const [viewBlacklistOpen, setViewBlacklistOpen] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const { setSnackbar } = useSnackbar();
    const { showLoading, hideLoading, getConfirmation } = useModal();

    const handleViewBlacklistedPersons = () => {
        setViewBlacklistOpen(true);
    };

    const handleCloseViewBlacklistedPersons = () => {
        setViewBlacklistOpen(false);
        setPage(0);
    };

    const handleChangePage = (_1: any, newPage: number) => setPage(newPage);
    const handleChangeRowsPerPage = () => {
        /* no-op */
    };

    const parseCSV = (
        file: File
    ): Promise<Array<{ company: string; firstName: string; lastName: string; title: string }>> => {
        return new Promise((resolve, reject) => {
            parse(file, {
                complete: (results) => {
                    if (results.errors.length > 0) {
                        reject(results.errors);
                    } else {
                        resolve(
                            results.data.map((d: { [field: string]: string }) => ({
                                company: d.Company,
                                firstName: d['First Name'],
                                lastName: d['Last Name'],
                                title: d.Position
                            }))
                        );
                    }
                },
                delimiter: ',',
                error: (error) => {
                    reject(error);
                },
                header: true,
                skipEmptyLines: true
            });
        });
    };

    const handleBlacklistPersonsDrop = async (files: File[]) => {
        setUploadConfirmationOpen(false);
        try {
            setSnackbar('Uploading ...');
            showLoading();
            if (files.length > 0) {
                const file = files[0];
                const parsedData = await parseCSV(file);
                const uniqueList = await getUniqueList([...parsedData, ...personBlacklist]);
                onChange(uniqueList);
                setSnackbar('Blacklist uploaded');
            } else {
                setSnackbar('No file selected');
            }
            setSnackbar('Blacklist uploaded');
        } catch {
            setSnackbar('Failed to upload blacklist');
        } finally {
            hideLoading();
        }
    };

    const handleBlacklistUploadClick = () => {
        setUploadConfirmationOpen(true);
    };

    const handleCloseBlacklistConfirmation = () => {
        setUploadConfirmationOpen(false);
    };

    const handleClear = () => {
        getConfirmation(() => onChange([]), 'Are you sure you want to clear the blacklist?', 'Clear blacklist');
    };

    const handleDownload = () => {
        const fileName = `${clientName}-person-blacklist.csv`;
        // tslint:disable: object-literal-sort-keys
        const csvContent = unparse(
            personBlacklist.map((person) => ({
                'First Name': person.firstName,
                'Last Name': person.lastName,
                Position: person.title,
                Company: person.company
            }))
        );
        // tslint:enable: object-literal-sort-keys
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, fileName);
    };

    const getDropzoneSection = (
        getRootProps: <T extends DropzoneRootProps>(props?: T) => T,
        getInputProps: <T extends DropzoneInputProps>(props?: T) => T
    ) => {
        return (
            <section>
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Button>Upload</Button>
                </div>
            </section>
        );
    };

    const acceptedFileTypes = {
        'text/csv': ['.csv'] // Specify the MIME type and file extension
    };
    const uploadBlacklistFileButton = (
        <Dropzone onDrop={handleBlacklistPersonsDrop} multiple={false} accept={acceptedFileTypes}>
            {({ getRootProps, getInputProps }) => getDropzoneSection(getRootProps, getInputProps)}
        </Dropzone>
    );

    const blacklistUploadConfirmation = uploadConfirmationOpen ? (
        <Dialog open={true} onClose={handleCloseBlacklistConfirmation}>
            <DialogTitle>
                <Typography variant="h4" component="div">
                    Upload list
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1" component="div">
                    Upload a list of people to blacklist. The list should be a CSV file with the following columns (and
                    a header row):
                </Typography>
                <ul>
                    <li>First Name</li>
                    <li>Last Name</li>
                    <li>Position</li>
                    <li>Company</li>
                </ul>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseBlacklistConfirmation}>Cancel</Button>
                {uploadBlacklistFileButton}
            </DialogActions>
        </Dialog>
    ) : null;

    const viewBlacklistButton =
        personBlacklist.length > 0 ? <Button onClick={handleViewBlacklistedPersons}>View</Button> : null;

    const clearButton = !disabled && personBlacklist.length > 0 ? <Button onClick={handleClear}>Clear</Button> : null;

    const addButton = disabled ? null : <Button onClick={handleBlacklistUploadClick}>Add</Button>;

    let viewBlacklistDialog;
    if (viewBlacklistOpen) {
        const rows = personBlacklist.slice(page * pageSize, (page + 1) * pageSize).map((person, index) => (
            <TableRow key={index}>
                <TableCell>{page * pageSize + index + 1}</TableCell>
                <TableCell>
                    {person.firstName} {person.lastName}
                </TableCell>
                <TableCell>{person.company}</TableCell>
                <TableCell>{person.title}</TableCell>
            </TableRow>
        ));
        viewBlacklistDialog = (
            <Dialog
                open={true}
                onClose={handleCloseViewBlacklistedPersons}
                maxWidth="md"
                fullWidth={true}
                css={viewDialogStyles}
            >
                <DialogTitle>
                    <Typography variant="h4" component="div">
                        Blacklisted Persons
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Table stickyHeader={true}>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Company</TableCell>
                                <TableCell>Title</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{rows}</TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <TablePagination
                        rowsPerPageOptions={[pageSize]}
                        component="div"
                        count={personBlacklist.length}
                        rowsPerPage={pageSize}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        className="pagination"
                    />
                    <div>
                        <Button onClick={handleDownload}>Download</Button>
                        {addButton}
                        <Button onClick={handleCloseViewBlacklistedPersons}>Close</Button>
                    </div>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <div css={styles(disabled || personBlacklist.length === 0)}>
            <div className="blacklist-persons-input">
                <TextField
                    fullWidth={true}
                    label="Person Blacklist"
                    value={`${personBlacklist.length} people in blacklist`}
                    disabled={disabled}
                />
            </div>
            <div className="blacklist-persons-buttons">
                {viewBlacklistButton}
                {addButton}
                {clearButton}
            </div>
            {blacklistUploadConfirmation}
            {viewBlacklistDialog}
        </div>
    );
};
