import { css } from '@emotion/core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Theme,
    Typography,
    useTheme
} from '@material-ui/core';
import { sortBy } from 'lodash';
import React, { createContext, ReactNode, useContext, useState } from 'react';

import { ModelParameters } from 'shared/common/llm';
import { hasRole } from 'shared/models/user';
import { downloadFile } from '../api';
import { useModal } from './use-modal';

import { useSession } from './use-session';

interface PromptInfoData {
    requestParameters?: ModelParameters;
    systemPrompt?: string;
    userPrompt?: string;
    completion?: string;
    parsedResponse?: any;
}

interface PromptInfoContextType {
    showPromptInfo: (arg: PromptInfoData | string) => Promise<void>;
    hidePromptInfo: () => void;
}

const PromptInfoContext = createContext<PromptInfoContextType | undefined>(undefined);

const styles = (theme: Theme) => css`
    .MuiDialogContent-root {
        .MuiInputBase-inputMultiline {
            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-track {
                background-color: white;
            }

            &::-webkit-scrollbar-thumb {
                background: rgba(0, 0, 0, 0.3);
            }
        }

        .parameters {
            border: thin solid ${theme.palette.divider};
            border-radius: ${theme.shape.borderRadius}px;
            margin-bottom: 40px;

            .parameter {
                display: flex;
                justify-content: space-between;
                padding: 10px 15px;
                border-bottom: thin solid ${theme.palette.divider};
            }
        }

        .MuiTextField-root {
            margin-bottom: 40px;
        }
    }
`;

export const PromptInfoProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [data, setData] = useState<PromptInfoData | null>(null);
    const theme = useTheme();
    const { userPermissions } = useSession();
    const { showLoading, hideLoading, setAlert } = useModal();
    const maxRows = 15;

    const showPromptInfo = async (arg: PromptInfoData | string) => {
        if (hasRole(userPermissions, 'prompts_editor')) {
            if (typeof arg === 'string') {
                try {
                    showLoading();
                    const response = await downloadFile(arg);
                    const responseContent: {
                        args: ModelParameters & { messages: Array<{ role: 'system' | 'user'; content: string }> };
                        completion: string;
                        parsedResponse: any;
                    } = await response.json();
                    hideLoading();
                    const { messages, ...requestParameters } = responseContent.args;
                    setData({
                        completion: responseContent.completion,
                        parsedResponse: responseContent.parsedResponse,
                        requestParameters,
                        systemPrompt: messages.find((m) => m.role === 'system')?.content,
                        userPrompt: messages.find((m) => m.role === 'user')?.content
                    });
                } catch {
                    hideLoading();
                    setAlert('Error', 'Failed to fetch prompts');
                }
            } else {
                setData(arg);
            }
        }
    };

    const hidePromptInfo = () => {
        setData(null);
    };

    let dialog: JSX.Element | null = null;
    if (data) {
        const paramsOrderArray = [
            'model',
            'temperature',
            'max_tokens',
            'top_p',
            'frequency_penalty',
            'presence_penalty'
        ];
        const parameters = sortBy(Object.keys(data.requestParameters), [
            (k) => (paramsOrderArray.indexOf(k) === -1 ? Infinity : paramsOrderArray.indexOf(k))
        ])
            .filter(
                (k) =>
                    data.requestParameters?.model?.startsWith('gpt') ||
                    ['model', 'temperature', 'max_tokens'].includes(k)
            )
            .map((key) => (
                <div key={key} className="parameter">
                    <div className="parameter-key">{key}</div>
                    <div className="parameter-value">{(data.requestParameters as any)[key]}</div>
                </div>
            ));

        const completion = data.completion ? (
            <TextField
                value={data.completion}
                label="Completion"
                fullWidth={true}
                multiline={true}
                variant="outlined"
                rows={maxRows}
                InputProps={{ readOnly: true }}
            />
        ) : null;

        const parsedResponse = data.parsedResponse ? (
            <TextField
                value={JSON.stringify(data.parsedResponse, null, 2)}
                label="Parsed Response"
                fullWidth={true}
                multiline={true}
                variant="outlined"
                rows={maxRows}
                InputProps={{ readOnly: true }}
            />
        ) : null;

        dialog = (
            <Dialog open={true} onClose={hidePromptInfo} css={styles(theme)} maxWidth="md" fullWidth={true}>
                <DialogTitle>
                    <Typography variant="h4" component="div">
                        Prompt Details
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <div className="parameters">{parameters}</div>
                    <div className="messages">
                        <TextField
                            value={data.systemPrompt}
                            label="System Prompt"
                            fullWidth={true}
                            multiline={true}
                            variant="outlined"
                            rows={maxRows}
                            InputProps={{ readOnly: true }}
                        />
                        <TextField
                            value={data.userPrompt}
                            label="User Prompt"
                            fullWidth={true}
                            multiline={true}
                            variant="outlined"
                            rows={maxRows}
                            InputProps={{ readOnly: true }}
                        />
                        {completion}
                        {parsedResponse}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={hidePromptInfo}>Close</Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <PromptInfoContext.Provider value={{ showPromptInfo, hidePromptInfo }}>
            {children}
            {dialog}
        </PromptInfoContext.Provider>
    );
};

export const usePromptInfo = () => {
    const context = useContext(PromptInfoContext);
    if (!context) {
        throw new Error('usePromptInfo must be used within a PromptInfoProvider');
    }
    return context;
};
