import { Map, OrderedMap } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';

import { UserData } from 'shared/models/user';

import { fetchJobsData } from '../actions';
import { JobsListDialog as JobsListDialogComponent } from '../components/jobs-list-dialog';
import { Client, Job, List, ListState, State } from '../state';

interface OwnProps {
    sourceJobId: string;
    onRequestClose: () => void;
    onJobSelect?: (jobIds: string | string[]) => void;
    jobsFilter?: (job: Job) => boolean;
    multiple?: boolean;
    confirmLabel?: string;
}

interface ConnectedProps {
    jobs: OrderedMap<string, Job>;
    clients: List<Client>;
    listsState: Map<string, ListState>;
    user: UserData;
}

interface ConnectedDispatch {
    fetchJobsData: () => void;
}

type JobsListDialogProps = OwnProps & ConnectedProps & ConnectedDispatch;

const JobsListDialogContainer: React.FC<JobsListDialogProps> = (props) => {
    const { sourceJobId, onRequestClose, onJobSelect, jobsFilter, jobs, clients, user, multiple, confirmLabel } = props;
    const jobsListState = props.listsState.get('jobs');

    React.useEffect(() => {
        if (!jobsListState) {
            props.fetchJobsData();
        }
    }, []);

    const loading = !jobsListState || jobsListState !== 'initialized' || !clients.initialized;

    return (
        <JobsListDialogComponent
            sourceJobId={sourceJobId}
            onRequestClose={onRequestClose}
            onJobSelect={onJobSelect}
            jobsFilter={jobsFilter}
            jobs={jobs}
            clients={clients}
            user={user}
            loading={loading}
            multiple={multiple}
            confirmLabel={confirmLabel}
        />
    );
};

const mapStateToProps = (state: State): ConnectedProps => ({
    clients: state.clients,
    jobs: state.jobs,
    listsState: state.listsState,
    user: state.session.user
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    fetchJobsData
};

export const JobsListDialog = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(JobsListDialogContainer);
