import { css } from '@emotion/core';
import { Theme } from '@material-ui/core';

export const notificationStyles = (theme: Theme) => css`
    &.MuiListItem-root {
        align-items: flex-start;
        padding: 16px;
        position: relative;
    }

    .MuiAvatar-root,
    .MuiListItemText-root {
        cursor: pointer;
    }

    .caption {
        display: flex;
        align-items: center;
        margin-left: 10px;
    }

    &:hover {
        background: rgba(0, 0, 0, 0.025);
    }

    .item-text {
        border-left: 2px solid ${theme.palette.divider};
        padding-left: 8px;
        margin-top: 4px;
        font-style: italic;
    }

    .MuiListItemText-multiline {
        margin: 0;

        .MuiTypography-h6 {
            line-height: 20px;
        }
    }
`;
