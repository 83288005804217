import { css } from '@emotion/core';
import { useTheme } from '@material-ui/core';
import { Update } from '@material-ui/icons';
import React from 'react';

import { clientStages } from 'shared/models/job-stages';

import { getWeekdaySince, timeRelativeDay } from '../../common/timestamp';
import { Candidate } from '../../graphql/queries/home';
import { InfoIcon } from './info-icon';

const styles = css`
    display: inline-flex;
    align-items: center;
`;

export const CandidateActivity: React.FC<{ candidate: Candidate }> = ({ candidate }) => {
    const theme = useTheme();
    let activityIcon;
    if (clientStages.indexOf(candidate.stage.name) !== -1) {
        const daysElapsed = getWeekdaySince(candidate.lastActivityAt);
        if (daysElapsed > candidate.job.maxIdleDays) {
            const color =
                // tslint:disable-next-line: no-magic-numbers
                daysElapsed >= 2 * candidate.job.maxIdleDays ? theme.palette.error.main : theme.palette.warning.main;
            activityIcon = (
                <InfoIcon
                    tooltip={`No activity for ${daysElapsed} days`}
                    color={color}
                    icon={<Update />}
                    className="left-pad"
                />
            );
        }
    }
    return (
        <span css={styles}>
            <span>{timeRelativeDay(candidate.lastActivityAt)}</span>
            {activityIcon}
        </span>
    );
};
