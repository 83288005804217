// tslint:disable:no-magic-numbers
import * as _ from 'lodash';
import * as moment from 'moment';

const globalDisabledHours = [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23];
const daysToLookAhead = 7;

export const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

export const getDisabledHours = (ts: number) => {
    if (moment().isSame(ts, 'day')) {
        const currentHour = moment().hour();
        return hours.filter((h) => h <= currentHour || globalDisabledHours.indexOf(h) !== -1);
    } else {
        return globalDisabledHours;
    }
};

export const isDayDisabled =
    (minDate: number, maxDate: number, disableWeekends = true) =>
    (day: Date) => {
        if (minDate && moment(day).isBefore(moment(minDate).startOf('day'))) return true;
        if (maxDate && moment(day).isAfter(moment(maxDate).endOf('day'))) return true;
        const dayOfWeek = moment(day).weekday();
        if (disableWeekends && (dayOfWeek === 0 || dayOfWeek === 6)) return true;
        return _.difference(hours, getDisabledHours(day.valueOf())).length === 0;
    };

export const getNearestValidEmailTs =
    (minDate: number, maxDate: number, disableWeekends = true) =>
    (value: number) => {
        const m = moment(value);
        const initialDay = m.day();
        let hour = m.hour();
        for (let dayIndex = 0; dayIndex < daysToLookAhead; dayIndex++) {
            m.startOf('day');
            if (!isDayDisabled(minDate, maxDate, disableWeekends)(m.toDate())) {
                const validHours = _.difference(hours, getDisabledHours(m.valueOf()));
                if (validHours.length > 0) {
                    if (m.day() !== initialDay || validHours.indexOf(hour) === -1) {
                        hour = validHours[0];
                    }
                    m.add(hour, 'hours');
                    const ts = m.valueOf();
                    if ((!minDate || ts > minDate) && (!maxDate || ts < maxDate)) {
                        return ts;
                    }
                }
            }
            m.add(1, 'days');
        }
        return value;
    };
