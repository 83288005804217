import { css } from '@emotion/core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Menu,
    MenuItem,
    Theme,
    Typography,
    useTheme
} from '@material-ui/core';
import { Archive, Edit, Unarchive, Visibility } from '@material-ui/icons';
import { groupBy, isEqual, orderBy, pick, pickBy } from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';

import {
    defaultOutreachSequence,
    defaultPhonescreenSequence,
    isRevivalSequence,
    SampleOutreachData,
    SequenceType,
    SequenceView
} from 'shared/models/sequence';

import { getDefaultJobAndCompanyPromptData, LLMPromptData } from 'shared/common/llm';
import { hasRole } from 'shared/models/user';
import { JobData } from '../graphql/queries/search';
import { Prompt, Sequence } from '../graphql/queries/sequences';
import { useModal } from '../hooks/use-modal';
import { useReduxState } from '../hooks/use-redux';
import { useSession } from '../hooks/use-session';
import { LLMPromptForm } from './llm-prompt-form';
import { PreviewLLMOutreachDialog } from './preview-llm-outreach-dialog';
import { SequenceForm } from './sequence-form';

// tslint:disable: no-magic-numbers
const styles = (theme: Theme) => css`
    margin-bottom: 32px;

    .sequence-form-content {
        flex: 1 1 auto;
    }

    .sequence-actions {
        display: flex;
        justify-content: flex-end;
        margin-top: 25px;
    }

    .sequences-panel {
        .sequence-groups {
            flex: 1 1 auto;
        }

        .job-sequence-group {
            border-bottom: thin solid ${theme.palette.divider};
            margin-bottom: 25px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .job-sequence-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            line-height: 1.5rem;

            .sequence-title-container {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;

                .sequence-title {
                    margin-right: 3px;
                }

                .sequence-creation-time {
                    margin-right: 3px;
                }

                &.archived {
                    opacity: 0.6;
                }
            }

            .sequence-action-buttons {
                display: flex;
            }

            .sequence-action-button {
                opacity: 0;
                transition: all 0.2s ease;
            }

            &:hover {
                background: ${theme.palette.grey[100]};
                .sequence-action-button {
                    opacity: 1;
                }
            }
        }

        label {
            line-height: 20px;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.3);
        }
    }
`;
// tslint:enable: no-magic-numbers

const promptFormStyles = (theme: Theme) => css`
    .MuiDialog-paper {
        width: 780px;
    }

    .MuiDialogContent-root {
        border-top: thin solid ${theme.palette.divider};
        border-bottom: thin solid ${theme.palette.divider};
        padding-top: 18px;
        padding-bottom: 18px;
    }

    .prompt-form-actions {
        flex: 1 1 auto;
        display: flex;
        justify-content: space-between;

        .MuiButton-root {
            margin: 0 8px;
        }
    }
`;

interface JobSequencesFormProps {
    job: JobData;
    role: string;
    sequences: Sequence[];
    prompts: Prompt[];
    promptDefaults: { systemPrompt: string; userPromptTemplate: string; modelParameters: Prompt['modelParameters'] };
    outreachGenModelOptions: Array<Prompt['modelParameters']>;
    isEditable: boolean;
    onUpdate: (sequence: Sequence) => void;
    onCreate: (sequence: Sequence) => void;
    onArchiveUpdate: (sequenceId: string, archive: boolean) => void;
    onCreatePrompt: (prompt: Partial<Prompt>) => void;
    onPromptArchiveUpdate: (promptId: string, archive: boolean) => void;
    onFetchSampleOutreach: (prompt: Partial<Prompt>) => Promise<SampleOutreachData>;
    onGenerateOutreachTemplate: () => Promise<void>;
}

// fields that can be edited in the prompt form
type PromptFormFields = Omit<Prompt, 'id' | 'jobId' | 'userId' | 'createdAt' | 'modifiedAt'> & Partial<Prompt>;

const sequenceTypeLabel = {
    [SequenceType.Outreach]: 'Initial Outreach',
    [SequenceType.Phonescreen]: 'Phonescreen'
};

interface ListItemProps<T extends { id: string; createdAt: number }> {
    itemName: string;
    onArchiveUpdate: (id: string, archive: boolean) => void;
    onEdit: (item: T) => void;
    item: T;
    isEditable: boolean;
    getTitle: (item: T) => string;
    getArchivedStatus: (item: T) => boolean;
}

const ListItem = <T extends { id: string; createdAt: number }>({
    itemName,
    onArchiveUpdate,
    onEdit,
    item,
    isEditable,
    getTitle,
    getArchivedStatus
}: ListItemProps<T>) => {
    const { getConfirmation } = useModal();

    const handleArchiveUpdate = (id: string, archive: boolean) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        const confirmationText = archive
            ? `This will archive the ${itemName}, and it will no longer be available.`
            : `This will unarchive the ${itemName}, and it will be available again.`;
        getConfirmation(
            () => {
                onArchiveUpdate(id, archive);
            },
            confirmationText,
            'Please confirm'
        );
    };

    const handleEdit = (updated: T) => () => {
        onEdit(updated);
    };

    const archived = getArchivedStatus(item);
    const archiveButton = archived ? (
        <div className="sequence-action-button">
            <IconButton size="small" onClick={handleArchiveUpdate(item.id, false)}>
                <Unarchive fontSize="small" />
            </IconButton>
        </div>
    ) : (
        <div className="sequence-action-button">
            <IconButton size="small" onClick={handleArchiveUpdate(item.id, true)}>
                <Archive fontSize="small" />
            </IconButton>
        </div>
    );

    const editViewIcon = archived || !isEditable ? <Visibility fontSize="small" /> : <Edit fontSize="small" />;
    const sideIcon = archived ? <Archive fontSize="inherit" /> : null;
    const creationDateString = archived ? (
        <span className="sequence-creation-time">{moment(item.createdAt).format('(MM/DD/YYYY)')}</span>
    ) : null;

    return (
        <div className="job-sequence-item" key={item.id} onClick={handleEdit(item)}>
            <div className={`${archived ? 'archived' : ''} sequence-title-container`}>
                <span className="sequence-title">{getTitle(item)}</span>
                {creationDateString}
                {sideIcon}
            </div>
            <div className="sequence-action-buttons">
                <div className="sequence-action-button">
                    <IconButton size="small">{editViewIcon}</IconButton>
                </div>
                {isEditable ? archiveButton : null}
            </div>
        </div>
    );
};

export const JobSequencesForm: React.FC<JobSequencesFormProps> = ({
    job,
    role,
    sequences,
    prompts,
    promptDefaults,
    outreachGenModelOptions,
    isEditable,
    onUpdate,
    onCreate,
    onArchiveUpdate,
    onCreatePrompt,
    onPromptArchiveUpdate,
    onFetchSampleOutreach,
    onGenerateOutreachTemplate
}) => {
    const theme = useTheme();
    const [editingSequence, setEditingSequence] = React.useState<SequenceView>(null);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement>(null);
    const [editingPrompt, setEditingPrompt] = React.useState<PromptFormFields>(null);
    const [previewingOutreach, setPreviewingOutreach] = React.useState<boolean>(false);
    const [outreachPreviewData, setOutreachPreviewData] = React.useState<SampleOutreachData>(null);
    const { getConfirmation } = useModal();
    const { userPermissions } = useSession();
    const clients = useReduxState((state) => state.clients);
    const client = clients.list.get(job?.clientId);

    const defaultPrompt = useMemo(() => {
        const defaultData = job && client ? getDefaultJobAndCompanyPromptData(job, client) : null;
        const defaultAdditionalContext = job?.outreachAIContext?.directions ?? '';
        return {
            ...defaultData,
            ...promptDefaults,
            active: true,
            additionalContext: defaultAdditionalContext,
            title: ''
        };
    }, [promptDefaults, job, client]);

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const sequenceWithRole = (seq: SequenceView) => {
        const stages = seq.stages.map((s) => ({
            ...s,
            body: s.body.replace(/{{ROLE}}/gi, role),
            subject: s.subject.replace(/{{ROLE}}/gi, role)
        }));
        return { ...seq, stages };
    };

    const handleAddSequenceSelect = (type: SequenceType) => () => {
        if (type === SequenceType.Outreach) {
            setEditingSequence(sequenceWithRole(defaultOutreachSequence));
        } else if (type === SequenceType.Phonescreen) {
            setEditingSequence(sequenceWithRole(defaultPhonescreenSequence));
        }
        setAnchorEl(null);
    };

    const handleCancelAddSequence = () => {
        setEditingSequence(null);
    };

    const handleAddPrompt = () => {
        setAnchorEl(null);
        setEditingPrompt(defaultPrompt);
    };

    const handleSavePrompt = () => {
        const { id, active, ...rest } = editingPrompt;
        const editedData = pickBy(rest, (v, k) => !isEqual(v, (promptDefaults as any)[k]));
        onCreatePrompt({ ...editedData, active: true });
        setEditingPrompt(null);
    };

    const hasPromptChanged = (prompt: Partial<Prompt>) => {
        let changed = false;
        const savedPrompt = prompts.find((p) => p.id === prompt.id) ?? defaultPrompt;
        changed = !isEqual(pick(savedPrompt, Object.keys(prompt)), prompt);
        return changed;
    };

    const handleCancelAddPrompt = () => {
        const changed = hasPromptChanged(editingPrompt);
        if (!changed) {
            setEditingPrompt(null);
        } else {
            getConfirmation(
                () => setEditingPrompt(null),
                'Are you sure you want to discard changes?',
                'Discard changes'
            );
        }
    };

    const handlePreviewLLMOutreach = async () => {
        setOutreachPreviewData(null);
        setPreviewingOutreach(true);
        try {
            const result = await onFetchSampleOutreach(editingPrompt);
            setOutreachPreviewData(result);
        } catch (error) {
            setOutreachPreviewData(null);
            setPreviewingOutreach(false);
        }
    };

    const handleClosePreviewOutreach = () => {
        setPreviewingOutreach(false);
        setOutreachPreviewData(null);
    };

    const handleEditPrompt = (prompt: Prompt) => {
        setEditingPrompt(prompt);
    };

    const handlePromptChange = (updated: LLMPromptData) => {
        setEditingPrompt({ ...editingPrompt, ...updated });
    };

    const handleSave = (newSequence: SequenceView) => {
        if (newSequence.id) {
            onUpdate(newSequence);
        } else {
            onCreate(newSequence);
        }
        setEditingSequence(null);
    };

    const handleEdit = (sequence: SequenceView) => {
        setEditingSequence(sequence);
    };

    const handleGenerateOutreachSequence = async () => {
        setAnchorEl(null);
        onGenerateOutreachTemplate();
    };

    const getSequenceTitle = (sequence: Sequence) => sequence.title;
    const getSequenceArchivedStatus = (sequence: Sequence) => sequence.archived;

    const getPromptTitle = (prompt: Prompt) => prompt.title;
    const getPromptArchivedStatus = (prompt: Prompt) => !prompt.active;

    const sequenceGroups = groupBy(sequences, 'type');
    const list: JSX.Element[] = [];
    for (const t of [SequenceType.Outreach, SequenceType.Phonescreen]) {
        if (sequenceGroups[t]) {
            const sortedSequences = [...sequenceGroups[t]].sort((a, b) => Number(a.archived) - Number(b.archived));
            const jobSequences = sortedSequences.map((sequence) => (
                <ListItem
                    key={sequence.id}
                    itemName="Sequence"
                    item={sequence}
                    isEditable={isEditable}
                    onArchiveUpdate={onArchiveUpdate}
                    onEdit={handleEdit}
                    getTitle={getSequenceTitle}
                    getArchivedStatus={getSequenceArchivedStatus}
                />
            ));
            list.push(
                <div className="job-sequence-group" key={t}>
                    <label>{sequenceTypeLabel[t]}</label>
                    {jobSequences}
                </div>
            );
        }
    }

    const sequenceForm = editingSequence ? (
        <Dialog open={true} maxWidth="md" fullWidth={true} disableEnforceFocus={true}>
            <SequenceForm
                title={editingSequence.id ? 'Edit Sequence' : 'Create Sequence'}
                sequence={editingSequence}
                disabled={!isEditable}
                onCancel={handleCancelAddSequence}
                onSave={handleSave}
            />
        </Dialog>
    ) : null;

    let promptsSection;
    if (prompts?.length > 0) {
        const promptItems = orderBy(prompts, ['active', 'createdAt'], ['desc', 'desc']).map((prompt) => (
            <ListItem
                key={prompt.id}
                itemName="Prompt"
                item={prompt}
                isEditable={isEditable}
                onArchiveUpdate={onPromptArchiveUpdate}
                onEdit={handleEditPrompt}
                getTitle={getPromptTitle}
                getArchivedStatus={getPromptArchivedStatus}
            />
        ));
        promptsSection = (
            <div className="job-sequence-group" key="gen-prompts">
                <label>Initial Outreach Generation Prompt</label>
                {promptItems}
            </div>
        );
    }

    const promptSaveDisabled = editingPrompt?.title?.length === 0;

    const promptDialogTitle = editingPrompt?.id
        ? 'Prompt for AI Generated Outreach'
        : 'Create a new prompt for AI Generated Outreach';

    const promptsForm = editingPrompt ? (
        <Dialog open={true} css={promptFormStyles(theme)} disableEnforceFocus={true} maxWidth="md">
            <DialogTitle>
                <Typography variant="h4" component="div">
                    {promptDialogTitle}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <LLMPromptForm
                    data={editingPrompt}
                    defaults={promptDefaults}
                    onChange={handlePromptChange}
                    modelOptions={outreachGenModelOptions}
                    isEditable={isEditable}
                />
            </DialogContent>
            <DialogActions>
                <div className="prompt-form-actions">
                    <div>
                        <Button disabled={promptSaveDisabled} onClick={handlePreviewLLMOutreach}>
                            Preview
                        </Button>
                    </div>
                    <div>
                        <Button onClick={handleCancelAddPrompt}>Cancel</Button>
                        <Button
                            onClick={handleSavePrompt}
                            disabled={promptSaveDisabled || !editingPrompt?.active || !hasPromptChanged(editingPrompt)}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    ) : null;

    const addAIOutreachPromptMenuItem = hasRole(userPermissions, 'prompts_editor') ? (
        <MenuItem onClick={handleAddPrompt}>Add Prompt for AI Outreach</MenuItem>
    ) : null;

    const outreachCreateMenuItem = sequences.some(
        (s) => !s.archived && s.type === SequenceType.Outreach && !isRevivalSequence(s)
    ) ? (
        <MenuItem onClick={handleAddSequenceSelect(SequenceType.Outreach)}>Add Initial Outreach</MenuItem>
    ) : (
        <MenuItem onClick={handleGenerateOutreachSequence}>Generate Initial Outreach</MenuItem>
    );

    const sequenceActions = isEditable ? (
        <div className="sequence-actions">
            <Button onClick={handleOpenMenu}>Add Sequence</Button>
            <Menu anchorEl={anchorEl} keepMounted={true} open={!!anchorEl} onClose={handleCloseMenu}>
                {outreachCreateMenuItem}
                {addAIOutreachPromptMenuItem}
                <MenuItem onClick={handleAddSequenceSelect(SequenceType.Phonescreen)}>Add Phone call</MenuItem>
            </Menu>
        </div>
    ) : null;

    return (
        <div css={styles(theme)}>
            <div className="sequences-panel">
                <div className="sequence-form-content">
                    <div className="sequence-groups">
                        {promptsSection}
                        {list}
                    </div>
                    {sequenceActions}
                </div>
            </div>
            {sequenceForm}
            {promptsForm}
            <PreviewLLMOutreachDialog
                data={outreachPreviewData}
                open={previewingOutreach}
                onClose={handleClosePreviewOutreach}
                onTryAnother={handlePreviewLLMOutreach}
            />
        </div>
    );
};
