import { css } from '@emotion/core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Theme,
    Tooltip,
    Typography,
    useTheme
} from '@material-ui/core';
import { Schedule } from '@material-ui/icons';
import React, { useState } from 'react';

import { OutreachesByJob } from './outreaches-by-job';

const styles = (theme: Theme) => css`
    .MuiDialog-paper {
        .outreach-dialog-title {
            border-bottom: thin solid ${theme.palette.divider};
        }

        .outreach-dialog-actions {
            border-top: thin solid ${theme.palette.divider};
        }

        .outreach-dialog-content {
            padding: 20px 24px;
        }

        .outreach-title {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .toggle {
                cursor: pointer;
            }
        }

        .select-field {
            display: flex;

            > * {
                flex: 1 0;
                margin: 0 10px;

                &:first-of-type {
                    margin-left: 0;
                }

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }

        .outreach-field {
            margin-bottom: 25px;
        }
    }
`;

export const JobScheduledOutreach: React.FC<{ jobId: string }> = ({ jobId }) => {
    const theme = useTheme();

    const [dialogOpen, setDialogOpen] = useState(false);

    const handleCloseOutreach = () => {
        setDialogOpen(false);
    };

    const handleMenuButtonTap = (event: React.SyntheticEvent<{}>) => {
        event.preventDefault();
        setDialogOpen(true);
    };

    const dialog = dialogOpen ? (
        <div>
            <Dialog onClose={handleCloseOutreach} open={true} css={styles(theme)} maxWidth="md" fullWidth={true}>
                <DialogTitle className="outreach-dialog-title">
                    <div className="outreach-title">
                        <Typography variant="h4" component="span">
                            Scheduled Outreaches
                        </Typography>
                    </div>
                </DialogTitle>
                <DialogContent className="outreach-dialog-content">
                    <OutreachesByJob jobId={jobId} />
                </DialogContent>
                <DialogActions className="outreach-dialog-actions">
                    <Button onClick={handleCloseOutreach}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    ) : null;

    const tooltip = 'Scheduled Outreaches';

    return (
        <div>
            <Tooltip title={tooltip}>
                <span>
                    <IconButton size="small" onClick={handleMenuButtonTap}>
                        <Schedule htmlColor="white" />
                    </IconButton>
                </span>
            </Tooltip>

            {dialog}
        </div>
    );
};
