import { startCase } from 'lodash';
import { InvoiceStatus } from 'shared/models/invoice';
import { Fee, Invoice } from '../graphql/queries/billing';

type FeeStatus =
    | 'invoice_not_found'
    | 'invoice_amount_mismatch'
    | 'waiting_for_payment'
    | 'paid_in_full'
    | 'invoice_not_sent';

export const getFeeStatusLabel = (status: FeeStatus): string => {
    return startCase(status);
};

export const feeStatus = (fee: Fee): FeeStatus => {
    const feeAllInvoicesAmount = fee.invoices.reduce((acc, i) => acc + i.totalAmount, 0);
    const paidAmount = fee.invoices
        .map((i) => i.payments.filter((p) => p.status === 'PAID'))
        .reduce((acc, p) => {
            return acc + p.reduce((acc2, l2) => acc2 + l2.amount, 0);
        }, 0);
    const feeInvoicedAmount = fee.invoices.reduce(
        (acc, i) => acc + i.lineItems.filter((l) => l.feeId === fee.id).reduce((acc2, l) => acc2 + l.amount, 0),
        0
    );
    const status =
        fee.amount === 0
            ? 'paid_in_full'
            : !fee.invoices.find((i) => i.lineItems.find((l) => l.feeId === fee.id))
              ? fee.dueAt > Date.now()
                  ? 'invoice_not_sent'
                  : 'invoice_not_found'
              : feeInvoicedAmount !== fee.amount
                ? 'invoice_amount_mismatch'
                : paidAmount !== feeAllInvoicesAmount
                  ? 'waiting_for_payment'
                  : 'paid_in_full';

    return status;
};

type InvoiceStatusExtended = 'FEE_NOT_FOUND' | 'OVERDUE' | InvoiceStatus;

export const getInvoiceStatusLabel = (status: InvoiceStatusExtended): string => {
    return startCase(status.toLocaleLowerCase());
};

export const invoiceStatus = (invoice: Invoice): InvoiceStatusExtended => {
    const notAppliedFees = invoice.lineItems.find((lineItem) => !lineItem.feeId);
    const status = notAppliedFees
        ? 'FEE_NOT_FOUND'
        : invoice.status !== 'PAID_IN_FULL' && invoice.dueDate < Date.now()
          ? 'OVERDUE'
          : invoice.status;
    return status;
};
