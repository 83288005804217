// cspell:ignore wogqnlgy wogql7yj 827lpokz
import { includes } from 'lodash';

import { beingScheduledStage } from 'shared/models/job-stages';
import { anyValue, maxRangeValue, SearchConfig, SearchProject } from 'shared/models/search';
import { Feature } from 'shared/models/user';

import { SearchStatus } from '../state';
import { searchLocations } from './search-locations';

const defaultSearchConfig = (
    _1: { features: Feature },
    job: { description: { location: string }; discipline: string },
    client: { linkedinUrl: string }
): SearchConfig => {
    return {
        clientEmployees: {
            disciplineMatch: !!job?.discipline,
            enabled: false,
            hopsAllowed: 3,
            maxCompanySize: 100,
            overlapRequired: true
        },
        diversity: {
            gender: anyValue,
            operator: 'and',
            race: []
        },
        education: {
            bootcampOk: true,
            fieldOfStudy: [],
            maxDegree: 'none',
            minDegree: 'none',
            negativeTerms: undefined,
            rankedSchools: [],
            schools: undefined
        },
        experience: {
            ccfseExclude: {
                enabled: true,
                when: 'latest'
            },
            companies: [{ exactMatch: true, names: [], negative: false, when: 'latest' }],
            consumerEnterprise: {
                enabled: false,
                maxProb: 1.0,
                minProb: 0,
                when: 'latest'
            },
            currentJobDurationMax: maxRangeValue,
            currentJobDurationMin: 0,
            groups: [],
            seniorityMax: 'executive',
            seniorityMin: 'entry',
            tenures: [{ disciplines: job ? [job.discipline] : [], experienceMax: 10, experienceMin: 2, ignore: false }],
            titles: [
                { list: [], negative: false, when: 'latest' },
                {
                    list: [
                        { isPreset: true, label: 'Entry', presetId: 'zm7ym714' },
                        { isPreset: true, label: 'Senior IC', presetId: 'l37xl7d1' },
                        { isPreset: true, label: 'Exec/Founder', presetId: 'wogql7yj' },
                        { isPreset: true, label: 'Director/Manager', presetId: '297e57bj' }
                    ],
                    negative: true,
                    when: 'latest'
                }
            ],
            yearsSinceGraduation: {
                maxYears: maxRangeValue,
                minYears: 0
            }
        },
        general: {
            disciplines: job ? [job.discipline] : [],
            locations:
                job?.description?.location?.trim()?.length && includes(searchLocations, job.description.location)
                    ? [{ locations: [{ isPreset: false, label: job.description.location }], negative: false }]
                    : [{ locations: [], negative: false }],
            visa: [{ isPreset: true, label: 'H1B Transfer OK', presetId: '827lpokz' }]
        },
        inferenceGroups: [{ keywords: [], negative: false, score: 1 }],
        keywordGroups: [{ keywords: [], ignore: false, negative: false, searchIn: 'last3YearAndSummary' }],
        linkedinSearch: {
            tags: []
        },
        openToWork: { open: 'all' },
        positiveResponse: {
            enabled: false,
            maxDays: maxRangeValue,
            minDays: 0,
            minStage: beingScheduledStage
        },
        similarityModel: [{ companyLink: client?.linkedinUrl, when: 'latest', minimumSimilarityScore: 0 }],
        skillModel: { mustHave1: [], mustHave2: [], negatives: [] }
    };
};

export const searchDefaults = (
    user: { features: Feature },
    job: { description: { location: string }; discipline: string },
    client: { linkedinUrl: string },
    project?: SearchProject
) => ({
    config: defaultSearchConfig(user, job, client),
    minimumSkillScore: 0,
    name: '',
    project: project || SearchProject.Titan,
    querySql: '',
    resultsSort: 'similarityScore' as 'similarityScore',
    status: SearchStatus.Initial
});
