import { startCase } from 'lodash';
import { blue200, pink200, red200, red600 } from 'material-ui/styles/colors';
import React, { useEffect, useMemo, useRef } from 'react';

import { SearchExperienceTitle, SearchTerm, toggleSearchTerm } from 'shared/models/search';

import { TextfieldWithChips } from '../core-ui/textfield-with-chips';
import { useModal } from '../hooks/use-modal';
import { SearchStatus } from '../state';
import { renderOption } from './search-autocomplete-option';
import { SearchFieldContext } from './search-field-context';
import { SearchGroup, SearchGroupAction } from './search-group';
import { SearchHelp } from './search-help';
import { useSearch } from './use-search';
import { useSearchPresets } from './use-search-presets';
import { useSearchPresetsForm } from './use-search-presets-form';

export const SearchExperienceTitlesRequirements: React.FC = () => {
    const searchContext = useSearch();
    const config = searchContext.data.config.experience;
    const onChange = searchContext.onConfigFieldChange('experience');
    const readonly = searchContext.readonly;
    const { requestPresets, updateSelectedPresets, searchPresets, getGroupSearchTerms } = useSearchPresets();
    const { requestPresetsSave } = useSearchPresetsForm();
    const { getConfirmation } = useModal();
    const configRef = useRef(config);
    const savedSearch = searchContext.data.status && searchContext.data.status !== SearchStatus.Initial;

    const titleOptions = useMemo(() => getGroupSearchTerms('titles'), [searchPresets]);

    useEffect(() => {
        configRef.current = config;
    }, [config]);

    const { titles } = config ?? {};

    const handleToggleTitleGroup = (index: number) => () => {
        const val = config.titles[index].negative;
        handleUpdateTitleGroup(index)('negative')(!val);
    };

    const handleRemoveTitleGroup = (index: number) => () => {
        let newTitles = titles.slice(0, index).concat(titles.slice(index + 1));
        if (newTitles.length === 0) {
            newTitles = [{ list: [], when: 'latest', negative: false }];
        }
        if (titles[index].list.length > 0) {
            getConfirmation(
                () => onChange(Object.assign({}, config, { titles: newTitles })),
                'Are you sure you want to remove a list of titles?',
                'Remove Confirmation'
            );
        } else {
            onChange(Object.assign({}, config, { titles: newTitles }));
        }
    };

    const handleAddTitleGroup = () => {
        const newTitles = config.titles.concat({ list: [], when: 'latest', negative: false });
        onChange(Object.assign({}, config, { titles: newTitles }));
    };

    const handleUpdateTitleGroup =
        (index: number) =>
        <T extends keyof SearchExperienceTitle>(field: T) =>
        (value: SearchExperienceTitle[T]) => {
            const title = Object.assign({}, titles[index], { [field]: value });
            const newTitles = titles.slice(0, index).concat([title], titles.slice(index + 1));
            onChange(Object.assign({}, config, { titles: newTitles }));
        };

    const handleToggleTitlePreset = (index: number) => (label: string, _1: string[], id: string) => {
        const old = configRef.current.titles[index];
        const newList = toggleSearchTerm({ label, isPreset: true, presetId: id }, old.list);
        const updated = Object.assign({}, old, { list: newList });
        const newTitles = titles.slice(0, index).concat([updated], titles.slice(index + 1));
        onChange(Object.assign({}, config, { titles: newTitles }));
        updateSelectedPresets(newTitles[index].list.filter((l) => l.isPreset));
    };

    const handleAddTitlePresetRequest = (index: number) => () => {
        requestPresets(
            handleToggleTitlePreset(index),
            ['titles'],
            configRef.current.titles[index].list.filter((l) => l.isPreset)
        );
    };

    const handlePresetSaved = (index: number) => (preset: SearchTerm) => {
        const old = configRef.current.titles[index];
        const updated = { ...old, list: [preset] };
        const newTitles = titles.slice(0, index).concat([updated], titles.slice(index + 1));
        onChange(Object.assign({}, config, { titles: newTitles }));
    };

    const handleSaveAsPreset = (index: number, names: SearchTerm[]) => () => {
        requestPresetsSave({
            groups: ['titles'],
            postSave: handlePresetSaved(index),
            selectedGroup: 'titles',
            terms: names
        });
    };

    const getChipStyle =
        <T extends { negative: boolean }>(g: T) =>
        (value: SearchTerm) => {
            const { isPreset } = value;
            const backgroundColor =
                g.negative && isPreset ? pink200 : g.negative ? red200 : isPreset ? blue200 : undefined;
            return { backgroundColor };
        };

    const getChipFromString = (label: string) => ({ isPreset: false, label });
    const getStringFromChip = (chip: SearchTerm) => chip.label;

    const helpTitle = 'Title Name Match - Case Insensitive, Regex Full Word';
    const helpExample = [
        '"Director" will match "Director of Marketing" and "Marketing director"',
        '"Director" will not match "MarketingDirector", "Director-Sales", and "Director, Sales"'
    ];

    const titlesList = titles
        ?.filter((list) => !savedSearch || list.list.length > 0)
        .map((t, i) => {
            const actions: SearchGroupAction[] = [{ name: 'Add', onSelect: handleAddTitleGroup }];
            actions.push({ name: 'Remove', onSelect: handleRemoveTitleGroup(i) });
            actions.push({
                checked: t.negative,
                name: 'Negative',
                onSelect: handleToggleTitleGroup(i),
                style: t.negative ? { color: red600 } : {}
            });
            actions.push({ name: 'Presets', onSelect: handleAddTitlePresetRequest(i) });
            if (t.list.length > 0) {
                actions.push({ name: 'Save as Preset', onSelect: handleSaveAsPreset(i, t.list) });
            }
            return (
                <SearchGroup readonly={readonly} actions={actions} key={i} className="and-group">
                    <div className="search-requirements-field with-context" key={i}>
                        <div style={{ flex: '1 1 auto' }}>
                            <label className="search-label">
                                Titles
                                <SearchHelp title={helpTitle} examples={helpExample} />
                            </label>
                            <div className="search-requirements-value">
                                <TextfieldWithChips
                                    getChipStyle={getChipStyle(t)}
                                    readonly={readonly}
                                    chips={t.list}
                                    hintText="Add Title"
                                    onChange={handleUpdateTitleGroup(i)('list')}
                                    getChipFromString={getChipFromString}
                                    getStringFromChip={getStringFromChip}
                                    options={titleOptions}
                                    renderOption={renderOption}
                                />
                            </div>
                        </div>
                        <SearchFieldContext
                            disabled={readonly}
                            label="Kind"
                            onSelect={handleUpdateTitleGroup(i)('when')}
                            selected={t.when}
                            options={['latest', 'past', 'latestOrPast']}
                            getOptionLabel={startCase}
                        />
                    </div>
                </SearchGroup>
            );
        });

    if (!titles) return null;

    return <div className="top-padding">{titlesList}</div>;
};
