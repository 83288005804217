import { config } from '../config';

export const colors = {
    aiScore: {
        alternate: 'black',
        main: 'white'
    },
    otherUser: {
        alternate: '#66CDAA',
        main: '#98FF98'
    },
    sessionUser: {
        alternate: '#DAA520',
        main: '#FFD700'
    }
};

export const getScoreColor = (s: { user?: { id?: string } }, user: { id: string }) => {
    if (!s.user || s.user.id === config.SystemUserId) {
        return colors.aiScore;
    } else if (s.user.id === user.id) {
        return colors.sessionUser;
    } else {
        return colors.otherUser;
    }
};
