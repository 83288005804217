import { MenuItem, SelectField } from 'material-ui';
import { white } from 'material-ui/styles/colors';
import * as React from 'react';
import { FlaggedEmailAccount } from '../graphql/queries/home';

interface FlaggedEmailAccountSelectorProps {
    selected: string;
    accounts: FlaggedEmailAccount[];
    onSelectAnotherAccount: (account: string) => void;
}

export class FlaggedEmailAccountSelector extends React.Component<FlaggedEmailAccountSelectorProps> {
    handleAccountSelect = (_1: any, _2: any, account: string) => {
        if (this.props.selected !== account) {
            this.props.onSelectAnotherAccount(account);
        }
    };

    render() {
        const { accounts, selected } = this.props;
        if (!accounts) return null;
        const menuItems = accounts
            .slice(0)
            .sort((s1, s2) => s1.name.localeCompare(s2.name))
            .map(({ count, account }) => {
                const primaryText = (
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>{account}</div> <div style={{ marginLeft: '20px' }}>[{count}]</div>
                    </div>
                );
                return <MenuItem value={account} primaryText={primaryText} key={account} />;
            });
        return (
            <div className="email-inbox-account-info">
                <SelectField
                    value={selected}
                    autoWidth={true}
                    onChange={this.handleAccountSelect}
                    underlineStyle={{ display: 'none' }}
                    style={{ width: 'auto' }}
                    labelStyle={{ color: white }}
                >
                    {menuItems}
                </SelectField>
            </div>
        );
    }
}
