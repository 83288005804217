import { Button } from '@material-ui/core';
import { startCase } from 'lodash';
import * as React from 'react';

import { SearchConfigSimilarityModel } from 'shared/models/search';

import { SimilaritySearchButton } from '../components/similarity-search-button';
import { CompanyNameSelectField } from '../components/talent-intelligence/company-name-select';
import { SelectField } from '../core-ui/simple-select-field';
import { SearchStatus } from '../state';
import { SearchFieldContext } from './search-field-context';
import { SearchGroup } from './search-group';
import { useSearch } from './use-search';

export const SearchSimilarityModelRequirements: React.FC = () => {
    const searchContext = useSearch();
    const config = searchContext.data.config.similarityModel;
    const onChange = searchContext.onConfigFieldChange('similarityModel');
    const readonly = searchContext.readonly;
    const savedSearch = searchContext.data.status && searchContext.data.status !== SearchStatus.Initial;

    const handleCompanyLinkChange = (index: number) => (company: { name: string; url: string }) => {
        const updated = Object.assign({}, config[index], { companyLink: company.url });
        const newSimilarity = config.slice(0, index).concat([updated], config.slice(index + 1));
        onChange(newSimilarity);
    };

    const handleUpdateCompany =
        (index: number) =>
        <T extends keyof SearchConfigSimilarityModel>(field: T) =>
        (value: SearchConfigSimilarityModel[T]) => {
            const updated = Object.assign({}, config[index], { [field]: value });
            const newSimilarity = config.slice(0, index).concat([updated], config.slice(index + 1));
            onChange(newSimilarity);
        };

    const handleScoreChanges = (index: number) => (val: number) => {
        handleUpdateCompany(index)('minimumSimilarityScore')(val);
    };

    const handleUseCompanyButtonClick = (index: number, company: { name: string; url: string }) => () => {
        handleCompanyLinkChange(index)(company);
    };

    const buttonForValue = (index: number) => (value: { name: string; url: string }) => {
        return <Button onClick={handleUseCompanyButtonClick(index, value)}>Use</Button>;
    };

    const scoreOptions = Array.from(Array(11), (_, x) => x / 10); // tslint:disable-line:no-magic-numbers
    const getScoreLabel = (v: number) => v.toFixed(1);

    const companiesLists = config
        .filter((l) => !savedSearch || !!l.companyLink)
        .map((l, i) => {
            const selectedCompany = l.companyLink ? { name: l.companyLink, url: l.companyLink } : null;
            return (
                <SearchGroup readonly={readonly} actions={[]} key={i} className="and-group">
                    <div className="search-requirements-field with-context" key={i}>
                        <div style={{ flex: '1 1 auto' }}>
                            <div className="search-requirements-value">
                                <div className="company-similarity-field">
                                    <div className="company-similarity-select-company">
                                        <CompanyNameSelectField
                                            selected={selectedCompany}
                                            onSelect={handleCompanyLinkChange(i)}
                                            disabled={readonly}
                                            variant="no-underline"
                                            recruiterLinkOnly={true}
                                            label="Company Similarity"
                                        />
                                    </div>
                                    <SimilaritySearchButton
                                        searchType="companies"
                                        searchText={l.companyLink}
                                        buttonForValue={buttonForValue(i)}
                                    />
                                </div>
                            </div>
                            <SelectField
                                disabled={readonly}
                                className="search-requirements-field"
                                selected={l.minimumSimilarityScore}
                                onSelect={handleScoreChanges(i)}
                                options={scoreOptions}
                                getOptionLabel={getScoreLabel}
                                label="Min similarity score"
                            />
                        </div>
                        <SearchFieldContext
                            disabled={readonly}
                            label="Kind"
                            onSelect={handleUpdateCompany(i)('when')}
                            selected={l.when}
                            options={['latest', 'all']}
                            getOptionLabel={startCase}
                        />
                    </div>
                </SearchGroup>
            );
        });

    return <>{companiesLists}</>;
};
