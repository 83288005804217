import { MenuItem, Select } from '@material-ui/core';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface NavigationSelectProps extends RouteComponentProps {
    routes: Array<[string, string]>; // Array of [label, url] tuples
    variant?: 'standard' | 'outlined' | 'filled';
}

const NavigationSelectComponent: React.FC<NavigationSelectProps> = ({
    routes,
    location,
    history,
    variant = 'outlined'
}) => {
    // Find current label based on current path
    const current = routes.find(([_, path]) => path === location.pathname)?.[0];

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const newValue = event.target.value as string;
        const newPath = routes.find(([label, _]) => label === newValue)?.[1];
        if (newPath && newPath !== location.pathname) {
            history.push(newPath);
        }
    };

    const menuItemElements = routes.map(([label, _]) => (
        <MenuItem key={label} value={label}>
            <div className="menu-item">{label}</div>
        </MenuItem>
    ));

    return (
        <Select value={current} onChange={handleChange} variant={variant} className="select-value">
            {menuItemElements}
        </Select>
    );
};

export const NavigationSelect = withRouter(NavigationSelectComponent);
