import { Map, OrderedMap } from 'immutable';
import { isEmpty } from 'lodash';

import { emailFoundStage, emailNotFoundStage, emailRequestedStage, sourcedStage } from 'shared/models/job-stages';

import {
    Action,
    ArchiveMessage,
    MoveCandidateToStage,
    ReceiveAccountManagerData,
    ReceiveAddSearchResultToJob,
    ReceiveCandidate,
    ReceiveCandidateAddedToJob,
    ReceiveCandidateAssigneeData,
    ReceiveCandidateUpdate,
    ReceiveCrossAddCandidate,
    ReceiveCrossAddMultiple,
    ReceiveInitialOutreachForJob,
    ReceiveJobAssigneeData,
    ReceiveMoveInGmail,
    ReceivePersonCommunications,
    ReceivePersonDetails,
    ReceivePersonOptOutUpdate,
    ReceivePersonSetBlacklisted,
    ReceivePurgeCandidatesResponse,
    ReceiveReassignCandidateAssignee,
    ReceiveSearchResultPersonAndCandidate,
    ReceiveUserBlacklistPerson,
    RequestCandidateDisqualify
} from '../actions';
import { ReceiveScheduledDisqualificationMail, ReceiveSentDisqualificationMail } from '../email-compose/actions';
import { Candidate } from '../state';

const initialState: Map<string, OrderedMap<string, Candidate>> = Map();

export function candidates(state = initialState, action: Action): Map<string, OrderedMap<string, Candidate>> {
    switch (action.type) {
        case MoveCandidateToStage: {
            const candidate: Candidate = Object.assign({}, action.payload.candidate, { stage: action.payload.stage });
            const jobCandidates = state.get(candidate.jobId);
            return state.set(candidate.jobId, jobCandidates.set(candidate.personId, candidate));
        }
        case ReceiveReassignCandidateAssignee:
        case ReceiveSearchResultPersonAndCandidate:
        case ReceiveAddSearchResultToJob:
        case ReceiveCandidateAddedToJob:
        case ReceiveCandidateUpdate: {
            if ((action.payload as any).errors || !action.payload.candidate) {
                return state;
            } else {
                const candidate = action.payload.candidate;
                const jobCandidates = state.get(candidate.jobId) || OrderedMap();
                return state.set(candidate.jobId, jobCandidates.set(candidate.personId, candidate));
            }
        }
        // When we receive a bunch of candidates for with different jobs
        case ReceiveCrossAddCandidate:
        case ReceivePersonOptOutUpdate:
        case ReceivePersonSetBlacklisted:
        case ReceiveUserBlacklistPerson:
        case ReceiveMoveInGmail: {
            const updatedCandidates = action.payload.candidates;
            let updatedState = state;

            for (const candidate of updatedCandidates) {
                const jobCandidates = state.get(candidate.jobId);
                if (jobCandidates) {
                    updatedState = updatedState.set(candidate.jobId, jobCandidates.set(candidate.personId, candidate));
                }
            }
            return updatedState;
        }
        case ReceiveScheduledDisqualificationMail:
        case ReceiveSentDisqualificationMail:
        case ReceiveCrossAddMultiple:
        case ReceiveAccountManagerData:
        case ReceiveJobAssigneeData:
        case ReceiveCandidateAssigneeData: {
            const newCandidates = action.payload.candidates;
            let newState = state;
            for (const candidate of newCandidates) {
                const jobCandidates = newState.get(candidate.jobId) || OrderedMap<string, Candidate>();
                newState = newState.set(candidate.jobId, jobCandidates.set(candidate.personId, candidate));
            }
            return newState;
        }
        case ReceiveCandidate: {
            const { jobId, personId } = action.payload.candidate;
            return state.set(jobId, (state.get(jobId) || OrderedMap()).set(personId, action.payload.candidate));
        }
        case RequestCandidateDisqualify: {
            const candidate: Candidate = Object.assign({}, action.payload.candidate, {
                disqualReason: action.payload.reason,
                disqualified: !action.payload.qualified
            });
            const jobCandidates = state.get(candidate.jobId);
            return state.set(candidate.jobId, jobCandidates.set(candidate.personId, candidate));
        }
        case ReceiveInitialOutreachForJob: {
            const outreachCandidates = action.payload.candidates;
            if (!isEmpty(outreachCandidates)) {
                const jobId = outreachCandidates[0].jobId;
                let stateAfterOutreach = state.get(outreachCandidates[0].jobId);
                for (const candidate of outreachCandidates) {
                    stateAfterOutreach = stateAfterOutreach.set(candidate.personId, candidate);
                }
                return state.set(jobId, stateAfterOutreach);
            }
            return state;
        }
        case ReceivePurgeCandidatesResponse: {
            const existingCandidates = state.get(action.payload.id);
            const newCandidates = existingCandidates.filter(
                (v) =>
                    v.stage !== sourcedStage &&
                    v.stage !== emailFoundStage &&
                    v.stage !== emailNotFoundStage &&
                    v.stage !== emailRequestedStage
            );
            return state.set(action.payload.id, newCandidates);
        }
        case ArchiveMessage: {
            const { account, messageId, personId } = action.payload;
            if (personId) {
                const jobCandidates = state.filter(
                    (job) =>
                        job.has(personId) &&
                        job
                            .get(personId)
                            .pendingEmails.filter(
                                (pending) => pending.account === account && pending.messageId === messageId
                            ).length > 0
                );
                return jobCandidates.reduce((updatedState, job) => {
                    const candidate = job.get(personId);
                    const pendingEmailsCount =
                        candidate.pendingEmailsCount -
                        candidate.pendingEmails.filter((pending) => pending.account === account).length;
                    return updatedState.set(
                        candidate.jobId,
                        job.set(personId, Object.assign({}, candidate, { pendingEmailsCount }))
                    );
                }, state);
            }
            return state;
        }
        case ReceivePersonDetails: {
            let newState = state;
            for (const candidate of action.payload.details.candidates) {
                const { jobId } = candidate;
                const candidatesForJob = newState.get(jobId) || Map<string, Candidate>();
                newState = newState.set(jobId, candidatesForJob.set(candidate.personId, candidate));
            }
            return newState;
        }
        case ReceivePersonCommunications: {
            let newState = state;
            for (const candidate of action.payload.candidates || []) {
                const { jobId } = candidate;
                const candidatesForJob = newState.get(jobId);
                if (candidatesForJob && candidatesForJob.has(candidate.personId)) {
                    newState = newState.set(jobId, candidatesForJob.set(candidate.personId, candidate));
                }
            }
            return newState;
        }
        default:
            return state;
    }
}
