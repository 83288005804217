import { Paper } from 'material-ui';
import * as React from 'react';

import { EmailAddress } from 'shared/types/email-compose';

import { fullDateTime } from '../common/timestamp';
import { JobTitle } from '../containers/job-title';
import { EmailAddress as Address } from '../sfc/email-address';

interface CommunicationHeaderDetailsProps {
    jobIds: string[];
    account: EmailAddress;
    from: EmailAddress;
    to: EmailAddress[];
    cc: EmailAddress[];
    bcc: EmailAddress[];
    subject: string;
    date: number;
}

export const CommunicationHeaderDetails: React.FC<CommunicationHeaderDetailsProps> = (props) => {
    const jobs = props.jobIds
        ? props.jobIds.map((j) => (
              <div className="header-text" key={j}>
                  <JobTitle id={j} />
              </div>
          ))
        : null;
    const account = (
        <div className="email-header">
            <div className="label">Account</div>
            <Address address={props.account} />
        </div>
    );
    const from = (
        <div className="email-header">
            <div className="label">From</div>
            <Address address={props.from} />
        </div>
    );
    const toHeaders = props.to.map((e, i) => <Address address={e} key={i} />);
    const to =
        props.to.length > 0 ? (
            <div className="email-header">
                <div className="label">To</div>
                {toHeaders}
            </div>
        ) : null;
    const ccHeaders = props.cc.map((e, i) => <Address address={e} key={i} />);
    const cc =
        props.cc.length > 0 ? (
            <div className="email-header">
                <div className="label">Cc</div>
                {ccHeaders}
            </div>
        ) : null;
    const bccHeaders = props.bcc.map((e, i) => <Address address={e} key={i} />);
    const bcc =
        props.bcc.length > 0 ? (
            <div className="email-header">
                <div className="label">Bcc</div>
                {bccHeaders}
            </div>
        ) : null;
    const date = (
        <div className="email-header">
            <div className="label">Date</div>
            <div className="header-text">{fullDateTime(props.date)}</div>
        </div>
    );
    const subject = props.subject ? (
        <div className="email-header">
            <div className="label">Subject</div>
            <div className="header-text">{props.subject}</div>
        </div>
    ) : null;
    return (
        <Paper className="email-headers-details">
            <div className="email-header">
                <div className="label">Job</div>
                <div className="email-header-jobs-list">{jobs}</div>
            </div>
            {account}
            {from}
            {to}
            {cc}
            {bcc}
            {date}
            {subject}
        </Paper>
    );
};
