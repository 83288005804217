import React from 'react';

interface BillingDataContextValue {
    refetch?: () => void;
}

const BillingDataContext = React.createContext<BillingDataContextValue>({});

export const BillingDataProvider: React.FC<BillingDataContextValue & { children: React.ReactNode }> = ({
    children,
    ...value
}) => {
    return <BillingDataContext.Provider value={value}>{children}</BillingDataContext.Provider>;
};

export const useBillingData = () => {
    return React.useContext(BillingDataContext);
};
