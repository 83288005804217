import { useLazyQuery, useMutation } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    LinearProgress,
    Menu,
    MenuItem,
    Theme,
    Tooltip,
    Typography,
    useTheme
} from '@material-ui/core';
import { Error, Receipt } from '@material-ui/icons';
import React from 'react';

import { hasRole } from 'shared/models/user';

import { fullDate } from '../common/timestamp';
import { client as apolloClient } from '../graphql/apollo-client';
import {
    APPLY_FEE_TO_LINE_ITEM,
    CLIENT_FEES,
    Fee as FeeData,
    FEE_DETAILS,
    Invoice as InvoiceData,
    InvoiceLineItem
} from '../graphql/queries/billing';
import { useBillingData } from '../hooks/use-billing-data';
import { FeesProvider } from '../hooks/use-fees';
import { useModal } from '../hooks/use-modal';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';
import { FeesList } from './fees-list';
import { JobFeeForm } from './job-fee-form';
import { PlacementFeeForm } from './placement-fee-form';

const styles = (theme: Theme) => css`
    width: 640px;

    .field {
        display: flex;

        .info-label {
            flex: 0 0 auto;
            width: 120px;
        }
    }

    .invoice-headers {
        margin-top: 20px;
        display: flex;
        flex-direction: column;

        &.customer {
            align-items: flex-end;
            margin-top: 0;
        }
    }

    .line-items {
        margin-top: 40px;
        margin-bottom: 10px;

        .line-item-field {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            &.item-row {
                font-size: 16px;
                line-height: 20px;
                margin: 5px 0 5px;
            }

            .MuiIconButton-root {
                margin-right: 5px;
                margin-left: -5px;
                margin-top: -3px;
            }

            .line-item-description {
                flex: 1 1 auto;

                &.unmatched {
                    color: ${theme.palette.error.main};
                }
            }

            .line-item-amount {
                flex: 0 0 auto;
                width: 120px;
                text-align: right;

                &.unmatched {
                    color: ${theme.palette.error.main};
                }
            }

            &.header {
                font-weight: 600;
                border-bottom: 2px solid black;
                padding-bottom: 5px;
                margin-bottom: 5px;
            }
        }
    }

    .totals {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 20px;
        margin-bottom: 40px;

        .totals-field {
            display: flex;
            justify-content: space-between;
            padding-top: 2px;

            .total-label {
                font-weight: 500;
                text-align: right;
            }

            .total-amount {
                width: 120px;
                text-align: right;
            }
        }
    }
`;

const feeSelectDialogStyles = css`
    .MuiDialogContent-root {
        min-width: 640px;
        padding: 0;
    }

    .MuiListItem-root {
        padding-left: 25px;
    }

    .fee-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;

export const InvoiceDetails: React.FC<{ data: InvoiceData }> = ({ data }) => {
    const theme = useTheme();
    const [selectedFeeId, setSelectedFeeId] = React.useState<string | undefined>(undefined);
    const [selectedLineItem, setSelectedLineItem] = React.useState<InvoiceLineItem | undefined>(undefined);
    const [fetchFeeData, { data: feeData }] = useLazyQuery<{ fee: FeeData }, { id: string }>(FEE_DETAILS);
    const [fetchClientFees, { data: clientFees, refetch: refetchClientFees }] = useLazyQuery<
        { fees: FeeData[] },
        { clientId: string }
    >(CLIENT_FEES, {
        variables: { clientId: data?.customer?.clientId }
    });
    const [applyFee] = useMutation<{ updatedLineItem: { feeId: string } }, { id: string; data: { feeId: string } }>(
        APPLY_FEE_TO_LINE_ITEM,
        {
            client: apolloClient('billing_admin')
        }
    );
    const { showLoading, hideLoading } = useModal();
    const { userPermissions } = useSession();
    const { setSnackbar } = useSnackbar();
    const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);
    const [activeFeeId, setActiveFeeId] = React.useState<string | null>(null);
    const { refetch } = useBillingData();

    const canEdit = hasRole(userPermissions, 'billing_admin');

    const handleViewFee = (feeId: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
        if (canEdit) {
            setMenuAnchor(event.currentTarget);
            setActiveFeeId(feeId);
        } else {
            setSelectedFeeId(feeId);
            showLoading();
            fetchFeeData({ variables: { id: feeId } }).finally(() => {
                hideLoading();
            });
        }
    };

    const handleSelectFeeForLineItem = (lineItem: InvoiceLineItem) => async () => {
        if (canEdit) {
            setSelectedLineItem(lineItem);
            await fetchClientFees();
        }
    };

    const handleApplyFee = async (feeId: string | null) => {
        setSelectedLineItem(undefined);
        handleCloseMenu();
        showLoading();
        try {
            const message = feeId ? 'Applying fee to line item...' : 'Removing fee from line item...';
            setSnackbar(message);

            const lineItemId = feeId
                ? selectedLineItem?.id
                : data.lineItems.find((item) => item.feeId === activeFeeId)?.id;

            if (lineItemId) {
                await applyFee({
                    variables: {
                        data: { feeId },
                        id: lineItemId
                    }
                });
                refetch();
                if (data.customer.clientId) {
                    refetchClientFees();
                }
                setSnackbar(feeId ? 'Fee applied to line item' : 'Fee removed from line item');
            }
        } catch (e) {
            setSnackbar(feeId ? 'Error applying fee to line item' : 'Error removing fee from line item');
        } finally {
            hideLoading();
        }
    };

    const handleSelectFee = async (feeId: string) => {
        await handleApplyFee(feeId);
    };

    const handleCloseFeeDialog = () => {
        setSelectedFeeId(undefined);
    };

    const handleCloseSelectFeeForLineItemDialog = () => {
        setSelectedLineItem(undefined);
    };

    const handleCloseMenu = () => {
        setMenuAnchor(null);
        setActiveFeeId(null);
    };

    const handleViewFeeDetails = async () => {
        handleCloseMenu();
        if (activeFeeId) {
            setSelectedFeeId(activeFeeId);
            showLoading();
            await fetchFeeData({ variables: { id: activeFeeId } });
            hideLoading();
        }
    };

    const handleRemoveFee = async () => {
        await handleApplyFee(null);
    };

    if (!data) {
        return <div css={styles(theme)}>Invoice removed</div>;
    }

    const lineItems = data.lineItems?.map((lineItem) => {
        const unmatched = !lineItem.feeId;
        const additionalClass = unmatched ? 'unmatched' : '';
        const button = !unmatched ? (
            <Tooltip title={canEdit ? 'Fee options' : 'View fee'}>
                <IconButton size="small" onClick={handleViewFee(lineItem.feeId)}>
                    <Receipt fontSize="small" />
                </IconButton>
            </Tooltip>
        ) : (
            <Tooltip title="No fee associated with this line item">
                <IconButton size="small" onClick={canEdit ? handleSelectFeeForLineItem(lineItem) : undefined}>
                    <Error fontSize="small" htmlColor={theme.palette.error.main} />
                </IconButton>
            </Tooltip>
        );
        return (
            <div key={lineItem.id} className="line-item-field item-row">
                {button}
                <span className={`line-item-description ${additionalClass}`}>{lineItem.description}</span>
                <span className={`line-item-amount ${additionalClass}`}>${lineItem.amount?.toLocaleString()}</span>
            </div>
        );
    });

    const paidAmount = data.payments?.reduce((acc, p) => p.amount + acc, 0) ?? 0;
    const dueAmount = data.totalAmount - paidAmount;

    const feeDialog =
        !selectedFeeId || !feeData || feeData.fee.id !== selectedFeeId ? null : feeData.fee.type === 'placement' ||
          feeData.fee.type === 'monthly-contractor' ? (
            <PlacementFeeForm data={feeData.fee} onClose={handleCloseFeeDialog} disabled={true} />
        ) : (
            <JobFeeForm data={feeData.fee} onClose={handleCloseFeeDialog} disabled={true} />
        );

    let selectFeeForLineItemDialog = null;
    if (selectedLineItem) {
        let content = null;
        if (clientFees) {
            content = (
                <FeesProvider
                    data={clientFees.fees}
                    refetch={fetchClientFees}
                    disabled={true}
                    onSelectFee={handleSelectFee}
                >
                    <FeesList />
                </FeesProvider>
            );
        } else {
            content = <LinearProgress variant="indeterminate" />;
        }
        selectFeeForLineItemDialog = (
            <Dialog
                open={true}
                onClose={handleCloseSelectFeeForLineItemDialog}
                maxWidth="md"
                css={feeSelectDialogStyles}
            >
                <DialogTitle>
                    <Typography variant="h4" component="div">
                        Select Fee
                    </Typography>
                </DialogTitle>
                <DialogContent>{content}</DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSelectFeeForLineItemDialog}>Cancel</Button>
                </DialogActions>
            </Dialog>
        );
    }

    const feeActionsMenu = canEdit ? (
        <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={handleCloseMenu}>
            <MenuItem dense={true} onClick={handleViewFeeDetails}>
                View Fee
            </MenuItem>
            <MenuItem dense={true} onClick={handleRemoveFee}>
                Remove Fee
            </MenuItem>
        </Menu>
    ) : null;

    return (
        <div css={styles(theme)}>
            <div className="invoice-headers customer">
                <div className="field">
                    <div className="info-value">{data.customer.name}</div>
                </div>
                <div className="field">
                    <div className="info-value">
                        {data.customer.contact?.firstName} {data.customer.contact?.lastName}
                    </div>
                </div>
                <div className="field">
                    <div className="info-value">{data.customer.email}</div>
                </div>
            </div>
            <div className="invoice-headers">
                <div className="field">
                    <div className="info-label">Invoice</div>
                    <div className="info-value">{data.invoiceNumber}</div>
                </div>
                <div className="field">
                    <div className="info-label">Invoice Date</div>
                    <div className="info-value">{fullDate(data.invoiceDate)}</div>
                </div>
                <div className="field">
                    <div className="info-label">Due Date</div>
                    <div className="info-value">{fullDate(data.dueDate)}</div>
                </div>
            </div>
            <div className="line-items">
                <div className="line-item-field header">
                    <div className="line-item-description">Description</div>
                    <div className="line-item-amount">Amount</div>
                </div>
                {lineItems}
            </div>
            <div className="totals">
                <div className="totals-field">
                    <div className="total-label">Total</div>
                    <div className="total-amount">${data.totalAmount?.toLocaleString()}</div>
                </div>
                <div className="totals-field">
                    <div className="total-label">Payments</div>
                    <div className="total-amount">${paidAmount.toLocaleString()}</div>
                </div>
                <div className="totals-field">
                    <div className="total-label">Amount Due</div>
                    <div className="total-amount">${dueAmount.toLocaleString()}</div>
                </div>
            </div>
            {feeActionsMenu}
            {selectFeeForLineItemDialog}
            {feeDialog}
        </div>
    );
};
