import { Dialog } from '@material-ui/core';
import * as React from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { JobPageHeader } from '../components/job-page-header';

import { CandidateDetails } from '../containers/candidate-details';

interface RouterProps {
    personId: string;
    jobId: string;
}

const CandidatePageComponent: React.FC<RouteComponentProps<RouterProps>> = ({ match }) => {
    const [dialogOpen, setDialogOpen] = React.useState(true);
    const { jobId, personId } = match.params;

    const handleDialogClose = () => setDialogOpen(false);

    if (!dialogOpen) {
        return <Redirect to={`/job/${jobId}/board`} push={true} />;
    }

    return (
        <div id="container">
            <JobPageHeader jobId={jobId} actions={[]} activeTab="Candidates" />

            <Dialog
                open={true}
                onClose={handleDialogClose}
                disableEnforceFocus={true}
                maxWidth="xl"
                className="profile-dialog-v4"
            >
                <CandidateDetails id={personId} jobId={jobId} />
            </Dialog>
        </div>
    );
};

export const CandidatePage = withRouter(CandidatePageComponent);
