import { css } from '@emotion/core';
import {
    Avatar,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Theme,
    Tooltip,
    Typography,
    useTheme
} from '@material-ui/core';
import { AttachMoney, CheckCircleOutline } from '@material-ui/icons';
import React from 'react';

import { feeTypeLabel } from 'shared/models/fee';

import { interviewTime, timeMonthYear } from '../../common/timestamp';
import { Fee } from '../../graphql/queries/billing';
import { useFees } from '../../hooks/use-fees';
import { useSession } from '../../hooks/use-session';
import { notificationStyles } from './notification-styles';

const styles = (theme: Theme) => css`
    ${notificationStyles(theme)}

    .item-actions {
        display: flex;
        position: absolute;
        bottom: 10px;
        right: 12px;
        opacity: 0;

        .MuiIconButton-root {
            margin-left: 5px;
        }
    }

    &:hover {
        background: rgba(0, 0, 0, 0.025);
        .item-actions {
            opacity: 1;
        }
    }
`;

export const FeeCreatedNotification: React.FC<{ fee: Fee; userId: string; onComplete: () => void }> = ({
    fee,
    userId,
    onComplete
}) => {
    const theme = useTheme();
    const { user } = useSession();
    const { onEditFee } = useFees();

    const handleToggleDetails = () => {
        onEditFee?.(fee);
    };

    const actions =
        user.id === userId ? (
            <div className="item-actions">
                <Tooltip title="OK">
                    <IconButton size="small" onClick={onComplete}>
                        <CheckCircleOutline fontSize="small" />
                    </IconButton>
                </Tooltip>
            </div>
        ) : null;

    const primary = (
        <Typography variant="h6">
            {fee.client.name} - {fee.job.title}
        </Typography>
    );
    const secondary = (
        <Typography variant="body2" color="textSecondary" component="div">
            {feeTypeLabel(fee.type)} fee created
            <br />${fee.amount.toLocaleString()}
            <br />
            {timeMonthYear(fee.incurredAt)}
        </Typography>
    );

    return (
        <ListItem css={styles(theme)} onClick={handleToggleDetails} button={true}>
            <ListItemAvatar>
                <Avatar>
                    <AttachMoney />
                </Avatar>
            </ListItemAvatar>
            <ListItemText disableTypography={true} primary={primary} secondary={secondary} />
            <div>
                <Typography color="textSecondary" noWrap={true} variant="caption" className="caption" component="div">
                    <span className="left-pad">{interviewTime(fee.createdAt)}</span>
                </Typography>
            </div>
            {actions}
        </ListItem>
    );
};
