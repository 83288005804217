import { Action, AppVersionOutdated, CompleteSignout, ReceiveSession, RequestSession } from '../actions';
import { Session } from '../state';

const initialState: Session = {
    appVersionOutdated: false,
    authToken: null,
    fetching: false,
    initialized: false,
    user: null,
    userPermissions: null
};

export function session(state = initialState, action: Action): Session {
    switch (action.type) {
        case CompleteSignout:
            return initialState;
        case RequestSession:
            return Object.assign({}, state, { fetching: true });
        case ReceiveSession:
            return Object.assign({}, state, { fetching: false, initialized: true }, action.session);
        case AppVersionOutdated:
            return Object.assign({}, state, { appVersionOutdated: true });
        default:
            return state;
    }
}
