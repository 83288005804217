import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import * as classNames from 'classnames';
import * as React from 'react';

import { JobStageData } from 'shared/models/job-stages';
import { Permissions } from 'shared/models/permission';
import { BASELINE_SEARCH_SORT_RANK } from 'shared/models/search';
import { UserData } from 'shared/models/user';

import { timeRelativeDay } from '../common/timestamp';
import { User } from '../sfc/user';
import { Job, JobProgressCounts, Search, SearchStatus } from '../state';
import { JobSearchesTitle } from './job-searches-title';

const styles = {
    headerCol: { height: 48 }
};

interface JobSearchesProgressProps {
    searches: Search[];
    progressCounts: JobProgressCounts[];
    displayPercent: 'count' | 'percent' | 'both';
    onDisplaySearchInfo: (searchId: string) => void;
    onCloneSearch: (data: Search) => void;
    onUpdateSearch: (id: string, updates: Partial<Search>, refetchSearches: boolean) => void;
    onDeleteSearch: (data: Search) => void;
    jobStages: JobStageData[];
    job: Job;
    user: UserData;
    userPermissions: Permissions;
}

export class JobSearchesProgress extends React.Component<JobSearchesProgressProps> {
    handleViewSearchInfo = (searchId: string) => () => {
        if (searchId) {
            this.props.onDisplaySearchInfo(searchId);
        }
    };

    getStageProgressCount = (
        searchId: string,
        sourcedBy: 'ai_sourced' | 'recruiter_sourced',
        stage: string,
        percentOfStage: string
    ) => {
        const { displayPercent, progressCounts, jobStages } = this.props;
        const searchCounts = progressCounts.find((p) => p.searchId === searchId && p.sourcedBy === sourcedBy);
        if (!searchCounts) return 0;
        const stageCount = (stg: string) => {
            let ctr = 0;
            const stgStage = jobStages.find((s) => s.name === stg);
            for (const c of searchCounts.counts) {
                const cStage = jobStages.find((s) => s.name === c.stage);
                if (stg === c.stage || (stgStage && cStage && stgStage.id <= cStage.id)) {
                    ctr += c.count;
                }
            }
            return ctr;
        };
        const count = stageCount(stage);
        if (displayPercent !== 'count' && percentOfStage) {
            const totalCount = stageCount(percentOfStage);
            if (totalCount === 0) return count;
            const percent = Math.ceil((count * 100) / totalCount); // tslint:disable-line:no-magic-numbers
            if (displayPercent === 'percent') {
                return <span>{percent}%</span>;
            } else {
                return (
                    <span>
                        {count}{' '}
                        <span className="secondary">
                            ({percent}
                            %)
                        </span>
                    </span>
                );
            }
        }
        return count;
    };

    render() {
        const { searches, progressCounts, jobStages, job, userPermissions } = this.props;
        let rowData: Array<{
            createdAt?: number;
            createdBy?: string;
            id: string;
            name: string;
            search?: Search;
            sourcedBy: 'ai_sourced' | 'recruiter_sourced';
            userId?: string;
        }> = searches
            .filter((s) => s.status !== SearchStatus.Initial)
            .map((s) => ({
                createdAt: s.createdAt,
                createdBy: s.createdBy,
                id: s.id,
                name: s.name,
                search: s,
                sourcedBy: 'ai_sourced' as 'ai_sourced',
                userId: s.userId
            }));
        if (progressCounts.find((p) => p.searchId === null && p.sourcedBy === 'recruiter_sourced')) {
            rowData = rowData.concat([{ name: 'Manually Sourced', id: null, sourcedBy: 'recruiter_sourced' }]);
        }
        if (progressCounts.find((p) => p.searchId === null && p.sourcedBy === 'ai_sourced')) {
            rowData = rowData.concat([{ name: 'Old AI searches', id: null, sourcedBy: 'ai_sourced' }]);
        }
        const rows = rowData.map((s) => {
            const key = s.id ? s.id : s.name;
            const addedCount = this.getStageProgressCount(s.id, s.sourcedBy, jobStages[0].name, undefined);
            const emailedCount = this.getStageProgressCount(s.id, s.sourcedBy, 'outreach_sent', jobStages[0].name);
            const respondedCount = this.getStageProgressCount(s.id, s.sourcedBy, 'response_received', 'outreach_sent');
            const notQualifiedCount = this.getStageProgressCount(
                s.id,
                s.sourcedBy,
                'not_qualified_on_review',
                'response_received'
            );
            const sentToClientCount = this.getStageProgressCount(
                s.id,
                s.sourcedBy,
                'awaiting_client_feedback',
                'response_received'
            );
            const acceptedCount = this.getStageProgressCount(
                s.id,
                s.sourcedBy,
                'client_first_round',
                'awaiting_client_feedback'
            );
            const offerCount = this.getStageProgressCount(s.id, s.sourcedBy, 'offer', 'client_first_round');
            const hiredCount = this.getStageProgressCount(s.id, s.sourcedBy, 'hired', 'offer');

            const title = (
                <JobSearchesTitle
                    onCloneSearch={this.props.onCloneSearch}
                    onUpdateSearch={this.props.onUpdateSearch}
                    onDeleteSearch={this.props.onDeleteSearch}
                    search={s.search}
                    job={job}
                    name={s.name}
                    user={this.props.user}
                    userPermissions={userPermissions}
                />
            );

            const classes = classNames({
                baseline: s.search?.sortRank === BASELINE_SEARCH_SORT_RANK,
                inactive: s.search?.status !== SearchStatus.Active
            });

            return (
                <TableRow key={key} hover={true} className={classes}>
                    <TableCell>{title}</TableCell>
                    <TableCell>{addedCount}</TableCell>
                    <TableCell>{emailedCount}</TableCell>
                    <TableCell>{respondedCount}</TableCell>
                    <TableCell>{notQualifiedCount}</TableCell>
                    <TableCell>{sentToClientCount}</TableCell>
                    <TableCell>{acceptedCount}</TableCell>
                    <TableCell>{offerCount}</TableCell>
                    <TableCell>{hiredCount}</TableCell>
                    <TableCell>{s.createdAt ? timeRelativeDay(s.createdAt) : '-'}</TableCell>
                    <TableCell>{s.userId ? <User id={s.userId} /> : '-'}</TableCell>
                    <TableCell>{s.createdBy ? <User id={s.createdBy} /> : '-'}</TableCell>
                </TableRow>
            );
        });

        return (
            <Paper>
                <TableContainer>
                    <Table stickyHeader={true} size="small">
                        <TableHead className="jobs-table-header">
                            <TableRow style={styles.headerCol}>
                                <TableCell>Title</TableCell>
                                <TableCell>Added</TableCell>
                                <TableCell>Emailed</TableCell>
                                <TableCell>Responded</TableCell>
                                <TableCell>Not Qualified</TableCell>
                                <TableCell>Sent to Client</TableCell>
                                <TableCell>Accepted</TableCell>
                                <TableCell>Offer</TableCell>
                                <TableCell>Hired</TableCell>
                                <TableCell>Created</TableCell>
                                <TableCell>Owner</TableCell>
                                <TableCell>Created By</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{rows}</TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        );
    }
}
