import moment from 'moment';

import { getClosestWorkingDay } from './timestamp';

export interface Duration {
    start: number;
    end: number;
    baselineStart: number;
    baselineEnd: number;
    period: string;
    baselinePeriod: string;
}

interface DurationOpt extends Duration {
    label: string;
}

export interface Periods {
    days?: number;
    weeks?: number;
    fortnights?: number;
    months?: number;
    quarters?: number;
    years?: number;
    allTime?: boolean;
}

export const defaultPeriods: Periods = {
    allTime: true,
    days: 0,
    fortnights: 0,
    months: 2,
    quarters: 4,
    weeks: 0,
    years: 2
};

const clampTime = (ts: number) => {
    if (ts > Date.now()) {
        return moment().endOf('day').valueOf();
    }
    return ts;
};

export const defaultDateFormat = 'MMM DD, YYYY';

const daysInFortnight = 14;

const dayPeriodDesc = (start: number) =>
    moment(start).startOf('day').valueOf() === moment().startOf('day').valueOf()
        ? 'Today'
        : moment(start).startOf('day').valueOf() === moment().subtract(1, 'day').startOf('day').valueOf()
          ? 'Yesterday'
          : moment(start).format('dddd');

interface CreateDurationParams {
    period: 'day' | 'week' | 'fortnight' | 'month' | 'quarter' | 'year';
    offset: number;
    baselineOffset?: number;
}

export const createDuration = ({ period, offset, baselineOffset = 1 }: CreateDurationParams): DurationOpt => {
    let end: number;
    let start: number;
    let baselineStart: number;
    let baselineEnd: number;
    let label: string;
    let baselinePeriod: string;

    if (period === 'day') {
        const current = getClosestWorkingDay(moment().subtract(offset, 'days').valueOf());
        end = clampTime(moment(current).endOf('day').valueOf());
        start = clampTime(moment(current).startOf('day').valueOf());

        const baselineDay = getClosestWorkingDay(moment(start).subtract(1, 'day').valueOf());
        baselineStart = moment(baselineDay).startOf('day').valueOf();
        baselineEnd = moment(baselineDay).endOf('day').valueOf();

        label = dayPeriodDesc(start);
        baselinePeriod = dayPeriodDesc(baselineStart);
    } else if (period === 'fortnight') {
        if (offset === 0) {
            // Current fortnight
            end = clampTime(moment().endOf('day').valueOf());
            start = moment(end)
                .subtract(daysInFortnight - 1, 'days')
                .startOf('day')
                .valueOf();
            baselineStart = moment(start).subtract(daysInFortnight, 'days').startOf('day').valueOf();
            baselineEnd = moment(start).subtract(1, 'day').endOf('day').valueOf();
        } else {
            // Past fortnights
            end = moment()
                .subtract(offset * daysInFortnight, 'days')
                .endOf('day')
                .valueOf();
            start = moment(end)
                .subtract(daysInFortnight - 1, 'days')
                .startOf('day')
                .valueOf();
            baselineStart = moment(start).subtract(daysInFortnight, 'days').startOf('day').valueOf();
            baselineEnd = moment(start).subtract(1, 'day').endOf('day').valueOf();
        }
        label =
            offset === 0
                ? 'Last 14 days'
                : `${moment(start).format(defaultDateFormat)} - ${moment(end).format(defaultDateFormat)}`;
        baselinePeriod =
            offset === 0
                ? 'Previous 14 days'
                : `${moment(baselineStart).format(defaultDateFormat)} - ${moment(baselineEnd).format(defaultDateFormat)}`;
    } else {
        // Handle other periods (week, month, quarter, year)
        const periodUnit = period; // Type narrowing
        end = clampTime(moment().subtract(offset, periodUnit).endOf(periodUnit).valueOf());
        start = clampTime(moment().subtract(offset, periodUnit).startOf(periodUnit).valueOf());
        baselineStart = moment(start).subtract(baselineOffset, periodUnit).startOf(periodUnit).valueOf();

        if (offset === 0) {
            baselineEnd = moment().subtract(1, periodUnit).endOf('day').valueOf();
        } else {
            baselineEnd = moment(baselineStart).endOf(periodUnit).valueOf();
        }

        if (period === 'week') {
            label =
                offset === 0
                    ? 'This Week'
                    : offset === 1
                      ? 'Previous Week'
                      : `${moment(start).format(defaultDateFormat)} - ${moment(end).format(defaultDateFormat)}`;
        } else if (period === 'month') {
            label = moment().subtract(offset, period).format('MMMM YYYY');
        } else if (period === 'quarter') {
            label = moment().subtract(offset, period).format('[Q]Q YYYY');
        } else {
            label = moment().subtract(offset, period).format('YYYY');
        }

        baselinePeriod = `${moment(baselineStart).format(defaultDateFormat)} - ${moment(baselineEnd).format(defaultDateFormat)}`;
    }

    return {
        baselineEnd,
        baselinePeriod,
        baselineStart,
        end,
        label,
        period: label,
        start
    };
};

export const getDurationOptions = ({ days, fortnights, weeks, months, quarters, years, allTime } = defaultPeriods) => {
    const options: DurationOpt[] = [];

    let lastDayAdded = 0;
    for (let i = 0; i < days; i++) {
        const duration = createDuration({ period: 'day', offset: lastDayAdded });
        lastDayAdded = moment().diff(moment(duration.start), 'days') + 1;
        options.push(duration);
    }

    for (let i = 0; i < weeks; i++) {
        options.push(createDuration({ period: 'week', offset: i }));
    }

    for (let i = 0; i < (fortnights ?? 0); i++) {
        options.push(createDuration({ period: 'fortnight', offset: i }));
    }

    for (let i = 0; i < months; i++) {
        options.push(createDuration({ period: 'month', offset: i }));
    }

    for (let i = 0; i < quarters; i++) {
        options.push(createDuration({ period: 'quarter', offset: i }));
    }

    for (let i = 0; i < years; i++) {
        options.push(createDuration({ period: 'year', offset: i }));
    }

    if (allTime) {
        options.push({
            baselineEnd: 0,
            baselinePeriod: '',
            baselineStart: 0,
            end: clampTime(moment().endOf('day').valueOf()),
            label: 'All Time',
            period: 'All Time',
            start: clampTime(0)
        });
    }

    return options;
};
