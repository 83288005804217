import { css } from '@emotion/core';
import { Theme } from '@material-ui/core';

export const styles = (theme: Theme) => css`
    background: #f4f6f8;
    flex: 1 1 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .metrics-container {
        overflow: hidden;
        padding: 15px 50px 20px;
        display: flex;
        flex-direction: column;

        .selectors {
            text-align: right;
            margin-bottom: 15px;
            flex: 0 0 auto;

            .MuiInputBase-root {
                margin-left: 15px;

                .MuiOutlinedInput-input {
                    padding-top: 14px;
                    padding-bottom: 14px;
                    background: white;
                }
            }
        }

        .chart-container {
            background: white;
            border-radius: ${theme.shape.borderRadius}px;
            flex: 1 1 auto;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            border: thin solid ${theme.palette.divider};

            .panel-header {
                padding: 15px 25px;
                border-bottom: thin solid ${theme.palette.divider};
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex: 0 0 auto;

                .inactive {
                    .MuiButton-label,
                    .MuiSvgIcon-fontSizeSmall {
                        opacity: 0.3;
                    }
                }

                .MuiButton-textSizeSmall {
                    padding: 4px 10px;
                    font-size: 0.75rem;
                }

                .view-selectors {
                    display: flex;
                    align-items: center;

                    .MuiButtonGroup-root {
                        margin-left: 20px;
                    }
                }
            }

            .panel-content {
                display: flex;
                overflow: hidden;

                .chart {
                    padding: 10px 20px;
                    flex: 1 1 auto;
                    overflow: auto;
                    min-height: 50px;

                    &::-webkit-scrollbar {
                        width: 4px;
                    }
                }
            }
        }
    }
`;
