// cspell:disable

const domainRanks = [
    'gmail.com',
    'yahoo.com',
    'hotmail.com', // cSpell:ignore hotmail
    'outlook.com',
    'aol.com',
    'icloud.com',
    'qq.com',
    '163.com',
    'sbcglobal.net',
    'earthlink.net',
    'verizon.net',
    'comcast.net',
    'ymail.com',
    'me.com',
    'mac.com',
    'live.com',
    'rocketmail.com'
];

export const getPersonalDomainRank = (email: string) => {
    const domain = email.split('@')[1];
    const rank = domainRanks.indexOf(domain);
    if (rank !== -1) {
        return rank;
    } else if (domain.includes('alum') && domain.endsWith('.edu')) {
        return domainRanks.length;
    } else {
        return -1;
    }
};
