import { useMutation } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Theme,
    Typography,
    useTheme
} from '@material-ui/core';
import { max } from 'lodash';
import React from 'react';
import { Editor } from 'react-ce';

import { NoteData } from 'shared/models/note';
import { PersonRecord } from '../graphql/queries/candidates-search';
import { CREATE_NOTES } from '../graphql/queries/notes';

const addNotesStyle = (theme: Theme) => css`
    .MuiDialogTitle-root {
        border-bottom: thin solid ${theme.palette.divider};
    }

    .MuiDialogContentText-root {
        font-size: 14px;
        font-style: italic;
        margin-bottom: 0px;
        margin-top: 12px;
    }

    .text-editor-toolbar {
        border-bottom: thin solid ${theme.palette.divider};
        margin-left: -24px;
        margin-right: -24px;
    }
`;

interface CandidatesSearchNoteAddProps {
    persons: PersonRecord[];
    onCancel: () => void;
    onClose: () => void;
}

export const CandidatesSearchNoteAdd: React.FC<CandidatesSearchNoteAddProps> = (props) => {
    const theme = useTheme();
    const { persons, onCancel, onClose } = props;
    const [noteText, setNoteText] = React.useState<string>('');

    const [addNote, { loading: addingNote }] = useMutation<
        {
            insert_notes: {
                returning: Array<{
                    id: string;
                    createdBy: string;
                    content: string;
                }>;
            };
        },
        { notes: Array<Partial<NoteData>> }
    >(CREATE_NOTES);

    const handleClose = () => {
        onCancel();
    };

    const handleChange = (value: string) => {
        setNoteText(value);
    };

    const handleAddNotes = async () => {
        const notes: Array<Partial<NoteData>> = [];

        for (const person of persons) {
            const assignees = person.person.recentAssignees.map((a) => a.user.name);
            if (assignees.length > 0) {
                const notable = 'persons-'.concat(person.person.id);

                let content: string = noteText;
                for (const assignee of assignees) {
                    content = content.concat(' ').concat('@').concat(assignee).concat(' ');
                }

                const note: Partial<NoteData> = { content, format: 'html', notable };
                notes.push(note);
            }
        }
        if (notes.length > 0) {
            await addNote({ variables: { notes } });
        }
        onClose();
    };

    const disabled = max(persons.map((p) => p.person.recentAssignees.length)) > 0 ? false : true;

    const addAssigneeNotes = (
        <Dialog open={persons.length > 0 ? true : false} onClose={handleClose} maxWidth="md" css={addNotesStyle(theme)}>
            <DialogTitle>
                <Typography variant="h4" component="span">
                    Add Notes
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Editor value={noteText} onChange={handleChange} />
                <DialogContentText>
                    {persons.filter((f) => f.person.recentAssignees.length > 0).length} out of {persons.length}{' '}
                    candidates have recent assignees
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    key="add-notes"
                    disabled={noteText?.trim().length === 0 || addingNote || disabled}
                    onClick={handleAddNotes}
                >
                    {addingNote ? 'Saving' : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    );

    return <React.Fragment>{addAssigneeNotes}</React.Fragment>;
};
