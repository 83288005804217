import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AMActiveCandidates } from '../containers/am-active-candidates';

interface RouterProps {
    username: string;
}

const AMActiveCandidatesComponent: React.FC<RouteComponentProps<RouterProps>> = ({ match }) => {
    return <AMActiveCandidates username={match.params.username} />;
};

export const AMActiveCandidatesPage = withRouter(AMActiveCandidatesComponent);
