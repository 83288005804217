import * as React from 'react';

import { BlacklistCheck as BlacklistCheckType } from '../state';

export const blacklistChecks = (blacklistCheck: BlacklistCheckType): JSX.Element[] => {
    const validCandidateChecks = [];
    if (blacklistCheck.profileBlacklisted) {
        validCandidateChecks.push(
            <div className="candidate-blocked" key="profile">
                Profile in blacklist
            </div>
        );
    }
    if (blacklistCheck.userBlacklisted) {
        validCandidateChecks.push(
            <div className="candidate-blocked" key="profile">
                Profile in User blacklist
            </div>
        );
    }
    if (blacklistCheck.clientCompanyBlacklisted || blacklistCheck.clientPersonBlacklisted) {
        validCandidateChecks.push(
            <div className="candidate-blocked" key="client">
                Client specific blacklist
            </div>
        );
    }
    if (blacklistCheck.globalCompanyBlacklisted) {
        validCandidateChecks.push(
            <div className="candidate-blocked" key="global">
                Current company in blacklist
            </div>
        );
    }
    if (blacklistCheck.presentedToClient) {
        validCandidateChecks.push(
            <div className="candidate-invalid" key="client-active">
                Already presented to Client
            </div>
        );
    }
    if (blacklistCheck.hired) {
        validCandidateChecks.push(
            <div className="candidate-invalid" key="hired">
                Placed by Rocket
            </div>
        );
    }
    return validCandidateChecks;
};
