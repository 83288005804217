import { useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import { Theme, useTheme } from '@material-ui/core';
import React from 'react';

import { ActivityMetricType } from 'shared/models/activity-metric';
import { isoDate } from '../../common/timestamp';

import { ACTIVITY_METRICS, MetricsData } from '../../graphql/queries/metrics';
import { internalClientIds as excludeClientIds, JobType } from '../../state';
import { FunnelMetricsTimeline } from './funnel-metrics-timeline';

const styles = (theme: Theme) => css`
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .chart-container {
        flex: 1 1 auto;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        .panel-header {
            padding: 15px 25px;
            border-bottom: thin solid ${theme.palette.divider};
            flex: 0 0 auto;
        }

        .panel-content {
            overflow: hidden;
            display: flex;

            .chart {
                padding: 10px 20px;
                flex: 1 1 auto;
                overflow: auto;
                min-height: 50px;

                &::-webkit-scrollbar {
                    width: 4px;
                }
            }
        }
    }
`;

export const PlacementTrends: React.FC<{ startTime: number; endTime: number; jobTypes: JobType[] }> = ({
    startTime,
    endTime,
    jobTypes
}) => {
    const theme = useTheme();
    const kinds: ActivityMetricType[] = [
        'rocket_screen_complete',
        'awaiting_client_feedback',
        'client_first_round',
        'client_second_round',
        'offer',
        'hired'
    ];
    const { data } = useQuery<{ metrics: MetricsData[] }>(ACTIVITY_METRICS, {
        variables: {
            where: {
                date: { _lte: isoDate(endTime), _gte: isoDate(startTime) },
                job: { jobType: { _in: jobTypes }, clientId: { _nin: excludeClientIds } },
                kind: { _in: kinds }
            }
        }
    });
    return (
        <div css={styles(theme)}>
            <FunnelMetricsTimeline
                metrics={data?.metrics}
                duration={{ start: startTime, end: endTime }}
                fields={kinds}
                title="Trends"
            />
        </div>
    );
};
