import { useMutation } from '@apollo/client';
import { Button, Tooltip } from '@material-ui/core';
import React from 'react';

import { colors, getScoreColor } from '../common/score-ratings';
import { JobCPCandidate } from '../graphql/queries/job';
import { CREATE_PROFILE_SCORE, DELETE_PROFILE_SCORE } from '../graphql/queries/profile-score';
import { useAIProfileScoreDetails } from '../hooks/use-ai-profile-score-details';
import { useSlides } from '../hooks/use-candidate-slides';
import { AdornmentGenerator, useProfileAdornments } from '../hooks/use-profile-adornment';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';
import { AIProfileScoreExplanation } from './ai-profile-score-explanation';
import { DataPanel, DataPanelColumn } from './data-panel';
import { StarRating } from './star-rating';

type Column = 'name' | 'score' | 'assignee' | 'job' | 'stage';

const columns: Array<DataPanelColumn<Column>> = [
    { id: 'name', label: 'Name' },
    { id: 'score', label: 'Score' },
    { id: 'assignee', label: 'Assignee' },
    { id: 'job', label: 'Job' },
    { id: 'stage', label: 'Stage' }
];

export const JobCPCandidatesTable: React.FC<{ jobId: string; candidates: JobCPCandidate[]; refetch: () => void }> = ({
    jobId,
    candidates,
    refetch
}) => {
    const [createScore, { loading: creating }] = useMutation<
        { score: { id: string } },
        { personId: string; jobId: string; score: number }
    >(CREATE_PROFILE_SCORE);
    const [deleteScore, { loading: deleting }] = useMutation<{}, { id: string }>(DELETE_PROFILE_SCORE);
    const { setList } = useSlides();
    const { setAdornmentGenerator } = useProfileAdornments();
    const { user } = useSession();
    const { showScoreDetails } = useAIProfileScoreDetails();
    const { setSnackbar } = useSnackbar();

    const handleDeleteScore = (scoreId: string) => async () => {
        setSnackbar('Deleting score...');
        await deleteScore({ variables: { id: scoreId } });
        refetch();
        setSnackbar('Score deleted');
    };

    const handleCreateScore = (personId: string) => async (rating: number) => {
        setSnackbar('Creating score...');
        const result = await createScore({ variables: { personId, jobId, score: rating } });
        refetch();
        setSnackbar(
            'Score created',
            <Button style={{ color: 'white' }} onClick={handleDeleteScore(result.data.score.id)}>
                Undo
            </Button>
        );
    };

    const handleScoreClick = (score: JobCPCandidate['profileScore']) => (e: React.MouseEvent) => {
        e.stopPropagation();
        showScoreDetails(score);
    };

    const getProfileAdornments: AdornmentGenerator = React.useCallback(
        (personId: string) => {
            const score = candidates.find((c) => c.candidate.person.id === personId)?.profileScore;
            if (!score) return {};

            const scoreDetailedView = score ? (
                <div style={{ marginRight: '20px', position: 'relative' }}>
                    <StarRating
                        rating={score.score}
                        tooltip={score.scoreExplanation}
                        htmlColor={getScoreColor(score, user).main}
                        onClick={handleScoreClick(score)}
                        onChange={creating || deleting ? null : handleCreateScore(personId)}
                        hoverHtmlColor={colors.sessionUser.main}
                    />
                </div>
            ) : null;
            const explanationDetailedView = <AIProfileScoreExplanation score={score} />;
            return {
                insertElements: [
                    { slot: 'explanationDetailedView', element: explanationDetailedView },
                    { slot: 'scoreDetailedView', element: scoreDetailedView }
                ]
            };
        },
        [candidates, creating, deleting, user]
    );

    React.useEffect(() => {
        if (candidates.length) {
            setAdornmentGenerator(getProfileAdornments);
        }
        return () => {
            setAdornmentGenerator(null);
        };
    }, [candidates, getProfileAdornments]);

    const handleCandidateClick = (candidate: JobCPCandidate['candidate']) => () => {
        const list = candidates.map((c) => ({
            personId: c.candidate.person.id,
            selectedTab: 'profile'
        }));
        setList(list, { personId: candidate.person.id, selectedTab: 'profile' });
    };

    const getCellValue = (column: Column) => (candidate: JobCPCandidate) => {
        switch (column) {
            case 'assignee':
                return candidate.candidate.assignee.name;
            case 'job':
                return `${candidate.candidate.job.client.name} - ${candidate.candidate.job.title}`;
            case 'name':
                return candidate.candidate.person.name;
            case 'score':
                return candidate.profileScore.score;
            case 'stage':
                return candidate.candidate.stage.label;
        }
    };

    const formatCellValue = {
        name: (value: string, row: JobCPCandidate) => {
            const nameCell = (
                <span className="clickable-cell" onClick={handleCandidateClick(row.candidate)}>
                    {value}
                </span>
            );
            return nameCell;
        },
        score: (value: number, row: JobCPCandidate) => {
            const scoreCell = (
                <Tooltip title={row.profileScore.scoreExplanation || ''} onClick={handleScoreClick(row.profileScore)}>
                    <span className="clickable-cell">{value}</span>
                </Tooltip>
            );
            return scoreCell;
        }
    };

    return (
        <DataPanel
            className="cp-candidates-table"
            columns={columns}
            data={candidates}
            getCellValue={getCellValue}
            formatCellValue={formatCellValue}
            sortableColumns={['score', 'assignee', 'job', 'stage']}
            filterableColumns={['score', 'assignee', 'job', 'stage']}
            pageSize={100}
        />
    );
};
