import { css } from '@emotion/core';
import { Button, TextField } from '@material-ui/core';
import { produce } from 'immer';
import { isEqual } from 'lodash';
import React, { useState } from 'react';

import { SequenceStage, SequenceView, validateSequence } from 'shared/models/sequence';

import { useModal } from '../hooks/use-modal';
import { EmailSequenceFormVariables } from './email-sequence-form-variables';
import { EmailSequenceStageForm } from './email-sequence-stage-form';

const styles = css`
    .MuiFormControl-root {
        margin: 15px 0;
    }
`;

interface SequenceFormProps {
    title: string;
    onCancel: () => void;
    onSave: (sequence: SequenceView) => void;
    sequence: SequenceView;
    disabled: boolean;
}

const variables = [
    '{{First Name}}',
    '{{Full Name}}',
    '{{Sender First Name}}',
    '{{Sender Full Name}}',
    '{{Sender Signature}}',
    '{{Subject}}',
    '{{Custom Text}}',
    '{{Phone Call Scheduling Link}}'
];

export const SequenceForm: React.FC<SequenceFormProps> = (props) => {
    const { onCancel, onSave, disabled, title } = props;
    const [sequence, setSequence] = useState(props.sequence);
    const { getConfirmation, setAlert } = useModal();

    const handleCancelClick = () => {
        if (isEqual(props.sequence, sequence)) {
            onCancel();
        } else {
            getConfirmation(
                onCancel,
                'You have unsaved changes. Are you sure you want to cancel?',
                'Confirm Discard Changes'
            );
        }
    };

    const handleSaveClick = () => {
        if (validateSequence(sequence)) {
            onSave(sequence);
        } else {
            setAlert('Cannot save sequence', 'Invalid Sequence. Check console for details');
        }
    };

    const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSequence(
            produce(sequence, (draft) => {
                draft.title = event.target.value;
            })
        );
    };

    const handleStageChange = (index: number) => (stage: SequenceStage) => {
        setSequence(
            produce(sequence, (draft) => {
                draft.stages[index] = stage;
            })
        );
    };

    const handleAddStage = () => {
        setSequence(
            produce(sequence, (draft) => {
                draft.stages.push({
                    attachments: [],
                    body: '',
                    subject: '',
                    waitDays: 5
                });
            })
        );
    };

    const removeStage = (index: number) => {
        setSequence(
            produce(sequence, (draft) => {
                draft.stages.splice(index);
            })
        );
    };

    const handleRemoveStage = (index: number) => () => {
        if (sequence.stages[index].body.length !== 0) {
            getConfirmation(
                () => removeStage(index),
                'Are you sure you want to delete this stage?',
                'Confirm remove stage'
            );
        } else {
            removeStage(index);
        }
    };

    const stages = sequence.stages.map((s, i) => (
        <div className="sequence-form-section" key={i}>
            <EmailSequenceStageForm
                stage={s}
                index={i}
                onChange={handleStageChange(i)}
                onRemove={handleRemoveStage(i)}
                disabled={disabled || sequence.archived}
            />
        </div>
    ));

    const variablesSelector =
        disabled || sequence.archived ? null : <EmailSequenceFormVariables variables={variables} />;

    return (
        <div className="sequence-form" css={styles}>
            <div className="form-title">{title}</div>
            <div className="form-body">
                <div className="sequence-form-section">
                    <TextField
                        value={sequence.title}
                        label="Sequence Title"
                        onChange={handleFieldChange}
                        fullWidth={true}
                        disabled={disabled}
                    />
                </div>
                {stages}
                <div className="sequence-add-stage">
                    <Button onClick={handleAddStage} disabled={disabled}>
                        Add Stage
                    </Button>
                </div>
            </div>
            <div className="form-actions">
                <div>{variablesSelector}</div>
                <div>
                    <Button onClick={handleCancelClick} color="secondary">
                        {disabled || sequence.archived ? 'Close' : 'Cancel'}
                    </Button>
                    <Button onClick={handleSaveClick} color="primary" disabled={disabled || sequence.archived}>
                        Save
                    </Button>
                </div>
            </div>
        </div>
    );
};
