import * as React from 'react';

import { Filters } from 'shared/types/candidates-search';

import { CandidatesSearch } from '../components/candidates-search';

export const CandidatesSearchPage = () => {
    const filters: Filters = {
        assignees: [],
        blacklistedOnly: false,
        csDegreeOnly: false,
        currentCompany: [],
        disciplines: [],
        diversity: [],
        excludedClients: [],
        favoritesOnly: false,
        keywords: [],
        locations: [],
        minYearsExp: '',
        profileGroup: [],
        progress: '',
        schoolRank: '',
        specificJobs: [],
        tags: [],
        timeWindow: '',
        visas: []
    };
    return <CandidatesSearch initFilters={filters} title="Candidates Search" />;
};
