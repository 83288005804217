import { Button, Dialog, DialogActions, DialogContent, MenuItem, TextField } from '@material-ui/core';
import { startCase } from 'lodash';
import { Tab, Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn, Tabs } from 'material-ui';
import * as moment from 'moment';
import * as React from 'react';

import { css } from '@emotion/core';
import { getSimilaritySearchResults } from '../api';
import { tabBackgroundColor, tableBorderColor } from '../common/css-variables';
import { Spinner } from '../core-ui/spinner';
import { CompanyNameSelectField } from './talent-intelligence/company-name-select';

type SearchType = 'skills' | 'companies';

interface SearchResult {
    result: string;
    similarity: number;
    count: string;
    url: string;
}

interface DefaultProps {
    searchType: SearchType;
    searchTypes: SearchType[];
    searchText: string;
    onClose: () => void;
    buttonForValue?: (value: { name: string; url: string }) => JSX.Element;
    dataSource: Array<{ label: string; value: string }>;
}

export interface SimilaritySearchProps extends Partial<DefaultProps> {}

interface SimilaritySearchState {
    searchType: SearchType;
    searchText: string;
    company: { name: string; url: string };
    topn: any;
    loadingResults: boolean;
    searchResults: SearchResult[];
}

const styles = css`
    .dialog-content {
        padding: 0;
        width: 720px;

        .similarity-search-form {
            display: flex;
            align-items: center;
            padding: 16px;

            .similarity-search-topn {
                flex: 0 0 auto;
                width: 100px;
                margin-right: 20px;
            }

            .company-name-select {
                flex: 1 1 auto;
            }
        }

        .similarity-search-results {
            padding: 16px;
        }
    }
`;

export class SimilaritySearch extends React.Component<SimilaritySearchProps, SimilaritySearchState> {
    static defaultProps: DefaultProps = {
        buttonForValue: null,
        dataSource: [],
        onClose: () => null,
        searchText: '',
        searchType: 'skills',
        searchTypes: ['skills', 'companies']
    };

    constructor(props: SimilaritySearchProps) {
        super(props);
        this.state = {
            company: undefined,
            loadingResults: false,
            searchResults: [],
            searchText: this.props.searchText,
            searchType: this.props.searchType,
            topn: 100
        };
    }

    componentDidMount() {
        if (this.props.searchText !== '') {
            this.getSearchResults(this.state.searchText, this.state.searchType, this.state.topn);
        }
    }

    componentDidUpdate(prevProps: SimilaritySearchProps) {
        if (prevProps.searchText !== this.props.searchText || prevProps.searchType !== this.props.searchType) {
            this.setState({ searchText: this.props.searchText, searchType: this.props.searchType, company: undefined });
            this.getSearchResults(this.props.searchText, this.props.searchType, this.state.topn);
        }
    }

    handleSearchTypeChange = (value: SearchType) => {
        this.setState({ searchType: value, searchText: '', searchResults: [] });
    };

    getSearchQuery = (searchText: string) => {
        const dataSource = this.props.dataSource.find((s) => s.label === searchText);
        if (dataSource) {
            return dataSource.value;
        }
        return searchText;
    };

    saveDownloadedSearchResults = () => {
        if (this.state.searchResults && this.state.searchResults.length > 0) {
            let csvResult = 'result,similarity,number_of_profiles\n';
            this.state.searchResults.map(
                (result) => (csvResult += result.result + ',' + result.similarity + ',' + result.count + '\n')
            );
            const blob = new Blob([csvResult], { type: 'octet/stream' });
            const date = moment();
            const dateStr = date.format('YYYY-MM-DD-hh-mm-ss');
            saveAs(blob, `${this.state.searchType} - ${this.getSearchQuery(this.state.searchText)} - ${dateStr}.csv`);
        }
    };

    handleSearch = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        const { searchText, searchType, topn } = this.state;
        if (searchText && searchType && topn) {
            this.setState({ loadingResults: true, searchResults: [] });
            this.getSearchResults(searchText, searchType, topn);
        }
    };

    getSearchResults = (searchText: string, searchType: SearchType, topn: number) => {
        const searchQuery = this.getSearchQuery(searchText);
        this.setState({ loadingResults: true, searchResults: [] });
        getSimilaritySearchResults(searchQuery, searchType, topn).then((result) => {
            this.setState({ loadingResults: false, searchResults: result.data });
        });
    };

    handleSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchText: e.target.value });
    };

    handleTextFieldKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            this.handleSearch({ preventDefault: () => null });
        }
    };

    handleCompanyChange = (company: { name: string; url: string }) => {
        this.setState({ company, searchText: company.url });
    };

    handleTopNChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ topn: Number(e.target.value) });
    };

    handleSearchRequest = (_1: any, _2: number) => {
        this.handleSearch({ preventDefault: () => null });
    };

    render() {
        const { searchResults, searchText, topn, loadingResults, searchType } = this.state;
        const { searchTypes } = this.props;

        let shouldRenderResults = false;
        let searchResultsContent = (
            <div>
                <Spinner />
            </div>
        );
        // Render the spinner only if currently loading results
        if (!loadingResults) {
            shouldRenderResults = searchResults && searchResults.length > 0;
            if (shouldRenderResults) {
                const tableRows = searchResults.map((resultObject) => {
                    const actionButton = this.props.buttonForValue
                        ? this.props.buttonForValue({
                              name: resultObject.result,
                              url: resultObject.url
                          })
                        : null;
                    const actionColumn = actionButton ? <TableRowColumn>{actionButton}</TableRowColumn> : null;
                    const formattedScore = Number(resultObject.similarity).toFixed(2);
                    return (
                        <TableRow key={resultObject.result} selectable={false}>
                            <TableRowColumn>
                                <a href={resultObject.url?.replace('/recruiter/company/', '/company/')} target="_blank">
                                    {resultObject.result ?? 'Unknown Name'}
                                </a>
                            </TableRowColumn>
                            <TableRowColumn>{formattedScore}</TableRowColumn>
                            <TableRowColumn>{resultObject.count}</TableRowColumn>
                            {actionColumn}
                        </TableRow>
                    );
                });
                const actionHeaderColumn = this.props.buttonForValue ? (
                    <TableHeaderColumn className="similarity-search-results-table-header" />
                ) : null;
                searchResultsContent = (
                    <div style={{ height: 550 }}>
                        <Table>
                            <TableHeader
                                displaySelectAll={false}
                                adjustForCheckbox={false}
                                className="jobs-table-header"
                            >
                                <TableRow style={{ borderTop: `thin solid ${tableBorderColor}` }}>
                                    <TableHeaderColumn className="similarity-search-results-table-header">
                                        Result
                                    </TableHeaderColumn>
                                    <TableHeaderColumn className="similarity-search-results-table-header">
                                        Similarity
                                    </TableHeaderColumn>
                                    <TableHeaderColumn className="similarity-search-results-table-header">
                                        Number of profiles
                                    </TableHeaderColumn>
                                    {actionHeaderColumn}
                                </TableRow>
                            </TableHeader>
                            <TableBody displayRowCheckbox={false} showRowHover={true}>
                                {tableRows}
                            </TableBody>
                        </Table>
                    </div>
                );
            } else {
                searchResultsContent = (
                    <div>
                        <p>No results (yet).</p>
                    </div>
                );
            }
        }

        const actions = [
            <Button key="save-as-csv" onClick={this.saveDownloadedSearchResults} disabled={!shouldRenderResults}>
                Save as CSV
            </Button>,
            <Button key="close" onClick={this.props.onClose}>
                Close
            </Button>,
            <Button
                key="search"
                onClick={this.handleSearch}
                disabled={loadingResults || searchText?.trim().length === 0}
            >
                Search
            </Button>
        ];

        const inputField =
            searchType === 'companies' ? (
                <CompanyNameSelectField
                    selected={this.state.company}
                    onSelect={this.handleCompanyChange}
                    disabled={loadingResults}
                    variant="standard"
                    onKeyPress={this.handleTextFieldKeyPress}
                    recruiterLinkOnly={true}
                />
            ) : (
                <TextField
                    value={searchText}
                    onChange={this.handleSearchQueryChange}
                    label="Skills"
                    fullWidth={true}
                    disabled={loadingResults}
                    onKeyPress={this.handleTextFieldKeyPress}
                />
            );

        const tabs = searchTypes.map((s, i) => <Tab key={i} label={startCase(s)} value={s} />);
        // tslint:disable-next-line:no-magic-numbers
        const numResultsOptions = [5, 10, 20, 50, 100, 200, 500];
        const numResultsMenuItems = numResultsOptions.map((numResults) => (
            <MenuItem key={numResults} value={numResults}>
                {numResults}
            </MenuItem>
        ));
        return (
            <Dialog css={styles} onClose={this.props.onClose} open={true} maxWidth="md">
                <DialogContent className="dialog-content">
                    <Tabs
                        value={searchType}
                        inkBarStyle={{ backgroundColor: tabBackgroundColor }}
                        onChange={this.handleSearchTypeChange}
                    >
                        {tabs}
                    </Tabs>
                    <div className="similarity-search-form">
                        <TextField
                            label="# results"
                            select={true}
                            value={topn}
                            onChange={this.handleTopNChange}
                            disabled={loadingResults}
                            className="similarity-search-topn"
                        >
                            {numResultsMenuItems}
                        </TextField>
                        {inputField}
                    </div>
                    <div className="similarity-search-results">{searchResultsContent}</div>
                </DialogContent>
                <DialogActions>{actions}</DialogActions>
            </Dialog>
        );
    }
}
