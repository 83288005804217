import { Action, CloseModalConfirmation, RequestModalConfirmation } from '../actions';
import { ConfirmModalState } from '../state';

const modalInitialState: ConfirmModalState = {
    description: null,
    nonCancelable: false,
    onConfirm: null,
    open: false,
    title: null
};

export function confirmationModal(state = modalInitialState, action: Action) {
    switch (action.type) {
        case RequestModalConfirmation:
            return { ...state, ...action.payload, open: true, nonCancelable: action.payload.nonCancelable || false };
        case CloseModalConfirmation:
            return modalInitialState;
        default:
            return state;
    }
}
