import { Tooltip } from '@material-ui/core';
import { Map, OrderedMap } from 'immutable';
import ContentReply from 'material-ui/svg-icons/content/reply';
import * as React from 'react';
import { connect } from 'react-redux';

import { disabledColor } from '../common/css-variables';
import { Candidate, Communication, EmailAccount, ListState, State } from '../state';

interface OwnProps {
    communication: Communication;
    onReply: () => void;
}

interface ConnectedProps {
    emailAccounts: Map<string, EmailAccount>;
    candidates: Map<string, OrderedMap<string, Candidate>>;
    listsState: Map<string, ListState>;
}

type CommunicationReplyButtonProps = OwnProps & ConnectedProps;

class CommunicationReplyButtonComp extends React.Component<CommunicationReplyButtonProps> {
    handleReplyButton = (disabled: boolean) => () => {
        if (disabled) return;
        this.props.onReply();
    };

    render() {
        const { emailAccounts, communication, listsState } = this.props;
        const hasData = !emailAccounts.isEmpty() && listsState.get('jobs') === 'initialized';
        const disabled = !hasData || communication.sender === 'notifications@mixmax.com';
        const tooltip = !hasData ? 'Loading' : 'Reply';
        const contentReply = (
            <Tooltip title={tooltip}>
                <span>
                    <ContentReply
                        color={disabled ? disabledColor : 'black'}
                        onClick={this.handleReplyButton(disabled)}
                    />
                </span>
            </Tooltip>
        );

        return <div className={`email-action-icon email-action ${disabled ? 'disabled' : ''}`}>{contentReply}</div>;
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    candidates: state.candidates,
    emailAccounts: state.emailAccounts,
    listsState: state.listsState
});

export const CommunicationReplyButton = connect<ConnectedProps, undefined, OwnProps>(mapStateToProps)(
    CommunicationReplyButtonComp
);
