// cspell:disable
export const rocketLocations = [
    'Iowa',
    'Ohio',
    'Utah',
    'Idaho',
    'Maine',
    'Texas',
    'Hawaii',
    'Kansas',
    'Nevada',
    'Oregon',
    'Alabama',
    'Florida',
    'Georgia',
    'Indiana',
    'Montana',
    'Vermont',
    'Wyoming',
    'Cary, NC',
    'Colorado',
    'Delaware',
    'Illinois',
    'Kentucky',
    'Maryland',
    'Mesa, AZ',
    'Michigan',
    'Missouri',
    'Nebraska',
    'Oklahoma',
    'Virginia',
    'Waco, TX',
    'Akron, OH',
    'Davie, FL',
    'Elgin, IL',
    'Fargo, ND',
    'Louisiana',
    'Macon, GA',
    'Minnesota',
    'Omaha, NE',
    'Plano, TX',
    'Salem, OR',
    'Tempe, AZ',
    'Tennessee',
    'Tyler, TX',
    'Vista, CA',
    'Wisconsin',
    'Alaska, AK',
    'Arvada, CO',
    'Athens, GA',
    'Aurora, CO',
    'Aurora, IL',
    'Clovis, CA',
    'Corona, CA',
    'Denton, TX',
    'Downey, CA',
    'Durham, NC',
    'Edison, NJ',
    'Frisco, TX',
    'Irvine, CA',
    'Irving, TX',
    'Joliet, IL',
    'Laredo, TX',
    'Lowell, MA',
    'New Jersey',
    'New Mexico',
    'Newark, NJ',
    'Norman, OK',
    'Odessa, TX',
    'Olathe, KS',
    'Oxnard, CA',
    'Peoria, AZ',
    'Pueblo, CO',
    'Rialto, CA',
    'Topeka, KS',
    'Warren, MI',
    'Washington',
    'Abilene, TX',
    'Akron, Ohio',
    'Alabama, AL',
    'Augusta, GA',
    'Boulder, CO',
    'Clinton, MI',
    'Connecticut',
    'Detroit, MI',
    'Fontana, CA',
    'Garland, TX',
    'Gilbert, AZ',
    'Greeley, CO',
    'Gresham, OR',
    'Hampton, VA',
    'Hialeah, FL',
    'Killeen, TX',
    'Lubbock, TX',
    'McAllen, TX',
    'Memphis, TN',
    'Midland, TX',
    'Miramar, FL',
    'Mississippi',
    'Modesto, CA',
    'Norwalk, CA',
    'Ontario, CA',
    'Puerto Rico',
    'Visalia, CA',
    'Amarillo, TX',
    'Arkansas, AR',
    'Beaumont, TX',
    'Billings, MT',
    'Carlsbad, CA',
    'Chandler, AZ',
    'Columbus, GA',
    'El Cajon, CA',
    'Glendale, AZ',
    'Lakewood, CO',
    'Lakewood, NJ',
    'McKinney, TX',
    'Mesquite, TX',
    'Murrieta, CA',
    'North Dakota',
    'Palm Bay, FL',
    'Pasadena, TX',
    'Paterson, NJ',
    'Pearland, TX',
    'Pennsylvania',
    'Phoenix Area',
    'Rhode Island',
    'South Dakota',
    'Stamford, CT',
    'Surprise, AZ',
    'Thornton, CO',
    'Allentown, PA',
    'Anchorage, AK',
    'Ann Arbor, MI',
    'Arlington, TX',
    'Elizabeth, NJ',
    'Elk Grove, CA',
    'Escondido, CA',
    'Fullerton, CA',
    'Henderson, NV',
    'Hillsboro, OR',
    'Hollywood, FL',
    'Inglewood, CA',
    'Lafayette, LA',
    'Lancaster, CA',
    'Massachusetts',
    'Milwaukee, WI',
    'New Hampshire',
    'New Haven, CT',
    'Oceanside, CA',
    'Riverside, CA',
    'Rochester, MN',
    'Roseville, CA',
    'Santa Ana, CA',
    'St. Louis, MO',
    'United States',
    'Vancouver, WA',
    'Waterbury, CT',
    'West Virginia',
    'Worcester, MA',
    'Abilene, Texas',
    'Alexandria, VA',
    'Bridgeport, CT',
    'California, CA',
    'Cape Coral, FL',
    'Carrollton, TX',
    'Centennial, CO',
    'Chesapeake, VA',
    'Clearwater, FL',
    'Costa Mesa, CA',
    'Fort Worth, TX',
    'High Point, NC',
    'Las Cruces, NM',
    'Lewisville, TX',
    'Manchester, NH',
    'Montgomery, AL',
    'Naperville, IL',
    'North Carolina',
    'Pittsburgh, PA',
    'Providence, RI',
    'Richardson, TX',
    'Round Rock, TX',
    'Saint Paul, MN',
    'San Angelo, TX',
    'Scottsdale, AZ',
    'Shreveport, LA',
    'South Bend, IN',
    'South Carolina',
    'Woodbridge, NJ',
    'Bakersfield, CA',
    'Brownsville, TX',
    'Chula Vista, CA',
    'Cincinnati Area',
    'Clarksville, TN',
    'Jersey City, NJ',
    'League City, TX',
    'Minneapolis, MN',
    'Santa Maria, CA',
    'Springfield, IL',
    'Victorville, CA',
    'West Jordan, UT',
    'Westminster, CO',
    'Arizona Area, AZ',
    'Broken Arrow, OK',
    'Cedar Rapids, IA',
    'Fayetteville, NC',
    'Garden Grove, CA',
    'Grand Rapids, MI',
    'Independence, MO',
    'Indianapolis, IN',
    'Murfreesboro, TN',
    'Newport News, VA',
    'Philadelphia, PA',
    'Provo, Utah Area',
    'White Plains, NY',
    'Boise, Idaho Area',
    'Coral Springs, FL',
    'Dayton, Ohio Area',
    'Grand Prairie, TX',
    'Jurupa Valley, CA',
    'Miami Gardens, FL',
    'Moreno Valley, CA',
    'Overland Park, KS',
    'Pompano Beach, FL',
    'Reno, Nevada Area',
    'Sandy Springs, GA',
    'Thousand Oaks, CA',
    'Toledo, Ohio Area',
    'Wichita Falls, TX',
    'Austin, Texas Area',
    'Corpus Christi, TX',
    'Greater Omaha Area',
    'Pembroke Pines, FL',
    'Port St. Lucie, FL',
    'San Bernardino, CA',
    'St. Petersburg, FL',
    'Virginia Beach, VA',
    'College Station, TX',
    'Columbus, Ohio Area',
    'El Paso, Texas Area',
    'Eugene, Oregon Area',
    'Fort Lauderdale, FL',
    'Greater Boston Area',
    'Greater Denver Area',
    'Houston, Texas Area',
    'Winston–Salem, NC',
    'Davenport, Iowa Area',
    'District Of Columbia',
    'Greater Atlanta Area',
    'Greater Chicago Area',
    'Greater Detroit Area',
    'Greater Memphis Area',
    'Greater Seattle Area',
    'Huntington Beach, CA',
    'Mobile, Alabama Area',
    'North Charleston, SC',
    'Portland, Maine Area',
    'Rancho Cucamonga, CA',
    'Sterling Heights, MI',
    'Tucson, Arizona Area',
    'Tulsa, Oklahoma Area',
    'Washington D.C. Area',
    'West Valley City, UT',
    'Wichita, Kansas Area',
    'Albany, New York Area',
    'Des Moines, Iowa Area',
    'Ithaca, New York Area',
    'Orlando, Florida Area',
    'Peoria, Illinois Area',
    'Portland, Oregon Area',
    'Dallas/Fort Worth Area',
    'Greater Milwaukee Area',
    'Greater Nashville Area',
    'Greater San Diego Area',
    'Greater St. Louis Area',
    'Lakeland, Florida Area',
    'Lansing, Michigan Area',
    'Las Vegas, Nevada Area',
    'Lincoln, Nebraska Area',
    'Norfolk, Virginia Area',
    'Roanoke, Virginia Area',
    'Saginaw, Michigan Area',
    'San Francisco Bay Area',
    'Sarasota, Florida Area',
    'Savannah, Georgia Area',
    'Columbia, Missouri Area',
    'Fresno, California Area',
    'Greater Pittsburgh Area',
    'Madison, Wisconsin Area',
    'Melbourne, Florida Area',
    'Oshkosh, Wisconsin Area',
    'Richmond, Virginia Area',
    'Rockford, Illinois Area',
    'San Antonio, Texas Area',
    'Syracuse, New York Area',
    'Baltimore, Maryland Area',
    'Birmingham, Alabama Area',
    'Burlington, Vermont Area',
    'Evansville, Indiana Area',
    'Fort Myers, Florida Area',
    'Fort Wayne, Indiana Area',
    'Greater Los Angeles Area',
    'Greater New Orleans Area',
    'Huntsville, Alabama Area',
    'Kalamazoo, Michigan Area',
    'Lexington, Kentucky Area',
    'Rochester, New York Area',
    'Salinas, California Area',
    'Spokane, Washington Area',
    'Fort Pierce, Florida Area',
    'Gainesville, Florida Area',
    'Greater Philadelphia Area',
    'Green Bay, Wisconsin Area',
    'Jackson, Mississippi Area',
    'Knoxville, Tennessee Area',
    'Louisville, Kentucky Area',
    'Stockton, California Area',
    'Tallahassee, Florida Area',
    'Cleveland/Akron, Ohio Area',
    'Greater New York City Area',
    'Hartford, Connecticut Area',
    'Indianapolis, Indiana Area',
    'Jacksonville, Florida Area',
    'Kansas City, Missouri Area',
    'Little Rock, Arkansas Area',
    'Miami/Fort Lauderdale Area',
    'Springfield, Missouri Area',
    'Baton Rouge, Louisiana Area',
    'Chattanooga, Tennessee Area',
    'Daytona Beach, Florida Area',
    'Fayetteville, Arkansas Area',
    'Fort Collins, Colorado Area',
    'Greater Salt Lake City Area',
    'Sacramento, California Area',
    'Scranton, Pennsylvania Area',
    'Albuquerque, New Mexico Area',
    'Johnson City, Tennessee Area',
    'Lancaster, Pennsylvania Area',
    'Oklahoma City, Oklahoma Area',
    'Columbia, South Carolina Area',
    'Harrisburg, Pennsylvania Area',
    'Providence, Rhode Island Area',
    'West Palm Beach, Florida Area',
    'Asheville, North Carolina Area',
    'Buffalo/Niagara, New York Area',
    'Charlotte, North Carolina Area',
    'Charlottesville, Virginia Area',
    'Santa Barbara, California Area',
    'Sioux Falls, South Dakota Area',
    'Charleston, South Carolina Area',
    'Colorado Springs, Colorado Area',
    'Greenville, South Carolina Area',
    'Springfield, Massachusetts Area',
    'Urbana-Champaign, Illinois Area',
    'Wilmington, North Carolina Area',
    'Greater Minneapolis-St. Paul Area',
    'Tampa/St. Petersburg, Florida Area',
    'Greater Grand Rapids, Michigan Area',
    'Raleigh-Durham, North Carolina Area',
    'New London/Norwich, Connecticut Area',
    'Greensboro/Winston-Salem, North Carolina Area',
    'Asia',
    'Peru',
    'Chile',
    'China',
    'Hong Kong',
    'Beijing City, China',
    'Shanghai City, China',
    'Shenzhen, Guangdong, China',
    'Guangzhou, Guangdong, China',
    'Egypt',
    'Ghana',
    'Goa',
    'Assam',
    'Bihar',
    'Delhi',
    'India',
    'Kerala',
    'Orissa',
    'Punjab',
    'Sikkim',
    'Gujarat',
    'Haryana',
    'Manipur',
    'Mizoram',
    'Tripura',
    'Nagaland',
    'Jharkhand',
    'Karnataka',
    'Meghalaya',
    'Rajasthan',
    'Chandigarh',
    'Tamil Nadu',
    'Chattisgarh',
    'Daman & Diu',
    'Lakshadweep',
    'Maharashtra',
    'Pondicherry',
    'Uttarakhand',
    'West Bengal',
    'Uttar Pradesh',
    'Andhra Pradesh',
    'Gurgaon, India',
    'Madhya Pradesh',
    'Jammu & Kashmir',
    'Himachal Pradesh',
    'Pune Area, India',
    'Arunachal Pradesh',
    'Noida Area, India',
    'Cochin Area, India',
    'Indore Area, India',
    'Jaipur Area, India',
    'Mumbai Area, India',
    'Nagpur Area, India',
    'Chennai Area, India',
    'Dadra& Nagar Haveli',
    'Kolkata Area, India',
    'Lucknow Area, India',
    'Vadodara Area, India',
    'Ahmedabad Area, India',
    'Bengaluru Area, India',
    'Hyderabad Area, India',
    'New Delhi Area, India',
    'Chandigarh Area, India',
    'Coimbatore Area, India',
    'Andaman & Nicobar Islands',
    'Italy',
    'Rome Area, Italy',
    'Milan Area, Italy',
    'Turin Area, Italy',
    'Venice Area, Italy',
    'Bologna Area, Italy',
    'Japan',
    'Kenya',
    'Korea',
    'Gangnam-gu, Seoul, Korea',
    'Nepal',
    'Qatar',
    'Spain',
    'Madrid Area, Spain',
    'Barcelona Area, Spain',
    'Africa',
    'Acre',
    'Bahia',
    'Pará',
    'Amapá',
    'Brazil',
    'Ceará',
    'Goiás',
    'Piauí',
    'Alagoas',
    'Paraná',
    'Roraima',
    'Sergipe',
    'Amazonas',
    'Paraíba',
    'Maranhão',
    'Rondônia',
    'Tocantins',
    'Pernambuco',
    'São Paulo',
    'Mato Grosso',
    'Minas Gerais',
    'Rio de Janeiro',
    'Santa Catarina',
    'Espírito Santo',
    'Distrito Federal',
    'Rio Grande do Sul',
    'Mato Grosso do Sul',
    'Rio Grande do Norte',
    'Campinas Area, Brazil',
    'Curitiba Area, Brazil',
    'São Paulo Area, Brazil',
    'Porto Alegre Area, Brazil',
    'Belo Horizonte Area, Brazil',
    'Rio de Janeiro Area, Brazil',
    'Yukon',
    'Canada',
    'Quebec',
    'Alberta',
    'Nunavut',
    'Ontario',
    'Manitoba',
    'Nova Scotia',
    'Saskatchewan',
    'New Brunswick',
    'Quebec, Canada',
    'Ontario, Canada',
    'British Columbia',
    'London, Canada Area',
    'Ottawa, Canada Area',
    'Calgary, Canada Area',
    'Halifax, Canada Area',
    'Prince Edward Island',
    'Toronto, Canada Area',
    'Edmonton, Canada Area',
    'Montreal, Canada Area',
    'Northwest Territories',
    'Winnipeg, Canada Area',
    'Kitchener, Canada Area',
    'Vancouver, Canada Area',
    'British Columbia, Canada',
    'Newfoundland And Labrador',
    'Europe',
    'France',
    'Lyon Area, France',
    'Nice Area, France',
    'Lille Area, France',
    'Paris Area, France',
    'Toulouse Area, France',
    'Marseille Area, France',
    'Greece',
    'Israel',
    'Jordan',
    'Kuwait',
    'Mexico',
    'Mexico City Area, Mexico',
    'Naucalpan de Juárez Area, Mexico',
    'Norway',
    'Oslo Area, Norway',
    'Panama',
    'Poland',
    'Serbia',
    'Sweden',
    'Taiwan',
    'Turkey',
    'Istanbul, Turkey',
    'Uganda',
    'Algeria',
    'Austria',
    'Bahrain',
    'Belgium',
    'Antwerp Area, Belgium',
    'Brussels Area, Belgium',
    'Bolivia',
    'Croatia',
    'Denmark',
    'Odense Area, Denmark',
    'Århus Area, Denmark',
    'Ålborg Area, Denmark',
    'Copenhagen Area, Denmark',
    'Ecuador',
    'Finland',
    'Germany',
    'Munich Area, Germany',
    'Cologne Area, Germany',
    'Frankfurt Am Main Area, Germany',
    'Hungary',
    'Ireland',
    'Morocco',
    'Nigeria',
    'Oceania',
    'Romania',
    'Tunisia',
    'Ukraine',
    'Uruguay',
    'Vietnam',
    'Bulgaria',
    'Cameroon',
    'Colombia',
    'Malaysia',
    'Selangor, Malaysia',
    'Kuala Lumpur, Malaysia',
    'Pakistan',
    'Portugal',
    'Porto Area, Portugal',
    'Lisbon Area, Portugal',
    'Tanzania',
    'Thailand',
    'Zimbabwe',
    'Argentina',
    'Australia',
    'Perth Area, Australia',
    'Sydney Area, Australia',
    'Adelaide Area, Australia',
    'Brisbane Area, Australia',
    'Canberra Area, Australia',
    'Melbourne Area, Australia',
    'Guatemala',
    'Indonesia',
    'Greater Jakarta Area, Indonesia',
    'Lithuania',
    'Singapore',
    'Sri Lanka',
    'Venezuela',
    'Antarctica',
    'Bangladesh',
    'Costa Rica',
    'Middle East',
    'Netherlands',
    'Breda Area, Netherlands',
    'Tilburg Area, Netherlands',
    'Utrecht Area, Netherlands',
    'Enschede Area, Netherlands',
    'Nijmegen Area, Netherlands',
    'Amsterdam Area, Netherlands',
    'Apeldoorn Area, Netherlands',
    'Eindhoven Area, Netherlands',
    'Groningen Area, Netherlands',
    'Rotterdam Area, Netherlands',
    'The Hague Area, Netherlands',
    'Almere Stad Area, Netherlands',
    'New Zealand',
    'Philippines',
    'Switzerland',
    'Geneva Area, Switzerland',
    'Zürich Area, Switzerland',
    'Saudi Arabia',
    'South Africa',
    'Durban Area, South Africa',
    'Cape Town Area, South Africa',
    'Johannesburg Area, South Africa',
    'Latin America',
    'North America',
    'Czech Republic',
    'United Kingdom',
    'Leeds, United Kingdom',
    'Harrow, United Kingdom',
    'London, United Kingdom',
    'Oxford, United Kingdom',
    'Slough, United Kingdom',
    'Bristol, United Kingdom',
    'Glasgow, United Kingdom',
    'Reading, United Kingdom',
    'Redhill, United Kingdom',
    'Brighton, United Kingdom',
    'Coventry, United Kingdom',
    'Cambridge, United Kingdom',
    'Edinburgh, United Kingdom',
    'Guildford, United Kingdom',
    'Leicester, United Kingdom',
    'Sheffield, United Kingdom',
    'Tonbridge, United Kingdom',
    'Birmingham, United Kingdom',
    'Chelmsford, United Kingdom',
    'Gloucester, United Kingdom',
    'Manchester, United Kingdom',
    'Nottingham, United Kingdom',
    'Portsmouth, United Kingdom',
    'Twickenham, United Kingdom',
    'Northampton, United Kingdom',
    'Southampton, United Kingdom',
    'Milton Keynes, United Kingdom',
    'Hemel Hempstead, United Kingdom',
    'Newcastle upon Tyne, United Kingdom',
    'Kingston upon Thames, United Kingdom',
    'Slovak Republic',
    'Dominican Republic',
    'Russian Federation',
    'Trinidad and Tobago',
    'United Arab Emirates'
];
