import { useQuery, useSubscription } from '@apollo/client';
import { css } from '@emotion/core';
import { MenuItem, Select, TableCell, TableRow, useTheme } from '@material-ui/core';
import { DoneAll, Whatshot } from '@material-ui/icons';
import { orderBy } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import {
    awaitingClientStage,
    responseReceivedStage,
    rocketScreenCompleteStage,
    rocketScreenScheduledStage
} from 'shared/models/job-stages';

import { awaitingClientAgeThreshold, responseReceivedAgeThreshold } from '../../common/job-board-utils';
import { getLocalStorageKey, setLocalStorageKey } from '../../common/local-storage';
import { PageDialogLink } from '../../common/page-dialog-link';
import { ClientCandidates } from '../../containers/client-candidates';
import { Job, RECRUITER_JOBS, RecruiterJobKind } from '../../graphql/queries/home';
import { MATERIALIZED_VIEW_REFRESH, RefreshesData } from '../../graphql/queries/view_refreshes';
import { useSlides } from '../../hooks/use-candidate-slides';
import { useUserContext } from '../../hooks/use-user-context';
import { useUserDataChanges } from '../../hooks/use-user-data-changes';
import { HomePageTable } from './home-page-table';
import { InfoIcon } from './info-icon';
import { JobActions } from './job-actions';
import { JobSearchBox } from './job-search-box';
import { JobTitle } from './job-title';

const rowsPerPage = 8;

const titleStyle = css`
    .MuiSelect-root {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.334;
        letter-spacing: -0.05px;
    }
`;

type JobKind = 'bookmarked' | 'active-candidate' | 'active-search' | 'assigned';
type FilterOption =
    | 'assigned-and-bookmarked'
    | 'assigned'
    | 'bookmarked'
    | 'active-search'
    | 'active-candidate'
    | 'all';
const filterOptions: FilterOption[] = [
    'all',
    'assigned-and-bookmarked',
    'assigned',
    'bookmarked',
    'active-search',
    'active-candidate'
];
const filterOptionToJobKinds: Map<FilterOption, JobKind[]> = new Map([
    ['all', ['active-candidate', 'active-search', 'assigned', 'bookmarked']],
    ['assigned-and-bookmarked', ['assigned', 'bookmarked']],
    ['assigned', ['assigned']],
    ['bookmarked', ['bookmarked']],
    ['active-candidate', ['active-candidate']],
    ['active-search', ['active-search']]
]);
const filterOptionLabel: Map<FilterOption, string> = new Map([
    ['all', 'All Jobs with Activity'],
    ['assigned-and-bookmarked', 'Assigned and Bookmarked Jobs'],
    ['assigned', 'Assigned Jobs'],
    ['bookmarked', 'Bookmarked Jobs'],
    ['active-candidate', 'Jobs with Active Candidates'],
    ['active-search', 'Jobs with Active Searches']
]);

const FireIcon: React.FC<{}> = () => {
    const theme = useTheme();
    return (
        <InfoIcon tooltip="Needs Attention" icon={<Whatshot />} color={theme.palette.error.main} className="left-pad" />
    );
};

const CheckedIcon: React.FC<{}> = () => {
    const theme = useTheme();
    return <InfoIcon tooltip="All Done" icon={<DoneAll />} color={theme.palette.success.main} className="left-pad" />;
};

const JobTableRow: React.FC<{ data: Job; list: Job[] }> = ({ data }) => {
    const { user } = useUserContext();
    const { setList } = useSlides();

    const handleShowCandidates =
        (
            stage:
                | 'response_received'
                | 'rocket_screen_scheduled'
                | 'rocket_screen_complete'
                | 'awaiting_client_feedback'
        ) =>
        () => {
            const candidates = data.candidates.filter((c) => c.stage === stage && c.assignee === user.id);
            if (candidates.length > 0) {
                setList(candidates, candidates[0]);
            }
        };

    const responseReceivedCandidates = data.candidates.filter(
        (c) => c.stage === responseReceivedStage && c.assignee === user.id
    );
    const rocketScreenScheduledCandidates = data.candidates.filter(
        (c) => c.stage === rocketScreenScheduledStage && c.assignee === user.id
    );
    const rocketScreenCompleteCandidates = data.candidates.filter(
        (c) => c.stage === rocketScreenCompleteStage && c.assignee === user.id
    );
    const awaitingClientCandidates = data.candidates.filter(
        (c) => c.stage === awaitingClientStage && c.assignee === user.id
    );

    const oldestRRCandidate = responseReceivedCandidates.sort(
        (c1, c2) => c1.lastStageChangedAt - c2.lastStageChangedAt
    )[0];
    const rrIcon =
        responseReceivedCandidates.length === 0 ? (
            <CheckedIcon />
        ) : responseReceivedCandidates.length > 0 &&
          Date.now() - (oldestRRCandidate.lastStageChangedAt ?? Date.now()) > responseReceivedAgeThreshold ? (
            <FireIcon />
        ) : null;
    const oldestAwaitingClientCandidate = awaitingClientCandidates.sort(
        (c1, c2) => c1.lastStageChangedAt - c2.lastStageChangedAt
    )[0];
    const clientFire =
        awaitingClientCandidates.length > 0 &&
        Date.now() - (oldestAwaitingClientCandidate.lastStageChangedAt ?? Date.now()) > awaitingClientAgeThreshold ? (
            <FireIcon />
        ) : null;
    return (
        <TableRow key={`recruiter-jobs-table-row-${data.id}`}>
            <TableCell>
                <PageDialogLink
                    Component={ClientCandidates}
                    componentProps={{ id: data.client.id }}
                    url={`/client/${data.client.id}/candidates`}
                >
                    <Link to={`/client/${data.client.id}/candidates`}>{data.client.name}</Link>
                </PageDialogLink>
            </TableCell>
            <TableCell>
                <JobTitle job={data} />
            </TableCell>
            <TableCell onClick={handleShowCandidates('response_received')}>
                <span style={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer' }}>
                    {responseReceivedCandidates.length} {rrIcon}
                </span>
            </TableCell>
            <TableCell onClick={handleShowCandidates('rocket_screen_scheduled')}>
                <span style={{ cursor: 'pointer' }}>{rocketScreenScheduledCandidates.length}</span>
            </TableCell>
            <TableCell onClick={handleShowCandidates('rocket_screen_complete')}>
                <span style={{ cursor: 'pointer' }}>{rocketScreenCompleteCandidates.length}</span>
            </TableCell>
            <TableCell onClick={handleShowCandidates('awaiting_client_feedback')}>
                <span style={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer' }}>
                    {awaitingClientCandidates.length} {clientFire}
                </span>
            </TableCell>
            <TableCell className="actions-cell">
                <JobActions
                    id={data.id}
                    client={data.client}
                    status={data.status}
                    dailyEmailLimit={data.dailyEmailLimit}
                />
            </TableCell>
        </TableRow>
    );
};

export const RecruiterJobsTable: React.FC = () => {
    const pollInterval = 900000; // 15 minutes
    const { user } = useUserContext();
    const { dataModificationTs } = useUserDataChanges();
    const localStorageKey = 'recruiter-jobs-filter';
    const savedFilter = getLocalStorageKey(localStorageKey, 'assigned-and-bookmarked');
    const [filter, setFilter] = React.useState<FilterOption>(savedFilter);
    const { data: userJobsRefreshData } = useSubscription<RefreshesData, { name: string }>(MATERIALIZED_VIEW_REFRESH, {
        variables: { name: 'user_recruiting_jobs' }
    });
    const { data, loading, refetch } = useQuery<
        { jobs: Array<{ job: Job; kind: RecruiterJobKind[] }> },
        { userId: string; kind: JobKind[] }
    >(RECRUITER_JOBS, {
        pollInterval,
        variables: { userId: user.id, kind: filterOptionToJobKinds.get(filter) }
    });

    React.useEffect(() => {
        if (!loading) {
            refetch();
        }
    }, [dataModificationTs?.lastJobModifiedAt, userJobsRefreshData?.refreshes.lastRefreshAt]);

    const jobs = data?.jobs;

    const handleFilterChange = (event: React.ChangeEvent<{ value: FilterOption }>) => {
        setFilter(event.target.value);
        setLocalStorageKey(localStorageKey, event.target.value, -1);
    };

    const handleFilterClose = (event: React.ChangeEvent<{}>) => {
        event.stopPropagation();
    };

    const columns = [
        { title: 'Client', sortFunc: (j: Job) => j.client.name },
        { title: 'Job', sortFunc: (j: Job) => j.title },
        {
            defaultDesc: true,
            sortFunc: (j: Job) =>
                j.candidates.filter((c) => c.stage === responseReceivedStage && c.assignee === user.id).length,
            title: 'Response Received'
        },
        {
            defaultDesc: true,
            sortFunc: (j: Job) =>
                j.candidates.filter((c) => c.stage === rocketScreenScheduledStage && c.assignee === user.id).length,
            title: 'Scheduled'
        },
        {
            defaultDesc: true,
            sortFunc: (j: Job) =>
                j.candidates.filter((c) => c.stage === rocketScreenCompleteStage && c.assignee === user.id).length,
            title: 'Screen Complete'
        },
        {
            defaultDesc: true,
            sortFunc: (j: Job) =>
                j.candidates.filter((c) => c.stage === awaitingClientStage && c.assignee === user.id).length,
            title: 'Awaiting Client'
        },
        { title: 'Actions' }
    ];

    const initialSortedJobs =
        orderBy(jobs, [(j) => j.job.client.name, (j) => j.job.title], ['asc', 'asc']).map((j) => j.job) ?? [];

    const menuItems = filterOptions.map((opt) => (
        <MenuItem key={opt} value={opt}>
            {filterOptionLabel.get(opt)}
        </MenuItem>
    ));
    const title = (
        <Select
            value={filter}
            disableUnderline={true}
            onChange={handleFilterChange}
            onClose={handleFilterClose}
            css={titleStyle}
        >
            {menuItems}
        </Select>
    );

    return (
        <HomePageTable
            columns={columns}
            records={initialSortedJobs}
            RowComponent={JobTableRow}
            title={title}
            sortKeyId="recruiter-jobs"
            initialSort={{ column: 'Client', ascending: true }}
            rowsPerPage={rowsPerPage}
            actions={<JobSearchBox />}
            showIfEmpty={true}
        />
    );
};
