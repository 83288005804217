import { useLazyQuery } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Theme,
    Tooltip,
    Typography,
    useTheme
} from '@material-ui/core';
import { escapeRegExp } from 'lodash';
import { orderBy } from 'lodash';
import * as React from 'react';
import { jobTypeLabel } from 'shared/common/job-constants';

import { JobStatus } from 'shared/models/job';
import {
    amRejected,
    amRequestedInfo,
    amSubmitApproved,
    awaitingAMApproval,
    awaitingClientStage,
    beingScheduledStage,
    clientFinalRoundStage,
    clientFirstRoundStage,
    clientMiddleRoundStage,
    emailFoundStage,
    hiredStage,
    offerStage,
    responseReceivedStage,
    rocketScreenCompleteStage,
    rocketScreenScheduledStage,
    sourcedStage,
    totalFunnelCount
} from 'shared/models/job-stages';
import { hasRole } from 'shared/models/user';

import { SearchTextField } from '../common/search-text-field';
import { timeFrom } from '../common/timestamp';
import { Spinner } from '../core-ui/spinner';
import { JOBS_WITH_METRICS, JobWithMetrics } from '../graphql/queries/jobs-table';
import { useLocalStorage } from '../hooks/use-local-storage';
import { useSession } from '../hooks/use-session';
import { JobStatusFilter, jobStatusForFilter, JobStatusLabels } from '../state';
import { JobTitle } from './home/job-title';
import { JobFunnelTargetsForm } from './job-funnel-targets-form';
import { JobsStatusFilters } from './jobs-table-status-filters';
import { TableColumnsSelector } from './table-columns-selector';

// tslint:disable:no-magic-numbers
const styles = (theme: Theme) => css`
    background: #f4f6f8;
    flex: 1 1 auto;
    padding: 25px 50px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .filters {
        display: flex;
        flex: 0 0 auto;
        justify-content: flex-end;
        padding: 0;
        margin-bottom: 20px;
    }

    .MuiPaper-root {
        display: flex;
        overflow: auto;
        flex-direction: column;
        justify-content: space-between;
        .MuiTableContainer-root {
            &::-webkit-scrollbar {
                border-left: thin solid ${theme.palette.divider};
                border-top: thin solid ${theme.palette.divider};
            }
            &::-webkit-scrollbar:vertical {
                border-left: thin solid ${theme.palette.divider};
            }
            .MuiTableRow-root:last-child {
                td.MuiTableCell-root.MuiTableCell-body {
                    border-bottom: none;
                }
            }
        }
    }

    .MuiTableRow-root:hover {
        background-color: ${theme.palette.action.hover};
    }

    .pagination {
        flex: 0 0 auto;
        border-top: thin solid ${theme.palette.divider};
    }

    .metric-goal {
        font-weight: 600;

        &.green {
            color: ${theme.palette.success.dark};
        }

        &.yellow {
            color: ${theme.palette.warning.dark};
        }

        &.red {
            color: ${theme.palette.error.main};
        }

        &.clickable {
            cursor: pointer;
        }
    }

    .metric-goal-delta {
        padding: 3px 6px;
        border-radius: ${theme.shape.borderRadius}px;
        font-weight: 500;

        &.green {
            background-color: ${theme.palette.success.light};
            color: ${theme.palette.success.contrastText};
        }

        &.yellow {
            background-color: ${theme.palette.warning.light};
            color: ${theme.palette.warning.contrastText};
        }

        &.red {
            background-color: ${theme.palette.error.main};
            color: ${theme.palette.error.contrastText};
        }

        &.clickable {
            cursor: pointer;
        }
    }
`;
// tslint:enable:no-magic-numbers

const defaultDisplayed = [
    'Account Manager',
    'Client',
    'Created',
    'Job',
    '# Accepted (14d)',
    '# Accepts Delta (14d)'
].reduce((acc, col) => ({ ...acc, [col]: true }), {});

const JobsTableColumns = [
    'Client',
    'Job',
    'Account Manager',
    '# Accepted (14d)',
    '# Accepts Delta (14d)',
    '# Submitted (14d)',
    '# Beyond RR (14d)',
    '# Responded (14d)',
    '# Emails Sent (14d)',
    'Created',
    'Type',
    'Status',
    'Location',
    'Sourced',
    'Email Found',
    'Response Received',
    'Rocket Stages',
    'Being Scheduled',
    'Rocket Screen Scheduled',
    'Rocket Screen Complete',
    'AM Requested Info',
    'AM Rejected',
    'Awaiting AM Approval',
    'Ready To Submit',
    'Awaiting Client',
    'Client Stages',
    'Client First Round',
    'Client Middle Round',
    'Client Final Round',
    'Offer',
    'Hired'
];

const rowsPerPage = 20;

export const JobsMetricsTable: React.FC<{}> = () => {
    const theme = useTheme();
    const { user, userPermissions } = useSession();

    const [sortCol, setSortCol] = useLocalStorage<string>('jobs-metrics-table-sort-column-2', '# Accepts Delta (14d)');
    const [sortAsc, setSortAsc] = useLocalStorage<boolean>('jobs-metrics-table-sort-asc-1', true);
    const [statusFilter, setStatusFilter] = useLocalStorage<JobStatusFilter>(
        'jobs-metrics-table-job-status-1',
        'active'
    );
    const [displayedColumns, setDisplayedColumns] = useLocalStorage<{ [col: string]: boolean }>(
        'jobs-metrics-table-cols-2',
        defaultDisplayed
    );

    const [page, setPage] = React.useState(0);
    const [search, setSearch] = React.useState('');
    const [updatingTargetJobId, setUpdatingTargetJobId] = React.useState<string | undefined>(undefined);

    const [fetchJobs, { data, refetch }] = useLazyQuery<
        { jobs: JobWithMetrics[]; jobStages: Array<{ id: string; name: string }> },
        { status: JobStatus[] }
    >(JOBS_WITH_METRICS);

    const { jobs, jobStages } = data ?? { jobs: undefined, jobStages: undefined };

    React.useEffect(() => {
        fetchJobs({ variables: { status: jobStatusForFilter.get(statusFilter) } });
    }, [statusFilter]);

    const stageCount = (job: JobWithMetrics, stage: string) => {
        return job.stageFunnelCounts[stage] ? totalFunnelCount(job.stageFunnelCounts[stage].qualified) : 0;
    };

    const betweenStagesCount = (job: JobWithMetrics, pre: string, post: string) => {
        const preIndex = jobStages.find((s) => s.name === pre).id;
        const postIndex = jobStages.find((s) => s.name === post).id;
        const betweenStages = jobStages.filter((s) => s.id > preIndex && s.id < postIndex);

        let count = 0;
        for (const stage of betweenStages) {
            count += stageCount(job, stage.name);
        }
        return count;
    };

    const metricColor = (value: number, goal: number) =>
        value >= goal ? 'green' : value >= goal / 2 ? 'yellow' : 'red';

    const metricWithGoal = (value: number, goal: number, onClick?: () => void) => {
        const color = metricColor(value, goal);
        return (
            <Tooltip title={`Target: ${goal}`}>
                <span className={`metric-goal ${color} ${!!onClick ? 'clickable' : ''}`} onClick={onClick}>
                    {value}
                </span>
            </Tooltip>
        );
    };

    const handleAcceptTargetClick = (jobId: string) => () => {
        setUpdatingTargetJobId(jobId);
    };

    const handleCloseUpdateTargetDialog = () => setUpdatingTargetJobId(undefined);

    const handleJobTargetUpdated = () => {
        refetch();
        handleCloseUpdateTargetDialog();
    };

    const getColumnContent = (column: string, job: JobWithMetrics) => {
        switch (column) {
            case 'Client':
                return job.client.name;
            case 'Job':
                return <JobTitle job={job} />;

            case 'Assignee':
                return job.assignee?.name;
            case 'Account Manager':
                return job.accountManager?.name;
            case 'Type':
                return jobTypeLabel(job.jobType);
            case 'Status':
                return JobStatusLabels.get(job.status);
            case 'Location':
                return job.description.location;
            case 'Created':
                return timeFrom(job.createdAt);
            case 'Sourced':
                return stageCount(job, sourcedStage);
            case 'Response Received':
                return stageCount(job, responseReceivedStage);
            case 'Rocket Stages':
                return betweenStagesCount(job, responseReceivedStage, awaitingClientStage);
            case 'Being Scheduled':
                return stageCount(job, beingScheduledStage);
            case 'Rocket Screen Scheduled':
                return stageCount(job, rocketScreenScheduledStage);
            case 'Rocket Screen Complete':
                return stageCount(job, rocketScreenCompleteStage);
            case 'AM Requested Info':
                return stageCount(job, amRequestedInfo);
            case 'Awaiting AM Approval':
                return stageCount(job, awaitingAMApproval);
            case 'Ready To Submit':
                return stageCount(job, amSubmitApproved);
            case 'AM Rejected':
                return stageCount(job, amRejected);
            case 'Awaiting Client':
                return stageCount(job, awaitingClientStage);
            case 'Client Stages':
                return betweenStagesCount(job, awaitingClientStage, hiredStage);
            case 'Email Found':
                return stageCount(job, emailFoundStage);
            case 'Client First Round':
                return stageCount(job, clientFirstRoundStage);
            case 'Client Middle Round':
                return stageCount(job, clientMiddleRoundStage);
            case 'Client Final Round':
                return stageCount(job, clientFinalRoundStage);
            case 'Offer':
                return stageCount(job, offerStage);
            case 'Hired':
                return stageCount(job, hiredStage);
            case '# Emails Sent (14d)':
                return <span>{metricWithGoal(job.metrics.emailsSent, job.targets.outreach_sent)}</span>;
            case '# Responded (14d)':
                return metricWithGoal(job.metrics.responseRate, job.targets.response_received);
            case '# Beyond RR (14d)':
                return metricWithGoal(job.metrics.movedPastResponseReceived, job.targets.being_scheduled);
            case '# Submitted (14d)':
                return metricWithGoal(job.metrics.submits, job.targets.awaiting_client_feedback);
            case '# Accepted (14d)': {
                const onClick =
                    job.accountManager?.id === user?.id || hasRole(userPermissions, 'job_funnel_target_editor')
                        ? handleAcceptTargetClick(job.id)
                        : undefined;
                return metricWithGoal(job.metrics.accepts, job.targets.client_first_round, onClick);
            }
            case '# Accepts Delta (14d)': {
                const onClick =
                    job.accountManager?.id === user?.id || hasRole(userPermissions, 'job_funnel_target_editor')
                        ? handleAcceptTargetClick(job.id)
                        : undefined;
                const color = metricColor(job.metrics.accepts, job.targets.client_first_round);
                return (
                    <Tooltip title={`${job.metrics.accepts} vs Target: ${job.targets.client_first_round}`}>
                        <span
                            className={`metric-goal-delta ${color} ${!!onClick ? 'clickable' : ''}`}
                            onClick={onClick}
                        >
                            {job.metrics.accepts - job.targets.client_first_round}
                        </span>
                    </Tooltip>
                );
            }
            default:
                return undefined;
        }
    };

    const sortFuncs = (col: string, sortOrder: 'asc' | 'desc') => {
        switch (col) {
            case 'Client':
                return { functions: [(j: JobWithMetrics) => j.client.name.toLocaleLowerCase()], order: [sortOrder] };
            case 'Job':
                return { functions: [(j: JobWithMetrics) => j.title], order: [sortOrder] };
            case 'Assignee':
                return {
                    functions: [(j: JobWithMetrics) => j.assignee?.name.toLocaleLowerCase()],
                    order: [sortOrder]
                };
            case 'Account Manager':
                return {
                    functions: [(j: JobWithMetrics) => j.accountManager?.name.toLocaleLowerCase()],
                    order: [sortOrder]
                };
            case 'Type':
                return { functions: [(j: JobWithMetrics) => j.jobType], order: [sortOrder] };
            case 'Status':
                return { functions: [(j: JobWithMetrics) => j.status], order: [sortOrder] };
            case 'Created':
                return { functions: [(j: JobWithMetrics) => -j.createdAt], order: [sortOrder] };
            case 'Location':
                return { functions: [(j: JobWithMetrics) => j.description?.location?.trim()], order: [sortOrder] };
            case 'Sourced':
                return { functions: [(j: JobWithMetrics) => stageCount(j, sourcedStage)], order: [sortOrder] };
            case 'Email Found':
                return { functions: [(j: JobWithMetrics) => stageCount(j, emailFoundStage)], order: [sortOrder] };
            case 'Response Received':
                return {
                    functions: [(j: JobWithMetrics) => stageCount(j, responseReceivedStage)],
                    order: [sortOrder]
                };
            case 'Rocket Stages':
                return {
                    functions: [
                        (j: JobWithMetrics) => betweenStagesCount(j, responseReceivedStage, awaitingClientStage)
                    ],
                    order: [sortOrder]
                };
            case 'Being Scheduled': {
                return {
                    functions: [(j: JobWithMetrics) => stageCount(j, beingScheduledStage)],
                    order: [sortOrder]
                };
            }
            case 'Rocket Screen Scheduled': {
                return {
                    functions: [(j: JobWithMetrics) => stageCount(j, rocketScreenScheduledStage)],
                    order: [sortOrder]
                };
            }
            case 'Rocket Screen Complete': {
                return {
                    functions: [(j: JobWithMetrics) => stageCount(j, rocketScreenCompleteStage)],
                    order: [sortOrder]
                };
            }
            case 'AM Requested Info':
                return {
                    functions: [(j: JobWithMetrics) => stageCount(j, amRequestedInfo)],
                    order: [sortOrder]
                };
            case 'AM Rejected':
                return {
                    functions: [(j: JobWithMetrics) => stageCount(j, amRejected)],
                    order: [sortOrder]
                };
            case 'Awaiting AM Approval':
                return {
                    functions: [(j: JobWithMetrics) => stageCount(j, awaitingAMApproval)],
                    order: [sortOrder]
                };
            case 'Ready To Submit':
                return {
                    functions: [(j: JobWithMetrics) => stageCount(j, amSubmitApproved)],
                    order: [sortOrder]
                };
            case 'Awaiting Client':
                return {
                    functions: [(j: JobWithMetrics) => stageCount(j, awaitingClientStage)],
                    order: [sortOrder]
                };
            case 'Client Stages':
                return {
                    functions: [(j: JobWithMetrics) => betweenStagesCount(j, awaitingClientStage, hiredStage)],
                    order: [sortOrder]
                };
            case 'Client First Round': {
                return {
                    functions: [(j: JobWithMetrics) => stageCount(j, clientFirstRoundStage)],
                    order: [sortOrder]
                };
            }
            case 'Client Middle Round': {
                return {
                    functions: [(j: JobWithMetrics) => stageCount(j, clientMiddleRoundStage)],
                    order: [sortOrder]
                };
            }
            case 'Client Final Round': {
                return {
                    functions: [(j: JobWithMetrics) => stageCount(j, clientFinalRoundStage)],
                    order: [sortOrder]
                };
            }
            case 'Offer': {
                return {
                    functions: [(j: JobWithMetrics) => stageCount(j, offerStage)],
                    order: [sortOrder]
                };
            }
            case '# Emails Sent (14d)': {
                return {
                    functions: [(j: JobWithMetrics) => j.metrics?.emailsSent ?? 0],
                    order: [sortOrder]
                };
            }
            case '# Responded (14d)': {
                return {
                    functions: [(j: JobWithMetrics) => j.metrics?.responseRate ?? 0],
                    order: [sortOrder]
                };
            }
            case '# Beyond RR (14d)': {
                return {
                    functions: [(j: JobWithMetrics) => j.metrics?.movedPastResponseReceived ?? 0],
                    order: [sortOrder]
                };
            }
            case '# Submitted (14d)': {
                return {
                    functions: [(j: JobWithMetrics) => j.metrics?.submits ?? 0],
                    order: [sortOrder]
                };
            }
            case '# Accepted (14d)': {
                return {
                    functions: [(j: JobWithMetrics) => j.metrics?.accepts ?? 0],
                    order: [sortOrder]
                };
            }
            case '# Accepts Delta (14d)':
                return {
                    functions: [
                        (j: JobWithMetrics) => (j.metrics?.accepts ?? 0) - (j.targets?.client_first_round ?? 0)
                    ],
                    order: [sortOrder]
                };
            default:
                return { functions: [], order: [] };
        }
    };

    const handleSortChange = (column: string) => () => {
        const newSortAsc = sortCol === column ? !sortAsc : true;
        setSortCol(column);
        setSortAsc(newSortAsc);
    };

    const handleChangePage = (_1: any, newPage: number) => setPage(newPage);
    const handleChangeRowsPerPage = () => {
        /* no-op */
    };

    const handleSelectFilter = (filter: JobStatusFilter) => {
        setStatusFilter(filter);
        setPage(0);
    };

    const handleUpdateDisplayedColumns = (col: string, selected: boolean) => {
        const updated = { ...displayedColumns, [col]: selected };
        setDisplayedColumns(updated);
    };

    const handleSearchChange = (newSearch: string) => {
        setSearch(newSearch);
        setPage(0);
    };

    if (!data) {
        return <Spinner />;
    }

    const getSortDirection = (ascending: boolean) => (ascending ? 'asc' : 'desc');
    const columns = JobsTableColumns.filter((c) => displayedColumns[c]);
    const headerColumns = columns.map((col) => {
        const columnHeader = (
            <TableSortLabel
                active={sortCol === col}
                direction={getSortDirection(sortAsc)}
                onClick={handleSortChange(col)}
            >
                {col}
            </TableSortLabel>
        );
        return <TableCell key={col}>{columnHeader}</TableCell>;
    });

    const searchRegex = new RegExp(escapeRegExp(search), 'i');
    const filteredJobs = jobs.filter(
        (j) =>
            jobStatusForFilter.get(statusFilter).indexOf(j.status) !== -1 &&
            (!search ||
                j.accountManager?.name.match(searchRegex) ||
                j.title.match(searchRegex) ||
                j.client.name.match(searchRegex) ||
                j.assignee?.name.match(searchRegex))
    );
    const { functions, order } = sortFuncs(sortCol, getSortDirection(sortAsc));
    const rows = orderBy(filteredJobs, functions, order)
        .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
        .map((job) => {
            const row = columns.map((c) => {
                return <TableCell key={c}>{getColumnContent(c, job)}</TableCell>;
            });
            return <TableRow key={job.id}>{row}</TableRow>;
        });

    const pagination =
        rowsPerPage && filteredJobs.length > rowsPerPage ? (
            <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                component="div"
                count={filteredJobs.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                className="pagination"
            />
        ) : null;

    const header = <TableRow>{headerColumns}</TableRow>;

    const updatingJobTargetDialog = (
        <Dialog open={!!updatingTargetJobId} onClose={handleCloseUpdateTargetDialog} maxWidth="sm" fullWidth={true}>
            <DialogTitle>
                <Typography variant="h5" component="div">
                    Allocations & Funnel Targets for a 14 day period
                </Typography>
            </DialogTitle>
            <DialogContent>
                <JobFunnelTargetsForm jobId={updatingTargetJobId} disabled={false} onChange={handleJobTargetUpdated} />
            </DialogContent>
        </Dialog>
    );

    return (
        <div css={styles(theme)}>
            <div className="filters">
                <SearchTextField value={search} variant="outlined" onValueChange={handleSearchChange} />
                <JobsStatusFilters selectedFilter={statusFilter} onSelectFilter={handleSelectFilter} />
                <TableColumnsSelector
                    columns={JobsTableColumns}
                    selected={displayedColumns}
                    onSelect={handleUpdateDisplayedColumns}
                />
            </div>
            <Paper>
                <TableContainer className="table">
                    <Table stickyHeader={true}>
                        <TableHead>{header}</TableHead>
                        <TableBody>{rows}</TableBody>
                    </Table>
                </TableContainer>
                {pagination}
            </Paper>
            {updatingJobTargetDialog}
        </div>
    );
};
