import { css } from '@emotion/core';
import * as React from 'react';

import { LightTooltip as Tooltip } from '../core-ui/light-tooltip';

const styles = css`
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    margin: -4px -8px;
    padding: 12px 15px;

    .search-help-text-title {
        font-weight: 500;
        margin-bottom: 5px;
    }

    ul {
        margin: 0;
        padding: 5px 14px 0;
        line-height: 15px;

        li {
            margin-bottom: 8px;
        }
    }
`;

export const SearchHelp: React.FC<{
    title: string;
    examples: string[];
}> = ({ title, examples }) => {
    const exampleList = examples.map((s, i) => <li key={i}>{s}</li>);
    const exampleContent = (
        <div css={styles}>
            <div className="search-help-text-title">{title}</div>
            <ul>{exampleList}</ul>
        </div>
    );
    return (
        <Tooltip title={exampleContent} interactive={true}>
            <div className="search-help">
                <i className="material-icons list-icon">help</i>
            </div>
        </Tooltip>
    );
};
