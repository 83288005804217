import { FlatButton } from 'material-ui';
import { white } from 'material-ui/styles/colors';
import * as React from 'react';

import { unexpectedEmailAddress } from '../common/communication-utils';
import { Communication } from '../state';

interface CommunicationWarningUnknownEmailProps {
    data: Communication;
    addContactFromMessage: (payload: {
        account: string;
        personId: string;
        value: string;
        messageId: string;
        threadId: string;
    }) => void;
    ignoreContactFromMessage: (account: string, messageId: string, threadId: string) => void;
}

export const CommunicationWarningUnknownEmail: React.FunctionComponent<CommunicationWarningUnknownEmailProps> = ({
    addContactFromMessage,
    ignoreContactFromMessage,
    data
}) => {
    const { account, messageId, threadId } = data;

    const handleAutoAddEmail = () => {
        const { unknownEmailFound, personIds } = data;
        const personId = personIds[0];
        addContactFromMessage({ account, personId, messageId, value: unknownEmailFound, threadId });
    };

    const handleIgnoreContactFromMessage = () => {
        ignoreContactFromMessage(account, messageId, threadId);
    };

    const hasUnexpectedEmail = unexpectedEmailAddress(data);
    const addUnexpectedEmailButton =
        hasUnexpectedEmail && data.personIds && data.personIds.length === 1 ? (
            <FlatButton onClick={handleAutoAddEmail} labelStyle={{ color: white }} key="add-email" label="Add Email" />
        ) : null;
    const ignoreUnexpectedEmailButton =
        hasUnexpectedEmail && data.personIds && data.personIds.length === 1 ? (
            <FlatButton
                onClick={handleIgnoreContactFromMessage}
                labelStyle={{ color: white }}
                key="ignore-email"
                label="Ignore New Email Address"
            />
        ) : null;

    const unexpectedEmailWarning = hasUnexpectedEmail ? (
        <div className="email-header-warning unexpected-email">
            <div>The email address {data.unknownEmailFound} is not yet associated with this candidate.</div>
            <div>
                {addUnexpectedEmailButton}
                {ignoreUnexpectedEmailButton}
            </div>
        </div>
    ) : null;

    return unexpectedEmailWarning;
};
