import { css } from '@emotion/core';
import { Button, Typography } from '@material-ui/core';
import { cloneDeep, uniq } from 'lodash';
import React from 'react';

import { JobRecruiterPermissions } from 'shared/models/job';

import { MultiAutocomplete, SelectField } from '../core-ui/form-fields';
import { JobFormData } from '../graphql/queries/job-form';
import { useModal } from '../hooks/use-modal';
import { useUsers } from '../hooks/use-users';
import { useFormContext } from './form-context';

const styles = css`
    .field-permissions {
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 32px;
        }

        .field-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
        }

        .copy-to-all {
            opacity: 0;
            transition: opacity 0.2s;
        }

        &:hover .copy-to-all {
            opacity: 1;
        }
    }
`;

export const JobFormRecruiterPermissions: React.FC = () => {
    const {
        data: { recruiterPermissions, accountManagerId, assignee },
        onFieldChange,
        disabled
    } = useFormContext<JobFormData>();
    const { users } = useUsers([accountManagerId, assignee]);
    const { getConfirmation } = useModal();

    const userOptions = users?.map((u) => ({ value: u.id, label: u.name })) ?? [];

    const permissionFields: Array<keyof JobRecruiterPermissions> = [
        'sendOutreach',
        'createSearch',
        'replyToClientEmails',
        'dqAtClientStage'
    ];

    const permissionsDescription: { [Prop in keyof JobRecruiterPermissions]: string } = {
        createSearch: 'Create Searches',
        dqAtClientStage: 'Disqualifying in Client Stages',
        replyToClientEmails: 'Reply to Client Emails',
        sendOutreach: 'Sending Outreach'
    };

    const handleAllowAllChange = (field: keyof JobRecruiterPermissions) => (val: string) => {
        const updated = val === 'true';
        onFieldChange('recruiterPermissions')({
            ...recruiterPermissions,
            [field]: {
                allowAll: updated,
                allowedUserIds: updated ? [] : uniq([accountManagerId, assignee]),
                disallowedUserIds: []
            }
        });
    };

    const handleUserIdsListChange = (field: keyof JobRecruiterPermissions) => (value: string[]) => {
        const list = recruiterPermissions[field]?.allowAll ? 'disallowedUserIds' : 'allowedUserIds';
        onFieldChange('recruiterPermissions')({
            ...recruiterPermissions,
            [field]: {
                ...recruiterPermissions[field],
                [list]: value
            }
        });
    };

    const handleCopyToAll = (field: keyof JobRecruiterPermissions) => () => {
        getConfirmation(
            () => {
                const perm = recruiterPermissions[field];
                onFieldChange('recruiterPermissions')({
                    createSearch: cloneDeep(perm),
                    dqAtClientStage: cloneDeep(perm),
                    replyToClientEmails: cloneDeep(perm),
                    sendOutreach: cloneDeep(perm)
                });
            },
            <span>
                Are you sure you want to copy <b>{permissionsDescription[field]}</b> permission to all fields?
            </span>,
            'Copy to all'
        );
    };

    const allowAllOpts = [
        { value: 'false', label: 'No' },
        { value: 'true', label: 'Yes' }
    ];

    const content = permissionFields.map((field) => {
        const allowAll = recruiterPermissions[field]?.allowAll;

        const userFieldLabel = allowAll ? 'Disallow Specific Recruiters' : 'Allow Specific Recruiters';
        const userFieldValue = recruiterPermissions[field]?.[allowAll ? 'disallowedUserIds' : 'allowedUserIds'] ?? [];
        const copyToALlButton = disabled ? null : (
            <Button onClick={handleCopyToAll(field)} className="copy-to-all">
                Copy to all
            </Button>
        );
        return (
            <div className="field-permissions" key={field}>
                <div className="field-title">
                    <Typography variant="h6" component="div">
                        {permissionsDescription[field]}
                    </Typography>
                    {copyToALlButton}
                </div>
                <div className="job-field-row narrow">
                    <SelectField
                        fullWidth={true}
                        label="Allow everyone by default"
                        value={allowAll ? 'true' : 'false'}
                        onChange={handleAllowAllChange(field)}
                        select={true}
                        disabled={disabled}
                        options={allowAllOpts}
                    />
                </div>
                <div className="job-field-row narrow">
                    <MultiAutocomplete
                        label={userFieldLabel}
                        fullWidth={true}
                        options={userOptions}
                        value={userFieldValue}
                        onChange={handleUserIdsListChange(field)}
                        disabled={disabled}
                    />
                </div>
            </div>
        );
    });

    return <div css={styles}>{content}</div>;
};
