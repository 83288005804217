import * as React from 'react';

// tslint:disable

const colorDefinitions = {
    primary1Color: '#596D85',
    primary2Color: '#0097a7',
    primary3Color: '#bdbdbd',
    accent1Color: '#ff4081',
    accent2Color: '#f5f5f5',
    accent3Color: '#9e9e9e',
    textColor: 'rgba(0, 0, 0, 0.87)',
    secondaryTextColor: 'rgba(0, 0, 0, 0.54)',
    alternateTextColor: '#ffffff',
    canvasColor: '#ffffff',
    borderColor: '#e0e0e0',
    disabledColor: 'rgba(0, 0, 0, 0.3)',
    pickerHeaderColor: '#00bcd4',
    clockCircleColor: 'rgba(0, 0, 0, 0.07)',
    shadowColor: 'rgba(0, 0, 0, 1)',
    alternate2TextColor: '#2C3644',
    lightBg1Color: '#fafafa',
    lightBg2Color: '#f8f8f8',
    lightBg3Color: '#f1f1f1',
    mediumBg1Color: '#d8d8d8'
};

const list = Object.keys(colorDefinitions).map((name) => {
    const color = (colorDefinitions as any)[name];
    return (
        <div style={{ height: '200px', width: '400px', background: color }} key={name}>
            {name} {color}
        </div>
    );
});

const colors = <div style={{ display: 'flex', flexWrap: 'wrap' }}>{list}</div>;

export const Palette = () => <div>{colors}</div>;
// tslint:enable
