import PDFObject from 'pdfobject';
import React, { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { logger } from '../common/logger';

interface PdfProps {
    url: string;
    height?: string;
    width?: string;
    className?: string;
}

export const Pdf: React.FC<PdfProps> = ({ url, height, width, className }) => {
    const [id] = useState(`pdf-${v4()}`);

    useEffect(() => {
        try {
            PDFObject.embed(url, `#${id}`);
        } catch (error) {
            logger.error('Failed to embed PDF', error);
        }
    }, [url, id]);

    const style: React.CSSProperties = {
        height: height || '100%',
        width: width || '100%'
    };

    return <div id={id} className={className} style={style} />;
};
