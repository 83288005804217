import React, { PropsWithChildren } from 'react';

import { usePageDialog } from '../hooks/use-page-dialog';

interface PageDialogLinkProps<T> {
    Component: React.ComponentType<T>;
    componentProps: T;
    url: string;
}

export function PageDialogLink<T>(props: PropsWithChildren<PageDialogLinkProps<T>>) {
    const { Component, componentProps, url } = props;
    const { setDialog } = usePageDialog();

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        setDialog(<Component {...componentProps} />, url);
    };

    return <span onClickCapture={handleClick}>{props.children}</span>;
}
