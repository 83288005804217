import { Button, ButtonGroup, Tooltip, Typography } from '@material-ui/core';
import { Sort, SortByAlpha } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { sortBy } from 'lodash';
import React from 'react';
import Chart from 'react-apexcharts';

import { fontFamily } from '../../common/css-variables';
import {
    clientActivityFields,
    ClientMetrics,
    customTooltip,
    defaultAggregateViewSelected,
    Field,
    fieldLabels,
    FieldsSelected,
    getChartHeight,
    getFieldColors,
    getMetricsByClient
} from '../../common/metrics';
import { MetricsData } from '../../graphql/queries/metrics';
import { useLocalStorage } from '../../hooks/use-local-storage';
import { Legend } from './legend';

export const UserClientMetrics: React.FC<{ metrics: MetricsData[] }> = ({ metrics }) => {
    const [variant, setVariant] = useLocalStorage<'cost' | 'count'>('agg-metrics-variant', 'count');
    const [selectedFields, setSelectedFields] = useLocalStorage<FieldsSelected>(
        'agg-metrics-fields',
        defaultAggregateViewSelected
    );
    const [hoveredField, setHoveredField] = React.useState<Field>(undefined);
    const [sort, setSort] = useLocalStorage<'alpha' | 'sum'>('agg-metrics-sort', 'alpha');

    const handleVariantChange = (value: 'cost' | 'count') => () => {
        setVariant(value);
    };

    const handleSortChange = (value: 'alpha' | 'sum') => () => {
        setSort(value);
    };

    const fields = clientActivityFields.filter((f) => selectedFields[f]);

    const metricsByClient: ClientMetrics[] = getMetricsByClient(metrics, variant)?.filter(
        (m) => fields.reduce((acc, f) => m.metrics[f].value + acc, 0) > 0
    );

    const sortedMetrics = sortBy(metricsByClient, (m) => {
        if (sort === 'alpha') {
            return m.client;
        } else {
            return -fields.reduce((acc, f) => m.metrics[f].value + acc, 0);
        }
    });

    const categories = sortedMetrics?.map((v) => v.client) ?? [];
    const series = fields.map((f) => ({
        data: sortedMetrics?.map((m) => m.metrics[f].value ?? 0),
        name: fieldLabels.get(f)
    }));

    const colors = getFieldColors(fields, hoveredField);

    const chartOptions = {
        chart: { fontFamily, stacked: true, toolbar: { show: false } },
        colors,
        legend: { show: false },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true
            }
        },
        tooltip: {
            custom: customTooltip(sortedMetrics, fields, series)
        },
        xaxis: {
            categories
        }
    };

    const tabs = (
        <div className="view-selectors">
            <ButtonGroup size="small" variant="text">
                <Tooltip title="Sort Alphabetical">
                    <Button className={sort === 'alpha' ? '' : 'inactive'} onClick={handleSortChange('alpha')}>
                        <SortByAlpha fontSize="small" />
                    </Button>
                </Tooltip>
                <Tooltip title="Sort by Activity">
                    <Button className={sort === 'sum' ? '' : 'inactive'} onClick={handleSortChange('sum')}>
                        <Sort fontSize="small" />
                    </Button>
                </Tooltip>
            </ButtonGroup>
            <ButtonGroup size="small" variant="text">
                <Tooltip title="Individual Action Counts">
                    <Button className={variant === 'count' ? '' : 'inactive'} onClick={handleVariantChange('count')}>
                        Raw Counts
                    </Button>
                </Tooltip>
                <Tooltip title="Weighted sums based on estimated action time">
                    <Button className={variant === 'cost' ? '' : 'inactive'} onClick={handleVariantChange('cost')}>
                        Weighted Sums
                    </Button>
                </Tooltip>
            </ButtonGroup>
        </div>
    );

    const chart = !metrics ? (
        <Skeleton variant="rect" />
    ) : categories.length > 0 ? (
        <Chart options={chartOptions} height={getChartHeight(categories?.length ?? 0)} series={series} type="bar" />
    ) : null;

    return (
        <div className="chart-container">
            <div className="panel-header">
                <div className="panel-title">
                    <Typography variant="h5">Activity by Client</Typography>
                </div>
                <div className="panel-options">{tabs}</div>
            </div>
            <div className="panel-content">
                <div className="chart">{chart}</div>
                <Legend
                    fields={clientActivityFields}
                    selected={selectedFields}
                    onFieldHover={setHoveredField}
                    onUpdateSelected={setSelectedFields}
                />
            </div>
        </div>
    );
};
