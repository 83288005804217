import { useTheme } from '@material-ui/core';
import * as React from 'react';
import DocumentTitle from 'react-document-title';

import { JobType } from 'shared/common/job-constants';

import { createDuration, defaultPeriods, Duration } from '../common/duration';
import { useLocalStorage } from '../hooks/use-local-storage';
import { ClientsTypeSelector } from './clients-type-selector';
import { dataPanelContainerStyles, selectorStyles } from './data-panel';
import { DurationSelector } from './duration-selector';
import { Header } from './header';
import { JobsTypeSelector, jobTypesForFilter } from './jobs-type-selector';
import { PlacementTrends } from './metrics/placement-trends';

export const CandidateTrends = (): JSX.Element => {
    const theme = useTheme();
    const [duration, setDuration] = React.useState<Duration>(createDuration({ period: 'quarter', offset: 0 }));
    const [jobTypes, setJobTypes] = useLocalStorage<JobType[]>('trends-job-types', jobTypesForFilter.get('all'));
    const [clientTypes, setClientTypes] = useLocalStorage<'external-clients' | 'all'>(
        'trends-client-types',
        'external-clients'
    );

    return (
        <DocumentTitle title="Trends">
            <div id="container">
                <Header title="Trends" />
                <div id="content" className="flex-fill">
                    <div css={dataPanelContainerStyles(theme)}>
                        <div className="selectors">
                            <div css={selectorStyles}>
                                <DurationSelector selected={duration} onSelect={setDuration} periods={defaultPeriods} />
                                <JobsTypeSelector selected={jobTypes} onChange={setJobTypes} />
                                <ClientsTypeSelector selected={clientTypes} onChange={setClientTypes} />
                            </div>
                        </div>
                        <div className="data-panel">
                            <PlacementTrends startTime={duration.start} endTime={duration.end} jobTypes={jobTypes} />
                        </div>
                    </div>
                </div>
            </div>
        </DocumentTitle>
    );
};
