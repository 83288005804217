import { css } from '@emotion/core';
import { Button, MenuItem, TextField, Theme, useTheme } from '@material-ui/core';
import { times } from 'lodash';
import moment from 'moment';
import React from 'react';

import { DayPicker } from '../core-ui/react-day-picker';

interface InterviewTimeSelectorProps {
    startTime: number;
    endTime: number;
    onClose: () => void;
    onChange: (startTime: number, endTime: number) => void;
}

const styles = (theme: Theme) => css`
    .title {
        background: ${theme.palette.primary.main};
        color: ${theme.palette.primary.contrastText};
        padding: 20px;
        font-weight: 500;

        .title-year {
            font-size: 16px;
            line-height: 16px;
            margin-bottom: 10px;
        }

        .title-date {
            font-size: 36px;
            line-height: 36px;
            margin-bottom: 10px;
        }

        .title-time {
            font-size: 16px;
            line-height: 16px;
        }
    }

    .date-picker {
        margin-bottom: 10px;
        padding: 0 20px;
    }

    .time-pickers {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;

        .MuiSelect-select {
            padding-right: 0;
            margin: 0 15px;
        }

        .MuiSelect-icon {
            display: none;
        }
    }

    .actions {
        padding: 10px;
        text-align: right;
    }
`;

export const InterviewTimeSelector: React.FC<InterviewTimeSelectorProps> = ({
    startTime,
    endTime,
    onChange,
    onClose
}) => {
    const theme = useTheme();

    const handleDateChange = (date: Date) => {
        const startTimeDayStart = moment(startTime).startOf('day').valueOf();
        const newStartTime = moment(date).valueOf() + (startTime - startTimeDayStart);
        const newEndTime = newStartTime + (endTime - startTime);
        onChange(newStartTime, newEndTime);
    };

    const handleStartTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const startTimeDayStart = moment(startTime).startOf('day').valueOf();
        const newStartTime = startTimeDayStart + parseInt(e.target.value, 10);
        const newEndTime = newStartTime + (endTime - startTime);
        onChange(newStartTime, newEndTime);
    };

    const handleEndTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const startTimeDayStart = moment(startTime).startOf('day').valueOf();
        const newEndTime = startTimeDayStart + parseInt(e.target.value, 10);
        onChange(startTime, newEndTime);
    };

    const msPerMin = 60000; // 1000 * 60
    const periodDeltaMins = 15;
    const periods = 96; // 24 hours * 4 periods per hour
    const periodOffsets = times(periods).map((i) => i * periodDeltaMins * msPerMin);

    const startTimeValue = startTime - moment(startTime).startOf('day').valueOf();
    const startTimeMenuOpts = periodOffsets.map((offset) => {
        const label = moment().startOf('day').add(offset, 'ms').format('h:mm a');
        return (
            <MenuItem key={offset} value={offset}>
                {label}
            </MenuItem>
        );
    });

    const endTimeValue = endTime - moment(endTime).startOf('day').valueOf();
    const endTimeMenuOpts = periodOffsets
        .filter((offset) => offset > startTimeValue)
        .map((offset) => {
            const label = moment().startOf('day').add(offset, 'ms').format('h:mm a');
            return (
                <MenuItem key={offset} value={offset}>
                    {label}
                </MenuItem>
            );
        });

    return (
        <div css={styles(theme)}>
            <div className="title">
                <div className="title-year">{moment(startTime).format('YYYY')}</div>
                <div className="title-date">{moment(startTime).format('ddd, MMM DD')}</div>
                <div className="title-time">
                    {moment(startTime).format('h:mm a')} - {moment(endTime).format('h:mm a')}
                </div>
            </div>
            <div className="date-picker">
                <DayPicker mode="single" selected={new Date(startTime)} onSelect={handleDateChange} />
            </div>
            <div className="time-pickers">
                <TextField
                    value={startTimeValue}
                    onChange={handleStartTimeChange}
                    select={true}
                    InputProps={{ disableUnderline: true }}
                >
                    {startTimeMenuOpts}
                </TextField>
                <span>{' — '}</span>
                <TextField
                    value={endTimeValue}
                    onChange={handleEndTimeChange}
                    select={true}
                    InputProps={{ disableUnderline: true }}
                >
                    {endTimeMenuOpts}
                </TextField>
            </div>
            <div className="actions">
                <Button onClick={onClose}>Done</Button>
            </div>
        </div>
    );
};
