import { useQuery } from '@apollo/client';
import React from 'react';

import { Autocomplete } from '../core-ui/form-fields';
import { ALL_CLIENTS, Client } from '../graphql/queries/clients';
import { useFormContext } from './form-context';

export const JobFormClient: React.FC = () => {
    const { data: clientsData } = useQuery<{ clients: Client[] }>(ALL_CLIENTS);
    const { data, getError, disabled, onFieldChange, validateFieldExists } = useFormContext<{ clientId: string }>();

    const clientOpts =
        clientsData?.clients?.map((client) => ({
            label: client.name,
            value: client.id
        })) || [];

    return (
        <div className="job-field-row">
            <Autocomplete
                label="Client"
                value={data.clientId}
                onChange={onFieldChange('clientId')}
                validate={validateFieldExists('clientId')}
                disabled={disabled}
                errorText={getError('clientId')}
                options={clientOpts}
            />
        </div>
    );
};
