import { Dialog, DialogContent } from '@material-ui/core';
import { CircularProgress } from 'material-ui';
import * as React from 'react';

const spinnerContainerStyle: React.CSSProperties = {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: '60px 20px'
};

export const LoadingModal: React.FC<{}> = () => {
    return (
        <Dialog open={true} maxWidth="sm" fullWidth={true}>
            <DialogContent>
                <div style={spinnerContainerStyle}>
                    <CircularProgress />
                </div>
            </DialogContent>
        </Dialog>
    );
};
