import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { ApexOptions } from 'apexcharts';
import React from 'react';
import Chart from 'react-apexcharts';
import { ActivityMetricType } from 'shared/models/activity-metric';

import { fontFamily } from '../../common/css-variables';
import {
    defaultFunnelSelected,
    Field,
    fieldLabels,
    FieldsSelected,
    funnelMetricFields,
    funnelMetrics,
    getChartHeight,
    getFieldColors
} from '../../common/metrics';
import { useLocalStorage } from '../../hooks/use-local-storage';
import { Legend } from './legend';

export const FunnelMetricsChart: React.FC<{
    metrics: Array<{ kind: ActivityMetricType; count: number; cost: number }>;
}> = ({ metrics }) => {
    const [selectedFields, setSelectedFields] = useLocalStorage<FieldsSelected>(
        'agg-metrics-fields',
        defaultFunnelSelected
    );
    const [hoveredField, setHoveredField] = React.useState<Field>(undefined);
    const [chartOptions, setChartOptions] = React.useState<ApexOptions>(undefined);

    const metricsByField = funnelMetrics(metrics);
    const fields = funnelMetricFields.filter((f) => selectedFields[f]);
    const categories = fields.map((f) => fieldLabels.get(f));
    const data = fields.map((f) => metricsByField?.[f] ?? 0);
    const series = [{ name: 'Count', data }];

    const colors = getFieldColors(fields, hoveredField);

    React.useEffect(() => {
        if (metricsByField && data && fields) {
            setChartOptions({
                chart: { fontFamily, toolbar: { show: false } },
                colors,
                dataLabels: {
                    formatter: (value: string, { dataPointIndex }: { dataPointIndex: number }) => {
                        const val = data?.[dataPointIndex];
                        const field = fields[dataPointIndex];
                        const index = funnelMetricFields.indexOf(field);
                        // exclude outreach being computed as a % of manual sourcing
                        const baseline = index > 1 ? metricsByField[funnelMetricFields[index - 1]] : null;
                        const percent = val && baseline ? ` (${Math.round((val / baseline) * 100)}%)` : '';
                        return `${value}${percent}`;
                    }
                },
                legend: { show: false },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        distributed: true,
                        horizontal: true
                    }
                },
                xaxis: {
                    categories
                }
            });
        }
    }, [metricsByField, data, fields]);

    const chart =
        !!metrics && !!chartOptions ? (
            <Chart options={chartOptions} height={getChartHeight(categories?.length ?? 0)} series={series} type="bar" />
        ) : (
            <Skeleton variant="rect" />
        );

    return (
        <div className="chart-container">
            <div className="panel-header">
                <Typography variant="h5">Funnel Metrics</Typography>
            </div>
            <div className="panel-content">
                <div className="chart">{chart}</div>
                <Legend
                    fields={funnelMetricFields}
                    selected={selectedFields}
                    onFieldHover={setHoveredField}
                    onUpdateSelected={setSelectedFields}
                />
            </div>
        </div>
    );
};
