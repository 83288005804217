import React from 'react';
import DocumentTitle from 'react-document-title';

import { SearchProject, SearchResultsViewType } from 'shared/models/search';

import { useReduxState } from '../hooks/use-redux';
import { Search } from '../search/search';
import { SearchResults } from '../search/search-results';
import { SearchProvider } from '../search/use-search';
import { State } from '../state';

interface JobSearchProps {
    jobId: string;
    searchId: string;
    resultType: SearchResultsViewType;
    personId?: string;
    baseline: boolean;
}

export const JobSearch: React.FC<JobSearchProps> = ({ jobId, searchId, resultType, baseline, personId }) => {
    const jobs = useReduxState((state: State) => state.jobs);
    const clients = useReduxState((state: State) => state.clients);

    const job = jobs?.get(jobId);
    const clientId = job?.clientId;
    const client = clients?.list?.get(clientId);

    return (
        <DocumentTitle title={`${client?.name} - ${job?.title} Search`}>
            <SearchProvider
                jobId={jobId}
                searchId={searchId}
                resultsType={resultType}
                baseline={baseline}
                project={SearchProject.Titan}
            >
                <div className="job-search-side-by-side">
                    <Search />
                    <SearchResults initialSelectedPersonId={personId} />
                </div>
            </SearchProvider>
        </DocumentTitle>
    );
};
