import { css } from '@emotion/core';
import {
    Button,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    IconButton,
    Theme,
    Tooltip,
    Typography,
    useTheme
} from '@material-ui/core';
import { ExpandMore, Send } from '@material-ui/icons';
import React from 'react';
import DocumentTitle from 'react-document-title';

import { SearchProject, SearchResultsViewType } from 'shared/models/search';

import { useSubscription } from '@apollo/client';
import { Redirect } from 'react-router-dom';
import { deleteSearch, fetchCampaignSearchStats, receiveNewSearch, updateSearch } from '../actions';
import { getSourcer } from '../api';
import EmojiRenderer from '../common/emoji-renderer';
import { logger } from '../common/logger';
import { Spinner } from '../core-ui/spinner';
import { JOB_SEARCHES, JobSearch } from '../graphql/queries/search';
import { useReduxDispatch, useReduxState } from '../hooks/use-redux';
import { useSession } from '../hooks/use-session';
import { Search, Search as SearchType, SearchStatus, SourcerData, State } from '../state';
import { Header } from './header';
import { SourcerAddProspects } from './sourcer-add-sourcer-members';
import { SourcerSearchesStats } from './sourcer-searches-stats';

// tslint:disable: no-magic-numbers
const styles = (_1: Theme) => css`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    gap: 40px;
    overflow: auto;
    padding: 40px 50px;
    background-color: rgb(244, 246, 248);

    .search-strategy-content {
        white-space: pre-wrap;
    }

    .job-searches-section {
        .job-search-title {
            cursor: default;
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 48px;
            max-width: 420px;

            .job-search-title-label {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            .job-search-actions {
                width: 22px;
                opacity: 0;
                flex: 0 0 auto;
                margin-left: 20px;
                transition: opacity 250ms;
            }

            &:hover {
                .job-search-actions {
                    opacity: 1;
                }
            }
        }

        .search-results-count-link,
        .job-search-title-label {
            cursor: pointer;
        }
    }

    .search-actions {
        display: flex;
        flex-direction: row-reverse;
    }
`;
// tslint:enable: no-magic-numbers

export const SourcerSearches: React.FC<{ id: string }> = ({ id }) => {
    const theme = useTheme();
    const { user, userPermissions } = useSession();
    const dispatch = useReduxDispatch();
    const searches = useReduxState((state: State) => state.searches);
    const pendingRequests = useReduxState((state: State) => state.pendingRequests);
    const [sourcer, setSourcer] = React.useState<SourcerData>(null);
    const [hasUser, setHasUser] = React.useState<boolean>(false);
    const [viewingResults, setViewingResults] = React.useState<{
        searchId: string;
        resultsType: SearchResultsViewType;
    }>(null);
    const [addSourcerMembersOpen, setAddSourcerMembersOpen] = React.useState<boolean>(false);
    const [creatingNewSearch, setCreatingNewSearch] = React.useState<boolean>(false);
    const { data: searchDataSub } = useSubscription<
        { searches: JobSearch[] },
        { where: { jobId: { _eq: string }; userId?: { _eq: string } } }
    >(JOB_SEARCHES, {
        variables: { where: { jobId: { _eq: id } } }
    });

    React.useEffect(() => {
        getSourcer(id)
            .then((result) => {
                if (result.success) {
                    setSourcer(result.data.sourcer);
                    setHasUser(result.data.hasUser);
                }
            })
            .catch((err) => {
                logger.error(err, { message: `Error fetching sourcer ${id}` });
            });
        if (!searches || !searches.has(id)) {
            dispatch(fetchCampaignSearchStats(id));
        }
    }, []);

    React.useEffect(() => {
        const reduxStateSearches = searches?.get(id)?.searches;
        if (searchDataSub && reduxStateSearches) {
            // find any searches that are in this sub
            // and are not in the searches object or the status does not match
            const mismatch =
                searchDataSub.searches.some((s) => {
                    const search = reduxStateSearches.find((ss) => ss.id === s.id);
                    return (
                        !search ||
                        search.status !== s.status ||
                        search.searchProfilesScoreId !== s.searchProfilesScoreId
                    );
                }) ||
                reduxStateSearches
                    .filter((s) => s.id)
                    .some((s) => {
                        const search = searchDataSub.searches.find((ss) => ss.id === s.id);
                        return (
                            !search ||
                            search.status !== s.status ||
                            search.searchProfilesScoreId !== s.searchProfilesScoreId
                        );
                    });
            if (mismatch) {
                dispatch(fetchCampaignSearchStats(id));
            }
        }
    }, [searchDataSub, dispatch, searches]);

    const handleViewSearchResults = (vr: { searchId: string; resultsType: SearchResultsViewType }) => {
        setViewingResults(vr);
    };

    const handleCreateNewSearch = () => {
        setCreatingNewSearch(true);
    };

    const handleCloneSearch = (data: SearchType) => {
        const jobSearchData = searches.get(id);
        const jobSearches = jobSearchData ? jobSearchData.searches || [] : [];
        const searchInProgress = jobSearches.find((s) => s.status === SearchStatus.Initial);
        if (searchInProgress) {
            dispatch(deleteSearch(searchInProgress));
        }
        const searchToClone = Object.assign({}, data, {
            createdAt: undefined,
            createdBy: user.id,
            id: undefined,
            jobId: id,
            maxProfileAgeInDays: -1,
            minSearchProfileScore: 0,
            modifiedAt: undefined,
            name: `Copy of ${data.name}`,
            project: SearchProject.HireflowV2,
            searchProfilesScoreId: null,
            sortRank: undefined,
            status: SearchStatus.Initial,
            userId: user.id
        });
        dispatch(receiveNewSearch(searchToClone));
        setCreatingNewSearch(true);
    };

    const handleSend = (e: React.MouseEvent) => {
        e.stopPropagation();
        setAddSourcerMembersOpen(true);
    };

    const handleCloseAddProspect = () => {
        setAddSourcerMembersOpen(false);
    };

    const handleUpdateSearch = (searchId: string, updates: Partial<Search>, refetchSearches: boolean) => {
        dispatch(updateSearch(searchId, updates, refetchSearches));
    };

    const handleDeleteSearch = (search: Search) => {
        dispatch(deleteSearch(search));
    };

    if (viewingResults) {
        return <Redirect to={`/sourcer/${id}/search/${viewingResults.searchId}/${viewingResults.resultsType}`} />;
    }

    if (creatingNewSearch) {
        return <Redirect to={`/sourcer/${id}/search`} />;
    }

    if (!sourcer || !searches || !searches.has(id)) {
        const size = 40;
        return <Spinner size={size} />;
    } else {
        const jobSearchData = searches.get(id);
        const content = (
            <div className="job-searches-section">
                <SourcerSearchesStats
                    searches={jobSearchData.searches}
                    searchStats={jobSearchData.searchStats}
                    user={user}
                    onDisplaySearchInfo={handleViewSearchResults}
                    onViewResults={handleViewSearchResults}
                    onCloneSearch={handleCloneSearch}
                    onUpdateSearch={handleUpdateSearch}
                    onDeleteSearch={handleDeleteSearch}
                    userPermissions={userPermissions}
                />
            </div>
        );

        const addProspectDialog = addSourcerMembersOpen ? (
            <SourcerAddProspects sourcer={sourcer} onClose={handleCloseAddProspect} hasUser={hasUser} />
        ) : null;

        const addSourcerMembersButton =
            jobSearchData.searches.length > 0 ? (
                <Tooltip title="Add Sourcer Members" key="send">
                    <span>
                        <IconButton size="small" onClick={handleSend}>
                            <Send htmlColor="white" fontSize="small" />
                        </IconButton>
                    </span>
                </Tooltip>
            ) : null;

        return (
            <DocumentTitle title="Sourcer Searches">
                <div id="container">
                    <Header title={`${sourcer.title} - ${sourcer.userName}`} actions={[addSourcerMembersButton]} />
                    <div css={styles(theme)}>
                        <div>
                            <ExpansionPanel>
                                <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                                    <Typography variant="h5" component="h5">
                                        AI Sourcing Strategy
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <div className="search-strategy-content">
                                        <EmojiRenderer text={sourcer.aiSourcingStrategy} />
                                    </div>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </div>
                        {content}
                        <div className="search-actions">
                            <div key="add-search">
                                <Button onClick={handleCreateNewSearch} disabled={pendingRequests.has('search-create')}>
                                    Add new search{' '}
                                </Button>
                            </div>
                        </div>
                        {addProspectDialog}
                    </div>
                </div>
            </DocumentTitle>
        );
    }
};
