import { css } from '@emotion/core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Theme,
    Tooltip,
    Typography,
    useTheme
} from '@material-ui/core';
import { ArrowBack, ArrowForward, ChevronLeft, ChevronRight } from '@material-ui/icons';
import { Paper } from 'material-ui';
import React, { useEffect } from 'react';
import DocumentTitle from 'react-document-title';
import { Redirect } from 'react-router-dom';

import { ContactData } from 'shared/models/contact';
import { canAutoMergeProfiles } from 'shared/models/profile';
import { hasRole } from 'shared/models/user';
import { ConflictProfile, ConflictProfileWithDetails } from 'shared/types/conflict-profiles';

import {
    enrichConflictProfile,
    fetchConflictProfiles,
    findConflictProfileByProfileIdsAndDelete,
    mergeProfiles,
    reassignContact as reassignContactData,
    reassignProfileUrl
} from '../api';
import { logger } from '../common/logger';
import { Header } from '../components/header';
import { LoadingModal } from '../core-ui/loading-modal';
import { Spinner } from '../core-ui/spinner';
import { useModal } from '../hooks/use-modal';
import { useSession } from '../hooks/use-session';
import { PersonProfile } from '../sfc/person-profile';

const messageStyles = css`
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const styles = css`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .content {
        flex: 1 1 auto;
        display: flex;
        margin: 0 30px 20px;
        overflow: hidden;

        .merge-profile-content {
            padding: 0 2px;
            overflow: auto;
            margin-right: 10px;
            flex: 1 1;

            &::-webkit-scrollbar-thumb {
                border-radius: 2px;
            }

            .detail-title {
                font-weight: 500;
            }
        }
    }

    .conflict-profile-index {
        margin: 0 10px;
        font-size: 14px;
    }
`;

const dialogStyles = (theme: Theme) => css`
    .data-section {
        display: flex;
        align-items: stretch;

        .profile-left,
        .profile-right {
            width: 50%;
            overflow: hidden;
        }

        .profile-left {
            padding-right: 10px;
            border-right: thin solid ${theme.palette.divider};
        }

        .profile-right {
            padding-left: 10px;
        }
    }

    .transfer {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &.right {
            flex-direction: row;
        }

        &.left {
            flex-direction: row-reverse;
        }

        .MuiIconButton-root {
            margin: 0 5px;
        }
    }

    .data-section.profile-name {
        .transfer {
            font-size: 20px;
            padding: 10px 56px;
            border-bottom: thin solid ${theme.palette.divider};
        }
    }
`;

export const ProfileConflicts: React.FC<{ personId1?: string; personId2?: string }> = (props) => {
    const theme = useTheme();
    const [conflictProfiles, setConflictProfiles] = React.useState<ConflictProfile[]>(null);
    const [currentConflict, setCurrentConflict] = React.useState<ConflictProfileWithDetails>(null);
    const [currentConflictIndex, setCurrentConflictIndex] = React.useState<number>(0);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [error, setError] = React.useState<Error>();
    const [loadingModal, setLoadingModal] = React.useState<boolean>(false);
    const [transferDialogOpen, setTransferDialogOpen] = React.useState(false);
    const [mergeFrom, setMergeFrom] = React.useState<string>(null);
    const [mergeTo, setMergeTo] = React.useState<string>(null);
    const [redirectToIds, setRedirectToIds] = React.useState<string[]>(null);
    const { getConfirmation } = useModal();
    const { userPermissions } = useSession();
    const isSingleConflict = props.personId1 && props.personId2;

    const isProfileConflictAdmin = hasRole(userPermissions, 'fix_profiles_conflict');

    useEffect(() => {
        const { personId1, personId2 } = props;
        if (personId1 && personId2) {
            setLoadingModal(true);
            setLoading(true);
            updateCurrentConflictProfile([personId1, personId2], -1).then(() => {
                setConflictProfiles([{ personId1, personId2, id: undefined, createdAt: undefined }]);
                setCurrentConflictIndex(0);
                setLoading(false);
                setLoadingModal(false);
            });
        } else {
            const fetchData = async () => {
                const result = await fetchConflictProfiles();
                if (result.success) {
                    setConflictProfiles(result.data);
                    const conflict = result.data[0];
                    if (conflict) {
                        const enrichmentResult = await enrichConflictProfile(conflict.personId1, conflict.personId2);
                        if (enrichmentResult.success) {
                            processConflictProfiles(enrichmentResult.data);
                        } else {
                            throw new Error('Error enriching the conflict profile');
                        }
                    }
                } else {
                    throw new Error('Error fetching the conflict profile');
                }
            };
            setLoading(true);
            fetchData()
                .catch((err) => {
                    setError(err);
                    logger.error(err, { message: 'Error fetching conflicted profiles' });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, []);

    const processConflictProfiles = (conflict: ConflictProfileWithDetails) => {
        let left = conflict.personIds[0];
        let right = conflict.personIds[1];
        if (conflict.details[right].candidates.length === 0 && conflict.details[left].candidates.length > 0) {
            left = conflict.personIds[1];
            right = conflict.personIds[0];
        }
        setMergeFrom(left);
        setMergeTo(right);
        setCurrentConflict(conflict);
    };

    const updateCurrentConflictProfile = async (personIds: string[], nextIndex: number) => {
        try {
            const enrichmentResult = await enrichConflictProfile(personIds[0], personIds[1]);
            if (enrichmentResult.success) {
                processConflictProfiles(enrichmentResult.data);
                setCurrentConflictIndex(nextIndex);
            } else {
                throw new Error('Error enriching the conflict profile');
            }
        } catch (error) {
            setError(error);
            logger.error(error);
        }
    };

    const refreshConflictProfiles = async () => {
        setLoadingModal(true);
        try {
            const result = await fetchConflictProfiles();
            if (result.success) {
                // set so we are at the "next" conflict from the "previous" one we had before the fix/skip
                // ensure the navigation stays along where it was supposed to be before the fix/skip
                const data: ConflictProfile[] = result.data;
                let nextIndex = 0;
                if (currentConflictIndex > 0) {
                    const previous = conflictProfiles[currentConflictIndex - 1];
                    const prevIndex = data.findIndex(
                        (c) => c.personId1 === previous.personId1 && c.personId2 === previous.personId2
                    );
                    nextIndex = prevIndex + 1;
                }
                const conflict = data[nextIndex];
                if (!conflict) {
                    setConflictProfiles([]);
                    setCurrentConflict(null);
                } else {
                    const personIds = [conflict.personId1, conflict.personId2];
                    setConflictProfiles(data);
                    await updateCurrentConflictProfile(personIds, nextIndex);
                }
            } else {
                throw new Error('Error fetching the conflict profile');
            }
        } catch (error) {
            setError(error);
            logger.error(error);
        }
        setLoadingModal(false);
    };

    const getNextConflictProfile = async () => {
        const nextIndex = currentConflictIndex + 1;
        const conflict = conflictProfiles[nextIndex];
        const personIds = [conflict.personId1, conflict.personId2];
        setLoadingModal(true);
        await updateCurrentConflictProfile(personIds, nextIndex);
        setLoadingModal(false);
    };

    const getPreviousConflictProfile = async () => {
        const previousIndex = currentConflictIndex - 1;
        const conflict = conflictProfiles[previousIndex];
        const personIds = [conflict.personId1, conflict.personId2];
        setLoadingModal(true);
        await updateCurrentConflictProfile(personIds, previousIndex);
        setLoadingModal(false);
    };

    const reassignUrl = (url: string, toPersonId: string) => () => {
        getConfirmation(
            () => reassignUrlConfirmed(url, toPersonId),
            'The profile url will be transferred to the other profile',
            'Please confirm'
        );
    };

    const reassignUrlConfirmed = (url: string, toPersonId: string) => {
        setLoadingModal(true);
        reassignProfileUrl(url, toPersonId)
            .then((result) => {
                if (result.success) {
                    const { personIds, details } = currentConflict;
                    const left = personIds[0];
                    const right = personIds[1];
                    const from = toPersonId === left ? right : left;
                    const target = details[from].profileUrls.find((u) => u.url === url);
                    details[from].profileUrls = details[from].profileUrls.filter((u) => u.url !== url);
                    details[toPersonId].profileUrls.push(target);
                }
            })
            .catch((err: Error) => {
                setError(err);
                logger.error(err, { message: 'Error reassign profile urls' });
            })
            .finally(() => {
                setLoadingModal(false);
            });
    };

    const reassignContact = (contact: ContactData, toPersonId: string) => () => {
        const associatedRecordsText =
            contact.channel === 'email'
                ? 'email messages and notes for related jobs'
                : 'phone calls, text messages and notes for related jobs';
        getConfirmation(
            () => reassignContactConfirmed(contact, toPersonId),
            `The ${contact.channel} will be transferred to the other profile, ` +
                `along with associated ${associatedRecordsText}`,
            'Please confirm'
        );
    };

    const reassignContactConfirmed = (contact: ContactData, toPersonId: string) => {
        setLoadingModal(true);
        reassignContactData(contact, toPersonId)
            .then((result) => {
                if (result.success) {
                    const { personIds, details } = currentConflict;
                    const left = personIds[0];
                    const right = personIds[1];
                    const from = toPersonId === left ? right : left;
                    details[from].contacts = details[from].contacts.filter((u) => u.value !== contact.value);
                    details[toPersonId].contacts.push(contact);
                }
            })
            .catch((err: Error) => {
                setError(err);
                logger.error(err, { message: 'Error reassigning contact' });
            })
            .finally(() => {
                setLoadingModal(false);
            });
    };

    const handleOpenTransferDataDialog = () => setTransferDialogOpen(true);
    const handleCloseTransferDialog = () => setTransferDialogOpen(false);

    const resolveConflictProfile = async () => {
        setLoadingModal(true);
        const { personIds } = currentConflict;
        try {
            handleCloseTransferDialog();
            const result = await findConflictProfileByProfileIdsAndDelete(personIds[0], personIds[1]);
            if (isSingleConflict) {
                setRedirectToIds([personIds[0], personIds[1]]);
            } else if (result.success) {
                await refreshConflictProfiles();
                setLoadingModal(false);
            }
        } catch (err) {
            setError(err);
            logger.error('Error reprocessing profiles', err);
        }
    };

    const mergeProfileData = async () => {
        getConfirmation(
            async () => {
                setLoadingModal(true);
                try {
                    const result = await mergeProfiles(mergeFrom, mergeTo);
                    const mergedId: string = result.data.mergedId;
                    if (isSingleConflict) {
                        setRedirectToIds([mergedId]);
                    } else {
                        await refreshConflictProfiles();
                        setLoadingModal(false);
                    }
                } catch (err) {
                    setError(err);
                    logger.error(err, { message: 'Error merging profiles' });
                }
            },
            'These profiles will be merged in to a single profile. This CANNOT be reversed',
            'Are you sure to merge these profiles?'
        );
    };

    const generateProfileUrls = (urls: string[], toPersonId: string, direction: 'left' | 'right') => {
        const icon = direction === 'left' ? <ArrowBack /> : <ArrowForward />;
        const urlElements = urls.map((u) => {
            return (
                <div className={`transfer ${direction}`} key={u}>
                    <a href={`https://${u}`} target="_blank">
                        {u}
                    </a>
                    <Tooltip title="Reassign url to the other profile">
                        <IconButton onClick={reassignUrl(u, toPersonId)}>{icon}</IconButton>
                    </Tooltip>
                </div>
            );
        });
        return urls && urls.length > 0 ? urlElements : undefined;
    };

    const generateContacts = (contacts: ContactData[], toPersonId: string, direction: 'left' | 'right') => {
        const icon = direction === 'left' ? <ArrowBack /> : <ArrowForward />;
        const contactElements = contacts.map((u) => {
            return (
                <div className={`transfer ${direction}`} key={u.value}>
                    {u.value}
                    <Tooltip title="Reassign contact to the other profile">
                        <IconButton onClick={reassignContact(u, toPersonId)}>{icon}</IconButton>
                    </Tooltip>
                </div>
            );
        });
        return contacts && contacts.length > 0 ? contactElements : undefined;
    };

    let content;
    let dialog;

    if (loading) {
        content = <Spinner />;
    } else if (error) {
        content = <div css={messageStyles}>An error has occurred.</div>;
    } else if (currentConflict) {
        const { personIds, details } = currentConflict;
        const left = personIds[0];
        const right = personIds[1];
        const leftProfile = details[left].profile;
        const rightProfile = details[right].profile;
        const leftProfileUrls = details[left].profileUrls.map((u) => u.url);
        const leftProfileUrlsSection = generateProfileUrls(leftProfileUrls, right, 'right');
        const leftContacts = details[left].contacts;
        const leftContactsSection = generateContacts(leftContacts, right, 'right');
        const rightProfileUrls = details[right].profileUrls.map((u) => u.url);
        const rightProfileUrlsSection = generateProfileUrls(rightProfileUrls, left, 'left');
        const rightContacts = details[right].contacts;
        const rightContactsSection = generateContacts(rightContacts, left, 'left');

        const navButtons = isSingleConflict ? null : (
            <>
                <Tooltip title="Previous">
                    <span>
                        <IconButton disabled={currentConflictIndex <= 0} onClick={getPreviousConflictProfile}>
                            <ChevronLeft />
                        </IconButton>
                    </span>
                </Tooltip>
                <span className="conflict-profile-index">
                    {currentConflictIndex + 1} / {conflictProfiles.length}
                </span>
                <Tooltip title="Next">
                    <span>
                        <IconButton
                            disabled={currentConflictIndex === conflictProfiles.length - 1}
                            onClick={getNextConflictProfile}
                        >
                            <ChevronRight />
                        </IconButton>
                    </span>
                </Tooltip>
            </>
        );

        const autoMergeOK = canAutoMergeProfiles(
            {
                candidatesCount: details[left].candidates.length,
                person: details[left].person,
                profile: leftProfile?.content
            },
            {
                candidatesCount: details[right].candidates.length,
                person: details[right].person,
                profile: rightProfile?.content
            }
        );

        const profileNotFound = <div>Profile content not found</div>;

        const leftProfileComponent = leftProfile ? (
            <PersonProfile personId={left} profile={leftProfile?.content} viewedAt={leftProfile?.viewedAt} />
        ) : (
            profileNotFound
        );
        const rightProfileComponent = rightProfile ? (
            <PersonProfile personId={right} profile={rightProfile?.content} viewedAt={rightProfile?.viewedAt} />
        ) : (
            profileNotFound
        );

        content = (
            <div id="content" className="flex-fill">
                <div css={styles}>
                    <div className="fix-profile-conflicts-button-container">
                        <div className="fix-profile-conflicts-next-previous-buttons">{navButtons}</div>
                        <div className="fix-profile-conflicts-actions">
                            <Button
                                disableElevation={true}
                                variant="contained"
                                onClick={handleOpenTransferDataDialog}
                                disabled={!isProfileConflictAdmin}
                            >
                                Transfer Data
                            </Button>
                            <Button
                                disableElevation={true}
                                variant="contained"
                                onClick={mergeProfileData}
                                disabled={!isProfileConflictAdmin && !autoMergeOK}
                            >
                                Merge Profiles
                            </Button>
                        </div>
                    </div>

                    <div className="content">
                        {loadingModal ? <LoadingModal /> : null}
                        <div className="merge-profile-content">
                            <Paper className="detailed-profile-card">
                                <div className="card-content">
                                    <div className="detail-title">
                                        <a href={`/person/${details[left].person?.id}`} target="_blank">
                                            {details[left].person.name.full}
                                        </a>
                                    </div>
                                    <div>{details[left].candidates.length} Jobs</div>
                                </div>
                            </Paper>
                            {leftProfileComponent}
                        </div>
                        <div className="merge-profile-content">
                            <Paper className="detailed-profile-card">
                                <div className="card-content">
                                    <div className="detail-title">
                                        <a target="_blank" href={`/person/${details[right].person.id}`}>
                                            {details[right].person.name.full}
                                        </a>
                                    </div>
                                    <div>{details[right].candidates.length} Jobs</div>
                                </div>
                            </Paper>
                            {rightProfileComponent}
                        </div>
                    </div>
                </div>
            </div>
        );

        dialog = (
            <Dialog
                open={transferDialogOpen}
                onClose={handleCloseTransferDialog}
                fullWidth={true}
                maxWidth="md"
                css={dialogStyles(theme)}
            >
                <DialogTitle>
                    <Typography variant="h5" component="span">
                        Transfer Profile data
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <div className="data-section profile-name">
                        <div className="profile-left">
                            <div className="transfer right">{leftProfile?.content.fullName}</div>
                        </div>
                        <div className="profile-right">
                            <div className="transfer left">{rightProfile?.content.fullName}</div>
                        </div>
                    </div>
                    <div className="data-section">
                        <div className="profile-left">{leftProfileUrlsSection}</div>
                        <div className="profile-right">{rightProfileUrlsSection}</div>
                    </div>
                    <div className="data-section">
                        <div className="profile-left">{leftContactsSection}</div>
                        <div className="profile-right">{rightContactsSection}</div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseTransferDialog}>Cancel</Button>
                    <Button onClick={resolveConflictProfile}>Done</Button>
                </DialogActions>
            </Dialog>
        );
    } else {
        content = <div css={messageStyles}>No profiles found</div>;
    }
    if (redirectToIds?.length > 0) {
        if (redirectToIds.length > 1) {
            window.open(`/person/${redirectToIds[1]}`, '_blank');
        }
        return <Redirect to={`/person/${redirectToIds[0]}`} />;
    }
    return (
        <DocumentTitle title="Profile Conflicts">
            <div id="container">
                <Header title="Profile Conflicts" actions={[]} />
                {content}
                {dialog}
            </div>
        </DocumentTitle>
    );
};
