// cspell:ignore vscroll
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';

import { Header } from '../../components/header';
import { Loading } from '../../core-ui/loading';
import { resetComposeWindows } from '../../email-compose/actions';
import { State } from '../../state';
import { fetchAccountData, fetchEmailAccountLabelThreads } from '../actions';
import { getLabelDisplay } from '../common';
import { EmailInboxData, threadsPageSize } from '../types';
import { EmailAccountSelector } from './email-account-selector';
import { EmailInboxActions } from './email-inbox-actions';
import { EmailInboxLabels } from './email-inbox-labels';
import { EmailInboxPagination } from './email-inbox-pagination';
import { EmailInboxThreadDetails } from './email-inbox-thread-details';
import { EmailInboxThreadPreview } from './email-inbox-thread-preview';

interface OwnProps {
    account: string;
}

interface ConnectedProps {
    emailInbox: EmailInboxData;
}

interface ConnectedDispatch {
    resetComposeWindows: () => void;
    fetchAccountData: (account: string) => void;
    fetchEmailAccountLabelThreads: (label: string) => void;
}

type EmailInboxProps = OwnProps & ConnectedProps & ConnectedDispatch;

class EmailInboxContainer extends React.Component<EmailInboxProps, undefined> {
    constructor(props: EmailInboxProps) {
        super(props);
        this.ensureDataExists(props);
        this.props.resetComposeWindows();
    }

    ensureDataExists(props: EmailInboxProps) {
        const { account, emailInbox } = props;
        if (!emailInbox || emailInbox.account !== account) {
            props.fetchAccountData(account);
        }
    }

    componentDidUpdate(prevProps: EmailInboxProps) {
        if (prevProps.account !== this.props.account) {
            this.ensureDataExists(this.props);
            this.props.resetComposeWindows();
        }
    }

    componentWillUnmount() {
        this.props.resetComposeWindows();
    }

    render() {
        const data = this.props.emailInbox;
        const mainPanel =
            !data || !data.initialized || !data.threads || (data.threads.length === 0 && data.fetching) ? (
                <Loading />
            ) : data.viewingThread ? (
                <EmailInboxThreadDetails />
            ) : (
                data.threads
                    .slice(0, threadsPageSize)
                    .map((thread) => <EmailInboxThreadPreview thread={thread} key={thread.id} />)
            );
        const emptyFolder =
            data && data.initialized && !data.fetching && data.threads.length === 0 ? (
                <div className="email-inbox-empty-folder">No messages!</div>
            ) : null;
        const folder =
            data && data.labels && data.selectedLabel
                ? getLabelDisplay(data.labels.find((l) => l.id === data.selectedLabel))
                : 'Inbox';
        const accountSelector = (
            <EmailAccountSelector key="account-selector" pagePath="emails" selected={this.props.account} />
        );
        return (
            <DocumentTitle title={`${folder} - ${this.props.account}`}>
                <div id="container">
                    <Header title={folder} actions={[accountSelector]} />
                    <div id="content" className="flex-fill">
                        <div className="email-inbox">
                            <div className="email-inbox-left">
                                <EmailInboxLabels
                                    data={data}
                                    onLabelSelect={this.props.fetchEmailAccountLabelThreads}
                                />
                            </div>
                            <div className="email-inbox-center">
                                <div className="email-inbox-header">
                                    <EmailInboxPagination />
                                    <EmailInboxActions />
                                </div>
                                <div className="email-inbox-threads email-inbox-vscroll">
                                    {emptyFolder}
                                    {mainPanel}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    emailInbox: state.emailInbox
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    fetchAccountData,
    fetchEmailAccountLabelThreads,
    resetComposeWindows
};

export const EmailInbox = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(EmailInboxContainer);
