import { Slider } from '@material-ui/core';
import React from 'react';

export const SliderForm: React.FC<{
    readonly: boolean;
    endpoints: number[];
    fieldName: string;
    value: [number, number];
    onChange: (val: [number, number]) => void;
    getLabel?: (val: number) => string;
    modifyRange?: (val: [number, number]) => [number, number];
}> = ({ readonly, endpoints, fieldName, value, onChange, getLabel, modifyRange }) => {
    const getNormalizedValue = (val: number): number => {
        const index = endpoints.indexOf(val);
        return (index / (endpoints.length - 1)) * 100;
    };

    const getActualValue = (val: number): number => {
        const index = Math.round((val * (endpoints.length - 1)) / 100);
        return endpoints[index];
    };

    const marks = endpoints.map((endpoint) => {
        return {
            label: getLabel?.(endpoint) ?? endpoint.toString(),
            value: getNormalizedValue(endpoint)
        };
    });

    const normalizedValue = value ? [getNormalizedValue(value[0]), getNormalizedValue(value[1])] : [0, 100];

    const handleChange = (_1: any, newValue: number | number[]) => {
        const snappedValues = (newValue as number[]).map(getActualValue) as [number, number];
        if (snappedValues[0] === endpoints[0] && snappedValues[1] === endpoints[endpoints.length - 1]) {
            onChange(undefined);
        } else if (snappedValues[0] === snappedValues[1]) {
            let lowIndex = endpoints.indexOf(snappedValues[0]);
            const highIndex = Math.min(lowIndex + 1, endpoints.length - 1);
            if (highIndex === endpoints.length - 1) {
                lowIndex = Math.max(highIndex - 1, 0);
            }
            onChange([endpoints[lowIndex], endpoints[highIndex]]);
        } else {
            onChange(snappedValues);
        }
    };

    const range = [getActualValue(normalizedValue[0]), getActualValue(normalizedValue[1])] as [number, number];
    const modifiedRange = modifyRange ? modifyRange(range) : range;

    return (
        <div className="filter-field">
            <div className="filter-label">
                <div className="description">{fieldName}</div>
                <div className="values">
                    {getLabel?.(modifiedRange[0]) ?? modifiedRange[0]} -{' '}
                    {getLabel?.(modifiedRange[1]) ?? modifiedRange[1]}
                </div>
            </div>
            <div className="filter-form">
                <Slider
                    disabled={readonly}
                    value={normalizedValue}
                    onChange={handleChange}
                    valueLabelDisplay="off"
                    marks={marks}
                    min={0}
                    max={100}
                    className="filter-slider"
                />
            </div>
        </div>
    );
};
