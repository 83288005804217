import { gql } from '@apollo/client';

export interface CandidatesSearchVariables {
    userId: string;
    tags: string[];
    favoritesOnly: boolean;
    blacklistedOnly: boolean;
    visas: string[];
    earliestTs: number;
    stageProgress: string;
    assignees: string[];
    locations: string[];
    disciplines: string[];
    csDegreeOnly: boolean;
    schoolRank: string;
    currentCompany: string[];
    specificJobs: string[];
    diversityGender: string;
    diversityVeteran: boolean;
    diversityURM: boolean;
    diversityLGBTQ: boolean;
    keywords: string[];
    minYearsExp: string;
    profileGroup: string[];
    excludedClients: string[];
}

export interface PersonRecord {
    person: {
        id: string;
        name: string;
        recentAssignees: Array<{
            user: {
                id: string;
                name: string;
            };
        }>;
    };
    lastClientRoundDate: string;
    latestClientRoundJob: {
        id: string;
        title: string;
        client: {
            id: string;
            name: string;
        };
    };
    lastPhoneScreenDate: string;
    latestPhoneScreenJob: {
        id: string;
        title: string;
        client: {
            id: string;
            name: string;
        };
    };
    currentLocation: string;
    totalExperience: string;
    currentCompany: string;
    currentTitle: string;
}

export const CANDIDATES_SEARCH = gql`
    query CandidatesSearch(
        $userId: String
        $tags: jsonb
        $favoritesOnly: Boolean
        $blacklistedOnly: Boolean
        $visas: jsonb
        $earliestTs: bigint
        $stageProgress: String
        $assignees: jsonb
        $locations: jsonb
        $disciplines: jsonb
        $csDegreeOnly: Boolean
        $schoolRank: String
        $currentCompany: jsonb
        $specificJobs: jsonb
        $diversity: jsonb
        $diversityGender: String
        $diversityVeteran: Boolean
        $diversityURM: Boolean
        $diversityLGBTQ: Boolean
        $keywords: jsonb
        $minYearsExp: String
        $profileGroup: jsonb
        $excludedClients: jsonb
    ) {
        persons: candidates_search(
            args: {
                userId: $userId
                tags: $tags
                favoritesOnly: $favoritesOnly
                blacklistedOnly: $blacklistedOnly
                visas: $visas
                earliestTs: $earliestTs
                stageProgress: $stageProgress
                assignees: $assignees
                locations: $locations
                disciplines: $disciplines
                csDegreeOnly: $csDegreeOnly
                schoolRank: $schoolRank
                currentCompany: $currentCompany
                specificJobs: $specificJobs
                diversityGender: $diversityGender
                diversityVeteran: $diversityVeteran
                diversityURM: $diversityURM
                diversityLGBTQ: $diversityLGBTQ
                keywords: $keywords
                minYearsExp: $minYearsExp
                profileGroup: $profileGroup
                excludedClients: $excludedClients
            }
        ) {
            person {
                id
                name(path: "full")
                recentAssignees {
                    user {
                        id
                        name(path: "full")
                    }
                }
            }
            lastClientRoundDate
            latestClientRoundJob {
                id
                title
                client {
                    id
                    name
                }
            }
            lastPhoneScreenDate
            latestPhoneScreenJob {
                id
                title
                client {
                    id
                    name
                }
            }
            currentLocation
            totalExperience
            currentCompany
            currentTitle
        }
    }
`;
