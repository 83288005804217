import { saveAs } from 'file-saver';
import { Dispatch } from 'redux';

import { State } from '../state';
import * as api from './api';
import { EmailAccountLabel, EmailThread, searchResultLabel, threadsPageSize } from './types';

export const RequestAccountData = 'RequestAccountData';
export const ReceiveAccountData = 'ReceiveAccountData';
export const RequestAccountsList = 'RequestAccountsList';
export const ReceiveAccountsList = 'ReceiveAccountsList';
export const SetVisibleThreads = 'SetVisibleThreads';
export const ReceiveThreadsPage = 'ReceiveThreadsPage';
export const ReceiveThreadsUpdate = 'ReceiveThreadsUpdate';
export const SelectEmailAccountLabel = 'SelectEmailAccountLabel';
export const ReceiveEmailAccountLabelData = 'ReceiveEmailAccountLabelData';
export const SelectAllThreads = 'SelectAllThreads';
export const SelectThread = 'SelectThread';
export const RequestDeleteThreads = 'RequestDeleteThreads';
export const RequestUntrashThreads = 'RequestUntrashThreads';
export const RequestDiscardDrafts = 'RequestDiscardDrafts';
export const RequestUpdateThreadLabels = 'RequestUpdateThreadLabels';
export const RequestInboxSearch = 'RequestInboxSearch';
export const ReceiveInboxSearchResults = 'ReceiveInboxSearchResults';
export const SetSearchString = 'SetSearchString';
export const ViewThreadDetails = 'ViewThreadDetails';
export const MarkMessagesUnread = 'MarkMessagesUnread';
export const RequestDeleteMessage = 'RequestDeleteMessage';
export const RequestAttachmentDownload = 'RequestAttachmentDownload';
export const ReceiveAttachmentDownload = 'ReceiveAttachmentDownload';

interface EmailAccountDataResponse {
    account: string;
    labels: EmailAccountLabel[];
    threads: EmailThread[];
    selectedLabel: string;
}

export type EmailAction =
    | {
          type: 'RequestAccountData';
          payload: {
              account: string;
          };
      }
    | {
          type: 'ReceiveAccountData';
          payload: EmailAccountDataResponse;
      }
    | {
          type: 'RequestAccountsList';
      }
    | {
          type: 'ReceiveAccountsList';
          payload: Array<{ name: string; email: string }>;
      }
    | {
          type: 'ReceiveThreadsPage';
          payload: {
              threads: EmailThread[];
              labels: EmailAccountLabel[];
          };
      }
    | {
          type: 'SetVisibleThreads';
          payload: { direction: 'forward' | 'backward' };
      }
    | {
          type: 'SelectEmailAccountLabel';
          payload: { label: string };
      }
    | {
          type: 'ReceiveEmailAccountLabelData';
          payload: {
              threads: EmailThread[];
              labels: EmailAccountLabel[];
          };
      }
    | {
          type: 'SelectAllThreads';
          payload: { checked: boolean };
      }
    | {
          type: 'SelectThread';
          payload: {
              checked: boolean;
              threadId: string;
          };
      }
    | {
          type: 'RequestUpdateThreadLabels';
          payload: {
              threadIds: string[];
              labelsRemoved: string[];
              labelsAdded: string[];
          };
      }
    | {
          type: 'RequestDeleteThreads';
          payload: {
              threadIds: string[];
          };
      }
    | {
          type: 'RequestUntrashThreads';
          payload: {
              threadIds: string[];
          };
      }
    | {
          type: 'RequestDiscardDrafts';
          payload: {
              threadIds: string[];
          };
      }
    | {
          type: 'ReceiveThreadsUpdate';
          payload: {
              threads: EmailThread[];
              labels: EmailAccountLabel[];
          };
      }
    | {
          type: 'RequestInboxSearch';
      }
    | {
          type: 'ReceiveInboxSearchResults';
          payload: {
              threads: EmailThread[];
              labelData: Partial<EmailAccountLabel>;
          };
      }
    | {
          type: 'SetSearchString';
          payload: { searchString: string };
      }
    | {
          type: 'ViewThreadDetails';
          payload: { threadId: string };
      }
    | {
          type: 'MarkMessagesUnread';
          payload: { messageId: string; threadId: string };
      }
    | {
          type: 'RequestDeleteMessage';
          payload: { messageId: string; threadId: string };
      }
    | {
          type: 'RequestAttachmentDownload';
          payload: {
              messageId: string;
              attachmentId: string;
          };
      }
    | {
          type: 'ReceiveAttachmentDownload';
          payload: {
              messageId: string;
              attachmentId: string;
          };
      };

function requestAccountsList(): EmailAction {
    return { type: RequestAccountsList };
}

function receiveAccountsList(payload: Array<{ name: string; email: string }>): EmailAction {
    return { payload, type: ReceiveAccountsList };
}

export function fetchAccounts() {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.get('fetch-email-inbox-accounts')) {
            dispatch(requestAccountsList());
            return api.fetchAccountsList().then((result) => {
                dispatch(receiveAccountsList(result.data));
            });
        }
    };
}

function requestAccountData(account: string): EmailAction {
    return { payload: { account }, type: RequestAccountData };
}

function receiveAccountData(payload: EmailAccountDataResponse): EmailAction {
    return { payload, type: ReceiveAccountData };
}

export function fetchAccountData(account: string) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        const data = getState().emailInbox;
        if (!data || !data.fetching || data.account !== account) {
            dispatch(requestAccountData(account));
            return api.fetchAccountData(account).then((result) => {
                if (result.success) {
                    dispatch(receiveAccountData(result.data));
                }
            });
        }
    };
}

function setVisibleThreads(payload: { direction: 'forward' | 'backward' }): EmailAction {
    return { payload, type: SetVisibleThreads };
}

function receiveThreadsPage(payload: { threads: EmailThread[]; labels: EmailAccountLabel[] }): EmailAction {
    return { type: ReceiveThreadsPage, payload };
}

export function navigateThreadsPage(direction: 'forward' | 'backward') {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        const data = getState().emailInbox;
        const { account, selectedLabel, fetching, threadsStartIndex, threads } = data;
        if (!fetching) {
            dispatch(setVisibleThreads({ direction }));
            const label = selectedLabel === 'ALL' || selectedLabel === searchResultLabel ? '' : selectedLabel;
            const offset =
                direction === 'forward'
                    ? threadsStartIndex + threads.length - 1
                    : threadsStartIndex - threadsPageSize - 1;
            const numPages = direction === 'forward' ? 1 : 2; // tslint:disable-line:no-magic-numbers
            return api
                .fetchThreads(account, label, offset, threadsPageSize * numPages, data.searchString)
                .then((result) => {
                    dispatch(receiveThreadsPage(result.data));
                });
        }
    };
}

export function selectAllThreads(checked: boolean): EmailAction {
    return {
        payload: { checked },
        type: SelectAllThreads
    };
}

export function selectThread(threadId: string, checked: boolean): EmailAction {
    return { payload: { checked, threadId }, type: SelectThread };
}

function selectEmailAccountLabel(label: string): EmailAction {
    return {
        payload: { label },
        type: SelectEmailAccountLabel
    };
}

function receiveEmailAccountLabelData(payload: { threads: EmailThread[]; labels: EmailAccountLabel[] }): EmailAction {
    return { type: ReceiveEmailAccountLabelData, payload };
}

export function fetchEmailAccountLabelThreads(selectedLabel: string) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        const data = getState().emailInbox;
        const { account, fetching } = data;
        dispatch(selectEmailAccountLabel(selectedLabel));
        if (!fetching) {
            const label = selectedLabel === 'ALL' ? '' : selectedLabel;
            const pagesToFetch = 2;
            return api.fetchThreads(account, label, 0, threadsPageSize * pagesToFetch).then((result) => {
                dispatch(receiveEmailAccountLabelData(result.data));
            });
        }
    };
}

function receiveThreadsUpdate(payload: { threads: EmailThread[]; labels: EmailAccountLabel[] }): EmailAction {
    return {
        payload,
        type: ReceiveThreadsUpdate
    };
}

function requestDeleteThreads(payload: { threadIds: string[] }): EmailAction {
    return {
        payload,
        type: RequestDeleteThreads
    };
}

export function deleteThreads(threadIds: string[]) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        const data = getState().emailInbox;
        const { account, fetching } = data;
        if (!fetching) {
            dispatch(requestDeleteThreads({ threadIds }));
            return api.deleteThreads(account, threadIds); // no result dispatched
        }
    };
}

function requestUntrashThreads(payload: { threadIds: string[] }): EmailAction {
    return {
        payload,
        type: RequestUntrashThreads
    };
}

export function untrashThreads(threadIds: string[]) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        const data = getState().emailInbox;
        const { account, fetching } = data;
        if (!fetching) {
            dispatch(requestUntrashThreads({ threadIds }));
            return api.untrashThreads(account, threadIds); // no result dispatched
        }
    };
}

function requestDiscardDrafts(payload: { threadIds: string[] }): EmailAction {
    return {
        payload,
        type: RequestDiscardDrafts
    };
}

export function discardDrafts(threadIds: string[]) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        const data = getState().emailInbox;
        const { account, fetching } = data;
        if (!fetching) {
            dispatch(requestDiscardDrafts({ threadIds }));
            const threads = data.threads.filter((t) => threadIds.indexOf(t.id) !== -1);
            const messageIds = [];
            for (const thread of threads) {
                for (const message of thread.messages) {
                    if (message.labels.indexOf('DRAFT') !== -1) {
                        messageIds.push(message.messageId);
                    }
                }
            }
            return api.discardDraftsByMessageIds(account, messageIds); // no result dispatched
        }
    };
}

function requestUpdateThreadLabels(payload: {
    threadIds: string[];
    labelsRemoved: string[];
    labelsAdded: string[];
}): EmailAction {
    return {
        payload,
        type: RequestUpdateThreadLabels
    };
}

export function updateThreadLabels(threadIds: string[], labelsAdded: string[], labelsRemoved: string[]) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        const data = getState().emailInbox;
        const { account, fetching } = data;
        if (!fetching) {
            dispatch(requestUpdateThreadLabels({ threadIds, labelsAdded, labelsRemoved }));
            return api.updateThreadLabels(account, threadIds, labelsAdded, labelsRemoved).then((result) => {
                dispatch(receiveThreadsUpdate(result.data));
            });
        }
    };
}

export function setSearchString(searchString: string): EmailAction {
    return {
        payload: { searchString },
        type: SetSearchString
    };
}

function requestInboxSearch(): EmailAction {
    return {
        type: RequestInboxSearch
    };
}

function receiveInboxSearchResults(payload: {
    threads: EmailThread[];
    labelData: Partial<EmailAccountLabel>;
}): EmailAction {
    return {
        payload,
        type: ReceiveInboxSearchResults
    };
}

export function searchInbox() {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        const data = getState().emailInbox;
        const { account, fetching } = data;
        if (!fetching) {
            dispatch(requestInboxSearch());
            return api.searchInbox(account, data.searchString).then((result) => {
                dispatch(receiveInboxSearchResults(result.data));
            });
        }
    };
}

function viewThreadDetailsAction(threadId: string): EmailAction {
    return {
        payload: { threadId },
        type: ViewThreadDetails
    };
}

export function viewThreadDetails(threadId: string) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        dispatch(viewThreadDetailsAction(threadId));
        if (threadId) {
            const data = getState().emailInbox;
            const thread = data.threads.find((t) => t.id === threadId);
            const hasUnread = thread.messages.findIndex((m) => m.labels.indexOf('UNREAD') !== -1) !== -1;
            if (hasUnread) {
                dispatch(updateThreadLabels([threadId], [], ['UNREAD']));
            }
        }
    };
}

export function navigateToThread(direction: 'forward' | 'backward') {
    return (dispatch: Dispatch<State>, getState: () => State): Promise<void> => {
        const data = getState().emailInbox;
        const currentIndex = data.threads.findIndex((t) => t.id === data.viewingThread) + data.threadsStartIndex;
        const newIndex = direction === 'forward' ? currentIndex + 1 : currentIndex - 1;
        const startIndex = data.threadsStartIndex;
        const endIndex = Math.min(data.threads.length, threadsPageSize) + startIndex - 1;
        if (newIndex >= startIndex && newIndex <= endIndex) {
            const newThreadId = data.threads[newIndex - data.threadsStartIndex].id;
            dispatch(viewThreadDetails(newThreadId));
        } else {
            // first get threads page, then navigate in thread direction
            return navigateThreadsPage(direction)(dispatch, getState).then(() => dispatch(navigateToThread(direction)));
        }
    };
}

function requestMarkMessagesUnread(payload: { messageId: string; threadId: string }): EmailAction {
    return {
        payload,
        type: MarkMessagesUnread
    };
}

export function markMessagesUnread(messageId: string, threadId: string) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        const emailInbox = getState().emailInbox;
        const threads = emailInbox.threads;
        const thread = threads.find((t) => t.id === threadId);
        const messageIds: string[] = [];
        let found = false;
        for (const message of thread.messages) {
            if (message.messageId === messageId) {
                found = true;
            }
            if (found) {
                messageIds.push(message.messageId);
            }
        }
        if (messageIds.length > 0) {
            dispatch(requestMarkMessagesUnread({ messageId, threadId }));
            return api.markMessagesUnread(emailInbox.account, messageIds);
        }
    };
}

function requestDeleteMessage(payload: { messageId: string; threadId: string }): EmailAction {
    return {
        payload,
        type: RequestDeleteMessage
    };
}

export function deleteMessage(messageId: string, threadId: string) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        dispatch(requestDeleteMessage({ messageId, threadId }));
        return api.deleteMessage(getState().emailInbox.account, messageId);
    };
}

function requestAttachmentDownload(messageId: string, attachmentId: string): EmailAction {
    return {
        payload: { messageId, attachmentId },
        type: RequestAttachmentDownload
    };
}

function receiveAttachmentDownload(messageId: string, attachmentId: string): EmailAction {
    return {
        payload: { messageId, attachmentId },
        type: ReceiveAttachmentDownload
    };
}

export function downloadAttachment(messageId: string, attachmentId: string, filename: string) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().emailInbox.downloadingAttachment) {
            dispatch(requestAttachmentDownload(messageId, attachmentId));
            return api
                .downloadAttachment(getState().emailInbox.account, messageId, attachmentId)
                .then((response) => {
                    if (response.ok) {
                        return response.blob();
                    } else {
                        dispatch(receiveAttachmentDownload(messageId, attachmentId));
                        throw new Error(`error fetching attachment ${attachmentId}`);
                    }
                })
                .then((blob) => {
                    saveAs(blob, filename);
                    dispatch(receiveAttachmentDownload(messageId, attachmentId));
                });
        }
    };
}
