import { css } from '@emotion/core';
import { Theme, useTheme } from '@material-ui/core';
import React from 'react';
import DocumentTitle from 'react-document-title';

import { SearchProject, SearchResultsViewType } from 'shared/models/search';

import { getSourcer } from '../api';
import { logger } from '../common/logger';
import { Loading } from '../core-ui/loading';
import { Search } from '../search/search';
import { SearchResults } from '../search/search-results';
import { SearchProvider } from '../search/use-search';
import { SourcerData } from '../state';
import { Header } from './header';

// tslint:disable: no-magic-numbers
const styles = (_1: Theme) => css`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: rgb(244, 246, 248);

    .job-search-detail {
        display: flex;
        flex: 1 1 auto;
        overflow: hidden;
    }
`;
// tslint:enable: no-magic-numbers

interface SourcerSearchesProps {
    sourcerId: string;
    searchId?: string;
    resultsType?: SearchResultsViewType;
}

export const SourcerSearchDetail: React.FC<SourcerSearchesProps> = ({ sourcerId, searchId, resultsType }) => {
    const theme = useTheme();
    const [sourcer, setSourcer] = React.useState<SourcerData>(null);

    React.useEffect(() => {
        getSourcer(sourcerId)
            .then((result) => {
                if (result.success) {
                    setSourcer(result.data.sourcer);
                }
            })
            .catch((err) => {
                logger.error(err, { message: `Error fetching sourcer ${sourcerId}` });
            });
    }, []);

    if (!sourcer) {
        return <Loading />;
    }

    return (
        <DocumentTitle title="Sourcer Search">
            <div id="container">
                <Header title={`${sourcer.title} - ${sourcer.userName}`} actions={[]} />
                <SearchProvider
                    jobId={sourcerId}
                    searchId={searchId}
                    resultsType={resultsType}
                    project={SearchProject.HireflowV2}
                >
                    <div css={styles(theme)}>
                        <div className="job-search-detail">
                            <div className="job-search-side-by-side">
                                <Search />
                                <SearchResults />
                            </div>
                        </div>
                    </div>
                </SearchProvider>
            </div>
        </DocumentTitle>
    );
};
