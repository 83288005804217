import * as _ from 'lodash';
import * as moment from 'moment';
import * as React from 'react';

export const externalLink = (url: string) =>
    url ? (
        <a href={url} target="_blank">
            {url}
        </a>
    ) : undefined;

export const pair = (
    v1: string | JSX.Element | number | Array<string | JSX.Element>,
    v2: string | JSX.Element | number | Array<string | JSX.Element>,
    joiner: string | JSX.Element,
    className?: string
) => {
    if (!v1 && !v2) return undefined;
    if (!v1) return <span className={className}>{v2}</span>;
    if (!v2) return <span className={className}>{v1}</span>;
    return (
        <span className={className}>
            {v1} {joiner} {v2}
        </span>
    );
};

const duration = (durationInMonths: number) => {
    const years = (durationInMonths / 12) | 0; // tslint:disable-line
    const months = durationInMonths % 12; // tslint:disable-line
    const durationStrParts = [];
    if (years > 0) {
        durationStrParts.push(`${years} years`);
    }
    if (months > 0) {
        durationStrParts.push(`${months} months`);
    }
    const durationStr = durationStrParts.join(' ');
    return durationStrParts.length > 0 ? <span className="secondary">({durationStr})</span> : <span />;
};

const period = (pos: { startDate: string; endDate: string }) => {
    const endDate = pos.endDate || 'Present';
    return (
        <span>
            {pos.startDate} – {endDate}{' '}
        </span>
    );
};

export const periodWithDuration = <T extends { startDate: string; endDate: string }>(pos: T) => {
    const endTs = pos.endDate ? Date.parse(pos.endDate) : undefined;
    const s = moment(Date.parse(pos.startDate));
    const e = moment(endTs);
    const durationInMonths = e.diff(s, 'months');
    return (
        <div>
            {period(pos)}
            {duration(durationInMonths)}
        </div>
    );
};

const globalSearch = (content: string, term: string) => {
    const firstChar = term[0];
    const lastChar = term[term.length - 1];
    let regexp;
    if (firstChar.match(/\w/) && lastChar.match(/\w/)) {
        regexp = new RegExp(`\\b${_.escapeRegExp(term)}\\b`, 'gi');
    } else {
        regexp = new RegExp(`(?:^|\\s)${_.escapeRegExp(term)}(?:\\s|$|,|\.|!)`, 'gi');
    }
    const matches = [];
    let match = regexp.exec(content);
    while (match) {
        matches.push({ start: match.index, end: match.index + match[0].length });
        match = regexp.exec(content);
    }
    return matches;
};

export const highlightContent = (
    content: string,
    highlightTerms: Array<{
        terms: string[];
        className: string;
    }>
): string | JSX.Element | Array<string | JSX.Element> => {
    if (!highlightTerms) {
        return content;
    }

    let ranges: Array<{ start: number; end: number; className: string }> = [];
    for (const group of highlightTerms) {
        for (const term of group.terms) {
            const matches = globalSearch(content, term);
            ranges.push(...matches.map((m) => ({ ...m, className: group.className })));
        }
    }
    ranges = ranges.sort((r1, r2) => (r1.start > r2.start ? 1 : -1));
    if (ranges.length === 0) {
        return content; // nothing to highlight
    }
    const combinedRanges = [];
    let currentRange = ranges[0];
    for (const range of ranges.slice(1)) {
        if (
            range.start >= currentRange.start &&
            range.start <= currentRange.end &&
            range.className === currentRange.className
        ) {
            currentRange.end = Math.max(currentRange.end, range.end);
        } else {
            combinedRanges.push(currentRange);
            currentRange = range;
        }
    }
    combinedRanges.push(currentRange);
    const newContent = [];
    if (combinedRanges[0].start !== 0) {
        newContent.push(content.slice(0, combinedRanges[0].start));
    }
    for (let i = 0; i < combinedRanges.length; i += 1) {
        const range = combinedRanges[i];
        newContent.push(
            <span key={i} className={`search-results-highlight ${range.className}`}>
                {content.slice(range.start, range.end)}
            </span>
        );
        if (i !== combinedRanges.length - 1) {
            const nextRange = combinedRanges[i + 1];
            newContent.push(content.slice(range.end, nextRange.start));
        }
    }
    if (combinedRanges[combinedRanges.length - 1].end !== content.length) {
        newContent.push(content.slice(combinedRanges[combinedRanges.length - 1].end));
    }

    return newContent;
};
