import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { LinkedInSearches } from '../components/linkedin-searches';

type RouterConnectedProps = RouteComponentProps<{ searchId: string }>;

export const LinkedInSearchesPageComponent: React.FC<RouterConnectedProps> = (props) => {
    const { searchId } = props.match.params;
    return <LinkedInSearches selected={searchId} />;
};

export const LinkedInSearchesPage = withRouter(LinkedInSearchesPageComponent);
