import { css } from '@emotion/core';
import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    IconButton,
    Theme,
    Tooltip,
    useTheme
} from '@material-ui/core';
import { Clear, ExpandMore } from '@material-ui/icons';
import React from 'react';
import { currencyFormat } from 'shared/common/string-format-utils';

import { fullDate } from '../common/timestamp';
import { Fee } from '../graphql/queries/billing';
import { useFees } from '../hooks/use-fees';

const styles = (theme: Theme) => css`
    .fee-summary {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        .fee-summary-actions {
            opacity: 0;
            transition: opacity 0.2s;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-25%);
        }

        &:hover {
            .fee-summary-actions {
                opacity: 1;
            }
        }
    }

    &.single-fee-details {
        margin-bottom: 12px;
        padding-bottom: 12px;
        border-bottom: thin solid ${theme.palette.divider};

        .fee-summary {
            margin-bottom: 12px;
            padding-bottom: 12px;
            border-bottom: thin solid ${theme.palette.divider};
        }

        .fee-summary-actions {
            position: relative;
            top: inherit;
            transform: none;
        }
    }

    .MuiExpansionPanel-root:before {
        display: none;
    }

    .MuiExpansionPanelSummary-content {
        margin: 0 0 12px 0;

        &.Mui-expanded {
            margin: 0;

            .fee-summary-actions {
                position: relative;
                transform: none;
                top: inherit;
            }
        }
    }

    .MuiExpansionPanel-root {
        box-shadow: none;
        width: 100%;

        &.Mui-expanded {
            margin-bottom: 50px;
            border-bottom: thin solid ${theme.palette.divider};
            padding-bottom: 10px;
        }
    }

    .MuiExpansionPanelSummary-root {
        padding: 0;
        border-bottom: thin solid ${theme.palette.divider};
        margin-bottom: 12px;

        &:last-child {
            border-bottom: none;
            margin-bottom: 0;
        }
    }

    .MuiExpansionPanelDetails-root {
        flex-direction: column;
        padding: 0;
    }
`;

interface FeeFieldData<T extends string | number> {
    label: string;
    value: T;
    formatter?: (value: T) => string;
}

interface FeeDetailsProps {
    fee: Fee;
    disabled: boolean;
    expanded?: boolean;
    onChange?: (expanded: boolean) => void;
    showExpansion?: boolean;
}

const FeeField: React.FC<{ field: FeeFieldData<string | number> }> = ({ field }) => (
    <React.Fragment>
        <div className="info-label">{field.label}</div>
        <div className="info-value">{field.formatter ? field.formatter(field.value) : field.value}</div>
    </React.Fragment>
);

export const PlacementsFeeDetails: React.FC<FeeDetailsProps> = ({
    fee,
    expanded,
    onChange,
    disabled,
    showExpansion = false
}) => {
    const { onDeleteFee } = useFees();
    const theme = useTheme();

    const handleRemoveFee = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onDeleteFee?.(fee.id);
    };

    const placementFeeFields: Array<FeeFieldData<string | number>> = [
        { label: 'Placement Fees', value: fee.amount, formatter: currencyFormat },
        { label: 'Hired On', value: fee.incurredAt, formatter: fullDate },
        { label: 'Start Date', value: fee.dueAt, formatter: fullDate },
        { label: 'Guarantee Date', value: fee.guaranteeCompletionAt, formatter: fullDate },
        { label: 'Base Salary', value: fee.salary },
        { label: 'Sign-On Bonus', value: fee.signOnBonus },
        { label: 'Recruiter Commission', value: fee.recruiterCommission, formatter: currencyFormat },
        { label: 'AM Commission', value: fee.accountManagerCommission, formatter: currencyFormat }
    ];

    const contractorFeeFields: Array<FeeFieldData<string | number>> = [
        { label: 'Monthly Fees', value: fee.amount, formatter: (val: number) => `$${val.toLocaleString()}` },
        { label: 'Contract Start Date', value: fee.incurredAt, formatter: fullDate },
        { label: 'Contract End Date', value: fee.dueAt, formatter: fullDate },
        { label: 'Monthly Rate', value: fee.salary },
        { label: 'Recruiter Commission', value: fee.recruiterCommission, formatter: currencyFormat },
        { label: 'AM Commission', value: fee.accountManagerCommission, formatter: currencyFormat }
    ];

    const feeFieldsData = (fee.type === 'placement' ? placementFeeFields : contractorFeeFields).filter(
        (field) => !!field.value || field.label === 'Monthly Fees' || field.label === 'Placement Fees'
    );

    const [summaryFieldData, ...expandedFieldsData] = feeFieldsData;

    const summaryActions = disabled ? null : (
        <div className="fee-summary-actions">
            <Tooltip title="Delete Fee">
                <IconButton size="small" onClick={handleRemoveFee}>
                    <Clear fontSize="small" />
                </IconButton>
            </Tooltip>
        </div>
    );

    const feeSummary = (
        <div className="fee-summary">
            <div className="fee-summary-content">
                <FeeField field={summaryFieldData} />
            </div>
            {summaryActions}
        </div>
    );

    const expandedFields = expandedFieldsData.map((field) => <FeeField key={field.label} field={field} />);

    if (!showExpansion) {
        return (
            <div css={styles(theme)} className="single-fee-details">
                {feeSummary}
                {expandedFields}
            </div>
        );
    }

    const handleExpansionChange = (_: React.ChangeEvent<{}>, isExpanded: boolean) => {
        onChange?.(isExpanded);
    };

    return (
        <div css={styles(theme)}>
            <ExpansionPanel expanded={expanded} onChange={handleExpansionChange}>
                <ExpansionPanelSummary expandIcon={<ExpandMore />}>{feeSummary}</ExpansionPanelSummary>
                <ExpansionPanelDetails>{expandedFields}</ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    );
};
