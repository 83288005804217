import { Tooltip } from '@material-ui/core';
import { Map, OrderedMap } from 'immutable';
import { CircularProgress, RaisedButton } from 'material-ui';
import { ContentAdd } from 'material-ui/svg-icons';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { hasJobPermission } from 'shared/models/job';

import { amRequestedInfo, awaitingAMApproval, JobStageData, sourcedStage } from 'shared/models/job-stages';
import { UserData } from 'shared/models/user';

import { crossAddCandidate, getConfirmation } from '../actions';
import { JobsListDialog } from '../containers/jobs-list-dialog';
import { SubmitToAccountManager } from '../containers/submit-to-account-manager';
import { Candidate, Client, Job, List, PersonDetails, RequestErrors, State } from '../state';
import { SubmissionGPTDialog } from './submission-gpt-dialog';

interface OwnProps {
    candidate: Candidate;
    personId?: string;
}

interface ConnectedProps {
    clients: List<Client>;
    jobs: OrderedMap<string, Job>;
    user: UserData;
    personsDetails: List<PersonDetails>;
    jobStages: JobStageData[];
    pendingRequests: Map<string, RequestErrors>;
}

interface ConnectedDispatch {
    crossAddCandidate: (
        personId: string,
        jobIds: string[],
        sourceJobId: string,
        newStage: string,
        source: 'cross-submit' | 'cross-add' | 'candidate-search-cross-add' | 'person-add'
    ) => void;
    getConfirmation: (onConfirm: () => void, description?: string | JSX.Element, title?: string) => void;
}

type RouterConnectedProps = RouteComponentProps<{}>;

type CrossAddCandidateProps = OwnProps & ConnectedProps & ConnectedDispatch & RouterConnectedProps;

const CrossAddCandidateComponent: React.FC<CrossAddCandidateProps> = (props) => {
    const { candidate, user, jobs, personsDetails, clients, jobStages, pendingRequests, personId } = props;
    const [jobSelectorOpen, setJobSelectorOpen] = React.useState(false);
    const [submitToJobId, setSubmitToJobId] = React.useState<string>(null);
    const [gptSubmissionDialogJobId, setGPTSubmissionDialogJobId] = React.useState<string>(null);
    const [generatedSubmission, setGeneratedSubmission] = React.useState<string>(null);

    const job = candidate ? jobs.get(candidate.jobId) : null;

    let source: 'cross-submit' | 'cross-add' | 'candidate-search-cross-add' | 'person-add';
    let newStage: string;
    if (props.location.pathname === '/candidates' || personId) {
        source = candidate ? 'candidate-search-cross-add' : 'person-add';
        newStage = sourcedStage;
    } else {
        newStage = jobStages.find((s) => s.name === candidate.stage).crossAddStage;
        if (newStage === awaitingAMApproval) {
            source = 'cross-submit';
        } else {
            source = 'cross-add';
        }
    }

    const isCrossSubmit = source === 'cross-submit';

    if (!personId) {
        if (candidate && candidate.disqualified && source !== 'candidate-search-cross-add') {
            return null;
        }

        if (isCrossSubmit && candidate.assignee !== user.id && job.accountManagerId !== user.id) {
            return null;
        }

        if (!newStage) {
            return null;
        }
    }

    const containerClassName = candidate
        ? 'candidate-move-button-container candidate-move-button-container-small'
        : 'person-add-to-jobs-button';
    let buttonClassName = candidate ? 'candidate-move-button' : '';

    const pending = pendingRequests.get(`cross-add-candidate-${candidate?.personId || personId}`);
    if (pending) {
        const spinnerSize = 20;
        const spinnerThickness = 2;
        const spinner = <CircularProgress size={spinnerSize} thickness={spinnerThickness} />;
        buttonClassName = `${buttonClassName} disabled`;
        return (
            <div className={containerClassName}>
                <div className={buttonClassName}>{spinner}</div>
            </div>
        );
    }

    const newStageLabel = jobStages.find((s) => s.name === newStage).label;

    const handleToggleJobSelector = () => {
        setJobSelectorOpen(!jobSelectorOpen);
    };

    const crossSubmitJobsFilter = (j: Job) => {
        const { candidates } = personsDetails.list.get(candidate.personId);
        const jobCandidate = candidates.find((c) => c.jobId === j.id);
        const hasSendOutreachPermission = hasJobPermission(j.recruiterPermissions.sendOutreach, user.id);
        if (!j.accountManagerId || !hasSendOutreachPermission) {
            return false;
        }

        if (!jobCandidate) {
            return true;
        }
        const amRequestedInfoIndex = jobStages.find((s) => s.name === amRequestedInfo).id;
        const jobCandidateStageIndex = jobStages.find((s) => s.name === jobCandidate.stage).id;
        return jobCandidateStageIndex < amRequestedInfoIndex;
    };

    const crossAddJobsFilter = (j: Job) => {
        const { candidates } = personsDetails.list.get(candidate?.personId || personId);
        const jobCandidate = candidates.find((c) => c.jobId === j.id);
        const hasSendOutreachPermission = hasJobPermission(j.recruiterPermissions.sendOutreach, user.id);
        return !jobCandidate && hasSendOutreachPermission;
    };

    const jobsFilter = isCrossSubmit ? crossSubmitJobsFilter : crossAddJobsFilter;

    const handleCancelSubmit = () => setSubmitToJobId(null);

    const handleSubmitNoteSaved = () => {
        props.crossAddCandidate(candidate.personId, [submitToJobId], candidate.jobId, newStage, source);
        setSubmitToJobId(null);
    };

    const handleGPTSubmissionDialogSkip = () => {
        setSubmitToJobId(gptSubmissionDialogJobId);
        setGPTSubmissionDialogJobId(null);
        setGeneratedSubmission(null);
    };

    const handleGPTSubmissionDialogConfirm = (generated: string) => {
        setGeneratedSubmission(generated);
        setSubmitToJobId(gptSubmissionDialogJobId);
        setGPTSubmissionDialogJobId(null);
    };

    const handleSubmitToJobSelected = (jobId: string | string[]) => {
        setJobSelectorOpen(false);
        if (!Array.isArray(jobId)) {
            const submitToJob = jobs.get(jobId);
            const client = clients.list.get(submitToJob.clientId);
            const description = (
                <span>
                    Please confirm the <b>candidate agreed</b> to be submitted for {client.name} - {submitToJob.title}{' '}
                    over a <b>phone call</b> or <b>email</b>
                </span>
            );
            props.getConfirmation(
                () => setGPTSubmissionDialogJobId(jobId),
                description,
                'Confirm Candidate agreed to be Submitted'
            );
        } else {
            const submitToJobs = jobId
                .map((id) => jobs.get(id))
                .map((j) => {
                    const client = clients.list.get(j.clientId);
                    return (
                        <li key={j.id}>
                            {client.name} - {j.title}
                        </li>
                    );
                });
            const description = (
                <div>
                    Are you sure you wish to add {candidate ? 'the candidate' : 'this profile'} to the following jobs?
                    <ul>{submitToJobs}</ul>
                </div>
            );
            props.getConfirmation(
                () =>
                    props.crossAddCandidate(candidate?.personId || personId, jobId, candidate?.jobId, newStage, source),
                description,
                `Confirm Jobs to ${candidate ? 'Cross-Add To' : 'add'}`
            );
        }
    };

    const confirmLabel = isCrossSubmit ? 'Confirm' : `Add to ${newStageLabel}`;
    const jobSelectorDialog = jobSelectorOpen ? (
        <JobsListDialog
            sourceJobId={candidate?.jobId}
            onRequestClose={handleToggleJobSelector}
            onJobSelect={handleSubmitToJobSelected}
            jobsFilter={jobsFilter}
            multiple={!isCrossSubmit}
            confirmLabel={confirmLabel}
        />
    ) : null;

    const submitNoteDialog = submitToJobId ? (
        <SubmitToAccountManager
            personId={candidate.personId}
            jobId={submitToJobId}
            generatedSubmission={generatedSubmission}
            onCancel={handleCancelSubmit}
            onSave={handleSubmitNoteSaved}
        />
    ) : null;

    const gptSubmissionJob = jobs.get(gptSubmissionDialogJobId);
    const gptSubmissionDialog = gptSubmissionDialogJobId ? (
        <SubmissionGPTDialog
            open={true}
            personId={candidate.personId}
            userId={candidate.assignee}
            job={gptSubmissionJob}
            onSkip={handleGPTSubmissionDialogSkip}
            onConfirm={handleGPTSubmissionDialogConfirm}
        />
    ) : null;

    const tooltipContent = isCrossSubmit ? 'Submit To Another Job' : 'Add To Another Job';

    return (
        <div className={containerClassName}>
            <Tooltip title={tooltipContent}>
                <span>
                    <RaisedButton
                        icon={candidate ? <ContentAdd /> : undefined}
                        primary={true}
                        label={candidate ? undefined : 'Add to Job'}
                        className={buttonClassName}
                        onClick={handleToggleJobSelector}
                        fullWidth={!!personId}
                    />
                </span>
            </Tooltip>
            {jobSelectorDialog}
            {submitNoteDialog}
            {gptSubmissionDialog}
        </div>
    );
};

const mapStateToProps = (state: State): ConnectedProps => ({
    clients: state.clients,
    jobStages: state.appConstants.constants.jobStages,
    jobs: state.jobs,
    pendingRequests: state.pendingRequests,
    personsDetails: state.personsDetails,
    user: state.session.user
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    crossAddCandidate,
    getConfirmation
};

export const CrossAddCandidate = withRouter(
    connect<ConnectedProps, ConnectedDispatch, OwnProps & RouterConnectedProps>(
        mapStateToProps,
        mapDispatchToProps
    )(CrossAddCandidateComponent)
);
