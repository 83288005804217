import { Map } from 'immutable';

import { Action, ReceiveCandidateAssigneeData } from '../actions';
import { CandidateAssigneeData } from '../state';

const initialState: Map<string, CandidateAssigneeData> = Map();

export function candidateAssigneeData(state = initialState, action: Action): Map<string, CandidateAssigneeData> {
    switch (action.type) {
        case ReceiveCandidateAssigneeData:
            return state.set(action.payload.id, action.payload.data);
        default:
            return state;
    }
}
