import { gql } from '@apollo/client';

export interface ProfileRatingReq {
    id: string;
    name: string;
}

export interface ProfileRating {
    rating: number;
    reqId: string;
    user: {
        id: string;
        name: string;
    };
}

export interface RatingCandidate {
    person: {
        id: string;
        name: {
            first: string;
            last: string;
            full: string;
        };
        profilePicUrl: string;
    };
    reqIds: string[];
    ratings: ProfileRating[];
}

export const REQS = gql`
    query ProfileRatingReqs {
        reqs: profile_ratings_reqs {
            id
            name
        }
    }
`;

export const RATING_CANDIDATES = gql`
    query Candidates($limit: Int!, $offset: Int!, $reqIds: [String!]!) {
        candidates: profile_ratings_candidates_grouped(
            where: { reqIds: { _has_keys_any: $reqIds } }
            limit: $limit
            offset: $offset
        ) {
            person {
                id
                name
                profilePicUrl
            }
            reqIds
            ratings {
                rating
                reqId
                user {
                    id
                    name(path: "full")
                }
            }
        }
        count: profile_ratings_candidates_grouped_aggregate(where: { reqIds: { _has_keys_any: $reqIds } }) {
            aggregate {
                count
            }
        }
    }
`;

export const CREATE_RATING = gql`
    mutation CreateRating($reqId: String!, $personId: String!, $rating: Int!) {
        rating: insert_profile_ratings(
            objects: { personId: $personId, rating: $rating, reqId: $reqId }
            on_conflict: { constraint: profile_ratings_pkey, update_columns: rating }
        ) {
            returning {
                rating
                reqId
                user {
                    id
                    name(path: "full")
                }
            }
        }
    }
`;
