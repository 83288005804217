import { css } from '@emotion/core';
import { IconButton, MenuItem, TextField } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { format } from 'date-fns';
import { CaptionProps, DayPicker as LibDayPicker, DayPickerProps, useNavigation } from 'react-day-picker';

const styles = css`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .buttons {
        flex: 0 0 auto;
    }

    .info {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        margin-bottom: -10px;
        margin-left: 5px;

        .MuiSelect-select {
            padding-right: 0;
            font-weight: 600;
            font-size: 22px;
            margin-right: 10px;

            &:focus {
                background-color: transparent;
            }

            &:focus-visible {
                outline: 0;
            }
        }

        .MuiSelect-icon {
            display: none;
        }
    }
`;

function CustomCaption(props: CaptionProps) {
    const { goToMonth, nextMonth, previousMonth } = useNavigation();

    const yearMin = 2017;
    const yearMax = new Date().getFullYear() + 1;

    const monthOptions = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];

    const handleMonthChange = (event: React.ChangeEvent<{ value: string }>) => {
        const month = event.target.value;
        const year = props.displayMonth.getFullYear();
        const monthIndexToGo = monthOptions.indexOf(month);
        const dateToGo = new Date(year, monthIndexToGo);
        goToMonth(dateToGo);
    };

    const handleYearChange = (event: React.ChangeEvent<{ value: string }>) => {
        const year = parseInt(event.target.value, 10);
        const month = props.displayMonth.getMonth();
        const dateToGo = new Date(year, month);
        goToMonth(dateToGo);
    };

    const handleNextMonthClick = () => {
        if (nextMonth) {
            goToMonth(nextMonth);
        }
    };

    const handlePreviousMonthClick = () => {
        if (previousMonth) {
            goToMonth(previousMonth);
        }
    };

    const selectedMonth = format(props.displayMonth, 'MMMM');
    const selectedYear = props.displayMonth.getFullYear();

    const monthMenuOpts = monthOptions.map((month) => (
        <MenuItem key={month} value={month}>
            {month}
        </MenuItem>
    ));

    const yearMenuOpts = [];
    for (let year = yearMin; year <= yearMax; year++) {
        yearMenuOpts.push(
            <MenuItem key={year} value={year}>
                {year}
            </MenuItem>
        );
    }

    return (
        <div css={styles}>
            <div className="info">
                <TextField
                    select={true}
                    value={selectedMonth}
                    onChange={handleMonthChange}
                    InputProps={{ disableUnderline: true }}
                >
                    {monthMenuOpts}
                </TextField>
                <TextField
                    select={true}
                    value={selectedYear}
                    onChange={handleYearChange}
                    InputProps={{ disableUnderline: true }}
                >
                    {yearMenuOpts}
                </TextField>
            </div>
            <div className="buttons">
                <IconButton onClick={handlePreviousMonthClick} disabled={!previousMonth}>
                    <ChevronLeft />
                </IconButton>
                <IconButton onClick={handleNextMonthClick} disabled={!nextMonth}>
                    <ChevronRight />
                </IconButton>
            </div>
        </div>
    );
}

export const DayPicker: React.FC<DayPickerProps> = (props) => {
    return <LibDayPicker {...props} components={{ Caption: CustomCaption }} />;
};
