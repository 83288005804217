import { useContext, useEffect, useState } from 'react';
import { Store } from 'redux';

import { State } from '../state';
import { ReduxContext } from './redux-context';

export const useReduxState = <TSelected>(selector: (state: State) => TSelected): TSelected => {
    const store = useContext(ReduxContext) as Store<State>;
    const [state, setState] = useState(selector(store.getState()));

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            setState(selector(store.getState()));
        });

        return unsubscribe;
    }, [store, selector]);

    return state;
};

export const useReduxDispatch = () => {
    const store = useContext(ReduxContext) as Store<State>;
    return store.dispatch;
};
