import React from 'react';
import { RouteProps, withRouter } from 'react-router-dom';
import { trackPageView } from '../analytics';

const RouteChangeTrackerComponent: React.FC<RouteProps> = (props) => {
    trackPageView(props.location.pathname);
    return null;
};

export const RouteChangeTracker = withRouter(RouteChangeTrackerComponent);
