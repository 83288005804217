import { css } from '@emotion/core';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Theme,
    Typography,
    useTheme
} from '@material-ui/core';
import React, { useState } from 'react';

import { getLocalStorageKey, removeLocalStorageKey, setLocalStorageKey } from '../../common/local-storage';
import { HomePagePanel } from './home-page-panel';

interface HomePageTableProps<T extends { id?: string; _id?: string }> {
    columns: Array<{ title: string; sortFunc?: (record: T) => string | number; defaultDesc?: boolean }>;
    RowComponent: React.ComponentType<{ data: T; list: T[] }>;
    records: T[];
    title: string | JSX.Element;
    sortKeyId: string;
    initialSort: { column: string; ascending: boolean };
    rowsPerPage?: number;
    actions?: JSX.Element;
    showIfEmpty?: boolean;
}

// tslint:disable:no-magic-numbers
const styles = (theme: Theme) => css`
    .MuiTableBody-root .MuiTableRow-root {
        .MuiTableCell-root.actions-cell {
            position: relative;
            min-width: 105px;

            .initial,
            .hovered {
                position: absolute;
                top: calc(50% - 14px);
                left: 12px;
                display: flex;
                align-items: center;
                transition: all 0.2s ease;

                > * {
                    margin-right: 8px;
                }
            }

            .initial {
                visibility: visible;
                opacity: 1;
            }

            .hovered {
                visibility: hidden;
                opacity: 0;
            }
        }

        &:hover {
            background: ${theme.palette.grey[100]};

            .MuiTableCell-root.actions-cell {
                .initial {
                    visibility: hidden;
                    opacity: 0;
                }
                .hovered {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
    .MuiTableRow-root:last-child {
        td.MuiTableCell-root.MuiTableCell-body {
            border-bottom: none;
        }
    }
    .empty-table {
        display: block;
        padding: 10px;
        text-align: center;
    }
`;
// tslint:enable:no-magic-numbers

const getSortDirection = (ascending: boolean) => (ascending ? 'asc' : 'desc');
const noDataMessageChangeDelayMs = 2500;

const HomePageTableComponent = <T extends { id?: string; _id?: string }>(props: {
    rows: JSX.Element[];
    columns: Array<{ title: string; sortFunc?: (record: T) => string | number; defaultDesc?: boolean }>;
    sortCol: string;
    sortAsc: boolean;
    showIfEmpty: boolean;
    handleSortChange: (column: string, defaultDesc: boolean) => () => void;
}) => {
    const theme = useTheme();
    const { columns, rows, sortCol, sortAsc, showIfEmpty, handleSortChange } = props;
    const [noDataMessage, setNoDataMessage] = React.useState('Loading...');

    React.useEffect(() => {
        if (rows.length === 0) {
            setNoDataMessage('Loading...');
            setTimeout(() => {
                if (rows.length === 0) {
                    setNoDataMessage('No Records Found');
                }
            }, noDataMessageChangeDelayMs);
        }
    }, [rows]);

    const headerColumns = columns.map((col) => {
        const columnHeader = col.sortFunc ? (
            <TableSortLabel
                active={sortCol === col.title}
                direction={sortCol === col.title ? getSortDirection(sortAsc) : getSortDirection(!col.defaultDesc)}
                onClick={handleSortChange(col.title, col.defaultDesc)}
            >
                {col.title}
            </TableSortLabel>
        ) : (
            col.title
        );
        return <TableCell key={col.title}>{columnHeader}</TableCell>;
    });

    let tableBody;
    if (rows.length === 0 && showIfEmpty) {
        tableBody = (
            <TableRow>
                <TableCell colSpan={headerColumns.length}>
                    <span className="empty-table">
                        <Typography variant="overline" color="textSecondary">
                            {noDataMessage}
                        </Typography>
                    </span>
                </TableCell>
            </TableRow>
        );
    } else {
        tableBody = rows;
    }

    return (
        <TableContainer css={styles(theme)}>
            <Table>
                <TableHead>
                    <TableRow>{headerColumns}</TableRow>
                </TableHead>
                <TableBody>{tableBody}</TableBody>
            </Table>
        </TableContainer>
    );
};

export const HomePageTable = <T extends { id?: string; _id?: string }>(props: HomePageTableProps<T>) => {
    const { columns, RowComponent, records, title, initialSort, sortKeyId, actions, showIfEmpty } = props;
    const sortPrefsKey = `homepage-table-sort-${sortKeyId}`;
    let savedSort = getLocalStorageKey(sortPrefsKey, initialSort);
    // FIX deploy issue
    if (savedSort.column === 'Priority') {
        savedSort = initialSort;
        removeLocalStorageKey(sortPrefsKey);
    }
    const [sortCol, setSortCol] = useState(savedSort?.column);
    const [sortAsc, setSortAsc] = useState(savedSort?.ascending ?? true);

    const handleSortChange = (column: string, defaultDesc: boolean) => () => {
        const newSortAsc = sortCol === column ? !sortAsc : !defaultDesc;
        setSortCol(column);
        setSortAsc(newSortAsc);
        setLocalStorageKey(sortPrefsKey, { column, ascending: newSortAsc }, -1);
    };

    const sortFunc = columns.find((c) => c.title === sortCol)?.sortFunc;
    const multiplier = getSortDirection(sortAsc) === 'desc' ? -1 : 1;
    const sortedRecords = records?.sort((r1, r2) => {
        switch (typeof sortFunc(r1)) {
            case 'number':
                return multiplier * ((sortFunc(r1) as number) - (sortFunc(r2) as number));
            case 'string':
                return multiplier * (sortFunc(r1) as string).localeCompare(sortFunc(r2) as string);
            default:
                return 0;
        }
    });

    const contentProps = {
        columns,
        handleSortChange,
        showIfEmpty,
        sortAsc,
        sortCol
    };

    return (
        <HomePagePanel
            RowComponent={RowComponent}
            ContentComponent={HomePageTableComponent}
            records={sortedRecords}
            title={title}
            containerClass="table"
            actions={actions}
            showIfEmpty={showIfEmpty}
            contentProps={contentProps}
            collapseKeyId={`table-${sortKeyId}`}
        />
    );
};
