import { useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import { IconButton, Popover, Tooltip } from '@material-ui/core';
import { ArrowDropDown, Clear } from '@material-ui/icons';
import { orderBy } from 'lodash';
import React from 'react';

import { responseReceivedStage } from 'shared/models/job-stages';
import { hasFeature, USER_FEATURE_CANDIDATE_CARD_SCORE } from 'shared/models/user';
import { colors, getScoreColor } from '../common/score-ratings';

import { fullDate } from '../common/timestamp';
import { StarRating } from '../components/star-rating';
import { config } from '../config';
import {
    CANDIDATE_PROFILE_SCORES,
    CandidateScore,
    CREATE_PROFILE_SCORE,
    DELETE_PROFILE_SCORE,
    ProfileScore
} from '../graphql/queries/profile-score';
import { State } from '../state';
import { useAIProfileScoreDetails } from './use-ai-profile-score-details';
import { AdornmentGenerator, useProfileAdornments } from './use-profile-adornment';
import { useReduxState } from './use-redux';
import { useSession } from './use-session';
import { useSnackbar } from './use-snackbar';

const styles = css`
    position: absolute;
    white-space: nowrap;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    .rating-container {
        display: flex;
    }
`;

const popoverStyles = css`
    padding: 20px 30px 20px 20px;

    .score-details {
        margin-bottom: 25px;
        &:last-child {
            margin-bottom: 0;
        }

        .score-label {
            font-size: 12px;
            margin-left: 5px;
            margin-bottom: 2px;
        }

        .score-ts {
            font-size: 10px;
            color: #999;
            margin-left: 5px;
            margin-top: 2px;
        }

        .star-rating-with-action {
            display: flex;
            align-items: center;

            .delete-button {
                margin-left: 10px;
                opacity: 0;
                transition: opacity 0.2s;
            }
        }

        &:hover {
            .delete-button {
                opacity: 1;
            }
        }
    }
`;

const CandidateScoreComponent: React.FC<{
    scores: CandidateScore[];
    onCreateScore: (rating: number) => void;
    onDelete: (id: string) => void;
    updating: boolean;
}> = ({ scores, onCreateScore, onDelete, updating }) => {
    const [anchor, setAnchor] = React.useState<HTMLElement | null>(null);
    const { showScoreDetails } = useAIProfileScoreDetails();
    const { user } = useSession();

    const handleScoreClick = (s: ProfileScore) => () => {
        showScoreDetails(s);
    };

    const handleMoreScoresButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        setAnchor(e.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchor(null);
    };

    const handleCreateScore = (rating: number) => {
        onCreateScore(rating);
    };

    const handleDelete = (id: string) => () => {
        setAnchor(null);
        onDelete(id);
    };

    const score = scores?.[0];
    const moreButton =
        scores?.length > 1 ? (
            <IconButton onClick={handleMoreScoresButtonClick}>
                <ArrowDropDown htmlColor="white" />
            </IconButton>
        ) : null;

    let popoverContent;
    if (scores?.length > 1) {
        const scoreItems = scores.map((s) => {
            const label =
                s.user?.id === config.SystemUserId
                    ? `Job Score - AI Generated`
                    : s.user
                      ? s.user.name
                      : s.searchId
                        ? 'Search Score - AI Generated'
                        : 'AI Score';
            const ts = fullDate(s.createdAt);
            const aiScore = !s.user || s.user?.id === config.SystemUserId;
            const deleteButton =
                s.user?.id === user.id ? (
                    <Tooltip title="Delete score">
                        <IconButton
                            size="small"
                            onClick={handleDelete(s.id)}
                            disabled={updating}
                            className="delete-button"
                        >
                            <Clear fontSize="small" htmlColor="black" />
                        </IconButton>
                    </Tooltip>
                ) : null;
            return (
                <div key={s.id} className="score-details">
                    <div className="score-label">{label}</div>
                    <div className="star-rating-with-action">
                        <StarRating
                            rating={s.score}
                            onClick={aiScore ? handleScoreClick(s) : undefined}
                            tooltip={aiScore ? 'AI Score' : 'User Score'}
                            htmlColor={getScoreColor(s, user).alternate}
                        />
                        {deleteButton}
                    </div>
                    <div className="score-ts">{ts}</div>
                </div>
            );
        });
        popoverContent = <div css={popoverStyles}>{scoreItems}</div>;
    }

    const popover = (
        <Popover
            anchorEl={anchor}
            open={Boolean(anchor) && !updating}
            onClose={handleClosePopover}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            {popoverContent}
        </Popover>
    );

    const selectScoreByAI = !score.user || score.user?.id === config.SystemUserId;

    return (
        <div css={styles}>
            <div className="rating-container">
                <StarRating
                    rating={score.score}
                    onClick={selectScoreByAI ? handleScoreClick(score) : null}
                    tooltip={selectScoreByAI ? 'AI Score, click for details' : 'User Score'}
                    onChange={updating ? null : handleCreateScore}
                    htmlColor={getScoreColor(score, user).main}
                    hoverHtmlColor={colors.sessionUser.main}
                />
                {moreButton}
                {popover}
            </div>
        </div>
    );
};

export const useCandidateScore = (personId: string, jobId: string) => {
    const {
        data: scoresData,
        refetch,
        loading
    } = useQuery<{ scores: CandidateScore[] }, { personId: string; jobId: string }>(CANDIDATE_PROFILE_SCORES, {
        variables: { personId, jobId }
    });
    const [createScore, { loading: creating }] = useMutation<{}, { personId: string; jobId: string; score: number }>(
        CREATE_PROFILE_SCORE
    );
    const [deleteScore, { loading: deleting }] = useMutation<{}, { id: string }>(DELETE_PROFILE_SCORE);
    const { user, userPermissions } = useSession();
    const candidates = useReduxState((state: State) => state.candidates);
    const { setAdornmentGenerator, getAdornmentGenerator } = useProfileAdornments();
    const { setSnackbar } = useSnackbar();
    const [adornmentGeneratorSet, setAdornmentGeneratorSet] = React.useState(false);

    const handleCreateScore = async (rating: number) => {
        setSnackbar('Creating score...');
        await createScore({ variables: { personId, jobId, score: rating } });
        refetch();
        setSnackbar('Score created');
    };

    const handleDeleteScore = async (id: string) => {
        setSnackbar('Deleting score...');
        await deleteScore({ variables: { id } });
        refetch();
        setSnackbar('Score deleted');
    };

    const getProfileAdornments: AdornmentGenerator = React.useCallback(
        (id: string) => {
            const candidate = candidates?.get?.(jobId)?.get?.(id);
            const orderedScores = orderBy(
                scoresData?.scores,
                [
                    (s) => (s.user?.id === user.id ? 1 : 0),
                    (s) => (s.user?.id && s.user.id !== config.SystemUserId ? 1 : 0),
                    (s) => (s.user?.id === config.SystemUserId ? 1 : 0),
                    (s) => (s.searchId === candidate?.searchId ? 1 : 0),
                    (s) => s.createdAt
                ],
                ['desc', 'desc', 'desc', 'desc', 'desc']
            );

            const score = orderedScores?.[0];

            if (!hasFeature(user, USER_FEATURE_CANDIDATE_CARD_SCORE) || !score || id !== personId || !candidate) {
                return {};
            }

            const scoreDetailedView = (
                <div style={{ marginRight: '20px', position: 'relative' }}>
                    <CandidateScoreComponent
                        scores={orderedScores}
                        onCreateScore={handleCreateScore}
                        onDelete={handleDeleteScore}
                        updating={loading || creating || deleting}
                    />
                </div>
            );

            const explanationDetailedView =
                candidate.stage === responseReceivedStage && score?.scoreExplanation ? (
                    <div className="separated">
                        <div style={{ fontWeight: 300, marginBottom: '15px', fontSize: '18px' }}>Score Explanation</div>
                        <div style={{ fontWeight: 500, color: 'black' }}>{score?.scoreExplanation}</div>
                    </div>
                ) : null;

            return {
                insertElements: [
                    { slot: 'explanationDetailedView', element: explanationDetailedView },
                    { slot: 'scoreDetailedView', element: scoreDetailedView }
                ]
            };
        },
        [jobId, personId, candidates, userPermissions, scoresData]
    );

    React.useEffect(() => {
        if (adornmentGeneratorSet || !getAdornmentGenerator()) {
            setAdornmentGenerator(getProfileAdornments);
            setAdornmentGeneratorSet(true);
        }
    }, [getProfileAdornments, adornmentGeneratorSet]);

    React.useEffect(() => {
        return () => {
            if (adornmentGeneratorSet) {
                setAdornmentGenerator(null);
            }
        };
    }, [adornmentGeneratorSet]);
};
