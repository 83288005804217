import { Map } from 'immutable';

import { UserData } from 'shared/models/user';

import {
    Action,
    ExtensionProfileParsed,
    ReceiveJobInfo,
    ReceiveJobsList,
    ReceivePersonDetails,
    ReceiveSession,
    ReceiveUserInfo,
    ReceiveUsersList,
    ReceiveUserUpdate
} from '../actions';
import { mergeArrayToMap } from '../common/lang/immutable-utils';

const initialState: Map<string, UserData> = Map();

export function users(state = initialState, action: Action): Map<string, UserData> {
    switch (action.type) {
        case ReceiveJobInfo:
        case ReceiveJobsList:
        case ReceiveUsersList:
        case ExtensionProfileParsed:
        case ReceivePersonDetails:
            return action.payload.users?.length > 0 ? mergeArrayToMap(state, action.payload.users) : state;
        case ReceiveSession:
            return state.set(action.session.user.id, action.session.user);
        case ReceiveUserUpdate:
        case ReceiveUserInfo:
            return state.set(action.payload.user.id, action.payload.user);
        default:
            return state;
    }
}
