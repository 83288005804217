import { Dialog, DropDownMenu, FlatButton, MenuItem, TextField } from 'material-ui';
import * as moment from 'moment';
import * as React from 'react';

interface NotInterestedRightNowState {
    optOutDuration: number;
    optOutNote: string;
}

interface OwnProps {
    show: boolean;
    requestClose: () => void;
    onDisqualify: (note: string, optOutUntil: number) => void;
}

type NotInterestedRightNowProps = OwnProps;

export class NotInterestedRightNow extends React.Component<NotInterestedRightNowProps, NotInterestedRightNowState> {
    constructor(props: NotInterestedRightNowProps) {
        super(props);
        this.state = {
            optOutDuration: 2,
            optOutNote: ''
        };
    }

    editNoteValue = (_1: React.FormEvent<{}>, value: string) => {
        this.setState({ optOutNote: value });
    };

    handleOptOut = () => {
        const didNotSpecifyDuration = 2;
        const optOutUntil = this.state.optOutDuration > 0 ? this.state.optOutDuration : didNotSpecifyDuration;
        this.props.onDisqualify(this.state.optOutNote, moment().add(optOutUntil, 'months').utc().valueOf());
    };

    handleOptOutSelectDuration = (_1: React.SyntheticEvent<{}>, _2: number, value: number) => {
        this.setState({ optOutDuration: value });
    };

    getDisplayText = (months: number) => {
        return `in ${moment().add(months, 'months').format('MMMM')}`;
    };

    render() {
        const optOutDialogActions = [
            <FlatButton
                key="not-interested-right-now-cancel"
                label="Cancel"
                primary={true}
                onClick={this.props.requestClose}
            />,
            <FlatButton key="not-interested-right-now-pause" label="Pause" primary={true} onClick={this.handleOptOut} />
        ];
        return (
            <Dialog
                className="opt-out-dialog"
                open={this.props.show}
                onRequestClose={this.props.requestClose}
                actions={optOutDialogActions}
                title="Not Right Time"
            >
                <div className="not-right-time-dropdown-line">
                    <span className="dropdown-label">Candidate wants to be contacted in:</span>
                    <DropDownMenu
                        value={this.state.optOutDuration}
                        onChange={this.handleOptOutSelectDuration}
                        labelStyle={{ paddingLeft: '0px' }}
                        underlineStyle={{ marginLeft: '0px' }}
                    >
                        {/* tslint:disable:no-magic-numbers */}
                        <MenuItem primaryText={this.getDisplayText(1)} value={1} />
                        <MenuItem primaryText={this.getDisplayText(2)} value={2} />
                        <MenuItem primaryText={this.getDisplayText(3)} value={3} />
                        <MenuItem primaryText="(Did not specify)" value={-1} />
                        {/* tslint:enable:no-magic-numbers */}
                    </DropDownMenu>
                </div>
                <TextField hintText="Add Note" onChange={this.editNoteValue} multiLine={true} />
                <div className="opt-out-note">This will remove the candidate from ALL current email sequences.</div>
            </Dialog>
        );
    }
}
