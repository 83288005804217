import { gql } from 'graphql-tag';

export interface Company {
    name: string;
    url: string;
    count: number;
}

export interface CompanySimilarityScore {
    sourceUrl: string;
    scores: Array<{
        url: string;
        name: string;
        similarityScore: number;
    }>;
}

export const COMPANY_QUERY = gql`
    query GetCompanyNames($search: String!) {
        companies: search_companies(args: { search: $search }) {
            name: companyName
            url: companyUrl
            count
        }
    }
`;

export const UPDATE_COMPANY_TAGS = gql`
    mutation UpdateCompanyTags($companyId: String!, $tag: String!, $userId: String!, $deleted: Boolean!) {
        update_companies_tags(
            _set: { deleted: $deleted, deletedBy: $userId }
            where: { companyId: { _eq: $companyId }, tag: { _eq: $tag } }
        ) {
            affected_rows
        }
    }
`;

export const CREATE_COMPANY_TAG = gql`
    mutation CreateCompanyTag($companyId: String!, $tag: String!) {
        insert_companies_tags_one(object: { companyId: $companyId, tag: $tag }) {
            id
        }
    }
`;

export const UPDATE_COMPANY_INVESTMENTS = gql`
    mutation UpdateCompanyInvestments(
        $companyId: String!
        $investorName: String!
        $userId: String!
        $deleted: Boolean!
    ) {
        update_companies_investments(
            _set: { deleted: $deleted, deletedBy: $userId }
            where: { companyId: { _eq: $companyId }, investorName: { _eq: $investorName } }
        ) {
            affected_rows
        }
    }
`;

export const CREATE_COMPANY_INVESTMENT = gql`
    mutation CreateCompanyInvestment(
        $companyId: String!
        $investorName: String!
        $investmentType: String
        $link: String
        $raisedAmount: bigint
    ) {
        insert_companies_investments_one(
            object: {
                companyId: $companyId
                investorName: $investorName
                investmentType: $investmentType
                link: $link
                raisedAmount: $raisedAmount
            }
        ) {
            id
        }
    }
`;

export const GET_COMPANY_SIMILARITY_SCORES = gql`
    query GetCompanySimilarityScores($sourceUrls: jsonb!, $additionalTargetCompanies: jsonb) {
        companySimilarityScores: get_similarity_scores(
            args: { source_urls: $sourceUrls, additional_target_companies: $additionalTargetCompanies }
        ) {
            _id
            sourceUrl
            scores
        }
    }
`;
