import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { JobNotesContainer } from '../containers/job-notes-container';

interface RouterProps {
    id: string;
}

const JobNotesPageComponent: React.SFC<RouteComponentProps<RouterProps>> = ({ match }) => (
    <JobNotesContainer jobId={match.params.id} />
);

export const JobNotesPage = withRouter(JobNotesPageComponent);
