import { useMutation } from '@apollo/client';
import { Button, ButtonProps } from '@material-ui/core';
import React from 'react';
import { Redirect } from 'react-router-dom';

import { JobStatus } from 'shared/models/job';
import { hasRole } from 'shared/models/user';
import { client as apolloClient } from '../graphql/apollo-client';

import { CREATE_JOB, JobFormData, UPDATE_JOB_DESCRIPTION } from '../graphql/queries/job-form';
import { useModal } from '../hooks/use-modal';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';

interface JobCloneProps extends ButtonProps {
    data: JobFormData;
}

export const JobCloneButton: React.FC<JobCloneProps> = ({ data, ...restProps }) => {
    const [redirectTo, setRedirectTo] = React.useState<string>(null);
    const { user, userPermissions } = useSession();
    const { getConfirmation, hideLoading, showLoading } = useModal();
    const { setSnackbar } = useSnackbar();
    const mutationClient = apolloClient('job_creator');
    const [insertJob] = useMutation<
        { job: { id: string } },
        { job: Omit<JobFormData, 'client' | 'id' | 'description'> }
    >(CREATE_JOB, { client: mutationClient });
    const [updateJD] = useMutation<
        { jobId: string },
        { jobId: string; description: Omit<JobFormData['description'], '__typename' | 'id' | 'jobId' | '_id'> }
    >(UPDATE_JOB_DESCRIPTION, { client: mutationClient });

    const createJob = async () => {
        try {
            const {
                __typename,
                description: { __typename: descTypename, _id, jobId, ...descriptionRest },
                client,
                id,
                ...rest
            } = data;
            const job: Omit<JobFormData, 'client' | 'id' | 'description'> = {
                ...rest,
                accountManagerId: user.id,
                assignee: user.id,
                status: JobStatus.Paused,
                title: 'Copy of ' + rest.title
            };
            showLoading();
            const result = await insertJob({ variables: { job } });
            const newJobId = result.data.job.id;
            await updateJD({
                variables: {
                    description: descriptionRest,
                    jobId: newJobId
                }
            });
            setSnackbar('Job created');
            setRedirectTo(`/job/${result.data.job.id}/edit`);
        } catch (e) {
            setSnackbar('Failed to create job');
        } finally {
            hideLoading();
        }
    };

    const handleClick = () => {
        getConfirmation(
            createJob,
            'This will create a new Job with same settings as the current job',
            'Please confirm'
        );
    };

    if (!hasRole(userPermissions, 'job_creator')) return null;

    if (redirectTo) {
        return <Redirect to={redirectTo} />;
    }

    return (
        <Button {...restProps} onClick={handleClick}>
            Clone This Job
        </Button>
    );
};
