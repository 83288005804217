import { Map } from 'immutable';
import * as moment from 'moment';
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';

import { fetchClientWeeklyUpdateData } from '../actions';
import { WeeklyUpdateEmailComponent } from '../components/weekly-update-email';
import { ClientUpdateData, RequestErrors, State } from '../state';

const START_DAY_OFFSET = 8;
const DATA_DAY_OFFSET = 120;

interface OwnProps {
    id: string;
    all: boolean;
}

interface ConnectedProps {
    clientUpdates: Map<string, ClientUpdateData>;
    pendingRequests: Map<string, RequestErrors>;
}

interface ConnectedDispatch {
    fetchClientWeeklyUpdateData: (clientId: string, startDate: number, endDate: number, all: boolean) => void;
}

interface WeeklyUpdateComponentState {
    startDate: number;
    endDate: number;
}

type WeeklyUpdateComponentProps = OwnProps & ConnectedProps & ConnectedDispatch;

class WeeklyUpdateComponent extends React.Component<WeeklyUpdateComponentProps, WeeklyUpdateComponentState> {
    constructor(props: WeeklyUpdateComponentProps) {
        super(props);
        this.state = {
            endDate: Date.now(),
            startDate: moment().subtract(START_DAY_OFFSET, 'day').valueOf()
        };
        this.ensureDataExists(props);
    }

    ensureDataExists(props: WeeklyUpdateComponentProps) {
        const { clientUpdates, id, all, pendingRequests } = props;
        if (!clientUpdates.has(id) && !pendingRequests.has(this.requestKey())) {
            this.props.fetchClientWeeklyUpdateData(
                id,
                moment().subtract(DATA_DAY_OFFSET, 'day').valueOf(),
                this.state.endDate,
                all
            );
        }
    }

    componentDidUpdate() {
        this.ensureDataExists(this.props);
    }

    handleDateChange = (startDate: number) => {
        const endDate = Date.now();
        this.setState({ startDate, endDate });
    };

    requestKey = () => {
        return `weekly-update-request-${this.props.id}`;
    };

    render() {
        const { clientUpdates, id, pendingRequests } = this.props;
        const data = clientUpdates.get(id);
        const inProgress = pendingRequests.has(this.requestKey());
        const clientName = data ? data.client.name : '';
        return (
            <DocumentTitle title={`Client Weekly Update - ${clientName}`}>
                <WeeklyUpdateEmailComponent
                    data={data}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    onDateChange={this.handleDateChange}
                    inProgress={inProgress}
                />
            </DocumentTitle>
        );
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    clientUpdates: state.clientWeeklyUpdates,
    pendingRequests: state.pendingRequests
});
const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    fetchClientWeeklyUpdateData
};

export const WeeklyUpdate = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(WeeklyUpdateComponent);
