import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Theme,
    Typography,
    useTheme
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { Pagination, Skeleton } from '@material-ui/lab';
import { times } from 'lodash';
import React from 'react';
import DocumentTitle from 'react-document-title';

import { standardizeUrl } from 'profile-parser';

import { fullDateTime } from '../common/timestamp';
import { Header } from '../components/header';
import { LightTooltip } from '../core-ui/light-tooltip';
import {
    LINKEDIN_SEARCH_RESULTS,
    LINKEDIN_SEARCHES,
    LinkedinSearch,
    SearchPerson,
    UPDATE_LINKEDIN_SEARCH_TAG
} from '../graphql/queries/search';
import { useSlides } from '../hooks/use-candidate-slides';
import { useModal } from '../hooks/use-modal';
import { useSnackbar } from '../hooks/use-snackbar';
import { Avatar } from '../sfc/avatar';

const styles = (theme: Theme) => css`
    background: #f4f6f8;
    flex: 1 1 auto;
    padding: 25px 50px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .searches-text-field {
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-end;

        .MuiTextField-root.search-field {
            min-width: 256px;
        }

        .search-field,
        > .MuiInputBase-root {
            margin-left: 15px;

            &:first-child {
                margin-left: 0;
            }

            .MuiOutlinedInput-input {
                padding-top: 14px;
                padding-bottom: 14px;
                background: white;
            }
        }
    }

    .searches-table {
        background: white;
        border: thin solid ${theme.palette.divider};
        border-radius: ${theme.shape.borderRadius}px;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
    }

    .results-pagination {
        display: flex;
        justify-content: center;
        padding: 10px;
        border-top: thin solid ${theme.palette.divider};
    }

    .table {
        .name-col {
            .MuiIconButton-root {
                margin-left: 20px;
                opacity: 0;
            }
        }

        tr:hover {
            background: ${theme.palette.action.hover};

            .name-col .MuiIconButton-root {
                opacity: 1;
            }
        }

        .MuiTableBody-root {
            .MuiTableRow-root {
                &:last-child {
                    .MuiTableCell-root {
                        border-bottom: none;
                    }
                }
            }
        }

        .clickable {
            cursor: pointer;
        }

        .result-avatars {
            display: flex;
            align-items: center;
            gap: 5px;

            .avatar-more {
                background: ${theme.palette.action.hover};
                font-weight: 500;
                border-radius: ${theme.shape.borderRadius}px;
                padding: 3px 10px;
                margin-left: 0px;
                text-align: center;
                min-width: 36px;
            }
        }
    }
`;

const tooltipStyle = css`
    padding: 4px 8px;
    font-size: 14px;
    line-height: 24px;
`;

const skeletonRowsCount = 10;
const columns = ['Tag', 'Count', 'Started At', 'Results'];
const avatarSize = 32;

const ResultAvatar: React.FC<{ result: SearchPerson; onClick: () => void }> = ({ result, onClick }) => {
    const tooltip = (
        <div css={tooltipStyle}>
            <div>
                <a href={`/person/${result.person.id}`} target="_blank">
                    {result.person.name.full}
                </a>
            </div>
            <div>
                <a href={`https://${result.url}`} target="_blank">
                    {result.url}
                </a>
            </div>
        </div>
    );
    return (
        <LightTooltip title={tooltip} interactive={true}>
            <span className="clickable" onClick={onClick}>
                <Avatar entity={result.person} size={avatarSize} key={result.person.id} />
            </span>
        </LightTooltip>
    );
};

const pageSize = 25;

export const LinkedInSearches: React.FC<{ selected: string }> = ({ selected }) => {
    const theme = useTheme();
    const [editing, setEditing] = React.useState<string>(null);
    const [page, setPage] = React.useState<number>(0);
    const [searchUrl, setSearchUrl] = React.useState<string>('');
    const [searchTag, setSearchTag] = React.useState<string>('');
    const [searchFieldInput, setSearchFieldInput] = React.useState<string>('');
    const [editedTag, setEditedTag] = React.useState<string>(null);
    const { data, refetch } = useQuery<
        { searches: LinkedinSearch[]; urls: SearchPerson[]; searchesCount: Array<{ result: number }> },
        { tag: string; profileUrl: string; limit: number; offset: number }
    >(LINKEDIN_SEARCHES, {
        variables: { tag: searchTag, profileUrl: searchUrl, limit: pageSize, offset: page * pageSize }
    });
    const [fetchResultPersonIds] = useLazyQuery<{ results: Array<{ personId: string }> }, { tag: string }>(
        LINKEDIN_SEARCH_RESULTS
    );
    const [updateTag] = useMutation<{}, { oldTag: string; newTag: string }>(UPDATE_LINKEDIN_SEARCH_TAG);
    const { setList } = useSlides();
    const { setSnackbar } = useSnackbar();
    const { getConfirmation } = useModal();

    const handleSelectSearch = (tag: string, initialPersonId: string) => async () => {
        const { data: results } = await fetchResultPersonIds({ variables: { tag } });
        // sort the searched personId to front
        const urlPersonId = data.urls?.[0]?.person?.id;
        const personIds = urlPersonId
            ? [{ personId: urlPersonId }, ...results.results.filter((result) => result.personId !== urlPersonId)]
            : results.results;
        setList(personIds, { personId: initialPersonId ?? personIds[0]?.personId });
    };

    React.useEffect(() => {
        if (selected) {
            handleSelectSearch(selected, null)();
        }
    }, [selected]);

    const handleEdit = (tag: string) => () => {
        setEditing(tag);
        setEditedTag(tag);
    };

    const handleCancelEditing = () => {
        setEditing(null);
        setEditedTag(null);
    };

    const handleEditedChange = (event: React.ChangeEvent<{ value: string }>) => {
        setEditedTag(event.target.value);
    };

    const handleSaveUpdatedTagConfirmed = async () => {
        setSnackbar('Updating tag');
        await updateTag({ variables: { oldTag: editing, newTag: editedTag } });
        refetch();
        const handleUndo = async () => {
            await updateTag({ variables: { oldTag: editedTag, newTag: editing } });
            setSnackbar('Tag restored');
            refetch();
        };
        const undoButton = (
            <Button style={{ color: 'white' }} onClick={handleUndo}>
                Undo
            </Button>
        );
        setSnackbar('Tag updated', undoButton);
        setEditing(null);
        setEditedTag(null);
    };

    const handleSaveUpdatedTag = () => {
        getConfirmation(
            handleSaveUpdatedTagConfirmed,
            'Please only update tags if you are sure this is a search you ran on linkedin,' +
                ' if needed review profiles by clicking on results to verify these profiles match your search',
            'Are you sure you want to update this tag?'
        );
    };

    const handleSearchFieldChange = (event: React.ChangeEvent<{ value: string }>) => {
        setSearchFieldInput(event.target.value);
    };

    const handleSearchFieldKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            if (searchFieldInput?.trim() === '') {
                setSearchTag('');
                setSearchUrl('');
            } else {
                const profileUrl = standardizeUrl(searchFieldInput?.trim());
                if (profileUrl?.match(/www\.linkedin\.com\/in/)) {
                    setSearchFieldInput(profileUrl);
                    setSearchUrl(profileUrl);
                } else {
                    setSearchTag(searchFieldInput);
                }
            }
        }
    };

    const handleChangePage = (_1: any, newPage: number) => setPage(newPage - 1);

    const skeletonRows = times(skeletonRowsCount).map((i) => (
        <TableRow key={i}>
            <TableCell colSpan={columns.length}>
                <Skeleton variant="rect" />
            </TableCell>
        </TableRow>
    ));

    const rows = data?.searches.map((search) => {
        const maxResults = 5;
        const results = data?.urls?.[0]?.person?.id
            ? [data.urls[0], ...search?.results.filter((r) => r.person.id !== data?.urls?.[0]?.person?.id)]
            : search?.results;
        const samples = results
            .slice(0, maxResults)
            .map((result) => (
                <ResultAvatar
                    result={result}
                    key={result.person.id}
                    onClick={handleSelectSearch(search.tag, result.person.id)}
                />
            ));
        const remaining = search.count - search.results.length;
        if (remaining > 0) {
            samples.push(
                <div key="remaining" className="avatar-more clickable" onClick={handleSelectSearch(search.tag, null)}>
                    +{remaining}
                </div>
            );
        }
        return (
            <TableRow key={search.tag}>
                <TableCell>
                    <span className="name-col">
                        {search.tag}
                        <IconButton size="small" onClick={handleEdit(search.tag)}>
                            <Edit fontSize="small" />
                        </IconButton>
                    </span>
                </TableCell>
                <TableCell>{search.count}</TableCell>
                <TableCell>{fullDateTime(search.results_aggregate.aggregate.min.viewedAt)}</TableCell>
                <TableCell>
                    <div className="result-avatars">{samples}</div>
                </TableCell>
            </TableRow>
        );
    });

    const headers = columns.map((column) => <TableCell key={column}>{column}</TableCell>);

    const editTagDialog = editing ? (
        <Dialog open={true} onClose={handleCancelEditing} maxWidth="sm" fullWidth={true}>
            <DialogTitle>
                <Typography variant="h4">Edit Tag</Typography>
            </DialogTitle>
            <DialogContent>
                <TextField label="Tag" value={editedTag} onChange={handleEditedChange} fullWidth={true} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancelEditing}>Cancel</Button>
                <Button onClick={handleSaveUpdatedTag}>Save</Button>
            </DialogActions>
        </Dialog>
    ) : null;

    const pages = Math.ceil(data?.searchesCount?.[0]?.result / pageSize);
    const pagination =
        pages > 1 ? (
            <div className="results-pagination">
                <Pagination count={pages} page={page + 1} onChange={handleChangePage} className="pagination" />
            </div>
        ) : null;

    return (
        <DocumentTitle title="Linkedin Searches">
            <div id="container">
                <Header title="Linkedin Searches" />
                <div css={styles(theme)}>
                    <div className="searches-text-field">
                        <TextField
                            value={searchFieldInput}
                            onChange={handleSearchFieldChange}
                            onKeyPress={handleSearchFieldKeyPress}
                            className="search-field"
                            variant="outlined"
                            placeholder="Search by Profile URL or Tag"
                        />
                    </div>
                    <div className="searches-table">
                        <TableContainer className="table">
                            <Table stickyHeader={true}>
                                <TableHead>
                                    <TableRow>{headers}</TableRow>
                                </TableHead>
                                <TableBody>{rows ?? skeletonRows}</TableBody>
                            </Table>
                        </TableContainer>
                        {pagination}
                    </div>
                </div>
                {editTagDialog}
            </div>
        </DocumentTitle>
    );
};
