import { useLazyQuery, useMutation, useQuery, useSubscription } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Button,
    IconButton,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
    useTheme
} from '@material-ui/core';
import { LinkedIn, Menu } from '@material-ui/icons';
import React from 'react';

import { Pagination, Skeleton } from '@material-ui/lab';
import { times } from 'lodash';
import { timeFrom } from '../common/timestamp';
import { config } from '../config';
import {
    DELETE_PROFILE_UPDATE_FAILED,
    GET_PROFILES_LINKEDIN_KEYWORDS,
    MARK_INVALID_LINK,
    MARK_PROFILE_UPDATE_FAILED,
    Profile,
    PROFILES_PENDING_UPDATES,
    PROFILES_PROCESSED_TIMESTAMP
} from '../graphql/queries/profiles';
import { useCodeModal } from '../hooks/use-code-modal';
import { useModal } from '../hooks/use-modal';
import { useSnackbar } from '../hooks/use-snackbar';

const styles = (theme: Theme) => css`
    background: #f4f6f8;
    flex: 1 1 auto;
    padding: 25px 50px;
    overflow: hidden;
    display: flex;

    .table-wrapper {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        border-radius: ${theme.shape.borderRadius}px;
        border: thin solid ${theme.palette.divider};

        .MuiTableContainer-root {
            background: white;
        }

        .MuiTableBody-root {
            .MuiTableRow-root {
                &:last-child {
                    .MuiTableCell-root {
                        border-bottom: none;
                    }
                }
            }
        }

        .table-actions {
            .actual {
                opacity: 0;
                .MuiButton-root {
                    margin-right: 20px;
                }
            }

            &:hover {
                .placeholder {
                    display: block;
                    opacity: 0;
                    width: 0;
                    height: 0;
                    overflow: hidden;
                }

                .actual {
                    opacity: 1;
                }
            }
        }
    }

    .results-pagination {
        background: white;
        display: flex;
        justify-content: center;
        padding: 10px;
        border-top: thin solid ${theme.palette.divider};
        position: relative;

        .li-query-button {
            position: absolute;
            left: 15px;
        }
    }
`;

export const ProfileUpdatesNeeded = () => {
    const rowsPerPage = 50;
    const theme = useTheme();
    const [page, setPage] = React.useState(1);
    const { data, loading, refetch } = useQuery<
        { profiles: Profile[]; count: { aggregate: { count: number } } },
        { limit: number; offset: number }
    >(PROFILES_PENDING_UPDATES, {
        variables: { limit: rowsPerPage, offset: (page - 1) * rowsPerPage }
    });
    const [fetchProfileKeywords, { loading: fetchingKeywords }] = useLazyQuery<
        { keywords: string },
        { personIds: string[] }
    >(GET_PROFILES_LINKEDIN_KEYWORDS);
    const { data: timestampData } = useSubscription<{ timestamp: { aggregate: { max: { createdAt: number } } } }>(
        PROFILES_PROCESSED_TIMESTAMP
    );
    const [updateLink, { loading: markingInvalid }] = useMutation<{}, { url: string; invalid: boolean }>(
        MARK_INVALID_LINK
    );
    const [markFailed, { loading: markingFailed }] = useMutation<{}, { objects: Array<{ personId: string }> }>(
        MARK_PROFILE_UPDATE_FAILED
    );
    const [deleteMarkFailed] = useMutation<{}, { personId: string }>(DELETE_PROFILE_UPDATE_FAILED);
    const { getConfirmation, setAlert } = useModal();
    const { setSnackbar } = useSnackbar();
    const { showCodeModal } = useCodeModal();

    React.useEffect(() => {
        refetch();
    }, [timestampData?.timestamp?.aggregate?.max?.createdAt]);

    React.useEffect(() => {
        const missingUrlPersonIds = data?.profiles
            ?.filter((p) => p.person.urls.length === 0)
            .map((p) => ({ personId: p.person.id }));
        if (missingUrlPersonIds?.length) {
            markFailed({ variables: { objects: missingUrlPersonIds } }).then(() => {
                refetch();
            });
        }
    }, [data]);

    const handleInvalidLinkClick = (profile: Profile) => () => {
        getConfirmation(
            async () => {
                const url = profile.person.urls?.[0]?.url;
                setSnackbar('Marking Link as Invalid');
                await updateLink({ variables: { url, invalid: true } });
                refetch();
                const undoAction = async () => {
                    setSnackbar('Link Marked as Valid');
                    await updateLink({ variables: { url, invalid: false } });
                    if (profile.person.urls.length === 1) {
                        // undo the creation of profile_update_failed record
                        await deleteMarkFailed({ variables: { personId: profile.person.id } });
                    }
                    refetch();
                };
                const undo = (
                    <Button style={{ color: 'white' }} onClick={undoAction}>
                        Undo
                    </Button>
                );
                setSnackbar('Link Marked as Invalid', undo);
            },
            'Are you sure you want to mark this link as invalid? Only do this if the link does not load a Linkedin Profile Page.',
            'Mark Invalid Link'
        );
    };

    const handleMarkFailed = (profile: Profile) => () => {
        getConfirmation(
            async () => {
                setSnackbar('Marking unable to refresh');
                await markFailed({ variables: { objects: [{ personId: profile.person.id }] } });
                refetch();
                const undoAction = async () => {
                    setSnackbar('Profile un-marked as unable to refresh');
                    await deleteMarkFailed({ variables: { personId: profile.person.id } });
                    refetch();
                };
                const undo = (
                    <Button style={{ color: 'white' }} onClick={undoAction}>
                        Undo
                    </Button>
                );
                setSnackbar('Profile marked as unable to refresh', undo);
            },
            'Are you sure you want to mark this as unable to refresh? Only do this if you have refreshed the profile using the link and this row is still showing up.',
            'Mark unable to Refresh'
        );
    };

    const handleChangePage = (_1: any, newPage: number) => setPage(newPage);

    const handleGetLinkedinKeywords = async () => {
        const personIds = data?.profiles.map((p) => p.person.id);
        if (personIds.length) {
            setAlert(
                'Fetching keywords...',
                <div>
                    <LinearProgress variant="indeterminate" />
                </div>,
                true
            );
            const result = await fetchProfileKeywords({ variables: { personIds } });
            showCodeModal('Keywords', result?.data?.keywords);
        }
    };

    const columns = ['Name', 'URL', 'Pending Since', ''];

    const skeletonRows = times(rowsPerPage).map((i) => (
        <TableRow key={i}>
            <TableCell colSpan={columns.length}>
                <Skeleton variant="rect" />
            </TableCell>
        </TableRow>
    ));

    const rows =
        !data?.profiles && loading
            ? skeletonRows
            : data?.profiles.map((profile) => {
                  const url = profile.person.urls?.[0]?.url;
                  return (
                      <TableRow key={profile.id}>
                          <TableCell>
                              <a
                                  href={`${config.AppUrl}/person/${profile.person.id}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                              >
                                  {profile.person.name}
                              </a>
                          </TableCell>
                          <TableCell>
                              <a href={`https://${url}`} target="_blank" rel="noopener noreferrer">
                                  {url}
                              </a>
                          </TableCell>
                          <TableCell>{timeFrom(profile.createdAt)}</TableCell>
                          <TableCell className="table-actions">
                              <span className="placeholder">
                                  <IconButton size="small">
                                      <Menu fontSize="small" />
                                  </IconButton>
                              </span>
                              <span className="actual">
                                  <Button
                                      size="small"
                                      onClick={handleInvalidLinkClick(profile)}
                                      disabled={!url || markingInvalid}
                                  >
                                      Mark Invalid Link
                                  </Button>
                                  <Button size="small" onClick={handleMarkFailed(profile)} disabled={markingFailed}>
                                      Mark Unable to Refresh
                                  </Button>
                              </span>
                          </TableCell>
                      </TableRow>
                  );
              });

    const headerRow = columns.map((column) => <TableCell key={column}>{column}</TableCell>);

    const pages = Math.ceil(data?.count?.aggregate?.count / rowsPerPage);
    const pagination =
        pages > 1 ? <Pagination count={pages} page={page} onChange={handleChangePage} className="pagination" /> : null;

    return (
        <div css={styles(theme)}>
            <div className="table-wrapper">
                <TableContainer>
                    <Table stickyHeader={true}>
                        <TableHead>
                            <TableRow>{headerRow}</TableRow>
                        </TableHead>
                        <TableBody>{rows}</TableBody>
                    </Table>
                </TableContainer>
                <div className="results-pagination">
                    <div className="li-query-button">
                        <Button
                            startIcon={<LinkedIn />}
                            size="small"
                            onClick={handleGetLinkedinKeywords}
                            disabled={fetchingKeywords}
                        >
                            Keywords
                        </Button>
                    </div>
                    {pagination}
                </div>
            </div>
        </div>
    );
};
