import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ClientMeetings } from '../components/client-meetings';

interface RouterProps {
    id: string;
}

const ClientMeetingsPageComponent: React.FC<RouteComponentProps<RouterProps>> = ({ match }) => (
    <ClientMeetings id={match.params.id} />
);

export const ClientMeetingsPage = withRouter(ClientMeetingsPageComponent);
