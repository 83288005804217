import * as React from 'react';
import DocumentTitle from 'react-document-title';

import { BillingPageHeader } from '../components/billing-page-header';
import { MonthlyFees } from '../components/monthly-fees';

export const MonthlyFeesPage: React.FC = () => (
    <DocumentTitle title="Monthly Fees">
        <div id="container">
            <BillingPageHeader />
            <div id="content" className="flex-fill">
                <MonthlyFees />
            </div>
        </div>
    </DocumentTitle>
);
