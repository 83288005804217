import { css } from '@emotion/core';
import { Tooltip } from '@material-ui/core';
import { OrderedMap } from 'immutable';
import * as _ from 'lodash';
import { Paper } from 'material-ui';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { JobStatus } from 'shared/models/job';
import { getDisqualReasonLabel, JobStageData } from 'shared/models/job-stages';

import { fullDate } from '../common/timestamp';
import { JobTitle } from '../containers/job-title';
import { useSlides } from '../hooks/use-candidate-slides';
import { Candidate, Job, State } from '../state';

const styles = css`
    .see-more {
        cursor: pointer;
        font-size: 14px;
        margin-top: 10px;
        width: 100%;
        text-align: center;
        text-transform: uppercase;
    }
`;

interface OwnProps {
    candidates: Candidate[];
    currentJobId: string;
}

interface ConnectedProps {
    jobs: OrderedMap<string, Job>;
    jobStages: JobStageData[];
}

type PersonCandidaciesProps = OwnProps & ConnectedProps;

const PersonCandidaciesComponent: React.FC<PersonCandidaciesProps> = (props) => {
    const { setList, getList } = useSlides();
    const [seeMoreJobsProgress, setSeeMoreJobsProgress] = React.useState(false);

    const lastCandidateActivity = (c: Candidate) =>
        Math.max(c.lastEmailExchangedAt, c.lastPhoneCallAt, c.lastStageChangedAt, c.lastTextMessageAt);

    if (!props.candidates || props.candidates.length === 0) return null;

    const candidates = _.orderBy(
        props.candidates.filter((c) => c.jobId !== props.currentJobId),
        [
            (c) => props.jobStages.find((stage) => stage.name === c.stage).id,
            (c) => props.jobs.get(c.jobId).status,
            'disqualified',
            lastCandidateActivity
        ],
        ['desc', 'asc', 'asc', 'desc']
    );

    if (candidates.length === 0) return null;

    const handleClick = (jobId: string, personId: string) => (e: React.MouseEvent) => {
        e.preventDefault();
        const { list, active } = getList();
        if (!list) {
            setList([{ personId, jobId }], { personId, jobId });
        } else {
            const index = list.findIndex(
                (c) => c.personId === props.candidates[0].personId && c.jobId === props.currentJobId
            );
            const updatedSelected = { ...active, personId, jobId };
            const updatedList = list.slice(0, index).concat([updatedSelected], list.slice(index + 1));
            setList(updatedList, updatedSelected);
        }
    };

    const visibleItems = 10;
    const jobsProgress = candidates.slice(0, seeMoreJobsProgress ? candidates.length : visibleItems).map((c) => {
        const job = props.jobs.get(c.jobId);
        const jobStatusIcon =
            job.status === JobStatus.Archived ? (
                <Tooltip title="Job Archived">
                    <i className="material-icons job-status-icon">archive</i>
                </Tooltip>
            ) : job.status === JobStatus.Paused ? (
                <Tooltip title="Job Paused">
                    <i className="material-icons job-status-icon">pause_circle_filled</i>
                </Tooltip>
            ) : null;
        const inActive = c.disqualified || job.status !== JobStatus.Active;
        const disqualReasonLabel = c.disqualified ? (
            <div className="job-candidate-status">
                <i className="material-icons">block</i>
                {getDisqualReasonLabel(c.disqualReason)}
            </div>
        ) : null;
        return (
            <div
                className={`person-candidacy ${inActive ? 'disqualified' : ''}`}
                key={`${c.jobId}-${c.disqualified}-${c.stage}`}
            >
                <Link to={`/job/${c.jobId}/board/candidate/${c.personId}`} onClick={handleClick(c.jobId, c.personId)}>
                    <div className="job-title">
                        <i className="material-icons list-icon">work</i>
                        <JobTitle id={c.jobId} /> {jobStatusIcon}
                    </div>
                    <div className="job-stage">{props.jobStages.find((s) => s.name === c.stage).label}</div>
                    <div className="job-last-stage-change">
                        <Tooltip title="Last Activity At">
                            <span>{fullDate(lastCandidateActivity(c))}</span>
                        </Tooltip>
                    </div>
                    {disqualReasonLabel}
                </Link>
            </div>
        );
    });

    const handleSeeMoreJobsProgress = () => {
        setSeeMoreJobsProgress(!seeMoreJobsProgress);
    };

    const seeMore =
        candidates.length > visibleItems ? (
            <div className="see-more" onClick={handleSeeMoreJobsProgress}>
                {seeMoreJobsProgress ? 'See less' : `See ${candidates.length - visibleItems} more`}
            </div>
        ) : null;

    return (
        <Paper css={styles} className="rightbar-card person-candidacies">
            {jobsProgress}
            {seeMore}
        </Paper>
    );
};

const mapStateToProps = (state: State): ConnectedProps => ({
    jobStages: state.appConstants.constants.jobStages,
    jobs: state.jobs
});

export const PersonCandidacies = connect<ConnectedProps, undefined, OwnProps>(mapStateToProps)(
    PersonCandidaciesComponent
);
