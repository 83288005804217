import { useQuery } from '@apollo/client';
import React from 'react';

import { CBIndustryGroups, CRUNCHBASE_INDUSTRY_GROUPS } from '../../graphql/queries/crunchbase';
import { MultiSelectForm } from './multi-select-form';

export const IndustryGroupsForm: React.FC<{
    readonly: boolean;
    filters: string[];
    onChange: (val: string[]) => void;
}> = ({ readonly, filters, onChange }) => {
    const { data: optionsData } = useQuery<{ industryGroups: CBIndustryGroups[] }>(CRUNCHBASE_INDUSTRY_GROUPS);

    const options = optionsData?.industryGroups.map((ig) => ig.group) ?? [];

    return (
        <MultiSelectForm
            readonly={readonly}
            filters={filters}
            label="Industry Groups"
            options={options}
            onChange={onChange}
        />
    );
};
