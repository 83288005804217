import React from 'react';
import DocumentTitle from 'react-document-title';

import { Header } from '../components/header';
import { CompanyTalent } from '../components/talent-intelligence/company-talent';

export const CompanyTalentPage = () => (
    <DocumentTitle title="Talent Intelligence">
        <div id="container">
            <Header title="Talent Intelligence" />
            <div id="content" className="flex-fill">
                <CompanyTalent />
            </div>
        </div>
    </DocumentTitle>
);
