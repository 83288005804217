import { gql } from '@apollo/client';

import { PhoneCallTranscript } from 'shared/models/phone-call';
import { CallTextParticipant } from 'shared/models/phone-number';
import { TextMessageAttachment } from 'shared/models/text-message';

export interface PhoneCall {
    id: string;
    callId: string;
    caller: CallTextParticipant;
    callee: CallTextParticipant;
    direction: 'In' | 'Out' | 'Voicemail';
    result: string;
    phoneNumber: string;
    user: {
        id: string;
        name: string;
    };
    timestamp: number;
    recordingLink: string;
    transcript: PhoneCallTranscript;
    duration: number;
    personIds: string[];
    jobIds: string[];
}

export interface TextMessage {
    messageId: string;
    message: string;
    sender: CallTextParticipant;
    recipients: CallTextParticipant[];
    direction: 'In' | 'Out';
    attachments: TextMessageAttachment[];
    timestamp: number;
}

export interface TextSession {
    id: string;
    sessionId: string;
    phoneNumber: string;
    messages: TextMessage[];
    user: {
        id: string;
        name: string;
    };
    personIds: string[];
    jobIds: string[];
}

export const PHONE_CALLS = gql`
    query calls($personId: String!) {
        calls: phone_calls(where: { personIds: { _has_key: $personId } }, order_by: { timestamp: desc }) {
            id
            caller
            callee
            result
            direction
            user {
                id
                name(path: "full")
            }
            timestamp
            recordingLink
            transcript
            duration
            personIds
            jobIds
        }
    }
`;

export const LATEST_PHONE_CALL = gql`
    subscription latestPhoneCall($personId: String!) {
        calls: phone_calls(where: { personIds: { _has_key: $personId } }, order_by: { timestamp: desc }, limit: 1) {
            timestamp
        }
    }
`;

export const TEXT_SESSIONS = gql`
    query texts($personId: String!) {
        texts: text_message_sessions(where: { personIds: { _has_key: $personId } }) {
            _id: sessionId
            sessionId
            personIds
            jobIds
            phoneNumber
            messages(order_by: { timestamp: desc }) {
                messageId
                message
                sender
                recipients
                direction
                attachments
                timestamp
            }
            user {
                id
                name(path: "full")
            }
        }
    }
`;

export const LATEST_TEXT_MESSAGE = gql`
    subscription latestTextSession($personId: String!) {
        texts: text_messages(where: { personIds: { _has_key: $personId } }, order_by: { timestamp: desc }, limit: 1) {
            timestamp
        }
    }
`;
