import { Map, OrderedSet } from 'immutable';

import { NoteView } from 'shared/models/note';

import { Action, ReceiveNewNote, ReceiveNotes, ReceivePersonOptOutUpdate, RequestNoteDelete } from '../actions';
import { ReceiveScheduledDisqualificationMail, ReceiveSentDisqualificationMail } from '../email-compose/actions';

const initialState: Map<string, OrderedSet<NoteView>> = Map();

export function notes(state = initialState, action: Action) {
    switch (action.type) {
        case ReceiveNewNote: {
            const n: OrderedSet<NoteView> = state.get(action.payload.note.notable, OrderedSet());
            const otherNotes = n.filter((v) => v.id !== action.payload.note.id);
            return state.set(action.payload.note.notable, OrderedSet([action.payload.note]).concat(otherNotes));
        }
        case ReceiveNotes: {
            return state.set(action.payload.notable, OrderedSet(action.payload.notes));
        }
        case ReceiveScheduledDisqualificationMail:
        case ReceiveSentDisqualificationMail:
        case ReceivePersonOptOutUpdate: {
            if (action.payload.note) {
                const n: OrderedSet<NoteView> = state.get(action.payload.note.notable, OrderedSet());
                return state.set(action.payload.note.notable, OrderedSet([action.payload.note]).concat(n));
            } else {
                return state;
            }
        }
        case RequestNoteDelete: {
            const notable = action.payload.note.notable;
            const notableNotes = state.get(notable).map((n) => {
                if (n.id === action.payload.note.id) {
                    n.deleted = true;
                }
                return n;
            });
            return state.set(notable, notableNotes);
        }
        default:
            return state;
    }
}
