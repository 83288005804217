import { Button, Menu, MenuItem } from '@material-ui/core';
import React from 'react';

import { PersonRecord } from '../graphql/queries/candidates-search';
import { CandidatesSearchAdd } from './candidates-search-add';
import { CandidatesSearchNoteAdd } from './candidates-search-note-add';

interface CandidatesSearchAddActionsProps {
    disabled: boolean;
    persons: PersonRecord[];
    dialogClose: (value: 'notes' | 'jobs') => void;
}

export const CandidatesSearchAddActions: React.FC<CandidatesSearchAddActionsProps> = (props) => {
    const { persons, disabled, dialogClose } = props;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [addNotes, setAddNotes] = React.useState<boolean>(false);
    const [addToJobs, setAddToJobs] = React.useState<boolean>(false);

    const handleAddNotes = () => {
        setAnchorEl(null);
        setAddNotes(true);
    };

    const handleAddToJobs = () => {
        setAnchorEl(null);
        setAddToJobs(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAdd = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleAddNoteClose = () => {
        setAddNotes(false);
        dialogClose('notes');
    };

    const handleAddNoteCancel = () => {
        setAddNotes(false);
    };

    const handleAddToJobsClose = () => {
        setAddToJobs(false);
        dialogClose('jobs');
    };

    const handleAddToJobsCancel = () => {
        setAddToJobs(false);
    };

    const addNotesDialog = addNotes ? (
        <CandidatesSearchNoteAdd persons={persons} onClose={handleAddNoteClose} onCancel={handleAddNoteCancel} />
    ) : null;

    const addToJobDialog = addToJobs ? (
        <CandidatesSearchAdd
            personIds={persons.map((p) => p.person.id)}
            onClose={handleAddToJobsClose}
            onCancel={handleAddToJobsCancel}
        />
    ) : null;

    return (
        <React.Fragment>
            <Button variant="outlined" disabled={disabled} onClick={handleAdd}>
                ADD
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted={true}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleAddToJobs}>Add to Sourced stage for a Job</MenuItem>
                <MenuItem onClick={handleAddNotes}>Add a note to recent assignees</MenuItem>
            </Menu>
            {addNotesDialog}
            {addToJobDialog}
        </React.Fragment>
    );
};
