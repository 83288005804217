import { useQuery } from '@apollo/client';
import _ from 'lodash';
import React from 'react';

import { CBFundingRounds, CRUNCHBASE_FUNDING_ROUNDS } from '../../graphql/queries/crunchbase';
import { MultiSelectForm } from './multi-select-form';

export const FundingRoundsForm: React.FC<{
    readonly: boolean;
    filters: string[];
    onChange: (val: string[]) => void;
}> = ({ readonly, filters, onChange }) => {
    const { data: optionsData } = useQuery<{ rounds: CBFundingRounds[] }>(CRUNCHBASE_FUNDING_ROUNDS);

    const options = _.orderBy(
        optionsData?.rounds.map((r) => r.round) ?? [],
        [
            (r) => r.match(/^ipo/i),
            (r) => r.match(/^acquired/i),
            (r) => r.match(/^pre_seed/i),
            (r) => r.match(/^seed/i),
            (r) => r.match(/^series/i),
            (r) => r
        ],
        ['asc', 'asc', 'asc', 'asc', 'asc', 'asc']
    );

    const renderOption = (option: string) => _.startCase(option)?.replace(/\bipo\b/gi, 'IPO');

    return (
        <MultiSelectForm
            readonly={readonly}
            filters={filters}
            label="Latest Funding Round"
            options={options}
            onChange={onChange}
            renderOption={renderOption}
        />
    );
};
