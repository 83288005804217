import { css } from '@emotion/core';
import { MenuItem, MenuList, Popover, Tab, Tabs, Tooltip } from '@material-ui/core';
import { Map, OrderedMap } from 'immutable';
import { flatMap, orderBy } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';

import { interpolateEmailTemplate } from 'shared/common/interpolate-template';
import { EmailTemplateView } from 'shared/models/email-templates';
import { UserData } from 'shared/models/user';
import { EmailAddress } from 'shared/types/email-compose';

import { getEmailTemplates } from '../../actions';
import { zIndexes } from '../../common/css-variables';
import { Client, EmailAccount, Job, List, Person, PersonDetails, State } from '../../state';

interface ConnectedProps {
    emailAccounts: Map<string, EmailAccount>;
    emailTemplates: EmailTemplateView[];
    persons: List<Person>;
    clients: List<Client>;
    personsDetails: List<PersonDetails>;
    jobs: OrderedMap<string, Job>;
    user: UserData;
}

interface ConnectedDispatch {
    getEmailTemplates: (group: string) => void;
}

interface OwnProps {
    context: {
        account: EmailAddress;
        to: EmailAddress[];
        cc: EmailAddress[];
        bcc: EmailAddress[];
        personId: string;
        jobId: string;
    };
    onSelect: (template: EmailTemplateView) => void;
}

type EmailComposeInsertTemplateProps = ConnectedProps & ConnectedDispatch & OwnProps;

interface EmailComposeInsertTemplateState {
    open: boolean;
    anchor: Element;
    selectedTab: 'Shared' | 'Personal';
}

const templatesGroup = 'generic';

const styles = css`
    .email-compose-templates-selector {
        height: 300px;
        width: 350px;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .MuiTab-root {
            flex: 1 1 auto;
        }

        .MuiListItem-root {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            display: block;
        }

        .email-compose-templates-selector-tabs {
            flex: 0 0 auto;
        }

        .email-compose-templates-list {
            flex: 1 1 auto;
            overflow-y: auto;
            padding: 10px 0;
        }
    }
`;

class EmailComposeInsertTemplateComponent extends React.Component<
    EmailComposeInsertTemplateProps,
    EmailComposeInsertTemplateState
> {
    constructor(props: EmailComposeInsertTemplateProps) {
        super(props);
        this.state = { open: false, anchor: null, selectedTab: 'Shared' };
        if (props.emailTemplates.filter((t) => t.group === templatesGroup).length === 0) {
            props.getEmailTemplates(templatesGroup);
        }
    }

    handleOpenDialog = (e: React.MouseEvent) => {
        this.setState({ open: true, anchor: e.currentTarget });
    };

    handleCloseDialog = () => {
        this.setState({ open: false });
    };

    handleAddTemplate = (t: EmailTemplateView) => () => {
        const {
            user,
            context: { personId, account, jobId }
        } = this.props;
        const { emailAccounts, persons, clients, jobs, personsDetails } = this.props;
        const person = persons.list.get(personId);
        const job = jobs.get(jobId);
        const client = job ? clients.list.get(job.clientId) : null;
        const senderAccount = emailAccounts.get(account.address);
        const details = personsDetails.list.get(personId);
        let templateData = { senderAccount, client, person, job };
        if (details) {
            const { profile, profileUrls, contacts } = details;
            templateData = Object.assign({}, templateData, { personContacts: contacts, profile, profileUrls, user });
        }

        const interpolatedTemplate = Object.assign({}, t, {
            body: interpolateEmailTemplate(t.body, templateData),
            subject: interpolateEmailTemplate(t.subject, templateData)
        });
        this.props.onSelect(interpolatedTemplate);
        this.setState({ open: false });
    };

    handleTabChange = (_1: React.ChangeEvent<{}>, selectedTab: 'Shared' | 'Personal') => {
        this.setState({ selectedTab });
    };

    render() {
        const { anchor, open, selectedTab } = this.state;
        const { context, emailAccounts, emailTemplates, user } = this.props;
        const disabled =
            ((!context.account || !context.account.name) && emailAccounts.isEmpty()) ||
            emailTemplates.filter((t) => t.group === templatesGroup).length === 0;
        const templatesList = disabled
            ? null
            : orderBy(
                  emailTemplates
                      .filter((t) => t.group === templatesGroup)
                      .filter((t) => {
                          if (selectedTab === 'Personal') {
                              return t.userId === user.id;
                          } else {
                              return !t.userId;
                          }
                      }),
                  ['kind', 'id']
              ).map((t) => (
                  <MenuItem dense={true} key={t.id} onClick={this.handleAddTemplate(t)}>
                      {t.kind}
                  </MenuItem>
              ));
        const selector = (
            <Popover
                open={open}
                onClose={this.handleCloseDialog}
                anchorEl={anchor}
                transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                style={{ zIndex: zIndexes.overEmailDialog }}
                css={styles}
            >
                <div className="email-compose-templates-selector">
                    <div className="email-compose-templates-selector-tabs">
                        <Tabs value={selectedTab} onChange={this.handleTabChange}>
                            <Tab label="Shared" value="Shared" />
                            <Tab label="Personal" value="Personal" />
                        </Tabs>
                    </div>
                    <div className="email-compose-templates-list">
                        <MenuList>{templatesList}</MenuList>
                    </div>
                </div>
            </Popover>
        );
        return (
            <div className="email-template-insert">
                <Tooltip title="Insert Template">
                    <button
                        className={`insert-template-button ${disabled ? 'disabled' : ''}`}
                        onClick={this.handleOpenDialog}
                        disabled={disabled}
                    >
                        <i className="fas fa-file-import fa-flip-horizontal" />
                    </button>
                </Tooltip>
                {selector}
            </div>
        );
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    clients: state.clients,
    emailAccounts: state.emailAccounts,
    emailTemplates: flatMap(state.emailTemplates.valueSeq().toArray()),
    jobs: state.jobs,
    persons: state.persons,
    personsDetails: state.personsDetails,
    user: state.session.user
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    getEmailTemplates
};

export const EmailComposeInsertTemplate = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(EmailComposeInsertTemplateComponent);
