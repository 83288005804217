import { css } from '@emotion/core';
import React from 'react';

import { TextfieldWithChips } from '../core-ui/textfield-with-chips';
import { useLinkedinSearchTags } from '../hooks/use-linkedin-search-tags';
import { renderOption } from './search-autocomplete-option';
import { SearchRequirementsSection } from './search-requirements-section';
import { useSearch } from './use-search';

const styles = css`
    margin-bottom: 20px;
`;

export const SearchConfigLinkedinSearch: React.FC = () => {
    const searchContext = useSearch();
    const config = searchContext.data.config.linkedinSearch;
    const onChange = searchContext.onConfigFieldChange('linkedinSearch');
    const readonly = searchContext.readonly;

    const [inputValue, setInputValue] = React.useState<string>('');
    const { tags, loading } = useLinkedinSearchTags(inputValue);

    const handleChange = (val: Array<{ value: string }>) => {
        onChange({ tags: val.map((v) => v.value) });
    };

    const getChipFromString = (text: string) => ({ value: text });
    const getStringFromChip = (chip: { value: string }) => chip.value;

    const chips = config?.tags?.map((tag) => ({ value: tag })) ?? [];
    const options = tags?.map((tag) => ({ value: tag.tag, count: tag.count })) ?? [];

    return (
        <SearchRequirementsSection section="linkedin-search">
            <div css={styles}>
                <TextfieldWithChips
                    readonly={readonly}
                    chips={chips}
                    options={options}
                    hintText="Add Linkedin Search Tag"
                    onChange={handleChange}
                    getChipFromString={getChipFromString}
                    getStringFromChip={getStringFromChip}
                    onInputChange={setInputValue}
                    renderOption={renderOption}
                    loading={loading}
                />
            </div>
        </SearchRequirementsSection>
    );
};
