import * as React from 'react';
import DocumentTitle from 'react-document-title';

import { Header } from '../components/header';
import { NewJob } from '../containers/job-new';

export const NewJobPage = () => (
    <DocumentTitle title="New Job">
        <div id="container">
            <Header title="New Job" />
            <div id="content" className="flex-fill">
                <NewJob />
            </div>
        </div>
    </DocumentTitle>
);
