import { saveAs } from 'file-saver';
import { isEqual } from 'lodash';
import { Dispatch } from 'redux';

import { RetryableParsingErrors, standardizeUrl } from 'profile-parser';
import { CandidateSummary } from 'shared/models/candidate';
import { ContactChannel } from 'shared/models/contact';
import { EmailTemplateView } from 'shared/models/email-templates';
import { InmailSendClientData } from 'shared/models/inmail-send';
import { ExtensionJobData } from 'shared/models/job';
import { awaitingClientStage } from 'shared/models/job-stages';
import { NoteView } from 'shared/models/note';
import { ScheduledMessageView } from 'shared/models/scheduled-messages';
import { SearchProject, SearchResultsViewType } from 'shared/models/search';
import { SearchConfig } from 'shared/models/search';
import { SequenceVariables } from 'shared/models/sequence';
import { UserData, UserData as User } from 'shared/models/user';
import { AppConstant } from 'shared/types/app-constant';
import { CrunchbaseData } from 'shared/types/crunchbase';
import { FilePayload, HrefFilePayload } from 'shared/types/file-payload';

import * as Analytics from './analytics';
import * as api from './api';
import { signOut } from './auth';
import {
    getLocalStorageKey,
    removeLocalStorageKey,
    selectedJobIdKey,
    setLocalStorageKey
} from './common/local-storage';
import { logger } from './common/logger';
import { EmailComposeAction } from './email-compose/actions';
import { EmailAction } from './email-inbox/actions';
import { isHtmlProfileDifferent } from './extension/common';
import { requestHtmlPendingLoadCounts } from './extension/message-listeners';
import { requestPageData } from './extension/messaging';
import { monitorAppVersion } from './lib/app-version-monitor';
import { openTabsWithDelay } from './lib/tab-opener';
import {
    AccountManagerData,
    BlacklistCheck,
    Candidate,
    CandidateAssigneeData,
    Client,
    ClientUpdateData,
    Communication,
    Contact,
    ContactType,
    EmailAccount,
    EmailSyncStatus,
    ExtensionState,
    Job,
    JobAssigneeData,
    JobMetrics,
    JobSearchesState,
    JobSearchesStats,
    KanbanState,
    Person,
    PersonDetails,
    PersonFile,
    PersonSearchResults,
    Preferences,
    ProfileUrlRecord,
    RequestErrors,
    Search,
    SearchPreset,
    SearchResult,
    SearchResultsState,
    SearchStatus,
    Session,
    SimilarityScoreResult,
    State,
    UserAction
} from './state';
import { RawJsonProfile } from './types/li-json-profile';
import { Profile } from './types/profile';
// Strings representing action types
export const RequestSession = 'RequestSession';
export const RequestSignout = 'RequestSignout';
export const AppVersionOutdated = 'AppVersionOutdated';
export const RequestUserInfo = 'RequestUserInfo';
export const ReceiveUserInfo = 'ReceiveUserInfo';
export const CompleteSignout = 'CompleteSignout';
export const ReceiveSession = 'ReceiveSession';
export const RequestJobsList = 'RequestJobsList';
export const ReceiveJobsList = 'ReceiveJobsList';
export const RequestJobInfo = 'RequestJobInfo';
export const ReceiveJobInfo = 'ReceiveJobInfo';
export const RequestJobMetrics = 'RequestJobMetrics';
export const ReceiveJobMetrics = 'ReceiveJobMetrics';
export const RequestCandidate = 'RequestCandidate';
export const ReceiveCandidate = 'ReceiveCandidate';
export const RequestNewJob = 'RequestNewJob';
export const ReceiveNewJob = 'ReceiveNewJob';
export const RequestEmailTemplates = 'RequestEmailTemplates';
export const ReceiveEmailTemplates = 'ReceiveEmailTemplates';
export const RequestSaveEmailTemplate = 'RequestSaveEmailTemplate';
export const ReceiveUpdatedEmailTemplate = 'ReceiveUpdatedEmailTemplate';
export const RequestTemplateDelete = 'RequestTemplateDelete';
export const ReceiveTemplateDeleteConfirmation = 'ReceiveTemplateDeleteConfirmation';
export const RequestJobOnePager = 'RequestJobOnePager';
export const ReceiveJobOnePagerResult = 'ReceiveJobOnePagerResult';
export const RequestClientFiles = 'RequestClientFiles';
export const ReceiveClientFilesResult = 'ReceiveClientFilesResult';
export const RequestRemoveClientFile = 'RequestRemoveClientFile';
export const ReceiveRemoveClientFileResult = 'ReceiveRemoveClientFileResult';
export const ReceiveJobEmails = 'ReceiveJobEmails';
export const RequestEmailAccountCreate = 'RequestEmailAccountCreate';
export const RequestJobEmails = 'RequestJobEmails';
export const ReceiveEmailAccountCreated = 'ReceiveEmailAccountCreated';
export const RequestClientData = 'RequestClientData';
export const ReceiveClientData = 'ReceiveClientData';
export const RequestClientUpdate = 'RequestClientUpdate';
export const ReceiveClientUpdate = 'ReceiveClientUpdate';
export const RequestUploadClientPersonBlacklist = 'RequestUploadClientPersonBlacklist';
export const ReceiveUploadClientPersonBlacklist = 'ReceiveUploadClientPersonBlacklist';
export const RequestEmailCounts = 'RequestEmailCounts';
export const ReceiveEmailCounts = 'ReceiveEmailCounts';
export const RequestPersonsList = 'RequestPersonsList';
export const ReceivePersonsList = 'ReceivePersonsList';
export const RequestPersonContacts = 'RequestPersonContacts';
export const ReceivePersonContacts = 'ReceivePersonContacts';
export const RequestPersonDetails = 'RequestPersonDetails';
export const ReceivePersonDetails = 'ReceivePersonDetails';
export const RequestPersonJobScore = 'RequestPersonJobScore';
export const ReceivePersonJobScore = 'ReceivePersonJobScore';
export const RequestPersonJobLabels = 'RequestPersonJobLabels';
export const ReceivePersonJobLabels = 'ReceivePersonJobLabels';
export const RequestCreatePersonJobLabel = 'RequestCreatePersonJobLabel';
export const RequestClientList = 'RequestClientList';
export const ReceiveClientList = 'ReceiveClientList';
export const RequestNewClient = 'RequestNewClient';
export const ReceiveNewClient = 'ReceiveNewClient';
export const RequestToasterClose = 'RequestToasterClose';
export const RequestToasterOpen = 'RequestToasterOpen';
export const RequestAddCandidateToJob = 'RequestAddCandidateToJob';
export const ReceiveCandidateAddedToJob = 'ReceiveCandidateAddedToJob';
export const MoveCandidateToStage = 'MoveCandidateToStage';
export const ReceiveCandidateUpdate = 'ReceiveCandidateUpdate';
export const RequestCandidateDisqualify = 'RequestCandidateDisqualify';
export const RequestNewNote = 'RequestNewNote';
export const ReceiveNewNote = 'ReceiveNewNote';
export const ReceiveNoteError = 'ReceiveNoteError';
export const RequestNotes = 'RequestNotes';
export const ReceiveNotes = 'ReceiveNotes';
export const RequestNoteDelete = 'RequestNoteDelete';
export const RequestUserActions = 'RequestUserActions';
export const ReceiveUserActions = 'ReceiveUserActions';
export const RequestContactAdd = 'RequestContactAdd';
export const RequestPersonUpdate = 'RequestPersonUpdate';
export const ReceivePersonUpdate = 'ReceivePersonUpdate';
export const RequestPersonDoNotEmailAgain = 'RequestPersonDoNotEmailAgain';
export const RequestPersonNotInterestedAtThisTime = 'RequestPersonNotInterestedAtThisTime';
export const ReceivePersonOptOutUpdate = 'ReceivePersonOptOutUpdate';
export const RequestAddContactFromMessage = 'RequestAddContactFromMessage';
export const ReceiveAddContactFromMessage = 'ReceiveAddContactFromMessage';
export const RequestContactUpdate = 'RequestContactUpdate';
export const ReceiveContactsUpdate = 'ReceiveContactsUpdate';
export const RequestPersonFilesUpload = 'RequestPersonFilesUpload';
export const ReceivePersonFilesUploadResult = 'ReceivePersonFilesUploadResult';
export const RequestPersonSearch = 'RequestPersonSearch';
export const ReceivePersonSearchResult = 'ReceivePersonSearchResult';
export const RequestSendSequenceToPerson = 'RequestSendSequenceToPerson';
export const ReceiveSendSequenceToPerson = 'ReceiveSendSequenceToPerson';
export const RequestUsersList = 'RequestUsersList';
export const ReceiveUsersList = 'ReceiveUsersList';
export const RequestClientWeeklyUpdateData = 'RequestClientWeeklyUpdateData';
export const ReceiveClientWeeklyUpdateData = 'ReceiveClientWeeklyUpdateData';
export const UpdateUserPrefs = 'UpdateUserPrefs';
export const ReceiveMoveInGmail = 'ReceiveMoveInGmail';
export const RequestMoveInGmail = 'RequestMoveInGmail';
export const ArchiveMessage = 'ArchiveMessage';
export const RequestOutreachForJob = 'RequestOutreachForJob';
export const ReceiveInitialOutreachForJob = 'ReceiveInitialOutreachForJob';
export const RequestAllEmailAccountInfo = 'RequestAllEmailAccountInfo';
export const ReceiveAllEmailAccountInfo = 'ReceiveAllEmailAccountInfo';
export const RequestReassignEmailAccount = 'RequestReassignEmailAccount';
export const ReceiveReassignEmailAccounts = 'ReceiveReassignEmailAccounts';
export const RequestJobSearches = 'RequestJobSearches';
export const ReceiveJobSearches = 'ReceiveJobSearches';
export const RequestJobSearchStats = 'RequestJobSearchStats';
export const ReceiveJobSearchStats = 'ReceiveJobSearchStats';
export const RequestJobSearchForSourcing = 'RequestJobSearchForSourcing';
export const ReceiveJobSearchForSourcing = 'ReceiveJobSearchForSourcing';
export const RequestAddSearchResultToJob = 'RequestAddSearchResultToJob';
export const ReceiveAddSearchResultToJob = 'ReceiveAddSearchResultToJob';
export const ResetJobSourcingSearch = 'ResetJobSourcingSearch';
export const RequestSearchResultPersonAndCandidate = 'RequestSearchResultPersonAndCandidate';
export const ReceiveSearchResultPersonAndCandidate = 'ReceiveSearchResultPersonAndCandidate';
export const RequestSearchResults = 'RequestSearchResults';
export const ReceiveSearchResults = 'ReceiveSearchResults';
export const RequestSearchDelete = 'RequestSearchDelete';
export const RequestSearchUpdate = 'RequestSearchUpdate';
export const ReceiveUpdatedSearch = 'ReceiveUpdatedSearch';
export const RequestSearchProfiles = 'RequestSearchProfiles';
export const ReceiveSearchProfiles = 'ReceiveSearchProfiles';
export const RequestSearchCreate = 'RequestSearchCreate';
export const ReceiveNewSearch = 'ReceiveNewSearch';
export const RequestSearchPurge = 'RequestSearchPurge';
export const ReceiveSearchPurge = 'ReceiveSearchPurge';
export const RequestPurgeCandidates = 'RequestPurgeCandidates';
export const ReceivePurgeCandidatesResponse = 'ReceivePurgeCandidatesResponse';
export const RequestCrossAddCandidate = 'RequestCrossAddCandidate';
export const ReceiveCrossAddCandidate = 'ReceiveCrossAddCandidate';
export const RequestFileDownload = 'RequestFileDownload';
export const ReceiveFileDownload = 'ReceiveFileDownload';
export const ReceivePresets = 'ReceivePresets';
export const RequestPresets = 'RequestPresets';
export const RequestSavePreset = 'RequestSavePreset';
export const ReceivedSavePreset = 'ReceivedSavePreset';
export const ReceiveJobsSearchesStats = 'ReceiveJobsSearchesStats';
export const RequestJobsSearchesStats = 'RequestJobsSearchesStats';
export const RemoveUnsavedLocalSearches = 'RemoveUnsavedLocalSearches';
export const RequestPersonWebsiteAdd = 'RequestPersonWebsiteAdd';
export const ReceivePersonWebsiteAdd = 'ReceivePersonWebsiteAdd';
export const RequestFlaggedEmails = 'RequestFlaggedEmails';
export const ReceiveFlaggedEmails = 'ReceiveFlaggedEmails';
export const RequestPersonCommunications = 'RequestPersonCommunications';
export const ReceivePersonCommunications = 'ReceivePersonCommunications';
export const RequestFlaggedEmailAccounts = 'RequestFlaggedEmailAccounts';
export const ReceiveFlaggedEmailAccounts = 'ReceiveFlaggedEmailAccounts';
export const RequestCommunicationMatchDataUpdate = 'RequestCommunicationMatchDataUpdate';
export const ReceiveCommunicationMatchDataUpdate = 'ReceiveCommunicationMatchDataUpdate';
export const RequestCommunicationMatcher = 'RequestCommunicationMatcher';
export const ReceiveCommunicationMatcher = 'ReceiveCommunicationMatcher';
export const RequestForwardFlaggedEmail = 'RequestForwardFlaggedEmail';
export const ReceiveForwardFlaggedEmail = 'ReceiveForwardFlaggedEmail';
export const RequestAccountManagerData = 'RequestAccountManagerData';
export const ReceiveAccountManagerData = 'ReceiveAccountManagerData';
export const RequestJobCandidatesSummary = 'RequestJobCandidatesSummary';
export const ReceiveJobCandidatesSummary = 'ReceiveJobCandidatesSummary';
export const AddNoteAttachments = 'AddNoteAttachments';
export const RemoveNoteAttachments = 'RemoveNoteAttachments';
export const RequestUpdateJobPrimaryEmail = 'RequestUpdateJobPrimaryEmail';
export const ReceiveUpdateJobPrimaryEmail = 'ReceiveUpdateJobPrimaryEmail';
export const RequestJobAssigneeData = 'RequestJobAssigneeData';
export const ReceiveJobAssigneeData = 'ReceiveJobAssigneeData';
export const ReceiveAppConstant = 'ReceiveAppConstant';
export const RequestPersonSetBlacklisted = 'RequestPersonSetBlacklisted';
export const ReceivePersonSetBlacklisted = 'ReceivePersonSetBlacklisted';
export const RequestPersonCrunchbaseData = 'RequestPersonCrunchbaseData';
export const ReceivePersonCrunchbaseData = 'ReceivePersonCrunchbaseData';
export const RequestCandidateAssigneeData = 'RequestCandidateAssigneeData';
export const ReceiveCandidateAssigneeData = 'ReceiveCandidateAssigneeData';
export const RequestCrossAddMultiple = 'RequestCrossAddMultiple';
export const ReceiveCrossAddMultiple = 'ReceiveCrossAddMultiple';
export const RequestUpdatePersonFilename = 'RequestUpdatePersonFilename';
export const ReceiveUpdatePersonFilename = 'ReceiveUpdatePersonFilename';
export const RequestScheduledMessages = 'RequestScheduledMessages';
export const ReceiveScheduledMessages = 'ReceiveScheduledMessages';
export const RequestRescheduleMessage = 'RequestRescheduleMessage';
export const ReceiveRescheduleMessage = 'ReceiveRescheduleMessage';
export const RequestCancelScheduledMessage = 'RequestCancelScheduledMessage';
export const ReceiveCancelScheduledMessage = 'ReceiveCancelScheduledMessage';
export const RequestPauseJobSearches = 'RequestPauseJobSearches';
export const ReceivePauseJobSearches = 'ReceivePauseJobSearches';
export const RequestUserUpdate = 'RequestUserUpdate';
export const ReceiveUserUpdate = 'ReceiveUserUpdate';
export const RequestReassignCandidateAssignee = 'RequestReassignCandidateAssignee';
export const ReceiveReassignCandidateAssignee = 'ReceiveReassignCandidateAssignee';
export const RequestUserBlacklistPerson = 'RequestUserBlacklistPerson';
export const ReceiveUserBlacklistPerson = 'ReceiveUserBlacklistPerson';
export const RequestIgnoreContactFromMessage = 'RequestIgnoreContactFromMessage';
export const ReceiveIgnoreContactFromMessage = 'ReceiveIgnoreContactFromMessage';
export const RequestAddProspectsToCampaign = 'RequestAddProspectsToCampaign';
export const ReceiveProspectsAddedToCampaign = 'ReceiveProspectsAddedToCampaign';
export const RequestAddSourcerMembersToSourcer = 'RequestAddSourcerMembersToSourcer';
export const ReceiveSourcerMembersAddedToSourcer = 'ReceiveSourcerMembersAddedToSourcer';
export const RequestContactDelete = 'RequestContactDelete';
export const ReceiveContactDelete = 'ReceiveContactDelete';
export const RequestProfileLinkUpdate = 'RequestProfileLinkUpdate';
export const ReceiveProfileLinkUpdate = 'ReceiveProfileLinkUpdate';
export const ClearProfileConflict = 'ClearProfileConflict';

// Extension related actions
export const ExtensionUpdateSelectedJob = 'ExtensionUpdateSelectedJob';
export const ExtensionProfileParsed = 'ExtensionProfileParsed';
export const ExtensionProfileParsingStatusChange = 'ExtensionProfileParsingStatusChange';
export const ExtensionRawJsonUpdate = 'ExtensionRawJsonUpdate';
export const ExtensionOutOfNetwork = 'ExtensionOutOfNetwork';
export const ExtensionHtmlPendingLoadCheck = 'ExtensionHtmlPendingLoadCheck';
export const ExtensionHtmlContentUpdate = 'ExtensionHtmlContentUpdate';
export const ExtensionPageUrlChange = 'ExtensionPageUrlChange';
export const ExtensionContainerElementIdChange = 'ExtensionContainerElementIdChange';
export const ExtensionScrollToPendingTags = 'ExtensionScrollToPendingTags';
export const ExtensionRequestJobs = 'ExtensionRequestJobs';
export const ExtensionReceiveJobs = 'ExtensionReceiveJobs';

// UI state related actions
export const UpdateKanbanState = 'UpdateKanbanState';
export const ToggleDrawer = 'ToggleDrawer';
export const CloseModalConfirmation = 'CloseModalConfirmation';
export const RequestModalConfirmation = 'RequestModalConfirmation';

interface JobsData {
    clients?: Client[];
    jobs: Job[];
    users: User[];
}

interface PersonDetailsResponse {
    jobs: Job[];
    clients: Client[];
    details: PersonDetails;
    personId?: string;
    jobId?: string;
    blacklistCheck: BlacklistCheck;
    similarityScore: number;
    skillScore: number;
    users: User[];
}

interface JobScoreResponse {
    blacklistCheck: BlacklistCheck;
    similarityScore: number;
    skillScore: number;
    nextSendTime?: number;
}

// tslint:disable-next-line:interface-over-type-literal
type UIActions =
    | {
          type: 'UpdateKanbanState';
          payload: Partial<KanbanState>;
      }
    | {
          type: 'ToggleDrawer';
      }
    | {
          type: 'RequestModalConfirmation';
          payload: {
              nonCancelable?: boolean;
              onConfirm: () => void;
              title?: string;
              description?: string | JSX.Element;
          };
      }
    | {
          type: 'CloseModalConfirmation';
      };

type ExtensionActions =
    | {
          type: 'ExtensionUpdateSelectedJob';
          payload: {
              jobId: string;
          };
      }
    | {
          type: 'ExtensionProfileParsed';
          payload: {
              extension: Partial<ExtensionState>;
              clients?: Client[];
              jobs?: Job[];
              users?: User[];
          };
      }
    | {
          type: 'ExtensionContainerElementIdChange';
          payload: {
              containerElementId: string;
          };
      }
    | {
          type: 'ExtensionProfileParsingStatusChange';
          payload: {
              parsingProfile: boolean;
          };
      }
    | {
          type: 'ExtensionRawJsonUpdate';
          payload: {
              jsonProfile: RawJsonProfile;
              url: string;
          };
      }
    | {
          type: 'ExtensionOutOfNetwork';
      }
    | {
          type: 'ExtensionHtmlPendingLoadCheck';
          payload: {
              url: string;
          };
      }
    | {
          type: 'ExtensionHtmlContentUpdate';
          payload: {
              html: string;
              pendingCount: number;
          };
      }
    | {
          type: 'ExtensionPageUrlChange';
      }
    | {
          type: 'ExtensionScrollToPendingTags';
          payload: {
              autoExpand: boolean;
              useAutoScroll: boolean;
          };
      }
    | {
          type: 'ExtensionRequestJobs';
      }
    | {
          type: 'ExtensionReceiveJobs';
          payload: {
              jobs: ExtensionJobData[];
          };
      };

// interface that all actions implement
export type Action =
    | ExtensionActions
    | UIActions
    | EmailAction
    | EmailComposeAction
    | {
          type: 'RequestSession';
      }
    | {
          type: 'RequestSignout';
      }
    | {
          type: 'AppVersionOutdated';
      }
    | {
          type: 'CompleteSignout';
      }
    | {
          type: 'ReceiveSession';
          session: Partial<Session>;
      }
    | {
          type: 'RequestUserInfo';
          payload: {
              id: string;
          };
      }
    | {
          type: 'ReceiveUserInfo';
          payload: {
              user: User;
          };
      }
    | {
          type: 'RequestJobsList';
      }
    | {
          type: 'ReceiveJobsList';
          payload: JobsData;
      }
    | {
          type: 'RequestAssignedJobsList';
      }
    | {
          type: 'ReceiveAssignedJobsList';
          payload: JobsData;
      }
    | {
          type: 'RequestNewJob';
      }
    | {
          type: 'ReceiveNewJob';
          payload: Job;
          error?: boolean;
      }
    | {
          type: 'RequestJobOnePager';
          payload: {
              jobId: string;
          };
      }
    | {
          type: 'ReceiveJobOnePagerResult';
          payload: {
              jobId: string;
              job: Job;
              errors: RequestErrors;
          };
      }
    | {
          type: 'RequestEmailTemplates';
          payload: { group: string };
      }
    | {
          type: 'ReceiveEmailTemplates';
          payload: { emailTemplates: EmailTemplateView[]; group: string };
      }
    | {
          type: 'RequestSaveEmailTemplate';
          payload: {
              data: Partial<EmailTemplateView>;
          };
      }
    | {
          type: 'ReceiveUpdatedEmailTemplate';
          payload: { template: EmailTemplateView };
      }
    | {
          type: 'RequestTemplateDelete';
          payload: { template: Partial<EmailTemplateView> };
      }
    | {
          type: 'ReceiveTemplateDeleteConfirmation';
          payload: { id: number };
      }
    | {
          type: 'ReceiveJobEmails';
          payload: {
              jobId: string;
              emails: EmailAccount[];
          };
          error: boolean;
      }
    | {
          type: 'RequestJobEmails';
          payload: {
              jobId: string;
          };
      }
    | {
          type: 'RequestEmailAccountCreate';
      }
    | {
          error: string;
          type: 'ReceiveEmailAccountCreated';
          payload: {
              jobId: string;
              emails: EmailAccount[];
          };
      }
    | {
          type: 'RequestClientFiles';
          payload: {
              clientId: string;
          };
      }
    | {
          type: 'ReceiveClientFilesResult';
          payload: {
              clientId: string;
              client: Client;
              errors: RequestErrors;
          };
      }
    | {
          type: 'RequestRemoveClientFile';
          payload: {
              clientId: string;
              key: string;
          };
      }
    | {
          type: 'ReceiveRemoveClientFileResult';
          payload: {
              client: Client;
              clientId: string;
              errors: RequestErrors;
              key: string;
          };
      }
    | {
          type: 'RequestClientData';
          payload: {
              clientId: string;
          };
      }
    | {
          type: 'ReceiveClientData';
          payload: {
              client: Client;
          };
          error: boolean;
      }
    | {
          type: 'RequestClientUpdate';
          payload: {
              clientId: string;
              updates: Partial<Client>;
          };
      }
    | {
          type: 'ReceiveClientUpdate';
          payload: {
              client: Client;
          };
          error: boolean;
      }
    | {
          type: 'RequestUploadClientPersonBlacklist';
          payload: {
              clientId: string;
          };
      }
    | {
          type: 'ReceiveUploadClientPersonBlacklist';
          payload: {
              client: Client;
          };
          error: boolean;
      }
    | {
          type: 'CancelJobUpdate';
          payload: { id: string };
      }
    | {
          type: 'RequestJobInfo';
          jobId: string;
      }
    | {
          type: 'ReceiveJobInfo';
          payload: {
              job: Job;
              client: Client;
              users: User[];
          };
      }
    | {
          type: 'RequestJobMetrics';
          payload: { ids: string[] };
      }
    | {
          type: 'ReceiveJobMetrics';
          payload: { metrics: Array<{ id: string; metrics: JobMetrics }> };
      }
    | {
          type: 'RequestCandidate';
          payload: { jobId: string; personId: string };
      }
    | {
          type: 'ReceiveCandidate';
          payload: { candidate: Candidate; person: Person; contacts: Contact[] };
      }
    | {
          type: 'RequestEmailCounts';
          payload: {
              jobId: string;
              email: string;
          };
      }
    | {
          type: 'ReceiveEmailCounts';
          payload: {
              email: string;
              sent: number;
              scheduled: { [date: string]: number };
          };
      }
    | {
          type: 'RequestClientList';
      }
    | {
          type: 'ReceiveClientList';
          payload: Client[];
      }
    | {
          type: 'RequestPersonsList';
      }
    | {
          type: 'ReceivePersonsList';
          payload: Person[];
      }
    | {
          type: 'RequestPersonContacts';
          payload: { personId: string };
      }
    | {
          type: 'ReceivePersonContacts';
          payload: {
              contacts: Contact[];
              personId: string;
          };
      }
    | {
          type: 'RequestPersonDetails';
          payload: {
              personId: string;
              jobId?: string;
          };
      }
    | {
          type: 'ReceivePersonDetails';
          payload: PersonDetailsResponse;
      }
    | {
          type: 'RequestPersonJobScore';
          personId: string;
          jobId: string;
      }
    | {
          type: 'ReceivePersonJobScore';
          payload: {
              personId: string;
              jobId: string;
              data: JobScoreResponse;
          };
      }
    | {
          type: 'RequestPersonJobLabels';
          jobId: string;
          personId: string;
      }
    | {
          type: 'ReceivePersonJobLabels';
          payload: {
              personId: string;
              jobId: string;
              labels: Array<{
                  jobId: string;
                  rating: boolean;
                  userId: string;
                  createdAt: number;
              }>;
          };
      }
    | {
          type: 'RequestCreatePersonJobLabel';
          personId: string;
          jobId: string;
          userId: string;
          label: boolean;
      }
    | {
          type: 'RequestNewClient';
      }
    | {
          type: 'ReceiveNewClient';
          payload:
              | {
                    client: Client;
                }
              | RequestErrors;
          error?: boolean;
      }
    | {
          type: 'RequestToasterClose';
      }
    | {
          type: 'RequestToasterOpen';
          payload: {
              autoHideDuration?: number;
              message: string;
          };
      }
    | {
          type: 'RequestAddCandidateToJob';
          payload: {
              personId: string;
              jobId: string;
              source: string;
          };
      }
    | {
          type: 'ReceiveCandidateAddedToJob';
          payload: {
              candidate: Candidate;
              source: string;
              inmailSends?: InmailSendClientData[];
          };
      }
    | {
          type: 'MoveCandidateToStage';
          payload: {
              candidate: Candidate;
              stage: string;
          };
      }
    | {
          type: 'ReceiveCandidateUpdate';
          payload: {
              candidate: Candidate;
              message: Communication;
          };
      }
    | {
          type: 'RequestCandidateDisqualify';
          payload: {
              candidate: Candidate;
              qualified: boolean;
              reason: string;
          };
      }
    | {
          type: 'RequestNewNote';
          payload: {
              note: Partial<NoteView>;
          };
      }
    | {
          type: 'ReceiveNewNote';
          payload: {
              note: NoteView;
          };
      }
    | {
          type: 'ReceiveNoteError';
          payload: {
              note: Partial<NoteView>;
          };
      }
    | {
          type: 'RequestNotes';
          payload: {
              notable: string;
          };
      }
    | {
          type: 'ReceiveNotes';
          payload: {
              notable: string;
              notes: NoteView[];
          };
      }
    | {
          type: 'RequestNoteDelete';
          payload: {
              note: NoteView;
          };
      }
    | {
          type: 'RequestUserActions';
          payload: {
              target: string;
          };
      }
    | {
          type: 'ReceiveUserActions';
          payload: {
              target: string;
              userActions: UserAction[];
          };
      }
    | {
          type: 'RequestContactAdd';
          payload: { personId: string; value: string; channel: ContactChannel };
      }
    | {
          type: 'RequestAddContactFromMessage';
          payload: {
              account: string;
              threadId: string;
              personId: string;
              value: string;
              messageId: string;
          };
      }
    | {
          type: 'ReceiveAddContactFromMessage';
          payload: {
              personId: string;
              communications: Communication[];
              contacts: Contact[];
              success: boolean;
              errors?: boolean;
          };
      }
    | {
          type: 'RequestPersonUpdate';
          id: string;
          updates: Partial<Person>;
      }
    | {
          type: 'ReceivePersonUpdate';
          payload: Person;
      }
    | {
          type: 'RequestPersonDoNotEmailAgain';
          payload: {
              personId: string;
              note: string;
              clientId: string;
          };
      }
    | {
          type: 'RequestPersonNotInterestedAtThisTime';
          payload: {
              personId: string;
              jobId: string;
              note: string;
              optOutUntil: number;
          };
      }
    | {
          type: 'ReceivePersonOptOutUpdate';
          payload: {
              person: Person;
              candidates: Candidate[];
              note: NoteView;
          };
      }
    | {
          type: 'RequestContactUpdate';
          payload: { personId: string; channel: ContactChannel };
      }
    | {
          type: 'ReceiveContactsUpdate';
          payload: {
              personId: string;
              profileConflictIds?: [string, string];
              contacts: Contact[];
              channel: ContactChannel;
              communications: Communication[];
              errors?: RequestErrors;
          };
      }
    | {
          type: 'RequestPersonFilesUpload';
          payload: {
              personId: string;
          };
      }
    | {
          type: 'ReceivePersonFilesUploadResult';
          payload: {
              personId: string;
              person: Person;
              errors: RequestErrors;
          };
      }
    | {
          type: 'RequestPersonSearch';
          payload: {
              searchString: string;
          };
      }
    | {
          type: 'ReceivePersonSearchResult';
          payload: {
              searchResults: PersonSearchResults;
          };
      }
    | {
          type: 'RequestSendSequenceToPerson';
          payload: {
              personId: string;
              jobId: string;
              sequenceId: string;
              variables: { [k: string]: string };
          };
      }
    | {
          type: 'ReceiveSendSequenceToPerson';
          payload: {
              personId: string;
              communications: Communication[];
          };
      }
    | {
          type: 'ReceiveUsersList';
          payload: {
              users: User[];
          };
      }
    | {
          type: 'RequestUsersList';
      }
    | {
          type: 'RequestClientWeeklyUpdateData';
          payload: {
              clientId: string;
          };
      }
    | {
          type: 'ReceiveClientWeeklyUpdateData';
          payload: {
              data: ClientUpdateData;
          };
      }
    | {
          type: 'UpdateUserPrefs';
          payload: {
              data: Partial<Preferences>;
          };
      }
    | {
          type: 'RequestOutreachForJob';
          payload: {
              emailAccount: string;
              jobId: string;
              sequence: string;
              scheduledAt: number;
              isRevival?: boolean;
              disqualReason?: string;
              provider: 'mixmax' | 'hireflow';
          };
      }
    | {
          type: 'ReceiveInitialOutreachForJob';
          payload: {
              candidates: Candidate[];
              success: boolean;
              job?: Job;
          };
      }
    | {
          type: 'RequestMoveInGmail';
          payload: {
              account: string;
              threadId: string;
              action: string;
          };
      }
    | {
          type: 'ReceiveMoveInGmail';
          payload: {
              candidates: Candidate[];
              communications: Communication[];
              personId: string;
              success: boolean;
          };
      }
    | {
          type: 'ArchiveMessage';
          payload: {
              account: string;
              personId: string;
              messageId: string;
          };
      }
    | {
          type: 'RequestAllEmailAccountInfo';
      }
    | {
          type: 'ReceiveAllEmailAccountInfo';
          payload: {
              emailAccounts: EmailAccount[];
          };
      }
    | {
          type: 'RequestReassignEmailAccount';
          payload: {
              email: string;
              update: {
                  jobId?: string;
                  owner?: string;
                  syncEnabled?: boolean;
              };
          };
      }
    | {
          type: 'ReceiveReassignEmailAccounts';
          payload: {
              emailAccounts: EmailAccount[];
          };
      }
    | {
          type: 'RequestJobSearches';
          payload: {
              jobId: string;
          };
      }
    | {
          type: 'ReceiveJobSearches';
          payload: {
              jobId: string;
              data: Partial<JobSearchesState>;
          };
      }
    | {
          type: 'RequestJobSearchStats';
          payload: {
              jobId: string;
              userId: string;
          };
      }
    | {
          type: 'ReceiveJobSearchStats';
          payload: {
              jobId: string;
              userId: string;
              data: Partial<JobSearchesState>;
          };
      }
    | {
          type: 'RequestSearchResults';
          payload: {
              search: Partial<Search>;
          };
      }
    | {
          type: 'ReceiveSearchResults';
          payload: {
              search: Search;
              data: SearchResultsState;
          };
      }
    | {
          type: 'RequestSearchDelete';
          payload: { search: Partial<Search> };
      }
    | {
          type: 'ReceiveUpdatedSearch';
          payload: { search: Search };
      }
    | {
          type: 'RequestSearchUpdate';
          payload: { id: string };
      }
    | {
          type: 'RequestSearchProfiles';
          payload: { searchId: string };
      }
    | {
          type: 'ReceiveSearchProfiles';
          payload: {
              searchId: string;
              profiles: Profile[];
              companySimilarity: SimilarityScoreResult;
              crunchbaseData: { [url: string]: CrunchbaseData };
          };
      }
    | {
          type: 'RequestSearchCreate';
      }
    | {
          type: 'ReceiveNewSearch';
          payload: { search: Search };
      }
    | {
          type: 'RequestSearchPurge';
          payload: { id: string };
      }
    | {
          type: 'ReceiveSearchPurge';
          payload: { id: string };
      }
    | {
          type: 'RequestPurgeCandidates';
          payload: { id: string };
      }
    | {
          type: 'ReceivePurgeCandidatesResponse';
          payload: { id: string };
      }
    | {
          type: 'RequestFileDownload';
          payload: { url: string };
      }
    | {
          type: 'ReceiveFileDownload';
          payload: { url: string };
      }
    | {
          type: 'ReceivePresets';
          payload: { presets: SearchPreset[] };
      }
    | {
          type: 'RequestPresets';
      }
    | {
          type: 'RequestSavePreset';
      }
    | {
          type: 'ReceivedSavePreset';
          payload: { preset: SearchPreset };
      }
    | {
          type: 'ReceiveJobsSearchesStats';
          payload: { stats: { [jobId: string]: JobSearchesStats } };
      }
    | {
          type: 'RequestJobsSearchesStats';
          payload: { refresh: boolean };
      }
    | {
          type: 'RemoveUnsavedLocalSearches';
          payload: { jobId: string };
      }
    | {
          type: 'RequestPersonWebsiteAdd';
          id: string;
      }
    | {
          type: 'ReceivePersonWebsiteAdd';
          payload: {
              person: Person;
              profileUrls: ProfileUrlRecord[];
              errors?: RequestErrors;
              profileConflictIds?: [string, string];
          };
      }
    | {
          type: 'ReceivePersonCommunications';
          payload: {
              id: string;
              communications: Communication[];
              candidates: Candidate[];
          };
      }
    | {
          type: 'RequestPersonCommunications';
          payload: {
              id: string;
          };
      }
    | { type: 'RequestFlaggedEmails'; payload: { account: string } }
    | {
          type: 'ReceiveFlaggedEmails';
          payload: {
              account: string;
              data: { threads: { [threadId: string]: Communication[] }; total: number };
          };
      }
    | {
          type: 'RequestCommunicationMatchDataUpdate';
          payload: {
              communication: Communication;
              updates: {
                  ignored?: boolean;
                  personIds?: string[];
                  jobIds?: string[];
                  error?: boolean;
                  manualOverridePersonIds?: boolean;
                  manualOverrideJobIds?: boolean;
              };
          };
      }
    | {
          type: 'ReceiveCommunicationMatchDataUpdate';
          payload: {
              communications: Communication[];
          };
      }
    | {
          type: 'RequestForwardFlaggedEmail';
          payload: {
              messageId: string;
              account: string;
          };
      }
    | {
          type: 'ReceiveForwardFlaggedEmail';
          payload: {
              communications: Communication[];
          };
      }
    | {
          type: 'ReceiveCommunicationMatcher';
          payload: { communications: Communication[] };
      }
    | {
          type: 'RequestCommunicationMatcher';
          payload: { communication: Communication };
      }
    | {
          type: 'ReceiveAccountManagerData';
          payload: {
              id: string;
              data: AccountManagerData;
              candidates: Candidate[];
          };
      }
    | {
          type: 'RequestAccountManagerData';
          payload: {
              id: string;
          };
      }
    | {
          type: 'RequestJobSearchForSourcing';
          payload: {
              jobId: string;
          };
      }
    | {
          type: 'ReceiveJobSearchForSourcing';
          payload: {
              jobId: string;
              selected: Search;
              searches: Search[];
              results: SearchResult[];
          };
      }
    | {
          type: 'ResetJobSourcingSearch';
          payload: {
              jobId: string;
          };
      }
    | {
          type: 'ReceiveAddSearchResultToJob';
          payload: {
              jobId: string;
              result: SearchResult;
              candidate: Candidate;
              person: Person;
              disqualified: boolean;
          };
      }
    | {
          type: 'RequestAddSearchResultToJob';
          payload: {
              jobId: string;
              result: SearchResult;
          };
      }
    | {
          type: 'RequestSearchResultPersonAndCandidate';
          payload: {
              result: SearchResult;
          };
      }
    | {
          type: 'ReceiveSearchResultPersonAndCandidate';
          payload: {
              result: SearchResult;
              candidate: Candidate;
              person: Person;
          };
      }
    | {
          type: 'ReceiveCandidateReminderConfirmation';
          payload: {
              candidate: Candidate;
              jobId: string;
              personId: string;
          };
      }
    | {
          type: 'RequestCandidateReminder';
          payload: {
              jobId: string;
              personId: string;
          };
      }
    | {
          type: 'ReceiveJobAssigneeData';
          payload: {
              id: string;
              data: JobAssigneeData;
              candidates: Candidate[];
          };
      }
    | {
          type: 'RequestJobCandidatesSummary';
          payload: {
              jobId: string;
          };
      }
    | {
          type: 'ReceiveJobCandidatesSummary';
          payload: {
              jobId: string;
              candidates: CandidateSummary[];
          };
      }
    | {
          type: 'AddNoteAttachments';
          payload: {
              noteDraftKey: string;
              attachments: FilePayload[];
          };
      }
    | {
          type: 'RemoveNoteAttachments';
          payload: {
              noteDraftKey: string;
          };
      }
    | {
          type: 'ReceiveUpdateJobPrimaryEmail';
          payload: {
              job: Job;
          };
      }
    | {
          type: 'RequestUpdateJobPrimaryEmail';
          payload: {
              id: string;
              primaryEmail: string;
          };
      }
    | {
          type: 'RequestCrossAddCandidate';
          payload: {
              personId: string;
              jobIds: string[];
              sourceJobId: string;
          };
      }
    | {
          type: 'ReceiveCrossAddCandidate';
          payload: {
              candidates: Candidate[];
              communications: Communication[];
          };
      }
    | {
          type: 'RequestJobAssigneeData';
          payload: {
              id: string;
          };
      }
    | {
          type: 'ReceiveAppConstant';
          appConstants: AppConstant;
      }
    | {
          type: 'RequestPersonSetBlacklisted';
          payload: {
              personId: string;
              blacklisted: boolean;
          };
      }
    | {
          type: 'ReceivePersonSetBlacklisted';
          payload: {
              person: Person;
              candidates: Candidate[];
          };
      }
    | {
          type: 'RequestEmailUpdateToken';
          payload: {
              email: string;
          };
      }
    | {
          error: string;
          type: 'ReceiveEmailUpdateToken';
          payload: {
              email: string;
          };
      }
    | {
          type: 'ReceivePersonCrunchbaseData';
          payload: {
              personId: string;
              crunchbaseData: { [url: string]: CrunchbaseData };
          };
      }
    | {
          type: 'RequestPersonCrunchbaseData';
          payload: {
              personId: string;
          };
      }
    | {
          type: 'RequestUserUpdate';
          payload: {
              userId: string;
          };
      }
    | {
          type: 'ReceiveUserUpdate';
          payload: {
              user: User;
          };
      }
    | {
          type: 'ReceiveCrossAddMultiple';
          payload: {
              candidates: Candidate[];
          };
      }
    | {
          type: 'RequestCrossAddMultiple';
      }
    | {
          type: 'RequestUpdatePersonFilename';
          payload: {
              path: string;
              personId: string;
              filename: string;
          };
      }
    | {
          type: 'ReceiveUpdatePersonFilename';
          payload: {
              files: PersonFile[];
              personId: string;
              path: string;
          };
      }
    | {
          type: 'ReceiveScheduledMessages';
          payload: {
              personId: string;
              scheduledMessages: ScheduledMessageView[];
          };
      }
    | {
          type: 'RequestScheduledMessages';
          payload: {
              personId: string;
          };
      }
    | { type: 'RequestRescheduleMessage'; payload: { scheduledMessage: ScheduledMessageView } }
    | { type: 'ReceiveRescheduleMessage'; payload: { scheduledMessage: ScheduledMessageView; oldMessageId: string } }
    | { type: 'RequestCancelScheduledMessage'; payload: { id: string } }
    | { type: 'ReceiveCancelScheduledMessage'; payload: { personId: string; id: string } }
    | {
          type: 'RequestPauseJobSearches';
          payload: {
              jobId: string;
          };
      }
    | {
          type: 'ReceivePauseJobSearches';
          payload: {
              jobId: string;
              searches: Search[];
          };
      }
    | {
          type: 'RequestCandidateAssigneeData';
          payload: {
              id: string;
          };
      }
    | {
          type: 'ReceiveCandidateAssigneeData';
          payload: {
              id: string;
              data: CandidateAssigneeData;
              candidates: Candidate[];
          };
      }
    | {
          type: 'RequestReassignCandidateAssignee';
          payload: {
              personId: string;
              jobId: string;
          };
      }
    | {
          type: 'ReceiveReassignCandidateAssignee';
          payload: {
              candidate: Candidate;
          };
      }
    | {
          type: 'RequestUserBlacklistPerson';
          payload: {
              personId: string;
          };
      }
    | {
          type: 'ReceiveUserBlacklistPerson';
          payload: {
              personId: string;
              candidates: Candidate[];
              userBlacklisted: boolean;
          };
      }
    | {
          type: 'RequestIgnoreContactFromMessage';
          payload: {
              account: string;
              messageId: string;
              threadId: string;
          };
      }
    | {
          type: 'ReceiveIgnoreContactFromMessage';
          payload: {
              account: string;
              messageId: string;
              threadId: string;
              communications: Communication[];
          };
      }
    | {
          type: 'RequestAddProspectsToCampaign';
          payload: {
              campaignId: string;
          };
      }
    | {
          type: 'ReceiveProspectsAddedToCampaign';
          payload: {
              campaignId: string;
          };
      }
    | {
          type: 'RequestAddSourcerMembersToSourcer';
          payload: {
              sourcerId: string;
          };
      }
    | {
          type: 'ReceiveSourcerMembersAddedToSourcer';
          payload: {
              sourcerId: string;
          };
      }
    | {
          type: 'ClearProfileConflict';
      }
    | {
          type: 'RequestContactDelete';
          payload: {
              personId: string;
              channel: string;
              value: string;
          };
      }
    | {
          type: 'ReceiveContactDelete';
          payload: {
              personId: string;
              channel: string;
              value: string;
          };
      }
    | {
          type: 'RequestProfileLinkUpdate';
          payload: {
              url: string;
          };
      }
    | {
          type: 'ReceiveProfileLinkUpdate';
          payload: {
              personId: string;
              profileUrls: ProfileUrlRecord[];
              url: string;
          };
      };

// action creators

// start - extension actions

const extensionRefetchRequestDelayMs = 500;
const extensionRetryParsingDelayMs = 500;
const extensionUrlChangeDelayMs = 5000;

function updateSelectedJobId(jobId: string): Action {
    return {
        payload: { jobId },
        type: ExtensionUpdateSelectedJob
    };
}

function extensionProfileParsed(payload: {
    extension: Partial<ExtensionState>;
    clients?: Client[];
    jobs?: Job[];
    users?: User[];
}): Action {
    return {
        payload,
        type: ExtensionProfileParsed
    };
}

function extensionHtmlPendingLoadCheck(payload: { url: string }): Action {
    return {
        payload,
        type: ExtensionHtmlPendingLoadCheck
    };
}

function extensionHtmlContentUpdate(data: { html: string; pendingCount: number }): Action {
    return {
        payload: data,
        type: 'ExtensionHtmlContentUpdate'
    };
}

function extensionProfileParsingStatusChange(parsingProfile: boolean): Action {
    return {
        payload: { parsingProfile },
        type: ExtensionProfileParsingStatusChange
    };
}

function extensionPageUrlChange(): Action {
    return {
        type: ExtensionPageUrlChange
    };
}

export function extensionContainerElementIdChange(containerElementId: string): Action {
    return {
        payload: { containerElementId },
        type: ExtensionContainerElementIdChange
    };
}

export function extensionUpdateSelectedJobId(jobId: string) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (getState().extension.person && getState().extension.selectedJobId !== jobId) {
            dispatch(updateSelectedJobId(jobId));
            dispatch(extensionProfileParsingStatusChange(true));
            const { profile, person } = getState().extension;
            api.scoreProfile({ profile, jobId, personId: person.id, recordView: true }).then((result) => {
                dispatch(extensionProfileParsingStatusChange(false));
                dispatch(extensionProfileParsed({ extension: result.data }));
            });
        }
    };
}

function parseProfile() {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        const {
            extension: { htmlPendingLoadTagsCount, htmlProfile, jsonProfile, url, selectedJobId }
        } = getState();
        if (url && (!!htmlProfile || !!jsonProfile)) {
            dispatch(extensionProfileParsingStatusChange(true));
            api.parseProfile({
                data: { url, html: htmlProfile, json: jsonProfile, pendingLoadSections: htmlPendingLoadTagsCount },
                jobId: selectedJobId
            })
                .then((result) => {
                    dispatch(extensionProfileParsingStatusChange(false));
                    if (standardizeUrl(getState().extension.url) !== standardizeUrl(result.data.extension?.url)) {
                        // url changed - reset
                        dispatch(extensionPageUrlChange());
                        setTimeout(requestPageData, extensionUrlChangeDelayMs);
                    } else if (RetryableParsingErrors.includes(result.data.extension?.parsingError)) {
                        setTimeout(requestPageData, extensionRetryParsingDelayMs); // ask for data again and we will re-parse
                    } else {
                        dispatch(extensionProfileParsed(result.data));
                    }
                })
                .catch((err) => {
                    logger.warn(err, { message: 'profile parsing error' });
                    dispatch(extensionProfileParsingStatusChange(false));
                });
        } else {
            setTimeout(requestPageData, extensionRefetchRequestDelayMs);
        }
    };
}

export function extensionProfileDataChange(data: { html?: string; url: string; containerElementId: string }) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        const state = getState();
        const elapsedTimeSinceLoadMs = Date.now() - state.extension.firstLoadTime;
        if (
            (!!state.extension.containerElementId && state.extension.containerElementId !== data.containerElementId) ||
            (!!state.extension.url && standardizeUrl(state.extension.url) !== standardizeUrl(data.url))
        ) {
            dispatch(extensionPageUrlChange());
            setTimeout(requestPageData, extensionUrlChangeDelayMs);
        } else {
            if (data.html) {
                dispatch(extensionHtmlPendingLoadCheck({ url: data.url }));
                requestHtmlPendingLoadCounts(
                    data.containerElementId,
                    state.extension.autoScrollToPendingHtmlTags,
                    false
                );
                Analytics.trackExtensionHtmlParsing(data.url, elapsedTimeSinceLoadMs);
            } else {
                Analytics.trackExtensionDataLoadError(data.url, elapsedTimeSinceLoadMs);
            }
        }
    };
}

export function processExtensionHtmlPendingData(data: {
    html: string;
    pendingLoad: Array<{ tag: string; text: string }>;
}) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        const {
            extension: {
                firstLoadTime,
                url,
                htmlProfile,
                jsonProfile,
                parsingProfile,
                person,
                htmlPendingLoadTagsCount
            }
        } = getState();
        const elapsedTimeSinceLoadMs = Date.now() - firstLoadTime;
        if (!jsonProfile) {
            if (data.pendingLoad.length === 0) {
                Analytics.trackExtensionHtmlLoaded(url, elapsedTimeSinceLoadMs);
            } else {
                Analytics.trackExtensionHtmlProfileIncompleteLoad(url, data.pendingLoad.length, elapsedTimeSinceLoadMs);
            }
            if (isHtmlProfileDifferent(data.html, htmlProfile)) {
                dispatch(
                    extensionHtmlContentUpdate({
                        html: data.html,
                        pendingCount: data.pendingLoad.length
                    })
                );
            }
            if ((!parsingProfile && !person) || (data.pendingLoad.length === 0 && htmlPendingLoadTagsCount > 0)) {
                dispatch(parseProfile());
            }
        }
    };
}

function extensionScrollToPendingTags(useAutoScroll: boolean, autoExpand: boolean): Action {
    return {
        payload: { useAutoScroll, autoExpand },
        type: 'ExtensionScrollToPendingTags'
    };
}

export function updateExtensionAutoScroll(useAutoScroll: boolean, autoClick: boolean) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        requestHtmlPendingLoadCounts(getState().extension.containerElementId, useAutoScroll, autoClick);
        dispatch(extensionScrollToPendingTags(useAutoScroll, autoClick));
    };
}

// end - extension actions

export function getConfirmation(onConfirm: () => void, description?: string | JSX.Element, title?: string): Action {
    return {
        payload: { onConfirm, description, title },
        type: RequestModalConfirmation
    };
}

export function closeConfirmationModal(): Action {
    return {
        type: CloseModalConfirmation
    };
}

export function showModalAlert(description: string | JSX.Element, title: string, nonCancelable?: boolean): Action {
    return {
        payload: { onConfirm: null, description, title, nonCancelable },
        type: RequestModalConfirmation
    };
}

export function updateKanban(updates: Partial<KanbanState>) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        const state = getState();
        let payload = updates;
        const jobId = updates.jobId || state.ui.kanban.jobId;
        const disqualifiedView =
            updates.disqualifiedView !== undefined ? updates.disqualifiedView : state.ui.kanban.disqualifiedView;
        if (jobId && updates.candidateId) {
            let candidateIdList = state.ui.kanban.candidateIdList;
            if (!candidateIdList) {
                // candidate selected - set the list
                const { jobs, candidates } = state;
                if (jobs.get(jobId) && candidates.get(jobId)) {
                    // can be null if we load in directly to candidate page
                    const selected = candidates.get(jobId).get(updates.candidateId);
                    if (selected && selected.disqualified === disqualifiedView) {
                        const candidateList = candidates
                            .get(jobId)
                            .filter((c) => c.stage === selected.stage && c.disqualified === disqualifiedView)
                            .valueSeq()
                            .toArray();
                        candidateIdList = candidateList.map((c) => c.personId);
                        payload = Object.assign({}, updates, { candidateIdList });
                    }
                }
            }
            // fetch previous and next candidates
            if (candidateIdList) {
                const currentIndex = candidateIdList.indexOf(updates.candidateId);
                if (currentIndex !== -1) {
                    if (currentIndex > 0) {
                        const prevId = candidateIdList[currentIndex - 1];
                        if (!state.personsDetails.list.has(prevId)) {
                            dispatch(fetchPersonDetails(prevId, jobId));
                        }
                    }
                    if (currentIndex < candidateIdList.length - 1) {
                        const nextId = candidateIdList[currentIndex + 1];
                        if (!state.personsDetails.list.has(nextId)) {
                            dispatch(fetchPersonDetails(nextId, jobId));
                        }
                    }
                }
            }
        }
        if (!updates.candidateId) {
            // candidate de-selected - reset the list
            payload = Object.assign({}, updates, { candidateIdList: undefined });
        }
        dispatch({
            payload,
            type: UpdateKanbanState
        });
    };
}

export function setAppVersionOutdated(): Action {
    return {
        type: AppVersionOutdated
    };
}

function pollAppVersion() {
    return (dispatch: Dispatch<State>) => {
        monitorAppVersion().then(() => {
            dispatch(setAppVersionOutdated());
        });
    };
}

function requestSession(): Action {
    return {
        type: RequestSession
    };
}

function receiveSession(session: Partial<Session>): Action {
    return {
        session,
        type: ReceiveSession
    };
}

function receiveAppConstants(appConstants: AppConstant): Action {
    return {
        appConstants,
        type: ReceiveAppConstant
    };
}

export function fetchSession(googleAuthToken: string, authToken: string) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().session.fetching) {
            dispatch(requestSession());
            return api.authenticate(googleAuthToken, authToken).then((result) => {
                const { session, appConstants } = result.data;
                Analytics.identify(session.user.email);
                setLocalStorageKey('session', { authToken: session.authToken }, -1);
                dispatch(receiveSession(session));
                dispatch(pollAppVersion());
                dispatch(receiveAppConstants(appConstants));
            });
        }
    };
}

function completeSignout() {
    return {
        type: CompleteSignout
    };
}

export function requestSignout() {
    removeLocalStorageKey('session');
    return async (dispatch: Dispatch<State>) => {
        await signOut();
        dispatch(completeSignout());
        window.location.reload();
    };
}

function requestUserInfo(id: string): Action {
    return {
        payload: { id },
        type: RequestUserInfo
    };
}

function receiveUserInfo(user: User): Action {
    return {
        payload: { user },
        type: ReceiveUserInfo
    };
}

export function fetchUserInfo(id: string) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.get(`user-fetch-${id}`)) {
            dispatch(requestUserInfo(id));
            api.fetchUserInfo(id).then((result) => {
                dispatch(receiveUserInfo(result.data.user));
            });
        }
    };
}

function requestJobs(): Action {
    return {
        type: RequestJobsList
    };
}

function receiveJobs(payload: JobsData): Action {
    return {
        payload,
        type: ReceiveJobsList
    };
}

export function fetchJobsData() {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().listsState.get('jobs')) {
            dispatch(requestJobs());
            return api.fetchJobs().then((result) => {
                dispatch(receiveJobs(result.data));
            });
        }
    };
}

function requestExtensionJobs(): Action {
    return {
        type: ExtensionRequestJobs
    };
}

function receiveExtensionJobs(jobs: ExtensionJobData[]): Action {
    return {
        payload: { jobs },
        type: ExtensionReceiveJobs
    };
}

export function fetchExtensionJobs() {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.get('extension-jobs-request')) {
            dispatch(requestExtensionJobs());
            return api.fetchExtensionJobs().then((result) => {
                const jobs: ExtensionJobData[] = result.data?.jobs;
                dispatch(receiveExtensionJobs(jobs));
                if (jobs?.findIndex((j) => j.id === getState().extension.selectedJobId) === -1) {
                    setLocalStorageKey(selectedJobIdKey, undefined, -1);
                    dispatch(updateUserPrefs({ selectedJobId: undefined }));
                }
            });
        }
    };
}

function requestNewJob(): Action {
    return {
        type: RequestNewJob
    };
}

function receiveNewJob(payload: Job, error?: boolean): Action {
    return {
        error,
        payload,
        type: ReceiveNewJob
    };
}

export function createNewJob(params: Partial<Job>) {
    return (dispatch: Dispatch<State>) => {
        dispatch(requestNewJob());
        return api.createJob(params).then((result) => {
            dispatch(receiveNewJob(result.data, !result.success));
        });
    };
}

function requestEmailTemplates(group: string): Action {
    return { type: RequestEmailTemplates, payload: { group } };
}

function receiveEmailTemplates(emailTemplates: EmailTemplateView[], group: string): Action {
    return { type: ReceiveEmailTemplates, payload: { emailTemplates, group } };
}

export function getEmailTemplates(group: string) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.has(`get-email-templates-${group}`)) {
            dispatch(requestEmailTemplates(group));
            return api.fetchEmailTemplates(group).then((result) => dispatch(receiveEmailTemplates(result.data, group)));
        }
    };
}

function requestSaveTemplate(data: Partial<EmailTemplateView>): Action {
    return {
        payload: { data },
        type: RequestSaveEmailTemplate
    };
}

function receiveUpdatedTemplate(template: EmailTemplateView): Action {
    return {
        payload: { template },
        type: ReceiveUpdatedEmailTemplate
    };
}

export function saveTemplate(data: Partial<EmailTemplateView>) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.get(`save-email-template`)) {
            dispatch(requestSaveTemplate(data));
            dispatch(requestToasterOpen('Saving Email Template'));
            const apiCall = data.id ? api.updateEmailTemplate(data) : api.createEmailTemplate(data);
            return apiCall.then((result) => {
                dispatch(receiveUpdatedTemplate(result.data));
                dispatch(requestToasterOpen('Template Saved'));
            });
        }
    };
}

function requestTemplateDelete(template: Partial<EmailTemplateView>): Action {
    return {
        payload: { template },
        type: RequestTemplateDelete
    };
}

function receiveTemplateDeleteConfirmation(id: number): Action {
    return {
        payload: { id },
        type: ReceiveTemplateDeleteConfirmation
    };
}

export function deleteTemplate(template: Partial<EmailTemplateView>) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.get(`delete-email-template`)) {
            dispatch(requestTemplateDelete(template));
            dispatch(requestToasterOpen('Deleting Email Template'));
            return api.deleteTemplate(template.id).then(() => {
                dispatch(receiveTemplateDeleteConfirmation(template.id));
                dispatch(requestToasterOpen('Template Deleted'));
            });
        }
    };
}

function requestEmailCounts(jobId: string, email: string): Action {
    return {
        payload: { email, jobId },
        type: RequestEmailCounts
    };
}

export function getEmailCounts(jobId: string, email: string) {
    const requestType = `get-email-counts-${email}`;
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.get(requestType)) {
            dispatch(requestEmailCounts(jobId, email));
            return api.getEmailCounts(jobId, email).then((result) => {
                dispatch({
                    payload: result.data,
                    type: ReceiveEmailCounts
                });
            });
        }
    };
}

function requestJobInfo(jobId: string): Action {
    return {
        jobId,
        type: RequestJobInfo
    };
}

function receiveJobInfo(payload: { job: Job; users: User[]; client: Client }): Action {
    return {
        payload,
        type: ReceiveJobInfo
    };
}

export function fetchJobInfo(jobId: string) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.get(`job-${jobId}`)) {
            dispatch(requestJobInfo(jobId));
            return api.fetchJobInfo(jobId).then((result) => {
                dispatch(receiveJobInfo(result.data));
            });
        }
    };
}

function requestJobMetrics(ids: string[]): Action {
    return {
        payload: { ids },
        type: RequestJobMetrics
    };
}

function receiveJobMetrics(metrics: Array<{ id: string; metrics: JobMetrics }>): Action {
    return { payload: { metrics }, type: ReceiveJobMetrics };
}

export function fetchJobMetrics(ids: string[]) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.get(`job-metrics`)) {
            dispatch(requestJobMetrics(ids));
            return api.getJobMetrics(ids).then((result) => {
                dispatch(receiveJobMetrics(result.data));
            });
        }
    };
}

function requestCandidate(jobId: string, personId: string): Action {
    return {
        payload: { jobId, personId },
        type: RequestCandidate
    };
}

function receiveCandidate(payload: { candidate: Candidate; person: Person; contacts: Contact[] }): Action {
    return {
        payload,
        type: ReceiveCandidate
    };
}

export function fetchCandidate(jobId: string, personId: string) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        const state = getState();
        if (
            (!state.candidates.get(jobId) || !state.candidates.get(jobId).get(personId)) &&
            !state.pendingRequests.get(`candidate-job-${jobId}-person-${personId}`)
        ) {
            dispatch(requestCandidate(jobId, personId));
            return api.fetchCandidate(jobId, personId).then((result) => {
                dispatch(receiveCandidate(result.data));
            });
        }
    };
}

function requestClients(): Action {
    return {
        type: RequestClientList
    };
}

function receiveClients(payload: Client[]): Action {
    return {
        payload,
        type: ReceiveClientList
    };
}

export function fetchClientsList() {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().clients.isFetching) {
            dispatch(requestClients());
            return api.fetchClients().then((result) => {
                dispatch(receiveClients(result.data.clients));
            });
        }
    };
}

function requestNewClient(): Action {
    return {
        type: RequestNewClient
    };
}

function receiveNewClient(payload: { client: Client } | RequestErrors, error?: boolean): Action {
    return {
        error,
        payload,
        type: ReceiveNewClient
    };
}

export function createNewClient(params: Partial<Client>) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().clients.isCreating) {
            dispatch(requestNewClient());
            return api.createClient(params).then((result) => {
                dispatch(receiveNewClient(result.data, !result.success));
                dispatch(requestToasterOpen('Client created'));
            });
        }
    };
}

export function requestToasterClose(): Action {
    return {
        type: RequestToasterClose
    };
}

export function requestToasterOpen(message: string, autoHideDuration?: number): Action {
    return {
        payload: {
            autoHideDuration,
            message
        },
        type: RequestToasterOpen
    };
}

function requestPersons(): Action {
    return {
        type: RequestPersonsList
    };
}

function receivePersons(payload: Person[]): Action {
    return {
        payload,
        type: ReceivePersonsList
    };
}

export function fetchPersonsList(ids: string[]) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().persons.isFetching) {
            dispatch(requestPersons());
            return api.fetchPersonsList(ids).then((result) => {
                dispatch(receivePersons(result.data));
            });
        }
    };
}

function requestPersonContacts(personId: string): Action {
    return {
        payload: { personId },
        type: RequestPersonContacts
    };
}

function receivePersonContacts(payload: { personId: string; contacts: Contact[] }) {
    return {
        payload,
        type: ReceivePersonContacts
    };
}

export function fetchPersonContacts(personId: string) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.has(`person-contacts-${personId}`)) {
            dispatch(requestPersonContacts(personId));
            return api.fetchPersonContacts(personId).then((result) => {
                dispatch(receivePersonContacts(result.data));
            });
        }
    };
}

function requestPersonDetails(payload: { personId: string; jobId?: string }): Action {
    return {
        payload,
        type: RequestPersonDetails
    };
}

function receivePersonDetails(payload: PersonDetailsResponse): Action {
    return {
        payload,
        type: ReceivePersonDetails
    };
}

export function fetchPersonDetails(personId: string, jobId?: string) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.has(`person-details-request-${personId}`)) {
            dispatch(requestPersonDetails({ personId, jobId }));
            return api.fetchPerson(personId, jobId).then((result) => {
                dispatch(receivePersonDetails(result.data));
            });
        }
    };
}

function requestPersonJobScore(personId: string, jobId: string): Action {
    return {
        jobId,
        personId,
        type: RequestPersonJobScore
    };
}

function receivePersonJobScore(payload: { personId: string; jobId: string; data: JobScoreResponse }): Action {
    return {
        payload,
        type: ReceivePersonJobScore
    };
}

export function fetchPersonJobScore(personId: string, jobId: string) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        const state = getState();
        const profile = state.personsDetails.list.get(personId).profile;
        if (profile && !state.pendingRequests.get(`person-job-score-${personId}-${jobId}`)) {
            dispatch(requestPersonJobScore(personId, jobId));
            return api.scoreProfile({ profile: profile.content, jobId, personId, recordView: false }).then((result) => {
                dispatch(
                    receivePersonJobScore({
                        data: result.data,
                        jobId,
                        personId
                    })
                );
            });
        }
    };
}

function receivePersonJobLabels(payload: {
    personId: string;
    jobId: string;
    labels: Array<{
        jobId: string;
        rating: boolean;
        userId: string;
        createdAt: number;
    }>;
}): Action {
    return {
        payload,
        type: ReceivePersonJobLabels
    };
}

function requestPersonJobLabelCreate(userId: string, personId: string, jobId: string, label: boolean): Action {
    return {
        jobId,
        label,
        personId,
        type: RequestCreatePersonJobLabel,
        userId
    };
}

export function createPersonJobLabel(personId: string, jobId: string, label: boolean) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        const state = getState();
        if (!state.pendingRequests.get(`person-job-labels-create-${personId}-${jobId}`)) {
            dispatch(requestPersonJobLabelCreate(state.session.user.id, personId, jobId, label));
            return api.createPersonJobLabel(personId, jobId, label).then((result) => {
                dispatch(receivePersonJobLabels(result.data));
            });
        }
    };
}

export function openJobTabs(jobIds: string[]) {
    const jobUrls = jobIds.map((id) => `job/${id}/board`);
    return () => {
        openTabsWithDelay(jobUrls);
    };
}

function requestCandidateStageChange(candidate: Candidate, stage: string) {
    return {
        payload: {
            candidate,
            stage
        },
        type: MoveCandidateToStage
    };
}

function receiveCandidateUpdate(payload: { candidate: Candidate; message?: Communication }) {
    return {
        payload,
        type: ReceiveCandidateUpdate
    };
}

export function moveCandidateToStage(candidate: Candidate, stage: string) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.has(`candidate-update-${candidate.personId}-${candidate.jobId}`)) {
            dispatch(requestCandidateStageChange(candidate, stage));
            return api.updateCandidateStage(candidate.personId, candidate.jobId, stage).then((result) => {
                Analytics.trackCandidateStateChange(candidate, stage);
                dispatch(receiveCandidateUpdate({ candidate: result.data.candidate, message: result.data.message }));
                if (stage === awaitingClientStage) {
                    dispatch(receiveJobCandidatesSummary(candidate.jobId, null));
                }
            });
        }
    };
}

function requestAddCandidate(personId: string, jobId: string, source: string) {
    return {
        payload: { jobId, personId, source },
        type: RequestAddCandidateToJob
    };
}

function receiveCandidateAdded(candidate: Candidate, source: string, inmailSends?: InmailSendClientData[]): Action {
    return {
        payload: { candidate, source, inmailSends },
        type: ReceiveCandidateAddedToJob
    };
}

export function addCandidateToJob(
    personId: string,
    jobId: string,
    source: string,
    profileUrl: string,
    stage: string,
    inMailSent: boolean
) {
    return (dispatch: Dispatch<State>) => {
        dispatch(requestAddCandidate(personId, jobId, source));
        return api.addCandidateToJob(personId, jobId, source, stage, inMailSent).then((result) => {
            const { candidate, inmailSends } = result.data;
            Analytics.trackCandidateAddedToJob(personId, profileUrl, jobId, source, candidate.stage);
            dispatch(receiveCandidateAdded(candidate, source, inmailSends));
        });
    };
}

function requestCandidateDisqualify(candidate: Candidate, qualified: boolean, reason: string): Action {
    return {
        payload: {
            candidate,
            qualified,
            reason
        },
        type: RequestCandidateDisqualify
    };
}

export function setCandidateQualification(candidate: Candidate, qualified: boolean, reason: string) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        const inProgress = getState().pendingRequests.has(`candidate-update-${candidate.personId}-${candidate.jobId}`);
        if (!inProgress) {
            dispatch(requestCandidateDisqualify(candidate, qualified, reason));
            return api
                .updateCandidateQualification(candidate.personId, candidate.jobId, qualified, reason)
                .then((result) => {
                    Analytics.trackCandidateQualification(candidate, qualified);
                    dispatch(receiveCandidateUpdate({ candidate: result.data }));
                });
        }
    };
}

function receiveNoteError(payload: { note: Partial<NoteView> }): Action {
    return {
        payload,
        type: ReceiveNoteError
    };
}

function requestNewNote(payload: { note: Partial<NoteView> }): Action {
    return {
        payload,
        type: RequestNewNote
    };
}

function receiveNewNote(payload: { note: NoteView }): Action {
    return {
        payload,
        type: ReceiveNewNote
    };
}

export function createNewNote(note: Partial<NoteView>, postSave?: () => void, onFailedSave?: () => void) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.has(`note-create-${note.notable}`)) {
            dispatch(requestNewNote({ note }));
            return api
                .createNote(note)
                .then((result) => {
                    dispatch(receiveNewNote({ note: result.data }));
                    if (postSave) {
                        postSave();
                    }
                })
                .catch(() => {
                    dispatch(requestToasterOpen('Error saving note'));
                    dispatch(receiveNoteError({ note }));
                    if (onFailedSave) {
                        onFailedSave();
                    }
                });
        }
    };
}

function requestNotes(payload: { notable: string }): Action {
    return {
        payload,
        type: RequestNotes
    };
}

function receiveNotes(payload: { notable: string; notes: NoteView[] }): Action {
    return {
        payload,
        type: ReceiveNotes
    };
}

export function fetchEntityNotes(notable: string) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.has(`notes-${notable}`)) {
            dispatch(requestNotes({ notable }));
            return api.fetchNotes(notable).then((result) => {
                dispatch(receiveNotes(result.data));
            });
        }
    };
}

function requestNoteDelete(note: NoteView): Action {
    return {
        payload: { note },
        type: RequestNoteDelete
    };
}

export function deleteNote(note: NoteView) {
    return (dispatch: Dispatch<State>) => {
        dispatch(requestNoteDelete(note));
        return api.deleteNote(note.id).then(() => {
            dispatch(fetchEntityNotes(note.notable));
        });
    };
}

function requestUserActions(payload: { target: string }): Action {
    return {
        payload,
        type: RequestUserActions
    };
}

function receiveUserActions(payload: { target: string; userActions: UserAction[] }): Action {
    return {
        payload,
        type: ReceiveUserActions
    };
}

export function fetchUserActions(target: string) {
    return (dispatch: Dispatch<State>) => {
        dispatch(requestUserActions({ target }));
        return api.fetchUserActions(target).then((result) => {
            dispatch(receiveUserActions(result.data));
        });
    };
}

function requestContactAdd(payload: { personId: string; channel: ContactChannel; value: string }): Action {
    return {
        payload,
        type: RequestContactAdd
    };
}

export function addPersonContact(payload: { personId: string; channel: ContactChannel; value: string }) {
    return (dispatch: Dispatch<State>) => {
        dispatch(requestContactAdd(payload));
        const { personId, channel, value } = payload;
        return api.addContact(personId, value, channel).then((result) => {
            if (result.success) {
                Analytics.trackContactAdded(personId, channel, value);
            }
            dispatch(receiveContactsUpdate(result.data));
        });
    };
}

function requestAddContactFromMessage(payload: {
    account: string;
    personId: string;
    value: string;
    messageId: string;
    threadId: string;
}): Action {
    return {
        payload,
        type: RequestAddContactFromMessage
    };
}

function receiveAddContactFromMessage(payload: {
    communications: Communication[];
    contacts: Contact[];
    personId: string;
    success: boolean;
}): Action {
    return { payload, type: ReceiveAddContactFromMessage };
}

export function addContactFromMessage(payload: {
    account: string;
    personId: string;
    value: string;
    messageId: string;
    threadId: string;
}) {
    return (dispatch: Dispatch<State>) => {
        dispatch(requestAddContactFromMessage(payload));
        const { account, personId, value, messageId } = payload;
        return api.addContactFromMessage(personId, account, messageId, value).then((result) => {
            if (result.success) {
                Analytics.trackContactAdded(personId, 'email', value);
            }
            const { communications, contacts } = result.data;
            dispatch(receiveAddContactFromMessage({ communications, contacts, personId, success: result.success }));
        });
    };
}

function requestIgnoreContactFromMessage(account: string, messageId: string, threadId: string): Action {
    return {
        payload: { account, messageId, threadId },
        type: RequestIgnoreContactFromMessage
    };
}

function receiveIgnoreContactFromMessage(
    account: string,
    messageId: string,
    threadId: string,
    communications: Communication[]
): Action {
    return {
        payload: { account, messageId, communications, threadId },
        type: ReceiveIgnoreContactFromMessage
    };
}

export function ignoreContactFromMessage(account: string, messageId: string, threadId: string) {
    return (dispatch: Dispatch<State>) => {
        dispatch(requestIgnoreContactFromMessage(account, messageId, threadId));
        return api.ignoreContactFromMessage(account, messageId).then((result) => {
            dispatch(receiveIgnoreContactFromMessage(account, messageId, threadId, result.data.communications));
        });
    };
}

function requestPersonUpdate(id: string, updates: Partial<Person>): Action {
    return {
        id,
        type: RequestPersonUpdate,
        updates
    };
}

function receivePersonUpdates(payload: Person): Action {
    return {
        payload,
        type: ReceivePersonUpdate
    };
}

export function updatePerson(id: string, updates: Partial<Person>) {
    return (dispatch: Dispatch<State>) => {
        dispatch(requestPersonUpdate(id, updates));
        return api.updatePerson(id, updates).then((result) => {
            dispatch(receivePersonUpdates(result.data));
        });
    };
}

function requestPersonDoNotEmailAgain(payload: { personId: string; note: string; clientId: string }): Action {
    return {
        payload,
        type: RequestPersonDoNotEmailAgain
    };
}

export function doNotEmailPerson(
    personId: string,
    payload: {
        note: string;
        clientId: string;
    }
) {
    return (dispatch: Dispatch<State>) => {
        const options = Object.assign({ personId }, payload);
        dispatch(requestPersonDoNotEmailAgain(options));
        return api.doNotEmailPerson(personId, payload).then((result) => {
            const receive = {
                payload: result.data,
                type: ReceivePersonOptOutUpdate
            };
            dispatch(receive);
        });
    };
}

function requestPersonNotInterestedAtThisTime(payload: {
    personId: string;
    jobId: string;
    note: string;
    optOutUntil: number;
}): Action {
    return {
        payload,
        type: RequestPersonNotInterestedAtThisTime
    };
}

export function personNotInterestedAtThisTime(
    personId: string,
    payload: {
        jobId: string;
        note: string;
        optOutUntil: number;
    }
) {
    return (dispatch: Dispatch<State>) => {
        const options = Object.assign({ personId }, payload);
        dispatch(requestPersonNotInterestedAtThisTime(options));
        return api.personNotInterestedAtThisTime(personId, payload).then((result) => {
            const receive = {
                payload: result.data,
                type: ReceivePersonOptOutUpdate
            };
            dispatch(receive);
        });
    };
}

function requestContactUpdate(payload: { personId: string; channel: ContactChannel }): Action {
    return {
        payload,
        type: RequestContactUpdate
    };
}

function receiveContactsUpdate(payload: {
    personId: string;
    contacts: Contact[];
    channel: ContactChannel;
    communications?: Communication[];
    errors?: RequestErrors;
    profileConflictIds: [string, string];
}): Action {
    return {
        payload: { communications: [], ...payload },
        type: ReceiveContactsUpdate
    };
}

export function updatePersonContact(
    personId: string,
    contact: Contact,
    attribute: keyof Contact,
    value: boolean | ContactType
) {
    return (dispatch: Dispatch<State>) => {
        dispatch(requestContactUpdate({ personId, channel: contact.channel }));
        return api.updateContact(personId, contact.value, contact.channel, attribute, value).then((result) => {
            dispatch(receiveContactsUpdate(result.data));
        });
    };
}

export function clearProfileConflicts(): Action {
    return {
        type: 'ClearProfileConflict'
    };
}

function requestPersonFilesUpload(personId: string): Action {
    return {
        payload: { personId },
        type: RequestPersonFilesUpload
    };
}

function receivePersonFilesUploadResult(personId: string, person: Person, errors: RequestErrors): Action {
    return {
        payload: {
            errors,
            person,
            personId
        },
        type: ReceivePersonFilesUploadResult
    };
}

export function addPersonFiles(personId: string, files: HrefFilePayload[]) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        const requestType = `person-files-upload-${personId}`;
        if (!getState().pendingRequests.get(requestType)) {
            dispatch(requestPersonFilesUpload(personId));
            return api.uploadPersonFile(personId, files).then((result) => {
                const person = result.success ? result.data.person : null;
                const errors = result.success ? null : result.data;
                dispatch(receivePersonFilesUploadResult(personId, person, errors));
            });
        }
    };
}

function requestJobOnePager(jobId: string): Action {
    return {
        payload: { jobId },
        type: RequestJobOnePager
    };
}

function receiveJobOnePagerResult(jobId: string, job: Job, errors: RequestErrors): Action {
    return {
        payload: {
            errors,
            job,
            jobId
        },
        type: ReceiveJobOnePagerResult
    };
}

export function addJobOnePager(jobId: string, file: HrefFilePayload) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        const requestType = `job-onepager-upload-${jobId}`;
        if (!getState().pendingRequests.get(requestType)) {
            dispatch(requestJobOnePager(jobId));
            return api.uploadJobOnePager(jobId, file).then((result) => {
                const job = result.success ? result.data.job : null;
                const errors = result.success ? null : result.data;
                dispatch(receiveJobOnePagerResult(jobId, job, errors));
            });
        }
    };
}

function requestPersonSearch(searchString: string): Action {
    return {
        payload: { searchString },
        type: RequestPersonSearch
    };
}

export function receivePersonSearchResult(searchResults: PersonSearchResults): Action {
    return {
        payload: { searchResults },
        type: ReceivePersonSearchResult
    };
}

export function searchForPerson(searchString: string) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        const requestType = `person-search`;
        if (!getState().pendingRequests.get(requestType)) {
            dispatch(requestPersonSearch(searchString));
            return api.searchForPerson(searchString).then((result) => {
                dispatch(receivePersonSearchResult(result.data));
            });
        }
    };
}

function requestSendSequenceToPerson(
    personId: string,
    jobId: string,
    sequenceId: string,
    variables: SequenceVariables
) {
    return {
        payload: { personId, jobId, sequenceId, variables },
        type: RequestSendSequenceToPerson
    };
}

function receiveSendSequenceToPerson(resultData: any) {
    return {
        payload: resultData,
        type: ReceiveSendSequenceToPerson
    };
}

export function sendSequenceToPerson(
    personId: string,
    jobId: string,
    account: string,
    sequenceId: string,
    variables: SequenceVariables,
    customStages: Array<{ subject: string; body: string }>
) {
    const scheduledMessagesFetchDelayMs = 2000;
    return (dispatch: Dispatch<State>) => {
        dispatch(requestSendSequenceToPerson(personId, jobId, sequenceId, variables));
        return api
            .requestSendSequenceToPerson(personId, jobId, account, sequenceId, variables, customStages)
            .then((result) => {
                dispatch(receiveSendSequenceToPerson(result.data));
                setTimeout(() => dispatch(getScheduledMessages(personId)), scheduledMessagesFetchDelayMs);
            });
    };
}

function requestUsers(): Action {
    return {
        type: RequestUsersList
    };
}

function receiveUsers(users: User[]): Action {
    return {
        payload: { users },
        type: ReceiveUsersList
    };
}

export function fetchUsers() {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        const requestType = 'users-list';
        if (!getState().pendingRequests.get(requestType)) {
            dispatch(requestUsers());
            return api.fetchUsers().then((result) => {
                dispatch(receiveUsers(result.data.users));
            });
        }
    };
}

function requestEmailAccountCreate(): Action {
    return {
        type: RequestEmailAccountCreate
    };
}

function receiveEmailAccountCreated(payload: { emails: EmailAccount[]; jobId: string }, error: string): Action {
    return {
        error,
        payload,
        type: ReceiveEmailAccountCreated
    };
}

function requestJobEmails(jobId: string) {
    return {
        payload: { jobId },
        type: RequestJobEmails
    };
}

function receiveJobEmails(payload: { jobId: string; emails: EmailAccount[] }, error: boolean): Action {
    return {
        error,
        payload,
        type: ReceiveJobEmails
    };
}

export function handleAddEmailAccount(userId: string, jobId: string, capacity: number) {
    return (dispatch: Dispatch<State>) => {
        dispatch(requestEmailAccountCreate());
        return api.addEmailAccount(userId, jobId, capacity).then((result) => {
            dispatch(receiveEmailAccountCreated({ jobId, emails: result.data.emails }, result.data.errors));
        });
    };
}

export function getJobEmails(jobId: string) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.has(`get-job-emails-${jobId}`)) {
            dispatch(requestJobEmails(jobId));
            return api.getJobEmails(jobId).then((result) => {
                dispatch(receiveJobEmails({ jobId, emails: result.data.emails }, !result.success));
            });
        }
    };
}

function requestClientData(clientId: string): Action {
    return {
        payload: { clientId },
        type: RequestClientData
    };
}

function receiveClientData(client: Client, error: boolean): Action {
    return {
        error,
        payload: { client },
        type: ReceiveClientData
    };
}

export function fetchClientById(clientId: string) {
    return (dispatch: Dispatch<State>) => {
        dispatch(requestClientData(clientId));
        return api.fetchClientById(clientId).then((result) => {
            dispatch(receiveClientData(result.data.client, !result.success));
        });
    };
}

function requestClientUpdate(clientId: string, updates: Partial<Client>): Action {
    return {
        payload: { clientId, updates },
        type: RequestClientUpdate
    };
}

function receiveClientUpdate(client: Client, error: boolean): Action {
    return {
        error,
        payload: { client },
        type: ReceiveClientUpdate
    };
}

export function updateClient(clientId: string, updates: Partial<Client>) {
    return (dispatch: Dispatch<State>) => {
        dispatch(requestClientUpdate(clientId, updates));
        return api.updateClient(clientId, updates).then((result) => {
            dispatch(receiveClientUpdate(result.data.client, !result.success));
            dispatch(requestToasterOpen('Client updated'));
        });
    };
}

function requestUploadClientPersonBlacklist(clientId: string): Action {
    return {
        payload: { clientId },
        type: RequestUploadClientPersonBlacklist
    };
}

function receiveUploadClientPersonBlacklist(client: Client, error: boolean): Action {
    return {
        error,
        payload: { client },
        type: ReceiveUploadClientPersonBlacklist
    };
}

export function uploadClientPersonBlacklist(clientId: string, files: string[]) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.has(`upload-client-person-blacklist-${clientId}`)) {
            dispatch(requestUploadClientPersonBlacklist(clientId));
            return api.uploadClientPersonBlacklist(clientId, files).then((result) => {
                dispatch(receiveUploadClientPersonBlacklist(result.data.client, !result.success));
            });
        }
    };
}

function requestClientFiles(clientId: string): Action {
    return {
        payload: { clientId },
        type: RequestClientFiles
    };
}

function receiveClientFilesResult(clientId: string, client: Client, errors: RequestErrors): Action {
    return {
        payload: {
            client,
            clientId,
            errors
        },
        type: ReceiveClientFilesResult
    };
}

export function uploadClientFiles(clientId: string, files: HrefFilePayload[]) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        const requestType = `client-files-upload-${clientId}`;
        if (!getState().pendingRequests.get(requestType)) {
            dispatch(requestClientFiles(clientId));
            return api.uploadClientFiles(clientId, files).then((result) => {
                const client = result.success ? result.data.client : null;
                const errors = result.success ? null : result.data;
                dispatch(receiveClientFilesResult(clientId, client, errors));
            });
        }
    };
}

function requestRemoveClientFile(clientId: string, key: string): Action {
    return {
        payload: { clientId, key },
        type: RequestRemoveClientFile
    };
}

function receiveRemoveClientFileResult(client: Client, clientId: string, key: string, errors: RequestErrors): Action {
    return {
        payload: { client, clientId, key, errors },
        type: ReceiveRemoveClientFileResult
    };
}

export function removeClientFile(clientId: string, key: string) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        const requestType = `remove-client-file-${clientId}-${key}`;
        if (!getState().pendingRequests.get(requestType)) {
            dispatch(requestRemoveClientFile(clientId, key));
            return api.removeClientFile(clientId, key).then((result) => {
                const client = result.success ? result.data.client : null;
                const errors = result.success ? null : result.data;
                dispatch(receiveRemoveClientFileResult(client, clientId, key, errors));
            });
        }
    };
}

function requestClientWeeklyUpdateData(clientId: string): Action {
    return {
        payload: {
            clientId
        },
        type: RequestClientWeeklyUpdateData
    };
}

function receiveClientWeeklyUpdateData(data: ClientUpdateData): Action {
    return {
        payload: { data },
        type: ReceiveClientWeeklyUpdateData
    };
}

export function fetchClientWeeklyUpdateData(clientId: string, startDate: number, endDate: number, all: boolean) {
    return (dispatch: Dispatch<State>) => {
        dispatch(requestClientWeeklyUpdateData(clientId));
        return api.fetchWeeklyUpdateData(clientId, startDate, endDate, all).then((result) => {
            dispatch(receiveClientWeeklyUpdateData(result.data));
        });
    };
}

export function updateUserPrefs(prefs: Partial<Preferences>) {
    return (dispatch: Dispatch<State>) => {
        for (const key of Object.keys(prefs)) {
            const value = (prefs as any)[key];
            const currentStoredValue = getLocalStorageKey(key, undefined);
            if (!isEqual(value, currentStoredValue)) {
                setLocalStorageKey(key, value, -1);
            }
        }
        dispatch({
            payload: { data: prefs },
            type: UpdateUserPrefs
        });
    };
}

export function toggleDrawer() {
    return {
        type: ToggleDrawer
    };
}

function requestMoveInGmail(account: string, threadId: string, action: string) {
    return {
        payload: { account, threadId, action },
        type: RequestMoveInGmail
    };
}

function receiveMoveInGmail(
    communications: Communication[],
    personId: string,
    success: boolean,
    candidates: Candidate[]
) {
    return {
        payload: { candidates, communications, personId, success },
        type: ReceiveMoveInGmail
    };
}

export function moveInGmail(account: string, personId: string, threadId: string, action: string) {
    return (dispatch: Dispatch<State>) => {
        dispatch(requestMoveInGmail(account, threadId, action));
        return api.moveInGmail(account, threadId, action).then((result) => {
            dispatch(receiveMoveInGmail(result.data.communications, personId, result.success, result.data.candidates));
            dispatch(syncAccountAndGetCommunications(account, personId));
        });
    };
}

export function archiveMessage(account: string, messageId: string, personId: string) {
    return {
        payload: { account, personId, messageId },
        type: ArchiveMessage
    };
}

function requestAllEmailAccountInfo() {
    return { type: RequestAllEmailAccountInfo };
}

function receiveAllEmailAccountInfo(payload: { emailAccounts: EmailAccount[] }): Action {
    return { payload, type: ReceiveAllEmailAccountInfo };
}

export function getAllEmailAccountInfo() {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.get(`get-all-email-accounts`)) {
            dispatch(requestAllEmailAccountInfo());
            return api.fetchAllEmailAccountInfo().then((result) => {
                dispatch(receiveAllEmailAccountInfo(result.data));
            });
        }
    };
}

function requestReassignEmailAccount(email: string, reassignTo: { owner?: string; jobId?: string }) {
    return { type: RequestReassignEmailAccount, payload: { email, reassignTo } };
}

function receiveReassignEmailAccounts(emailAccounts: EmailAccount[]) {
    return { type: ReceiveReassignEmailAccounts, payload: { emailAccounts } };
}

export function updateEmailAccountInfo(
    email: string,
    updates: { owners?: string[]; jobId?: string; syncStatus?: EmailSyncStatus }
) {
    return (dispatch: Dispatch<State>) => {
        dispatch(requestReassignEmailAccount(email, updates));
        return api.updateEmailAccount(email, updates).then((result) => {
            dispatch(receiveReassignEmailAccounts([result.data.emailAccount] as EmailAccount[]));
        });
    };
}

function requestJobSearchStats(jobId: string, userId: string): Action {
    return {
        payload: {
            jobId,
            userId
        },
        type: RequestJobSearchStats
    };
}

function receiveJobSearchStats(jobId: string, userId: string, data: JobSearchesState): Action {
    return {
        payload: {
            data,
            jobId,
            userId
        },
        type: ReceiveJobSearchStats
    };
}

export function fetchJobSearchStats(jobId: string, userId: string) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.has(`job-searches-stats-${jobId}-${userId}`)) {
            dispatch(requestJobSearchStats(jobId, userId));
            return api
                .fetchJobSearchStats(jobId, userId)
                .then((result) => dispatch(receiveJobSearchStats(jobId, userId, result.data)));
        }
    };
}

export function fetchCampaignSearchStats(jobId: string) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.has(`job-searches-stats-${jobId}`)) {
            dispatch(requestJobSearchStats(jobId, null));
            return api
                .fetchCampaignSearchStats(jobId)
                .then((result) => dispatch(receiveJobSearchStats(jobId, null, result.data)));
        }
    };
}

function requestJobSearchForSourcing(jobId: string): Action {
    return {
        payload: { jobId },
        type: RequestJobSearchForSourcing
    };
}

function receiveJobSearchForSourcing(
    jobId: string,
    selected: Search,
    searches: Search[],
    results: SearchResult[]
): Action {
    return {
        payload: { jobId, selected, searches, results },
        type: ReceiveJobSearchForSourcing
    };
}

export function resetJobSourcingSearch(jobId: string): Action {
    return {
        payload: { jobId },
        type: ResetJobSourcingSearch
    };
}

export function fetchJobSearchForSourcing(jobId: string, sortRank: number, direction: 'previous' | 'next') {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.has(`job-search-for-sourcing-${jobId}`)) {
            dispatch(requestJobSearchForSourcing(jobId));
            return api.getSearchForSourcing(jobId, sortRank, direction).then((result) => {
                const { searches, selected, results } = result.data;
                dispatch(receiveJobSearchForSourcing(jobId, selected, searches, results));
            });
        }
    };
}

function requestSearchResults(search: Partial<Search>): Action {
    return {
        payload: { search },
        type: RequestSearchResults
    };
}

function receiveSearchResults(payload: { search: Search; data: SearchResultsState }): Action {
    return {
        payload,
        type: ReceiveSearchResults
    };
}

export function fetchSearchResults(
    data: Partial<Search>,
    resultsType: SearchResultsViewType,
    isCampaign: boolean,
    limit?: number
) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.has('fetching-search-results')) {
            dispatch(requestSearchResults(data));
            const apiCall =
                data.status === SearchStatus.Initial
                    ? api.fetchSearchResultsPreview(data, resultsType, limit)
                    : api.fetchSearchResults(data, resultsType, isCampaign, limit);
            return apiCall
                .then((result) => {
                    if (result.success) {
                        dispatch(receiveSearchResults(result.data));
                    } else {
                        const error = result?.data?.error ?? 'Error fetching search results';
                        dispatch(
                            receiveSearchResults({ search: data as Search, data: { results: [], resultsType, error } })
                        );
                    }
                })
                .catch((err) => {
                    const error = err?.message ?? 'Error fetching search results';
                    dispatch(
                        receiveSearchResults({ search: data as Search, data: { results: [], resultsType, error } })
                    );
                    dispatch(requestToasterOpen(error, -1));
                });
        }
    };
}

function requestAddSearchResultToJob(jobId: string, result: SearchResult): Action {
    return {
        payload: { jobId, result },
        type: RequestAddSearchResultToJob
    };
}

function receiveAddSearchResultToJob(
    jobId: string,
    result: SearchResult,
    candidate: Candidate,
    person: Person,
    disqualified: boolean
): Action {
    return {
        payload: { jobId, result, candidate, person, disqualified },
        type: ReceiveAddSearchResultToJob
    };
}

export function addSearchResultToJob(jobId: string, result: SearchResult, disqualified: boolean) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.has(`adding-search-result-${jobId}-${result.searchId}-${result.personId}`)) {
            dispatch(requestAddSearchResultToJob(jobId, result));
            return api.addSearchResultToJob(jobId, result, disqualified).then((res) => {
                if (res.success) {
                    dispatch(
                        receiveAddSearchResultToJob(
                            jobId,
                            res.data.result,
                            res.data.candidate,
                            res.data.person,
                            disqualified
                        )
                    );
                    dispatch(
                        requestToasterOpen(disqualified ? `Candidate Rejected from Search` : `Candidate Added to Job`)
                    );
                }
            });
        }
    };
}

function requestSearchResultPersonAndCandidate(result: SearchResult): Action {
    return {
        payload: { result },
        type: RequestSearchResultPersonAndCandidate
    };
}

function receiveSearchResultPersonAndCandidate(result: SearchResult, candidate: Candidate, person: Person): Action {
    return {
        payload: { result, candidate, person },
        type: ReceiveSearchResultPersonAndCandidate
    };
}

export function fetchSearchResultPersonAndCandidate(result: SearchResult, jobId: string) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (
            !getState().pendingRequests.has(
                `fetch-search-result-person-candidate-${result.searchId}-${result.personId}`
            )
        ) {
            dispatch(requestSearchResultPersonAndCandidate(result));
            return api.fetchSearchResultPersonAndCandidate(result, jobId).then((res) => {
                if (res.success) {
                    dispatch(receiveSearchResultPersonAndCandidate(result, res.data.candidate, res.data.person));
                }
            });
        }
    };
}

function requestContactDelete(personId: string, channel: string, value: string): Action {
    return {
        payload: { personId, channel, value },
        type: RequestContactDelete
    };
}

function receiveContactDelete(personId: string, channel: string, value: string) {
    return {
        payload: { personId, channel, value },
        type: ReceiveContactDelete
    };
}

export function deletePersonContact(personId: string, channel: string, value: string) {
    return (dispatch: Dispatch<State>) => {
        dispatch(requestContactDelete(personId, channel, value));
        return api.deleteContact(personId, channel, value).then((result) => {
            if (result.success) {
                dispatch(receiveContactDelete(personId, channel, value));
            }
        });
    };
}

function requestSearchDelete(search: Partial<Search>): Action {
    return {
        payload: { search },
        type: RequestSearchDelete
    };
}

export function deleteSearch(search: Partial<Search>) {
    return (dispatch: Dispatch<State>) => {
        dispatch(requestSearchDelete(search));
        return api.deleteSearch(search.id).then(() => {
            dispatch(fetchJobSearchStats(search.jobId, search.userId));
        });
    };
}

function requestSearchUpdate(id: string): Action {
    return {
        payload: { id },
        type: RequestSearchUpdate
    };
}

function receiveUpdatedSearch(search: Search): Action {
    return {
        payload: { search },
        type: ReceiveUpdatedSearch
    };
}

export function updateSearch(id: string, updates: Partial<Search>, refetchSearches: boolean) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.has(`search-updates-${id}`)) {
            dispatch(requestSearchUpdate(id));
            return api.updateSearch(id, updates).then((result) => {
                dispatch(receiveUpdatedSearch(result.data));
                if (refetchSearches) {
                    if (result.data.project === SearchProject.HireflowV2) {
                        dispatch(fetchCampaignSearchStats(result.data.jobId));
                    } else {
                        dispatch(fetchJobSearchStats(result.data.jobId, result.data.userId));
                    }
                }
            });
        }
    };
}

function requestSearchProfiles(searchId: string): Action {
    return {
        payload: { searchId },
        type: RequestSearchProfiles
    };
}

function receiveSearchProfiles(
    searchId: string,
    data: {
        profiles: Profile[];
        companySimilarity: SimilarityScoreResult;
        crunchbaseData: { [url: string]: CrunchbaseData };
    }
): Action {
    return {
        payload: { searchId, ...data },
        type: ReceiveSearchProfiles
    };
}

export function fetchSearchProfiles(searchId: string, personIds: string[]) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.has(`search-profiles-${searchId}`)) {
            dispatch(requestSearchProfiles(searchId));
            return api.getSearchProfiles(searchId, personIds).then((result) => {
                dispatch(receiveSearchProfiles(searchId, result.data));
            });
        }
    };
}

function requestSearchCreate(): Action {
    return {
        type: RequestSearchCreate
    };
}

export function receiveNewSearch(search: Search): Action {
    return {
        payload: { search },
        type: ReceiveNewSearch
    };
}

export function createSearch(search: Partial<Search>) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.has(`search-create`)) {
            dispatch(requestSearchCreate());
            return api.initializeSearch(search).then((result) => {
                dispatch(receiveNewSearch(result.data));
            });
        }
    };
}

function requestSearchPurge(id: string): Action {
    return {
        payload: { id },
        type: RequestSearchPurge
    };
}

function receiveSearchPurge(id: string): Action {
    return {
        payload: { id },
        type: ReceiveSearchPurge
    };
}

export function purgeSearch(search: { id: string; jobId: string; userId: string }) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.has(`search-purge-${search.id}`)) {
            dispatch(requestSearchPurge(search.id));
            return api.purgeSearch(search.id).then(() => {
                dispatch(fetchJobSearchStats(search.jobId, search.userId));
                dispatch(receiveSearchPurge(search.id));
            });
        }
    };
}

function requestJobPurgeCandidates(id: string): Action {
    return {
        payload: { id },
        type: RequestPurgeCandidates
    };
}

function receiveJobPurgeCandidates(id: string): Action {
    return {
        payload: { id },
        type: ReceivePurgeCandidatesResponse
    };
}

export function purgeJobCandidates(jobId: string) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.has(`job-purge-${jobId}`)) {
            dispatch(requestJobPurgeCandidates(jobId));
            return api.purgeJobCandidates(jobId).then(() => {
                dispatch(receiveJobPurgeCandidates(jobId));
            });
        }
    };
}

export function refreshSearch(id: string) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.has(`search-updates-${id}`)) {
            dispatch(requestSearchUpdate(id));
            return api.refreshSearch(id).then((result) => {
                dispatch(receiveUpdatedSearch(result.data));
            });
        }
    };
}

function requestFileDownload(url: string): Action {
    return {
        payload: { url },
        type: RequestFileDownload
    };
}

function receiveFileDownload(url: string): Action {
    return {
        payload: { url },
        type: ReceiveFileDownload
    };
}

export function downloadFile(url: string, filename: string) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.has(`download-file-${url}`)) {
            dispatch(requestFileDownload(url));
            return api
                .downloadFile(url)
                .then((response) => {
                    if (response.ok) {
                        return response.blob();
                    } else {
                        dispatch(receiveFileDownload(url));
                        throw new Error(`error fetching file ${url}`);
                    }
                })
                .then((blob) => {
                    saveAs(blob, filename);
                    dispatch(receiveFileDownload(url));
                });
        }
    };
}

function requestPresets(): Action {
    return {
        type: RequestPresets
    };
}

function receivePresets(presets: SearchPreset[]): Action {
    return {
        payload: { presets },
        type: ReceivePresets
    };
}

export function fetchPresets() {
    return (dispatch: Dispatch<State>) => {
        dispatch(requestPresets());
        return api.requestPresets().then((result) => {
            const presets = result.data;
            dispatch(receivePresets(presets));
        });
    };
}

function requestSavePreset(): Action {
    return {
        type: RequestSavePreset
    };
}

function receivedSavePreset(preset: SearchPreset): Action {
    return {
        payload: { preset },
        type: ReceivedSavePreset
    };
}

export function savePreset(searchConfig: SearchConfig, name: string, group: string, groupLabel: string) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.get(`save-preset`)) {
            dispatch(requestSavePreset());
            return api.savePreset(searchConfig, name, group, groupLabel).then((result) => {
                const preset = result.data;
                dispatch(receivedSavePreset(preset));
            });
        }
    };
}

export function sendTestEmail(subject: string, body: string) {
    return (dispatch: Dispatch<State>) => {
        dispatch(requestToasterOpen('Sending Test Message'));
        return api.sendTestEmail(subject, body).then((result) => {
            const toasterMessage = result.success ? 'Sent Test Message!' : 'ERROR sending Test Message';
            dispatch(requestToasterOpen(toasterMessage));
        });
    };
}

function receiveJobsSearchesStats(stats: { [jobId: string]: JobSearchesStats }): Action {
    return {
        payload: { stats },
        type: ReceiveJobsSearchesStats
    };
}

function requestJobsSearchesStats(refresh: boolean): Action {
    return {
        payload: { refresh },
        type: RequestJobsSearchesStats
    };
}

export function fetchJobsSearchesStats(refresh: boolean) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().jobsSearchesStats.isFetching) {
            dispatch(requestJobsSearchesStats(refresh));
            return api.getJobsSearchesStats(refresh).then((result) => {
                const stats = result.data;
                dispatch(receiveJobsSearchesStats(stats));
            });
        }
    };
}

export function removeUnsavedLocalSearches(jobId: string) {
    return {
        payload: { jobId },
        type: RemoveUnsavedLocalSearches
    };
}

function receivePersonWebsiteAdds(payload: {
    person: Person;
    profileUrls: ProfileUrlRecord[];
    errors?: RequestErrors;
    profileConflictIds?: [string, string];
}): Action {
    return {
        payload,
        type: ReceivePersonWebsiteAdd
    };
}

function requestPersonWebsiteAdd(id: string): Action {
    return {
        id,
        type: RequestPersonWebsiteAdd
    };
}

export function addWebsite(payload: { personId: string; value: string }) {
    return (dispatch: Dispatch<State>) => {
        const { personId, value } = payload;
        dispatch(requestPersonWebsiteAdd(personId));
        return api.addWebsite(personId, value).then((result) => {
            dispatch(receivePersonWebsiteAdds(result.data));
        });
    };
}

function requestFlaggedEmails(account: string) {
    return {
        payload: { account },
        type: RequestFlaggedEmails
    };
}

function receiveFlaggedEmails(
    account: string,
    data: {
        threads: { [threadId: string]: Communication[] };
        total: number;
    }
) {
    return {
        payload: { account, data },
        type: ReceiveFlaggedEmails
    };
}

export function getFlaggedEmails(account: string, offset: number, limit: number) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.get(`flagged-emails-${account}`)) {
            dispatch(requestFlaggedEmails(account));
            return api.getFlaggedEmails(account, offset, limit).then((result) => {
                dispatch(receiveFlaggedEmails(account, result.data));
            });
        }
    };
}

function requestPersonCommunications(id: string): Action {
    return {
        payload: { id },
        type: RequestPersonCommunications
    };
}

function receivePersonCommunications(id: string, communications: Communication[], candidates: Candidate[]): Action {
    return {
        payload: {
            candidates,
            communications,
            id
        },
        type: ReceivePersonCommunications
    };
}

export function getCommunications(personId: string) {
    return (dispatch: Dispatch<State>) => {
        dispatch(requestPersonCommunications(personId));
        return api.getPersonCommunications(personId).then((result) => {
            const communications = result.success ? result.data.communications : undefined;
            const candidates = result.success ? result.data.candidates : undefined;
            dispatch(receivePersonCommunications(personId, communications, candidates));
        });
    };
}

export function syncAccountAndGetCommunications(account: string, personId: string) {
    return (dispatch: Dispatch<State>) => {
        dispatch(requestPersonCommunications(personId));
        return api.syncAccountAndGetCommunications(account, personId).then((result) => {
            const communications = result.success ? result.data.communications : undefined;
            const candidates = result.success ? result.data.candidates : undefined;
            dispatch(receivePersonCommunications(personId, communications, candidates));
        });
    };
}

function requestScheduledMessages(personId: string): Action {
    return { payload: { personId }, type: RequestScheduledMessages };
}

function receiveScheduledMessages(personId: string, scheduledMessages: ScheduledMessageView[]): Action {
    return { payload: { personId, scheduledMessages }, type: ReceiveScheduledMessages };
}

export function getScheduledMessages(personId: string) {
    return (dispatch: Dispatch<State>) => {
        dispatch(requestScheduledMessages(personId));
        return api.getPersonScheduledMessages(personId).then((result) => {
            dispatch(receiveScheduledMessages(personId, result.data.scheduledMessages));
        });
    };
}

function requestRescheduleMessage(scheduledMessage: ScheduledMessageView): Action {
    return {
        payload: { scheduledMessage },
        type: RequestRescheduleMessage
    };
}
function receiveRescheduleMessage(scheduledMessage: ScheduledMessageView, oldMessageId: string): Action {
    return {
        payload: { scheduledMessage, oldMessageId },
        type: ReceiveRescheduleMessage
    };
}

export function rescheduleMessage(
    scheduledMessage: ScheduledMessageView,
    newScheduledAt: number,
    personId: string,
    jobId: string
) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.has(`scheduled-messages-update-${scheduledMessage.id}`)) {
            dispatch(requestRescheduleMessage(scheduledMessage));
            return api
                .rescheduleMessage(scheduledMessage.account, scheduledMessage.id, newScheduledAt, personId, jobId)
                .then((result) => {
                    if (result.success) {
                        dispatch(receiveRescheduleMessage(result.data.scheduledMessage, scheduledMessage.id));
                    } else {
                        dispatch(requestToasterOpen('Error rescheduling message.'));
                    }
                });
        }
    };
}

function requestCancelScheduledMessage(id: string): Action {
    return {
        payload: { id },
        type: RequestCancelScheduledMessage
    };
}
function receiveCancelScheduledMessage(personId: string, id: string): Action {
    return {
        payload: { personId, id },
        type: ReceiveCancelScheduledMessage
    };
}

export function cancelScheduledMessage(scheduledMessage: ScheduledMessageView, personId: string, jobId: string) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.has(`scheduled-messages-update-${scheduledMessage.id}`)) {
            dispatch(requestCancelScheduledMessage(scheduledMessage.id));
            return api
                .cancelScheduledMessage(scheduledMessage.account, scheduledMessage.id, personId, jobId)
                .then(() => {
                    dispatch(receiveCancelScheduledMessage(personId, scheduledMessage.id));
                });
        }
    };
}

function requestCommunicationMatchDataUpdate(
    communication: Communication,
    updates: {
        ignored?: boolean;
        personIds?: string[];
        jobIds?: string[];
        error?: boolean;
        manualOverridePersonIds?: boolean;
        manualOverrideJobIds?: boolean;
    }
): Action {
    return {
        payload: {
            communication,
            updates
        },
        type: RequestCommunicationMatchDataUpdate
    };
}

function receiveCommunicationMatchDataUpdate(communications: Communication[]): Action {
    return {
        payload: { communications },
        type: ReceiveCommunicationMatchDataUpdate
    };
}

export function updateCommunicationMatchData(
    communication: Communication,
    data: {
        ignored?: boolean;
        personIds?: string[];
        jobIds?: string[];
        error?: boolean;
        manualOverridePersonIds?: boolean;
        manualOverrideJobIds?: boolean;
        bug?: boolean;
    }
) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.get(`update-match-data-${communication.account}-${communication.threadId}`)) {
            dispatch(requestCommunicationMatchDataUpdate(communication, data));
            return api.requestSetEmailMatch(communication.account, communication.messageId, data).then((result) => {
                dispatch(receiveCommunicationMatchDataUpdate(result.data));
            });
        }
    };
}

function requestCommunicationMatcher(communication: Communication): Action {
    return {
        payload: { communication },
        type: 'RequestCommunicationMatcher'
    };
}

function receiveCommunicationMatcher(communications: Communication[]): Action {
    return {
        payload: { communications },
        type: 'ReceiveCommunicationMatcher'
    };
}

export function requestCommunicationMatch(communication: Communication) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.get(`update-match-data-${communication.account}-${communication.threadId}`)) {
            dispatch(requestCommunicationMatcher(communication));
            dispatch(requestToasterOpen('Re-matching data'));
            return api.requestCommunicationMatch(communication.account, communication.threadId).then((result) => {
                dispatch(requestToasterOpen('Finished re-matching'));
                dispatch(receiveCommunicationMatcher(result.data));
            });
        }
    };
}

function requestForwardFlaggedEmail(account: string, messageId: string): Action {
    return {
        payload: { account, messageId },
        type: 'RequestForwardFlaggedEmail'
    };
}

function receiveForwardFlaggedEmail(communication: Communication): Action {
    return {
        payload: { communications: [communication] },
        type: 'ReceiveForwardFlaggedEmail'
    };
}

export function forwardFlaggedEmail(account: string, messageId: string, email: string) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.has(`forward-flagged-email-${account}-${messageId}`)) {
            dispatch(requestForwardFlaggedEmail(account, messageId));
            return api.requestForwardFlaggedEmail(account, messageId, email).then((result) => {
                dispatch(receiveForwardFlaggedEmail(result.data));
            });
        }
    };
}

function requestJobCandidatesSummary(jobId: string): Action {
    return {
        payload: { jobId },
        type: RequestJobCandidatesSummary
    };
}

function receiveJobCandidatesSummary(jobId: string, candidates: CandidateSummary[]): Action {
    return {
        payload: { jobId, candidates },
        type: ReceiveJobCandidatesSummary
    };
}

export function fetchJobCandidatesSummary(jobId: string, stages: string[]) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.get(`fetch-candidates-summary-${jobId}`)) {
            dispatch(requestJobCandidatesSummary(jobId));
            return api.fetchJobCandidatesSummary(jobId, stages).then((result) => {
                dispatch(receiveJobCandidatesSummary(jobId, result.data));
            });
        }
    };
}

export function addNoteAttachments(noteDraftKey: string, attachments: FilePayload[]): Action {
    return {
        payload: { noteDraftKey, attachments },
        type: AddNoteAttachments
    };
}

export function removeNoteAttachments(noteDraftKey: string): Action {
    return {
        payload: { noteDraftKey },
        type: RemoveNoteAttachments
    };
}

function requestCrossAddCandidate(personId: string, jobIds: string[], sourceJobId: string): Action {
    return {
        payload: {
            jobIds,
            personId,
            sourceJobId
        },
        type: RequestCrossAddCandidate
    };
}

function receiveCrossAddCandidate(candidates: Candidate[], communications: Communication[]): Action {
    return {
        payload: { candidates, communications },
        type: ReceiveCrossAddCandidate
    };
}

export function crossAddCandidate(
    personId: string,
    jobIds: string[],
    sourceJobId: string,
    newStage: string,
    source: 'cross-submit' | 'cross-add' | 'candidate-search-cross-add' | 'person-add'
) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.get(`cross-add-candidate-${personId}`)) {
            dispatch(requestCrossAddCandidate(personId, jobIds, sourceJobId));
            return api.crossAddCandidate(personId, jobIds, sourceJobId, newStage, source).then((result) => {
                if (result.success) {
                    const { candidates, communications } = result.data;
                    dispatch(receiveCrossAddCandidate(candidates, communications));
                }
            });
        }
    };
}

function receivePersonSetBlacklisted(payload: { person: Person; candidates: Candidate[] }): Action {
    return {
        payload,
        type: ReceivePersonSetBlacklisted
    };
}

function requestPersonSetBlacklisted(payload: { personId: string; blacklisted: boolean }): Action {
    return {
        payload,
        type: RequestPersonSetBlacklisted
    };
}

export function blacklistPerson(personId: string, blacklisted: boolean) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.get(`person-update-blacklisted-${personId}`)) {
            dispatch(requestPersonSetBlacklisted({ personId, blacklisted }));
            return api.updatePersonBlacklisted(personId, blacklisted).then((result) => {
                dispatch(receivePersonSetBlacklisted(result.data));
            });
        }
    };
}

function requestUserUpdate(userId: string): Action {
    return {
        payload: { userId },
        type: RequestUserUpdate
    };
}

function receiveUserUpdate(user: User): Action {
    return {
        payload: { user },
        type: ReceiveUserUpdate
    };
}

export function updateUser(userId: string, updates: Partial<UserData>) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.get(`user-update-${userId}`)) {
            dispatch(requestUserUpdate(userId));
            return api.updateUser(userId, updates).then((result) => {
                dispatch(receiveUserUpdate(result.data.user));
            });
        }
    };
}

function requestUpdatePersonFilename(payload: { path: string; filename: string; personId: string }): Action {
    return {
        payload,
        type: RequestUpdatePersonFilename
    };
}

function receiveUpdatePersonFilename(payload: { personId: string; files: PersonFile[]; path: string }): Action {
    return {
        payload,
        type: ReceiveUpdatePersonFilename
    };
}

export function updatePersonFilename(personId: string, path: string, newFilename: string) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.get(`update-filename-${personId}-${path}`)) {
            dispatch(requestUpdatePersonFilename({ path, filename: newFilename, personId }));
            return api.updatePersonFilename(personId, path, newFilename).then((result) => {
                dispatch(receiveUpdatePersonFilename({ personId, path, ...result.data }));
            });
        }
    };
}

function requestPauseJobSearches(jobId: string): Action {
    return {
        payload: { jobId },
        type: RequestPauseJobSearches
    };
}

function receivePauseJobSearches(jobId: string, searches: Search[]): Action {
    return {
        payload: { jobId, searches },
        type: ReceivePauseJobSearches
    };
}

export function pauseJobSearches(jobId: string) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.has(`job-${jobId}-pause-all-searches`)) {
            dispatch(requestPauseJobSearches(jobId));
            return api.pauseJobSearches(jobId).then((result) => {
                dispatch(receivePauseJobSearches(jobId, result.data.searches));
            });
        }
    };
}

function requestReassignCandidateAssignee(personId: string, jobId: string): Action {
    return {
        payload: { personId, jobId },
        type: RequestReassignCandidateAssignee
    };
}
function receiveReassignCandidateAssignee(candidate: Candidate): Action {
    return {
        payload: { candidate },
        type: ReceiveReassignCandidateAssignee
    };
}

export function reassignCandidateAssignee(personId: string, jobId: string, newAssigneeId: string) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        if (!getState().pendingRequests.has(`candidate-reassign-assignee-${personId}-${jobId}`)) {
            dispatch(requestReassignCandidateAssignee(personId, jobId));
            return api.reassignCandidateAssignee(personId, jobId, newAssigneeId).then((result) => {
                dispatch(receiveReassignCandidateAssignee(result.data.candidate));
            });
        }
    };
}

function requestAddProspects(campaignId: string) {
    return {
        payload: { campaignId },
        type: RequestAddProspectsToCampaign
    };
}

function receiveProspectsAdded(campaignId: string): Action {
    return {
        payload: { campaignId },
        type: ReceiveProspectsAddedToCampaign
    };
}

export function addProspectsToCampaign(campaignId: string, limit: number) {
    return (dispatch: Dispatch<State>) => {
        dispatch(requestAddProspects(campaignId));
        return api.addProspects({ campaignId, limit }).then((result) => {
            if (result.success) dispatch(receiveProspectsAdded(campaignId));
        });
    };
}

function requestAddSourcerMembers(sourcerId: string) {
    return {
        payload: { sourcerId },
        type: RequestAddSourcerMembersToSourcer
    };
}

function receiveSourcerMembersAdded(sourcerId: string): Action {
    return {
        payload: { sourcerId },
        type: ReceiveSourcerMembersAddedToSourcer
    };
}

export function addSourcerMembersToSourcer(sourcerId: string, limit: number) {
    return (dispatch: Dispatch<State>) => {
        dispatch(requestAddSourcerMembers(sourcerId));
        return api.addSourcerMembers({ sourcerId, limit }).then((result) => {
            if (result.success) dispatch(receiveSourcerMembersAdded(sourcerId));
        });
    };
}

export function receiveProfileLinkUpdate(payload: {
    personId: string;
    profileUrls: ProfileUrlRecord[];
    url: string;
}): Action {
    return {
        payload,
        type: ReceiveProfileLinkUpdate
    };
}

function requestProfileLinkUpdate(url: string): Action {
    return {
        payload: { url },
        type: RequestProfileLinkUpdate
    };
}

export function updateProfileLink(personId: string, url: string, invalid: boolean) {
    return (dispatch: Dispatch<State>) => {
        dispatch(requestProfileLinkUpdate(url));
        return api.updateProfileLink(personId, url, invalid).then((result) => {
            dispatch(receiveProfileLinkUpdate(result.data));
        });
    };
}

export function deleteProfileLink(personId: string, url: string) {
    return (dispatch: Dispatch<State>) => {
        dispatch(requestProfileLinkUpdate(url));
        return api.deleteProfileLink(personId, url).then((result) => {
            dispatch(receiveProfileLinkUpdate(result.data));
        });
    };
}
