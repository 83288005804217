import { MenuItem, TextField } from '@material-ui/core';
import * as React from 'react';

import { hiredStage, responseReceivedStage } from 'shared/models/job-stages';
import { maxRangeValue, SearchConfigPositiveResponse } from 'shared/models/search';

import { RangeSelectField } from '../core-ui/range-select-field';
import { useReduxState } from '../hooks/use-redux';
import { RadioButtons } from './search-radio-buttons';
import { SearchRequirementsSection } from './search-requirements-section';
import { useSearch } from './use-search';

export const SearchRocketProgress: React.FC = () => {
    const searchContext = useSearch();
    const config = searchContext.data.config.positiveResponse;
    const onChange = searchContext.onConfigFieldChange('positiveResponse');
    const readonly = searchContext.readonly;

    const jobStages = useReduxState((state) => state.appConstants.constants.jobStages);
    const configRef = React.useRef(config);
    configRef.current = config;

    if (!config) return null;

    const handleUpdateResponseRange = (min: number, max: number) => {
        const old = configRef.current;
        const newConfig = Object.assign({}, old, { minDays: min, maxDays: max });
        onChange(newConfig);
    };

    const handleUpdate =
        <T extends keyof SearchConfigPositiveResponse>(field: T) =>
        (val: SearchConfigPositiveResponse[T]) => {
            const old = configRef.current;
            const newConfig = Object.assign({}, old, { [field]: val });
            onChange(newConfig);
        };

    const handleMinStageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const old = configRef.current;
        const newConfig = Object.assign({}, old, { minStage: e.target.value });
        onChange(newConfig);
    };

    // tslint:disable:no-magic-numbers
    const responseOpts = [0, 1, 7, 14, 30, 60, 90, 120, 180, 365].map((v) => ({
        name: null,
        value: v
    }));
    responseOpts.push({ value: maxRangeValue, name: 'Max' });
    // tslint:enable
    const responseRangeField = (
        <div className="search-requirements-field with-context">
            <div style={{ flex: '1 1 auto' }}>
                <label className="search-label">Last stage change (days)</label>
                <div className="search-requirements-value range-value">
                    <RangeSelectField
                        readonly={readonly}
                        max={config.maxDays}
                        min={config.minDays}
                        maxValueOpts={responseOpts}
                        minValueOpts={responseOpts}
                        onChange={handleUpdateResponseRange}
                    />
                </div>
            </div>
        </div>
    );

    const responseReceivedIndex = jobStages.find((s) => s.name === responseReceivedStage).id;
    const hiredStageIndex = jobStages.find((s) => s.name === hiredStage).id;
    const getStageName = (stageName: string) => jobStages.find((s) => s.name === stageName)?.label || stageName;
    const stageOptions = jobStages
        .filter((s) => s.id >= responseReceivedIndex && s.id <= hiredStageIndex)
        .map((s) => (
            <MenuItem key={s.id} value={s.name}>
                {getStageName(s.name)}
            </MenuItem>
        ));

    const stageField = (
        <div className="search-requirements-field with-context">
            <div style={{ flex: '1 1 auto' }}>
                <div className="search-requirements-value range-value">
                    <TextField
                        value={config.minStage ?? ''}
                        label="Min stage progress"
                        fullWidth={true}
                        select={true}
                        onChange={handleMinStageChange}
                        InputProps={{ disableUnderline: true }}
                    >
                        {stageOptions}
                    </TextField>
                </div>
            </div>
        </div>
    );

    const options = [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' }
    ];

    return (
        <SearchRequirementsSection section="rocket-progress">
            <div className="search-requirements-field">
                <label className="search-label">Enabled</label>
                <div className="search-requirements-value radio-buttons">
                    <RadioButtons
                        name="Positive Emails"
                        selected={config.enabled}
                        options={options}
                        onChange={handleUpdate('enabled')}
                        readonly={readonly}
                    />
                </div>
            </div>
            {config.enabled ? responseRangeField : null}
            {config.enabled ? stageField : null}
        </SearchRequirementsSection>
    );
};
