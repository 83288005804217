import { formatEmail, getDomain } from 'shared/common/email-utils';
import { JobAdditionalAllowedEmails } from 'shared/models/runtime-constants';
import { UserData } from 'shared/models/user';

import { AddressField } from '../../email-compose/components/email-compose-window';
import { Client, Contact, EmailAccount, Job } from '../../state';
import { candidateCommsEmailAddress, clientCommsEmailAddress } from './emails';

interface EmailAddressHeaders {
    to: string[];
    cc: string[];
    bcc: string[];
}

type EmailKind = 'candidate' | 'client' | 'intro' | 'introReply';

interface EmailValidationSetting {
    validCandidateContact: boolean; // Union[primaryCandidateContact, nonPrimaryCandidateContact]
    primaryCandidateContact: boolean;
    nonPrimaryCandidateContact: boolean;

    clientEmail: boolean; // Union[clientDomain, clientHiringManager]
    clientDomain: boolean;
    clientHiringManager: boolean;

    rocketEmail: boolean; // Union[rocketEmail, rocketOutreachEmail]
    getRocketEmail: boolean;
    rocketOutreachEmail: boolean;

    jobAccountManager: boolean;

    clientComms: boolean;
    candidateComms: boolean;

    other: boolean;
}

// tslint:disable:object-literal-sort-keys
const allowedEmailSettings: {
    [emailKind in EmailKind]: EmailValidationSetting;
} = {
    candidate: {
        validCandidateContact: true,
        primaryCandidateContact: true,
        nonPrimaryCandidateContact: true,
        clientEmail: false,
        clientDomain: false,
        clientHiringManager: false,
        rocketEmail: true,
        getRocketEmail: true,
        rocketOutreachEmail: true,
        jobAccountManager: true,
        clientComms: false,
        candidateComms: true,
        other: true
    },
    intro: {
        validCandidateContact: true,
        primaryCandidateContact: true,
        nonPrimaryCandidateContact: true,
        clientEmail: true,
        clientDomain: true,
        clientHiringManager: true,
        rocketEmail: true,
        getRocketEmail: true,
        rocketOutreachEmail: true,
        jobAccountManager: true,
        clientComms: true,
        candidateComms: true,
        other: false
    },
    introReply: {
        validCandidateContact: true,
        primaryCandidateContact: true,
        nonPrimaryCandidateContact: true,
        clientEmail: true,
        clientDomain: true,
        clientHiringManager: true,
        rocketEmail: true,
        getRocketEmail: true,
        rocketOutreachEmail: true,
        jobAccountManager: true,
        clientComms: true,
        candidateComms: true,
        other: false
    },
    client: {
        validCandidateContact: false,
        primaryCandidateContact: false,
        nonPrimaryCandidateContact: false,
        clientEmail: true,
        clientDomain: true,
        clientHiringManager: true,
        rocketEmail: true,
        getRocketEmail: true,
        rocketOutreachEmail: true,
        jobAccountManager: true,
        clientComms: true,
        candidateComms: false,
        other: false
    }
};

const requiredEmailsSettings: { [emailKind in EmailKind]: EmailValidationSetting } = {
    candidate: {
        validCandidateContact: true,
        primaryCandidateContact: false,
        nonPrimaryCandidateContact: false,
        clientEmail: false,
        clientDomain: false,
        clientHiringManager: false,
        rocketEmail: false,
        getRocketEmail: false,
        rocketOutreachEmail: false,
        jobAccountManager: false,
        clientComms: false,
        candidateComms: false,
        other: false
    },
    intro: {
        validCandidateContact: true,
        primaryCandidateContact: false,
        nonPrimaryCandidateContact: false,
        clientEmail: true,
        clientDomain: false,
        clientHiringManager: false,
        rocketEmail: false,
        getRocketEmail: false,
        rocketOutreachEmail: false,
        jobAccountManager: false,
        clientComms: false,
        candidateComms: false,
        other: false
    },
    introReply: {
        validCandidateContact: false,
        primaryCandidateContact: false,
        nonPrimaryCandidateContact: false,
        clientEmail: false,
        clientDomain: false,
        clientHiringManager: false,
        rocketEmail: false,
        getRocketEmail: false,
        rocketOutreachEmail: false,
        jobAccountManager: false,
        clientComms: false,
        candidateComms: false,
        other: false
    },
    client: {
        validCandidateContact: false,
        primaryCandidateContact: false,
        nonPrimaryCandidateContact: false,
        clientEmail: true,
        clientDomain: false,
        clientHiringManager: false,
        rocketEmail: false,
        getRocketEmail: false,
        rocketOutreachEmail: false,
        jobAccountManager: false,
        clientComms: true,
        candidateComms: false,
        other: false
    }
};
// tslint:enable

const requirementsErrorMessage: {
    [key in keyof EmailValidationSetting]: string;
} = {
    candidateComms: 'candidatecomms@getrocket.com is required',
    clientComms: 'clientcomms@getrocket.com is required',
    clientDomain: 'An email with a client domain is required',
    clientEmail: "An email with a client domain or one of the client's hiring manager is required",
    clientHiringManager: 'A hiring manager of the client is required',
    getRocketEmail: 'A @getrocket.com email is required',
    jobAccountManager: "The job's account manager is required",
    nonPrimaryCandidateContact: "A candidate email that is not the candidate's primary email is required",
    other: 'An email that is not a client email, a candidate email, nor a rocket email is required', // never
    primaryCandidateContact: "The candidate's primary email is required",
    rocketEmail: 'A rocket email is required',
    rocketOutreachEmail: 'A rocket email assigned to a job is required',
    validCandidateContact: 'A valid candidate email is required'
};

/**
 * Determines if the headers for an email is valid and should be allowed to send
 *
 * @param data      Data used to determine if the emails are valid given the rules defined
 * @param emails    Emails in the header of the email compose window
 * @return { notAllowed: string[]; valid: boolean; requirementsErrors: string[] }
 *  @param notAllowed         An array of not allowed email addresses
 *  @param requirementsErrors Additional error messages to show the user.
 *                            Unlike errors, these error messages are no related to any specific email.
 *                            Rather they're whether an email type is missing from emails.
 *  @param valid              Whether emails is valid.
 *                            Condition: notAllowed and requirementsErrors are empty
 */
function headerValidator(
    data: {
        emailKind: EmailKind;
        initialParticipants: EmailAddressHeaders;
        personContacts: Contact[];
        emailAccounts: EmailAccount[];
        users: UserData[];
        jobAdditionalAllowedEmails: JobAdditionalAllowedEmails[];
        client: Client; // can be null
        job: Job; // can be null
    },
    emails: Array<{ address: string; field: AddressField }>
): {
    notAllowed: string[];
    valid: boolean;
    requirementsErrors: string[];
} {
    const {
        emailKind,
        initialParticipants,
        personContacts,
        client,
        emailAccounts,
        users,
        job,
        jobAdditionalAllowedEmails
    } = data;
    const rocketOutreachEmails = new Set(
        emailAccounts
            .filter((e) => !!e.jobId && e.syncStatus === 'enabled' && !e.email.match(/@getrocket.com$/))
            .map((e) => e.email.toLowerCase())
    );
    const getRocketEmails = new Set(
        users
            .map((e) => e.email.toLowerCase())
            .concat(emailAccounts.filter((e) => e.email.match(/@getrocket.com$/)).map((e) => e.email.toLowerCase()))
    ); // Assumes users will always be getrocket.com emails
    const clientDomains = new Set(client.domains);
    const hiringManagers = new Set(client.hiringManagers.map((h) => h.email.toLowerCase()));
    const accountManagerEmail = job.accountManagerId ? users.find((u) => u.id === job.accountManagerId).email : null;
    const allowedSettings = allowedEmailSettings[emailKind];
    const requiredSettings = requiredEmailsSettings[emailKind];
    const additionalAllowedEmails = job ? jobAdditionalAllowedEmails.find((j) => j.jobId === job.id) : null;

    /* Initialize metRequirements to be the opposite of requiredSettings
     * If "validCandidateContact" is required, then requiredSettings.validCandidateContact = true;
     * We should then set metRequirements.validCandidateContact = false so that we can set it to true
     * after confirming that it was met
     * If it's set required, then the requirement is already met (hence false -> true)
     */
    const metRequirements: EmailValidationSetting = { ...requiredSettings };
    for (const key of Object.keys(metRequirements) as Array<keyof EmailValidationSetting>) {
        metRequirements[key] = !requiredSettings[key];
    }
    const notAllowed: string[] = [];
    for (const email of emails) {
        const { field } = email;
        const address = formatEmail(email.address);
        let error = true;

        const isClientDomain = clientDomains.has(getDomain(address));
        const isHiringManager = hiringManagers.has(address.toLowerCase());
        const isOutreachEmail = rocketOutreachEmails.has(address.toLowerCase());
        const isGetRocketEmail = getRocketEmails.has(address.toLowerCase());
        const isAccountManagerEmail = accountManagerEmail.toLowerCase() === address.toLowerCase();
        const isClientComms = address.toLowerCase() === clientCommsEmailAddress.address.toLowerCase();
        const isCandidateComms = address.toLowerCase() === candidateCommsEmailAddress.address.toLowerCase();
        const anyRocketEmail = new Set(
            emailAccounts.map((e) => e.email.toLowerCase()).concat(users.map((u) => u.email.toLowerCase()))
        );
        const personContact = personContacts.find((c) => c.value.toLowerCase() === address.toLowerCase());

        if (initialParticipants[field].includes(address)) {
            error = false;
        }
        if (additionalAllowedEmails) {
            const { emails: allowedEmails } = additionalAllowedEmails;
            if (allowedEmails.includes(address)) {
                error = false;
            }
        }

        if (personContact && !personContact.invalid) {
            metRequirements.validCandidateContact = true;
            if (personContact.primary) {
                metRequirements.primaryCandidateContact = true;
            } else {
                metRequirements.nonPrimaryCandidateContact = true;
            }
            if (
                allowedSettings.validCandidateContact ||
                (allowedSettings.primaryCandidateContact && personContact.primary) ||
                (allowedSettings.nonPrimaryCandidateContact && !personContact.primary)
            ) {
                error = false;
            }
        } else if (isClientDomain || isHiringManager) {
            if (isClientDomain) {
                metRequirements.clientEmail = true;
                metRequirements.clientDomain = true;
            }
            if (isHiringManager) {
                metRequirements.clientEmail = true;
                metRequirements.clientHiringManager = true;
            }
            if (
                ((isClientDomain || isHiringManager) && allowedSettings.clientEmail) ||
                (client.domains.includes(getDomain(address)) && allowedSettings.clientDomain) ||
                (isHiringManager && allowedSettings.clientHiringManager)
            ) {
                error = false;
            }
        } else if (isOutreachEmail || isGetRocketEmail) {
            if (isOutreachEmail) {
                metRequirements.rocketEmail = true;
                metRequirements.rocketOutreachEmail = true;
            }
            if (isGetRocketEmail) {
                metRequirements.rocketEmail = true;
                metRequirements.getRocketEmail = true;
            }
            if (
                ((isOutreachEmail || isGetRocketEmail) && allowedSettings.rocketEmail) ||
                (isOutreachEmail && allowedSettings.rocketOutreachEmail) ||
                (isGetRocketEmail && allowedSettings.rocketEmail)
            ) {
                error = false;
            }
        } else if (isAccountManagerEmail) {
            metRequirements.jobAccountManager = true;
            if (isAccountManagerEmail && allowedSettings.jobAccountManager) {
                error = false;
            }
        } else if (isClientComms) {
            metRequirements.clientComms = true;
            if (allowedSettings.clientComms) {
                error = false;
            }
        } else if (isCandidateComms) {
            metRequirements.candidateComms = true;
            if (allowedSettings.candidateComms) {
                error = false;
            }
        } else if (
            !personContact &&
            !isClientDomain &&
            !isHiringManager &&
            !isOutreachEmail &&
            !isGetRocketEmail &&
            !isAccountManagerEmail &&
            !isClientComms &&
            !isCandidateComms &&
            !anyRocketEmail.has(address) &&
            allowedSettings.other
        ) {
            error = false;
        }

        if (error) {
            notAllowed.push(address);
        }
    }

    const requirementsErrors: string[] = [];
    for (const key of Object.keys(metRequirements) as Array<keyof EmailValidationSetting>) {
        if (!metRequirements[key]) {
            requirementsErrors.push(requirementsErrorMessage[key]);
        }
    }

    return {
        notAllowed,
        requirementsErrors,
        valid: notAllowed.length === 0 && requirementsErrors.length === 0
    };
}

export { headerValidator, EmailKind };
