import { css } from '@emotion/core';
import { IconButton, Tooltip } from '@material-ui/core';
import { Star, StarBorder, StarHalf } from '@material-ui/icons';
import React, { useEffect, useRef, useState } from 'react';

interface StarRatingProps {
    rating: number;
    maxRating?: number;
    htmlColor?: string;
    hoverHtmlColor?: string;
    onChange?: (rating: number) => void;
    onClick?: (e: React.MouseEvent) => void;
    tooltip?: string;
}

const defaultMaxRating = 5;
const ratingStep = 0.5;
const defaultHtmlColor = 'white';

const styles = css`
    display: flex;
    align-items: center;

    .star-rating-buttons {
        .MuiIconButton-sizeSmall {
            padding: 1px;
        }
    }

    .star-rating-value {
        font-size: 16px;
        font-weight: 700;
        margin-left: 5px;
        width: 36px;
        display: inline-flex;
        justify-content: center;
    }
`;

export const StarRating: React.FC<StarRatingProps> = ({
    maxRating = defaultMaxRating,
    htmlColor: nonHoveredHtmlColor = defaultHtmlColor,
    hoverHtmlColor = defaultHtmlColor,
    onChange,
    onClick,
    rating,
    tooltip
}) => {
    const [hoveredRating, setHoveredRating] = useState<number | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleGlobalMouseMove = (event: MouseEvent) => {
            if (containerRef.current) {
                const { left, right, top, bottom } = containerRef.current.getBoundingClientRect();
                const isOutside =
                    event.clientX < left || event.clientX > right || event.clientY < top || event.clientY > bottom;
                if (isOutside) {
                    setHoveredRating(null);
                }
            }
        };

        if (hoveredRating !== null) {
            document.addEventListener('mousemove', handleGlobalMouseMove);
        } else {
            document.removeEventListener('mousemove', handleGlobalMouseMove);
        }

        return () => {
            document.removeEventListener('mousemove', handleGlobalMouseMove);
        };
    }, [hoveredRating]);

    const handleMouseMove = (starIndex: number) => (event: React.MouseEvent<HTMLButtonElement>) => {
        if (onChange) {
            const { left, width } = event.currentTarget.getBoundingClientRect();
            const mouseX = event.clientX - left; // Mouse X position relative to the star element
            const isHoveringLeft = mouseX < width / 2; // Determine if hovering on the left or right half
            const newHoveredRating = isHoveringLeft ? starIndex - ratingStep : starIndex;
            setHoveredRating(newHoveredRating);
        }
    };

    const handleMouseLeave = () => {
        setHoveredRating(null);
    };

    const handleClick = () => {
        onChange(hoveredRating);
    };

    const formatRating = (val: number) => {
        return !val ? '' : val.toFixed(1);
    };

    const htmlColor = hoveredRating === null ? nonHoveredHtmlColor : hoverHtmlColor;

    const renderStarIcon = (starIndex: number) => {
        if (hoveredRating !== null) {
            if (starIndex <= hoveredRating) {
                // full star
                return <Star htmlColor={htmlColor} />;
            } else if (hoveredRating + ratingStep === starIndex) {
                // half star
                return <StarHalf htmlColor={htmlColor} />;
            }
        } else if (starIndex <= rating) {
            return <Star htmlColor={htmlColor} />;
        } else if (starIndex === rating + ratingStep) {
            return <StarHalf htmlColor={htmlColor} />;
        }

        // default to empty star
        return <StarBorder htmlColor={htmlColor} />;
    };

    const starIcons = Array.from({ length: maxRating }, (_, i) => (
        <IconButton
            size="small"
            key={i}
            onClick={handleClick}
            onMouseMove={handleMouseMove(i + 1)}
            disabled={!onChange}
        >
            {renderStarIcon(i + 1)}
        </IconButton>
    ));

    return (
        <div css={styles}>
            <div className="star-rating-buttons" onMouseLeave={handleMouseLeave} ref={containerRef}>
                {starIcons}
            </div>
            <Tooltip title={tooltip ?? ''}>
                <div
                    className="star-rating-value"
                    style={{ color: nonHoveredHtmlColor, cursor: onClick ? 'pointer' : 'default' }}
                    onClick={onClick}
                >
                    {formatRating(hoveredRating ?? rating)}
                </div>
            </Tooltip>
        </div>
    );
};
