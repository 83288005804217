import * as React from 'react';

import { amRejected, amRequestedInfo, awaitingAMApproval } from 'shared/models/job-stages';
import { NoteView } from 'shared/models/note';
import { Permissions } from 'shared/models/permission';
import { hasRole, UserData } from 'shared/models/user';

import { Candidate, Job } from '../state';
import { SubmissionNoteAMActions } from './submission-note-am-actions';
import { SubmissionNoteReply } from './submission-note-reply';
import { SubmissionNoteResubmit } from './submission-note-resubmit';
import { SubmissionNoteStatusActions } from './submission-note-status-actions';

interface SubmissionNoteActionProps {
    note: NoteView;
    job: Job;
    candidate: Candidate;
    user: UserData;
    userPermissions: Permissions;
}
export const SubmissionNoteActions: React.FC<SubmissionNoteActionProps> = (props) => {
    const { note, job, candidate, user, userPermissions } = props;
    if (!job || !candidate || (!note.context.isSubmissionNote && !note.context.isSubmissionNoteChangeRequest)) {
        return null;
    }

    // When the note form from the SubmissionNoteAMActions, SubmissionNoteReply is active, the other should NOT show
    const [amNoteFormActive, setAmNoteFormActive] = React.useState(false);

    const handleToggleAmNoteFormActive = (isActive: boolean) => {
        setAmNoteFormActive(isActive);
    };

    // User is the candidate assignee and the AM has requested info or rejected
    const assigneeActions =
        user.id === candidate.assignee &&
        (candidate.stage === amRequestedInfo || candidate.stage === amRejected) &&
        !note.context.isSubmissionNoteChangeRequest &&
        !note.context.isATSSubmissionNote &&
        !note.context.isSubmissionNoteReply ? (
            <SubmissionNoteResubmit candidate={candidate} />
        ) : null;

    // User is the AM and the candidate is in the process of being approved by the candidate
    // the note is the submission note (not a reply, not a change request)
    const amActions =
        (user.id === job.accountManagerId || hasRole(userPermissions, 'submit_approver')) &&
        (candidate.stage === awaitingAMApproval || candidate.stage === amRequestedInfo) &&
        !note.context.isSubmissionNoteChangeRequest &&
        !note.context.isATSSubmissionNote &&
        !note.context.isSubmissionNoteReply ? (
            <SubmissionNoteAMActions note={note} candidate={candidate} onNoteToggle={handleToggleAmNoteFormActive} />
        ) : null;
    // A change is needed in the note by someone that is not the current user
    // the note is the submission note (not a reply, not a change request)
    // The AM has not opened a note form to reject the candidate
    // i.e.: recruiter is waiting on the AM to approve or AM is waiting on the recruiter to edit submission note
    const statusActions =
        candidate.assignee !== job.accountManagerId &&
        ((user.id === candidate.assignee && candidate.stage === awaitingAMApproval) ||
            (user.id === job.accountManagerId && candidate.stage === amRequestedInfo)) &&
        !amNoteFormActive &&
        !note.context.isSubmissionNoteChangeRequest &&
        !note.context.isATSSubmissionNote &&
        !note.context.isSubmissionNoteReply ? (
            <SubmissionNoteStatusActions note={note} />
        ) : null;
    // The user is the assignee and changes have been requested by the AM. The button shows only for change request notes
    const replyAction =
        candidate.assignee === user.id &&
        (candidate.stage === amRequestedInfo || candidate.stage === amRejected) &&
        note.context.isSubmissionNoteChangeRequest ? (
            <SubmissionNoteReply note={note} />
        ) : null;

    if (amActions || statusActions || replyAction || assigneeActions) {
        return (
            <div className="submission-note-actions">
                {assigneeActions}
                {amActions}
                {statusActions}
                {replyAction}
            </div>
        );
    } else {
        return null;
    }
};
