import { css } from '@emotion/core';
import { Avatar, Chip, CircularProgress, TextField } from '@material-ui/core';
import { Autocomplete, RenderInputParams } from '@material-ui/lab';
import { debounce, escapeRegExp, isEqual } from 'lodash';
import * as React from 'react';

import { standardizeUrl } from 'profile-parser';
import { splitStringToParts } from '../lib/text-utils';
import { PresetChip } from './preset-chip';

interface TextfieldWithChipsProps<T> {
    onInputChange?: (value: string) => void;
    separatorKeys?: string[];
    hintText?: string;
    options?: T[];
    chips: T[];
    readonly: boolean;
    onChange: (chips: T[]) => void;
    getChipFromString: (text: string, dataSource?: boolean) => T;
    getStringFromChip: (chip: T) => string;
    getChipStyle?: (value: T) => React.CSSProperties;
    prefixChips?: JSX.Element;
    loading?: boolean;
    renderOption?: (option: T) => React.ReactNode;
}

const textFieldStyles = css`
    .MuiInput-input.Mui-disabled {
        display: none;
    }

    .MuiInputBase-multiline {
        padding-top: 0;
    }

    .MuiInput-input {
        min-width: 100px;
    }

    .MuiInput-underline:before {
        border-bottom: none;
    }

    .MuiInput-underline:after {
        border-bottom: none;
    }

    &:hover {
        .MuiInput-underline:before {
            border-bottom: none;
        }

        .MuiInput-underline:after {
            border-bottom: none;
        }
    }
`;

const textFieldWithChipsStyles = css`
    display: flex;
    flex-wrap: wrap;
`;

const textInputDebounceDelayMs = 200;
const loadingSize = 20;

export function TextfieldWithChips<T>({
    hintText = 'Add',
    options = [],
    chips,
    readonly,
    onChange,
    getChipFromString,
    getStringFromChip,
    getChipStyle,
    prefixChips,
    onInputChange,
    loading,
    renderOption
}: TextfieldWithChipsProps<T>) {
    const [value, setValue] = React.useState('');

    // Create a debounced version of the function passed from the parent
    // useCallback ensures this function isn't recreated on every render
    const debouncedOnChange = React.useCallback(
        debounce((newValue: string) => {
            onInputChange?.(newValue);
        }, textInputDebounceDelayMs),
        [onInputChange] // onInputChange is a dependency here
    );

    const handleKeyPress = (e: React.KeyboardEvent<{}>) => {
        if (e.key === 'Backspace') {
            if (value.length === 0) {
                onChange(chips.slice(0, chips.length - 1));
            }
        }
    };

    const handleInputChange = (_2: any, newInputValue: string, reason: string) => {
        if (reason !== 'reset') {
            // Auto complete seems to reset the input value on a keystroke
            setValue(newInputValue);
            debouncedOnChange(newInputValue); // Call the debounced function
        }
    };

    const handleRemove = (index: number) => () => {
        const newChips = chips.slice(0, index).concat(chips.slice(index + 1));
        onChange(newChips);
    };

    const handleChange = (_1: any, values: T[], reason: string) => {
        let newChips = values;
        if (reason === 'create-option') {
            const addedValue = values[values.length - 1];
            if (typeof addedValue === 'string') {
                const addedChips = splitStringToParts(addedValue)
                    .map((text) => text.trim())
                    .filter((text) => text.length > 0)
                    .map<T>((text) => {
                        return getChipFromString(text);
                    });
                newChips = [...values.slice(0, values.length - 1), ...addedChips];
            }
        }
        onChange(newChips);
        setValue('');
        debouncedOnChange('');
    };

    const chipStyle = (chip: T) => {
        return getChipStyle ? getChipStyle(chip) : {};
    };

    const hint = chips.length > 0 ? 'Add' : hintText;

    const renderInput = (params: RenderInputParams) => {
        const inputProps = {
            ...params.InputProps,
            endAdornment: (
                <>
                    {loading ? <CircularProgress color="inherit" size={loadingSize} /> : null}
                    {params.InputProps.endAdornment}
                </>
            )
        };

        const prependChips = chips.length > 0 ? null : prefixChips;

        return (
            <div css={textFieldWithChipsStyles}>
                {prependChips}
                <TextField
                    css={textFieldStyles}
                    placeholder={hint}
                    {...params}
                    InputProps={inputProps}
                    variant="standard"
                    fullWidth={true}
                    multiline={true}
                    onKeyPress={handleKeyPress}
                    inputProps={{ ...params.inputProps, autoComplete: 'new-password' }}
                />
            </div>
        );
    };

    const filterOptions = (opts: T[], state: { inputValue: string }) => {
        const matcher = new RegExp(escapeRegExp(state?.inputValue) ?? '', 'i');
        return opts.filter(
            (option) => matcher.test(getStringFromChip(option)) && chips.findIndex((c) => isEqual(c, option)) === -1
        );
    };

    const handleChipClick = (url: string) => (_1: React.MouseEvent) => {
        const formattedUrl = 'https://' + standardizeUrl(url).replace(/\/recruiter\//, '/');
        window.open(formattedUrl, '_blank');
    };

    const renderTags = (values: T[]) => {
        const tags = values.map((c, i) => {
            const style = chipStyle(c);
            const onDelete = readonly ? undefined : handleRemove(i);
            const label = getStringFromChip(c);
            const chipValue = c.hasOwnProperty('value') ? (c as any).value : undefined;
            const avatar = chipValue?.match(/www\.linkedin\.com/) ? (
                <Avatar>
                    <i className="fab fa-linkedin-in" />
                </Avatar>
            ) : undefined;
            const chip = c.hasOwnProperty('presetId') ? (
                <PresetChip key={i} style={style} onDelete={onDelete} label={label} presetId={(c as any).presetId} />
            ) : (
                <Chip
                    avatar={avatar}
                    key={i}
                    style={style}
                    onDelete={onDelete}
                    label={label}
                    onClick={avatar ? handleChipClick(chipValue) : undefined}
                />
            );
            return (
                <div className={`textfield-with-chips-chip ${readonly ? 'readonly' : ''}`} key={i}>
                    {chip}
                </div>
            );
        });
        return (
            <>
                {prefixChips}
                {tags}
            </>
        );
    };

    return (
        <div className="textfield-with-chips">
            <Autocomplete
                freeSolo={true}
                fullWidth={true}
                disabled={readonly}
                multiple={true}
                options={options}
                value={chips ?? []}
                filterOptions={filterOptions}
                renderInput={renderInput}
                renderOption={renderOption ?? getStringFromChip}
                renderTags={renderTags}
                onChange={handleChange}
                inputValue={value}
                onInputChange={handleInputChange}
                disableClearable={true}
                loading={loading}
            />
        </div>
    );
}
