import { useSubscription } from '@apollo/client';
import { Map } from 'immutable';
import * as React from 'react';

import { EmailTemplateView } from 'shared/models/email-templates';
import { NoteView } from 'shared/models/note';

import { fetchEntityNotes } from '../actions';
import { Note as NoteComponent } from '../components/note';
import { NoteForm } from '../components/note-form';
import { Spinner } from '../core-ui/spinner';
import { ENTITY_LATEST_NOTE_MODIFIED_AT } from '../graphql/queries/notes';
import { useReduxDispatch, useReduxState } from '../hooks/use-redux';
import { State } from '../state';

interface DefaultProps {
    actions: Array<{ text: string; handler: () => void }>;
    notesFilter: (note: NoteView) => boolean;
    templateInterpolators: Map<string, (body: string) => string>;
}

interface NotesProps extends Partial<DefaultProps> {
    notable: string;
    context: { [field: string]: string };
    startingTemplates: EmailTemplateView[];
    noteDraftKey: string;
    templateLabels: Map<string, string>;
    noteFormContext?: { [field: string]: string };
}

export const Notes: React.FC<NotesProps> = ({
    actions = [],
    notesFilter = (_1: NoteView) => true,
    templateInterpolators = Map(),
    notable,
    context,
    startingTemplates,
    noteDraftKey,
    templateLabels,
    noteFormContext
}) => {
    const notes = useReduxState((state: State) => state.notes);
    const dispatch = useReduxDispatch();
    const { data: notesSubData } = useSubscription<{ notes: Array<{ modifiedAt: number }> }, { notable: string }>(
        ENTITY_LATEST_NOTE_MODIFIED_AT,
        { variables: { notable } }
    );

    React.useEffect(() => {
        dispatch(fetchEntityNotes(notable));
    }, [notable, notesSubData?.notes?.[0]?.modifiedAt]);

    const entityNotes = notes.get(notable);

    if (!entityNotes) return <Spinner />;

    const notesSeq = entityNotes.filter(notesFilter).valueSeq();

    const displayChildren = (parentNote: NoteView) => {
        return notesSeq
            .filter((note) => note.parentId === parentNote.id && !note.deleted)
            .sort((n1, n2) => ((n1.lastEditedAt ?? n1.createdAt) > (n2.lastEditedAt ?? n2.createdAt) ? 1 : -1))
            .map((note) => (
                <div key={note.id} className={'note-child'}>
                    <NoteComponent note={note} context={context} />
                </div>
            ));
    };

    const allNotes = notesSeq
        .filter((note) => note.parentId === null)
        .filter((parentNote) => {
            const childSeq = notesSeq.filter((childNote) => childNote.parentId === parentNote.id && !childNote.deleted);
            return !parentNote.deleted || childSeq.count() !== 0;
        })
        .map((parentNote) => {
            return (
                <NoteComponent note={parentNote} key={parentNote.id} context={context}>
                    {displayChildren(parentNote)}
                </NoteComponent>
            );
        });

    const form = (
        <NoteForm
            parentId={null}
            notable={notable}
            context={noteFormContext ?? context}
            noteDraftKey={noteDraftKey}
            startingTemplates={startingTemplates}
            templateLabels={templateLabels}
            templateInterpolators={templateInterpolators}
            actions={actions}
        />
    );

    return (
        <div>
            {form}
            {allNotes}
        </div>
    );
};
