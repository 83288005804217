import { logger } from '../common/logger';
import UniqWorker from './uniq.worker';

// Initialize the worker
const uniqWorker = new UniqWorker();

// A map to keep track of pending requests and their resolvers
const pendingRequestsMap = new Map<number, (result: any) => void>();
let globalRequestIdSerial = 0;

interface Item {
    firstName: string;
    lastName: string;
    company: string;
    title: string;
}

// Function to send data to the worker and return a promise
const getUniqueList = async (list: Item[]): Promise<Item[]> => {
    return new Promise((resolve) => {
        const id = globalRequestIdSerial++;

        // Set up the resolver in the map
        pendingRequestsMap.set(id, resolve);

        // Send data to the worker
        uniqWorker.postMessage({
            list,
            requestId: id
        });
    });
};

// Worker will resolve the promise when the result is ready
uniqWorker.onmessage = (event: MessageEvent) => {
    const { requestId, uniqueList } = event.data;
    const resolver = pendingRequestsMap.get(requestId);
    if (resolver) {
        resolver(uniqueList); // Resolve the promise with the unique list
        pendingRequestsMap.delete(requestId); // Clean up the map
    } else {
        logger.error('Could not find pending request for requestId:', requestId);
    }
};

export { getUniqueList };
