import { createContext, useContext, useState } from 'react';

import { FieldPaths, PathValue } from '../types/field-paths';

export interface FormContextProps<T extends {}> {
    data: T;
    disabled: boolean;
    getError: (key: FieldPaths<T>) => string | undefined;
    setError: (key: FieldPaths<T>, message: string) => void;
    onChange: (updated: Partial<T>) => void;
    onFieldChange: <K extends keyof T>(key: K) => (val: T[K]) => void;
    validateFieldExists: <K extends keyof T>(key: K) => (val: T[K]) => boolean;
    isDirty: boolean;
    resetForm: (value: T) => void;
    hasErrors: boolean;
}

export const FormContext = createContext<FormContextProps<any> | null>(null);

export const useFormContext = <T extends {}>() => {
    const context = useContext(FormContext) as FormContextProps<T> | null;
    if (!context) {
        throw new Error('useFormContext must be used within a FormContext.Provider');
    }
    return context;
};

export const useFormContextData = <T extends {}>(initialData: T) => {
    const [data, setData] = useState<T>(initialData);
    const [isDirty, setIsDirty] = useState(false);
    const [errors, setErrors] = useState<Map<FieldPaths<T>, string>>(new Map());

    const handleChange = (updated: Partial<T>) => {
        setIsDirty(true);
        setData((prev) => ({ ...prev, ...updated }));
    };

    const handleFieldChange =
        <K extends keyof T>(field: K) =>
        (value: T[K]) => {
            setIsDirty(true);
            setData((prev) => ({ ...prev, [field]: value }));
        };

    const getError = (key: FieldPaths<T>) => {
        return errors.get(key);
    };

    const handleSetError = (key: FieldPaths<T>, message: string) => {
        setErrors((prev) => {
            const next = new Map(prev);
            if (message) {
                next.set(key, message);
            } else {
                next.delete(key);
            }
            return next;
        });
    };

    const validateFieldExists =
        <K extends FieldPaths<T>>(key: K) =>
        (val: PathValue<T, K>) => {
            if ((typeof val === 'string' && val.trim().length === 0) || val === null || val === undefined) {
                setErrors((prev) => {
                    const next = new Map(prev);
                    next.set(key, 'Required');
                    return next;
                });
                return false;
            } else {
                setErrors((prev) => {
                    const next = new Map(prev);
                    next.delete(key);
                    return next;
                });
                return true;
            }
        };

    const resetForm = (value: T) => {
        setData(value);
        setIsDirty(false);
        setErrors(new Map());
    };

    const context: Omit<FormContextProps<T>, 'disabled'> = {
        data,
        getError,
        hasErrors: errors.size > 0,
        isDirty,
        onChange: handleChange,
        onFieldChange: handleFieldChange,
        resetForm,
        setError: handleSetError,
        validateFieldExists
    };

    return context;
};
