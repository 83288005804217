export const addErrorHighlights = (body: string) => {
    return (body || '').replace(/{({*[\s\S]+?}*)}/g, '<span style="background-color: #fbc02d">{$1}</span>');
};

export const removeErrorHighlights = (body: string) => {
    return (body || '').replace(
        /<span.*? style=.*?background-color: (?:#fbc02d|rgb\(251, 192, 45\))[^>]*?>((.|\n)*?)<\/span>/g,
        '$1'
    );
};
