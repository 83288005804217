import { css } from '@emotion/core';
import { Chip, TextField } from '@material-ui/core';
import { Autocomplete, RenderInputParams } from '@material-ui/lab';
import { debounce, escapeRegExp } from 'lodash';
import React from 'react';

const styles = css`
    padding-right: 15px;

    .MuiChip-root {
        margin-right: 4px;
        margin-bottom: 4px;
    }

    .filter-field-chips {
        margin-bottom: -4px;
    }
`;

const textInputDebounceDelayMs = 500;

export const MultiSelectForm: React.FC<{
    readonly: boolean;
    filters: string[];
    label: string;
    options: string[];
    onChange: (val: string[]) => void;
    renderOption?: (option: string) => string;
    className?: string;
    onInputChange?: (value: string) => void;
}> = ({ readonly, filters, label, onChange, renderOption, options, className, onInputChange }) => {
    const [inputValue, setInputValue] = React.useState<string>('');
    // Create a debounced version of the function passed from the parent
    // useCallback ensures this function isn't recreated on every render
    const debouncedOnChange = React.useCallback(
        debounce((newValue: string) => {
            onInputChange?.(newValue);
        }, textInputDebounceDelayMs), // Adjust debounce time as needed
        [onInputChange] // onInputChange is a dependency here
    );

    const handleInputChange = (_2: any, newInputValue: string, reason: string) => {
        if (reason !== 'reset') {
            // Auto complete seems to reset the input value on a keystroke
            setInputValue(newInputValue);
            debouncedOnChange(newInputValue); // Call the debounced function
        }
    };

    const handleChange = (_1: any, value: string[]) => {
        setInputValue('');
        debouncedOnChange('');
        if (value.length === 0) {
            onChange(undefined);
        } else {
            onChange(value);
        }
    };

    const handleDelete = (index: number) => () => {
        handleChange(undefined, [...filters.slice(0, index), ...filters.slice(index + 1)]);
    };

    const renderOptionWithFallback = renderOption ?? ((option: string) => option);

    const renderTags = (values: string[]) => {
        const chips = values.map((val, i) => (
            <Chip key={val} label={renderOptionWithFallback(val)} onDelete={readonly ? null : handleDelete(i)} />
        ));
        return <div className="filter-field-chips">{chips}</div>;
    };

    const renderInput = (params: RenderInputParams) => (
        <TextField
            {...params}
            variant="outlined"
            label={label}
            fullWidth={true}
            inputProps={{ ...params.inputProps, autoComplete: 'new-password' }} // hack to prevent browser autocomplete
        />
    );

    const filterOptions = (opts: string[], state: { inputValue: string }) => {
        const matcher = new RegExp(escapeRegExp(state?.inputValue) ?? '', 'i');
        return opts.filter((option) => matcher.test(renderOptionWithFallback(option)));
    };

    return (
        <div className={`filter-field text-field ${className}`}>
            <Autocomplete
                css={styles}
                multiple={true}
                disabled={readonly}
                disableCloseOnSelect={true}
                filterSelectedOptions={true}
                options={options}
                filterOptions={filterOptions}
                renderOption={renderOptionWithFallback}
                renderTags={renderTags}
                value={filters ?? []}
                onChange={handleChange}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                renderInput={renderInput}
            />
        </div>
    );
};
