// cspell:ignore Accountmanagerid
import { gql } from '@apollo/client';

import { CommunicationCategory } from 'shared/models/communication';
import { InterviewKind } from 'shared/models/interview';
import { JobStatus } from 'shared/models/job';

import { candidateFields } from './common';

export interface Candidate {
    _id: string;
    disqualified: boolean;
    personId: string;
    jobId: string;
    assignee: {
        id: string;
        name: string;
    };
    person: {
        name: string;
        profilePicUrl: string;
    };
    job: {
        title: string;
        status: JobStatus;
        client: {
            id: string;
            name: string;
        };
        accountManager: {
            id: string;
            name: string;
        };
        assignee: string;
        maxIdleDays: number;
    };
    stage: {
        label: string;
        name: string;
        id: number;
    };
    lastStageChangedAt: number;
    lastActivityAt: number;
    pendingEmailsCount: number;
    pendingEmails: Array<{
        account: string;
        messageId: string;
        isClientComm: boolean;
        category: CommunicationCategory;
    }>;
}

export interface Interview {
    id: string;
    kind: InterviewKind;
    candidate: Candidate & {
        person: {
            phones: Array<{ value: string }>;
        };
        pendingEmailsCount: number;
    };
    startTime: number;
    endTime: number;
}

export type RecruiterJobKind = 'bookmarked' | 'active-search' | 'active-candidate' | 'assigned';

export interface Job {
    id: string;
    title: string;
    status: JobStatus;
    dailyEmailLimit: number;
    client: {
        id: string;
        name: string;
    };
    assignee: {
        name: string;
        id: string;
    };
    accountManager: {
        name: string;
        id: string;
    };
    candidates: Array<{
        personId: string;
        jobId: string;
        stage: string;
        assignee: string;
        lastStageChangedAt: number;
    }>;
}

export interface FlaggedEmailAccount {
    _id: string;
    profilePicUrl: string;
    account: string;
    count: number;
    name: string;
}

export interface StageCandidates {
    candidates: Array<{ personId: string; jobId: string }>;
}

const jobFields = gql`
    fragment jobFields on jobs {
        id
        title
        status
        dailyEmailLimit
        assignee: userByAssignee {
            id
            name(path: "full")
        }
        accountManager: userByAccountmanagerid {
            id
            name(path: "full")
        }
        client {
            id
            name
        }
    }
`;

export const SUBMISSIONS = gql`
    ${candidateFields}
    query AssigneeSubmissions($userId: String!) {
        candidates(
            where: {
                _or: [
                    {
                        _and: [
                            { assignee: { _eq: $userId } }
                            {
                                stage: {
                                    _in: [
                                        "am_requested_info"
                                        "am_rejected"
                                        "awaiting_am_approval"
                                        "am_submit_approved"
                                    ]
                                }
                            }
                        ]
                    }
                    {
                        _and: [
                            { job: { accountManagerId: { _eq: $userId } } }
                            { stage: { _in: ["am_requested_info", "awaiting_am_approval"] } }
                        ]
                    }
                ]
                job: { status: { _neq: 3 } }
                disqualified: { _eq: false }
            }
            order_by: { lastStageChangedAt: desc }
        ) {
            ...candidateFields
        }
    }
`;

export const INTERVIEWS = gql`
    ${candidateFields}
    query interviews($userId: String!, $interviewsStartTime: bigint!) {
        interviews(
            where: {
                _or: [
                    { candidate: { assignee: { _eq: $userId } } }
                    { job: { accountManagerId: { _eq: $userId } }, kind: { _neq: "Rocket Screen" } }
                ]
                startTime: { _gte: $interviewsStartTime }
            }
            order_by: { startTime: asc }
        ) {
            id
            kind
            candidate {
                ...candidateFields
                person {
                    phones: contacts(where: { channel: { _eq: "phone" }, invalid: { _eq: false } }) {
                        value
                    }
                }
            }
            startTime
            endTime
        }
    }
`;

export const CALLS_BY_PERIOD = gql`
    ${candidateFields}
    query interviews($userId: String!, $startTime: bigint!, $endTime: bigint!) {
        interviews(
            where: {
                candidate: { assignee: { _eq: $userId } }
                startTime: { _gte: $startTime, _lte: $endTime }
                kind: { _eq: "Rocket Screen" }
            }
            order_by: { startTime: asc }
        ) {
            id
            kind
            candidate {
                ...candidateFields
                person {
                    phones: contacts(where: { channel: { _eq: "phone" }, invalid: { _eq: false } }) {
                        value
                    }
                }
            }
            startTime
            endTime
        }
    }
`;
export const RECRUITER_JOBS = gql`
    ${jobFields}
    query RecruiterJobs($userId: String!, $kind: [String!]!) {
        jobs: user_recruiting_jobs(where: { userId: { _eq: $userId }, kind: { _has_keys_any: $kind } }) {
            kind
            job {
                ...jobFields
                candidates(
                    where: {
                        disqualified: { _neq: true }
                        stage: {
                            _in: [
                                "response_received"
                                "rocket_screen_scheduled"
                                "rocket_screen_complete"
                                "awaiting_client_feedback"
                            ]
                        }
                    }
                    order_by: [{ pendingEmailsCount: desc }, { lastStageChangedAt: asc }]
                ) {
                    personId
                    jobId
                    stage
                    assignee
                    lastStageChangedAt
                }
            }
        }
    }
`;

export const AM_JOBS = gql`
    ${jobFields}
    query AMJobs($userId: String!) {
        jobs(
            where: { accountManagerId: { _eq: $userId }, status: { _neq: 3 } }
            order_by: [{ client: { name: asc } }, { title: asc }]
        ) {
            ...jobFields
            candidates(
                where: {
                    disqualified: { _neq: true }
                    stage: {
                        _in: [
                            "rocket_screen_scheduled"
                            "awaiting_client_feedback"
                            "client_first_round"
                            "client_middle_round"
                            "client_second_round"
                        ]
                    }
                }
                order_by: [{ pendingEmailsCount: desc }, { lastStageChangedAt: asc }]
            ) {
                personId
                jobId
                stage
                assignee
                lastStageChangedAt
            }
        }
    }
`;

export const ASSIGNEE_CANDIDATES_BY_STAGE = gql`
    ${candidateFields}
    query AssigneeCandidates($userId: String!, $stages: [String!]) {
        candidates(
            where: {
                assignee: { _eq: $userId }
                job: { status: { _neq: 3 }, jobType: { _nin: ["S", "SwC"] } }
                disqualified: { _eq: false }
                stage: { _in: $stages }
            }
            order_by: [{ job_stage: { id: desc } }, { lastStageChangedAt: desc }]
        ) {
            ...candidateFields
        }
    }
`;

export const PENDING_EMAILS = gql`
    ${candidateFields}
    query PendingEmails($userId: String!) {
        candidates(
            where: {
                pendingEmailsCount: { _gt: 0 }
                job: { status: { _neq: 3 } }
                disqualified: { _eq: false }
                _or: [
                    {
                        job: { accountManagerId: { _eq: $userId } }
                        stage: {
                            _in: [
                                "awaiting_client_feedback"
                                "client_first_round"
                                "client_middle_round"
                                "client_second_round"
                                "offer"
                                "hired"
                            ]
                        }
                    }
                    { assignee: { _eq: $userId } }
                ]
            }
            order_by: [
                { personId: asc }
                { crossSubmitJobIds: asc }
                { job_stage: { id: desc } }
                { job: { status: asc, client: { name: asc }, title: asc } }
                { person: { name: asc } }
                { lastStageChangedAt: desc }
            ]
            distinct_on: [personId, crossSubmitJobIds]
        ) {
            ...candidateFields
        }
    }
`;

export const PENDING_EMAILS_ARCHIVED = gql`
    ${candidateFields}
    query PendingEmailsArchived($userId: String!) {
        candidates(
            where: {
                pendingEmailsCount: { _gt: 0 }
                lastStageChangedAt: { _gte: "1586934000000" }
                _and: [
                    { _or: [{ job: { status: { _eq: 3 } } }, { disqualified: { _eq: true } }] }
                    {
                        _or: [
                            {
                                job: { accountManagerId: { _eq: $userId } }
                                stage: {
                                    _in: [
                                        "awaiting_client_feedback"
                                        "client_first_round"
                                        "client_middle_round"
                                        "client_second_round"
                                        "offer"
                                        "hired"
                                    ]
                                }
                            }
                            { assignee: { _eq: $userId } }
                        ]
                    }
                ]
            }
            order_by: [
                { personId: asc }
                { crossSubmitJobIds: asc }
                { job_stage: { id: desc } }
                { job: { status: asc, client: { name: asc }, title: asc } }
                { person: { name: asc } }
                { lastStageChangedAt: desc }
            ]
            distinct_on: [personId, crossSubmitJobIds]
        ) {
            ...candidateFields
        }
    }
`;

export const ALL_FLAGGED_EMAIL_ACCOUNTS = gql`
    query GetFlaggedEmailAccounts {
        accounts: flagged_email_accounts(order_by: [{ sortOrder: asc }, { name: asc }]) {
            _id
            profilePicUrl
            account
            count
            name
        }
    }
`;

export const OWNER_FLAGGED_EMAIL_ACCOUNTS = gql`
    subscription GetFlaggedEmailAccounts($userId: String!) {
        accounts: flagged_email_accounts(
            where: { owners: { _has_key: $userId } }
            order_by: [{ sortOrder: asc }, { name: asc }]
        ) {
            _id
            profilePicUrl
            account
            count
            name
        }
    }
`;
