import { Theme, Tooltip as MUITooltip, withStyles } from '@material-ui/core';
import { IconButton } from 'material-ui';
import ExpandLess from 'material-ui/svg-icons/navigation/expand-less';
import * as React from 'react';

import { EmailAddress } from 'shared/types/email-compose';

import { commViewClient, commViewLocation } from '../common/communication-utils';
import { fullDateTime } from '../common/timestamp';
import { FileDownloadLink } from '../core-ui/file-download-link';
import { insertEmailMarkup } from '../lib/email-markup';
import { Communication, EmailSentiment } from '../state';
import { AIEmailResponseDebug } from './ai-email-response-debug';
import { CommunicationHeaders } from './communication-headers';
import { CommunicationWarning } from './communications-warnings';

interface CommunicationProps {
    personId: string;
    data: Communication;
    handleClose: () => void;
    actions: JSX.Element[];
    fromCrossSubmit: boolean;
    emailContentBlacklistedDomains: string;
    jobId?: string;
    onReply?: (data: Communication, initialBody?: string) => void;
}

interface CommunicationState {
    headersOpen: boolean;
    jobSelectorOpen: boolean;
}

const Tooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        color: theme.palette.text.primary,
        maxWidth: 500
    }
}))(MUITooltip);

export class CommunicationDetails extends React.Component<CommunicationProps, CommunicationState> {
    private emailBodyRef: HTMLElement;

    constructor(props: CommunicationProps) {
        super(props);
        this.state = { headersOpen: false, jobSelectorOpen: false };
    }

    componentDidMount() {
        const forwardedMessage = this.props.data.subject && !!this.props.data.subject.match(/^fwd/i);
        insertEmailMarkup(
            this.props.data.body,
            this.emailBodyRef,
            forwardedMessage,
            this.props.emailContentBlacklistedDomains,
            this.props.data.attachments
                .filter((a) => !!a.contentId)
                .map((a) => ({ path: a.key, contentId: a.contentId }))
        );
    }

    setEmailBodyRef = (body: any) => {
        this.emailBodyRef = body;
    };

    handleReply = (initialBody: string) => {
        this.props.onReply?.(this.props.data, initialBody);
    };

    render() {
        const { data, handleClose, personId, jobId } = this.props;
        const { headers } = data;

        const accountInfo =
            data.accountInfo.name.first.charAt(0).toLocaleUpperCase() +
            data.accountInfo.name.last.charAt(0).toLocaleUpperCase();
        let sentimentClass;
        if ('sentiment' in data) {
            if (data.sentiment === EmailSentiment.Positive) sentimentClass = 'email-sentiment-green';
            else if (data.sentiment === EmailSentiment.Negative) sentimentClass = 'email-sentiment-red';
            else if (data.sentiment === EmailSentiment.Unknown) sentimentClass = 'email-sentiment-grey';
        }

        const attachments = data.attachments.map((a) => (
            <FileDownloadLink key={a.id} path={a.key} filename={a.filename}>
                <div className="email-attachment">
                    <div className="list-centered-icon">
                        <i className="fas fa-file-alt attachment-icon" />
                        <span>{a.filename}</span>
                    </div>
                </div>
            </FileDownloadLink>
        ));

        let date = <div className="date">{fullDateTime(data.internalDate)}</div>;
        if (data.views && data.views.length > 0) {
            const views = data.views.map((v, i) => (
                <tr className="communication-view-row" key={i}>
                    <td className="communication-view-when">{fullDateTime(v.ts)}</td>
                    <td className="communication-view-where">{commViewLocation(v)}</td>
                    <td className="communication-view-device">{commViewClient(v)}</td>
                </tr>
            ));
            const tooltip = (
                <table className="communication-views">
                    <thead>
                        <tr className="communication-view-row title">
                            <th className="communication-view-when">Opened</th>
                            <th className="communication-view-where">Location</th>
                            <th className="communication-view-device">Client/Device</th>
                        </tr>
                    </thead>
                    <tbody>{views}</tbody>
                </table>
            );
            date = (
                <Tooltip title={tooltip} placement="bottom" interactive={true}>
                    {date}
                </Tooltip>
            );
        }

        const fromCrossSubmitIcon = this.props.fromCrossSubmit ? (
            <MUITooltip title="Email from Cross submission">
                <div className="is-cross-submit-email">
                    <i className="material-icons">shuffle</i>
                </div>
            </MUITooltip>
        ) : null;

        const accountAddress: EmailAddress = { address: data.account, name: data.accountInfo.name.full };
        const aiResponseDebug = data.fromCandidate ? (
            <AIEmailResponseDebug personId={personId} jobId={jobId} onReply={this.handleReply} />
        ) : null;

        return (
            <div className="email-details-container">
                <div className="email-details">
                    <div className="subject">
                        <div>
                            <div className="account">
                                {accountInfo}
                                {fromCrossSubmitIcon}
                            </div>
                            <div className="text">{data.subject}</div>
                        </div>
                        <div className="actions">
                            {aiResponseDebug}
                            {this.props.actions}
                        </div>
                    </div>
                    <div className={`main ${sentimentClass}`}>
                        <CommunicationWarning key="warning" data={data} />
                        <div className="header">
                            <CommunicationHeaders
                                to={headers.to}
                                bcc={headers.bcc}
                                cc={headers.cc}
                                from={headers.from}
                                jobIds={data.jobIds}
                                account={accountAddress}
                                subject={data.subject}
                                date={data.internalDate}
                            />
                            {date}
                        </div>
                        <div className="body" ref={this.setEmailBodyRef} />
                        <div className="email-attachments">{attachments}</div>
                        <div className="close-button">
                            <IconButton>
                                <ExpandLess onClick={handleClose} />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
