import { useMutation } from '@apollo/client';
import { Button, useTheme } from '@material-ui/core';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { hasRole } from 'shared/models/user';

import { Autocomplete, TextField } from '../core-ui/form-fields';
import { client as apolloClient } from '../graphql/apollo-client';
import { CREATE_CLIENT } from '../graphql/queries/clients';
import { useModal } from '../hooks/use-modal';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';
import { useUsers } from '../hooks/use-users';
import { styles } from './client-form-styles';
import { useFormContextData } from './form-context';

interface ClientCreateData {
    accountManagerId: string;
    name: string;
}

const clientDefaults: ClientCreateData = {
    accountManagerId: '',
    name: ''
};

export const ClientNew: React.FC = () => {
    const theme = useTheme();
    const client = apolloClient('client_creator');
    const [createClient] = useMutation<{ client: { id: string } }, { client: ClientCreateData }>(CREATE_CLIENT, {
        client
    });
    const { data, onFieldChange, getError, validateFieldExists, isDirty } =
        useFormContextData<ClientCreateData>(clientDefaults);
    const { users } = useUsers();
    const [redirect, setRedirect] = React.useState('');
    const [saving, setSaving] = React.useState(false);
    const { setSnackbar } = useSnackbar();
    const { userPermissions } = useSession();
    const { getConfirmation } = useModal();

    const canEdit = hasRole(userPermissions, 'client_creator');
    const disabled = !canEdit || saving;

    const validate = () => {
        const fields: Array<keyof ClientCreateData> = ['name', 'accountManagerId'];
        let isValid = true;
        fields.forEach((field) => {
            isValid = validateFieldExists(field)(data[field]) && isValid;
        });
        return isValid;
    };

    const handleSubmit = async () => {
        if (validate()) {
            try {
                setSaving(true);
                setSnackbar('Saving...');
                const result = await createClient({ variables: { client: data } });
                setRedirect(`/client/${result.data.client.id}/edit`);
                setSnackbar('Saved');
            } catch {
                setSnackbar('Failed to save');
            } finally {
                setSaving(false);
            }
        }
    };

    const handleCancel = () => {
        if (isDirty) {
            getConfirmation(() => setRedirect('/'), 'Are you sure you want to discard changes?', 'Discard changes');
        } else {
            setRedirect('/');
        }
    };

    const userOptions =
        users?.map((user) => ({
            label: user.name,
            value: user.id
        })) ?? [];

    if (redirect) {
        return <Redirect to={redirect} />;
    }

    return (
        <div css={styles(theme)}>
            <div className="client-form">
                <div className="form-field-row">
                    <TextField
                        label="Name"
                        value={data.name}
                        onChange={onFieldChange('name')}
                        disabled={disabled}
                        errorText={getError('name')}
                        validate={validateFieldExists('name')}
                    />
                </div>
                <div className="form-field-row">
                    <Autocomplete
                        label="Account Manager"
                        options={userOptions}
                        value={data.accountManagerId}
                        onChange={onFieldChange('accountManagerId')}
                        validate={validateFieldExists('accountManagerId')}
                        disabled={disabled}
                        errorText={getError('accountManagerId')}
                    />
                </div>
            </div>
            <div className="form-action-buttons">
                <Button disabled={disabled} variant="contained" color="default" onClick={handleCancel}>
                    Cancel
                </Button>
                <Button
                    type="submit"
                    disabled={!isDirty || disabled}
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                >
                    Save
                </Button>
            </div>
        </div>
    );
};
