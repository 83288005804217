import { merge, pickBy } from 'lodash';
import { Dispatch } from 'redux';

import { formatEmail } from 'shared/common/email-utils';
import { CandidateDisqualReason } from 'shared/models/job-stages';
import { NoteView } from 'shared/models/note';
import { ScheduledMessageView } from 'shared/models/scheduled-messages';
import { UserData } from 'shared/models/user';
import { EmailAddress } from 'shared/types/email-compose';

import { archiveMessage, requestToasterOpen, syncAccountAndGetCommunications } from '../actions';
import { EmailKind, headerValidator } from '../common/email/header-validator';
import { timeFrom } from '../common/timestamp';
import { emailForwardData, emailReplyData } from '../lib/email-markup';
import { Candidate, Client, Communication, Contact, EmailAccount, Job, Person, State } from '../state';
import * as api from './api';
import { AddressField } from './components/email-compose-window';
import { ComposeEmailWindowData } from './types';

export const ComposeEmail = 'ComposeEmail';
export const RequestSendMail = 'RequestSendMail';
export const ReceiveSentMail = 'ReceiveSentMail';
export const RequestSaveDraft = 'RequestSaveDraft';
export const ReceiveSavedDraft = 'ReceiveSavedDraft';
export const RequestCloseWindow = 'RequestCloseWindow';
export const ResetComposeWindows = 'ResetComposeWindows';
export const ToggleComposeWindow = 'ToggleComposeWindow';
export const ReceiveScheduledMessage = 'ReceiveScheduledMessage';
export const RequestSendDisqualificationMail = 'RequestSendDisqualificationMail';
export const ReceiveSentDisqualificationMail = 'ReceiveSentDisqualificationMail';
export const ReceiveScheduledDisqualificationMail = 'ReceiveScheduledDisqualificationMail';

export interface EmailValidationData {
    job: Job; // can be null
    client: Client; // can be null
    personContacts: Contact[];
    users: UserData[];
    emailAccounts: EmailAccount[];
}

export type EmailComposeAction =
    | {
          type: 'ComposeEmail';
          payload: ComposeEmailWindowData;
      }
    | {
          type: 'RequestSendMail';
          payload: {
              windowId: string;
          };
      }
    | {
          type: 'RequestSendDisqualificationMail';
          payload: {
              windowId: string;
          };
      }
    | {
          type: 'ReceiveScheduledDisqualificationMail';
          payload: {
              windowId: string;
              personId: string;
              jobId: string;
              scheduledMessage: ScheduledMessageView;
              candidates: Candidate[];
              person?: Person;
              note?: NoteView;
          };
      }
    | {
          type: 'ReceiveSentDisqualificationMail';
          payload: {
              windowId: string;
              candidates: Candidate[];
              communication: Communication;
              personId: string;
              jobId: string;
              person?: Person;
              note?: NoteView;
          };
      }
    | {
          type: 'ReceiveSentMail';
          payload: { windowId: string; message: Communication; personId?: string; clientId?: string; jobId?: string };
      }
    | {
          type: 'RequestCloseWindow';
          payload: { windowId: string };
      }
    | {
          type: 'ResetComposeWindows';
      }
    | {
          type: 'ToggleComposeWindow';
          payload: {
              windowId: string;
              disabled: boolean;
          };
      }
    | {
          type: 'ReceiveScheduledMessage';
          payload: {
              windowId: string;
              scheduledMessage: ScheduledMessageView;
              personId?: string;
          };
      };

export function composeEmail(payload: ComposeEmailWindowData): EmailComposeAction {
    return {
        payload,
        type: ComposeEmail
    };
}

export function toggleComposeWindow(windowId: string, disabled: boolean) {
    return async (dispatch: Dispatch<State>, getState: () => State) => {
        if (getState().emailComposeWindows.get(windowId)) {
            dispatch({
                payload: { windowId, disabled },
                type: ToggleComposeWindow
            });
        }
    };
}

export function composeEmailWithValidation(
    payload: ComposeEmailWindowData,
    emailKind: EmailKind,
    validationData: EmailValidationData
) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        const { job, personContacts, emailAccounts, users, client } = validationData;
        const jobAdditionalAllowedEmails = getState().appConstants.constants.jobAdditionalAllowedEmails;
        const validator: ComposeEmailWindowData['validator'] = (
            emails: Array<{ field: AddressField; address: string }>
        ) => {
            return headerValidator(
                {
                    client,
                    emailAccounts,
                    emailKind,
                    initialParticipants: {
                        bcc: [],
                        cc: [],
                        to: []
                    },
                    job,
                    jobAdditionalAllowedEmails,
                    personContacts,
                    users
                },
                emails
            );
        };
        const payloadWithValidation = {
            ...payload,
            validator
        };

        dispatch(composeEmail(payloadWithValidation));
    };
}

export function replyToEmail(
    accountOptions: EmailAddress[],
    emailContentBlacklistedDomains: string,
    message: Communication,
    replyAll: boolean,
    archiveOnReply = false,
    composeWindowData?: Partial<ComposeEmailWindowData>,
    initialBody?: string
): EmailComposeAction {
    const { account, messageId, threadId } = message;
    const windowId = `reply-${account}-${messageId}`;
    const replyData = emailReplyData(message, replyAll, emailContentBlacklistedDomains);
    const { replyBody } = replyData;
    const headers = { ...replyData.headers, ...pickBy(composeWindowData?.headers) };
    const initialReplyContent = message.aiDraftedReply ?? initialBody ?? '';
    const body = `${initialReplyContent}${replyBody}`;
    const participants: EmailAddress[] = [].concat(message.headers.to, message.headers.cc, message.headers.bcc, [
        message.headers.from
    ]);
    const accountEmailAddress = participants.find((p) => p.address === account);
    const payload: ComposeEmailWindowData = merge(
        {},
        {
            account: accountEmailAddress,
            accountOptions,
            archiveOnReply,
            attachments: [],
            body,
            draftSavedAt: null,
            headers,
            replyToMessageId: messageId,
            threadId,
            validator: null,
            windowId
        },
        composeWindowData
    );
    return {
        payload,
        type: ComposeEmail
    };
}

export function replyToEmailWithValidation(
    accountOptions: EmailAddress[],
    emailContentBlacklistedDomains: string,
    message: Communication,
    replyAll: boolean,
    emailKind: EmailKind,
    validationData: EmailValidationData,
    archiveOnReply = false,
    composeWindowData?: Partial<ComposeEmailWindowData>,
    initialBody?: string
) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        const { job, client, emailAccounts, users, personContacts } = validationData;
        const validator: ComposeEmailWindowData['validator'] = (
            emails: Array<{ field: AddressField; address: string }>
        ) => {
            const { headers } = emailReplyData(message, replyAll, emailContentBlacklistedDomains);
            const jobAdditionalAllowedEmails = getState().appConstants.constants.jobAdditionalAllowedEmails;
            return headerValidator(
                {
                    client,
                    emailAccounts,
                    emailKind,
                    initialParticipants: {
                        bcc: headers.bcc.map((e) => formatEmail(e.address)),
                        cc: headers.cc.map((e) => formatEmail(e.address)),
                        to: headers.to.map((e) => formatEmail(e.address))
                    },
                    job,
                    jobAdditionalAllowedEmails,
                    personContacts,
                    users
                },
                emails
            );
        };
        const composeWindowDataWithValidation = { ...composeWindowData, validator };

        dispatch(
            replyToEmail(
                accountOptions,
                emailContentBlacklistedDomains,
                message,
                replyAll,
                archiveOnReply,
                composeWindowDataWithValidation,
                initialBody
            )
        );
    };
}

export function replyToEmailWithDifferentAccount(
    accountOptions: EmailAddress[],
    emailContentBlacklistedDomains: string,
    message: Communication,
    newAccount: EmailAddress,
    replyAll: boolean,
    composeWindowData?: Partial<ComposeEmailWindowData>,
    initialBody?: string
): EmailComposeAction {
    const { account } = message;
    const windowId = `reply-${account}-${message.messageId}`;
    const { headers, replyBody } = emailReplyData(message, replyAll, emailContentBlacklistedDomains);
    const body = `${initialBody || ''}${replyBody}`;
    const payload: ComposeEmailWindowData = merge(
        {},
        {
            account: newAccount,
            accountOptions,
            attachments: [],
            body,
            draftSavedAt: null,
            headers: {
                bcc: headers.bcc,
                cc: headers.cc,
                subject: headers.subject,
                to: headers.to
            },
            threadId: undefined,
            validator: null,
            windowId
        },
        composeWindowData
    );
    return {
        payload,
        type: ComposeEmail
    };
}

export function replyToEmailWithValidationWithDifferentAccount(
    accountOptions: EmailAddress[],
    emailContentBlacklistedDomains: string,
    message: Communication,
    newAccount: EmailAddress,
    replyAll: boolean,
    emailKind: EmailKind,
    validationData: EmailValidationData,
    composeWindowData?: Partial<ComposeEmailWindowData>,
    initialBody?: string
) {
    return (dispatch: Dispatch<State>, getState: () => State) => {
        const { job, client, emailAccounts, users, personContacts } = validationData;
        const validator: ComposeEmailWindowData['validator'] = (
            emails: Array<{ field: AddressField; address: string }>
        ) => {
            const { headers } = emailReplyData(message, replyAll, emailContentBlacklistedDomains);
            const jobAdditionalAllowedEmails = getState().appConstants.constants.jobAdditionalAllowedEmails;
            return headerValidator(
                {
                    client,
                    emailAccounts,
                    emailKind,
                    initialParticipants: {
                        bcc: headers.bcc.map((e) => formatEmail(e.address)),
                        cc: headers.cc.map((e) => formatEmail(e.address)),
                        to: headers.to.map((e) => formatEmail(e.address))
                    },
                    job,
                    jobAdditionalAllowedEmails,
                    personContacts,
                    users
                },
                emails
            );
        };
        const composeWindowDataWithValidation = { ...composeWindowData, validator };

        dispatch(
            replyToEmailWithDifferentAccount(
                accountOptions,
                emailContentBlacklistedDomains,
                message,
                newAccount,
                replyAll,
                composeWindowDataWithValidation,
                initialBody
            )
        );
    };
}

export function forwardEmail(message: Communication, emailContentBlacklistedDomains: string): EmailComposeAction {
    const { account, messageId, threadId } = message;
    const windowId = `forward-${account}-${messageId}`;
    const { headers, body } = emailForwardData(message, emailContentBlacklistedDomains);
    const participants: EmailAddress[] = [].concat(message.headers.to, message.headers.cc, message.headers.bcc, [
        message.headers.from
    ]);
    const accountEmailAddress = participants.find((p) => p.address === account);
    const payload: ComposeEmailWindowData = {
        account: accountEmailAddress,
        accountOptions: [accountEmailAddress],
        attachments: [],
        body,
        canEditToRecipients: true,
        draftSavedAt: null,
        headers,
        threadId,
        windowId
    };
    return {
        payload,
        type: ComposeEmail
    };
}

function requestSendMail(windowId: string): EmailComposeAction {
    return { payload: { windowId }, type: RequestSendMail };
}

function receiveScheduledMessage(payload: {
    windowId: string;
    scheduledMessage: ScheduledMessageView;
    personId?: string;
}): EmailComposeAction {
    return { type: ReceiveScheduledMessage, payload };
}

function receiveSentMail(payload: {
    windowId: string;
    message: Communication;
    personId?: string;
    clientId?: string;
    jobId?: string;
}): EmailComposeAction {
    return { type: ReceiveSentMail, payload };
}

export function sendMail(payload: ComposeEmailWindowData, sendAt: number) {
    return (dispatch: Dispatch<State>) => {
        dispatch(requestSendMail(payload.windowId));
        const sendingMessage = !sendAt || sendAt <= Date.now() ? 'Sending Message' : 'Scheduling Message';
        dispatch(requestToasterOpen(sendingMessage));
        return api.sendMail(payload.account.address, payload, sendAt).then((result) => {
            if (result.success) {
                const { personId, jobId } = payload;
                const scheduled = !!result.data.scheduledMessage;
                const scheduledAt = scheduled ? result.data.scheduledMessage.scheduledAt : null;
                const sentMessage = scheduled ? `Message scheduled to send ${timeFrom(scheduledAt)}` : 'Message Sent';
                if (!scheduled) {
                    dispatch(
                        receiveSentMail({ windowId: payload.windowId, message: result.data.message, personId, jobId })
                    );
                    // refresh messages from server after sending
                    dispatch(syncAccountAndGetCommunications(payload.account.address, personId));
                } else {
                    dispatch(
                        receiveScheduledMessage({
                            personId,
                            scheduledMessage: result.data.scheduledMessage,
                            windowId: payload.windowId
                        })
                    );
                }
                if (payload.onSentAction) {
                    dispatch(payload.onSentAction);
                }
                if (payload.archiveOnReply) {
                    dispatch(archiveMessage(payload.account.address, payload.replyToMessageId, payload.personId));
                }
                dispatch(requestToasterOpen(sentMessage));
            } else {
                dispatch(requestToasterOpen('ERROR sending message'));
            }
        });
    };
}

function requestCloseWindow(windowId: string): EmailComposeAction {
    return {
        payload: { windowId },
        type: RequestCloseWindow
    };
}

export function closeEmailComposeWindow(payload: ComposeEmailWindowData) {
    return (dispatch: Dispatch<State>) => {
        dispatch(requestCloseWindow(payload.windowId));
        if (payload.onDeleteAction) {
            dispatch(payload.onDeleteAction);
        }
    };
}

export function resetComposeWindows(): EmailComposeAction {
    return { type: ResetComposeWindows };
}

function requestSendDisqualificationMail(windowId: string): EmailComposeAction {
    return {
        payload: { windowId },
        type: RequestSendDisqualificationMail
    };
}

function receiveSentDisqualificationMail(payload: {
    windowId: string;
    communication: Communication;
    candidates: Candidate[];
    personId: string;
    jobId: string;
    person?: Person;
    note?: NoteView;
}): EmailComposeAction {
    return {
        payload,
        type: ReceiveSentDisqualificationMail
    };
}

function receiveScheduledDisqualificationMail(payload: {
    windowId: string;
    personId: string;
    jobId: string;
    scheduledMessage: ScheduledMessageView;
    candidates: Candidate[];
    person?: Person;
    note?: NoteView;
}): EmailComposeAction {
    return {
        payload,
        type: ReceiveScheduledDisqualificationMail
    };
}

export function sendDisqualificationMail(
    payload: ComposeEmailWindowData,
    sendAt: number,
    updates: {
        disqualReason: CandidateDisqualReason;
        note: string;
        optOutUntil?: number;
    }
) {
    return (dispatch: Dispatch<State>) => {
        dispatch(requestSendDisqualificationMail(payload.windowId));
        const sendingMessage = !sendAt || sendAt <= Date.now() ? 'Sending Message' : 'Scheduling Message';
        dispatch(requestToasterOpen(sendingMessage));
        return api.sendDisqualificationMail(payload.account.address, payload, sendAt, updates).then((result) => {
            if (result.success) {
                const { personId, jobId } = payload;
                const scheduled = !!result.data.scheduledMessage;
                const scheduledAt = scheduled ? result.data.scheduledMessage.scheduledAt : null;
                const sentMessage = scheduled ? `Message scheduled to send ${timeFrom(scheduledAt)}` : 'Message Sent';
                if (!scheduled) {
                    dispatch(
                        receiveSentDisqualificationMail({
                            candidates: result.data.candidates,
                            communication: result.data.message,
                            jobId,
                            note: result.data.note,
                            person: result.data.person,
                            personId,
                            windowId: payload.windowId
                        })
                    );
                } else {
                    dispatch(
                        receiveScheduledDisqualificationMail({
                            candidates: result.data.candidates,
                            jobId,
                            note: result.data.note,
                            person: result.data.person,
                            personId,
                            scheduledMessage: result.data.scheduledMessage,
                            windowId: payload.windowId
                        })
                    );
                }
                if (payload.onSentAction) {
                    dispatch(payload.onSentAction);
                }
                if (payload.archiveOnReply) {
                    dispatch(archiveMessage(payload.account.address, payload.replyToMessageId, payload.personId));
                }
                dispatch(requestToasterOpen(sentMessage));
            } else {
                dispatch(requestToasterOpen('ERROR sending message'));
            }
        });
    };
}
