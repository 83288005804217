import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ClientNotesContainer } from '../containers/client-notes-container';

interface RouterProps {
    id: string;
}

const ClientNotesPageComponent: React.SFC<RouteComponentProps<RouterProps>> = ({ match }) => (
    <ClientNotesContainer clientId={match.params.id} />
);

export const ClientNotesPage = withRouter(ClientNotesPageComponent);
