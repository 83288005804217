import React from 'react';
import { ActivityMetricType } from 'shared/models/activity-metric';

import { defaultFunnelSelected, FieldsSelected } from '../../common/metrics';
import { useLocalStorage } from '../../hooks/use-local-storage';
import { MetricsTimeline } from './metrics-timeline';

export const FunnelMetricsTimeline: React.FC<{
    metrics: Array<{ kind: ActivityMetricType; count: number; date: string }>;
    fields: ActivityMetricType[];
    duration: { start: number; end: number };
    title: string;
}> = ({ metrics, duration, fields, title }) => {
    const [selectedFields, setSelectedFields] = useLocalStorage<FieldsSelected>(
        'job-metrics-fields',
        defaultFunnelSelected
    );
    return (
        <MetricsTimeline
            metrics={metrics}
            title={title}
            duration={duration}
            fields={fields}
            selectedFields={selectedFields}
            onChangeSelectedFields={setSelectedFields}
        />
    );
};
