import * as React from 'react';
import DocumentTitle from 'react-document-title';

import { Header } from '../components/header';
import { ProfileRatings as ProfileRatingsComponent } from '../components/profile-ratings';

export const ProfileRatings = () => (
    <DocumentTitle title="Profile Ratings">
        <div id="container">
            <Header title="Profile Ratings" actions={[]} />
            <div id="content" className="flex-fill">
                <ProfileRatingsComponent />
            </div>
        </div>
    </DocumentTitle>
);
