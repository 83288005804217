import { Map } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';

import { UserData } from 'shared/models/user';

import { fetchUserInfo } from '../actions';
import { State } from '../state';

interface InjectedProps {
    user: UserData;
}

interface ExposedProps {
    id: string;
}

type ReactComp<T> = React.ComponentClass<T> | React.StatelessComponent<T>;

export function withUser<P>(WrappedComponent: ReactComp<P & InjectedProps>): ReactComp<P & ExposedProps> {
    interface ConnectedProps {
        users: Map<string, UserData>;
    }

    interface ConnectedDispatch {
        fetchUserInfo: (id: string) => void;
    }

    type WithUserWrapperProps = P & ExposedProps & ConnectedProps & ConnectedDispatch;

    class WithUserWrapper extends React.Component<WithUserWrapperProps, undefined> {
        static displayName = `WithUser(${WrappedComponent.displayName || WrappedComponent.name})`;

        constructor(props: WithUserWrapperProps) {
            super(props);
            this.ensureDataExists(props);
        }

        ensureDataExists(props: Readonly<WithUserWrapperProps>) {
            const { id, users } = props;
            if (!users.has(id)) {
                props.fetchUserInfo(id);
            }
        }

        componentDidUpdate() {
            this.ensureDataExists(this.props);
        }

        render() {
            const { id, users } = this.props;
            if (!users.has(id)) {
                return null;
            } else {
                const user = users.get(id);
                return <WrappedComponent user={user} {...this.props} />;
            }
        }
    }

    const mapStateToProps = (state: State): ConnectedProps => ({
        users: state.users
    });
    const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
        fetchUserInfo
    };

    return connect<ConnectedProps, ConnectedProps, P & ExposedProps>(
        mapStateToProps,
        mapDispatchToProps
    )(WithUserWrapper);
}
