import { uniq } from 'lodash';
import { FlatButton, MenuItem, SelectField } from 'material-ui';
import { white } from 'material-ui/styles/colors';
import * as React from 'react';

import { EmailAccountView } from 'shared/models/email-account';

import { Communication, List, Person } from '../state';
import { SearchBox } from './search-box';

interface CommunicationWarningPersonsProps {
    data: Communication;
    emailAccount: EmailAccountView;
    persons: List<Person>;
    updateCommunicationMatchData: (
        communication: Communication,
        data: {
            ignored?: boolean;
            personIds?: string[];
            jobIds?: string[];
            error?: boolean;
            manualOverridePersonIds?: boolean;
            manualOverrideJobIds?: boolean;
            bug?: boolean;
        }
    ) => void;
}

export const CommunicationWarningPersons: React.FunctionComponent<CommunicationWarningPersonsProps> = ({
    data,
    persons,
    updateCommunicationMatchData,
    emailAccount
}) => {
    const { personIds, jobIds } = data;
    const assignPersonId = (id: string) => () => {
        const error = (!jobIds || jobIds.length !== 1) && !data.matchData.manualOverrideJobIds ? true : undefined;
        updateCommunicationMatchData(data, { personIds: [id], error, manualOverridePersonIds: true });
    };
    const markOk = () => {
        const error = (!jobIds || jobIds.length !== 1) && !data.matchData.manualOverrideJobIds ? true : undefined;
        updateCommunicationMatchData(data, { error, manualOverridePersonIds: true });
    };

    const handleSelect = (selected: Array<{ personId: string; jobId: string }>) => {
        updateCommunicationMatchData(data, {
            error: undefined,
            jobIds: uniq(selected.map((s) => s.jobId)),
            manualOverrideJobIds: true,
            manualOverridePersonIds: true,
            personIds: uniq(selected.map((s) => s.personId))
        });
    };

    const personOptions = (personIds || []).map((id) => {
        const name = persons.list.get(id).name.full;
        return <MenuItem key={id} value={id} label={id} primaryText={name} onClick={assignPersonId(id)} />;
    });
    const hintStyle = {
        alignItems: 'center',
        color: white,
        display: 'flex',
        justifyContent: 'center',
        opacity: 0.7
    };
    const personSelector =
        personIds && personIds.length > 0 ? (
            <SelectField
                hintStyle={hintStyle}
                underlineStyle={{ display: 'none' }}
                style={{ width: '150px' }}
                hintText="Assign Person"
                autoWidth={true}
            >
                {personOptions}
            </SelectField>
        ) : null;

    let personIdErrorMessage;
    let confirmButtonLabel;
    if (!personIds || personIds.length === 0) {
        personIdErrorMessage = 'No person could be associated with this email';
        confirmButtonLabel = 'Confirm no candidate for email thread';
    } else if (personIds.length > 1) {
        personIdErrorMessage = 'Multiple people were associated with this email';
        confirmButtonLabel = `Confirm ${personIds.length} candidates for email thread`;
    }

    const shouldShowWarning = (!personIds || personIds.length !== 1) && !data.matchData.manualOverridePersonIds;
    const personIdWarning = shouldShowWarning ? (
        <div className="email-header-warning email-person-warning">
            {personIdErrorMessage}
            <div className="email-match-person-ids-selector">
                {personSelector}
                <FlatButton onClick={markOk} labelStyle={{ color: white }} key="ok" label={confirmButtonLabel} />
                <SearchBox onSelect={handleSelect} prioritizeJobId={emailAccount.jobId} />
            </div>
        </div>
    ) : null;

    return personIdWarning;
};
