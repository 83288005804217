import { useQuery, useSubscription } from '@apollo/client';
import { css } from '@emotion/core';
import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Typography, useTheme } from '@material-ui/core';
import React from 'react';

import { hasRole } from 'shared/models/user';

import { PageDialogLink } from '../../common/page-dialog-link';
import { FlaggedEmails } from '../../containers/flagged-emails';
import { ALL_FLAGGED_EMAIL_ACCOUNTS, FlaggedEmailAccount } from '../../graphql/queries/home';
import { MATERIALIZED_VIEW_REFRESH, RefreshesData } from '../../graphql/queries/view_refreshes';
import { useSession } from '../../hooks/use-session';
import { useUserContext } from '../../hooks/use-user-context';
import { HomePagePanel } from './home-page-panel';
import { InfoIcon } from './info-icon';

const styles = css`
    color: inherit;

    .avatar {
        width: 24px;
        height: 24px;
        font-size: 16px;
    }
`;

const Content: React.FC<{ rows: JSX.Element[] }> = ({ rows }) => <List disablePadding={true}>{rows}</List>;

const Account: React.FC<{ data: FlaggedEmailAccount; list: FlaggedEmailAccount[] }> = ({ data, list }) => {
    const theme = useTheme();

    const primary = <Typography variant="h6">{data.name}</Typography>;
    const secondary = (
        <Typography variant="body2" color="textSecondary" component="div">
            {data.account}
        </Typography>
    );
    const url = '/emails/flagged';
    return (
        <div css={styles}>
            <PageDialogLink
                Component={FlaggedEmails}
                componentProps={{ account: data.account, accounts: list }}
                url={url}
            >
                <ListItem component="a" href={url} button={true} target="_blank">
                    <ListItemAvatar>
                        <Avatar src={data.profilePicUrl} />
                    </ListItemAvatar>
                    <ListItemText disableTypography={true} primary={primary} secondary={secondary} />
                    <InfoIcon
                        tooltip={`${data.count} threads need attention`}
                        icon={<span>{data.count}</span>}
                        color={theme.palette.primary.main}
                    />
                </ListItem>
            </PageDialogLink>
        </div>
    );
};

export const FlaggedEmailAccounts: React.FC = () => {
    const session = useSession();
    const { user } = useUserContext();

    if (!hasRole(session.userPermissions, 'email_match_setter') || session.user.id !== user.id) {
        return null;
    }

    const { data: refreshData } = useSubscription<RefreshesData, { name: string }>(MATERIALIZED_VIEW_REFRESH, {
        variables: { name: 'flagged_email_accounts' }
    });
    const { data, refetch, loading } = useQuery<{ accounts: FlaggedEmailAccount[] }>(ALL_FLAGGED_EMAIL_ACCOUNTS);

    React.useEffect(() => {
        if (!loading) {
            refetch();
        }
    }, [refreshData?.refreshes.lastRefreshAt]);

    return (
        <HomePagePanel
            RowComponent={Account}
            ContentComponent={Content}
            records={data?.accounts}
            title="Email Accounts Needing Attention"
            containerClass="list"
            collapseKeyId="panel-flagged-emails"
        />
    );
};
