import { Map, OrderedMap, OrderedSet } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';

import { EmailTemplateView } from 'shared/models/email-templates';
import { SubmissionAccount } from 'shared/models/job';
import { NoteView } from 'shared/models/note';
import { UserData } from 'shared/models/user';

import { fetchEntityNotes, fetchJobInfo, getAllEmailAccountInfo, getEmailTemplates } from '../actions';
import { SubmitToAccountManager as SubmitToAccountManagerComponent } from '../components/submit-to-account-manager';
import { LoadingModal } from '../core-ui/loading-modal';
import { Client, EmailAccount, Job, List, ListState, PersonDetails, State } from '../state';

interface OwnProps {
    personId: string;
    jobId: string;
    generatedSubmission: string;
    onSave: () => void;
    onCancel: () => void;
}

interface ConnectedProps {
    clients: List<Client>;
    jobs: OrderedMap<string, Job>;
    user: UserData;
    emailAccounts: Map<string, EmailAccount>;
    emailTemplates: EmailTemplateView[];
    personsDetails: List<PersonDetails>;
    notes: Map<string, OrderedSet<NoteView>>;
    listsState: Map<string, ListState>;
}

interface ConnectedDispatch {
    getEmailTemplates: (group: string) => void;
    getAllEmailAccountInfo: () => void;
    fetchJobInfo: (jobId: string) => void;
    fetchEntityNotes: (notable: string) => void;
}

type SubmitToAMComponentProps = OwnProps & ConnectedProps & ConnectedDispatch;

const SubmitToAMContainer: React.FC<SubmitToAMComponentProps> = (props) => {
    const {
        personId,
        jobId,
        clients,
        jobs,
        generatedSubmission,
        emailAccounts,
        emailTemplates,
        personsDetails,
        notes,
        onSave,
        onCancel,
        user
    } = props;

    const notable = `persons-${personId}`;

    React.useEffect(() => {
        if (!jobs.get(jobId)) {
            props.fetchJobInfo(jobId);
        }
        if (!emailTemplates) {
            props.getEmailTemplates('submission');
        }
        if (emailAccounts.isEmpty() || !emailAccounts.has(SubmissionAccount)) {
            props.getAllEmailAccountInfo();
        }
        if (!notes || !notes.has(notable)) {
            props.fetchEntityNotes(notable);
        }
    }, []);

    return !jobs.get(jobId) ||
        !clients.list.get(jobs.get(jobId).clientId) ||
        !emailTemplates ||
        emailAccounts.isEmpty() ||
        !emailAccounts.has(SubmissionAccount) ||
        !notes ||
        !notes.has(notable) ? (
        <LoadingModal />
    ) : (
        <SubmitToAccountManagerComponent
            personId={personId}
            jobId={jobId}
            generatedSubmission={generatedSubmission}
            clients={clients}
            jobs={jobs}
            emailAccounts={emailAccounts}
            emailTemplates={emailTemplates}
            personsDetails={personsDetails}
            notes={notes}
            onSave={onSave}
            onCancel={onCancel}
            user={user}
        />
    );
};

const mapStateToProps = (state: State): ConnectedProps => ({
    clients: state.clients,
    emailAccounts: state.emailAccounts,
    emailTemplates: state.emailTemplates.get('submission'),
    jobs: state.jobs,
    listsState: state.listsState,
    notes: state.notes,
    personsDetails: state.personsDetails,
    user: state.session.user
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    fetchEntityNotes,
    fetchJobInfo,
    getAllEmailAccountInfo,
    getEmailTemplates
};

export const SubmitToAccountManager = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(SubmitToAMContainer);
