import { css } from '@emotion/core';
import { Popover, TextField, Theme, useTheme } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import React from 'react';

const rangeFilterStyles = (theme: Theme) => css`
    .filter-icon-container {
        display: flex;
        align-items: center;
        width: 20px;
        height: 20px;
        font-size: 0.8rem;
        justify-content: flex-end;
        color: ${theme.palette.text.primary};
    }
`;

const popoverStyles = css`
    .MuiPopover-paper {
        display: flex;
        padding: 10px;
        width: 200px;
    }
`;

export interface Range {
    min: number;
    max: number;
}

export const RangeFilter: React.FC<{
    range?: Range;
    onChange: (updated: Range) => void;
}> = ({ range, onChange }) => {
    const [popoverAnchor, setPopoverAnchor] = React.useState(null);
    const anchorRef = React.useRef();
    const theme = useTheme();

    const handleClickFilter = () => {
        setPopoverAnchor(anchorRef.current);
    };

    const handleFilterClose = () => {
        setPopoverAnchor(null);
    };

    const handleMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const min = event.target.value === '' ? undefined : Number(event.target.value);
        onChange({ max: range?.max, min });
    };

    const handleMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const max = event.target.value === '' ? undefined : Number(event.target.value);

        onChange({ max, min: range?.min });
    };
    const filterIcon =
        range?.min !== undefined || range?.max !== undefined ? (
            <i className="fas fa-filter" onClick={handleClickFilter} />
        ) : (
            <FilterList onClick={handleClickFilter} />
        );
    return (
        <div css={rangeFilterStyles(theme)}>
            <div className="filter-icon-container" ref={anchorRef}>
                {filterIcon}
            </div>
            <Popover
                css={popoverStyles}
                open={Boolean(popoverAnchor)}
                anchorEl={popoverAnchor}
                onClose={handleFilterClose}
            >
                <TextField
                    label="min"
                    value={range?.min ?? ''}
                    onChange={handleMinChange}
                    fullWidth={true}
                    type="number"
                    style={{ marginRight: '10px' }}
                />
                <TextField
                    label="max"
                    value={range?.max ?? ''}
                    onChange={handleMaxChange}
                    fullWidth={true}
                    type="number"
                />
            </Popover>
        </div>
    );
};
