import { BASELINE_SEARCH_SORT_RANK } from 'shared/models/search';

import { Search, SearchStatus } from '../state';

export const getStatusLabel = (search: Partial<Search>, status: SearchStatus) => {
    if (search.sortRank === BASELINE_SEARCH_SORT_RANK) {
        return 'Baseline';
    }
    switch (status) {
        case SearchStatus.Aborted:
            return 'Aborted';
        case SearchStatus.Active:
            return 'Active';
        case SearchStatus.Caching:
            return 'Caching';
        case SearchStatus.Complete:
            return 'Complete';
        case SearchStatus.Initial:
            return 'Initial';
        case SearchStatus.Paused:
            return 'Paused';
        case SearchStatus.Waiting:
            return 'Waiting for Initialization';
        case SearchStatus.InitFailed:
            return 'Failed to initialize';
        case SearchStatus.ScoringProfiles:
            return 'Scoring Profiles';
        case SearchStatus.UpdatingProfiles:
            return 'Updating Profiles';
        case SearchStatus.ResultsCached:
            return 'Results Cached';
        case SearchStatus.Archived:
            return 'Archived';
        case SearchStatus.ScoringFailed:
            return 'Scoring Failed';
        default:
            throw new Error('Unknown search status: ' + status);
    }
};
