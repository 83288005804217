import { css } from '@emotion/core';
import { Theme, Typography, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import * as React from 'react';

import { ProfileContent } from 'shared/models/profile';
import { highlightContent, pair } from '../core-ui/profile/utils';
import { useProfileAdornments } from '../hooks/use-profile-adornment';
import { Avatar } from '../sfc/avatar';
import { ProfileAge } from '../sfc/profile-age';
import { CompanyInfo } from './company-info';
import { SchoolInfo } from './school-info';

const styles = (theme: Theme) => css`
    position: relative;
    padding-right: 125px;
    flex: 1 1 auto;

    .profile-age {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    .text-separator {
        &:before {
            content: '·';
            padding: 0 2px 0 4px;
            font-weight: 900;
        }

        &:after {
            content: '';
            padding: 0 1px;
        }
    }

    .secondary-text {
        color: rgba(0, 0, 0, 0.42);
    }

    .section {
        display: flex;
        margin-bottom: 15px;
        line-height: 22px;

        &:last-child {
            margin-bottom: 0;
        }

        .section-icon {
            flex: 0 0 auto;
            width: 80px;
            text-align: right;
            color: ${theme.palette.text.secondary};
        }

        .section-details {
            flex: 1 1 auto;
            padding-left: 20px;
            color: ${theme.palette.text.primary};
        }
    }

    .cursor {
        cursor: pointer;
    }

    .school-name {
        color: ${theme.palette.primary.main};
        cursor: default;

        &.no-ranks {
            color: ${theme.palette.text.primary};
        }
    }
`;

const avatarSize = 60;
const maxPreviewItems = 5;

const DetailsWithSeparator: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const validChildren = React.Children.toArray(children).filter((child) => child);
    const childrenWithDots = validChildren.map((child, index) => (
        <React.Fragment key={index}>
            {child}
            {index < validChildren.length - 1 ? <span className="text-separator" /> : null}
        </React.Fragment>
    ));
    return <div>{childrenWithDots}</div>;
};

export interface PersonProfilePreviewProps {
    person: {
        id: string;
        name: {
            first: string;
            last: string;
            full: string;
        };
    };
    profile: ProfileContent;
    viewedAt: number;
    onClick?: () => void;
}

export const PersonProfilePreview: React.FC<PersonProfilePreviewProps> = ({ person, profile, viewedAt, onClick }) => {
    const theme = useTheme();
    const { getAdornmentsForPerson } = useProfileAdornments();
    const { insertElements, highlightTerms } = getAdornmentsForPerson(person?.id);
    const scoreAdornment = insertElements?.find((a) => a.slot === 'score')?.element;
    const explanationAdornment = insertElements?.find((a) => a.slot === 'explanation')?.element;

    const highlight = (content: string) => {
        return highlightContent(content, highlightTerms);
    };

    const handleClick = () => {
        onClick?.();
    };

    const moreItems = (
        <div className="secondary-text" key="more-items">
            more...
        </div>
    );

    if (!person) {
        const skeletonItems = Array.from({ length: 10 }, (_, i) => (
            <div key={i}>
                <Skeleton variant="text" />
            </div>
        ));
        return <div className="preview-loading">{skeletonItems}</div>;
    }

    const currentPositions = profile.positions.filter((p) => p.current).slice(0, maxPreviewItems);
    const currentPositionsItems = currentPositions.map((p, i) => (
        <DetailsWithSeparator key={i}>
            {highlight(p.title)}
            <CompanyInfo data={p} highlight={highlight} />
            {pair(p.startDate, 'Present', '-', 'secondary-text')}
        </DetailsWithSeparator>
    ));
    if (currentPositions.length > maxPreviewItems) {
        currentPositionsItems.push(moreItems);
    }

    const pastPositions = profile.positions.filter((p) => !p.current).slice(0, maxPreviewItems);
    const pastPositionsItems = pastPositions.map((p, i) => (
        <DetailsWithSeparator key={i}>
            {highlight(p.title)}
            <CompanyInfo data={p} />
            {pair(p.startDate, p.endDate, '-', 'secondary-text')}
        </DetailsWithSeparator>
    ));
    if (pastPositions.length > maxPreviewItems) {
        pastPositionsItems.push(moreItems);
    }

    const educations = profile.educations
        ? profile.educations.slice(0, maxPreviewItems).map((e, i) => {
              const duration = pair(e.startDate, e.endDate, '-', 'secondary-text');
              const degreeAndMajor = pair(highlight(e.degree), highlight(e.fieldOfStudy), ' ');
              return (
                  <DetailsWithSeparator key={i}>
                      {degreeAndMajor}
                      <SchoolInfo education={e} highlight={highlight} />
                      {duration}
                  </DetailsWithSeparator>
              );
          })
        : [];
    if (educations.length > maxPreviewItems) {
        educations.push(moreItems);
    }

    return (
        <div css={styles(theme)}>
            {scoreAdornment}
            <div className="profile-age">
                <ProfileAge viewedAt={viewedAt} />
            </div>
            <div className="section">
                <div className="section-icon cursor" onClick={handleClick}>
                    <Avatar entity={person} size={avatarSize} />
                </div>
                <div className="section-details cursor" onClick={handleClick}>
                    <Typography variant="h4" component="div">
                        {person.name.full}
                    </Typography>
                    <div>{highlight(profile.headline)}</div>
                    <div>{highlight(profile.location)}</div>
                </div>
            </div>
            {explanationAdornment}
            <div className="section">
                <div className="section-icon">Current</div>
                <div className="section-details">{currentPositionsItems}</div>
            </div>
            <div className="section">
                <div className="section-icon">Past</div>
                <div className="section-details">{pastPositionsItems}</div>
            </div>
            <div className="section">
                <div className="section-icon">Education</div>
                <div className="section-details">{educations}</div>
            </div>
        </div>
    );
};
