import * as React from 'react';

import { Header } from '../components/header';
import { AnalyticsReport } from '../sfc/analytics-report';

export const AnalyticsReportPage = () => (
    <div id="container">
        <Header title="Stats Report" />
        <div id="content" className="flex-fill">
            <AnalyticsReport />
        </div>
    </div>
);
