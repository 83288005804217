import { useMutation } from '@apollo/client';
import { css } from '@emotion/core';
import { AppBar, Button, Dialog, DialogContent, Slide, Theme, Toolbar, useTheme } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { orderBy, pickBy } from 'lodash';
import * as React from 'react';
import { formatNumberForGoogleVoice, isValidUSNumber } from 'shared/models/contact';

import { NoteData } from 'shared/models/note';
import { PersonData, PersonSalary, RecruitingActivity } from 'shared/models/person';
import { deleteDraft } from '../common/draft-storage';
import { SAVE_PHONESCREEN_DATA } from '../graphql/queries/person';
import { useSnackbar } from '../hooks/use-snackbar';
import { Avatar } from '../sfc/avatar';
import { Client, Contact, Job, PersonDetails } from '../state';
import { PhoneScreenForm, PhoneScreenFormData } from './phone-screen-form';
import { PhoneScreenJobs } from './phone-screen-jobs';
import { PhoneScreenProfile } from './phone-screen-profile';

interface PhoneScreenDialogProps {
    open: boolean;
    personDetails: PersonDetails;
    job: Job;
    client: Client;
    onClose: () => void;
    postSave: () => void;
}

// tslint:disable: no-magic-numbers
const styles = (theme: Theme) => css`
    .content {
        padding: 0;
        display: flex;
        flex-direction: column;
    }

    .app-bar {
        color: white;

        .heading {
            font-weight: 400;
            font-size: 24px;
        }

        .phone-number {
            font-size: 16px;
            line-height: 24px;
            margin-top: 2px;
            color: white;
            text-decoration: none;
            display: block;
            cursor: pointer;
        }

        .toolbar {
            display: flex;
            justify-content: space-between;
            align-items: center;

            button {
                margin-right: 20px;
                color: white;
            }

            .toolbar-right {
                display: flex;
                align-items: center;
            }

            .candidate-avatar {
                display: flex;
                align-items: center;
                padding: ${theme.spacing(2)}px 0;
            }
        }
    }

    .phonescreen-main {
        flex-grow: 1;
        display: flex;
        background: rgb(244, 246, 248);
        overflow: hidden;

        div::-webkit-scrollbar-thumb {
            border-radius: ${theme.shape.borderRadius}px;
        }
    }
`;
// tslint:enable: no-magic-numbers

const Transition = React.forwardRef(
    (props: TransitionProps & { children?: React.ReactElement }, ref: React.Ref<unknown>) => {
        return <Slide direction="up" ref={ref} {...props} />;
    }
);

const avatarSize = 48;

export const PhoneScreenDialog: React.FC<PhoneScreenDialogProps> = ({
    open,
    personDetails,
    job,
    client,
    onClose,
    postSave
}) => {
    const theme = useTheme();
    const { setSnackbar } = useSnackbar();
    const getInitialData = () =>
        ({
            activity: undefined,
            location: personDetails.person.location ?? personDetails.profile?.content.location,
            note: undefined,
            salary: undefined,
            visa: personDetails.person.visaStatusSource === 'user_input' ? personDetails.person.visaStatus : undefined
        }) as PhoneScreenFormData;
    const [data, setData] = React.useState<PhoneScreenFormData>(getInitialData());
    const [saveData] = useMutation<{ personId: string; note: Partial<NoteData>; personUpdates: Partial<PersonData> }>(
        SAVE_PHONESCREEN_DATA
    );

    React.useEffect(() => {
        setData(getInitialData());
    }, [personDetails.person.id]);

    const noteDraftKey = `phonescreen-note-draft-${job.id}-${personDetails.person.id}`;

    const handleSave = () => {
        onClose();

        const salary: PersonSalary[] = data.salary
            ? (personDetails.person.salary ?? []).concat([
                  {
                      jobId: job.id,
                      kind: 'desired',
                      value: data.salary
                  }
              ])
            : undefined;

        const recruitingActivity: RecruitingActivity[] = data.activity
            ? (personDetails.person.recruitingActivity ?? []).concat([
                  {
                      createdAt: Date.now(),
                      jobId: job.id,
                      value: data.activity
                  }
              ])
            : undefined;

        const note = {
            content: data.note.content,
            context: data.note.context,
            format: 'html',
            notable: `persons-${personDetails.person.id}`
        };

        const personUpdates = pickBy({
            location: data.location !== personDetails.profile?.content?.location ? data.location : undefined,
            recruitingActivity,
            salary,
            visaStatus: data.visa,
            visaStatusSource: data.visa ? 'user_input' : undefined
        });

        saveData({
            variables: {
                note,
                personId: personDetails.person.id,
                personUpdates
            }
        }).then(() => {
            deleteDraft(noteDraftKey);
            postSave();
        });
    };

    const handlePhoneNumberClick = (value: string) => () => {
        navigator.clipboard.writeText(value);
        setSnackbar('Phone number copied to the clipboard');
    };

    const phoneNumber = orderBy(
        personDetails.contacts.filter((c) => c.channel === 'phone' && c.invalid === false),
        ['primary', (c: Contact) => c.contactType === 'personal', 'createdAt'],
        ['desc', 'desc', 'desc']
    )[0]?.value;

    const phoneNumberInfo =
        phoneNumber && isValidUSNumber(phoneNumber) ? (
            <a className="phone-number" href={`callto:${formatNumberForGoogleVoice(phoneNumber)}`}>
                {phoneNumber}
            </a>
        ) : phoneNumber ? (
            <div className="phone-number" onClick={handlePhoneNumberClick(phoneNumber)}>
                {phoneNumber}
            </div>
        ) : null;

    return (
        <Dialog open={open} onClose={onClose} fullScreen={true} TransitionComponent={Transition} css={styles(theme)}>
            <DialogContent className="content">
                <AppBar position="static" className="app-bar">
                    <Toolbar className="toolbar">
                        <div className="toolbar-left">
                            <div className="heading">{personDetails.person.name.full}</div>
                            {phoneNumberInfo}
                        </div>
                        <div className="toolbar-right">
                            <Button onClick={onClose}>Cancel</Button>
                            <Button onClick={handleSave}>Save</Button>
                            <div className="candidate-avatar">
                                <Avatar entity={personDetails.person} size={avatarSize} />
                            </div>
                        </div>
                    </Toolbar>
                </AppBar>
                <div className="phonescreen-main">
                    <PhoneScreenProfile personDetails={personDetails} job={job} client={client} />
                    <PhoneScreenForm
                        job={job}
                        personDetails={personDetails}
                        noteDraftKey={noteDraftKey}
                        data={data}
                        onChange={setData}
                    />
                    <PhoneScreenJobs personId={personDetails.person.id} job={job} />
                </div>
            </DialogContent>
        </Dialog>
    );
};
