import { css } from '@emotion/core';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@material-ui/core';
import { startCase } from 'lodash';
import React from 'react';

import { jdUpdateContextDescription, JDUpdateResultSchema } from 'shared/models/jd-llm-updates';
import { hasRole } from 'shared/models/user';

import { downloadFile } from '../api';
import { PageDialogLink } from '../common/page-dialog-link';
import { fullDateTime } from '../common/timestamp';
import { JobEdit } from '../containers/job-edit';
import { JDLLMUpdate } from '../graphql/queries/job-form';
import { useModal } from '../hooks/use-modal';
import { usePromptInfo } from '../hooks/use-prompt-info';
import { useSession } from '../hooks/use-session';
import { JDDiff } from './jd-diffs';

const detailsDialogStyles = css`
    .MuiDialogTitle-root {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;

const rawResultsStyle = css`
    display: flex;
    flex-direction: column;

    .MuiInputBase-inputMultiline {
        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            background-color: white;
        }

        &::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.3);
        }
    }

    .MuiTextField-root {
        margin-bottom: 40px;
    }
`;

const textFieldRows = 10;

export const JDLLMUpdateDetails: React.FC<{
    open: boolean;
    onClose: () => void;
    job: { id: string; title: string; client: { name: string } };
    updateData: JDLLMUpdate;
}> = ({ open, onClose, job, updateData }) => {
    const session = useSession();
    const { setAlert, showLoading, hideLoading } = useModal();
    const { showPromptInfo } = usePromptInfo();

    const handleViewInput = async () => {
        try {
            showLoading();
            const response = await downloadFile(updateData.input);
            const input = await response.json();
            setAlert('JD Changes Input', <TextField value={input} multiline={true} fullWidth={true} />, false, true);
            hideLoading();
        } catch {
            hideLoading();
            setAlert('Error', 'Failed to fetch input');
        }
    };

    const handleShowPrompts = () => {
        showPromptInfo(updateData.resultsFile);
    };

    const handleShowRawResults = async () => {
        try {
            showLoading();
            const response = await downloadFile(updateData.resultsFile);
            const responseContent: { parsedResponse: JDUpdateResultSchema } = await response.json();
            hideLoading();
            const fields: Array<keyof JDUpdateResultSchema> = [
                'relevantInformation',
                'removedInformation',
                'addedInformation',
                'updatedInformation',
                'finalData'
            ];
            const textFields = fields.map((field) => {
                if (!responseContent.parsedResponse[field]) return null;
                const value =
                    typeof responseContent.parsedResponse[field] === 'object'
                        ? JSON.stringify(responseContent.parsedResponse[field], null, 2)
                        : responseContent.parsedResponse[field];
                const rows = field === 'finalData' ? textFieldRows * 2 : textFieldRows / 2;
                return responseContent.parsedResponse[field] ? (
                    <TextField
                        key={field}
                        value={value}
                        label={startCase(field)}
                        fullWidth={true}
                        multiline={true}
                        variant="outlined"
                        rows={rows}
                        InputProps={{ readOnly: true }}
                    />
                ) : null;
            });
            const info = (
                <div css={rawResultsStyle}>
                    {textFields}
                    <TextField
                        value={JSON.stringify(responseContent.parsedResponse, null, 2)}
                        label="Full Result"
                        fullWidth={true}
                        multiline={true}
                        variant="outlined"
                        rows={textFieldRows * 2}
                        InputProps={{ readOnly: true }}
                    />
                </div>
            );
            setAlert('JD Update - Raw LLM Result', info, false, true);
        } catch {
            hideLoading();
            setAlert('Error', 'Failed to fetch results');
        }
    };

    const viewPromptsButton = hasRole(session.userPermissions, 'prompts_editor') ? (
        <Button onClick={handleShowPrompts}>Prompts</Button>
    ) : null;
    const viewRawResultsButton = hasRole(session.userPermissions, 'prompts_editor') ? (
        <Button onClick={handleShowRawResults}>Raw Result</Button>
    ) : null;

    return (
        <Dialog open={open} onClose={onClose} css={detailsDialogStyles} fullWidth={true} maxWidth="md">
            <DialogTitle disableTypography={true}>
                <Typography variant="h4" component="div">
                    {job.client.name} - {job.title}
                </Typography>
                <Typography variant="caption" color="textSecondary" component="div">
                    {jdUpdateContextDescription(updateData.context)} - {fullDateTime(updateData.updatedAt)}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <JDDiff updates={updateData.updates} />
            </DialogContent>
            <DialogActions>
                {viewPromptsButton}
                {viewRawResultsButton}
                <PageDialogLink Component={JobEdit} componentProps={{ id: job.id }} url={`/job/${job.id}/edit`}>
                    <Button href={`/job/${job.id}/edit`}>Job Settings</Button>
                </PageDialogLink>
                <Button onClick={handleViewInput}>Input</Button>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};
