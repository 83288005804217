import { css } from '@emotion/core';
import { LinearProgress, List, ListItem, ListItemText } from '@material-ui/core';
import { orderBy } from 'lodash';
import React from 'react';

import { useFees } from '../hooks/use-fees';
import { Fee } from './fee';

const styles = css`
    .MuiListItem-container {
        .MuiIconButton-root {
            margin-left: 10px;
        }

        .MuiListItemText-root {
            padding-right: 240px;
        }

        .show-on-hover {
            opacity: 0;
            width: 0;
            padding: 0;
            overflow: hidden;
            transition:
                opacity 200ms ease-out,
                width 200ms ease-out;
        }

        &:hover {
            .show-on-hover {
                opacity: 1;
                width: 48px;
                padding: 12px;
            }
        }
    }
`;

export const FeesList: React.FC = () => {
    const { data: fees, onAddFee } = useFees();

    const feesList = orderBy(
        fees,
        [
            (fee) => {
                const paidAmount = fee.invoices
                    .map((i) => i.lineItems.filter((l) => l.feeId === fee.id))
                    .reduce((acc, l) => {
                        return acc + l.reduce((acc2, l2) => acc2 + l2.amount, 0);
                    }, 0);
                return paidAmount === fee.amount ? 0 : 1;
            },
            (fee) => fee.incurredAt
        ],
        ['desc', 'desc']
    )
        .filter((fee) => fee.amount > 0 && !fee.appliedToFeeId)
        .map((fee) => <Fee key={fee.id} fee={fee} />);

    const list = !fees ? (
        <LinearProgress variant="indeterminate" />
    ) : feesList.length === 0 ? (
        <ListItem>
            <ListItemText primary="No Fees" secondary="No fees found" />
        </ListItem>
    ) : (
        feesList
    );

    const addFeeButton = !onAddFee ? null : (
        <ListItem button={true} onClick={onAddFee}>
            <ListItemText primary="Add Fees" />
        </ListItem>
    );

    return (
        <List css={styles}>
            {list}
            {addFeeButton}
        </List>
    );
};
