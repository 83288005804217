import { gql } from 'graphql-tag';

export interface School {
    name: string;
    url: string;
    count: number;
}

export const SCHOOL_QUERY = gql`
    query GetSchools($search: String!) {
        schools: search_schools(args: { search: $search }) {
            count
            name: schoolName
            url: schoolUrl
        }
    }
`;
