import { Map } from 'immutable';

import { Candidate, Communication, CommunicationView } from '../state';

export const unexpectedEmailAddress = (comm: Communication) => {
    return !!comm.unknownEmailFound && !comm.bounceReport;
};

export const communicationNeedsAction = (comm: Communication) => {
    return comm.labels.indexOf('INBOX') !== -1 || !!comm.matchData.error || unexpectedEmailAddress(comm);
};

export const filteredAttachmentsMimeTypes = new Set(['application/ics', 'text/calendar']);

export const commViewLocation = (view: CommunicationView) => {
    if (!view.userAgent || view.userAgent.match(/GoogleImageProxy/)) {
        return '-';
    }
    if (view.geoIP) {
        if (view.geoIP.country === 'United States') {
            return view.geoIP.city;
        } else {
            return `${view.geoIP.city}, ${view.geoIP.country}`;
        }
    }
    return view.ips;
};

export const commViewClient = (view: CommunicationView) => {
    if (!view.userAgent) {
        return '-';
    }
    if (view.userAgent.match(/GoogleImageProxy/)) {
        return 'Gmail';
    }
    if (view.userAgent.match(/Chrome/)) {
        return 'Chrome';
    }
    if (view.userAgent.match(/AppleWebKit/)) {
        return 'Safari';
    }
    if (view.userAgent.match(/iPhone/)) {
        return 'iPhone';
    }
    return view.userAgent;
};

export type EmailsView = 'candidate' | 'client' | 'candidate-and-client';

export const getPendingCountsByView = (pendingEmails: Candidate['pendingEmails']) => {
    let counts = Map<EmailsView, number>();
    if (pendingEmails) {
        counts = counts.set(
            'candidate',
            pendingEmails.filter((c) => c.category === 'candidate' || c.category === 'unknown').length
        );
        counts = counts.set('client', pendingEmails.filter((c) => c.category === 'client').length);
        counts = counts.set('candidate-and-client', pendingEmails.filter((c) => c.category === 'shared').length);
    }
    return counts;
};
