import { css } from '@emotion/core';
import { useTheme } from '@material-ui/core';
import { Pause } from '@material-ui/icons';
import React from 'react';
import { Link } from 'react-router-dom';

import { JobStatus } from 'shared/models/job';
import { PageDialogLink } from '../../common/page-dialog-link';
import { JobCandidatesBoard } from '../../containers/job-candidates-board';

import { InfoIcon } from './info-icon';

const styles = css`
    display: inline-flex;
    align-items: center;
`;

export const JobTitle: React.FC<{
    job: {
        id: string;
        title: string;
        status: JobStatus;
    };
}> = ({ job }) => {
    const theme = useTheme();

    const icon =
        job.status === JobStatus.Active ? null : (
            <InfoIcon tooltip="Paused Job" color={theme.palette.error.main} icon={<Pause />} className="left-pad" />
        );

    const url = `/job/${job.id}/board`;

    return (
        <PageDialogLink Component={JobCandidatesBoard} componentProps={{ jobId: job.id }} url={url}>
            <Link to={url}>
                <span css={styles}>
                    <span>{job.title}</span>
                    {icon}
                </span>
            </Link>
        </PageDialogLink>
    );
};
