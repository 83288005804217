import { gql } from '@apollo/client';

import { SearchConfig } from 'shared/models/search';

export interface PresetsData {
    presets: Array<{
        id: string;
        group: string;
        name: string;
        createdAt: number;
        data: {
            config: SearchConfig;
            sort: 'skillScore' | 'similarityScore' | 'totalScore';
        };
        user: {
            id: string;
            name: string;
        };
    }>;
}

export interface PresetSaveResponse {
    presets: {
        returning: Array<{
            id: string;
            name: string;
        }>;
    };
}

export const INSERT_PRESET = gql`
    mutation InsertPreset($preset: search_presets_insert_input!) {
        presets: insert_search_presets(objects: [$preset]) {
            returning {
                id
                name
            }
        }
    }
`;

export const UPDATE_PRESET = gql`
    mutation UpdatePreset($id: String!, $updates: search_presets_set_input!) {
        presets: update_search_presets(where: { id: { _eq: $id } }, _set: $updates) {
            returning {
                id
                name
            }
        }
    }
`;

export const FETCH_SEARCH_PRESETS = gql`
    query SearchPresets($userId: String!) {
        presets: search_presets(
            where: {
                _or: [
                    { group: { _eq: "search-shared" } }
                    { group: { _eq: "search-personal" }, createdBy: { _eq: $userId } }
                ]
            }
            order_by: { name: asc }
        ) {
            id
            group
            name
            createdAt
            data: list
            user {
                id
                name(path: "full")
            }
        }
    }
`;
