import { IconButton, Tooltip } from '@material-ui/core';
import { Menu, People, Settings } from '@material-ui/icons';
import React from 'react';
import { Link } from 'react-router-dom';

import { JobStatus } from 'shared/models/job';

import { PageDialogLink } from '../../common/page-dialog-link';
import { JobEdit } from '../../containers/job-edit';
import { CandidatesOutreach } from '../candidates-outreach-button';
import { JobSearches } from '../job-searches';

export const JobActions: React.FC<{
    id: string;
    client: { name: string };
    status: JobStatus;
    dailyEmailLimit: number;
}> = ({ client, id, status, dailyEmailLimit }) => {
    const editUrl = `/job/${id}/edit`;
    const searchesUrl = `/job/${id}/searches`;

    return (
        <>
            <span>
                <span className="initial">
                    <IconButton size="small">
                        <Menu fontSize="small" />
                    </IconButton>
                </span>
                <span className="hovered">
                    <CandidatesOutreach
                        client={client}
                        jobId={id}
                        dailyEmailLimit={dailyEmailLimit}
                        status={status}
                        iconColor="primary"
                        iconSize="small"
                    />
                    <PageDialogLink Component={JobEdit} componentProps={{ id }} url={editUrl}>
                        <Tooltip title="View/Edit Job Settings">
                            <Link to={editUrl}>
                                <IconButton size="small">
                                    <Settings fontSize="small" />
                                </IconButton>
                            </Link>
                        </Tooltip>
                    </PageDialogLink>
                    <PageDialogLink Component={JobSearches} componentProps={{ id }} url={searchesUrl}>
                        <Tooltip title="Job Searches">
                            <Link to={searchesUrl}>
                                <IconButton size="small">
                                    <People fontSize="small" />
                                </IconButton>
                            </Link>
                        </Tooltip>
                    </PageDialogLink>
                </span>
            </span>
        </>
    );
};
