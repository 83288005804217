import { Avatar, Chip, TextField as MUITextField, Tooltip } from '@material-ui/core';
import { Autocomplete, GetTagProps, RenderInputParams } from '@material-ui/lab';
import React from 'react';

import { standardizeBlacklistEntry } from '../common/blacklist-utils';
import { ClientFormData } from '../graphql/queries/clients';
import { useFormContext } from './form-context';

export const ClientFormCompanySpecificBlacklist: React.FC = () => {
    const {
        data: { blacklistedLinks },
        onFieldChange,
        disabled
    } = useFormContext<ClientFormData>();

    const onChange = onFieldChange('blacklistedLinks');

    const handleToggleBlacklistOption = (index: number) => () => {
        const updated = blacklistedLinks.map((item, i) => {
            if (i === index) {
                // go in the order all -> current -> past
                if (item.current && item.past) {
                    return { ...item, current: true, past: false };
                } else if (item.current) {
                    return { ...item, current: false, past: false };
                } else {
                    return { ...item, current: true, past: true };
                }
            }
            return item;
        });
        onChange(updated);
    };

    const handleBlacklistedLinksChange = (
        _1: React.ChangeEvent<{}>,
        newValue: Array<ClientFormData['blacklistedLinks'][0] | string>
    ) => {
        // Ensure that if the user types in a new freeSolo value, it is added as an object with default values
        const updatedValue: ClientFormData['blacklistedLinks'] = newValue.map((item) => {
            if (typeof item === 'string') {
                return { link: standardizeBlacklistEntry(item), current: true, past: false };
            }
            return item;
        });
        onChange(updatedValue);
    };

    const renderBlacklistedLinksInput = (params: RenderInputParams) => (
        <MUITextField label="Company Specific Blacklist" {...params} fullWidth={true} />
    );

    const renderBlacklistedLinksTags = (tagValue: ClientFormData['blacklistedLinks'], getTagProps: GetTagProps) =>
        tagValue.map((option, index) => {
            const avatarText = option.current && option.past ? 'All' : option.current ? 'Current' : 'Past';
            const avatar = (
                <Tooltip title="Click to change">
                    <Avatar className="blacklist-kind-avatar">{avatarText}</Avatar>
                </Tooltip>
            );
            return (
                <Chip
                    onClick={handleToggleBlacklistOption(index)}
                    key={option.link}
                    avatar={avatar}
                    label={option.link}
                    {...getTagProps({ index })}
                />
            );
        });

    return (
        <Autocomplete
            fullWidth={true}
            multiple={true}
            options={[]}
            value={blacklistedLinks}
            disableClearable={true}
            renderInput={renderBlacklistedLinksInput}
            renderTags={renderBlacklistedLinksTags}
            disabled={disabled}
            onChange={handleBlacklistedLinksChange}
            freeSolo={true}
        />
    );
};
