import { OrderedMap } from 'immutable';

import { Action } from '../actions';
import { deleteDraft } from '../common/draft-storage';
import { logger } from '../common/logger';
import {
    ComposeEmail,
    ReceiveScheduledDisqualificationMail,
    ReceiveScheduledMessage,
    ReceiveSentDisqualificationMail,
    ReceiveSentMail,
    RequestCloseWindow,
    RequestSendDisqualificationMail,
    RequestSendMail,
    ResetComposeWindows,
    ToggleComposeWindow
} from './actions';
import { ComposeEmailWindowData } from './types';

export function emailComposeWindows(
    state: OrderedMap<string, ComposeEmailWindowData> = OrderedMap(),
    action: Action
): OrderedMap<string, ComposeEmailWindowData> {
    switch (action.type) {
        case ComposeEmail:
            return state.has(action.payload.windowId) ? state : state.set(action.payload.windowId, action.payload);
        case RequestSendDisqualificationMail:
        case RequestSendMail:
        case RequestCloseWindow: {
            return state.remove(action.payload.windowId);
        }
        case ResetComposeWindows:
            return OrderedMap();
        case ToggleComposeWindow: {
            const { windowId, disabled } = action.payload;
            return state.set(windowId, { ...state.get(windowId), disabled });
        }
        case ReceiveScheduledMessage:
        case ReceiveScheduledDisqualificationMail:
        case ReceiveSentDisqualificationMail:
        case ReceiveSentMail: {
            deleteDraft(`email-compose-${action.payload.windowId}`).catch((err) =>
                logger.warn(err, { message: 'error deleting draft' })
            );
            return state;
        }
        default:
            return state;
    }
}
