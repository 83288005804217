import { Chip } from 'material-ui';
import * as moment from 'moment';
import * as React from 'react';

interface ProfileAgeProps {
    viewedAt: number;
}

const [amberA100, black, deepOrangeA100, greenA100, greenA400, white, yellow500] = [
    '#ffab00',
    '#000000',
    '#dd2c00',
    '#00c853',
    '#00e676',
    '#ffffff',
    '#ffeb3b'
];

export const ProfileAge = ({ viewedAt }: ProfileAgeProps) => {
    const age = moment().diff(viewedAt, 'days');
    // tslint:disable:no-magic-numbers
    const ageBgColor =
        age <= 14 ? greenA100 : age <= 60 ? greenA400 : age <= 90 ? yellow500 : age <= 120 ? amberA100 : deepOrangeA100;
    const ageTextColor = ageBgColor === deepOrangeA100 ? white : black;
    // tslint:enable:no-magic-numbers
    return (
        <Chip backgroundColor={ageBgColor} labelColor={ageTextColor}>
            {moment(viewedAt).fromNow()}
        </Chip>
    );
};
