import { Dialog, FlatButton, TextField } from 'material-ui';
import * as React from 'react';

interface DoNotEmailAgainProps {
    show: boolean;
    requestClose: () => void;
    onDisqualify?: (note: string) => void;
}

interface DoNotEmailAgainState {
    optOutNote: string;
}

export class DoNotEmailAgain extends React.Component<DoNotEmailAgainProps, DoNotEmailAgainState> {
    constructor(props: DoNotEmailAgainProps) {
        super(props);
        this.state = { optOutNote: '' };
    }

    editNoteValue = (_1: React.FormEvent<{}>, value: string) => {
        this.setState({ optOutNote: value });
    };

    handleDoNotEmail = () => {
        event.preventDefault();
        this.props.onDisqualify(this.state.optOutNote);
    };

    render() {
        const doNotEmailDialogActions = [
            <FlatButton
                key="do-not-email-again-cancel"
                label="Cancel"
                primary={true}
                onClick={this.props.requestClose}
            />,
            <FlatButton
                key="do-not-email-again-disqualify"
                label="Disqualify"
                primary={true}
                onClick={this.handleDoNotEmail}
            />
        ];
        return (
            <Dialog
                className="opt-out-dialog"
                open={this.props.show}
                onRequestClose={this.props.requestClose}
                actions={doNotEmailDialogActions}
                title="Do Not Email"
            >
                <p>Are you sure?</p>
                <TextField hintText="Add Note" onChange={this.editNoteValue} multiLine={true} />
                <div className="opt-out-note">
                    This will disqualify the candidate and remove from ALL active email sequences.
                </div>
            </Dialog>
        );
    }
}
