import { OrderedMap } from 'immutable';
import { find } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';

import { JobStageData } from 'shared/models/job-stages';

import { fetchJobInfo } from '../actions';
import { Job, State } from '../state';

interface OwnProps {
    id: string;
    stage: string;
}

interface ConnectedProps {
    jobs: OrderedMap<string, Job>;
    jobStages: JobStageData[];
}

interface ConnectedDispatch {
    fetchJobInfo: (id: string) => void;
}

type JobStageInfoComponentProps = OwnProps & ConnectedDispatch & ConnectedProps;

class JobStageInfoComponent extends React.Component<JobStageInfoComponentProps, undefined> {
    constructor(props: JobStageInfoComponentProps) {
        super(props);
        this.ensureDataExists(props);
    }

    ensureDataExists(props: JobStageInfoComponentProps) {
        const { id, jobs } = props;
        if (!jobs || !jobs.get(id)) {
            this.props.fetchJobInfo(id);
        }
    }

    componentDidUpdate() {
        this.ensureDataExists(this.props);
    }

    render() {
        const { id, jobs, stage, jobStages } = this.props;
        if (jobs && jobs.get(id)) {
            const foundStage = find(jobStages, { name: stage });
            const mappedStage = foundStage ? foundStage.label : stage;
            return <span>{mappedStage}</span>;
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    jobStages: state.appConstants.constants.jobStages,
    jobs: state.jobs
});
const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    fetchJobInfo
};
export const JobStageInfo = connect<ConnectedProps, ConnectedProps, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(JobStageInfoComponent);
