import { css } from '@emotion/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, useTheme } from '@material-ui/core';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { groupBy, map, orderBy, sumBy, times } from 'lodash';
import React, { useState } from 'react';

import { isoDate } from '../../common/timestamp';
import { OutreachMetrics } from '../../graphql/queries/metrics';

type Column = 'Sequence Name' | 'Sent' | 'Opens' | 'Open Rate' | 'Response' | 'Response Rate';

const skeletonRowsCount = 10;
const factor = 100;

const styles = (theme: Theme) => css`
    .MuiTableContainer-root {
        .column-action-icon {
            display: inline-flex;
            align-items: center;
            opacity: 0;
            transition: opacity 200ms;
            margin-left: 5px;
            cursor: pointer;

            .MuiSvgIcon-root {
                font-size: 1.25rem;
                color: ${theme.palette.text.secondary};
            }

            &.visible {
                opacity: 1;
            }
        }

        .table-header-cell {
            display: inline-flex;
            align-items: center;
            cursor: pointer;

            &:hover {
                .column-action-filter.column-action-icon {
                    opacity: 1;
                }
            }
        }
    }
`;

const columns: Column[] = ['Sequence Name', 'Sent', 'Opens', 'Open Rate', 'Response', 'Response Rate'];

export const FunnelMetricsOutreach: React.FC<{
    metrics: OutreachMetrics[];
    showJobTitle: boolean;
}> = ({ metrics, showJobTitle }) => {
    const theme = useTheme();

    const [sortAsc, setSortAsc] = useState<'asc' | 'desc'>('asc');
    const [sortCol, setSortCol] = useState<Column>('Sequence Name');

    const valueFunc =
        (col: Column) =>
        (record: {
            sequenceId: string;
            opens: number;
            sent: number;
            openRate: number;
            response: number;
            responseRate: number;
            sequence: {
                id: string;
                title: string;
                archived: boolean;
                createdAt: number;
            };
            job: {
                id: string;
                title: string;
            };
        }) => {
            const sequenceName = `${showJobTitle ? `${record.job.title} - ` : ''} ${record.sequence.title} ${
                record.sequence.archived ? `(${isoDate(record.sequence.createdAt)})` : ''
            }`;
            switch (col) {
                case 'Sequence Name':
                    return sequenceName;
                case 'Sent':
                    return record.sent;
                case 'Opens':
                    return record.opens;
                case 'Open Rate':
                    return record.openRate;
                case 'Response':
                    return record.response;
                case 'Response Rate':
                    return record.responseRate;
                default:
                    return null;
            }
        };

    const handleSortChange = (col: Column) => () => {
        if (col === sortCol) {
            if (sortAsc === 'asc') {
                setSortAsc('desc');
            } else {
                setSortAsc('asc');
            }
        } else {
            setSortCol(col);
            setSortAsc('desc');
        }
    };

    const headers = columns.map((col) => {
        const sortIcon = sortAsc === 'desc' ? <ArrowDownward /> : <ArrowUpward />;
        const sort = (
            <span className={`column-action-icon ${sortCol === col ? 'visible' : ''}`} onClick={handleSortChange(col)}>
                {sortIcon}
            </span>
        );
        return (
            <TableCell key={col}>
                <span className="table-header-cell">
                    <span onClick={handleSortChange(col)}>{col}</span>
                    {sort}
                </span>
            </TableCell>
        );
    });
    let rows: JSX.Element[];

    if (!!metrics) {
        const statsGroupedBySequence = groupBy(metrics, 'sequenceId');

        const sequenceStats = map(statsGroupedBySequence, (stats) => {
            const totalOpens = sumBy(stats, (s) => s.recipientsReached);
            const totalSent = sumBy(stats, (s) => s.recipientsAdded);
            const totalResponse = sumBy(stats, (s) => s.recipientsResponded);
            return {
                job: stats[0].job,
                openRate: Math.round((totalOpens * factor) / totalSent),
                opens: totalOpens,
                response: totalResponse,
                responseRate: Math.round((totalResponse * factor) / totalSent),
                sent: totalSent,
                sequence: stats[0].sequence,
                sequenceId: stats[0].sequenceId
            };
        });

        const meaningfulStats = sequenceStats.filter((stats) => {
            return stats.sent || stats.opens || stats.response;
        });

        const sortedRows = orderBy(
            meaningfulStats,
            [
                (stats) => {
                    const value = valueFunc(sortCol)(stats);
                    if (typeof value !== 'number' || sortAsc === 'asc') {
                        return value;
                    } else if (Number.isFinite(value)) {
                        return value;
                    } else {
                        // desc sort on numeric values
                        // This will put the NaNs and Infinity values at the end
                        return -Infinity;
                    }
                }
            ],
            [sortAsc]
        );

        rows = sortedRows.map((record) => {
            const openRate = Number.isFinite(record.openRate) ? `${record.openRate}%` : '-';
            const responseRate = Number.isFinite(record.responseRate) ? `${record.responseRate}%` : '-';
            const sequenceName = `${showJobTitle ? `${record.job.title} - ` : ''} ${record.sequence.title} ${
                record.sequence.archived ? `(${isoDate(record.sequence.createdAt)})` : ''
            }`;
            return (
                <TableRow key={record.sequence.id}>
                    <TableCell>{sequenceName}</TableCell>
                    <TableCell>{record.sent}</TableCell>
                    <TableCell>{record.opens}</TableCell>
                    <TableCell>{openRate}</TableCell>
                    <TableCell>{record.response}</TableCell>
                    <TableCell>{responseRate}</TableCell>
                </TableRow>
            );
        });
    } else {
        const skeletonRows = times(skeletonRowsCount).map((i) => {
            const cells = times(columns.length).map((j) => (
                <TableCell key={j}>
                    <Skeleton variant="rect" width="120px" />
                </TableCell>
            ));
            return <TableRow key={i}>{cells}</TableRow>;
        });
        rows = skeletonRows;
    }

    return (
        <div className="chart-container" css={styles(theme)}>
            <TableContainer>
                <Table stickyHeader={true}>
                    <TableHead>
                        <TableRow>{headers}</TableRow>
                    </TableHead>
                    <TableBody>{rows}</TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
