import { Tooltip } from '@material-ui/core';
import { Buffer } from 'buffer';
import { IconButton } from 'material-ui';
import ExpandLess from 'material-ui/svg-icons/navigation/expand-less';
import * as React from 'react';

import { ScheduledMessageView } from 'shared/models/scheduled-messages';
import { EmailAddress } from 'shared/types/email-compose';

import { fullDateTime } from '../common/timestamp';
import { FileDownloadLink } from '../core-ui/file-download-link';
import { insertEmailMarkup } from '../lib/email-markup';
import { EmailAccount } from '../state';
import { CommunicationHeaders } from './communication-headers';

interface OwnProps {
    scheduledMessage: ScheduledMessageView;
    actions: JSX.Element[];
    emailAccount: EmailAccount;
    handleClose: () => void;
    accountInitials: string;
    fromCrossSubmit: boolean;
    emailContentBlacklistedDomains: string;
}

type ScheduledMessagesProps = OwnProps;

export const ScheduledMessagesDetail: React.FC<ScheduledMessagesProps> = ({
    scheduledMessage,
    actions,
    handleClose,
    emailAccount,
    accountInitials,
    emailContentBlacklistedDomains
}) => {
    const emailBodyRef = React.useRef<HTMLDivElement>();

    const { account, payload, scheduledAt, jobId } = scheduledMessage;
    const { headers, body, attachments } = payload;
    const { subject } = headers;

    React.useEffect(() => {
        const forwardedMessage = subject && !!subject.match(/^fwd/i);
        insertEmailMarkup(
            Buffer.from(body).toString('base64'),
            emailBodyRef.current,
            forwardedMessage,
            emailContentBlacklistedDomains,
            []
        );
    }, []);

    const fromCrossSubmitIcon = (
        <Tooltip title="Scheduled Email">
            <div className="is-cross-submit-email">
                <i className="material-icons">schedule</i>
            </div>
        </Tooltip>
    );

    const date = <div className="date">{fullDateTime(scheduledAt)}</div>;

    const accountAddress: EmailAddress = { address: account, name: emailAccount.name.full };

    const attachmentsComponent = (attachments || []).map((a) => {
        if (a.type === 'href') {
            return (
                <a href={a.href} key={a.filename} download={a.filename} className="href-container">
                    <div className="email-attachment">
                        <div className="list-centered-icon">
                            <i className="fas fa-file-alt attachment-icon" />
                            <span>{a.filename}</span>
                        </div>
                    </div>
                </a>
            );
        } else {
            return (
                <FileDownloadLink key={a.key} path={a.key} filename={a.filename}>
                    <div className="email-attachment">
                        <div className="list-centered-icon">
                            <i className="fas fa-file-alt attachment-icon" />
                            <span>{a.filename}</span>
                        </div>
                    </div>
                </FileDownloadLink>
            );
        }
    });

    return (
        <div className="email-details-container">
            <div className="email-details">
                <div className="subject">
                    <div>
                        <div className="account">
                            {accountInitials}
                            {fromCrossSubmitIcon}
                        </div>
                        <div className="text">{subject}</div>
                    </div>
                    <div className="actions">{actions}</div>
                </div>
                <div className={`main`}>
                    <div className="header">
                        <CommunicationHeaders
                            to={headers.to}
                            bcc={headers.bcc}
                            cc={headers.cc}
                            from={accountAddress}
                            jobIds={jobId ? [jobId] : []}
                            account={accountAddress}
                            subject={subject}
                            date={scheduledAt}
                        />
                        {date}
                    </div>
                    <div className="body" ref={emailBodyRef} />
                    <div className="email-attachments">{attachmentsComponent}</div>
                    <div className="close-button">
                        <IconButton>
                            <ExpandLess onClick={handleClose} />
                        </IconButton>
                    </div>
                </div>
            </div>
        </div>
    );
};
