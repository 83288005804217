import * as Mousetrap from 'mousetrap';
import * as React from 'react';

interface ProfileNavButtonsProps {
    count: number;
    currentIndex: number;
    onNavigate: (index: number) => void;
}

export function ProfileNavButtons(props: ProfileNavButtonsProps) {
    const { count, currentIndex, onNavigate } = props;

    const handleNavigate = (index: number) => () => {
        onNavigate(index);
    };

    const handleKeyboardShortCut = (dir: 'left' | 'right') => () => {
        if (dir === 'left' && currentIndex > 0) {
            onNavigate(currentIndex - 1);
        } else if (dir === 'right' && currentIndex !== -1 && currentIndex < count - 1) {
            onNavigate(currentIndex + 1);
        }
    };

    React.useEffect(() => {
        Mousetrap.reset();
        Mousetrap.bind('left', handleKeyboardShortCut('left'));
        Mousetrap.bind('right', handleKeyboardShortCut('right'));
        return () => {
            Mousetrap.unbind('left');
            Mousetrap.unbind('right');
        };
    }, [currentIndex]);

    let prevLink = null;
    let nextLink = null;
    if (currentIndex !== -1) {
        if (currentIndex > 0) {
            prevLink = (
                <div className="candidate-nav previous-button" onClick={handleNavigate(currentIndex - 1)}>
                    <i className="material-icons">navigate_before</i>
                </div>
            );
        }
        if (currentIndex < count - 1) {
            nextLink = (
                <div className="candidate-nav next-button" onClick={handleNavigate(currentIndex + 1)}>
                    <i className="material-icons">navigate_next</i>
                </div>
            );
        }
    }
    return (
        <div className="profile-nav-buttons">
            {prevLink}
            {nextLink}
        </div>
    );
}
