import { Map, Set } from 'immutable';
import * as _ from 'lodash';
import { Paper, Toggle } from 'material-ui';
import * as React from 'react';
import { connect } from 'react-redux';

import { fetchUserActions } from '../actions';
import { Spinner } from '../core-ui/spinner';
import { UserAction as UserActionSFC } from '../sfc/user-action';
import { State, UserAction as UserActionData } from '../state';

interface OwnProps {
    target: string;
    context: { [field: string]: string };
}

interface ConnectedProps {
    userActions: Map<string, Set<UserActionData>>;
}

interface ConnectedDispatch {
    fetchUserActions: (target: string) => void;
}

interface UserActionState {
    currentJobOnly: boolean;
}

type UserActionsComponentProps = OwnProps & ConnectedDispatch & ConnectedProps;

class UserActionsComponent extends React.Component<UserActionsComponentProps, UserActionState> {
    constructor(props: UserActionsComponentProps) {
        super(props);
        this.ensureDataExists(props);
        this.state = { currentJobOnly: true };
    }

    ensureDataExists(props: UserActionsComponentProps) {
        const { userActions, target } = props;
        if (!userActions.get(target)) {
            this.props.fetchUserActions(target);
        }
    }

    componentDidUpdate() {
        this.ensureDataExists(this.props);
    }

    handleJobFilterToggle = () => {
        this.setState({ currentJobOnly: !this.state.currentJobOnly });
    };

    render() {
        const { userActions, target, context } = this.props;
        const { currentJobOnly } = this.state;

        if (userActions.get(target)?.size === 0) return null;

        const actions = userActions.get(target) ? (
            userActions
                .get(target)
                .filter(
                    (action) =>
                        !currentJobOnly || _.reduce(context, (result, v, k) => result && action.context[k] === v, true)
                )
                .valueSeq()
                .map((action) => <UserActionSFC action={action} key={action.id} />)
        ) : (
            <Spinner />
        );
        const label = currentJobOnly ? <span>Current Job</span> : <span>All</span>;
        const toggle = context.jobId ? (
            <div className="action-toggle">
                <Toggle onToggle={this.handleJobFilterToggle} label={label as any} toggled={currentJobOnly} />
            </div>
        ) : null;
        return (
            <div>
                <Paper className="flat-card">
                    {toggle}
                    {actions}
                </Paper>
            </div>
        );
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    userActions: state.userActions
});
const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    fetchUserActions
};
export const UserActions = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(UserActionsComponent);
