import { ApolloProvider } from '@apollo/client';
import * as React from 'react';
import DocumentTitle from 'react-document-title';

import { Header } from '../components/header';
import { AddAccount } from '../containers/add-account';
import { EmailAccountsTable } from '../containers/email-accounts-table';
import { client } from '../graphql/apollo-client';

export const EmailAccountsDashboardPage = () => (
    <DocumentTitle title="Email Accounts">
        <div id="container">
            <Header
                title="Email Accounts"
                actions={[<AddAccount buttonStyle={{ color: 'white' }} key="add-account" />]}
            />
            <div id="content" className="flex-fill">
                <ApolloProvider client={client('email_account_admin')}>
                    <EmailAccountsTable />
                </ApolloProvider>
            </div>
        </div>
    </DocumentTitle>
);
