import { useQuery } from '@apollo/client';
import _ from 'lodash';
import React from 'react';

import { CBCompanyStatuses, CRUNCHBASE_COMPANY_STATUSES } from '../../graphql/queries/crunchbase';
import { MultiSelectForm } from './multi-select-form';

export const CompanyStatusForm: React.FC<{
    readonly: boolean;
    filters: string[];
    onChange: (val: string[]) => void;
}> = ({ readonly, filters, onChange }) => {
    const { data: optionsData } = useQuery<{ company: CBCompanyStatuses[] }>(CRUNCHBASE_COMPANY_STATUSES);

    const options = optionsData?.company.map((r) => r.status) ?? [];

    const renderOption = (option: string) => _.startCase(option);

    return (
        <MultiSelectForm
            readonly={readonly}
            filters={filters}
            label="Company Status"
            options={options}
            onChange={onChange}
            renderOption={renderOption}
        />
    );
};
