import { css } from '@emotion/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@material-ui/core';
import React from 'react';
import { ActivityMetricType } from 'shared/models/activity-metric';

import { Duration } from '../../common/duration';
import { fieldLabels, funnelMetricFields, metricsByPeriod } from '../../common/metrics';

const styles = css`
    display: inline-flex;
    align-items: center;

    .percent {
        border-radius: 4px;
        background: #f6f6f6;
        font-size: 10px;
        padding: 2px 8px 1px;
        margin-left: 6px;
    }
`;

export const FunnelMetricsTable: React.FC<{
    metrics: Array<{ kind: ActivityMetricType; count: number; date: string }>;
    duration: Duration;
}> = ({ metrics, duration }) => {
    const dataByPeriod = metricsByPeriod(metrics, duration.start, duration.end);

    const headers = funnelMetricFields.map((c) => <TableCell key={c}>{fieldLabels.get(c)}</TableCell>);

    const rows = dataByPeriod?.map((data) => {
        const columns = funnelMetricFields.map((field) => {
            const value = data.metrics[field] ?? 0;
            const index = funnelMetricFields.indexOf(field);
            const baselineField = funnelMetricFields[index - 1];
            const baseline = data.metrics[baselineField] ?? 0;
            const percent = Math.round((value / baseline) * 100);
            const tip =
                value && baseline ? (
                    <Tooltip title={`${percent}% of ${fieldLabels.get(baselineField)}`}>
                        <span className="percent">{percent}%</span>
                    </Tooltip>
                ) : (
                    ''
                );
            return (
                <TableCell key={field}>
                    <span css={styles}>
                        {value} {tip}
                    </span>
                </TableCell>
            );
        });
        return (
            <TableRow key={data.period.start}>
                <TableCell>{data.period.label}</TableCell>
                {columns}
            </TableRow>
        );
    });

    return (
        <div className="chart-container">
            <TableContainer>
                <Table stickyHeader={true}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Period</TableCell>
                            {headers}
                        </TableRow>
                    </TableHead>
                    <TableBody>{rows}</TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
