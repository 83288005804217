import { useQuery } from '@apollo/client';
import { useTheme } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';

import { internalClientIds, JobType } from 'shared/common/job-constants';
import { currencyFormat } from 'shared/common/string-format-utils';

import { createDuration, defaultPeriods, Duration } from '../common/duration';
import { PageDialogLink } from '../common/page-dialog-link';
import { SearchTextField } from '../common/search-text-field';
import { JobEdit } from '../containers/job-edit';
import { PlacementFee, PLACEMENTS } from '../graphql/queries/billing';
import { useSlides } from '../hooks/use-candidate-slides';
import { useLocalStorage } from '../hooks/use-local-storage';
import { billingPageRoutes } from './billing-page-header';
import { ClientInvoicesFees } from './client-invoices-fees';
import { ClientFilterType, ClientsTypeSelector } from './clients-type-selector';
import { CellValue, DataPanel, DataPanelColumn, dataPanelContainerStyles, selectorStyles } from './data-panel';
import { DurationSelector } from './duration-selector';
import { JobsTypeSelector, jobTypesForFilter } from './jobs-type-selector';
import { NavigationSelect } from './navigation-select';

type Column = 'client' | 'job' | 'candidate' | 'rating' | 'assignee' | 'accountManager' | 'placementDate' | 'billing';

const columns: Array<DataPanelColumn<Column>> = [
    { id: 'placementDate', label: 'Placement Date' },
    { id: 'client', label: 'Client' },
    { id: 'job', label: 'Job' },
    { id: 'candidate', label: 'Candidate' },
    { id: 'rating', label: 'Rating' },
    { id: 'assignee', label: 'Assignee' },
    { id: 'accountManager', label: 'Account Manager' },
    { id: 'billing', label: 'Billing' }
];

export const Placements = (): JSX.Element => {
    const theme = useTheme();
    const { setList } = useSlides();
    const [searchText, setSearchText] = React.useState('');
    const [duration, setDuration] = React.useState<Duration>(createDuration({ period: 'month', offset: 0 }));
    const [jobTypes, setJobTypes] = useLocalStorage<JobType[]>('placements-job-types', jobTypesForFilter.get('all'));
    const [clientTypes, setClientTypes] = useLocalStorage<ClientFilterType>(
        'placements-client-types',
        'external-clients'
    );

    const { data } = useQuery<
        { fees: PlacementFee[] },
        { jobType: string[]; startTime: number; endTime: number; excludeClientIds: string[] }
    >(PLACEMENTS, {
        variables: {
            endTime: duration.end,
            excludeClientIds: clientTypes === 'external-clients' ? internalClientIds : [],
            jobType: jobTypes,
            startTime: duration.start
        }
    });

    const handleSelectPerson = (personId: string, jobId: string) => () => {
        const list = data?.fees.map((f) => ({
            jobId: f.jobId,
            personId: f.personId
        }));
        const selected = { jobId, personId };
        setList(list, selected);
    };

    const getCellValue = (column: Column) => (fee: PlacementFee) => {
        switch (column) {
            case 'client':
                return fee.client.name;
            case 'job':
                return fee.job.title;
            case 'candidate':
                return fee.person.name;
            case 'rating':
                return fee.candidate.scores.filter((s) => !s.searchProfilesScoreId)?.[0]?.score;
            case 'assignee':
                return fee.recruiter.name;
            case 'accountManager':
                return fee.accountManager.name;
            case 'placementDate':
                return fee.incurredAt;
            case 'billing':
                return fee.amount;
        }
    };

    const formatCellValue = {
        billing: (value: CellValue) => currencyFormat(value as number),
        candidate: (value: CellValue, fee: PlacementFee) => (
            <span className="clickable-cell" onClick={handleSelectPerson(fee.personId, fee.jobId)}>
                {value}
            </span>
        ),
        client: (value: CellValue, fee: PlacementFee) => (
            <PageDialogLink
                Component={ClientInvoicesFees}
                componentProps={{ clientId: fee.client.id, activeTab: 'fees' }}
                url={`/client/${fee.client.id}/fees`}
            >
                <a href={`/client/${fee.client.id}/fees`}>{value}</a>
            </PageDialogLink>
        ),
        job: (value: CellValue, fee: PlacementFee) => (
            <PageDialogLink Component={JobEdit} componentProps={{ id: fee.jobId }} url={`/job/${fee.jobId}/edit`}>
                <a href={`/job/${fee.jobId}/edit`}>{value}</a>
            </PageDialogLink>
        ),
        placementDate: (value: CellValue) => moment(value as number).format('MMM DD, YYYY'),
        rating: (value: CellValue) => (value ? (value as number).toFixed(1) : '-')
    };

    const renderSummary = React.useCallback(
        (fees: PlacementFee[]) => {
            const total = fees.reduce((acc, fee) => acc + fee.amount, 0);
            return data?.fees ? <div>Total: {currencyFormat(total)}</div> : null;
        },
        [data?.fees]
    );

    const rowFilter = React.useCallback(
        (fee: PlacementFee) => {
            if (!searchText) return true;

            const searchRegex = new RegExp(searchText, 'i');
            return !!(
                fee.recruiter.name.match(searchRegex) ||
                fee.client.name.match(searchRegex) ||
                fee.job.title.match(searchRegex) ||
                fee.person.name?.match(searchRegex) ||
                fee.accountManager?.name?.match(searchRegex)
            );
        },
        [searchText]
    );

    return (
        <div css={dataPanelContainerStyles(theme)}>
            <div className="selectors">
                <div css={selectorStyles}>
                    <SearchTextField value={searchText} onValueChange={setSearchText} variant="outlined" />
                    <NavigationSelect routes={billingPageRoutes} variant="outlined" />
                    <DurationSelector selected={duration} onSelect={setDuration} periods={defaultPeriods} />
                    <JobsTypeSelector selected={jobTypes} onChange={setJobTypes} />
                    <ClientsTypeSelector selected={clientTypes} onChange={setClientTypes} />
                </div>
            </div>
            <div className="data-panel">
                <DataPanel
                    columns={columns}
                    data={data?.fees}
                    getCellValue={getCellValue}
                    formatCellValue={formatCellValue}
                    sortableColumns={['placementDate', 'billing', 'assignee', 'accountManager', 'client', 'rating']}
                    filterableColumns={['client', 'assignee', 'accountManager', 'rating']}
                    rowFilter={rowFilter}
                    initialSortColumn="placementDate"
                    initialSortDirection="desc"
                    renderSummary={renderSummary}
                />
            </div>
        </div>
    );
};
