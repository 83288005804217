import { gql } from '@apollo/client';

export const CREATE_NOTES = gql`
    mutation CreateNotes($notes: [notes_insert_input!]!) {
        insert_notes(objects: $notes) {
            returning {
                id
                createdBy
                content
            }
        }
    }
`;

export const ENTITY_LATEST_NOTE_MODIFIED_AT = gql`
    subscription EntityLatestNote($notable: String!) {
        notes(where: { notable: { _eq: $notable } }, limit: 1, order_by: { modifiedAt: desc }) {
            modifiedAt
        }
    }
`;
