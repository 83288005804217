import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from '@material-ui/core';
import { Map } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';

import { UserData } from 'shared/models/user';

import { handleAddEmailAccount } from '../actions';
import { UserSelector } from '../components/user-selector';
import { RequestErrors, State } from '../state';

interface OwnProps {
    userId?: string;
    jobId?: string;
    buttonStyle?: React.CSSProperties;
}

interface ConnectedProps {
    pendingRequests: Map<string, RequestErrors>;
}

interface ConnectedDispatch {
    handleAddEmailAccount: (userId: string, jobId: string, capacity: number) => void;
}

type AddAccountComponentProps = OwnProps & ConnectedProps & ConnectedDispatch;

const AddAccountComponent: React.FC<AddAccountComponentProps> = (props) => {
    const capacityOptions = [75, 150, 225, 300]; // tslint:disable-line:no-magic-numbers
    const [userId, setUserId] = React.useState(props.userId);
    const [userSelectorOpen, setUserSelectorOpen] = React.useState(false);
    const [capacity, setCapacity] = React.useState(capacityOptions[0]);

    const handleChangeCapacity = (event: React.ChangeEvent<{ value: number }>) => {
        setCapacity(event.target.value);
    };

    const handleAddAccount = () => {
        setUserSelectorOpen(false);
        props.handleAddEmailAccount(userId, props.jobId, capacity);
    };

    const handleSelectUser = (id: string | string[]) => {
        setUserId(id as string);
    };

    const userFilter = (u: UserData) => u.status === 'active';

    const handleToggleUserSelector = () => setUserSelectorOpen(!userSelectorOpen);

    const capacityOpts = capacityOptions.map((o) => (
        <MenuItem value={o} key={o}>
            {o}
        </MenuItem>
    ));

    const disabledStyles: React.CSSProperties = props.pendingRequests.has(`create-email-account`)
        ? { opacity: 0.4 }
        : {};

    return (
        <>
            <Button
                onClick={handleToggleUserSelector}
                disabled={props.pendingRequests.has(`create-email-account`)}
                style={{ ...props.buttonStyle, ...disabledStyles }}
            >
                Add Accounts
            </Button>
            <Dialog open={userSelectorOpen} onClose={handleToggleUserSelector} maxWidth="sm" fullWidth={true}>
                <DialogTitle>
                    <Typography variant="h4">Add Outreach Accounts</Typography>
                </DialogTitle>
                <DialogContent style={{ overflow: 'hidden' }}>
                    <UserSelector
                        onSelect={handleSelectUser}
                        value={userId}
                        label="User"
                        fullWidth={true}
                        userFilter={userFilter}
                    />
                    <FormControl fullWidth={true} style={{ margin: '20px 0' }}>
                        <InputLabel id="capacity-select-label">Capacity to Add</InputLabel>
                        <Select value={capacity} onChange={handleChangeCapacity} labelId="capacity-select-label">
                            {capacityOpts}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleToggleUserSelector}>Cancel</Button>
                    <Button onClick={handleAddAccount} disabled={!userId}>
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state: State): ConnectedProps => ({
    pendingRequests: state.pendingRequests
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    handleAddEmailAccount
};

export const AddAccount = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(AddAccountComponent);
