import { useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Theme,
    Typography,
    useTheme
} from '@material-ui/core';
import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';

import { CrunchbaseCompanyFilters } from 'shared/common/crunchbase-filters';

import { CRUNCHBASE_COMPANIES_COUNT, CrunchbaseCompanyFilterQueryParams } from '../../graphql/queries/crunchbase';
import { useModal } from '../../hooks/use-modal';
import { usePrevious } from '../../hooks/use-previous';
import { CBFiltersForm } from './cb-filters-form';
import { CBPreview } from './cb-preview';
import { getGraphRequestParams } from './common';

const styles = (theme: Theme) => css`
    div::-webkit-scrollbar {
        width: 5px;
    }

    div::-webkit-scrollbar-thumb {
        border-radius: 2px;
    }

    .MuiDialogTitle-root {
        padding: 16px 29px;
    }

    .MuiDialogContent-root {
        overflow: hidden;
        display: flex;
    }

    .MuiDialogActions-root {
        justify-content: space-between;
        padding: 8px 12px 12px 28px;

        .preview-count {
            font-size: 14px;
            font-weight: 500;
        }
    }

    .dialog-content {
        flex: 1 1 auto;
        display: flex;
        overflow: hidden;

        .cb-form {
            padding: 0 5px;
            overflow-y: auto;
            overflow-x: hidden;
            flex: 1 1 auto;
        }

        .cb-preview {
            flex: 1 1 auto;
            border-left: thin solid ${theme.palette.divider};
            padding-left: 30px;
            margin-left: 30px;
            overflow-y: auto;
            overflow-x: hidden;

            &.stale {
                opacity: 0.5;
            }
        }
    }

    .MuiDialog-paperWidthLg {
        .dialog-content {
            .cb-form {
                width: 50%;
            }

            .cb-preview {
                width: 50%;
            }
        }
    }
`;

interface CBFiltersDialogProps {
    open: boolean;
    readonly: boolean;
    initialFilters: CrunchbaseCompanyFilters;
    onClose: () => void;
    onConfirm: (filters: CrunchbaseCompanyFilters) => void;
}

export const CBFiltersDialog: React.FC<CBFiltersDialogProps> = ({
    open,
    readonly,
    onClose,
    initialFilters,
    onConfirm
}) => {
    const theme = useTheme();
    const [filters, setFilters] = useState<CrunchbaseCompanyFilters>(initialFilters);
    const [previewFilters, setPreviewFilters] = useState<CrunchbaseCompanyFilters>(undefined);
    const { getConfirmation } = useModal();
    const previousFilters = usePrevious(filters);

    const whereClause = getGraphRequestParams(filters ?? {});
    const { data } = useQuery<
        { companiesCount: { aggregate: { count: string } } },
        { where: CrunchbaseCompanyFilterQueryParams }
    >(CRUNCHBASE_COMPANIES_COUNT, { variables: { where: whereClause } });

    useEffect(() => {
        if (initialFilters !== undefined && previousFilters === undefined) {
            setFilters(initialFilters);
        }
    }, [initialFilters]);

    const { useNameMatch, ...rest } = filters ?? {};
    const { useNameMatch: initialUseNameMatch, ...initialRest } = initialFilters ?? {};
    const { useNameMatch: previewUseNameMatch, ...previewRest } = previewFilters ?? {};
    const filtersEdited =
        !isEqual(rest, initialRest) || (useNameMatch !== initialUseNameMatch && Object.keys(rest).length > 0);
    const previewFiltersStale = !isEqual(rest, previewRest);

    const handleUpdateCBFilters = (updated: CrunchbaseCompanyFilters) => {
        if (
            updated.size === undefined &&
            updated.funding === undefined &&
            updated.foundingYear === undefined &&
            updated.fundingRounds === undefined &&
            updated.status === undefined &&
            updated.industries === undefined &&
            updated.industryGroups === undefined &&
            updated.countries === undefined &&
            updated.regions === undefined &&
            updated.cities === undefined &&
            updated.lastFundingRound === undefined &&
            updated.investors === undefined &&
            updated.useNameMatch === undefined
        ) {
            setFilters(undefined);
        } else {
            setFilters(updated);
        }
    };

    const handleCancel = () => {
        setPreviewFilters(undefined);
        setFilters(initialFilters);
        onClose();
    };

    const handleCancelWithConfirmation = () => {
        if (!filtersEdited) {
            handleCancel();
        } else {
            getConfirmation(handleCancel, 'Are you sure? You will lose any unsaved changes.', 'Confirm');
        }
    };

    const handlePreview = () => {
        setPreviewFilters(filters);
    };

    const handleConfirm = () => {
        onConfirm(filters);
        onClose();
    };

    const totalCount = parseInt(data?.companiesCount?.aggregate?.count, 10);

    const preview = previewFilters ? (
        <div className={`cb-preview ${previewFiltersStale ? 'stale' : ''}`}>
            <CBPreview filters={previewFilters} totalCount={totalCount} />
        </div>
    ) : null;

    const estimatedCount = data ? `${totalCount?.toLocaleString()} Companies` : 'Estimating Count...';

    return (
        <Dialog open={open} maxWidth={previewFilters ? 'lg' : 'md'} fullWidth={true} css={styles(theme)}>
            <DialogTitle>
                <Typography variant="h4" component="span">
                    Crunchbase Filters
                </Typography>
            </DialogTitle>
            <DialogContent>
                <div className="dialog-content">
                    <div className="cb-form">
                        <CBFiltersForm readonly={readonly} filters={filters} onChange={handleUpdateCBFilters} />
                    </div>
                    {preview}
                </div>
            </DialogContent>
            <DialogActions>
                <div className="preview-count">{estimatedCount}</div>
                <div>
                    <Button onClick={handleCancelWithConfirmation}>Cancel</Button>
                    <Button onClick={handlePreview} disabled={!filters || !previewFiltersStale || totalCount === 0}>
                        {previewFilters ? 'Update Preview' : 'Preview'}
                    </Button>
                    <Button onClick={handleConfirm} disabled={!filtersEdited}>
                        Use
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};
