import { gql } from '@apollo/client';

export interface Team {
    id: string;
    name: string;
    leadUserId: string;
    users: Array<{
        user: {
            id: string;
            name: string;
        };
    }>;
}

export const TEAM = gql`
    query Team($id: String!) {
        team: teams_by_pk(id: $id) {
            id
            name
            leadUserId
            users: team_users(where: { user: { status: { _eq: "active" } } }, order_by: [{ user: { username: asc } }]) {
                user {
                    id
                    name(path: "full")
                }
            }
        }
    }
`;

export const ALL_TEAMS = gql`
    query AllTeams {
        teams(where: { restricted: { _eq: false } }, order_by: { name: asc }) {
            id
            name
        }
    }
`;
