import { css } from '@emotion/core';
import { Button, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { Add, FilterList, Info, MemoryOutlined, Sort, SwapVertRounded } from '@material-ui/icons';
import { startCase } from 'lodash';
import React from 'react';

import { ResultSort, resultSortToString } from 'shared/models/search';

import { hasFeature, USER_FEATURE_SCORE_PROFILES_LLM } from 'shared/models/user';
import { fullDate } from '../common/timestamp';
import { SearchScore } from '../graphql/queries/profile-score';
import { useModal } from '../hooks/use-modal';
import { useSession } from '../hooks/use-session';
import { SearchStatus } from '../state';
import { SearchProfilesScoreForm } from './search-profile-score-form';
import { useSearch } from './use-search';

const menuStyles = css`
    .MuiMenuItem-root {
        max-width: 480px;

        .MuiIconButton-root {
            margin-left: auto;
        }

        .MuiTypography-root {
            margin-right: 10px;
        }
    }
`;

export const SearchResultsHeader: React.FC = () => {
    const [sortMenuAnchor, setSortMenuAnchor] = React.useState<null | HTMLElement>(null);
    const [filterMenuAnchor, setFilterMenuAnchor] = React.useState<null | HTMLElement>(null);
    const [scoringDialogOpen, setScoringDialogOpen] = React.useState(false);
    const [viewingScore, setViewingScore] = React.useState<SearchScore | null>(null);

    const {
        data: search,
        searchResults,
        getResultsToScoreCount,
        resultsType,
        onFieldChange,
        profileScores,
        onChange,
        maxSearchResultsToScoreUsingLLM
    } = useSearch();
    const { setAlert, showLoading, hideLoading } = useModal();
    const { user } = useSession();
    const results = searchResults?.results ?? [];

    const handleSortMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setSortMenuAnchor(event.currentTarget);
    };

    const handleSortChange = (newSortOrder: ResultSort) => () => {
        onChange({ ...search, resultsSort: newSortOrder, searchProfilesScoreId: null });
        setSortMenuAnchor(null);
    };

    const handleUpdateSortScore = (scoreId: string) => () => {
        onFieldChange('searchProfilesScoreId')(scoreId);
        setSortMenuAnchor(null);
    };

    const handleCloseSortMenu = () => {
        setSortMenuAnchor(null);
    };

    const handleFilterMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setFilterMenuAnchor(event.currentTarget);
    };

    const handleProfileAgeChange = (newProfileAge: number) => () => {
        onFieldChange('maxProfileAgeInDays')(newProfileAge);
        setFilterMenuAnchor(null);
    };

    const handleCloseFilterMenu = () => {
        setFilterMenuAnchor(null);
    };

    const handleAddAIScoring = () => {
        setSortMenuAnchor(null);
        const enabled = hasFeature(user, USER_FEATURE_SCORE_PROFILES_LLM);
        if (!enabled) {
            setAlert('Feature not enabled', 'AI Scoring is not enabled');
        } else {
            showLoading();
            getResultsToScoreCount().then((resultsToScoreCount) => {
                hideLoading();
                if (resultsToScoreCount >= maxSearchResultsToScoreUsingLLM) {
                    setAlert(
                        'Too many results',
                        `AI Scoring can only be used on searches with at most ${maxSearchResultsToScoreUsingLLM} results in Ready + In Cooldown status. This search has ${resultsToScoreCount} results.`
                    );
                } else {
                    setScoringDialogOpen(true);
                }
            });
        }
    };

    const handleViewSortScore = (scoreId: string) => (e: React.MouseEvent) => {
        e.stopPropagation();
        setSortMenuAnchor(null);
        setScoringDialogOpen(true);
        setViewingScore(profileScores.find((score) => score.id === scoreId) ?? null);
    };

    const handleCloseAddAIScoringDialog = () => {
        setScoringDialogOpen(false);
        setViewingScore(null);
    };

    const handleMinScoreChange = (minScore: number) => () => {
        onFieldChange('minSearchProfileScore')(minScore);
        setFilterMenuAnchor(null);
    };

    const label = search.status === SearchStatus.Initial ? '' : startCase(resultsType);

    const resultsSort = search?.resultsSort || 'similarityScore';

    const sorts: ResultSort[] = ['similarityScore', 'random', 'profileAge'];
    const standardSortMenuItems = sorts.map((sort) => (
        <MenuItem key={sort} onClick={handleSortChange(sort)}>
            <ListItemIcon>
                <SwapVertRounded fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit" noWrap={true}>
                {resultSortToString(sort)}
            </Typography>
        </MenuItem>
    ));
    const scoreSortMenuItems = profileScores.map((score) => (
        <MenuItem key={score.id} onClick={handleUpdateSortScore(score.id)}>
            <ListItemIcon>
                <MemoryOutlined fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit" noWrap={true}>
                {score.title} - {fullDate(score.createdAt)}
            </Typography>
            <IconButton size="small" onClick={handleViewSortScore(score.id)}>
                <Info fontSize="small" />
            </IconButton>
        </MenuItem>
    ));
    const scoreSortDivider = scoreSortMenuItems.length > 0 ? <Divider /> : null;

    const addScoringOption = [
        SearchStatus.Active,
        SearchStatus.Caching,
        SearchStatus.ResultsCached,
        SearchStatus.UpdatingProfiles
    ].includes(search.status) ? (
        <MenuItem onClick={handleAddAIScoring}>
            <ListItemIcon>
                <Add fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit" noWrap={true}>
                Add New AI Scoring
            </Typography>
        </MenuItem>
    ) : null;
    const addScoreDivider = addScoringOption ? <Divider /> : null;

    const sortMenu = (
        <Menu
            id="simple-menu"
            anchorEl={sortMenuAnchor}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            open={Boolean(sortMenuAnchor)}
            onClose={handleCloseSortMenu}
            css={menuStyles}
        >
            {standardSortMenuItems}
            {scoreSortDivider}
            {scoreSortMenuItems}
            {addScoreDivider}
            {addScoringOption}
        </Menu>
    );

    if (!searchResults?.results || searchResults?.resultsType !== resultsType) {
        return null;
    }

    let filterMenu;
    let filterButton;

    if (search.resultsSort === 'profileAge') {
        filterButton = (
            <Tooltip title="Max Profile Age">
                <Button onClick={handleFilterMenuClick} startIcon={<FilterList />}>
                    {search.maxProfileAgeInDays === -1 ? 'All' : `${search.maxProfileAgeInDays} days`}
                </Button>
            </Tooltip>
        );
        // tslint:disable-next-line:no-magic-numbers
        const profileAgeDaysOpts = [-1, 15, 30, 60, 90, 180, 360, 540, 720];
        const profileAgeDaysMenuItems = profileAgeDaysOpts.map((days) => (
            <MenuItem key={days} onClick={handleProfileAgeChange(days)}>
                {days === -1 ? 'All' : `${days} days`}
            </MenuItem>
        ));
        filterMenu = (
            <Menu
                id="simple-menu"
                anchorEl={filterMenuAnchor}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                open={Boolean(filterMenuAnchor)}
                onClose={handleCloseFilterMenu}
            >
                {profileAgeDaysMenuItems}
            </Menu>
        );
    }

    if (search.searchProfilesScoreId) {
        filterButton = (
            <Tooltip title="Min Search Profile Score">
                <Button onClick={handleFilterMenuClick} startIcon={<FilterList />}>
                    {search.minSearchProfileScore.toFixed(1)}
                </Button>
            </Tooltip>
        );
        const maxScore = 5;
        const step = 0.5;
        const scoreOpts = Array.from({ length: maxScore / step + 1 }, (_, i) => i * step).map((score) => (
            <MenuItem key={score} onClick={handleMinScoreChange(score)}>
                {score.toFixed(1)}
            </MenuItem>
        ));
        filterMenu = (
            <Menu
                id="simple-menu"
                anchorEl={filterMenuAnchor}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                open={Boolean(filterMenuAnchor)}
                onClose={handleCloseFilterMenu}
            >
                {scoreOpts}
            </Menu>
        );
    }

    const scoringDialog = scoringDialogOpen ? (
        <SearchProfilesScoreForm data={viewingScore} open={scoringDialogOpen} onClose={handleCloseAddAIScoringDialog} />
    ) : null;

    const buttonLabel =
        profileScores?.find((score) => score.id === search?.searchProfilesScoreId)?.title ??
        resultSortToString(resultsSort);

    return (
        <div className="search-results-header">
            <div className="search-stats">
                <span>
                    {Number(results.length).toLocaleString()} {label} Candidates
                </span>
            </div>
            <div className="search-filter-and-sort">
                <div className="search-filter">
                    {filterButton}
                    {filterMenu}
                </div>
                <div className="search-sort">
                    <div className="dropdown">
                        <Tooltip title="Sort Order">
                            <Button onClick={handleSortMenuClick} startIcon={<Sort />}>
                                <Typography variant="inherit" noWrap={true}>
                                    {buttonLabel}
                                </Typography>
                            </Button>
                        </Tooltip>
                        {sortMenu}
                    </div>
                </div>
            </div>
            {scoringDialog}
        </div>
    );
};
