import { css } from '@emotion/core';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@material-ui/core';
import React from 'react';

import { Editor } from 'react-ce';
import { ModelParameters } from 'shared/common/llm';
import { responseReceivedStage } from 'shared/models/job-stages';
import { hasFeature, hasRole, USER_FEATURE_RR_EMAIL_LLM_RESPONSE_UI } from 'shared/models/user';

import { getAIEmailResponse } from '../api';
import { useModal } from '../hooks/use-modal';
import { usePromptInfo } from '../hooks/use-prompt-info';
import { useReduxState } from '../hooks/use-redux';
import { useSession } from '../hooks/use-session';

const styles = css`
    .field {
        margin-bottom: 20px;
    }

    .text-editor-container {
        border: thin solid #c8c8c8;
        border-radius: 6px;
        padding: 12px;

        label.text-editor-label {
            top: -7px;
            background: white;
            padding: 0 2px;
        }

        .text-editor-content.disabled {
            opacity: 1;
            cursor: inherit;
        }
    }
`;

export const AIEmailResponseDebug: React.FC<{
    personId: string;
    jobId: string;
    onReply: (initialBody: string) => void;
}> = ({ personId, jobId, onReply }) => {
    const [fetching, setFetching] = React.useState(false);
    const [viewing, setViewing] = React.useState(false);
    const [fetchError, setFetchError] = React.useState(false);
    const [response, setResponse] = React.useState<{
        completionArgs: ModelParameters & {
            messages: Array<{ role: 'system' | 'user'; content: string }>;
        };
        result: {
            analysis: string[];
            htmlEmailContent: string;
            responseCategory: string;
        };
    } | null>(null);
    const { user, userPermissions } = useSession();
    const candidates = useReduxState((state) => state.candidates);
    const { showLoading, hideLoading, setAlert } = useModal();
    const { showPromptInfo } = usePromptInfo();

    const candidate = candidates.get(jobId)?.get(personId);
    const fetchErrorDescription = 'Failed to fetch AI email response';

    React.useEffect(() => {
        if (
            !response &&
            hasFeature(user, USER_FEATURE_RR_EMAIL_LLM_RESPONSE_UI) &&
            candidate?.stage === responseReceivedStage &&
            candidate.assignee === user.id
        ) {
            fetchAIEmailResponse();
        }
    }, [candidate, response, personId, jobId]);

    React.useEffect(() => {
        if (response || fetchError) {
            hideLoading();
        }
    }, [response, fetchError]);

    React.useEffect(() => {
        setResponse(null);
    }, [personId, jobId]);

    const fetchAIEmailResponse = async () => {
        if (!response && !fetching) {
            setFetching(true);
            try {
                const result = await getAIEmailResponse(personId, jobId);
                setResponse(result.data);
            } catch (e) {
                setFetchError(true);
            } finally {
                setFetching(false);
            }
        }
    };

    const handleAIEmailResponseClick = async () => {
        setViewing(true);
        if (!response) {
            if (fetchError) {
                setAlert('Error', fetchErrorDescription);
            } else {
                showLoading();
                try {
                    await fetchAIEmailResponse();
                } catch (e) {
                    setAlert('Error', fetchErrorDescription);
                }
            }
        }
    };

    const handleCloseDialog = () => {
        setViewing(false);
    };

    const handleViewPromptsClick = () => {
        const { messages, ...requestParameters } = response.completionArgs;
        const systemPrompt = messages.find((m) => m.role === 'system')?.content;
        const userPrompt = messages.find((m) => m.role === 'user')?.content;
        showPromptInfo({
            requestParameters,
            systemPrompt,
            userPrompt
        });
    };

    const handleUseReplyClick = () => {
        onReply(response.result.htmlEmailContent);
        setViewing(false);
    };

    if (!hasFeature(user, USER_FEATURE_RR_EMAIL_LLM_RESPONSE_UI) || candidate?.stage !== responseReceivedStage) {
        return null;
    }

    const viewPromptsButton = hasRole(userPermissions, 'prompts_editor') ? (
        <Button onClick={handleViewPromptsClick}>View Prompts</Button>
    ) : null;

    const responseDialog =
        response && viewing ? (
            <Dialog open={true} onClose={handleCloseDialog} maxWidth="md">
                <DialogTitle>
                    <Typography variant="h4" component="div">
                        AI Generated Email Response
                    </Typography>
                </DialogTitle>
                <DialogContent css={styles}>
                    <TextField
                        label="Response Category"
                        value={response.result.responseCategory}
                        fullWidth={true}
                        variant="outlined"
                        className="field"
                    />
                    <TextField
                        label="Analysis"
                        value={response.result.analysis.join('\n')}
                        fullWidth={true}
                        multiline={true}
                        variant="outlined"
                        className="field"
                    />
                    <Editor
                        value={response.result.htmlEmailContent}
                        disabled={true}
                        label="Email Content"
                        className="field"
                    />
                </DialogContent>
                <DialogActions>
                    {viewPromptsButton}
                    <Button onClick={handleUseReplyClick}>Use</Button>
                    <Button onClick={handleCloseDialog}>Close</Button>
                </DialogActions>
            </Dialog>
        ) : null;

    return (
        <>
            <Button style={{ marginRight: '20px' }} size="small" onClick={handleAIEmailResponseClick}>
                AI Response
            </Button>
            {responseDialog}
        </>
    );
};
