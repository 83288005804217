import { Map } from 'immutable';
import * as _ from 'lodash';

import {
    Action,
    ArchiveMessage,
    ReceiveAddContactFromMessage,
    ReceiveCandidateUpdate,
    ReceiveCommunicationMatchDataUpdate,
    ReceiveCommunicationMatcher,
    ReceiveContactsUpdate,
    ReceiveCrossAddCandidate,
    ReceiveIgnoreContactFromMessage,
    ReceiveMoveInGmail,
    ReceivePersonCommunications,
    ReceivePersonDetails,
    ReceiveSendSequenceToPerson
} from '../actions';
import { ReceiveSentDisqualificationMail, ReceiveSentMail } from '../email-compose/actions';
import { Communication } from '../state';

const initialState: Map<string, Communication[]> = Map();

export function communications(state = initialState, action: Action): Map<string, Communication[]> {
    switch (action.type) {
        case ReceivePersonDetails:
            return state.set(action.payload.details.person.id, action.payload.details.communications);
        case ReceiveSendSequenceToPerson:
            return state.set(action.payload.personId, action.payload.communications);
        case ReceiveMoveInGmail:
        case ReceiveAddContactFromMessage:
            if (!action.payload.success || !state.get(action.payload.personId)) {
                return state;
            }
            return state.set(
                action.payload.personId,
                state.get(action.payload.personId).map((c) => {
                    return _.find(action.payload.communications, { messageId: c.messageId }) || c;
                })
            );
        case ArchiveMessage: {
            const { personId, account, messageId } = action.payload;
            if (personId) {
                const existing = state.get(personId) || [];
                const archivedMessages = existing.map((c) => {
                    if (c.account === account && c.messageId === messageId) {
                        return Object.assign({}, c, {
                            labels: c.labels.filter((l) => l.toLowerCase() !== 'inbox' && l.toLowerCase() !== 'unread')
                        });
                    } else {
                        return c;
                    }
                });
                return state.set(personId, archivedMessages);
            }
            return state;
        }
        case ReceiveCandidateUpdate: {
            const { candidate, message } = action.payload;
            if (!message) {
                return state;
            } else {
                const { personId, jobId } = candidate;
                const comm: Communication = {
                    ...message,
                    jobIds: [jobId],
                    labels: message.labels || [],
                    matchData: {},
                    personIds: [personId]
                };
                const existing = state.get(personId) || [];
                return state.set(personId, existing.concat([comm]));
            }
        }
        case ReceiveSentMail: {
            const { personId, jobId, message } = action.payload;
            if (!personId || !jobId) {
                return state;
            } else {
                const comm: Communication = {
                    ...message,
                    jobIds: [jobId],
                    labels: message.labels || [],
                    matchData: {},
                    personIds: [personId]
                };
                const existing = state.get(personId) || [];
                return state.set(personId, existing.concat([comm]));
            }
        }
        case ReceiveSentDisqualificationMail: {
            const { personId, communication, jobId } = action.payload;
            if (!communication) {
                return state;
            }
            const comm: Communication = {
                ...communication,
                jobIds: [jobId],
                labels: communication.labels || [],
                matchData: {},
                personIds: [personId]
            };
            return state.set(personId, (state.get(personId) || []).concat([comm]));
        }
        case ReceivePersonCommunications:
            return action.payload.communications ? state.set(action.payload.id, action.payload.communications) : state;
        case ReceiveIgnoreContactFromMessage:
        case ReceiveContactsUpdate:
        case ReceiveCrossAddCandidate:
        case ReceiveCommunicationMatchDataUpdate:
        case ReceiveCommunicationMatcher: {
            let newState = state;
            for (const comm of action.payload.communications) {
                const { personIds, messageId, account } = comm;
                if (personIds) {
                    for (const personId of personIds) {
                        if (newState.has(personId)) {
                            const oldCommunications = newState.get(personId);
                            const newCommunications = oldCommunications
                                .filter((c) => !(c.messageId === messageId && c.account === account))
                                .concat([comm])
                                .sort((c1, c2) => c2.internalDate - c1.internalDate);
                            newState = newState.set(personId, newCommunications);
                        }
                    }
                }
            }
            return newState;
        }
        default:
            return state;
    }
}
