import { OrderedMap } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';

import { fetchJobInfo } from '../actions';
import { Client, Job, List, State } from '../state';

interface OwnProps {
    id: string;
}

interface ConnectedProps {
    jobs: OrderedMap<string, Job>;
    clients: List<Client>;
}

interface ConnectedDispatch {
    fetchJobInfo: (id: string) => void;
}

type JobTitleComponentProps = OwnProps & ConnectedDispatch & ConnectedProps;

class JobTitleComponent extends React.Component<JobTitleComponentProps, undefined> {
    constructor(props: JobTitleComponentProps) {
        super(props);
        this.ensureDataExists(props);
    }

    ensureDataExists(props: JobTitleComponentProps) {
        const { id, jobs, clients } = props;
        if (!jobs || !jobs.get(id) || !clients.list.get(jobs.get(id).clientId)) {
            this.props.fetchJobInfo(id);
        }
    }

    componentDidUpdate() {
        this.ensureDataExists(this.props);
    }

    render() {
        const { id, jobs, clients } = this.props;
        if (jobs && jobs.get(id) && clients.list.get(jobs.get(id).clientId)) {
            const job = jobs.get(id);
            const client = clients.list.get(job.clientId);
            return (
                <div className="list-centered-icon flex-wrap">
                    {client.name}
                    <i className="material-icons separator">chevron_right</i>
                    {job.title}
                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    clients: state.clients,
    jobs: state.jobs
});
const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    fetchJobInfo
};
export const JobTitle = connect<ConnectedProps, ConnectedProps, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(JobTitleComponent);
