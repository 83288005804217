import { orderBy } from 'lodash';

interface DataPoint {
    name: string;
    count: number;
}

function preprocessData(data: DataPoint[], maxCategories = 10): DataPoint[] {
    if (data.length <= maxCategories) {
        return data;
    }
    // Sort data by count in descending order
    const sortedData = orderBy(data, ['count', 'name'], ['desc', 'asc']);
    const included = sortedData.slice(0, maxCategories - 1);
    const rest = sortedData.slice(maxCategories - 1);
    const othersCount = rest.reduce((acc, item) => acc + item.count, 0);
    const sortedIncluded = orderBy(included, (v) => data.findIndex((d) => d.name === v.name), ['asc']);
    return [...sortedIncluded, { name: 'Others', count: othersCount }];
}

export { preprocessData };
