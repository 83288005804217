import { css } from '@emotion/core';
import { Map } from 'immutable';
import React from 'react';
import DocumentTitle from 'react-document-title';

import { ClientPageHeader } from '../components/client-page-header';
import { Notes } from './notes';

const styles = css`
    .client-notes {
        margin: 0 auto;
        flex: 1 1;
        padding: 40px;
        overflow-y: auto;

        .client-notes-section {
            margin: 0 auto;
            min-width: 768px;
            max-width: 1024px;
            margin-bottom: 30px;
            box-shadow: none !important;
        }
    }
`;

interface OwnProps {
    clientId: string;
}

export const ClientNotesContainer: React.FC<OwnProps> = ({ clientId }) => {
    const templateLabels = Map({
        kickoff: 'Start kickoff note',
        weekly_sync: 'Start weekly sync note'
    });

    return (
        <DocumentTitle title="Notes">
            <div id="container">
                <ClientPageHeader clientId={clientId} actions={[]} activeTab="Notes" />
                <div id="content" className="flex-fill" css={styles}>
                    <div className="client-notes">
                        <div className="client-notes-section">
                            <Notes
                                context={{ jobId: undefined }}
                                notable={`clients-${clientId}`}
                                noteDraftKey={`client-note`}
                                startingTemplates={[]}
                                templateLabels={templateLabels}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </DocumentTitle>
    );
};
