import * as React from 'react';

import { SelectField, SelectFieldProps } from '../core-ui/simple-select-field';

export function SearchFieldContext<T>(props: SelectFieldProps<T>) {
    return (
        <SelectField
            {...props}
            menuStyle={{ fontSize: '14px' }}
            className="requirements-context"
            contentStyle={{ fontSize: '14px' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            targetOrigin={{ horizontal: 'right', vertical: 'top' }}
        />
    );
}
