import { Tooltip } from '@material-ui/core';
import { Map } from 'immutable';
import * as _ from 'lodash';
import * as React from 'react';

import { interpolateEmailTemplate } from 'shared/common/interpolate-template';
import { NoteKind, NoteView } from 'shared/models/note';

import { fetchEntityNotes, fetchPersonDetails, getEmailTemplates } from '../actions';
import { Notes } from '../containers/notes';
import { useReduxDispatch, useReduxState } from '../hooks/use-redux';
import { Job, PersonDetails } from '../state';
import { PhoneScreenDialog } from './phone-screen-dialog';

interface PersonNotesProps {
    personDetails: PersonDetails;
    notable: string;
    context: { [field: string]: string };
    job?: Job;
    jobNotesOnly?: boolean;
    notesKind?: NoteKind;
}

export const PersonNotes: React.FC<PersonNotesProps> = (props) => {
    const { context, notable, personDetails, job } = props;
    const [jobNotesOnly, setJobNotesOnly] = React.useState((props.jobNotesOnly && !!job) ?? false);
    const [notesKind, setNotesKind] = React.useState(props.notesKind || 'all');
    const [phoneScreenActive, setPhoneScreenActive] = React.useState(false);
    const dispatch = useReduxDispatch();
    const emailTemplates = useReduxState((state) => state.emailTemplates);
    const pendingRequests = useReduxState((state) => state.pendingRequests);
    const clients = useReduxState((state) => state.clients);

    React.useEffect(() => {
        if (!emailTemplates.get('phonescreen')) {
            dispatch(getEmailTemplates('phonescreen'));
        }
    }, []);

    const noteDraftKey = () => {
        return `note-draft-${context.jobId}-${personDetails.person.id}`;
    };

    const handleUpdateJobSpecific = (updated: boolean) => () => {
        setJobNotesOnly(updated);
    };

    const handleUpdateNoteKind = (updated: NoteKind) => () => {
        setNotesKind(updated);
    };

    const noteFilter = (note: NoteView) => {
        return (
            (!jobNotesOnly ||
                _.reduce(context, (result, v, k) => (result && note.context[k] === v) || !note.context[k], true)) &&
            (notesKind === 'all' ||
                (notesKind === 'phonescreen' && note.context.isPhonescreenTemplate) ||
                (notesKind === 'submission' && note.context.isSubmissionNote))
        );
    };

    const handleRefresh = () => {
        dispatch(fetchEntityNotes(notable));
    };

    const handleStartPhoneScreen = () => {
        setPhoneScreenActive(true);
    };

    const handleClosePhonescreen = () => {
        setPhoneScreenActive(false);
    };

    const handlePostPhonescreenSave = () => {
        dispatch(fetchEntityNotes(notable));
        dispatch(fetchPersonDetails(personDetails.person.id, job?.id));
    };

    const fetching = pendingRequests.has(`notes-${notable}`);

    const jobFilterTabOpts: Array<[boolean, string]> = !job
        ? [[false, 'All Jobs']]
        : [
              [false, 'All Jobs'],
              [true, 'Current Job']
          ];
    const noteKindTabOpts: Array<[NoteKind, string]> = [
        ['all', 'All Notes'],
        ['phonescreen', 'Phonescreen'],
        ['submission', 'Submission']
    ];

    const jobFilterTabs = jobFilterTabOpts.map(([jobSpecific, label]) => (
        <div
            key={jobSpecific.toString()}
            className={`notes-tab ${jobNotesOnly === jobSpecific ? 'active' : ''}`}
            onClick={handleUpdateJobSpecific(jobSpecific)}
        >
            {label}
        </div>
    ));
    const noteKindTabs = noteKindTabOpts.map(([noteKind, label]) => (
        <div
            key={noteKind}
            className={`notes-tab ${notesKind === noteKind ? 'active' : ''}`}
            onClick={handleUpdateNoteKind(noteKind)}
        >
            {label}
        </div>
    ));

    const actions = [{ text: 'Start Phonescreen', handler: handleStartPhoneScreen }];

    const tabs = (
        <div className="notes-tabs">
            <div className="notes-tabs-left">
                <div className="notes-tab-kind-filters">{noteKindTabs}</div>
            </div>
            <div className="notes-tabs-right">
                {jobFilterTabs}
                <Tooltip title={fetching ? 'Loading' : 'Refresh'}>
                    <div className={`refresh-action-button ${fetching ? 'disabled' : ''}`} onClick={handleRefresh}>
                        <i className="material-icons">refresh</i>
                    </div>
                </Tooltip>
            </div>
        </div>
    );
    const phonescreenInterpolator = (body: string) =>
        interpolateEmailTemplate(body, {
            person: personDetails.person,
            profile: personDetails.profile,
            profileUrls: personDetails.profileUrls
        });
    const noteComponent = (
        <Notes
            notable={notable}
            context={context}
            noteDraftKey={noteDraftKey()}
            notesFilter={noteFilter}
            startingTemplates={[]}
            templateLabels={Map({ Phonescreen: 'Start phonescreen note' })}
            templateInterpolators={Map({ Phonescreen: phonescreenInterpolator })}
            actions={actions}
        />
    );

    const phoneScreenDialog = job ? (
        <PhoneScreenDialog
            open={phoneScreenActive}
            personDetails={personDetails}
            job={job}
            client={clients.list.get(job.clientId)}
            onClose={handleClosePhonescreen}
            postSave={handlePostPhonescreenSave}
        />
    ) : null;

    return (
        <div>
            {tabs}
            {noteComponent}
            {phoneScreenDialog}
        </div>
    );
};
