import { MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { MuiThemeProvider as V0MuiThemeProvider } from 'material-ui/styles';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { connect, Provider } from 'react-redux';

import { fetchSession } from './actions';
import { initializeMixpanel } from './analytics';
import { authPopup, ensureAuth } from './auth';
import { deleteExpiredDrafts } from './common/draft-storage';
import { getLocalStorageKey, pruneExpiredKeys } from './common/local-storage';
import { logger } from './common/logger';
import { store } from './configure-store';
import { Loading } from './core-ui/loading';
import { ReduxContext } from './hooks/redux-context';
import { SessionProvider } from './hooks/use-session';
import { muiTheme, muiThemeV0 } from './mui-theme';
import { AppConstantState, Session, State } from './state';

interface OwnProps {
    component: JSX.Element;
}

interface ConnectedProps {
    appConstants: AppConstantState;
    session: Session;
}

type AppProps = OwnProps & ConnectedProps;

const AppComponent: React.SFC<AppProps> = (props: AppProps) => {
    const { session, component, appConstants } = props;

    if (!session.initialized || !appConstants.initialized) {
        const loadingSize = 60;
        const style = {
            height: '100vh',
            width: '100vw'
        };
        return (
            <div style={style}>
                <Loading size={loadingSize} />
            </div>
        );
    } else {
        pruneExpiredKeys();
        deleteExpiredDrafts().catch((err) => {
            logger.warn(err, { message: 'error pruning expired drafts' });
        });
        return component;
    }
};

const mapStateToProps = (state: State): ConnectedProps => ({
    appConstants: state.appConstants,
    session: state.session
});
const App = connect<ConnectedProps, undefined, OwnProps>(mapStateToProps)(AppComponent);

export function init(authUXMode: 'redirect' | 'popup', component: JSX.Element, waitForDocumentVisible = false) {
    initializeMixpanel();

    const renderDomContent = () => {
        ReactDOM.render(
            <Provider store={store}>
                <ReduxContext.Provider value={store}>
                    <SessionProvider>
                        <MuiThemeProvider theme={muiTheme}>
                            <StylesProvider injectFirst={true}>
                                <V0MuiThemeProvider muiTheme={muiThemeV0}>
                                    <App component={component} />
                                </V0MuiThemeProvider>
                            </StylesProvider>
                        </MuiThemeProvider>
                    </SessionProvider>
                </ReduxContext.Provider>
            </Provider>,
            document.getElementById('app-root')
        );
    };

    const renderDom = () => {
        if (!waitForDocumentVisible || !document.hidden) {
            renderDomContent();
        } else {
            const handleVisibilityChange = () => {
                if (!document.hidden) {
                    document.removeEventListener('visibilitychange', handleVisibilityChange);
                    renderDomContent();
                }
            };
            document.addEventListener('visibilitychange', handleVisibilityChange, false);
        }
    };

    const authSuccessHandler = (token: string) => {
        store.dispatch(fetchSession(token, undefined));
        renderDom();
    };

    const useSavedSessionAuth = (token: string) => {
        store.dispatch(fetchSession(undefined, token));
        renderDom();
    };

    const runPopupLogin = () => {
        authPopup(() => window.location.reload(), authErrorHandler);
    };

    const authErrorHandler = () => {
        ReactDOM.render(
            <div className="extension-login-container">
                <button className="login-button" onClick={runPopupLogin}>
                    Sign In
                </button>
            </div>,
            document.getElementById('app-root')
        );
    };

    // check session from local storage
    const session = getLocalStorageKey('session', undefined);
    if (session) {
        useSavedSessionAuth(session.authToken);
        // initialize google auth lib in either case
        ensureAuth(
            authUXMode,
            () => {
                /* */
            },
            () => {
                /** */
            }
        );
    } else {
        ensureAuth(authUXMode, authSuccessHandler, authErrorHandler);
    }
}
