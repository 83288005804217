export const styles = {
    hint: {
        bottom: 0,
        fontSize: 14
    },
    input: {
        fontSize: 14
    },
    textFieldRoot: {
        height: 24,
        marginBottom: 5,
        marginTop: 4
    }
};
