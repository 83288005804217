import { MenuItem, MenuList, Popover, Tooltip } from '@material-ui/core';
import * as React from 'react';
import Dropzone, { DropzoneInputProps, DropzoneRef, DropzoneRootProps } from 'react-dropzone';

import { getMediaFileKey } from 'shared/common/file-storage';
import { S3FilePayload } from 'shared/types/file-payload';

import { secondaryTextColor, zIndexes } from '../common/css-variables';
import { fullDate } from '../common/timestamp';
import { JobTitle } from '../containers/job-title';
import { JobsListDialog } from '../containers/jobs-list-dialog';
import { useReduxState } from '../hooks/use-redux';
import { Job, JobStatus } from '../state';

interface AttachmentButtonProps {
    personId: string;
    jobId: string;
    onDrop: (files: File[]) => void;
    onUploadedFilesSelect: (attachments: S3FilePayload[]) => void;
}

const secondaryTextStyle: React.CSSProperties = {
    color: secondaryTextColor,
    fontSize: 10,
    lineHeight: 1,
    paddingBottom: 10
};

export const AttachmentButton: React.FC<AttachmentButtonProps> = (props) => {
    const dropzoneRef = React.createRef<DropzoneRef>();
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement>(null);
    const [personFileAnchorEl, setPersonFileAnchorEl] = React.useState<HTMLElement>(null);
    const [jobOnePagerAnchorEl, setJobOnePagerAnchorEl] = React.useState<HTMLElement>(null);
    const [jobSelectorOpen, setJobSelectorOpen] = React.useState(false);
    const persons = useReduxState((state) => state.persons);
    const jobs = useReduxState((state) => state.jobs);
    const clients = useReduxState((state) => state.clients);

    const { personId, jobId } = props;
    const job = jobs.get(jobId);
    const person = persons.list.get(personId);

    const handleAttachmentButtonClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePersonFilesButtonClick = (event: React.MouseEvent<HTMLElement>) => {
        setPersonFileAnchorEl(event.currentTarget);
    };

    const handleJobOnePagerButtonClick = (event: React.MouseEvent<HTMLElement>) => {
        setJobOnePagerAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setPersonFileAnchorEl(null);
        setJobOnePagerAnchorEl(null);
    };

    const selectUploadedFile = (selectedFile: S3FilePayload) => () => {
        handleCloseMenu();
        props.onUploadedFilesSelect([selectedFile]);
    };

    const handleOpenDropzone = () => {
        handleCloseMenu();
        dropzoneRef.current.open();
    };

    const openJobSelector = () => {
        handleCloseMenu();
        setJobSelectorOpen(true);
    };

    const closeJobSelector = () => {
        setJobSelectorOpen(false);
    };

    const handleAddJobOnePagers = (jobIds: string[]) => {
        handleCloseMenu();
        setJobSelectorOpen(false);
        const onePagers = jobIds.map((jid) => {
            const onePagerJob = jobs.get(jid);
            const client = clients.list.get(onePagerJob.clientId);
            const onePager = onePagerJob.onePager;
            const extension = onePager.key.split('.').reverse()[0];
            return {
                filename: `${client.name} - ${onePagerJob.title}.${extension}`,
                key: getMediaFileKey(onePager.key),
                size: onePager.size,
                type: 's3Key' as 's3Key'
            };
        });
        props.onUploadedFilesSelect(onePagers);
    };

    const jobFilter = (j: Job) => {
        return j.status === JobStatus.Active && !!j.onePager;
    };

    const jobOnePagersMenuItems = [];
    if (job && job.onePager) {
        const { key, size } = job.onePager;
        const client = clients.list.get(job.clientId);
        const onePagerExtension = key.split('.').reverse()[0];
        const onePagerName = `${client.name} - ${job.title}.${onePagerExtension}`;
        const file: S3FilePayload = {
            filename: onePagerName,
            key: getMediaFileKey(key),
            size,
            type: 's3Key'
        };
        const primaryText = (
            <div>
                <div>
                    <JobTitle id={job.id} />
                </div>
                <div style={secondaryTextStyle}>{onePagerName}</div>
            </div>
        );
        jobOnePagersMenuItems.push(
            <MenuItem key={key} onClick={selectUploadedFile(file)}>
                {primaryText}
            </MenuItem>
        );
    }
    jobOnePagersMenuItems.push(
        <MenuItem key="other-jobs" onClick={openJobSelector}>
            One pager for other Jobs
        </MenuItem>
    );

    const personFilesMenuItems = person
        ? person.files.map((f) => {
              const key = getMediaFileKey(f.path);
              const primaryText = (
                  <div>
                      <div>{f.name}</div>
                      <div style={secondaryTextStyle}>{fullDate(f.createdAt)}</div>
                  </div>
              );
              const file: S3FilePayload = {
                  filename: f.name,
                  key: getMediaFileKey(f.path),
                  size: f.size,
                  type: 's3Key'
              };
              return (
                  <MenuItem key={key} onClick={selectUploadedFile(file)}>
                      {primaryText}
                  </MenuItem>
              );
          })
        : [];

    const jobSelector = jobSelectorOpen ? (
        <JobsListDialog
            sourceJobId={jobId}
            onRequestClose={closeJobSelector}
            onJobSelect={handleAddJobOnePagers}
            multiple={true}
            jobsFilter={jobFilter}
            confirmLabel="Add One Pagers"
        />
    ) : null;

    const getDropZoneSection = (
        getRootProps: <T extends DropzoneRootProps>(props?: T) => T,
        getInputProps: <T extends DropzoneInputProps>(props?: T) => T
    ) => {
        return (
            <section style={{ display: 'none' }}>
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                </div>
            </section>
        );
    };

    const mainPopover = (
        <Popover
            open={!!anchorEl}
            onClose={handleCloseMenu}
            anchorEl={anchorEl}
            transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            style={{ zIndex: zIndexes.overEmailDialog }}
        >
            <MenuList>
                <MenuItem onClick={handleOpenDropzone}>Your computer</MenuItem>
                <MenuItem disabled={personFilesMenuItems.length === 0} onClick={handlePersonFilesButtonClick}>
                    Person files
                </MenuItem>
                <MenuItem disabled={jobOnePagersMenuItems.length === 0} onClick={handleJobOnePagerButtonClick}>
                    Job one pagers
                </MenuItem>
            </MenuList>
        </Popover>
    );

    const personFilePopover = (
        <Popover
            open={!!personFileAnchorEl}
            onClose={handleCloseMenu}
            anchorEl={personFileAnchorEl}
            transformOrigin={{ horizontal: 'right', vertical: 'center' }}
            anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
            style={{ zIndex: zIndexes.overEmailDialog }}
        >
            <MenuList>{personFilesMenuItems}</MenuList>
        </Popover>
    );

    const jobOnePagerPopover = (
        <Popover
            open={!!jobOnePagerAnchorEl}
            onClose={handleCloseMenu}
            anchorEl={jobOnePagerAnchorEl}
            transformOrigin={{ horizontal: 'right', vertical: 'center' }}
            anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
            style={{ zIndex: zIndexes.overEmailDialog }}
        >
            <MenuList>{jobOnePagersMenuItems}</MenuList>
        </Popover>
    );

    const dropZone = (
        <Dropzone ref={dropzoneRef} onDrop={props.onDrop}>
            {({ getRootProps, getInputProps }) => getDropZoneSection(getRootProps, getInputProps)}
        </Dropzone>
    );

    const button = (
        <Tooltip title="Attach files">
            <div className="email-compose-attach-action" onClick={handleAttachmentButtonClick}>
                <button className="icon-label">
                    <i className="fas fa-paperclip" />
                </button>
            </div>
        </Tooltip>
    );

    return (
        <>
            {button}
            {mainPopover}
            {personFilePopover}
            {jobOnePagerPopover}
            {dropZone}
            {jobSelector}
        </>
    );
};
