import { Map } from 'immutable';
import moment from 'moment';

import { ProfileContentRecord } from 'shared/models/profile';
import { CrunchbaseData } from 'shared/types/crunchbase';

export const getSubmissionProfileData = (profile: ProfileContentRecord, crunchbase: Map<string, CrunchbaseData>) => {
    return profile?.content
        ? {
              ...profile,
              content: {
                  ...profile?.content,
                  positions: profile?.content.positions.map((p) => {
                      const cbData = crunchbase.get(p?.meta?.rocketCompanyData?.cbUUID);
                      return {
                          ...p,
                          companyIPOYear: moment(cbData?.ipo.publicOn, 'YYYY-MM-DD').year(),
                          companyMarketCap: cbData?.marketCap ?? 0,
                          companyStatus: cbData?.ipo?.status,
                          companyStockSymbolLink: 'https://www.google.com/finance?q=' + cbData?.ipo?.stockSymbol?.value,
                          companyUrl: cbData?.crunchbaseLink
                      };
                  })
              }
          }
        : null;
};
