import { useMutation } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Avatar,
    Button,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Theme,
    Tooltip,
    Typography,
    useTheme
} from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';
import React from 'react';

import { recipientMentionText, senderMentionText, UserMentionData } from 'shared/models/user-mention';

import { interviewTime } from '../../common/timestamp';
import { config } from '../../config';
import { ClientNotesContainer } from '../../containers/client-notes-container';
import { JobNotesContainer } from '../../containers/job-notes-container';
import { UPDATE_MENTION, UserMention } from '../../graphql/queries/user-mentions';
import { useSlides } from '../../hooks/use-candidate-slides';
import { usePageDialog } from '../../hooks/use-page-dialog';
import { useSession } from '../../hooks/use-session';
import { useSnackbar } from '../../hooks/use-snackbar';
import { useUserContext } from '../../hooks/use-user-context';
import { notificationStyles } from './notification-styles';

const styles = (theme: Theme) => css`
    ${notificationStyles(theme)}

    .mention-actions {
        display: flex;
        position: absolute;
        bottom: 15px;
        right: 12px;
        opacity: 0;

        .MuiIconButton-root {
            margin-left: 5px;
        }
    }

    &:hover .mention-actions {
        opacity: 1;
    }

    .mention-info {
        display: flex;
        flex-wrap: wrap;

        .mention-sender {
            color: ${theme.palette.text.primary};
            margin-right: 0.25rem;
        }

        .mention-text {
            white-space: nowrap;
        }
    }

    .note-text {
        border-left: 2px solid ${theme.palette.divider};
        padding-left: 8px;
        margin-top: 4px;
        font-style: italic;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .MuiListItemText-multiline {
        margin: 0;

        .MuiTypography-h6 {
            line-height: 1;
            margin-bottom: 4px;
        }
    }
`;

export const Mention: React.FC<{
    data: UserMention;
    list: UserMention[];
    view: 'received' | 'sent';
}> = ({ data, list, view }) => {
    const [markedComplete, setMarkedComplete] = React.useState(false);
    const theme = useTheme();
    const { setList } = useSlides();
    const { user } = useUserContext();
    const { user: sessionUser } = useSession();
    const { setSnackbar } = useSnackbar();
    const [updateMention] = useMutation<{}, { id: string; updates: Partial<UserMentionData> }>(UPDATE_MENTION);
    const { setDialog } = usePageDialog();

    const handleSelect = () => {
        if (data.job && data.client) {
            setDialog(<JobNotesContainer jobId={data.job.id} />, `/job/${data.job.id}/notes`);
        }
        if (!data.job && data.client) {
            setDialog(<ClientNotesContainer clientId={data.client.id} />, `/client/${data.client.id}/notes`);
        }
        if (data.person) {
            const selected = {
                jobId: data.job?.id,
                personId: data.person.id,
                selectedTab: 'notes'
            };
            const ids = list
                .filter((c) => !!c.person)
                .map((c) => {
                    return {
                        jobId: c.job?.id,
                        personId: c.person.id,
                        selectedTab: 'notes'
                    };
                });

            setList(ids, selected);
        }
    };

    const handleMarkComplete = async () => {
        setSnackbar('Clearing mention');
        setMarkedComplete(true);
        await updateMention({ variables: { id: data.id, updates: { completed: true } } });
        const handleUndo = async () => {
            await updateMention({ variables: { id: data.id, updates: { completed: false } } });
            setSnackbar('Mention marked pending');
            setMarkedComplete(false);
        };
        const undoButton = (
            <Button onClick={handleUndo} style={{ color: 'white' }}>
                Undo
            </Button>
        );
        setSnackbar('Mention marked completed', undoButton);
    };

    const {
        createdAt,
        client,
        job,
        kind,
        note,
        person,
        recipient,
        sender: { name: senderName }
    } = data;
    if (markedComplete) {
        return null;
    }

    const primary = (
        <Typography variant="h6">
            {person ? person.name : job ? `${job.client.name} - ${job.title}` : client.name}
        </Typography>
    );
    const noteText = note ? <div className="note-text" dangerouslySetInnerHTML={{ __html: note.content }} /> : null;
    const mentionInfo =
        view === 'received' ? (
            <div className="mention-info">
                <div className="mention-sender">@{senderName}</div>
                <div className="mention-text">{recipientMentionText(kind)}</div>
            </div>
        ) : (
            <div className="mention-info">
                {recipient && <div className="mention-sender">@{recipient.name}</div>}
                <div className="mention-text">{senderMentionText(kind)}</div>
            </div>
        );
    const jobTitle =
        job && person ? (
            <div>
                {job.client.name} - {job.title}
            </div>
        ) : null;
    const secondary = (
        <Typography variant="body2" color="textSecondary" component="div">
            {jobTitle}
            {mentionInfo}
            {noteText}
        </Typography>
    );
    const avatar = person ? (
        person.profilePicUrl ? (
            <Avatar src={`${config.AssetHost}/${person.profilePicUrl}`} onClick={handleSelect} />
        ) : (
            <Avatar onClick={handleSelect}>{person.name.charAt(0).toLocaleUpperCase()}</Avatar>
        )
    ) : job ? (
        <Avatar onClick={handleSelect}>{job.client.name.charAt(0)}</Avatar>
    ) : (
        <Avatar onClick={handleSelect}>{client.name.charAt(0)}</Avatar>
    );

    const actions =
        user.id === sessionUser.id ? (
            <div className="mention-actions">
                <Tooltip title="Mark Complete">
                    <IconButton size="small" onClick={handleMarkComplete}>
                        <CheckCircleOutline fontSize="small" />
                    </IconButton>
                </Tooltip>
            </div>
        ) : null;

    return (
        <ListItem key={data.id} css={styles(theme)}>
            <ListItemAvatar>{avatar}</ListItemAvatar>
            <ListItemText onClick={handleSelect} disableTypography={true} primary={primary} secondary={secondary} />
            <div>
                <Typography color="textSecondary" noWrap={true} variant="caption" className="caption" component="div">
                    <span className="left-pad">{interviewTime(createdAt)}</span>
                </Typography>
                {actions}
            </div>
        </ListItem>
    );
};
