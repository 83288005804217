import { css } from '@emotion/core';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { closeConfirmationModal } from '../actions';
import { zIndexes } from '../common/css-variables';
import { useReduxDispatch, useReduxState } from '../hooks/use-redux';
import { State } from '../state';

const styles = css`
    .MuiDialogContent-root {
        line-height: 1.4rem;
        min-width: 480px;
    }
`;

export const ConfirmationModal: React.FC = () => {
    const state = useReduxState((s: State) => s.confirmationModal);
    const { open, onConfirm } = state;

    const dispatch = useReduxDispatch();

    const handleClose = () => {
        dispatch(closeConfirmationModal());
    };

    const handleConfirmed = () => {
        handleClose();
        onConfirm?.();
    };

    if (!open) return null;
    const title = state.title ?? 'Confirm';
    const description = state.description ?? 'Please hit Continue to proceed';

    const buttons = onConfirm ? (
        <>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleConfirmed}>Continue</Button>
        </>
    ) : (
        <Button onClick={handleClose} disabled={state.nonCancelable}>
            Close
        </Button>
    );

    const confirmDialog = state ? (
        <Dialog
            open={true}
            onClose={state.nonCancelable ? null : handleClose}
            style={{ zIndex: zIndexes.modalDialog }}
            css={styles}
            maxWidth="md"
        >
            <DialogTitle>
                <Typography variant="h4" component="div">
                    {title}
                </Typography>
            </DialogTitle>

            <DialogContent>{description}</DialogContent>
            <DialogActions>{buttons}</DialogActions>
        </Dialog>
    ) : null;

    const modal = document.createElement('div');
    modal.setAttribute('class', 'confirmation-modal');
    document.body.appendChild(modal);
    return ReactDOM.createPortal(confirmDialog, modal);
};
