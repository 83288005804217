import { css } from '@emotion/core';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, Typography } from '@material-ui/core';
import { Receipt } from '@material-ui/icons';
import React from 'react';

import { FeeType, feeTypeLabel } from 'shared/models/fee';

import { DateTextField, SelectField, TextField } from '../core-ui/form-fields';
import { BaseFee, Fee, Invoice } from '../graphql/queries/billing';
import { useModal } from '../hooks/use-modal';
import { useFormContextData } from './form-context';
import { InvoiceDetailsDialog } from './invoice-details-dialog';

const styles = css`
    .MuiDialogContent-root {
        min-width: 480px;
    }

    .MuiFormLabel-root.Mui-disabled,
    .MuiInputBase-root.Mui-disabled {
        color: inherit;
        opacity: 0.8;
    }

    .form-row {
        display: flex;
        gap: 32px;
        margin-bottom: 32px;

        &:last-child {
            margin-bottom: 16px;
        }

        &.invoices-list {
            flex-wrap: wrap;
        }
    }
`;

export const JobFeeForm: React.FC<{
    data: BaseFee & { invoices?: Invoice[]; job?: { title?: string } };
    disabled: boolean;
    onClose: () => void;
    onSave?: (data: Partial<Fee>) => Promise<void>;
}> = ({ data: initialData, disabled: propDisabled, onSave, onClose }) => {
    const initialFeeData: BaseFee = {
        accountManagerCommission: initialData.accountManagerCommission,
        amount: initialData.amount,
        clientId: initialData.clientId,
        description: initialData.description,
        dueAt: initialData.dueAt,
        guaranteeCompletionAt: initialData.guaranteeCompletionAt,
        id: initialData.id,
        incurredAt: initialData.incurredAt,
        recruiterCommission: initialData.recruiterCommission,
        salary: initialData.salary,
        signOnBonus: initialData.signOnBonus,
        type: initialData.type
    };
    const { invoices, job } = initialData;
    const { data, onFieldChange, validateFieldExists, getError, isDirty, setError } =
        useFormContextData<Partial<Fee>>(initialFeeData);
    const { getConfirmation, setAlert } = useModal();
    const [isSaving, setIsSaving] = React.useState(false);
    const [selectedInvoiceId, setSelectedInvoiceId] = React.useState<string | undefined>(undefined);

    const disabled = propDisabled || !onSave;

    const validateData = () => {
        let valid = true;
        valid = validateFieldExists('incurredAt')(data.incurredAt) && valid;
        valid = validateFieldExists('dueAt')(data.dueAt) && valid;
        valid = validateAmount(data.amount) && valid;
        return valid;
    };

    const validateAmount = (val: number) => {
        if (!val) {
            setError('amount', 'Amount is required');
            return false;
        }
        return true;
    };

    const handleSave = async () => {
        if (!validateData()) {
            setAlert('Errors found', 'Please correct the errors and try again');
            return;
        }
        setIsSaving(true);
        try {
            await onSave(data);
        } finally {
            setIsSaving(false);
        }
    };

    const handleCancel = () => {
        if (isDirty) {
            getConfirmation(onClose, 'Cancel and discard changes?', 'Confirm Discard');
        } else {
            onClose();
        }
    };

    const handleShowInvoice = (invoice: Invoice) => () => {
        setSelectedInvoiceId(invoice.id);
    };

    const handleCloseInvoiceDetails = () => {
        setSelectedInvoiceId(undefined);
    };

    const feeTypeOptions = (['monthly-rpo', 'prepayment'] as FeeType[]).map((t) => ({
        label: feeTypeLabel(t),
        value: t
    }));

    const invoicesList = invoices?.map((invoice) => (
        <div className="invoice" key={invoice.id}>
            <Tooltip title="View Invoice">
                <Button variant="outlined" startIcon={<Receipt />} onClick={handleShowInvoice(invoice)}>
                    {invoice.invoiceNumber}
                </Button>
            </Tooltip>
        </div>
    ));

    let invoiceDialog;
    if (selectedInvoiceId) {
        const invoice = invoices.find((i) => i.id === selectedInvoiceId);
        invoiceDialog = <InvoiceDetailsDialog open={true} onClose={handleCloseInvoiceDetails} invoice={invoice} />;
    }

    const invoicesField = invoices?.length > 0 ? <div className="form-row invoices-list">{invoicesList}</div> : null;

    const readOnlyActions = (
        <Button onClick={onClose} disabled={isSaving}>
            Close
        </Button>
    );

    const editActions = (
        <React.Fragment>
            <Button onClick={handleCancel} disabled={isSaving}>
                Cancel
            </Button>
            <Button onClick={handleSave} disabled={isSaving}>
                {isSaving ? 'Saving...' : 'Save'}
            </Button>
        </React.Fragment>
    );

    return (
        <Dialog open={true} onClose={onClose} css={styles}>
            <DialogTitle>
                <Typography variant="h4" component="div">
                    Job Fee {job?.title ? ` - ${job.title}` : ''}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <div className="form-row">
                    <SelectField
                        label="Fee Type"
                        value={data.type}
                        options={feeTypeOptions}
                        onChange={onFieldChange('type')}
                        disabled={disabled}
                    />
                </div>
                <div className="form-row">
                    <TextField
                        label="Amount"
                        value={data.amount}
                        onChange={onFieldChange('amount')}
                        type="number"
                        disabled={disabled}
                        validate={validateAmount}
                        errorText={getError('amount')}
                    />
                </div>
                <div className="form-row">
                    <TextField
                        label="Description"
                        value={data.description}
                        onChange={onFieldChange('description')}
                        disabled={disabled}
                    />
                </div>
                <div className="form-row">
                    <DateTextField
                        label="Incurred At"
                        value={data.incurredAt}
                        onChange={onFieldChange('incurredAt')}
                        disabled={disabled}
                        validate={validateFieldExists('incurredAt')}
                        errorText={getError('incurredAt')}
                    />
                </div>
                <div className="form-row">
                    <DateTextField
                        label="Due At"
                        value={data.dueAt}
                        onChange={onFieldChange('dueAt')}
                        disabled={disabled}
                        validate={validateFieldExists('dueAt')}
                        errorText={getError('dueAt')}
                    />
                </div>
                {invoicesField}
            </DialogContent>
            <DialogActions>{disabled || (!isDirty && !!data?.id) ? readOnlyActions : editActions}</DialogActions>
            {invoiceDialog}
        </Dialog>
    );
};
