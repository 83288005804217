import { useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import React, { useState } from 'react';
import { JobFilter } from 'shared/models/job';
import { UserData } from 'shared/models/user';
import { logger } from '../common/logger';
import { UPDATE_USER, User, USER_BY_USERNAME } from '../graphql/queries/user';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';
import { LoadFilters } from './load-filters';
import { SaveFilter } from './save-filters';

const styles = css`
    display: flex;

    .MuiButton-root {
        margin-right: 15px;
    }
`;

interface JobsFilterActionsProps {
    selected: JobFilter[];
    onChange: (filters: JobFilter[]) => void;
}

export const JobsFilterActions: React.FC<JobsFilterActionsProps> = ({ selected, onChange }) => {
    const { setSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    const { user: currentUser } = useSession();
    const { data: userData, refetch } = useQuery<
        { users: User[]; userPermissions: Permissions[] },
        { username: string }
    >(USER_BY_USERNAME, {
        variables: { username: currentUser?.username }
    });

    const [updateUser] = useMutation<{}, { id: string; updates: Partial<UserData> }>(UPDATE_USER);

    const currentUserInfo = userData?.users[0] || currentUser;

    const handleSelectPresets = (index: number) => {
        onChange([...currentUserInfo.jobsFilterPresets.find((_, i) => i === index).values]);
    };

    const handleSavePresets = async (name: string) => {
        setSnackbar('Saving...');
        setLoading(true);
        try {
            await updateUser({
                variables: {
                    id: currentUserInfo.id,
                    updates: { jobsFilterPresets: [...currentUserInfo.jobsFilterPresets, { name, values: selected }] }
                }
            });
            setSnackbar('Saved');
            refetch();
        } catch (err) {
            setSnackbar('Error updating');
            logger.error(err, { message: 'Error saving job search presets' });
        }
        setLoading(false);
    };
    const handleDeletePresets = async (index: number) => {
        setSnackbar('Deleting...');
        setLoading(true);
        try {
            await updateUser({
                variables: {
                    id: currentUserInfo.id,
                    updates: { jobsFilterPresets: currentUserInfo.jobsFilterPresets.filter((_, i) => i !== index) }
                }
            });
            setSnackbar('Deleted');
            refetch();
        } catch (err) {
            setSnackbar('Error deleting');
            logger.error(err, { message: 'Error deleting job search presets' });
        }
        setLoading(false);
    };

    const saveFilter = <SaveFilter onSave={handleSavePresets} disabled={loading || selected.length === 0} />;
    const loadFilters = (
        <LoadFilters
            onSelect={handleSelectPresets}
            onDelete={handleDeletePresets}
            list={currentUserInfo.jobsFilterPresets}
            disabled={loading}
        />
    );

    return (
        <div css={styles}>
            {loadFilters}
            {saveFilter}
        </div>
    );
};
