import { Dialog, TextField } from '@material-ui/core';
import React from 'react';

import { fullDate } from '../common/timestamp';
import { DayPicker } from '../core-ui/react-day-picker';
import { ClientFormData } from '../graphql/queries/clients';
import { useFormContext } from './form-context';

export const ClientFormGlobalBlacklist: React.FC = () => {
    const [globalBlacklistDialogOpen, setGlobalBlacklistDialogOpen] = React.useState(false);
    const { data, onFieldChange, disabled } = useFormContext<ClientFormData>();
    const globalBlacklistLabel = !data.globalBlacklistForcedTill
        ? 'ON'
        : data.globalBlacklistForcedTill < Date.now()
          ? 'OFF'
          : fullDate(data.globalBlacklistForcedTill);

    const handleGlobalBlacklistClick = () => {
        setGlobalBlacklistDialogOpen(true);
    };

    const handleCloseGlobalBlacklistDialog = () => {
        setGlobalBlacklistDialogOpen(false);
    };

    const handleBlacklistedTillChange = (date: Date) => {
        onFieldChange('globalBlacklistForcedTill')(date.getTime());
        handleCloseGlobalBlacklistDialog();
    };

    let globalBlacklistDialog = null;
    if (globalBlacklistDialogOpen) {
        const selected =
            data.globalBlacklistForcedTill > Date.now() ? new Date(data.globalBlacklistForcedTill) : new Date();
        globalBlacklistDialog = (
            <Dialog open={true} onClose={handleCloseGlobalBlacklistDialog}>
                <DayPicker
                    mode="single"
                    selected={selected}
                    defaultMonth={selected}
                    onSelect={handleBlacklistedTillChange}
                />
            </Dialog>
        );
    }

    return (
        <>
            <TextField
                label="Global Blacklist"
                value={globalBlacklistLabel}
                disabled={disabled}
                fullWidth={true}
                onClick={handleGlobalBlacklistClick}
                className="clickable-field"
            />
            {globalBlacklistDialog}
        </>
    );
};
