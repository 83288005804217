import { Button } from '@material-ui/core';
import * as classNames from 'classnames';
import * as filesize from 'filesize';
import { Map } from 'immutable';
import { isEqual } from 'lodash';
import { CircularProgress } from 'material-ui';
import * as React from 'react';
import { connect } from 'react-redux';
import * as striptags from 'striptags';

import { Editor } from 'react-ce';

import { NoteAttachment, NoteView } from 'shared/models/note';
import { UserData } from 'shared/models/user';
import { FilePayload, HrefFilePayload, S3FilePayload } from 'shared/types/file-payload';

import { addNoteAttachments, createNewNote, getConfirmation, removeNoteAttachments } from '../actions';
import { updateDraft } from '../common/draft-storage';
import { logger } from '../common/logger';
import { withUsers } from '../hocs/with-users';
import { addErrorHighlights, removeErrorHighlights } from '../lib/html-markup';
import { readFile } from '../lib/read-file-payload';
import { Avatar } from '../sfc/avatar';
import { NoteDraftState, State } from '../state';
import { Attachment } from './attachment';
import { AttachmentButton } from './attachment-button';
import { EditorTag } from './editor-tag';

const avatarSize = 32;
const spinnerSize = 20;
const spinnerThickness = 2;
const maxAttachmentSize = 26214400;

interface OwnProps {
    initialDraft: NoteDraftState;
    context: { [field: string]: any };
    noteDraftKey: string;
    notable: string;
    highlightErrors: boolean;
    hasChanged: (content: string) => boolean;
    id?: string;
    placeholder?: string;
    postDiscard?: () => void;
    postSave?: () => void;
    parentId?: string;
}

interface ConnectedProps {
    noteAttachments: Map<string, FilePayload[]>;
    user: UserData;
}

interface ConnectedDispatch {
    addNoteAttachments: (noteDraftKey: string, attachments: FilePayload[]) => void;
    removeNoteAttachments: (noteDraftKey: string) => void;
    getConfirmation: (onConfirm: () => void, description?: string, title?: string) => void;
    createNewNote: (note: Partial<NoteView>, postSave?: () => void, onFailedSave?: () => void) => void;
}

interface ConnectedPropsUsers {
    users: Map<string, UserData>;
}

type NoteEditorComponentProps = ConnectedProps & ConnectedDispatch & ConnectedPropsUsers & OwnProps;

interface NoteEditorComponentState {
    errorsHighlighted: boolean;
    initialAttachments: FilePayload[];
    draft: NoteDraftState;
}

const confirmMessage = 'Note not saved, discard changes?';

class NoteEditorComponent extends React.Component<NoteEditorComponentProps, NoteEditorComponentState> {
    private editorRef: React.RefObject<Editor>;

    constructor(props: NoteEditorComponentProps) {
        super(props);
        this.editorRef = React.createRef<Editor>();
        const { highlightErrors, initialDraft, noteAttachments, noteDraftKey } = props;
        const draft = initialDraft;
        const initialAttachments: FilePayload[] =
            draft.initialAttachments.map((a) => ({ ...a, type: 's3Key' as 's3Key' })) ?? [];
        if (!noteAttachments.get(noteDraftKey)) {
            props.addNoteAttachments(noteDraftKey, initialAttachments);
        }
        if (highlightErrors) {
            const { content } = draft;
            const highlighted = addErrorHighlights(removeErrorHighlights(content));
            draft.content = highlighted;
        }

        this.state = {
            draft,
            errorsHighlighted: highlightErrors,
            initialAttachments
        };
    }

    handleUpdateNoteDraft = (updates: Partial<NoteDraftState>) => {
        const newDraft = { ...this.state.draft, ...updates, modifiedAt: Date.now() };
        updateDraft(this.props.noteDraftKey, newDraft);
        this.setState({ draft: newDraft });
    };

    handleFailedSave = () => {
        this.handleUpdateNoteDraft({ saving: false });
    };

    handleSaveNote = () => {
        const { notable, noteDraftKey, user, noteAttachments, parentId } = this.props;
        const { draft } = this.state;
        const draftAttachments = noteAttachments.get(noteDraftKey) || [];
        const { content } = draft;
        if (!this.noteHasErrors()) {
            const context = { ...draft.context, ...this.props.context };
            const userId = user.id;
            const savedAttachments: NoteAttachment[] = (
                draftAttachments.filter((a) => a.type === 's3Key') as S3FilePayload[]
            ).map((a) => ({ filename: a.filename, key: a.key, size: a.size }));
            const newAttachments: HrefFilePayload[] = draftAttachments.filter(
                (a) => a.type === 'href'
            ) as HrefFilePayload[];
            const newNote: Partial<NoteView> = {
                attachments: savedAttachments,
                content: removeErrorHighlights(content),
                context,
                format: 'html',
                modifiedBy: userId,
                newAttachments,
                notable,
                parentId
            };
            if (this.props.id) {
                newNote.id = this.props.id;
            } else {
                newNote.createdBy = userId;
            }
            this.handleUpdateNoteDraft({ saving: true });
            this.props.createNewNote(newNote, this.props.postSave, this.handleFailedSave);
            this.props.removeNoteAttachments(this.props.noteDraftKey);
        } else {
            this.props.getConfirmation(
                null,
                'Please fill in the variables marked by curly {} braces in the body',
                'Errors'
            );
            this.highlightErrors(content);
            this.setState({ errorsHighlighted: true });
        }
    };

    onChange = (content: string) => {
        this.handleUpdateNoteDraft({ content });
    };

    handleConfirmedDiscard = () => {
        this.props.removeNoteAttachments(this.props.noteDraftKey);
        if (this.props.postDiscard) {
            this.props.postDiscard();
        }
    };

    handleDiscard = () => {
        const { draft } = this.state;
        const content = draft ? draft.content : '';
        if (!this.props.hasChanged(content) && !this.attachmentsHasChanged()) {
            this.handleConfirmedDiscard();
        } else {
            this.props.getConfirmation(this.handleConfirmedDiscard, confirmMessage, 'Confirm');
        }
    };

    attachmentUnderSizeLimit = (newFiles: Array<{ size: number }>) => {
        const { noteAttachments, noteDraftKey } = this.props;
        const attachments = noteAttachments.get(noteDraftKey);
        let errorString;
        let totalSize = attachments.map((a) => a.size).reduce((acc, s) => s + acc, 0);
        for (const file of newFiles) {
            totalSize += file.size;
            if (file.size > maxAttachmentSize) {
                errorString = `Maximum size for an attachment is ${filesize(maxAttachmentSize, { round: 0 })}`;
            }
        }
        if (!errorString && totalSize > maxAttachmentSize) {
            errorString = `Maximum total size for attachments is ${filesize(maxAttachmentSize, { round: 0 })}`;
        }
        if (errorString) {
            this.props.getConfirmation(null, errorString, 'Errors');
            return false;
        } else {
            return true;
        }
    };

    handleAddPreloadedFiles = (files: S3FilePayload[]) => {
        if (this.attachmentUnderSizeLimit(files)) {
            const { noteAttachments, noteDraftKey } = this.props;
            const attachments = noteAttachments.get(noteDraftKey);
            const newAttachments = attachments.concat(files);
            this.props.addNoteAttachments(noteDraftKey, newAttachments);
        }
    };

    handleAddAttachments = (files: File[]) => {
        if (this.attachmentUnderSizeLimit(files)) {
            const { noteAttachments, noteDraftKey } = this.props;
            const attachments = noteAttachments.get(noteDraftKey);
            this.handleUpdateNoteDraft({ addingAttachment: true });
            Promise.all(files.map(readFile))
                .then((result) => {
                    const newAttachments = attachments.concat(result);
                    this.props.addNoteAttachments(noteDraftKey, newAttachments);
                    this.handleUpdateNoteDraft({ addingAttachment: false });
                })
                .catch((err) => {
                    logger.warn(err, { files, message: 'error adding attachments to note' });
                    this.props.getConfirmation(null, 'There was a problem adding the attachment', 'Errors');
                    this.props.addNoteAttachments(noteDraftKey, attachments);
                    this.handleUpdateNoteDraft({ addingAttachment: false });
                });
        }
    };

    handleRemoveAttachment = (index: number) => () => {
        const attachments = this.props.noteAttachments.get(this.props.noteDraftKey);
        const newAttachments = attachments.slice(0, index).concat(attachments.slice(index + 1));
        this.props.addNoteAttachments(this.props.noteDraftKey, newAttachments);
    };

    handleAttachmentFileNameUpdate = (index: number) => (filename: string) => {
        const attachments = this.props.noteAttachments.get(this.props.noteDraftKey);
        const newAttachments = attachments.map((a, i) => {
            if (i === index) {
                return {
                    ...a,
                    filename
                };
            } else {
                return a;
            }
        });
        this.props.addNoteAttachments(this.props.noteDraftKey, newAttachments);
    };

    highlightErrors = (body: string) => {
        const highlighted = addErrorHighlights(removeErrorHighlights(body));
        this.onChange(highlighted);
    };

    noteHasErrors = () => {
        const { highlightErrors } = this.props;
        const { draft } = this.state;
        if (highlightErrors) {
            const body = draft.content;
            const text = removeErrorHighlights(striptags(body).trim());
            return !!text.match(/{[\s\S]+?}/);
        } else {
            return false;
        }
    };

    handleToggleErrorHighlights = () => {
        const { draft } = this.state;
        const { content } = draft;
        if (content) {
            if (this.state.errorsHighlighted) {
                const cleaned = removeErrorHighlights(content);
                this.onChange(cleaned);
            } else {
                this.highlightErrors(content);
            }
            this.setState({ errorsHighlighted: !this.state.errorsHighlighted });
        }
    };

    attachmentsHasChanged = () => {
        return !isEqual(this.state.initialAttachments, this.props.noteAttachments.get(this.props.noteDraftKey));
    };

    render() {
        const { user, noteDraftKey, highlightErrors, placeholder, noteAttachments, users } = this.props;
        const attachments = noteAttachments.get(noteDraftKey) || [];
        const { errorsHighlighted, draft } = this.state;

        if (!draft) {
            return (
                <div className="note-editor-container">
                    <div className="note-header note-form flex-columns-container">
                        <Avatar entity={user} size={avatarSize} />
                    </div>
                </div>
            );
        }

        const { content, saving, addingAttachment } = draft;
        const isPhonescreenTemplate = draft.context && draft.context.isPhonescreenTemplate;

        let attachmentsList;
        if (attachments.length > 0) {
            attachmentsList = attachments.map((f, i) => (
                <Attachment
                    key={i}
                    file={f}
                    onRemove={this.handleRemoveAttachment(i)}
                    onFileNameUpdate={this.handleAttachmentFileNameUpdate(i)}
                />
            ));
        }
        const attachmentsSection =
            attachments.length > 0 ? <div className="note-editor-attachments">{attachmentsList}</div> : null;

        const editorClassName = classNames({
            disabled: saving,
            'note-form-editor': true,
            'phonescreen-note': isPhonescreenTemplate,
            'text-editor-toolbar-hidden': saving
        });

        const form = (
            <div className="note-form-content">
                <Editor
                    value={content}
                    ref={this.editorRef}
                    onChange={this.onChange}
                    autoFocus={true}
                    disabled={saving}
                    className={editorClassName}
                    postEditorContent={attachmentsSection}
                    placeholder={placeholder}
                />
                <div className="note-form-content-border-container">
                    <div className="note-form-content-border" />
                </div>
            </div>
        );
        const discardButton = saving ? null : <Button onClick={this.handleDiscard}>Discard</Button>;
        const spinner = saving ? <CircularProgress size={spinnerSize} thickness={spinnerThickness} /> : null;
        const savedDisabled =
            saving || (!this.props.hasChanged(content) && !this.attachmentsHasChanged()) || addingAttachment;
        const saveButton = (
            <Button onClick={this.handleSaveNote} disabled={savedDisabled}>
                Save
            </Button>
        );

        const notableParts = this.props.notable.split('-');
        const personId = notableParts[0] === 'persons' ? notableParts[1] : null;
        const addAttachmentsButton = (
            <AttachmentButton
                personId={personId}
                jobId={this.props.context.jobId}
                onDrop={this.handleAddAttachments}
                onUploadedFilesSelect={this.handleAddPreloadedFiles}
            />
        );
        const toggleHighlight = highlightErrors ? (
            <button
                className={`icon-label ${errorsHighlighted ? 'button-active' : ''}`}
                onClick={this.handleToggleErrorHighlights}
                disabled={saving}
            >
                <i className="material-icons">border_color</i>
            </button>
        ) : null;

        const editorTagOptions = users
            .filter((u) => u.status === 'active')
            .valueSeq()
            .map((u) => u.name.full)
            .concat(['here'])
            .sort()
            .toArray();

        return (
            <div className="note-editor-container">
                <div className="note-header note-form flex-columns-container">
                    <Avatar entity={user} size={avatarSize} />
                    {form}
                    <EditorTag tagOptions={editorTagOptions} tagCssClass="note-user-tag" />
                </div>
                <div className="note-form-buttons">
                    <div className="note-form-buttons-left">
                        {toggleHighlight}
                        {addAttachmentsButton}
                    </div>
                    <div className="right">
                        {discardButton}
                        {spinner}
                        {saveButton}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    noteAttachments: state.noteAttachments,
    user: state.session.user
});
const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    addNoteAttachments,
    createNewNote,
    getConfirmation,
    removeNoteAttachments
};
export const NoteEditor = withUsers<OwnProps>(
    connect<ConnectedProps, ConnectedDispatch, OwnProps & ConnectedPropsUsers>(
        mapStateToProps,
        mapDispatchToProps
    )(NoteEditorComponent)
);
