import { Button, IconButton } from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';

import { RenderAppendedToBody } from '../sfc/render-appended-to-body';
import { State } from '../state';

interface ConnectedProps {
    appVersionOutdated: boolean;
}

type AppVersionCheckerProps = ConnectedProps;

const AppVersionCheckerComponent = ({ appVersionOutdated }: AppVersionCheckerProps) => {
    const [closed, setClosed] = React.useState(false);

    React.useEffect(() => {
        setClosed(false);
    }, [appVersionOutdated]);

    const handleReload = () => window.location.reload();
    const handleClose = () => setClosed(true);

    return appVersionOutdated && !closed ? (
        <RenderAppendedToBody>
            <div className="toaster-container app-version-message">
                <div className="toaster-message">A new version of Titan is available.</div>
                <Button className="toaster-action" onClick={handleReload}>
                    Refresh
                </Button>
                <IconButton onClick={handleClose}>
                    <i className="material-icons">close</i>
                </IconButton>
            </div>
        </RenderAppendedToBody>
    ) : null;
};

const mapStateToProps = (state: State): ConnectedProps => ({
    appVersionOutdated: state.session.appVersionOutdated
});
export const AppVersionChecker = connect<ConnectedProps, undefined, {}>(mapStateToProps)(AppVersionCheckerComponent);
