import { css } from '@emotion/core';
import { Button } from '@material-ui/core';
import { isEqual } from 'lodash';
import React from 'react';
import { JobStatus } from 'shared/models/job';
import { hasRole } from 'shared/models/user';

import { JobSequencesForm } from '../containers/job-sequences-form';
import { JobFormData, JobUpdateData } from '../graphql/queries/job-form';
import { useModal } from '../hooks/use-modal';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';
import { FormContext, FormContextProps, useFormContextData } from './form-context';
import { JobCloneButton } from './job-clone-button';
import { JobFees } from './job-fees';
import { JobFormAIContext } from './job-form-ai-context';
import { JobFormCompanyInfo } from './job-form-company-info';
import { JobFormContract } from './job-form-contract';
import { JobFormGeneral } from './job-form-general';
import { JobFormIntroInfo } from './job-form-intro-info';
import { JobFormJD } from './job-form-jd';
import { JobFormOutreach } from './job-form-outreach';
import { JobFormPanel } from './job-form-panel';
import { JobFormRecruiterPermissions } from './job-form-recruiter-permissions';
import { JobFormSubmissionInfo } from './job-form-submission-info';
import { JobFormTemplates } from './job-form-templates';
import { JobFunnelTargetsForm } from './job-funnel-targets-form';

interface JobFormProps {
    job: JobFormData;
    onSave: (data: JobUpdateData) => Promise<void>;
}

const styles = css`
    margin: 0 auto;
    width: 960px;
    padding-bottom: 40px;

    .job-form-buttons {
        display: flex;
        justify-content: space-between;
    }
`;

export const JobEditForm: React.FC<JobFormProps> = ({ job, onSave }) => {
    const [saving, setSaving] = React.useState(false);
    const { getConfirmation, setAlert } = useModal();
    const { setSnackbar } = useSnackbar();
    const context = useFormContextData<JobFormData>(job);
    const { data, isDirty, resetForm, hasErrors } = context;
    const { user, userPermissions } = useSession();
    const canEdit =
        job.status !== JobStatus.Archived &&
        hasRole(userPermissions, 'job_creator') &&
        (job.assignee === user.id || job.accountManagerId === user.id || hasRole(userPermissions, 'settings_editor'));

    const disabled = saving || !canEdit;

    const getDataToSave = (input: JobFormData): JobUpdateData => {
        const {
            client: { id: _1, __typename: _2, name, ...updatedClient },
            description: { jobId, __typename: _3, _id: _4, ...updatedDescription },
            id: _5,
            __typename: _6,
            ...updatedJob
        } = input;
        return {
            client: updatedClient,
            clientId: input.clientId,
            description: updatedDescription,
            job: updatedJob,
            jobId: input.id
        };
    };

    const handleSubmit = async () => {
        if (hasErrors) {
            setAlert('Errors exist', 'Please fix the errors before saving');
        } else {
            try {
                setSnackbar('Saving...');
                setSaving(true);
                await onSave(getDataToSave(data));
                setSaving(false);
                resetForm(data);
                setSnackbar('Saved');
            } catch {
                setAlert('Error', 'Failed to save');
            }
        }
    };

    const handleResetButtonClick = () => {
        getConfirmation(
            () => {
                resetForm(job);
            },
            'Are you sure you want to discard all changes?',
            'Discard changes'
        );
    };

    React.useEffect(() => {
        if (!isDirty || isEqual(job, data)) {
            resetForm(job);
        } else {
            getConfirmation(
                () => {
                    resetForm(job);
                    setSnackbar('Data updated to the latest version');
                },
                'Job data has been updated elsewhere. Press OK to reset to the latest data.',
                'Job data changed',
                true
            );
        }
    }, [job]);

    const resetButton = isDirty ? (
        <Button onClick={handleResetButtonClick} disabled={disabled}>
            Discard
        </Button>
    ) : null;
    const cloneButton = isDirty ? null : (
        <JobCloneButton data={data} disabled={!hasRole(userPermissions, 'job_creator')} />
    );

    const formContext: FormContextProps<JobFormData> = {
        ...context,
        disabled
    };

    return (
        <div css={styles}>
            <FormContext.Provider value={formContext}>
                <JobFormPanel>
                    <JobFormGeneral />
                </JobFormPanel>
                <JobFormPanel title="Job Description" subtitle="View/Edit Detailed JD">
                    <JobFormJD />
                </JobFormPanel>
                <JobFormPanel title="Company Info" subtitle="View/Edit Company Info">
                    <JobFormCompanyInfo />
                </JobFormPanel>
                <JobFormPanel title="AI Context Settings" subtitle="View/Edit Context used by AI">
                    <JobFormAIContext />
                </JobFormPanel>
                <JobFormPanel
                    title="Allocations & Funnel Targets"
                    subtitle="View/Edit Funnel Targets & Allocations for a 14 day period"
                >
                    <JobFunnelTargetsForm jobId={data.id} disabled={disabled} />
                </JobFormPanel>
                <JobFormPanel title="Outreach Settings" subtitle="View/Edit Outreach Settings">
                    <JobFormOutreach />
                </JobFormPanel>
                <JobFormPanel title="Email Sequences" subtitle="View/Edit Email Sequences">
                    <JobSequencesForm jobId={data.id} role={data?.client?.name} isEditable={!disabled} />
                </JobFormPanel>
                <JobFormPanel title="Submission Settings" subtitle="View/Edit Submission Settings">
                    <JobFormSubmissionInfo />
                </JobFormPanel>
                <JobFormPanel title="Introduction Settings" subtitle="View/Edit Client-Candidate Intro Settings">
                    <JobFormIntroInfo />
                </JobFormPanel>
                <JobFormPanel title="Job Templates" subtitle="View/Edit Job Templates">
                    <JobFormTemplates />
                </JobFormPanel>
                <JobFormPanel title="Recruiter Permissions" subtitle="View/Edit Recruiter Permissions">
                    <JobFormRecruiterPermissions />
                </JobFormPanel>
                <JobFormPanel title="Contract & Fees" subtitle="View/Edit Job Contract Details">
                    <JobFormContract />
                    <JobFees job={data} disabled={disabled} />
                </JobFormPanel>

                <div className="job-form-buttons">
                    <div>{cloneButton}</div>
                    <div>
                        {resetButton}
                        <Button type="submit" disabled={!isDirty || disabled} onClick={handleSubmit}>
                            Save
                        </Button>
                    </div>
                </div>
            </FormContext.Provider>
        </div>
    );
};
