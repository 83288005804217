import { Map, Set } from 'immutable';
import { uniqBy } from 'lodash';
import {
    Action,
    ReceiveAddContactFromMessage,
    ReceiveAddSearchResultToJob,
    ReceiveCandidate,
    ReceiveCandidateAddedToJob,
    ReceiveCandidateUpdate,
    ReceiveCommunicationMatchDataUpdate,
    ReceiveCommunicationMatcher,
    ReceiveContactsUpdate,
    ReceiveCrossAddCandidate,
    ReceiveIgnoreContactFromMessage,
    ReceivePersonDetails,
    ReceivePersonFilesUploadResult,
    ReceivePersonJobLabels,
    ReceivePersonOptOutUpdate,
    ReceivePersonSetBlacklisted,
    ReceivePersonsList,
    ReceivePersonUpdate,
    ReceivePersonWebsiteAdd,
    ReceiveProfileLinkUpdate,
    ReceiveReassignCandidateAssignee,
    ReceiveSearchResultPersonAndCandidate,
    ReceiveUpdatePersonFilename,
    ReceiveUserBlacklistPerson,
    RequestContactDelete,
    RequestPersonsList,
    RequestPersonUpdate
} from '../actions';
import { mergeArrayToMap } from '../common/lang/immutable-utils';
import { ReceiveScheduledDisqualificationMail, ReceiveSentDisqualificationMail } from '../email-compose/actions';
import { Communication, List, Person, PersonDetails } from '../state';

const initialState: List<Person> = {
    formErrors: null,
    initialized: false,
    isCreating: false,
    isFetching: false,
    list: Map()
};

export function persons(state = initialState, action: Action): List<Person> {
    switch (action.type) {
        case ReceiveSearchResultPersonAndCandidate:
        case ReceiveAddSearchResultToJob:
        case ReceiveCandidate:
            if (action.payload.person) {
                return Object.assign({}, state, {
                    list: state.list.set(action.payload.person.id, action.payload.person)
                });
            } else {
                return state;
            }
        case RequestPersonsList:
            return Object.assign({}, state, { isFetching: true });
        case ReceivePersonsList:
            return Object.assign({}, state, {
                initialized: true,
                isFetching: false,
                list: mergeArrayToMap(state.list, action.payload)
            });
        case ReceiveSentDisqualificationMail:
        case ReceiveScheduledDisqualificationMail: {
            const { person } = action.payload;
            if (!person) {
                return state;
            } else {
                return Object.assign({}, state, {
                    list: state.list.set(person.id, person)
                });
            }
        }
        case ReceivePersonOptOutUpdate:
        case ReceivePersonSetBlacklisted:
            return Object.assign({}, state, {
                isFetching: false,
                list: state.list.set(action.payload.person.id, action.payload.person)
            });
        case RequestPersonUpdate: {
            const personRecord = state.list.get(action.id);
            return Object.assign({}, state, {
                list: state.list.set(action.id, Object.assign({}, personRecord, action.updates))
            });
        }
        case ReceivePersonUpdate:
            return Object.assign({}, state, {
                initialized: true,
                isFetching: false,
                list: state.list.set(action.payload.id, action.payload)
            });
        case ReceivePersonDetails:
            return Object.assign({}, state, {
                isFetching: false,
                list: state.list.set(action.payload.details.person.id, action.payload.details.person)
            });
        case ReceivePersonFilesUploadResult:
            return action.payload.errors
                ? state
                : Object.assign({}, state, {
                      isFetching: false,
                      list: state.list.set(action.payload.person.id, action.payload.person)
                  });
        case ReceivePersonJobLabels: {
            const updatedPerson = Object.assign({}, state.list.get(action.payload.personId), {
                jobLabels: action.payload.labels
            });
            return Object.assign({}, state, { list: state.list.set(action.payload.personId, updatedPerson) });
        }
        case ReceiveUpdatePersonFilename: {
            const updatedPerson = Object.assign({}, state.list.get(action.payload.personId), {
                files: action.payload.files
            });
            return Object.assign({}, state, { list: state.list.set(action.payload.personId, updatedPerson) });
        }
        default:
            return state;
    }
}

const personsDetailsInitialState: List<PersonDetails> = {
    list: Map()
};

export function personsDetails(state = personsDetailsInitialState, action: Action): List<PersonDetails> {
    switch (action.type) {
        case ReceivePersonDetails:
            return Object.assign({}, state, {
                isFetching: false,
                list: state.list.set(action.payload.details.person.id, action.payload.details)
            });
        case RequestPersonUpdate: {
            const person = Object.assign({}, state.list.get(action.id).person, action.updates);
            const details = Object.assign({}, state.list.get(action.id), { person });
            return Object.assign({}, state, {
                list: state.list.set(person.id, details)
            });
        }
        case ReceivePersonUpdate: {
            const person = Object.assign({}, state.list.get(action.payload.id).person, action.payload);
            const details = Object.assign({}, state.list.get(action.payload.id), { person });
            return Object.assign({}, state, {
                list: state.list.set(person.id, details)
            });
        }
        case ReceivePersonWebsiteAdd: {
            const person = Object.assign({}, state.list.get(action.payload.person.id).person, action.payload.person);
            const details = Object.assign({}, state.list.get(action.payload.person.id), {
                person,
                profileUrls: action.payload.profileUrls
            });
            return Object.assign({}, state, {
                list: state.list.set(person.id, details)
            });
        }
        case ReceiveProfileLinkUpdate: {
            const details = Object.assign({}, state.list.get(action.payload.personId), {
                profileUrls: action.payload.profileUrls
            });
            return Object.assign({}, state, {
                list: state.list.set(action.payload.personId, details)
            });
        }
        case ReceiveScheduledDisqualificationMail: {
            const { personId, candidates } = action.payload;
            const personDetails = state.list.get(personId);
            if (!personDetails) {
                return state;
            } else {
                const newCandidates = personDetails.candidates
                    .filter((c) => !candidates.find((newCandidate) => newCandidate.jobId === c.jobId))
                    .concat(candidates);
                const newPersonDetails: PersonDetails = {
                    ...personDetails,
                    candidates: newCandidates
                };
                return {
                    ...state,
                    list: state.list.set(personId, newPersonDetails)
                };
            }
        }
        case ReceiveSentDisqualificationMail: {
            const { personId, jobId, candidates, communication } = action.payload;
            if (!communication) {
                return state;
            }
            const personDetails = state.list.get(personId);
            if (!personDetails) {
                return state;
            } else {
                const newCandidates = personDetails.candidates
                    .filter((c) => !candidates.find((newCandidate) => newCandidate.jobId === c.jobId))
                    .concat(candidates);
                const comm: Communication = {
                    ...communication,
                    jobIds: [jobId],
                    labels: communication.labels || [],
                    matchData: {},
                    personIds: [personId]
                };
                const newCommunications = personDetails.communications
                    .filter((c) => c.account !== communication.account && c.account !== communication.messageId)
                    .concat(comm);
                const newPersonDetails: PersonDetails = {
                    ...personDetails,
                    candidates: newCandidates,
                    communications: newCommunications
                };
                return {
                    ...state,
                    list: state.list.set(personId, newPersonDetails)
                };
            }
        }
        case ReceiveReassignCandidateAssignee:
        case ReceiveCandidateAddedToJob: {
            if ((action.payload as any).errors) {
                return state;
            } else {
                const candidate = action.payload.candidate;
                const personDetails = state.list.get(candidate.personId);
                if (!personDetails) {
                    return state;
                } else {
                    const newCandidates = personDetails.candidates.filter((c) => c.jobId !== candidate.jobId);
                    newCandidates.push(candidate);
                    const newPersonDetails = Object.assign({}, personDetails, { candidates: newCandidates });
                    return Object.assign({}, state, {
                        list: state.list.set(candidate.personId, newPersonDetails)
                    });
                }
            }
        }
        case ReceiveCandidateUpdate: {
            if ((action.payload as any).errors) {
                return state;
            } else {
                const candidate = action.payload.candidate;
                const personDetails = state.list.get(candidate.personId);
                if (!personDetails) {
                    return state;
                } else {
                    const newCandidates = personDetails.candidates.filter((c) => c.jobId !== candidate.jobId);
                    newCandidates.push(candidate);

                    const newComms = personDetails.communications;
                    if (action.payload.message) {
                        const comm: Communication = {
                            ...action.payload.message,
                            jobIds: [candidate.jobId],
                            labels: action.payload.message.labels || [],
                            matchData: {},
                            personIds: [candidate.personId]
                        };
                        newComms.push(comm);
                    }

                    const newPersonDetails = Object.assign({}, personDetails, {
                        candidates: newCandidates,
                        communications: newComms
                    });
                    return Object.assign({}, state, {
                        list: state.list.set(candidate.personId, newPersonDetails)
                    });
                }
            }
        }
        case ReceiveCrossAddCandidate: {
            const { candidates, communications } = action.payload;
            const personId = candidates[0].personId;
            const personDetails = state.list.get(personId);
            const newCandidates = uniqBy(candidates.concat(personDetails.candidates), 'jobId');
            const newCommunications = uniqBy(
                communications.concat(personDetails.communications),
                (c) => `${c.account}-${c.messageId}`
            );
            const newPersonDetails = { ...personDetails, candidates: newCandidates, communications: newCommunications };
            return { ...state, list: state.list.set(personId, newPersonDetails) };
        }
        case ReceivePersonSetBlacklisted:
        case ReceivePersonOptOutUpdate: {
            const { candidates, person } = action.payload;
            const personDetails = state.list.get(person.id);
            const updatedCandidateJobIds = Set(candidates.map((x) => x.jobId));
            const unchangedCandidates = personDetails.candidates.filter(
                (x) => !updatedCandidateJobIds.contains(x.jobId)
            );
            const withNewCandidates = unchangedCandidates.concat(candidates);

            const newPersonDetails = Object.assign({}, personDetails, { candidates: withNewCandidates, person });
            return Object.assign({}, state, {
                list: state.list.set(person.id, newPersonDetails)
            });
        }
        case ReceiveContactsUpdate:
        case ReceiveAddContactFromMessage: {
            if (!action.payload.errors && state.list.get(action.payload.personId)) {
                const record = state.list.get(action.payload.personId);
                const communications = record.communications
                    .filter(
                        (c) =>
                            !action.payload.communications.find(
                                (newComm) => c.account === newComm.account && c.messageId === newComm.messageId
                            )
                    )
                    .concat(action.payload.communications);
                const updatedRecord = Object.assign({}, record, {
                    communications,
                    contacts: action.payload.contacts,
                    person: record.person
                });
                return Object.assign({}, state, {
                    list: state.list.set(action.payload.personId, updatedRecord)
                });
            } else {
                return state;
            }
        }
        case ReceivePersonFilesUploadResult: {
            if (action.payload.errors) return state;
            const record = state.list.get(action.payload.person.id);
            const updatedRecord = Object.assign({}, record, {
                person: action.payload.person
            });
            return Object.assign({}, state, {
                list: state.list.set(action.payload.personId, updatedRecord)
            });
        }
        case ReceivePersonJobLabels: {
            const oldRecord = state.list.get(action.payload.personId);
            const updatedRecord = Object.assign({}, oldRecord, {
                person: Object.assign({}, oldRecord.person, { jobLabels: action.payload.labels })
            });
            return Object.assign({}, state, {
                list: state.list.set(action.payload.personId, updatedRecord)
            });
        }
        case ReceiveIgnoreContactFromMessage:
        case ReceiveCommunicationMatchDataUpdate:
        case ReceiveCommunicationMatcher: {
            let newList = state.list;
            for (const comm of action.payload.communications) {
                const { personIds, messageId, account } = comm;
                if (personIds) {
                    for (const personId of personIds) {
                        if (newList.has(personId)) {
                            const oldRecord = newList.get(personId);
                            const oldCommunications = oldRecord.communications;
                            const newCommunications = oldCommunications
                                .filter((c) => !(c.messageId === messageId && c.account === account))
                                .concat([comm])
                                .sort((c1, c2) => c2.internalDate - c1.internalDate);
                            const newRecord = { ...oldRecord, communications: newCommunications };
                            newList = newList.set(personId, newRecord);
                        }
                    }
                }
            }
            return { ...state, list: newList };
        }
        case ReceiveUpdatePersonFilename: {
            const details = state.list.get(action.payload.personId);
            const updatedPerson = Object.assign({}, details.person, {
                files: action.payload.files
            });
            const updatedDetails = Object.assign({}, details, { person: updatedPerson });
            return Object.assign({}, state, { list: state.list.set(action.payload.personId, updatedDetails) });
        }
        case ReceiveUserBlacklistPerson: {
            const { candidates, personId, userBlacklisted } = action.payload;
            const personDetails = state.list.get(personId);
            const updatedCandidateJobIds = Set(candidates.map((x) => x.jobId));
            const unchangedCandidates = personDetails.candidates.filter(
                (x) => !updatedCandidateJobIds.contains(x.jobId)
            );
            const withNewCandidates = unchangedCandidates.concat(candidates);

            const newPersonDetails = Object.assign({}, personDetails, {
                candidates: withNewCandidates,
                userBlacklisted
            });
            return Object.assign({}, state, {
                list: state.list.set(personId, newPersonDetails)
            });
        }
        case RequestContactDelete: {
            const record = state.list.get(action.payload.personId);
            const updatedContacts = record.contacts.filter((c) => c.value !== action.payload.value);
            const updatedRecord = Object.assign({}, record, { contacts: updatedContacts });
            return Object.assign({}, state, { list: state.list.set(action.payload.personId, updatedRecord) });
        }
        default:
            return state;
    }
}
