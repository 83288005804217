import * as _ from 'lodash';
import * as React from 'react';

import { EmailAddress } from 'shared/types/email-compose';

import { Communication } from '../../state';
import { EmailThread } from '../types';

interface EmailThreadParticipantsProps {
    thread: EmailThread;
    account: string;
    selectedLabel: string;
}

export class EmailThreadParticipants extends React.Component<EmailThreadParticipantsProps, undefined> {
    threadVisibleMessages = () => {
        const { thread, selectedLabel } = this.props;
        return thread.messages.filter((m) => {
            switch (selectedLabel) {
                case 'DRAFT':
                    return m.labels.indexOf('DRAFT') !== -1 && m.labels.indexOf('TRASH') === -1;
                case 'TRASH':
                    return m.labels.indexOf('TRASH') !== -1;
                default:
                    return m.labels.indexOf('DRAFT') === -1 && m.labels.indexOf('TRASH') === -1;
            }
        });
    };

    getNameFromEmailAddressHeader(h: EmailAddress, full: boolean) {
        return h.name ? (full ? h.name : h.name.split(/\s/)[0]) : h.address.split('@')[0];
    }

    sentFolderParticipants() {
        const { account } = this.props;
        const names: Map<string, string> = new Map();
        for (const message of this.threadVisibleMessages()) {
            const participants = message.headers.to.concat(message.headers.cc, message.headers.bcc, [
                message.headers.from
            ]);
            for (const recipient of participants) {
                if (recipient.address !== account) {
                    names.set(recipient.address, this.getNameFromEmailAddressHeader(recipient, false));
                }
            }
        }
        if (names.size === 0) {
            names.set(account, 'me');
        }
        const recipientNames: string[] = [];
        names.forEach((v) => recipientNames.push(v));
        const messages = this.threadVisibleMessages();
        const messagesCountIndicator =
            messages.length > 1 ? <span className="messages-count">{messages.length}</span> : null;
        return (
            <div className="email-inbox-thread-senders no-wrap">
                To: {recipientNames.join(', ')} {messagesCountIndicator}
            </div>
        );
    }

    render() {
        const { account, selectedLabel } = this.props;

        if (selectedLabel === 'SENT') return this.sentFolderParticipants();
        if (selectedLabel === 'DRAFT') return <div className="email-inbox-thread-senders no-wrap">Draft</div>;

        const messages = this.threadVisibleMessages();
        const latestMessage = messages[messages.length - 1];
        const nameFromHeader = (c: Communication, full: boolean) =>
            this.getNameFromEmailAddressHeader(c.headers.from, full);
        const senderName = (m: Communication) => {
            const name = m.headers.from.address === account ? 'me' : nameFromHeader(m, false);
            return <span className={m.labels.indexOf('UNREAD') !== -1 ? 'unread' : ''}>{name}</span>;
        };

        const uniqueSenders: Communication[] = [];
        const sendersMap: Map<string, boolean> = new Map();
        for (const message of messages) {
            if (message.labels.indexOf('UNREAD') !== -1 || !sendersMap.has(message.headers.from.address)) {
                sendersMap.set(message.headers.from.address, true);
                if (uniqueSenders.length > 0) {
                    const prevMessage = uniqueSenders[uniqueSenders.length - 1];
                    if (
                        prevMessage.headers.from.address === message.headers.from.address &&
                        (message.labels.indexOf('UNREAD') !== -1) === (prevMessage.labels.indexOf('UNREAD') !== -1)
                    ) {
                        continue;
                    }
                }
                uniqueSenders.push(message);
            }
        }
        const maxSendersToShowAllNames = 2;
        const messagesCountIndicator =
            messages.length > 1 ? <span className="messages-count">{messages.length}</span> : null;
        const senderText =
            uniqueSenders.length === 1 ? (
                <span>
                    <span className={messages[0].labels.indexOf('UNREAD') !== -1 ? 'unread' : ''}>
                        {nameFromHeader(messages[0], true)}
                    </span>{' '}
                    {messagesCountIndicator}
                </span>
            ) : uniqueSenders.length <= maxSendersToShowAllNames ? (
                <span>
                    {senderName(uniqueSenders[0])}, {senderName(uniqueSenders[1])} {messagesCountIndicator}
                </span>
            ) : (
                <span>
                    {senderName(messages[0])} .. {senderName(latestMessage)} {messagesCountIndicator}
                </span>
            );
        return <div className="email-inbox-thread-senders no-wrap">{senderText}</div>;
    }
}
