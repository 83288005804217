import { css } from '@emotion/core';
import { Checkbox, Menu, MenuItem, Theme, useTheme } from '@material-ui/core';
import { Clear, FilterList } from '@material-ui/icons';
import { Map } from 'immutable';
import { identity, noop } from 'lodash';
import * as React from 'react';

const filterMenuStyles = (theme: Theme) => css`
    .MuiMenuItem-root.MuiListItem-gutters {
        padding: 0 14px 0 8px;

        .filter-value {
            display: flex;
            justify-content: space-between;
            flex: 1 1 auto;

            .filter-value-count {
                margin-left: 20px;
            }
        }
    }

    .clear-menu {
        font-style: italic;
        border-bottom: thin solid ${theme.palette.divider};

        &.MuiMenuItem-root.MuiListItem-gutters {
            padding: 8px 15px;
        }

        .MuiSvgIcon-root {
            margin-right: 10px;
        }
    }
`;

interface TableColumnFilterHeaderProps<T> {
    values: T[];
    selected: T[];
    onSelect: (selected: T[]) => void;
    renderValue?: (value: T) => string | number | JSX.Element;
    onFilterOpen?: (open: boolean) => void;
}

export function TableColumnFilterHeader<T>({
    values,
    selected,
    onSelect,
    onFilterOpen = noop,
    renderValue = identity
}: TableColumnFilterHeaderProps<T>) {
    const theme = useTheme();
    const [menuAnchor, setMenuAnchor] = React.useState(null);

    const handleClickFilter = (event: React.MouseEvent) => {
        setMenuAnchor(event.target);
        onFilterOpen(true);
    };

    const handleClearFilter = () => {
        onSelect([]);
    };

    const handleFilterClose = () => {
        setMenuAnchor(null);
        onFilterOpen(false);
    };

    const handleFilterOptionClick = (opt: T, checked: boolean) => () => {
        const updated = checked ? selected.concat([opt]) : selected.filter((v) => v !== opt);
        onSelect(updated);
    };

    let valuesWithCounts = Map<T, number>();
    for (const value of values) {
        if (value !== undefined && value !== null) {
            valuesWithCounts = valuesWithCounts.set(value, valuesWithCounts.get(value, 0) + 1);
        }
    }
    valuesWithCounts = valuesWithCounts.sortBy((_1, k) => k);
    const menuItems = valuesWithCounts
        .map((val, opt) => {
            const checked = selected.indexOf(opt) !== -1;
            return (
                <MenuItem key={opt + '-' + val} dense={true} onClick={handleFilterOptionClick(opt, !checked)}>
                    <Checkbox checked={checked} />
                    <div className="filter-value">
                        <div className="filter-value-name">{renderValue(opt)}</div>
                        <div className="filter-value-count">{val}</div>
                    </div>
                </MenuItem>
            );
        })
        .valueSeq();

    return (
        <>
            <span className={`column-action-icon ${selected.length > 0 ? 'visible' : ''}`} onClick={handleClickFilter}>
                <FilterList />
            </span>
            <Menu open={!!menuAnchor} anchorEl={menuAnchor} onClose={handleFilterClose} css={filterMenuStyles(theme)}>
                <MenuItem dense={true} onClick={handleClearFilter} className="clear-menu">
                    <Clear />
                    Clear All
                </MenuItem>
                {menuItems}
            </Menu>
        </>
    );
}
