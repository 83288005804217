import { Map } from 'immutable';
import { RaisedButton } from 'material-ui';
import React from 'react';
import { connect } from 'react-redux';

import { awaitingAMApproval } from 'shared/models/job-stages';

import { moveCandidateToStage } from '../actions';
import { Candidate, RequestErrors, State } from '../state';

interface OwnProps {
    candidate: Candidate;
}

interface ConnectedProps {
    pendingRequests: Map<string, RequestErrors>;
}

interface ConnectedDispatch {
    moveCandidateToStage: (candidate: Candidate, stage: string) => void;
}

type SubmissionNoteResubmitProps = OwnProps & ConnectedProps & ConnectedDispatch;

const SubmissionNoteResubmitComponent: React.FC<SubmissionNoteResubmitProps> = (props) => {
    const handleClick = () => {
        props.moveCandidateToStage(props.candidate, awaitingAMApproval);
    };

    const disabled = props.pendingRequests.has(`candidate-update-${props.candidate.personId}-${props.candidate.jobId}`);

    return (
        <RaisedButton
            className="action-button"
            primary={true}
            label="Resubmit for Approval"
            onClick={handleClick}
            disabled={disabled}
        />
    );
};

const mapStateToProps = (state: State) => ({
    pendingRequests: state.pendingRequests
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    moveCandidateToStage
};

export const SubmissionNoteResubmit = connect<{}, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(SubmissionNoteResubmitComponent);
