import { Map } from 'immutable';

import { Action, ReceiveJobAssigneeData } from '../actions';
import { JobAssigneeData } from '../state';

const initialState: Map<string, JobAssigneeData> = Map();

export function jobAssigneeData(state = initialState, action: Action): Map<string, JobAssigneeData> {
    switch (action.type) {
        case ReceiveJobAssigneeData:
            return state.set(action.payload.id, action.payload.data);
        default:
            return state;
    }
}
