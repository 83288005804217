import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { impersonate } from '../api';
import { setLocalStorageKey } from '../common/local-storage';
import { config } from '../config';
import { Loading } from '../core-ui/loading';

type RouterConnectedProps = RouteComponentProps<{ email: string }>;

const ImpersonateComponent: React.FC<RouterConnectedProps> = (props) => {
    React.useEffect(() => {
        const { email } = props.match.params;
        impersonate(email).then((result) => {
            const { session } = result.data;
            setLocalStorageKey('session', { authToken: session.authToken }, -1);
            window.location.href = config.AppUrl;
        });
    }, []);

    return <Loading />;
};

export const Impersonate = withRouter(ImpersonateComponent);
