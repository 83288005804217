import * as _ from 'lodash';
import * as React from 'react';

import { getLabelDisplay } from '../common';
import { EmailInboxData } from '../types';

interface EmailInboxProps {
    data: EmailInboxData;
    onLabelSelect: (label: string) => void;
}

const icons: { [key: string]: string } = {
    ALL: 'email',
    DRAFT: 'note',
    INBOX: 'inbox',
    SENT: 'send',
    SNOOZED: 'access_time',
    SPAM: 'error',
    STARRED: 'star',
    TRASH: 'delete'
};

export class EmailInboxLabels extends React.Component<EmailInboxProps, undefined> {
    handleLabelSelect = (label: string) => () => {
        this.props.onLabelSelect(label);
    };

    render() {
        const { data } = this.props;
        if (!data || !data.labels) {
            return null;
        }
        const labels = _.orderBy(
            data.labels.filter(
                (label) =>
                    label.id === 'TRASH' ||
                    label.id === 'SPAM' ||
                    !label.labelListVisibility ||
                    label.labelListVisibility !== 'labelHide'
            ),
            [
                (l) => l.type === 'system',
                (l) => {
                    const definedSort = ['INBOX', 'STARRED', 'SNOOZED', 'SENT', 'DRAFT', 'ALL', 'TRASH', 'SPAM'];
                    const definedSortIndex = definedSort.indexOf(l.id);
                    if (definedSortIndex !== -1) return definedSortIndex;
                    return definedSort.length;
                },
                (l) => l.threadsUnread,
                (l) => l.name
            ],
            ['desc', 'asc', 'desc']
        ).map((label) => {
            const classNames = ['email-inbox-label'];
            const labelsSkipEmphasis = ['TRASH', 'STARRED'];
            const labelEmphasis =
                (label.threadsUnread > 0 && labelsSkipEmphasis.indexOf(label.id) === -1) ||
                (label.id === 'DRAFT' && label.threadsTotal > 0);
            const labelDisplayCount = label.id === 'DRAFT' ? label.threadsTotal : label.threadsUnread;
            if (label.id === data.selectedLabel) {
                classNames.push('email-inbox-label-selected');
            }
            if (labelEmphasis) {
                classNames.push('email-inbox-label-unreads'); // cspell:disable-line
            }
            const labelDisplayText = <div className="email-inbox-label-name">{getLabelDisplay(label)}</div>;
            const labelUnreadCount = labelEmphasis ? (
                <div className="email-inbox-label-unread-count">{labelDisplayCount}</div>
            ) : null;
            return (
                <div className={classNames.join(' ')} key={label.id} onClick={this.handleLabelSelect(label.id)}>
                    <div className="email-inbox-label-icon">
                        <i className="material-icons">{icons[label.id] ? icons[label.id] : 'label'}</i>
                    </div>
                    <div className="email-inbox-label-text">
                        {labelDisplayText}
                        {labelUnreadCount}
                    </div>
                </div>
            );
        });
        return <div className="email-inbox-labels email-inbox-vscroll">{labels}</div>; // cspell:disable-line
    }
}
