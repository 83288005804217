import { useSubscription } from '@apollo/client';
import * as React from 'react';

import { EmailAccountsTable as EmailAccountsTableComponent } from '../components/email-accounts-table';
import { Spinner } from '../core-ui/spinner';
import { EMAIL_ACCOUNTS, EmailAccount } from '../graphql/queries/email-accounts-table';

export const EmailAccountsTable: React.FC<{}> = () => {
    const { data } = useSubscription<{ email_accounts: EmailAccount[] }>(EMAIL_ACCOUNTS);
    if (data) {
        return <EmailAccountsTableComponent emailAccounts={data.email_accounts} />;
    } else {
        return <Spinner />;
    }
};
