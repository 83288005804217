import { Map } from 'immutable';

import { Action, ReceiveJobsSearchesStats, RequestJobsSearchesStats } from '../actions';
import { JobSearchesStats, List } from '../state';

const initialState: List<JobSearchesStats> = {
    formErrors: null,
    initialized: false,
    isCreating: false,
    isFetching: false,
    list: Map()
};

export function jobsSearchesStats(state = initialState, action: Action): List<JobSearchesStats> {
    switch (action.type) {
        case RequestJobsSearchesStats:
            return Object.assign({}, state, { isFetching: true });
        case ReceiveJobsSearchesStats:
            const { stats } = action.payload;
            const list = Map(stats);
            return Object.assign({}, state, { initialized: true, isFetching: false, list });
            return state;
        default:
            return state;
    }
}
