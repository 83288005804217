import React from 'react';

import { Interview } from '../../graphql/queries/home';
import { Interviews } from './interviews';

interface ClientRoundsProps {
    interviews: Interview[];
}

const rowsPerPage = 10;

export const ClientRounds: React.FC<ClientRoundsProps> = (props) => {
    const interviews = props.interviews ? props.interviews.filter((i) => i.kind !== 'Rocket Screen') : undefined;
    return <Interviews interviews={interviews} title="Client Rounds" rowsPerPage={rowsPerPage} showIfEmpty={false} />;
};
