import { css } from '@emotion/core';
import { FolderOutlined, LinkedIn } from '@material-ui/icons';
import React from 'react';

import { Tooltip } from '@material-ui/core';
import { standardizeUrl } from 'profile-parser';

const optionStyle = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .option-info {
        margin-left: 10px;
        flex: 0 0 auto;
        display: flex;
        gap: 5px;
        align-items: center;
    }

    .link-icon {
        cursor: pointer;
        display: flex;
        align-items: center;
    }
`;

export const SearchAutocompleteOption: React.FC<{
    option: { count?: number; value?: string; label?: string; presetId?: string };
}> = ({ option }) => {
    const handleLinkClick = (url: string) => (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        const formattedUrl = 'https://' + standardizeUrl(url).replace(/\/recruiter\//, '/');
        window.open(formattedUrl, '_blank');
    };
    const link = option.value?.match(/www.linkedin.com\//) ? (
        <div className="link-icon" onClick={handleLinkClick(option.value)}>
            <Tooltip title="Open in LinkedIn">
                <LinkedIn fontSize="small" />
            </Tooltip>
        </div>
    ) : null;
    const optionCount = option.count ? <div>{option.count?.toLocaleString()}</div> : null;
    const preset = option.presetId ? (
        <div className="link-icon">
            <Tooltip title="Preset">
                <FolderOutlined fontSize="small" />
            </Tooltip>
        </div>
    ) : null;
    return (
        <div css={optionStyle}>
            <div>{option.label ?? option.value}</div>
            <div className="option-info">
                {optionCount}
                {preset}
                {link}
            </div>
        </div>
    );
};

export const renderOption = (option: { count?: number; value?: string; label?: string }) => {
    return <SearchAutocompleteOption option={option} />;
};
