import * as React from 'react';
import { Redirect } from 'react-router';

import { Filters } from 'shared/types/candidates-search';
import { CandidatesSearch } from './candidates-search';

export const CandidatesSearchUserPreset: React.FC<{ filters: Partial<Filters>; title: string }> = (props) => {
    const { filters, title } = props;
    const [redirect, setRedirect] = React.useState(false);

    const handleRedirect = () => {
        setRedirect(true);
    };
    let presetFilters: Filters = {
        assignees: [],
        blacklistedOnly: false,
        csDegreeOnly: false,
        currentCompany: [],
        disciplines: [],
        diversity: [],
        excludedClients: [],
        favoritesOnly: false,
        keywords: [],
        locations: [],
        minYearsExp: '',
        profileGroup: [],
        progress: '',
        schoolRank: '',
        specificJobs: [],
        tags: [],
        timeWindow: '',
        visas: []
    };

    presetFilters = { ...presetFilters, ...filters };

    if (redirect) {
        const redirectLink = '/candidates/search?filters='.concat(JSON.stringify(presetFilters));
        return <Redirect to={redirectLink} push={true} />;
    }

    return <CandidatesSearch initFilters={presetFilters} userPage={true} title={title} redirect={handleRedirect} />;
};
