import { Avatar } from 'material-ui';
import * as React from 'react';

import { EmailAddress as EmailAddressData } from 'shared/types/email-compose';

const avatarSize = 32;

export const EmailAddress: React.SFC<{ address: EmailAddressData }> = ({ address }) => {
    const avatarText = (address.name ? address.name.charAt(0) : address.address.charAt(0)).toLocaleUpperCase();
    const name = address.name ? <div className="primary">{address.name}</div> : null;
    return (
        <div className="email-address">
            <div className="email-address-avatar">
                <Avatar size={avatarSize}>{avatarText}</Avatar>
            </div>
            <div className="email-address-text">
                {name}
                <div className="secondary">{address.address}</div>
            </div>
        </div>
    );
};
