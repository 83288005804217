import { css } from '@emotion/core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    TextField,
    Theme,
    Typography,
    useTheme
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { startCase } from 'lodash';
import React, { createContext, FC } from 'react';

import { ModelParameters } from 'shared/common/llm';
import { Score } from 'shared/models/profile-score';
import { hasRole } from 'shared/models/user';

import { downloadFile } from '../api';
import { config } from '../config';
import { ProfileScore } from '../graphql/queries/profile-score';
import { useModal } from '../hooks/use-modal';
import { usePromptInfo } from '../hooks/use-prompt-info';
import { useSession } from '../hooks/use-session';

interface ScoreDetails {
    args: ModelParameters & { messages: Array<{ role: 'system' | 'user'; content: string }> };
    parsedResponse: { scores: Score[] };
}

const scoreDetailsStyle = (theme: Theme) => css`
    .MuiExpansionPanel-root {
        box-shadow: none;
    }

    .MuiExpansionPanelSummary-root.Mui-expanded {
        min-height: 48px;
    }

    .MuiExpansionPanelSummary-content.Mui-expanded {
        margin: 10px 0;
    }

    .MuiExpansionPanel-root.Mui-expanded {
        border-top: thin solid ${theme.palette.divider};
        border-bottom: thin solid ${theme.palette.divider};
        margin: 0;

        &:first-of-type {
            border-top: none;
        }

        &:last-of-type {
            border-bottom: none;
        }
    }

    .Mui-expanded .MuiExpansionPanelDetails-root {
        /* border-top: thin solid ${theme.palette.divider}; */
    }

    .score-analysis {
        margin-top: 20px;
        border: thin solid ${theme.palette.divider};
        border-radius: ${theme.shape.borderRadius}px;
    }

    .field-title {
        flex: 1 1 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .field-scores {
            display: flex;
            gap: 15px;
        }
        .field-weight {
            color: #666;
            font-size: 0.8em;
        }
        .field-score {
            color: #666;
            font-size: 0.8em;
            font-weight: 500;
            width: 75px;
            text-align: right;
        }
    }
`;

interface AIProfileScoreDetailsContextType {
    showScoreDetails: (score: ProfileScore) => void;
}

const AIProfileScoreDetailsContext = createContext<AIProfileScoreDetailsContextType | undefined>(undefined);

export const AIProfileScoreDetailsProvider: FC = ({ children }) => {
    const [score, setScore] = React.useState<ProfileScore>(null);
    const [details, setDetails] = React.useState<ScoreDetails>(null);
    const { showPromptInfo } = usePromptInfo();
    const { setAlert, showLoading, hideLoading } = useModal();
    const { userPermissions } = useSession();
    const theme = useTheme();

    const handleShowPromptInfo = () => {
        const {
            args: { messages, ...modelParameters }
        } = details;
        const systemPrompt = messages.find((m) => m.role === 'system')?.content;
        const userPrompt = messages.find((m) => m.role === 'user')?.content;
        showPromptInfo({
            requestParameters: modelParameters,
            systemPrompt,
            userPrompt
        });
    };

    const formatScore = (val: number | string) => {
        const num = Number(val);
        return num.toFixed(1);
    };

    const handleCloseDialog = () => {
        setDetails(null);
    };

    const getDetailsDialog = () => {
        const showPromptButton = hasRole(userPermissions, 'prompts_editor') ? (
            <Button onClick={handleShowPromptInfo}>Show Prompt Info</Button>
        ) : null;
        const personScore = details?.parsedResponse.scores.find((s) => s.personId === score.personId);
        const analysisFields: JSX.Element[] = [];
        if (personScore?.fullAnalysis) {
            if (Array.isArray(personScore.fullAnalysis)) {
                personScore.fullAnalysis.forEach((item) => {
                    analysisFields.push(
                        <ExpansionPanel key={item.category} defaultExpanded={false}>
                            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                                <div className="field-title">
                                    <div className="field-name">{startCase(item.category)}</div>
                                    <div className="field-scores">
                                        <div className="field-weight">Weight: {item.weight}</div>
                                        <div className="field-score">Score: {formatScore(item.score)}</div>
                                    </div>
                                </div>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography variant="body1" component="div">
                                    {item.justification}
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    );
                });
            } else if (typeof personScore.fullAnalysis === 'object') {
                for (const key of Object.keys(personScore?.fullAnalysis)) {
                    const field = personScore?.fullAnalysis as any;
                    if (!field || !field.justification || !field.weight) {
                        continue;
                    }
                    const scoreField = field.hasOwnProperty('score')
                        ? `Score: ${formatScore((field as any).score)}`
                        : field.scoreBoost !== undefined
                          ? `Boost: ${formatScore(field.scoreBoost)}`
                          : field.scorePenalty !== undefined
                            ? `Penalty: ${formatScore(field.scorePenalty)}`
                            : '';
                    analysisFields.push(
                        <ExpansionPanel key={key} defaultExpanded={false}>
                            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                                <div className="field-title">
                                    <div className="field-name">{startCase(key)}</div>
                                    <div className="field-scores">
                                        <div className="field-weight">Weight: {field.weight}</div>
                                        <div className="field-score">{scoreField}</div>
                                    </div>
                                </div>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography variant="body1" component="div">
                                    {field.justification}
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    );
                }
            }
        }
        const analysisFieldsList = analysisFields.length ? (
            <div className="score-analysis">{analysisFields}</div>
        ) : null;
        return (
            <Dialog
                open={true}
                onClose={handleCloseDialog}
                maxWidth="md"
                fullWidth={true}
                css={scoreDetailsStyle(theme)}
            >
                <DialogTitle>
                    <Typography variant="h4" component="div">
                        Score Details: {formatScore(personScore?.score)}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        value={personScore?.explanation}
                        label="Explanation"
                        fullWidth={true}
                        multiline={true}
                        variant="outlined"
                        InputProps={{ readOnly: true }}
                    />
                    {analysisFieldsList}
                </DialogContent>
                <DialogActions>
                    {showPromptButton}
                    <Button onClick={handleCloseDialog}>Close</Button>
                </DialogActions>
            </Dialog>
        );
    };

    const showScoreDetails = async (profileScore: ProfileScore & { user?: { id: string; name?: string } }) => {
        if (!profileScore.generationDetails) {
            if (profileScore?.user?.name && profileScore?.user?.id !== config.SystemUserId) {
                setAlert('User Rated', `Rated by ${profileScore.user.name}`);
            } else {
                setAlert('Error', 'Scoring details not available');
            }
        } else {
            try {
                showLoading();
                const data = await downloadFile(profileScore.generationDetails);
                const fetchedDetails = await data.json();
                setDetails(fetchedDetails);
                setScore(profileScore);
                hideLoading();
            } catch (e) {
                hideLoading();
                setAlert('Error', 'Failed to fetch detailed score explanation');
            }
        }
    };

    const dialog = details && score ? getDetailsDialog() : null;

    return (
        <AIProfileScoreDetailsContext.Provider value={{ showScoreDetails }}>
            {children}
            {dialog}
        </AIProfileScoreDetailsContext.Provider>
    );
};

export const useAIProfileScoreDetails = () => {
    const context = React.useContext(AIProfileScoreDetailsContext);
    if (!context) {
        throw new Error('useAIProfileScoreDetails must be used within AIProfileScoreDetailsProvider');
    }
    return context;
};
