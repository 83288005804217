import { css } from '@emotion/core';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React from 'react';
import { isEmail } from 'validator';

import { TextField } from '../core-ui/form-fields';
import { useFormContextData } from './form-context';

interface CandidateData {
    firstName: string;
    lastName: string;
    email: string;
}

const defaultData: CandidateData = {
    email: '',
    firstName: '',
    lastName: ''
};

const styles = css`
    .form-field-row {
        display: flex;
        margin-bottom: 40px;
        gap: 32px;

        &:last-child {
            margin-bottom: 30px;
        }
    }
`;

export const AddCandidateWithoutLinkedin: React.FC<{
    onSave: (data: { firstName: string; lastName: string; email: string }) => Promise<void>;
    onClose: () => void;
    disabled: boolean;
}> = ({ onSave, onClose, disabled }) => {
    const { data, onFieldChange, validateFieldExists, getError, setError } =
        useFormContextData<CandidateData>(defaultData);

    const validateFields = () => {
        let valid = true;
        for (const field of ['firstName', 'lastName', 'email'] as Array<keyof CandidateData>) {
            if (!validateFieldExists(field)(data[field])) {
                setError(field, 'Required');
                valid = false;
            }
        }
        valid = validateEmail(data.email) && valid;
        return valid;
    };

    const validateEmail = (email: string) => {
        if (!isEmail(email)) {
            setError('email', 'Please enter a valid email address');
            return false;
        } else {
            setError('email', '');
            return true;
        }
    };

    const handleSave = async () => {
        if (!validateFields()) {
            return;
        }
        await onSave(data);
    };

    return (
        <Dialog open={true} maxWidth="sm" fullWidth={true} css={styles}>
            <DialogTitle>
                <Typography variant="h4" component="div">
                    Add Candidate without Linkedin
                </Typography>
            </DialogTitle>
            <DialogContent>
                <div className="form-field-row">
                    <TextField
                        inputProps={{ autoComplete: 'new-password' }}
                        label="First Name"
                        value={data.firstName}
                        onChange={onFieldChange('firstName')}
                        disabled={disabled}
                        validate={validateFieldExists('firstName')}
                        errorText={getError('firstName')}
                    />
                    <TextField
                        inputProps={{ autoComplete: 'new-password' }}
                        label="Last Name"
                        value={data.lastName}
                        onChange={onFieldChange('lastName')}
                        disabled={disabled}
                        validate={validateFieldExists('lastName')}
                        errorText={getError('lastName')}
                    />
                </div>
                <div className="form-field-row">
                    <TextField
                        inputProps={{ autoComplete: 'new-password' }}
                        label="Email Address"
                        value={data.email}
                        disabled={disabled}
                        onChange={onFieldChange('email')}
                        validate={validateEmail}
                        errorText={getError('email')}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button disabled={disabled} onClick={onClose}>
                    Close
                </Button>
                <Button disabled={disabled} onClick={handleSave}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
