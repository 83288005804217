import { emailContainerClass, emailQuotesClass } from 'shared/common/email-markup-constants';

function emailQuotesClickHandler(event: Event) {
    const target: HTMLElement = event.target as HTMLElement;
    if (target.className === emailQuotesClass) {
        const container = target.closest(`.${emailContainerClass}`);
        const quotedElements = container.querySelectorAll('blockquote, .gmail_quote');
        if (quotedElements.length > 0) {
            const hiddenStyle = 'display: none';
            const visibleStyle = 'display: block';
            const style = quotedElements[0].getAttribute('style') === hiddenStyle ? visibleStyle : hiddenStyle;
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < quotedElements.length; i++) {
                quotedElements[i].setAttribute('style', style);
            }
        }
    }
}

document.addEventListener('click', emailQuotesClickHandler);
