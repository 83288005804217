import { isEqual } from 'lodash';
import React, { createContext, ReactNode, useContext } from 'react';

import { SearchConfig } from 'shared/models/search';

import { useLocalStorage } from '../hooks/use-local-storage';
import { useModal } from '../hooks/use-modal';
import { useSession } from '../hooks/use-session';
import { searchDefaults } from './search-defaults';
import { useSearch } from './use-search';

export type SearchConfigSection =
    | 'logistics'
    | 'work'
    | 'companies'
    | 'education'
    | 'education-bootcamp'
    | 'keywords'
    | 'inferred-skills'
    | 'diversity'
    | 'rocket-progress'
    | 'client-employees'
    | 'linkedin-search';

export const allSections: SearchConfigSection[] = [
    'logistics',
    'work',
    'companies',
    'education',
    'education-bootcamp',
    'keywords',
    'inferred-skills',
    'diversity',
    'rocket-progress',
    'client-employees',
    'linkedin-search'
];

export const defaultEnabledSections: SearchConfigSection[] = [
    'logistics',
    'work',
    'companies',
    'education',
    'keywords'
];

function sectionTitleFn(section: SearchConfigSection): string {
    switch (section) {
        case 'logistics':
            return 'Logistics';
        case 'work':
            return 'Work Experience';
        case 'companies':
            return 'Companies';
        case 'education':
            return 'Education';
        case 'education-bootcamp':
            return 'Bootcamp';
        case 'keywords':
            return 'Keywords/Skills';
        case 'inferred-skills':
            return 'Inferred Skills';
        case 'diversity':
            return 'Diversity';
        case 'rocket-progress':
            return 'Rocket Progress';
        case 'client-employees':
            return "Client's Employees ex co-workers";
        case 'linkedin-search':
            return 'LinkedIn Search';
        default:
            return '';
    }
}

function isSectionEmptyFn(config: SearchConfig, section: SearchConfigSection, defaultConfig: SearchConfig): boolean {
    // only use isEqual for default config properties if the default does not Add constraints
    // else do explicit checks for each relevant property
    switch (section) {
        case 'logistics':
            return !config.general.locations.length && !config.general.visa.length;
        case 'work': {
            const { companies, consumerEnterprise, ...rest } = config.experience;
            return !Object.values(rest).length;
        }
        case 'companies':
            return !config.experience.companies.length && !config.similarityModel.length;
        case 'education':
            return !config.education || isEqual(config.education, defaultConfig.education);
        case 'education-bootcamp':
            return config.education?.bootcampOk === undefined || config.education?.bootcampOk === true;
        case 'keywords':
            return (
                !config.keywordGroups.length ||
                isEqual(config.keywordGroups, defaultConfig.keywordGroups) ||
                !config.keywordGroups.some((g) => g.keywords.length)
            );
        case 'inferred-skills':
            return !config.inferenceGroups.length || isEqual(config.inferenceGroups, defaultConfig.inferenceGroups);
        case 'diversity':
            return !config.diversity || isEqual(config.diversity, defaultConfig.diversity);
        case 'rocket-progress':
            return (
                !config.positiveResponse ||
                !config.positiveResponse.enabled ||
                isEqual(config.positiveResponse, defaultConfig.positiveResponse)
            );
        case 'client-employees':
            return (
                !config.clientEmployees ||
                !config.clientEmployees?.enabled ||
                isEqual(config.clientEmployees, defaultConfig.clientEmployees)
            );
        case 'linkedin-search':
            return !config.linkedinSearch || isEqual(config.linkedinSearch, defaultConfig.linkedinSearch);
        default:
            return false;
    }
}

function removeSectionFn(config: SearchConfig, section: SearchConfigSection): SearchConfig {
    switch (section) {
        case 'logistics':
            return { ...config, general: { ...config.general, locations: [], visa: [] } };
        case 'work':
            return {
                ...config,
                experience: {
                    companies: config.experience.companies,
                    consumerEnterprise: config.experience.consumerEnterprise
                },
                general: { ...config.general, disciplines: [] }
            };
        case 'companies':
            return { ...config, experience: { ...config.experience, companies: [] }, similarityModel: [] };
        case 'education':
            return { ...config, education: null };
        case 'education-bootcamp':
            return { ...config, education: { ...config.education, bootcampOk: undefined } };
        case 'keywords':
            return { ...config, keywordGroups: [] };
        case 'inferred-skills':
            return { ...config, inferenceGroups: [] };
        case 'diversity':
            return { ...config, diversity: null };
        case 'rocket-progress':
            return { ...config, positiveResponse: null };
        case 'client-employees':
            return { ...config, clientEmployees: null };
        case 'linkedin-search':
            return { ...config, linkedinSearch: null };
        default:
            return config;
    }
}

function addSectionFn(config: SearchConfig, section: SearchConfigSection, defaultConfig: SearchConfig): SearchConfig {
    switch (section) {
        case 'logistics': {
            const { locations, visa } = defaultConfig.general;
            return { ...config, general: { ...config.general, locations, visa } };
        }
        case 'work': {
            const { companies, consumerEnterprise, ...rest } = defaultConfig.experience;
            const { disciplines } = defaultConfig.general;
            return {
                ...config,
                experience: {
                    companies: config.experience.companies,
                    consumerEnterprise: config.experience.consumerEnterprise,
                    ...rest
                },
                general: { ...config.general, disciplines }
            };
        }
        case 'companies': {
            const { companies } = defaultConfig.experience;
            const { similarityModel } = defaultConfig;
            return { ...config, experience: { ...config.experience, companies }, similarityModel };
        }
        case 'education':
            return { ...config, education: defaultConfig.education };
        case 'education-bootcamp':
            return { ...config, education: { ...config.education, bootcampOk: defaultConfig.education.bootcampOk } };
        case 'keywords':
            return { ...config, keywordGroups: defaultConfig.keywordGroups };
        case 'inferred-skills':
            return { ...config, inferenceGroups: defaultConfig.inferenceGroups };
        case 'diversity':
            return { ...config, diversity: defaultConfig.diversity };
        case 'rocket-progress':
            return { ...config, positiveResponse: defaultConfig.positiveResponse };
        case 'client-employees':
            return { ...config, clientEmployees: defaultConfig.clientEmployees };
        case 'linkedin-search':
            return { ...config, linkedinSearch: defaultConfig.linkedinSearch };
        default:
            return config;
    }
}

interface SearchSectionsContextType {
    sectionTitle: (section: SearchConfigSection) => string;
    isSectionEmpty: (section: SearchConfigSection) => boolean;
    removeSection: (section: SearchConfigSection) => void;
    addSection: (section: SearchConfigSection) => void;
    enabledSections: SearchConfigSection[];
    setEnabledSections: (sections: SearchConfigSection[]) => void;
}

const SearchSectionsContext = createContext<SearchSectionsContextType | undefined>(undefined);

export const SearchSectionsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const localStorageKey = 'searchConfigSections';
    const { data, onFieldChange: onChange, job, client } = useSearch();
    const { user } = useSession();
    const [defaultSearchConfig] = React.useState(searchDefaults(user, job, client, data.project).config);
    const [enabledSections, setEnabledSections] = useLocalStorage(localStorageKey, defaultEnabledSections);
    const { getConfirmation } = useModal();

    const sectionTitle = sectionTitleFn;

    const isSectionEmpty = (section: SearchConfigSection) =>
        isSectionEmptyFn(data.config, section, defaultSearchConfig);

    const removeSection = (section: SearchConfigSection) => {
        getConfirmation(
            () => {
                setEnabledSections(enabledSections.filter((s) => s !== section));
                onChange('config')(removeSectionFn(data.config, section));
            },
            <span>
                <span>Are you sure you want to remove the</span> <b>{sectionTitle(section)}</b>{' '}
                <span>section? This will remove all search settings from this section.</span>
            </span>,
            'Remove Section'
        );
    };

    const addSection = (section: SearchConfigSection) => {
        if (enabledSections.includes(section)) {
            return;
        }
        setEnabledSections(allSections.filter((s) => s === section || enabledSections.includes(s)));
        onChange('config')(addSectionFn(data.config, section, defaultSearchConfig));
    };

    return (
        <SearchSectionsContext.Provider
            value={{ sectionTitle, isSectionEmpty, addSection, removeSection, enabledSections, setEnabledSections }}
        >
            {children}
        </SearchSectionsContext.Provider>
    );
};

export const useSearchSections = () => {
    const context = useContext(SearchSectionsContext);
    if (!context) {
        throw new Error('useSearchSections must be used within a SearchSectionsProvider');
    }
    return context;
};
