import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { JobEdit } from '../containers/job-edit';

interface RouterProps {
    id: string;
}

const JobEditPageComponent: React.SFC<RouteComponentProps<RouterProps>> = ({ match }) => (
    <JobEdit id={match.params.id} />
);

export const JobEditPage = withRouter(JobEditPageComponent);
