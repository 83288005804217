import { useMutation, useQuery } from '@apollo/client';
import { IconButton } from 'material-ui';
import * as React from 'react';

import { UserFavoriteData } from 'shared/models/user-favorites';

import {
    CREATE_USER_FAVORITE,
    DELETE_USER_FAVORITE,
    GET_USER_FAVORITES,
    MutateUserFavoriteVariables,
    UserFavoritesData,
    UserFavoritesVariables
} from '../graphql/queries/user-favorites';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';

export const PersonFavorite: React.FC<{ personId: string }> = ({ personId }) => {
    const session = useSession();
    const { setSnackbar } = useSnackbar();
    const userId = session.user.id;
    const {
        loading,
        error,
        data: userFavoritesData,
        refetch
    } = useQuery<UserFavoritesData, UserFavoritesVariables>(GET_USER_FAVORITES, { variables: { userId } });

    const [addUserFavorite, { loading: addPending }] = useMutation<
        { users_favorites: UserFavoriteData },
        MutateUserFavoriteVariables
    >(CREATE_USER_FAVORITE, {
        variables: {
            personId,
            userId
        }
    });

    const [deleteUserFavorite, { loading: deletePending }] = useMutation<
        { users_favorites: UserFavoriteData },
        MutateUserFavoriteVariables
    >(DELETE_USER_FAVORITE, {
        variables: {
            personId,
            userId
        }
    });

    const isUserFavorite = !loading && !error && userFavoritesData.users_favorites.find((u) => u.personId === personId);

    const handleClick = async () => {
        if (!isUserFavorite) {
            setSnackbar('Adding as favorite');
            await addUserFavorite();
            setSnackbar('Added as favorite');
        } else {
            setSnackbar('Removing from favorites');
            await deleteUserFavorite();
            setSnackbar('Removed from favorites');
        }
        refetch();
    };

    const icon = isUserFavorite ? 'star' : 'star_outline';

    return (
        <IconButton
            tooltip="Favorite"
            iconStyle={{ color: 'white' }}
            onClick={handleClick}
            className={`header-candidate-action ${isUserFavorite || addPending || deletePending ? 'show' : ''}`}
            disabled={addPending || deletePending}
        >
            <i className="material-icons">{icon}</i>
        </IconButton>
    );
};
