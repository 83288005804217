import * as EmailMarkupLib from 'shared/common/email-markup';
import { CommunicationData } from 'shared/models/communication';

export const getHeaderValue = (headers: Array<{ name: string; value: string }>) => (name: string) =>
    EmailMarkupLib.getHeaderValue(headers)(name);

export const insertEmailMarkup = (
    base64Content: string,
    emailContainer: HTMLElement,
    forwarded: boolean,
    emailContentBlacklistedDomains: string,
    attachments: Array<{
        path: string;
        contentId: string;
    }>
) =>
    EmailMarkupLib.insertEmailMarkup(
        window,
        base64Content,
        emailContainer,
        forwarded,
        emailContentBlacklistedDomains,
        attachments
    );

export const emailReplyData = (data: CommunicationData, replyAll: boolean, emailContentBlacklistedDomains: string) =>
    EmailMarkupLib.emailReplyData(window, data, replyAll, emailContentBlacklistedDomains);

export const emailForwardData = (data: CommunicationData, emailContentBlacklistedDomains: string) =>
    EmailMarkupLib.emailForwardData(window, data, emailContentBlacklistedDomains);
