import { Map } from 'immutable';

import { UserData } from 'shared/models/user';
import { EmailAddress } from 'shared/types/email-compose';

import { EmailAccount } from '../../state';

export function getAccountOptions(
    emailAccounts: Map<string, EmailAccount>,
    user: UserData,
    jobId: string,
    defaultAccount?: EmailAddress
) {
    // tslint:disable:no-magic-numbers
    const accounts = emailAccounts
        .valueSeq()
        .filter(
            (e) =>
                e.owners.indexOf(user.id) !== -1 &&
                e.syncStatus === 'enabled' &&
                e.messageProcessorEnabled &&
                ((jobId && e.jobId === jobId) || (!e.jobId && e.name.full === user.name.full))
        )
        .sort((acc1, acc2) => (acc1.email === defaultAccount?.address ? -2 : acc1.email.localeCompare(acc2.email)))
        .toArray();
    // tslint:enable:no-magic-numbers
    const options: EmailAddress[] = [];
    let openAccountSelected = false;
    for (const account of accounts) {
        if (account.jobId || account.email === defaultAccount?.address) {
            options.push({ name: account.name.full, address: account.email });
        } else {
            if (!openAccountSelected) {
                openAccountSelected = true;
                options.push({ name: account.name.full, address: account.email });
            }
        }
    }
    return options;
}
