import { combineReducers } from 'redux';

import { emailComposeWindows } from '../email-compose/reducer';
import { emailInbox } from '../email-inbox/reducers/email-inbox';
import { emailInboxAccounts } from '../email-inbox/reducers/email-inbox-accounts';
import { State } from '../state';
import { accountManagerData } from './account-manager-data';
import { appConstants } from './app-constants';
import { candidateAssigneeData } from './candidate-assignee-data';
import { candidates } from './candidates';
import { clientWeeklyUpdates } from './client-weekly-updates';
import { clients } from './clients';
import { communications } from './communications';
import { confirmationModal } from './confirmation-modal';
import { contacts } from './contacts';
import { crunchbase } from './crunchbase';
import { emailAccounts } from './email-accounts';
import { emailCounts } from './email-counts';
import { emailTemplates } from './email-templates';
import { extension } from './extension';
import { flaggedEmails } from './flagged-emails';
import { jobAssigneeData } from './job-assignee-data';
import { jobCandidatesSummary } from './job-candidates-summary';
import { jobEmails } from './job-emails';
import { jobMetrics } from './job-metrics';
import { jobSearchSourcing } from './job-sourcing';
import { jobs } from './jobs';
import { jobsSearchesStats } from './jobs-searches-stats';
import { listsState } from './lists-state';
import { noteAttachments } from './note-attachments';
import { notes } from './notes';
import { pendingRequests } from './pending-requests';
import { blacklists } from './person-job-scores';
import { persons, personsDetails } from './persons';
import { preferences } from './preferences';
import { profileConflicts } from './profile-conflicts';
import { scheduledMessages } from './scheduled-messages';
import { searchPresets } from './search-presets';
import { searchResults } from './search-results';
import { searches } from './searches';
import { session } from './session';
import { toast } from './toast';
import { ui } from './ui';
import { userActions } from './user-actions';
import { users } from './users';

export const root = combineReducers<State>({
    accountManagerData,
    appConstants,
    blacklists,
    candidateAssigneeData,
    candidates,
    clientWeeklyUpdates,
    clients,
    communications,
    confirmationModal,
    contacts,
    crunchbase,
    emailAccounts,
    emailComposeWindows,
    emailCounts,
    emailInbox,
    emailInboxAccounts,
    emailTemplates,
    extension,
    flaggedEmails,
    jobAssigneeData,
    jobCandidatesSummary,
    jobEmails,
    jobMetrics,
    jobSearchSourcing,
    jobs,
    jobsSearchesStats,
    listsState,
    noteAttachments,
    notes,
    pendingRequests,
    persons,
    personsDetails,
    preferences,
    profileConflicts,
    scheduledMessages,
    searchPresets,
    searchResults,
    searches,
    session,
    toast,
    ui,
    userActions,
    users
});
