import { RaisedButton } from 'material-ui';
import * as React from 'react';
import * as striptags from 'striptags';

import { NoteView } from 'shared/models/note';

import { deleteDraft, getDraft } from '../common/draft-storage';
import { NoteDraftState } from '../state';
import { NoteEditor } from './note-editor';

interface SubmissionNoteAMActionsProps {
    note: NoteView;
}

export const SubmissionNoteReply: React.FC<SubmissionNoteAMActionsProps> = ({ note }) => {
    const noteDraftKey = `submission-note-reply-${note.id}`;

    const [initialDraft, setInitialDraft] = React.useState<NoteDraftState>(null);

    React.useEffect(() => {
        getDraft<NoteDraftState>(noteDraftKey).then((draft) => {
            setInitialDraft(draft);
        });
    }, []);

    const handleChangeNoteDiscard = () => {
        deleteDraft(noteDraftKey);
        setInitialDraft(null);
    };

    const handleChangeNoteSaved = () => {
        deleteDraft(noteDraftKey);
        setInitialDraft(null);
    };

    const handleButtonClick = () => {
        setInitialDraft({
            addingAttachment: false,
            content: '',
            context: { isSubmissionNoteReply: true, replyTo: note.id },
            initialAttachments: [],
            modifiedAt: Date.now(),
            saving: false
        });
    };

    const hasNoteChanged = (content: string) => {
        return striptags(content).trim().length !== 0;
    };
    let requestButton;
    let noteForm;
    if (initialDraft) {
        noteForm = (
            <NoteEditor
                initialDraft={initialDraft}
                noteDraftKey={noteDraftKey}
                notable={note.notable}
                hasChanged={hasNoteChanged}
                context={{ ...note.context, isSubmissionNoteChangeRequest: undefined }}
                postDiscard={handleChangeNoteDiscard}
                postSave={handleChangeNoteSaved}
                highlightErrors={true}
            />
        );
    } else {
        requestButton = (
            <RaisedButton className="action-button" primary={true} label="Reply" onClick={handleButtonClick} />
        );
    }
    return (
        <div className="submission-note-changes-request">
            {noteForm}
            {requestButton}
        </div>
    );
};
