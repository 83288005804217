import { Map } from 'immutable';
import { FloatingActionButton } from 'material-ui';
import * as React from 'react';
import { connect } from 'react-redux';
import { v4 } from 'uuid';

import { EmailAddress } from 'shared/types/email-compose';

import { composeEmail } from '../../email-compose/actions';
import { ComposeEmailWindowData } from '../../email-compose/types';
import { EmailInboxData } from '../../email-inbox/types';
import { State } from '../../state';

interface ConnectedProps {
    accounts: Map<string, EmailAddress>;
    emailInbox: EmailInboxData;
}

interface ConnectedDispatch {
    composeEmail: (payload: ComposeEmailWindowData) => void;
}

type EmailComposeFabProps = ConnectedProps & ConnectedDispatch;

class EmailComposeFabComponent extends React.Component<EmailComposeFabProps> {
    handleComposeButtonClick = () => {
        const windowId = v4();
        const account = this.props.accounts.get(this.props.emailInbox.account);
        this.props.composeEmail({
            account,
            accountOptions: [account],
            attachments: [],
            body: '',
            canEditToRecipients: true,
            draftSavedAt: null,
            headers: { to: [], cc: [], bcc: [], subject: '' },
            threadId: null,
            windowId
        });
    };

    render() {
        const disabled = !this.props.emailInbox || !this.props.emailInbox.initialized;
        return (
            <div className="email-compose-fab">
                <FloatingActionButton disabled={disabled} onClick={this.handleComposeButtonClick}>
                    <i className="material-icons">edit</i>
                </FloatingActionButton>
            </div>
        );
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    accounts: state.emailInboxAccounts,
    emailInbox: state.emailInbox
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    composeEmail
};

export const EmailComposeFab = connect<ConnectedProps, ConnectedDispatch, {}>(
    mapStateToProps,
    mapDispatchToProps
)(EmailComposeFabComponent);
