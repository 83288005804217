import moment from 'moment';

interface Duration {
    start: number;
    end: number;
    baselineStart: number;
    baselineEnd: number;
}

const defaultDateFormat = 'MMM DD, YYYY';

export type DurationOptionLabel =
    | '30 days (ending 2 weeks ago)'
    | '90 days (ending 2 weeks ago)'
    | '30 days (ending today)'
    | '90 days (ending today)'
    | 'Last 3 months'
    | 'Last 6 months'
    | 'Last 12 months'
    | 'Custom';

export interface DurationOption {
    label: DurationOptionLabel;
    value: Duration;
}

// tslint:disable:no-magic-numbers
const getConversionDurationOptions = (): DurationOption[] => [
    {
        label: '30 days (ending 2 weeks ago)',
        value: {
            baselineEnd: moment().endOf('day').subtract(2, 'weeks').subtract(30, 'days').valueOf(),
            baselineStart: moment().endOf('day').subtract(2, 'weeks').subtract(60, 'days').valueOf(),
            end: moment().endOf('day').subtract(2, 'weeks').valueOf(),
            start: moment().endOf('day').subtract(2, 'weeks').subtract(30, 'days').valueOf()
        }
    },
    {
        label: '90 days (ending 2 weeks ago)',
        value: {
            baselineEnd: moment().endOf('day').subtract(2, 'weeks').subtract(90, 'days').valueOf(),
            baselineStart: moment().endOf('day').subtract(2, 'weeks').subtract(180, 'days').valueOf(),
            end: moment().endOf('day').subtract(2, 'weeks').valueOf(),
            start: moment().endOf('day').subtract(2, 'weeks').subtract(90, 'days').valueOf()
        }
    },
    {
        label: '30 days (ending today)',
        value: {
            baselineEnd: moment().endOf('day').subtract(30, 'days').valueOf(),
            baselineStart: moment().endOf('day').subtract(60, 'days').valueOf(),
            end: moment().endOf('day').valueOf(),
            start: moment().endOf('day').subtract(30, 'days').valueOf()
        }
    },
    {
        label: '90 days (ending today)',
        value: {
            baselineEnd: moment().endOf('day').subtract(90, 'days').valueOf(),
            baselineStart: moment().endOf('day').subtract(180, 'days').valueOf(),
            end: moment().endOf('day').valueOf(),
            start: moment().endOf('day').subtract(90, 'days').valueOf()
        }
    }
];

const getTrendDurationOptions = (): DurationOption[] => [
    {
        label: 'Last 3 months',
        value: {
            baselineEnd: moment().endOf('day').subtract(3, 'months').valueOf(),
            baselineStart: moment().endOf('day').subtract(6, 'months').valueOf(),
            end: moment().endOf('day').valueOf(),
            start: moment().endOf('day').subtract(3, 'months').valueOf()
        }
    },
    {
        label: 'Last 6 months',
        value: {
            baselineEnd: moment().endOf('day').subtract(6, 'months').valueOf(),
            baselineStart: moment().endOf('day').subtract(12, 'months').valueOf(),
            end: moment().endOf('day').valueOf(),
            start: moment().endOf('day').subtract(6, 'months').valueOf()
        }
    },
    {
        label: 'Last 12 months',
        value: {
            baselineEnd: moment().endOf('day').subtract(12, 'months').valueOf(),
            baselineStart: moment().endOf('day').subtract(24, 'months').valueOf(),
            end: moment().endOf('day').valueOf(),
            start: moment().endOf('day').subtract(12, 'months').valueOf()
        }
    }
];
// tslint:enable:no-magic-numbers

const getConversionPeriodDuration = (label: DurationOptionLabel): Duration => {
    const options = getConversionDurationOptions();
    const index = options.findIndex((option) => option.label === label);
    return index === -1 ? undefined : options[index].value;
};

const getTrendPeriodDuration = (label: DurationOptionLabel): Duration => {
    const options = getTrendDurationOptions();
    const index = options.findIndex((option) => option.label === label);
    return index === -1 ? undefined : options[index].value;
};

const getLabel = (start: number, end: number) =>
    `${moment(start).format(defaultDateFormat)} - ${moment(end).format(defaultDateFormat)}`;

const getDurationForCustomRange = (from: number, to: number): Duration => {
    let delta: number | moment.Duration = to - from;
    if (moment(from).add(1, 'day') >= moment(to)) {
        delta = moment.duration(1, 'day');
    } else if (moment(from).add(1, 'week') >= moment(to)) {
        delta = moment.duration(1, 'week');
    } else if (moment(from).add(1, 'month') >= moment(to)) {
        delta = moment.duration(1, 'month');
    } else if (moment(from).add(1, 'quarter') >= moment(to)) {
        delta = moment.duration(1, 'quarter');
    } else if (moment(from).add(1, 'year') >= moment(to)) {
        delta = moment.duration(1, 'year');
    }
    const baselineStart = moment(from).subtract(delta).valueOf();
    const baselineEnd = moment(to).subtract(delta).valueOf();
    return { start: from, end: to, baselineStart, baselineEnd };
};

export {
    Duration,
    getConversionPeriodDuration,
    getDurationForCustomRange,
    getLabel,
    defaultDateFormat,
    getConversionDurationOptions,
    getTrendPeriodDuration,
    getTrendDurationOptions
};
