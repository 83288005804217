import * as React from 'react';
import DocumentTitle from 'react-document-title';

import { Permissions } from 'shared/models/permission';

import { useQuery } from '@apollo/client';
import { Header } from '../components/header';
import { Home } from '../containers/home';
import { Loading } from '../core-ui/loading';
import { User, USER_BY_USERNAME } from '../graphql/queries/user';
import { NotificationsDataProvider } from '../hooks/use-notifications-data';
import { UserContextProvider } from '../hooks/use-user-context';
import { UserDataChangesProvider } from '../hooks/use-user-data-changes';

export const UserDashboard: React.FC<{ username: string }> = ({ username }) => {
    const { data } = useQuery<{ users: User[]; userPermissions: Permissions[] }, { username: string }>(
        USER_BY_USERNAME,
        {
            variables: { username }
        }
    );
    if (!data) {
        return <Loading />;
    }
    const user = data.users[0];
    const userPermissions = data.userPermissions[0];
    const title = `Dashboard - ${user.name.full}`;
    return (
        <DocumentTitle title={title}>
            <div id="container">
                <Header title={title} />
                <div id="content" className="flex-fill">
                    <UserDataChangesProvider userId={user.id}>
                        <NotificationsDataProvider userId={user.id}>
                            <UserContextProvider user={user} userPermissions={userPermissions}>
                                <Home />
                            </UserContextProvider>
                        </NotificationsDataProvider>
                    </UserDataChangesProvider>
                </div>
            </div>
        </DocumentTitle>
    );
};
