import { Action, ReceivePersonSearchResult, RequestPersonSearch, ToggleDrawer, UpdateKanbanState } from '../actions';
import { UIState } from '../state';

const initialState: UIState = {
    drawerOpen: false,
    kanban: {
        boardAutoScroll: 'pending',
        candidateId: undefined,
        candidateIdList: undefined,
        disqualifiedView: false,
        jobId: null
    },
    searchResults: undefined
};

export function ui(state = initialState, action: Action): UIState {
    switch (action.type) {
        case UpdateKanbanState: {
            let boardAutoScroll = action.payload.boardAutoScroll || state.kanban.boardAutoScroll;
            if (state.kanban.jobId && action.payload.jobId && action.payload.jobId !== state.kanban.jobId) {
                boardAutoScroll = 'pending';
            }
            return Object.assign({}, state, {
                kanban: Object.assign({}, state.kanban, action.payload, { boardAutoScroll })
            });
        }
        case ToggleDrawer:
            return Object.assign({}, state, {
                drawerOpen: !state.drawerOpen
            });
        case RequestPersonSearch:
            return Object.assign({}, state, { searchResults: undefined });
        case ReceivePersonSearchResult:
            return Object.assign({}, state, { searchResults: action.payload.searchResults });
        default:
            return state;
    }
}
