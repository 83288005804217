import { Map, OrderedMap } from 'immutable';

export function mergeArrayToMap<T extends { id: string }>(m: Map<string, T>, values: T[]) {
    let result = m;
    for (const v of values) {
        result = result.set(v.id, v);
    }
    return result;
}

export function getMapFromArray<T extends {}>(arr: T[], key: keyof T): OrderedMap<string, T> {
    return OrderedMap<string, T>(arr.map((v) => [v[key], v]) as any);
}
