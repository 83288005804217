import * as _ from 'lodash';
import * as React from 'react';

import { Profile } from '../types/profile';

export const profileEssentials = (profile: Profile): JSX.Element[] => {
    const { experience } = profile.meta;

    const experiencesByDiscipline = _.orderBy(
        _.filter(
            _.map(experience.totalExperienceByJobDiscipline, (d) =>
                d.years === 0
                    ? undefined
                    : {
                          discipline: d.discipline,
                          duration: d.years.toFixed(1)
                      }
            )
        ),
        ['duration'],
        ['desc']
    );

    return [
        <div key="years-at-current" className="profile-essential">
            <div>Years at latest company</div>
            <div>{experience.totalExperienceAtLatestCompany.toFixed(1)}</div>
        </div>
    ].concat(
        experiencesByDiscipline.map((exp) => (
            <div key={exp.discipline} className="profile-essential">
                <div>Years as {exp.discipline}</div>
                <div>{exp.duration}</div>
            </div>
        ))
    );
};
