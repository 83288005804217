import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { JobCandidatesBoard } from '../containers/job-candidates-board';

interface RouterProps {
    jobId: string;
    candidateId?: string;
}

const JobBoardPageComponent: React.FC<RouteComponentProps<RouterProps>> = ({ match }) => {
    const { jobId, candidateId } = match.params;
    return <JobCandidatesBoard jobId={jobId} candidateId={candidateId} />;
};

export const JobBoardPage = withRouter(JobBoardPageComponent);
