import { css } from '@emotion/core';
import { MenuItem, MenuList, Popover } from '@material-ui/core';
import * as React from 'react';

interface DefaultProps {
    menuStyle: React.CSSProperties;
}

interface RangeMenuItem {
    name?: string;
    value: number;
}

interface RangeSelectFieldProps extends Partial<DefaultProps> {
    minValueOpts: RangeMenuItem[];
    maxValueOpts: RangeMenuItem[];
    min: number;
    max: number;
    onChange: (min: number, max: number) => void;
    readonly: boolean;
}

interface RangeSelectFieldState {
    open: boolean;
    anchor: Element;
}

const popoverStyle = css`
    .range-select-menu {
        max-height: 320px;
        &::-webkit-scrollbar {
            width: 4px;
        }
    }

    .MuiListItem-root {
        padding: 6px 24px;
        min-width: 100px;
    }
`;

export class RangeSelectField extends React.Component<RangeSelectFieldProps, RangeSelectFieldState> {
    static defaultProps: DefaultProps = {
        menuStyle: {}
    };
    constructor(props: RangeSelectFieldProps) {
        super(props);
        this.state = { open: false, anchor: null };
    }

    handleOpen = (e: React.MouseEvent) => {
        this.setState({ open: true, anchor: e.currentTarget });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleMinChange = (val: number) => () => {
        const max = this.props.max >= val ? this.props.max : val;
        this.props.onChange(val, max);
    };

    handleMaxChange = (val: number) => () => {
        this.props.onChange(this.props.min, val);
    };

    render() {
        const { min, max, readonly, menuStyle } = this.props;
        let { minValueOpts, maxValueOpts } = this.props;
        minValueOpts = minValueOpts.sort((v1, v2) => v1.value - v2.value);
        maxValueOpts = maxValueOpts.sort((v1, v2) => v1.value - v2.value);
        const { open, anchor } = this.state;

        const minItem = minValueOpts.find((s) => s.value === min);
        const maxItem = maxValueOpts.find((s) => s.value === max);
        const minText = minItem?.name ?? minItem?.value ?? min;
        const maxText = maxItem?.name ?? maxItem?.value ?? max;
        const content = (
            <div className="range-select-field-content" onClick={this.handleOpen}>
                {minText} — {maxText}
            </div>
        );
        let popover;
        if (open && !readonly) {
            const minMenuItems = minValueOpts.map((v) => (
                <MenuItem
                    value={v.value}
                    key={v.value}
                    selected={v.value === min}
                    onClick={this.handleMinChange(v.value)}
                >
                    {v.name || `${v.value}`}
                </MenuItem>
            ));
            const maxMenuItems = maxValueOpts
                .filter((v) => v.value >= min)
                .map((v) => (
                    <MenuItem
                        value={v.value}
                        key={v.value}
                        selected={v.value === max}
                        onClick={this.handleMaxChange(v.value)}
                    >
                        {v.name || `${v.value}`}
                    </MenuItem>
                ));
            const minMenu = <MenuList style={menuStyle}>{minMenuItems}</MenuList>;
            const maxMenu = <MenuList style={menuStyle}>{maxMenuItems}</MenuList>;
            popover = (
                <Popover
                    open={true}
                    onClose={this.handleClose}
                    anchorEl={anchor}
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                    css={popoverStyle}
                >
                    <div className="range-select-menus">
                        <div className="range-select-menu">{minMenu}</div>
                        <div className="range-select-menu">{maxMenu}</div>
                    </div>
                </Popover>
            );
        }
        return (
            <div className="range-select-field">
                {content}
                {popover}
            </div>
        );
    }
}
