import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { PersonJobLabel } from '../containers/person-job-label';

interface RouterProps {
    jobId: string;
    personId: string;
}

const PersonPageComponent: React.SFC<RouteComponentProps<RouterProps>> = ({ match }) => (
    <div id="container" className="person-profile-page">
        <div id="content" className="flex-fill">
            <PersonJobLabel personId={match.params.personId} jobId={match.params.jobId} />
        </div>
    </div>
);

export const PersonJobLabelPage = withRouter(PersonPageComponent);
