import { css } from '@emotion/core';
import { Button, Menu, MenuItem } from '@material-ui/core';
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';

import { hasRole } from 'shared/models/user';

import { Header } from '../components/header';
import { Home } from '../containers/home';
import { useSession } from '../hooks/use-session';
import { UserContextProvider } from '../hooks/use-user-context';

const buttonStyle = css`
    color: white;
    margin-right: 18px;
`;

const linkStyle = css`
    color: rgba(0, 0, 0, 0.87);
`;

export const HomePage = () => {
    const { user, userPermissions } = useSession();
    const [anchor, setAnchor] = React.useState(null);

    const handleMenuRequestClose = () => {
        setAnchor(null);
    };

    const handleMenuButtonTap = (event: React.SyntheticEvent<{}>) => {
        event.preventDefault();
        setAnchor(event.currentTarget);
    };

    const viewAllCandidatesButton = !hasRole(userPermissions, 'account_manager') ? (
        <Link to="/recruiter/me/candidates" target="_blank" key="active-candidates">
            <Button css={buttonStyle}>All My Candidates</Button>
        </Link>
    ) : (
        <div key="active-candidates">
            <Button onClick={handleMenuButtonTap} css={buttonStyle}>
                All My Candidates
            </Button>

            <Menu anchorEl={anchor} open={!!anchor} onClose={handleMenuRequestClose}>
                <MenuItem onClick={handleMenuRequestClose}>
                    <Link css={linkStyle} to="/recruiter/me/candidates" key="recruiter-candidates" target="_blank">
                        Recruiter Candidates
                    </Link>
                </MenuItem>
                <MenuItem onClick={handleMenuRequestClose}>
                    <Link
                        css={linkStyle}
                        to="/account-manager/me/candidates"
                        key="account-manager-candidates"
                        target="_blank"
                    >
                        AM Candidates
                    </Link>
                </MenuItem>
            </Menu>
        </div>
    );

    const actions = [viewAllCandidatesButton];
    return (
        <DocumentTitle title="Home">
            <div id="container">
                <Header title="Home" actions={actions} />
                <div id="content" className="flex-fill">
                    <UserContextProvider user={user} userPermissions={userPermissions}>
                        <Home />
                    </UserContextProvider>
                </div>
            </div>
        </DocumentTitle>
    );
};
