import { escapeRegExp } from 'lodash';

import { htmlToText } from 'shared/common/html-to-text';
import { JobFilter, JobFilterField, visaOptToString } from 'shared/models/job';

import { JobWithDetails } from '../graphql/queries/jobs-table';
import { Job } from '../graphql/queries/person';

export function experienceText(job: Job) {
    return job.description.maxExperienceYears !== undefined
        ? `${job.description.minExperienceYears} - ${job.description.maxExperienceYears} Years`
        : job.description.minExperienceYears !== undefined
          ? `${job.description.maxExperienceYears}+ Years`
          : '';
}

function jobFilterAnywhere(j: JobWithDetails, text: string) {
    const regex = new RegExp(escapeRegExp(text), 'i');
    return !!(
        !text ||
        j.title.match(regex) ||
        j.description?.location?.match(regex) ||
        j.discipline?.match(regex) ||
        j.client.name.match(regex) ||
        j.assignee.name.match(regex) ||
        j.accountManager?.name?.match(regex) ||
        j.description.visa?.find((v) => visaOptToString(v).match(regex)) ||
        j.description.visa?.map((v) => v.replace('_', ' ')).find((v) => v.match(regex)) ||
        j.description.compensation?.match(regex) ||
        htmlToText(j.description.description)?.match(regex) ||
        htmlToText(j.description.mustHaves)?.match(regex) ||
        htmlToText(j.description.niceToHaves)?.match(regex) ||
        j.client.salesPitch?.match(regex) ||
        j.client.teamSize?.match(regex) ||
        j.client.founders?.match(regex) ||
        j.client.founders?.match(regex) ||
        j.description.hiringManager?.match(regex) ||
        j.client.hq?.match(regex) ||
        j.client.foundedDate?.match(regex) ||
        j.description.diversity?.match(regex) ||
        j.description.summary?.match(regex) ||
        htmlToText(j.description.interviewPrep)?.match(regex)
    );
}

function jobFilterSelected(j: JobWithDetails, text: string) {
    const regex = new RegExp(escapeRegExp(text), 'i');
    return !!(
        !text ||
        j.title.match(regex) ||
        j.description?.location?.match(regex) ||
        j.discipline?.match(regex) ||
        j.assignee.name.match(regex) ||
        j.accountManager?.name?.match(regex) ||
        j.client.name.match(regex) ||
        j.description.visa?.find((v) => visaOptToString(v).match(regex)) ||
        j.description.visa?.map((v) => v.replace('_', ' ')).find((v) => v.match(regex)) ||
        j.client.founders?.match(regex) ||
        j.client.founders?.match(regex) ||
        j.description.hiringManager?.match(regex) ||
        j.client.hq?.match(regex) ||
        j.client.foundedDate?.match(regex) ||
        j.description.diversity?.match(regex) ||
        htmlToText(j.description.mustHaves)?.match(regex) ||
        htmlToText(j.description.niceToHaves)?.match(regex)
    );
}

export function addFilter(
    current: JobFilter[],
    added: { field: JobFilterField; value: { label?: string; name: string; tooltip?: string } }
): JobFilter[] {
    let updated = current;
    if (added.value?.name?.trim().length > 0) {
        if (added.field === 'keywords-and') {
            updated = updated.concat([
                { field: added.field, values: [{ name: added.value.name.trim(), label: added.value.label }] }
            ]);
        } else {
            const filter = updated.find((f) => f.field === added.field) ?? { field: added.field, values: [] };
            const values = filter.values
                .filter((v) => v.name !== added.value.name)
                .concat([{ name: added.value.name.trim(), label: added.value.label }]);
            filter.values = values;
            updated = updated.filter((f) => f.field !== filter.field).concat([filter]);
        }
    }
    return updated;
}

export function removeFilter(
    current: JobFilter[],
    removed: { field: JobFilterField; value: { label?: string; name: string; tooltip?: string } }
) {
    const filterIndex = current.findIndex(
        (f) => f.field === removed.field && f.values.findIndex((v) => v.name === removed.value.name) !== -1
    );
    const values = current[filterIndex].values.filter((v) => v.name !== removed.value.name);
    return current
        .slice(0, filterIndex)
        .concat([{ field: current[filterIndex].field, values }], current.slice(filterIndex + 1))
        .filter((f) => f.values.length > 0);
}

export function setFilterFieldValues(
    current: JobFilter[],
    field: JobFilterField,
    values: Array<{ name: string; label?: string }>
) {
    let updated = current.filter((f) => f.field !== field);
    if (values.length > 0) {
        updated = updated.concat([{ field, values }]);
    }
    return updated;
}

export function isJobPassingFilters(
    curatedLists: { hotClients: string[]; newJobs: string[] },
    filters: JobFilter[],
    job: JobWithDetails
) {
    let passing = true;
    for (const { field, values } of filters) {
        switch (field) {
            case 'curated-hot':
                passing = passing && curatedLists?.hotClients?.indexOf(job.client.id) !== -1;
                break;
            case 'curated-new':
                passing = passing && curatedLists?.newJobs?.indexOf(job.id) !== -1;
                break;
            // tslint:disable:no-magic-numbers
            case 'headcount':
                passing = passing && job.headcount >= 3;
                break;
            case 'experience':
                passing =
                    passing &&
                    values.reduce((acc, v) => {
                        return (
                            acc ||
                            (v.name === '2-5 Years' &&
                                job.description.minExperienceYears <= 5 &&
                                job.description.maxExperienceYears >= 2) ||
                            (v.name === '5+ Years' && job.description.minExperienceYears >= 5)
                        );
                    }, false);
                break;
            // tslint:enable:no-magic-numbers
            case 'discipline':
                passing = passing && values.findIndex((v) => job.discipline?.match(new RegExp(v.name, 'i'))) !== -1;
                break;
            case 'keywords':
            case 'keywords-and':
                passing =
                    passing &&
                    values.reduce((acc, v) => {
                        return acc || jobFilterSelected(job, v.name);
                    }, false);
                break;
            case 'diversity':
                passing = passing && values.findIndex((v) => v.name === job.description.diversity) !== -1;
                break;
            case 'location':
                passing =
                    passing &&
                    values.reduce((acc, v) => {
                        const regex = new RegExp(escapeRegExp(v.name), 'i');
                        return acc || !!job.description?.location?.match(regex);
                    }, false);
                break;

            case 'visa':
                passing =
                    passing &&
                    values.reduce((acc, v) => {
                        const regex = new RegExp(escapeRegExp(v.name), 'i');
                        return acc || (job.description.visa ?? []).findIndex((k) => k.match(regex)) !== -1;
                    }, false);
                break;
            case 'anywhere':
                passing = passing && values.reduce((acc, v) => acc || jobFilterAnywhere(job, v.name), false);
        }
        if (!passing) break;
    }
    return passing;
}

export const tableFilterPresets: JobFilter[] = [
    {
        field: 'curated-hot',
        values: [
            {
                name: 'HOT 🔥',
                tooltip: 'Hot Jobs'
            }
        ]
    },
    {
        field: 'curated-new',
        values: [
            {
                name: 'NEW',
                tooltip: 'Jobs created in last month'
            }
        ]
    },
    {
        field: 'headcount',
        values: [
            {
                name: '3+ Headcount',
                tooltip: 'Jobs with at least 3 headcount'
            }
        ]
    },
    {
        field: 'experience',
        values: [
            {
                name: '2-5 Years',
                tooltip: 'Jobs needing a minimum experience between 2 and 5 years'
            },
            {
                name: '5+ Years',
                tooltip: 'Jobs needing at least 5 years of experience'
            }
        ]
    },
    {
        field: 'discipline',
        values: [
            {
                label: 'SWE',
                name: 'Software developer',
                tooltip: 'Jobs with Software Developer discipline'
            },
            {
                label: 'PM',
                name: 'Product management',
                tooltip: 'Jobs with Product Manager discipline'
            },
            {
                name: 'Design',
                tooltip: 'Jobs with Product Design discipline'
            }
        ]
    },
    {
        field: 'visa',
        values: [
            {
                label: 'Visa Transfer',
                name: 'h1_transfers',
                tooltip: 'Jobs that support visa transfers'
            }
        ]
    },
    {
        field: 'keywords',
        values: [
            {
                name: 'React',
                tooltip: 'Jobs needing React.js as a skill'
            },
            {
                name: 'Python',
                tooltip: 'Jobs needing Python as a skill'
            },
            {
                name: 'Node.js',
                tooltip: 'Jobs needing Node.js as a skill'
            },
            {
                name: 'Java',
                tooltip: 'Jobs needing Java as a skill'
            },
            {
                name: 'iOS',
                tooltip: 'Jobs needing iOS as a skill'
            },
            {
                name: 'Android',
                tooltip: 'Jobs needing Android as a skill'
            }
        ]
    },
    {
        field: 'location',
        values: [
            {
                name: 'Remote',
                tooltip: 'Jobs that support Remote work'
            },
            {
                label: 'SF Bay Area',
                name: 'San Francisco',
                tooltip: 'Jobs in SF Bay area'
            }
        ]
    },
    {
        field: 'group',
        values: [
            {
                label: 'Backend',
                name: 'Backend engineer',
                tooltip: 'Jobs with Backend Engineer Group'
            },
            {
                label: 'Frontend',
                name: 'Frontend engineer',
                tooltip: 'Jobs with Frontend Engineer Group'
            },
            {
                label: 'Fullstack',
                name: 'Fullstack engineer',
                tooltip: 'Jobs with Fullstack Engineer Group'
            }
        ]
    }
];

export const phoneScreenFilterPresets: JobFilter[] = [
    {
        field: 'curated-hot',
        values: [
            {
                name: 'HOT 🔥',
                tooltip: 'Hot Jobs'
            }
        ]
    },
    {
        field: 'curated-new',
        values: [
            {
                name: 'NEW',
                tooltip: 'Jobs created in last month'
            }
        ]
    },
    {
        field: 'headcount',
        values: [
            {
                name: '3+ Headcount',
                tooltip: 'Jobs with at least 3 headcount'
            }
        ]
    },
    {
        field: 'visa',
        values: [
            {
                label: 'Visa Transfer',
                name: 'h1_transfers',
                tooltip: 'Jobs that support visa transfers'
            }
        ]
    },
    {
        field: 'location',
        values: [
            {
                name: 'Remote',
                tooltip: 'Jobs that support Remote work'
            },
            {
                label: 'SF Bay Area',
                name: 'San Francisco',
                tooltip: 'Jobs in SF Bay area'
            }
        ]
    }
];
