import { css } from '@emotion/core';
import { MenuItem, Select } from '@material-ui/core';
import * as React from 'react';

import { JobFilterTypes, jobTypeFilterLabels } from '../state';

const styles = css`
    background: white;
    margin: 0 0 0 15px;

    .MuiOutlinedInput-input {
        padding-top: 9px;
        padding-bottom: 9px;
        background: white;
    }
`;

interface JobsTypeFilterProps {
    selectedFilter: JobFilterTypes;
    onSelectFilter: (filter: JobFilterTypes) => void;
}

export const JobsTypeFilter: React.FC<JobsTypeFilterProps> = (props) => {
    const { selectedFilter } = props;

    const handleChange = (event: React.ChangeEvent<{ value: JobFilterTypes }>) => {
        props.onSelectFilter(event.target.value);
    };

    const menuItems = (['placement-fees', 'sourcer', 'staffing', 'rpo', 'all'] as JobFilterTypes[]).map((filter) => (
        <MenuItem key={filter} value={filter}>
            {jobTypeFilterLabels.get(filter)}
        </MenuItem>
    ));
    return (
        <Select css={styles} value={selectedFilter} onChange={handleChange} variant="outlined">
            {menuItems}
        </Select>
    );
};
