import { Action, ReceiveAppConstant } from '../actions';
import { AppConstantState } from '../state';

const initialState: AppConstantState = {
    constants: null,
    initialized: false
};
export function appConstants(state = initialState, action: Action): AppConstantState {
    switch (action.type) {
        case ReceiveAppConstant:
            return { ...state, initialized: true, constants: action.appConstants };
        default:
            return state;
    }
}
