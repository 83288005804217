import { useSubscription } from '@apollo/client';
import React, { createContext, FC, useContext } from 'react';

import { USER_DATA_MODIFICATION_TS, UserDataModificationTimestamps } from '../graphql/queries/user';
import { useSession } from './use-session';

const UserDataChangesContext = createContext<{ dataModificationTs: UserDataModificationTimestamps }>({
    dataModificationTs: undefined
});

const UserDataChangesProvider: FC<{ userId?: string }> = (props) => {
    const { user } = useSession();

    const userId = props.userId ?? user?.id;

    const { data } = useSubscription<{ user: UserDataModificationTimestamps }, { userId: string }>(
        USER_DATA_MODIFICATION_TS,
        { variables: { userId } }
    );

    const dataModificationTs = data?.user;

    return (
        <UserDataChangesContext.Provider value={{ dataModificationTs }}>
            {props.children}
        </UserDataChangesContext.Provider>
    );
};

const useUserDataChanges = () => useContext(UserDataChangesContext);

export { UserDataChangesProvider, useUserDataChanges };
