import { useQuery } from '@apollo/client';
import { MenuItem, Tooltip } from '@material-ui/core';
import React from 'react';

import { PHONE_CALL_RECIPIENT } from '../graphql/queries/sequence-recipient';

interface SchedulingSequenceMenuOptionProps {
    candidate: { personId: string; jobId: string };
    text: string;
    onClick: () => void;
    hasLastEmail: boolean;
}

export const SchedulingSequenceMenuOption: React.FC<SchedulingSequenceMenuOptionProps> = ({
    candidate,
    text,
    onClick,
    hasLastEmail
}) => {
    const { data, loading } = useQuery<{ recipients: Array<{ id: string }> }, { personId: string; jobId: string }>(
        PHONE_CALL_RECIPIENT,
        { fetchPolicy: 'network-only', variables: { personId: candidate.personId, jobId: candidate.jobId } }
    );
    const isDisabled = loading || !hasLastEmail || !!data?.recipients?.[0]?.id;
    const menuItem = (
        <MenuItem onClick={onClick} disabled={isDisabled}>
            {text}
        </MenuItem>
    );
    const tooltipText = loading
        ? 'Please wait...'
        : !hasLastEmail
          ? 'Could not find the email to reply to'
          : 'Sequence already sent';
    const result = isDisabled ? (
        <Tooltip title={tooltipText}>
            <span>{menuItem}</span>
        </Tooltip>
    ) : (
        menuItem
    );
    return result;
};
