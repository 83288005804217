import { css } from '@emotion/core';
import { LinearProgress, Slider, Theme, useTheme } from '@material-ui/core';
import React from 'react';

const styles = (theme: Theme) => css`
    .progress {
        height: 8px;
        margin-top: 10px;
        margin-bottom: 40px;
    }

    .slider {
        height: 8px;
        padding: 10px 0;

        .MuiSlider-rail,
        .MuiSlider-track {
            height: 8px;
            border-radius: 2px;
        }
        .MuiSlider-thumb {
            height: 20px;
            width: 20px;
            margin-top: -6px;
            margin-left: -10px;
            background: white;
            border: 2px solid ${theme.palette.primary.main};
        }
    }
`;

const maxBarValue = 100;

export const OutreachCountsBar: React.FC<{
    label: JSX.Element;
    sendingCount: number;
    sentCount: number;
    scheduledCount: number;
    maxSends: number;
    onChange: (value: number) => void;
}> = ({ label, sendingCount, sentCount, scheduledCount, maxSends, onChange }) => {
    const theme = useTheme();
    const scale = (maxSends + scheduledCount + sentCount) / maxBarValue;

    const handleChange = (_1: any, newValue: number | number[]) => {
        onChange(Math.floor((newValue as number) * scale - scheduledCount - sentCount));
    };

    const value = sendingCount === undefined ? 0 : (sendingCount + sentCount + scheduledCount) / scale;

    const outreachCountsBar =
        sendingCount === undefined ? (
            <LinearProgress className="progress" />
        ) : (
            <Slider value={value} onChange={handleChange} className="slider" />
        );

    return (
        <div css={styles(theme)}>
            {label}
            {outreachCountsBar}
        </div>
    );
};
