import { css } from '@emotion/core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import React from 'react';

interface SaveFilterProps {
    onSave: (name: string) => void;
    disabled: boolean;
}

const buttonStyle = css`
    min-width: 48px;
    padding: 8.5px 0;
    background: white;

    i.fas {
        font-size: 16px;
    }
`;

export const SaveFilter: React.FC<SaveFilterProps> = ({ onSave, disabled }) => {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [name, setName] = React.useState('');
    const [errorText, setErrorText] = React.useState<string>('');

    const handleToggleDialog = () => setDialogOpen(!dialogOpen);

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
        setErrorText('');
    };

    const handleKeyPress = (e: React.KeyboardEvent<{}>) => {
        if (e.key === 'Enter' && name.trim().length > 0) {
            handleSave();
        }
    };

    const handleSave = () => {
        setDialogOpen(false);
        setErrorText('');
        onSave(name);
    };

    const button = (
        <Tooltip title="Save Filters">
            <span>
                <Button css={buttonStyle} disabled={disabled} onClick={handleToggleDialog} variant="outlined">
                    <i className="fas fa-save" />
                </Button>
            </span>
        </Tooltip>
    );

    const dialog = (
        <Dialog open={dialogOpen} onClose={handleToggleDialog} maxWidth="sm" fullWidth={true}>
            <DialogTitle>
                <Typography variant="h4" component="span">
                    Save Filters
                </Typography>
            </DialogTitle>
            <DialogContent>
                <TextField
                    key="search-name"
                    value={name}
                    onChange={handleTextChange}
                    onKeyPress={handleKeyPress}
                    placeholder="A name to recall this filter preset with"
                    error={!!errorText}
                    helperText={errorText}
                    fullWidth={true}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleToggleDialog}>Cancel</Button>
                <Button disabled={name?.trim().length === 0} onClick={handleSave}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <>
            {button}
            {dialog}
        </>
    );
};
