import { useLazyQuery } from '@apollo/client';
import { css } from '@emotion/core';
import {
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
    useTheme
} from '@material-ui/core';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { orderBy, times } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';

import { internalClientIds, placementFeeJobTypes, sourcerJobTypes } from 'shared/models/client';

import { Duration } from '../common/duration';
import { BILLINGS_LEADERBOARD, LeaderboardBilling } from '../graphql/queries/recruiter-stats';
import { DurationSelector } from './duration-selector';
import { Header } from './header';

const skeletonRowsCount = 20;

const styles = (theme: Theme) => css`
    background: #f4f6f8;
    flex: 1 1 auto;
    padding: 25px 50px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .selectors {
        flex: 0 0 auto;
        text-align: right;
        margin-bottom: 20px;

        .search-field,
        > .MuiInputBase-root {
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }

            .MuiOutlinedInput-input {
                padding-top: 14px;
                padding-bottom: 14px;
                background: white;
            }
        }
    }

    .table {
        border: thin solid ${theme.palette.divider};
        border-radius: ${theme.shape.borderRadius}px;
        background: white;

        .table-pagination {
            text-align: right;
            padding: 18px 25px;
            font-size: 13px;
            text-transform: uppercase;
        }

        .table-footer {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;

            .billing-summary {
                margin-left: 15px;
                font-size: 14px;
            }
        }

        tr:hover {
            background: ${theme.palette.action.hover};
        }

        .candidate-name {
            color: ${theme.palette.primary.main};
            cursor: pointer;
        }

        .table-header-cell {
            display: inline-flex;
            align-items: center;
            cursor: pointer;
        }

        .column-action-icon {
            display: inline-flex;
            align-items: center;
            opacity: 0;
            transition: opacity 200ms;
            margin-left: 5px;
            cursor: pointer;

            .MuiSvgIcon-root {
                font-size: 1.25rem;
                color: ${theme.palette.text.secondary};
            }

            &.visible {
                opacity: 1;
            }
        }

        th:hover {
            .column-action-icon {
                opacity: 1;
            }
        }

        .MuiTableRow-root:last-child {
            .MuiTableCell-root {
                border-bottom: none;
            }
        }
    }
`;

type Column = 'Recruiter' | 'Recruiter Billings' | 'AM Billings' | 'Total Billings';

export const Leaderboard: React.FC<{}> = () => {
    const theme = useTheme();
    const [sortCol, setSortCol] = React.useState<Column>('Recruiter Billings');
    const [sortAsc, setSortAsc] = React.useState<'asc' | 'desc'>('desc');
    const [startTime, setStartTime] = useState(moment().startOf('month').valueOf());
    const [endTime, setEndTime] = useState(moment().endOf('day').valueOf());
    const [clientTypes, setClientTypes] = useState<'external-clients' | 'all'>('external-clients');
    const [jobTypes, setJobTypes] = useState<'placement-fees' | 'sourcer' | 'all'>('placement-fees');
    const [fetchPlacements, { data }] = useLazyQuery<
        { leaderboard: LeaderboardBilling[] },
        { jobType: string[]; startTime: number; endTime: number; excludeClientIds: string[] }
    >(BILLINGS_LEADERBOARD);

    const columns: Column[] = ['Recruiter', 'Recruiter Billings'];

    const getJobTypes = () =>
        jobTypes === 'placement-fees'
            ? placementFeeJobTypes
            : jobTypes === 'sourcer'
              ? sourcerJobTypes
              : placementFeeJobTypes.concat(sourcerJobTypes);

    const getExcludedClientIds = () => (clientTypes === 'external-clients' ? internalClientIds : []);

    useEffect(() => {
        fetchPlacements({
            variables: {
                endTime,
                excludeClientIds: getExcludedClientIds(),
                jobType: getJobTypes(),
                startTime
            }
        });
    }, [startTime, endTime, clientTypes, jobTypes]);

    const handleJobTypesChange = (event: React.ChangeEvent<{ value: any }>) => {
        setJobTypes(event.target.value);
    };

    const handleClientsTypeChange = (event: React.ChangeEvent<{ value: any }>) => {
        setClientTypes(event.target.value);
    };

    const handleSetDuration = ({ start, end }: Duration) => {
        setStartTime(start);
        setEndTime(end);
    };

    const handleSortChange = (col: Column) => () => {
        if (col === sortCol) {
            if (sortAsc === 'asc') {
                setSortAsc('desc');
            } else {
                setSortAsc('asc');
            }
        } else {
            setSortCol(col);
            setSortAsc('asc');
        }
    };

    const headers = columns.map((col) => {
        const sortIcon = sortAsc === 'desc' ? <ArrowDownward /> : <ArrowUpward />;
        const sort = (
            <span className={`column-action-icon ${sortCol === col ? 'visible' : ''}`} onClick={handleSortChange(col)}>
                {sortIcon}
            </span>
        );
        return (
            <TableCell key={col}>
                <span className="table-header-cell">
                    <span onClick={handleSortChange(col)}>{col}</span>
                    {sort}
                </span>
            </TableCell>
        );
    });

    const skeletonRows = times(skeletonRowsCount).map((i) => (
        <TableRow key={i}>
            <TableCell colSpan={columns.length}>
                <Skeleton variant="rect" />
            </TableCell>
        </TableRow>
    ));

    const valueFunc = (col: Column) => (u: LeaderboardBilling) => {
        switch (col) {
            case 'Recruiter':
                return u.name;
            case 'Recruiter Billings':
                return u.recruiterBillings;
            case 'AM Billings':
                return u.amBillings;
            case 'Total Billings':
                return u.totalBillings;
            default:
                return null;
        }
    };

    const sortedRows: LeaderboardBilling[] = data
        ? orderBy(data.leaderboard, [valueFunc(sortCol), 'name'], [sortAsc, 'asc'])
        : undefined;

    const rows =
        sortedRows === undefined
            ? skeletonRows
            : sortedRows.map((user) => {
                  const cols = columns.map((col) => {
                      return <TableCell key={col}>{valueFunc(col)(user).toLocaleString()}</TableCell>;
                  });
                  return <TableRow key={`${user.userId}`}>{cols}</TableRow>;
              });

    return (
        <DocumentTitle title="Billings Leaderboard">
            <div id="container">
                <Header title="Billings Leaderboard" />
                <div css={styles(theme)}>
                    <div className="selectors">
                        <DurationSelector selected={{ start: startTime, end: endTime }} onSelect={handleSetDuration} />
                        <Select value={jobTypes} onChange={handleJobTypesChange} variant="outlined">
                            <MenuItem value={'placement-fees'}>Contingency/Retained</MenuItem>
                            <MenuItem value={'sourcer'}>Sourcer Jobs</MenuItem>
                            <MenuItem value={'all'}>All Jobs</MenuItem>
                        </Select>
                        <Select value={clientTypes} onChange={handleClientsTypeChange} variant="outlined">
                            <MenuItem value={'external-clients'}>External Clients</MenuItem>
                            <MenuItem value={'all'}>Include Rocket Jobs</MenuItem>
                        </Select>
                    </div>
                    <TableContainer className="table">
                        <Table stickyHeader={true}>
                            <TableHead>
                                <TableRow>{headers}</TableRow>
                            </TableHead>
                            <TableBody>{rows}</TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </DocumentTitle>
    );
};
