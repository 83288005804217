import { css } from '@emotion/core';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import React from 'react';

interface OutreachSendCountsInfoProps {
    availableCount: number;
    alreadyScheduled: number;
    dailyEmailLimit: number;
    dailyPerUserEmailLimit: number;
    jobSentCount: number;
    jobScheduledCount: number;
    userJobSentCount: number;
    userJobScheduledCount: number;
    userDailyLimit: number;
    emailAccountSlots: number;
    userSentCount: number;
    userScheduledCount: number;
}

const styles = css`
    font-weight: normal;
    font-size: 11px;

    td.numeric {
        text-align: right;
    }

    tr:last-child {
        td {
            border-bottom: none;
        }
    }
`;

export const OutreachSendCountsInfo: React.FC<OutreachSendCountsInfoProps> = ({
    dailyEmailLimit,
    dailyPerUserEmailLimit,
    availableCount,
    alreadyScheduled,
    jobSentCount,
    jobScheduledCount,
    userSentCount,
    userScheduledCount,
    emailAccountSlots,
    userDailyLimit,
    userJobSentCount,
    userJobScheduledCount
}) => {
    return (
        <div css={styles}>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>Candidates/Search Results</TableCell>
                        <TableCell className="numeric">{availableCount}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Job's daily limit</TableCell>
                        <TableCell className="numeric">{dailyEmailLimit}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Sent for Job by all users</TableCell>
                        <TableCell className="numeric">{jobSentCount}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Scheduled for Job by all users</TableCell>
                        <TableCell className="numeric">{jobScheduledCount}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Job's per user daily limit</TableCell>
                        <TableCell className="numeric">{dailyPerUserEmailLimit}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Sent by user for Job</TableCell>
                        <TableCell className="numeric">{userJobSentCount}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Scheduled by user for Job</TableCell>
                        <TableCell className="numeric">{userJobScheduledCount}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Scheduled by user for Job for other days</TableCell>
                        <TableCell className="numeric">{alreadyScheduled - userJobScheduledCount}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>User's overall daily limit</TableCell>
                        <TableCell className="numeric">{userDailyLimit}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Email account(s) capacity</TableCell>
                        <TableCell className="numeric">{emailAccountSlots}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Sent by user for all jobs</TableCell>
                        <TableCell className="numeric">{userSentCount}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Scheduled by user for all jobs</TableCell>
                        <TableCell className="numeric">{userScheduledCount}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    );
};
