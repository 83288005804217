import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Dialog,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Theme,
    useTheme
} from '@material-ui/core';
import { StarBorder } from '@material-ui/icons';
import { Rating } from '@material-ui/lab';
import produce from 'immer';
import React from 'react';

import { Loading } from '../core-ui/loading';
import { PROFILE } from '../graphql/queries/person';
import {
    CREATE_RATING,
    ProfileRating,
    ProfileRatingReq,
    RATING_CANDIDATES,
    RatingCandidate,
    REQS
} from '../graphql/queries/profile-ratings';
import { useSession } from '../hooks/use-session';
import { Profile } from '../types/profile';
import { ProfileNavButtons } from './profile-nav-buttons';
import { ProfileRatingCandidate } from './profile-rating-candidate';

const styles = (theme: Theme) => css`
    background: #f4f6f8;
    flex: 1 1 auto;
    overflow: auto;
    padding: 25px 50px;

    .filter {
        text-align: right;
        margin-bottom: 25px;

        .MuiOutlinedInput-input {
            padding-top: 14px;
            padding-bottom: 14px;
            background: white;
        }
    }

    .person-link {
        cursor: pointer;
        color: ${theme.palette.primary.main};
    }
`;

export const ProfileRatings: React.FC = () => {
    const limit = 25;
    const ratingPrecision = 0.5;
    const { user } = useSession();
    const theme = useTheme();
    const { data: reqsData } = useQuery<{ reqs: ProfileRatingReq[] }>(REQS);
    const [candidates, setCandidates] = React.useState<RatingCandidate[]>(undefined);
    const [fetchCandidates, { data: candidatesData }] = useLazyQuery<
        { candidates: RatingCandidate[]; count: { aggregate: { count: number } } },
        { limit: number; offset: number; reqIds: string[] }
    >(RATING_CANDIDATES);
    const [fetchProfile, { data: profileData }] = useLazyQuery<
        { profile: Array<{ content: Profile; viewedAt: number }> },
        { personId: string }
    >(PROFILE);
    const [createRating] = useMutation<
        {
            rating: {
                returning: ProfileRating[];
            };
        },
        { personId: string; reqId: string; rating: number }
    >(CREATE_RATING);
    const [reqFilter, setReqFilter] = React.useState<'all' | string>('all');
    const [page, setPage] = React.useState(0);
    const [selectedPerson, setSelectedPerson] = React.useState<string>(undefined);

    const pageCandidates = candidates?.slice(page * limit, (page + 1) * limit);
    const totalCount = candidatesData?.count.aggregate.count;

    const loadMoreCandidates = () => {
        if (reqsData) {
            if (
                !candidates ||
                (candidates?.length < candidatesData?.count.aggregate.count && pageCandidates?.length < limit)
            ) {
                const offset = page * limit;
                const reqIds = reqFilter === 'all' ? reqsData.reqs.map((r) => r.id) : [reqFilter];
                fetchCandidates({ variables: { limit, offset, reqIds } });
            }
        }
    };

    React.useEffect(loadMoreCandidates, [reqsData, reqFilter, page]);

    React.useEffect(() => {
        if (candidatesData) {
            setCandidates((candidates ?? []).concat(candidatesData.candidates));
        }
    }, [candidatesData]);

    React.useEffect(() => {
        if (selectedPerson) {
            fetchProfile({ variables: { personId: selectedPerson } });
        }
    }, [selectedPerson]);

    const handleFilterChange = (event: React.ChangeEvent<{ value: string | 'all' }>) => {
        setReqFilter(event.target.value);
        setCandidates(undefined);
    };

    const handleChangePage = (_1: any, newPage: number) => setPage(newPage);
    const handleChangeRowsPerPage = () => {
        /* no-op */
    };

    const findListIndex = (list: RatingCandidate[], personId: string) => {
        return list.findIndex((c) => c.person.id === personId);
    };

    const handlePersonClick = (personId: string) => () => {
        setSelectedPerson(personId);
        if (findListIndex(candidates, personId) === candidates.length - 1 && candidates.length < totalCount) {
            setPage(page + 1);
        }
    };

    const handleCloseDialog = () => setSelectedPerson(undefined);

    const handleNavigate = (index: number) => {
        setSelectedPerson(candidates[index].person.id);
        if (index === candidates.length - 1 && candidates.length < totalCount) {
            setPage(page + 1);
        }
    };

    const handleRatingChange = async (personId: string, reqId: string, rating: number) => {
        const result = await createRating({ variables: { personId, reqId, rating } });
        const updatedCandidates = produce(candidates, (draft) => {
            const record = draft.find((c) => c.person.id === personId);
            record.ratings = record.ratings
                .filter((r) => r.reqId !== reqId || r.user.id !== user.id)
                .concat(result.data.rating.returning[0]);
        });
        setCandidates(updatedCandidates);
    };

    if (!reqsData) {
        return <Loading />;
    }

    const filterMenuItems = reqsData.reqs.map((req) => (
        <MenuItem value={req.id} key={req.id}>
            {req.name}
        </MenuItem>
    ));

    const selectedReqs = reqsData.reqs.filter((r) => (reqFilter === 'all' ? true : r.id === reqFilter));
    const ratingsColumnHeaders = selectedReqs.map((r) => <TableCell key={r.id}>{r.name}</TableCell>);

    const rows = pageCandidates?.map((candidate) => {
        const cols = selectedReqs.map((req) => {
            const rating =
                (candidate.ratings.find((r) => r.reqId === req.id && r.user.id === user.id)?.rating ?? 0) *
                ratingPrecision;
            return (
                <TableCell key={req.id}>
                    <Rating
                        name="profile-rating"
                        precision={ratingPrecision}
                        value={rating}
                        emptyIcon={<StarBorder fontSize="inherit" />}
                        readOnly={true}
                    />
                </TableCell>
            );
        });
        return (
            <TableRow key={candidate.person.id}>
                <TableCell onClick={handlePersonClick(candidate.person.id)} className="person-link">
                    {candidate.person.name.full}
                </TableCell>
                {cols}
            </TableRow>
        );
    });

    const pagination = candidatesData ? (
        <TablePagination
            rowsPerPageOptions={[limit]}
            component="div"
            count={totalCount}
            rowsPerPage={limit}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        />
    ) : null;

    let profileDialog;
    if (selectedPerson) {
        const index = findListIndex(candidates, selectedPerson);
        const reqs = reqsData.reqs.filter((req) => (reqFilter === 'all' ? true : req.id === reqFilter));
        const content = profileData ? (
            <ProfileRatingCandidate
                profile={profileData.profile[0].content}
                viewedAt={profileData.profile[0].viewedAt}
                person={candidates[index].person}
                reqs={reqs}
                ratings={candidates[index].ratings}
                onRatingChange={handleRatingChange}
            />
        ) : (
            <div style={{ minHeight: '320px', width: '100%' }}>
                <Loading />
            </div>
        );
        profileDialog = (
            <Dialog
                open={true}
                onClose={handleCloseDialog}
                maxWidth="xl"
                fullWidth={true}
                className="profile-dialog-v4"
            >
                {content}
                <ProfileNavButtons
                    count={candidates.length}
                    currentIndex={findListIndex(candidates, selectedPerson)}
                    onNavigate={handleNavigate}
                />
            </Dialog>
        );
    }

    return (
        <div css={styles(theme)}>
            <div className="filter">
                <Select value={reqFilter} onChange={handleFilterChange} variant="outlined">
                    <MenuItem value="all">All Roles</MenuItem>
                    {filterMenuItems}
                </Select>
            </div>
            <div className="candidates-table">
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                {ratingsColumnHeaders}
                            </TableRow>
                        </TableHead>
                        <TableBody>{rows}</TableBody>
                    </Table>
                    {pagination}
                </TableContainer>
            </div>
            {profileDialog}
        </div>
    );
};
