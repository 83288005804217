import * as React from 'react';

import { NoteAttachment } from 'shared/models/note';

import { timeCalendar } from '../common/timestamp';
import { FileDownloadLink } from '../core-ui/file-download-link';
import { User } from './user';
import { UserAvatar } from './user-avatar';

interface NoteProps {
    content: string;
    format: string;
    createdBy: string;
    createdAt: number;
    edited: boolean;
    context: JSX.Element;
    className: string;
    options: JSX.Element;
    actions?: JSX.Element;
    attachments: NoteAttachment[];
}

const avatarSize = 32;

export const Note: React.FC<NoteProps> = (props) => {
    const { actions, format, content, createdAt, edited, context, options, className, attachments, createdBy } = props;
    const body =
        format === 'html' ? (
            <div dangerouslySetInnerHTML={{ __html: content }} />
        ) : (
            <div className="note-body-plaintext">{content}</div>
        );

    const attachmentsList = attachments.map((a) => {
        return (
            <FileDownloadLink key={a.key} path={a.key} filename={a.filename}>
                <div className="note-attachment">
                    <div className="list-centered-icon">
                        <i className="fas fa-file-alt attachment-icon" />
                        <span>{a.filename}</span>
                    </div>
                </div>
            </FileDownloadLink>
        );
    });
    const attachmentsSection =
        attachments.length > 0 ? <div className="note-attachments-section">{attachmentsList}</div> : null;

    return (
        <React.Fragment>
            <div className="note-header-bar">
                <div className="note-header flex-columns-container">
                    <UserAvatar id={createdBy} size={avatarSize} />
                    <div className="note-header-text flex-rows-container">
                        <div>
                            <span className="text600 title">
                                <User id={createdBy} />
                            </span>
                            <span className="secondary small2">
                                {timeCalendar(createdAt)} {edited ? '(edited)' : ''}
                            </span>
                        </div>
                        {context}
                    </div>
                </div>

                {options}
            </div>
            <div className={className}>{body}</div>
            {attachmentsSection}
            {actions}
        </React.Fragment>
    );
};
