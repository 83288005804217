import { css } from '@emotion/core';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Theme,
    Typography,
    useTheme
} from '@material-ui/core';
import { ViewColumn } from '@material-ui/icons';
import * as React from 'react';

interface TableColumnsSelectorProps {
    columns: string[];
    selected: { [column: string]: boolean };
    onSelect: (column: string, selected: boolean) => void;
}

const styles = css`
    background: white;
    margin: 0 0 0 15px;

    .MuiButton-outlined {
        padding: 5px;
        min-width: 48px;
        background: white;
    }
`;

const dialogStyles = (theme: Theme) => css`
    .MuiDialogContent-root,
    .MuiListItem-root,
    .MuiDialogTitle-root {
        border-bottom: thin solid ${theme.palette.divider};
    }

    .MuiDialogContent-root {
        padding-top: 0;
        padding-bottom: 0;
    }

    .MuiDialogTitle-root {
        padding-left: 32px;
        padding-right: 32px;
    }

    .MuiListItem-root {
        padding-left: 0;

        &:last-child {
            border-bottom: none;
        }
    }
`;

export const TableColumnsSelector: React.FC<TableColumnsSelectorProps> = ({ columns, selected, onSelect }) => {
    const theme = useTheme();
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const handleToggleDialog = () => {
        setDialogOpen(!dialogOpen);
    };

    const handleClick = (column: string) => () => {
        onSelect(column, !selected[column]);
    };

    const items = columns.map((column, index) => {
        return (
            <ListItem key={index} onClick={handleClick(column)}>
                <ListItemIcon>
                    <Checkbox color="primary" checked={!!selected[column]} />
                </ListItemIcon>
                <ListItemText>{column}</ListItemText>
            </ListItem>
        );
    });

    const dialog = dialogOpen ? (
        <Dialog open={true} onClose={handleToggleDialog} fullWidth={true} maxWidth="sm" css={dialogStyles(theme)}>
            <DialogTitle>
                <Typography variant="h4">Select Columns</Typography>
            </DialogTitle>
            <DialogContent>
                <List dense={true}>{items}</List>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleToggleDialog}>Close</Button>
            </DialogActions>
        </Dialog>
    ) : null;

    return (
        <div css={styles}>
            <Button variant="outlined" onClick={handleToggleDialog}>
                <ViewColumn />
            </Button>
            {dialog}
        </div>
    );
};
