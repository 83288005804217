import { Map } from 'immutable';
import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { EmailAddress } from 'shared/types/email-compose';

import { fetchAccounts } from '../email-inbox/actions';
import { EmailInbox } from '../email-inbox/components/email-inbox';
import { State } from '../state';

interface ConnectedProps {
    accounts: Map<string, EmailAddress>;
}

interface ConnectedDispatch {
    fetchAccounts: () => void;
}

type RouterConnectedProps = RouteComponentProps<{
    account: string;
}>;

type EmailInboxPageProps = ConnectedProps & ConnectedDispatch & RouterConnectedProps;

interface EmailInboxPageState {
    redirect: boolean;
}

class EmailInboxPageComponent extends React.Component<EmailInboxPageProps, EmailInboxPageState> {
    constructor(props: EmailInboxPageProps) {
        super(props);
        if (!props.accounts) {
            props.fetchAccounts();
        }
        const redirect = !props.match.params.account;
        this.state = { redirect };
    }

    componentDidUpdate(_1: EmailInboxPageProps, prevState: EmailInboxPageState) {
        if (!this.props.match.params.account && !prevState.redirect) {
            this.setState({ redirect: true });
        }
        if (this.props.match.params.account && prevState.redirect) {
            this.setState({ redirect: false });
        }
    }

    render() {
        if (!this.props.accounts || this.props.accounts.size === 0) return null;
        if (!this.props.match.params.account) {
            if (this.state.redirect) {
                const redirectToAccount: EmailAddress = this.props.accounts
                    .sortBy(
                        (a) => a,
                        (a1, a2) => a1.name.localeCompare(a2.name) || a1.address.localeCompare(a2.address)
                    )
                    .first(undefined);
                return <Redirect to={`/emails/${redirectToAccount.address}/`} />;
            } else {
                return null;
            }
        }
        const { account } = this.props.match.params;
        return <EmailInbox account={account} />;
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    accounts: state.emailInboxAccounts
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    fetchAccounts
};

export const EmailInboxPage = withRouter(
    connect<ConnectedProps, ConnectedDispatch, RouterConnectedProps>(
        mapStateToProps,
        mapDispatchToProps
    )(EmailInboxPageComponent)
);
