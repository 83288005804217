import { Map } from 'immutable';
import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';

import { checkSendAccess } from 'shared/common/email-access';
import { convertToPlainText } from 'shared/common/email-utils';
import { Permissions } from 'shared/models/permission';
import { ScheduledMessageView } from 'shared/models/scheduled-messages';
import { hasRole, UserData } from 'shared/models/user';

import { fetchJobsData } from '../actions';
import { CommunicationPreview } from '../sfc/communication-preview';
import { Candidate, EmailAccount, Job, ListState, State } from '../state';
import { ScheduledMessagesDetail } from './scheduled-messages-detail';

interface OwnProps {
    personId: string;
    jobId?: string;
    actions: JSX.Element[];
    scheduledMessage: ScheduledMessageView;
    emailAccounts: Map<string, EmailAccount>;
}

interface ConnectedProps {
    emailContentBlacklistedDomains: string;
    candidates: Map<string, Map<string, Candidate>>;
    listsState: Map<string, ListState>;
    sessionUser: UserData;
    jobs: Map<string, Job>;
    userPermissions: Permissions;
}

interface ConnectedDispatch {
    fetchJobsData: () => void;
}

type ScheduledMessageProps = OwnProps & ConnectedProps & ConnectedDispatch;

const ScheduledMessageComponent: React.FC<ScheduledMessageProps> = (props) => {
    const [expanded, setExpanded] = React.useState(false);

    const { emailAccounts, candidates, scheduledMessage, personId, jobId, listsState } = props;
    const {
        payload: { isClientComm },
        account,
        restricted
    } = scheduledMessage;

    React.useEffect(() => {
        if (listsState.get('jobs') !== 'initialized') {
            props.fetchJobsData();
        }
    });

    if (
        !emailAccounts ||
        !emailAccounts.get(props.scheduledMessage.account) ||
        listsState.get('jobs') !== 'initialized'
    ) {
        return null;
    }

    const emailAccount = emailAccounts.get(account);
    const candidate = jobId ? candidates.get(jobId).get(personId) : null;

    const handleToggleExpand = (open: boolean) => () => {
        setExpanded(open);
    };

    const checkActionAccess = () => {
        const { sessionUser, jobs, userPermissions } = props;
        const crossSubmitJobIds = candidate ? candidate.crossSubmitJobIds || [] : [];
        const crossSubmitJobs = crossSubmitJobIds.map((j) => jobs.get(j));
        const commJobs = jobId ? [jobs.get(jobId)].concat(crossSubmitJobs) : crossSubmitJobs;

        const personsCandidates: Candidate[] = [];
        for (const job of commJobs) {
            const jobCandidates = candidates.get(job.id);
            if (jobCandidates && jobCandidates.get(personId)) {
                personsCandidates.push(jobCandidates.get(personId));
            }
        }

        return (
            (!restricted || hasRole(userPermissions, 'can_modify_restricted_scheduled_messages')) &&
            checkSendAccess(sessionUser, userPermissions, emailAccount, {
                jobs: commJobs,
                personsCandidates
            })
        );
    };

    const fromCrossSubmit =
        !isClientComm && candidate && candidate.crossSubmitJobIds
            ? emailAccounts.get(account).jobId !== jobId && candidate.crossSubmitJobIds.includes(jobId)
            : false;
    const accountInitials =
        emailAccount.name.first.charAt(0).toLocaleUpperCase() + emailAccount.name.last.charAt(0).toLocaleUpperCase();

    const {
        headers: { subject },
        body,
        attachments
    } = props.scheduledMessage.payload;

    const snippetSize = 200; // Google uses ~200 characters (+/- a few to evenly break at a word)
    const snippet = convertToPlainText(body).slice(0, snippetSize);

    const actions = checkActionAccess() ? props.actions : [];
    return expanded ? (
        <ScheduledMessagesDetail
            scheduledMessage={props.scheduledMessage}
            actions={actions}
            handleClose={handleToggleExpand(false)}
            accountInitials={accountInitials}
            emailAccount={emailAccount}
            fromCrossSubmit={fromCrossSubmit}
            emailContentBlacklistedDomains={props.emailContentBlacklistedDomains}
        />
    ) : (
        <CommunicationPreview
            handleSelect={handleToggleExpand(true)}
            needsAction={false}
            sender={emailAccount.name.full}
            hasAttachments={attachments && attachments.length > 0}
            snippet={snippet}
            subject={subject}
            date={props.scheduledMessage.scheduledAt}
            fromCrossSubmit={fromCrossSubmit}
            accountInitials={accountInitials}
            isScheduledEmail={true}
        />
    );
};

const mapStateToProps = (state: State) => ({
    candidates: state.candidates,
    emailAccounts: state.emailAccounts,
    emailContentBlacklistedDomains: state.appConstants.constants.emailContentBlacklistedDomains,
    jobs: state.jobs,
    listsState: state.listsState,
    sessionUser: state.session.user,
    userPermissions: state.session.userPermissions
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    fetchJobsData
};

export const ScheduledMessage = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(ScheduledMessageComponent);
