import { Map } from 'immutable';
import * as _ from 'lodash';

import { EmailTemplateView } from 'shared/models/email-templates';

import { Action, ReceiveEmailTemplates, ReceiveUpdatedEmailTemplate, RequestTemplateDelete } from '../actions';

const emailTemplatesInitialState: Map<string, EmailTemplateView[]> = Map();

export function emailTemplates(state = emailTemplatesInitialState, action: Action): Map<string, EmailTemplateView[]> {
    switch (action.type) {
        case ReceiveEmailTemplates: {
            let newState = state;
            for (const template of action.payload.emailTemplates) {
                const group = template.group;
                newState = newState.set(
                    group,
                    (newState.get(group) || [])
                        .filter((t) => t.id !== template.id && t.id !== undefined)
                        .concat([template])
                );
            }
            return newState;
        }
        case ReceiveUpdatedEmailTemplate: {
            const group = action.payload.template.group;
            return state.set(
                group,
                (state.get(group) || [])
                    .filter((t) => t.id !== action.payload.template.id && t.id !== undefined)
                    .concat([action.payload.template])
            );
        }
        case RequestTemplateDelete: {
            const group = action.payload.template.group;
            const id = action.payload.template.id;
            return state.set(
                group,
                state.get(group).filter((t) => t.id !== id && t.id !== undefined)
            );
        }
        default:
            return state;
    }
}
