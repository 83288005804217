import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { SourcerSearchDetail } from '../components/sourcer-search-detail';

interface RouterProps {
    sourcerId: string;
}

const SourcerSearchNewPageComponent: React.SFC<RouteComponentProps<RouterProps>> = ({ match }) => (
    <SourcerSearchDetail sourcerId={match.params.sourcerId} />
);

export const SourcerSearchNewPage = withRouter(SourcerSearchNewPageComponent);
