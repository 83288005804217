import { Tooltip } from '@material-ui/core';
import React from 'react';

import { useSession } from '../../hooks/use-session';

export const UserSignatureInsertButton: React.FC<{ onSelect: (signature: string) => void }> = ({ onSelect }) => {
    const { user } = useSession();

    const handleClick = () => {
        onSelect(user.signature);
    };

    return user.signature ? (
        <Tooltip title="Insert Signature">
            <button className="icon-label" onClick={handleClick}>
                <i className="fas fa-signature" />
            </button>
        </Tooltip>
    ) : null;
};
