import { flatten } from 'lodash';

export const personTags = [
    '.net',
    'Startup',
    'accounting',
    'adtech',
    'agile',
    'analyst',
    'android',
    'artificial intelligence',
    'artist',
    'b2c',
    'backend',
    'biotech',
    'c#',
    'c++',
    'c2c',
    'computer vision',
    'consumer',
    'crypto',
    'cto',
    'cybersecurity',
    'd2c',
    'data',
    'deep learning',
    'devops',
    'director',
    'docker',
    'ecommerce',
    'embedded',
    'enterprise',
    'executive',
    'faang',
    'faang-like',
    'finance',
    'fintech',
    'frontend',
    'fullstack',
    'funding series a',
    'funding series b',
    'funding series c',
    'funding series d',
    'funding series e',
    'funding series f',
    'funding series g',
    'funding series seed',
    'gaming',
    'golang',
    'good communication',
    'graphql',
    'growth',
    'healthtech',
    'hr',
    'html',
    'hybrid',
    'ic',
    'in-office',
    'infrastructure',
    'insurtech',
    'ios',
    'iot',
    'java',
    'javascript',
    'jira',
    'kafka',
    'kotlin',
    'kubernetes',
    'lead',
    'machine learning',
    'manager',
    'market research',
    'marketing',
    'marketplace',
    'ml',
    'mobile',
    'mongodb',
    'node.js',
    'operations',
    'postgres',
    'pre-ipo',
    'product management',
    'python',
    'rails',
    'react',
    'react native',
    'remote',
    'ruby',
    'ruby on rails',
    'saas',
    'sdlc',
    'security',
    'sql',
    'sre',
    'strong soft skills',
    'swift',
    'technical writing',
    'terraform',
    'typescript',
    'ui',
    'ui/ux',
    'unicorn',
    'ux',
    'vp',
    'vue.js',
    'web development'
];

export const presetPersonTags = flatten(
    personTags.map((t) => {
        const tags: string[] = [];
        tags.push(t);
        tags.push('wants '.concat(t));
        tags.push('not '.concat(t));
        return tags;
    })
);

export const getTagType = (tag: string) => {
    const prefix = tag.trim().toLowerCase().split(' ')[0];
    switch (prefix) {
        case 'wants':
            return 'wants';
        case 'not':
            return 'not';
        default:
            return 'plain';
    }
};
