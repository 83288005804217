import { useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Dialog,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Theme,
    useTheme
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { capitalize, times } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';

import { fullDate } from '../common/timestamp';
import { DayPicker } from '../core-ui/react-day-picker';
import { Spinner } from '../core-ui/spinner';
import { SCHEDULED_OUTREACHES_BY_JOB, ScheduledOutreachByJob } from '../graphql/queries/outreach';

const skeletonRowsCount = 5;
const rowsPerPage = 10;

const styles = (theme: Theme) => css`
    flex: 1 1 auto;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .selectors {
        display: flex;
        flex: 0 0 auto;
        text-align: right;
        margin-bottom: 20px;
        width: 300px;
        justify-content: end;
        align-self: end;
        padding: 10px 0;

        .MuiFormControl-root {
            margin: 0 0 0 15px;
        }

        .MuiInputBase-root {
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }

            .MuiOutlinedInput-input {
                background: white;
                padding: 14px 14px;
            }
        }
    }

    .table {
        .table-pagination {
            text-align: right;
            padding: 18px 25px;
            font-size: 13px;
            text-transform: uppercase;
        }

        .table-footer {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
        }

        tr:hover {
            background: ${theme.palette.action.hover};
        }

        .table-header-cell {
            display: inline-flex;
            align-items: center;
        }

        .column-action-icon {
            display: inline-flex;
            align-items: center;
            opacity: 0;
            transition: opacity 200ms;
            margin-left: 5px;
            cursor: pointer;

            .MuiSvgIcon-root {
                font-size: 1.25rem;
                color: ${theme.palette.text.secondary};
            }

            &.visible {
                opacity: 1;
            }
        }

        th:hover {
            .column-action-icon {
                opacity: 1;
            }
        }
    }
`;

type Column = 'Recruiter' | 'Sending Time' | 'Requested Time' | 'Status' | 'Requested' | 'Added';

export const OutreachesByJob: React.FC<{ jobId: string }> = ({ jobId }) => {
    const theme = useTheme();

    const [page, setPage] = React.useState(0);

    const [startDateDialogState, setStartDateDialogState] = React.useState<boolean>(false);
    const [startDate, setStartDate] = useState(Date.now());

    const [endDateDialogState, setEndDateDialogState] = React.useState<boolean>(false);
    const [endDate, setEndDate] = useState(Date.now());

    const { data: outreachesData } = useQuery<
        { scheduled_outreaches: ScheduledOutreachByJob[] },
        { jobId: string; startDate: number; endDate: number }
    >(SCHEDULED_OUTREACHES_BY_JOB, {
        variables: {
            endDate: moment(endDate).endOf('day').valueOf(),
            jobId,
            startDate: moment(startDate).startOf('day').valueOf()
        }
    });

    if (!outreachesData) return <Spinner />;
    const outreaches = outreachesData?.scheduled_outreaches;

    const handleChangePage = (_1: any, newPage: number) => setPage(newPage);
    const handleChangeRowsPerPage = () => {
        /* no-op */
    };

    const columns: Column[] = ['Recruiter', 'Requested Time', 'Status', 'Requested', 'Sending Time', 'Added'];
    const headers = columns.map((col) => {
        return (
            <TableCell key={col}>
                <span className="table-header-cell">
                    <span>{col}</span>
                </span>
            </TableCell>
        );
    });

    const skeletonRows = times(skeletonRowsCount).map((i) => (
        <TableRow key={i}>
            <TableCell colSpan={columns.length}>
                <Skeleton variant="rect" />
            </TableCell>
        </TableRow>
    ));

    const rows =
        outreaches === undefined
            ? skeletonRows
            : outreaches.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((outreach) => {
                  return (
                      <TableRow key={`${outreach.id}`}>
                          <TableCell>{outreach.user.name}</TableCell>
                          <TableCell>{moment(outreach.requestedAt).format('MMM DD, YYYY hh:mm A')}</TableCell>
                          <TableCell>{capitalize(outreach.status)}</TableCell>
                          <TableCell>{outreach.limit}</TableCell>
                          <TableCell>{moment(outreach.scheduledAt).format('MMM DD, YYYY hh:mm A')}</TableCell>
                          <TableCell>{outreach.addedCount}</TableCell>
                      </TableRow>
                  );
              });

    const pagination =
        outreaches === undefined ? (
            <div className="table-pagination">Loading...</div>
        ) : outreaches.length > rowsPerPage ? (
            <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                component="div"
                count={outreaches.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        ) : (
            <div className="table-pagination">{outreaches.length} Outreaches</div>
        );

    const handleDatePickerOpen = (start: boolean) => () => {
        start ? setStartDateDialogState(true) : setEndDateDialogState(true);
    };

    const handleDateChange = (start: boolean) => (date: Date) => {
        start ? setStartDate(date.valueOf()) : setEndDate(date.valueOf());

        start ? setStartDateDialogState(false) : setEndDateDialogState(false);
    };
    const handleCloseDateDialog = (start: boolean) => () =>
        start ? setStartDateDialogState(false) : setEndDateDialogState(false);

    const startDateDialog = startDateDialogState ? (
        <Dialog open={true} onClose={handleCloseDateDialog(true)} maxWidth="md">
            <DayPicker
                defaultMonth={startDate ? new Date(startDate) : new Date()}
                onDayClick={handleDateChange(true)}
            />
        </Dialog>
    ) : null;

    const endDateDialog = endDateDialogState ? (
        <Dialog open={true} onClose={handleCloseDateDialog(false)} maxWidth="md">
            <DayPicker defaultMonth={endDate ? new Date(endDate) : new Date()} onDayClick={handleDateChange(false)} />
        </Dialog>
    ) : null;

    return (
        <div css={styles(theme)}>
            <div className="selectors">
                <TextField
                    label="Start Date"
                    value={startDate ? fullDate(startDate) : ''}
                    onClick={handleDatePickerOpen(true)}
                    variant="outlined"
                />
                {startDateDialog}

                <TextField
                    label="End Date"
                    value={endDate ? fullDate(endDate) : ''}
                    onClick={handleDatePickerOpen(false)}
                    variant="outlined"
                />
                {endDateDialog}
            </div>
            <TableContainer className="table">
                <Table stickyHeader={true}>
                    <TableHead>
                        <TableRow>{headers}</TableRow>
                    </TableHead>
                    <TableBody>{rows}</TableBody>
                </Table>
                <div className="table-footer">{pagination}</div>
            </TableContainer>
        </div>
    );
};
