/**
 * Adapted from https://usehooks.com/useLocalStorage/
 */

import { useState } from 'react';

import { getLocalStorageKey, setLocalStorageKey } from '../common/local-storage';
import { logger } from '../common/logger';

// Hook
export function useLocalStorage<T>(key: string, initialValue: T, expireAt = -1) {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>(() => {
        if (typeof window === 'undefined') {
            return initialValue;
        }
        try {
            // Get from local storage by key
            const item = getLocalStorageKey(key, initialValue);
            return item ?? initialValue;
        } catch (err) {
            // If error also return initialValue
            logger.warn(err, { message: 'error in useLocalStorage' });
            return initialValue;
        }
    });
    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value: T | ((val: T) => T)) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            if (typeof window !== 'undefined') {
                setLocalStorageKey(key, valueToStore, expireAt);
            }
        } catch (err) {
            // A more advanced implementation would handle the error case
            logger.warn(err, { message: 'error in useLocalStorage' });
        }
    };
    return [storedValue, setValue] as const;
}
