import { css } from '@emotion/core';
import { Theme, Typography, useTheme } from '@material-ui/core';
import { groupBy } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';

import { Interview } from '../../graphql/queries/home';
import { useUserContext } from '../../hooks/use-user-context';
import { Interviews } from './interviews';
interface PhoneCallsProps {
    interviews: Interview[];
}

// tslint:disable: no-magic-numbers
const styles = (theme: Theme) => css`
    .stats {
        display: flex;
        border-bottom: thin solid ${theme.palette.divider};

        .stat {
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            padding: ${theme.spacing(2, 2)};
            cursor: pointer;
            opacity: 0.65;

            &.selected {
                opacity: 1;
            }

            &:not(:last-of-type) {
                border-right: thin solid ${theme.palette.divider};
            }

            &:hover {
                background: ${theme.palette.grey[100]};
            }
        }
    }
`;
// tslint:enable: no-magic-numbers

const rowsPerPage = 10;

export const PhoneCalls: React.FC<PhoneCallsProps> = (props) => {
    const [window, setWindow] = useState<'today' | 'tomorrow' | 'later'>('today');
    const { user } = useUserContext();

    const theme = useTheme();
    const handleSelect = (w: 'today' | 'tomorrow' | 'later') => () => setWindow(w);

    let interviews;
    let stats;

    if (props.interviews) {
        const phoneCalls = props.interviews.filter((i) => i.kind === 'Rocket Screen');

        if (phoneCalls.length === 0) return null;

        const interviewGroups = groupBy(phoneCalls, (i) => {
            if (moment().isAfter(moment(i.startTime), 'day')) {
                return 'past';
            } else if (moment().isSame(moment(i.startTime), 'day')) {
                return 'today';
            } else if (moment().add(1, 'day').isSame(moment(i.startTime), 'day')) {
                return 'tomorrow';
            } else {
                return 'later';
            }
        });

        interviews = interviewGroups[window] ?? [];

        stats = ['today' as 'today', 'tomorrow' as 'tomorrow', 'later' as 'later'].map((w) => (
            <div className={`stat ${w === window ? 'selected' : ''}`} onClick={handleSelect(w)} key={w}>
                <Typography variant="h4" color="textPrimary">
                    {(interviewGroups[w] ?? []).length}
                </Typography>
                <Typography variant="overline" color="textSecondary">
                    {w}
                </Typography>
            </div>
        ));
    }

    return (
        <div css={styles(theme)}>
            <Interviews
                interviews={interviews}
                title="Phone Calls"
                header={<div className="stats">{stats}</div>}
                rowsPerPage={window === 'later' ? rowsPerPage : undefined}
                showIfEmpty={true}
                disableCollapse={!user?.id}
            />
        </div>
    );
};
