import { useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import { Theme, useTheme } from '@material-ui/core';
import React from 'react';

import { hiredStage } from 'shared/models/job-stages';
import { hasRole } from 'shared/models/user';
import { BaseFee, Fee, PLACEMENT_FEES } from '../graphql/queries/billing';
import { FeesProvider } from '../hooks/use-fees';
import { useSession } from '../hooks/use-session';
import { Candidate, Job } from '../state';
import { PlacementsBillingActions } from './placements-billing-actions';
import { PlacementsFeeDetails } from './placements-fee-details';

const styles = (theme: Theme) => css`
    border: thin solid ${theme.palette.divider};
    border-radius: 2px;
    background: white;
    margin-bottom: 20px;
    padding: 15px 18px;
    position: relative;

    .actions-button {
        position: absolute;
        bottom: 5px;
        right: 5px;
        opacity: 0;
        transition: opacity 0.2s;
    }

    &:hover {
        .actions-button {
            opacity: 1;
        }
    }

    .info-label {
        text-transform: uppercase;
        font-size: 10px;
        color: ${theme.palette.primary.light};
        margin-bottom: 4px;
        padding: 0 2px;
    }

    .info-value {
        font-size: 14px;
        margin-bottom: 12px;
        border-bottom: thin solid ${theme.palette.divider};
        padding: 0 2px 10px;

        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }
    }
`;

interface PlacementsBillingInfoProps {
    candidate: Candidate;
    job: Job;
}

export const PlacementsBillingInfo: React.FC<PlacementsBillingInfoProps> = ({ candidate, job }) => {
    const theme = useTheme();
    const { user, userPermissions } = useSession();
    const { data: feesData, refetch } = useQuery<{ fees: Fee[] }>(PLACEMENT_FEES, {
        skip: !(candidate?.stage === hiredStage) || !candidate?.personId || !job?.id,
        variables: { personId: candidate?.personId, jobId: job?.id }
    });

    if (!candidate || !job) {
        return null;
    }

    const canEdit =
        candidate?.stage === hiredStage &&
        ((job.accountManagerId === user.id && hasRole(userPermissions, 'account_manager')) ||
            hasRole(userPermissions, 'billing_admin'));

    const getNewFeeInitialData = canEdit
        ? (): BaseFee => ({
              accountManagerId: candidate.accountManagerId,
              amount: undefined,
              clientId: job.clientId,
              id: undefined,
              incurredAt: Date.now(),
              jobId: job.id,
              personId: candidate.personId,
              recruiterId: candidate.assignee,
              type: 'placement'
          })
        : undefined;

    const percentagesContent = (
        <>
            <div className="info-label">Recruiter Percentage</div>
            <div className="info-value">{candidate.recruiterPercentage}</div>
            <div className="info-label">AM Percentage</div>
            <div className="info-value">{candidate.accountManagerPercentage}</div>
        </>
    );

    let feesContent = null;
    if (feesData?.fees?.length) {
        const sortedFees = [...feesData.fees].sort((a, b) => b.incurredAt - a.incurredAt);

        if (sortedFees.length === 1) {
            feesContent = <PlacementsFeeDetails fee={sortedFees[0]} disabled={!canEdit} />;
        } else {
            const feeElements = sortedFees.map((fee) => (
                <PlacementsFeeDetails key={fee.id} fee={fee} disabled={!canEdit} showExpansion={true} />
            ));
            feesContent = <div className="fees-list">{feeElements}</div>;
        }
    }

    return (
        <FeesProvider
            data={feesData?.fees}
            refetch={refetch}
            disabled={!canEdit}
            getNewFeeInitialData={getNewFeeInitialData}
        >
            <div css={styles(theme)}>
                <PlacementsBillingActions candidate={candidate} disabled={!canEdit} />
                {feesContent}
                {percentagesContent}
            </div>
        </FeesProvider>
    );
};
