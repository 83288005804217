import { Tooltip } from '@material-ui/core';
import * as React from 'react';

import { timeRelativeDay } from '../common/timestamp';

interface CommunicationProps {
    handleSelect: () => void;
    needsAction: boolean;
    sender: string;
    hasAttachments: boolean;
    snippet: string;
    subject: string;
    date: number;
    accountInitials: string;
    fromCrossSubmit?: boolean;
    isScheduledEmail?: boolean;
}

export const CommunicationPreview: React.SFC<CommunicationProps> = ({
    handleSelect,
    fromCrossSubmit,
    hasAttachments,
    snippet,
    subject,
    date,
    sender,
    accountInitials,
    needsAction,
    isScheduledEmail
}) => {
    const attachmentIcon = hasAttachments ? (
        <div className="attachment-indicator-icon">
            <i className="material-icons list-icon">attach_file</i>
        </div>
    ) : null;
    const fromCrossSubmitIcon = isScheduledEmail ? (
        <Tooltip title="Scheduled Email">
            <div className="is-cross-submit-email">
                <i className="material-icons">schedule</i>
            </div>
        </Tooltip>
    ) : fromCrossSubmit ? (
        <Tooltip title="Email from Cross submission">
            <div className="is-cross-submit-email">
                <i className="material-icons">shuffle</i>
            </div>
        </Tooltip>
    ) : null;
    return (
        <div className={needsAction ? 'email email-needs-action' : 'email'} onClick={handleSelect}>
            <div className="account">
                {accountInitials}
                {fromCrossSubmitIcon}
            </div>
            <div className="sender">{sender}</div>
            <div className="preview">
                <span className="subject">{subject}</span>
                <span className="snippet" dangerouslySetInnerHTML={{ __html: snippet }} />
            </div>
            {attachmentIcon}
            <div className="date">{timeRelativeDay(date)}</div>
        </div>
    );
};
