import { gql } from '@apollo/client';

export interface EmailDeliveryStatsEnriched {
    job: {
        id: string;
        title: string;
        client: {
            id: string;
            name: string;
        };
    };
    account: string;
    emailsSent: number;
    emailsOpened: number;
    emailsReplied: number;
    recipientsAdded: number;
    recipientsReached: number;
    recipientsResponded: number;
}

export const GET_EMAIL_DELIVERY_STATS_BY_TIME_PERIOD = gql`
    query GetEmailDeliveryStatsByTimePeriod($startDate: date!, $endDate: date) {
        emailDeliveryStats: get_email_delivery_stats_by_time_period(
            args: { start_date: $startDate, end_date: $endDate }
        ) {
            job {
                id
                title
                client {
                    id
                    name
                }
            }
            account
            emailsSent
            emailsOpened
            emailsReplied
            recipientsAdded
            recipientsReached
            recipientsResponded
        }
    }
`;
