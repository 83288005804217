import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { RouteComponentProps } from 'react-router';

import { SearchResultsViewType } from 'shared/models/search';

import { Header } from '../components/header';
import { JobSearch } from '../components/job-search';
import { JobTitle } from '../containers/job-title';

interface RouterProps {
    jobId: string;
    searchId?: string;
    personId?: string;
    resultType?: SearchResultsViewType;
}

export const JobSearchPage: React.SFC<RouteComponentProps<RouterProps>> = (props) => {
    const {
        match,
        location: { state }
    } = props;
    return (
        <DocumentTitle title="Job Search">
            <div id="container">
                <Header title={<JobTitle id={match.params.jobId} />} />
                <div id="content" className="flex-fill">
                    <JobSearch
                        jobId={match.params.jobId}
                        searchId={match.params.searchId}
                        resultType={match.params.resultType}
                        personId={match.params.personId}
                        baseline={!!state?.baseline}
                    />
                </div>
            </div>
        </DocumentTitle>
    );
};
