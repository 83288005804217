import React, { createContext, FC, useContext } from 'react';
import { connect } from 'react-redux';

import { Session, State } from '../state';

const SessionContext = createContext<Session>(undefined);

const SessionProviderComp: FC<{ session: Session }> = ({ session, children }) => {
    return <SessionContext.Provider value={session}>{children}</SessionContext.Provider>;
};

const mapStateToProps = (state: State) => ({
    session: state.session
});

const SessionProvider = connect<{ session: Session }, undefined, {}>(mapStateToProps)(SessionProviderComp);

const useSession = () => useContext(SessionContext);

export { SessionProvider, useSession };
