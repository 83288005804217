import { css } from '@emotion/core';
import { FormControl, FormControlLabel, FormGroup, Radio, RadioGroup, Theme, useTheme } from '@material-ui/core';
import React from 'react';

import { CrunchbaseCompanyFilters } from 'shared/common/crunchbase-filters';

import { CompanyStatusForm } from './company-status-form';
import { EmployeesSizeForm } from './employees-size-form';
import { FoundingYearForm } from './founding-year-form';
import { FundingAmountForm } from './funding-amount-form';
import { FundingRoundsForm } from './funding-rounds-form';
import { IndustriesForm } from './industries-form';
import { IndustryGroupsForm } from './industry-groups-form';
import { InvestorsForm } from './investors-form';
import { LastFundingForm } from './last-funding-form';
import { LocationForm } from './location-form';

const styles = (theme: Theme) => css`
    .filter-field {
        border-top: thin solid ${theme.palette.divider};
        padding: 25px 0 15px;

        &.text-field {
            padding: 35px 0 35px;
        }

        &:first-child {
            border-top: none;
            padding-top: 5px;
        }

        .filter-label {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 11px;
            text-transform: uppercase;
            margin-bottom: 2px;
            pointer-events: none;

            .description {
                opacity: 0.6;
            }

            .values {
                opacity: 1;
                margin-right: 12px;
                font-weight: 600;
                font-size: 14px;
            }
        }

        .filter-form {
            .filter-slider {
                margin: 0 18px 30px 2px;
                width: calc(100% - 25px);

                .MuiSlider-rail,
                .MuiSlider-track {
                    height: 5px;
                    border-radius: 2px;
                }
                .MuiSlider-mark {
                    width: 1px;
                    height: 5px;
                }
                .MuiSlider-thumb {
                    height: 15px;
                    width: 15px;
                    margin-top: -5px;
                    margin-left: -5px;
                    background: white;
                    border: 2px solid ${theme.palette.primary.main};
                }
            }
        }
    }

    .name-match-radio {
        margin-bottom: 10px;

        .MuiFormGroup-root {
            flex-direction: row;

            .MuiFormControlLabel-root {
                margin-right: 32px;

                .MuiFormControlLabel-label {
                    font-size: 14px;
                }
            }
        }
    }
`;

export const CBFiltersForm: React.FC<{
    readonly: boolean;
    filters: CrunchbaseCompanyFilters;
    onChange: (updated: CrunchbaseCompanyFilters) => void;
}> = ({ readonly, filters, onChange }) => {
    const theme = useTheme();

    const handleChange =
        <T extends keyof CrunchbaseCompanyFilters>(key: T) =>
        (val: CrunchbaseCompanyFilters[T]) => {
            const updated = { ...filters, [key]: val };
            onChange(updated);
        };

    const handleLocationChange = (val: { countries?: string[]; regions?: string[]; cities?: string[] }) => {
        onChange({ ...filters, ...val });
    };

    const handleUseNameMatchChange = (_1: any, value: string) => {
        onChange({ ...filters, useNameMatch: value === 'true' });
    };

    const nameMatchFilter = filters?.useNameMatch ? (
        <FormControl component="fieldset">
            <FormGroup className="name-match-radio">
                <RadioGroup value={filters?.useNameMatch ? 'true' : 'false'} onChange={handleUseNameMatchChange}>
                    <FormControlLabel
                        value={'false'}
                        control={<Radio disabled={readonly} />}
                        label="Use Company Linkedin URL Match"
                    />
                    <FormControlLabel
                        value={'true'}
                        control={<Radio disabled={readonly} />}
                        label="Use Company Name Match"
                    />
                </RadioGroup>
            </FormGroup>
        </FormControl>
    ) : null;

    return (
        <div css={styles(theme)}>
            {nameMatchFilter}
            <InvestorsForm readonly={readonly} filters={filters?.investors} onChange={handleChange('investors')} />
            <FundingRoundsForm
                readonly={readonly}
                filters={filters?.fundingRounds}
                onChange={handleChange('fundingRounds')}
            />
            <LocationForm readonly={readonly} filters={filters} onChange={handleLocationChange} />
            <CompanyStatusForm readonly={readonly} filters={filters?.status} onChange={handleChange('status')} />
            <EmployeesSizeForm readonly={readonly} filters={filters?.size} onChange={handleChange('size')} />
            <FundingAmountForm readonly={readonly} filters={filters?.funding} onChange={handleChange('funding')} />
            <FoundingYearForm
                readonly={readonly}
                filters={filters?.foundingYear}
                onChange={handleChange('foundingYear')}
            />
            <LastFundingForm
                readonly={readonly}
                filters={filters?.lastFundingRound}
                onChange={handleChange('lastFundingRound')}
            />
            <IndustryGroupsForm
                readonly={readonly}
                filters={filters?.industryGroups}
                onChange={handleChange('industryGroups')}
            />
            <IndustriesForm readonly={readonly} filters={filters?.industries} onChange={handleChange('industries')} />
        </div>
    );
};
