import { gql } from '@apollo/client';

import { ProfileContent } from 'shared/models/profile';
export interface Profile {
    id: string;
    order: number;
    createdAt: number;
    person: {
        id: string;
        name: string;
        urls: Array<{
            url: string;
            invalid: boolean;
        }>;
    };
}

export interface ProfileRecord {
    person: {
        id: string;
        name: {
            first: string;
            last: string;
            full: string;
        };
        profilePicUrl: string;
    };
    content: ProfileContent;
    viewedAt: number;
}

export const PROFILES_PENDING_UPDATES = gql`
    query GetProfilesNeedingUrlMapping($limit: Int!, $offset: Int!) {
        profiles: profile_scraping_manual_refresh_needed(
            limit: $limit
            offset: $offset
            order_by: [{ order: asc }, { createdAt: desc_nulls_last }]
        ) {
            id
            order
            createdAt
            person {
                id
                name(path: "full")
                urls: profile_urls(
                    where: { url: { _ilike: "www.linkedin.com/in/%" }, invalid: { _eq: false } }
                    order_by: { createdAt: asc }
                ) {
                    url
                    invalid
                }
            }
        }
        count: profile_scraping_manual_refresh_needed_aggregate {
            aggregate {
                count
            }
        }
    }
`;

export const PROFILES_PROCESSED_TIMESTAMP = gql`
    subscription GetProfilesProcessingTracker {
        timestamp: profiles_processing_tracker_aggregate {
            aggregate {
                max {
                    createdAt
                }
            }
        }
    }
`;

export const MARK_INVALID_LINK = gql`
    mutation MarkInvalidLink($url: String!, $invalid: Boolean!) {
        update_profile_urls(where: { url: { _eq: $url } }, _set: { invalid: $invalid }) {
            affected_rows
        }
    }
`;

export const MARK_PROFILE_UPDATE_FAILED = gql`
    mutation MarkProfileUpdateFailed($objects: [profile_scraping_manual_refresh_failed_insert_input!]!) {
        insert_profile_scraping_manual_refresh_failed(
            objects: $objects
            on_conflict: { constraint: manual_refresh_failed_pkey, update_columns: [] }
        ) {
            affected_rows
        }
    }
`;

export const DELETE_PROFILE_UPDATE_FAILED = gql`
    mutation DeleteProfileUpdateFailed($personId: String!) {
        delete_profile_scraping_manual_refresh_failed(where: { personId: { _eq: $personId } }) {
            affected_rows
        }
    }
`;

export const GET_PROFILES_LINKEDIN_KEYWORDS = gql`
    query GetProfilesLinkedinKeywords($personIds: [String!]!) {
        keywords: profileLinkedinKeywords(personIds: $personIds)
    }
`;

export const GET_PERSON_PROFILE_LINKS = gql`
    query GetPersonProfileLinks($personId: String!) {
        person: persons_by_pk(id: $personId) {
            id
            personId: id
            profileUrls: profile_urls {
                id: url
                personId
                url
                invalid
                createdAt
                modifiedAt
            }
        }
    }
`;

export const GET_PROFILES = gql`
    query GetProfiles($personIds: [String!]!) {
        profiles: personProfiles(personIds: $personIds) {
            content
            viewedAt
            person {
                id
                name
                profilePicUrl
            }
        }
    }
`;
