import * as React from 'react';
import DocumentTitle from 'react-document-title';

import { Header } from '../components/header';
import { ScrumUsers } from '../containers/scrum-users';

export const ScrumUsersPage = () => (
    <DocumentTitle title="Scrum">
        <div id="container">
            <Header title="Scrum" />
            <div id="content" className="flex-fill">
                <ScrumUsers />
            </div>
        </div>
    </DocumentTitle>
);
