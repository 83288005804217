import * as React from 'react';

import { Header } from './header';
import { HeaderNavigationSelect } from './header-navigation-select';

export type BillingPageType = 'Placements' | 'Monthly Fees' | 'Fees' | 'Invoices';

export const billingPageRoutes: Array<[BillingPageType, string]> = [
    ['Placements', '/placements'],
    ['Monthly Fees', '/monthly-fees'],
    ['Fees', '/fees'],
    ['Invoices', '/invoices']
];

export const BillingPageHeader: React.FC<{
    actions?: JSX.Element[];
}> = ({ actions }) => {
    const titleDropdown = <HeaderNavigationSelect routes={billingPageRoutes} />;
    return <Header title={titleDropdown} actions={actions} />;
};
