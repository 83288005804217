import { Button, Dialog, Divider, IconButton, Menu, MenuItem, useTheme } from '@material-ui/core';
import { white } from 'material-ui/styles/colors';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { UserData } from 'shared/models/user';

import { requestSignout, toggleDrawer } from '../actions';
import { EnvHeader } from '../common/env-header';
import { PageDialogLink } from '../common/page-dialog-link';
import { previousWeekday } from '../common/timestamp';
import { usePageDialog } from '../hooks/use-page-dialog';
import { State } from '../state';
import { UserMetrics } from './metrics/user-metrics';
import { Notifications } from './notifications';
import { SearchBox } from './search-box';
import { UserDailyActivity } from './user-daily-activity';

interface OwnProps {
    title: JSX.Element | string;
    actions?: JSX.Element[];
}

interface ConnectedProps {
    user: UserData;
}

interface ConnectedDispatch {
    requestSignout: () => void;
    toggleDrawer: () => void;
}

type HeaderProps = OwnProps & ConnectedProps & ConnectedDispatch;

const HeaderComponent: React.FC<HeaderProps> = (props) => {
    const theme = useTheme();
    const { title, actions, user, children } = props;
    const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);
    const [menuOpen, setMenuOpen] = React.useState(false);

    const [activityDialogState, setActivityDialogState] = React.useState<number>(null);
    const { onClose } = usePageDialog();

    const lastDay = previousWeekday();

    const handleMenuButtonTap = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setAnchor(event.currentTarget);
        setMenuOpen(true);
    };

    const handleSignoutClick = (event: React.SyntheticEvent<{}>) => {
        event.preventDefault();
        props.requestSignout();
    };

    const handleActivityClick = (ts: number) => () => {
        setMenuOpen(false);
        setActivityDialogState(ts);
    };

    const handleCloseActivityDialog = () => setActivityDialogState(null);
    const handleMenuRequestClose = () => {
        setMenuOpen(false);
    };

    const userButtonStyles: React.CSSProperties = {
        borderRadius: '2px',
        color: white
    };

    const icon = onClose ? (
        <IconButton onClick={onClose}>
            <i className="material-icons">close</i>
        </IconButton>
    ) : (
        <IconButton onClick={props.toggleDrawer}>
            <i className="material-icons">menu</i>
        </IconButton>
    );

    const activityDialogContent = activityDialogState ? (
        <UserDailyActivity user={user} time={activityDialogState} />
    ) : null;

    const activityDialog = (
        <Dialog open={!!activityDialogState} onClose={handleCloseActivityDialog}>
            {activityDialogContent}
        </Dialog>
    );

    return (
        <div className="flex-fixed" id="page-header">
            <EnvHeader />
            <div className="page-header-primary">
                <div className="header-left">
                    <div className="header-left-menu">{icon}</div>
                    <div className="header-title">{title}</div>
                </div>
                <div className="header-right">
                    {actions}
                    <SearchBox />
                    <Button style={userButtonStyles} onClick={handleMenuButtonTap}>
                        {user.name.first}
                    </Button>
                    <Notifications />
                </div>
            </div>
            <div className="page-header-secondary">{children}</div>
            <Menu anchorEl={anchor} open={menuOpen} onClose={handleMenuRequestClose}>
                <MenuItem onClick={handleActivityClick(moment().valueOf())}>Today's Activity</MenuItem>
                <MenuItem onClick={handleActivityClick(lastDay.valueOf())}>
                    {lastDay.format('dddd')}'s Activity
                </MenuItem>
                <MenuItem>
                    <PageDialogLink
                        url={`/user/${user.username}/metrics`}
                        Component={UserMetrics}
                        componentProps={{ username: user.username }}
                    >
                        Recruiting Metrics
                    </PageDialogLink>
                </MenuItem>
                <Divider />
                <MenuItem>
                    <Link to="/account" style={{ color: theme.palette.text.primary }}>
                        Settings
                    </Link>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleSignoutClick}>Sign out</MenuItem>
            </Menu>
            {activityDialog}
        </div>
    );
};

const mapStateToProps = (state: State): ConnectedProps => ({ user: state.session.user });
const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    requestSignout,
    toggleDrawer
};
export const Header = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(HeaderComponent);
