import { Map } from 'immutable';

import { Action, ReceiveEmailCounts } from '../actions';
import { EmailCountInfo } from '../state';

const emailCountsInitialState: Map<string, EmailCountInfo> = Map();

export function emailCounts(state = emailCountsInitialState, action: Action) {
    switch (action.type) {
        case ReceiveEmailCounts:
            const { email, sent, scheduled } = action.payload;
            return state.set(email, { sent, scheduled: Map(scheduled) });
        default:
            return state;
    }
}
