import { useQuery } from '@apollo/client';
import React from 'react';
import DocumentTitle from 'react-document-title';

import { Duration, getDurationOptions } from '../../common/duration';
import { funnelMetricFields } from '../../common/metrics';
import { isoDate } from '../../common/timestamp';
import { CLIENT_METRICS, CLIENT_OUTREACH_METRICS, MetricsData, OutreachMetrics } from '../../graphql/queries/metrics';
import { useLocalStorage } from '../../hooks/use-local-storage';
import { ClientPageHeader } from '../client-page-header';
import { FunnelMetrics } from './funnel-metrics';

export const ClientMetrics: React.FC<{ clientId: string }> = ({ clientId }) => {
    const allUserSelectOpt = 'all';

    const durationPeriods = { days: 0, weeks: 2, months: 2, quarters: 4, years: 2, allTime: true };
    const durationOpts = getDurationOptions(durationPeriods);
    const defaultOption = durationOpts.find((opt) => opt.label === 'This Week');
    const [duration, setDuration] = useLocalStorage<Duration>('client-metrics-duration', defaultOption);
    const [viewType, setViewType] = useLocalStorage<'timeline' | 'funnel' | 'table' | 'outreach'>(
        'client-metrics-view',
        'funnel'
    );
    const [selectedUser, setSelectedUser] = React.useState<string>(allUserSelectOpt);

    const { data } = useQuery<
        { metrics: MetricsData[] },
        { clientId: string; kinds: string[]; startDate: string; endDate: string }
    >(CLIENT_METRICS, {
        variables: {
            clientId,
            endDate: isoDate(duration.end),
            kinds: funnelMetricFields,
            startDate: isoDate(duration.start)
        }
    });

    const { data: outreachData } = useQuery<
        {
            outreachMetrics: OutreachMetrics[];
        },
        { clientId: string; startDate: string; endDate: string }
    >(CLIENT_OUTREACH_METRICS, {
        variables: { clientId, endDate: isoDate(duration.end), startDate: isoDate(duration.start) }
    });

    return (
        <DocumentTitle title="Metrics">
            <div id="container">
                <ClientPageHeader clientId={clientId} actions={[]} activeTab="Metrics" />
                <FunnelMetrics
                    metrics={data?.metrics}
                    outreachMetrics={outreachData?.outreachMetrics}
                    selectedUser={selectedUser}
                    onSelectUser={setSelectedUser}
                    viewType={viewType}
                    onSelectView={setViewType}
                    duration={duration}
                    onChangeDuration={setDuration}
                    showJobTitle={true}
                />
            </div>
        </DocumentTitle>
    );
};
