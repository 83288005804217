export const pageBgColor = 'rgb(244, 246, 248)';
export const tabBackgroundColor = '#A9BED9';
export const tableBorderColor = '#e0e0e0';
export const borderColor = '#e0e0e0';
export const borderRadius = '2px';
export const buttonBorderColor = '#e0e0e0';
export const disabledColor = 'rgba(0, 0, 0, 0.3)';
export const primary1Color = ' #596d85';
export const secondaryTextColor = 'rgba(0, 0, 0, 0.54)';
export const okColor = '#8bc34a';
export const fontFamily = 'Roboto, sans-serif';

// tslint:disable: object-literal-sort-keys
export const zIndexes = {
    defaultDialog: 1500,
    emailComposeLayer: 1600,
    notificationsLayer: 1450,
    overEmailDialog: 1700,
    timePickerDialog: 1750,
    timePickerDropdown: 1800, // cspell:ignore dropdown
    modalDialog: 1900,
    snackBar: 2000
};
