import { css } from '@emotion/core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Theme,
    Tooltip,
    Typography,
    useTheme
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import React from 'react';
import { useModal } from '../hooks/use-modal';
import { useSnackbar } from '../hooks/use-snackbar';

interface LoadFilterProps<T extends { name: string }> {
    list: T[];
    onSelect: (index: number) => void;
    onDelete: (index: number) => void;
    disabled: boolean;
}

const buttonStyle = css`
    min-width: 48px;
    padding: 8.5px 0;
    background: white;

    i.fas {
        font-size: 16px;
    }
`;

const dialogStyle = (theme: Theme) => css`
    .MuiListItem-root {
        padding: 4px 12px;
        cursor: pointer;
        border-radius: ${theme.shape.borderRadius}px;

        .MuiListItemIcon-root {
            opacity: 0;
            transition: opacity 0.2ms;
            cursor: pointer;
        }

        .MuiListItemText-primary {
            font-size: 1rem;
            font-weight: 500;
        }

        &:hover {
            .MuiListItemIcon-root {
                opacity: 1;
            }
        }

        &:hover {
            background: ${theme.palette.action.hover};
        }
    }

    .info-text {
        line-height: 1.5rem;
        font-style: italic;
        color: rgba(0, 0, 0, 0.54);
    }
`;

export const LoadFilters = <T extends { name: string }>(props: LoadFilterProps<T>) => {
    const { list, onSelect, disabled, onDelete } = props;
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const theme = useTheme();
    const { getConfirmation } = useModal();
    const { setSnackbar } = useSnackbar();

    const handleToggleDialog = () => setDialogOpen(!dialogOpen);

    const handleSelect = (index: number) => () => {
        setSnackbar('Filters loaded');
        onSelect(index);
        setDialogOpen(false);
    };

    const handleDelete = (index: number, name: string) => () => {
        getConfirmation(
            () => {
                onDelete(index);
                setDialogOpen(false);
            },
            <span>
                This will delete the saved filter: <b>{name}</b>
            </span>,
            'Please confirm'
        );
    };

    const button = (
        <Tooltip title="Load Filters">
            <span>
                <Button css={buttonStyle} disabled={disabled} onClick={handleToggleDialog} variant="outlined">
                    <i className="fas fa-upload" />
                </Button>
            </span>
        </Tooltip>
    );

    const options = list.map((opt, i) => (
        <ListItem key={i}>
            <ListItemText primary={opt.name} onClick={handleSelect(i)} />
            <ListItemIcon onClick={handleDelete(i, opt.name)}>
                <Clear />
            </ListItemIcon>
        </ListItem>
    ));

    const infoText =
        list.length === 0 ? (
            <span className="info-text">
                No saved filters found to load. You can save the selected set of filters by clicking the save filters
                button
            </span>
        ) : null;

    const dialog = (
        <Dialog open={dialogOpen} onClose={handleToggleDialog} maxWidth="sm" fullWidth={true} css={dialogStyle(theme)}>
            <DialogTitle>
                <Typography variant="h4" component="span">
                    Load Filters
                </Typography>
            </DialogTitle>
            <DialogContent>
                <List dense={true}>{options}</List>
                {infoText}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleToggleDialog}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <>
            {button}
            {dialog}
        </>
    );
};
