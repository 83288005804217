import { Buffer } from 'buffer';
import { Map } from 'immutable';
import * as _ from 'lodash';
import { IconButton } from 'material-ui';
import * as React from 'react';
import { connect } from 'react-redux';
import { v4 } from 'uuid';

import { EmailAddress } from 'shared/types/email-compose';

import { filteredAttachmentsMimeTypes } from '../../common/communication-utils';
import { updateDraft } from '../../common/draft-storage';
import { logger } from '../../common/logger';
import { timeRelativeDay } from '../../common/timestamp';
import { composeEmail } from '../../email-compose/actions';
import { ComposeEmailWindowData } from '../../email-compose/types';
import { getHeaderValue } from '../../lib/email-markup';
import { State } from '../../state';
import { deleteThreads, discardDrafts, selectThread, updateThreadLabels, viewThreadDetails } from '../actions';
import { EmailInboxData, EmailThread } from '../types';
import { EmailThreadParticipants } from './email-inbox-thread-participants';

interface OwnProps {
    thread: EmailThread;
}

interface ConnectedProps {
    accounts: Map<string, EmailAddress>;
    data: EmailInboxData;
}

interface ConnectedDispatch {
    composeEmail: (payload: ComposeEmailWindowData) => void;
    deleteThreads: (threadIds: string[]) => void;
    discardDrafts: (threadIds: string[]) => void;
    selectThread: (threadId: string, checked: boolean) => void;
    updateThreadLabels: (threadIds: string[], labelsAdded: string[], labelsRemoved: string[]) => void;
    viewThreadDetails: (threadId: string) => void;
}

type EmailInboxThreadPreviewProps = ConnectedProps & ConnectedDispatch & OwnProps;

class EmailInboxThreadPreviewComponent extends React.Component<EmailInboxThreadPreviewProps, undefined> {
    threadVisibleMessages = () => {
        const { data, thread } = this.props;
        return thread.messages.filter((m) => {
            switch (data.selectedLabel) {
                case 'DRAFT':
                    return m.labels.indexOf('DRAFT') !== -1 && m.labels.indexOf('TRASH') === -1;
                case 'TRASH':
                    return m.labels.indexOf('TRASH') !== -1;
                default:
                    return m.labels.indexOf('DRAFT') === -1 && m.labels.indexOf('TRASH') === -1;
            }
        });
    };

    handleSelect = (e: React.SyntheticEvent<{}>) => {
        e.stopPropagation();
        const { thread, data } = this.props;
        const { selectedThreads } = data;
        const selected = selectedThreads.indexOf(thread.id) !== -1;
        this.props.selectThread(thread.id, !selected);
    };

    handleStar = (e: React.SyntheticEvent<{}>) => {
        e.stopPropagation();
        const starred = this.threadVisibleMessages().findIndex((m) => m.labels.indexOf('STARRED') !== -1) !== -1;
        const labelsAdded = starred ? [] : ['STARRED'];
        const labelsRemoved = starred ? ['STARRED'] : [];
        this.props.updateThreadLabels([this.props.thread.id], labelsAdded, labelsRemoved);
    };

    handleArchive = (e: React.SyntheticEvent<{}>) => {
        e.stopPropagation();
        this.props.updateThreadLabels([this.props.thread.id], [], ['INBOX']);
    };

    handleDelete = (e: React.SyntheticEvent<{}>) => {
        e.stopPropagation();
        this.props.deleteThreads([this.props.thread.id]);
    };

    handleReadUnread = (e: React.SyntheticEvent<{}>) => {
        e.stopPropagation();
        const messages = this.threadVisibleMessages();
        const latestMessage = messages[messages.length - 1];
        const unread = latestMessage.labels.indexOf('UNREAD') !== -1;
        const labelsAdded = unread ? [] : ['UNREAD'];
        const labelsRemoved = unread ? ['UNREAD'] : [];
        this.props.updateThreadLabels([this.props.thread.id], labelsAdded, labelsRemoved);
    };

    handleViewThread = () => {
        if (this.props.data.selectedLabel !== 'DRAFT') {
            this.props.viewThreadDetails(this.props.thread.id);
        } else {
            const message = this.threadVisibleMessages()[0];
            const base64Content = message.body;
            const body = Buffer.from(base64Content, 'base64').toString('utf8');
            const subject = message.subject;
            const windowId = !subject
                ? `draft-${message.messageId}`
                : subject.match(/^Re:/)
                  ? `reply-${message.account}-${message.messageId}`
                  : subject.match(/^Fwd:/)
                    ? `forward-${message.account}-${message.messageId}`
                    : v4();
            const headers = {
                bcc: message.headers.bcc,
                cc: message.headers.cc,
                inReplyTo: getHeaderValue(message.rawHeaders)('In-Reply-To'),
                references: getHeaderValue(message.rawHeaders)('References'),
                subject,
                to: message.headers.to
            };
            const { accounts, data } = this.props;
            const account = accounts.get(data.account);
            const payload: ComposeEmailWindowData = {
                account,
                accountOptions: [account],
                attachments: [],
                body,
                draftSavedAt: null,
                headers,
                threadId: message.threadId,
                windowId
            };
            this.props.discardDrafts([this.props.thread.id]);
            this.props.composeEmail(payload);
            updateDraft(`email-compose-${payload.windowId}`, {
                body: payload.body,
                subject: payload.headers.subject
            }).catch((err) => {
                logger.warn(err, { body, message: 'error saving email draft', subject });
            });
        }
    };

    render() {
        const { thread, data } = this.props;
        const { account, selectedThreads } = data;
        const messages = this.threadVisibleMessages();
        if (messages.length === 0) return null;
        const selected = selectedThreads.indexOf(thread.id) !== -1;
        const latestMessage = messages[messages.length - 1];
        const unread = latestMessage.labels.indexOf('UNREAD') !== -1;
        const snippet = latestMessage.snippet;
        const subject = messages[0].subject;
        const starred = messages.findIndex((m) => m.labels.indexOf('STARRED') !== -1) !== -1;
        const archived = latestMessage.labels.indexOf('INBOX') === -1;
        const inTrash = latestMessage.labels.indexOf('TRASH') !== -1;
        const starIcon = starred ? (
            <i className="material-icons email-inbox-thread-starred">star</i>
        ) : (
            <i className="material-icons email-inbox-thread-star">star_border</i>
        );
        const checkbox = selected ? 'check_box' : 'check_box_outline_blank';

        let threadHasAttachment = false;
        for (const message of thread.messages) {
            if (message.attachments.filter((a) => !filteredAttachmentsMimeTypes.has(a.mimeType)).length > 0) {
                threadHasAttachment = true;
                break;
            }
        }
        const attachmentIcon = threadHasAttachment ? (
            <div className="email-inbox-attachment-indicator">
                <i className="material-icons list-icon">attach_file</i>
            </div>
        ) : null;
        const threadActions = [];
        if (data.selectedLabel !== 'TRASH' && data.selectedLabel !== 'DRAFT') {
            threadActions.push(
                <IconButton
                    className="email-inbox-thread-action-button"
                    tooltip="Archive"
                    disabled={archived}
                    onClick={this.handleArchive}
                    key="archive"
                >
                    <i className="material-icons">archive</i>
                </IconButton>
            );
            threadActions.push(
                <IconButton
                    className="email-inbox-thread-action-button"
                    tooltip="Delete"
                    disabled={inTrash}
                    onClick={this.handleDelete}
                    key="delete"
                >
                    <i className="material-icons">delete</i>
                </IconButton>
            );
            threadActions.push(
                <IconButton
                    className="email-inbox-thread-action-button"
                    tooltip={`Mark as ${unread ? 'read' : 'unread'}`}
                    onClick={this.handleReadUnread}
                    key="readUnread"
                >
                    <i className={`fas fa-envelope${unread ? '-open' : ''}`} />
                </IconButton>
            );
        }
        return (
            <div
                className={`email-inbox-thread ${unread ? 'unread' : ''} ${selected ? 'selected' : ''}`}
                onClick={this.handleViewThread}
            >
                <div className="email-inbox-thread-select-star">
                    <IconButton style={{ marginRight: -10 }} onClick={this.handleSelect}>
                        <i className="material-icons email-inbox-thread-select">{checkbox}</i>
                    </IconButton>
                    <IconButton onClick={this.handleStar}>{starIcon}</IconButton>
                </div>
                <div className="email-inbox-thread-senders no-wrap">
                    <EmailThreadParticipants thread={thread} account={account} selectedLabel={data.selectedLabel} />
                </div>
                <div className="email-inbox-thread-subject-snippet no-wrap">
                    <span className={unread ? 'unread' : ''}>{subject}</span>
                    <span className="email-inbox-thread-subject-snippet-separator">-</span>
                    <div className="secondary" dangerouslySetInnerHTML={{ __html: snippet }} />
                </div>
                {attachmentIcon}
                <div className="email-inbox-thread-date">
                    <span className={unread ? 'unread' : ''}>{timeRelativeDay(latestMessage.internalDate)}</span>
                </div>
                <div className="email-inbox-thread-actions">{threadActions}</div>
            </div>
        );
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    accounts: state.emailInboxAccounts,
    data: state.emailInbox
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    composeEmail,
    deleteThreads,
    discardDrafts,
    selectThread,
    updateThreadLabels,
    viewThreadDetails
};

export const EmailInboxThreadPreview = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(EmailInboxThreadPreviewComponent);
