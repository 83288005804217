// cspell:ignore oRD3gokPjuc9xE1bpvJUuoYn
/**
 * Custom logger - Uses console log for development and sends logs to server for production
 */
import { Logtail } from '@logtail/browser';
import { ILogtailLog } from '@logtail/types';

import { config } from '../config';
import { store } from '../configure-store';
import { notifyBugSnag } from './bugsnag';

const logtail = new Logtail('oRD3gokPjuc9xE1bpvJUuoYn');

logtail.use(async (log: ILogtailLog) => {
    const user = store?.getState()?.session?.user?.email;
    return {
        ...log,
        appVersion: config.AppVersion,
        user
    };
});

// tslint:disable
const consoleLogger = {
    assert: console.assert,
    debug: console.debug,
    error: console.error,
    info: console.info,
    trace: console.trace,
    warn: console.warn
};
// tslint:enable

const formattedLogArgs = (message: string | Error, context: any): [string | Error, any] => {
    if (!message && context && context.hasOwnProperty('message')) {
        message = context.message;
        delete context.message;
    } else if (context && context.hasOwnProperty('message') && message) {
        context.__message__ = context.message;
        delete context.message;
    }
    return [message, context];
};

const logtailLogger = {
    assert: (_1: string | Error, _2: any) => {
        /* no-op */
    },
    debug: (_1: string | Error, _2: any) => {
        /* no-op */
    },
    error: (message: string | Error, context: any) => {
        if (message instanceof Error) {
            notifyBugSnag(message.message, {
                context,
                stack: message.stack
            });
        } else {
            notifyBugSnag(message, context);
        }
        logtail.error(...formattedLogArgs(message, context));
    },
    info: (message: string | Error, context: any) => logtail.info(...formattedLogArgs(message, context)),
    trace: (_1: string | Error, _2: any) => {
        /* no-op */
    },
    warn: (message: string | Error, context: any) => logtail.warn(...formattedLogArgs(message, context))
};

type Logger = (message: string | Error, context?: any) => void;

export const logger: {
    assert: Logger;
    debug: Logger;
    error: Logger;
    info: Logger;
    trace: Logger;
    warn: Logger;
} = config.Env === 'production' ? logtailLogger : consoleLogger;
