import { Map } from 'immutable';

import { FilePayload } from 'shared/types/file-payload';

import { Action, AddNoteAttachments, RemoveNoteAttachments } from '../actions';

const initialState: Map<string, FilePayload[]> = Map();

export function noteAttachments(state = initialState, action: Action) {
    switch (action.type) {
        case AddNoteAttachments:
            return state.set(action.payload.noteDraftKey, action.payload.attachments);
        case RemoveNoteAttachments:
            return state.remove(action.payload.noteDraftKey);
        default:
            return state;
    }
}
