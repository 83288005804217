import { Menu, MenuItem, Popover } from 'material-ui';
import * as React from 'react';

interface EditorTagProps {
    tagOptions: string[];
    tagCssClass?: string;
}

export const EditorTag: React.FC<EditorTagProps> = ({ tagOptions, tagCssClass }) => {
    const menuBufferHeight = 5;
    const maxMenuHeight = 200;
    const [menuOptions, setMenuOptions] = React.useState<string[]>([]);
    const [initializingRect, setInitializingRect] = React.useState(false);
    const [rect, setRect] = React.useState<{ top: number; left: number; height: number }>(null);

    let keyboardEventListener: () => void = null;

    const getCurrentText = () => {
        const sel = window.getSelection();
        if (sel.rangeCount) {
            if (sel.anchorNode.nodeName === '#text') {
                return sel.anchorNode.textContent;
            }
        } else {
            return null;
        }
    };

    const getValidOptions = () => {
        const text = getCurrentText();
        if (text && text.indexOf('@') !== -1) {
            const parts = text.split('@');
            const last = parts[parts.length - 1].toLowerCase().replace(/\xA0\;/g, ' ');
            const options = tagOptions.filter(
                (opt) => opt.toLowerCase().indexOf(last) === 0 && opt.toLowerCase().length !== last.length
            );
            return options;
        } else {
            return [];
        }
    };

    const keyDownHandler = () => {
        setTimeout(() => {
            // need to force to next paint to get things to sync up
            const options = getValidOptions();
            setMenuOptions(options);
            if (options.length === 0) {
                setRect(null);
            }
        }, 0);
    };

    const getNodeBoundingRect = (node: Node): { top: number; left: number; height: number } => {
        if (node.nodeName === 'DIV') {
            return (node as any).getBoundingClientRect();
        } else if (node.parentNode) {
            return getNodeBoundingRect(node.parentNode);
        } else {
            return null;
        }
    };

    const handleClose = () => {
        setMenuOptions([]);
        setRect(null);
    };

    const selectMenuItem = (opt: string) => () => {
        const sel = window.getSelection();
        if (sel.rangeCount) {
            if (sel.anchorNode.nodeName === '#text') {
                const text = sel.anchorNode.textContent;
                if (text && text.indexOf('@') !== -1) {
                    const parts = text.split('@');
                    const last = `@${parts[parts.length - 1]}`;
                    sel.anchorNode.textContent = ``;

                    const range = sel.getRangeAt(0);

                    const node1 = document.createElement('span');
                    node1.innerText = '\xA0';
                    range.insertNode(node1);

                    const node2 = document.createElement('span');
                    node2.className = tagCssClass || '';
                    node2.innerText = `@${opt}`;
                    range.insertNode(node2);

                    const node3 = document.createElement('span');
                    node3.innerText = `${text.substring(0, text.lastIndexOf(last))} `;
                    range.insertNode(node3);

                    // Move the caret immediately after the inserted span
                    range.setStartAfter(node2);
                    range.collapse(true);
                    sel.removeAllRanges();
                    sel.addRange(range);
                }
            }
        }
        handleClose();
    };

    React.useEffect(() => {
        if (keyboardEventListener) {
            document.removeEventListener('keydown', keyboardEventListener);
        }
        keyboardEventListener = keyDownHandler;
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
            keyboardEventListener = null;
        };
    }, [tagOptions]);

    React.useEffect(() => {
        if (menuOptions.length > 0 && !rect && !initializingRect) {
            setInitializingRect(true);
        }
    }, [menuOptions]);

    React.useEffect(() => {
        if (initializingRect) {
            const sel = window.getSelection();
            if (sel.rangeCount) {
                if (sel.anchorNode.nodeName === '#text') {
                    const range = sel.getRangeAt(0).cloneRange();
                    if (range.getBoundingClientRect) {
                        setRect(range.getBoundingClientRect());
                    }
                } else {
                    try {
                        const boundingRect = getNodeBoundingRect(sel.anchorNode);
                        setRect(boundingRect);
                    } catch (_) {
                        /** no-op */
                    }
                }
            }
            setInitializingRect(false);
        }
    }, [initializingRect]);

    if (menuOptions.length === 0 || !rect) {
        return null;
    }

    const selectorStyle = {
        transform: `translate(${rect.left}px, ${rect.top + rect.height + menuBufferHeight}px)`,
        transition: 'all 0ms'
    };

    const menuItems = menuOptions.map((opt) => <MenuItem primaryText={opt} key={opt} onClick={selectMenuItem(opt)} />);

    return (
        <Popover open={true} onRequestClose={handleClose} useLayerForClickAway={false} style={selectorStyle}>
            <Menu desktop={true} maxHeight={maxMenuHeight} disableAutoFocus={true}>
                {menuItems}
            </Menu>
        </Popover>
    );
};
