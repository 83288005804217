import moment from 'moment';

// tslint:disable:object-literal-sort-keys

enum ISODays {
    Monday = 1,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
    Sunday
}

const defaultRelativeTime = {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    ss: '%d seconds',
    m: 'a minute',
    mm: '%d minutes',
    h: 'an hour',
    hh: '%d hours',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years'
};

const shortRelativeTime = {
    future: 'in %s',
    past: '%s ago',
    s: 'now',
    ss: 'now',
    m: '1h',
    mm: '1h',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1mo',
    MM: '%dmo',
    y: '1y',
    yy: '%dy'
};

const calendarFormat = {
    lastDay: '[Yesterday,] LT',
    sameDay: '[Today,] LT',
    nextDay: '[Tomorrow,] LT',
    lastWeek: '[last] dddd[,] LT',
    nextWeek: 'dddd[,] LT',
    sameElse: 'L'
};

// tslint:enable:object-literal-sort-keys

const momentForTimeDiff = (ts: number, short = false) => {
    const shortFormatDaysThreshold = 90;
    const longFormatDaysThreshold = 28;
    if (short) {
        moment.relativeTimeThreshold('d', shortFormatDaysThreshold);
    } else {
        moment.relativeTimeThreshold('d', longFormatDaysThreshold);
    }
    const format = short ? shortRelativeTime : defaultRelativeTime;
    moment.updateLocale('en', { relativeTime: format });
    return moment(ts);
};

export const timeFrom = (ts: number, short = false) => {
    return momentForTimeDiff(ts, short).fromNow(short);
};

export const timeTill = (ts: number, short = false) => {
    return momentForTimeDiff(ts, short).toNow(short);
};

moment.updateLocale('en', { calendar: calendarFormat });

export const timeCalendar = (ts: number) => {
    return moment(ts).calendar();
};

export const timeMonthYear = (ts: number) => {
    const date = moment(ts);
    return date.format('MMM YYYY');
};

export const timeMonthYearUTC = (ts: number) => {
    const date = moment.utc(ts);
    return date.format('MMM YYYY');
};

export const timeMonthDate = (ts: number) => {
    const date = moment(ts);
    const now = moment.now();
    const maxHoursForRelative = -21;
    if (date.diff(now, 'hours') > maxHoursForRelative) {
        return timeFrom(ts, true);
    } else if (date.isSame(now, 'year')) {
        return date.format('MMM DD');
    } else {
        return date.format('MMM DD, YYYY');
    }
};

export const timeRelativeDay = (ts: number) => {
    const date = moment(ts);
    const now = moment.now();
    if (date.isSame(now, 'day')) {
        return date.format('h:mm A');
    } else if (date.isSame(now, 'year')) {
        return date.format('MMM D');
    } else {
        return date.format('MM/D/YY');
    }
};

export const fullDateTime = (ts: number) => {
    const date = moment(ts);
    return date.format('MMM DD, YYYY, h:mm A');
};

export const fullDate = (ts: number) => {
    const date = moment(ts);
    return date.format('MMM DD, YYYY');
};

export const getWeekdaySince = (ts: number) => {
    const now = moment();
    let diff = -1; // do not count partial days
    let date = moment(ts);
    while (date.isBefore(now)) {
        if (date.isoWeekday() <= ISODays.Friday) {
            diff++;
        }
        date = moment(date).add(1, 'day');
    }
    return diff;
};

export const interviewTime = (ts: number) => {
    const date = moment(ts);
    const now = moment.now();
    if (date.isSame(now, 'day') || date.isSame(moment().add(1, 'day'), 'day')) {
        return date.format('hh:mm A');
    } else if (date.isSame(now, 'year')) {
        return date.format('MMM D');
    } else {
        return date.format('MM/D/YY');
    }
};

export const callTime = (ts: number) => {
    const date = moment(ts);
    const now = moment.now();
    if (date.isSame(now, 'day')) {
        return date.format('hh:mm A');
    } else if (date.isSame(now, 'year')) {
        return date.format('MMM DD hh:mm A');
    } else {
        return date.format('MM/DD/YY hh:mm A');
    }
};

export const callDuration = (seconds: number) => {
    // tslint:disable:no-magic-numbers
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds - hours * 3600) / 60);
    const secondsLeft = seconds - minutes * 60 - hours * 3600;
    return `${hours > 0 ? `${hours}:` : ''}${`00${minutes}`.substr(-2)}:${`00${secondsLeft}`.substr(-2)}`;
    // tslint:enable:no-magic-numbers
};

export const previousWeekday = () => {
    let date = moment().subtract(1, 'day');
    while (date.isoWeekday() > ISODays.Friday) {
        date = date.subtract(1, 'day');
    }
    return date;
};

export const diffInDays = (start: number, end: number): number => {
    return moment.duration(moment(end).diff(moment(start))).asDays();
};
export const getPreviousWeeks = (p: number) => {
    return moment().subtract(p, 'weeks').valueOf();
};
export const getPreviousMonths = (m: number) => {
    return moment().subtract(m, 'months').valueOf();
};

export const getClosestWorkingDay = (ts: number) => {
    let result = moment(ts).startOf('day').valueOf();
    do {
        const day = moment(result).day();
        // tslint:disable-next-line:no-magic-numbers
        if (day === 0 || day === 6) {
            result = moment(result).subtract(1, 'day').valueOf();
        } else {
            break;
        }
    } while (true);
    return result;
};

export const isoDate = (ts: number) => {
    return moment(ts).format('YYYY-MM-DD');
};

export const chartDate = (start: number, end: number) => {
    const monthDuration =
        moment(start).endOf('month').valueOf() === moment(end).valueOf() &&
        moment(start).startOf('month').valueOf() === moment(start).valueOf();
    const getDateString = (ts: number) => {
        if (monthDuration) {
            return moment().isSame(moment(ts), 'year') ? moment(ts).format('MMMM') : moment(ts).format('MMM YYYY');
        } else {
            return moment().isSame(moment(ts), 'year')
                ? moment(ts).format('MMM DD')
                : moment(ts).format('MMM DD, YYYY');
        }
    };
    const startStr = getDateString(start);
    const endStr = getDateString(end);
    if (startStr === endStr) {
        return startStr;
    } else {
        return `${startStr} - ${endStr}`;
    }
};
