import { css } from '@emotion/core';
import React from 'react';

import { ProfileEducation } from 'shared/models/profile';

import { LightTooltip as Tooltip } from '../core-ui/light-tooltip';

interface SchoolInfoProps {
    education: ProfileEducation;
    highlight?: (content: string) => string | JSX.Element | Array<string | JSX.Element>;
}

const defaultRank = 111111;

const tooltipStyles = css`
    padding: 5px 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: left;

    .school-data {
        margin: 5px 0 15px;

        .school-data-section-header {
            text-transform: uppercase;
            font-size: 10px;
            margin-bottom: 3px;
            opacity: 0.6;
        }
    }
`;

export const SchoolInfo: React.FC<SchoolInfoProps> = ({ education, highlight }) => {
    const tippyContent = (
        <div css={tooltipStyles}>
            <div className="school-data">
                <div className="school-data-section-header">Country</div>
                {education?.meta?.schoolCountry ?? ''}
            </div>
            <div className="school-data">
                <div className="school-data-section-header">CS Rank</div>
                {education?.meta?.schoolCSRank ?? ''}
            </div>
            <div className="school-data">
                <div className="school-data-section-header">Overall Rank</div>
                {education?.meta?.schoolOverallRank ?? ''}
            </div>
        </div>
    );

    const hasRealData =
        (education?.meta?.schoolCSRank && education?.meta?.schoolCSRank !== defaultRank) ||
        (education?.meta?.schoolOverallRank && education?.meta?.schoolOverallRank !== defaultRank);

    return hasRealData ? (
        <Tooltip title={tippyContent} interactive={true} placement="bottom-start">
            <span className="title">
                <span className="school-name">{highlight?.(education.schoolName) ?? education.schoolName}</span>
            </span>
        </Tooltip>
    ) : (
        <span className="title">
            <span className="school-name no-ranks">{highlight?.(education.schoolName) ?? education.schoolName}</span>
        </span>
    );
};
