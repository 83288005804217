import { css } from '@emotion/core';
import { Map } from 'immutable';
import { Chip, CircularProgress, FlatButton, RadioButton, RadioButtonGroup, TextField } from 'material-ui';
import * as React from 'react';
import { connect } from 'react-redux';

import { updatePerson } from '../actions';
import { parseCompensation } from '../common/parse-compensation';
import { JobTitle } from '../containers/job-title';
import { Person, RequestErrors, State } from '../state';

const styles = css`
    .see-more {
        cursor: pointer;
        font-size: 14px;
        margin-top: 10px;
    }
`;

interface OwnProps {
    person: Person;
    jobId: string;
}

interface ConnectedProps {
    pendingRequests: Map<string, RequestErrors>;
}

interface ConnectedDispatch {
    updatePerson: (id: string, updates: Partial<Person>) => void;
}

type PersonCompensationProps = OwnProps & ConnectedDispatch & ConnectedProps;

interface PersonCompensationState {
    value: string;
    kind: string;
    active: boolean;
    errorText: string;
    moreSalaries: boolean;
}

const defaultState: PersonCompensationState = {
    active: false,
    errorText: null,
    kind: null,
    moreSalaries: false,
    value: ''
};

class PersonCompensationComponent extends React.Component<PersonCompensationProps, PersonCompensationState> {
    private textInput: HTMLInputElement;

    constructor(props: PersonCompensationProps) {
        super(props);
        this.state = defaultState;
    }

    textRef = (input: any) => {
        this.textInput = input;
    };

    requestKey = () => {
        return `person-update-request-${this.props.person.id}`;
    };

    componentDidUpdate(prevProps: PersonCompensationProps, prevState: PersonCompensationState) {
        if (prevProps.person.id !== this.props.person.id) {
            this.setState(defaultState);
        } else {
            if (!prevState.active && this.state.active) {
                this.textInput.focus();
            }
            const requestKey = this.requestKey();
            if (this.props.pendingRequests.has(requestKey) && !prevProps.pendingRequests.has(requestKey)) {
                this.setState(defaultState);
            }
        }
    }

    handleUpdateValue = (_: any, value: string) => {
        this.setState({ value, errorText: null });
    };

    handleUpdateKind = (_: any, kind: string) => {
        this.setState({ kind });
    };

    setActive = () => {
        this.setState({ active: true });
    };

    cancelAdd = () => {
        this.setState({ active: false, value: '', kind: null, errorText: null });
    };

    handleSeeMoreSalaries = () => {
        this.setState({ moreSalaries: !this.state.moreSalaries });
    };

    updatePerson = () => {
        const { person, jobId } = this.props;
        const { value, kind } = this.state;
        const { valid, formatted } = parseCompensation(value);
        if (!valid) {
            this.setState({ errorText: 'Enter a range (120K-130K) or a value (120K)' });
        } else {
            const salary = (person.salary || []).concat([{ value: formatted, kind, jobId }]);
            this.props.updatePerson(person.id, { salary });
        }
    };

    render() {
        const { pendingRequests, person } = this.props;
        const { value, kind, active, errorText } = this.state;
        const isSaving = pendingRequests.has(this.requestKey()) && pendingRequests.get(this.requestKey()).isEmpty();
        const spinnerSize = 20;
        const spinnerThickness = 2;
        const saveEnabled = !!kind && value.length > 0;

        const salaryKindLabels = Map({
            accepted: 'Will Accept',
            desired: 'Desired',
            volunteered: 'Volunteered'
        });

        let salariesSeen = Map<string, boolean>();
        const reversedSalaries = person.salary.slice().reverse();
        const visibleItems = 3;
        const length = this.state.moreSalaries
            ? reversedSalaries.length
            : Math.min(visibleItems, reversedSalaries.length);
        let salaries: JSX.Element[] = [];
        for (let i = 0; i < length; i++) {
            const salary = reversedSalaries[i];
            const key = `${salary.jobId}-${salary.kind}`;
            if (salariesSeen.has(key)) {
                continue;
            } else {
                salariesSeen = salariesSeen.set(key, true);
                const jobTitleElement = salary.jobId ? (
                    <div className="person-prop-row-secondary">
                        <JobTitle id={salary.jobId} />
                    </div>
                ) : null;
                const salaryLabelStyle = {
                    fontSize: '10px',
                    lineHeight: '20px',
                    paddingLeft: '10px',
                    paddingRight: '10px'
                };
                salaries = [
                    <div key={i} className="person-prop-row">
                        <div className="person-prop-row-spaced">
                            <div>
                                {salary.value}
                                {jobTitleElement}
                            </div>
                            <Chip labelStyle={salaryLabelStyle}>{salaryKindLabels.get(salary.kind)}</Chip>
                        </div>
                    </div>
                ].concat(salaries);
            }
        }

        const actions = isSaving ? (
            <div>
                <CircularProgress size={spinnerSize} thickness={spinnerThickness} />
            </div>
        ) : active ? (
            <div className="form-actions">
                <FlatButton label="Cancel" disabled={isSaving} onClick={this.cancelAdd} />
                <FlatButton label="Save" disabled={isSaving || !saveEnabled} onClick={this.updatePerson} />
            </div>
        ) : null;

        const radioButtons = salaryKindLabels
            .map((v, k) => <RadioButton value={k} label={v} className="radio-salary-option" key={k} />)
            .valueSeq();
        const form = active ? (
            <div className="person-prop-form person-salary-form">
                <TextField
                    value={value}
                    ref={this.textRef}
                    hintText="Add Salary"
                    onChange={this.handleUpdateValue}
                    errorText={errorText}
                    fullWidth={true}
                />
                <RadioButtonGroup
                    name="salary_type"
                    className="radio-salary-type"
                    onChange={this.handleUpdateKind}
                    valueSelected={kind}
                >
                    {radioButtons}
                </RadioButtonGroup>
                <div className="action-button">{actions}</div>
            </div>
        ) : (
            <div className="placeholder" onClick={this.setActive}>
                Add Salary
            </div>
        );

        const seeMoreSalaries =
            reversedSalaries.length > visibleItems ? (
                <div className="see-more" onClick={this.handleSeeMoreSalaries}>
                    {this.state.moreSalaries ? 'See less' : `See ${reversedSalaries.length - visibleItems} more`}
                </div>
            ) : null;

        const notEmptyProps = salaries.length > 0 ? 'person-props-not-empty' : '';
        return (
            <div css={styles} className={`person-props person-props-compensation ${notEmptyProps}`}>
                <i className="prop-icon material-icons section-icon">attach_money</i>
                <div className="prop">
                    {salaries}
                    {seeMoreSalaries}
                    {form}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: State): any => ({
    pendingRequests: state.pendingRequests
});

const mapStateToDispatch = {
    updatePerson
};

export const PersonCompensation = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapStateToDispatch
)(PersonCompensationComponent);
