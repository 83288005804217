import { Button, IconButton, LinearProgress, Menu, MenuItem, TextField, Tooltip } from '@material-ui/core';
import { isEqual } from 'lodash';
import { CircularProgress } from 'material-ui';
import { NavigationMoreVert } from 'material-ui/svg-icons';
import React, { ChangeEvent, FormEvent, MouseEvent, useRef, useState } from 'react';
import Dropzone, { DropzoneInputProps, DropzoneRef, DropzoneRootProps } from 'react-dropzone';

import { BASELINE_SEARCH_SORT_RANK, SEARCH_PREVIEW_LIMIT, SearchProject } from 'shared/models/search';
import { hasRole, UserData } from 'shared/models/user';

import { purgeSearch, refreshSearch } from '../actions';
import { getSQLForConfig } from '../api';
import { getStatusLabel } from '../common/searches';
import { UserSelector } from '../components/user-selector';
import { useCodeModal } from '../hooks/use-code-modal';
import { useModal } from '../hooks/use-modal';
import { useReduxDispatch } from '../hooks/use-redux';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';
import { Search as SearchType, SearchStatus } from '../state';
import { SearchConfigUI } from './search-config-ui';
import { SearchLoadSavePreset } from './search-load-save-preset';
import { SearchSectionsSelectMenu } from './search-sections-select-menu';
import { previewEstimateTooManyResultsError, previewEstimateUnknownError, useSearch } from './use-search';

export const Search: React.FC = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const dropzoneRef = useRef<DropzoneRef>(null);
    const { setSnackbar } = useSnackbar();
    const { getConfirmation, setAlert } = useModal();
    const { showCodeModal } = useCodeModal();
    const { user, userPermissions } = useSession();

    const {
        data,
        savedData,
        estimatedCount,
        onChange,
        onFieldChange,
        readonly,
        job,
        preview,
        onEstimateCount,
        onCancel,
        onSave,
        onPreview,
        fetchingResults,
        isPreviewOutdated,
        updating
    } = useSearch();

    const dispatch = useReduxDispatch();

    const handleStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
        onFieldChange('status')(event.target.value as any);
    };

    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        onFieldChange('name')(event.target.value);
    };

    const handleDownload = () => {
        setAnchorEl(null);
        const { minimumSkillScore, name, config, resultsSort, userId, project } = data;
        const dataToDownload = { config, minimumSkillScore, name, project, querySql: '', resultsSort, userId };
        const blob = new Blob([JSON.stringify(dataToDownload)], { type: 'application/json' });
        saveAs(blob, `${data.name}-data.json`);
    };

    const handleDrop = (files: File[]) => {
        const reader = new FileReader();
        reader.readAsText(files[0]);
        reader.onloadend = () => {
            try {
                const uploaded = JSON.parse(reader.result as string) as SearchType;
                uploaded.id = data.id;
                uploaded.jobId = data.jobId;
                uploaded.status = SearchStatus.Initial;
                uploaded.project = data.project;
                if (data.sortRank === BASELINE_SEARCH_SORT_RANK) {
                    uploaded.sortRank = BASELINE_SEARCH_SORT_RANK;
                    uploaded.name = data.name;
                } else {
                    uploaded.name = `Copy of ${uploaded.name}`;
                }
                uploaded.createdBy = user.id;
                uploaded.userId = user.id;
                onChange(uploaded);
            } catch (err) {
                setAlert('Invalid Search File', 'Could not parse search configuration');
            }
        };
    };

    const handleRefresh = () => {
        setAnchorEl(null);
        getConfirmation(
            () => {
                dispatch(refreshSearch(data.id));
            },
            `Are you sure you wish to refresh the results for this search? ` +
                `Any results that no longer match will be removed, ` +
                `and any new results will be added. This may take a few minutes.`,
            'Refresh Search Results'
        );
    };

    const handlePurge = () => {
        setAnchorEl(null);
        getConfirmation(
            () => {
                setSnackbar('Search results purge requested');
                dispatch(purgeSearch(data as SearchType));
            },
            'Are you sure? This will remove all candidates from this search that were not added to job via a thumbs up/down and that have not received outreach yet',
            'Purge Search Results'
        );
    };

    const handleDiscard = () => {
        onChange(savedData);
    };

    const handlePreview = (limit: boolean) => () => {
        onPreview(limit ? SEARCH_PREVIEW_LIMIT : null);
    };

    const handleUploadRequest = (_1: FormEvent<{}>) => {
        setAnchorEl(null);
        getConfirmation(
            dropzoneRef.current.open,
            'Are you sure you wish to upload a file? All changes will be overwritten',
            'Upload Warning'
        );
    };

    const handlePrintSqlForConfig = () => {
        setAnchorEl(null);
        setAlert(
            'Loading SQL...',
            <div style={{ textAlign: 'center' }}>
                <LinearProgress variant="indeterminate" />
            </div>,
            true
        );
        getSQLForConfig(data.config, job?.id).then((value) => {
            if (value.success) {
                showCodeModal('Search SQL', value.data.sql);
            }
        });
    };

    const handlePrintJSON = () => {
        setAnchorEl(null);
        showCodeModal('Search JSON', JSON.stringify(data, null, 2));
    };

    const activeUserFilter = (u: UserData) => {
        return u.status === 'active';
    };

    const handleBottomMenuClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleBottomMenuClose = () => {
        setAnchorEl(null);
    };

    const canUpdateSearch = userPermissions.search_create_access;

    const menuButton = (
        <IconButton onClick={handleBottomMenuClick} key="left-actions-menu">
            <NavigationMoreVert className="icon-button-more" />
        </IconButton>
    );

    const menuItems = [];
    menuItems.push(
        <MenuItem dense={true} key="download" onClick={handleDownload}>
            Download
        </MenuItem>
    );
    if (!readonly) {
        menuItems.push(
            <MenuItem dense={true} key="upload-menu-item" onClick={handleUploadRequest}>
                Upload
            </MenuItem>
        );
    }
    menuItems.push(
        <MenuItem dense={true} key="print-config" onClick={handlePrintSqlForConfig}>
            Print SQL
        </MenuItem>
    );
    menuItems.push(
        <MenuItem dense={true} key="print-json" onClick={handlePrintJSON}>
            Print JSON
        </MenuItem>
    );
    const canPurge =
        savedData.status === data.status &&
        savedData.status === SearchStatus.Paused &&
        (user.id === data.userId || user.id === job?.accountManagerId || hasRole(userPermissions, 'settings_editor'));
    if (canPurge) {
        menuItems.push(
            <MenuItem dense={true} key="purge" disabled={updating} onClick={handlePurge}>
                Purge
            </MenuItem>
        );
    }
    if (
        data.status === SearchStatus.Active &&
        (user.id === data.userId || data.project !== SearchProject.Titan || hasRole(userPermissions, 'settings_editor'))
    ) {
        menuItems.push(
            <MenuItem dense={true} key="refresh" disabled={updating} onClick={handleRefresh}>
                Refresh Results
            </MenuItem>
        );
    }

    const leftActions: JSX.Element[] = [menuButton];
    leftActions.push(
        <Menu
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            getContentAnchorEl={null}
            key="action-items"
            onClose={handleBottomMenuClose}
        >
            {menuItems}
        </Menu>
    );

    if (
        data.status === SearchStatus.Active ||
        (data.status === SearchStatus.Paused && data.sortRank !== BASELINE_SEARCH_SORT_RANK)
    ) {
        const statusOptions = [SearchStatus.Active, SearchStatus.Paused].map((status) => (
            <MenuItem key={status} value={status}>
                {getStatusLabel(data, status)}
            </MenuItem>
        ));
        const disabledStatusSelector =
            (!canUpdateSearch || user.id !== data.userId) && data.project === SearchProject.Titan;
        const statusSelectField = (
            <TextField
                value={data.status}
                onChange={handleStatusChange}
                select={true}
                InputProps={{ disableUnderline: true }}
                disabled={disabledStatusSelector}
                key="status-select-field"
            >
                {statusOptions}
            </TextField>
        );
        leftActions.push(statusSelectField);
    }

    const rightActions = [];
    if (data.status === SearchStatus.Initial) {
        rightActions.push(
            <Button key="close" onClick={onCancel}>
                Cancel
            </Button>
        );

        const countBtnKey = 'get-count';
        const countBtnDisabled = estimatedCount !== null;
        const countBtnLabel =
            estimatedCount === null
                ? 'Estimate Count'
                : estimatedCount === previewEstimateTooManyResultsError
                  ? `25,000+ Results`
                  : estimatedCount === previewEstimateUnknownError
                    ? 'Error getting count'
                    : `${estimatedCount.toLocaleString()} Estimated Results`;
        const countButton =
            estimatedCount === null && fetchingResults ? (
                <CircularProgress key={countBtnKey} size={20} thickness={2} /> // tslint:disable-line
            ) : (
                <Button key={countBtnKey} disabled={countBtnDisabled} onClick={onEstimateCount}>
                    {countBtnLabel}
                </Button>
            );
        rightActions.push(countButton);

        const hasCurrentPreview = preview?.fetched && !isPreviewOutdated;

        const editorHasSql = data.querySql && data.querySql.trim() !== '';
        if (
            editorHasSql ||
            (estimatedCount &&
                estimatedCount !== previewEstimateTooManyResultsError &&
                estimatedCount !== previewEstimateUnknownError)
        ) {
            if (fetchingResults) {
                rightActions.push(<CircularProgress key="preview" size={20} thickness={2} />); // tslint:disable-line
            } else {
                if (estimatedCount >= SEARCH_PREVIEW_LIMIT) {
                    rightActions.push(
                        <Button key="preview" onClick={handlePreview(true)}>
                            Preview
                        </Button>
                    );
                }
                rightActions.push(
                    <Button key="full-preview" onClick={handlePreview(false)}>
                        {estimatedCount >= SEARCH_PREVIEW_LIMIT ? 'Full Preview' : 'Preview'}
                    </Button>
                );
            }
        }

        if (data.userId && hasCurrentPreview) {
            const saveDisabled = data.name?.trim() === '';
            rightActions.push(
                <Tooltip title={saveDisabled ? 'A title for the search is required' : ''} key="save-tooltip">
                    <span>
                        <Button onClick={onSave} disabled={saveDisabled}>
                            Save
                        </Button>
                    </span>
                </Tooltip>
            );
        }
    } else {
        const changed = !isEqual(data, savedData);
        if (changed) {
            rightActions.push(
                <Button key="revert" onClick={handleDiscard}>
                    Discard Changes
                </Button>
            );
            rightActions.push(
                <Button key="update" onClick={onSave}>
                    Update
                </Button>
            );
        } else {
            rightActions.push(
                <Button key="close" onClick={onCancel}>
                    Back
                </Button>
            );
        }
    }

    const userSelector = (
        <UserSelector
            onSelect={onFieldChange('userId')}
            value={data.userId}
            label={null}
            userFilter={activeUserFilter}
            InputProps={{ disableUnderline: true }}
            disabled={data.status !== SearchStatus.Initial}
            style={{ flex: '1 0 auto' }}
        />
    );
    const title = (
        <div className="job-search-title">
            <TextField
                multiline={true}
                value={data.name}
                onChange={handleNameChange}
                disabled={!canUpdateSearch}
                className="job-search-title-input"
                fullWidth={true}
                InputProps={{ disableUnderline: true, style: { fontSize: 20, fontWeight: 500 } }}
                placeholder="Add a descriptive title for this search"
            />
            {userSelector}
        </div>
    );

    const jobSearchActions = (
        <div className="job-search-actions">
            <div className="job-search-actions-left">{leftActions}</div>
            <div className="job-search-actions-right">{rightActions}</div>
        </div>
    );

    const getDropzoneSection = (
        getRootProps: <T extends DropzoneRootProps>(props?: T) => T,
        getInputProps: <T extends DropzoneInputProps>(props?: T) => T
    ) => {
        return (
            <section style={{ display: 'none' }}>
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                </div>
            </section>
        );
    };

    const dropZone = (
        <Dropzone key="upload" ref={dropzoneRef} onDrop={handleDrop}>
            {({ getRootProps, getInputProps }) => getDropzoneSection(getRootProps, getInputProps)}
        </Dropzone>
    );

    return (
        <div className="job-search-container">
            <div className="job-search-info">
                {title}
                <div className="job-search-type-select">
                    <SearchLoadSavePreset />
                    <SearchSectionsSelectMenu />
                </div>
                <SearchConfigUI />
                {jobSearchActions}
                {dropZone}
            </div>
        </div>
    );
};
