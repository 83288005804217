import React from 'react';

import { ContactType } from 'shared/models/contact';

import { SelectField, TextField } from '../core-ui/form-fields';
import { JobFormData } from '../graphql/queries/job-form';
import { useFormContext } from './form-context';

enum PreferredContactType {
    PersonalOnly = 'Personal emails only',
    PersonalPreferred = 'Personal emails preferred, use work emails if personal not found',
    WorkOnly = 'Work emails only',
    WorkPreferred = 'Work emails preferred, use personal emails if work not found'
}

function convertToContactTypes(preferredContactType: PreferredContactType) {
    switch (preferredContactType) {
        case PreferredContactType.PersonalOnly:
            return [ContactType.Personal];
        case PreferredContactType.PersonalPreferred:
            return [ContactType.Personal, ContactType.Unknown, ContactType.Work];
        case PreferredContactType.WorkOnly:
            return [ContactType.Work];
        case PreferredContactType.WorkPreferred:
            return [ContactType.Work, ContactType.Unknown, ContactType.Personal];
    }
}

export const JobFormOutreach: React.FC = () => {
    const { data, onFieldChange, disabled, getError } = useFormContext<JobFormData>();

    const contactTypeOptions = [
        PreferredContactType.PersonalOnly,
        PreferredContactType.PersonalPreferred,
        PreferredContactType.WorkOnly,
        PreferredContactType.WorkPreferred
    ].map((type) => ({ label: type, value: convertToContactTypes(type) }));

    return (
        <div>
            <div className="job-field-row">
                <SelectField
                    label="Outreach Email Type"
                    value={data.contactTypes}
                    onChange={onFieldChange('contactTypes')}
                    options={contactTypeOptions}
                    disabled={disabled}
                />
            </div>
            <div className="job-field-row">
                <TextField
                    label="Max Daily Outreach"
                    value={data.dailyEmailLimit}
                    onChange={onFieldChange('dailyEmailLimit')}
                    errorText={getError('dailyEmailLimit')}
                    disabled={disabled}
                    type="number"
                />
                <TextField
                    label="Max Daily Outreach Per User"
                    value={data.dailyPerUserEmailLimit}
                    onChange={onFieldChange('dailyPerUserEmailLimit')}
                    errorText={getError('dailyPerUserEmailLimit')}
                    disabled={disabled}
                    type="number"
                />
            </div>
            <div className="job-field-row">
                <TextField
                    label="Max Search Size for Auto Outreach"
                    value={data.mlMaxResultsAutoOutreach}
                    onChange={onFieldChange('mlMaxResultsAutoOutreach')}
                    errorText={getError('mlMaxResultsAutoOutreach')}
                    disabled={disabled}
                    type="number"
                />
                <TextField
                    label="Max Outreach Before Manual Review Needed"
                    value={data.mlAutoOutreachLimit}
                    onChange={onFieldChange('mlAutoOutreachLimit')}
                    errorText={getError('mlAutoOutreachLimit')}
                    disabled={disabled}
                    type="number"
                />
            </div>
        </div>
    );
};
