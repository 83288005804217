import { css } from '@emotion/core';
import * as React from 'react';
import { fullDate } from '../common/timestamp';

import { JobTitle } from '../containers/job-title';
import { Person } from '../state';

const styles = css`
    .person-prop-row-spaced {
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .job-title-row {
        margin-bottom: -20px;
    }

    .timestamp {
        margin-top: 5px;
    }
`;

export const PersonRecruitingActivity: React.FC<{ person: Person; jobId?: string }> = ({ person, jobId }) => {
    if (person.recruitingActivity?.length === 0) return null;
    const rows = person.recruitingActivity
        .filter((activity) => !jobId || activity.jobId === jobId)
        .map((activity, i) => {
            const jobTitle = jobId ? null : (
                <div className="job-title-row">
                    <JobTitle id={activity.jobId} />
                </div>
            );
            return (
                <div className="person-prop-row-spaced" key={i}>
                    <div>
                        {activity.value}
                        <div className="person-prop-row-secondary">
                            {jobTitle}
                            <div className="timestamp">{fullDate(activity.createdAt)}</div>
                        </div>
                    </div>
                </div>
            );
        });
    return (
        <div className="person-props person-props-recruiting-activity">
            <i className="fas fa-arrow-circle-right section-icon" />
            <div className="prop">
                <div className="person-prop-row" css={styles}>
                    {rows}
                </div>
            </div>
        </div>
    );
};
