import { css } from '@emotion/core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Theme,
    Typography,
    useTheme
} from '@material-ui/core';
import * as React from 'react';
import { crossAddCandidates } from '../api';
import { JobTitle } from '../containers/job-title';

import { JobsListDialog } from '../containers/jobs-list-dialog';

const addToJobStyle = (theme: Theme) => css`
    .add-candidates-confirm-description {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        > b {
            margin: 0 5px;
        }
    }
    .add-candidate-title {
        border-bottom: thin solid ${theme.palette.divider};
    }
`;

interface CandidatesSearchAddProps {
    personIds: string[];
    onClose: () => void;
    onCancel: () => void;
}

export const CandidatesSearchAdd: React.FC<CandidatesSearchAddProps> = (props) => {
    const theme = useTheme();
    const { personIds, onCancel, onClose } = props;
    const [addingJob, setAddingJob] = React.useState<boolean>(false);
    const [jobId, setJobId] = React.useState<string>(undefined);
    const [dialogOpen, setDialogOpen] = React.useState(true);

    const handleAdd = async () => {
        setAddingJob(true);
        await crossAddCandidates({
            jobId,
            personIds
        });
        setJobId(null);
        setAddingJob(false);
        onClose();
    };

    const handleCancel = () => {
        setJobId(null);
        onCancel();
    };

    const handleJobSelect = (id: string) => {
        setDialogOpen(false);
        setJobId(id);
    };

    const closeDialog = () => {
        setDialogOpen(false);
        if (jobId === undefined) {
            onCancel();
        }
    };

    const jobsFilter = () => true;

    const jobSelectDialog = dialogOpen ? (
        <JobsListDialog
            sourceJobId={null}
            onRequestClose={closeDialog}
            onJobSelect={handleJobSelect}
            jobsFilter={jobsFilter}
            multiple={false}
        />
    ) : null;

    const addPersonsToJob =
        jobId?.length > 0 ? (
            <Dialog open={jobId?.length > 0 ? true : false} onClose={handleCancel} css={addToJobStyle(theme)}>
                <DialogTitle className="add-candidate-title">
                    <Typography variant="h4" component="span">
                        Add Candidates
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <div className="add-candidates-confirm-description">
                        Confirm adding {personIds?.length} candidates to{' '}
                        <b>
                            <JobTitle id={jobId} />
                        </b>
                    </div>
                    <DialogActions>
                        <Button key="add-to-job-id" onClick={handleAdd} disabled={addingJob}>
                            {addingJob ? 'Adding' : 'Add to Job'}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        ) : null;

    return (
        <React.Fragment>
            {jobSelectDialog}
            {addPersonsToJob}
        </React.Fragment>
    );
};
