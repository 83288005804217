import { css } from '@emotion/core';
import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Paper,
    Theme,
    Typography,
    useTheme
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import React from 'react';

const styles = (theme: Theme) => css`
    margin: 18px 0 36px;

    &.MuiExpansionPanel-root.Mui-expanded {
        margin: 20px auto 35px;
    }

    box-shadow: none;
    border: thin solid ${theme.palette.divider};
    border-radius: ${theme.shape.borderRadius}px;

    .no-header-content {
        padding: 30px 36px 0;
    }

    &.MuiExpansionPanel-root:before {
        background-color: transparent;
    }

    .MuiExpansionPanelSummary-root {
        padding: 0 36px;
    }

    .MuiExpansionPanelDetails-root {
        padding: 16px 36px 0;
        flex-direction: column;
    }

    .MuiExpansionPanelSummary-content {
        margin: 16px 0;

        &.Mui-expanded {
            margin: 24px 0;
        }
    }

    .MuiFormLabel-root.Mui-disabled,
    .MuiInputBase-root.Mui-disabled {
        color: inherit;
        opacity: 0.8;
    }

    .job-field-row {
        display: flex;
        gap: 40px;
        margin-bottom: 40px;
    }

    .job-field-row.narrow {
        margin-bottom: 20px;
    }
`;

type JobFormPanelProps = { title: string; subtitle: string } | { title?: undefined; subtitle?: undefined };

export const JobFormPanel: React.FC<JobFormPanelProps> = ({ title, subtitle, children }) => {
    const theme = useTheme();

    if (!title) {
        return (
            <Paper css={styles(theme)}>
                <div className="no-header-content">{children}</div>
            </Paper>
        );
    }
    return (
        <ExpansionPanel css={styles(theme)}>
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                <div>
                    <Typography variant="h5" component="div">
                        {title}
                    </Typography>
                    <Typography variant="h6" color="textSecondary" component="div">
                        {subtitle}
                    </Typography>
                </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>{children}</ExpansionPanelDetails>
        </ExpansionPanel>
    );
};
