import { Tooltip } from '@material-ui/core';
import * as filesize from 'filesize';
import * as React from 'react';

import { FilePayload } from 'shared/types/file-payload';

interface AttachmentProps {
    file: FilePayload;
    onRemove: () => void;
    onFileNameUpdate: (name: string) => void;
}

export const Attachment: React.FC<AttachmentProps> = (props) => {
    const { file } = props;
    const inputRef = React.useRef(null);

    const fileNameSplitByDot = file.filename.split('.');
    const fileName = fileNameSplitByDot.slice(0, -1).join('.');
    const fileNameExtension = `.${fileNameSplitByDot.slice(-1)}`;

    const handleFileNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        props.onFileNameUpdate(e.target.value + fileNameExtension);
    };

    const focusInput = () => {
        inputRef.current.focus();
    };

    return (
        <div className="attachment">
            <div className="attachment-label-container">
                <input
                    className="attachment-filename"
                    value={fileName}
                    onChange={handleFileNameChange}
                    ref={inputRef}
                />
                <div className="attachment-label-width-placeholder">{fileName}</div>
            </div>
            <span className="attachment-extension">{fileNameExtension}</span>
            <span className="attachment-file-size">({filesize(file.size, { round: 0 })})</span>
            <Tooltip title="Rename attachment">
                <div className="attachment-button" onClick={focusInput}>
                    <i className="fas fa-pen" />
                </div>
            </Tooltip>
            <Tooltip title="Remove attachment">
                <div className="attachment-button" onClick={props.onRemove}>
                    <i className="fas fa-times" />
                </div>
            </Tooltip>
        </div>
    );
};
