import { startCase } from 'lodash';
import * as React from 'react';

import { anyValue, maxRangeValue, SearchConfigExperienceTenure } from 'shared/models/search';

import { jobDisciplines } from '../common/job-disciplines';
import { RangeSelectField } from '../core-ui/range-select-field';
import { useModal } from '../hooks/use-modal';
import { SearchStatus } from '../state';
import { SearchFieldContext } from './search-field-context';
import { SearchGroup, SearchGroupAction } from './search-group';
import { useSearch } from './use-search';

const medExperienceYears = 20;
const increments = 5;
const maxExperienceYears = 40;
const numIncrements = (maxExperienceYears - medExperienceYears) / increments;
const overMedium = Array.from(Array(numIncrements), (_, x) => medExperienceYears + (x + 1) * increments).concat([
    maxRangeValue
]);
const experienceOpts = Array.from([0].concat(Array(medExperienceYears)), (_, x) => x).concat(overMedium);
// tslint:disable-next-line:no-magic-numbers
const currentJobDurationOpts = [0, 0.25, 0.5, 0.75]
    .concat(Array.from(Array(medExperienceYears), (_, x) => x + 1))
    .concat(overMedium);

const medYearsSinceGrad = 20;
const maxYearsSinceGrad = 40;
const numGradIncrements = (maxYearsSinceGrad - medYearsSinceGrad) / increments;

export const SearchExperienceTenureRequirements: React.FC = () => {
    const searchContext = useSearch();
    const config = searchContext.data.config.experience;
    const onChange = searchContext.onConfigFieldChange('experience');
    const readonly = searchContext.readonly;
    const savedSearch = searchContext.data.status && searchContext.data.status !== SearchStatus.Initial;

    const { getConfirmation } = useModal();

    if (!config?.tenures) return null;

    const { tenures, currentJobDurationMax, currentJobDurationMin, yearsSinceGraduation } = config;

    const handleUpdateCurrentJobDuration = (min: number, max: number) => {
        const updated = Object.assign({}, config, {
            currentJobDurationMax: max,
            currentJobDurationMin: min
        });
        onChange(updated);
    };

    const handleUpdateExperienceTenure = (tenureIndex: number) => (updates: Partial<SearchConfigExperienceTenure>) => {
        const updatedTenure = Object.assign({}, tenures[tenureIndex], updates);
        const newTenures = tenures.slice(0, tenureIndex).concat([updatedTenure], tenures.slice(tenureIndex + 1));
        const updated = Object.assign({}, config, { tenures: newTenures });
        onChange(updated);
    };

    const handleUpdateExperienceDiscipline = (tenureIndex: number) => (disciplines: string[]) => {
        handleUpdateExperienceTenure(tenureIndex)({ disciplines });
    };

    const handleUpdateExperienceYears = (tenureIndex: number) => (experienceMin: number, experienceMax: number) => {
        handleUpdateExperienceTenure(tenureIndex)({ experienceMax, experienceMin });
    };

    const handleRemoveTenure = (index: number) => () => {
        const newTenures = tenures.slice(0, index).concat(tenures.slice(index + 1));
        getConfirmation(
            () => onChange(Object.assign({}, config, { tenures: newTenures })),
            'Are you sure you want to remove a tenure requirement?',
            'Remove Confirmation'
        );
    };

    const handleAddTenure = () => {
        const newTenures = config.tenures.concat([
            { experienceMin: 2, experienceMax: 10, disciplines: [], ignore: false }
        ]);
        const updated = Object.assign({}, config, { tenures: newTenures });
        onChange(updated);
    };

    const getLabel = (o: number) => ({
        name: o === maxRangeValue ? 'Max' : `${o}`,
        value: o
    });

    const handleIgnore = (i: number) => () => {
        handleUpdateExperienceTenure(i)({ ignore: !config.tenures[i].ignore });
    };

    const handleUpdateYearsSinceGraduation = (minYears: number, maxYears: number) => {
        const updated = Object.assign({}, config, { yearsSinceGraduation: { maxYears, minYears } });
        onChange(updated);
    };

    const tenuresList = tenures
        .filter(
            (t) =>
                !savedSearch ||
                t.disciplines.length > 0 ||
                t.experienceMax !== maxExperienceYears ||
                t.experienceMin !== 0
        )
        .map((t, i) => {
            const addAction = { name: 'Add', onSelect: handleAddTenure };
            const actions: SearchGroupAction[] = [];
            if (tenures.length === 1) {
                if (!tenures[0].ignore) {
                    actions.push(addAction);
                }
                actions.push({ name: 'Ignore', onSelect: handleIgnore(i), checked: t.ignore });
            } else {
                actions.push(addAction);
                actions.push({ name: 'Remove', onSelect: handleRemoveTenure(i) });
            }

            return (
                <SearchGroup readonly={readonly} actions={actions} key={i} className="and-group">
                    <div className={`search-requirements-field with-context ${t.ignore ? 'ignore' : ''}`} key={i}>
                        <div style={{ flex: '1 1 auto' }}>
                            <label className="search-label">Years of experience</label>
                            <div className="search-requirements-value range-value">
                                <RangeSelectField
                                    readonly={readonly || t.ignore}
                                    max={t.experienceMax}
                                    min={t.experienceMin}
                                    maxValueOpts={experienceOpts.map(getLabel)}
                                    minValueOpts={experienceOpts.map(getLabel)}
                                    onChange={handleUpdateExperienceYears(i)}
                                />
                            </div>
                        </div>
                        <div className="tenure-discipline">
                            <SearchFieldContext
                                disabled={readonly || t.ignore}
                                selected={t.disciplines}
                                onSelect={handleUpdateExperienceDiscipline(i)}
                                options={[anyValue, ...jobDisciplines]}
                                getOptionLabel={startCase}
                                label="Discipline"
                                multiple={true}
                            />
                        </div>
                    </div>
                </SearchGroup>
            );
        });

    const yearsSinceGrad = Array.from([0].concat(Array(medYearsSinceGrad)), (_, x) => x)
        .concat(Array.from(Array(numGradIncrements), (_, x) => medYearsSinceGrad + (x + 1) * increments))
        .concat([maxRangeValue]);
    const yearsSinceGradOpts = yearsSinceGrad.map((v) => ({ value: v, name: v === maxRangeValue ? 'Max' : null }));

    const yearsSinceUndergrad =
        yearsSinceGraduation.maxYears === maxRangeValue && yearsSinceGraduation.minYears === 0 ? null : (
            <div className="search-requirements-field">
                <label className="search-label">Years Since Undergrad Graduation</label>
                <div className="search-requirements-value range-value">
                    <RangeSelectField
                        readonly={readonly}
                        max={yearsSinceGraduation.maxYears}
                        min={yearsSinceGraduation.minYears}
                        maxValueOpts={yearsSinceGradOpts}
                        minValueOpts={yearsSinceGradOpts}
                        onChange={handleUpdateYearsSinceGraduation}
                    />
                </div>
            </div>
        );

    const yearsAtCurrentJob =
        savedSearch && currentJobDurationMax === maxRangeValue && currentJobDurationMin === 0 ? null : (
            <div className="search-requirements-field top-padding">
                <label className="search-label">Years at current job</label>
                <div className="search-requirements-value range-value">
                    <RangeSelectField
                        readonly={readonly}
                        max={currentJobDurationMax}
                        min={currentJobDurationMin}
                        maxValueOpts={currentJobDurationOpts.map(getLabel)}
                        minValueOpts={currentJobDurationOpts.map(getLabel)}
                        onChange={handleUpdateCurrentJobDuration}
                    />
                </div>
            </div>
        );

    return (
        <>
            {yearsAtCurrentJob}
            <div className="top-padding">{tenuresList}</div>
            {yearsSinceUndergrad}
        </>
    );
};
