import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface RouterProps {
    report: string;
}

const reportLinks = new Map<string, string>([
    ['candidates-inflow', 'https://datastudio.google.com/embed/reporting/1xh4XWgdfyoLE12p79AHbP7vDKxeWX8o4/page/rLia'],
    ['emails-out', 'https://datastudio.google.com/embed/reporting/1bdGca5f7aT-YFxoMxYBTzaOByvljaobW/page/zK6a'],
    ['job-funnel-by-month', 'https://datastudio.google.com/embed/reporting/1DMk2SFt3O4ozls4CBDsok4oqB55qY5oZ/page/Kyia']
]);

const AnalyticsReportComponent: React.SFC<RouteComponentProps<RouterProps>> = ({ match }) => {
    const reportSrc = reportLinks.get(match.params.report);
    return (
        <div className="analytics-report-container">
            <div id="report-iframe">
                <iframe
                    src={reportSrc}
                    style={{ border: 0 }}
                    width="100%"
                    height="100%"
                    frameBorder={0}
                    allowFullScreen={true}
                />
            </div>
        </div>
    );
};

export const AnalyticsReport = withRouter(AnalyticsReportComponent);
