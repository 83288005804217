import { CircularProgress } from '@material-ui/core';
import * as React from 'react';

interface LoadingProps {
    size?: number;
    thickness?: number;
}

const Loading: React.SFC<LoadingProps> = ({ size, thickness }) => {
    const loadingStyle = {
        left: '50%',
        marginLeft: `${-size / 2}px`, // tslint:disable-line:no-magic-numbers
        marginTop: `${-size}px`,
        position: 'absolute' as 'absolute',
        top: '50%'
    };
    return <CircularProgress size={size} style={loadingStyle} thickness={thickness} />;
};

Loading.defaultProps = {
    size: 40,
    thickness: 3
};

export { Loading };
