import { Map } from 'immutable';
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';

import { UserData } from 'shared/models/user';
import { fetchJobsData } from '../actions';
import { FlaggedEmails as FlaggedEmailsComponent } from '../components/flagged-emails';
import { FlaggedEmailAccountSelector } from '../components/flagged-emails-selector';
import { Header } from '../components/header';
import { Spinner } from '../core-ui/spinner';
import { FlaggedEmailAccount } from '../graphql/queries/home';
import { withUsers } from '../hocs/with-users';
import { ListState, State } from '../state';

interface OwnProps {
    account?: string;
    accounts: FlaggedEmailAccount[];
}

interface ConnectedProps {
    listsState: Map<string, ListState>;
}

interface ConnectedDispatch {
    fetchJobsData: () => void;
}

interface InjectedProps {
    users: Map<string, UserData>;
}

type FlaggedEmailsProps = OwnProps & ConnectedProps & ConnectedDispatch & InjectedProps;

interface FlaggedEmailsState {
    account: string;
}
class FlaggedEmailsContainer extends React.Component<FlaggedEmailsProps, FlaggedEmailsState> {
    constructor(props: FlaggedEmailsProps) {
        super(props);
        if (!props.listsState.get('jobs')) {
            props.fetchJobsData();
        }
        this.state = { account: props.account ?? props.accounts?.[0].account };
    }

    componentDidUpdate() {
        if (!this.state.account && this.props.accounts?.length > 0) {
            this.setState({ account: this.props.accounts[0].account });
        }
    }

    handleSelect = (account: string) => {
        this.setState({ account });
    };

    render() {
        const { accounts, listsState, users } = this.props;
        const { account } = this.state;

        let content: JSX.Element = null;
        const actions: JSX.Element[] = [];

        if (!accounts || accounts.length === 0 || !listsState.get('jobs')) {
            content = <Spinner />;
        } else {
            const activeUsers = users
                .valueSeq()
                .toArray()
                .filter((u) => u.status === 'active');
            content = <FlaggedEmailsComponent selected={account} activeUsers={activeUsers} />;
            actions.push(
                <FlaggedEmailAccountSelector
                    selected={account}
                    accounts={accounts}
                    onSelectAnotherAccount={this.handleSelect}
                    key="selector"
                />
            );
        }

        return (
            <DocumentTitle title="Flagged Emails">
                <div id="container">
                    <Header title="Flagged Emails" actions={actions} />
                    <div id="content" className="flex-fill">
                        {content}
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    listsState: state.listsState
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    fetchJobsData
};

export const FlaggedEmails = withUsers<OwnProps>(
    connect<ConnectedProps, ConnectedDispatch, OwnProps & InjectedProps>(
        mapStateToProps,
        mapDispatchToProps
    )(FlaggedEmailsContainer)
);
