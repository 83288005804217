import { Chip } from '@material-ui/core';
import _ from 'lodash';
import { lightGreen200, orange400 } from 'material-ui/styles/colors';
import React from 'react';

import { CrunchbaseCompanyFilters, employeeRangeModifier } from 'shared/common/crunchbase-filters';
import { currencyFormat } from 'shared/common/string-format-utils';

export const CBFiltersChip: React.FC<{
    filters: CrunchbaseCompanyFilters;
    negative: boolean;
    onClick: () => void;
    onDelete: () => void;
}> = ({ filters: crunchbaseFilters, negative, onClick, onDelete }) => {
    const cbFilters: JSX.Element[] = [];

    const getORList = (list: string[], category: string) => {
        const orList = [];
        for (let i = 0; i < list.length; i++) {
            orList.push(
                <span className="cb-filter-chip-info-or-list" key={`${category}-${i}`}>
                    {_.startCase(list[i])}
                </span>
            );
        }
        return orList;
    };

    if (crunchbaseFilters.investors?.length > 0) {
        const investorsList = getORList(
            crunchbaseFilters.investors?.map((i) => i.name),
            'investors'
        );
        cbFilters.push(
            <span className="cb-filter-chip-info" key="investors">
                {investorsList}
            </span>
        );
    }
    if (crunchbaseFilters.fundingRounds?.length > 0) {
        cbFilters.push(
            <span className="cb-filter-chip-info" key="fundingRounds">
                {getORList(crunchbaseFilters.fundingRounds, 'fundingRounds')}
            </span>
        );
    }
    if (crunchbaseFilters.status?.length > 0) {
        cbFilters.push(
            <span className="cb-filter-chip-info" key="status">
                {getORList(crunchbaseFilters.status, 'status')}
            </span>
        );
    }
    if (crunchbaseFilters.countries?.length > 0) {
        cbFilters.push(
            <span className="cb-filter-chip-info" key="countries">
                {getORList(crunchbaseFilters.countries, 'countries')}
            </span>
        );
    }
    if (crunchbaseFilters.regions?.length > 0) {
        cbFilters.push(
            <span className="cb-filter-chip-info" key="regions">
                {getORList(crunchbaseFilters.regions, 'regions')}
            </span>
        );
    }
    if (crunchbaseFilters.cities?.length > 0) {
        cbFilters.push(
            <span className="cb-filter-chip-info" key="cities">
                {getORList(crunchbaseFilters.cities, 'cities')}
            </span>
        );
    }
    if (crunchbaseFilters.size) {
        cbFilters.push(
            <span className="cb-filter-chip-info" key="size">
                Size: {employeeRangeModifier(crunchbaseFilters.size).join(' - ')}
            </span>
        );
    }
    if (crunchbaseFilters.funding) {
        cbFilters.push(
            <span className="cb-filter-chip-info" key="funding">
                Funding: {crunchbaseFilters.funding.map((v) => currencyFormat(v, 0)).join(' - ')}
            </span>
        );
    }
    if (crunchbaseFilters.foundingYear) {
        cbFilters.push(
            <span className="cb-filter-chip-info" key="foundingYear">
                Founded: {crunchbaseFilters.foundingYear.join(' - ')}
            </span>
        );
    }
    if (crunchbaseFilters.industryGroups?.length > 0) {
        cbFilters.push(
            <span className="cb-filter-chip-info" key="industryGroups">
                Industry Groups: {getORList(crunchbaseFilters.industryGroups, 'industry-groups')}
            </span>
        );
    }
    if (crunchbaseFilters.industries?.length > 0) {
        cbFilters.push(
            <span className="cb-filter-chip-info" key="industries">
                Industry: {getORList(crunchbaseFilters.industries, 'industries')}
            </span>
        );
    }
    if (crunchbaseFilters.lastFundingRound?.length > 0) {
        cbFilters.push(
            <span className="cb-filter-chip-info" key="lastRound">
                {crunchbaseFilters.lastFundingRound.join(' - ')}
            </span>
        );
    }
    const label = <span>{cbFilters}</span>;
    return (
        <div className={`textfield-with-chips-chip ${!onDelete ? 'readonly' : ''}`}>
            <Chip
                style={{ backgroundColor: negative ? orange400 : lightGreen200, maxWidth: '100%' }}
                onClick={onClick}
                className="cb-filters-chip"
                onDelete={onDelete}
                label={label}
            />
        </div>
    );
};
