import { config } from '../config';
import { store } from '../configure-store';
import { Session } from '../state';
import { loadExternalJS } from './external-js-loader';

declare let Bugsnag: any;

const bugSnagAPI = 'https://d2wy8f7a9ursnm.cloudfront.net/bugsnag-3.min.js';
const apiKey = 'b68e663114982cc786f704a6b301655e';

let sessionInitialized = false;

const setBugsnagUser = (session: Session) => {
    if (session.initialized) {
        Bugsnag.user = {
            email: session.user.email,
            id: session.user.id,
            name: session.user.name.full
        };
    } else {
        Bugsnag.user = {};
    }
};

const trackSessionState = () => {
    store.subscribe(() => {
        const session = store.getState().session;
        if (sessionInitialized !== session.initialized) {
            sessionInitialized = session.initialized;
            setBugsnagUser(session);
        }
    });
};

export const initBugSnag = () => {
    loadExternalJS('bugsnag-script', bugSnagAPI, () => {
        trackSessionState();
        Bugsnag.apiKey = apiKey;
        Bugsnag.appVersion = config.AppVersion;
    });
};

export const notifyBugSnag = (message: string, metaData: any, severity = 'error') => {
    if (config.Env === 'production') {
        Bugsnag.notifyException(new Error(message), { metaData, severity });
    } else {
        // tslint:disable-next-line:no-console
        console.error(message, metaData, severity);
    }
};

if (config.Env === 'production') {
    initBugSnag();
}
