import { v4 } from 'uuid';

import { ExtensionMessage } from 'shared/extension/messaging';

import { logger } from '../common/logger';
import { config } from '../config';
import { handleElementsCheckResponse, handlePageDataChange } from './message-listeners';

const targetOrigin = `chrome-extension://${config.ExtensionId}`;

function sendToContentScript(message: ExtensionMessage) {
    window.parent.postMessage(message, targetOrigin);
}

function isTrustedMessage(e: MessageEvent) {
    return e.origin === targetOrigin;
}

function handleWindowMessage(event: MessageEvent) {
    if (!isTrustedMessage(event)) {
        logger.debug('remote iframe: event not from a trusted source, ignoring', { event });
    } else {
        const data: ExtensionMessage = event?.data;
        switch (data?.type) {
            case 'pageDataUpdate':
                handlePageDataChange(data.payload);
                break;
            case 'elementsCheckResponse':
                handleElementsCheckResponse({ html: data.payload.html, pendingLoad: data.payload.elements });
                break;
            default:
                logger.info('remote iframe: unhandled message', { event });
                break;
        }
    }
}

function addWindowMessageListener() {
    window.addEventListener('message', handleWindowMessage);
}

function requestPageData() {
    sendToContentScript({ type: 'pageDataRequest', messageId: v4(), sender: 'remote' });
}

function copyToClipboard(text: string) {
    sendToContentScript({ type: 'copyToClipboard', messageId: v4(), sender: 'remote', payload: { text } });
}

function requestPendingElements(payload: {
    containerElementId: string;
    options: {
        targetElementSelector: string;
        includedTreeSelectors: string[];
        excludedTreeSelectors: string[];
        scrollOptions: ScrollIntoViewOptions;
        autoClick: boolean;
        autoClickDelayMs: number;
    };
}) {
    sendToContentScript({ type: 'elementsCountCheck', messageId: v4(), sender: 'remote', payload });
}

function updatePageStyles(
    updates: Array<{
        selector: string;
        styles: string;
    }>
) {
    sendToContentScript({
        messageId: v4(),
        payload: { updates },
        sender: 'remote',
        type: 'domElementsStyleUpdateRequest'
    });
}

export { copyToClipboard, addWindowMessageListener, requestPageData, requestPendingElements, updatePageStyles };
