import React, { createContext, FC, useContext } from 'react';

interface CandidatesBoardContext {
    jobIds: string[];
    assignee: string;
    disqualified: boolean;
    showClientName: boolean;
    showJobName: boolean;
    showAssigneeName: boolean;
}

const CandidatesBoardContext = createContext<CandidatesBoardContext>({
    assignee: undefined,
    disqualified: undefined,
    jobIds: undefined,
    showAssigneeName: false,
    showClientName: false,
    showJobName: false
});

const CandidatesBoardProvider: FC<CandidatesBoardContext> = ({
    assignee,
    children,
    disqualified,
    jobIds,
    showAssigneeName,
    showClientName,
    showJobName
}) => {
    return (
        <CandidatesBoardContext.Provider
            value={{ assignee, disqualified, jobIds, showJobName, showAssigneeName, showClientName }}
        >
            {children}
        </CandidatesBoardContext.Provider>
    );
};

const useCandidatesBoard = () => useContext(CandidatesBoardContext);

export { CandidatesBoardProvider, useCandidatesBoard };
