// cspell:disable
// This list of job disciplines originally comes from Vincent.
// Commented out job Disciplines that currently should not be selectable in app but keeping them here
// for completeness.

export const jobDisciplines = [
    'Academic experience',
    'Accounting & finance',
    'Administrative',
    'Banking/financial firms',
    'Communications',
    'Customer support',
    'Data science',
    'Devops',
    'Education',
    'Electrical engineering',
    'Engineering non-software',
    'Graphic design',
    'Healthcare',
    'HR',
    'Investment banking',
    'IT',
    'Literary',
    'Management',
    'Management consulting',
    'Marketing',
    'Operations',
    'Product design',
    'Product management',
    'QA',
    'Recruiting',
    'Sales',
    'Software developer',
    'Software related'
];

export const idealGroups: { [discipline: string]: string[] } = {
    'Academic experience': ['Student/grad researcher', 'Univ/school experience'],
    'Accounting & finance': [
        'Accountant',
        'Accounts payable/receivable',
        'Auditor',
        'Bookkeeper',
        'Controller',
        'Corporate strategy',
        'Finance operations',
        'Financial reporting',
        'FP&A',
        'Other accounting & finance',
        'Payroll manager',
        'Risk manager',
        'Tax related',
        'Treasury'
    ],
    Administrative: ['Assistants/secretary', 'Clerk', 'Other administrative'],
    Communications: ['Corporate communications', 'Public relation'],
    'Customer support': ['Community manager', 'Customer support'],
    'Data science': ['BI analyst', 'Data analyst', 'Data scientist', 'Quant'],
    Devops: ['Devops engineer', 'SRE', 'Webmaster/sys admin'],
    Education: ['College/university faculty', 'Education administrator', 'Librarian', 'School teacher'],
    'Electrical engineering': [
        'Circuit/chip design engineer',
        'Controls engineer',
        'Electrical engineer',
        'Electronics engineer',
        'Robotics engineer'
    ],
    'Engineering non-software': [
        'Aerospace engineer',
        'Chemical engineer',
        'Civil engineer',
        'Environmental engineer',
        'Industrial engineer',
        'Instrumentation engineer',
        'Manufacturing engineer',
        'Materials engineer',
        'Mechanical engineer',
        'Nuclear engineer',
        'Other engineering non-software',
        'Petroleum engineer'
    ],
    'Graphic design': ['Animator', 'Design other', 'Graphic/brand designer', 'Illustrator/artist'],
    HR: ['HR manager', 'Learning/development', 'Other HR'],
    Healthcare: [
        'Dental assistant/hygenist',
        'Dentist',
        'Dietician',
        'Doctor',
        'Healthcare social worker',
        'Massage therapist',
        'Medical assistant',
        'Medical records management',
        'Medical technician',
        'Nurse',
        'Optician',
        'Other healthcare',
        'Personal care aide',
        'Pharmacist',
        'Surgeon',
        'Therapist',
        'Veterinarian'
    ],
    'Hedge funds': ['Hedge fund analyst/portfolio manager'],
    IT: ['IT'],
    'Investment banking': ['Investment banker'],
    Literary: ['Editor', 'Reporter', 'Writer/author'],
    Management: ['CEO', 'General manager', 'Other management'],
    'Management consulting': ['Management consultant'],
    Marketing: [
        'Advertising',
        'Brand manager',
        'Brand marketer',
        'Content marketing',
        'Copywriter',
        'Digital marketing',
        'Email/social media marketer',
        'Growth marketing',
        'Market research',
        'Media buyer',
        'Other marketing',
        'Partnership marketing',
        'Product marketer',
        'SEO marketing'
    ],
    Operations: [
        'Business operations',
        'Buyer/purchasing manager',
        'COO',
        'Facilities',
        'Inventory manager',
        'Merchandising manager',
        'Other operations',
        'Production operations',
        'Program manager',
        'Project manager',
        'Service delivery manager',
        'Supply chain'
    ],
    'Product design': [
        'Interaction designer',
        'Motion graphic designer',
        'Product designer',
        'UI designer',
        'UX designer',
        'Visual designer'
    ],
    'Product management': ['Growth PM', 'Product analyst/operations', 'Product manager'],
    QA: ['QA analyst/tester', 'QA/automation engineer', 'QA manager'],
    Recruiting: ['Recruiter', 'Recruiting coordinator', 'Sourcer'],
    Sales: [
        'Account manager',
        'AE manager',
        'SMB/MM AE',
        'Business development',
        'Enterprise AE',
        'Generic AE',
        'Inside AE',
        'Non typical AE title',
        'Retail sales',
        'Sales operations',
        'SDR manager',
        'SDR/BDR',
        'VP/Director sales'
    ],
    'Software developer': [
        'AI/ML engineer',
        'Backend engineer',
        'CTO',
        'Database engineer',
        'Data engineer',
        'Distributed systems engineer',
        'Embedded/systems engineer',
        'Engineering manager',
        'Firmware engineer',
        'Frontend engineer',
        'Fullstack engineer',
        'Mobile engineer',
        'Vanilla software engineer'
    ],
    'Software related': [
        '3D engineer/graphics engineer',
        'BI/analytics/salesforce engineer',
        'Bioinformatic engineer',
        'Database administrator (DBA)',
        'Flash/multimedia engineer',
        'Game engineer',
        'Network/networking engineer',
        'Other software related',
        'Sales/solution engineer',
        'Security engineer',
        'Support engineer',
        'Wordpress/drupal/content/cms engineer'
    ]
};
