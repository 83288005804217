import { Map } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';

import { UserData } from 'shared/models/user';

import { fetchUsers } from '../actions';
import { ListState, State } from '../state';

interface InjectedProps {
    users: Map<string, UserData>;
}

type ReactComp<T> = React.ComponentClass<T> | React.StatelessComponent<T>;

export function withUsers<P>(WrappedComponent: ReactComp<P & InjectedProps>) {
    interface ConnectedProps {
        users: Map<string, UserData>;
        listsState: Map<string, ListState>;
    }

    interface ConnectedDispatch {
        fetchUsers: () => void;
    }

    type WithUsersWrapperProps = P & ConnectedProps & ConnectedDispatch;

    class WithUsersWrapper extends React.Component<WithUsersWrapperProps, undefined> {
        static displayName = `WithUsers(${WrappedComponent.displayName || WrappedComponent.name})`;

        constructor(props: WithUsersWrapperProps) {
            super(props);
            this.ensureDataExists(props);
        }

        ensureDataExists(props: Readonly<WithUsersWrapperProps>) {
            if (!props.listsState.get('users')) props.fetchUsers();
        }

        componentDidUpdate() {
            this.ensureDataExists(this.props);
        }

        render() {
            if (!this.props.listsState.get('users')) {
                return null;
            } else {
                return <WrappedComponent {...this.props} />;
            }
        }
    }

    const mapStateToProps = (state: State): ConnectedProps => ({
        listsState: state.listsState,
        users: state.users
    });
    const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
        fetchUsers
    };

    return connect<ConnectedProps, ConnectedProps, P>(mapStateToProps, mapDispatchToProps)(WithUsersWrapper);
}
