import { useQuery } from '@apollo/client';
import * as React from 'react';
import DocumentTitle from 'react-document-title';

import { JobPageHeader } from '../components/job-page-header';
import { CLIENT_MEETINGS, Meeting } from '../graphql/queries/clients';
import { JOB_TITLE, JobTitleData } from '../graphql/queries/job';
import { Meetings } from './meetings';

export const JobMeetings: React.FC<{ id: string }> = ({ id }) => {
    const { data: jobData } = useQuery<{ job: JobTitleData }, { jobId: string }>(JOB_TITLE, {
        variables: { jobId: id }
    });
    const { data, refetch } = useQuery<{ meetings: Meeting[] }, { clientId: string }>(CLIENT_MEETINGS, {
        skip: !jobData?.job,
        variables: { clientId: jobData?.job?.client.id }
    });

    const client = jobData?.job?.client;
    const job = jobData?.job;
    const meetings = data?.meetings?.filter((m) => m.jobIds?.includes(id));

    return (
        <DocumentTitle title={`Job Meetings - ${client?.name} - ${job?.title}`}>
            <div id="container">
                <JobPageHeader jobId={id} actions={[]} activeTab="Meetings" />
                <div id="content" className="flex-fill">
                    <Meetings meetings={meetings} refetch={refetch} jobIds={[id]} />
                </div>
            </div>
        </DocumentTitle>
    );
};
