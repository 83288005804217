import { css } from '@emotion/core';
import { Theme, useTheme } from '@material-ui/core';
import * as React from 'react';

import { useQuery } from '@apollo/client';
import { visaOptToString } from 'shared/models/job';
import { timeMonthYear } from '../common/timestamp';
import { LightTooltip } from '../core-ui/light-tooltip';
import { JOB_TOOLTIP_DATA, JobTooltipData } from '../graphql/queries/job';
import { User } from '../sfc/user';

const styles = (theme: Theme) => css`
    padding: 5px;
    font-weight: normal;
    font-size: 12.5px;

    .job-info-row {
        border-bottom: thin solid ${theme.palette.divider};
        padding-bottom: 10px;
        margin-bottom: 10px;
        text-align: left;

        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: 0;
        }

        .job-info-row-header {
            text-transform: uppercase;
            font-size: 10px;
            color: ${theme.palette.primary.light};
            margin-bottom: 2px;
        }
    }
`;

const tooltipEnterDelay = 750;

export const JobInfoTooltip: React.FC<{ jobId: string }> = ({ jobId, children }) => {
    const theme = useTheme();
    const { data } = useQuery<{ job: JobTooltipData }, { jobId: string }>(JOB_TOOLTIP_DATA, { variables: { jobId } });

    const job = data?.job;
    if (!job) {
        return <>{children}</>;
    }

    const experience = [job?.description?.minExperienceYears, job?.description?.maxExperienceYears]
        .filter((v) => v !== undefined)
        .join(' - ');
    const experienceInfo =
        job?.description?.minExperienceYears || job?.description?.maxExperienceYears ? (
            <div className="job-info-row">
                <div className="job-info-row-header">Experience</div>
                <div className="info-value">{experience} Years</div>
            </div>
        ) : null;
    const assignee =
        job.assignee !== job.accountManagerId ? (
            <div className="job-info-row">
                <div className="job-info-row-header">Assignee</div>
                <div className="job-info-row-value">
                    <User id={job.assignee} />
                </div>
            </div>
        ) : null;
    const title = (
        <div css={styles(theme)}>
            <div className="job-info-row">
                <div className="job-info-row-header">Account Manager</div>
                <div className="job-info-row-value">
                    <User id={job.accountManagerId} />
                </div>
            </div>
            {assignee}
            <div className="job-info-row">
                <div className="job-info-row-header">Location</div>
                <div className="job-info-row-value">{job.description?.location}</div>
            </div>
            <div className="job-info-row">
                <div className="job-info-row-header">Salary</div>
                <div className="job-info-row-value">{job.description?.compensation}</div>
            </div>
            <div className="job-info-row">
                <div className="job-info-row-header">Visa</div>
                <div className="job-info-row-value">{job.description.visa?.map(visaOptToString).join(', ')}</div>
            </div>
            <div className="job-info-row">
                <div className="job-info-row-header">Funding</div>
                <div className="job-info-row-value">{job.client?.funding}</div>
            </div>
            {experienceInfo}
            <div className="job-info-row">
                <div className="job-info-row-header">Placement Fees</div>
                <div className="job-info-row-value">{job.placementFees}</div>
            </div>
            <div className="job-info-row">
                <div className="job-info-row-header">Recruiter Percentage</div>
                <div className="job-info-row-value">{job.recruiterPercentage}</div>
            </div>
            <div className="job-info-row">
                <div className="job-info-row-header">Account Manager Percentage</div>
                <div className="job-info-row-value">{job.accountManagerPercentage}</div>
            </div>
            <div className="job-info-row">
                <div className="job-info-row-header">Created</div>
                <div className="job-info-row-value">{timeMonthYear(job.createdAt)}</div>
            </div>
        </div>
    );
    return (
        <LightTooltip title={title} placement="bottom" interactive={true} enterDelay={tooltipEnterDelay}>
            {children as any}
        </LightTooltip>
    );
};
