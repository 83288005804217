import { gql } from '@apollo/client';

import { ActivityMetricType } from 'shared/models/activity-metric';

export interface MetricsData {
    user: {
        id: string;
        name: string;
    };
    job: {
        id: string;
        title: string;
        client: {
            id: string;
            name: string;
        };
    };
    kind: ActivityMetricType;
    count: number;
    date: string;
    cost: number;
}

export interface AggregateMetricsData {
    metrics: {
        aggregate: {
            sum: {
                count: number;
            };
        };
    };
}

export interface OutreachMetrics {
    sequenceId: string;
    date: string;
    userId: string;
    recipientsAdded: number;
    recipientsReached: number;
    recipientsResponded: number;
    sequence: {
        id: string;
        title: string;
        archived: boolean;
        createdAt: number;
    };
    job: {
        id: string;
        title: string;
    };
}

export interface StageCandidatesCount {
    stageCount: {
        aggregate: {
            count: number;
        };
    };
}

export interface Fees {
    fees: {
        aggregate: {
            sum: {
                fees: number;
            };
        };
    };
}

const metricsFields = gql`
    fragment metricsFields on activity_metrics {
        user {
            id
            name(path: "full")
        }
        job {
            id
            title
            client {
                id
                name
            }
        }
        kind
        date
        count
        cost: computedCost
    }
`;
const outreachMetricsFields = gql`
    fragment outreachMetricsFields on outreach_metrics {
        sequenceId
        date
        userId
        recipientsAdded
        recipientsReached
        recipientsResponded
        sequence {
            archived
            createdAt
            id
            title
        }
        job {
            id
            title
        }
    }
`;

export const USER_METRICS = gql`
    ${metricsFields}
    query UserActivityMetrics(
        $userId: String
        $excludeClientIds: [String!]!
        $kinds: [String]!
        $jobTypes: [String]!
        $startDate: date!
        $endDate: date!
    ) {
        metrics: activity_metrics(
            where: {
                userId: { _eq: $userId }
                kind: { _in: $kinds }
                date: { _lte: $endDate, _gte: $startDate }
                job: { jobType: { _in: $jobTypes }, clientId: { _nin: $excludeClientIds } }
            }
            order_by: [{ job: { client: { name: asc } } }, { job: { title: asc } }, { date: asc }]
        ) {
            ...metricsFields
        }
    }
`;

export const CLIENT_METRICS = gql`
    ${metricsFields}
    query ClientMetrics($clientId: String!, $kinds: [String]!, $startDate: date!, $endDate: date!) {
        metrics: activity_metrics(
            where: {
                job: { clientId: { _eq: $clientId }, status: { _in: [1, 2] } }
                kind: { _in: $kinds }
                date: { _lte: $endDate, _gte: $startDate }
            }
            order_by: [{ date: asc }]
        ) {
            ...metricsFields
        }
    }
`;

export const JOB_METRICS = gql`
    ${metricsFields}
    query JobMetrics($jobId: String!, $kinds: [String]!, $startDate: date!, $endDate: date!) {
        metrics: activity_metrics(
            where: { jobId: { _eq: $jobId }, kind: { _in: $kinds }, date: { _lte: $endDate, _gte: $startDate } }
            order_by: [{ date: asc }]
        ) {
            ...metricsFields
        }
    }
`;

export const METRICS_FILTERS_DATA = gql`
    query MetricsFiltersData {
        accountManagers: users(where: { isActiveAccountManager: {} }, order_by: { name: asc }) {
            id
            name(path: "full")
        }
        recruiters: users(where: { isActiveRecruiter: {} }, order_by: { name: asc }) {
            id
            name(path: "full")
        }
        clients: clients(order_by: { name: asc }) {
            id
            name
        }
        teams(where: { restricted: { _eq: false } }) {
            id
            name
            lead {
                id
                name(path: "full")
            }
            users: team_users {
                user {
                    id
                    name(path: "full")
                }
            }
        }
    }
`;

export const ACTIVITY_METRICS = gql`
    ${metricsFields}
    query ActivityMetrics($where: activity_metrics_bool_exp!) {
        metrics: activity_metrics(where: $where, order_by: [{ date: asc }]) {
            ...metricsFields
        }
    }
`;

export const AGGREGATE_ACTIVITY_METRICS = gql`
    query AggregateActivityMetrics($where: activity_metrics_bool_exp!) {
        metrics: activity_metrics_aggregate(where: $where) {
            aggregate {
                sum {
                    count
                }
            }
        }
    }
`;

export const TEAM_METRICS = gql`
    ${metricsFields}
    query ActivityMetrics(
        $userIds: [String]!
        $kinds: [String]!
        $jobTypes: [String]!
        $excludeClientIds: [String!]!
        $startDate: date!
        $endDate: date!
    ) {
        metrics: activity_metrics(
            where: {
                user: { status: { _eq: "active" }, id: { _in: $userIds } }
                kind: { _in: $kinds }
                job: { jobType: { _in: $jobTypes }, clientId: { _nin: $excludeClientIds } }
                date: { _lte: $endDate, _gte: $startDate }
            }
            order_by: [{ date: asc }]
        ) {
            ...metricsFields
        }
    }
`;

export const JOB_OUTREACH_METRICS = gql`
    ${outreachMetricsFields}
    query JobOutreachMetrics($jobId: String!, $startDate: date!, $endDate: date!) {
        outreachMetrics: outreach_metrics(
            where: { date: { _gte: $startDate, _lte: $endDate }, jobId: { _eq: $jobId } }
            order_by: { sequence: { createdAt: desc } }
        ) {
            ...outreachMetricsFields
        }
    }
`;

export const CLIENT_OUTREACH_METRICS = gql`
    ${outreachMetricsFields}
    query ClientOutreachMetrics($clientId: String!, $startDate: date!, $endDate: date!) {
        outreachMetrics: outreach_metrics(
            where: {
                date: { _gte: $startDate, _lte: $endDate }
                sequence: { job: { clientId: { _eq: $clientId }, status: { _in: [1, 2] } } }
            }
            order_by: { sequence: { createdAt: desc } }
        ) {
            ...outreachMetricsFields
        }
    }
`;

export const STAGE_CANDIDATES_COUNT = gql`
    query StageCandidatesCount($where: candidates_bool_exp!) {
        stageCount: candidates_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;

export const FEES = gql`
    query Fees($where: fees_bool_exp!) {
        fees: fees_aggregate(where: $where) {
            aggregate {
                sum {
                    fees: amount
                }
            }
        }
    }
`;

export const STAGE_CONVERSION = gql`
    query Conversion(
        $fromStage: String!
        $toStage: String!
        $startTime: bigint!
        $endTime: bigint!
        $jobTypes: jsonb!
        $excludeClientIds: jsonb!
        $restrictToClientIds: jsonb
        $restrictToAccountManagerIds: jsonb
    ) {
        conversion: stage_conversion_ratio(
            args: {
                initialStage: $fromStage
                finalStage: $toStage
                startTime: $startTime
                endTime: $endTime
                jobTypes: $jobTypes
                excludeClientIds: $excludeClientIds
                restrictToClientIds: $restrictToClientIds
                restrictToAccountManagerIds: $restrictToAccountManagerIds
            }
        ) {
            conversionPercentage
        }
    }
`;
