import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { HardwareKeyboardArrowDown } from 'material-ui/svg-icons';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { BASELINE_SEARCH_SORT_RANK } from 'shared/models/search';

import { Permissions } from 'shared/models/permission';
import { hasFeature, hasRole, USER_FEATURE_REFRESH_SEARCH_PROFILES, UserData } from 'shared/models/user';

import { useModal } from '../hooks/use-modal';

import { requestSearchProfilesRefresh } from '../api';
import { useSnackbar } from '../hooks/use-snackbar';
import { Job, Search, SearchStatus } from '../state';

interface JobSearchesTitleProps {
    onCloneSearch: (data: Search) => void;
    onUpdateSearch: (id: string, updates: Partial<Search>, refetchSearches: boolean) => void;
    onDeleteSearch: (data: Search) => void;
    onSearchClick?: (searchId: string) => void;
    search: Search;
    job: Job;
    user: UserData;
    userPermissions: Permissions;
    name?: string;
    isCampaign?: boolean;
}

export const JobSearchesTitle: React.FC<JobSearchesTitleProps> = (props) => {
    const [refreshRequested, setRefreshRequested] = React.useState(false);
    const [menuAnchor, setMenuAnchor] = React.useState<HTMLElement | null>(null);
    const { getConfirmation } = useModal();
    const { setSnackbar } = useSnackbar();

    React.useEffect(() => {
        if (props?.search?.status === SearchStatus.UpdatingProfiles) {
            setRefreshRequested(false);
        }
    }, [props.search]);

    const handleOnCloneSearch = () => {
        props.onCloneSearch(props.search);
    };

    const handlePauseSearch = () => {
        props.onUpdateSearch(props.search.id, { status: SearchStatus.Paused }, false);
        setMenuAnchor(null);
    };

    const handleDeleteSearch = () => {
        getConfirmation(
            () => {
                props.onDeleteSearch(props.search);
                setMenuAnchor(null);
            },
            'This will delete the Search and all associated Search results',
            'Please confirm'
        );
    };

    const handleRefreshProfilesClick = async () => {
        if (search.status !== SearchStatus.UpdatingProfiles) {
            setRefreshRequested(true);
            setMenuAnchor(null);
            setSnackbar('Requesting profiles refresh...');
            await requestSearchProfilesRefresh(props.search.id);
            setSnackbar('Profiles refresh requested');
        }
    };

    const handleSearchClick = () => {
        if (props.onSearchClick) {
            props.onSearchClick(props.search.id);
        }
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setMenuAnchor(null);
    };

    const { search, name, user, job, userPermissions, isCampaign } = props;
    let menuButton: JSX.Element;

    let actionMenu: JSX.Element;
    if (search && hasRole(userPermissions, 'search_create_access')) {
        const pauseSearchItem = [SearchStatus.Active, SearchStatus.ScoringFailed].includes(search.status) ? (
            <MenuItem dense={true} onClick={handlePauseSearch}>
                Pause Search
            </MenuItem>
        ) : null;
        const deleteSearchItem =
            search.sortRank === BASELINE_SEARCH_SORT_RANK && user.id === job.accountManagerId ? (
                <MenuItem dense={true} onClick={handleDeleteSearch}>
                    Delete Search
                </MenuItem>
            ) : null;
        const refreshProfilesItem =
            search.status === SearchStatus.Active && hasFeature(user, USER_FEATURE_REFRESH_SEARCH_PROFILES) ? (
                <MenuItem dense={true} onClick={handleRefreshProfilesClick} disabled={refreshRequested}>
                    {refreshRequested ? 'Refreshing Profiles' : 'Refresh Profiles'}
                </MenuItem>
            ) : null;
        actionMenu = (
            <Menu open={Boolean(menuAnchor)} onClose={handleCloseMenu} anchorEl={menuAnchor}>
                <MenuItem dense={true} onClick={handleOnCloneSearch}>
                    Clone Search
                </MenuItem>
                {refreshProfilesItem}
                {pauseSearchItem}
                {deleteSearchItem}
            </Menu>
        );
        menuButton = (
            <div className="job-search-actions">
                <IconButton onClick={handleMenuClick}>
                    <HardwareKeyboardArrowDown />
                </IconButton>
            </div>
        );
    }

    const content =
        search && !isCampaign ? (
            <Link to={`/job/${search.jobId}/search/${search.id}/total`}>{name || search.name}</Link>
        ) : (
            name
        );

    return (
        <div className="job-search-title">
            <div className="job-search-title-label" onClick={handleSearchClick}>
                {content}
            </div>
            {menuButton}
            {actionMenu}
        </div>
    );
};
