import { css } from '@emotion/core';
import { Theme, useTheme } from '@material-ui/core';

import { Candidate, Interview, Job } from '../../graphql/queries/home';
import { AMJobsTable } from './am-jobs-table';
import { AssigneeCandidates } from './assignee-candidates-table';
import { ClientRounds } from './client-rounds';
import { FlaggedEmailAccounts } from './flagged-email-accounts';
import { JobStatusUpdates } from './job-status-updates';
import { PendingEmails } from './pending-emails';
import { PhoneCalls } from './phone-calls';
import { RecruiterJobsTable } from './recruiter-jobs-table';
import { Reminders } from './reminders';
import { HomeStats } from './stats';
import { SubmissionsTable } from './submissions-table';
import { UserMentions } from './user-mentions';
import { UserNotifications } from './user-notifications';

interface HomeProps {
    submissions: Candidate[];
    interviews: Interview[];
    amJobs: Job[];
    candidates: Candidate[];
    pendingEmails: Candidate[];
    pendingEmailsArchived: Candidate[];
}

const styles = (theme: Theme) => css`
    background: #f4f6f8;
    flex: 1 1 auto;
    overflow: auto;
    padding: 25px 50px;

    .skeleton {
        width: 100%;
        height: 20px;
        margin-bottom: 25px;
    }

    .panel-columns {
        display: flex;
    }

    .link {
        color: ${theme.palette.primary.main};
        cursor: pointer;
    }

    .left-panels {
        flex: 1 1 auto;
    }

    .right-panels {
        flex: 0 0 auto;
        width: 420px;
        margin-left: 25px;

        &:empty {
            width: 0;
            margin-left: 0;
        }
    }

    .panel {
        margin-bottom: 25px;
    }
`;

export const Home: React.FC<HomeProps> = (props) => {
    const theme = useTheme();
    const { submissions, interviews, amJobs, candidates, pendingEmails, pendingEmailsArchived } = props;
    return (
        <div css={styles(theme)}>
            <HomeStats />
            <div className="panel-columns">
                <div className="left-panels">
                    <SubmissionsTable candidates={submissions} />
                    <AMJobsTable jobs={amJobs} />
                    <RecruiterJobsTable />
                    <AssigneeCandidates candidates={candidates} />
                </div>
                <div className="right-panels">
                    <PhoneCalls interviews={interviews} />
                    <UserMentions />
                    <Reminders />
                    <UserNotifications />
                    <PendingEmails candidates={pendingEmails} title="Pending Emails" />
                    <ClientRounds interviews={interviews} />
                    <JobStatusUpdates />
                    <PendingEmails candidates={pendingEmailsArchived} title="Archived Candidates Emails" />
                    <FlaggedEmailAccounts />
                </div>
            </div>
        </div>
    );
};
