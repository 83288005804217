import { Button } from '@material-ui/core';
import { Map, OrderedMap } from 'immutable';
import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';

import { interpolateEmailTemplate } from 'shared/common/interpolate-template';
import { UserData } from 'shared/models/user';

import { fetchJobsData, fetchPersonDetails, getAllEmailAccountInfo, sendTestEmail } from '../actions';
import { Client, EmailAccount, Job, List, PersonDetails, State } from '../state';

interface OwnProps {
    // template is not declared as Partial<EmailTemplate> but explicitly lists the required fields
    template: { body: string; subject: string; group: string };
}

interface ConnectedProps {
    emailAccounts: Map<string, EmailAccount>;
    jobs: OrderedMap<string, Job>;
    personsDetails: List<PersonDetails>;
    user: UserData;
    clients: List<Client>;
}

interface ConnectedDispatch {
    fetchJobsData: () => void;
    fetchPersonDetails: (personId: string, jobId?: string) => void;
    getAllEmailAccountInfo: () => void;
    sendTestEmail: (subject: string, body: string) => void;
}

type EmailTemplateSendSampleProps = OwnProps & ConnectedProps & ConnectedDispatch;

const samplePersonId = '1k51em73';

class EmailTemplateSendSampleComponent extends React.Component<EmailTemplateSendSampleProps> {
    componentDidMount() {
        this.ensureDataExists(this.props);
    }

    componentDidUpdate() {
        this.ensureDataExists(this.props);
    }

    ensureDataExists = (props: EmailTemplateSendSampleProps) => {
        const { emailAccounts, jobs, personsDetails } = this.props;
        if (emailAccounts.isEmpty()) {
            props.getAllEmailAccountInfo();
        }
        if (!jobs || jobs.isEmpty()) {
            props.fetchJobsData();
            return; // Need the jobs to be able to continue
        }
        if (personsDetails.list.isEmpty()) {
            props.fetchPersonDetails(samplePersonId);
            return;
        }
    };

    handleSendTestEmail = () => {
        const { clients, jobs, personsDetails, template, user } = this.props;
        let body: string;
        let subject: string;
        const job: Job = jobs.find((j) => j.submissionSettings?.emailTo?.length > 0);
        const client = clients.list.get(job.clientId);
        const personDetails = personsDetails.list.find(() => true);
        const data = {
            client,
            job,
            person: personDetails.person,
            profile: personDetails.profile,
            profileUrls: personDetails.profileUrls,
            senderName: user.name
        };
        body = interpolateEmailTemplate(template.body, data);
        subject = interpolateEmailTemplate(template.subject, data);
        this.props.sendTestEmail(subject, body);
    };

    render() {
        const { clients, emailAccounts, jobs, personsDetails } = this.props;
        if (
            emailAccounts.isEmpty() ||
            !clients.initialized ||
            clients.list.isEmpty() ||
            !jobs ||
            jobs.isEmpty() ||
            personsDetails.list.isEmpty()
        ) {
            return null;
        }
        return <Button onClick={this.handleSendTestEmail}>Send Test Email</Button>;
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    clients: state.clients,
    emailAccounts: state.emailAccounts,
    jobs: state.jobs,
    personsDetails: state.personsDetails,
    user: state.session.user
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    fetchJobsData,
    fetchPersonDetails,
    getAllEmailAccountInfo,
    sendTestEmail
};

export const EmailTemplateSendSample = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(EmailTemplateSendSampleComponent);
