import { useMutation } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    TextField,
    Typography
} from '@material-ui/core';
import { orderBy, startCase } from 'lodash';
import React from 'react';

import { currencyFormat } from 'shared/common/string-format-utils';
import { Company } from '../graphql/queries/clients';
import { CREATE_COMPANY_INVESTMENT, UPDATE_COMPANY_INVESTMENTS } from '../graphql/queries/companies';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';

const dialogStyle = css`
    .MuiTextField-root {
        margin-bottom: 20px;
    }
`;

const infoDialogStyle = css`
    .company-investor-info {
        padding: 15px 15px 5px 15px;
        min-width: 300px;

        .title {
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: -5px 0;

            .close {
                cursor: pointer;
                font-size: 20px;
                padding: 5px;
            }
        }

        .header {
            font-size: 10px;
            text-transform: uppercase;
            opacity: 0.7;
            margin: 15px 0 2px 0;
        }

        .content {
            font-size: 14px;
            margin-bottom: 20px;
            line-height: 1.5;
        }

        .investment {
            margin-bottom: 15px;
        }
    }
`;

interface InvestorFields {
    investorName: string;
    investmentType?: string;
    raisedAmount?: number;
    link?: string;
}

const investmentTypes = [
    'angel',
    'convertible_note',
    'corporate_round',
    'debt_financing',
    'equity_crowdfunding',
    'grant',
    'initial_coin_offering',
    'non_equity_assistance',
    'post_ipo_debt',
    'post_ipo_equity',
    'post_ipo_secondary',
    'pre_seed',
    'private_equity',
    'secondary_market',
    'seed',
    'series_a',
    'series_b',
    'series_c',
    'series_d',
    'series_e',
    'series_f',
    'series_g',
    'series_h',
    'series_i',
    'series_unknown',
    'undisclosed'
];

const defaultExpanded = 10;

export const CompanyInvestors: React.FC<{ company: Company; searchText: string }> = ({ company, searchText }) => {
    const chipsRef = React.useRef<HTMLSpanElement>(null);
    const [hasExpanded, setHasExpanded] = React.useState<boolean>(false);
    const [investments, setInvestments] = React.useState<InvestorFields[]>([]);
    const [investors, setInvestors] = React.useState<string[]>([]);
    const [adding, setAdding] = React.useState<boolean>(false);
    const [expanded, setExpanded] = React.useState<number>(0);
    const [formInput, setFormInput] = React.useState<InvestorFields>(null);
    const [investorInfoDetail, setInvestorInfoDetail] = React.useState<string>(null);
    const [updateInvestments] = useMutation<
        {},
        { companyId: string; investorName: string; userId: string; deleted: boolean }
    >(UPDATE_COMPANY_INVESTMENTS);
    const [createInvestment, { loading }] = useMutation<{}, { companyId: string } & InvestorFields>(
        CREATE_COMPANY_INVESTMENT
    );
    const { setSnackbar } = useSnackbar();
    const { user } = useSession();

    React.useEffect(() => {
        setInvestments(company?.investments);
    }, [company]);

    React.useEffect(() => {
        if (expanded === 0 && hasExpanded && chipsRef.current) {
            chipsRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [expanded]);

    React.useEffect(() => {
        const uniqueInvestors: Set<string> = new Set();
        investments?.forEach((investment) => {
            if (!uniqueInvestors.has(investment.investorName)) {
                uniqueInvestors.add(investment.investorName);
            }
        });
        setInvestors(Array.from(uniqueInvestors).sort());
    }, [investments]);

    const handleDelete = (investor: string) => () => {
        setInvestors(investors.filter((t) => t !== investor));
        updateInvestments({
            variables: { companyId: company.id, investorName: investor, userId: user.id, deleted: true }
        });
        const undoDelete = () => {
            setInvestors(investors);
            setSnackbar('Investor restored');
            updateInvestments({
                variables: { companyId: company.id, investorName: investor, userId: null, deleted: false }
            });
        };
        setSnackbar(
            'Investor removed',
            <Button style={{ color: 'white' }} onClick={undoDelete}>
                Undo
            </Button>
        );
    };

    const handleAddClick = () => {
        setAdding(true);
    };

    const handleCancelAdd = () => setAdding(false);

    const handleInputChange = (field: keyof InvestorFields) => (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormInput({ ...formInput, [field]: e.target.value });
    };

    const handleCreate = async () => {
        const value = formInput?.investorName?.trim();
        if (value) {
            setSnackbar('Adding investment...');
            const variables = { companyId: company.id, ...formInput };
            await createInvestment({ variables });
            setInvestments([...investments, variables]);
            setFormInput(null);
            setAdding(false);
            setSnackbar('Investment added');
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleCreate();
        }
    };

    const handleCloseInvestorInfo = () => setInvestorInfoDetail(null);

    const handleOpenInvestorInfo = (investor: string) => () => setInvestorInfoDetail(investor);

    const handleExpand = () => {
        expanded === 0 ? setExpanded(defaultExpanded) : setExpanded(investors.length);
        setHasExpanded(true);
    };

    const handleCollapse = () => setExpanded(0);

    const searchRegex = searchText ? new RegExp(searchText, 'i') : undefined;
    const sortedInvestors = searchText
        ? orderBy(investors, [(v) => v?.match(searchRegex), (v) => v], ['asc', 'asc'])
        : investors;

    const viewedInvestors = sortedInvestors.slice(0, expanded);

    const list = viewedInvestors.map((investor) => (
        <Chip
            variant="outlined"
            key={investor}
            className="chip"
            label={investor}
            onDelete={handleDelete(investor)}
            onClick={handleOpenInvestorInfo(investor)}
        />
    ));
    const addButton =
        expanded > 0 || investors.length === 0 ? (
            <Chip variant="outlined" key="add" className="chip add-chip" label="Add" onClick={handleAddClick} />
        ) : null;
    const hideButton =
        expanded > 0 ? (
            <Chip variant="outlined" key="hide" className="chip" label="Close" onClick={handleCollapse} />
        ) : null;
    const expandAllButton =
        sortedInvestors.length > expanded ? (
            <Chip
                variant="outlined"
                key="expand"
                className="chip add-chip"
                label={`${sortedInvestors.length - expanded} ${expanded > 0 ? 'more' : 'investors'}`}
                onClick={handleExpand}
            />
        ) : null;

    const investmentTypeOpts = investmentTypes.map((type) => (
        <MenuItem key={type} value={type}>
            {startCase(type)}
        </MenuItem>
    ));

    const addForm = (
        <Dialog css={dialogStyle} open={adding} onClose={handleCancelAdd} maxWidth="sm" fullWidth={true}>
            <DialogTitle>
                <Typography variant="h4" component="div">
                    {company?.name} - Add Investment
                </Typography>
            </DialogTitle>
            <DialogContent>
                <TextField
                    label="Investor Name"
                    fullWidth={true}
                    onChange={handleInputChange('investorName')}
                    value={formInput?.investorName ?? ''}
                    disabled={loading}
                    onKeyPress={handleKeyPress}
                />
                <TextField
                    label="Investment Type"
                    fullWidth={true}
                    onChange={handleInputChange('investmentType')}
                    value={formInput?.investmentType ?? ''}
                    disabled={loading}
                    select={true}
                    onKeyPress={handleKeyPress}
                >
                    {investmentTypeOpts}
                </TextField>
                <TextField
                    label="Raised Amount"
                    fullWidth={true}
                    onChange={handleInputChange('raisedAmount')}
                    value={formInput?.raisedAmount ?? ''}
                    disabled={loading}
                    onKeyPress={handleKeyPress}
                />
                <TextField
                    label="Link"
                    fullWidth={true}
                    onChange={handleInputChange('link')}
                    value={formInput?.link ?? ''}
                    disabled={loading}
                    onKeyPress={handleKeyPress}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancelAdd} disabled={loading}>
                    Cancel
                </Button>
                <Button onClick={handleCreate} disabled={loading}>
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );

    let infoDialogContent;
    if (investorInfoDetail) {
        const details = investments
            .filter((investment) => investment.investorName === investorInfoDetail)
            .sort((a, b) => (b.raisedAmount ?? 0) - (a.raisedAmount ?? 0))
            .map((investment, index) => {
                const link = investment.link ? (
                    <a href={investment.link} target="_blank">
                        Link
                    </a>
                ) : (
                    ''
                );
                return (
                    <div className="investment" key={index}>
                        <div>{startCase(investment.investmentType)}</div>
                        <div>{currencyFormat(investment.raisedAmount)}</div>
                        <div>{link}</div>
                    </div>
                );
            });
        infoDialogContent = (
            <div className="company-investor-info">
                <div className="title">
                    <span>Investment details</span>
                    <span className="close" onClick={handleCloseInvestorInfo}>
                        ×
                    </span>
                </div>
                <div className="header">Company</div>
                <div className="content">{company.name}</div>
                <div className="header">Investor</div>
                <div className="content">{investorInfoDetail}</div>
                <div className="header">Investments</div>
                <div className="content">{details}</div>
            </div>
        );
    }
    const infoDialog = (
        <Dialog css={infoDialogStyle} open={!!investorInfoDetail} onClose={handleCloseInvestorInfo}>
            {infoDialogContent}
        </Dialog>
    );

    return (
        <span className="chips-list" ref={chipsRef}>
            {list}
            {expandAllButton}
            {hideButton}
            {addButton}
            {addForm}
            {infoDialog}
        </span>
    );
};
