import { Tooltip } from '@material-ui/core';
import { Map, OrderedMap } from 'immutable';
import * as _ from 'lodash';
import { CircularProgress, Dialog, FlatButton } from 'material-ui';
import { black } from 'material-ui/styles/colors';
import { ActionSchedule } from 'material-ui/svg-icons';
import * as React from 'react';
import { connect } from 'react-redux';

import { checkSendAccess } from 'shared/common/email-access';
import { emailToNoteContent } from 'shared/common/email-markup';
import { hasJobPermission, IntroEmailsAccount } from 'shared/models/job';
import { NoteView } from 'shared/models/note';
import { Permissions } from 'shared/models/permission';
import { ScheduledMessageView } from 'shared/models/scheduled-messages';
import { UserData } from 'shared/models/user';
import { EmailAddress } from 'shared/types/email-compose';

import { useSubscription } from '@apollo/client';
import {
    cancelScheduledMessage,
    createNewNote,
    fetchJobsData,
    fetchUsers,
    getAllEmailAccountInfo,
    getCommunications,
    getConfirmation,
    getScheduledMessages,
    moveInGmail,
    requestToasterOpen,
    rescheduleMessage,
    showModalAlert
} from '../actions';
import { reportIncorrectCandidate } from '../api';
import { getPendingCountsByView } from '../common/communication-utils';
import { getDisabledHours, getNearestValidEmailTs, isDayDisabled } from '../common/email-sending-times';
import { getAccountOptions } from '../common/email/account-options';
import { EmailKind } from '../common/email/header-validator';
import { getLocalStorageKey, setLocalStorageKey } from '../common/local-storage';
import { DateTimePicker } from '../core-ui/date-time-picker';
import {
    EmailValidationData,
    replyToEmailWithValidation,
    replyToEmailWithValidationWithDifferentAccount
} from '../email-compose/actions';
import { ComposeEmailWindowData } from '../email-compose/types';
import { PERSON_LATEST_COMMUNICATION_AT, PERSON_LATEST_SCHEDULED_MESSAGE_AT } from '../graphql/queries/communications';
import { useReduxDispatch } from '../hooks/use-redux';
import {
    Candidate,
    Client,
    Communication,
    Contact,
    EmailAccount,
    Job,
    ListState,
    Person,
    RequestErrors,
    State
} from '../state';
import { Communication as CommunicationComponent } from './communication';
import { PersonCommunicationActions } from './person-communication-actions';
import { ScheduledMessage } from './scheduled-messages';

interface OwnProps {
    person: Person;
    job?: Job;
    client?: Client;
    contacts: Contact[];
}

interface ConnectedProps {
    emailContentBlacklistedDomains: string;
    candidates: Map<string, Map<string, Candidate>>;
    communications: Map<string, Communication[]>;
    jobs: OrderedMap<string, Job>;
    pendingRequests: Map<string, RequestErrors>;
    emailAccounts: Map<string, EmailAccount>;
    users: Map<string, UserData>;
    listsState: Map<string, ListState>;
    scheduledMessages: Map<string, ScheduledMessageView[]>;
    user: UserData;
    permissions: Permissions;
}

interface ConnectedDispatch {
    getCommunications: (id: string) => void;
    createNewNote: (note: Partial<NoteView>, postSave?: () => void, onFailedSave?: () => void) => void;
    moveInGmail: (account: string, personId: string, threadId: string, action: string) => void;
    fetchJobsData: () => void;
    replyToEmailWithValidation: (
        accountOptions: EmailAddress[],
        emailContentBlacklistedDomains: string,
        message: Communication,
        replyAll: boolean,
        emailKind: EmailKind,
        validationData: EmailValidationData,
        archiveOnReply?: boolean,
        composeWindowData?: Partial<ComposeEmailWindowData>,
        initialBody?: string
    ) => void;
    replyToEmailWithValidationWithDifferentAccount: (
        accountOptions: EmailAddress[],
        emailContentBlacklistedDomains: string,
        message: Communication,
        newAccount: EmailAddress,
        replyAll: boolean,
        emailKind: EmailKind,
        validationData: EmailValidationData,
        composeWindowData?: Partial<ComposeEmailWindowData>,
        initialBody?: string
    ) => void;
    getAllEmailAccountInfo: () => void;
    fetchUsers: () => void;

    rescheduleMessage: (
        scheduledMessage: ScheduledMessageView,
        newScheduledAt: number,
        personId: string,
        jobId: string
    ) => void;
    cancelScheduledMessage: (scheduledMessage: ScheduledMessageView, personId: string, jobId: string) => void;
    getScheduledMessages: (personId: string) => void;
    getConfirmation: (onConfirm: () => void, description?: string | JSX.Element, title?: string) => void;
    requestToasterOpen: (message: string, autoHideDuration?: number) => void;
    showModalAlert: (description: string | JSX.Element, title: string) => void;
}

type CommunicationsComponentProps = ConnectedProps & OwnProps & ConnectedDispatch;

type EmailsView = 'candidate' | 'client' | 'candidate-and-client';

interface CommunicationsComponentState {
    currentJobOnly: boolean;
    view: EmailsView;

    emailsAlreadyConvertedToNotes: string[];
    reschedulingMessage: ScheduledMessageView;
    rescheduleTime: number;
}

const viewKey = 'candidate-card-emails-view';

class CommunicationsComponent extends React.Component<CommunicationsComponentProps, CommunicationsComponentState> {
    constructor(props: CommunicationsComponentProps) {
        super(props);
        this.state = {
            currentJobOnly: !!props.job,
            emailsAlreadyConvertedToNotes: [],
            rescheduleTime: null,
            reschedulingMessage: null,
            view: this.getInitialView(props)
        };

        if (this.props.emailAccounts.isEmpty()) {
            this.props.getAllEmailAccountInfo();
        }

        if (!this.props.listsState.get('users')) {
            this.props.fetchUsers();
        }

        if (props.listsState.get('jobs') !== 'initialized') {
            props.fetchJobsData();
        }
    }

    getInitialView = (props: CommunicationsComponentProps): EmailsView => {
        const counts = this.getPendingCountsByView(props);
        return counts.get('candidate-and-client') > 0
            ? 'candidate-and-client'
            : counts.get('client') > 0
              ? 'client'
              : counts.get('candidate') > 0
                ? 'candidate'
                : getLocalStorageKey<EmailsView>(viewKey, 'candidate');
    };

    getPendingCountsByView = (props: CommunicationsComponentProps) => {
        const { person, job, candidates } = props;
        return job
            ? getPendingCountsByView(candidates.get(job.id)?.get(person.id)?.pendingEmails)
            : Map<EmailsView, number>();
    };

    getEmailsForView = () => {
        const { person, communications, job, scheduledMessages, candidates } = this.props;
        const { currentJobOnly, view } = this.state;
        const jobId = job ? job.id : null;
        const personId = person.id;
        const crossSubmitJobIds =
            jobId && candidates.get(jobId).get(personId) && candidates.get(jobId).get(personId).crossSubmitJobIds
                ? candidates.get(jobId).get(personId).crossSubmitJobIds
                : [];

        const viewFilter = (c: Pick<Communication, 'account' | 'isClientComm' | 'jobIds' | 'category'>) => {
            const clientEmail = c.category === 'client';
            const candidateAndClientEmail = c.category === 'shared';
            const candidateEmail = c.category === 'candidate' || c.category === 'unknown';

            const viewFilterCheck =
                (view === 'candidate' && candidateEmail) ||
                (view === 'client' && clientEmail) ||
                (view === 'candidate-and-client' && candidateAndClientEmail);
            return (
                viewFilterCheck &&
                (!jobId ||
                    !currentJobOnly ||
                    c.jobIds.indexOf(jobId) !== -1 ||
                    (!c.isClientComm &&
                        crossSubmitJobIds.includes(jobId) &&
                        _.intersection(crossSubmitJobIds, c.jobIds ?? []).length > 0))
            );
        };

        return {
            communications: communications.get(person.id).filter(viewFilter),
            scheduledMessages: (scheduledMessages.get(person.id) || []).filter((c) =>
                viewFilter({
                    account: c.payload.account.address,
                    category: c.category,
                    isClientComm: c.payload.isClientComm,
                    jobIds: c.jobId ? [c.jobId] : []
                })
            )
        };
    };

    getThreadGroups = (communications: Communication[]) => {
        const sortedEmails = communications.sort((a, b) => b.internalDate - a.internalDate);
        return _.values(_.groupBy(sortedEmails, this.getFullThreadId));
    };

    handleJobFilterChange = (currentJobOnly: boolean) => () => {
        this.setState({ currentJobOnly });
    };

    handleEmailViewChange = (view: EmailsView) => () => {
        this.setState({ view });
        setLocalStorageKey(viewKey, view, -1);
    };

    getFullThreadId = (c: Communication) => {
        return `${c.account}-${c.threadId}`;
    };

    handleRefresh = () => {
        const { pendingRequests, person } = this.props;
        const { id } = person;
        if (!pendingRequests.has(`person-communications-${id}`)) {
            this.props.getCommunications(id);
            this.props.getScheduledMessages(id);
        }
    };

    handleReplyButton = (data: Communication, initialBody?: string) => {
        const { candidates, person, job, client, contacts, emailAccounts, users, user, permissions, jobs } = this.props;
        let emailKind: EmailKind;
        if (this.state.view === 'client' || this.state.view === 'candidate-and-client') {
            if (data.account === IntroEmailsAccount) {
                emailKind = 'introReply';
            } else {
                emailKind = 'client';
            }
        } else {
            emailKind = 'candidate';
        }

        const hasReplyToClientEmailsPermission = hasJobPermission(
            job?.recruiterPermissions.replyToClientEmails,
            user.id
        );

        if (data.isClientComm && !hasReplyToClientEmailsPermission) {
            this.props.showModalAlert(
                'You do not have permission to reply to client emails for this role, contact the AM',
                'Error'
            );
        } else {
            let newAccount: EmailAccount;
            if (emailAccounts.get(data.account).syncStatus !== 'enabled' && job && person) {
                const assignee = users.get(candidates.get(job.id).get(person.id).assignee);
                const accountOptions = getAccountOptions(emailAccounts, assignee, undefined);
                newAccount = emailAccounts.get(accountOptions[0]?.address);
            }

            // check account access
            const account = newAccount ?? emailAccounts.get(data.account);

            const jobIds = data.jobIds || [];
            const personIds = data.personIds || [];

            const personsCandidates: Candidate[] = [];
            for (const jobId of jobIds) {
                const jobCandidates = candidates.get(jobId);
                if (jobCandidates) {
                    for (const personId of personIds) {
                        const candidate = jobCandidates.get(personId);
                        if (candidate) {
                            personsCandidates.push(candidate);
                        }
                    }
                }
            }

            const commJobs = (data.jobIds || []).map((j) => jobs.get(j));

            const accountAccess = checkSendAccess(user, permissions, account, {
                jobs: commJobs,
                personsCandidates
            });

            if (!account) {
                this.props.showModalAlert('No account found to reply to this email', 'Error');
            } else if (!accountAccess) {
                this.props.showModalAlert(`No access to send emails using the account: ${account.email}`, 'Error');
            } else {
                if (newAccount && newAccount.email !== data.account) {
                    const defaultAccount = {
                        address: newAccount.email,
                        name: newAccount.name.full
                    };
                    const accountOptions = getAccountOptions(emailAccounts, user, job.id, defaultAccount);
                    this.props.replyToEmailWithValidationWithDifferentAccount(
                        accountOptions,
                        this.props.emailContentBlacklistedDomains,
                        data,
                        defaultAccount,
                        true,
                        emailKind,
                        {
                            client,
                            emailAccounts: emailAccounts.valueSeq().toArray(),
                            job,
                            personContacts: contacts,
                            users: users.valueSeq().toArray()
                        },
                        {
                            canEditToRecipients: true,
                            isClientComm: data.isClientComm,
                            jobId: job ? job.id : undefined,
                            personId: person.id
                        }
                    );
                } else {
                    const defaultAccount = { name: data.accountInfo.name.full, address: data.account };
                    const accountOptions = getAccountOptions(emailAccounts, user, job.id, defaultAccount);
                    this.props.replyToEmailWithValidation(
                        accountOptions,
                        this.props.emailContentBlacklistedDomains,
                        data,
                        true,
                        emailKind,
                        {
                            client,
                            emailAccounts: emailAccounts.valueSeq().toArray(),
                            job,
                            personContacts: contacts,
                            users: users.valueSeq().toArray()
                        },
                        true,
                        {
                            canEditToRecipients: true,
                            isClientComm: data.isClientComm,
                            jobId: job ? job.id : undefined,
                            personId: person.id
                        },
                        initialBody
                    );
                }
            }
        }
    };

    handleArchiveButton = (data: Communication) => {
        this.props.moveInGmail(data.account, this.props.person.id, data.threadId, 'archive');
    };

    handleReportIncorrectCandidate = async (data: Communication) => {
        const reportingToasterDuration = 2000;
        this.props.requestToasterOpen('Reporting ...', reportingToasterDuration);
        const response = await reportIncorrectCandidate(data.account, data.messageId);
        if (response?.success) {
            const duration = 5000;
            this.props.requestToasterOpen('Incorrect candidate reported', duration);
            this.handleRefresh();
        }
    };

    handleConvertEmailToNote = (data: Communication) => {
        const { job, user, client } = this.props;
        const content = emailToNoteContent(data);

        const newNote: Partial<NoteView> = {
            attachments: [],
            content,
            context: { jobId: job?.id, messageId: data.messageId },
            createdBy: user.id,
            format: 'html',
            history: [],
            modifiedAt: Date.now(),
            modifiedBy: user.id,
            newAttachments: []
        };

        newNote.notable = 'clients-' + client?.id;
        this.props.createNewNote(
            newNote,
            () => {
                this.setState({
                    emailsAlreadyConvertedToNotes: [...this.state.emailsAlreadyConvertedToNotes, data.messageId]
                });

                const duration = 5000;
                this.props.requestToasterOpen('Email converted to note', duration);
            },
            undefined
        );
    };

    renderCommunicationComponent = (data: Communication) => {
        const { listsState, emailAccounts } = this.props;
        if (listsState.get('users') !== 'initialized' || emailAccounts.isEmpty()) {
            return null;
        }

        const addAsNoteBtnDisabled = this.state.emailsAlreadyConvertedToNotes.includes(data.messageId);

        const personCommunicationActions = (
            <PersonCommunicationActions
                handleConvertEmailToNote={this.handleConvertEmailToNote}
                handleReportIncorrectCandidate={this.handleReportIncorrectCandidate}
                handleReplyButton={this.handleReplyButton}
                handleArchiveButton={this.handleArchiveButton}
                data={data}
                job={this.props.job}
                disabled={addAsNoteBtnDisabled || !this.props.client}
            />
        );

        const comp = (
            <CommunicationComponent
                personId={this.props.person.id}
                actions={[personCommunicationActions]}
                data={data}
                key={`${data.account}_${data.messageId}`}
                emailAccounts={this.props.emailAccounts}
                jobId={this.props.job ? this.props.job.id : null}
                emailContentBlacklistedDomains={this.props.emailContentBlacklistedDomains}
                onReply={this.handleReplyButton}
            />
        );
        return comp;
    };

    handleRequestRescheduleMessage = (message: ScheduledMessageView) => () => {
        this.setState({ reschedulingMessage: message, rescheduleTime: message.scheduledAt });
    };

    handleCancelRescheduleMessage = () => {
        this.setState({ reschedulingMessage: null, rescheduleTime: null });
    };

    handleRescheduleMessage = () => {
        const { person, job } = this.props;
        const jobId = job ? job.id : null;
        // tslint:disable-next-line:no-magic-numbers
        const randomOffsetBefore = Math.round(Math.random() * 30 * 60 * 1000); // 30 mins before
        const newTime = this.state.rescheduleTime - randomOffsetBefore;
        const message = this.props.scheduledMessages
            .get(person.id)
            .find((m) => m.id === this.state.reschedulingMessage.id);
        this.props.rescheduleMessage(message, newTime, person.id, jobId);
        this.setState({ reschedulingMessage: null, rescheduleTime: null });
    };

    handleRescheduleTimeChange = (newValue: number) => {
        this.setState({ rescheduleTime: newValue });
    };

    handleCancelScheduledMessage = (message: ScheduledMessageView) => () => {
        const { person, job } = this.props;
        const jobId = job ? job.id : null;
        const title = message.sequenceRecipientId ? 'Exit Recipient From Sequence?' : 'Cancel Scheduled Message?';
        const description = message.sequenceRecipientId
            ? `Are you sure you want to exit this recipient from the sequence?
               No further messages will be sent out as part of the sequence`
            : 'Are you sure you want to cancel sending this message?';
        this.props.getConfirmation(
            () => {
                this.props.cancelScheduledMessage(message, person.id, jobId);
            },
            description,
            title
        );
    };

    renderScheduledMessageComponent = (message: ScheduledMessageView) => {
        const { job, person } = this.props;
        const jobId = job ? job.id : undefined;
        const personId = person.id;

        const size = 20;
        const thickness = 2;
        const spinner = <CircularProgress key="spinner" size={size} thickness={thickness} />;

        const rescheduleIcon = (
            <Tooltip title="Reschedule" key="reschedule-icon">
                <div
                    className={`email-action-icon email-action`}
                    onClick={this.handleRequestRescheduleMessage(message)}
                >
                    <ActionSchedule color={black} />
                </div>
            </Tooltip>
        );

        const cancelIcon = (
            <Tooltip title="Cancel" key="cancel-icon">
                <div className={`email-action-icon email-action`} onClick={this.handleCancelScheduledMessage(message)}>
                    <i className="material-icons">delete</i>
                </div>
            </Tooltip>
        );

        const actions = this.props.pendingRequests.has(`scheduled-messages-update-${message.id}`)
            ? [spinner]
            : [rescheduleIcon, cancelIcon];

        return (
            <div className="emails-group scheduled-emails" key={message.id}>
                <ScheduledMessage
                    scheduledMessage={message}
                    emailAccounts={this.props.emailAccounts}
                    personId={personId}
                    jobId={jobId}
                    actions={actions}
                />
            </div>
        );
    };

    render() {
        const { pendingRequests, person, job } = this.props;
        const { currentJobOnly, view } = this.state;
        const personId = person.id;
        const fetching = pendingRequests.has(`person-communications-${personId}`);
        const { communications: allEmails, scheduledMessages } = this.getEmailsForView();

        const threads = this.getThreadGroups(allEmails);

        const jobFilterTabOpts: Array<[boolean, string]> = job
            ? [
                  [false, 'All Jobs'],
                  [true, 'Current Job']
              ]
            : [[false, 'All Jobs']];
        const jobFilterTabs = jobFilterTabOpts.map(([jobSpecific, label]) => (
            <div
                key={jobSpecific.toString()}
                className={`notes-tab ${currentJobOnly === jobSpecific ? 'active' : ''}`}
                onClick={this.handleJobFilterChange(jobSpecific)}
            >
                {label}
            </div>
        ));

        const commKindTabOpts: Array<[EmailsView, string]> = [
            ['candidate', 'Candidate'],
            ['client', 'Client'],
            ['candidate-and-client', 'Shared']
        ];
        const pendingCounts = this.getPendingCountsByView(this.props);

        const commKindTabs = commKindTabOpts.map(([kind, label]) => {
            const pendingCount = pendingCounts.get(kind, 0);
            const badge =
                pendingCount > 0 ? <div className={`pending-emails-badge ${kind}`}>{pendingCount}</div> : null;
            return (
                <div
                    key={kind}
                    className={`notes-tab ${view === kind ? 'active' : ''}`}
                    onClick={this.handleEmailViewChange(kind)}
                >
                    {label} {badge}
                </div>
            );
        });

        const navBar = (
            <div className="notes-tabs">
                <div className="notes-tabs-left">
                    <div className="notes-tab-kind-filters">{commKindTabs}</div>
                </div>
                <div className="notes-tabs-right">
                    {jobFilterTabs}
                    <Tooltip title={fetching ? 'Loading' : 'Refresh'}>
                        <div
                            className={`refresh-action-button ${fetching ? 'disabled' : ''}`}
                            onClick={this.handleRefresh}
                        >
                            <i className="material-icons">refresh</i>
                        </div>
                    </Tooltip>
                </div>
            </div>
        );

        const groups = threads.map((thread, i) => {
            if (thread.length === 0) return null;
            const comms = thread.map(this.renderCommunicationComponent);
            return (
                <div className="emails-group" key={i}>
                    {comms}
                </div>
            );
        });

        const scheduledGroup = scheduledMessages
            .sort((m1, m2) => m2.scheduledAt - m1.scheduledAt)
            .map(this.renderScheduledMessageComponent);

        let rescheduleDialog;
        const { reschedulingMessage, rescheduleTime } = this.state;
        if (reschedulingMessage) {
            const rescheduleActions = [
                <FlatButton label="Cancel" onClick={this.handleCancelRescheduleMessage} key="cancel" />,
                <FlatButton
                    disabled={reschedulingMessage.scheduledAt === rescheduleTime}
                    label="Reschedule"
                    onClick={this.handleRescheduleMessage}
                    key="save"
                />
            ];
            const now = Date.now();
            rescheduleDialog = this.state.reschedulingMessage ? (
                <Dialog
                    open={true}
                    onRequestClose={this.handleCancelRescheduleMessage}
                    contentStyle={{ width: 360 }}
                    bodyStyle={{ padding: 0, height: 360 }}
                    actions={rescheduleActions}
                    actionsContainerClassName="email-compose-send-later-actions"
                >
                    <DateTimePicker
                        onChange={this.handleRescheduleTimeChange}
                        value={rescheduleTime}
                        minDate={Date.now()}
                        getNearestValidTime={getNearestValidEmailTs(now, undefined)}
                        getDisabledHours={getDisabledHours}
                        isDayDisabled={isDayDisabled(now, undefined)}
                    />
                </Dialog>
            ) : null;
        }

        return (
            <div className="person-emails">
                {navBar}
                {scheduledGroup}
                {groups}
                {rescheduleDialog}
            </div>
        );
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    candidates: state.candidates,
    communications: state.communications,
    emailAccounts: state.emailAccounts,
    emailContentBlacklistedDomains: state.appConstants.constants.emailContentBlacklistedDomains,
    jobs: state.jobs,
    listsState: state.listsState,
    pendingRequests: state.pendingRequests,
    permissions: state.session.userPermissions,
    scheduledMessages: state.scheduledMessages,
    user: state.session.user,
    users: state.users
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    cancelScheduledMessage,
    createNewNote,
    fetchJobsData,
    fetchUsers,
    getAllEmailAccountInfo,
    getCommunications,
    getConfirmation,
    getScheduledMessages,
    moveInGmail,
    replyToEmailWithValidation,
    replyToEmailWithValidationWithDifferentAccount,
    requestToasterOpen,
    rescheduleMessage,
    showModalAlert
};

const PersonCommunicationsContainer = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(CommunicationsComponent);

export const PersonCommunications: React.FC<OwnProps> = (props) => {
    const { data: commsData } = useSubscription<
        { communications: Array<{ internalDate: number }> },
        { personId: string }
    >(PERSON_LATEST_COMMUNICATION_AT, { variables: { personId: props.person.id } });
    const { data: scheduledMessagesData } = useSubscription<
        { messages: Array<{ modifiedAt: number }> },
        { personId: string }
    >(PERSON_LATEST_SCHEDULED_MESSAGE_AT, { variables: { personId: props.person.id } });
    const dispatch = useReduxDispatch();

    React.useEffect(() => {
        if (commsData?.communications?.length > 0) {
            dispatch(getCommunications(props.person.id));
        }
    }, [commsData?.communications?.[0]?.internalDate]);

    React.useEffect(() => {
        if (scheduledMessagesData?.messages?.length > 0) {
            dispatch(getScheduledMessages(props.person.id));
        }
    }, [scheduledMessagesData?.messages?.[0]?.modifiedAt]);

    return <PersonCommunicationsContainer {...props} />;
};
