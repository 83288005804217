import { Map } from 'immutable';
import { map } from 'lodash';

import { CrunchbaseData } from 'shared/types/crunchbase';

import { Action, ReceivePersonCrunchbaseData, ReceiveSearchProfiles } from '../actions';

const initialState: Map<string, CrunchbaseData> = Map();

export function crunchbase(state = initialState, action: Action): Map<string, CrunchbaseData> {
    switch (action.type) {
        case ReceivePersonCrunchbaseData:
        case ReceiveSearchProfiles: {
            let updated = state;
            map(action.payload.crunchbaseData, (cb, cbUUID) => {
                updated = updated.set(cbUUID, cb);
            });
            return updated;
        }
        default:
            return state;
    }
}
