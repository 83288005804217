import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { JobMetrics } from '../components/metrics/job-metrics';

interface RouterProps {
    id: string;
}

const JobMetricsComponent: React.FC<RouteComponentProps<RouterProps>> = ({ match }) => (
    <JobMetrics jobId={match.params.id} />
);

export const JobMetricsPage = withRouter(JobMetricsComponent);
