import { Action, UpdateUserPrefs } from '../actions';
import {
    disableAutoRedirectKey,
    jobSearchesTablePrefsKey,
    searchSourcingStatsTablePrefsKey,
    selectedJobIdKey,
    selectedProfileTabKey
} from '../common/local-storage';
import { getLocalStorageKey } from '../common/local-storage';
import { Preferences } from '../state';

const initialState: Preferences = {
    [disableAutoRedirectKey]: getLocalStorageKey(disableAutoRedirectKey, false),
    [selectedJobIdKey]: getLocalStorageKey(selectedJobIdKey, null),
    [selectedProfileTabKey]: getLocalStorageKey(selectedProfileTabKey, 'profile'),
    [searchSourcingStatsTablePrefsKey]: getLocalStorageKey(searchSourcingStatsTablePrefsKey, {
        sortAsc: true,
        sortColumn: 'Job'
    }),
    [jobSearchesTablePrefsKey]: getLocalStorageKey(jobSearchesTablePrefsKey, {})
};

export function preferences(state = initialState, action: Action): Preferences {
    switch (action.type) {
        case UpdateUserPrefs:
            return Object.assign({}, state, action.payload.data);
        default:
            return state;
    }
}
