import { css } from '@emotion/core';
import { CircularProgress } from '@material-ui/core';
import React from 'react';

import { StageCountsRecordWithFunnel } from '../graphql/queries/candidates-board';
import { KanbanColumn } from './kanban-column';

const styles = css`
    flex: 1 1 auto;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    background: #f8f8f8;

    .scroll-surface {
        cursor: grab;
        flex: 1 1 auto;
        overflow-x: auto;
        display: flex;
        align-items: stretch;

        &.grabbing {
            cursor: grabbing;
        }
    }

    .loading-indicator {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const outreachSentStageId = 8;

export const Kanban: React.FC<{ stageCounts: StageCountsRecordWithFunnel[] }> = ({ stageCounts }) => {
    const containerRef = React.useRef<HTMLDivElement>();
    const scrollToRef = React.useRef<HTMLDivElement>();
    const [initialScrollDone, setInitialScrollDone] = React.useState(false);
    const [grabbing, setGrabbing] = React.useState(false);
    const [startX, setStartX] = React.useState(0);
    const [scrollLeft, setScrollLeft] = React.useState(0);

    React.useEffect(() => {
        if (stageCounts && !initialScrollDone && scrollToRef.current) {
            scrollToRef.current.scrollIntoView({ inline: 'start' });
            setInitialScrollDone(true);
        }
    }, [stageCounts]);

    const handleMouseDown = (e: React.MouseEvent) => {
        setStartX(e.pageX - containerRef.current.offsetLeft);
        setScrollLeft(containerRef.current.scrollLeft);
        setGrabbing(true);
    };

    const handleMouseUp = (_1: React.MouseEvent) => {
        setGrabbing(false);
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        if (grabbing) {
            e.preventDefault();
            const scrollSpeed = 1;
            const x = e.pageX - containerRef.current.offsetLeft;
            const walk = x - startX;
            containerRef.current.scrollLeft = scrollLeft - walk * scrollSpeed;
        }
    };

    let content: JSX.Element | JSX.Element[];
    if (stageCounts) {
        content = [];
        let scrollTargetAdded = false;
        for (const stage of stageCounts) {
            if (stage.id > outreachSentStageId && !scrollTargetAdded) {
                scrollTargetAdded = true;
                content.push(<div key="kanban-scroll-target" ref={scrollToRef} />);
            }
            content.push(<KanbanColumn stageCounts={stage} key={stage.name} />);
        }
    } else {
        content = (
            <div className="loading-indicator">
                <CircularProgress />
            </div>
        );
    }
    return (
        <div css={styles}>
            <div
                className={`scroll-surface ${grabbing ? 'grabbing' : ''}`}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                onMouseMove={handleMouseMove}
                ref={containerRef}
            >
                {content}
            </div>
        </div>
    );
};
