import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React from 'react';
import { FileDownloadLink } from '../core-ui/file-download-link';

import { Invoice as InvoiceData } from '../graphql/queries/billing';
import { InvoiceDetails } from './invoice-details';

interface InvoiceDetailsDialogProps {
    open: boolean;
    onClose: () => void;
    invoice?: InvoiceData;
}

export const InvoiceDetailsDialog: React.FC<InvoiceDetailsDialogProps> = ({ open, onClose, invoice }) => {
    if (!invoice) return null;

    const viewPdfButton = invoice.pdf ? (
        <FileDownloadLink path={invoice.pdf} filename={invoice.pdf?.split('/')?.pop()}>
            <Button>View PDF</Button>
        </FileDownloadLink>
    ) : null;

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md">
            <DialogTitle>
                <Typography variant="h4" component="div">
                    Invoice Details
                </Typography>
            </DialogTitle>
            <DialogContent>
                <InvoiceDetails data={invoice} />
            </DialogContent>
            <DialogActions>
                {viewPdfButton}
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};
