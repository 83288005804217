import { useLazyQuery } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';
import moment from 'moment';
import React, { createContext, FC, useCallback, useContext, useMemo, useState } from 'react';

import { CandidateStageChange, STAGE_CHANGE_CANDIDATES } from '../graphql/queries/recruiter-stats';
import { useSlides } from './use-candidate-slides';
import { useModal } from './use-modal';

interface PeriodFunnelCandidatesData {
    stage: string;
    label: string;
    user: { id: string; name: string };
    period: { start: number; end: number };
}

interface PeriodFunnelCandidatesContextType {
    setPeriodFunnelCandidatesData: (data: PeriodFunnelCandidatesData) => void;
}

const PeriodFunnelCandidatesContext = createContext<PeriodFunnelCandidatesContextType | undefined>(undefined);

const stageChangeDialogStyles = css`
    .dialog-title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .secondary {
            font-size: 16px;
        }
    }

    .MuiTableRow-root {
        cursor: pointer;

        &:hover {
            background-color: #f5f5f5;
        }
    }
`;

const getDates = (numerical: { start: number; end: number }) => {
    // add 1 ms to start and subtract 1 ms from end to bound to be "in between" the days
    const startDate = moment(numerical.start + 1).format('YYYY-MM-DD');
    const endDate = moment(numerical.end - 1).format('YYYY-MM-DD');
    return { startDate, endDate };
};

export const PeriodFunnelCandidatesProvider: FC = ({ children }) => {
    const [data, setData] = useState<PeriodFunnelCandidatesData | undefined>(undefined);
    const [changes, setChanges] = useState<CandidateStageChange[] | undefined>(undefined);
    const [fetchStageChangeCandidates] = useLazyQuery<
        { changes: CandidateStageChange[] },
        { userId: string; stage: string; startDate: string; endDate: string }
    >(STAGE_CHANGE_CANDIDATES);
    const { setAlert, showLoading, hideLoading } = useModal();
    const { setList } = useSlides();

    const fetchChangesData = async (val: PeriodFunnelCandidatesData) => {
        const { stage, period, user } = val;
        const { startDate, endDate } = getDates(period);
        showLoading();
        try {
            const result = await fetchStageChangeCandidates({
                variables: {
                    endDate,
                    stage,
                    startDate,
                    userId: user.id
                }
            });
            setChanges(result.data.changes);
            hideLoading();
        } catch (err) {
            hideLoading();
            setAlert('Error', 'Unable to fetch candidate details');
        }
    };

    const handleShowSlides = (personId: string, jobId: string) => () => {
        const list = changes.map((change) => ({ personId: change.person.id, jobId: change.job.id }));
        setList(list, { personId, jobId });
    };

    const handleCloseDialog = () => {
        setData(undefined);
        setChanges(undefined);
    };

    const setPeriodFunnelCandidatesData = useCallback(
        (val: PeriodFunnelCandidatesData) => {
            setData(val);
            fetchChangesData(val);
        },
        [setData, fetchChangesData]
    );

    const value = useMemo(() => ({ setPeriodFunnelCandidatesData }), [setPeriodFunnelCandidatesData]);

    let dialog;

    if (data && changes) {
        const { period, user, label } = data;
        const changeRows = changes.map((change, i) => {
            return (
                <TableRow key={change.personId} onClick={handleShowSlides(change.personId, change.jobId)}>
                    <TableCell>{i + 1}</TableCell>
                    <TableCell>{change.person.name}</TableCell>
                    <TableCell>
                        {change.job.client.name} - {change.job.title}
                    </TableCell>
                    <TableCell>{change.date}</TableCell>
                </TableRow>
            );
        });
        const { startDate, endDate } = getDates(period);
        dialog = (
            <Dialog
                open={true}
                onClose={handleCloseDialog}
                maxWidth="md"
                fullWidth={true}
                css={stageChangeDialogStyles}
            >
                <DialogTitle>
                    <Typography variant="h4" component="div" className="dialog-title">
                        <div>
                            {label} - {user.name}
                        </div>
                        <div className="secondary">
                            {startDate} - {endDate}
                        </div>
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Candidate</TableCell>
                                <TableCell>Job</TableCell>
                                <TableCell>Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{changeRows}</TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Close</Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <PeriodFunnelCandidatesContext.Provider value={value}>
            {children}
            {dialog}
        </PeriodFunnelCandidatesContext.Provider>
    );
};

export const usePeriodFunnelCandidates = () => {
    const context = useContext(PeriodFunnelCandidatesContext);
    if (!context) {
        throw new Error('usePeriodFunnelCandidates must be used within a PeriodFunnelCandidatesProvider');
    }
    return context;
};
