import * as _ from 'lodash';

import { Action } from '../../actions';
import { Communication } from '../../state';
import {
    MarkMessagesUnread,
    ReceiveAccountData,
    ReceiveAttachmentDownload,
    ReceiveEmailAccountLabelData,
    ReceiveInboxSearchResults,
    ReceiveThreadsPage,
    ReceiveThreadsUpdate,
    RequestAccountData,
    RequestAttachmentDownload,
    RequestDeleteMessage,
    RequestDeleteThreads,
    RequestDiscardDrafts,
    RequestInboxSearch,
    RequestUntrashThreads,
    RequestUpdateThreadLabels,
    SelectAllThreads,
    SelectEmailAccountLabel,
    SelectThread,
    SetSearchString,
    SetVisibleThreads,
    ViewThreadDetails
} from '../actions';
import { EmailInboxData, EmailThread, searchResultLabel, threadsPageSize } from '../types';

const stateDefaults: {
    searchString: string;
    selectedThreads: string[];
    threadsStartIndex: number;
    viewingThread: string;
} = {
    searchString: '',
    selectedThreads: [],
    threadsStartIndex: 1,
    viewingThread: null
};

export function emailInbox(state: EmailInboxData = null, action: Action): EmailInboxData {
    switch (action.type) {
        case ReceiveAccountData: {
            return Object.assign(
                {},
                state,
                stateDefaults,
                {
                    fetching: false,
                    initialized: true
                },
                action.payload
            );
        }
        case RequestAccountData: {
            return Object.assign({}, stateDefaults, {
                account: action.payload.account,
                fetching: true,
                initialized: false
            });
        }
        case SetVisibleThreads: {
            switch (action.payload.direction) {
                case 'backward':
                    return Object.assign({}, state, {
                        fetching: true,
                        threads: [],
                        threadsStartIndex: state.threadsStartIndex - threadsPageSize
                    });
                case 'forward':
                    return Object.assign({}, state, {
                        fetching: true,
                        threads: state.threads.slice(threadsPageSize),
                        threadsStartIndex: state.threadsStartIndex + threadsPageSize
                    });
                default:
                    throw new Error('unknown navigation direction: ' + action.payload.direction);
            }
        }
        case ReceiveThreadsPage: {
            const labels = state.labels
                .filter((l) => action.payload.labels.findIndex((lbl) => lbl.id === l.id) === -1)
                .concat(action.payload.labels);
            const threads = state.threads.concat(action.payload.threads);
            return Object.assign({}, state, { fetching: false, labels, threads, initialized: true });
        }
        case SelectEmailAccountLabel: {
            const threads =
                state.selectedLabel === action.payload.label && state.threadsStartIndex === 1 ? state.threads : [];
            return Object.assign({}, state, {
                fetching: true,
                initialized: threads.length > 0,
                searchString: '',
                selectedLabel: action.payload.label,
                selectedThreads: [],
                threads,
                threadsStartIndex: 1,
                viewingThread: null
            });
        }
        case ReceiveEmailAccountLabelData: {
            const labels = state.labels
                .filter((l) => action.payload.labels.findIndex((lbl) => lbl.id === l.id) === -1)
                .concat(action.payload.labels);
            const threads = action.payload.threads;
            return Object.assign({}, state, { fetching: false, labels, threads, initialized: true });
        }
        case SelectAllThreads: {
            const selectedThreads = action.payload.checked ? state.threads.map((t) => t.id) : [];
            return Object.assign({}, state, { selectedThreads });
        }
        case SelectThread: {
            const { checked, threadId } = action.payload;
            const selectedThreads = checked
                ? state.selectedThreads.concat([threadId])
                : state.selectedThreads.filter((t) => t !== threadId);
            return Object.assign({}, state, { selectedThreads });
        }
        case ReceiveThreadsUpdate: {
            const updatedThreads = action.payload.threads;
            const threads = state.threads.map((t) => {
                const updated = updatedThreads.find((thread) => thread.id === t.id);
                return updated || t;
            });
            const labels = state.labels
                .filter((l) => action.payload.labels.findIndex((lbl) => lbl.id === l.id) === -1)
                .concat(action.payload.labels);
            return Object.assign({}, state, { threads, labels });
        }
        case RequestUpdateThreadLabels: {
            const { threadIds, labelsRemoved, labelsAdded } = action.payload;
            const labelCounts: {
                [label: string]: {
                    messagesTotal: number;
                    messagesUnread: number;
                    threadsTotal: number;
                    threadsUnread: number;
                };
            } = {};
            for (const l of state.labels) {
                labelCounts[l.id] = {
                    messagesTotal: l.messagesTotal,
                    messagesUnread: l.messagesUnread,
                    threadsTotal: l.threadsTotal,
                    threadsUnread: l.threadsUnread
                };
            }
            const unreadRemoved = labelsRemoved.indexOf('UNREAD') !== -1;
            const unreadAdded = labelsAdded.indexOf('UNREAD') !== -1;
            const threads: EmailThread[] = [];
            for (const thread of state.threads) {
                if (threadIds.indexOf(thread.id) === -1) {
                    threads.push(thread);
                } else {
                    const threadLabels: string[] = _.uniq(_.flattenDeep<string>(thread.messages.map((m) => m.labels)));
                    for (const l of _.difference(threadLabels, ['UNREAD'])) {
                        if (unreadRemoved) {
                            labelCounts[l].threadsUnread--;
                        }
                        if (unreadAdded) {
                            labelCounts[l].threadsUnread++;
                        }
                    }
                    for (const l of labelsRemoved) {
                        labelCounts[l].threadsTotal--;
                        if (threadLabels.indexOf('UNREAD') !== -1 && l !== 'UNREAD') {
                            labelCounts[l].threadsUnread--;
                        }
                    }
                    for (const l of labelsAdded) {
                        labelCounts[l].threadsTotal++;
                        if (threadLabels.indexOf('UNREAD') !== -1 && l !== 'UNREAD') {
                            labelCounts[l].threadsUnread++;
                        }
                    }
                    if (unreadRemoved) {
                        labelCounts.UNREAD.threadsUnread--;
                    }
                    if (unreadAdded) {
                        labelCounts.UNREAD.threadsUnread++;
                    }
                    const messages: Communication[] = [];
                    for (const message of thread.messages) {
                        for (const l of _.difference(message.labels, ['UNREAD'])) {
                            if (unreadRemoved) {
                                labelCounts[l].messagesUnread--;
                            }
                            if (unreadAdded) {
                                labelCounts[l].messagesUnread++;
                            }
                        }
                        for (const l of labelsRemoved) {
                            labelCounts[l].messagesTotal--;
                            if (message.labels.indexOf('UNREAD') !== -1 && l !== 'UNREAD') {
                                labelCounts[l].messagesUnread--;
                            }
                        }
                        for (const l of labelsAdded) {
                            labelCounts[l].messagesTotal++;
                            if (message.labels.indexOf('UNREAD') !== -1 && l !== 'UNREAD') {
                                labelCounts[l].messagesUnread++;
                            }
                        }
                        if (unreadRemoved) {
                            labelCounts.UNREAD.messagesUnread--;
                        }
                        if (unreadAdded) {
                            labelCounts.UNREAD.messagesUnread++;
                        }
                        const messageLabels = _.difference(message.labels.concat(labelsAdded), labelsRemoved);
                        if (messageLabels.indexOf(state.selectedLabel) !== -1) {
                            messages.push(
                                Object.assign({}, message, {
                                    labels: messageLabels
                                })
                            );
                        }
                    }
                    if (messages.length > 0) {
                        threads.push(Object.assign({}, thread, { messages }));
                    }
                }
            }
            const labels = state.labels.map((l) => Object.assign({}, l, labelCounts[l.id]));
            const selectedThreads = _.intersection(
                state.selectedThreads,
                threads.map((t) => t.id)
            );
            return Object.assign({}, state, { threads, labels, selectedThreads });
        }
        case RequestDeleteThreads: {
            const [removedThreads, remainingThreads] = _.partition(
                state.threads,
                (t) => action.payload.threadIds.indexOf(t.id) !== -1
            );
            const labelCounts: {
                [label: string]: {
                    messagesTotal: number;
                    messagesUnread: number;
                    threadsTotal: number;
                    threadsUnread: number;
                };
            } = {};
            for (const l of state.labels) {
                labelCounts[l.id] = {
                    messagesTotal: l.messagesTotal,
                    messagesUnread: l.messagesUnread,
                    threadsTotal: l.threadsTotal,
                    threadsUnread: l.threadsUnread
                };
            }
            for (const thread of removedThreads) {
                const threadLabels: string[] = _.uniq(_.flattenDeep<string>(thread.messages.map((m) => m.labels)));
                const unread = threadLabels.indexOf('UNREAD') !== -1;
                for (const l of threadLabels) {
                    labelCounts[l].threadsTotal--;
                    labelCounts.ALL.threadsTotal--;
                    if (unread) {
                        labelCounts[l].threadsUnread--;
                    }
                }
                for (const message of thread.messages) {
                    const unreadMessage = message.labels.indexOf('UNREAD') !== -1;
                    for (const l of message.labels) {
                        labelCounts[l].messagesTotal--;
                        labelCounts.ALL.messagesTotal--;
                        if (unreadMessage) {
                            labelCounts[l].messagesUnread--;
                        }
                    }
                }
            }
            const labels = state.labels.map((l) => Object.assign({}, l, labelCounts[l.id]));
            const selectedThreads = _.intersection(
                state.selectedThreads,
                remainingThreads.map((t) => t.id)
            );
            return Object.assign({}, state, { threads: remainingThreads, labels, selectedThreads });
        }
        case RequestUntrashThreads: {
            // recover only happens from TRASH selected label - when recovering these messages go away from the view
            const threads = state.threads.filter((t) => action.payload.threadIds.indexOf(t.id) === -1);
            return Object.assign({}, state, { threads, selectedThreads: [] });
        }
        case RequestDiscardDrafts: {
            // discard drafts only happens from DRAFT selected label when discarding remove these threads from the view
            const threads = state.threads.filter((t) => action.payload.threadIds.indexOf(t.id) === -1);
            const labels = state.labels.map((l) => {
                if (l.id === 'DRAFT') {
                    return Object.assign({}, l, {
                        threadsTotal: l.threadsTotal - action.payload.threadIds.length
                    });
                } else {
                    return l;
                }
            });
            return Object.assign({}, state, { threads, selectedThreads: [], labels });
        }
        case RequestInboxSearch: {
            return Object.assign({}, state, {
                fetching: true,
                labels: state.labels.concat([
                    {
                        id: searchResultLabel,
                        labelListVisibility: 'labelHide',
                        messageListVisibility: 'hide',
                        messagesTotal: 0,
                        messagesUnread: 0,
                        name: '',
                        threadsTotal: 0,
                        threadsUnread: 0,
                        type: 'user'
                    }
                ]),
                selectedLabel: searchResultLabel,
                selectedThreads: [],
                threads: [],
                threadsStartIndex: 1
            });
        }
        case ReceiveInboxSearchResults: {
            const labels = state.labels.map((l) =>
                l.id === searchResultLabel ? Object.assign({}, l, action.payload.labelData) : l
            );
            return Object.assign({}, state, {
                fetching: false,
                labels,
                selectedThreads: [],
                threads: action.payload.threads,
                threadsStartIndex: 1,
                viewingThread: null
            });
        }
        case SetSearchString:
            return Object.assign({}, state, { searchString: action.payload.searchString });
        case ViewThreadDetails: {
            const { threadId } = action.payload;
            return Object.assign({}, state, { viewingThread: threadId, selectedThreads: threadId ? [threadId] : [] });
        }
        case MarkMessagesUnread: {
            const { messageId, threadId } = action.payload;
            const threadIndex = state.threads.findIndex((t) => t.id === threadId);
            const thread = state.threads[threadIndex];
            const messages = [];
            let found = false;
            for (const message of thread.messages) {
                if (message.messageId === messageId) {
                    found = true;
                }
                const updatedMessage = found
                    ? Object.assign({}, message, { labels: message.labels.concat(['UNREAD']) })
                    : message;
                messages.push(updatedMessage);
            }
            const updatedThread = Object.assign({}, thread, { messages });
            const threads = state.threads
                .slice(0, threadIndex)
                .concat([updatedThread], state.threads.slice(threadIndex + 1));
            return Object.assign({}, state, { threads });
        }
        case RequestDeleteMessage: {
            const { messageId, threadId } = action.payload;
            const threadIndex = state.threads.findIndex((t) => t.id === threadId);
            const thread = state.threads[threadIndex];
            const messages = [];
            for (const message of thread.messages) {
                const updatedMessage =
                    message.messageId === messageId
                        ? Object.assign({}, message, {
                              labels: ['TRASH']
                          })
                        : message;
                messages.push(updatedMessage);
            }
            const updatedThread = Object.assign({}, thread, { messages });
            const threads = state.threads
                .slice(0, threadIndex)
                .concat([updatedThread], state.threads.slice(threadIndex + 1));
            return Object.assign({}, state, { threads });
        }
        case RequestAttachmentDownload:
            return Object.assign({}, state, { downloadingAttachment: true });
        case ReceiveAttachmentDownload:
            return Object.assign({}, state, { downloadingAttachment: false });
        default:
            return state;
    }
}
