import { FlatButton, IconButton } from 'material-ui';
import * as React from 'react';
import { connect } from 'react-redux';

import { State } from '../../state';
import {
    deleteThreads,
    discardDrafts,
    fetchEmailAccountLabelThreads,
    selectAllThreads,
    untrashThreads,
    updateThreadLabels,
    viewThreadDetails
} from '../actions';
import { EmailInboxData } from '../types';
import { EmailInboxSearch } from './email-inbox-search';

interface ConnectedProps {
    data: EmailInboxData;
}

interface ConnectedDispatch {
    deleteThreads: (threadIds: string[]) => void;
    discardDrafts: (threadIds: string[]) => void;
    fetchEmailAccountLabelThreads: (label: string) => void;
    selectAllThreads: (checked: boolean) => void;
    updateThreadLabels: (threadIds: string[], labelsAdded: string[], labelsRemoved: string[]) => void;
    viewThreadDetails: (threadId: string) => void;
    untrashThreads: (threadIds: string[]) => void;
}

type EmailInboxActionsProps = ConnectedProps & ConnectedDispatch;

class EmailInboxActionsComponent extends React.Component<EmailInboxActionsProps, undefined> {
    handleRefreshClick = () => {
        this.props.fetchEmailAccountLabelThreads(this.props.data.selectedLabel);
    };

    handleCheckboxClick = () => {
        const { data } = this.props;
        const selectedCount = !data || !data.selectedThreads ? 0 : data.selectedThreads.length;
        const checked = selectedCount === 0;
        this.props.selectAllThreads(checked);
    };

    handleArchive = () => {
        this.props.updateThreadLabels(this.props.data.selectedThreads, [], ['INBOX']);
    };

    handleReadUnread = () => {
        this.props.updateThreadLabels(this.props.data.selectedThreads, [], ['UNREAD']);
    };

    handleDelete = () => {
        this.props.deleteThreads(this.props.data.selectedThreads);
    };

    handleBack = () => {
        this.props.viewThreadDetails(null);
    };

    handleRecoverThread = () => {
        this.props.untrashThreads(this.props.data.selectedThreads);
    };

    handleDiscardDrafts = () => {
        this.props.discardDrafts(this.props.data.selectedThreads);
    };

    render() {
        const { data } = this.props;
        const disabled = !data || !data.initialized || data.fetching;
        const selectedCount = !data || !data.selectedThreads ? 0 : data.selectedThreads.length;
        const selectedActions = [];
        if (!disabled && selectedCount > 0) {
            if (data.selectedLabel !== 'DRAFT' && data.selectedLabel !== 'TRASH') {
                selectedActions.push(<div className="email-inbox-action-divider" key="divider-select-actions" />);
                selectedActions.push(
                    <IconButton
                        className="email-inbox-action-button"
                        key="archive"
                        tooltip="Archive"
                        onClick={this.handleArchive}
                        disabled={disabled}
                    >
                        <i className="material-icons">archive</i>
                    </IconButton>
                );
                selectedActions.push(
                    <IconButton
                        className="email-inbox-action-button"
                        key="delete"
                        tooltip="Delete"
                        disabled={disabled}
                        onClick={this.handleDelete}
                    >
                        <i className="material-icons">delete</i>
                    </IconButton>
                );
                selectedActions.push(
                    <IconButton
                        className="email-inbox-action-button"
                        key="mark-read"
                        tooltip="Mark as read"
                        onClick={this.handleReadUnread}
                        disabled={disabled}
                    >
                        <i className="fas fa-envelope-open" />
                    </IconButton>
                );
            }
            if (data.selectedLabel === 'TRASH') {
                selectedActions.push(<div className="email-inbox-action-divider" key="divider-select-actions" />);
                selectedActions.push(
                    <FlatButton
                        className="email-inbox-action-button"
                        key="recover"
                        onClick={this.handleRecoverThread}
                        disabled={disabled}
                        label="Recover"
                        labelStyle={{ textTransform: 'capitalize' }}
                    />
                );
            }
            if (data.selectedLabel === 'DRAFT') {
                selectedActions.push(<div className="email-inbox-action-divider" key="divider-select-actions" />);
                selectedActions.push(
                    <FlatButton
                        className="email-inbox-action-button"
                        key="discard"
                        onClick={this.handleDiscardDrafts}
                        disabled={disabled}
                        label="Discard Drafts"
                        labelStyle={{ textTransform: 'capitalize' }}
                    />
                );
            }
        }
        const checkbox =
            selectedCount === 0
                ? 'check_box_outline_blank'
                : selectedCount === data.threads.length
                  ? 'check_box'
                  : 'indeterminate_check_box';
        const listActions = data.viewingThread
            ? null
            : [
                  <IconButton
                      className="email-inbox-action-button checkbox"
                      disabled={disabled}
                      onClick={this.handleCheckboxClick}
                      key="select"
                  >
                      <i className="material-icons">{checkbox}</i>
                  </IconButton>,
                  <div className="email-inbox-action-divider" key="divider" />,
                  <IconButton
                      className="email-inbox-action-button"
                      disabled={disabled}
                      key="refresh"
                      onClick={this.handleRefreshClick}
                  >
                      <i className="material-icons">refresh</i>
                  </IconButton>
              ];
        const viewingThreadActions = data.viewingThread ? (
            <IconButton className="email-inbox-action-button" disabled={disabled} onClick={this.handleBack}>
                <i className="material-icons">arrow_back</i>
            </IconButton>
        ) : null;
        return (
            <div className="email-inbox-actions">
                {listActions}
                {viewingThreadActions}
                {selectedActions}
                <div className="email-inbox-action-divider" />
                <EmailInboxSearch />
            </div>
        );
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    data: state.emailInbox
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    deleteThreads,
    discardDrafts,
    fetchEmailAccountLabelThreads,
    selectAllThreads,
    untrashThreads,
    updateThreadLabels,
    viewThreadDetails
};

export const EmailInboxActions = connect<ConnectedProps, ConnectedDispatch, {}>(
    mapStateToProps,
    mapDispatchToProps
)(EmailInboxActionsComponent);
