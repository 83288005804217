import { useMutation } from '@apollo/client';
import { css } from '@emotion/core';
import { Button } from '@material-ui/core';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { hasRole } from 'shared/models/user';

import { FormContext, FormContextProps, useFormContextData } from '../components/form-context';
import { JobFormClient } from '../components/job-form-client';
import { JobFormContract } from '../components/job-form-contract';
import { JobFormGeneral } from '../components/job-form-general';
import { JobFormPanel } from '../components/job-form-panel';
import { client as apolloClient } from '../graphql/apollo-client';
import { CREATE_JOB } from '../graphql/queries/job-form';
import { useModal } from '../hooks/use-modal';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';
import { JobType } from '../state';

const styles = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 auto;
    padding-bottom: 20px;
    overflow-y: auto;

    .form-main {
        width: 960px;
        margin: 0 auto;
    }

    .form-action-buttons {
        display: flex;
        width: 960px;
        justify-content: flex-end;

        .MuiButton-root {
            margin-left: 10px;
        }
    }
`;

interface NewJobData {
    accountManagerId: string;
    accountManagerPercentage: number;
    assignee: string;
    clientId: string;
    discipline: string;
    jobType: JobType;
    placementFees: string;
    recruiterPercentage: number;
    title: string;
}

const jobDefaults: NewJobData = {
    accountManagerId: null,
    accountManagerPercentage: 5,
    assignee: null,
    clientId: null,
    discipline: null,
    jobType: null,
    placementFees: null,
    recruiterPercentage: 10,
    title: ''
};

export const NewJob: React.FC = () => {
    const mutationClient = apolloClient('job_creator');
    const [createJob, { loading }] = useMutation<{ job: { id: string } }, { job: NewJobData }>(CREATE_JOB, {
        client: mutationClient
    });
    const { setSnackbar } = useSnackbar();
    const { getConfirmation } = useModal();
    const [redirect, setRedirect] = React.useState<string | null>(null);
    const context = useFormContextData<NewJobData>(jobDefaults);
    const { data, validateFieldExists, isDirty } = context;
    const { userPermissions } = useSession();
    const canEdit = hasRole(userPermissions, 'job_creator');
    const disabled = !canEdit || loading;

    const handleCancel = () => {
        if (isDirty) {
            getConfirmation(() => setRedirect('/'), 'Are you sure you want to discard changes?', 'Discard changes');
        } else {
            setRedirect('/');
        }
    };

    const validateForm = () => {
        const fields: Array<keyof NewJobData> = [
            'title',
            'clientId',
            'discipline',
            'jobType',
            'accountManagerId',
            'assignee',
            'placementFees'
        ];
        let isValid = true;
        fields.forEach((field) => {
            isValid = validateFieldExists(field)(data[field]) && isValid;
        });
        return isValid;
    };

    const handleSubmit = async () => {
        if (validateForm()) {
            setSnackbar('Creating job...');
            const result = await createJob({ variables: { job: data } });
            setSnackbar('Job created');
            setRedirect(`/job/${result.data.job.id}/edit`);
        } else {
            setSnackbar('Please fill in all required fields');
        }
    };

    if (redirect) {
        return <Redirect to={redirect} />;
    }

    const formContext: FormContextProps<NewJobData> = {
        ...context,
        disabled
    };

    return (
        <div css={styles}>
            <div className="form-main">
                <FormContext.Provider value={formContext}>
                    <JobFormPanel>
                        <JobFormClient />
                        <JobFormGeneral />
                        <JobFormContract />
                    </JobFormPanel>
                    <div className="form-action-buttons">
                        <Button disabled={loading} variant="contained" color="default" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            disabled={!isDirty || disabled}
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            Save
                        </Button>
                    </div>
                </FormContext.Provider>
            </div>
        </div>
    );
};
