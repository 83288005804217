import { gql } from '@apollo/client';

export interface RefreshesData {
    refreshes: {
        _id: string;
        lastRefreshAt: number;
    };
}

export const MATERIALIZED_VIEW_REFRESH = gql`
    subscription MaterializedViewRefresh($name: String!) {
        refreshes: materialized_view_refreshes_by_pk(name: $name) {
            _id: name
            lastRefreshAt
        }
    }
`;
