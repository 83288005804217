import { format, isValidNumber, parse } from 'libphonenumber-js';

const defaultCountry = 'US';

export function formatNumber(value: string): string {
    const phoneNumber = parse(value, { defaultCountry });
    const formatLocale = phoneNumber.country === defaultCountry ? 'National' : 'International';
    return format(phoneNumber, formatLocale);
}

export function isValidPhoneNumber(value: string): boolean {
    return isValidNumber(value, defaultCountry);
}
