import { orderBy } from 'lodash';

import { ContactChannel } from 'shared/models/contact';

import { Contact, ContactType, Person } from '../state';
import { getPersonalDomainRank } from './email/personal-domains';
import { isWhitelistedWorkEmail } from './email/whitelisted-work-domains';

// Return the expiration date of our opt-out period, for this client/job
export function getOptOutTime(person: Person, clientId: string): number {
    const globalCooldown = person.optOutGlobal ? person.optOutGlobal : 0;
    const clientCooldownRecord = clientId ? person.optOutClients.find((o) => o.clientId === clientId) : null;
    const clientCooldown = clientCooldownRecord ? clientCooldownRecord.ts : 0;
    return Math.max(globalCooldown, clientCooldown);
}

export function timeStampInOptOutPeriod(timeStamp: number): boolean {
    return timeStamp > Date.now();
}
export function inOptOutPeriod(person: Person, clientId: string): boolean {
    return timeStampInOptOutPeriod(getOptOutTime(person, clientId));
}

export function getEmailForOutreach(contacts: Contact[], personId: string, contactTypes: ContactType[]) {
    for (const contactType of contactTypes) {
        const primary = contacts.filter(
            (c) => c.channel === ContactChannel.Email && c.primary && c.contactType === contactType
        );
        if (primary.length === 1) {
            return primary[0].value;
        } else if (primary.length > 1) {
            throw new Error('multiple primary emails for ' + personId + ' for contact type ' + contactType);
        } else {
            // If no primary email
            // find email in the following order for personal
            // 1: high quality personal email
            // 2: marked personal email
            // 3: educational email

            // find email in the following order for work
            // 1: marked work email
            // 2: whitelisted work email

            const emails = contacts.filter((c) => c.channel === ContactChannel.Email && !c.invalid);
            if (contactType === ContactType.Personal) {
                const highQualityEmails = emails.filter((e) => getPersonalDomainRank(e.value) !== -1);
                const highQuality = orderBy(highQualityEmails, [(c) => getPersonalDomainRank(c.value)], ['asc'])[0];
                if (highQuality) return highQuality.value;

                const lowQuality = emails.find((c) => c.contactType === ContactType.Personal);
                if (lowQuality) return lowQuality.value;

                const educational = emails.find((c) => c.value.endsWith('.edu'));
                if (educational) return educational.value;
            } else if (contactType === ContactType.Work) {
                const work = emails.find((c) => c.contactType === ContactType.Work);
                if (work) return work.value;

                const whitelistedWork = emails.find((c) => isWhitelistedWorkEmail(c.value));
                if (whitelistedWork) return whitelistedWork.value;
            } else {
                const unknown = emails.find((c) => c.contactType === ContactType.Unknown);
                if (unknown) return unknown.value;
            }
        }
    }
    return null;
}
