import React from 'react';

import { MAX_FUNDING } from 'shared/common/crunchbase-filters';
import { currencyFormat } from 'shared/common/string-format-utils';

import { SliderForm } from './slider-form';

// tslint:disable-next-line: no-magic-numbers
const ENDPOINTS = [0, 500000, 1000000, 2000000, 5000000, 10000000, 25000000, 50000000, MAX_FUNDING];

const fundingToLabel = (value: number): string => {
    const suffix = value === MAX_FUNDING ? '+' : '';
    return currencyFormat(value, 0) + suffix;
};

export const FundingAmountForm: React.FC<{
    readonly: boolean;
    filters: [number, number];
    onChange: (val: [number, number]) => void;
}> = ({ readonly, filters, onChange }) => {
    return (
        <SliderForm
            readonly={readonly}
            endpoints={ENDPOINTS}
            fieldName="Total Funding"
            value={filters}
            onChange={onChange}
            getLabel={fundingToLabel}
        />
    );
};
