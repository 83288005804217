import { Pagination } from '@material-ui/lab';
import { Map, OrderedMap } from 'immutable';
import { CircularProgress, IconButton, Menu, MenuItem, Popover } from 'material-ui';
import { ActionInfo, ContentForward } from 'material-ui/svg-icons';
import * as React from 'react';
import { connect } from 'react-redux';

import { Permissions } from 'shared/models/permission';
import { hasRole, UserData } from 'shared/models/user';

import { EmailAddress } from 'shared/types/email-compose';
import {
    forwardFlaggedEmail,
    getFlaggedEmails,
    requestCommunicationMatch,
    updateCommunicationMatchData
} from '../actions';
import { clientCommsEmailAddress } from '../common/email/emails';
import { JobTitle } from '../containers/job-title';
import { PersonName } from '../containers/person-name';
import { Spinner } from '../core-ui/spinner';
import { replyToEmail } from '../email-compose/actions';
import { ComposeEmailWindowData } from '../email-compose/types';
import { Communication, FlaggedEmails as FlaggedEmailsType, RequestErrors, State } from '../state';
import { Communication as CommunicationComponent } from './communication';
import { CommunicationReplyButton } from './communication-reply-button';

interface OwnProps {
    selected: string;
    activeUsers: UserData[];
}

interface ConnectedProps {
    emailContentBlacklistedDomains: string;
    flaggedEmails: OrderedMap<string, FlaggedEmailsType>;
    pendingRequests: Map<string, RequestErrors>;
    sessionUser: UserData;
    userPermissions: Permissions;
}

interface ConnectedDispatch {
    getFlaggedEmails: (account: string, offset: number, limit: number) => void;
    updateCommunicationMatchData: (
        communication: Communication,
        data: {
            ignored?: boolean;
            personIds?: string[];
            jobIds?: string[];
            error?: boolean;
            manualOverridePersonIds?: boolean;
            manualOverrideJobIds?: boolean;
            bug?: boolean;
        }
    ) => void;
    requestCommunicationMatch: (communication: Communication) => void;
    replyToEmail: (
        accountOptions: EmailAddress[],
        emailContentBlacklistedDomains: string,
        message: Communication,
        replyAll: boolean,
        archiveOnReply?: boolean,
        composeWindowData?: Partial<ComposeEmailWindowData>
    ) => void;
    forwardFlaggedEmail: (account: string, messageId: string, email: string) => void;
}

type FlaggedEmailsProps = OwnProps & ConnectedDispatch & ConnectedProps;

interface FlaggedEmailsState {
    currentPage: number;
    moreInfoOpen: string;
    moreInfoRef: React.ReactInstance;

    forwardSelectorOpen: string;
    forwardRef: React.ReactInstance;
}

const numThreadsPerPage = 20;

class FlaggedEmailsComponent extends React.Component<FlaggedEmailsProps, FlaggedEmailsState> {
    constructor(props: FlaggedEmailsProps) {
        super(props);
        this.state = {
            currentPage: 0,
            forwardRef: null,
            forwardSelectorOpen: null,
            moreInfoOpen: null,
            moreInfoRef: null
        };
    }

    componentDidMount() {
        this.props.getFlaggedEmails(this.props.selected, 0, numThreadsPerPage);
    }

    componentDidUpdate(prevProps: FlaggedEmailsProps) {
        if (prevProps.selected !== this.props.selected) {
            this.setState({ currentPage: 0 });
            this.props.getFlaggedEmails(this.props.selected, 0, numThreadsPerPage);
        }
    }

    handlePageChange = (_1: any, newPage: number) => {
        this.setState({ currentPage: newPage - 1 });
        this.props.getFlaggedEmails(this.props.selected, numThreadsPerPage * (newPage - 1), numThreadsPerPage);
    };

    handleReplyButton = (data: Communication) => () => {
        const { personIds, jobIds, accountInfo, account } = data;

        this.props.replyToEmail(
            [{ address: account, name: accountInfo.name.full }],
            this.props.emailContentBlacklistedDomains,
            data,
            true,
            true,
            {
                isClientComm: data.isClientComm,
                jobId: jobIds && jobIds.length === 1 ? jobIds[0] : null,
                personId: personIds && personIds.length === 1 ? personIds[0] : null
            }
        );
    };

    setMoreInfoRef = (anchor: any) => {
        this.setState({ moreInfoRef: anchor });
    };

    handleOpenMoreInfo = (data: Communication) => (event: React.SyntheticEvent<{}>) => {
        const moreInfoOpen = `${data.account}-${data.messageId}`;
        this.setState({ moreInfoOpen, moreInfoRef: event.target as React.ReactInstance });
    };

    handleCloseMoreInfo = () => {
        this.setState({ moreInfoOpen: null, moreInfoRef: null });
    };

    handleCloseForwardSelector = () => {
        this.setState({ forwardSelectorOpen: null, forwardRef: null });
    };

    handleForwardButton = (data: Communication) => (event: React.SyntheticEvent<{}>) => {
        const forwardSelectorOpen = `${data.account}-${data.messageId}`;
        this.setState({ forwardSelectorOpen, forwardRef: event.target as React.ReactInstance });
    };

    handleForwardToSelect = (data: Communication, email: string) => () => {
        this.props.forwardFlaggedEmail(data.account, data.messageId, email);
        this.handleCloseForwardSelector();
    };

    renderCommunicationComponent = (data: Communication) => {
        const hasError = data.matchData && data.matchData.error;

        const { threadId, personIds, jobIds, matchData, messageId } = data;
        const personIdsWithLinks =
            personIds && personIds.length > 0
                ? personIds.map((id) => (
                      <div key={id}>
                          <a target="_blank" href={`/person/${id}`}>
                              <PersonName id={id} />
                          </a>
                      </div>
                  ))
                : null;
        const jobIdsWithLinks =
            jobIds && jobIds.length > 0
                ? jobIds.map((id) => (
                      <a key={id} target="_blank" href={`/job/${id}/board`}>
                          <JobTitle id={id} />
                      </a>
                  ))
                : null;
        const matchReasons = [];
        if (matchData.multiMatch) {
            matchReasons.push(...matchData.multiMatch.map((r) => <div key={r}>{r}</div>));
        } else if (matchData.matchReason) {
            matchReasons.push(<div key={matchData.matchReason}>{matchData.matchReason}</div>);
        }
        if (matchData.manualOverrideJobIds) {
            matchReasons.push(<div key="manualOverrideJobIds">manualOverrideJobIds</div>);
        }
        if (matchData.manualOverridePersonIds) {
            matchReasons.push(<div key="manualOverridePersonIds">manualOverridePersonIds</div>);
        }

        const moreInfo = (
            <div className="flagged-emails-more-info">
                <div className="flagged-emails-more-info-title">Persons</div>
                <div className="flagged-emails-more-info-list">{personIdsWithLinks}</div>
                <div className="flagged-emails-more-info-title">Jobs</div>
                <div className="flagged-emails-more-info-list">{jobIdsWithLinks}</div>
                <div className="flagged-emails-more-info-title">Match Reasons</div>
                <div className="flagged-emails-more-info-list">{matchReasons}</div>
                <div className="flagged-emails-more-info-title">Debug Info</div>
                <div className="flagged-emails-more-info-list">
                    <div>ThreadId: {threadId}</div>
                    <div>MessageId: {messageId}</div>
                </div>
            </div>
        );

        const moreInfoIcon = (
            <div key="more-info" onClick={this.handleOpenMoreInfo(data)}>
                <IconButton className="email-action-icon email-action more-info">
                    <ActionInfo />
                </IconButton>
                <Popover
                    open={this.state.moreInfoOpen === `${data.account}-${data.messageId}`}
                    onRequestClose={this.handleCloseMoreInfo}
                    anchorEl={this.state.moreInfoRef}
                    anchorOrigin={{ vertical: 'center', horizontal: 'middle' }}
                    targetOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                    {moreInfo}
                </Popover>
            </div>
        );

        const replyButton = (
            <CommunicationReplyButton key="reply-action" communication={data} onReply={this.handleReplyButton(data)} />
        );

        const spinnerSize = 20;
        const spinnerThickness = 2;
        const forwardToOpts = [
            <MenuItem
                key="clientcomms"
                value={null}
                onClick={this.handleForwardToSelect(data, clientCommsEmailAddress.address)}
            >
                {clientCommsEmailAddress.address}
            </MenuItem>
        ].concat(
            this.props.activeUsers
                .sort((u1, u2) => u1.name.full.localeCompare(u2.name.full))
                .map((u) => (
                    <MenuItem key={u.id} value={u.id} onClick={this.handleForwardToSelect(data, u.email)}>
                        {u.name.full}
                    </MenuItem>
                ))
        );
        const maxHeight = 400;
        const forwardButton = !hasRole(
            this.props.userPermissions,
            'email_match_setter'
        ) ? null : this.props.pendingRequests.has(`forward-flagged-email-${data.account}-${data.messageId}`) ? (
            <CircularProgress
                style={{ marginRight: 18 }}
                size={spinnerSize}
                thickness={spinnerThickness}
                key="forward"
            />
        ) : (
            <div className="email-action-icon email-action" key="forward">
                <ContentForward color="black" onClick={this.handleForwardButton(data)} />
                <Popover
                    open={this.state.forwardSelectorOpen === `${data.account}-${data.messageId}`}
                    onRequestClose={this.handleCloseForwardSelector}
                    anchorEl={this.state.forwardRef}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    targetOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                    <Menu maxHeight={maxHeight} desktop={true}>
                        {forwardToOpts}
                    </Menu>
                </Popover>
            </div>
        );

        const actions = [replyButton, forwardButton, moreInfoIcon];

        return (
            <div
                key={`${data.account}_${data.messageId}`}
                className={`flagged-emails-email ${hasError ? 'error' : ''}`}
            >
                <CommunicationComponent
                    personId={null}
                    data={data}
                    actions={actions}
                    initiallyExpanded={false}
                    emailContentBlacklistedDomains={this.props.emailContentBlacklistedDomains}
                />
            </div>
        );
    };

    render() {
        const { flaggedEmails, selected: account } = this.props;
        let content: JSX.Element;
        if (!flaggedEmails.get(account)) {
            content = <Spinner />;
        } else {
            const { threads, total } = this.props.flaggedEmails.get(this.props.selected);
            const groups = threads
                .valueSeq()
                .toArray()
                .map((thread) => {
                    if (thread.length === 0) return null;
                    const comms = thread.map(this.renderCommunicationComponent);
                    return (
                        <div className="emails-group" key={thread[0].threadId}>
                            {comms}
                        </div>
                    );
                });

            const pageCount = Math.ceil(total / numThreadsPerPage);
            const pagination =
                pageCount > 1 ? (
                    <div className="react-paginate">
                        <Pagination
                            count={pageCount}
                            page={this.state.currentPage + 1}
                            onChange={this.handlePageChange}
                            className="pagination"
                        />
                    </div>
                ) : null;

            content =
                total === 0 ? (
                    <div className="flagged-no-emails">No emails</div>
                ) : (
                    <div className="flagged-emails-list-container">
                        <div className="flagged-emails-list">{groups}</div>
                        {pagination}
                    </div>
                );
        }
        return (
            <div id="content" className="flex-fill">
                {content}
            </div>
        );
    }
}

const mapStateToProps = (state: State) => ({
    emailContentBlacklistedDomains: state.appConstants.constants.emailContentBlacklistedDomains,
    flaggedEmails: state.flaggedEmails,
    pendingRequests: state.pendingRequests,
    sessionUser: state.session.user,
    userPermissions: state.session.userPermissions
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    forwardFlaggedEmail,
    getFlaggedEmails,
    replyToEmail,
    requestCommunicationMatch,
    updateCommunicationMatchData
};

export const FlaggedEmails = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(FlaggedEmailsComponent);
