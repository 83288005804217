import { gql } from '@apollo/client';

export interface CrunchbaseCompany {
    uuid: string;
    name: string;
    linkedinUrl?: string;
    cbUrl?: string;
    description?: string;
    logoUrl?: string;
    funding?: number;
    employees?: string;
    latestRound?: {
        investMentType: string;
    };
}

export interface CrunchbaseCompanyFilterQueryParams {
    employee_count?: { _in: string[] };
    total_funding_usd?: { _lte?: number; _gte?: number };
    founded_on?: { _lte?: string; _gte?: string };
    latest_funding_round?: { investment_type?: { _in: string[] }; funding_date?: { _lte?: string; _gte?: string } };
    categories?: { category: { _in: string[] } };
    category_groups?: { category_group: { _in: string[] } };
    status?: { _in: string[] };
    country_code?: { _in: string[] };
    region?: { _in: string[] };
    city?: { _in: string[] };
    funding_rounds?: { investment: { investor_uuid: { _in: string[] } } };
}

export interface CBIndustryGroups {
    group: string;
}

export interface CBIndustries {
    name: string;
}

export interface CBFundingRounds {
    round: string;
}

export interface CBInvestor {
    name: string;
    uuid: string;
    investment_count: number;
}

export interface CBCompanyStatuses {
    status: string;
}

export interface CBCountries {
    countryCode: string;
}

export interface CBRegions {
    region: string;
}

export interface CBCities {
    city: string;
}

export interface CrunchbaseOrganization {
    uuid: string;
    shortDescription: string;
    numEmployees: string;
    totalFundingUSD: number;
    lastSeries: {
        name: string;
    };
    crunchbaseLink: string;
    investors: Array<{
        name: string;
        crunchbaseLink: string;
    }>;
    ipo: {
        publicOn: string;
        stockSymbol: string;
        stockExchangeSymbol: string;
    };
    foundingDate: string;
    city: string;
    region: string;
    countryCode: string;
    linkedinRecruiterUrls: Array<{
        url: string;
    }>;
}

export const CRUNCHBASE_COMPANIES = gql`
    query CrunchbaseCompanies($where: crunchbase_organizations_bool_exp!, $limit: Int!, $offset: Int!) {
        companies: crunchbase_organizations(where: $where, limit: $limit, offset: $offset, order_by: { name: asc }) {
            uuid
            name
            linkedinUrl: linkedin_url
            cbUrl: cb_url
            logoUrl: logo_url
            description: short_description
            funding: total_funding_usd
            employees: employee_count
            latestRound: latest_funding_round {
                investMentType: investment_type
            }
        }
    }
`;

export const CRUNCHBASE_COMPANIES_COUNT = gql`
    query CrunchbaseCompaniesCount($where: crunchbase_organizations_bool_exp!) {
        companiesCount: crunchbase_organizations_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;

export const CRUNCHBASE_INDUSTRY_GROUPS = gql`
    query CrunchbaseIndustryGroups {
        industryGroups: crunchbase_category_groups_list(order_by: { value: asc }) {
            group: value
        }
    }
`;

export const CRUNCHBASE_INDUSTRIES = gql`
    query CrunchbaseIndustries {
        industries: crunchbase_category_list(order_by: { value: asc }) {
            name: value
        }
    }
`;

export const CRUNCHBASE_FUNDING_ROUNDS = gql`
    query CrunchbaseFundingRounds {
        rounds: crunchbase_investment_rounds(order_by: { investment_type: asc }) {
            round: investment_type
        }
    }
`;

export const CRUNCHBASE_INVESTORS = gql`
    query CrunchbaseInvestors($prefix: String!, $limit: Int!, $uuids: [String!]!) {
        investors: crunchbase_investors(
            where: { _or: [{ lowercase_name: { name: { _like: $prefix } } }, { uuid: { _in: $uuids } }] }
            order_by: [{ investment_count: desc_nulls_last }]
            limit: $limit
        ) {
            name
            uuid
            investment_count
        }
    }
`;

export const CRUNCHBASE_COMPANY_STATUSES = gql`
    query CrunchbaseCompanyStatuses {
        company: crunchbase_organizations(distinct_on: status, order_by: { status: asc }) {
            status
        }
    }
`;

export const CRUNCHBASE_COUNTRIES = gql`
    query CrunchbaseCountries {
        country: crunchbase_organizations(distinct_on: country_code, order_by: { country_code: asc }) {
            countryCode: country_code
        }
    }
`;

export const CRUNCHBASE_REGIONS = gql`
    query CrunchbaseRegions($where: crunchbase_organizations_bool_exp!) {
        regions: crunchbase_organizations(where: $where, distinct_on: [country_code, region]) {
            region
        }
    }
`;

export const CRUNCHBASE_CITIES = gql`
    query CrunchbaseCities($where: crunchbase_city_org_counts_bool_exp!) {
        cities: crunchbase_city_org_counts(where: $where, order_by: { orgs_count: desc }) {
            city
        }
    }
`;

export const CRUNCHBASE_ORGANIZATIONS = gql`
    query CBOrgsQuery($linkedinUrls: [String!]!) {
        organizations: crunchbase_organizations(
            where: { linkedinRecruiterUrls: { linkedin_recruiter_url: { _in: $linkedinUrls } } }
        ) {
            uuid
            shortDescription: short_description
            numEmployees: employee_count
            totalFundingUSD: total_funding_usd
            lastSeries: latest_funding_round {
                name: investment_type
            }
            crunchbaseLink: cb_url
            investors(order_by: { investmentsCount: desc_nulls_last }, limit: 5) {
                name: investorName
                crunchbaseLink
            }
            ipo {
                publicOn: went_public_on
                stockSymbol: stock_symbol
                stockExchangeSymbol: stock_exchange_symbol
            }
            foundingDate: founded_on
            city
            region
            country_code
            linkedinRecruiterUrls {
                url: linkedin_recruiter_url
            }
        }
    }
`;
