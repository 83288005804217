import { produce } from 'immer';

import {
    Action,
    ExtensionContainerElementIdChange,
    ExtensionHtmlContentUpdate,
    ExtensionHtmlPendingLoadCheck,
    ExtensionOutOfNetwork,
    ExtensionPageUrlChange,
    ExtensionProfileParsed,
    ExtensionProfileParsingStatusChange,
    ExtensionRawJsonUpdate,
    ExtensionReceiveJobs,
    ExtensionScrollToPendingTags,
    ExtensionUpdateSelectedJob,
    ReceiveCandidateAddedToJob
} from '../actions';
import { getLocalStorageKey, selectedJobIdKey } from '../common/local-storage';
import { ExtensionState } from '../state';

const initialState: ExtensionState = {
    autoExpandingPendingSections: false,
    autoScrollToPendingHtmlTags: false,
    blacklistCheck: null,
    candidates: [],
    containerElementId: null,
    firstLoadTime: Date.now(),
    htmlPendingLoadTagsCount: null,
    htmlProfile: null,
    inmailSends: [],
    jobs: null,
    jsonProfile: null,
    latestOutreach: null,
    nextSendTime: null,
    outOfNetwork: false,
    parsingError: null,
    parsingProfile: null,
    person: null,
    profile: null,
    profileViews: null,
    searchResults: [],
    selectedJobId: getLocalStorageKey(selectedJobIdKey, null),
    tags: [],
    url: null
};

export function extension(state = initialState, action: Action): ExtensionState {
    return produce(state, (draft) => {
        switch (action.type) {
            case ExtensionOutOfNetwork:
                draft.outOfNetwork = true;
                break;
            case ExtensionRawJsonUpdate:
                draft.jsonProfile = action.payload.jsonProfile;
                draft.url = action.payload.url;
                break;
            case ExtensionUpdateSelectedJob:
                draft.selectedJobId = action.payload.jobId;
                break;
            case ExtensionHtmlPendingLoadCheck:
                draft.url = action.payload.url;
                break;
            case ExtensionProfileParsingStatusChange:
                draft.parsingProfile = action.payload.parsingProfile;
                break;
            case ExtensionProfileParsed:
                if (action.payload.extension && !action.payload.extension.parsingError) {
                    Object.assign(draft, action.payload.extension);
                } else {
                    draft.parsingError = action.payload?.extension?.parsingError ?? 'Profile parsing error';
                }
                break;
            case ReceiveCandidateAddedToJob:
                draft.blacklistCheck = produce(draft.blacklistCheck, (b) => {
                    b.alreadyInJob = true;
                });
                draft.candidates = draft.candidates
                    .filter((c) => c.jobId !== action.payload.candidate.jobId)
                    .concat([action.payload.candidate]);
                if (action.payload.inmailSends) {
                    draft.inmailSends = action.payload.inmailSends;
                }
                break;
            case ExtensionHtmlContentUpdate:
                draft.htmlPendingLoadTagsCount = action.payload.pendingCount;
                draft.htmlProfile = action.payload.html;
                break;
            case ExtensionPageUrlChange:
                Object.assign(draft, initialState, {
                    firstLoadTime: Date.now(),
                    jobs: draft.jobs,
                    selectedJobId: draft.selectedJobId
                });
                break;
            case ExtensionContainerElementIdChange:
                draft.containerElementId = action.payload.containerElementId;
                break;
            case ExtensionScrollToPendingTags:
                draft.autoScrollToPendingHtmlTags = action.payload.useAutoScroll;
                draft.autoExpandingPendingSections = action.payload.autoExpand;
                break;
            case ExtensionReceiveJobs:
                draft.jobs = action.payload.jobs;
                break;
            default:
                break;
        }
    });
}
