import { RaisedButton } from 'material-ui';
import * as React from 'react';

import { NoteView } from 'shared/models/note';

import { requestSubmissionNoteStatusUpdate } from '../api';

interface SubmissionNoteStatusActionsProps {
    note: NoteView;
}

export const SubmissionNoteStatusActions: React.FC<SubmissionNoteStatusActionsProps> = (props) => {
    const { note } = props;
    const [updateRequested, setUpdateRequested] = React.useState(false);

    const handleClick = () => {
        setUpdateRequested(true);
        requestSubmissionNoteStatusUpdate(note).then((value) => {
            if (!value.success) {
                setUpdateRequested(false);
            }
        });
    };

    const label = updateRequested ? 'Sent' : 'Request Status Update';
    return (
        <RaisedButton
            className="action-button"
            primary={true}
            label={label}
            onClick={handleClick}
            disabled={updateRequested}
        />
    );
};
