import { css } from '@emotion/core';
import * as React from 'react';

import { timeMonthDate } from '../common/timestamp';
import { JobTitle } from '../containers/job-title';
import { NoteDataWithUser } from '../graphql/queries/person';
import { Avatar } from '../sfc/avatar';

const styles = css`
    .toggles {
        display: flex;
        justify-content: flex-end;
        margin: 15px;

        .toggle-title {
            text-transform: uppercase;
            font-size: 12px;
            margin-right: 10px;
            padding-right: 10px;
            border-right: thin solid #e0e0e0;
            opacity: 0.7;
            cursor: pointer;

            &:last-child {
                border-right: none;
                margin-right: 0;
                padding-right: 0;
            }

            &.active {
                font-weight: 600;
                opacity: 1;
            }
        }
    }

    .note {
        padding: 10px 24px 0;
        border: none;

        .note-body {
            border-bottom: thin solid #e0e0e0;
            margin-top: 15px;
            padding-bottom: 20px;
        }

        &:last-child {
            .note-body {
                border-bottom: none;
                margin-bottom: 0;
            }
        }
    }
`;

export const Notes: React.FC<{ notes: NoteDataWithUser[]; jobId: string }> = ({ notes, jobId }) => {
    const [jobNotesOnly, setJobNotesOnly] = React.useState(false);

    const handleToggleJobNotesOnly = () => setJobNotesOnly(!jobNotesOnly);

    const noteElements = notes
        .filter((note) => !jobNotesOnly || !note.context.jobId || note.context.jobId === jobId)
        .map((note) => {
            const body =
                note.format === 'html' ? (
                    <div dangerouslySetInnerHTML={{ __html: note.content }} />
                ) : (
                    <div className="note-plaintext">{note.content}</div>
                );
            const job = note.context.jobId ? (
                <div className="small2 secondary">
                    <JobTitle id={note.context.jobId} />
                </div>
            ) : null;
            return (
                <div key={note.id} className="note">
                    <div className="note-header-bar">
                        <div className="note-header flex-columns-container">
                            <Avatar entity={note.user} />
                            <div className="note-header-text flex-rows-container">
                                <div>
                                    <span className="text600 title">{note.user.name.full}</span>
                                    <span className="secondary small2">{timeMonthDate(note.createdAt)}</span>
                                </div>
                                {job}
                            </div>
                        </div>
                    </div>
                    <div className="note-body">{body}</div>
                </div>
            );
        });

    return (
        <div css={styles}>
            <div className="toggles">
                <div className={`${jobNotesOnly ? '' : 'active'} toggle-title`} onClick={handleToggleJobNotesOnly}>
                    All Notes
                </div>
                <div className={`${jobNotesOnly ? 'active' : ''} toggle-title`} onClick={handleToggleJobNotesOnly}>
                    Job Notes
                </div>
            </div>
            {noteElements}
        </div>
    );
};
