import { CircularProgress } from 'material-ui';
import * as React from 'react';

const spinnerStyle = {
    alignItems: 'center' as any,
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: 'center' as any,
    minHeight: '400px'
};

const Spinner: React.SFC<{ size?: number }> = ({ size }) => (
    <div style={spinnerStyle}>
        <CircularProgress size={size} />
    </div>
);

Spinner.defaultProps = {
    size: 30
};

export { Spinner };
