import { List } from '@material-ui/core';
import React from 'react';

import { jobStatusMentions } from 'shared/models/user-mention';

import { useNotificationsData } from '../../hooks/use-notifications-data';
import { HomePagePanel } from './home-page-panel';
import { Mention } from './mention';

const Content: React.FC<{
    rows: JSX.Element[];
}> = ({ rows }) => <List disablePadding={true}>{rows}</List>;

export const JobStatusUpdates: React.FC = () => {
    const { mentions } = useNotificationsData();

    const jobUpdates = mentions.filter((m) => jobStatusMentions.includes(m.kind));

    return (
        <HomePagePanel
            RowComponent={Mention}
            ContentComponent={Content}
            records={jobUpdates}
            rowProps={{ view: 'received' }}
            title="Job Status Updates"
            containerClass="list"
            collapseKeyId="job-status-updates"
            showIfEmpty={false}
        />
    );
};
