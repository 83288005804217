import { css } from '@emotion/core';
import { LinearProgress, List, ListItem, ListItemText } from '@material-ui/core';
import { orderBy } from 'lodash';
import React from 'react';

import { Invoice as InvoiceData } from '../graphql/queries/billing';
import { Invoice } from './invoice';

const styles = css`
    .MuiListItem-container {
        .show-on-hover {
            opacity: 0;
            transition: opacity 0.2s;
        }

        &:hover {
            .show-on-hover {
                opacity: 1;
            }
        }
    }
`;

export const InvoicesList: React.FC<{ invoices: InvoiceData[] }> = ({ invoices }) => {
    const invoicesList = orderBy(
        invoices?.filter((invoice) => {
            const paidAmount = invoice.payments.reduce((acc, payment) => acc + payment.amount, 0);
            return !(invoice.archived && paidAmount === 0);
        }),
        [
            (invoice) => (invoice.lineItems.find((lineItem) => !lineItem.fee) ? 1 : 0),
            (invoice) => (invoice.status === 'PAID_IN_FULL' ? 0 : 1),
            (invoice) => invoice.invoiceDate
        ],
        ['desc', 'desc', 'desc']
    ).map((invoice) => <Invoice key={invoice.id} invoice={invoice} />);

    const list = !invoices ? (
        <LinearProgress variant="indeterminate" />
    ) : invoicesList.length === 0 ? (
        <ListItem>
            <ListItemText primary="No Invoices" secondary="No invoices found" />
        </ListItem>
    ) : (
        invoicesList
    );

    return (
        <List css={styles} className="client-invoices-list">
            {list}
        </List>
    );
};
