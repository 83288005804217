import { Map } from 'immutable';

import { Action, ReceiveJobsList, ReceiveUsersList, RequestJobsList, RequestUsersList } from '../actions';
import { ListState } from '../state';

const initialState: Map<string, ListState> = Map();

export function listsState(state = initialState, action: Action) {
    switch (action.type) {
        case RequestJobsList:
            return state.set('jobs', 'initializing');
        case ReceiveJobsList:
            return state.set('jobs', 'initialized');
        case RequestUsersList:
            return state.set('users', 'initializing');
        case ReceiveUsersList:
            return state.set('users', 'initialized');
        default:
            return state;
    }
}
