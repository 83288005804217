import { css } from '@emotion/core';
import { InputBase, Theme, useTheme } from '@material-ui/core';
import { Clear, Search } from '@material-ui/icons';
import * as React from 'react';

// tslint:disable:no-magic-numbers
const styles = (theme: Theme) => css`
    background: white;
    position: relative;
    margin-left: 0;
    display: flex;
    align-items: center;

    .search-icon {
        padding: ${theme.spacing(0, 2)};
        height: 100%;
        position: absolute;
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .clear-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fafafa;
        padding: 5px;
        border-radius: 50%;
        cursor: pointer;
        opacity: 0;

        .MuiSvgIcon-root {
            font-size: 15px;
        }

        &.visible {
            opacity: 1;
        }
    }

    .MuiInputBase-input {
        padding: 5px;
        padding-left: 40px;
        transition: ${theme.transitions.create('width')};
        width: 12ch;
        &:focus {
            width: 20ch;
        }
    }

    &.outlined {
        border: thin solid rgba(0, 0, 0, 0.23);
        border-radius: ${theme.shape.borderRadius}px;
        background: white;

        &:hover,
        &:focus-within {
            border-color: #263238;
        }

        .search-icon {
            padding-left: ${theme.spacing(1.6)}px;
        }

        .MuiInputBase-input {
            padding: 5px;
            padding-left: 40px;
        }

        .clear-icon {
            margin: ${theme.spacing(0, 1)};
        }
    }
`;
// tslint:enable

export const SearchTextField: React.FC<{
    value: string;
    onValueChange: (newValue: string) => void;
    onEnter?: () => void;
    variant?: 'outlined' | 'default';
}> = ({ value, onValueChange, onEnter, variant }) => {
    const theme = useTheme();

    const handleValueChange = (event: { target: { value: string } }) => {
        onValueChange(event.target.value);
    };

    const handleKeyPress = (e: React.KeyboardEvent) => {
        if (onEnter && e.key === 'Enter') {
            onEnter();
        }
    };

    const handleClear = () => {
        onValueChange('');
    };

    const clearIcon = (
        <div className={`clear-icon ${value.length > 0 ? 'visible' : ''}`} onClick={handleClear}>
            <Clear />
        </div>
    );

    return (
        <div className={`search ${variant}`} css={styles(theme)}>
            <div className="search-icon">
                <Search />
            </div>
            <InputBase placeholder="Search…" value={value} onChange={handleValueChange} onKeyPress={handleKeyPress} />
            {clearIcon}
        </div>
    );
};
