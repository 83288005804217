import React from 'react';
import { SliderForm } from './slider-form';

import { EMPLOYEE_SIZE_THRESHOLDS, employeeRangeModifier, MAX_EMPLOYEES } from 'shared/common/crunchbase-filters';

const employeeValueToLabel = (value: number): string => {
    if (value === 0) {
        return '1';
    } else if (value === MAX_EMPLOYEES) {
        return '10,000+';
    } else {
        return value.toLocaleString();
    }
};

export const EmployeesSizeForm: React.FC<{
    readonly: boolean;
    filters: [number, number];
    onChange: (val: [number, number]) => void;
}> = ({ readonly, filters, onChange }) => {
    return (
        <SliderForm
            readonly={readonly}
            endpoints={EMPLOYEE_SIZE_THRESHOLDS}
            fieldName="Number of Employees"
            value={filters}
            onChange={onChange}
            getLabel={employeeValueToLabel}
            modifyRange={employeeRangeModifier}
        />
    );
};
