import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import React from 'react';

import { SearchStatus } from '../state';
import { useSearch } from './use-search';
import { allSections, SearchConfigSection, useSearchSections } from './use-search-sections';

export const SearchSectionsSelectMenu: React.FC = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { sectionTitle, enabledSections, isSectionEmpty, removeSection, addSection } = useSearchSections();
    const { data } = useSearch();

    if (data.status && data?.status !== SearchStatus.Initial) return null;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleToggle = (section: SearchConfigSection) => () => {
        const selected = enabledSections.includes(section) || !isSectionEmpty(section);
        if (!selected) {
            addSection(section);
        } else {
            removeSection(section);
        }
        setAnchorEl(null);
    };

    const menuItems = allSections.map((section) => {
        const selected = enabledSections.includes(section) || !isSectionEmpty(section);
        return (
            <MenuItem key={section} selected={selected} onClick={handleToggle(section)}>
                {sectionTitle(section)}
            </MenuItem>
        );
    });

    return (
        <div>
            <IconButton onClick={handleClick} size="small">
                <MenuIcon fontSize="small" />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
                {menuItems}
            </Menu>
        </div>
    );
};
