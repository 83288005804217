import { css } from '@emotion/core';
import { FormControlLabel, Switch } from '@material-ui/core';
import React from 'react';

const toggleStyles = css`
    color: white;
    margin-right: 10px;

    .MuiSwitch-track {
        background-color: #989898;
        opacity: 1;
    }

    .MuiSwitch-thumb {
        background-color: #c5c5c5;
    }

    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
        background-color: #bdbdbd;
        opacity: 1;
    }

    .Mui-checked .MuiSwitch-thumb {
        background-color: #f8f8f8;
    }
`;

export const PrimaryToggle: React.FC<{ checked: boolean; label: string; onChange: () => void }> = ({
    checked,
    label,
    onChange
}) => (
    <FormControlLabel
        css={toggleStyles}
        control={<Switch checked={checked} onChange={onChange} />}
        label={label}
        labelPlacement="start"
    />
);
