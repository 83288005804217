import { useLazyQuery } from '@apollo/client';
import _ from 'lodash';
import React from 'react';

import { CBInvestor, CRUNCHBASE_INVESTORS } from '../../graphql/queries/crunchbase';
import { MultiSelectForm } from './multi-select-form';

export const InvestorsForm: React.FC<{
    readonly: boolean;
    filters: Array<{ name: string; uuid: string }>;
    onChange: (val: Array<{ name: string; uuid: string }>) => void;
}> = ({ readonly, filters, onChange }) => {
    const limit = 1000;
    const [prefix, setPrefix] = React.useState<string>('');
    const [fetchInvestors] = useLazyQuery<
        { investors: CBInvestor[] },
        { prefix: string; limit: number; uuids: string[] }
    >(CRUNCHBASE_INVESTORS, {
        variables: { prefix: `${_.lowerCase(prefix).trim()}%`, limit, uuids: filters?.map((f) => f.uuid) ?? [] }
    });
    const [investorsData, setInvestorsData] = React.useState<CBInvestor[]>([]);

    const sortInvestors = (investors: CBInvestor[]) => _.orderBy(investors ?? [], ['name'], ['asc']);

    React.useEffect(() => {
        fetchInvestors({
            variables: { prefix: `${_.lowerCase(prefix).trim()}%`, limit, uuids: filters?.map((f) => f.uuid) ?? [] }
        }).then((result) => {
            const existingData = investorsData ?? [];
            const newData = sortInvestors(result.data?.investors ?? []);
            const updatedData = _.uniqBy([...existingData, ...newData], 'uuid');
            setInvestorsData(updatedData);
        });
    }, [prefix, filters]);

    const handleTextChange = (value: string) => {
        setPrefix(value);
    };

    const handleChange = (value: string[]) => {
        const updated = investorsData?.filter((r) => value?.includes(r.uuid)) ?? [];
        onChange(updated);
    };

    const options = investorsData?.map((r) => r.uuid) ?? [];

    const renderOption = (option: string) => investorsData?.find((r) => r.uuid === option)?.name ?? '';

    return (
        <MultiSelectForm
            readonly={readonly}
            filters={filters?.map((f) => f.uuid) ?? []}
            label="Investors"
            options={options}
            onChange={handleChange}
            renderOption={renderOption}
            onInputChange={handleTextChange}
        />
    );
};
