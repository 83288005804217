import { css } from '@emotion/core';
import { IconButton } from '@material-ui/core';
import { FileCopyOutlined } from '@material-ui/icons';
import React from 'react';

import { useModal } from './use-modal';
import { useSnackbar } from './use-snackbar';

const codeStyles = css`
    position: relative;

    .copy-button {
        position: absolute;
        right: 0;
        top: 0;
        margin: 0;
    }
`;

export function useCodeModal() {
    const { setAlert } = useModal();
    const { setSnackbar } = useSnackbar();

    const getCodeContent = (text: string) => {
        const handleCopy = () => {
            navigator.clipboard.writeText(text).catch();
            setSnackbar('Copied to clipboard');
        };

        return (
            <div css={codeStyles}>
                <div>
                    <pre>
                        <code>{text}</code>
                    </pre>
                </div>
                <div className="copy-button">
                    <IconButton onClick={handleCopy}>
                        <FileCopyOutlined />
                    </IconButton>
                </div>
            </div>
        );
    };

    const showCodeModal = (title: string, text: string) => {
        setAlert(title, getCodeContent(text));
    };

    return { showCodeModal };
}
