// cspell:disable
export const whitelistedWorkDomains = new Set<string>([
    'apple.com',
    'wework.com',
    'spacex.com',
    'lyft.com',
    'nvidia.com',
    'dropbox.com',
    'doordash.com',
    'reddit.com',
    'stripe.com',
    'ring.com',
    'linkedin.com',
    'sofi.com',
    'nutanix.com',
    'robinhood.com',
    'merck.com',
    'ge.com',
    'squareup.com',
    'ericsson.com',
    '23andme.com',
    'uber.com',
    'hubspot.com',
    'flatiron.com',
    'lenovo.com',
    'sonos.com',
    'novartis.com',
    'tesla.com',
    'postmates.com',
    'houzz.com',
    'ripple.com',
    'collectivehealth.com',
    'bugcrowd.com',
    'groupon.com',
    'atlassian.com',
    'gopro.com',
    'netflix.com',
    'skydio.com',
    'buzzfeed.com',
    'theinfatuation.com',
    'appannie.com',
    'glassdoor.com',
    'whoop.com',
    'helix.com',
    'airbnb.com',
    'symantec.com',
    'verizon.com',
    'xerox.com',
    'siemens.com',
    'grove.co',
    'opentext.com',
    'impossiblefoods.com',
    'redfin.com',
    'gm.com',
    'cloudflare.com',
    'splunk.com',
    'liveramp.com',
    'stitchfix.com',
    'snowflake.net',
    'coursera.org',
    'zscaler.com',
    'ujet.co',
    'coinbase.com',
    'yelp.com',
    'servicenow.com',
    'infor.com',
    'cloudera.com',
    'malwarebytes.com',
    'pinterest.com',
    'fireeye.com',
    'zendesk.com',
    'reflektive.com',
    'hackerone.com',
    'homeaway.com',
    'crunchbase.com',
    'genesys.com',
    'starskyrobotics.com',
    'macquarie.com',
    'datavisor.com',
    'trulia.com',
    'quizlet.com',
    'marketo.com',
    'ebay.com',
    'theathletic.com',
    'kixeye.com',
    'mulesoft.com',
    'jd.com',
    'equifax.com',
    'beyondmeat.com',
    'fundingcircle.com',
    'looker.com',
    'cognizant.com',
    'wish.com',
    'honeywell.com',
    'criteo.com',
    'lendingclub.com',
    'venmo.com',
    'workday.com',
    'triplebyte.com',
    'fitbit.com',
    'carbonblack.com',
    'flexport.com',
    'daimler.com',
    'vivino.com',
    'jwplayer.com',
    'amazon.com',
    'wealthfront.com',
    'zynga.com',
    'udacity.com',
    'blend.com',
    'appdynamics.com',
    'facebook.com',
    'pandora.com',
    'clarifai.com',
    'motorolasolutions.com',
    'bigcommerce.com',
    'baesystems.com',
    'doubledutch.me',
    'paloaltonetworks.com',
    'purestorage.com',
    'conductor.com',
    'intuit.com',
    'zylo.com',
    'iheartmedia.com',
    'trustpilot.com',
    'namely.com',
    'patreon.com',
    'modernatx.com',
    'smartthings.com',
    'instacart.com',
    'allscripts.com',
    'centurylink.com',
    'dynamicsignal.com',
    'cylance.com',
    'dialpad.com',
    'zenefits.com',
    'mixmax.com',
    'harrys.com',
    'gigya.com',
    'pantheon.io',
    'valeant.com',
    'equinix.com',
    'yext.com',
    'current.com',
    'mobileiron.com',
    'credit-suisse.com',
    'databricks.com',
    'snap.com',
    'twitch.tv',
    'appdirect.com',
    'chegg.com',
    'datos.io',
    'nokia.com',
    'juniper.net',
    'volvocars.com',
    'personalcapital.com',
    'turo.com',
    'walkme.com',
    'aurora.tech',
    'byton.com',
    'github.com',
    'citigroup.com',
    'zuora.com',
    'mapr.com',
    'affirm.com',
    'vonage.com',
    'tunein.com',
    'zumper.com',
    'att.com',
    'teamworks.com',
    'nuro.ai',
    'mixpanel.com',
    'tipalti.com',
    'sentinelone.com',
    'nextdoor.com',
    'figma.com',
    'everfi.com',
    'ringcentral.com',
    'newrelic.com',
    'thoughtspot.com',
    'coinsquare.io',
    'gonitro.com',
    'lendup.com',
    'genworth.com',
    'limebike.com',
    'appsflyer.com',
    'domo.com',
    'shipt.com',
    'marketingevolution.com',
    'qualcomm.com',
    'nerdwallet.com',
    'letote.com',
    'bluecore.com',
    'chacha.com',
    'egnyte.com',
    'honest.com',
    'lookout.com',
    'flipboard.com',
    'boeing.com',
    'sugarcrm.com',
    'scribd.com',
    'splice.com',
    'massdrop.com',
    'heartflow.com',
    'microsoft.com',
    'exabeam.com',
    'google.com',
    'menlosecurity.com',
    'walmart.com',
    'safeguard.com',
    'algolia.com',
    'zoosk.com',
    'brigade.com',
    'pagerduty.com',
    'nauto.com',
    'booker.com',
    'clarizen.com',
    'cumulusnetworks.com',
    'turn.com',
    'qubole.com',
    'uipath.com',
    'sunrun.com',
    'faradayfuture.com',
    'wipro.com',
    'intercom.com',
    'cornerstoneondemand.com',
    'ziprecruiter.com',
    '8x8.com',
    'metricstream.com',
    'bazaarvoice.com',
    'sensortower.com',
    'okta.com',
    'snaplogic.com',
    'huawei.com',
    'iterable.com',
    'vmware.com',
    'playosmo.com',
    'apigee.com',
    'pivotal.io',
    'viewray.com',
    'tradeshift.com',
    'talend.com',
    'roku.com',
    'amplitude.com',
    'weddingwire.com',
    'showpad.com',
    'procore.com',
    'glu.com',
    'quantcast.com',
    'ooyala.com',
    'siftscience.com',
    'riotgames.com',
    'minted.com',
    'periscopedata.com',
    'blueapron.com',
    'eligible.com',
    'altschool.com',
    'mainstreethub.com',
    'redislabs.com',
    'sprinklr.com',
    'descarteslabs.com',
    'spiceworks.com',
    'rigetti.com',
    'taulia.com',
    'matterport.com',
    'getbase.com',
    'dnanexus.com',
    'demandbase.com',
    'mapd.com',
    'deputy.com',
    'nbcuni.com',
    'homechef.com',
    'tango.me',
    'invitae.com',
    'raytheon.com',
    'celgene.com',
    'meraki.com',
    'khanacademy.org',
    'insidesales.com',
    'roofstock.com',
    'simility.com',
    'circleup.com',
    'classdojo.com',
    'birst.com',
    'castlighthealth.com',
    'kariusdx.com',
    'twistbioscience.com',
    'tevapharm.com',
    'nimblestorage.com',
    'expa.com',
    'finisar.com',
    'concur.com',
    'daqri.com',
    'layer.com',
    'bloomreach.com',
    'zimmer.com',
    'virtusa.com',
    'woebot.io',
    'getaround.com',
    'marqeta.com',
    'persado.com',
    'nytimes.com',
    'granular.ag',
    'marklogic.com',
    'rainforestqa.com',
    'osisoft.com',
    'shift.com',
    'chargepoint.com',
    'viasat.com',
    'parkwhiz.com',
    'healthtap.com',
    '10xgenomics.com',
    'hiltonworldwide.com',
    'calliduscloud.com',
    'newvoicemedia.com',
    'lennar.com',
    'path.com',
    'pocketgems.com',
    'rubrik.com',
    'tune.com',
    'firstdata.com',
    'rapid7.com',
    'fastly.com',
    'returnpath.com',
    'housecanary.com',
    'capriza.com',
    'lucidworks.com',
    'digitalocean.com',
    'carbon3d.com',
    'proteus.com',
    'calix.com',
    'logikcull.com',
    'outsystems.com',
    'checkr.com',
    'tintri.com',
    'mercari.com',
    'tanium.com',
    'rocketrip.com',
    'stem.com',
    'gilt.com',
    'nektar.com',
    'alation.com',
    'dominodatalab.com',
    'forusall.com',
    'chartboost.com',
    'quotient.com',
    'applovin.com',
    'juullabs.com',
    'insightly.com',
    'livingsocial.com',
    'simple.com',
    'theranos.com',
    'nginx.com',
    'exacttarget.com',
    'wrike.com',
    'betterup.co',
    'mz.com',
    'brightedge.com',
    'verifone.com',
    'tapad.com',
    'scoutrfp.com',
    'makethunder.com',
    'upstart.com',
    'appthority.com',
    'accela.com',
    'unikrn.com',
    'csdisco.com',
    'smule.com',
    'crewapp.com',
    'trinet.com',
    'cappasity.com',
    'nordstrom.com',
    'natera.com',
    'heapanalytics.com',
    'tubemogul.com',
    'kenshoo.com',
    'thomsonreuters.com',
    'mirantis.com',
    'farmersbusinessnetwork.com',
    'lever.co',
    'datera.io',
    'crowdrise.com',
    'five9.com',
    'junipersquare.com',
    'spin.pm',
    'shyp.com',
    'monetate.com',
    'drchrono.com',
    'takescoop.com',
    'twilio.com',
    'civilmaps.com',
    'eatclub.com',
    'globality.com',
    'audentestx.com',
    'nutonomy.com',
    'beomni.com',
    'clari.com',
    'yotpo.com',
    'liveperson.com',
    'ridezum.com',
    'doximity.com',
    'aerohive.com',
    'prezi.com',
    'enjoy.com',
    'tracxn.com',
    'bugsnag.com',
    'revelsystems.com',
    'soundhound.com',
    'mediamath.com',
    'flywire.com',
    'appzen.com',
    'thredup.com',
    'onemedical.com',
    'vicarious.com',
    'digitalgenius.com',
    'whitehatsec.com',
    'etagen.com',
    'bizzabo.com',
    'ey.com',
    'rubiconproject.com',
    'spotify.com',
    'viagogo.com',
    'skyroam.com',
    'calm.com',
    'guidebook.com',
    'streamsets.com',
    'quip.com',
    'lithium.com',
    'zymergen.com',
    'circleci.com',
    'ginkgobioworks.com',
    'ensilo.com',
    'freenome.com',
    'shoprunner.com',
    'couchbase.com',
    'quanergy.com',
    'everlane.com',
    'bill.com',
    'jet.com',
    'greenhouse.io',
    'signalsciences.com',
    'slack.com',
    'climate.com',
    'oportun.com',
    'cultureamp.com',
    'lightstep.com',
    'lifelock.com',
    'takecareof.com',
    'kyriba.com',
    'opengov.com',
    'scaleapi.com',
    'kabam.com',
    'bluejeans.com',
    'attentivemobile.com',
    'guardicore.com',
    'airtime.com',
    'gocardless.com',
    'coupa.com',
    'datorama.com',
    'micron.com',
    'lattice-engines.com',
    'jobcase.com',
    'hellosign.com',
    'forescout.com',
    'unilever.com',
    'homelight.com',
    'plaid.com',
    'nuna.com',
    'capsulecares.com',
    'dosh.cash',
    'luxoft.com',
    'gong.io',
    'stackrox.com',
    'level3.com',
    'qumulo.com',
    'vsco.co',
    'stackla.com',
    'on24.com',
    'nextvr.com',
    'eero.com',
    'modcloth.com',
    'druva.com',
    'workiva.com',
    'popsugar.com',
    'life360.com',
    'luminartech.com',
    'lattice.com',
    'insideview.com',
    'peartherapeutics.com',
    'yodlee.com',
    'panoramaed.com',
    'seal-software.com',
    'plex.com',
    'moogsoft.com',
    'joinroot.com',
    'barracuda.com',
    'clara.com',
    'visier.com',
    'mimecast.com',
    'stitchlabs.com',
    'gooddata.com',
    'brightbytes.net',
    'ultimatesoftware.com',
    'control4.com',
    'bloomenergy.com',
    'cardivamedical.com',
    'edcast.com',
    'captricity.com',
    'twitter.com',
    'broadcom.com',
    'meltwater.com',
    'vibes.com',
    'virtualinstruments.com',
    'buildingconnected.com',
    'comfyapp.com',
    'apptentive.com',
    'cloudability.com',
    'bmc.com',
    'edmodo.com',
    'hustle.com',
    'aurisrobotics.com',
    'baobabstudios.com',
    'medtronic.com',
    'recurly.com',
    'skipthedishes.com',
    'mcafee.com',
    'comparably.com',
    'gametime.co',
    'talkdesk.com',
    'strava.com',
    'marcusmillichap.com',
    'ipsen.com',
    'unitasglobal.com',
    'prnewswire.com',
    'usebutton.com',
    'bouncex.com',
    'noom.com',
    'anchorfree.com',
    'relativity.com',
    'ouster.io',
    'digitalreasoning.com',
    'yume.com',
    'ubisoft.com',
    'sonder.com',
    'zinc.it',
    'privy.com',
    'ginger.io',
    'unity3d.com',
    'jamcity.com',
    'spglobal.com',
    'baublebar.com',
    'ptcbio.com',
    'oneconcern.com',
    'simpplr.com',
    'heroku.com',
    'ibsplc.com',
    'cenveo.com',
    'mybrightwheel.com',
    'lob.com',
    'thanx.com',
    'financialforce.com',
    'a10networks.com',
    'achievers.com',
    'concordnow.com',
    'cedar.com',
    'adyen.com',
    'zonarsystems.com',
    'slicelife.com',
    'workato.com',
    'insikt.com',
    'modeanalytics.com',
    'eargo.com',
    'carecloud.com',
    'trueaccord.com',
    'everbridge.com',
    'businessinsider.com',
    'healthline.com',
    'sungard.com',
    'qg.com',
    'ezcater.com',
    'rallydev.com',
    'skytap.com',
    'smartrecruiters.com',
    'berkeleylights.com',
    'intacct.com',
    'getrileynow.com',
    'vetsfirstchoice.com',
    'wandisco.com',
    'jasper.com',
    'timeinc.com',
    'wagwalking.com',
    'quora.com',
    'blinkhealth.com',
    'neurala.com',
    'aeratechnology.com',
    'optimusride.com',
    'skillz.com',
    'snapdocs.com',
    'clarabridge.com',
    'intarcia.com',
    'rinse.com',
    'kaseya.com',
    'adstage.io',
    'clearslide.com',
    'dataxu.com',
    'moz.com',
    'enphaseenergy.com',
    'cdw.com',
    'trendkite.com',
    'curology.com',
    'trialpay.com',
    'branch.co',
    'jivesoftware.com',
    'marinsoftware.com',
    'naspers.com',
    'warbyparker.com',
    'capeanalytics.com',
    'aconex.com',
    'crowdstrike.com',
    'richrelevance.com',
    'nextcapital.com',
    'servicemax.com',
    'synthego.com',
    'bird.co',
    'appcelerator.com',
    'even.com',
    'sparkpost.com',
    'apixio.com',
    'ruckuswireless.com',
    'patientpop.com',
    'ariasystems.com',
    'imperva.com',
    'hyperscience.com',
    'nexthink.com',
    'kahuna.com',
    'silverspringnet.com',
    'varonis.com',
    'jobvite.com',
    'gettyimages.com',
    'fond.co',
    'amcsgroup.com',
    'hopskipdrive.com',
    'nexenta.com',
    'cloudpassage.com',
    'philo.com',
    'vodafone.com',
    'teachable.com',
    'adform.com',
    'media.mit.edu',
    'benchling.com',
    'enlightedinc.com',
    'beeswax.com',
    'opower.com',
    'leandatainc.com',
    'qtsdatacenters.com',
    'neatorobotics.com',
    'lumosity.com',
    'adobe.com',
    'artivest.co',
    'kraken.com',
    'incorta.com',
    'zenprospect.com',
    'maxwellhealth.com',
    'catchpoint.com',
    '3vr.com',
    'target.com',
    'talkiq.com',
    'geteverwise.com',
    'symphonycommerce.com',
    'vfc.com',
    'docusign.com',
    'quandl.com',
    'surveymonkey.com',
    'octanelending.com',
    'fractalanalytics.com',
    'avepoint.com',
    'medium.com',
    'predictivetechnologies.com',
    'ncr.com',
    'mckinsey.com',
    'express-scripts.com',
    'amicusrx.com',
    'lovepopcards.com',
    'constantcontact.com',
    'harbor.com',
    'adara.com',
    'arista.com',
    'xignite.com',
    'momentummachines.com',
    'yaypay.com',
    'insightsquared.com',
    'ch2m.com',
    'entefy.com',
    'palantir.com',
    'alan.eu',
    'nodesource.com',
    'jitterbit.com',
    'formation.ai',
    'corelogic.com',
    'sanofipasteur.com',
    'electricimp.com',
    'raise.me',
    'liveaction.com',
    'docsend.com',
    'skedulo.com',
    'aricent.com',
    'friendster.com',
    'flashpoint-intel.com',
    'imgur.com',
    'io.com',
    'dynavax.com',
    'momentfeed.com',
    'loopnet.com',
    'billtrust.com',
    'kiip.me',
    'thistle.co',
    'apptimize.com',
    'vitals.com',
    'gaikai.com',
    'controlup.com',
    'warriors.com',
    'statestitle.com',
    'bandwidth.com',
    'onekingslane.com',
    'frontapp.com',
    'eventbrite.com',
    'logdna.com',
    'boku.com',
    'twoporeguys.com',
    'axiomlaw.com',
    'zugata.com',
    'gartner.com',
    'regeneron.com',
    'guidespark.com',
    'pubmatic.com',
    'discordapp.com',
    'globant.com',
    'thinkbrg.com',
    'opendoor.com',
    'tripadvisor.com',
    'troops.ai',
    'archive.org',
    'cbs.com',
    'dealpath.com',
    'meettally.com',
    'box.com',
    't-mobile.com',
    'yubico.com',
    'zerocater.com',
    'wageworks.com',
    'cleardata.com',
    'giphy.com',
    'ignitionone.com',
    'workspan.com',
    'hintmd.com',
    'appian.com',
    'katerra.com',
    'leadgenius.com',
    'wonolo.com',
    'teamable.com',
    'renaissance.com',
    'joyent.com',
    'trilogyed.com',
    'neuropace.com',
    'quri.com',
    'tapclicks.com',
    'shoesofprey.com',
    'cigna.com',
    'contently.com',
    'bestbuy.com',
    'ahalogy.com',
    'sutterhealth.org',
    'nasa.gov',
    'wework.com',
    'growbots.com',
    'sangamo.com',
    'nike.com',
    'anaplan.com',
    'brightfunnel.com',
    'mubi.com',
    'scoot.co',
    'infosys.com',
    'paperlesspost.com',
    'pacificbiosciences.com',
    'userzoom.com',
    'sprucehealth.com',
    'limeade.com',
    'smashfly.com',
    'docker.com',
    'smartdrive.net',
    'classpass.com',
    'springcm.com',
    'eversightlabs.com',
    'califiafarms.com',
    'tuition.io',
    'qordoba.com',
    'kiva.org',
    'ceridian.com',
    'singulex.com',
    'nationbuilder.com',
    'placecast.net',
    'cask.co',
    'teamexos.com',
    'blackboard.com',
    'nevro.com',
    'good.co',
    'genomemedical.com',
    'saama.com',
    'veeva.com',
    'zipwhip.com',
    'trifacta.com',
    'msg.ai',
    'gobalto.com',
    'soasta.com',
    'demandware.com',
    'primuspower.com',
    'bluekai.com',
    'wildfireapp.com',
    'brighttalk.com',
    'bio-optronics.com',
    'reflexion.com',
    'amyris.com',
    'wonderschool.com',
    'creditkarma.com',
    'prodege.com',
    'codehs.com',
    'intouchhealth.com',
    'presencelearning.com',
    'firebase.com',
    'thenewbarn.com',
    'beam.dental',
    'tokbox.com',
    'kenandy.com',
    'sunpower.com',
    'manticoregames.com',
    'spigit.com',
    'incontextsolutions.com',
    'americanexpress.com',
    'blispay.com',
    'adchemy.com',
    'polyvore.com',
    'onscale.com',
    'ivy.com',
    'doc.ai',
    'falconstor.com',
    'art.com',
    'intuitymedical.com',
    'conduit.com',
    'voxy.com',
    'logichub.com',
    'velo3d.com',
    'mobify.com',
    'flywheel.com',
    'aetion.com',
    'cox.com',
    'npmjs.com',
    'segment.com',
    'nuodb.com',
    'invisage.com',
    'dstillery.com',
    'wowway.com',
    'textiq.com',
    'smartzip.com',
    'hbo.com',
    'oden.io',
    'serenaandlily.com',
    'mybasis.com',
    'redhat.com',
    'actiance.com',
    'allakos.com',
    'brocade.com',
    'betterplace.com',
    'everlaw.com',
    'paystand.com',
    'rentlytics.com',
    'indinero.com',
    'imvu.com',
    'arenasolutions.com',
    'squarefoot.com',
    'tableau.com',
    'apartmentlist.com',
    'stanza.co',
    'justanswer.com',
    'bluetalon.com',
    'satmetrix.com',
    'iconaircraft.com',
    'arcadiasolutions.com',
    'broadsoft.com',
    'ironplanet.com',
    'redawning.com',
    'simondata.com',
    'mightyhive.com',
    'augur.net',
    'streetlightdata.com',
    'bleacherreport.com',
    'wmeentertainment.com',
    'patternenergy.com',
    'pepsico.com',
    'fusionio.com',
    'personalis.com',
    'webgility.com',
    'jyve.com',
    'bp.com',
    'weka.io',
    'pfizer.com',
    'farmwise.io',
    'mobitv.com',
    'sap.com',
    'jumptap.com',
    'loopcommerce.com',
    'livenation.com',
    'stumbleupon.com',
    'quantum.com',
    'abbott.com',
    'guvera.com',
    'shutterstock.com',
    'certain.com',
    'apporbit.com',
    'saleshood.com',
    'energyrecovery.com',
    'measurabl.com',
    'textrecruit.com',
    'gyft.com',
    'kpmg.com',
    'arcot.com',
    'attunity.com',
    'alere.com',
    'outsetmedical.com',
    'tripit.com',
    'cytomx.com',
    'viacom.com',
    'savi.com',
    'greencharge.net',
    'brightsourceenergy.com',
    'bayer.com',
    'apttus.com',
    'palm.com',
    'trustlook.com',
    'contino.io',
    'mblox.com',
    'klout.com',
    'zaarly.com',
    'gigamon.com',
    'steelwedge.com',
    'plateiq.com',
    'badgeville.com',
    'genius.com',
    'tcd.ie',
    'networkedinsights.com',
    'mindtouch.com',
    'youtube.com',
    'risesmart.com',
    'evernote.com',
    'attensity.com',
    'alluxio.com',
    'businesswire.com',
    'aimmune.com',
    'karhoo.com',
    'hdsglobal.com',
    'predictix.com',
    'siriusxm.com',
    'hi5.com',
    'decarta.com',
    'twobitcircus.com',
    'adicetbio.com',
    'flite.com',
    'springbot.com',
    'xplusone.com',
    'lumapps.com',
    'activenetwork.com',
    'earthlink.net',
    'schwab.com',
    'gameclosure.com',
    'ngmbio.com',
    'ieee.org',
    'rms.com',
    'eaton.com',
    'highfive.com',
    'numerate.com',
    'mesosphere.com',
    '3m.com',
    'askattest.com',
    'uxpin.com',
    'clearwire.com',
    'cureatr.com',
    'demandforce.com',
    'ixiacom.com',
    'brightroll.com',
    'genband.com',
    'perficient.com',
    'freedomfinancialnetwork.com',
    'ironnetcyber.com',
    'plumlending.com',
    'biotimeinc.com',
    'pusher.com',
    'scientist.com',
    'common.net',
    'epam.com',
    'guidewire.com',
    'hautelook.com',
    'alterg.com',
    'twenty20.com'
]);

export const isWhitelistedWorkEmail = (email: string) => {
    return whitelistedWorkDomains.has(email.split('@')[1]);
};
