import { Map, OrderedMap } from 'immutable';
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';

import { createPersonJobLabel, fetchPersonDetails, toggleDrawer } from '../actions';
import { PersonJobLabel as PersonJobLabelComponent } from '../components/person-job-label';
import { Spinner } from '../core-ui/spinner';
import { Job, List, PersonDetails, RequestErrors, State } from '../state';

interface OwnProps {
    jobId: string;
    personId: string;
}

interface ConnectedProps {
    persons: List<PersonDetails>;
    pendingRequests: Map<string, RequestErrors>;
    jobs: OrderedMap<string, Job>;
    userId: string;
}

interface ConnectedDispatch {
    createPersonJobLabel: (personId: string, jobId: string, label: boolean) => void;
    fetchPersonDetails: (id: string) => void;
    toggleDrawer: () => void;
}

type PersonLabelComponentProps = OwnProps & ConnectedProps & ConnectedDispatch;

class PersonLabelComponent extends React.Component<PersonLabelComponentProps, undefined> {
    constructor(props: PersonLabelComponentProps) {
        super(props);
        props.fetchPersonDetails(props.personId);
    }

    ensureDataExists(props: PersonLabelComponentProps) {
        const { personId, persons } = props;
        if (!persons.list || !persons.list.get(personId)) {
            props.fetchPersonDetails(personId);
        }
    }

    componentDidUpdate() {
        this.ensureDataExists(this.props);
    }

    render() {
        const { personId, persons, jobId, jobs, userId, pendingRequests } = this.props;
        if (!persons.list || !persons.list.get(personId) || !jobs.get(jobId)) {
            return <Spinner />;
        } else {
            const job = jobs.get(jobId);
            const details = persons.list.get(personId);
            const label = details.person.jobLabels.find((l) => l.userId === userId && l.jobId === jobId);
            const creating = pendingRequests.has(`person-job-labels-create-${personId}-${jobId}`);
            return (
                <DocumentTitle title="Person Job Rating">
                    <PersonJobLabelComponent
                        personDetails={details}
                        job={job}
                        userLabel={label}
                        createLabel={this.props.createPersonJobLabel}
                        creating={creating}
                        toggleDrawer={this.props.toggleDrawer}
                    />
                </DocumentTitle>
            );
        }
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    jobs: state.jobs,
    pendingRequests: state.pendingRequests,
    persons: state.personsDetails,
    userId: state.session.user.id
});
const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    createPersonJobLabel,
    fetchPersonDetails,
    toggleDrawer
};
export const PersonJobLabel = connect<ConnectedProps, ConnectedProps, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(PersonLabelComponent);
