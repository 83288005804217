import { gql } from '@apollo/client';

export interface PersonTag {
    id: string;
    tagId: string;
    createdBy: string;
    tag: {
        value: string;
    };
}

export interface RemoveTags {
    id: string;
    tagId: string;
}

export const GET_TAGS = gql`
    query Tags($taggableId: String!, $taggableType: taggable!) {
        taggings(where: { taggableId: { _eq: $taggableId }, taggableType: { _eq: $taggableType } }) {
            id
            tagId
            createdBy
            tag {
                value
            }
        }
    }
`;

export const REMOVE_TAG = gql`
    mutation removeUserCreatedTags($deletedTagId: String!, $createdBy: String!) {
        delete_taggings(where: { id: { _eq: $deletedTagId }, createdBy: { _eq: $createdBy } }) {
            affected_rows
        }
    }
`;

export const ADD_TAG = gql`
    mutation insertNewPersonTags($taggableId: String!, $taggableType: taggable!, $value: String!) {
        insert_taggings(
            objects: {
                taggableType: $taggableType
                taggableId: $taggableId
                tag: {
                    data: { value: $value }
                    on_conflict: { constraint: tags_value_key, update_columns: [modifiedAt] }
                }
            }
        ) {
            returning {
                id
                tagId
                createdBy
                tag {
                    value
                }
            }
        }
    }
`;
