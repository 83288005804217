import * as React from 'react';
import { connect } from 'react-redux';

import { fetchPersonsList } from '../actions';
import { List, Person, State } from '../state';

interface OwnProps {
    id: string;
}

interface ConnectedProps {
    persons: List<Person>;
}

interface ConnectedDispatch {
    fetchPersonsList: (ids: string[]) => void;
}

type PersonNameProps = OwnProps & ConnectedDispatch & ConnectedProps;

class PersonNameComponent extends React.Component<PersonNameProps, undefined> {
    constructor(props: PersonNameProps) {
        super(props);
        this.ensureDataExists(props);
    }

    ensureDataExists(props: PersonNameProps) {
        const { id, persons } = props;
        if (!persons.list.has(id)) {
            this.props.fetchPersonsList([id]);
        }
    }

    componentDidUpdate() {
        this.ensureDataExists(this.props);
    }

    render() {
        const { id, persons } = this.props;
        if (!persons.list.has(id)) {
            return null;
        }
        return <span>{persons.list.get(id).name.full}</span>;
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    persons: state.persons
});
const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    fetchPersonsList
};
export const PersonName = connect<ConnectedProps, ConnectedProps, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(PersonNameComponent);
