import { useQuery } from '@apollo/client';
import React from 'react';

import { Users } from '../components/users';
import { Loading } from '../core-ui/loading';
import { ACTIVE_USERS, ScrumUserData } from '../graphql/queries/user';

export const AllUsers: React.FC = () => {
    const { data, refetch } = useQuery<{
        users: ScrumUserData[];
    }>(ACTIVE_USERS);
    if (!data) {
        return <Loading />;
    } else {
        return <Users users={data.users} refetch={refetch} />;
    }
};
