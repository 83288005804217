import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ClientInvoicesFees } from '../components/client-invoices-fees';

interface RouterProps {
    id: string;
}

const ClientInvoicesFeesPageComponent: React.FC<RouteComponentProps<RouterProps>> = ({ match, location }) => {
    const activeTab = location.pathname.split('/').pop() as 'invoices' | 'fees';
    return <ClientInvoicesFees clientId={match.params.id} activeTab={activeTab} />;
};

export const ClientInvoicesFeesPage = withRouter(ClientInvoicesFeesPageComponent);
