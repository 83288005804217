import { css } from '@emotion/core';
import { Tab, Tabs } from 'material-ui';
import * as React from 'react';
import { Redirect } from 'react-router';

import { tabBackgroundColor } from '../common/css-variables';
import { ClientJobTitle } from './client-job-title';
import { Header } from './header';

const styles = css`
    .client-page-header-secondary {
        flex: 1 1 auto;
    }

    .client-page-header-tabs {
        margin: 0 auto;
        width: 840px;
    }
`;

type ClientPageTab = 'Candidates' | 'Metrics' | 'Notes' | 'Settings' | 'Meetings' | 'Invoices';

interface OwnProps {
    clientId: string;
    activeTab: string;
    actions?: JSX.Element[];
}
type ClientPageHeaderProps = OwnProps;

export const ClientPageHeader: React.FC<ClientPageHeaderProps> = (props) => {
    const { actions, clientId, activeTab } = props;

    const [redirectTo, setRedirectTo] = React.useState<ClientPageTab>(null);

    const title = <ClientJobTitle clientId={clientId} />;

    React.useEffect(() => {
        if (redirectTo && activeTab === redirectTo) {
            setRedirectTo(null);
        }
    }, [redirectTo, activeTab]);

    const getTabPath = (tabName: ClientPageTab) => {
        const pathSuffix = {
            Candidates: 'candidates',
            Invoices: 'invoices',
            Meetings: 'meetings',
            Metrics: 'metrics',
            Notes: 'notes',
            Settings: 'edit'
        };
        return `/client/${clientId}/${pathSuffix[tabName]}`;
    };

    const handleTabActive = (tabName: ClientPageTab) => () => {
        if (activeTab !== tabName) {
            setRedirectTo(tabName);
        }
    };

    if (redirectTo && redirectTo !== activeTab) {
        return <Redirect to={getTabPath(redirectTo)} push={true} />;
    }

    const tabNames: ClientPageTab[] = ['Candidates', 'Notes', 'Metrics', 'Meetings', 'Settings', 'Invoices'];

    const tabs = tabNames.map((tab) => (
        <Tab
            label={tab}
            style={{ height: '32px' }}
            buttonStyle={{ height: '32px', fontSize: '12px' }}
            onActive={handleTabActive(tab)}
            value={tab}
            key={tab}
        />
    ));

    return (
        <Header title={title} actions={actions}>
            <div css={styles}>
                <div className="client-page-header-secondary">
                    <div className="client-page-header-tabs">
                        <Tabs
                            tabItemContainerStyle={{ backgroundColor: 'transparent' }}
                            value={activeTab}
                            inkBarStyle={{ backgroundColor: tabBackgroundColor }}
                        >
                            {tabs}
                        </Tabs>
                    </div>
                </div>
            </div>
        </Header>
    );
};
