import * as React from 'react';

import { SearchConfigClientEmployees } from 'shared/models/search';

import { SelectField } from '../core-ui/simple-select-field';
import { RadioButtons } from './search-radio-buttons';
import { SearchRequirementsSection } from './search-requirements-section';
import { useSearch } from './use-search';

export const SearchClientEmployeesRequirements: React.FC = () => {
    const searchContext = useSearch();
    const config = searchContext.data.config.clientEmployees;
    const onChange = searchContext.onConfigFieldChange('clientEmployees');
    const readonly = searchContext.readonly;
    const jobDiscipline = searchContext.data.config.general.disciplines[0];
    const clientUrl = searchContext.client?.linkedinUrl;

    const configRef = React.useRef(config);
    configRef.current = config;

    if (!config) return null;

    const { enabled, overlapRequired, disciplineMatch, hopsAllowed, maxCompanySize } = config;

    const handleUpdate =
        <T extends keyof SearchConfigClientEmployees>(field: T) =>
        (val: SearchConfigClientEmployees[T]) => {
            onChange(Object.assign({}, configRef.current, { [field]: val }));
        };

    const selectFieldOptions = [true, false];
    const getRequirePrefixLabels = (val: boolean) => (val ? 'Required' : 'Not Required');
    const disciplineMatchLabel = (
        <span>
            Only use client employees matching <b>{jobDiscipline}</b>
        </span>
    );
    const hopOptions = [1, 2, 3, 4, 5]; // tslint:disable-line:no-magic-numbers
    const companySizeOptions = [10, 50, 100, 200, 500, 1000, 10000, 0]; // tslint:disable-line:no-magic-numbers
    const companySizeLabel = (c: number) => (c !== 0 ? `${c}` : '10000+');

    let disciplineField;
    let overlapField;
    let hopsCountField;
    let companySizeField;

    if (enabled) {
        disciplineField = (
            <SelectField
                disabled={readonly}
                className="search-requirements-field"
                selected={disciplineMatch}
                onSelect={handleUpdate('disciplineMatch')}
                options={selectFieldOptions}
                getOptionLabel={getRequirePrefixLabels}
                label={disciplineMatchLabel}
            />
        );
        overlapField = (
            <SelectField
                disabled={readonly}
                className="search-requirements-field"
                selected={overlapRequired}
                onSelect={handleUpdate('overlapRequired')}
                options={selectFieldOptions}
                getOptionLabel={getRequirePrefixLabels}
                label="Time overlap required"
            />
        );
        hopsCountField = (
            <SelectField
                disabled={readonly}
                className="search-requirements-field"
                selected={hopsAllowed}
                onSelect={handleUpdate('hopsAllowed')}
                options={hopOptions}
                label="Job hops since at same company"
            />
        );
        companySizeField = (
            <SelectField
                disabled={readonly}
                className="search-requirements-field"
                selected={maxCompanySize}
                onSelect={handleUpdate('maxCompanySize')}
                options={companySizeOptions}
                getOptionLabel={companySizeLabel}
                label="Max size of common company"
            />
        );
    }
    const options = [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' }
    ];
    return (
        <SearchRequirementsSection section="client-employees">
            <div className="search-requirements-field">
                <label className="search-label">Enabled</label>
                <div className="search-requirements-value radio-buttons">
                    <RadioButtons
                        name="Positive Emails"
                        selected={enabled}
                        options={options}
                        onChange={handleUpdate('enabled')}
                        readonly={readonly || !clientUrl}
                    />
                </div>
            </div>
            {disciplineField}
            {overlapField}
            {hopsCountField}
            {companySizeField}
        </SearchRequirementsSection>
    );
};
