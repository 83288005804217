import { useQuery } from '@apollo/client';
import React from 'react';

import { Users } from '../components/users';
import { Loading } from '../core-ui/loading';
import { SCRUM_USERS, ScrumUserData } from '../graphql/queries/user';
import { useSession } from '../hooks/use-session';

export const ScrumUsers: React.FC = () => {
    const { user } = useSession();
    const { data, refetch } = useQuery<
        {
            users: Array<{
                user: ScrumUserData;
            }>;
        },
        { lead: string }
    >(SCRUM_USERS, {
        variables: { lead: user.id }
    });
    if (!data) {
        return <Loading />;
    } else {
        return <Users users={data.users.map((u) => u.user)} refetch={refetch} />;
    }
};
