import { Map } from 'immutable';
import { orderBy } from 'lodash';
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { SearchTerm } from 'shared/models/search';
import { PresetGroup, SearchPresetData } from 'shared/models/search-preset';

import { useMutation } from '@apollo/client';
import { fetchPresets } from '../actions';
import { INSERT_PRESET, PresetSaveResponse, UPDATE_PRESET } from '../graphql/queries/search-presets';
import { useReduxDispatch, useReduxState } from '../hooks/use-redux';
import { useSession } from '../hooks/use-session';
import { SearchPresets } from './search-presets';
import { SearchPresetsFormProvider } from './use-search-presets-form';

interface SearchPresetsContextType {
    requestPresets: (
        onSelect: (label: string, values: string[], id: string) => void,
        validTabs: PresetGroup[],
        selectedPresets: SearchTerm[]
    ) => void;
    updateSelectedPresets: (terms: SearchTerm[]) => void;
    getGroupSearchTerms: (group: PresetGroup, additionalTerms?: SearchTerm[]) => SearchTerm[];
    searchPresets: SearchPresetData[];
    refreshPresets: () => void;
    createPreset: (preset: Partial<SearchPresetData>) => Promise<{ id: string; name: string }>;
    updatePreset: (id: string, updates: Partial<SearchPresetData>) => Promise<{ id: string; name: string }>;
    loading: boolean;
}

const SearchPresetsContext = createContext<SearchPresetsContextType | undefined>(undefined);

export const SearchPresetsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { user } = useSession();
    const [handleSelect, setHandleSelect] =
        useState<(label: string, values: string[], id: string) => void | null>(null);
    const [tabs, setTabs] = useState<PresetGroup[]>([]);
    const [presets, setPresets] = useState<SearchTerm[]>(null);
    const searchPresets = useReduxState((state) => state.searchPresets.valueSeq().toArray());
    const [createPresetMutation, { loading: creating }] = useMutation<
        PresetSaveResponse,
        { preset: Partial<SearchPresetData> }
    >(INSERT_PRESET);
    const [updatePresetMutation, { loading: updating }] = useMutation<
        PresetSaveResponse,
        { id: string; updates: Partial<SearchPresetData> }
    >(UPDATE_PRESET);
    const presetGroups = useMemo(() => {
        let result = Map<PresetGroup, SearchPresetData[]>();
        for (const preset of searchPresets) {
            const group = preset.group;
            if (!result.has(group)) {
                result = result.set(group, []);
            }
            result = result.set(group, [...result.get(group), preset]);
        }
        return result;
    }, [searchPresets]);
    const dispatch = useReduxDispatch();

    useEffect(() => {
        if (!searchPresets || searchPresets.length === 0) {
            dispatch(fetchPresets());
        }
    }, []);

    const requestPresets = (
        onSelect: (label: string, values: string[], id: string) => void,
        validTabs: PresetGroup[],
        selectedPresets: SearchTerm[]
    ) => {
        setHandleSelect(() => onSelect);
        setTabs(validTabs);
        setPresets(selectedPresets);
    };

    const refreshPresets = () => {
        dispatch(fetchPresets());
    };

    const createPreset = useCallback(
        async (preset: Partial<SearchPresetData>) => {
            const result = await createPresetMutation({ variables: { preset } });
            refreshPresets();
            return result.data.presets.returning[0];
        },
        [createPresetMutation]
    );

    const updatePreset = useCallback(
        async (id: string, updates: Partial<SearchPresetData>) => {
            const result = await updatePresetMutation({ variables: { id, updates } });
            refreshPresets();
            return result.data.presets.returning[0];
        },
        [updatePresetMutation]
    );

    const loading = creating || updating;

    const updateSelectedPresets = (terms: SearchTerm[]) => {
        setPresets(terms);
    };

    const handleClosePresets = () => {
        setHandleSelect(null);
        setTabs([]);
        setPresets(null);
    };

    const sortPresetTerms = (terms: SearchTerm[]) => {
        return orderBy(
            terms,
            [
                // Priority 1: Terms with the a count
                (term) => (term as any).count ?? 0,
                // Priority 2: Terms with the current user's full name as a prefix
                (term) => (term.label?.match(new RegExp(`^\\[${user.name.full}\\]`, 'i')) ? 0 : 1),
                // Priority 3: Terms without any prefix
                (term) => (term.label?.match(/^\[.*\]/) ? 1 : 0),
                // Priority 4: Terms with any other user's name as a prefix
                (term) => (term.label?.match(/^\[.*\]/) ? 1 : 0),
                // Lexicographic sorting of terms by label
                (term) => term.label?.toLowerCase() || ''
            ],
            ['desc', 'asc', 'asc', 'desc', 'asc']
        );
    };

    const getGroupSearchTerms = (group: PresetGroup, additionalTerms?: SearchTerm[]) => {
        const groupPresets = presetGroups.get(group) || [];
        const terms = groupPresets.map((p) => ({ isPreset: true, label: p.name, presetId: p.id }));
        return sortPresetTerms([...terms, ...(additionalTerms || [])]);
    };

    const presetsDialog = handleSelect ? (
        <SearchPresets
            onSelect={handleSelect}
            onCancel={handleClosePresets}
            validTabs={tabs}
            selectedPresets={presets}
        />
    ) : null;

    const contextValue = {
        createPreset,
        getGroupSearchTerms,
        loading,
        refreshPresets,
        requestPresets,
        searchPresets,
        updatePreset,
        updateSelectedPresets
    };

    return (
        <SearchPresetsContext.Provider value={contextValue}>
            <SearchPresetsFormProvider>
                {children}
                {presetsDialog}
            </SearchPresetsFormProvider>
        </SearchPresetsContext.Provider>
    );
};

export const useSearchPresets = () => {
    const context = useContext(SearchPresetsContext);
    if (!context) {
        throw new Error('useSearchPresets must be used within a SearchPresetsProvider');
    }
    return context;
};
