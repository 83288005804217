import { Tooltip } from '@material-ui/core';
import * as classNames from 'classnames';
import { OrderedMap } from 'immutable';
import { Avatar as MaterialAvatar, Checkbox, Tab, Tabs } from 'material-ui';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { ContactChannel } from 'shared/models/contact';
import { NoteKind } from 'shared/models/note';

import { getPendingCountsByView } from '../common/communication-utils';
import { tabBackgroundColor } from '../common/css-variables';
import { JobTitle } from '../containers/job-title';
import { UserActions } from '../containers/user-actions';
import { usePrevious } from '../hooks/use-previous';
import { useProfileAdornments } from '../hooks/use-profile-adornment';
import { useReduxState } from '../hooks/use-redux';
import { Avatar } from '../sfc/avatar';
import { CandidateActions } from '../sfc/candidate-actions';
import { PersonProfile } from '../sfc/person-profile';
import { User } from '../sfc/user';
import { Candidate as CandidateData, Client, Job, PersonDetails, Preferences } from '../state';
import { ChannelContacts } from './channel-contacts';
import { CrossAddCandidate } from './cross-add-candidate';
import { CrosspitchJobs } from './crosspitch-jobs';
import { JobInfoTooltip } from './job-info-tooltip';
import { PersonBlacklisted } from './person-blacklisted';
import { PersonCallsTexts } from './person-calls-texts';
import { PersonCandidacies } from './person-candidacies';
import { PersonCommunications } from './person-communications';
import { PersonCompensation } from './person-compensation';
import { PersonFavorite } from './person-favorite';
import { PersonFiles } from './person-files';
import { PersonInterviews } from './person-interviews';
import { PersonJobScore } from './person-job-score';
import { PersonLinks } from './person-links';
import { PersonLocation } from './person-location';
import { PersonNameEdit } from './person-name-edit';
import { PersonNotes } from './person-notes';
import { PersonRecruitingActivity } from './person-recruiting-activity';
import { PersonReminders } from './person-reminders';
import { PersonTags } from './person-tags';
import { PersonVisaStatus } from './person-visa-status';
import { PlacementsBillingInfo } from './placements-billing-info';
import { ReassignCandidateAssignee } from './reassign-candidate-assignee';

interface PersonProps {
    details: PersonDetails;
    candidate?: CandidateData;
    job?: Job;
    client?: Client;
    updateUserPrefs: (prefs: Partial<Preferences>) => void;
    selectedProfileTab: string;
    menuButton?: JSX.Element;
    jobNotesOnly?: boolean;
    notesKind?: NoteKind;
    selected?: boolean;
    onSelect?: (_1: any, selected: boolean) => void;
}

export const Person: React.FC<PersonProps> = (props) => {
    const {
        details,
        job,
        candidate,
        selectedProfileTab,
        jobNotesOnly,
        menuButton,
        notesKind,
        client,
        onSelect,
        selected
    } = props;
    const jobStages = useReduxState((state) => state.appConstants.constants.jobStages);
    const { getAdornmentsForPerson } = useProfileAdornments();
    const { insertElements } = getAdornmentsForPerson(details?.person?.id, job?.id);
    const scoreAdornment = insertElements?.find((a) => a.slot === 'scoreDetailedView')?.element;
    const profileActionsAdornment = insertElements?.find((a) => a.slot === 'detailProfileActions')?.element;

    // tslint:disable:object-literal-sort-keys
    const tabNames = OrderedMap({
        profile: 'Profile',
        notes: 'Notes',
        communications: 'Emails',
        phone: 'Phone',
        interviews: 'Interviews'
    });
    // tslint:enable:object-literal-sort-keys

    const initialTab = tabNames.has(selectedProfileTab) ? selectedProfileTab : 'profile';
    const [activeTab, setActiveTab] = React.useState<string>(initialTab);
    const headerRef = React.useRef<HTMLDivElement>(null);
    const prevCandidate = usePrevious(candidate);

    React.useEffect(() => {
        if (
            prevCandidate &&
            (prevCandidate.jobId !== candidate.jobId || prevCandidate.personId !== candidate.personId)
        ) {
            headerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [candidate]);

    const handleTabActive = (tab: string) => () => {
        props.updateUserPrefs({ selectedProfileTab: tab });
        setActiveTab(tab);
    };

    const { person } = details;
    const avatarSize = 75;
    const profileLinks = <PersonLinks personDetails={details} />;
    const emails = details.contacts.filter((c) => c.channel === ContactChannel.Email);
    const emailContacts = (
        <ChannelContacts
            channel={ContactChannel.Email}
            contacts={emails}
            details={details}
            job={job}
            client={client}
            personId={details.person.id}
        />
    );
    const phones = details.contacts.filter((c) => c.channel === ContactChannel.Phone);
    const phoneContacts = (
        <ChannelContacts
            channel={ContactChannel.Phone}
            contacts={phones}
            personId={details.person.id}
            details={details}
            job={job}
            client={client}
        />
    );
    const context = candidate ? { jobId: candidate.jobId } : {};
    let jobInfo;
    if (job) {
        const tooltip = (
            <span>
                Assignee: <User id={candidate.assignee} />
            </span>
        );
        const assigneeAvatarSize = 24;
        const assignee = (
            <Tooltip title={tooltip}>
                <div style={{ marginLeft: 15 }}>
                    <MaterialAvatar size={assigneeAvatarSize}>
                        <User id={candidate.assignee} name="initials" />
                    </MaterialAvatar>
                </div>
            </Tooltip>
        );
        jobInfo = (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <JobInfoTooltip jobId={job.id}>
                    <div className="job-title">
                        <i className="material-icons list-icon">work</i>
                        <Link to={`/job/${job.id}/board`}>
                            <JobTitle id={job.id} />
                        </Link>
                    </div>
                </JobInfoTooltip>
                {assignee}
            </div>
        );
    }
    const candidateActions = candidate ? <CandidateActions candidate={candidate} jobStages={jobStages} /> : null;
    const personAction = !candidate ? <CrossAddCandidate candidate={candidate} personId={person.id} /> : null;
    const crosspitchJobs = candidate ? <CrosspitchJobs personId={person.id} jobId={job?.id} /> : null;

    const notesTabContent = (
        <PersonNotes
            personDetails={details}
            notable={`persons-${person.id}`}
            context={context}
            job={job}
            jobNotesOnly={jobNotesOnly}
            notesKind={notesKind}
        />
    );
    const communicationsTabContent = (
        <PersonCommunications client={client} person={person} job={job} contacts={emails} />
    );

    const profileTabContent = details.profile ? (
        <PersonProfile
            personId={details.person.id}
            jobId={job?.id}
            profile={details.profile.content}
            viewedAt={details.profile.viewedAt}
        />
    ) : null;
    const interviewsTabContent = <PersonInterviews personId={person.id} jobId={job?.id} />;
    // tslint:disable:object-literal-sort-keys
    const tabsContent = OrderedMap({
        profile: profileTabContent,
        communications: communicationsTabContent,
        notes: notesTabContent,
        phone: <PersonCallsTexts personId={details.person.id} jobId={candidate?.jobId} />,
        interviews: interviewsTabContent
    });
    // tslint:enable:object-literal-sort-keys
    const countsByView = getPendingCountsByView(candidate?.pendingEmails);
    const tabs = tabNames
        .map((v: string, k: string) => {
            let label: React.ReactNode;
            switch (k) {
                case 'communications':
                    const classes = classNames({
                        'candidate-unread':
                            countsByView.get('candidate') > 0 || countsByView.get('candidate-and-client') > 0,
                        'client-unread': countsByView.get('client') > 0 || countsByView.get('candidate-and-client') > 0,
                        'notification-badge': true
                    });
                    label =
                        candidate?.pendingEmailsCount > 0 ? (
                            <div className="tab-label">
                                <div>{v}</div>
                                <div className={classes}>{candidate.pendingEmailsCount}</div>
                            </div>
                        ) : (
                            v
                        );
                    break;
                default:
                    label = v;
                    break;
            }
            return (
                <Tab
                    label={label}
                    style={{ height: '32px' }}
                    buttonStyle={{ height: '32px', fontSize: '12px' }}
                    onActive={handleTabActive(k)}
                    value={k}
                    key={k}
                />
            );
        })
        .toArray();
    const scores = <PersonJobScore personId={person.id} jobId={job?.id} />;
    const drawerMenu = menuButton ? <div className="header-left-menu">{menuButton}</div> : null;
    const remindersSection = <PersonReminders jobId={job?.id} personId={person.id} />;
    const reassignCandidate = job ? <ReassignCandidateAssignee jobId={job.id} personId={person.id} /> : null;
    const selectionSection = onSelect ? (
        <div className="profile-selected-toggle">
            <Checkbox checked={selected} onCheck={onSelect} iconStyle={{ margin: 0 }} />
        </div>
    ) : null;

    return (
        <div className="profile">
            <div className="header" ref={headerRef}>
                <div className="header-hero">
                    {drawerMenu}
                    {selectionSection}
                    <div className="header-hero-left">
                        <div className="header-candidate-info-actions">
                            <div className="header-candidate-info">
                                <div className="header-candidate-info-name">
                                    <h1>{person.name.full}</h1>
                                    <PersonNameEdit personId={person.id} />
                                </div>
                                {jobInfo}
                            </div>
                            <div className="header-candidate-actions">
                                <PersonFavorite personId={person.id} />
                                {remindersSection}
                                {reassignCandidate}
                                <PersonBlacklisted personId={person.id} jobId={job?.id} />
                            </div>
                        </div>
                        <div className="tab-bar">
                            <Tabs
                                tabItemContainerStyle={{ backgroundColor: 'transparent' }}
                                value={activeTab}
                                inkBarStyle={{ backgroundColor: tabBackgroundColor }}
                            >
                                {tabs}
                            </Tabs>
                        </div>
                    </div>
                    <div className="header-hero-right">
                        <div className="ratings">{scoreAdornment}</div>
                        <div className="profile-actions">{profileActionsAdornment}</div>
                        <Avatar entity={person} size={avatarSize} />
                    </div>
                </div>
            </div>
            <div className="content-scroll">
                <div className="content">
                    <div className="left-col">{tabsContent.get(activeTab)}</div>
                    <div className="right-col">
                        <div>
                            {personAction}
                            {candidateActions}
                            {emailContacts}
                            {phoneContacts}
                            <PersonLocation details={details} />
                            <PersonVisaStatus person={person} />
                            <PersonCompensation person={person} jobId={job?.id} />
                            <PersonRecruitingActivity person={person} jobId={job?.id} />
                            <PersonFiles person={person} />
                            {profileLinks}
                            {crosspitchJobs}
                            <PersonTags personId={person.id} />
                            <PlacementsBillingInfo candidate={candidate} job={job} />
                            {scores}
                            <PersonCandidacies candidates={details.candidates} currentJobId={job?.id} />
                            <UserActions target={`persons-${person.id}`} context={context} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
