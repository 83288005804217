import * as _ from 'lodash';
import { EmailAccountLabel } from './types';

export const getLabelDisplay = (label: EmailAccountLabel) => {
    const labelNameOverrides: { [id: string]: string } = {
        DRAFT: 'Drafts'
    };
    if (labelNameOverrides[label.id]) return labelNameOverrides[label.id];
    return label.type === 'system' ? _.startCase(_.toLower(label.name)) : label.name;
};
