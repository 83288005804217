import * as React from 'react';
import DocumentTitle from 'react-document-title';

import { Account as AccountComponent } from '../components/account';
import { Header } from '../components/header';
import { useSession } from '../hooks/use-session';

export const Account = () => {
    const { user } = useSession();
    return (
        <DocumentTitle title="Account">
            <div id="container">
                <Header title="Account" />
                <div id="content" className="flex-fill">
                    <AccountComponent username={user.username} />
                </div>
            </div>
        </DocumentTitle>
    );
};
