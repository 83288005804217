import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Outreaches } from '../components/outreaches';

interface RouterProps {
    username: string;
}

const OutreachesComponent: React.FC<RouteComponentProps<RouterProps>> = ({ match }) => {
    return <Outreaches username={match.params.username} />;
};

export const OutreachesPage = withRouter(OutreachesComponent);
