import { gql } from '@apollo/client';

export interface ClientCandidatesReminderTemplates {
    id: string;
    kind: string;
    body: string;
}

export const CLIENT_CANDIDATES_REMINDER_TEMPLATES = gql`
    query ClientReminderTemplates($group: String!, $kind: String!) {
        email_templates(where: { group: { _eq: $group }, kind: { _eq: $kind } }) {
            id
            kind
            body
        }
    }
`;
