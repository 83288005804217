import { gql } from '@apollo/client';
import { FeeType } from 'shared/models/fee';

import { InvoicePaymentStatus, InvoiceStatus } from 'shared/models/invoice';

export interface BaseFee {
    __typename?: 'fees';
    id: string;
    type: FeeType;
    amount: number;
    incurredAt: number;
    clientId: string;
    description?: string;
    dueAt?: number;
    salary?: number;
    signOnBonus?: number;
    recruiterCommission?: number;
    accountManagerId?: string;
    recruiterId?: string;
    accountManagerCommission?: number;
    guaranteeCompletionAt?: number;
    appliedToFeeId?: string;
    personId?: string;
    jobId?: string;
    createdAt?: number;
}

export interface Fee extends BaseFee {
    person?: {
        id: string;
        name: string;
    };
    job?: {
        id: string;
        title: string;
        accountManager: {
            id: string;
            name: string;
        };
    };
    client: {
        id: string;
        name: string;
    };
    recruiter: {
        id: string;
        name: string;
    };
    accountManager: {
        id: string;
        name: string;
    };
    invoices: Invoice[];
}
export interface PlacementFee extends Fee {
    candidate: {
        scores: Array<{
            id: string;
            score: number;
            createdAt: number;
            searchProfilesScoreId: string | null;
        }>;
    };
}
export interface InvoiceLineItem {
    id: string;
    description: string;
    amount: number;
    price: number;
    quantity: number;
    feeId: string;
    refunded: boolean;
    fee?: Fee;
}

export interface InvoicePayment {
    id: string;
    amount: number;
    date: number;
    status: InvoicePaymentStatus;
}

export interface InvoiceCustomer {
    id: string;
    name: string;
    contact: { firstName: string; lastName: string };
    email: string;
    clientId: string;
    client: {
        id: string;
        name: string;
    };
}

export interface Invoice {
    id: string;
    totalAmount: number;
    status: InvoiceStatus;
    invoiceNumber: string;
    invoiceDate: number;
    archived: boolean;
    dueDate: number;
    pdf: string;
    payments: InvoicePayment[];
    customer: InvoiceCustomer;
    lineItems: InvoiceLineItem[];
}

export const feeFragment = gql`
    fragment FeeFragment on fees {
        id
        amount
        type
        salary
        signOnBonus
        description
        dueAt
        incurredAt
        recruiterCommission
        accountManagerCommission
        guaranteeCompletionAt
        appliedToFeeId
        personId
        jobId
        createdAt
        client {
            id
            name
        }
        person {
            id
            name(path: "full")
        }
        job {
            id
            title
            accountManager: userByAccountmanagerid {
                id
                name(path: "full")
            }
        }
        accountManager {
            id
            name(path: "full")
        }
        recruiter {
            id
            name(path: "full")
        }
    }
`;

export const invoiceFragment = gql`
    fragment InvoiceFragment on invoices {
        id
        totalAmount
        status
        invoiceNumber
        invoiceDate
        archived
        dueDate
        pdf
        lineItems {
            id
            description
            amount
            price
            quantity
            feeId
            refunded
        }
        payments {
            id
            amount
            date
            status
        }
        customer {
            id
            name
            contact
            email
            clientId
            client {
                id
                name
            }
        }
    }
`;

export const CANDIDATE_COMMISSION_PERCENTAGES = gql`
    query CandidateCommissionPercentages($personId: String!, $jobId: String!) {
        percentages: candidates_by_pk(personId: $personId, jobId: $jobId) {
            _id
            recruiterPercentage
            accountManagerPercentage
        }
    }
`;

export const PLACEMENT_FEES = gql`
    ${feeFragment}
    ${invoiceFragment}
    query PlacementFees($personId: String!, $jobId: String!) {
        fees(
            where: {
                personId: { _eq: $personId }
                jobId: { _eq: $jobId }
                type: { _in: ["placement", "monthly-contractor"] }
            }
        ) {
            ...FeeFragment
            invoices {
                ...InvoiceFragment
            }
        }
    }
`;

export const UPDATE_PLACEMENT_FEE = gql`
    mutation UpdatePlacementFee($id: String!, $data: fees_set_input!) {
        update_fees_by_pk(pk_columns: { id: $id }, _set: $data) {
            id
        }
    }
`;

export const CREATE_PLACEMENT_FEE = gql`
    mutation CreatePlacementFee($data: fees_insert_input!) {
        insert_fees_one(object: $data) {
            id
        }
    }
`;

export const JOB_FEES = gql`
    ${invoiceFragment}
    ${feeFragment}
    query JobFees($jobId: String!) {
        fees(where: { jobId: { _eq: $jobId } }, order_by: { incurredAt: desc }) {
            ...FeeFragment
            invoices {
                ...InvoiceFragment
            }
        }
    }
`;

export const CREATE_JOB_FEE = gql`
    mutation CreateJobFee($data: fees_insert_input!) {
        insert_fees_one(object: $data) {
            id
        }
    }
`;

export const EDIT_JOB_FEE = gql`
    mutation EditJobFee($id: String!, $data: fees_set_input!) {
        update_fees_by_pk(pk_columns: { id: $id }, _set: $data) {
            id
        }
    }
`;

export const DELETE_JOB_FEE = gql`
    mutation DeleteJobFee($id: String!) {
        delete_fees_by_pk(id: $id) {
            id
        }
    }
`;

export const CLIENT_INVOICES = gql`
    ${feeFragment}
    ${invoiceFragment}
    query ClientInvoices($clientId: String!) {
        invoices(where: { customer: { client: { id: { _eq: $clientId } } } }, order_by: { invoiceDate: desc }) {
            ...InvoiceFragment
            lineItems {
                fee {
                    ...FeeFragment
                }
            }
        }
    }
`;

export const CLIENT_FEES = gql`
    ${feeFragment}
    ${invoiceFragment}
    query ClientFees($clientId: String!) {
        fees(where: { clientId: { _eq: $clientId } }, order_by: { incurredAt: desc }) {
            ...FeeFragment
            invoices {
                ...InvoiceFragment
            }
        }
    }
`;

export const FEE_DETAILS = gql`
    ${feeFragment}
    ${invoiceFragment}
    query FeeDetails($id: String!) {
        fee: fees_by_pk(id: $id) {
            ...FeeFragment
            invoices {
                ...InvoiceFragment
            }
        }
    }
`;

export const APPLY_FEE_TO_LINE_ITEM = gql`
    mutation ApplyFeeToLineItem($id: String!, $data: invoice_line_items_set_input!) {
        updatedLineItem: update_invoice_line_items_by_pk(pk_columns: { id: $id }, _set: $data) {
            id
            feeId
        }
    }
`;

export const FEES = gql`
    ${feeFragment}
    ${invoiceFragment}
    query Fees($jobType: [String!], $startTime: bigint!, $endTime: bigint!, $excludeClientIds: [String!]) {
        fees(
            where: {
                _or: [{ job: { jobType: { _in: $jobType } } }, { jobId: { _is_null: true } }]
                client: { id: { _nin: $excludeClientIds } }
                incurredAt: { _gte: $startTime, _lte: $endTime }
                type: { _neq: "prepayment" }
            }
            order_by: { incurredAt: desc }
        ) {
            ...FeeFragment
            invoices {
                ...InvoiceFragment
            }
        }
    }
`;

export const INVOICES = gql`
    ${invoiceFragment}
    ${feeFragment}
    query Invoices($jobType: [String!], $startTime: bigint!, $endTime: bigint!, $excludeClientIds: [String!]) {
        invoices(
            where: {
                archived: { _eq: false }
                invoiceDate: { _gte: $startTime, _lte: $endTime }
                customer: { client: { id: { _nin: $excludeClientIds } }, isStaffingCustomer: { _eq: false } }
                _or: [
                    { lineItems: { feeId: { _is_null: true } } }
                    { lineItems: { fee: { jobId: { _is_null: true } } } }
                    { lineItems: { fee: { job: { jobType: { _in: $jobType } } } } }
                ]
            }
            order_by: { invoiceDate: desc }
        ) {
            ...InvoiceFragment
            lineItems {
                fee {
                    ...FeeFragment
                }
            }
        }
    }
`;

export const MONTHLY_RPO_FEES = gql`
    ${feeFragment}
    ${invoiceFragment}
    query MonthlyRPOFees($jobType: [String!], $startTime: bigint!, $endTime: bigint!, $excludeClientIds: [String!]) {
        fees(
            where: {
                type: { _eq: "monthly-rpo" }
                client: { id: { _nin: $excludeClientIds } }
                incurredAt: { _gte: $startTime, _lte: $endTime }
                _or: [{ job: { jobType: { _in: $jobType } } }, { jobId: { _is_null: true } }]
            }
            order_by: { incurredAt: desc }
        ) {
            ...FeeFragment
            invoices {
                ...InvoiceFragment
            }
        }
    }
`;

export const PLACEMENTS = gql`
    ${feeFragment}
    ${invoiceFragment}
    query Placements($jobType: [String!], $startTime: bigint!, $endTime: bigint!, $excludeClientIds: [String!]) {
        fees(
            where: {
                type: { _in: ["placement", "monthly-contractor"] }
                client: { id: { _nin: $excludeClientIds } }
                incurredAt: { _gte: $startTime, _lte: $endTime }
                _or: [{ job: { jobType: { _in: $jobType } } }, { jobId: { _is_null: true } }]
            }
            order_by: { incurredAt: desc }
        ) {
            ...FeeFragment
            invoices {
                ...InvoiceFragment
            }
            candidate {
                scores(order_by: { createdAt: desc }) {
                    id
                    score
                    createdAt
                    searchProfilesScoreId
                }
            }
        }
    }
`;
