import { css } from '@emotion/core';
import { Theme } from '@material-ui/core';

export const styles = (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 auto;
    overflow-y: auto;
    padding: 20px 0 36px;

    .client-form {
        margin: 0 auto 36px;
        width: 920px;
        padding: 20px;
        background: white;
        border: thin solid ${theme.palette.divider};
        border-radius: ${theme.shape.borderRadius}px;
    }

    .MuiFormLabel-root.Mui-disabled,
    .MuiInputBase-root.Mui-disabled,
    .MuiChip-root.Mui-disabled {
        color: inherit;
        opacity: 0.8;
    }

    .form-action-buttons {
        display: flex;
        width: 960px;
        justify-content: flex-end;

        .MuiButton-root {
            margin-left: 10px;
        }
    }

    .form-field-row {
        display: flex;
        gap: 40px;
        margin-bottom: 40px;
    }

    .clickable-field {
        cursor: pointer;
        color: ${theme.palette.primary.main};
    }

    .blacklist-kind-avatar {
        width: auto;
        border-radius: 12px;
        padding: 0 8px;
    }
`;
