import { OrderedMap } from 'immutable';
import { AutoComplete, FlatButton, MenuItem, SelectField } from 'material-ui';
import { white } from 'material-ui/styles/colors';
import * as React from 'react';

import { timeMonthYear } from '../common/timestamp';
import { Client, Communication, Job, List } from '../state';

interface CommunicationWarningJobsProps {
    data: Communication;
    clients: List<Client>;
    jobs: OrderedMap<string, Job>;
    updateCommunicationMatchData: (
        communication: Communication,
        data: {
            ignored?: boolean;
            personIds?: string[];
            jobIds?: string[];
            error?: boolean;
            manualOverridePersonIds?: boolean;
            manualOverrideJobIds?: boolean;
            bug?: boolean;
        }
    ) => void;
}

export const CommunicationWarningJobs: React.FunctionComponent<CommunicationWarningJobsProps> = ({
    data,
    jobs,
    clients,
    updateCommunicationMatchData
}) => {
    const { personIds } = data;

    const handleUpdateInput = (text: string, _1: any[]) => {
        setSearchText(text);
    };
    const handleNewRequestJobId = (chosenRequest: { text: string; value: string }, _1: number) => {
        const error =
            (!personIds || personIds.length !== 1) && !data.matchData.manualOverridePersonIds ? true : undefined;
        updateCommunicationMatchData(data, { jobIds: [chosenRequest.value], error, manualOverrideJobIds: true });
    };

    const handleSelectJob = (id: string) => () => {
        const error =
            (!personIds || personIds.length !== 1) && !data.matchData.manualOverridePersonIds ? true : undefined;
        updateCommunicationMatchData(data, { jobIds: [id], error, manualOverrideJobIds: true });
    };

    const markOk = () => {
        const error =
            (!personIds || personIds.length !== 1) && !data.matchData.manualOverridePersonIds ? true : undefined;
        updateCommunicationMatchData(data, { error, manualOverrideJobIds: true });
    };

    const { jobIds } = data;
    const [searchText, setSearchText] = React.useState('');

    let jobIdErrorMessage: string;
    let confirmButtonLabel: string;
    if (!jobIds || jobIds.length === 0) {
        jobIdErrorMessage = 'No job could be associated with this email';
        confirmButtonLabel = 'Confirm no job for email';
    } else if (jobIds.length > 1) {
        jobIdErrorMessage = 'Multiple jobs were associated with this email';
        confirmButtonLabel = `Confirm ${jobIds.length} jobs for email`;
    }
    const jobIdOptions = jobIds || jobs.keySeq().toArray();
    const jobOptions = jobIdOptions
        .map((jobId) => jobs.get(jobId))
        .sort((job1, job2) => {
            const job1Label = `${clients.list.get(job1.clientId).name} > ${job1.title} (${job1.createdAt})`;
            const job2Label = `${clients.list.get(job2.clientId).name} > ${job2.title} (${job2.createdAt})`;
            return job1Label.localeCompare(job2Label);
        });

    const hintStyle = {
        alignItems: 'center',
        color: white,
        display: 'flex',
        justifyContent: 'center',
        opacity: 0.7
    };
    let jobSelector;
    if (jobIds && jobIds.length > 0) {
        const jobItems = jobOptions.map((job) => {
            const text = `${clients.list.get(job.clientId).name} > ${job.title} (${timeMonthYear(job.createdAt)})`;
            return (
                <MenuItem
                    key={job.id}
                    value={job.id}
                    label={job.id}
                    primaryText={text}
                    onClick={handleSelectJob(job.id)}
                />
            );
        });
        jobSelector = (
            <SelectField
                underlineStyle={{ display: 'none' }}
                style={{ width: '150px' }}
                hintText="Assign Job"
                hintStyle={hintStyle}
                autoWidth={true}
            >
                {jobItems}
            </SelectField>
        );
    } else {
        const dataSource = jobOptions.map((j) => ({
            text: `${clients.list.get(j.clientId).name} > ${j.title} (${timeMonthYear(j.createdAt)})`,
            value: j.id
        }));
        jobSelector = (
            <AutoComplete
                className="email-header-warning-job-auto-complete"
                searchText={searchText}
                dataSource={dataSource}
                style={{ display: 'flex', flex: '1 1 auto', width: 'auto' }}
                underlineShow={false}
                fullWidth={true}
                hintText={searchText ? '' : 'Assign Job'}
                openOnFocus={true}
                filter={AutoComplete.fuzzyFilter}
                onUpdateInput={handleUpdateInput}
                hintStyle={hintStyle}
                onNewRequest={handleNewRequestJobId}
            />
        );
    }

    const shouldShowJobWarning = (!jobIds || jobIds.length !== 1) && !data.matchData.manualOverrideJobIds;
    const jobWarning = shouldShowJobWarning ? (
        <div className="email-header-warning email-job-warning">
            {jobIdErrorMessage}
            <div className="email-match-job-ids-selector">
                {jobSelector}
                <FlatButton onClick={markOk} labelStyle={{ color: white }} key="ok" label={confirmButtonLabel} />
            </div>
        </div>
    ) : null;

    return jobWarning;
};
