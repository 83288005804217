import { Button, MenuItem, MenuList, Popover } from '@material-ui/core';
import * as React from 'react';

interface EmailSequenceFormVariablesProps {
    variables: string[];
}

export const EmailSequenceFormVariables: React.FC<EmailSequenceFormVariablesProps> = ({ variables }) => {
    const [popoverOpen, setPopoverOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClosePopover = () => {
        setPopoverOpen(false);
    };

    const handleOpenPopover = (e: React.MouseEvent) => {
        e.preventDefault();
        setAnchorEl(e.currentTarget);
        setPopoverOpen(true);
    };

    const handleInsertVariable = (variable: string) => (e: React.MouseEvent) => {
        e.preventDefault();
        if (variable === '{{Phone Call Scheduling Link}}') {
            document.execCommand(
                'insertHtml',
                false,
                '<a href="{{Phone Call Scheduling Link}}">{{Phone Call Scheduling Link Text}}</a>'
            );
        } else if (variable === '{{atsLink}}' || variable === '{{personLinkedin}}') {
            document.execCommand('insertHtml', false, `<a href="${variable}">${variable}</a>`);
        } else if (variable === '{{primaryEmail}}') {
            document.execCommand('insertHtml', false, '<a href="mailto:{{primaryEmail}}">{{primaryEmail}}</a>');
        } else {
            document.execCommand('insertText', false, variable);
        }
        setPopoverOpen(false);
    };

    const menuItems = variables.map((v) => {
        return (
            <MenuItem key={v} onClick={handleInsertVariable(v)}>
                {v}
            </MenuItem>
        );
    });
    const popover = (
        <Popover
            open={popoverOpen}
            onClose={handleClosePopover}
            anchorEl={anchorEl}
            transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        >
            <MenuList>{menuItems}</MenuList>
        </Popover>
    );

    return (
        <React.Fragment>
            <Button onClick={handleOpenPopover}>Personalize</Button>
            {popover}
        </React.Fragment>
    );
};
