import { Map } from 'immutable';

import {
    Action,
    ReceiveAddSearchResultToJob,
    ReceiveJobSearchForSourcing,
    ReceiveSearchProfiles,
    ReceiveSearchResults,
    RequestSearchDelete,
    RequestSearchResults
} from '../actions';
import { SearchResultsState } from '../state';

const initialState: Map<string, SearchResultsState> = null;

export function searchResults(state = initialState, action: Action): Map<string, SearchResultsState> {
    switch (action.type) {
        case RequestSearchResults: {
            const data = (state || Map()).get(action.payload.search.id);
            if (data?.error) {
                return state.remove(action.payload.search.id);
            } else {
                return state;
            }
        }
        case ReceiveSearchResults:
            return (state || Map()).set(action.payload.search.id, action.payload.data);
        case RequestSearchDelete:
            return (state || Map()).remove(action.payload.search.id);
        case ReceiveSearchProfiles: {
            const data = state.get(action.payload.searchId);
            let profiles = data.profiles || Map();
            for (const p of action.payload.profiles) {
                profiles = profiles.set(p.personId, p);
            }
            const companySimilarity = Object.assign({}, data.companySimilarity, action.payload.companySimilarity);
            return state.set(action.payload.searchId, Object.assign({}, data, { profiles, companySimilarity }));
        }
        case ReceiveJobSearchForSourcing:
            return action.payload.selected
                ? (state || Map()).set(action.payload.selected.id, {
                      results: action.payload.results,
                      resultsType: undefined
                  })
                : state;
        case ReceiveAddSearchResultToJob: {
            const { result } = action.payload;
            const data = state.get(result.searchId);
            if (data) {
                const results = data.results.filter((r) => r.personId !== result.personId); // remove old result
                return state.set(result.searchId, { ...data, results });
            } else {
                return state;
            }
        }
        default:
            return state;
    }
}
