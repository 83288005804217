import { Map, OrderedMap } from 'immutable';
import { CircularProgress, FlatButton } from 'material-ui';
import { white } from 'material-ui/styles/colors';
import * as React from 'react';
import { connect } from 'react-redux';

import { EmailAccountView } from 'shared/models/email-account';
import { Permissions } from 'shared/models/permission';
import { hasRole, UserData } from 'shared/models/user';

import {
    addContactFromMessage,
    fetchJobInfo,
    fetchJobsData,
    fetchPersonsList,
    getAllEmailAccountInfo,
    ignoreContactFromMessage,
    requestCommunicationMatch,
    updateCommunicationMatchData
} from '../actions';
import { unexpectedEmailAddress } from '../common/communication-utils';
import {
    Client,
    Communication as CommunicationType,
    Job,
    List,
    ListState,
    Person,
    RequestErrors,
    State
} from '../state';
import { CommunicationWarningJobs } from './communications-warnings-jobs';
import { CommunicationWarningPersons } from './communications-warnings-persons';
import { CommunicationWarningUnknownEmail } from './communications-warnings-unknown-email';

interface OwnProps {
    data: CommunicationType;
}

interface ConnectedProps {
    user: UserData;
    jobs: OrderedMap<string, Job>;
    listsState: Map<string, ListState>;
    pendingRequests: Map<string, RequestErrors>;
    persons: List<Person>;
    clients: List<Client>;
    emailAccounts: Map<string, EmailAccountView>;
    userPermissions: Permissions;
}

interface ConnectedDispatch {
    fetchJobsData: () => void;
    updateCommunicationMatchData: (
        communication: CommunicationType,
        data: {
            ignored?: boolean;
            personIds?: string[];
            jobIds?: string[];
            error?: boolean;
            manualOverridePersonIds?: boolean;
            manualOverrideJobIds?: boolean;
            bug?: boolean;
        }
    ) => void;
    fetchJobInfo: (jobId: string) => void;
    requestCommunicationMatch: (communication: CommunicationType) => void;
    addContactFromMessage: (payload: {
        account: string;
        personId: string;
        value: string;
        messageId: string;
        threadId: string;
    }) => void;
    fetchPersonsList: (ids: string[]) => void;
    getAllEmailAccountInfo: () => void;
    ignoreContactFromMessage: (account: string, messageId: string, threadId: string) => void;
}

type CommunicationWarningProps = OwnProps & ConnectedProps & ConnectedDispatch;

const CommunicationWarningComponent: React.FunctionComponent<CommunicationWarningProps> = (props) => {
    const { data, jobs, emailAccounts } = props;
    const { account } = data;
    const emailAccount = emailAccounts.get(account);
    const { jobIds, personIds } = data;

    const requestRematch = () => {
        props.requestCommunicationMatch(data);
    };
    const setIgnoreMatchData = () => {
        props.updateCommunicationMatchData(data, {
            ignored: true,
            manualOverrideJobIds: true,
            manualOverridePersonIds: true
        });
    };
    const reportCommunicationBug = () => {
        props.updateCommunicationMatchData(data, {
            bug: true,
            manualOverrideJobIds: true,
            manualOverridePersonIds: true
        });
    };

    React.useEffect(() => {
        if (jobIds) {
            for (const jobId of jobIds || []) {
                if (!jobs.has(jobId)) {
                    props.fetchJobInfo(jobId);
                }
            }
        } else {
            if (!props.listsState.get('jobs')) {
                props.fetchJobsData();
            }
        }

        if (emailAccounts.isEmpty()) {
            props.getAllEmailAccountInfo();
        }
    });

    React.useEffect(() => {
        if (personIds) {
            const idsToFetch = personIds.filter((id) => !props.persons.list.has(id));
            if (idsToFetch.length > 0) {
                props.fetchPersonsList(idsToFetch);
            }
        }
    }, [personIds]);

    const hasUnexpectedEmail = unexpectedEmailAddress(data);
    const shouldShowWarnings = data.matchData.error || hasUnexpectedEmail;
    if (!shouldShowWarnings || !props.clients.initialized || emailAccounts.isEmpty()) {
        return null;
    }

    const hasAllJobData = jobIds
        ? jobIds.map((jid) => jobs.has(jid)).reduce((prev, curr) => prev && curr, true)
        : props.listsState.get('jobs') && props.listsState.get('jobs') === 'initialized';
    const hasAllPersons = (personIds || [])
        .map((id) => props.persons.list.has(id))
        .reduce((prev, curr) => prev && curr, true);
    const requestInFlight = props.pendingRequests.has(`update-match-data-${props.data.account}-${props.data.threadId}`);
    if (requestInFlight || !hasAllJobData || !hasAllPersons) {
        const size = 30;
        return (
            <div className="email-header-warnings">
                <div className="email-header-warning email-header-loading">
                    <CircularProgress size={size} color={white} />
                </div>
            </div>
        );
    }

    const ignoreButton = (
        <FlatButton onClick={setIgnoreMatchData} labelStyle={{ color: white }} key="ignore" label="Ignore" />
    );
    const ignoreAction =
        data.matchData.error && hasRole(props.userPermissions, 'email_ignore_match_setter') ? (
            <div className="email-header-warning email-warning-action-buttons">{ignoreButton}</div>
        ) : null;

    const reMatchButton = (
        <FlatButton onClick={requestRematch} labelStyle={{ color: white }} key="rematch" label="Rematch" />
    );
    const rematchAction = data.matchData.error ? (
        <div className="email-header-warning email-warning-action-buttons">{reMatchButton}</div>
    ) : null;

    const reportButton = (
        <FlatButton onClick={reportCommunicationBug} labelStyle={{ color: white }} key="report" label="Report" />
    );
    const reportAction =
        data.matchData.error && hasRole(props.userPermissions, 'email_match_setter') ? (
            <div className="email-header-warning email-warning-action-buttons">{reportButton}</div>
        ) : null;

    return (
        <div className="email-header-warnings">
            <CommunicationWarningPersons
                data={data}
                persons={props.persons}
                updateCommunicationMatchData={props.updateCommunicationMatchData}
                emailAccount={emailAccount}
            />
            <CommunicationWarningJobs
                data={data}
                clients={props.clients}
                jobs={props.jobs}
                updateCommunicationMatchData={props.updateCommunicationMatchData}
            />
            <CommunicationWarningUnknownEmail
                data={data}
                addContactFromMessage={props.addContactFromMessage}
                ignoreContactFromMessage={props.ignoreContactFromMessage}
            />
            {ignoreAction}
            {rematchAction}
            {reportAction}
        </div>
    );
};

const mapStateToProps = (state: State) => ({
    clients: state.clients,
    emailAccounts: state.emailAccounts,
    jobs: state.jobs,
    listsState: state.listsState,
    pendingRequests: state.pendingRequests,
    persons: state.persons,
    user: state.session.user,
    userPermissions: state.session.userPermissions
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    addContactFromMessage,
    fetchJobInfo,
    fetchJobsData,
    fetchPersonsList,
    getAllEmailAccountInfo,
    ignoreContactFromMessage,
    requestCommunicationMatch,
    updateCommunicationMatchData
};

export const CommunicationWarning = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(CommunicationWarningComponent);
