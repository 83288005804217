import { useMutation, useQuery } from '@apollo/client';
import {
    Avatar,
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    Typography
} from '@material-ui/core';
import { escapeRegExp, orderBy } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';

import { hasRole } from 'shared/models/user';

import { PageDialogLink } from '../common/page-dialog-link';
import { ClientCandidates } from '../containers/client-candidates';
import { JobCandidatesBoard } from '../containers/job-candidates-board';
import { ALLOCATIONS_BY_JOB, DELETE_ALLOCATION, JobAllocation, JobAllocations } from '../graphql/queries/allocations';
import { useLocalStorage } from '../hooks/use-local-storage';
import { useModal } from '../hooks/use-modal';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';
import { JobFunnelTargetsForm } from './job-funnel-targets-form';

export const AllocationsJob: React.FC<{ filterText: string }> = ({ filterText }) => {
    const { data, refetch } = useQuery<JobAllocations>(ALLOCATIONS_BY_JOB);
    const [deleteAllocation] = useMutation<{}, { jobId: string; userId: string }>(DELETE_ALLOCATION);
    const [sortCol, setSortCol] = useLocalStorage<string>('allocations-job-table-sort-column', 'Client');
    const [sortAsc, setSortAsc] = useLocalStorage<boolean>('allocations-job-table-sort-asc', true);
    const { user, userPermissions } = useSession();
    const { getConfirmation } = useModal();
    const { setSnackbar } = useSnackbar();
    const [addingAllocationJobId, setAddingAllocationJobId] = React.useState<string | null>(null);

    const handleSortChange = (column: string) => () => {
        const newSortAsc = sortCol === column ? !sortAsc : true;
        setSortCol(column);
        setSortAsc(newSortAsc);
    };
    const getSortDirection = (ascending: boolean) => (ascending ? 'asc' : 'desc');

    const sortFuncs = (col: string, sortOrder: 'asc' | 'desc') => {
        switch (col) {
            case 'Client':
                return {
                    functions: [(job: JobAllocation) => job.client.name.toLocaleLowerCase()],
                    order: [sortOrder]
                };
            case 'Job':
                return {
                    functions: [(job: JobAllocation) => job.title.toLocaleLowerCase()],
                    order: [sortOrder]
                };
            case 'Allocated':
                return {
                    functions: [
                        (job: JobAllocation) => job.allocations_aggregate.aggregate.sum.acceptsGoal ?? 0,
                        (job: JobAllocation) => job.client.name.toLocaleLowerCase()
                    ],
                    order: [sortOrder, 'asc' as 'asc']
                };
            case 'Target':
                return {
                    functions: [
                        (job: JobAllocation) => job.target?.goal ?? 0,
                        (job: JobAllocation) => job.client.name.toLocaleLowerCase()
                    ],
                    order: [sortOrder, 'asc' as 'asc']
                };
            case 'Allocation Gap':
                return {
                    functions: [
                        (job: JobAllocation) =>
                            (job.allocations_aggregate.aggregate.sum.acceptsGoal ?? 0) - (job.target?.goal ?? 0),
                        (job: JobAllocation) => job.client.name.toLocaleLowerCase()
                    ],
                    order: [sortOrder, 'asc' as 'asc']
                };
            case 'Last 14d':
                return {
                    functions: [
                        (job: JobAllocation) => job.metrics?.accepts ?? 0,
                        (job: JobAllocation) => job.client.name.toLocaleLowerCase()
                    ],
                    order: [sortOrder, 'asc' as 'asc']
                };
            case 'Last 14d Gap':
                return {
                    functions: [
                        (job: JobAllocation) => (job.metrics?.accepts ?? 0) - (job.target?.goal ?? 0),
                        (job: JobAllocation) => job.client.name.toLocaleLowerCase()
                    ],
                    order: [sortOrder, 'asc' as 'asc']
                };
            default:
                return { functions: [], order: [] };
        }
    };

    const handleDeleteAllocation = (jobId: string, userId: string) => () => {
        getConfirmation(
            async () => {
                setSnackbar('Deleting allocation...');
                await deleteAllocation({ variables: { jobId, userId } });
                await refetch();
                setSnackbar('Allocation deleted');
            },
            'Are you sure you want to delete this allocation?',
            'Delete Allocation'
        );
    };

    const handleAddAllocation = (jobId: string) => () => {
        setAddingAllocationJobId(jobId);
    };

    const handleCloseAddAllocationDialog = () => setAddingAllocationJobId(null);

    const handleAllocationUpdated = () => {
        refetch();
        handleCloseAddAllocationDialog();
    };

    const getValueTargetClass = (value: number, target: number) => {
        return value === target ? 'equal' : value < target / 2 ? 'under-severe' : value < target ? 'under' : 'over';
    };
    const getGapString = (value: number, target: number) => {
        const gap = value - target;
        return gap === 0 ? '0' : gap > 0 ? `+${gap}` : `${gap}`;
    };

    const { functions, order } = sortFuncs(sortCol, getSortDirection(sortAsc));
    const searchRegex = new RegExp(escapeRegExp(filterText), 'i');
    const filteredData = data?.jobs.filter(
        (job) => !filterText || searchRegex.test(job.client.name) || searchRegex.test(job.title)
    );
    const sortedData = orderBy(filteredData, functions, order);
    const rows = sortedData?.map((job) => {
        const canEdit = user.id === job.accountManagerId || hasRole(userPermissions, 'job_funnel_target_editor');
        const allocated = job.allocations_aggregate.aggregate.sum.acceptsGoal ?? 0;
        const target = job.target?.goal ?? 0;
        const last14d = job.metrics?.accepts ?? 0;
        const last14dGap = getGapString(last14d, target);
        const allocatedGap = getGapString(allocated, target);
        const totalClass = getValueTargetClass(allocated, target);
        const last14Class = getValueTargetClass(last14d, target);
        const users = job.allocations.map((allocation) => (
            <Chip
                key={allocation.user.id}
                label={allocation.user.name}
                variant="outlined"
                avatar={<Avatar>{allocation.acceptsGoal}</Avatar>}
                onDelete={canEdit ? handleDeleteAllocation(job.id, allocation.user.id) : undefined}
            />
        ));
        const addAllocation = canEdit ? (
            <Chip
                className="add-allocation-chip"
                key="add"
                label="Add"
                variant="outlined"
                avatar={<Avatar>+</Avatar>}
                onClick={handleAddAllocation(job.id)}
            />
        ) : null;

        return (
            <TableRow key={job.id}>
                <TableCell>
                    <PageDialogLink
                        Component={ClientCandidates}
                        componentProps={{ id: job.client.id }}
                        url={`/client/${job.client.id}/candidates`}
                    >
                        <Link to={`/client/${job.client.id}/candidates`}>{job.client.name}</Link>
                    </PageDialogLink>
                </TableCell>
                <TableCell>
                    <PageDialogLink
                        Component={JobCandidatesBoard}
                        componentProps={{ jobId: job.id }}
                        url={`/job/${job.id}/board`}
                    >
                        <Link to={`/job/${job.id}/board`}>{job.title}</Link>
                    </PageDialogLink>
                </TableCell>
                <TableCell>
                    <div className="role-chips">
                        {users}
                        {addAllocation}
                    </div>
                </TableCell>
                <TableCell>
                    <Tooltip title={`Target accepts in a 14 day period: ${target}`}>
                        <span>{target}</span>
                    </Tooltip>
                </TableCell>
                <TableCell>
                    <Tooltip title={`${allocated} accepts allocated to recruiters`}>
                        <span>{allocated}</span>
                    </Tooltip>
                </TableCell>
                <TableCell>
                    <Tooltip title={`Gap of ${allocatedGap} accepts between target and allocations to recruiters`}>
                        <span className={`total ${totalClass}`}>{target - allocated}</span>
                    </Tooltip>
                </TableCell>
                <TableCell>
                    <Tooltip title={`${last14d} accepts in the last 14 day period`}>
                        <span>{last14d}</span>
                    </Tooltip>
                </TableCell>
                <TableCell>
                    <Tooltip title={`Gap of ${last14dGap} between actual in last 14 days and target`}>
                        <span className={`total ${last14Class}`}>{last14d - target}</span>
                    </Tooltip>
                </TableCell>
            </TableRow>
        );
    });

    const addAllocationDialog = (
        <Dialog open={!!addingAllocationJobId} onClose={handleCloseAddAllocationDialog} maxWidth="sm" fullWidth={true}>
            <DialogTitle>
                <Typography variant="h5" component="div">
                    Allocations & Funnel Targets for a 14 day period
                </Typography>
            </DialogTitle>
            <DialogContent>
                <JobFunnelTargetsForm
                    jobId={addingAllocationJobId}
                    disabled={false}
                    onChange={handleAllocationUpdated}
                />
            </DialogContent>
        </Dialog>
    );

    const columns = [
        'Client',
        'Job',
        'Allocations',
        'Target',
        'Allocated',
        'Allocation Gap',
        'Last 14d',
        'Last 14d Gap'
    ];
    const colClasses: { [key: string]: string } = {
        'Allocation Gap': 'width120',
        Client: 'width120',
        Job: 'width120',
        'Last 14d': 'width90',
        'Last 14d Gap': 'width120'
    };

    const headerColumns = columns.map((col) => {
        const columnHeader =
            col === 'Allocations' ? (
                col
            ) : (
                <TableSortLabel
                    active={sortCol === col}
                    direction={getSortDirection(sortAsc)}
                    onClick={handleSortChange(col)}
                >
                    {col}
                </TableSortLabel>
            );
        return (
            <TableCell className={colClasses[col] ?? ''} key={col}>
                {columnHeader}
            </TableCell>
        );
    });

    return (
        <Paper>
            <TableContainer>
                <Table stickyHeader={true}>
                    <TableHead>
                        <TableRow>{headerColumns}</TableRow>
                    </TableHead>
                    <TableBody>{rows}</TableBody>
                </Table>
            </TableContainer>
            {addAllocationDialog}
        </Paper>
    );
};
