import { Map } from 'immutable';
import * as _ from 'lodash';
import { CircularProgress, FlatButton, TextField } from 'material-ui';
import * as React from 'react';
import { connect } from 'react-redux';

import { standardizeUrl } from 'profile-parser';

import { addWebsite } from '../actions';
import { isURL } from '../common/blacklist-utils';
import { PersonDetails, RequestErrors, State } from '../state';
import { ProfileLinkAttributes } from './profile-link-attributes';
import { ProfileUrlAttributes } from './profile-url-attributes';

interface OwnProps {
    personDetails: PersonDetails;
}

interface PersonLinksState {
    active: boolean;
    value: string;
    errorText: string;
}

interface ConnectedProps {
    pendingRequests: Map<string, RequestErrors>;
}

interface ConnectedDispatch {
    addWebsite: (payload: { personId: string; value: string }) => void;
}

type PersonLinksProps = OwnProps & ConnectedDispatch & ConnectedProps;

interface Link {
    invalid?: boolean;
    linkedin: boolean;
    personal: boolean;
    personId?: string;
    value: string;
    website: boolean;
}

class PersonLinksComponent extends React.Component<PersonLinksProps, PersonLinksState> {
    private textInput: HTMLInputElement;

    constructor(props: PersonLinksProps) {
        super(props);
        this.state = { active: false, value: '', errorText: '' };
    }

    textRef = (input: any) => {
        this.textInput = input;
    };

    validateInput = (link: string): boolean => {
        return isURL(link);
    };

    componentDidUpdate(prevProps: PersonLinksProps, prevState: PersonLinksState) {
        if (!prevState.active && this.state.active) {
            this.textInput.focus();
        }
        const requestKey = this.requestKey();
        if (prevProps.pendingRequests.has(requestKey)) {
            if (!this.props.pendingRequests.has(requestKey)) {
                this.setState({ value: '', errorText: '', active: false });
            } else if (
                prevProps.pendingRequests.get(requestKey) !== this.props.pendingRequests.get(requestKey) &&
                this.state.value !== ''
            ) {
                const errors = this.props.pendingRequests.get(requestKey);
                if (!errors.isEmpty()) {
                    this.setState({ errorText: errors.get('value'), active: true });
                }
            }
        }
    }

    handleTextChange = (event: React.FormEvent<{}>, newValue: string) => {
        event.preventDefault();
        if (newValue === '') {
            this.setState({ value: newValue, errorText: '' });
        } else {
            const value = this.state.value.length < newValue.length ? newValue.trim() : newValue;
            this.setState({ value, errorText: '' });
        }
    };

    handleKeyPress = (event: any) => {
        const enterKeyCode = 13;
        if (event.charCode === enterKeyCode) {
            this.addPersonLink();
        }
    };

    setActive = () => {
        this.setState({ active: true });
    };

    cancelEdits = () => {
        this.setState({ active: false, value: '', errorText: '' });
    };

    addPersonLink = () => {
        const { personDetails } = this.props;
        const { value } = this.state;
        if (!this.validateInput(value)) {
            this.setState({ errorText: `Not a valid link` });
        } else {
            const formattedValue = standardizeUrl(value);
            this.setState({ errorText: '' });
            this.props.addWebsite({
                personId: personDetails.person.id,
                value: formattedValue
            });
        }
    };

    requestKey = () => {
        return `website-update-request-${this.props.personDetails.person.id}`;
    };

    render() {
        const { pendingRequests, personDetails } = this.props;
        const { active, value, errorText } = this.state;
        const spinnerSize = 20;
        const spinnerThickness = 2;
        const isSaving = pendingRequests.has(this.requestKey()) && pendingRequests.get(this.requestKey()).isEmpty();

        const actions = isSaving ? (
            <div>
                <CircularProgress size={spinnerSize} thickness={spinnerThickness} />
            </div>
        ) : active ? (
            <div>
                <FlatButton label="Cancel" disabled={isSaving} onClick={this.cancelEdits} />
                <FlatButton label="Save" disabled={isSaving} onClick={this.addPersonLink} />
            </div>
        ) : null;

        const placeholder = active ? null : (
            <div className="placeholder" onClick={this.setActive}>
                Add Link
            </div>
        );

        const form = (
            <div className={`person-prop-row auto-hide ${active ? 'not-hidden' : 'hidden'}`}>
                <div className="person-prop-form person-contact-form">
                    <TextField
                        hintText={`Add Link`}
                        ref={this.textRef}
                        value={value}
                        onChange={this.handleTextChange}
                        errorText={errorText}
                        onKeyPress={this.handleKeyPress}
                        disabled={isSaving}
                        fullWidth={true}
                    />
                    <div className="form-actions">{actions}</div>
                </div>
            </div>
        );

        const { person, profileUrls, profile } = personDetails;
        const linkedinLinks: Link[] = _.sortBy(
            profileUrls
                .filter((s) => s.url.includes('linkedin.com/in') || s.url.includes('linkedin.com/talent/profile'))
                .map((u) => ({
                    invalid: u.invalid,
                    linkedin: true,
                    personId: u.personId,
                    personal: false,
                    value: u.url,
                    website: false
                })),
            (l) => l.value
        );
        const websites: Link[] = profile
            ? profile.content.websites.map((w) => ({
                  invalid: false,
                  linkedin: false,
                  personal: false,
                  value: w.url,
                  website: true
              }))
            : [];
        const personalLinks: Link[] = person.websites.map((w) => ({
            invalid: false,
            linkedin: false,
            personId: person.id,
            personal: true,
            value: w,
            website: false
        }));
        const links = linkedinLinks.concat(websites).concat(personalLinks);
        const sortedLinks = _.uniqBy(
            _.orderBy(links || [], ['invalid', 'linkedin', 'website', 'personal'], ['asc', 'desc', 'desc', 'desc']),
            'value'
        );
        const items = sortedLinks.map((c) => {
            const url = standardizeUrl(c.value);
            const urlElement = c.invalid ? (
                <div className="invalid-link">{url}</div>
            ) : (
                <a className="link" href={`//${url}`} target="_blank">
                    {url}
                </a>
            );
            const profileUrlAttributes =
                c.personId && c.linkedin ? (
                    <ProfileUrlAttributes url={c.value} invalid={c.invalid} personId={c.personId} />
                ) : c.personId && !c.linkedin ? (
                    <ProfileLinkAttributes url={c.value} personId={c.personId} />
                ) : null;
            return (
                <div className="person-prop-row" key={c.value}>
                    <div className="person-prop-row-spaced">
                        {urlElement}
                        {profileUrlAttributes}
                    </div>
                </div>
            );
        });

        return (
            <div className={`person-props person-props-contacts ${items.length > 0 ? 'person-props-not-empty' : ''}`}>
                <i className="material-icons section-icon">link</i>
                <div className="prop">
                    {items}
                    {placeholder}
                    {form}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: State): any => ({
    pendingRequests: state.pendingRequests
});

const mapStateToDispatch = {
    addWebsite
};

export const PersonLinks = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapStateToDispatch
)(PersonLinksComponent);
