import { Action, ClearProfileConflict, ReceiveContactsUpdate, ReceivePersonWebsiteAdd } from '../actions';

export type ProfileConflict = [string, string];

const initialState: ProfileConflict = null;

export function profileConflicts(state = initialState, action: Action): ProfileConflict {
    switch (action.type) {
        case ReceivePersonWebsiteAdd:
        case ReceiveContactsUpdate:
            return action.payload.profileConflictIds ?? null;
        case ClearProfileConflict:
            return null;
        default:
            return state;
    }
}
