import { useMutation } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Theme,
    Typography,
    useTheme
} from '@material-ui/core';
import { Edit, Menu, Phone, Settings, ShowChart } from '@material-ui/icons';
import { clamp } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { hasRole } from 'shared/models/user';

import { PageDialogLink } from '../common/page-dialog-link';
import { previousWeekday } from '../common/timestamp';
import { AMActiveCandidates } from '../containers/am-active-candidates';
import { RecruiterActiveCandidates } from '../containers/recruiter-active-candidates';
import { EDIT_USER, ScrumUserData as User } from '../graphql/queries/user';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';
import { UserMetrics } from './metrics/user-metrics';
import { RecruiterFunnelTargetsForm } from './recruiter-funnel-targets-form';
import { UserCalls } from './user-calls';
import { UserDailyActivity } from './user-daily-activity';
import { UserDashboard } from './user-dashboard';

const MIN_CALLS_PER_DAY = 4;
const MAX_CALLS_PER_DAY = 9;

// tslint:disable:no-magic-numbers
const styles = (theme: Theme) => css`
    flex: 1 1 auto;
    padding: 25px 50px;
    overflow: auto;

    .MuiTableBody-root {
        .MuiTableRow-root:last-child {
            .MuiTableCell-root {
                border-bottom: none;
            }
        }
    }

    .MuiTableRow-root {
        .MuiButton-root {
            opacity: 0;
            margin-left: -5px;
            margin-right: 15px;
        }

        .settings-cell {
            min-width: 100px;
        }

        .data-container {
            position: relative;
        }

        .data-buttons {
            opacity: 0;
        }

        .menu-button {
            position: absolute;
        }

        .edit-targets-button {
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
            margin-left: 10px;
        }

        &:hover {
            .menu-button {
                opacity: 0;
                left: -5px;
                top: 5px;
            }

            .data-buttons {
                opacity: 1;
            }

            background: ${theme.palette.grey[100]};

            .MuiButton-root {
                opacity: 1;
            }

            .edit-targets-button {
                opacity: 1;
            }
        }

        .user-info {
            .MuiAvatar-root {
                margin-right: 10px;
            }

            display: inline-flex;
            align-items: center;
        }
    }
`;
// tslint:enable:no-magic-numbers

const editDialogStyles = css`
    .MuiTextField-root {
        margin-bottom: 25px;

        &:last-child {
            margin-bottom: 10px;
        }
    }
`;

const editTargetsDialogStyles = css`
    .recruiter-targets-form {
        min-height: 100px;

        .target-form-button {
            margin-bottom: 10px;
        }
    }
`;

export const Users: React.FC<{ users: User[]; refetch: () => void }> = ({ users, refetch }) => {
    const { user: sessionUser, userPermissions } = useSession();
    const theme = useTheme();
    const { setSnackbar } = useSnackbar();
    const [updateUser, { loading }] = useMutation<{}, { userId: string; updates: Partial<User> }>(EDIT_USER);
    const [activityDialogState, setActivityDialogState] = React.useState<{ user: User; ts: number }>(null);
    const [callsDialogState, setCallsDialogState] = React.useState<{ user: User; ts: number }>(null);
    const [editing, setEditing] = React.useState<{ user: User; updates: Partial<User> }>(null);
    const [editingTargetsUserId, setEditingTargetsUserId] = React.useState<string>(null);

    const handleActivityClick = (user: User, ts: number) => () => {
        setActivityDialogState({ user, ts });
    };

    const handleCallClick = (user: User, ts: number) => () => {
        setCallsDialogState({ user, ts });
    };

    const handleCloseDialog = () => {
        setActivityDialogState(null);
        setCallsDialogState(null);
    };

    const handleSettingsClick = (user: User) => () => {
        if (!loading && hasRole(userPermissions, 'user_settings_editor')) {
            setEditing({
                updates: {
                    calendarLink: user.calendarLink,
                    calendlyToken: user.calendlyToken,
                    maxCallsPerDay: user.maxCallsPerDay
                },
                user
            });
        }
    };

    const handleCancelEdit = () => setEditing(null);

    const handleEditField = (field: keyof User) => (event: { target: { value: any } }) => {
        const value =
            field === 'maxCallsPerDay'
                ? clamp(event.target.value, MIN_CALLS_PER_DAY, MAX_CALLS_PER_DAY)
                : event.target.value;
        setEditing({ ...editing, updates: { ...editing.updates, [field]: value } });
    };

    const handleUpdateUser = async () => {
        setEditing(null);
        setSnackbar('Updating user settings');
        await updateUser({ variables: { userId: editing.user.id, updates: editing.updates } });
        refetch();
        setSnackbar('User settings updated');
    };

    const handleEditTargets = (id: string) => () => {
        setEditingTargetsUserId(id);
    };

    const handleCloseEditTargets = () => {
        setEditingTargetsUserId(null);
        refetch();
    };

    const rows = users.map((user) => {
        const { profilePicUrl, id, name, username, email } = user;
        const avatar = profilePicUrl ? (
            <Avatar src={profilePicUrl} />
        ) : (
            <Avatar>
                {name.first.charAt(0).toLocaleUpperCase()}
                {name.last.charAt(0).toLocaleUpperCase()}
            </Avatar>
        );
        const primary = <Typography variant="h6">{name.full}</Typography>;
        const secondary = (
            <Typography variant="body2" color="textSecondary" component="div">
                {email}
            </Typography>
        );
        const lastDay = previousWeekday();
        const settingsColumn = hasRole(userPermissions, 'user_settings_editor') ? (
            <TableCell className="settings-cell">
                <IconButton size="small" onClick={handleSettingsClick(user)}>
                    <Settings fontSize="small" />
                </IconButton>
            </TableCell>
        ) : null;
        const metricsLink = (
            <PageDialogLink url={`/user/${username}/metrics`} Component={UserMetrics} componentProps={{ username }}>
                <Button href={`/user/${username}/dashboard`}>Metrics</Button>
            </PageDialogLink>
        );
        const acceptsTarget = user.targets?.[0]?.goal;
        const canEditTarget =
            user.scrumLeads?.findIndex((lead) => lead.leadUserId === sessionUser.id) !== -1 ||
            hasRole(userPermissions, 'recruiter_funnel_target_editor');
        const editTargetButton = canEditTarget ? (
            <IconButton size="small" className="edit-targets-button" onClick={handleEditTargets(user.id)}>
                <Edit fontSize="small" />
            </IconButton>
        ) : null;
        return (
            <TableRow key={id}>
                <TableCell>
                    <div className="user-info">
                        {avatar}
                        <div>
                            {primary}
                            {secondary}
                        </div>
                    </div>
                </TableCell>
                {settingsColumn}
                <TableCell>
                    {acceptsTarget} {editTargetButton}
                </TableCell>
                <TableCell>
                    <div className="data-container">
                        <div className="menu-button">
                            <IconButton size="small">
                                <Menu fontSize="small" />
                            </IconButton>
                        </div>
                        <div className="data-buttons">
                            <PageDialogLink
                                url={`/user/${username}/dashboard`}
                                Component={UserDashboard}
                                componentProps={{ username }}
                            >
                                <Button href={`/user/${username}/dashboard`}>Dashboard</Button>
                            </PageDialogLink>
                            {metricsLink}
                            <PageDialogLink
                                url={`/recruiter/${username}/candidates`}
                                Component={RecruiterActiveCandidates}
                                componentProps={{ username }}
                            >
                                <Button href={`/recruiter/${username}/candidates`}>Recruiter Candidates</Button>
                            </PageDialogLink>
                            <PageDialogLink
                                url={`/account-manager/${username}/candidates`}
                                Component={AMActiveCandidates}
                                componentProps={{ username }}
                            >
                                <Button href={`/account-manager/${username}/candidates`}>AM Candidates</Button>
                            </PageDialogLink>
                        </div>
                    </div>
                </TableCell>
                <TableCell>
                    <div className="data-container">
                        <div className="menu-button">
                            <IconButton size="small">
                                <Menu fontSize="small" />
                            </IconButton>
                        </div>
                        <div className="data-buttons">
                            <Button startIcon={<ShowChart />} onClick={handleActivityClick(user, moment().valueOf())}>
                                Today
                            </Button>
                            <Button startIcon={<ShowChart />} onClick={handleActivityClick(user, lastDay.valueOf())}>
                                {lastDay.format('dddd')}
                            </Button>
                        </div>
                    </div>
                </TableCell>
                <TableCell>
                    <div className="data-container">
                        <div className="menu-button">
                            <IconButton size="small">
                                <Menu fontSize="small" />
                            </IconButton>
                        </div>
                        <div className="data-buttons">
                            <Button startIcon={<Phone />} onClick={handleCallClick(user, moment().valueOf())}>
                                Today
                            </Button>
                            <Button startIcon={<Phone />} onClick={handleCallClick(user, lastDay.valueOf())}>
                                {lastDay.format('dddd')}
                            </Button>
                        </div>
                    </div>
                </TableCell>
            </TableRow>
        );
    });

    const activityDialogContent = activityDialogState ? (
        <UserDailyActivity user={activityDialogState.user} time={activityDialogState.ts} />
    ) : null;

    const callDialogContent = callsDialogState ? (
        <UserCalls user={callsDialogState.user} ts={callsDialogState.ts} />
    ) : null;

    let editingDialog;
    if (editing) {
        editingDialog = (
            <Dialog open={true} onClose={handleCancelEdit} fullWidth={true} maxWidth="sm" css={editDialogStyles}>
                <DialogTitle>
                    <Typography variant="h4" component="div">
                        Edit Settings for {editing.user.name.full}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        label="Max Calls Per Day"
                        value={editing.updates.maxCallsPerDay}
                        onChange={handleEditField('maxCallsPerDay')}
                        fullWidth={true}
                        type="number"
                    />
                    <TextField
                        label="Calendar Link"
                        value={editing.updates.calendarLink}
                        onChange={handleEditField('calendarLink')}
                        fullWidth={true}
                    />
                    <TextField
                        label="Calendly Token"
                        value={editing.updates.calendlyToken}
                        onChange={handleEditField('calendlyToken')}
                        fullWidth={true}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelEdit}>Cancel</Button>
                    <Button onClick={handleUpdateUser} disabled={loading}>
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    let editingTargetsDialog;
    if (editingTargetsUserId) {
        const editingTargetsUser = users.find((user) => user.id === editingTargetsUserId);
        editingTargetsDialog = (
            <Dialog
                open={true}
                onClose={handleCloseEditTargets}
                fullWidth={true}
                maxWidth="md"
                css={editTargetsDialogStyles}
            >
                <DialogTitle>
                    <Typography variant="h4" component="div">
                        Edit Accepts Targets for {editingTargetsUser.name.full}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <RecruiterFunnelTargetsForm
                        username={editingTargetsUser.username}
                        onSaved={handleCloseEditTargets}
                    />
                </DialogContent>
            </Dialog>
        );
    }

    const settingsColumnHeader = hasRole(userPermissions, 'user_settings_editor') ? (
        <TableCell>Settings</TableCell>
    ) : null;

    return (
        <div css={styles(theme)}>
            <TableContainer component={Paper}>
                <Table stickyHeader={true}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            {settingsColumnHeader}
                            <TableCell>Accepts Target</TableCell>
                            <TableCell>Data</TableCell>
                            <TableCell>Activity</TableCell>
                            <TableCell>Calls</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{rows}</TableBody>
                </Table>
            </TableContainer>
            <Dialog open={!!activityDialogState} onClose={handleCloseDialog}>
                {activityDialogContent}
            </Dialog>
            <Dialog open={!!callsDialogState} onClose={handleCloseDialog} maxWidth="sm" fullWidth={true}>
                {callDialogContent}
            </Dialog>
            {editingDialog}
            {editingTargetsDialog}
        </div>
    );
};
