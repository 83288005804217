import { Avatar as MaterialAvatar } from 'material-ui';
import * as React from 'react';

import { config } from '../config';

interface Entity {
    profilePicUrl?: string;
    name: {
        first: string;
        full: string;
        last: string;
    };
}

interface AvatarProps<T extends Entity> {
    entity: T;
    size?: number;
}

const defaultSize = 40;

export const Avatar = <T extends Entity>(props: AvatarProps<T>) => {
    const entity = props.entity;
    const size = props.size || defaultSize;
    let avatarText: string;
    let avatarUrl: string;
    if (entity.profilePicUrl && entity.profilePicUrl.match(/^images/)) {
        avatarUrl = `${config.AssetHost}/${entity.profilePicUrl}`;
    }
    if (!avatarUrl) {
        avatarText = entity.name.first.charAt(0).toLocaleUpperCase() + entity.name.last.charAt(0).toLocaleUpperCase();
    }
    return avatarUrl ? (
        <MaterialAvatar className={'avatar'} src={avatarUrl} size={size} style={{ flex: '0 0 auto' }} />
    ) : (
        <MaterialAvatar className={'avatar'} size={size} style={{ flex: '0 0 auto' }}>
            {avatarText}
        </MaterialAvatar>
    );
};
