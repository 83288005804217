import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { UserMetrics } from '../components/metrics/user-metrics';

interface RouterProps {
    username: string;
}

const UserMetricsComponent: React.FC<RouteComponentProps<RouterProps>> = ({ match }) => {
    const { username } = match.params;
    return <UserMetrics username={username} />;
};

export const UserMetricsPage = withRouter(UserMetricsComponent);
