import { useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip
} from '@material-ui/core';
import { AddAlarm } from '@material-ui/icons';
import { uniq } from 'lodash';
import moment from 'moment';
import * as React from 'react';

import { ReminderData } from 'shared/models/reminder';

import { fullDateTime } from '../common/timestamp';
import { CANDIDATE_USERS, CandidateUsers } from '../graphql/queries/candidates';
import { CREATE_REMINDER, Reminder, REMINDERS } from '../graphql/queries/reminders';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';
import { useTimepicker } from '../hooks/use-time-picker';

const styles = css`
    .MuiDialog-paper {
        min-width: 800px;
    }

    .MuiDialogTitle-root {
        .MuiTypography-h6 {
            font-size: 24px;
        }
    }

    .dialog-actions-container {
        flex: 1 1 auto;
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
    }

    .dialog-content-fields {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .select-field {
            flex: 0 0 auto;
            margin-left: 20px;
            min-width: 135px;
        }
    }
`;

export const PersonReminders: React.FC<{ personId: string; jobId?: string }> = ({ personId, jobId }) => {
    const { user } = useSession();
    const { setSnackbar } = useSnackbar();
    const { getCustomTime } = useTimepicker();
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [text, setText] = React.useState<string>('');
    const [remindersListDialogOpen, setRemindersListDialogOpen] = React.useState(false);
    const [recipients, setRecipients] = React.useState<string[]>([user.id]);
    const [data, setData] = React.useState<{ reminders: Reminder[] } | null>(null);
    const { data: remindersData } = useQuery<
        { reminders: Reminder[] },
        { personId: string; jobId: string; recipient: string }
    >(REMINDERS, { variables: { personId, jobId, recipient: user.id } });
    const { data: usersData } = useQuery<{ candidates: CandidateUsers[] }, { personId: string; jobId: string }>(
        CANDIDATE_USERS,
        { variables: { personId, jobId }, skip: !jobId || !personId }
    );
    const [createReminder] = useMutation<{ returning: Reminder[] }, { reminder: Partial<ReminderData> }>(
        CREATE_REMINDER,
        {
            refetchQueries: [
                {
                    query: REMINDERS,
                    variables: { personId, jobId, recipient: user.id }
                }
            ]
        }
    );

    React.useEffect(() => {
        if (remindersData) {
            const reminders = remindersData.reminders.filter((r) => (!r.jobId && !jobId) || r.jobId === jobId);
            setData({ reminders });
        }
    }, [remindersData]);

    const setReminderText = (event: React.ChangeEvent<{ value: string }>) => {
        setText(event.target.value);
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleSetReminder = (ts: number) => async () => {
        setDialogOpen(false);
        setText('');
        setRecipients([user.id]);
        setSnackbar('Creating Reminder');
        for (const recipient of uniq(recipients)) {
            await createReminder({ variables: { reminder: { personId, jobId, remindAt: ts, recipient, text } } });
        }
        setSnackbar('Reminder created');
    };

    const handleOpenTimeDialog = () => {
        getCustomTime({
            buttonLabel: 'Set Reminder',
            onSelect: (ts: number) => {
                handleSetReminder(ts)();
            }
        });
    };

    const handleSetReminderTomorrow = () => {
        handleSetReminder(moment().add(1, 'd').valueOf())();
    };

    const handleSetReminderNextWeek = () => {
        handleSetReminder(moment().add(1, 'w').valueOf())();
    };

    const handleSetRecipients = (event: React.ChangeEvent<{ value: string[] }>) => {
        setRecipients(event.target.value);
    };

    const handleOpenList = () => {
        setRemindersListDialogOpen(true);
    };

    const handleCloseList = () => {
        setRemindersListDialogOpen(false);
    };

    let dialog;
    if (dialogOpen) {
        const inProgress = false;
        const existingReminders = data?.reminders.filter((r) => r.remindAt > Date.now()) ?? [];
        const showExistingLink =
            existingReminders.length > 0 ? (
                <div>
                    <Button onClick={handleOpenList} disabled={inProgress}>
                        {existingReminders.length} Existing
                    </Button>
                </div>
            ) : null;
        const actionsDisabled = inProgress || !text || recipients.length === 0;
        const actions = [
            <div className="dialog-actions-container" key="dialog-action">
                <div>
                    <Button onClick={handleOpenTimeDialog} disabled={actionsDisabled}>
                        Custom
                    </Button>
                    <Button onClick={handleSetReminderTomorrow} disabled={actionsDisabled}>
                        Tomorrow
                    </Button>
                    <Button onClick={handleSetReminderNextWeek} disabled={actionsDisabled}>
                        Next Week
                    </Button>
                </div>
                {showExistingLink}
            </div>
        ];
        let listDialog;
        if (remindersListDialogOpen) {
            const closeListButton = (
                <Button key="close" onClick={handleCloseList}>
                    Close
                </Button>
            );
            const existingList = existingReminders
                .sort((r1, r2) => r1.remindAt - r2.remindAt)
                .map((r, i) => (
                    <div key={i} style={{ marginBottom: `${i < existingReminders.length - 1 ? '25px' : '0'}` }}>
                        <div
                            style={{ textTransform: 'uppercase', fontSize: '12px', marginBottom: '5px', color: '#888' }}
                        >
                            {fullDateTime(r.remindAt)}
                        </div>
                        <div style={{ color: 'black' }}>{r.text}</div>
                    </div>
                ));
            listDialog = (
                <Dialog open={true} onClose={handleCloseList} fullWidth={true} maxWidth="sm" css={styles}>
                    <DialogTitle>Existing Reminders</DialogTitle>
                    <DialogContent>{existingList}</DialogContent>
                    <DialogActions>{closeListButton}</DialogActions>
                </Dialog>
            );
        }
        let recipientSelectField: JSX.Element;
        if (
            usersData &&
            user.id === usersData.candidates[0].accountManager.id &&
            user.id !== usersData.candidates[0].assignee.id
        ) {
            recipientSelectField = (
                <FormControl className="select-field">
                    <InputLabel>Send Reminder To</InputLabel>
                    <Select value={recipients} onChange={handleSetRecipients} multiple={true}>
                        <MenuItem value={usersData.candidates[0].accountManager.id}>
                            {usersData.candidates[0].accountManager.name}
                        </MenuItem>
                        <MenuItem value={usersData.candidates[0].assignee.id}>
                            {usersData.candidates[0].assignee.name}
                        </MenuItem>
                    </Select>
                </FormControl>
            );
        }
        dialog = (
            <Dialog open={true} onClose={handleDialogClose} fullWidth={true} css={styles}>
                <DialogTitle>Add Reminder</DialogTitle>
                <DialogContent className="dialog-content-fields">
                    <TextField
                        value={text}
                        label="Reminder Text"
                        onChange={setReminderText}
                        name="reminder-text-field"
                        fullWidth={true}
                        disabled={inProgress}
                        autoComplete="off"
                    />
                    {recipientSelectField}
                </DialogContent>
                <DialogActions>{actions}</DialogActions>
                {listDialog}
            </Dialog>
        );
    }

    return (
        <div className="header-candidate-action">
            <Tooltip title="Add Reminder">
                <IconButton onClick={handleDialogOpen} disabled={!data}>
                    <AddAlarm htmlColor="white" />
                </IconButton>
            </Tooltip>
            {dialog}
        </div>
    );
};
