import { Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { Add, Edit, MoreVert } from '@material-ui/icons';
import { sortBy } from 'lodash';
import React from 'react';

import { hiredStage } from 'shared/models/job-stages';
import { fullDate } from '../common/timestamp';
import { BaseFee } from '../graphql/queries/billing';
import { useFees } from '../hooks/use-fees';
import { usePrevious } from '../hooks/use-previous';
import { Candidate } from '../state';

interface PlacementsBillingActionsProps {
    candidate: Candidate;
    disabled: boolean;
}

export const PlacementsBillingActions: React.FC<PlacementsBillingActionsProps> = ({ candidate, disabled }) => {
    const { onEditFee, data, onAddFee } = useFees();
    const prevStage = usePrevious(candidate?.stage);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    React.useEffect(() => {
        if (!disabled && candidate.stage === hiredStage && prevStage !== hiredStage && prevStage !== undefined) {
            onAddFee();
        }
    }, [candidate, prevStage]);

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleAddFee = () => {
        handleMenuClose();
        onAddFee();
    };

    const handleEditFee = (fee: BaseFee) => () => {
        handleMenuClose();
        onEditFee?.(fee);
    };

    if (disabled) {
        return null;
    }

    if (!data?.length) {
        return (
            <Tooltip title="Add Fee">
                <IconButton className="actions-button" onClick={onAddFee} disabled={disabled}>
                    <Add />
                </IconButton>
            </Tooltip>
        );
    }

    const menuItems = sortBy(data, (f) => -f.incurredAt)?.map((fee) => (
        <MenuItem dense={true} key={fee.id} onClick={handleEditFee(fee)}>
            <ListItemIcon>
                <Edit fontSize="small" />
            </ListItemIcon>
            View/Edit Fee ({fullDate(fee.incurredAt)})
        </MenuItem>
    ));

    const addMenuItem = (
        <MenuItem dense={true} onClick={handleAddFee}>
            <ListItemIcon>
                <Add fontSize="small" />
            </ListItemIcon>
            Add New Fee
        </MenuItem>
    );

    return (
        <>
            <IconButton className="actions-button" onClick={handleMenuOpen} disabled={disabled}>
                <MoreVert />
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                {menuItems}
                <Divider />
                {addMenuItem}
            </Menu>
        </>
    );
};
