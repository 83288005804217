import { gql } from '@apollo/client';

export const PHONE_CALL_RECIPIENT = gql`
    query PhoneCallRecipient($personId: String!, $jobId: String!) {
        recipients: sequence_recipients(
            where: {
                sequence: { jobId: { _eq: $jobId }, type: { _eq: phonescreen } }
                contact: { personId: { _eq: $personId } }
            }
        ) {
            id
        }
    }
`;
