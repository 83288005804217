import { useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import { Avatar, Button, Chip, TextField, Theme, Typography, useTheme } from '@material-ui/core';
import { clamp } from 'lodash';
import React from 'react';
import { hasRole } from 'shared/models/user';
import {
    RECRUITER_FUNNEL_TARGETS,
    RecruiterFunnelTargetData,
    UPDATE_RECRUITER_FUNNEL_DATA,
    User,
    USER_BY_USERNAME
} from '../graphql/queries/user';
import { useModal } from '../hooks/use-modal';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';
import { AddRecruiterAllocationsButton } from './add-recruiter-allocations-button';

const styles = (theme: Theme) => css`
    .target-form-button {
        margin: 20px 0 0;
        text-align: right;
    }

    .allocations {
        margin-top: 30px;
        margin-bottom: 5px;

        .allocations-content {
            padding-bottom: 3px;
        }

        .allocations-label {
            color: ${theme.palette.primary.main};
            margin-bottom: 8px;
            font-size: 12px;
        }

        .allocations-list {
            margin: -3px;
            display: flex;
            flex-wrap: wrap;
            border-bottom: thin solid ${theme.palette.primary.main};
            padding-bottom: 5px;
            margin-bottom: 0;

            .MuiChip-root {
                margin: 3px;
            }
        }

        .allocations-helper {
            color: ${theme.palette.text.secondary};
            margin: 0;
            font-size: 0.75rem;
            margin-top: 3px;
            text-align: left;
            font-weight: 400;
            line-height: 1.66;
            letter-spacing: 0.03333em;
        }

        &:hover {
            .allocations-list {
                padding-bottom: 4px;
                border-bottom: 2px solid ${theme.palette.primary.main};
            }
        }

        &.error {
            .allocations-list {
                padding-bottom: 4px;
                border-bottom: 2px solid ${theme.palette.error.main} !important;
            }

            .allocations-helper {
                color: ${theme.palette.error.main};
            }
        }
    }
`;

const minAccepts = 2;
const maxAccepts = 20;

export const RecruiterFunnelTargetsForm: React.FC<{ username: string; onSaved?: () => void }> = ({
    username,
    onSaved
}) => {
    const theme = useTheme();
    const { user, userPermissions } = useSession();
    const { data: userData } = useQuery<{ users: User[] }, { username: string }>(USER_BY_USERNAME, {
        variables: { username }
    });
    const { data, refetch } = useQuery<RecruiterFunnelTargetData, { username: string }>(RECRUITER_FUNNEL_TARGETS, {
        variables: { username }
    });
    const [updateTarget] = useMutation<
        {},
        {
            username: string;
            stage: string;
            goal: number;
            allocations: Array<{ jobId: string; acceptsGoal: number; userId: string }>;
        }
    >(UPDATE_RECRUITER_FUNNEL_DATA);
    const [target, setTarget] = React.useState<number>(undefined);
    const [allocations, setAllocations] = React.useState<
        Array<{
            acceptsGoal: number;
            userId: string;
            job: {
                id: string;
                title: string;
                accountManagerId: string;
                client: {
                    id: string;
                    name: string;
                };
            };
        }>
    >(undefined);
    const [saving, setSaving] = React.useState<boolean>(false);
    const [isDirty, setIsDirty] = React.useState(false);
    const { setSnackbar } = useSnackbar();
    const { getConfirmation } = useModal();

    React.useEffect(() => {
        if (data) {
            setAllocations([...data?.allocations]);
            const savedTarget = data?.targets.find((t) => t.stage === 'client_first_round')?.goal;
            setTarget(savedTarget);
            setIsDirty(false);
        }
    }, [data]);

    const handleSave = async () => {
        setSaving(true);
        setSnackbar('Saving funnel target');
        await updateTarget({
            variables: {
                allocations: allocations.map((a) => ({
                    acceptsGoal: a.acceptsGoal,
                    jobId: a.job.id,
                    userId: a.userId
                })),
                goal: target,
                stage: 'client_first_round',
                username
            }
        });
        await refetch();
        setSnackbar('Funnel target saved');
        setSaving(false);
        onSaved?.();
    };

    const handleDeleteAllocation = (jobId: string, userId: string) => () => {
        getConfirmation(
            () => {
                setAllocations(allocations.filter((a) => a.job.id !== jobId || a.userId !== userId));
                setIsDirty(true);
            },
            'Are you sure you want to delete this allocation?',
            'Delete Allocation'
        );
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value, 10);
        const clamped = clamp(value, minAccepts, maxAccepts);
        setIsDirty(isDirty || clamped !== data?.targets.find((t) => t.stage === 'client_first_round')?.goal);
        setTarget(clamped);
    };

    const canEdit =
        hasRole(userPermissions, 'recruiter_funnel_target_editor') ||
        data?.leads.find((l) => l.leadUserId === user?.id) ||
        user.username === username;

    const buttonDisabled = saving || target === undefined || isDirty === false;

    const saveButton = canEdit ? (
        <div className="target-form-button">
            <Button onClick={handleSave} disabled={buttonDisabled} variant="contained" color="primary">
                Save
            </Button>
        </div>
    ) : null;

    const helperText = data ? `${data.metrics?.[0]?.count ?? 0} accepts in last 14 days` : undefined;

    const currentAllocations = allocations?.map((allocation) => {
        const canDelete =
            !saving &&
            (user.id === allocation.job.accountManagerId || hasRole(userPermissions, 'recruiter_funnel_target_editor'));
        return (
            <Chip
                key={allocation.job.id}
                label={`${allocation.job.client.name} - ${allocation.job.title}`}
                variant="outlined"
                avatar={<Avatar>{allocation.acceptsGoal}</Avatar>}
                onDelete={canDelete ? handleDeleteAllocation(allocation.job.id, allocation.userId) : undefined}
            />
        );
    });

    const totalCurrent = allocations?.reduce((total, allocation) => total + allocation.acceptsGoal, 0);
    const userForAdd = {
        allocated: totalCurrent,
        id: userData?.users?.[0]?.id,
        name: userData?.users?.[0]?.name.full,
        target
    };

    return (
        <div className="recruiter-targets-form" css={styles(theme)}>
            <div className="target-form-textfield">
                <TextField
                    label="Client Accepts 14d Target"
                    disabled={!canEdit || saving}
                    value={target ?? ''}
                    fullWidth={true}
                    onChange={handleChange}
                    type="number"
                    helperText={helperText}
                />
            </div>
            <div className={`allocations ${totalCurrent > target ? 'error' : ''}`}>
                <Typography variant="subtitle1" component="div" className="allocations-label">
                    Current Allocations
                </Typography>
                <div className="allocations-content">
                    <div className="allocations-list">
                        {currentAllocations}
                        <AddRecruiterAllocationsButton user={userForAdd} onSave={refetch} />
                    </div>
                </div>
                <Typography variant="subtitle1" component="div" className="allocations-helper">
                    {totalCurrent} total accepts allocated{' '}
                    {totalCurrent > target ? `(${totalCurrent - target} over capacity)` : ''}
                </Typography>
            </div>
            {saveButton}
        </div>
    );
};
