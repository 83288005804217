// cspell:ignore gapi

import { config } from './config';

const GoogleApiUrl = 'https://apis.google.com/js/api.js';
const GoogleClientID = config.GoogleClientId;
const GoogleHostedDomain = 'getrocket.com';
const RedirectUrl = config.AppUrl;
const Scope = 'profile email';
const EmailScope = [
    'https://www.googleapis.com/auth/userinfo.email',
    'https://www.googleapis.com/auth/userinfo.profile',
    'https://mail.google.com',
    'https://www.googleapis.com/auth/calendar',
    'https://www.googleapis.com/auth/contacts'
].join(' ');

const defaultParams = {
    client_id: GoogleClientID,
    hosted_domain: GoogleHostedDomain,
    redirect_uri: RedirectUrl
};

export function ensureAuth(
    authUXMode: 'redirect' | 'popup',
    loginCb: (token: string) => void,
    errorCb: (e: Error) => void
) {
    const params = Object.assign({}, defaultParams, { ux_mode: authUXMode });
    ((d: Document, id: string, cb: () => void) => {
        const element = d.getElementsByTagName('script')[0];
        const fjs = element;
        let js = element;
        js = d.createElement('script');
        js.id = id;
        js.src = GoogleApiUrl;
        fjs.parentNode.insertBefore(js, fjs);
        js.onload = cb;
    })(document, 'google-login', () => {
        const gapi = (window as any).gapi;
        gapi.load('auth2', () => {
            gapi.auth2.init(params).then((auth2: any) => {
                if (auth2.isSignedIn.get()) {
                    const googleUser = auth2.currentUser.get();
                    auth2.isSignedIn.listen(location.reload);
                    loginCb(googleUser.getAuthResponse().id_token);
                } else {
                    const options = {
                        fetch_basic_profile: true,
                        prompt: '',
                        redirect_uri: RedirectUrl,
                        response_type: 'permission',
                        scope: Scope,
                        ux_mode: authUXMode
                    };
                    // navigate away to get login (ref: ux_mode)
                    auth2.signIn(options).then(() => {
                        /* no-op */
                    }, errorCb);
                }
            }, errorCb);
        });
    });
}

export function authPopup(loginCb: (token: string) => void, errorCb: (e: Error) => void) {
    const options = {
        fetch_basic_profile: true,
        prompt: '',
        redirect_uri: RedirectUrl,
        response_type: 'permission',
        scope: Scope,
        ux_mode: 'popup'
    };
    const gapi = (window as any).gapi;
    gapi.auth2.getAuthInstance().signIn(options).then(loginCb, errorCb);
}

export function signOut() {
    const gapi = (window as any).gapi;
    if (gapi) {
        return gapi.auth2.getAuthInstance().signOut();
    }
}

export function authorizeOfflineEmailAccess(): Promise<string> {
    return new Promise((resolve, reject) => {
        const gapi = (window as any).gapi;
        const options = {
            scope: EmailScope
        };
        gapi.auth2
            .getAuthInstance()
            .grantOfflineAccess(options)
            .then((response: any) => {
                resolve(response.code);
            })
            .catch(reject);
    });
}
