import { HrefFilePayload } from 'shared/types/file-payload';

export function readFile(file: File): Promise<HrefFilePayload> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            const data = reader.result as string;
            resolve({ href: data, contentType: file.type, filename: file.name, size: file.size, type: 'href' });
        };
        reader.onabort = () => reject(new Error('file reading was aborted'));
        reader.onerror = () => reject(new Error('file reading has failed'));
        reader.readAsDataURL(file);
    });
}
