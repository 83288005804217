import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
    Typography
} from '@material-ui/core';
import { kebabCase, orderBy, pick } from 'lodash';
import { unparse } from 'papaparse';
import React from 'react';
import ApexCharts from 'react-apexcharts';

import { GetApp, Reorder } from '@material-ui/icons';
import { preprocessData } from '../../common/chart-data-preprocessors';
import { fontFamily } from '../../common/css-variables';

export function DataPanel<T extends { name: string; count: number }>({
    companyName,
    title,
    secondaryTitle,
    data,
    view,
    sort,
    onRawDownload
}: {
    companyName: string;
    title: string;
    secondaryTitle?: string;
    data: T[];
    view: 'table' | 'pie' | 'bar';
    sort: boolean;
    onRawDownload?: () => void;
}) {
    const initialOrdered = sort ? orderBy(data, ['count', 'name'], ['desc', 'asc']) : data;
    const ordered = orderBy(initialOrdered, [(val) => val.name?.toLowerCase() === 'unknown'], ['asc']);

    const handleDownloadCounts = () => {
        const fileName = kebabCase(`${companyName}-${title}`).toLowerCase() + '.csv';
        const lines = ordered.map((entity) => pick(entity, ['name', 'count']));
        const csvContent = unparse(lines);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, fileName);
    };

    const rows = ordered.map((entity, index) => (
        <TableRow key={index} hover={true} selected={false}>
            <TableCell>{entity.name}</TableCell>
            <TableCell className="count-col">{entity.count}</TableCell>
        </TableRow>
    ));

    const sum = ordered.reduce((acc, curr) => acc + curr.count, 0);

    const table = (
        <TableContainer>
            <Table>
                <TableBody>
                    {rows}
                    <TableRow className="totals-row">
                        <TableCell>Total</TableCell>
                        <TableCell className="count-col">{sum}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );

    const chartData = preprocessData(ordered);
    const series = [{ name: 'Count', data: chartData.map((v) => v.count) }];
    const categories = chartData.map((v) => v.name);

    const pieChartOptions: ApexCharts.ApexOptions = {
        chart: { fontFamily, toolbar: { show: true }, width: '100%' },
        dataLabels: {
            enabled: true,
            formatter(val) {
                if (typeof val === 'number') {
                    return `${val.toFixed(2)}%`;
                } else if (Array.isArray(val) && typeof val[0] === 'number') {
                    return `${val[0].toFixed(2)}%`;
                } else {
                    return val as string;
                }
            }
        },
        labels: categories,
        legend: {
            position: 'right',
            show: true,
            formatter(seriesName, opts) {
                const maxLength = 48;
                const truncatedSeriesName =
                    seriesName.length > maxLength ? seriesName.slice(0, maxLength) + '...' : seriesName;
                return `${truncatedSeriesName} (${opts.w.globals.series[opts.seriesIndex]})`;
            }
        }
    };

    const pieChart = (
        <div className="pie-chart">
            <ApexCharts options={pieChartOptions} series={series[0]?.data} type="donut" height="480" />
        </div>
    );

    const barChartOptions: ApexCharts.ApexOptions = {
        chart: { fontFamily, toolbar: { show: true }, width: '100%' },
        dataLabels: {
            enabled: true,
            formatter(val) {
                if (typeof val === 'number') {
                    return val.toString();
                } else if (Array.isArray(val) && typeof val[0] === 'number') {
                    return val[0].toString();
                } else {
                    return val as string;
                }
            }
        },
        legend: { show: false },
        plotOptions: {
            bar: {
                borderRadius: 4,
                distributed: true,
                horizontal: true
            }
        },
        xaxis: { categories }
    };
    const barChart = (
        <div className="bar-chart">
            <ApexCharts options={barChartOptions} series={series} type="bar" height="480" />
        </div>
    );

    const content = view === 'bar' ? barChart : view === 'pie' ? pieChart : table;

    const rawDownloadButton = onRawDownload ? (
        <Tooltip title="Download Raw Data">
            <IconButton size="small" onClick={onRawDownload}>
                <Reorder fontSize="small" />
            </IconButton>
        </Tooltip>
    ) : null;

    const secondaryTitleElement = secondaryTitle ? (
        <Typography variant="h4" component="h4">
            {secondaryTitle}
        </Typography>
    ) : null;

    return (
        <div className="table-section">
            <div className="table-section-header">
                <div>
                    <Typography variant="h4" component="h4">
                        {title}
                    </Typography>
                </div>
                <div className="buttons">
                    {secondaryTitleElement}
                    {rawDownloadButton}
                    <Tooltip title="Download CSV">
                        <IconButton size="small" onClick={handleDownloadCounts}>
                            <GetApp fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            <div className={`content-wrapper ${view}`}>{content}</div>
        </div>
    );
}
