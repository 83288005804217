import { Map } from 'immutable';

import { Action, ReceiveJobSearchForSourcing, ResetJobSourcingSearch } from '../actions';
import { Search } from '../state';

const initialState = Map<string, { selected: Search; searches: Search[] }>();

export function jobSearchSourcing(state = initialState, action: Action) {
    switch (action.type) {
        case ReceiveJobSearchForSourcing:
            return state.set(action.payload.jobId, {
                searches: action.payload.searches,
                selected: action.payload.selected
            });
        case ResetJobSourcingSearch:
            return state.remove(action.payload.jobId);
        default:
            return state;
    }
}
