import { Tooltip } from '@material-ui/core';
import { Map, OrderedMap } from 'immutable';
import * as _ from 'lodash';
import { IconButton, Menu, MenuItem, Popover } from 'material-ui';
import { white } from 'material-ui/styles/colors';
import { CommunicationEmail } from 'material-ui/svg-icons';
import * as React from 'react';
import { connect } from 'react-redux';

import { interpolateEmailTemplate } from 'shared/common/interpolate-template';
import { CandidateSummary } from 'shared/models/candidate';
import { EmailTemplateView } from 'shared/models/email-templates';
import { SubmissionAccount } from 'shared/models/job';
import {
    awaitingClientStage,
    clientFinalRoundStage,
    clientFirstRoundStage,
    clientMiddleRoundStage
} from 'shared/models/job-stages';
import { UserData } from 'shared/models/user';
import { EmailAddress } from 'shared/types/email-compose';

import {
    fetchJobCandidatesSummary,
    fetchJobInfo,
    fetchUsers,
    getAllEmailAccountInfo,
    getEmailTemplates
} from '../actions';
import { clientCommsEmailAddress } from '../common/email/emails';
import { EmailKind } from '../common/email/header-validator';
import { composeEmailWithValidation, EmailValidationData } from '../email-compose/actions';
import { ComposeEmailWindowData } from '../email-compose/types';
import { Client, EmailAccount, Job, List, ListState, State } from '../state';

interface OwnProps {
    jobId: string;
}

interface ConnectedProps {
    jobCandidatesSummary: Map<string, Map<string, CandidateSummary[]>>;
    emailAccounts: Map<string, EmailAccount>;
    emailTemplates: EmailTemplateView[];
    jobs: OrderedMap<string, Job>;
    listsState: Map<string, ListState>;
    sessionUser: UserData;
    users: Map<string, UserData>;
    clients: List<Client>;
}

interface ConnectedDispatch {
    composeEmailWithValidation: (
        payload: ComposeEmailWindowData,
        emailKind: EmailKind,
        validationData: EmailValidationData
    ) => void;
    fetchJobCandidatesSummary: (jobId: string, stages: string[]) => void;
    fetchJobInfo: (jobId: string) => void;
    fetchUsers: () => void;
    getAllEmailAccountInfo: () => void;
    getEmailTemplates: (group: string) => void;
}

type ClientReminderEmailProps = OwnProps & ConnectedProps & ConnectedDispatch;

const ClientReminderEmailComponent: React.FunctionComponent<ClientReminderEmailProps> = (props) => {
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [anchor, setAnchor] = React.useState(null);

    const {
        jobId,
        jobCandidatesSummary,
        jobs,
        clients,
        emailTemplates,
        emailAccounts,
        sessionUser,
        listsState,
        users
    } = props;

    React.useEffect(() => {
        if (!jobs.get(jobId)) {
            props.fetchJobInfo(jobId);
        }
    }, []);

    const shouldNotRenderComponent =
        !jobs.get(jobId) || !jobs.get(jobId).accountManagerId || jobs.get(jobId).accountManagerId !== sessionUser.id;
    const dataDoesNotExists =
        !jobs.get(jobId) ||
        !clients.list.get(jobs.get(jobId).clientId) ||
        !jobCandidatesSummary.get(jobId) ||
        jobCandidatesSummary.get(jobId).size === 0 ||
        listsState.get('users') !== 'initialized' ||
        emailAccounts.isEmpty();

    React.useEffect(() => {
        if (!shouldNotRenderComponent) {
            if (!emailTemplates) {
                props.getEmailTemplates('client');
            }
            if (emailAccounts.isEmpty()) {
                props.getAllEmailAccountInfo();
            }
            if (!listsState.get('users')) {
                props.fetchUsers();
            }
        }
    }, [shouldNotRenderComponent]);

    React.useEffect(() => {
        if (!shouldNotRenderComponent && !jobCandidatesSummary.get(jobId)) {
            props.fetchJobCandidatesSummary(jobId, [
                awaitingClientStage,
                clientFinalRoundStage,
                clientFirstRoundStage,
                clientMiddleRoundStage
            ]);
        }
    }, [shouldNotRenderComponent, !!jobCandidatesSummary.get(jobId)]);

    if (shouldNotRenderComponent || dataDoesNotExists) {
        return null;
    }

    const handleMenuButtonTap = (event: React.SyntheticEvent<{}>) => {
        event.preventDefault();
        const anchorTarget = event.target as React.ReactInstance;
        setAnchor(anchorTarget);
        setMenuOpen(true);
    };

    const handleMenuRequestClose = () => {
        setMenuOpen(false);
    };

    const handleClick = (candidatesAtClientRound: boolean) => () => {
        setMenuOpen(false);
        const job = jobs.get(jobId);
        const client = clients.list.get(job.clientId);

        const { hiringManagers } = client;
        const account = emailAccounts.get(SubmissionAccount);
        const to: EmailAddress[] = job.submissionSettings.emailTo.map((address) => ({
            address,
            name: hiringManagers.find((hiringManager) => hiringManager.email === address).name.full
        }));

        const accountManager = users.get(job.accountManagerId);
        const assignee = users.get(job.assignee);
        const cc = [
            {
                address: accountManager.email,
                name: accountManager.name.full
            },
            {
                address: assignee.email,
                name: assignee.name.full
            },
            clientCommsEmailAddress
        ];

        const templateKind = candidatesAtClientRound ? 'Client Round Candidates' : 'Client Reminder of Candidates';
        const template = emailTemplates.find((t) => t.kind === templateKind).body;

        const awaitingClientFeedbackCandidates = jobCandidatesSummary.get(jobId).get(awaitingClientStage);
        const clientFinalRoundCandidates = jobCandidatesSummary.get(jobId).get(clientFinalRoundStage);

        const clientFirstRoundStageCandidates = jobCandidatesSummary.get(jobId).get(clientFirstRoundStage);
        const clientMiddleRoundStageCandidates = jobCandidatesSummary.get(jobId).get(clientMiddleRoundStage);
        const clientFirstRoundCandidates =
            clientFirstRoundStageCandidates && clientMiddleRoundStageCandidates
                ? clientFirstRoundStageCandidates.concat(clientMiddleRoundStageCandidates)
                : clientFirstRoundStageCandidates || clientMiddleRoundStageCandidates;

        const personIds = _.uniq(
            (awaitingClientFeedbackCandidates || [])
                .concat(clientFinalRoundCandidates || [])
                .concat(clientFirstRoundCandidates || [])
                .map((c) => c.personId)
        );

        const body = interpolateEmailTemplate(template, {
            awaitingClientFeedbackCandidates,
            client,
            clientFinalRoundCandidates,
            clientFirstRoundCandidates,
            job,
            senderName: assignee.name
        });

        props.composeEmailWithValidation(
            {
                account: { name: account.name.full, address: account.email },
                accountOptions: [{ name: account.name.full, address: account.email }],
                attachments: [],
                body,
                canEditToRecipients: true,
                draftSavedAt: null,
                headers: {
                    bcc: [],
                    cc,
                    subject: '',
                    to
                },
                isClientComm: true,
                jobId,
                personIds,
                threadId: null,
                windowId: `new-email-${jobId}`
            },
            'client',
            {
                client,
                emailAccounts: emailAccounts.valueSeq().toArray(),
                job,
                personContacts: [],
                users: users.valueSeq().toArray()
            }
        );
    };

    return (
        <React.Fragment>
            <Tooltip title="Send reminder email to client about submitted candidates">
                <IconButton onClick={handleMenuButtonTap}>
                    <CommunicationEmail color={white} />
                </IconButton>
            </Tooltip>
            <Popover open={menuOpen} anchorEl={anchor} onRequestClose={handleMenuRequestClose}>
                <Menu desktop={true}>
                    <MenuItem primaryText="Awaiting Feedback Reminder" onClick={handleClick(false)} />
                    <MenuItem primaryText="All Candidates Status Update" onClick={handleClick(true)} />
                </Menu>
            </Popover>
        </React.Fragment>
    );
};

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    composeEmailWithValidation,
    fetchJobCandidatesSummary,
    fetchJobInfo,
    fetchUsers,
    getAllEmailAccountInfo,
    getEmailTemplates
};

const mapStateToProps = (state: State) => ({
    clients: state.clients,
    emailAccounts: state.emailAccounts,
    emailTemplates: state.emailTemplates.get('client'),
    jobCandidatesSummary: state.jobCandidatesSummary,
    jobs: state.jobs,
    listsState: state.listsState,
    sessionUser: state.session.user,
    users: state.users
});

export const ClientReminderEmails = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(ClientReminderEmailComponent);
