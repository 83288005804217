import { useLazyQuery } from '@apollo/client';
import { css } from '@emotion/core';
import {
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
    useTheme
} from '@material-ui/core';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { Map, Set } from 'immutable';
import { orderBy, times } from 'lodash';
import * as moment from 'moment';
import React, { useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';

import { internalClientIds } from 'shared/models/client';
import { awaitingClientStage, clientFirstRoundStage, rocketScreenCompleteStage } from 'shared/models/job-stages';

import { Duration, getDurationOptions } from '../common/duration';
import { PageDialogLink } from '../common/page-dialog-link';
import { getStatsMap, Stat, UserRecruitingStats } from '../common/recruiter-stats';
import { FUNNEL_STATS, FunnelStats, MetricsVariables } from '../graphql/queries/recruiter-stats';
import { usePeriodFunnelCandidates } from '../hooks/use-period-funnel-candidates';
import { JobFilterTypes, jobTypesForFilter } from '../state';
import { DurationSelector } from './duration-selector';
import { FunnelStat } from './funnel-stat';
import { Header } from './header';
import { JobsTypeFilter } from './jobs-type-filter';
import { UserMetrics } from './metrics/user-metrics';
import { TableColumnFilterHeader } from './table-column-filter-header';

const decimalPrecision = 3;

type Column =
    | 'Recruiter'
    | 'Response Rate'
    | 'Outreaches Sent'
    | 'Phone Screens'
    | 'Submits'
    | 'Client Accepts'
    | 'Accepts/Call'
    | 'Accepts/1K Outreach'
    | 'Billing';

const skeletonRowsCount = 10;

const styles = (theme: Theme) => css`
    background: #f4f6f8;
    flex: 1 1 auto;
    padding: 25px 50px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .funnel-stat {
        padding: 5px 0;
    }

    .selectors {
        flex: 0 0 auto;
        text-align: right;
        margin-bottom: 20px;

        .search-field,
        > .MuiInputBase-root {
            margin-left: 15px;

            &:first-child {
                margin-left: 0;
            }

            .MuiOutlinedInput-input {
                padding-top: 14px;
                padding-bottom: 14px;
                background: white;
            }
        }
    }

    .table {
        border: thin solid ${theme.palette.divider};
        border-radius: ${theme.shape.borderRadius}px;
        background: white;

        th:hover {
            .column-action-icon {
                opacity: 1;
            }
        }

        tr:hover {
            background: ${theme.palette.action.hover};
        }

        .MuiTableBody-root {
            .MuiTableRow-root:last-child {
                .MuiTableCell-root {
                    border-bottom: none;
                }
            }
        }

        .table-header-cell {
            display: inline-flex;
            align-items: center;
            cursor: pointer;
        }

        .column-action-icon {
            display: inline-flex;
            align-items: center;
            opacity: 0;
            transition: opacity 200ms;
            margin-left: 5px;
            cursor: pointer;

            .MuiSvgIcon-root {
                font-size: 1.25rem;
                color: ${theme.palette.text.secondary};
            }

            &.visible {
                opacity: 1;
            }
        }
    }

    .stat-main {
        font-weight: 500;
        font-size: 16px;
    }

    .recruiter-name {
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;

        &:hover {
            color: ${theme.palette.primary.main};
        }
    }

    .billing-stats {
        .delta {
            display: none;
        }
    }

    .totals-row {
        .MuiTableCell-root {
            font-weight: 500;
            font-size: 16px;
        }
    }
`;

const columns: Column[] = [
    'Recruiter',
    'Outreaches Sent',
    // 'Response Rate',
    'Phone Screens',
    'Submits',
    'Client Accepts',
    'Accepts/Call',
    // 'Accepts/1K Outreach',
    'Billing'
];

const filterableColumns: Column[] = ['Recruiter'];

// const acceptOutreachRatioFormatter = (val: number) => (Math.ceil(val * 100) / 100).toLocaleString();

export const RecruiterStats: React.FC<{}> = () => {
    const theme = useTheme();
    const durationPeriods = { days: 2, weeks: 2, fortnights: 1, months: 2, quarters: 4, years: 2, allTime: true };
    const durationOpts = getDurationOptions(durationPeriods);
    const defaultOption =
        durationOpts.find(
            (opt) => opt.start === moment().startOf('quarter').valueOf() && opt.label?.match(/^Q\d \d{4}/)
        ) ?? durationOpts[0];

    const [jobTypes, setJobTypes] = useState<JobFilterTypes>('all');
    const [clientTypes, setClientTypes] = useState<'external-clients' | 'all'>('external-clients');
    const [duration, setDuration] = useState<Duration>(defaultOption);
    const [sortCol, setSortCol] = useState<Column>('Recruiter');
    const [sortAsc, setSortAsc] = useState<'asc' | 'desc'>('asc');
    const [filters, setFilters] = useState<Map<Column, Set<string | number>>>(Map());
    const [fetchFunnelStats, { data: funnelData }] = useLazyQuery<FunnelStats, MetricsVariables>(FUNNEL_STATS);
    const [fetchFunnelBaselineStats, { data: funnelBaselineData }] = useLazyQuery<FunnelStats, MetricsVariables>(
        FUNNEL_STATS
    );
    const { setPeriodFunnelCandidatesData } = usePeriodFunnelCandidates();

    useEffect(() => {
        const excludeClientIds = clientTypes === 'external-clients' ? internalClientIds : [];
        const allJobTypes = jobTypesForFilter.get('all');
        const includedJobTypes = jobTypesForFilter.get(jobTypes);
        const excludeJobTypes = allJobTypes.filter((jt) => includedJobTypes.indexOf(jt) === -1);
        const variables = {
            endTime: duration.end,
            excludeClientIds,
            excludeJobTypes,
            startTime: duration.start
        };
        const baselineVariables = {
            endTime: duration.baselineEnd,
            excludeClientIds,
            excludeJobTypes,
            startTime: duration.baselineStart
        };
        fetchFunnelStats({
            variables
        });
        fetchFunnelBaselineStats({
            variables: baselineVariables
        });
    }, [jobTypes, clientTypes, duration]);

    const valueFunc = (col: Column) => (record: UserRecruitingStats) => {
        switch (col) {
            case 'Client Accepts':
                return record.clientAccepts.value;
            case 'Outreaches Sent':
                return record.outreachesSent.value;
            case 'Phone Screens':
                return record.phoneScreens.value;
            case 'Recruiter':
                return record.user.name;
            case 'Submits':
                return record.submits.value;
            case 'Billing':
                return record.billing.value;
            case 'Accepts/Call':
                return record.callAcceptRatio.value;
            case 'Accepts/1K Outreach':
                return record.acceptOutreachRatio.value;
            case 'Response Rate':
                return record.outreachResponseRatio.value;
            default:
                return null;
        }
    };

    const handleSortChange = (col: Column) => () => {
        if (col === sortCol) {
            if (sortAsc === 'asc') {
                setSortAsc('desc');
            } else {
                setSortAsc('asc');
            }
        } else {
            setSortCol(col);
            setSortAsc('desc');
        }
    };

    const handleColumnFilterChange = (filterMenuColumn: Column) => (selected: string[]) => {
        setFilters(
            selected.length === 0 ? filters.remove(filterMenuColumn) : filters.set(filterMenuColumn, Set(selected))
        );
    };

    const handleJobTypesChange = (updated: JobFilterTypes) => {
        setJobTypes(updated);
    };

    const handleClientsTypeChange = (event: React.ChangeEvent<{ value: any }>) => {
        setClientTypes(event.target.value);
    };

    const handleStatClick = (label: string, record: UserRecruitingStats, stat: Stat, stage: string) => () => {
        if (stat?.value) {
            setPeriodFunnelCandidatesData({ label, period: duration, user: record.user, stage });
        }
    };

    const moneyFormat = (val: number) => `$${val.toLocaleString()}`;

    const skeletonRows = times(skeletonRowsCount).map((i) => {
        const cells = times(columns.length).map((j) => (
            <TableCell key={j}>
                <Skeleton variant="rect" width="120px" />
            </TableCell>
        ));
        return <TableRow key={i}>{cells}</TableRow>;
    });

    let filterValues: Array<string | number> = [];
    let sortedData: UserRecruitingStats[];
    let rowsData: UserRecruitingStats[];
    let totalsRow;

    if (funnelData) {
        const { dataMap: initialDataMap } = getStatsMap(funnelData, funnelBaselineData);

        const initialSortedData = orderBy(
            Array.from(initialDataMap.values()).filter(
                (row) =>
                    row.submits?.value ||
                    row.clientAccepts?.value ||
                    row.phoneScreens?.value ||
                    row.outreachesSent?.value ||
                    row.billing?.value
            ),
            [valueFunc(sortCol), valueFunc('Recruiter')],
            [sortAsc, 'asc']
        );

        filterValues = initialSortedData?.map((data) => data.user.name) ?? [];

        const selectedUsers = filters?.get('Recruiter')?.toArray() ?? [];
        const selectedFunnelData =
            selectedUsers.length > 0
                ? {
                      funnel: funnelData?.funnel?.filter((f) => selectedUsers.indexOf(f.user.name) !== -1) ?? []
                  }
                : funnelData;
        const selectedFunnelBaselineData =
            selectedUsers.length > 0
                ? {
                      funnel:
                          funnelBaselineData?.funnel?.filter((f) => selectedUsers?.indexOf(f.user.name) !== -1) ?? []
                  }
                : funnelBaselineData;

        const { dataMap, totals } = getStatsMap(selectedFunnelData, selectedFunnelBaselineData);

        sortedData = orderBy(
            Array.from(dataMap.values()).filter(
                (row) =>
                    row.submits?.value ||
                    row.clientAccepts?.value ||
                    row.phoneScreens?.value ||
                    row.outreachesSent?.value ||
                    row.billing?.value
            ),
            [
                (record: UserRecruitingStats) =>
                    valueFunc(sortCol)(record) === undefined || valueFunc(sortCol)(record) === null,
                valueFunc(sortCol),
                valueFunc('Recruiter')
            ],
            ['asc', sortAsc, 'asc']
        );

        rowsData = sortedData.filter((c) => {
            return filters.reduce((res, val, col) => res && val.has(valueFunc(col)(c)), true);
        });

        const avgAccept = (totals.phoneScreensSum ? totals.clientAcceptsSum / totals.phoneScreensSum : 0).toPrecision(
            decimalPrecision
        );
        // const avgOutreachConversion = (totals.acceptOutreachRecruiters
        //     ? totals.acceptOutreachRatioSum / totals.acceptOutreachRecruiters
        //     : 0
        // ).toPrecision(decimalPrecision - 1);
        // const avgOutreachResponseRate = (totals.outreachResponseRatio
        //     ? totals.outreachResponseRatio / totals.outreachResponseRatioRecruiters
        //     : 0
        // ).toPrecision(decimalPrecision - 1);
        totalsRow = (
            <TableRow className="totals-row">
                <TableCell>
                    <span className="recruiter-name">Total</span>
                </TableCell>
                <TableCell>{totals.outreachesSum.toLocaleString()}</TableCell>
                {/* <TableCell>{avgOutreachResponseRate}</TableCell> */}
                <TableCell>{totals.phoneScreensSum.toLocaleString()}</TableCell>
                <TableCell>{totals.submitsSum.toLocaleString()}</TableCell>
                <TableCell>{totals.clientAcceptsSum.toLocaleString()}</TableCell>
                <TableCell>{avgAccept}</TableCell>
                {/* <TableCell>{avgOutreachConversion}</TableCell> */}
                <TableCell> - </TableCell>
            </TableRow>
        );
    }

    const { baselinePeriod, period } = duration;

    const rows = !rowsData
        ? skeletonRows
        : rowsData.map((record) => {
              const { username } = record.user;
              const recruiterName = <span className="recruiter-name">{record.user.name}</span>;
              const userLink = (
                  <PageDialogLink
                      url={`/user/${username}/metrics`}
                      Component={UserMetrics}
                      componentProps={{ username }}
                  >
                      {recruiterName}
                  </PageDialogLink>
              );
              const phoneCallClickHandler = handleStatClick(
                  'Phone calls',
                  record,
                  record.phoneScreens,
                  rocketScreenCompleteStage
              );

              const acceptsClickHandler = handleStatClick(
                  'Client Accepts',
                  record,
                  record.clientAccepts,
                  clientFirstRoundStage
              );

              const submitsClickHandler = handleStatClick('Submissions', record, record.submits, awaitingClientStage);

              return (
                  <TableRow key={record.user.id}>
                      <TableCell>{userLink}</TableCell>
                      <TableCell>
                          <FunnelStat
                              data={record.outreachesSent}
                              description="outreach emails sent"
                              period={period}
                              baselinePeriod={baselinePeriod}
                          />
                      </TableCell>
                      {/* tslint:disable-next-line: jsx-no-multiline-js */}
                      {/* <TableCell>
                          <FunnelStat
                              data={record.outreachResponseRatio}
                              description="responses per 100 outreach emails"
                              period={period}
                              baselinePeriod={baselinePeriod}
                          />
                      </TableCell> */}
                      <TableCell>
                          <FunnelStat
                              data={record.phoneScreens}
                              description="phone calls"
                              period={period}
                              baselinePeriod={baselinePeriod}
                              onClick={phoneCallClickHandler}
                          />
                      </TableCell>
                      <TableCell>
                          <FunnelStat
                              data={record.submits}
                              description="submissions sent"
                              period={period}
                              baselinePeriod={baselinePeriod}
                              onClick={submitsClickHandler}
                          />
                      </TableCell>
                      <TableCell>
                          <FunnelStat
                              data={record.clientAccepts}
                              description="candidates accepted by clients"
                              period={period}
                              baselinePeriod={baselinePeriod}
                              onClick={acceptsClickHandler}
                          />
                      </TableCell>
                      <TableCell>
                          <FunnelStat
                              data={record.callAcceptRatio}
                              description="accepts per call"
                              period={period}
                              baselinePeriod={baselinePeriod}
                          />
                      </TableCell>
                      {/* tslint:disable-next-line: jsx-no-multiline-js */}
                      {/* <TableCell>
                          <FunnelStat
                              data={record.acceptOutreachRatio}
                              description="accepts per 1000 outreach"
                              period={period}
                              baselinePeriod={baselinePeriod}
                              formatter={acceptOutreachRatioFormatter}
                          />
                      </TableCell> */}
                      <TableCell>
                          <span className="billing-stats">
                              <FunnelStat
                                  data={record.billing}
                                  description="in Billings"
                                  period={period}
                                  baselinePeriod={baselinePeriod}
                                  formatter={moneyFormat}
                              />
                          </span>
                      </TableCell>
                  </TableRow>
              );
          });

    const headers = columns.map((col) => {
        const selected: Array<string | number> = filters?.get(col)?.toArray() ?? [];

        const filterIcon =
            filterableColumns.indexOf(col) === -1 ? null : (
                <TableColumnFilterHeader
                    values={filterValues}
                    selected={selected}
                    onSelect={handleColumnFilterChange(col)}
                />
            );

        const sortIcon = sortAsc === 'asc' ? <ArrowUpward /> : <ArrowDownward />;
        const sort = (
            <span className={`column-action-icon ${sortCol === col ? 'visible' : ''}`} onClick={handleSortChange(col)}>
                {sortIcon}
            </span>
        );
        return (
            <TableCell key={col}>
                <span className="table-header-cell">
                    <span onClick={handleSortChange(col)}>{col}</span>
                    {sort}
                    {filterIcon}
                </span>
            </TableCell>
        );
    });

    return (
        <DocumentTitle title="Recruiting Stats">
            <div id="container">
                <Header title="Recruiting Stats" />
                <div css={styles(theme)}>
                    <div className="selectors">
                        <DurationSelector selected={duration} onSelect={setDuration} periods={durationPeriods} />
                        <JobsTypeFilter selectedFilter={jobTypes} onSelectFilter={handleJobTypesChange} />
                        <Select value={clientTypes} onChange={handleClientsTypeChange} variant="outlined">
                            <MenuItem value={'external-clients'}>External Clients</MenuItem>
                            <MenuItem value={'all'}>Include Rocket Jobs</MenuItem>
                        </Select>
                    </div>
                    <TableContainer className="table">
                        <Table stickyHeader={true}>
                            <TableHead>
                                <TableRow>{headers}</TableRow>
                            </TableHead>
                            <TableBody>
                                {rows}
                                {totalsRow}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </DocumentTitle>
    );
};
