import { sortBy } from 'lodash';
import React from 'react';

import { SelectField, TextField } from '../core-ui/form-fields';
import { JobFormData } from '../graphql/queries/job-form';
import { JobTypes } from '../state';
import { useFormContext } from './form-context';

export const JobFormContract: React.FC = () => {
    const { data, onChange, onFieldChange, validateFieldExists, getError, disabled } = useFormContext<JobFormData>();

    const jobTypeOpts =
        sortBy(
            JobTypes.map((val, key) => ({ label: val.label, value: key }))
                .valueSeq()
                .toArray(),
            (v) => v.label
        ) ?? [];

    // Define the maximum total percentage
    const TOTAL_PERCENTAGE = 15;

    const handlePercentageChange = (key: 'accountManagerPercentage' | 'recruiterPercentage') => (value: number) => {
        const adjustedValue = Math.max(0, Math.min(TOTAL_PERCENTAGE, value));
        const otherKey = key === 'accountManagerPercentage' ? 'recruiterPercentage' : 'accountManagerPercentage';
        const adjustedOtherValue = TOTAL_PERCENTAGE - adjustedValue;
        const finalOtherValue = Math.max(0, Math.min(TOTAL_PERCENTAGE, adjustedOtherValue));
        onChange({ [otherKey]: finalOtherValue, [key]: adjustedValue });
    };

    const monthlyFeesField =
        data.jobType === 'RPO' ? (
            <TextField
                label="Monthly Fees"
                value={data.monthlyFees}
                onChange={onFieldChange('monthlyFees')}
                validate={validateFieldExists('monthlyFees')}
                errorText={getError('monthlyFees')}
                disabled={disabled}
            />
        ) : null;

    return (
        <div>
            <div className="job-field-row">
                <SelectField
                    label="Job Type"
                    value={data.jobType}
                    onChange={onFieldChange('jobType')}
                    validate={validateFieldExists('jobType')}
                    errorText={getError('jobType')}
                    disabled={disabled}
                    options={jobTypeOpts}
                />
            </div>
            <div className="job-field-row">
                {monthlyFeesField}
                <TextField
                    label="Placement Fees"
                    value={data.placementFees}
                    onChange={onFieldChange('placementFees')}
                    validate={validateFieldExists('placementFees')}
                    errorText={getError('placementFees')}
                    disabled={disabled}
                />
            </div>
            <div className="job-field-row">
                <TextField
                    label="Account Manager Percentage"
                    value={data.accountManagerPercentage}
                    onChange={handlePercentageChange('accountManagerPercentage')}
                    errorText={getError('accountManagerPercentage')}
                    disabled={disabled}
                    type="number"
                />
                <TextField
                    label="Recruiter Percentage"
                    value={data.recruiterPercentage}
                    onChange={handlePercentageChange('recruiterPercentage')}
                    errorText={getError('recruiterPercentage')}
                    disabled={disabled}
                    type="number"
                />
            </div>
        </div>
    );
};
