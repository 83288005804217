import { FlatButton } from 'material-ui';
import * as React from 'react';

import { SimilaritySearch, SimilaritySearchProps } from './similarity-search';

interface SimilaritySearchButtonProps extends Partial<SimilaritySearchProps> {}

interface SimilaritySearchButtonState {
    dialogOpen: boolean;
}

export class SimilaritySearchButton extends React.Component<SimilaritySearchButtonProps, SimilaritySearchButtonState> {
    constructor(props: SimilaritySearchButtonProps) {
        super(props);
        this.state = {
            dialogOpen: false
        };
    }

    handleOpenSimilaritySearch = () => {
        this.setState({ dialogOpen: true });
    };

    handleCloseSimilaritySearch = () => {
        this.setState({ dialogOpen: false });
    };

    render() {
        const similaritySearch = this.state.dialogOpen ? (
            <SimilaritySearch {...this.props} onClose={this.handleCloseSimilaritySearch} />
        ) : null;
        return (
            <div className="job-searches-footer">
                <FlatButton
                    onClick={this.handleOpenSimilaritySearch}
                    className="similarity-button"
                    icon={<i className="fas fa-magic" />}
                />
                {similaritySearch}
            </div>
        );
    }
}
