// Define the ScoreData interface
interface ScoreData {
    score: number;
    personId: string;
    jobId: string;
}

// Extend ScoringMetrics to include weighted metrics
export interface ScoringMetrics {
    precision: number;
    recall: number;
    f1Score: number;
    weightedPrecision: number;
    weightedRecall: number;
    weightedF1Score: number;
}

// Hardcoded mapping of stage scores to weights based on business impact
const stageWeights: { [score: number]: number } = {
    5.0: 5, // Hired / Offer
    4.5: 4, // Final Round, No Offer
    4.0: 3, // Second Round, Does Not Make It to Final Round
    3.5: 2, // Accepted by Hiring Manager, but Does Not Make It to Second Round
    3.0: 1, // Submitted to Hiring Manager, Not Accepted
    2.5: 1, // Recruiter Screen Complete, Not Submitted
    1.5: 1 // Responds to the Email (But Not Screened by Recruiter)
};

// Weight function (linear weights)
function weightFunction(delta: number, maxDelta: number): number {
    return Math.max(0, 1 - delta / maxDelta);
}

function getClosestStageScore(predictedScore: number, stageScores: number[]): number {
    return stageScores.reduce((prev, curr) => {
        return Math.abs(curr - predictedScore) < Math.abs(prev - predictedScore) ? curr : prev;
    });
}

export function getAllStageScores(scores: ScoreData[]): number[] {
    const uniqueScores = scores.reduce((acc, a) => {
        if (!acc.includes(a.score)) {
            acc.push(a.score);
        }
        return acc;
    }, [] as number[]);
    uniqueScores.sort((a, b) => a - b);
    return uniqueScores;
}

// The main function to calculate metrics
export function getMetrics(actual: ScoreData[], predicted: ScoreData[], errorMargin: number, stageScores: number[]) {
    // Define the possible score range
    const minPossibleScore = 1.5;
    const maxPossibleScore = 5.0;
    const maxDelta = maxPossibleScore - minPossibleScore;

    // Create a map to easily look up predicted scores by personId and jobId
    const predictedMap = new Map<string, number>();

    predicted.forEach((p) => {
        const key = `${p.personId}-${p.jobId}`;
        predictedMap.set(key, p.score);
    });

    // Filter to matching scores and collect stage weights
    const matchedActualScores: number[] = [];
    const matchedPredictedScores: number[] = [];
    const matchedWeights: number[] = [];

    actual.forEach((a) => {
        const key = `${a.personId}-${a.jobId}`;
        if (predictedMap.has(key)) {
            matchedActualScores.push(a.score);
            matchedPredictedScores.push(predictedMap.get(key)!);
            const stageWeight = stageWeights[a.score] || 1; // Default weight is 1
            matchedWeights.push(stageWeight);
        }
    });

    // Calculate the metrics
    const n = matchedActualScores.length;

    if (n === 0) {
        return null;
    }

    // Adjust predicted values based on error margin
    const adjustedPredictedScores: number[] = matchedPredictedScores.map((p, i) => {
        const a = matchedActualScores[i];
        return Math.abs(a - p) <= errorMargin ? a : p;
    });

    // Initialize counts for standard metrics
    let tp = 0;
    let fp = 0;
    let fn = 0;

    // Initialize sums for weighted metrics
    let weightedTP = 0;
    let weightedFP = 0;
    let weightedFN = 0;

    // Initialize confusion matrix using Map
    const confusionMatrix = new Map<number, Map<number, number>>();

    // Initialize the confusion matrix with zeros
    stageScores.forEach((actualScore) => {
        const rowMap = new Map<number, number>();
        stageScores.forEach((predictedScore) => {
            rowMap.set(predictedScore, 0);
        });
        confusionMatrix.set(actualScore, rowMap);
    });

    // Loop over matched scores to calculate metrics and populate confusion matrix
    for (let i = 0; i < n; i++) {
        const a = matchedActualScores[i];
        const pOriginal = adjustedPredictedScores[i];
        const originalP = matchedPredictedScores[i];
        const delta = Math.abs(a - originalP);
        const stageWeight = matchedWeights[i];

        // Calculate the weight for this instance
        const misclassificationWeight = weightFunction(delta, maxDelta);
        const totalWeight = misclassificationWeight * stageWeight;

        // Map predicted score to the closest stage score
        const p = getClosestStageScore(pOriginal, stageScores);

        // Update weighted confusion matrix
        const actualRow = confusionMatrix.get(a)!;
        const currentValue = actualRow.get(p)!;
        actualRow.set(p, currentValue + totalWeight);

        // Update counts for metrics
        if (a === p) {
            // True Positive: prediction matches the actual stage
            tp++;
            weightedTP += totalWeight;
        } else if (p > a) {
            // False Positive: predicted progress too high
            fp++;
            weightedFP += totalWeight;
        } else if (p < a) {
            // False Negative: predicted progress too low
            fn++;
            weightedFN += totalWeight;
        }
    }

    // Calculate standard Precision, Recall, and F1 Score
    const precision = tp / (tp + fp);
    const recall = tp / (tp + fn);
    const f1Score = (2 * precision * recall) / (precision + recall);

    // Calculate weighted Precision, Recall, and F1 Score
    const weightedPrecision = weightedTP / (weightedTP + weightedFP);
    const weightedRecall = weightedTP / (weightedTP + weightedFN);
    const weightedF1Score = (2 * weightedPrecision * weightedRecall) / (weightedPrecision + weightedRecall);

    // Collect the statistics
    const statistics: ScoringMetrics = {
        f1Score,
        precision,
        recall,
        weightedF1Score,
        weightedPrecision,
        weightedRecall
    };

    // Descriptions for each metric
    const scoringMetricsInfo: { [K in keyof ScoringMetrics]: string } = {
        f1Score: `F1 Score is the harmonic mean of precision and recall. It combines both precision and recall into a single value.`,
        precision: `Precision measures the proportion of predictions that are correctly within the acceptable error margin (${errorMargin}) relative to all predictions within this range.`,
        recall: `Recall measures the proportion of actual cases within the acceptable error margin (${errorMargin}) that are correctly predicted by the model.`,
        weightedF1Score: `Weighted F1 Score is the harmonic mean of weighted precision and weighted recall, reflecting both the correctness and the business impact of predictions.`,
        weightedPrecision: `Weighted Precision accounts for the severity of misclassifications and the business impact of different stages by assigning higher weights to critical stages.`,
        weightedRecall: `Weighted Recall accounts for the severity of misclassifications and the importance of correctly predicting critical stages.`
    };

    // Thresholds for Metrics
    const scoringMetricsThresholds: {
        [K in keyof ScoringMetrics]: { min: number; max: number; good: number; bad: number };
    } = {
        f1Score: { min: 0, max: 1, good: 0.8, bad: 0.5 },
        precision: { min: 0, max: 1, good: 0.8, bad: 0.5 },
        recall: { min: 0, max: 1, good: 0.8, bad: 0.5 },
        weightedF1Score: { min: 0, max: 1, good: 0.8, bad: 0.5 },
        weightedPrecision: { min: 0, max: 1, good: 0.8, bad: 0.5 },
        weightedRecall: { min: 0, max: 1, good: 0.8, bad: 0.5 }
    };

    // Labels for Metrics
    const scoringMetricsLabels: { [K in keyof ScoringMetrics]: string } = {
        f1Score: 'F1 Score',
        precision: 'Precision',
        recall: 'Recall',
        weightedF1Score: 'Weighted F1 Score',
        weightedPrecision: 'Weighted Precision',
        weightedRecall: 'Weighted Recall'
    };

    // Return statistics, weighted confusion matrix, and additional information
    return {
        confusionMatrix,
        scoringMetricsInfo,
        scoringMetricsLabels,
        scoringMetricsThresholds,
        statistics
    };
}
