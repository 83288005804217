import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { JobSearches } from '../components/job-searches';

interface RouterProps {
    id: string;
}

const JobSearchesPageComponent: React.SFC<RouteComponentProps<RouterProps>> = ({ match }) => (
    <JobSearches id={match.params.id} />
);

export const JobSearchesPage = withRouter(JobSearchesPageComponent);
