import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';

import * as JobStages from 'shared/models/job-stages';

import { CandidateWithPerson, Job, State } from '../state';

interface OwnProps {
    candidates: CandidateWithPerson[];
    clientName: string;
    sectionName: string;
    job: Job;
    showLabels: boolean;
}

interface ConnectedProps {
    jobStages: JobStages.JobStageData[];
}

type WeeklyUpdateCandidatesProps = OwnProps & ConnectedProps;

const headerStyle = {
    color: '#666',
    fontWeight: 'bold' as any
};

const linkStyle = {
    color: '#3367d6',
    fontWeight: 'bold' as any,
    textDecoration: 'underline'
};

const sectionStyle = {
    marginBottom: '25px'
};

const ulStyle = {
    marginBottom: '20px',
    marginTop: '0px'
};

const liStyle = {
    lineHeight: '1.5'
};

class WeeklyUpdateCandidatesComponent extends React.Component<WeeklyUpdateCandidatesProps, undefined> {
    render() {
        const candidates = _.orderBy(
            this.props.candidates,
            [(c) => this.props.jobStages.find((s) => s.name === c.candidate.stage).id, 'candidate.disqualified'],
            ['desc', 'asc']
        );

        const candidatesList = candidates.map((c) => {
            const linkedInLinks = c.profileUrls.map((p) => p.url).filter((s) => s.includes('linkedin.com/in'));
            let candidateName = <span>{c.person.name.full}</span>;

            if (linkedInLinks.length > 0) {
                candidateName = (
                    <a style={linkStyle} href={'https://' + linkedInLinks[0]}>
                        {c.person.name.full}
                    </a>
                );
            }

            let candidateStatusText = '';
            let skipCandidate = false;

            // 'rocket_screen_scheduled',
            // 'awaiting_client_feedback',
            // 'client_first_round',
            // 'client_second_round',
            // 'offer',
            // 'hired'

            if (!c.candidate.disqualified) {
                // Candidate is still in the running
                switch (c.candidate.stage) {
                    case JobStages.hiredStage:
                        candidateStatusText = `Hired`;
                        break;
                    case JobStages.offerStage:
                        candidateStatusText = `Moved to offer stage`;
                        break;
                    case JobStages.rocketScreenScheduledStage:
                        candidateStatusText = `Scheduled for Rocket phone screen`;
                        break;
                    case JobStages.rocketScreenCompleteStage:
                        candidateStatusText = `Rocket phone screen completed`;
                        break;
                    case JobStages.awaitingClientStage:
                        candidateStatusText = `Awaiting feedback from ${this.props.clientName}`;
                        break;
                    case JobStages.clientFirstRoundStage:
                    case JobStages.clientMiddleRoundStage:
                        candidateStatusText = `At ${this.props.clientName} phone screen stage`;
                        break;
                    case JobStages.clientFinalRoundStage:
                        candidateStatusText = `At ${this.props.clientName} onsite stage`;
                        break;
                    default:
                        candidateStatusText = '';
                }
            } else {
                // Candidate is disqualified
                switch (c.candidate.disqualReason) {
                    case 'candidate_declined_compensation_issue':
                        candidateStatusText = 'Not aligned on compensation';
                        break;
                    case 'candidate_declined_withdrawing':
                        candidateStatusText = 'Withdrawing from consideration';
                        break;
                    case 'client_declined_not_qualified':
                        candidateStatusText = `Passed on by ${this.props.clientName}`;
                        break;
                    case 'client_declined_compensation_mismatch':
                        candidateStatusText = 'Not aligned on compensation';
                        break;
                    case 'client_declined_overqualified':
                        candidateStatusText = 'Overqualified for the position';
                        break;
                    case 'client_declined_pulled_offer':
                        candidateStatusText = 'Offer rescinded';
                        break;
                    default:
                        skipCandidate = true;
                }
            }

            if (this.props.showLabels) {
                return skipCandidate ? null : (
                    <li style={liStyle} key={`${c.candidate.jobId} ${c.person.id}`}>
                        {candidateName} - {candidateStatusText}
                    </li>
                );
            } else {
                return (
                    <li style={liStyle} key={`${c.candidate.jobId} ${c.person.id}`}>
                        {candidateName}
                    </li>
                );
            }
        });

        if (candidatesList.length === 0) {
            return <span />;
        }

        return (
            <div style={sectionStyle}>
                <div style={headerStyle}>{this.props.sectionName}</div>
                <ul style={ulStyle}>{candidatesList}</ul>
            </div>
        );
    }
}

const mapStateToProps = (state: State) => ({
    jobStages: state.appConstants.constants.jobStages
});

export const WeeklyUpdateCandidates = connect<ConnectedProps, {}, OwnProps>(mapStateToProps)(
    WeeklyUpdateCandidatesComponent
);
