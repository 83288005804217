import { gql, useLazyQuery } from '@apollo/client';
import { uniq } from 'lodash';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { UserStatus } from 'shared/models/user';

interface User {
    id: string;
    name: string;
    email: string;
    status: UserStatus;
}

interface UsersContextType {
    users: User[];
    loading: boolean;
    error: any | null;
    fetchUsers: (userIds?: string[]) => void;
}

const UsersContext = createContext<UsersContextType | undefined>(undefined);

const USERS_LIMITED_DATA = gql`
    query UsersLimitedData($userIds: [String!]) {
        users(where: { _or: [{ status: { _eq: "active" } }, { id: { _in: $userIds } }] }, order_by: { name: asc }) {
            id
            name(path: "full")
            email
            status
        }
    }
`;

const UsersProvider: React.FC = ({ children }) => {
    const [getActiveUsers, { data, loading, error }] = useLazyQuery<{ users: User[] }, { userIds: string[] }>(
        USERS_LIMITED_DATA
    );
    const [requestedUserIds, setRequestedUserIds] = useState<string[]>([]);

    const fetchUsers = useCallback(
        (userIds?: string[]) => {
            const updatedUserIds = uniq([...requestedUserIds, ...(userIds ?? [])]);
            setRequestedUserIds(updatedUserIds);
            if (!loading) {
                getActiveUsers({ variables: { userIds: updatedUserIds } });
            }
        },
        [requestedUserIds, loading, getActiveUsers]
    );

    const users = data?.users ?? [];

    return <UsersContext.Provider value={{ users, loading, error, fetchUsers }}>{children}</UsersContext.Provider>;
};

const useUsers = (specificUserIds?: string[]): UsersContextType => {
    const context = useContext(UsersContext);
    if (context === undefined) {
        throw new Error('useUsers must be used within a UsersProvider');
    }

    const userIds = useMemo(() => (specificUserIds ?? []).filter((id) => id), [specificUserIds]);

    useEffect(() => {
        // Fetch if users is empty or if there are missing users
        const missingUsers = userIds.filter((id) => !context.users.some((user) => user.id === id));
        if (!context.loading && (context.users.length === 0 || missingUsers.length > 0)) {
            context.fetchUsers(missingUsers.length > 0 ? missingUsers : userIds);
        }
    }, [userIds, context.loading, context.users, context.fetchUsers]);

    return context;
};

export { UsersProvider, useUsers };
