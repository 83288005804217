import { ApolloProvider } from '@apollo/client';
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { BrowserRouter as Router } from 'react-router-dom';

import { RouteChangeTracker } from '../common/route-change-tracker';
import { AppVersionChecker } from '../components/app-version-info';
import { Drawer } from '../containers/drawer';
import { Toaster } from '../containers/toaster';
import { ConfirmationModal } from '../core-ui/confirmation-modal';
import { ProfileConflictAlert } from '../core-ui/profile-conflict-alert';
import { EmailComposeLayer } from '../email-compose/components/email-compose-layer';
import { client } from '../graphql/apollo-client';
import { AIProfileScoreDetailsProvider } from '../hooks/use-ai-profile-score-details';
import { SlidesProvider } from '../hooks/use-candidate-slides';
import { CompanySimilarityProvider } from '../hooks/use-company-similarity';
import { CrunchbaseDataProvider } from '../hooks/use-crunchbase-data';
import { ModalProvider } from '../hooks/use-modal';
import { NotificationsDataProvider } from '../hooks/use-notifications-data';
import { PageDialogProvider } from '../hooks/use-page-dialog';
import { PeriodFunnelCandidatesProvider } from '../hooks/use-period-funnel-candidates';
import { ProfileAdornmentsProvider } from '../hooks/use-profile-adornment';
import { PromptInfoProvider } from '../hooks/use-prompt-info';
import { SnackbarProvider } from '../hooks/use-snackbar';
import { TimepickerProvider } from '../hooks/use-time-picker';
import { UserDataChangesProvider } from '../hooks/use-user-data-changes';
import { UsersProvider } from '../hooks/use-users';
import { Routes } from './routes';

export const Main = () => (
    <div>
        <ApolloProvider client={client()}>
            <UsersProvider>
                <UserDataChangesProvider>
                    <NotificationsDataProvider>
                        <SnackbarProvider>
                            <ModalProvider>
                                <TimepickerProvider>
                                    <DocumentTitle title="Titan">
                                        <Router>
                                            <PromptInfoProvider>
                                                <AIProfileScoreDetailsProvider>
                                                    <CompanySimilarityProvider>
                                                        <CrunchbaseDataProvider>
                                                            <ProfileAdornmentsProvider>
                                                                <SlidesProvider>
                                                                    <PeriodFunnelCandidatesProvider>
                                                                        <PageDialogProvider>
                                                                            <div>
                                                                                <Drawer />
                                                                                <Routes />
                                                                                <EmailComposeLayer />
                                                                                <RouteChangeTracker />
                                                                            </div>
                                                                        </PageDialogProvider>
                                                                    </PeriodFunnelCandidatesProvider>
                                                                </SlidesProvider>
                                                            </ProfileAdornmentsProvider>
                                                        </CrunchbaseDataProvider>
                                                    </CompanySimilarityProvider>
                                                </AIProfileScoreDetailsProvider>
                                            </PromptInfoProvider>
                                        </Router>
                                    </DocumentTitle>
                                    <Toaster />
                                    <ConfirmationModal />
                                    <AppVersionChecker />
                                </TimepickerProvider>
                                <ProfileConflictAlert />
                            </ModalProvider>
                        </SnackbarProvider>
                    </NotificationsDataProvider>
                </UserDataChangesProvider>
            </UsersProvider>
        </ApolloProvider>
    </div>
);
