import {
    Action,
    ExtensionOutOfNetwork,
    ReceiveEmailAccountCreated,
    ReceiveInitialOutreachForJob,
    ReceiveMoveInGmail,
    ReceiveNewClient,
    ReceiveNewJob,
    RequestEmailAccountCreate,
    RequestMoveInGmail,
    RequestOutreachForJob,
    RequestToasterClose,
    RequestToasterOpen
} from '../actions';
import { Toast } from '../state';

const maxAutoHideDuration = 86400000;

const autoHideDuration = 30000;

const initialState: Toast = {
    autoHideDuration,
    message: '',
    open: false
};

export function toast(state = initialState, action: Action) {
    switch (action.type) {
        case RequestToasterClose:
            return Object.assign({}, state, { open: false });
        case RequestToasterOpen:
            return {
                autoHideDuration:
                    action.payload.autoHideDuration === -1
                        ? null
                        : (action.payload.autoHideDuration ?? autoHideDuration),
                message: action.payload.message,
                open: true
            };
        case ReceiveNewClient:
            return action.error
                ? state
                : Object.assign({}, state, { message: 'New client created', open: true, autoHideDuration });
        case ReceiveNewJob:
            return action.error
                ? state
                : Object.assign({}, state, { message: 'New job created', open: true, autoHideDuration });
        case RequestEmailAccountCreate:
            return Object.assign({}, state, {
                autoHideDuration,
                message: 'Creating new email account',
                open: true
            });
        case ReceiveEmailAccountCreated:
            const message = action.error
                ? action.error === 'Cannot add a job to a @getrocket email'
                    ? 'Cannot add a job to a @getrocket email'
                    : 'Error adding email'
                : `New email account created`;
            return Object.assign({}, state, {
                autoHideDuration,
                message,
                open: true
            });
        case RequestOutreachForJob:
            return Object.assign({}, state, { message: 'Sequence queuing', open: true });
        case ReceiveInitialOutreachForJob:
            if (action.payload.success) {
                return Object.assign({}, state, { message: 'Sequence queued', open: true });
            } else {
                return Object.assign({}, state, { message: 'Sequence failed to be queued', open: true });
            }
        case RequestMoveInGmail:
            if (action.payload.action === 'archive') {
                return Object.assign({}, state, { message: 'Archiving thread', open: true, autoHideDuration });
            } else if (action.payload.action === 'inbox') {
                return Object.assign({}, state, { message: 'Moving thread to inbox', open: true, autoHideDuration });
            } else {
                return state;
            }
        case ReceiveMoveInGmail:
            if (action.payload.success) {
                return Object.assign({}, state, { message: 'Moved thread', open: true, autoHideDuration });
            } else {
                return Object.assign({}, state, {
                    autoHideDuration,
                    message: 'Failed to move email thread',
                    open: true
                });
            }
        case ExtensionOutOfNetwork:
            return Object.assign({}, state, {
                autoHideDuration: maxAutoHideDuration,
                message: 'Out of Network Profile',
                open: true
            });
        default:
            return state;
    }
}
