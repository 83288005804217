import { useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Theme,
    Tooltip,
    Typography,
    useTheme
} from '@material-ui/core';
import { ArrowDownward, ArrowUpward, OpenInNew } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { Map } from 'immutable';
import { flatten, orderBy, times, uniq } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import {
    diversityOptToString,
    JobDiversity,
    JobFilter,
    JobFilterField,
    JobStatus,
    JobVisa,
    visaOptToString
} from 'shared/models/job';
import { hasRole } from 'shared/models/user';

import {
    addFilter,
    experienceText,
    isJobPassingFilters,
    setFilterFieldValues,
    tableFilterPresets
} from '../common/job';
import { PageDialogLink } from '../common/page-dialog-link';
import { SearchTextField } from '../common/search-text-field';
import { ClientCandidates } from '../containers/client-candidates';
import { JobCandidatesBoard } from '../containers/job-candidates-board';
import { HOT_CLIENTS } from '../graphql/queries/clients';
import { JOBS_WITH_DETAILS, JobWithDetails, NEW_JOBS } from '../graphql/queries/jobs-table';
import { useLocalStorage } from '../hooks/use-local-storage';
import { useSession } from '../hooks/use-session';
import { JobFilterTypes, JobStatusFilter, jobStatusForFilter, JobType, jobTypesForFilter } from '../state';
import { JobDetails } from './job-details';
import { JobsFilterActions } from './jobs-filter-actions';
import { JobsFilters } from './jobs-filters';
import { JobsStatusFilters } from './jobs-table-status-filters';
import { JobsTypeFilter } from './jobs-type-filter';
import { TableColumnFilterHeader } from './table-column-filter-header';
import { TableColumnsSelector } from './table-columns-selector';

const styles = (theme: Theme) => css`
    background: #f4f6f8;
    flex: 1 1 auto;
    padding: 25px 50px;
    display: flex;
    flex-direction: column;

    .filters {
        display: flex;
        flex: 0 0 auto;
        justify-content: space-between;
        padding: 0;
        margin-bottom: 20px;

        .right {
            display: flex;
            align-items: flex-start;
        }

        .search {
            padding: 3px 0;

            .MuiInputBase-input {
                width: 10ch;
                &:focus {
                    width: 16ch;
                }
            }
        }
    }

    .MuiPaper-root {
        display: flex;
        overflow: auto;
        flex-direction: column;
        justify-content: space-between;
        .MuiTableContainer-root {
            &::-webkit-scrollbar {
                border-left: thin solid ${theme.palette.divider};
                border-top: thin solid ${theme.palette.divider};
            }
            &::-webkit-scrollbar:vertical {
                border-left: thin solid ${theme.palette.divider};
            }
            .MuiTableRow-root:last-child {
                td.MuiTableCell-root.MuiTableCell-body {
                    border-bottom: none;
                }
            }
        }
    }

    .table {
        th:hover {
            .column-action-icon {
                opacity: 1;
            }
        }

        tr {
            cursor: pointer;

            &:hover {
                background: ${theme.palette.action.hover};
            }
        }

        .table-header-cell {
            display: inline-flex;
            align-items: center;
            cursor: pointer;
        }

        .column-action-icon {
            display: inline-flex;
            align-items: center;
            opacity: 0;
            transition: opacity 200ms;
            margin-left: 5px;
            cursor: pointer;

            .MuiSvgIcon-root {
                font-size: 1.25rem;
                color: ${theme.palette.text.secondary};
            }

            &.visible {
                opacity: 1;
            }
        }

        .table-footer {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
        }

        .cell-Discipline {
            text-transform: capitalize;
        }
    }

    .pagination-text {
        text-align: right;
        padding: 18px 25px;
        font-size: 13px;
        text-transform: uppercase;
        border-top: thin solid ${theme.palette.divider};
    }
    .pagination {
        flex: 0 0 auto;
        border-top: thin solid ${theme.palette.divider};
    }
`;

const jobDetailsTitleStyle = (theme: Theme) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: thin solid ${theme.palette.divider};
    margin: -16px -24px;
    padding: 4px 16px 4px 20px;
`;

const skeletonRowsCount = 5;
const rowsPerPage = 15;

type Column =
    | 'Client'
    | 'Job'
    | 'Experience'
    | 'Location'
    | 'Discipline'
    | 'Comp'
    | 'Visa'
    | 'Diversity'
    | 'AM'
    | 'Headcount'
    | 'Funding';

const allColumns: Column[] = [
    'Client',
    'Job',
    'Headcount',
    'Experience',
    'Location',
    'Discipline',
    'Comp',
    'Visa',
    'Diversity',
    'Funding',
    'AM'
];

const filterableColumns: Column[] = ['Discipline', 'Diversity', 'Visa', 'AM'];
const sortableColumns: Column[] = ['Client', 'Diversity', 'Headcount', 'Experience', 'Discipline', 'Visa', 'Funding'];
const autoHideableColumns: Column[] = ['Discipline', 'Visa'];
const defaultDisplayed = [
    'Client',
    'Job',
    'Headcount',
    'Experience',
    'Location',
    'Discipline',
    'Comp',
    'Visa',
    'Diversity',
    'AM'
].reduce((acc, col) => ({ ...acc, [col]: true }), {});

const columnToFilterFieldMap = Map<Column, JobFilterField>([
    ['Discipline', 'discipline'],
    ['Group', 'group'],
    ['Visa', 'visa'],
    ['Diversity', 'diversity'],
    ['AM', 'keywords']
] as Array<[Column, JobFilterField]>);

const getColumnContentText = (column: Column) => (job: JobWithDetails) => {
    switch (column) {
        case 'Client':
            return job.client.name;
        case 'Job':
            return job.title;
        case 'Experience':
            return experienceText(job);
        case 'Location':
            return job.description?.location;
        case 'Discipline':
            return job.discipline;
        case 'Comp':
            return job.description.compensation;
        case 'Visa':
            return job.description?.visa?.map(visaOptToString)?.join(', ');
        case 'Diversity':
            return diversityOptToString(job.description.diversity);
        case 'AM':
            return job.accountManager?.name;
        case 'Headcount':
            return job.headcount;
        case 'Funding':
            return job.client.funding;
        default:
            return undefined;
    }
};

const getColumnContent =
    (column: Column) =>
    (job: JobWithDetails): JSX.Element => {
        switch (column) {
            case 'Client':
                return (
                    <PageDialogLink
                        url={`/client/${job.client.id}/candidates`}
                        Component={ClientCandidates}
                        componentProps={{ id: job.client.id }}
                    >
                        <a href={`/client/${job.client.id}/candidates`} target="_blank">
                            {job.client.name}
                        </a>
                    </PageDialogLink>
                );
            case 'Job':
                return (
                    <PageDialogLink
                        url={`/job/${job.id}/board`}
                        Component={JobCandidatesBoard}
                        componentProps={{ jobId: job.id }}
                    >
                        <a href={`/job/${job.id}/board`} target="_blank">
                            {job.title}
                        </a>
                    </PageDialogLink>
                );
            default:
                return <span>{getColumnContentText(column)(job)}</span>;
        }
    };

const sortFunction = (col: Column) => (job: JobWithDetails) => {
    switch (col) {
        case 'Experience':
            return job.description.minExperienceYears;
        default:
            return getColumnContentText(col)(job);
    }
};

const filterFunction = (col: Column) => (job: JobWithDetails) => {
    switch (col) {
        case 'Visa':
            return job.description.visa;
        case 'Diversity':
            return job.description.diversity;
        default:
            return getColumnContentText(col)(job);
    }
};

export const Jobs: React.FC = () => {
    const { user, userPermissions } = useSession();
    const theme = useTheme();

    const [sortCol, setSortCol] = useLocalStorage<Column>('jobs-details-table-sort-column', 'Client');
    const [sortAsc, setSortAsc] = useLocalStorage<boolean>('jobs-details-table-sort-asc', true);
    const [filters, setFilters] = useLocalStorage<JobFilter[]>('jobs-details-table-filters', []);
    const [typeFilter, setTypeFilter] = useLocalStorage<JobFilterTypes>(
        'jobs-details-table-job-types',
        'placement-fees'
    );
    const [statusFilter, setStatusFilter] = useLocalStorage<JobStatusFilter>('jobs-details-table-job-status', 'active');
    const [displayedColumns, setDisplayedColumns] = useLocalStorage<{ [col: string]: boolean }>(
        'jobs-details-table-cols',
        defaultDisplayed
    );

    const [page, setPage] = useState(0);
    const [selectedJob, setSelectedJob] = useState<string>(undefined);
    const [searchText, setSearchText] = useState('');
    const [autoHiddenColumns, setAutoHiddenColumns] = useState<Column[]>([]);
    const [newJobsCreatedAfter] = useState(moment().subtract(1, 'month').valueOf());
    const [filterMenuOpen, setFilterMenuOpen] = useState(false);

    const { data: hotClientsData } = useQuery<{ clients: Array<{ id: string }> }>(HOT_CLIENTS);
    const { data: newJobsData } = useQuery<
        { jobs: Array<{ id: string }> },
        { status: JobStatus[]; types: JobType[]; createdAfter: number }
    >(NEW_JOBS, {
        variables: {
            createdAfter: newJobsCreatedAfter,
            status: jobStatusForFilter.get(statusFilter),
            types: jobTypesForFilter.get(typeFilter)
        }
    });
    const { data, refetch } = useQuery<{ jobs: JobWithDetails[] }, { status: JobStatus[]; types: JobType[] }>(
        JOBS_WITH_DETAILS,
        {
            variables: { status: jobStatusForFilter.get(statusFilter), types: jobTypesForFilter.get(typeFilter) }
        }
    );

    const hotClients = hotClientsData?.clients.map((c) => c.id);
    const newJobs = newJobsData?.jobs.map((j) => j.id);
    const allJobs = data?.jobs;
    const jobs = allJobs?.filter((j) => isJobPassingFilters({ hotClients, newJobs }, filters, j));

    useEffect(() => {
        refetch();
    }, [statusFilter, typeFilter]);

    useEffect(() => {
        if (!filterMenuOpen) {
            const updatedAutoHiddenColumns: Column[] = [];
            for (const col of autoHideableColumns) {
                if (
                    filters.findIndex((f) => f.field === columnToFilterFieldMap.get(col)) !== -1 &&
                    uniq(jobs?.map(getColumnContentText(col))).length === 1 &&
                    filters.findIndex((f) => f.field === columnToFilterFieldMap.get(col)) !== -1
                ) {
                    updatedAutoHiddenColumns.push(col);
                }
            }
            setAutoHiddenColumns(updatedAutoHiddenColumns);
        }
        setPage(0);
    }, [filters, filterMenuOpen]);

    const handleUpdateDisplayedColumns = (c: Column, selected: boolean) => {
        setDisplayedColumns({ ...displayedColumns, [c]: selected });
    };

    const handleTypeFilter = (filter: JobFilterTypes) => {
        setTypeFilter(filter);
        setPage(0);
    };

    const handleStatusFilter = (filter: JobStatusFilter) => {
        setStatusFilter(filter);
        setPage(0);
    };

    const getSortDirection = (ascending: boolean) => (ascending ? 'asc' : 'desc');

    const handleSortChange = (column: Column) => () => {
        if (sortableColumns.indexOf(column) !== -1) {
            const newSortAsc = sortCol === column ? !sortAsc : true;
            setSortCol(column);
            setSortAsc(newSortAsc);
        }
    };

    const handleJobView = (id: string) => () => {
        setSelectedJob(id);
    };

    const handleChangePage = (_1: any, newPage: number) => setPage(newPage);

    const handleChangeRowsPerPage = () => {
        /* no-op */
    };

    const handleCloseJobDialog = () => {
        setSelectedJob(undefined);
    };

    const handleColumnFilterChange = (filterMenuColumn: Column) => (selected: string[]) => {
        const field = columnToFilterFieldMap.get(filterMenuColumn);
        const values = selected.map((v) => ({
            label:
                field === 'visa'
                    ? visaOptToString(v as JobVisa)
                    : field === 'diversity'
                      ? diversityOptToString(v as JobDiversity)
                      : v,
            name: v
        }));
        setFilters(setFilterFieldValues(filters, field, values));
    };

    const handleSearchTextEnter = () => {
        if (searchText?.match(/^anywhere /)) {
            setFilters(
                addFilter(filters, { field: 'anywhere', value: { name: searchText.replace(/^anywhere /, '') } })
            );
        } else if (searchText?.match(/^and /)) {
            setFilters(addFilter(filters, { field: 'keywords-and', value: { name: searchText.replace(/^and /, '') } }));
        } else {
            setFilters(addFilter(filters, { field: 'keywords', value: { name: searchText } }));
        }
        setSearchText('');
    };

    const getRenderValue = (col: Column) => {
        switch (col) {
            case 'Visa':
                return visaOptToString;
            case 'Diversity':
                return diversityOptToString;
        }

        return undefined;
    };

    const columns = allColumns.filter((c) => displayedColumns[c] && autoHiddenColumns.indexOf(c) === -1);
    const headers = columns
        .filter((c) => displayedColumns[c])
        .map((col) => {
            const selected =
                filters.find((f) => f.field === columnToFilterFieldMap.get(col))?.values?.map((v) => v.name) ?? [];
            const values = flatten(allJobs?.map(filterFunction(col))) ?? [];
            const filterIcon =
                filterableColumns.indexOf(col) === -1 ? null : (
                    <TableColumnFilterHeader
                        values={values}
                        selected={selected}
                        renderValue={getRenderValue(col)}
                        onFilterOpen={setFilterMenuOpen}
                        onSelect={handleColumnFilterChange(col)}
                    />
                );

            const sortIcon = sortAsc ? <ArrowUpward /> : <ArrowDownward />;
            const sort =
                sortableColumns.indexOf(col as Column) === -1 ? null : (
                    <span
                        className={`column-action-icon ${sortCol === col ? 'visible' : ''}`}
                        onClick={handleSortChange(col)}
                    >
                        {sortIcon}
                    </span>
                );

            return (
                <TableCell key={col}>
                    <span className="table-header-cell">
                        <span onClick={handleSortChange(col)}>{col}</span>
                        {sort}
                        {filterIcon}
                    </span>
                </TableCell>
            );
        });

    const skeletonRows = times(skeletonRowsCount).map((i) => (
        <TableRow key={i}>
            <TableCell colSpan={columns.filter((c) => displayedColumns[c]).length}>
                <Skeleton variant="rect" />
            </TableCell>
        </TableRow>
    ));

    const orderedJobs = orderBy(jobs, [sortFunction(sortCol)], [getSortDirection(sortAsc)]);

    const rows = orderedJobs.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((job) => {
        const row = columns
            .filter((c) => displayedColumns[c])
            .map((c) => {
                return (
                    <TableCell key={c}>
                        <span className={`cell-${c}`}>{getColumnContent(c)(job)}</span>
                    </TableCell>
                );
            });
        return (
            <TableRow key={job.id} className="table-row" onClick={handleJobView(job.id)}>
                {row}
            </TableRow>
        );
    });

    const body = jobs === undefined ? skeletonRows : rows;

    let jobDetailsDialog;
    if (selectedJob && jobs) {
        const job = jobs.find((j) => j.id === selectedJob);
        const content = <JobDetails job={job} />;

        const isEditable = hasRole(userPermissions, 'settings_editor') || user.id === job.accountManager.id;
        const editButton = isEditable ? (
            <Button target="_blank" href={`/job/${job.id}/edit`}>
                Edit
            </Button>
        ) : null;

        jobDetailsDialog = (
            <Dialog open={true} onClose={handleCloseJobDialog} maxWidth="md">
                <DialogTitle>
                    <div css={jobDetailsTitleStyle(theme)}>
                        <Typography variant="h4" component="div">
                            {job.client.name} - {job.title}
                        </Typography>
                        <div>
                            <PageDialogLink
                                url={`/job/${job.id}/board`}
                                Component={JobCandidatesBoard}
                                componentProps={{ jobId: job.id }}
                            >
                                <Tooltip title="Open Job Board">
                                    <a href={`/job/${job.id}/board`} target="_blank">
                                        <IconButton>
                                            <OpenInNew />
                                        </IconButton>
                                    </a>
                                </Tooltip>
                            </PageDialogLink>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>{content}</DialogContent>
                <DialogActions>
                    {editButton}
                    <Button onClick={handleCloseJobDialog}>Close</Button>
                </DialogActions>
            </Dialog>
        );
    }

    const pagination =
        jobs === undefined ? (
            <div className="pagination-text">Loading...</div>
        ) : jobs.length > rowsPerPage ? (
            <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                component="div"
                count={jobs.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                className="pagination"
            />
        ) : (
            <div className="pagination-text">{jobs.length} Jobs</div>
        );

    return (
        <div css={styles(theme)}>
            <div className="filters">
                <div className="left">
                    <JobsFilters presets={tableFilterPresets} selected={filters} onChange={setFilters} />
                </div>
                <div className="right">
                    <JobsFilterActions selected={filters} onChange={setFilters} />
                    <SearchTextField
                        value={searchText}
                        variant="outlined"
                        onValueChange={setSearchText}
                        onEnter={handleSearchTextEnter}
                    />
                    <JobsTypeFilter selectedFilter={typeFilter} onSelectFilter={handleTypeFilter} />
                    <JobsStatusFilters selectedFilter={statusFilter} onSelectFilter={handleStatusFilter} />
                    <TableColumnsSelector
                        columns={allColumns}
                        selected={displayedColumns}
                        onSelect={handleUpdateDisplayedColumns}
                    />
                </div>
            </div>
            <Paper>
                <TableContainer className="table">
                    <Table stickyHeader={true}>
                        <TableHead>
                            <TableRow>{headers}</TableRow>
                        </TableHead>
                        <TableBody>{body}</TableBody>
                    </Table>
                </TableContainer>

                {pagination}
            </Paper>
            {jobDetailsDialog}
        </div>
    );
};
