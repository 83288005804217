import { css } from '@emotion/core';
import { startCase } from 'lodash';
import React from 'react';

import { JobStatus } from 'shared/models/job';

import { jobDisciplines } from '../common/job-disciplines';
import { Autocomplete, SelectField, TextField } from '../core-ui/form-fields';
import { JobFormData } from '../graphql/queries/job-form';
import { useModal } from '../hooks/use-modal';
import { useUsers } from '../hooks/use-users';
import { JobStatusLabels } from '../state';
import { useFormContext } from './form-context';

const styles = css`
    .job-title {
        flex: 1 1 auto;
    }

    .job-status {
        flex: 0 0 auto;
        width: 120px;
    }
`;

export const JobFormGeneral: React.FC = () => {
    const { data, getError, disabled, onFieldChange, validateFieldExists } = useFormContext<JobFormData>();
    const { users } = useUsers([data.accountManagerId, data.assignee]);
    const { getConfirmation } = useModal();

    const handleStatusChange = (status: JobStatus) => {
        if (data.status === JobStatus.Active && status !== JobStatus.Active) {
            const confirmationText =
                status === JobStatus.Paused
                    ? 'Are you sure you want to pause this job? All candidate email sequences will be stopped.'
                    : 'Are you sure you want to archive this job? This cannot be undone.';
            getConfirmation(() => onFieldChange('status')(status), confirmationText, 'Please confirm');
        } else {
            onFieldChange('status')(status);
        }
    };

    const disciplineOptions = jobDisciplines.map((discipline) => ({
        label: startCase(discipline),
        value: discipline
    }));
    const disciplineField = (
        <Autocomplete
            label="Discipline"
            options={disciplineOptions}
            value={data.discipline}
            onChange={onFieldChange('discipline')}
            validate={validateFieldExists('discipline')}
            disabled={disabled}
            errorText={getError('discipline')}
        />
    );

    const userOptions =
        users?.map((user) => ({
            label: user.name,
            value: user.id
        })) ?? [];

    const amField = (
        <Autocomplete
            label="Account Manager"
            options={userOptions}
            value={data.accountManagerId}
            onChange={onFieldChange('accountManagerId')}
            validate={validateFieldExists('accountManagerId')}
            disabled={disabled}
            errorText={getError('accountManagerId')}
        />
    );

    const assigneeField = (
        <Autocomplete
            label="Assignee"
            options={userOptions}
            value={data.assignee}
            onChange={onFieldChange('assignee')}
            validate={validateFieldExists('assignee')}
            disabled={disabled}
            errorText={getError('assignee')}
        />
    );

    let statusField;
    if (data?.id) {
        const options = [JobStatus.Active, JobStatus.Paused, JobStatus.Archived].map((status) => ({
            label: JobStatusLabels.get(status),
            value: status
        }));
        statusField = (
            <SelectField
                className="job-status"
                label="Status"
                value={data.status}
                onChange={handleStatusChange}
                disabled={disabled}
                options={options}
            />
        );
    }

    return (
        <div css={styles}>
            <div className="job-field-row">
                <TextField
                    label="Title"
                    value={data.title}
                    onChange={onFieldChange('title')}
                    disabled={disabled}
                    className="job-title"
                    errorText={getError('title')}
                    validate={validateFieldExists('title')}
                />
                {statusField}
            </div>
            <div className="job-field-row">{disciplineField}</div>
            <div className="job-field-row">
                {amField}
                {assigneeField}
            </div>
        </div>
    );
};
