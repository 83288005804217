import { Avatar, Chip, Dialog } from '@material-ui/core';
import { Set } from 'immutable';
import * as _ from 'lodash';
import { FlatButton, IconButton } from 'material-ui';
import { lightBlack, red600 } from 'material-ui/styles/colors';
import { NavigationClose } from 'material-ui/svg-icons';
import * as React from 'react';

import { SearchTerm } from 'shared/models/search';
import { PresetGroup, SearchPresetData } from 'shared/models/search-preset';
import { hasRole } from 'shared/models/user';

import { standardizeUrl } from 'profile-parser';
import { Spinner } from '../core-ui/spinner';
import { useSession } from '../hooks/use-session';
import { useSearchPresets } from './use-search-presets';
import { useSearchPresetsForm } from './use-search-presets-form';

export interface SearchPresetsProps {
    onSelect: (label: string, values: string[], id: string) => void;
    onCancel: () => void;
    validTabs: PresetGroup[];
    selectedPresets: SearchTerm[];
}

export const SearchPresets: React.FC<SearchPresetsProps> = ({ onCancel, validTabs, selectedPresets, onSelect }) => {
    const { searchPresets } = useSearchPresets();
    const { requestPresetsSave } = useSearchPresetsForm();
    const { user, userPermissions } = useSession();
    const selected = validTabs[0] || '';
    const [selectedTab, setSelectedTab] = React.useState(validTabs[0]);
    const [opened, setOpened] = React.useState<Set<number>>(Set());
    const [expanded, setExpanded] = React.useState<Set<number>>(Set());

    React.useEffect(() => {
        if (selected && !selectedTab && selectedTab !== selected) {
            setSelectedTab(selected);
        }
    }, [selected]);

    const handleSelectPreset = (label: string, values: string[], id: string) => () => {
        onSelect(label, values, id);
    };

    const handleSelectTab = (tab: PresetGroup) => () => {
        if (validTabs.indexOf(tab) !== -1) {
            setSelectedTab(tab);
        }
    };

    const handleExpandAll = (index: number) => () => {
        setExpanded(expanded.add(index));
    };

    const handleCollapseClick = (index: number) => () => {
        const sel = opened.has(index) ? opened.delete(index) : opened.add(index);
        setOpened(sel);
        setExpanded(expanded.delete(index));
    };

    const handleCreateNewPreset = () => {
        requestPresetsSave({ terms: [], selectedGroup: selectedTab, groups: validTabs, disableTabs: true });
    };

    const handleEditPreset = (preset: SearchPresetData) => () => {
        requestPresetsSave({
            disableTabs: true,
            groups: [preset.group],
            presetData: preset,
            selectedGroup: preset.group
        });
    };

    const handleChipClick = (url: string) => () => {
        const formattedUrl = 'https://' + standardizeUrl(url).replace(/\/recruiter\//, '/');
        window.open(formattedUrl, '_blank');
    };

    const allGroups = _.uniq(searchPresets.map((p) => p.group).concat(validTabs));
    const tabs = allGroups.map((p) => {
        const className = `search-preset-tab-label ${
            selectedTab === p ? 'active' : validTabs.indexOf(p) !== -1 ? 'valid' : ''
        }`;
        return (
            <div className={className} key={p} onClick={handleSelectTab(p)}>
                {p}
            </div>
        );
    });
    const addPresetButton = hasRole(userPermissions, 'save_search_presets') ? (
        <div className="search-preset-tab-label add-preset-button" onClick={handleCreateNewPreset}>
            Add Preset
        </div>
    ) : null;

    const activePresets = _.orderBy(
        searchPresets.filter((p) => p.group === selectedTab),
        [(p) => p.createdBy === user.id, (p) => p.sortRank, (p) => !!p.name.match(/^\[/), (p) => p.name],
        ['desc', 'desc', 'asc', 'asc']
    );

    const content =
        activePresets.length > 0
            ? activePresets.map((k, index) => {
                  // tslint:disable-next-line: no-magic-numbers
                  const maxVisibleChips = expanded.has(index) ? k.list.length : 50;
                  const chips = k.list
                      .sort((k1, k2) => (k1.label ?? k1.value).localeCompare(k2.label ?? k2.value))
                      .slice(0, maxVisibleChips)
                      .map((v, i) => {
                          const avatar = v?.value?.match(/www\.linkedin\.com/) ? (
                              <Avatar>
                                  <i className="fab fa-linkedin-in" />
                              </Avatar>
                          ) : undefined;
                          return (
                              <Chip
                                  key={i}
                                  label={v.label ?? v.value}
                                  style={{ maxWidth: '100%' }}
                                  avatar={avatar}
                                  onClick={avatar ? handleChipClick(v.value) : undefined}
                              />
                          );
                      });
                  if (k.list.length > maxVisibleChips) {
                      chips.push(
                          <Chip
                              className="preset-chip"
                              key="More"
                              label={`${k.list.length - maxVisibleChips} more`}
                              onClick={handleExpandAll(index)}
                          />
                      );
                  }
                  const alreadySelected = selectedPresets.findIndex((p) => p.label === k.name) !== -1;
                  const label = alreadySelected ? 'Remove' : 'Add';
                  const color = alreadySelected ? red600 : undefined;
                  const handleClick = handleSelectPreset(
                      k.name,
                      k.list.map((v) => v.value),
                      k.id
                  );
                  const editButton =
                      hasRole(userPermissions, 'save_search_presets') && k.createdBy === user.id ? (
                          <FlatButton onClick={handleEditPreset(k)} label="Edit" />
                      ) : null;
                  const addSection =
                      validTabs.indexOf(selectedTab) === -1 ? null : (
                          <div className="search-preset-action">
                              <FlatButton style={{ color }} onClick={handleClick} label={label} />
                              {editButton}
                          </div>
                      );
                  const values = opened.has(index) ? <div className="search-preset-values">{chips}</div> : null;
                  return (
                      <div className="search-preset" key={k.name}>
                          <div className={`search-preset-info${opened.has(index) ? ' expanded' : ''}`}>
                              <div className="search-preset-header" onClick={handleCollapseClick(index)}>
                                  {k.name}
                              </div>
                              {values}
                          </div>
                          {addSection}
                      </div>
                  );
              })
            : [
                  <div className="search-preset-empty" key="None">
                      No Available Preset
                  </div>
              ];

    const spinner = <Spinner />;

    return (
        <Dialog open={true} onClose={onCancel} classes={{ paper: 'search-presets-dialog' }} maxWidth="md">
            <div className="search-presets-tabs">
                <div>{tabs}</div>
                <div>{addPresetButton}</div>
            </div>
            <div className="search-presets-content">
                <div key="cancel btn" className="search-preset-close">
                    <IconButton tooltip="Close" onClick={onCancel}>
                        <NavigationClose color={lightBlack} />
                    </IconButton>
                </div>
                {searchPresets.length > 0 ? content : spinner}
            </div>
        </Dialog>
    );
};
