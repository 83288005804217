import React from 'react';

// tslint:disable-next-line: no-var-requires
const data = require('emoji-mart/data/all.json');

interface EmojiRendererProps {
    text: string;
}

const EmojiRenderer: React.FC<EmojiRendererProps> = ({ text }) => {
    const renderEmoji = (input: string): string => {
        try {
            return input.replace(/:([a-zA-Z0-9_]+):/g, (match, p1) => {
                const emoji = data.emojis[p1];
                return emoji ? String.fromCodePoint(...emoji.b.split('-').map((u: any) => parseInt(u, 16))) : match;
            });
        } catch (e) {
            return input;
        }
    };

    return <span>{renderEmoji(text)}</span>;
};

export default EmojiRenderer;
