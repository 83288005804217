import { Map } from 'immutable';
import * as _ from 'lodash';

import {
    Action,
    ReceiveAddSearchResultToJob,
    ReceiveJobSearches,
    ReceiveJobSearchStats,
    ReceiveNewSearch,
    ReceivePauseJobSearches,
    ReceivePurgeCandidatesResponse,
    ReceiveSearchResults,
    ReceiveUpdatedSearch,
    RemoveUnsavedLocalSearches,
    RequestSearchDelete
} from '../actions';
import { JobSearchesState } from '../state';

const initialState: Map<string, JobSearchesState> = null;

export function searches(state = initialState, action: Action): Map<string, JobSearchesState> {
    switch (action.type) {
        case ReceiveJobSearches:
        case ReceiveJobSearchStats: {
            const stateVal = (state || Map()).get(action.payload.jobId);
            const searchStats = action.payload.data.searchStats ?? stateVal?.searchStats ?? [];
            const progressCounts = action.payload.data.progressCounts ?? stateVal?.progressCounts ?? [];
            const unsavedStateSearches = stateVal?.searches?.filter((s) => !s.id) ?? [];
            const updatedSearches = (action.payload.data.searches ?? stateVal?.searches ?? []).concat(
                unsavedStateSearches
            );

            return (state || Map()).set(action.payload.jobId, {
                progressCounts,
                searchStats,
                searches: updatedSearches
            });
        }
        case ReceiveAddSearchResultToJob: {
            const { result, jobId, disqualified } = action.payload;
            const stats = state.get(jobId).searchStats;
            const searchStats = stats.find((s) => s.id === result.searchId);
            const updates = disqualified
                ? { rejected: searchStats.rejected ?? 0 + 1 }
                : { added: searchStats.added ?? 0 + 1 };
            const updatedSearchStats = { ...searchStats, ...updates, ready: searchStats.ready - 1 };
            return state.set(jobId, {
                ...state.get(jobId),
                searchStats: stats.map((s) => (s.id === result.searchId ? updatedSearchStats : s))
            });
        }
        case ReceiveNewSearch:
        case ReceiveUpdatedSearch:
        case ReceiveSearchResults: {
            const { search } = action.payload;
            const data = state.get(search.jobId);
            const jobSearches = data.searches
                .filter((s) => s.id && s.id !== search.id)
                .concat([search])
                .sort((s1, s2) => s1.sortRank - s2.sortRank);
            const searchStats = data.searchStats;
            return state.set(search.jobId, { searches: jobSearches, searchStats, progressCounts: data.progressCounts });
        }
        case RequestSearchDelete: {
            const { search } = action.payload;
            const data = state.get(search.jobId);
            const jobSearches = data.searches.map((s) => (s.id === search.id ? { ...s, deleted: true } : s));
            const searchStats = data.searchStats.filter((s) => s.id !== search.id);
            return state.set(search.jobId, { searches: jobSearches, searchStats, progressCounts: data.progressCounts });
        }
        case ReceivePurgeCandidatesResponse: {
            if (state && state.has(action.payload.id)) {
                return state.remove(action.payload.id);
            } else {
                return state;
            }
        }
        case RemoveUnsavedLocalSearches: {
            const { jobId } = action.payload;
            const data = state.get(jobId);
            const jobSearches = data.searches.filter((s) => !!s.id).sort((s1, s2) => s1.sortRank - s2.sortRank);
            const searchStats = data.searchStats.filter((s) => !!s.id);
            return state.set(jobId, { searches: jobSearches, searchStats, progressCounts: data.progressCounts });
        }
        case ReceivePauseJobSearches: {
            const { jobId } = action.payload;
            const data = state.get(jobId);
            const jobSearches = data.searches
                .filter((d) => !action.payload.searches.find((s) => s.id === d.id))
                .concat(action.payload.searches);
            const newData = { ...data, searches: jobSearches };
            return state.set(jobId, newData);
        }
        default:
            return state;
    }
}
