import { css } from '@emotion/core';
import {
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Theme,
    Tooltip,
    useTheme
} from '@material-ui/core';
import { Clear, Edit } from '@material-ui/icons';
import React from 'react';

import { orderBy } from 'lodash';
import { HiringManagerData, mergeHiringManagers } from 'shared/models/client';
import { ClientFormData } from '../graphql/queries/clients';
import { useFormContext } from './form-context';
import { HiringManagerForm } from './hiring-manager-form';

const disabledOpacity = 0.8;

const styles = (theme: Theme, disabled: boolean) => css`
    flex: 1 1 auto;
    position: relative;
    padding-top: 7.5px;

    span.hm-details {
        display: block;
    }

    .hm-label {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 1rem;
        transform: translate(0, 0) scale(0.75);
        transform-origin: top left;
        transition: all 200ms ease-out;
        color: ${theme.palette.text.secondary};
    }

    .MuiList-root {
        margin-left: -20px;
        margin-right: -64px;
        width: calc(100% + 40px);
        opacity: ${disabled ? disabledOpacity : 1};

        &:after {
            content: '';
            display: block;
            clear: both;
            border-bottom: thin solid ${theme.palette.primary.main};
            margin: 0 20px;
        }

        .MuiListItem-root {
            padding-left: 20px;
            padding-right: 64px;
        }

        .MuiIconButton-root {
            opacity: 0;
            transition: opacity 200ms ease-out;
        }

        .MuiListItem-container:hover .MuiIconButton-root {
            opacity: 1;
        }
    }
`;

export const ClientFormHiringManagers: React.FC = () => {
    const {
        data: { hiringManagers },
        onFieldChange,
        disabled
    } = useFormContext<ClientFormData>();
    const onChange = onFieldChange('hiringManagers');

    const theme = useTheme();
    const [hmFormData, setHMFormData] = React.useState<{ data: HiringManagerData; title: string } | null>(null);

    const handleAddHiringManagerClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setHMFormData({ data: null, title: 'Add Hiring Manager' });
    };

    const handleEditHiringManager = (hm: HiringManagerData) => () => {
        setHMFormData({ data: hm, title: 'Edit Hiring Manager' });
    };

    const handleRemoveHM = (hm: HiringManagerData) => () => {
        const newHiringManagers = hiringManagers.filter((h) => h.id !== hm.id);
        onChange(newHiringManagers);
    };

    const handleHiringManagerSave = (hm: HiringManagerData) => {
        const updated = mergeHiringManagers(hiringManagers, hm);
        onChange(updated);
        setHMFormData(null);
    };

    const handleHiringManagerFormCancel = () => {
        setHMFormData(null);
    };

    const list = orderBy(hiringManagers, (h) => h.name.full).map((hm) => {
        const secondaryText = [hm.linkedIn, hm.email].filter(Boolean).map((text, index) => (
            <span className="hm-details" key={index}>
                {text}
            </span>
        ));
        const buttons = disabled ? null : (
            <ListItemSecondaryAction>
                <Tooltip title="Edit">
                    <span>
                        <IconButton onClick={handleEditHiringManager(hm)}>
                            <Edit />
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title="Remove">
                    <span>
                        <IconButton onClick={handleRemoveHM(hm)}>
                            <Clear />
                        </IconButton>
                    </span>
                </Tooltip>
            </ListItemSecondaryAction>
        );
        return (
            <ListItem key={hm.id} button={true} onClick={handleEditHiringManager(hm)}>
                <ListItemText primary={hm.name.full} secondary={secondaryText} />
                {buttons}
            </ListItem>
        );
    });

    const addHMButton = disabled ? null : (
        <ListItem button={true} onClick={handleAddHiringManagerClick}>
            <ListItemText primary="Add Hiring Manager" />
        </ListItem>
    );

    const hmForm = hmFormData ? (
        <HiringManagerForm
            hiringManager={hmFormData.data}
            formTitle={hmFormData.title}
            onSave={handleHiringManagerSave}
            onCancel={handleHiringManagerFormCancel}
            disabled={disabled}
        />
    ) : null;

    return (
        <div css={styles(theme, disabled)}>
            <div className="hm-label">Hiring Managers</div>
            <List dense={true}>
                {list}
                {addHMButton}
            </List>
            {hmForm}
        </div>
    );
};
