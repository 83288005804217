import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { LinkedinSearchTag, SEARCH_LINKEDIN_TAGS } from '../graphql/queries/search';

const minInputLengthForSearch = 3;

export const useLinkedinSearchTags = (partial: string) => {
    // Define the state and hooks for fetching school names
    const [tags, setTags] = useState<LinkedinSearchTag[]>([]);
    const [fetchLinkedinSearchTag, { data, loading, error }] = useLazyQuery<
        { tags: LinkedinSearchTag[] },
        { search: string }
    >(SEARCH_LINKEDIN_TAGS);

    // Fetch tags when input changes
    useEffect(() => {
        if (partial.length >= minInputLengthForSearch) {
            fetchLinkedinSearchTag({
                variables: { search: partial }
            });
        } else {
            setTags([]);
        }
    }, [fetchLinkedinSearchTag, partial]);

    // Update the state when new data is fetched
    useEffect(() => {
        if (data && data.tags) {
            setTags(data.tags);
        }
    }, [data]);

    return {
        error,
        loading,
        tags
    };
};
