import React from 'react';

import { SearchExperienceCompaniesRequirements } from './search-experience-companies-requirements';
import { SearchRequirementsSection } from './search-requirements-section';
import { SearchSimilarityModelRequirements } from './search-similaritymodel-requirements';

export const SearchConfigCompanies: React.FC = () => {
    return (
        <SearchRequirementsSection section="companies">
            <div>
                <SearchExperienceCompaniesRequirements />
                <SearchSimilarityModelRequirements />
            </div>
        </SearchRequirementsSection>
    );
};
