import { css } from '@emotion/core';
import { Theme, Tooltip, useTheme } from '@material-ui/core';
import * as classNames from 'classnames';
import React from 'react';

import { StageFunnelData } from '../graphql/queries/candidates-board';

const styles = (theme: Theme) => css`
    display: flex;
    justify-content: space-between;
    width: 232px;

    .funnel-metric {
        flex: 0 0 auto;
        background: #ccc;
        color: white;
        border-radius: 4px;
        margin-bottom: 5px;
        width: 93px;
        padding: 2px 9px;
        display: flex;
        align-items: center;
        cursor: default;

        i.fas {
            font-size: 14px;
            margin-right: 8px;
        }

        i.material-icons {
            margin-right: 6px;
            font-size: 20px;
        }

        &.danger {
            background: ${theme.palette.error.main};
        }

        &.warning {
            background: ${theme.palette.warning.light};
        }

        &.success {
            background: ${theme.palette.success.light};
        }

        &.unicorn {
            background: ${theme.palette.success.dark};
        }
    }
`;

export const StageFunnelMetrics: React.FC<{ metrics: Partial<StageFunnelData> }> = ({ metrics: stageCounts }) => {
    const theme = useTheme();

    const funnelTooltip = stageCounts.count
        ? `${Math.round(
              (stageCounts.count / stageCounts.prevCount) * 100 // tslint:disable-line:no-magic-numbers
          )}% of candidates continued to this stage (All Time)`
        : '';

    const funnel =
        stageCounts.count !== undefined ? (
            <Tooltip title={funnelTooltip}>
                <div className="funnel-metric">
                    <i className="fas fa-filter" />
                    {stageCounts.count?.toLocaleString() ?? '-'}
                </div>
            </Tooltip>
        ) : null;

    const funnel7dTooltip =
        stageCounts.target !== null ? (
            <div style={{ textAlign: 'center' }}>
                {stageCounts.last14d} candidates continued to this stage in last 14 days
                <br />
                (until midnight last night)
                <br />
                Target {stageCounts.target}
            </div>
        ) : (
            ''
        );

    // tslint:disable:no-magic-numbers
    const funnel7dClass =
        stageCounts.target !== null
            ? classNames({
                  danger: stageCounts.last14d < stageCounts.target / 2,
                  'funnel-7d-metric': true,
                  'funnel-metric': true,
                  success: stageCounts.last14d >= stageCounts.target,
                  unicorn: stageCounts.last14d >= 2 * stageCounts.target,
                  warning: stageCounts.last14d >= stageCounts.target / 2
              })
            : '';
    // tslint:enable:no-magic-numbers

    const funnel7Day =
        stageCounts.target !== null ? (
            <Tooltip title={funnel7dTooltip}>
                <div className={funnel7dClass}>
                    <i className="material-icons">show_chart</i>
                    {stageCounts.last14d?.toLocaleString() ?? '-'}
                </div>
            </Tooltip>
        ) : null;

    return funnel ? (
        <div css={styles(theme)}>
            {funnel}
            {funnel7Day}
        </div>
    ) : null;
};
