import { css } from '@emotion/core';
import { Avatar, Chip } from '@material-ui/core';
import React from 'react';

import { standardizeUrl } from 'profile-parser';
import { useSearchPresets } from '../search/use-search-presets';
import { LightTooltip as Tooltip } from './light-tooltip';

const styles = css`
    margin: -4px -8px;
    max-height: 320px;
    display: flex;
    overflow: hidden;
    border-radius: 4px;
    font-weight: 400;

    .chips-container {
        flex: 1 1 auto;
        display: flex;
        flex-wrap: wrap;
        padding: 10px 12px;
        gap: 7px;
        overflow-y: auto;
        overflow-x: hidden;
    }
`;

export const PresetChip: React.FC<{
    presetId: string;
    label: React.ReactNode;
    onDelete: () => void;
    style: React.CSSProperties;
}> = ({ presetId, label, onDelete, style }) => {
    const { searchPresets } = useSearchPresets();
    const preset = searchPresets.find((p) => p.id === presetId);
    const [expanded, setExpanded] = React.useState(false);

    let tooltipContent = null;
    const defaultMaxChips = 50;

    const handleChipClick = (url: string) => (_1: React.MouseEvent) => {
        const formattedUrl = 'https://' + standardizeUrl(url).replace(/\/recruiter\//, '/');
        window.open(formattedUrl, '_blank');
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    if (preset && Array.isArray(preset.list)) {
        const maxVisibleChips = expanded ? preset.list.length : defaultMaxChips;
        const presetItems = preset.list
            .sort((p1, p2) => (p1.label ?? p1.value).localeCompare(p2.label ?? p2.value))
            .slice(0, maxVisibleChips)
            .map((item, i) => {
                const avatar = item?.value?.match(/www\.linkedin\.com/) ? (
                    <Avatar>
                        <i className="fab fa-linkedin-in" />
                    </Avatar>
                ) : undefined;
                return (
                    <Chip
                        key={i}
                        label={item.label ?? item.value}
                        style={{ maxWidth: '100%' }}
                        avatar={avatar}
                        onClick={avatar ? handleChipClick(item.value) : undefined}
                    />
                );
            });
        const showMore =
            preset.list.length > defaultMaxChips && !expanded ? (
                <Chip
                    key="show-more"
                    label={`+${preset.list.length - defaultMaxChips}`}
                    style={{ maxWidth: '100%' }}
                    onClick={handleExpandClick}
                />
            ) : null;
        tooltipContent = (
            <div css={styles}>
                <div className="chips-container">
                    {presetItems}
                    {showMore}
                </div>
            </div>
        );
    }

    return preset ? (
        <Tooltip title={tooltipContent} interactive={true} placement="bottom">
            <Chip style={{ ...style, cursor: 'pointer' }} onDelete={onDelete} label={preset.name} />
        </Tooltip>
    ) : (
        <Chip style={style} onDelete={onDelete} label={label} />
    );
};
