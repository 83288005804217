import { MenuItem, MenuList, Popover } from '@material-ui/core';
import { isEmpty, isEqual } from 'lodash';
import * as React from 'react';

import { disabledColor } from '../common/css-variables';

interface DefaultProps {
    maxMenuHeight: number;
    minMenuWidth: number;
    menuStyle: React.CSSProperties;
    anchorOrigin: { horizontal: 'right' | 'left' | 'center'; vertical: 'top' | 'center' | 'bottom' };
    targetOrigin: { horizontal: 'right' | 'left' | 'center'; vertical: 'top' | 'center' | 'bottom' };
    hintText: string;
    disabled: boolean;
    className: string;
    includeNoneOpt: boolean;
    contentStyle: React.CSSProperties;
    label: string | JSX.Element | JSX.Element[];
    getOptionLabel: <T>(opt: T) => string;
    multiple: boolean;
    checked: boolean;
}

export interface SelectFieldProps<T> extends Partial<DefaultProps> {
    selected: T | T[];
    options: T[];
    onSelect: (val: T | T[] | 'deselect') => void;
}

interface SelectFieldState {
    open: boolean;
    anchor: Element;
}

const deselectOptVal: any = 'deselect';

export class SelectField<T> extends React.Component<SelectFieldProps<T>, SelectFieldState> {
    static defaultProps: DefaultProps = {
        anchorOrigin: { horizontal: 'left', vertical: 'top' },
        checked: true,
        className: '',
        contentStyle: {},
        disabled: false,
        getOptionLabel: <T extends {}>(o: T) => o,
        hintText: 'Select',
        includeNoneOpt: false,
        label: null,
        maxMenuHeight: 250,
        menuStyle: {},
        minMenuWidth: 120,
        multiple: false,
        targetOrigin: { horizontal: 'center', vertical: 'center' }
    };

    constructor(props: SelectFieldProps<T>) {
        super(props);
        this.state = { open: false, anchor: null };
    }

    handleOpen = (e: React.MouseEvent) => {
        this.setState({ open: true, anchor: e.currentTarget });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    isSelected = (o: T) => {
        if (this.props.multiple) {
            return (this.props.selected as T[]).filter((s) => isEqual(s, o)).length > 0;
        } else {
            return isEqual(o, this.props.selected);
        }
    };

    handleSelect = (o: T) => (_1: React.FormEvent<{}>) => {
        let selected;
        if (o === deselectOptVal) {
            selected = this.props.multiple ? [] : undefined;
            this.setState({ open: false });
        } else {
            if (this.props.multiple) {
                if (!this.isSelected(o)) {
                    selected = (this.props.selected as T[]).concat([o]);
                } else {
                    selected = (this.props.selected as T[]).filter((s) => !isEqual(s, o));
                }
            } else {
                this.setState({ open: false });
                selected = o;
            }
        }
        this.props.onSelect(selected);
    };

    render() {
        const {
            label,
            options,
            getOptionLabel,
            maxMenuHeight,
            anchorOrigin,
            menuStyle,
            minMenuWidth,
            hintText,
            disabled,
            className,
            includeNoneOpt,
            contentStyle,
            selected,
            multiple
        } = this.props;
        const { anchor, open } = this.state;

        const menuItems = options.map((o, i) => {
            const isSelected = this.isSelected(o);

            return (
                <MenuItem
                    className={`menu-option ${isSelected ? 'selected' : ''}`}
                    key={i}
                    style={menuStyle}
                    onClick={this.handleSelect(o)}
                    selected={isSelected}
                >
                    {getOptionLabel(o)}
                </MenuItem>
            );
        });

        const deselectOpt = includeNoneOpt ? (
            <MenuItem className="menu-option deselect" style={menuStyle} onClick={this.handleSelect(deselectOptVal)}>
                None
            </MenuItem>
        ) : null;
        const popover = (
            <Popover open={!disabled && open} onClose={this.handleClose} anchorEl={anchor} anchorOrigin={anchorOrigin}>
                <MenuList className="custom-select-menu" style={{ maxHeight: maxMenuHeight, minWidth: minMenuWidth }}>
                    {deselectOpt}
                    {menuItems}
                </MenuList>
            </Popover>
        );
        const hint = <span style={{ color: disabledColor }}>{hintText}</span>;

        const selectionEmpty = multiple ? isEmpty(selected) : (selected as any) === '';
        const selectedVal =
            selected === undefined || selected === null || selectionEmpty
                ? hint
                : multiple
                  ? (selected as T[]).map((s, i) => <div key={i}>{getOptionLabel(s)}</div>)
                  : getOptionLabel(selected);

        const fieldLabel = label ? <label className="search-label">{label}</label> : null;
        return (
            <div className={className}>
                {fieldLabel}
                <div>
                    <div
                        className={`custom-select-field-content ${disabled ? 'disabled' : ''}`}
                        onClick={this.handleOpen}
                        style={contentStyle}
                    >
                        {selectedVal}
                    </div>
                    {popover}
                </div>
            </div>
        );
    }
}
