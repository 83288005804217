import { FlatButton } from 'material-ui';
import * as moment from 'moment';
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';

import { hasRole } from 'shared/models/user';

import { getLocalStorageKey, setLocalStorageKey } from '../common/local-storage';
import { config } from '../config';
import { Session, State } from '../state';

const canUpdateLimitInMinutes = 30;
interface ConnectedProps {
    session: Session;
}

export const ParachuteComponent: React.FC<ConnectedProps> = ({ session }) => {
    const [updated, setUpdateStatus] = React.useState(false);
    const [updating, setUpdating] = React.useState(false);

    if (hasRole(session.userPermissions, 'parachute_access')) {
        const lastParachuteUpdate = getLocalStorageKey('lastParachuteUpdate', undefined);

        const canUpdate =
            lastParachuteUpdate === undefined ||
            moment().diff(moment(Number(lastParachuteUpdate)), 'minutes') > canUpdateLimitInMinutes;

        const handleClick = () => {
            setUpdating(true);
            const endpoint = `${config.ApiEndpoint}/parachute`;
            fetch(endpoint, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authtoken: session.authToken
                },
                method: 'post'
            }).then((response) => {
                if (response.ok) {
                    setUpdateStatus(true);
                }
            });
            setLocalStorageKey('lastParachuteUpdate', Date.now(), Date.now());
        };
        return (
            <DocumentTitle title="Parachute">
                <div id="container">
                    <div style={{ margin: '20px' }}>
                        <FlatButton
                            primary={true}
                            label={updated ? 'Profiles Updates started' : 'Update profiles'}
                            onClick={handleClick}
                            key="parachute"
                            disabled={!canUpdate || updating}
                        />
                    </div>
                </div>
            </DocumentTitle>
        );
    } else {
        return null;
    }
};

const mapStateToProps = (state: State) => ({
    session: state.session
});

export const Parachute = connect<ConnectedProps, undefined, {}>(mapStateToProps)(ParachuteComponent);
