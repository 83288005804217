import { Map } from 'immutable';

import { ScheduledMessageView } from 'shared/models/scheduled-messages';
import {
    Action,
    ReceiveCancelScheduledMessage,
    ReceivePersonDetails,
    ReceiveRescheduleMessage,
    ReceiveScheduledMessages
} from '../actions';
import { ReceiveScheduledDisqualificationMail, ReceiveScheduledMessage } from '../email-compose/actions';

const initialState: Map<string, ScheduledMessageView[]> = Map();

export function scheduledMessages(state = initialState, action: Action) {
    switch (action.type) {
        case ReceivePersonDetails: {
            const { details } = action.payload;
            return state.set(details.person.id, details.scheduledMessages);
        }
        case ReceiveScheduledMessages:
            return state.set(action.payload.personId, action.payload.scheduledMessages);
        case ReceiveScheduledDisqualificationMail:
        case ReceiveScheduledMessage: {
            const { scheduledMessage } = action.payload;
            const personIds = scheduledMessage.personIds || [];
            let newState = state;
            for (const personId of personIds) {
                if (newState.get(personId)) {
                    const messages = newState
                        .get(personId)
                        .filter((message) => message.id !== scheduledMessage.id)
                        .concat([scheduledMessage]);
                    newState = newState.set(personId, messages);
                }
            }
            return newState;
        }
        case ReceiveCancelScheduledMessage: {
            const { id } = action.payload;
            const canceledMessage = state.get(action.payload.personId).find((m) => m.id === id);
            const personIds = canceledMessage.personIds || [];
            let newState = state;
            for (const personId of personIds) {
                if (newState.get(personId)) {
                    const messages = newState.get(personId).filter((message) => message.id !== canceledMessage.id);
                    newState = newState.set(personId, messages);
                }
            }
            return newState;
        }
        case ReceiveRescheduleMessage: {
            const { scheduledMessage, oldMessageId } = action.payload;
            const personIds = scheduledMessage.personIds || [];
            let newState = state;
            for (const personId of personIds) {
                if (newState.get(personId)) {
                    const messages = newState
                        .get(personId)
                        .filter((message) => message.id !== oldMessageId)
                        .concat([scheduledMessage]);
                    newState = newState.set(personId, messages);
                }
            }
            return newState;
        }
        default:
            return state;
    }
}
