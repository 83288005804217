import { css } from '@emotion/core';
import { Menu, MenuItem } from '@material-ui/core';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';

const styles = css`
    .header-title {
        color: white;
        font-size: 23px;
        cursor: pointer;
    }
`;

const menuStyles = css`
    .menu-item {
        display: inline-flex;
        align-items: center;
    }
`;

interface HeaderNavigationSelectProps<T extends string> extends RouteComponentProps {
    routes: Array<[T, string]>;
    onPageChange?: (page: T) => void;
}

const HeaderNavigationSelectComponent = <T extends string>({
    routes,
    location,
    onPageChange
}: HeaderNavigationSelectProps<T>): JSX.Element => {
    const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [redirectTo, setRedirectTo] = React.useState<T | null>(null);

    const activePage = React.useMemo(
        () => routes.find(([_, path]) => path === location.pathname)?.[0],
        [routes, location.pathname]
    );

    React.useEffect(() => {
        if (redirectTo && activePage === redirectTo) {
            setRedirectTo(null);
        }
    }, [redirectTo, activePage]);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        setAnchor(event.currentTarget);
        setMenuOpen(true);
    };

    const handleMenuRequestClose = () => {
        setAnchor(null);
        setMenuOpen(false);
    };

    const handlePageChange = (value: T) => () => {
        handleMenuRequestClose();
        if (activePage !== value) {
            if (onPageChange) {
                onPageChange(value);
            } else {
                setRedirectTo(value);
            }
        }
    };

    const getPagePath = (page: T) => {
        return routes.find(([label, _]) => label === page)?.[1];
    };

    if (redirectTo && redirectTo !== activePage) {
        return <Redirect to={getPagePath(redirectTo)} push={true} />;
    }

    const menuItemElements = routes.map(([label, _]) => (
        <MenuItem key={label} onClick={handlePageChange(label)}>
            <div className="menu-item">{label}</div>
        </MenuItem>
    ));

    return (
        <>
            <div css={styles} onClick={handleClick}>
                <div className="header-title">{activePage}</div>
            </div>
            <Menu
                anchorEl={anchor}
                open={menuOpen}
                onClose={handleMenuRequestClose}
                css={menuStyles}
                style={{ zIndex: 2000 }}
            >
                {menuItemElements}
            </Menu>
        </>
    );
};

export const HeaderNavigationSelect = withRouter(HeaderNavigationSelectComponent);
