import { Chip, TextField } from '@material-ui/core';
import { parseAddressList, ParsedMailbox, parseOneAddress } from 'email-addresses';
import { uniqBy } from 'lodash';
import { black, grey200, red500, white } from 'material-ui/styles/colors';
import * as React from 'react';
import { v1 } from 'uuid';

import { EmailAddress } from 'shared/types/email-compose';

interface DefaultProps {
    addressValid: (address: string) => boolean;
}

interface EmailAddressFieldProps extends Partial<DefaultProps> {
    addresses: EmailAddress[];
    hintText: string;
    label: JSX.Element;
    onChange: (addresses: EmailAddress[]) => void;
    onBlur?: () => void;
    disabled?: boolean;
}

interface EmailAddressFieldState {
    value: string;
}

export class EmailAddressField extends React.Component<EmailAddressFieldProps, EmailAddressFieldState> {
    static defaultProps: DefaultProps = {
        addressValid: (address: string) => !!parseOneAddress(address)
    };

    private id: string;
    private textInput: React.RefObject<HTMLInputElement>;

    constructor(props: EmailAddressFieldProps) {
        super(props);
        this.textInput = React.createRef();
        this.state = { value: '' };
        this.id = v1();
    }

    handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ value: event.target.value });
    };

    handleKeyPress = (e: React.KeyboardEvent<{}>) => {
        if (e.key === ' ' || e.key === ',' || e.key === ';' || e.key === 'Enter') {
            const newAddressParsed = this.parseAddresses();
            if (newAddressParsed) {
                e.preventDefault();
            }
        }
    };

    handleRemove = (address: string) => () => {
        const addresses = this.props.addresses.filter((a) => a.address !== address);
        this.props.onChange(addresses);
    };

    parseAddresses = (force = false) => {
        const { value } = this.state;
        const parsed = parseAddressList(value);
        let newAddresses: EmailAddress[] = [];
        let newValue = value;
        if (parsed) {
            newAddresses = parsed.map((v: ParsedMailbox) => ({ address: v.address, name: v.name }));
            newValue = '';
        } else if (force && value.trim() !== '') {
            newAddresses = [{ name: null, address: value }];
            newValue = '';
        }
        const addresses = uniqBy((this.props.addresses || []).concat(newAddresses), 'address');
        this.props.onChange(addresses);
        this.setState({ value: newValue });
        return !!parsed;
    };

    handleBlur = () => {
        this.parseAddresses(true);
        if (this.props.onBlur) {
            this.props.onBlur();
        }
    };

    handleDoubleClick = (recipient: EmailAddress, editable: boolean) => (event: React.MouseEvent<HTMLElement>) => {
        const doubleClick = 2;
        if (event.nativeEvent.detail === doubleClick && !this.props.disabled && editable) {
            const { address, name } = recipient;
            const value = name ? `${name} <${address}>` : address;
            this.handleRemove(address)();
            this.setState({ value });
            this.textInput.current.focus();
        }
    };

    render() {
        const { hintText, disabled, label, addresses } = this.props;
        const { value } = this.state;
        const hint = (addresses || []).length === 0 ? hintText : '';
        const textField = (
            <TextField
                placeholder={hint}
                value={value}
                id={this.id}
                onChange={this.handleTextChange}
                onKeyPress={this.handleKeyPress}
                InputProps={{ disableUnderline: true }}
                autoFocus={true}
                className="email-recipients-textfield"
                onBlur={this.handleBlur}
                ref={this.textInput}
                disabled={disabled}
            />
        );
        const addedRecipients = (addresses || []).map((rec) => {
            const valid = this.props.addressValid(rec.address);
            const labelColor = valid ? black : white;
            const backgroundColor = valid ? grey200 : red500;
            const editable = !disabled;
            return (
                <Chip
                    style={{ backgroundColor, color: labelColor, fontSize: 'inherit' }}
                    onClick={this.handleDoubleClick(rec, editable)}
                    onDelete={editable ? this.handleRemove(rec.address) : null}
                    key={rec.address}
                    label={rec.name ? `${rec.name} (${rec.address.split('@')[1]})` : rec.address}
                />
            );
        });
        return (
            <div className="recipient-field">
                {label}
                <div className="recipients">
                    {addedRecipients}
                    {textField}
                </div>
            </div>
        );
    }
}
