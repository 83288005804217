import { CandidateDisqualReason } from 'shared/models/job-stages';
import { ComposeEmailPayload } from 'shared/types/email-compose';

import { ApiEndpoint, getHeaders, parseResponse } from '../api';

export const sendMail = (account: string, data: ComposeEmailPayload, sendAt: number) => {
    const endpoint = `${ApiEndpoint}/email/${account}/send`;
    return fetch(endpoint, {
        body: JSON.stringify({ data, sendAt }),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const sendDisqualificationMail = (
    account: string,
    data: ComposeEmailPayload,
    sendAt: number,
    updates: {
        disqualReason: CandidateDisqualReason;
    }
) => {
    const endpoint = `${ApiEndpoint}/email/${account}/send-disqualification`;
    return fetch(endpoint, {
        body: JSON.stringify({ data, sendAt, updates }),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};
