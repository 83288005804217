import * as React from 'react';
import DocumentTitle from 'react-document-title';

import { BillingPageHeader } from '../components/billing-page-header';
import { Placements } from '../components/placements';

export const PlacementsPage: React.FC = () => (
    <DocumentTitle title="Placements">
        <div id="container">
            <BillingPageHeader />
            <div id="content" className="flex-fill">
                <Placements />
            </div>
        </div>
    </DocumentTitle>
);
