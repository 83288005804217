import { useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import { Theme, useTheme } from '@material-ui/core';
import React from 'react';

import { monthlyFeeJobTypes, prepaymentJobTypes } from 'shared/common/job-constants';
import { hasRole } from 'shared/models/user';

import { BaseFee, Fee as FeeData, JOB_FEES } from '../graphql/queries/billing';
import { FeesProvider } from '../hooks/use-fees';
import { useSession } from '../hooks/use-session';
import { JobType } from '../state';
import { FeesList } from './fees-list';

interface JobFeesProps {
    job: {
        id: string;
        clientId: string;
        monthlyFees: number;
        assignee: string;
        accountManagerId: string;
        jobType: JobType;
    };
    disabled: boolean;
}

const disabledOpacity = 0.8;

const styles = (theme: Theme, disabled: boolean) => css`
    flex: 1 1 auto;
    position: relative;
    padding-top: 7.5px;

    .fees-label {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 1rem;
        transform: translate(0, 0) scale(0.75);
        transform-origin: top left;
        transition: all 200ms ease-out;
        color: ${theme.palette.text.secondary};
    }

    .MuiList-root {
        margin-left: -36px;
        margin-right: -36px;
        opacity: ${disabled ? disabledOpacity : 1};

        &:after {
            content: '';
            display: block;
            clear: both;
            border-bottom: thin solid ${theme.palette.primary.main};
            margin: 0 36px;
        }

        .MuiListItem-root {
            padding-left: 36px;
            padding-right: 36px;
        }

        .MuiListItemSecondaryAction-root {
            right: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
        }
    }
`;

export const JobFees: React.FC<JobFeesProps> = ({ job, disabled }) => {
    const theme = useTheme();
    const doesJobHaveFees = prepaymentJobTypes.includes(job.jobType) || monthlyFeeJobTypes.includes(job.jobType);
    const { data, refetch } = useQuery<{ fees: FeeData[] }, { jobId: string }>(JOB_FEES, {
        variables: { jobId: job.id }
    });
    const { userPermissions } = useSession();

    const canEdit = hasRole(userPermissions, 'account_manager');

    const getNewFeeInitialData =
        canEdit && doesJobHaveFees
            ? (): BaseFee => {
                  return {
                      accountManagerId: job.accountManagerId,
                      amount: 0,
                      clientId: job.clientId,
                      id: undefined,
                      incurredAt: Date.now(),
                      jobId: job.id,
                      recruiterId: job.assignee,
                      type: job.jobType === 'RPO' ? 'monthly-rpo' : 'prepayment'
                  };
              }
            : undefined;

    if (!data || !job.id || (!doesJobHaveFees && data?.fees?.length === 0)) {
        return null;
    }

    return (
        <div className="job-field-row">
            <div css={styles(theme, disabled)}>
                <div className="fees-label">Fees</div>
                <FeesProvider
                    data={data.fees}
                    getNewFeeInitialData={getNewFeeInitialData}
                    refetch={refetch}
                    disabled={disabled}
                >
                    <FeesList />
                </FeesProvider>
            </div>
        </div>
    );
};
