import { css } from '@emotion/core';
import {
    Avatar,
    Button,
    Chip,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    Theme,
    useTheme
} from '@material-ui/core';
import { startCase, uniq } from 'lodash';
import React from 'react';

import { PresetGroup } from 'shared/models/search-preset';

import { standardizeUrl } from 'profile-parser';
import { useModal } from '../hooks/use-modal';
import { useSession } from '../hooks/use-session';
import { splitStringToParts } from '../lib/text-utils';
import { SearchPreset } from '../state';

interface SearchPresetFormProps {
    title: string | React.ReactNode;
    groups: PresetGroup[];
    selected: PresetGroup;
    data: Partial<SearchPreset>;
    onClose: () => void;
    onSave: (id: string, preset: Partial<SearchPreset>) => void;
    onDelete: (id: string) => void;
    disabled: boolean;
}

const styles = (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    color: ${theme.palette.text.primary};

    .body {
        padding: 10px 18px;
        flex: 1 1 auto;
        min-width: 640px;
        overflow-y: auto;
    }

    .actions {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        padding: 20px 18px 10px;
        flex: 0 0 auto;

        .primary-actions {
            display: flex;
        }
    }

    .name-field {
        margin-bottom: 25px;
    }

    .values-field {
        margin-top: 25px;
        border-bottom: thin solid rgba(0, 0, 0, 0.42);

        label {
            font-size: 12.5px;
            opacity: 0.7;
            line-height: 20px;
        }
    }

    .value-chips {
        margin-top: 5px;
        margin-bottom: 8px;

        .MuiChip-root {
            margin-right: 3px;
            margin-bottom: 3px;
        }
    }

    .shared-field {
        margin-top: 25px;
    }
`;

export const SearchPresetForm: React.FC<SearchPresetFormProps> = (props) => {
    const theme = useTheme();
    const { user } = useSession();
    const { getConfirmation } = useModal();
    const { groups, title, onClose, disabled } = props;
    const [selected, setSelected] = React.useState(props.selected);
    const [name, setName] = React.useState(props.data?.name ?? '');
    const [values, setValues] = React.useState<Array<{ label: string; value?: string }>>(props.data?.list ?? []);
    const [text, setText] = React.useState('');
    const [shared, setShared] = React.useState(props.data?.shared ?? false);

    React.useEffect(() => {
        setName(props.data?.name ?? '');
        setShared(props.data?.shared ?? false);
    }, [props.data]);

    const getNameWithPrefix = (value: string) => {
        const sharedNamePrefix = shared ? `[${user.name.full}] ` : '';
        if (!shared) {
            return value;
        } else {
            return (
                sharedNamePrefix +
                value.replace(new RegExp(`^${sharedNamePrefix.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')}`), '')
            );
        }
    };

    React.useEffect(() => {
        setName(getNameWithPrefix(name));
    }, [shared]);

    const handleRemove = (index: number) => () => {
        setValues(values.slice(0, index).concat(values.slice(index + 1)));
    };

    const handleGroupChange = (event: React.ChangeEvent<{ value: PresetGroup }>) => setSelected(event.target.value);

    const handleNameChange = (event: React.ChangeEvent<{ value: string }>) => {
        setName(getNameWithPrefix(event.target.value));
    };

    const handleTextChange = (event: React.ChangeEvent<{ value: string }>) => {
        const val = (event.target.value ?? '').trim() === '' ? '' : event.target.value;
        setText(val);
    };

    const handleKeyPress = (e: React.KeyboardEvent<{}>) => {
        if (e.key === 'Enter') {
            const newValues = splitStringToParts(text ?? '').map((w) => ({
                label: null,
                value: w.trim().replace(/^"(.*)"$/, '$1')
            }));
            setValues(uniq(values.concat(newValues)));
            setText('');
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<{}>) => {
        if (e.key === 'Backspace' && (text ?? '').trim().length === 0) {
            setValues(values.slice(0, values.length - 1));
        }
    };

    const handleToggleShared = () => setShared(!shared);

    const handleSave = async () => {
        const preset: Partial<SearchPreset> = {
            group: selected,
            groupLabel: startCase(selected),
            list: values,
            name,
            shared
        };
        props.onSave(props.data?.id, preset);
    };

    const handleDeleteClick = () => {
        getConfirmation(
            () => {
                props.onDelete(props.data.id);
            },
            'This will delete the preset permanently',
            'Are you sure?'
        );
    };

    const handleChipClick = (url: string) => () => {
        const formattedUrl = 'https://' + standardizeUrl(url).replace(/\/recruiter\//, '/');
        window.open(formattedUrl, '_blank');
    };

    const chipElements = values.map((v, i) => {
        const avatar = v?.value?.match(/www\.linkedin\.com/) ? (
            <Avatar>
                <i className="fab fa-linkedin-in" />
            </Avatar>
        ) : undefined;
        return (
            <Chip
                key={i}
                label={v.label ?? v.value}
                onDelete={disabled ? null : handleRemove(i)}
                style={{ maxWidth: '100%' }}
                avatar={avatar}
                onClick={avatar ? handleChipClick(v.value) : undefined}
            />
        );
    });
    const chips = values.length > 0 ? <div className="value-chips">{chipElements}</div> : null;

    const menuItems = groups.map((group) => (
        <MenuItem value={group} key={group}>
            {startCase(group)}
        </MenuItem>
    ));

    const saveDisabled = name.trim().length === 0 || disabled;

    const deleteButton = props.data?.id ? (
        <div className="secondary-actions">
            <Button onClick={handleDeleteClick}>Delete</Button>
        </div>
    ) : null;

    return (
        <div css={styles(theme)}>
            {title}
            <div className="body">
                <TextField
                    label="Name"
                    fullWidth={true}
                    value={name}
                    onChange={handleNameChange}
                    className="name-field"
                    disabled={disabled}
                />
                <FormControl fullWidth={true}>
                    <InputLabel id="preset-form-group-label">Group</InputLabel>
                    <Select
                        value={selected}
                        onChange={handleGroupChange}
                        labelId="preset-form-group-label"
                        disabled={!!props.data?.id || disabled}
                    >
                        {menuItems}
                    </Select>
                </FormControl>
                <div className="values-field">
                    <label>Values</label>
                    {chips}
                    <TextField
                        fullWidth={true}
                        value={text}
                        multiline={true}
                        onChange={handleTextChange}
                        onKeyPress={handleKeyPress}
                        onKeyDown={handleKeyDown}
                    />
                </div>
                <div className="shared-field">
                    <FormControlLabel
                        control={<Switch checked={shared} onChange={handleToggleShared} />}
                        label="Shared Preset"
                        disabled={disabled}
                    />
                </div>
            </div>
            <div className="actions">
                <div className="primary-actions">
                    <Button onClick={onClose} disabled={disabled}>
                        Cancel
                    </Button>
                    <Button onClick={handleSave} disabled={saveDisabled}>
                        Save
                    </Button>
                </div>
                {deleteButton}
            </div>
        </div>
    );
};
