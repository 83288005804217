import { MenuItem, Select } from '@material-ui/core';
import React from 'react';

export type ClientFilterType = 'external-clients' | 'all';

export const ClientsTypeSelector: React.FC<{
    selected: ClientFilterType;
    onChange: (value: ClientFilterType) => void;
}> = ({ selected, onChange }) => {
    const handleChange = (event: React.ChangeEvent<{ value: ClientFilterType }>) => {
        onChange(event.target.value);
    };

    return (
        <Select value={selected} onChange={handleChange} variant="outlined">
            <MenuItem value="external-clients">External Clients</MenuItem>
            <MenuItem value="all">All</MenuItem>
        </Select>
    );
};
