import { CircularProgress, IconButton } from 'material-ui';
import * as React from 'react';
import { connect } from 'react-redux';

import { State } from '../../state';
import { navigateThreadsPage, navigateToThread } from '../actions';
import { EmailInboxData, threadsPageSize } from '../types';

interface ConnectedProps {
    data: EmailInboxData;
}

interface ConnectedDispatch {
    navigateThreadsPage: (direction: 'forward' | 'backward') => void;
    navigateToThread: (direction: 'forward' | 'backward') => void;
}

type EmailInboxPaginationProps = ConnectedProps & ConnectedDispatch;

class EmailInboxPaginationComponent extends React.Component<EmailInboxPaginationProps, undefined> {
    handleNavClick = (direction: 'forward' | 'backward') => () => {
        if (this.props.data.viewingThread) {
            this.props.navigateToThread(direction);
        } else {
            this.props.navigateThreadsPage(direction);
        }
    };

    render() {
        const { data } = this.props;
        if (!data || !data.initialized || !data.threads) return null;

        const currentIndex = data.viewingThread
            ? data.threads.findIndex((t) => t.id === data.viewingThread) + data.threadsStartIndex
            : null;
        const startIndex = data.threads.length === 0 ? 0 : data.threadsStartIndex;
        const endIndex =
            data.threads.length === 0 ? 0 : Math.min(data.threads.length, threadsPageSize) + startIndex - 1;
        const totalCount = data.labels.find((l) => l.id === data.selectedLabel).threadsTotal;

        if (totalCount === 0) return null;

        const positionText = data.viewingThread ? currentIndex : `${startIndex}–${endIndex}`;
        const loadingThickness = 2;
        const loadingSize = 20;

        const nextDisabled = data.viewingThread ? currentIndex >= totalCount : endIndex >= totalCount;
        const prevDisabled = data.viewingThread ? currentIndex <= 1 : data.threadsStartIndex === 1;

        const buttons = data.fetching ? (
            <div className="email-inbox-pagination-loading">
                <CircularProgress size={loadingSize} thickness={loadingThickness} />
            </div>
        ) : (
            [
                <IconButton
                    iconClassName="material-icons"
                    disabled={prevDisabled}
                    onClick={this.handleNavClick('backward')}
                    key="before"
                >
                    navigate_before
                </IconButton>,
                <IconButton
                    iconClassName="material-icons"
                    disabled={nextDisabled}
                    onClick={this.handleNavClick('forward')}
                    key="after"
                >
                    navigate_next
                </IconButton>
            ]
        );
        return (
            <div className="email-inbox-pagination">
                <span className="email-inbox-pagination-count">
                    {positionText} of {totalCount}
                </span>
                {buttons}
            </div>
        );
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    data: state.emailInbox
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    navigateThreadsPage,
    navigateToThread
};

export const EmailInboxPagination = connect<ConnectedProps, ConnectedDispatch, {}>(
    mapStateToProps,
    mapDispatchToProps
)(EmailInboxPaginationComponent);
