import { css } from '@emotion/core';
import { Theme, useTheme } from '@material-ui/core';
import React from 'react';

import { Field, fieldColors, fieldLabels, FieldsSelected } from '../../common/metrics';

interface LegendProps {
    fields: Field[];
    selected: FieldsSelected;
    onUpdateSelected: (sel: FieldsSelected) => void;
    onFieldHover: (field: Field) => void;
}

const styles = (theme: Theme) => css`
    padding: 20px;
    flex: 0 0 auto;
    border-left: thin solid ${theme.palette.divider};

    .legend-field {
        padding: 5px 0;
        display: flex;
        align-items: center;
        cursor: pointer;
        opacity: 0.4;

        &.separated {
            padding-top: 15px;
            margin-top: 10px;
            border-top: thin solid ${theme.palette.divider};
        }

        &.active {
            opacity: 1;
        }

        .legend-color {
            width: 12px;
            height: 12px;
            border-radius: 2px;
        }

        .legend-field-name {
            font-size: 11px;
            text-transform: uppercase;
            padding: 0 10px;
        }

        .legend-field-only {
            opacity: 0;
            font-size: 11px;
            font-weight: 500;
            text-transform: uppercase;
            flex: 1 1 auto;
            text-align: right;
            padding-left: 10px;
        }

        &:hover {
            opacity: 0.75;

            .legend-field-only {
                opacity: 1;
            }
        }
    }
`;

export const Legend: React.FC<LegendProps> = ({ fields, selected, onUpdateSelected, onFieldHover }) => {
    const theme = useTheme();

    const handleFieldClick = (field: Field) => () => {
        onUpdateSelected({ ...selected, [field]: !selected[field] });
    };

    const handleFieldHover = (field: Field) => () => {
        if (selected[field] || field === undefined) {
            onFieldHover(field);
        }
    };

    const handleFieldOnlySelect = (field: Field) => () => {
        onUpdateSelected(fields.reduce((acc, f) => ({ ...acc, [f]: f === field }), {} as FieldsSelected));
    };

    const handleSelectAll = () => {
        onUpdateSelected(fields.reduce((acc, f) => ({ ...acc, [f]: true }), {} as FieldsSelected));
    };

    const legend = fields.map((f) => {
        const legendStyle = {
            background: selected[f] ? fieldColors.get(f) : 'white',
            border: `2px solid ${fieldColors.get(f)}`
        };
        return (
            <div
                className={`legend-field ${selected[f] ? 'active' : ''}`}
                key={f}
                onMouseEnter={handleFieldHover(f)}
                onMouseLeave={handleFieldHover(undefined)}
            >
                <div className="legend-color" onClick={handleFieldClick(f)} style={legendStyle} />
                <div className="legend-field-name" onClick={handleFieldClick(f)}>
                    {fieldLabels.get(f)}
                </div>
                <div className="legend-field-only" onClick={handleFieldOnlySelect(f)}>
                    only
                </div>
            </div>
        );
    });

    const allSelect = fields.reduce((acc, f) => acc && selected[f], true) ? null : (
        <div className="legend-field separated">
            <div className="legend-color" style={{ background: 'black', border: '2px solid black' }} />
            <div className="legend-field-name" onClick={handleSelectAll}>
                Select All
            </div>
        </div>
    );

    return (
        <div css={styles(theme)}>
            {legend}
            {allSelect}
        </div>
    );
};
