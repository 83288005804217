import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import * as React from 'react';

import { JobSubmissionType } from 'shared/models/job';
import { NoteView } from 'shared/models/note';
import { HrefFilePayload } from 'shared/types/file-payload';

import { Editor } from 'react-ce';

import { createNewNote } from '../actions';
import { DropZone } from '../core-ui/drop-zone';
import { useReduxDispatch, useReduxState } from '../hooks/use-redux';
import { readFile } from '../lib/read-file-payload';

interface SubmissionDirectionsDialogProps {
    jobId: string;
    personId: string;
    onRequestClose: () => void;
    requestCreateSubmission: () => void;
    onSubmitConfirmed: () => void;
}

export const SubmissionDirectionsDialog: React.FC<SubmissionDirectionsDialogProps> = (props) => {
    const [submissionAttachment, setSubmissionAttachment] = React.useState<HrefFilePayload>(null);
    const { jobId, personId } = props;
    const jobs = useReduxState((state) => state.jobs);
    const notes = useReduxState((state) => state.notes);
    const dispatch = useReduxDispatch();
    const job = jobs.get(jobId);
    const {
        submissionSettings: { username, password, type, description, atsLink }
    } = job;

    const atsSubmission = type.includes(JobSubmissionType.ATS);

    const noteContent = 'ATS Submission Note';

    const handleConfirm = () => {
        props.onRequestClose();
        if (atsSubmission && submissionAttachment) {
            const newNote: Partial<NoteView> = {
                attachments: [],
                content: noteContent,
                context: {
                    isATSSubmissionNote: true,
                    isSubmissionNote: true,
                    jobId
                },
                format: 'html',
                newAttachments: [submissionAttachment],
                notable: `persons-${personId}`
            };
            dispatch(createNewNote(newNote));
        }
        if (type.includes(JobSubmissionType.Email)) {
            props.requestCreateSubmission();
        } else {
            props.onSubmitConfirmed();
        }
    };

    const handleDrop = (files: File[]) => {
        Promise.all(files.map(readFile)).then((result) => {
            setSubmissionAttachment(result[0]);
        });
    };

    const handleRequestClose = () => {
        props.onRequestClose();
    };

    const attachment = submissionAttachment ? (
        <a
            href={submissionAttachment.href}
            key={submissionAttachment.filename}
            download={submissionAttachment.filename}
            className="href-container"
        >
            <div className="email-attachment">
                <div className="list-centered-icon">
                    <i className="fas fa-file-alt attachment-icon" />
                    <span>{submissionAttachment.filename}</span>
                </div>
            </div>
        </a>
    ) : null;

    const actions: JSX.Element[] = [];
    const alreadySubmitted =
        notes.get(`persons-${personId}`) &&
        notes.get(`persons-${personId}`).find((n) => n.context.isATSSubmissionNote && n.context.jobId === jobId);

    let existingAttachment = null;
    if (alreadySubmitted) {
        const existingAttachmentData = alreadySubmitted.attachments?.[0];
        existingAttachment = (
            <a
                key={existingAttachmentData.filename}
                download={existingAttachmentData.filename}
                className="href-container"
            >
                <div className="email-attachment">
                    <div className="list-centered-icon">
                        <i className="fas fa-file-alt attachment-icon" />
                        <span>{existingAttachmentData.filename}</span>
                    </div>
                </div>
            </a>
        );
    }

    const nextButton =
        !atsSubmission || attachment || existingAttachment ? (
            <Button onClick={handleConfirm} key="save" disabled={!attachment && atsSubmission && !existingAttachment}>
                Continue
            </Button>
        ) : null;
    actions.push(
        <Button onClick={handleRequestClose} key="cancel">
            Cancel
        </Button>,
        nextButton
    );

    const usernameInfo = !!username ? (
        <div className="ats-submission-field">
            <label className="ats-submission-field-label">Username</label>
            {username}
        </div>
    ) : null;
    const passwordInfo = !!password ? (
        <div className="ats-submission-field">
            <label className="ats-submission-field-label">Password</label>
            {password}
        </div>
    ) : null;

    const uploadButton = <Button>{alreadySubmitted ? 'Upload New Screenshot' : 'Upload Screenshot'}</Button>;

    const dropzone =
        !attachment && atsSubmission ? <DropZone onDrop={handleDrop} renderElement={uploadButton} /> : null;

    const atsInfo = atsSubmission ? (
        <>
            <div className="ats-submission-fields">
                <div className="ats-submission-field">
                    <label className="ats-submission-field-label">ATS Link</label>
                    <a href={atsLink} target="_blank">
                        {atsLink}
                    </a>
                </div>
                {usernameInfo}
                {passwordInfo}
            </div>
            <div className="ats-submission-attachment">
                <div className="email-attachments">{attachment || existingAttachment}</div>
            </div>
        </>
    ) : null;

    return (
        <Dialog open={true} onClose={props.onRequestClose}>
            <DialogTitle>
                <Typography variant="h4" component="div">
                    Submit to Client
                </Typography>
            </DialogTitle>
            <DialogContent>
                <div className="ats-submission-body">
                    <Editor
                        value={description}
                        className="ats-submission-description"
                        label="Submission Steps"
                        disabled={true}
                    />
                    {atsInfo}
                </div>
            </DialogContent>
            <DialogActions>
                {dropzone}
                {actions}
            </DialogActions>
        </Dialog>
    );
};
