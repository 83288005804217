import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Header } from '../components/header';
import { WeeklyUpdate as WeeklyUpdateComponent } from '../containers/weekly-update';

interface RouterProps {
    clientId: string;
    all: string;
}

export const WeeklyUpdate: React.SFC<RouteComponentProps<RouterProps>> = ({ match }) => (
    <div id="container">
        <Header title="Client Weekly Update" />
        <div id="content" className="flex-fill">
            <WeeklyUpdateComponent id={match.params.clientId} all={match.params.all === 'all'} />
        </div>
    </div>
);
